import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './InspectionType.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getInspectionType } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialInspectionTypeState } from '../../store/reducers/initialState';
import { getInspectionTypeThunk, saveInspectionTypeThunk } from '../../store/actions/inspectionType';
import InspectionTypeListTable from './InspectionTypeListTable';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const InspectionTypeList = ({ userProfileWidgets, intl }) => {
  const history = useHistory();

  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;

  const dispatch = useDispatch();
  const inspectionType = useSelector(state => getInspectionType(state));

  let initialRecords = inspectionType.InspectionTypes.slice(0, initialLimit);
  const [inspectionTypeOpen, setInspectionTypeOpen] = useState(false);

  useEffect(() => {
    dispatch(getInspectionTypeThunk('WQSP_INSPECTIONTYPE_LIST'));
  }, [getInspectionTypeThunk, dispatch, inspectionType.isOpSuccessful]);

  const [localInspectionTypes, setLocalInspectionTypes] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [inspectionTypeId, setInspectionTypeId] = useState(0);

  const [searchText, setSearchText] = useState('');

  const localInspectionTypeLength = localInspectionTypes.length;
  const globalSettingLength = inspectionType.InspectionTypes.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalInspectionTypes(inspectionType.InspectionTypes);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = inspectionType.InspectionTypes.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalInspectionTypes(filteredList);
  };

  const filterData = () => {
    let filteredList = inspectionType.InspectionTypes;
    if (isFilter) {
      filteredList = inspectionType.InspectionTypes.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getInspectionTypeData = () => {
    let inspectionType = [];
    if (showAll) {
      inspectionType = filterData();
    } else {
      if (isFilter) {
        inspectionType = localInspectionTypeLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        inspectionType = initialRecords;
      }
    }
    return inspectionType.sort(compareValues('Name'));
  };

  const onRemoveInspectionTypeClick = inspectionTypeId => {
    setActive(true);
    setInspectionTypeId(inspectionTypeId);
  };

  const DeleteInspectionType = () => {
    let saveData = inspectionType.SelectedInspectionType;
    saveData.PerformDelete = true;
    saveData.InspectionTypeId = inspectionTypeId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveInspectionTypeThunk(saveData, 'WQSP_INSPECTIONTYPE_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setInspectionTypeId(0);
  };

  const handleConfirm = () => {
    DeleteInspectionType();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push('/operational-setup/inspection-report/inspection-type');
  };

  const onChangeInspectionTypeClick = inspectionTypeId => {
    history.push(`/operational-setup/inspection-report/inspection-type/${inspectionTypeId}`);
  };

  let messageId = (inspectionType && inspectionType.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONTYPE_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONTYPE_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'inspectionTypes.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this inspection type?'
  });

  return (
    <div className={s.inspectionTypeList}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'WQSP_INSPECTIONTYPE_LIST')}>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={inspectionTypeOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setInspectionTypeOpen(!inspectionTypeOpen)}
                aria-controls="model-component-type-section"
                aria-expanded={inspectionTypeOpen}
              ></img>
              <h4>
                <FormattedMessage id="inspectionTypes.inspectionType" defaultMessage="Inspection Type" />
              </h4>
              <span className={s.circle}>{inspectionType.InspectionTypes.length}</span>
            </Row>
          </Col>
        </Row>
        <Collapse in={inspectionTypeOpen}>
          <div className={s.innerComponent} id="model-component-type-section">
            <div className={s.component}>
              {inspectionType.isLoading && <LoadingSpinner />}
              {inspectionType.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={inspectionType.showBanner}
                  status={inspectionType.isOpSuccessful}
                  successText={messageText}
                />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <div className={s.contentWrapper}>
                <Row className={s.topRow}>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={inspectionType.showAddEditScreen}
                      // initialText={filter}
                      placeHolderTextId="common.search"
                      data-unittest="searchInspectionTypes"
                    />
                  </Col>
                  <Col sm={6} lg={8}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'WQSP_INSPECTIONTYPE_ADDNEW')}
                      onClick={onAddNewClick}
                      disabled={inspectionType.showAddEditScreen}
                      data-unittest="addInspectionTypeButton"
                    >
                      + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>

                <div>
                  <InspectionTypeListTable
                    RemoveInspectionType={onRemoveInspectionTypeClick}
                    InspectionTypeData={getInspectionTypeData()}
                    ChangeInspectionType={onChangeInspectionTypeClick}
                    UpdateAllowed={isUpdateAllowed}
                    DeleteAllowed={isDeleteAllowed}
                  />
                </div>
                {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localInspectionTypeLength > initialLimit)) && (
                  <Row className={s.topRow}>
                    <Col>
                      <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                        {!showAll
                          ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localInspectionTypeLength})`
                          : showLessText}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

InspectionTypeList.defaultProps = {
  ...initialInspectionTypeState
};

export default injectIntl(InspectionTypeList);
