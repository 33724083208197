import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from './TestResultReport.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import uuidv4 from 'uuid/v4';
import { compareValues } from '../../utils';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';

const ReportItem = ({ onAddReportItem, testCategories, resultItems, onCancelReportItem, qualityReport, intl }) => {
  const [localformErrors, setFormErrors] = useState({});
  const [localTestCategory, setLocalTestCategory] = useState({});

  const onCancelClick = () => {
    setLocalTestCategory({});
    onCancelReportItem();
  };

  useEffect(() => {
    return () => {
      onCancelClick();
    };
  }, []);

  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }
    let testCategory = { ...localTestCategory };
    testCategory.GuidIdentifier = uuidv4();
    if (qualityReport) {
      testCategory.QualityGoalsReportItemId = 0;
    } else {
      testCategory.TestResultReportItemId = 0;
      testCategory.Value = '';
    }

    onAddReportItem(testCategory);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!localTestCategory || !localTestCategory.TestCategoryId) {
      formErrors.TestCategory = intl.formatMessage({
        id: 'testResultItem.testCategoryRequired',
        defaultMessage: 'Test Category is required'
      });
      isValid = false;
    }

    if (!localTestCategory || !localTestCategory.TestCategoryItemId) {
      formErrors.TestCategoryType = intl.formatMessage({
        id: 'testResultItem.testTypeRequired',
        defaultMessage: 'Test type is required'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const categoryOptions = () => {
    let categoryData = [];
    testCategories.forEach(element => {
      categoryData.push({
        ...element,
        label: element.Name,
        value: element.TestCategoryId
      });
    });

    let sortedData = categoryData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onCategoryDropdownChange = e => {
    setLocalTestCategory({
      ...localTestCategory,
      TestCategoryId: e.value,
      CategoryName: e.label
    });
  };

  const categoryTypeOptions = () => {
    let categoryTypeData = [];
    let categoryTypes = [];
    let existingCategoryTypes = [];
    if (localTestCategory.TestCategoryId) {
      let category = testCategories.find(item => item.TestCategoryId === localTestCategory.TestCategoryId);
      if (category) {
        categoryTypes = category.testCategoryTypes || [];
      }
      let existingCategory = resultItems.find(item => !item.IsDeleted && item.TestCategoryId === localTestCategory.TestCategoryId);
      if (existingCategory) {
        existingCategoryTypes = existingCategory.testCategoryTypes || [];
      }
    }
    categoryTypes.forEach(element => {
      if (existingCategoryTypes.find(item => !item.IsDeleted && item.TestCategoryItemId === element.TestCategoryItemId)) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
      categoryTypeData.push({
        ...element,
        label: element.Name,
        value: element.TestCategoryItemId
      });
    });

    let sortedData = categoryTypeData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onCategoryTypeDropdownChange = e => {
    setLocalTestCategory({
      ...localTestCategory,
      TestCategoryItemId: e.value,
      Name: e.label,
      UnitOfMeasure: e.UnitOfMeasure,
      RealisticMeanValue: e.RealisticMeanValue,
      RealisticMaxValue: e.RealisticMaxValue,
      RealisticLRV: e.RealisticLRV,
      AspirationalMeanValue: e.AspirationalMeanValue,
      AspirationalMaxValue: e.AspirationalMaxValue,
      AspirationalLRV: e.AspirationalLRV
    });
  };

  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });

  return (
    <div className={s.testcategory}>
      <div className={s.testcategoryContent}>
        <div className={s.topRow}>
          <Row>
            <Col lg={4}>
              <Form.Group controlId="formTestCategory">
                <Form.Label>
                  <FormattedMessage id="categoryType.testCategory" defaultMessage="Test Category" />
                </Form.Label>

                <Dropdown
                  id="formDdlTestCategory"
                  dataArray={categoryOptions()}
                  controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.TestCategory && s.ddlError }}
                  onDropdownChange={onCategoryDropdownChange}
                  data-unittest="formDdlTestCategory"
                  selectedOption={categoryOptions().filter(option => option.value === localTestCategory.TestCategoryId)}
                />
                {localformErrors && localformErrors.TestCategory && (
                  <p role="alert" className={s.error}>
                    {localformErrors.TestCategory}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group controlId="formTestType">
                <Form.Label>
                  <FormattedMessage id="wqsp.common.TestType" defaultMessage="Test Type" />
                </Form.Label>

                <Dropdown
                  id="formDdlTestCategoryType"
                  dataArray={categoryTypeOptions()}
                  controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.TestCategoryType && s.ddlError }}
                  onDropdownChange={onCategoryTypeDropdownChange}
                  data-unittest="formDdlTestCategoryType"
                  selectedOption={categoryTypeOptions().filter(option => option.value === localTestCategory.TestCategoryItemId)}
                />
                {localformErrors && localformErrors.TestCategoryType && (
                  <p role="alert" className={s.error}>
                    {localformErrors.TestCategoryType}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChangesSmall)}
                onClick={submitForm}
                noValidate
                data-unittest="add"
              >
                <FormattedMessage id="common.add" defaultMessage="ADD" />
              </Button>
              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancelSmall)}
                onClick={onCancelClick}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
            <Col lg={8}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ReportItem);
