import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './HazardEvent.module.scss';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Activity from '../WSAControls/Activity/Activity';
import uuidv4 from 'uuid/v4';
import { initialHazardEventState } from '../../store/reducers/initialState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { compareValues } from '../../utils';

const HazardEventComments = ({ hazardEvent, saveHazardEventComment, UpdateAllowed, currentTimezone }) => {
  const [hazardEventComment, setHazardEventComment] = useState('');

  const commentChangeHandler = e => {
    setHazardEventComment(e.target.value);
  };

  const commentSaveHandler = () => {
    if (hazardEventComment.length > 0) {
      const comment = {
        HazardEventId: hazardEvent.selectedHazardEvent.HazardEventId,
        GuidIdentifier: uuidv4(),
        Description: hazardEventComment
      };
      saveHazardEventComment(comment);
      setHazardEventComment('');
    }
  };

  const commentCancelHandler = () => {
    setHazardEventComment('');
  };

  const comments = () => {
    let commentstData = hazardEvent.selectedHazardEvent.comments || [];

    if (commentstData && commentstData.length > 0) {
      commentstData = commentstData.map(comment => ({
        ...comment,
        ActivityDate: comment.CreatedDateTime,
        CreatedByName: comment.FullName,
        Id: comment.HazardEventCommentId
      }));
    }

    return commentstData.sort(compareValues('ActivityDate', 'desc'));
  };

  const headertext = '';

  return (
    <div className={s.hazardeventContent}>
      {hazardEvent.isLoading && <LoadingSpinner />}
      <Row className={s.breakrow}>
        <Col sm={12}>
          <h4>
            <FormattedMessage id="common.comments" defaultMessage="Comments" />
            <span data-unittest="hazardDataCount" className={s.circle}>
              {hazardEvent.selectedHazardEvent.comments.length}
            </span>
          </h4>
        </Col>
      </Row>
      <Row className={s.breakrow}>
        <Col sm={12}>
          <Activity
            description={hazardEventComment || ''}
            onActivityModified={commentChangeHandler}
            onSaveClick={commentSaveHandler}
            onCancelClick={commentCancelHandler}
            currentTimezone={currentTimezone}
            activities={comments()}
            isUpdateAllowed={UpdateAllowed}
            isViewAllowed={true}
            headingText={headertext}
          />
        </Col>
      </Row>
    </div>
  );
};

HazardEventComments.defaultProps = {
  hazardEvent: {
    ...initialHazardEventState
  }
};

HazardEventComments.propTypes = {
  hazardEvent: PropTypes.object.isRequired,
  saveHazardEventComment: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  UpdateAllowed: PropTypes.bool.isRequired
};

export default HazardEventComments;
