import React, { useState } from 'react';
import s from './EmergencyPlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { WidgetVisibility } from '../../../utils/widgetManager';
import SearchBar from '../../SearchBar/SearchBar';
import { getDateInDDMMYYYFormat } from '../../../utils';
import { CHECKLIST_STATUS_ACTIVE } from '../../../constants/index';
import { useHistory } from 'react-router-dom';

const ChecklistItemTable = ({ checklistItems, currentTimeZone, intl }) => {
  const [searchFilter, setSearchFilter] = useState('');
  const history = useHistory();
  const searchHandler = value => {
    setSearchFilter(value);
  };

  const viewHandler = checklistId => {
    history.push(`/tasks-and-checklists/checklist-detail/${checklistId}?view=1`);
  };

  let checklistItemsFiltered =
    (searchFilter && checklistItems.filter(item => item.Name.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0)) || checklistItems;

  return (
    <div>
      <div className={s.emergencyPlanItemContent}>
        <Row>
          <Col>
            <h4 className={s.subheading}>
              <FormattedMessage id="emergencyPlan.checklists" defaultMessage="Checklists" />
              <span className={s.circle}>{checklistItemsFiltered.filter(item => !item.IsDeleted).length || 0}</span>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              initialText={searchFilter}
              placeHolderTextId="emergencyPlan.searchChecklist"
            />
          </Col>
        </Row>
      </div>
      <div>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="common.name" defaultMessage="Name" />
              </th>
              <th>
                <FormattedMessage id="emergencyResponse.dueDateTime" defaultMessage="Due date time" />
              </th>
              <th>
                <FormattedMessage id="emergencyResponse.taskCompleted" defaultMessage="Tasks Completed" />
              </th>
              <th>
                <FormattedMessage id="common.status" defaultMessage="Status" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {checklistItemsFiltered.map(item => {
              return (
                <tr
                  key={item.ChecklistId}
                  data-unittest="EventItemList"
                  className={item.Status === CHECKLIST_STATUS_ACTIVE ? s.active : s.complete}
                >
                  <td>{item.Name}</td>
                  <td>
                    {item.NoOfTask}/{item.NoOfCompletedTasks}
                  </td>
                  <td>{getDateInDDMMYYYFormat(item.DueDateTime, currentTimeZone)}</td>
                  <td>
                    <span className={`${s.dot} ${item.Status === CHECKLIST_STATUS_ACTIVE ? s.dotActive : s.dotComplete}`}></span>{' '}
                    {item.Status}
                  </td>
                  <td>
                    <div className={s.buttonContainer}>
                      <Button
                        variant="outline-secondary"
                        className={s.removeButton}
                        onClick={viewHandler.bind(this, item.ChecklistId)}
                        data-unittest="viewData"
                      >
                        <FormattedMessage id="common.view" defaultMessage="VIEW" />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

ChecklistItemTable.defaultProps = {
  checklistItems: {},
  userProfileWidgets: []
};

export default injectIntl(ChecklistItemTable);
