import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './RiskAssessment.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE, RISK_STATUS, RISK_RATINGS } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import RiskAssessmentListTable from './RiskAssessmentListTable';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import { getRiskRating } from '../../utils/appUtils';
import { getUserProfileWidget, getRiskAssessment, getGeneralData } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { initialRiskAssessmentState } from '../../store/reducers/initialState';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import SearchBar from '../SearchBar/SearchBar';
import Cookies from 'js-cookie';
import {
  getRiskAssessmentThunk,
  setFilteredDataThunk,
  setCurrentPage,
  setRiskAssessmentChangeThunk,
  getFilterThunk,
  setPageFilter,
  saveRiskAssessmentThunk
} from '../../store/actions/riskAssessment';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';

const SITE = 'SITE';
const WATER_SUPPLY_DESCRIPTION = 'WATER_SUPPLY_DESCRIPTION';
const ENTITY_TYPE = 'ENTITY_TYPE';
const ENTITY = 'ENTITY';
const STATUS = 'STATUS';
const INHERENT_RISK_RATING = 'INHERENT_RISK_RATING';
const RESIDUAL_RISK_RATING = 'RESIDUAL_RISK_RATING';
const SEARCH_FILTER = 'SEARCH_FILTER';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const RiskAssessmentList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const riskAssessment = useSelector(state => getRiskAssessment(state));
  const generalData = useSelector(state => getGeneralData(state));
  let { siteId, entityId, segmentName, selectedTab, hazardEventId } = useParams();
  // //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setRiskAssessmentChangeThunk({
          ...initialRiskAssessmentState.riskAssesment
        })
      );
    };
  }, []);

  const [active, setActive] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [riskAssessmentId, setRiskAssessmentId] = useState(0);
  const [filterSiteId, setFilterSiteId] = useState((siteId && parseInt(siteId)) || 0);
  const [filterWaterSupplyDescriptionId, setFilterWaterSupplyDescriptionId] = useState(0);
  const [filterEntityTypeId, setFilterEntityTypeId] = useState(0);
  const [filterEntityId, setFilterEntityId] = useState(0);
  const [filterStatusId, setFilterStatusId] = useState(0);
  const [filterInherentRiskRating, setFilterInherentRiskRating] = useState('');
  const [filterResidualRiskRating, setFilterResidualRiskRating] = useState('');

  const [entities, setEntities] = useState([]);
  const [asendingOrder, setAsendingOrder] = useState(true);

  let filter = riskAssessment.filterBy.filter || null;
  let offset = riskAssessment.offset || 0;
  let limit = riskAssessment.limit || 1000;

  let filterLocalSiteId = siteId || riskAssessment.filterBy.filterSiteId || null;
  let filterLocalHazardEventId = hazardEventId || riskAssessment.filterBy.filterHazardEventId || null;
  let filterLocalWaterSupplyDescriptionId = riskAssessment.filterBy.filterWaterSupplyDescriptionId || null;
  let filterLocalEntityId = riskAssessment.filterBy.filterEntityId || null;
  let filterLocalEntityTypeId = riskAssessment.filterBy.filterEntityTypeId || null;
  let filterLocalStatusId = riskAssessment.filterBy.filterStatusId || null;
  let filterLocalInherentRiskRating = riskAssessment.filterBy.filterInherentRiskRating || null;
  let filterLocalResidualRiskRating = riskAssessment.filterBy.filterResidualRiskRating || null;

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_RISKASSESSMENT_LIST', siteId, hazardEventId));
  }, [dispatch, getFilterThunk, siteId, hazardEventId]);

  useEffect(() => {
    let filterObj = {
      filter: filter,
      filterSiteId: filterLocalSiteId,
      filterHazardEventId: filterLocalHazardEventId,
      filterWaterSupplyDescriptionId: filterLocalWaterSupplyDescriptionId,
      filterEntityId: filterLocalEntityId,
      filterEntityTypeId: filterLocalEntityTypeId,
      filterStatusId: filterLocalStatusId,
      filterInherentRiskRating: filterLocalInherentRiskRating,
      filterResidualRiskRating: filterLocalResidualRiskRating,
      offset: offset,
      limit: limit
    };
    dispatch(getRiskAssessmentThunk(filterObj, 'WQSP_RISKASSESSMENT_LIST'));
  }, [
    getRiskAssessmentThunk,
    filter,
    filterLocalSiteId,
    filterLocalHazardEventId,
    filterLocalWaterSupplyDescriptionId,
    filterLocalEntityId,
    filterLocalEntityTypeId,
    filterLocalStatusId,
    filterLocalInherentRiskRating,
    filterLocalResidualRiskRating
  ]);

  let disableControls = (riskAssessment && riskAssessment.isLoading) || false;

  let messageId = (riskAssessment && riskAssessment.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const siteOptions = () => {
    let siteData = [];

    generalData.sites.forEach(element => {
      siteData.push({
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allSites" defaultMessage="All Sites" />,
      value: 0
    });
    return sortedData;
  };

  const waterSupplyDescriptionOptions = () => {
    let waterSupplyDescriptionData = [];

    generalData.waterSupplyDescriptions.forEach(element => {
      if (element.SiteId === filterSiteId) {
        waterSupplyDescriptionData.push({
          label: element.Name,
          value: element.WaterSupplyDescriptionId
        });
      }
    });

    let sortedData = waterSupplyDescriptionData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allWaterSupplies" defaultMessage="All Water Supplies" />,
      value: 0
    });
    return sortedData;
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];

    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
      value: 0
    });
    return sortedData;
  };

  const entityOptions = () => {
    let entityData = [];

    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntities" defaultMessage="All Entities" />,
      value: 0
    });
    return sortedData;
  };

  const riskStatus = generalData.generalFieldTypes.filter(item => item.FieldType === RISK_STATUS);
  const statusOptions = () => {
    let statusData = [];

    riskStatus.forEach(element => {
      statusData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allStatus" defaultMessage="All Status" />,
      value: 0
    });
    return sortedData;
  };

  const riskRatingOptions = type => {
    let opData = [];

    RISK_RATINGS.forEach(element => {
      let rating = getRiskRating(element.name, intl);
      opData.push({
        label: rating,
        value: element.name
      });
    });

    let sortedData = opData.sort(compareValues('label'));
    if (type === INHERENT_RISK_RATING) {
      sortedData.splice(0, 0, {
        label: <FormattedMessage id="riskAssessment.allInherentRiskRatings" defaultMessage="All Inherent Risk Ratings" />,
        value: ''
      });
    } else {
      sortedData.splice(0, 0, {
        label: <FormattedMessage id="riskAssessment.allResidualRiskRatings" defaultMessage="All Residual Risk Ratings" />,
        value: ''
      });
    }

    return sortedData;
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onSiteChange = e => {
    setFilterSiteId(e.value);
    setFilterWaterSupplyDescriptionId(0);
    setFilterEntityTypeId(0);
    setFilterEntityId(0);
    filteredResults(SITE, e.value);
    setCurrentPage(1);
  };

  const onWaterSupplyDescriptionChange = e => {
    setFilterWaterSupplyDescriptionId(e.value);
    setFilterEntityTypeId(0);
    setFilterEntityId(0);
    filteredResults(WATER_SUPPLY_DESCRIPTION, e.value);
    setCurrentPage(1);
  };

  const onEntityTypeChange = e => {
    setFilterEntityTypeId(e.value);
    setFilterEntityId(0);
    filteredResults(ENTITY_TYPE, e.value);
    let locFilteredOption = generalData.entities.filter(item => item.EntityTypeId === e.value);
    if (filterWaterSupplyDescriptionId) {
      locFilteredOption = locFilteredOption.filter(item => item.WaterSupplyDescriptionId === filterWaterSupplyDescriptionId);
    }
    setEntities(locFilteredOption);
    setCurrentPage(1);
  };

  const onEntityChange = e => {
    setFilterEntityId(e.value);
    filteredResults(ENTITY, e.value);
    setCurrentPage(1);
  };

  const onStatusChange = e => {
    setFilterStatusId(e.value);
    filteredResults(STATUS, e.value);
    setCurrentPage(1);
  };

  const onInherentRiskRatingChange = e => {
    setFilterInherentRiskRating(e.value);
    filteredResults(INHERENT_RISK_RATING, e.value);
    setCurrentPage(1);
  };

  const onResidualRiskRatingChange = e => {
    setFilterResidualRiskRating(e.value);
    filteredResults(RESIDUAL_RISK_RATING, e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = riskAssessment.riskAssessments;

    let apifilterObj = {
      filter: null,
      filterSiteId: null,
      filterWaterSupplyDescriptionId: null,
      filterEntityId: null,
      filterEntityTypeId: null,
      filterStatusId: null,
      filterInherentRiskRating: null,
      filterResidualRiskRating: null
    };

    if (filterType === SITE) {
      if (value !== 0) {
        apifilterObj.filterSiteId = value;
        filteredList = filteredList.filter(function(item) {
          return item.SiteId === value;
        });
      }
    } else {
      if (filterSiteId !== 0) {
        apifilterObj.filterSiteId = filterSiteId;
        filteredList = filteredList.filter(function(item) {
          return item.SiteId === filterSiteId;
        });
      }
    }

    if (filterType === WATER_SUPPLY_DESCRIPTION) {
      if (value !== 0) {
        apifilterObj.filterWaterSupplyDescriptionId = value;
        filteredList = filteredList.filter(function(item) {
          return item.WaterSupplyDescriptionId === value;
        });
      }
    } else {
      if (filterWaterSupplyDescriptionId !== 0) {
        apifilterObj.filterWaterSupplyDescriptionId = filterWaterSupplyDescriptionId;
        filteredList = filteredList.filter(function(item) {
          return item.WaterSupplyDescriptionId === filterWaterSupplyDescriptionId;
        });
      }
    }

    if (filterType === ENTITY_TYPE) {
      if (value !== 0) {
        apifilterObj.filterEntityTypeId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === value;
        });
      }
    } else {
      if (filterEntityTypeId !== 0) {
        apifilterObj.filterEntityTypeId = filterEntityTypeId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityTypeId;
        });
      }
    }

    if (filterType === ENTITY) {
      if (value !== 0) {
        apifilterObj.filterEntityId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === value;
        });
      }
    } else {
      if (filterEntityId !== 0) {
        apifilterObj.filterEntityId = filterEntityId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === filterEntityId;
        });
      }
    }

    if (filterType === STATUS) {
      if (value !== 0) {
        apifilterObj.filterStatusId = value;
        filteredList = filteredList.filter(function(item) {
          return item.StatusId === value;
        });
      }
    } else {
      if (filterStatusId !== 0) {
        apifilterObj.filterStatusId = filterStatusId;
        filteredList = filteredList.filter(function(item) {
          return item.StatusId === filterStatusId;
        });
      }
    }

    if (filterType === INHERENT_RISK_RATING) {
      if (value !== '') {
        apifilterObj.filterInherentRiskRating = value;
        filteredList = filteredList.filter(function(item) {
          return item.InherentRiskRating === value;
        });
      }
    } else {
      if (filterInherentRiskRating !== '') {
        apifilterObj.filterInherentRiskRating = filterInherentRiskRating;
        filteredList = filteredList.filter(function(item) {
          return item.InherentRiskRating === filterInherentRiskRating;
        });
      }
    }

    if (filterType === RESIDUAL_RISK_RATING) {
      if (value !== '') {
        apifilterObj.filterResidualRiskRating = value;
        filteredList = filteredList.filter(function(item) {
          return item.ResidualRiskRating === value;
        });
      }
    } else {
      if (filterResidualRiskRating !== '') {
        apifilterObj.filterResidualRiskRating = filterResidualRiskRating;
        filteredList = filteredList.filter(function(item) {
          return item.ResidualRiskRating === filterResidualRiskRating;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.RiskCategoryName.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.RiskCategoryName.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (riskAssessment.riskAssessmentListCount > riskAssessment.riskAssessments.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let riskAssessmentsObj = (riskAssessment && Object.values(riskAssessment.filteredResults)) || [];

  if (asendingOrder) {
    riskAssessmentsObj = riskAssessmentsObj.sort(compareValues('DateRaised'));
  } else {
    riskAssessmentsObj = riskAssessmentsObj.sort(compareValues('DateRaised', 'desc'));
  }

  const onAddNewClick = () => {
    if (segmentName === 'identify') {
      if (hazardEventId) {
        history.push(
          `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment`
        );
      } else {
        history.push(`/site/${siteId}/${segmentName}/${selectedTab}/risk-assessments/risk-assessment`);
      }
    } else if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment`
      );
    } else {
      history.push('/risk-assessments/risk-assessment');
    }
  };

  const onViewClick = (riskAssessmentId, selectedSiteId) => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment-view/${riskAssessmentId}`
      );
    } else if (segmentName === 'identify' && hazardEventId) {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment-view/${riskAssessmentId}`
      );
    } else {
      history.push(`/site/${selectedSiteId}/identify/3/risk-assessments/risk-assessment-view/${riskAssessmentId}`);
    }
  };

  const onRemoveClick = riskAssessmentId => {
    setActive(true);
    setRiskAssessmentId(riskAssessmentId);
  };

  const handleClose = () => {
    setActive(false);
    setRiskAssessmentId(0);
  };

  const handleConfirm = () => {
    deleteHazardEvent();
    handleClose();
  };

  const deleteHazardEvent = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.RiskAssessmentId = riskAssessmentId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveRiskAssessmentThunk(saveData, 'WQSP_RISKASSESSMENT_DELETE'));
  };

  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKASSESSMENT_VIEW');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKASSESSMENT_DELETE');
  const dialogTitle = intl.formatMessage({
    id: 'riskAssessment.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this risk assessment?'
  });

  const hazardEventName = riskAssessmentsObj.length > 0 ? ' (' + riskAssessmentsObj[0].HazardEventName + ')' : '';
  let hazardEventHeading = intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + hazardEventName;

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  const identifyHazardEventcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  let breadcrumb = identifyHazardEventcrumb;

  if (segmentName === 'define') {
    breadcrumb = defineEntityCrumb;
  }

  return (
    <div className={s.riskAssessmentList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {segmentName ? (
          hazardEventId && <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        ) : (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />}</h1>
          </div>
        )}

        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
        <div className={s.contentWrapper}>
          {riskAssessment.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={riskAssessment.showBanner}
            status={riskAssessment.isOpSuccessful}
            successText={messageText}
          />
          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="riskAssessment.searchRiskCategory"
              />
            </Col>
            <Col sm={6} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_RISKASSESSMENT_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                {' '}
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          {!entityId && (
            <Row>
              {!siteId && (
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drp"
                    dataArray={siteOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />,
                      customClassName: ''
                    }}
                    onDropdownChange={e => onSiteChange(e)}
                    selectedOption={siteOptions().filter(option => option.value === filterSiteId)}
                    disabled={disableControls}
                  />
                </Col>
              )}
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpWaterSupllyDescriptionId"
                  dataArray={waterSupplyDescriptionOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="wqsp.common.waterSupplyDescription" defaultMessage="Water Supply Description" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onWaterSupplyDescriptionChange(e)}
                  selectedOption={waterSupplyDescriptionOptions().filter(option => option.value === filterWaterSupplyDescriptionId)}
                  disabled={disableControls}
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpEntityTypeId"
                  dataArray={entityTypeOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onEntityTypeChange(e)}
                  selectedOption={entityTypeOptions().filter(option => option.value === filterEntityTypeId)}
                  disabled={disableControls}
                />
              </Col>
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpEntityId"
                  dataArray={entityOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onEntityChange(e)}
                  selectedOption={entityOptions().filter(option => option.value === filterEntityId)}
                  disabled={disableControls}
                />
              </Col>
            </Row>
          )}
          <Row className="pb-2">
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpInherentRiskRating"
                dataArray={riskRatingOptions(INHERENT_RISK_RATING)}
                controlData={{
                  placeholderText: <FormattedMessage id="riskAssessment.inherentRiskRating" defaultMessage="Inherent Risk Rating" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onInherentRiskRatingChange(e)}
                selectedOption={riskRatingOptions(INHERENT_RISK_RATING).filter(option => option.value === filterInherentRiskRating)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpResidualRiskRating"
                dataArray={riskRatingOptions(RESIDUAL_RISK_RATING)}
                controlData={{
                  placeholderText: <FormattedMessage id="riskAssessment.residualRiskRating" defaultMessage="Residual Risk Rating" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onResidualRiskRatingChange(e)}
                selectedOption={riskRatingOptions(RESIDUAL_RISK_RATING).filter(option => option.value === filterResidualRiskRating)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpStatus"
                dataArray={statusOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="common.status" defaultMessage="Status" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onStatusChange(e)}
                selectedOption={statusOptions().filter(option => option.value === filterStatusId)}
                disabled={disableControls}
              />
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={riskAssessmentsObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={riskAssessment.currentPage}
            >
              <RiskAssessmentListTable
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                viewRiskAssessment={onViewClick}
                removeRiskAssessment={onRemoveClick}
                viewAllowed={isViewAllowed}
                deleteAllowed={isDeleteAllowed}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(RiskAssessmentList);
