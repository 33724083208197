import { connect } from 'react-redux';

import OperationalSetup from './OperationalSetup';
import { getUserProfileWidget } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    userProfileWidgets: getUserProfileWidget(state)
  };
};

export default connect(mapStateToProps, null)(OperationalSetup);
