import { initialOrganisationTranslationState } from '../initialState';

import {
  ORGANISATION_TRANSLATION_ERROR,
  GET_ALL_ORGANISATION_TRANSLATION_DETAILS,
  ORGANISATION_TRANSLATION_IS_LOADING,
  SET_ORGANISATION_TRANSLATION_CURRENT_PAGE,
  SET_ORGANISATION_TRANSLATION_PAGE_FILTER,
  SET_ORGANISATION_TRANSLATION_CHANGE,
  SET_ORGANISATION_TRANSLATION,
  UPDATE_ORGANISATION_TRANSLATION_SAVE_STATUS,
  SET_ORGANISATION_TRANSLATION_FILTERS,
  SET_ORGANISATION_TRANSLATION_RESULTS,
  SET_ORGANISATION_TRANSLATION_CLEANUP,
  REMOVE_ORGANISATION_TRANSLATION
} from '../../../constants/index';

const organisationTranslationReducer = (state = initialOrganisationTranslationState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_ORGANISATION_TRANSLATION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          ...data
        }
      };
    }

    case SET_ORGANISATION_TRANSLATION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          currentPage
        }
      };
    }

    case SET_ORGANISATION_TRANSLATION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_ORGANISATION_TRANSLATION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          ...data
        }
      };
    }

    case ORGANISATION_TRANSLATION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          isLoading: isLoading
        }
      };
    }

    case ORGANISATION_TRANSLATION_ERROR: {
      const { data } = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          ...data
        }
      };
    }

    case SET_ORGANISATION_TRANSLATION: {
      let { selectedOrganisationTranslation } = payload;

      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          isOpSuccessful: false,
          showBanner: false,
          selectedOrganisationTranslation: {
            ...state.organisationTranslation.selectedOrganisationTranslation,
            ...selectedOrganisationTranslation
          }
        }
      };
    }

    case SET_ORGANISATION_TRANSLATION_CHANGE: {
      let { selectedOrganisationTranslation } = payload;

      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedOrganisationTranslation: {
            ...state.organisationTranslation.selectedOrganisationTranslation,
            ...selectedOrganisationTranslation
          }
        }
      };
    }

    case SET_ORGANISATION_TRANSLATION_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          ...filteredResults
        }
      };
    }

    case SET_ORGANISATION_TRANSLATION_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          ...data
        }
      };
    }

    case REMOVE_ORGANISATION_TRANSLATION: {
      const { organisationTranslationId } = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          isOpSuccessful: true,
          showBanner: false,
          isRedirect: false,
          OrganisationTranslations: [
            ...state.organisationTranslation.OrganisationTranslations.filter(
              item => item.OrganisationTranslationId !== organisationTranslationId
            )
          ],
          filteredResults: [
            ...state.organisationTranslation.OrganisationTranslations.filter(
              item => item.OrganisationTranslationId !== organisationTranslationId
            )
          ],
          organisationTranslationListCount: state.organisationTranslation.organisationTranslationListCount - 1
        }
      };
    }

    case SET_ORGANISATION_TRANSLATION_CLEANUP: {
      let selectedOrganisationTranslation = payload;
      return {
        ...state,
        organisationTranslation: {
          ...state.organisationTranslation,
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          selectedOrganisationTranslation: selectedOrganisationTranslation
        }
      };
    }

    default:
      return state;
  }
};

export default organisationTranslationReducer;
