import React, { useEffect } from 'react';
import s from './ImprovementPlan.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialImprovementPlanState } from '../../../store/reducers/initialState';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { getDateInDDMMYYYFormat } from '../../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import uuid from 'uuid';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../../WSAControls/Breadcrumb';

import { getImprovementPlan, getUserProfileWidget, getCurrentTimezone } from '../../../selectors/index';
import {
  describeImprovementPlanThunk,
  saveImprovementPlanThunk,
  setImprovementPlanChangeThunk
} from '../../../store/actions/hazardEvent/improvementPlan';
import ImprovementPlanComments from './ImprovementPlanComments';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const ImprovementPlanView = ({ intl }) => {
  let IsEdit = false;
  const dispatch = useDispatch();
  let { improvementPlanId, hazardEventId, siteId, entityId, segmentName, selectedTab } = useParams();

  const history = useHistory();
  segmentName = segmentName || 'control';
  selectedTab = selectedTab || '3';

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const improvementPlan = useSelector(state => getImprovementPlan(state));

  let disableControls = improvementPlan.isLoading || false;

  if (improvementPlanId && improvementPlanId > 0) {
    IsEdit = true;
  } else {
    improvementPlanId = 0;
  }
  let widgetCode = 'WQSP_IMPROVEMENT_PLAN_VIEW';
  let addCommentsAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_IMPROVEMENT_PLAN_COMMENT_ADD`);

  useEffect(() => {
    dispatch(describeImprovementPlanThunk(improvementPlanId, widgetCode));
  }, [describeImprovementPlanThunk, improvementPlanId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setImprovementPlanChangeThunk({
          ...initialImprovementPlanState.improvementPlan
        })
      );
    };
  }, []);

  const saveImprovementPlanComment = comment => {
    let saveData = {
      ...comment
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.performCommentInsert = true;
    dispatch(saveImprovementPlanThunk(saveData, 'WQSP_IMPROVEMENT_PLAN_COMMENT_ADD'));
  };

  const onChangeClick = () => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans/improvement-plan/${improvementPlanId}`
      );
    } else if (segmentName === 'identify') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans/improvement-plan/${improvementPlanId}`
      );
    } else {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/improvement-plans/improvement-plan/${improvementPlanId}`);
    }
  };

  let messageId = (improvementPlan && improvementPlan.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const actionName = improvementPlan.selectedImprovementPlan.IsActioned ? yesText : noText;

  let breadcrumbHeading = intl.formatMessage({
    id: 'siteControlsTabs.listOfImprovementPlans',
    defaultMessage: 'List of Improvement Plans'
  });
  let improvementPlanHeading = intl.formatMessage({ id: 'common.viewTextCC', defaultMessage: 'View' });
  improvementPlanHeading = `${improvementPlanHeading} ${
    improvementPlan.selectedImprovementPlan.Name ? ' - ' + improvementPlan.selectedImprovementPlan.Name : ''
  }`;

  const hazardEventName = improvementPlan.selectedImprovementPlan.HazardEventName;
  let hazardEventHeading =
    intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + ' (' + hazardEventName + ')';

  const breadcrumbControl = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/improvement-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: improvementPlanHeading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="improvementPlan.improvementPlans" defaultMessage="Improvement Plans" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 5, name: improvementPlanHeading, url: ``, showlink: false, showCrumb: true }
  ];

  const identifyHazardEventcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="improvementPlan.improvementPlans" defaultMessage="Improvement Plans" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 4, name: improvementPlanHeading, url: ``, showlink: false, showCrumb: true }
  ];

  const getBreadcrumbData = () => {
    if (segmentName === 'define') {
      return defineEntityCrumb;
    } else if (segmentName === 'identify') {
      return identifyHazardEventcrumb;
    } else {
      return breadcrumbControl;
    }
  };

  return (
    <div className={s.improvementPlan}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {improvementPlan.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={improvementPlan.showBanner}
          status={improvementPlan.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          <Breadcrumb data={getBreadcrumbData()} segmentName={segmentName} />
          <div>
            <Row>
              <Col>
                <Row className={s.colActionButton}>
                  <Button
                    className={classNames(s.margin5, s.changeButton)}
                    style={WidgetVisibility(userProfileWidgets, 'WQSP_IMPROVEMENT_PLAN_EDIT')}
                    onClick={() => {
                      onChangeClick();
                    }}
                    disabled={disableControls}
                    data-unittest="changeImprovementPlan"
                  >
                    <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={s.improvementPlanContent}>
            <Form>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                  </Form.Label>
                </Col>
                <Col>{improvementPlan.selectedImprovementPlan.SiteName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                  </Form.Label>
                </Col>
                <Col>{improvementPlan.selectedImprovementPlan.WaterSupplyDescriptionName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                  </Form.Label>
                </Col>
                <Col>{improvementPlan.selectedImprovementPlan.EntityTypeName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                  </Form.Label>
                </Col>
                <Col>{improvementPlan.selectedImprovementPlan.Entity || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="improvementPlan.hazardEvent" defaultMessage="Hazard Event" />
                  </Form.Label>
                </Col>
                <Col>{improvementPlan.selectedImprovementPlan.HazardEventName || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.name" defaultMessage="Name" />
                  </Form.Label>
                </Col>
                <Col>{improvementPlan.selectedImprovementPlan.Name || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.description" defaultMessage="Description" />
                  </Form.Label>
                </Col>
                <Col>{improvementPlan.selectedImprovementPlan.Description || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="improvementPlan.dateRaised" defaultMessage="Date Raised" />
                  </Form.Label>
                </Col>
                <Col>{getDateInDDMMYYYFormat(improvementPlan.selectedImprovementPlan.DateRaised, currentTimezone)}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="improvementPlan.dateDue" defaultMessage="Date Due" />
                  </Form.Label>
                </Col>
                <Col>{getDateInDDMMYYYFormat(improvementPlan.selectedImprovementPlan.DateDue, currentTimezone)}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.lastReviewDate" defaultMessage="Date Of Last Review" />
                  </Form.Label>
                </Col>
                <Col>
                  {(improvementPlan.selectedImprovementPlan.LastReviewDate &&
                    getDateInDDMMYYYFormat(improvementPlan.selectedImprovementPlan.LastReviewDate, currentTimezone)) ||
                    '-'}
                </Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                  </Form.Label>
                </Col>
                <Col>
                  {(improvementPlan.selectedImprovementPlan.NextReviewDate &&
                    getDateInDDMMYYYFormat(improvementPlan.selectedImprovementPlan.NextReviewDate, currentTimezone)) ||
                    '-'}
                </Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                  </Form.Label>
                </Col>
                <Col>{improvementPlan.selectedImprovementPlan.ReviewFrequencyName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                  </Form.Label>
                </Col>
                <Col>{actionName || '-'}</Col>
              </Row>

              {!improvementPlan.selectedImprovementPlan.IsActioned && (
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="common.status" defaultMessage="Status" />
                    </Form.Label>
                  </Col>
                  <Col>{improvementPlan.selectedImprovementPlan.StatusName || '-'}</Col>
                </Row>
              )}
            </Form>
          </div>

          <div className={s.improvementPlanContent}>
            <ImprovementPlanComments
              improvementPlan={improvementPlan}
              saveImprovementPlanComment={saveImprovementPlanComment}
              UpdateAllowed={addCommentsAllowed}
              currentTimezone={currentTimezone}
            />
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(ImprovementPlanView);
