import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';
import {
  ENTITY_SUBTYPE_ERROR,
  GET_ALL_ENTITY_SUBTYPE_DETAILS,
  ENTITY_SUBTYPE_IS_LOADING,
  SET_ENTITY_SUBTYPE_CURRENT_PAGE,
  SET_ENTITY_SUBTYPE_PAGE_FILTER,
  SET_ENTITY_SUBTYPE_CHANGE,
  SET_ENTITY_SUBTYPE,
  SET_ENTITY_SUBTYPE_CLEANUP,
  UPDATE_ENTITY_SUBTYPE_SAVE_STATUS,
  SET_ENTITY_SUBTYPE_RESULTS,
  REMOVE_ENTITY_SUBTYPE
} from '../../../constants/index';

import { ENTITY_SUBTYPE_MANAGEMENT, CREATE_ENTITY_SUBTYPE, UPDATE_ENTITY_SUBTYPE } from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setEntitySubTypeErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setEntitySubTypeError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEntitySubTypeError(messageCode, false));
  }, 2500);
};

export const setEntitySubTypeError = (messageCode, status) => {
  return {
    type: ENTITY_SUBTYPE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: ENTITY_SUBTYPE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getEntitySubTypeThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getEntitySubType(widgetCode, orgId));
};

export const getEntitySubType = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/entitysubtypes`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllEntitySubTypeListThunk(retData),
    onFailure: err => setEntitySubTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllEntitySubTypeListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllEntitySubTypeList(messageCode, data));
};

export const setAllEntitySubTypeList = (messageCode, data) => ({
  type: GET_ALL_ENTITY_SUBTYPE_DETAILS,
  payload: {
    data: {
      entitySubTypes: data,
      filteredResults: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_ENTITY_SUBTYPE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_ENTITY_SUBTYPE_PAGE_FILTER,
  payload: { filter }
});

export const describeEntitySubTypeThunk = (EntitySubTypeId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeEntitySubType(EntitySubTypeId, widgetCode, orgId));
};

export const describeEntitySubType = (EntitySubTypeId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/entitysubtypes`,
    data: {
      EntitySubTypeId: EntitySubTypeId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setEntitySubTypeThunk(retData, EntitySubTypeId),
    onFailure: err => setEntitySubTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setEntitySubTypeThunk = ({ retData, EntitySubTypeId }) => dispatch => {
  let data = retData.data;
  if (data.length === 0 || (EntitySubTypeId && EntitySubTypeId !== data[0].EntitySubTypeId)) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setEntitySubType(data));
};

export const setEntitySubType = data => ({
  type: SET_ENTITY_SUBTYPE,
  payload: data[0]
});

export const saveEntitySubTypeThunk = (data, widgetCode) => dispatch => {
  let url = '/management/entitysubtypes/save';
  let actionName = data.EntitySubTypeId === 0 ? CREATE_ENTITY_SUBTYPE : UPDATE_ENTITY_SUBTYPE;
  let log = logEntry(ENTITY_SUBTYPE_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveEntitySubType(data, url, log, widgetCode));
};

export const saveEntitySubType = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setEntitySubTypeStatusThunk(retData, data),
    onFailure: err => setEntitySubTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setEntitySubTypeStatusThunk = (message, data) => dispatch => {
  dispatch(setEntitySubTypeStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEntitySubTypeStatus(message, false, true));
    if (data.PerformDelete) {
      dispatch(removeEntitySubType(data.EntitySubTypeId));
    }
  }, 2500);
};

export const removeEntitySubType = data => ({
  type: REMOVE_ENTITY_SUBTYPE,
  payload: {
    entitySubTypeId: data
  }
});

export const setEntitySubTypeStatus = (messageCode, status, redirect) => ({
  type: UPDATE_ENTITY_SUBTYPE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: status,
      showBanner: status,
      isLoading: false,
      isRedirect: redirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setEntitySubTypeChangeThunk = retData => dispatch => {
  dispatch(setEntitySubTypeChange(retData));
};

export const setEntitySubTypeChange = retData => ({
  type: SET_ENTITY_SUBTYPE_CHANGE,
  payload: retData
});

export const setEntitySubTypeCleanUpThunk = SelectedEntitySubType => dispatch => {
  dispatch({ type: SET_ENTITY_SUBTYPE_CLEANUP, payload: SelectedEntitySubType });
};

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_ENTITY_SUBTYPE_RESULTS,
  payload: retData
});
