import { initialEntitySubTypeState } from '../initialState';

import {
  ENTITY_SUBTYPE_ERROR,
  GET_ALL_ENTITY_SUBTYPE_DETAILS,
  ENTITY_SUBTYPE_IS_LOADING,
  SET_ENTITY_SUBTYPE_CURRENT_PAGE,
  SET_ENTITY_SUBTYPE_PAGE_FILTER,
  SET_ENTITY_SUBTYPE_CHANGE,
  SET_ENTITY_SUBTYPE_CLEANUP,
  SET_ENTITY_SUBTYPE,
  UPDATE_ENTITY_SUBTYPE_SAVE_STATUS,
  SET_ENTITY_SUBTYPE_RESULTS,
  REMOVE_ENTITY_SUBTYPE
} from '../../../constants/index';

const entitySubTypeReducer = (state = initialEntitySubTypeState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_ENTITY_SUBTYPE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          ...data
        }
      };
    }

    case SET_ENTITY_SUBTYPE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          currentPage
        }
      };
    }

    case SET_ENTITY_SUBTYPE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          filter
        }
      };
    }

    case UPDATE_ENTITY_SUBTYPE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          ...data
        }
      };
    }

    case ENTITY_SUBTYPE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          isLoading: isLoading
        }
      };
    }

    case ENTITY_SUBTYPE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          ...data
        }
      };
    }

    case SET_ENTITY_SUBTYPE: {
      let data = payload;

      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          isOpSuccessful: false,
          showBanner: false,
          SelectedEntitySubType: {
            ...state.entitySubType.SelectedEntitySubType,
            ...data
          }
        }
      };
    }

    case REMOVE_ENTITY_SUBTYPE: {
      const { entitySubTypeId } = payload;
      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          isOpSuccessful: false,
          isRedirect: false,
          entitySubTypes: [...state.entitySubType.entitySubTypes.filter(item => item.EntitySubTypeId !== entitySubTypeId)],
          filteredResults: [...state.entitySubType.filteredResults.filter(item => item.EntitySubTypeId !== entitySubTypeId)]
        }
      };
    }

    case SET_ENTITY_SUBTYPE_CHANGE: {
      let { SelectedEntitySubType } = payload;

      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          isOpSuccessful: false,
          showBanner: false,
          SelectedEntitySubType: {
            ...state.entitySubType.SelectedEntitySubType,
            ...SelectedEntitySubType
          }
        }
      };
    }
    case SET_ENTITY_SUBTYPE_CLEANUP: {
      let SelectedEntitySubType = payload;
      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          SelectedEntitySubType: SelectedEntitySubType
        }
      };
    }

    case SET_ENTITY_SUBTYPE_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        entitySubType: {
          ...state.entitySubType,
          ...filteredResults
        }
      };
    }

    default:
      return state;
  }
};

export default entitySubTypeReducer;
