import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './EmergencyPlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialEmergencyResponsePlanState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { getDateInDDMMYYYFormat } from '../../../utils';
import uuid from 'uuid';
import { IsWidgetAccessible, WidgetVisibility } from '../../../utils/widgetManager';
import 'react-datetime/css/react-datetime.css';
import MoreOption from '../../WSAControls/MoreOption/MoreOption';
import ChecklistItemTable from './ChecklistItemTable';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../../WSAControls/Breadcrumb';
import { getUserProfileWidget, getCurrentTimezone, getEmergencyResponsePlan } from '../../../selectors/index';

import {
  describeEmergencyResponsePlanThunk,
  setEmergencyResponsePlanChangeThunk
} from '../../../store/actions/safetyPlan/emergencyResponsePlan';

const EmergencyPlanView = ({ intl }) => {
  let heading = '';
  const dispatch = useDispatch();
  const history = useHistory();
  let { emergencyResponsePlanId, siteId, segmentName, selectedTab } = useParams();
  siteId = (siteId && parseInt(siteId)) || '0';

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const emergencyPlan = useSelector(state => getEmergencyResponsePlan(state));
  let disableControls = emergencyPlan.isLoading || false;

  let widgetCode = 'WQSP_ERP_VIEW';
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ERP_EDIT');
  const isInitiateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ERP_INITIATE');
  let backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/emergency-response-plans`;

  useEffect(() => {
    dispatch(describeEmergencyResponsePlanThunk(emergencyResponsePlanId, widgetCode));
  }, [describeEmergencyResponsePlanThunk, emergencyResponsePlanId]);

  const onChangeClick = () => {
    if (isUpdateAllowed) {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/emergency-response-plans/emergency-response-plan/${emergencyResponsePlanId}`
      );
    }
  };

  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const actionName = emergencyPlan.selectedEmergencyResponsePlan.IsActioned ? yesText : noText;

  const getMoreOptions = () => {
    let options = [];

    if (isInitiateAllowed) {
      options.push({
        Name: <FormattedMessage id="emergencyResponse.initiate" defaultMessage="INITIATE" />,
        Url: `/tasks-and-checklists/checklist-detail/0?ERPId=${emergencyPlan.EmergencyResponsePlanId}&checklistTemplateId=${emergencyPlan.ChecklistTemplateId}`
      });
    }
    return options;
  };

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setEmergencyResponsePlanChangeThunk({
          ...initialEmergencyResponsePlanState.emergencyResponsePlan
        })
      );
    };
  }, []);

  let messageId = (emergencyPlan && emergencyPlan.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let selectedEmergencyPlan = emergencyPlan.selectedEmergencyResponsePlan;
  heading = intl.formatMessage({ id: 'common.viewTextCC', defaultMessage: 'View' }) + ' - ' + selectedEmergencyPlan.Name;

  const breadcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.listOfEmergencyResponses" defaultMessage="List of Emergency Responses" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/emergency-response-plans`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 2, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <div className={s.emergencyplan}>
      {emergencyPlan.isLoading && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={emergencyPlan.showBanner}
        status={emergencyPlan.isOpSuccessful}
        successText={messageText}
      />
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        <div className={s.contentWrapper}>
          <div>
            <Row>
              <Col>
                <Row className={s.colActionButton}>
                  <Button
                    className={classNames(s.margin5, s.changeButton)}
                    style={WidgetVisibility(userProfileWidgets, 'WQSP_ERP_EDIT')}
                    onClick={() => {
                      onChangeClick();
                    }}
                    disabled={disableControls}
                    data-unittest="changeHazardEvent"
                  >
                    <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                  </Button>
                  <MoreOption
                    key={uuidv4()}
                    dataArray={[
                      {
                        Name: <FormattedMessage id="emergencyResponse.initiate" defaultMessage="INITIATE" />,
                        Url: `/tasks-and-checklists/checklist-detail/0?ERPId=${selectedEmergencyPlan.EmergencyResponsePlanId}&checklistTemplateId=${selectedEmergencyPlan.ChecklistTemplateId}`
                      }
                    ]}
                  />
                </Row>
              </Col>
            </Row>
          </div>
          <div className={s.emergencyplanContent}>
            <Form>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="emergencyPlan.name" defaultMessage="Name" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.Name || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.description" defaultMessage="Description" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.Description || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.SiteName || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.WaterSupplyDescriptionName || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.EntityTypeName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.EntityName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="emergencyPlan.hazardEvent" defaultMessage="Hazard Event" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.HazardEventName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="emergencyPlan.checklistTemplate" defaultMessage="Checklist Template" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.ChecklistTemplateName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="emergencyPlan.stakeholder" defaultMessage="Stakeholder" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.PersonnelName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                  </Form.Label>
                </Col>
                <Col>{getDateInDDMMYYYFormat(emergencyPlan.selectedEmergencyResponsePlan.NextReviewDate, currentTimezone)}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                  </Form.Label>
                </Col>
                <Col>{getDateInDDMMYYYFormat(emergencyPlan.selectedEmergencyResponsePlan.LastReviewDate, currentTimezone)}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                  </Form.Label>
                </Col>
                <Col>{emergencyPlan.selectedEmergencyResponsePlan.ReviewFrequencyName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                  </Form.Label>
                </Col>
                <Col>{actionName || '-'}</Col>
              </Row>
              {!emergencyPlan.selectedEmergencyResponsePlan.IsActioned && (
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="common.status" defaultMessage="Status" />
                    </Form.Label>
                  </Col>
                  <Col>{emergencyPlan.selectedEmergencyResponsePlan.StatusName || '-'}</Col>
                </Row>
              )}
            </Form>
          </div>
          <div>
            <ChecklistItemTable
              checklistItems={emergencyPlan.selectedEmergencyResponsePlan.checklists}
              view={true}
              showAddEdit={false}
              userProfileWidgets={userProfileWidgets}
              updateAllowed={false}
            />
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(EmergencyPlanView);
