import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import s from './QualityGoalsReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ReportItem from '../TestResultReport/ReportItem';
import { WidgetVisibility } from '../../utils/widgetManager';
import Form from 'react-bootstrap/Form';

const QualityGoalsReportItemTable = ({
  resultItems,
  testCategories,
  setShowAddEdit,
  showAddEdit,
  disableMode,
  setDisableMode,
  onAddReportItem,
  onChangeReportItem,
  userProfileWidgets,
  updateAllowed,
  formError,
  intl
}) => {
  const addNewReportItemHandler = () => {
    setShowAddEdit(true);
    setDisableMode(true);
  };

  const deleteDataHandler = (QualityGoalReportItemId, identifer) => {
    if (QualityGoalReportItemId > -1) {
      let resultItemList = [...resultItems] || [];
      resultItemList = resultItemList.map(item => {
        if (item.testCategoryTypes && item.testCategoryTypes.length > 0) {
          item.testCategoryTypes = item.testCategoryTypes.filter(type => {
            if (type.GuidIdentifier === identifer) {
              type.IsDeleted = true;
            }
            return type;
          });
        }
        if (item.testCategoryTypes.length == 0 || item.testCategoryTypes.filter(type => !type.IsDeleted).length === 0) {
          item.IsDeleted = true;
        }
        return item;
      });

      resultItemList = resultItemList.map(item => {
        item.testCategoryTypes = item.testCategoryTypes.filter(type => !type.IsDeleted || type.QualityGoalReportItemId);
        return item;
      });
      onChangeReportItem(resultItemList);
    }
  };

  const onCancelReportItem = () => {
    setShowAddEdit(false);
    setDisableMode(false);
  };

  const onValueChange = (e, testCategoryType) => {
    const resultItemList = [...resultItems] || [];
    resultItemList.map(item => {
      if (item.testCategoryTypes && item.testCategoryTypes.length > 0) {
        item.testCategoryTypes = item.testCategoryTypes.map(type => {
          if (type.GuidIdentifier === testCategoryType.GuidIdentifier) {
            return {
              ...type,
              [e.target.name]: e.target.value
            };
          }
          return type;
        });
      }
      return item;
    });
    onChangeReportItem(resultItemList);
  };

  const CategoryContent = (
    <ReportItem
      onAddReportItem={onAddReportItem}
      testCategories={testCategories}
      resultItems={resultItems}
      onCancelReportItem={onCancelReportItem}
      qualityReport={true}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{CategoryContent}</td>
    </tr>
  );

  let newRowClass = showAddEdit ? s.newRow : '';

  return (
    <div>
      <div className={s.testresultContent}>
        <Row>
          <Col>
            <h4 className={s.subheading}>
              <FormattedMessage id="qualityGoals.goals" defaultMessage="Goals" />
            </h4>
          </Col>
          <Col>
            <Button
              className={s.addbutton}
              style={WidgetVisibility(userProfileWidgets, 'WQSP_QUALITYGOALSREPORT_ITEM_ADDNEW')}
              onClick={addNewReportItemHandler}
              disabled={disableMode}
            >
              + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        {resultItems.map(item => {
          return (
            !item.IsDeleted && (
              <div key={item.TestCategoryId} className={s.grpItem}>
                <Row className={s.grpHeading}>
                  <Col>
                    <h4>{item.CategoryName}</h4>
                  </Col>
                </Row>

                {item.testCategoryTypes && (
                  <Table key={item.GuidIdentifier} variant className={s.innerTable}>
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="wqsp.common.testType" defaultMessage="Test Type" />
                        </th>
                        <th>
                          <FormattedMessage id="qualityGoals.uOM" defaultMessage="Unit of Measure" />
                        </th>
                        <th>
                          <FormattedMessage id="qualityGoals.realisticMean" defaultMessage="Realistic Mean" />
                        </th>
                        <th>
                          <FormattedMessage id="qualityGoals.realisticMax" defaultMessage="Realistic Max" />
                        </th>
                        <th>
                          <FormattedMessage id="qualityGoals.realisticLRV" defaultMessage="Realistic LRV" />
                        </th>
                        <th>
                          <FormattedMessage id="qualityGoals.aspirationalMean" defaultMessage="Aspirational Mean" />
                        </th>
                        <th>
                          <FormattedMessage id="qualityGoals.aspirationalMax" defaultMessage="Aspirational Max" />
                        </th>
                        <th>
                          <FormattedMessage id="qualityGoals.aspirationalLRV" defaultMessage="Aspirational LRV" />
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.testCategoryTypes.map(n => {
                        return (
                          !n.IsDeleted && (
                            <Fragment key={n.GuidIdentifier}>
                              <tr key={n.TestResultItemId} data-unittest="CategoryItemList">
                                <td>{n.Name}</td>
                                <td>{n.UnitOfMeasure}</td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name="RealisticMeanValue"
                                    onChange={e => onValueChange(e, n)}
                                    value={n.RealisticMeanValue || ''}
                                    className={`${s.formControlSmall}`}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name="RealisticMaxValue"
                                    onChange={e => onValueChange(e, n)}
                                    value={n.RealisticMaxValue || ''}
                                    className={`${s.formControlSmall}`}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name="RealisticLRV"
                                    onChange={e => onValueChange(e, n)}
                                    value={n.RealisticLRV || ''}
                                    className={`${s.formControlSmall}`}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name="AspirationalMeanValue"
                                    onChange={e => onValueChange(e, n)}
                                    value={n.AspirationalMeanValue || ''}
                                    className={`${s.formControlSmall}`}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name="AspirationalMaxValue"
                                    onChange={e => onValueChange(e, n)}
                                    value={n.AspirationalMaxValue || ''}
                                    className={`${s.formControlSmall}`}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    name="AspirationalLRV"
                                    onChange={e => onValueChange(e, n)}
                                    value={n.AspirationalLRV || ''}
                                    className={`${s.formControlSmall}`}
                                  />
                                </td>
                                <td>
                                  {updateAllowed && (
                                    <div className={s.buttonContainer}>
                                      <Button
                                        variant="outline-secondary"
                                        className={s.removeButton}
                                        href="#"
                                        onClick={deleteDataHandler.bind(this, n.QualityGoalReportItemId, n.GuidIdentifier)}
                                        disabled={disableMode}
                                        data-unittest="deleteData"
                                        style={WidgetVisibility(userProfileWidgets, `WQSP_QUALITYGOALSREPORT_ITEM_DELETE`)}
                                      >
                                        <FormattedMessage id="common.remove" defaultMessage="Remove" />
                                      </Button>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            </Fragment>
                          )
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </div>
            )
          );
        })}
        {formError && (
          <div className={s.grpHeading}>
            <p role="alert" className={s.error}>
              {formError}
            </p>
          </div>
        )}
        <div className={newRowClass}>{showAddEdit && CategoryContent}</div>
      </div>
    </div>
  );
};

QualityGoalsReportItemTable.defaultProps = {
  resultItems: {},
  testCategories: [],
  userProfileWidgets: [],
  showAddEdit: false,
  disableMode: false,
  updateAllowed: false
};

QualityGoalsReportItemTable.propTypes = {
  setShowAddEdit: PropTypes.func.isRequired,
  setDisableMode: PropTypes.func.isRequired,
  showAddEdit: PropTypes.bool,
  onAddReportItem: PropTypes.func.isRequired,
  onChangeReportItem: PropTypes.func.isRequired,
  updateAllowed: PropTypes.bool,
  userProfileWidgets: PropTypes.object
};

export default injectIntl(QualityGoalsReportItemTable);
