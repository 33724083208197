import React from 'react';
import s from './Site.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getDateInDDMMYYYFormat } from '../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../selectors/index';
import MoreOption from '../WSAControls/MoreOption/MoreOption';

const SiteListTable = ({ paginatedData, onSortOrder, asendingOrder, ChangeSiteReport, UpdateAllowed, ConfigurationAllowed }) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
            </th>
            <th>
              <FormattedMessage id="site.households" defaultMessage="Households" />
            </th>
            <th>
              <FormattedMessage id="site.inhabitants" defaultMessage="Inhabitants" />
            </th>

            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="site.region" defaultMessage="Region" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>

            <th>
              <FormattedMessage id="common.description" defaultMessage="Description" />
            </th>
            {UpdateAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.SiteId}>
                <td>{n.Name || ''}</td>
                <td>{n.Households || ''}</td>
                <td>{n.Inhabitants || ''}</td>
                <td>{n.Region || ''}</td>
                <td>{n.Description || ''}</td>

                {(UpdateAllowed || ConfigurationAllowed) && (
                  <td>
                    <MoreOption
                      dataArray={[
                        // ConfigurationAllowed
                        //   ? {
                        //       Url: `/sites/${n.SiteId}/site-configuration`,
                        //       Name: <FormattedMessage id="common.configuration" defaultMessage="Configuration" />
                        //     }
                        //   : {},
                        UpdateAllowed
                          ? {
                              Url: `/sites/site/${n.SiteId}/build/2`,
                              Name: <FormattedMessage id="common.edit" defaultMessage="Edit" />
                            }
                          : {}

                        // { Url: `#`, Name: <FormattedMessage id="common.delete" defaultMessage="Delete" /> }
                      ]}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

SiteListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(SiteListTable);
