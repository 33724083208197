import React, { useEffect, useState } from 'react';
import s from './TestPurpose.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getTestPurpose } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialTestPurposeState } from '../../store/reducers/initialState';
import { getTestPurposeThunk, saveTestPurposeThunk } from '../../store/actions/testPurposes';
import TestPurposeListTable from './TestPurposeListTable';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const TestPurposeList = ({ userProfileWidgets, intl }) => {
  const history = useHistory();

  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;

  const dispatch = useDispatch();
  const testPurpose = useSelector(state => getTestPurpose(state));

  let initialRecords = testPurpose.TestPurposes.slice(0, initialLimit);
  const [testPurposeOpen, setTestPurposeOpen] = useState(false);

  useEffect(() => {
    dispatch(getTestPurposeThunk('WQSP_ADMINTESTPURPOSE_LIST'));
  }, [getTestPurposeThunk, dispatch, testPurpose.isOpSuccessful]);

  const [localTestPurposes, setLocalTestPurposes] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [testPurposeId, setTestPurposeId] = useState(0);

  const [searchText, setSearchText] = useState('');

  const localTestPurposeLength = localTestPurposes.length;
  const globalSettingLength = testPurpose.TestPurposes.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalTestPurposes(testPurpose.TestPurposes);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = testPurpose.TestPurposes.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalTestPurposes(filteredList);
  };

  const filterData = () => {
    let filteredList = testPurpose.TestPurposes;
    if (isFilter) {
      filteredList = testPurpose.TestPurposes.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getTestPurposeData = () => {
    let testPurpose = [];
    if (showAll) {
      testPurpose = filterData();
    } else {
      if (isFilter) {
        testPurpose = localTestPurposeLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        testPurpose = initialRecords;
      }
    }
    return testPurpose.sort(compareValues('Name'));
  };

  const onRemoveTestPurposeClick = testPurposeId => {
    setActive(true);
    setTestPurposeId(testPurposeId);
  };

  const DeleteTestPurpose = () => {
    let saveData = testPurpose.selectedTestPurpose;
    saveData.PerformDelete = true;
    saveData.TestPurposeId = testPurposeId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveTestPurposeThunk(saveData, 'WQSP_TESTPURPOSES_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setTestPurposeId(0);
  };

  const handleConfirm = () => {
    DeleteTestPurpose();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push('/operational-setup/test-results/test-purpose');
  };

  const onChangeTestPurposeClick = testPurposeId => {
    history.push(`/operational-setup/test-results/test-purpose/${testPurposeId}`);
  };

  let messageId = (testPurpose && testPurpose.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TESTPURPOSES_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TESTPURPOSES_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'testPurposes.confirmDelete',
    defaultMessage: 'Are you sure you want to delete test purpose?'
  });

  return (
    <div className={s.testPurposeList}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'WQSP_ADMINTESTPURPOSE_LIST')}>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={testPurposeOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setTestPurposeOpen(!testPurposeOpen)}
                aria-controls="model-component-type-section"
                aria-expanded={testPurposeOpen}
              ></img>
              <h4>
                <FormattedMessage id="testPurposes.testPurpose" defaultMessage="Test Purpose" />
              </h4>
              <span className={s.circle}>{testPurpose.TestPurposes.length}</span>
            </Row>
          </Col>
        </Row>
        <Collapse in={testPurposeOpen}>
          <div className={s.innerComponent} id="model-component-type-section">
            <div className={s.component}>
              {testPurpose.isLoading && <LoadingSpinner />}
              {testPurpose.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={testPurpose.showBanner}
                  status={testPurpose.isOpSuccessful}
                  successText={messageText}
                />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <div className={s.contentWrapper}>
                <Row className={s.topRow}>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={testPurpose.showAddEditScreen}
                      // initialText={filter}
                      placeHolderTextId="common.search"
                      data-unittest="searchTestPurposes"
                    />
                  </Col>
                  <Col sm={6} lg={8}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'WQSP_TESTPURPOSES_ADDNEW')}
                      onClick={onAddNewClick}
                      disabled={testPurpose.showAddEditScreen}
                      data-unittest="addTestPurposeButton"
                    >
                      + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>

                <div>
                  <TestPurposeListTable
                    removeTestPurpose={onRemoveTestPurposeClick}
                    testPurposeData={getTestPurposeData()}
                    changeTestPurpose={onChangeTestPurposeClick}
                    updateAllowed={isUpdateAllowed}
                    deleteAllowed={isDeleteAllowed}
                  />
                </div>
                {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localTestPurposeLength > initialLimit)) && (
                  <Row className={s.topRow}>
                    <Col>
                      <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                        {!showAll
                          ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localTestPurposeLength})`
                          : showLessText}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

TestPurposeList.defaultProps = {
  ...initialTestPurposeState
};

export default injectIntl(TestPurposeList);
