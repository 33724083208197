import { RISK_INFOS, RISK_RATINGS } from '../constants/index';
import translation from '../translations/organisationTranslations';

export const getRiskName = (id, intl) => {
  const risk = RISK_INFOS.find(x => x.id === id);
  let riskName = '';
  if (risk && Object.keys(risk).length > 0) {
    riskName = intl.formatMessage({
      id: risk.name,
      defaultMessage: risk.defaultText
    });
  }
  return riskName;
};

export const riskCalulator = percentageValue => {
  return RISK_INFOS.find(x => percentageValue >= x.low && percentageValue <= x.high);
};

export const getRiskRating = (name, intl) => {
  const risk = RISK_RATINGS.find(x => x.name === name);
  let riskRating = '';
  if (risk && Object.keys(risk).length > 0) {
    riskRating = intl.formatMessage({
      id: risk.name,
      defaultMessage: risk.defaultText
    });
  }
  return riskRating;
};

export const processOrganisationTranslation = (orgTranslations, locale) => {
  let transData = translation[locale];
  if (!orgTranslations || !orgTranslations.length === 0) {
    Object.keys(transData).forEach(key => {
      transData[key] = transData[key].replace('{', '').replace('}', '');
    });
    return transData;
  }

  Object.keys(transData).forEach(key => {
    orgTranslations.forEach(item => {
      let transKey = `{${item.TranslationKey}}`;
      transData[key] = transData[key].replace(transKey, item.TranslationValue);
      transData[key] = transData[key].replace(transKey.toLowerCase(), item.TranslationValue.toLowerCase());
      transData[key] = transData[key].replace(transKey.toUpperCase(), item.TranslationValue.toUpperCase());
    });
    transData[key] = transData[key].replace('{', '').replace('}', '');
  });

  return transData;
};
