import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './ControlMonitoring.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialControlMeasureState } from '../../store/reducers/initialState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getDateInDDMMYYYFormat } from '../../utils';
import uuid from 'uuid';
import CorrectiveActionListTable from './CorrectiveActionListTable';
import 'react-datetime/css/react-datetime.css';
import { getUserProfileWidget, getControlMeasure, getCurrentTimezone, getTeam } from '../../selectors/index';
import { describeControlMonitoringThunk, setControlMonitoringChangeThunk } from '../../store/actions/hazardEvent/controlMeasures';
import { getTeamThunk } from '../../store/actions/safetyPlan/team';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import classNames from 'classnames';
import Breadcrumb from '../WSAControls/Breadcrumb';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';

const ControlMonitoringView = ({ intl }) => {
  let IsEdit = false;
  const dispatch = useDispatch();
  let { controlMonitoringId, controlMeasureId, hazardEventId, entityId, siteId, segmentName, selectedTab } = useParams();

  segmentName = segmentName || 'control';
  selectedTab = selectedTab || '2';

  let backUrl = `/hazard-events/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`;
  if (segmentName === 'control') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure-view/${controlMeasureId}`;
  } else if (segmentName === 'monitor') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings`;
  }

  const history = useHistory();

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const controlMeasure = useSelector(state => getControlMeasure(state));
  const team = useSelector(state => getTeam(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let disableControls = controlMeasure.isLoading || false;

  if (controlMonitoringId && controlMonitoringId > 0) {
    IsEdit = true;
  } else {
    controlMonitoringId = 0;
  }
  let widgetCode = 'WQSP_CONTROL_MONITORINGS_VIEW';

  const isItemListingAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CORRECTIVE_ACTIONS_LIST');
  const isCorrectiveActionViewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CORRECTIVE_ACTIONS_VIEW');

  useEffect(() => {
    dispatch(describeControlMonitoringThunk(controlMonitoringId, controlMeasureId, widgetCode));
  }, [describeControlMonitoringThunk, controlMonitoringId, controlMeasureId]);

  useEffect(() => {
    let filterObj = {
      filterName: '',
      filterCompany: '',
      offset: 0,
      limit: 1000
    };
    dispatch(getTeamThunk('WQSP_CORRECTIVE_ACTIONS_ADDNEW', filterObj, null));
  }, [getTeamThunk, null]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setControlMonitoringChangeThunk({
          ...initialControlMeasureState.controlMeasure.selectedControlMeasure
        })
      );
    };
  }, []);

  const onChangeClick = id => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring/${controlMonitoringId}`
      );
    } else if (segmentName === 'identify') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring/${controlMonitoringId}`
      );
    } else if (segmentName === 'monitor') {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings/control-monitoring/${controlMonitoringId}`);
    } else {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure/${controlMeasureId}/control-monitoring/${controlMonitoringId}`
      );
    }
  };

  const onViewCorrectiveActionClick = correctiveActionId => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}/correction-action-view/${correctiveActionId}`
      );
    } else if (segmentName === 'identify') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}/correction-action-view/${correctiveActionId}`
      );
    } else if (segmentName === 'monitor') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings/control-monitoring/${controlMonitoringId}/correction-action-view/${correctiveActionId}`
      );
    } else {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}/correction-action-view/${correctiveActionId}`
      );
    }
  };

  let messageId = (controlMeasure && controlMeasure.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });
  let controlMeasureName = controlMeasure.selectedControlMeasure.selectedControlMonitoring.ControlMeasureName || '';
  let headingControlMeasure =
    intl.formatMessage({ id: 'controlMeasure.controlMeasure', defaultMessage: 'Control Measure' }) + ' (' + controlMeasureName + ')';
  let heading =
    segmentName === 'monitor'
      ? intl.formatMessage({ id: 'common.viewTextCC', defaultMessage: 'View' })
      : intl.formatMessage({ id: 'controlMonitoring.viewControlMonitoring', defaultMessage: 'View Control Monitoring' });

  heading += ' - ' + controlMeasure.selectedControlMeasure.selectedControlMonitoring.MonitoredItem;

  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const actionName = controlMeasure.selectedControlMeasure.selectedControlMonitoring.IsActioned ? yesText : noText;
  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfControlMeasures', defaultMessage: 'List of Control Measures' });
  let monitorBreadcrumbHeading = intl.formatMessage({
    id: 'siteControlsTabs.listOfControlMonitors',
    defaultMessage: 'List of Control Monitors'
  });
  let controlMonitoringHeading = intl.formatMessage({ id: 'controlMeasure.controlMonitoring', defaultMessage: 'Control Monitoring' });
  controlMonitoringHeading += ' (' + controlMeasure.selectedControlMeasure.selectedControlMonitoring.MonitoredItem + ')';

  const hazardEventName = controlMeasure.selectedControlMeasure.selectedControlMonitoring.HazardEventName;
  let hazardEventHeading =
    intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + ' (' + hazardEventName + ')';

  const breadcrumbControl = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: headingControlMeasure,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: IsEdit
    },

    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 5,
      name: headingControlMeasure,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 7, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const identifyHazardEventcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: headingControlMeasure,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 5, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const monitorBreadcrumbControl = [
    {
      orderNo: 1,
      name: monitorBreadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const getBreadcrumbData = () => {
    if (segmentName === 'define') {
      return defineEntityCrumb;
    } else if (segmentName === 'identify') {
      return identifyHazardEventcrumb;
    } else if (segmentName === 'monitor') {
      return monitorBreadcrumbControl;
    } else {
      return breadcrumbControl;
    }
  };

  return (
    <div className={s.controlMonitoring}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {controlMeasure.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={controlMeasure.showBanner}
          status={controlMeasure.isOpSuccessful}
          successText={messageText}
        />
        <div className={s.contentWrapper}>
          <Breadcrumb data={getBreadcrumbData()} segmentName={segmentName} />
          <div>
            <Row>
              <Col>
                <Row className={s.colActionButton}>
                  <Button
                    className={classNames(s.margin5, s.changeButton)}
                    style={WidgetVisibility(userProfileWidgets, 'WQSP_CONTROL_MONITORINGS_EDIT')}
                    onClick={() => {
                      onChangeClick(controlMonitoringId);
                    }}
                    disabled={disableControls}
                    data-unittest="changeControlMonitoring"
                  >
                    <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={s.controlMonitoringContent}>
            <Form>
              {segmentName === 'monitor' ? (
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="controlMonitoring.controlMeasure" defaultMessage="Control Measure" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.ControlMeasureName}</Col>
                </Row>
              ) : (
                ''
              )}
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="controlMonitoring.unitComponentPosition" defaultMessage="Unit Component Position" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.ModelComponentPositionName}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="controlMonitoring.stakeholder" defaultMessage="Stakeholder" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.PersonnelName}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="'controlMonitoring.monitoredItem" defaultMessage="Monitored Item" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.MonitoredItem}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.description" defaultMessage="Description" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.Description || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="controlMonitoring.whenMonitoredDetails" defaultMessage="When Monitored Details" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.WhenMonitoredDetails || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="controlMonitoring.frequency" defaultMessage="Frequency" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.Frequency || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="controlMonitoring.whereMeasureTaken" defaultMessage="Where Measure Taken" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.WhereMeasureTaken || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="controlMonitoring.records" defaultMessage="Records" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.Records || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                  </Form.Label>
                </Col>
                <Col>
                  {getDateInDDMMYYYFormat(
                    controlMeasure.selectedControlMeasure.selectedControlMonitoring.LastReviewDate,
                    currentTimezone
                  ) || '-'}
                </Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                  </Form.Label>
                </Col>
                <Col>
                  {(controlMeasure.selectedControlMeasure.selectedControlMonitoring.NextReviewDate &&
                    getDateInDDMMYYYFormat(
                      controlMeasure.selectedControlMeasure.selectedControlMonitoring.NextReviewDate,
                      currentTimezone
                    )) ||
                    '-'}
                </Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.ReviewFrequencyName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                  </Form.Label>
                </Col>
                <Col>{actionName || '-'}</Col>
              </Row>

              {!controlMeasure.selectedControlMeasure.selectedControlMonitoring.IsActioned && (
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="common.status" defaultMessage="Status" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.StatusName || '-'}</Col>
                </Row>
              )}
            </Form>
          </div>
          <div>
            {isItemListingAllowed && (
              <CorrectiveActionListTable
                correctiveActions={controlMeasure.selectedControlMeasure.selectedControlMonitoring.correctiveActions}
                team={team}
                view={true}
                showAddEdit={false}
                userProfileWidgets={userProfileWidgets}
                updateAllowed={false}
                viewAllowed={isCorrectiveActionViewAllowed}
                onViewClick={onViewCorrectiveActionClick}
              />
            )}
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(ControlMonitoringView);
