import React from 'react';
import moment from 'moment-timezone';

export const SectionControl = ({ sectionData, getImages, getActionName, currentTimezone }) => {
  return (
    <div id="section4" className="pageBreak sectionContent">
      <h1>Section 4 - Control</h1>
      <div id="controlMeasures" className="level1">
        <h2>Control Measures</h2>
      </div>
      {sectionData.ControlMeasures &&
        sectionData.ControlMeasures.map(n => {
          if (n.ControlMeasureId) {
            return (
              <ControlMeasureHtml
                key={n.ControlMeasureId}
                item={n}
                id={n.ControlMeasureId}
                getImages={getImages}
                getActionName={getActionName}
                currentTimezone={currentTimezone}
              />
            );
          }
        })}
      <div id="improvementPlans" className="level1">
        <h2>Improvement Plans</h2>
      </div>
      {sectionData.ImprovementPlans &&
        sectionData.ImprovementPlans.map(n => {
          return (
            <ImprovementPlansHtml
              key={n.ImprovementPlanId}
              item={n}
              id={n.ImprovementPlanId}
              getActionName={getActionName}
              currentTimezone={currentTimezone}
            />
          );
        })}
    </div>
  );
};

const ControlMeasureHtml = ({ item, getImages, getActionName, currentTimezone }) => {
  return (
    <div>
      <h4>{`Control Measure for ${item.ControlMeasureName}`}</h4>
      <table className="level1-table">
        <tr>
          <td>Hazard Event </td>
          <td>{item.HazardEventName}</td>
        </tr>
        <tr>
          <td>Control Measure Name</td>
          <td>{item.ControlMeasureName}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{item.Description}</td>
        </tr>
        <tr>
          <td>Date Implemented</td>
          <td>
            {item.DateImplemented
              ? moment
                  .unix(parseInt(item.DateImplemented))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Target Criteria Type</td>
          <td>{item.TargetCriteriaTypeName}</td>
        </tr>
        <tr>
          <td>Target Criteria</td>
          <td>{item.TargetCriteria}</td>
        </tr>
        <tr>
          <td>Alert Criteria Type</td>
          <td>{item.AlertCriteriaName}</td>
        </tr>
        <tr>
          <td>Alert Criteria</td>
          <td>{item.AlertCriteriaTrigger}</td>
        </tr>
        <tr>
          <td>Critical Limit Type</td>
          <td>{item.CriticalLimitTypeName}</td>
        </tr>
        <tr>
          <td>Critical Limit Trigger</td>
          <td>{item.CriticalLimitTrigger}</td>
        </tr>
        <tr>
          <td>Unit of Measure </td>
          <td>{item.UnitOfMeasure}</td>
        </tr>
        <tr>
          <td>Last Review Date </td>
          <td>
            {item.LastReviewDate
              ? moment
                  .unix(parseInt(item.LastReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Next Review Date </td>
          <td>
            {item.NextReviewDate
              ? moment
                  .unix(parseInt(item.NextReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Review Frequency</td>
          <td>{item.ReviewFrequencyName}</td>
        </tr>
        <tr>
          <td>Actioned</td>
          <td>{getActionName(item.IsActioned)}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{item.StatusName}</td>
        </tr>
        <tr>
          <td>Images</td>
          <td>{getImages(item.Images)}</td>
        </tr>
      </table>
      <ControlMonitorsHtml controlMonitorsItems={item.ControlMonitors} currentTimezone={currentTimezone} />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

const ControlMonitorsHtml = ({ controlMonitorsItems, currentTimezone }) => {
  if (!controlMonitorsItems || controlMonitorsItems.length == 0) {
    return '';
  }

  return (
    <div className="level2">
      <h3>Control Monitors</h3>
      <table className="independent-table">
        <tr>
          <th>Unit Component Position</th>
          <th>Stakeholder</th>
          <th>Monitored Item</th>
          <th>Last Review Date</th>
        </tr>

        {controlMonitorsItems.map(n => {
          return (
            <tr key={n.ControlMonitoringId}>
              <td>{n.UnitComponentPosition}</td>
              <td>{n.StakeholderRegisterName}</td>
              <td>{n.MonitoredItem}</td>
              <td>
                {n.LastReviewDate
                  ? moment
                      .unix(parseInt(n.LastReviewDate))
                      .tz(currentTimezone)
                      .format('DD-MMM-YYYY')
                  : 'N/A'}
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const ImprovementPlansHtml = ({ item, getActionName, currentTimezone }) => {
  return (
    <div>
      <h4>{`Improvement Plans for ${item.ImprovementPlanName}`}</h4>
      <table className="level1-table">
        <tr>
          <td>Hazard Event </td>
          <td>{item.HazardEventName}</td>
        </tr>
        <tr>
          <td>Improvement Plan Name </td>
          <td>{item.ImprovementPlanName}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{item.Description}</td>
        </tr>
        <tr>
          <td>Date Raised </td>
          <td>
            {item.DateRaised
              ? moment
                  .unix(parseInt(item.DateRaised))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Date Due </td>
          <td>
            {item.DateDue
              ? moment
                  .unix(parseInt(item.DateDue))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Last Review Date </td>
          <td>
            {item.LastReviewDate
              ? moment
                  .unix(parseInt(item.LastReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Next Review Date </td>
          <td>
            {item.NextReviewDate
              ? moment
                  .unix(parseInt(item.NextReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Review Frequency</td>
          <td>{item.ReviewFrequencyName}</td>
        </tr>
        <tr>
          <td>Actioned</td>
          <td>{getActionName(item.IsActioned)}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{item.StatusName}</td>
        </tr>
      </table>
    </div>
  );
};
