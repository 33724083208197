import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from './TestCategory.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import uuidv4 from 'uuid/v4';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';

const CategoryItem = ({ isEdit, selectedTestCategoryType, onChangeCategoryType, onSaveCategoryType, cancelCategoryItemHandler, intl }) => {
  const [localformErrors, setFormErrors] = useState({});
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    cancelCategoryItemHandler();
    onSaveCategoryType();
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!selectedTestCategoryType || !selectedTestCategoryType.Name || isRequired(selectedTestCategoryType.Name, 1)) {
      formErrors.Name = intl.formatMessage({
        id: 'categoryType.nameMandatory',
        defaultMessage: 'Category type name is required'
      });
      isValid = false;
    }

    if (!selectedTestCategoryType || !selectedTestCategoryType.UnitOfMeasure || isRequired(selectedTestCategoryType.UnitOfMeasure, 1)) {
      formErrors.UnitOfMeasure = intl.formatMessage({
        id: 'categoryType.uOMMandatory',
        defaultMessage: 'Unit of measure is required'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    onChangeCategoryType(e);
  };

  let saveText = <FormattedMessage id="common.save" defaultMessage="SAVE" />;

  return (
    <div className={s.testcategory}>
      <div className={s.testcategoryContent}>
        <div className={s.topRow}>
          <Row>
            <Col lg={3}>
              <Form.Group controlId="formName">
                <Form.Label>
                  <FormattedMessage id="wqsp.common.TestType" defaultMessage="Test type" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="Name"
                  onChange={onChange}
                  value={selectedTestCategoryType.Name}
                  className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                />
                {localformErrors && localformErrors.Name && (
                  <p role="alert" className={s.error}>
                    {localformErrors.Name}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formUOM">
                <Form.Label>
                  <FormattedMessage id="categoryType.uOM" defaultMessage="Unit of Measure" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="UnitOfMeasure"
                  onChange={onChange}
                  value={selectedTestCategoryType.UnitOfMeasure}
                  className={`${s.formControl} ${localformErrors && localformErrors.UnitOfMeasure ? s.formControlError : ''}`}
                />
                {localformErrors && localformErrors.UnitOfMeasure && (
                  <p role="alert" className={s.error}>
                    {localformErrors.UnitOfMeasure}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group controlId="formDescription">
                <Form.Label>
                  <FormattedMessage id="common.description" defaultMessage="Description" />
                </Form.Label>

                <Form.Control
                  as="textarea"
                  rows="3"
                  name="Description"
                  onChange={onChange}
                  placeholder={intl.formatMessage({
                    id: 'categoryType.enterDescription',
                    defaultMessage: 'Enter description'
                  })}
                  value={selectedTestCategoryType.Description}
                  className={`${s.textArea}`}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group controlId="formRealisticMean">
                <Form.Label>
                  <FormattedMessage id="categoryType.realisticMean" defaultMessage="Default Realistic Mean" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="RealisticMeanValue"
                  onChange={onChange}
                  value={selectedTestCategoryType.RealisticMeanValue}
                  className={`${s.formControl}`}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formRealisticMax">
                <Form.Label>
                  <FormattedMessage id="categoryType.realisticMax" defaultMessage="Default Realistic Max" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="RealisticMaxValue"
                  onChange={onChange}
                  value={selectedTestCategoryType.RealisticMaxValue}
                  className={`${s.formControl}`}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formRealisticLRV">
                <Form.Label>
                  <FormattedMessage id="categoryType.realisticLRV" defaultMessage="Default Realistic LRV" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="RealisticLRV"
                  onChange={onChange}
                  value={selectedTestCategoryType.RealisticLRV}
                  className={`${s.formControl}`}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group controlId="formAspirationalMean">
                <Form.Label>
                  <FormattedMessage id="categoryType.aspirationalMean" defaultMessage="Default Aspirational Mean" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="AspirationalMeanValue"
                  onChange={onChange}
                  value={selectedTestCategoryType.AspirationalMeanValue}
                  className={`${s.formControl}`}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formAspirationalMax">
                <Form.Label>
                  <FormattedMessage id="categoryType.aspirationalMax" defaultMessage="Default Aspirational Max" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="AspirationalMaxValue"
                  onChange={onChange}
                  value={selectedTestCategoryType.AspirationalMaxValue}
                  className={`${s.formControl}`}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formAspirationalLRV">
                <Form.Label>
                  <FormattedMessage id="categoryType.aspirationalLRV" defaultMessage="Default Aspirational LRV" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="AspirationalLRV"
                  onChange={onChange}
                  value={selectedTestCategoryType.AspirationalLRV}
                  className={`${s.formControl}`}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChangesSmall)}
                onClick={submitForm}
                noValidate
                data-unittest="saveData"
              >
                {saveText}
              </Button>
              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancelSmall)}
                onClick={cancelCategoryItemHandler}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
            <Col lg={8}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CategoryItem);
