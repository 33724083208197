import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import s from './SiteSegments.module.scss';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import sitePathBlue from '../../../assets/table-blue.svg';
import sitePathGrey from '../../../assets/table-grey.svg';
import mapGrey from '../../../assets/map-grey.svg';
import mapBlue from '../../../assets/map-blue.svg';
import Cookies from 'js-cookie';
import { IsWidgetAccessible } from '../../../utils/widgetManager';
import languageObject from '../../../translations/messages';
import { getUserProfileWidget, getSelectedSiteSegmentsView, getTranslation, getSelectedSitePathway } from '../../../selectors/index';
import { setSelectedSiteSegmentsViewThunk } from '../../../store/actions/siteSegments';
import { useSelector, useDispatch } from 'react-redux';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import SitePathway from './SitePathway/SitePathway';
import Map from './Map/Map';

const SiteSegments = ({ intl }) => {
  const dispatch = useDispatch();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let activeView = useSelector(state => getSelectedSiteSegmentsView(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const locale = Cookies.get(`defaultLanguage-${envName}`) || 'en';
  const { siteId } = useParams('siteId');
  const selectedSitePathway = useSelector(state => getSelectedSitePathway(state));

  const translations = useSelector(state => getTranslation(state)).translations || [];
  const langObject = { ...languageObject[locale], ...translations };

  const SITEPATHVIEW = 'SITEPATHVIEW';
  const SITEMAPVIEW = 'SITEMAPVIEW';
  let isSitePathwayViewAvailbable = IsWidgetAccessible(userProfileWidgets, 'WQSP_SITE_PATHWAY');
  let isMapViewAvailable = IsWidgetAccessible(userProfileWidgets, 'WQSP_SITE_ELEMENTS_MAP_VIEW');

  let selectedView = activeView ? activeView : isSitePathwayViewAvailbable ? SITEPATHVIEW : isMapViewAvailable ? SITEMAPVIEW : '';

  if (selectedView !== activeView) {
    dispatch(setSelectedSiteSegmentsViewThunk(selectedView));
  }

  //reset the timezone back to the selected timezone in account settings or local timezone
  let defaultTimezone = Cookies.get(`defaultTimezone-${envName}`);
  if (defaultTimezone === undefined) {
    defaultTimezone = moment.tz.guess();
  }

  const handleTabClick = tab => {
    dispatch(setSelectedSiteSegmentsViewThunk(tab));
  };

  return (
    <IntlProvider locale={locale} messages={langObject}>
      <SiteDashboardControlsContentTemplate
        selectedPage="siteDashboard"
        siteId={siteId}
        segmentName={(selectedSitePathway && selectedSitePathway.segmentName) || null}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.siteSegments}>
          <Row className={s.titleRows}>
            <Col className={'d-none d-md-block'}>
              {isSitePathwayViewAvailbable && isMapViewAvailable ? (
                <div className="float-right row">
                  <div
                    className={`mr-2 ${s.link}`}
                    onClick={() => {
                      handleTabClick(SITEPATHVIEW);
                    }}
                  >
                    <img src={activeView === SITEPATHVIEW ? sitePathBlue : sitePathGrey} alt="Site Path view"></img>
                    <span className={activeView === SITEPATHVIEW ? s.active : s.nonActive}>
                      <FormattedMessage id="siteSegments.sitePath" defaultMessage="Site Path" />
                    </span>
                  </div>
                  <div className={s.pipeSeparator}></div>
                  <div
                    className={`ml-2 ${s.link}`}
                    onClick={() => {
                      handleTabClick(SITEMAPVIEW);
                    }}
                  >
                    <img src={activeView === SITEPATHVIEW ? mapGrey : mapBlue} alt="Map view"></img>
                    <span className={activeView === SITEPATHVIEW ? s.nonActive : s.active}>
                      <FormattedMessage id="siteSegments.mapView" defaultMessage="Map view" />
                    </span>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </Col>
          </Row>

          <div id="sites-section">
            {/* MOBILE VIEW START */}
            {isSitePathwayViewAvailbable && isMapViewAvailable && (
              <div>
                <Row className={s.hideDesktop}>
                  <Col xs={6} className={s.viewMap}></Col>
                  <Col xs={6} className={s.viewMap}>
                    {activeView !== SITEPATHVIEW && (
                      <Button
                        className={s.mapButton}
                        variant="outline-secondary"
                        onClick={() => {
                          handleTabClick(SITEPATHVIEW);
                        }}
                      >
                        <FormattedMessage id="siteSegments.sitePath" defaultMessage="Site Path" />
                      </Button>
                    )}
                    {activeView === SITEPATHVIEW && (
                      <Button
                        className={s.mapButton}
                        variant="outline-secondary"
                        onClick={() => {
                          handleTabClick(SITEMAPVIEW);
                        }}
                      >
                        <FormattedMessage id="siteList.mapView" defaultMessage="Map View" />
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className={s.hideDesktop}>
                  <Col className={s.horizontalLine}>
                    <hr />
                  </Col>
                </Row>
              </div>
            )}
            {/* MOBILE VIEW END */}

            {activeView !== SITEMAPVIEW ? <SitePathway /> : <span />}

            {activeView === SITEMAPVIEW ? (
              <div className={s.siteWrapper}>
                <Map />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </IntlProvider>
  );
};

const SortOptions = ({ intl, sortItems, onSortItemChange, setSortSelection }) => {
  const clickRef = useRef();

  const handleClickOutside = e => {
    if (!clickRef.current.contains(e.target)) {
      setSortSelection(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  return (
    <div className={s.options} ref={clickRef}>
      {sortItems.map((elem, index) => {
        return (
          <Row
            onClick={() => {
              onSortItemChange(elem);
            }}
            key={index}
            className={[s.rows, index === 0 ? s.noBorder : '']}
          >
            <Col>{elem.Name}</Col>
          </Row>
        );
      })}
    </div>
  );
};

SiteSegments.defaultProps = {
  site: {},
  userProfileWidgets: {}
};

SiteSegments.propTypes = {
  site: PropTypes.object,
  userProfileWidgets: PropTypes.object
};

export default injectIntl(SiteSegments);
