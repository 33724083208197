import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './HazardEventLibrary.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const HazardEventLibraryListTable = ({ tableData, removeHazardEventTemplate, viewName, hazardClasses, isViewAllowed, isDeleteAllowed }) => {
  const getHazardClassName = hazardClassIds => {
    let hazardClassNames = hazardClassIds
      .split(',')
      .map(id => {
        return hazardClasses.find(item => item.GeneralFieldTypeId === parseInt(id))?.Name;
      })
      .join(', ');
    return hazardClassNames;
  };

  return (
    <Table variant className={s.innerTable}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="common.name" defaultMessage="Name" />
          </th>
          <th>
            <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
          </th>
          <th>
            <FormattedMessage id="wqsp.common.hazardClass" defaultMessage="Hazard Class" />
          </th>
          {(isViewAllowed || isDeleteAllowed) && <th></th>}
        </tr>
      </thead>
      <tbody>
        {tableData.map(n => {
          return (
            <Fragment key={`${viewName}-${n.Id}`}>
              <tr key={`${viewName}-${n.Id}`} data-unittest="templateData">
                <td>{n.Name}</td>
                <td>{n.EntityTypeName}</td>
                <td>{getHazardClassName(n.HazardClass)}</td>
                {(isViewAllowed || isDeleteAllowed) && (
                  <td className={s.btnCol}>
                    {isViewAllowed && (
                      <Link to={n.Link}>
                        <Button variant="outline-secondary" data-unittest="view" className={s.viewButton}>
                          <FormattedMessage id="common.view" defaultMessage="VIEW" />
                        </Button>
                      </Link>
                    )}
                    {isDeleteAllowed && (
                      <Button
                        variant="outline-secondary"
                        data-unittest="view"
                        className={s.removeButton}
                        onClick={() => {
                          removeHazardEventTemplate(n.Id);
                        }}
                      >
                        <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                      </Button>
                    )}
                  </td>
                )}
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

HazardEventLibraryListTable.defaultProps = {
  tableData: []
};

HazardEventLibraryListTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  removeHazardEventTemplate: PropTypes.func.isRequired,
  viewName: PropTypes.string.isRequired
};

export default injectIntl(HazardEventLibraryListTable);
