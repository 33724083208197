import React from 'react';
import moment from 'moment-timezone';

export const SectionBuild = ({ sectionData, getImages, getActionName, currentTimezone }) => {
  let siteDetailsData = sectionData.SiteDetails || {};
  let safetyPlanData = sectionData.SafetyPlan || {};
  return (
    <div id="section1" className="pageBreak">
      <h1>Section 1 - Build</h1>
      <div id="siteDetails" className="level1">
        <h2>Site Details</h2>
        <table className="level1-table">
          <tr>
            <td>Name</td>
            <td>{siteDetailsData.Name || ''}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{siteDetailsData.Description || ''}</td>
          </tr>
          <tr>
            <td>Households</td>
            <td>{siteDetailsData.Households || ''}</td>
          </tr>
          <tr>
            <td>Inhabitants</td>
            <td>{siteDetailsData.Inhabitants || ''}</td>
          </tr>
          <tr>
            <td>Region</td>
            <td>{siteDetailsData.Region || ''}</td>
          </tr>
          <tr>
            <td>GPS Latitude </td>
            <td>{siteDetailsData.GpsLatitude || ''}</td>
          </tr>
          <tr>
            <td>GPS Longitude </td>
            <td>{siteDetailsData.GpsLongitude || ''}</td>
          </tr>
          <tr>
            <td>Location </td>
            <td>{siteDetailsData.Location || ''}</td>
          </tr>
          <tr>
            <td>Last Review Date </td>
            <td>
              {siteDetailsData.LastReviewDate
                ? moment
                    .unix(parseInt(siteDetailsData.LastReviewDate))
                    .tz(currentTimezone)
                    .format('DD-MMM-YYYY')
                : 'N/A'}
            </td>
          </tr>
          <tr>
            <td>Next Review Date </td>
            <td>
              {siteDetailsData.NextReviewDate
                ? moment
                    .unix(parseInt(siteDetailsData.NextReviewDate))
                    .tz(currentTimezone)
                    .format('DD-MMM-YYYY')
                : 'N/A'}
            </td>
          </tr>
          <tr>
            <td>Review Frequency</td>
            <td>{siteDetailsData.ReviewFrequencyName || ''}</td>
          </tr>
          <tr>
            <td>Actioned</td>
            <td>{getActionName(siteDetailsData.IsActioned)}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{siteDetailsData.StatusName || ''}</td>
          </tr>

          {siteDetailsData.Images && siteDetailsData.Images.length > 0 ? (
            <tr>
              <td>Images</td>
              <td>{getImages(siteDetailsData.Images)}</td>
            </tr>
          ) : (
            ''
          )}
        </table>
      </div>
      <div id="safetyPlan" className="level1">
        <h2>Safety Plan</h2>
        <table className="level1-table">
          <tr>
            <td>Name</td>
            <td>{safetyPlanData.Name || ''}</td>
          </tr>
          <tr>
            <td>Plan Number</td>
            <td>{safetyPlanData.PlanNumber || ''}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{safetyPlanData.Name || ''}</td>
          </tr>
          <tr>
            <td>Last Review Date </td>
            <td>
              {safetyPlanData.LastReviewDate
                ? moment
                    .unix(parseInt(safetyPlanData.LastReviewDate))
                    .tz(currentTimezone)
                    .format('DD-MMM-YYYY')
                : 'N/A'}
            </td>
          </tr>
          <tr>
            <td>Next Review Date </td>
            <td>
              {safetyPlanData.NextReviewDate
                ? moment
                    .unix(parseInt(safetyPlanData.NextReviewDate))
                    .tz(currentTimezone)
                    .format('DD-MMM-YYYY')
                : 'N/A'}
            </td>
          </tr>
          <tr>
            <td>Review Frequency</td>
            <td>{safetyPlanData.ReviewFrequencyName || ''}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};
