import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './ControlMeasure.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialControlMeasureState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import uuid from 'uuid';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { getUserProfileWidget, getGeneralData, getControlMeasure, getCurrentTimezone, getHazardEvent } from '../../../selectors/index';
import {
  describeControlMeasureThunk,
  saveControlMeasureThunk,
  setControlMeasureChangeThunk,
  saveControlMonitoringThunk
} from '../../../store/actions/hazardEvent/controlMeasures';
import { getHazardEventThunk } from '../../../store/actions/hazardEvent/hazardEvent';
import DateTimePicker from '../../WSAControls/DateTimePicker/DateTimePicker';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import { CONTROL_MEASURE_CRITERIA, GENERAL_STATUS, CRITICAL_LIMIT_TYPE, FREQUENCY } from '../../../constants/index';
import InputNumber from '../../WSAControls/InputNumber/InputNumber';
import ControlMonitoringListTable from './ControlMonitoringListTable';
import { IsWidgetAccessible } from '../../../utils/widgetManager';
import FileUploader from '../../WSAControls/FileUploader/FileUploader';
import FileList from '../../WSAControls/FileList/FileList';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';
import Breadcrumb from '../../WSAControls/Breadcrumb';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const ControlMeasure = ({ intl }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  let { controlMeasureId, hazardEventId, siteId, entityId, segmentName, selectedTab } = useParams();

  segmentName = segmentName || 'control';
  selectedTab = selectedTab || '2';
  let IsEdit = false;

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const controlMeasure = useSelector(state => getControlMeasure(state));
  const generalData = useSelector(state => getGeneralData(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);
  const [localformErrors, setFormErrors] = useState({});
  const [active, setActive] = useState(false);
  const [controlMonitoringId, setControlMonitoringId] = useState(0);
  const [asendingOrder, setAsendingOrder] = useState(true);
  const hazardEvent = useSelector(state => getHazardEvent(state));

  const disableFutureDates = current => current.isBefore(currentTimestamp);

  const ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  let backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-measures`;
  if (segmentName === 'define') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`;
  }
  if (segmentName === 'identify') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`;
  }

  let widgetCode = 'WQSP_CONTROL_MEASURES_ADDNEW';
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  if (controlMeasureId) {
    IsEdit = true;
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure-view/${controlMeasureId}`;
    widgetCode = 'WQSP_CONTROL_MEASURES_EDIT';
    if (segmentName === 'define') {
      backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`;
    }
    if (segmentName === 'identify') {
      backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`;
    }
  }

  useEffect(() => {
    if (siteId) {
      let filterObj = {
        filterSiteId: siteId
      };
      dispatch(getHazardEventThunk(widgetCode, filterObj));
    }
  }, [getHazardEventThunk, siteId]);

  useEffect(() => {
    if (controlMeasureId) {
      dispatch(describeControlMeasureThunk(controlMeasureId, widgetCode));
    }
  }, [describeControlMeasureThunk, controlMeasureId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setControlMeasureChangeThunk({
          ...initialControlMeasureState.controlMeasure
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalControlMeasure = currentState => {
    dispatch(setControlMeasureChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = controlMeasure.selectedControlMeasure;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.orgId = orgId || 1;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ControlMeasureId = 0;
      saveData.HazardEventId = hazardEventId ? hazardEventId : saveData.HazardEventId;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_CONTROL_MEASURES_ADDNEW';
    }

    dispatch(saveControlMeasureThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !hazardEventId &&
      (!controlMeasure ||
        !controlMeasure.selectedControlMeasure ||
        !controlMeasure.selectedControlMeasure.HazardEventId ||
        isRequired(controlMeasure.selectedControlMeasure.HazardEventId, 1))
    ) {
      formErrors.HazardEvent = intl.formatMessage({
        id: 'common.hazardEventRequired',
        defaultMessage: 'Hazard Event is a mandatory field'
      });
      isValid = false;
    }

    if (
      !controlMeasure ||
      !controlMeasure.selectedControlMeasure ||
      !controlMeasure.selectedControlMeasure.Name ||
      isRequired(controlMeasure.selectedControlMeasure.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'common.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !controlMeasure ||
      !controlMeasure.selectedControlMeasure ||
      !controlMeasure.selectedControlMeasure.DateImplemented ||
      isRequired(controlMeasure.selectedControlMeasure.DateImplemented, 1)
    ) {
      formErrors.DateImplemented = intl.formatMessage({
        id: 'controlMeasure.dateImplementedRequired',
        defaultMessage: 'Date implemented is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const CriteriaOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === CONTROL_MEASURE_CRITERIA);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const hazardEventOptions = () => {
    let opData = [];
    const data = hazardEvent.hazardEvents.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.HazardEventId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === GENERAL_STATUS);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const onStatusChange = e => {
    setLocalControlMeasure({
      selectedControlMeasure: {
        ...controlMeasure.selectedControlMeasure,
        [e.target.name]: e.target.value,
        StatusId: null
      }
    });
  };

  const CriticalLimitOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === CRITICAL_LIMIT_TYPE);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  //on control value change
  const onChange = e => {
    setLocalControlMeasure({
      ...controlMeasure,
      selectedControlMeasure: { ...controlMeasure.selectedControlMeasure, [e.target.name]: e.target.value }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalControlMeasure({
      ...controlMeasure,
      selectedControlMeasure: { ...controlMeasure.selectedControlMeasure, [fieldName]: e.value }
    });
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();

    setLocalControlMeasure({
      ...controlMeasure,
      selectedControlMeasure: { ...controlMeasure.selectedControlMeasure, [fieldName]: changeDate }
    });
  };

  const onChangeControlMonitoring = controlMonitorings => {
    dispatch(
      setLocalControlMeasure({
        selectedControlMeasure: {
          controlMonitorings: controlMonitorings
        }
      })
    );
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const onAddNewControlMonitoringClick = () => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring`
      );
    } else if (segmentName === 'identify') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring`
      );
    } else {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure/${controlMeasureId}/control-monitoring`);
    }
  };

  const onControlMonitoringRemoveClick = controlMonitoringId => {
    setActive(true);
    setControlMonitoringId(controlMonitoringId);
  };

  const handleClose = () => {
    setActive(false);
    setControlMonitoringId(0);
  };

  const handleConfirm = () => {
    deleteControlMonitoring();
    handleClose();
  };

  const deleteControlMonitoring = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.ControlMonitoringId = controlMonitoringId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveControlMonitoringThunk(saveData, 'WQSP_CONTROL_MONITORINGS_DELETE'));
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  //FILE UPLOAD
  const onUploadStart = files => {
    // setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    //  setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          SiteId: 0,
          newFile: true,
          S3URL: item.S3URL,
          ImageId: item.fileGuid,
          Name: item.name
        };
      });
      let allFiles = [...controlMeasure.selectedControlMeasure.Files, ...newFiles];

      setLocalControlMeasure({
        selectedControlMeasure: {
          ...controlMeasure.selectedControlMeasure,
          Files: allFiles
        }
      });
    }
  };

  const removeFile = fileGuid => {
    let files = controlMeasure.selectedControlMeasure.Files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...controlMeasure.selectedControlMeasure.deletedFileIds];
    deletedFileIds.push(fileGuid);
    setLocalControlMeasure({
      selectedControlMeasure: {
        ...controlMeasure.selectedControlMeasure,
        Files: files,
        deletedFileIds: deletedFileIds
      }
    });
  };
  //END FILE UPLOAD

  let messageId = (controlMeasure && controlMeasure.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const controlMeasureName = (controlMeasure.selectedControlMeasure ? controlMeasure.selectedControlMeasure.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + controlMeasureName;
  } else {
    heading = intl.formatMessage({ id: 'controlMeasure.addNewControlMeasure', defaultMessage: 'Add new Control Measure' });
  }

  const dialogTitle = intl.formatMessage({
    id: 'controlMonitoring.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this control monitoring?'
  });

  const isItemListingAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MONITORINGS_LIST');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MONITORINGS_DELETE');
  let controlMonitoringsObj = controlMeasure.selectedControlMeasure.controlMonitorings;
  if (asendingOrder) {
    controlMonitoringsObj = controlMonitoringsObj.sort(compareValues('LastReviewDate'));
  } else {
    controlMonitoringsObj = controlMonitoringsObj.sort(compareValues('LastReviewDate', 'desc'));
  }

  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfControlMeasures', defaultMessage: 'List of Control Measures' });
  let controlMeasureHeading = intl.formatMessage({ id: 'controlMeasure.controlMeasureView', defaultMessage: 'View Control Measure' });
  //controlMeasureHeading += ' (' + controlMeasureName + ')'
  let hazardEventName = controlMeasure.selectedControlMeasure.HazardName;
  if (hazardEventId) {
    let selectedItem = hazardEvent.hazardEvents.find(x => x.HazardEventId == hazardEventId) || {};
    if (Object.keys(selectedItem).length > 0) {
      hazardEventName = selectedItem.Name;
    }
  }
  let hazardEventHeading =
    intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + ' (' + hazardEventName + ')';

  const breadcrumbControl = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: controlMeasureHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: IsEdit
    },

    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 5,
      name: <FormattedMessage id="controlMeasure.controlMeasureView" defaultMessage="View Control Measure" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 6, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const identifyHazardEventcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="controlMeasure.controlMeasureView" defaultMessage="View Control Measure" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 5, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const getBreadcrumbData = () => {
    if (segmentName === 'define') {
      return defineEntityCrumb;
    } else if (segmentName === 'identify') {
      return identifyHazardEventcrumb;
    } else {
      return breadcrumbControl;
    }
  };

  return (
    <div className={s.controlMeasure}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {controlMeasure.isLoading && <LoadingSpinner />}
        {controlMeasure.isRedirect ? <Redirect to={backUrl} /> : ''}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={controlMeasure.showBanner}
          status={controlMeasure.isOpSuccessful}
          successText={messageText}
        />
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
        <div className={s.contentWrapper}>
          <Breadcrumb data={getBreadcrumbData()} segmentName={segmentName} />
          <div className={s.controlMeasureContent}>
            <Form>
              <div className={s.topRow}>
                {!hazardEventId && (
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formHazardEvent">
                        <Form.Label>
                          <FormattedMessage id="controlMeasure.hazardEvent" defaultMessage="Hazard Event" />
                        </Form.Label>

                        <Dropdown
                          id="formHazardEvent"
                          dataArray={hazardEventOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: localformErrors.HazardEvent ? s.ddlError : ''
                          }}
                          name="HazardEventId"
                          onDropdownChange={e => onDropdownChange(e, 'HazardEventId')}
                          selectedOption={hazardEventOptions().filter(
                            option => option.value === controlMeasure.selectedControlMeasure.HazardEventId
                          )}
                          disabled={IsEdit}
                        />
                        {localformErrors && localformErrors.HazardEvent && (
                          <p role="alert" className={s.error}>
                            {localformErrors.HazardEvent}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formControlMeasureName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={controlMeasure.selectedControlMeasure.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formControlMeasureDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={controlMeasure.selectedControlMeasure.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formDateImplemented">
                      <Form.Label>
                        <FormattedMessage id="controlMeasure.dateImplemented" defaultMessage="Date Implemented" />
                      </Form.Label>
                      <DateTimePicker
                        isValidDate={disableFutureDates}
                        className={`${localformErrors.DateImplemented ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'DateImplemented')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(controlMeasure.selectedControlMeasure.DateImplemented)}
                        defaultValue={getFormattedate(controlMeasure.selectedControlMeasure.DateImplemented)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.DateImplemented && (
                        <p role="alert" className={s.error}>
                          {localformErrors.DateImplemented}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formTargetCriteria">
                      <Form.Label>
                        <FormattedMessage id="controlMeasure.targetCriteria" defaultMessage="Target Criteria" />
                      </Form.Label>
                      <InputNumber
                        name="TargetCriteria"
                        step="0.1"
                        min="0"
                        onInputChange={onChange}
                        customClassName={s.smallText}
                        value={controlMeasure.selectedControlMeasure.TargetCriteria?.toString()}
                        id="TargetCriteria"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formTargetCriteriaType">
                      <Form.Label>
                        <FormattedMessage id="controlMeasure.targetCriteriaType" defaultMessage="Target Criteria Type" />
                      </Form.Label>

                      <Dropdown
                        id="formTargetCriteriaType"
                        dataArray={CriteriaOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="TargetCriteriaTypeId"
                        onDropdownChange={e => onDropdownChange(e, 'TargetCriteriaTypeId')}
                        selectedOption={CriteriaOptions().filter(
                          option => option.value === controlMeasure.selectedControlMeasure.TargetCriteriaTypeId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formAlertCriteriaTrigger">
                      <Form.Label>
                        <FormattedMessage id="controlMeasure.alertTargetTrigger" defaultMessage="Alert Criteria Trigger" />
                      </Form.Label>
                      <InputNumber
                        name="AlertCriteriaTrigger"
                        step="0.1"
                        min="0"
                        onInputChange={onChange}
                        customClassName={s.smallText}
                        value={controlMeasure.selectedControlMeasure.AlertCriteriaTrigger?.toString()}
                        id="AlertCriteriaTrigger"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formAlertCriteria">
                      <Form.Label>
                        <FormattedMessage id="controlMeasure.alertCriteria" defaultMessage="Alert Criteria" />
                      </Form.Label>

                      <Dropdown
                        id="formAlertCriteria"
                        dataArray={CriteriaOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="AlertCriteriaId"
                        onDropdownChange={e => onDropdownChange(e, 'AlertCriteriaId')}
                        selectedOption={CriteriaOptions().filter(
                          option => option.value === controlMeasure.selectedControlMeasure.AlertCriteriaId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formCriticalLimitTrigger">
                      <Form.Label>
                        <FormattedMessage id="controlMeasure.criticalLimitTrigger" defaultMessage="Critical Limit Trigger" />
                      </Form.Label>
                      <InputNumber
                        name="CriticalLimitTrigger"
                        step="0.1"
                        min="0"
                        onInputChange={onChange}
                        customClassName={s.smallText}
                        value={controlMeasure.selectedControlMeasure.CriticalLimitTrigger?.toString()}
                        id="CriticalLimitTrigger"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formCriticalLimitType">
                      <Form.Label>
                        <FormattedMessage id="controlMeasure.criticalLimitType" defaultMessage="Critical Limit Type" />
                      </Form.Label>
                      <Dropdown
                        id="formCriticalLimitType"
                        dataArray={CriticalLimitOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="CriticalLimitTypeId"
                        onDropdownChange={e => onDropdownChange(e, 'CriticalLimitTypeId')}
                        selectedOption={CriticalLimitOptions().filter(
                          option => option.value === controlMeasure.selectedControlMeasure.CriticalLimitTypeId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formUnitOfMeasure">
                      <Form.Label>
                        <FormattedMessage id="controlMeasure.unitOfMeasure" defaultMessage="Unit Of Measure" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="UnitOfMeasure"
                        onChange={onChange}
                        value={controlMeasure.selectedControlMeasure.UnitOfMeasure}
                        className={`${s.formControl} ${localformErrors && localformErrors.UnitOfMeasure ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.unitOfMeasure',
                          defaultMessage: 'Unit Of Measure'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formLastReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.LastReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'LastReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(controlMeasure.selectedControlMeasure.LastReviewDate)}
                        defaultValue={getFormattedate(controlMeasure.selectedControlMeasure.LastReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.LastReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.LastReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formNextReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'NextReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(controlMeasure.selectedControlMeasure.NextReviewDate)}
                        defaultValue={getFormattedate(controlMeasure.selectedControlMeasure.NextReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.NextReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.NextReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formGroupReviewFrequency">
                      <Form.Label>
                        <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                      </Form.Label>

                      <Dropdown
                        id="formMaintenanceFrequency"
                        dataArray={frequencyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="ReviewFrequencyId"
                        onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                        selectedOption={frequencyOptions().filter(
                          option => option.value === controlMeasure.selectedControlMeasure.ReviewFrequencyId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formActioned">
                      <Form.Label>
                        <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                      </Form.Label>

                      <ToggleSwitch
                        handleClick={() => {
                          onStatusChange({ target: { name: 'IsActioned', value: !controlMeasure.selectedControlMeasure.IsActioned } });
                        }}
                        classname={s.switch}
                        checked={controlMeasure.selectedControlMeasure.IsActioned}
                        labelChecked={yesText}
                        labelUnchecked={noText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!controlMeasure.selectedControlMeasure.IsActioned && (
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formStatus">
                        <Form.Label>
                          <FormattedMessage id="common.status" defaultMessage="Status" />
                        </Form.Label>

                        <Dropdown
                          id="formStatus"
                          dataArray={statusOptions()}
                          controlData={{ placeholderText: ddlInitText }}
                          onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                          data-unittest="formddlStatus"
                          selectedOption={statusOptions().filter(option => option.value === controlMeasure.selectedControlMeasure.StatusId)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Label>
                      <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                    </Form.Label>
                    <FileUploader
                      widgetCode={widgetCode}
                      allowedFileTypes={[
                        ['image/jpeg', 'jpeg'],
                        ['application/pdf', 'pdf'],
                        ['application/msword', 'doc']
                      ]}
                      location={`organisation/${orgId}/sites/${siteId}/controlmeasure`}
                      enableMultipleUpload={true}
                      maxFiles={5}
                      onUploadComplete={onUploadComplete}
                      onUploadStart={onUploadStart}
                    />
                  </Col>
                </Row>
                <FileList files={controlMeasure.selectedControlMeasure.Files} onRemoveFile={removeFile} />
                <Row className={s.btnRow}>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div>
            {isItemListingAllowed && (
              <ControlMonitoringListTable
                controlMonitorings={controlMonitoringsObj}
                onChangeControlMonitoring={onChangeControlMonitoring}
                userProfileWidgets={userProfileWidgets}
                deleteAllowed={isDeleteAllowed}
                IsEditControlMeasure={IsEdit}
                onAddNewClick={onAddNewControlMonitoringClick}
                removeControlMonitoring={onControlMonitoringRemoveClick}
                onSortOrder={onSortOrder}
              />
            )}
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(ControlMeasure);
