import { initialHazardEventState } from '../initialState';

import {
  HAZARD_EVENT_ERROR,
  GET_ALL_HAZARD_EVENT_DETAILS,
  HAZARD_EVENT_IS_LOADING,
  SET_HAZARD_EVENT_CURRENT_PAGE,
  SET_HAZARD_EVENT_PAGE_FILTER,
  SET_HAZARD_EVENT_CHANGE,
  SET_HAZARD_EVENT,
  UPDATE_HAZARD_EVENT_SAVE_STATUS,
  SET_HAZARD_EVENT_FILTERS,
  SET_HAZARD_EVENT_RESULTS,
  SET_HAZARD_EVENT_COMMENTS,
  REMOVE_HAZARD_EVENT
} from '../../../constants/index';

const hazardEventReducer = (state = initialHazardEventState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_HAZARD_EVENT_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          ...data
        }
      };
    }

    case SET_HAZARD_EVENT_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          currentPage
        }
      };
    }

    case SET_HAZARD_EVENT_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_HAZARD_EVENT_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          ...data
        }
      };
    }

    case SET_HAZARD_EVENT_COMMENTS: {
      const { selectedHazardEvent } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          selectedHazardEvent: {
            ...state.hazardEvent.selectedHazardEvent,
            ...selectedHazardEvent
          }
        }
      };
    }

    case REMOVE_HAZARD_EVENT: {
      const { hazardEventId } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          hazardEvents: [...state.hazardEvent.hazardEvents.filter(item => item.HazardEventId !== hazardEventId)],
          filteredResults: [...state.hazardEvent.filteredResults.filter(item => item.HazardEventId !== hazardEventId)],
          hazardEventListCount: state.hazardEvent.hazardEventListCount - 1
        }
      };
    }

    case HAZARD_EVENT_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          isLoading: isLoading
        }
      };
    }

    case HAZARD_EVENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          ...data
        }
      };
    }

    case SET_HAZARD_EVENT: {
      let { selectedHazardEvent } = payload;

      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          isOpSuccessful: false,
          showBanner: false,
          selectedHazardEvent: {
            ...state.hazardEvent.selectedHazardEvent,
            ...selectedHazardEvent
          }
        }
      };
    }

    case SET_HAZARD_EVENT_CHANGE: {
      let { selectedHazardEvent } = payload;

      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedHazardEvent: {
            ...state.hazardEvent.selectedHazardEvent,
            ...selectedHazardEvent
          }
        }
      };
    }

    case SET_HAZARD_EVENT_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          ...filteredResults
        }
      };
    }

    case SET_HAZARD_EVENT_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        hazardEvent: {
          ...state.hazardEvent,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default hazardEventReducer;
