import {
  GET_ALL_PROFILE_DETAILS,
  PROFILE_DETAIL,
  PROFILE_IS_LOADING,
  UPDATE_SAVE_STATUS,
  SET_SELECTED_PROFILE,
  UPDATE_ERROR_STATUS,
  SET_SELECTED_PROFILE_WIDGET
} from '../../constants/index';
import { initialProfileState } from './initialState';

const profilesReducer = (state = initialProfileState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_PROFILE_DETAILS: {
      let { profile } = payload;
      return {
        ...state,
        profile: {
          profiles: profile.profiles,
          selectedProfile: {
            ProfileId: 0,
            Name: '',
            Description: '',
            GuidIdentifier: '',
            IsActive: true,
            IsMFAEnabled: false,
            IsDefault: false,
            ProfileWigets: [],
            formValid: false,
            errorCount: null,
            errors: {
              Name: { required: true, error: '', value: '' },
              Description: { required: true, error: '', value: '' }
            }
          },
          isLoading: false,
          showBanner: false,
          isOpSuccessful: true,
          redirect: false,
          isError: false,
          errorMessage: ''
        }
      };
    }

    case SET_SELECTED_PROFILE: {
      const { profile } = payload;
      return {
        ...state,
        profile: {
          selectedProfile: profile,
          isLoading: false,
          showBanner: false,
          isOpSuccessful: true,
          redirect: false,
          isError: false,
          errorMessage: ''
        }
      };
    }
    case PROFILE_DETAIL: {
      const { profile } = payload;

      return {
        ...state,
        profile: {
          selectedProfile: {
            ProfileId: profile.ProfileId,
            Name: profile.Name,
            Description: profile.Description,
            GuidIdentifier: profile.GuidIdentifier,
            IsActive: profile.IsActive,
            IsMFAEnabled: profile.IsMFAEnabled,
            ProfileWigets: [],
            formValid: false,
            errorCount: null,
            errors: {
              Name: { required: true, error: '', value: profile.Name },
              Description: { required: true, error: '', value: profile.Description }
            }
          },
          isLoading: false,
          showBanner: false,
          isOpSuccessful: true,
          redirect: false,
          isError: false,
          errorMessage: ''
        }
      };
    }

    case PROFILE_IS_LOADING: {
      const { status } = payload;
      return {
        ...state,
        profile: {
          selectedProfile: state.profile.selectedProfile,
          isLoading: status,
          showBanner: false,
          isOpSuccessful: true,
          redirect: false,
          isError: false,
          errorMessage: ''
        }
      };
    }

    case UPDATE_SAVE_STATUS: {
      const { message, showBanner, redirect } = payload;
      return {
        ...state,
        profile: {
          isLoading: false,
          showBanner: showBanner,
          isOpSuccessful: true,
          redirect: redirect,
          isError: false,
          errorMessage: message
        }
      };
    }
    case SET_SELECTED_PROFILE_WIDGET: {
      const { profile } = payload;
      return {
        ...state,
        profile: {
          selectedProfile: {
            ProfileId: profile.profile.ProfileId,
            Name: profile.profile.Name,
            Description: profile.profile.Description,
            GuidIdentifier: profile.profile.GuidIdentifier,
            IsActive: profile.profile.IsActive,
            IsMFAEnabled: profile.profile.IsMFAEnabled,
            ProfileWigets: profile.profile.ProfileWigets,
            formValid: false,
            errorCount: null,
            errors: {
              Name: { required: true, error: '', value: profile.profile.Name },
              Description: { required: true, error: '', value: profile.profile.Description }
            }
          },
          isLoading: false,
          showBanner: false,
          isOpSuccessful: true,
          redirect: false,
          isError: false,
          errorMessage: ''
        }
      };
    }

    case UPDATE_ERROR_STATUS: {
      const { message, showBanner } = payload;
      return {
        ...state,
        profile: {
          selectedProfile: state.profile.selectedProfile,
          isLoading: false,
          showBanner: showBanner,
          isOpSuccessful: false,
          redirect: false,
          isError: true,
          errorMessage: message
        }
      };
    }
    default:
      return state;
  }
};

export default profilesReducer;
