import React, { useState, useEffect } from 'react';
import s from './Entity.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getEntity, getHazardEventLibrary, getGeneralData, getCurrentTimezone, getUserProfileWidget } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialEntityState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import Cookies from 'js-cookie';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import Banner from '../Banner/Banner';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import {
  FREQUENCY,
  GENERAL_STATUS,
  CONDITION,
  SUPPLY_TYPE,
  ENTITY_PURPOSE,
  TREATMENT_TYPE,
  CATEGORY_OF_WATER
} from '../../constants/index';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import { describeEntityThunk, saveEntityThunk, setEntityChangeThunk, setEntityCleanUpThunk } from '../../store/actions/entity';
import { getHazardEventTemplateThunk } from '../../store/actions/hazardEventLibrary';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';
import EntityItemTable from './EntityItemTable';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Breadcrumb from '../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const Entity = ({ intl }) => {
  let history = useHistory();
  let { entityId, siteId, segmentName, selectedTab } = useParams();
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  const dispatch = useDispatch();
  const entity = useSelector(state => getEntity(state));
  const generalData = useSelector(state => getGeneralData(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const hazardEventLibrary = useSelector(state => getHazardEventLibrary(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);
  const currentTimestamp = moment().tz(currentTimezone);
  const disableFutureDates = current => current.isBefore(currentTimestamp);

  const ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});
  const [fileUploadStarted, setFileUploadStarted] = useState(false);
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [disableMode, setDisableMode] = useState(false);

  const backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities`;
  let widgetCode = '';
  if (entityId) {
    IsEdit = true;
    widgetCode = 'WQSP_ENTITIES_UPDATE';
  } else {
    widgetCode = 'WQSP_ENTITIES_ADD';
    entityId = 0;
  }

  useEffect(() => {
    if (entityId && siteId) {
      dispatch(describeEntityThunk(entityId, widgetCode, siteId));
    }
  }, [describeEntityThunk, dispatch, entityId, siteId]);

  useEffect(() => {
    dispatch(getHazardEventTemplateThunk(widgetCode));
  }, []);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setEntityCleanUpThunk(initialEntityState.entity.SelectedEntity));
    };
  }, []);

  // Update redux store
  const setLocalEntity = currentState => {
    dispatch(setEntityChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = entity.SelectedEntity;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.SiteId = siteId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.EntityId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_ENTITIES_ADD';
    }

    dispatch(saveEntityThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !entity ||
      !entity.SelectedEntity ||
      !entity.SelectedEntity.WaterSupplyDescriptionId ||
      isRequired(entity.SelectedEntity.WaterSupplyDescriptionId, 1)
    ) {
      formErrors.WaterSupplyDescriptionId = intl.formatMessage({
        id: 'wqsp.common.waterSupplyMandatory',
        defaultMessage: 'Water supply name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !entity ||
      !entity.SelectedEntity ||
      !entity.SelectedEntity.EntitySubTypeId ||
      isRequired(entity.SelectedEntity.EntitySubTypeId, 1)
    ) {
      formErrors.EntitySubTypeId = intl.formatMessage({
        id: 'entities.entitySubTypeRequired',
        defaultMessage: 'Entity sub type is a mandatory field'
      });
      isValid = false;
    }

    if (!entity || !entity.SelectedEntity || !entity.SelectedEntity.Name || isRequired(entity.SelectedEntity.Name, 1)) {
      formErrors.Name = intl.formatMessage({
        id: 'entities.NameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalEntity({
      ...entity,
      SelectedEntity: { ...entity.SelectedEntity, [fieldName]: e.value }
    });
  };

  //on control value change
  const onChange = e => {
    setLocalEntity({
      ...entity,
      SelectedEntity: { ...entity.SelectedEntity, [e.target.name]: e.target.value }
    });
  };

  const onDateChange = (newVal, fieldName) => {
    let inspectionDate = null;
    if (newVal && newVal.unix) inspectionDate = newVal.unix();

    setLocalEntity({
      ...entity,
      SelectedEntity: { ...entity.SelectedEntity, [fieldName]: inspectionDate }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (entity && entity.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const title = (entity.SelectedEntity ? entity.SelectedEntity.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + title;
  } else {
    heading = intl.formatMessage({
      id: 'entities.addEntity',
      defaultMessage: 'Add new entity'
    });
  }

  const waterSupplyOptions = () => {
    let opData = [];

    const data = generalData.waterSupplyDescriptions.filter(x => x.SiteId === parseInt(siteId));

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.WaterSupplyDescriptionId
      });
    });
    return opData;
  };

  const categoryOfWaterOptions = () => {
    let opData = [];

    const data = CATEGORY_OF_WATER;

    data.forEach(element => {
      opData.push({
        label: element.name,
        value: element.id
      });
    });
    return opData;
  };

  const entityTypeOptions = () => {
    let opData = [];

    const data = generalData.entityTypes;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntityTypeId
      });
    });
    return opData;
  };

  const entitySubTypeOptions = () => {
    let opData = [];
    const data = generalData.entitySubTypes.filter(x => x.EntityTypeId === entity.SelectedEntity.EntityTypeId);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntitySubTypeId
      });
    });
    return opData;
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const entityConditionOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === CONDITION);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  let ddlClassWaterSupply = localformErrors && localformErrors.WaterSupplyDescriptionId ? s.ddlError : '';
  let ddlClassEntityType = localformErrors && localformErrors.EntityTypeId ? s.ddlError : '';
  let ddlClassEntitySubType = localformErrors && localformErrors.EntitySubTypeId ? s.ddlError : '';

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  const onUploadStart = files => {
    setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          SiteId: 0,
          newFile: true,
          S3URL: item.S3URL,
          ImageId: item.fileGuid,
          Name: item.name
        };
      });
      let allFiles = [...entity.SelectedEntity.Files, ...newFiles];

      setLocalEntity({
        ...entity,
        SelectedEntity: { ...entity.SelectedEntity, Files: allFiles }
      });
    }
  };

  const removeFile = fileGuid => {
    let files = entity.SelectedEntity.Files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...entity.SelectedEntity.deletedFileIds];
    deletedFileIds.push(fileGuid);
    setLocalEntity({
      ...entity,
      SelectedEntity: { ...entity.SelectedEntity, Files: files, deletedFileIds: deletedFileIds }
    });
  };

  const onAddEntityItem = entityHazardEventLibraryItem => {
    let hazardEventTemplateItems =
      (entity.SelectedEntity.HazardEventTemplateItems && [...entity.SelectedEntity.HazardEventTemplateItems]) || [];

    entityHazardEventLibraryItem.GuidIdentifier = uuidv4();
    entityHazardEventLibraryItem.EntityHazardId = 0;
    entityHazardEventLibraryItem.IsActive = true;
    entityHazardEventLibraryItem.IsDeleted = false;
    hazardEventTemplateItems.push(entityHazardEventLibraryItem);

    setShowAddEdit(false);
    setLocalEntity({
      ...entity,
      SelectedEntity: { ...entity.SelectedEntity, HazardEventTemplateItems: hazardEventTemplateItems }
    });
  };

  const onChangeEventItem = entityHazardEventLibraryItems => {
    setLocalEntity({
      ...entity,
      SelectedEntity: { ...entity.SelectedEntity, HazardEventTemplateItems: entityHazardEventLibraryItems }
    });
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onStatusChange = e => {
    setLocalEntity({
      ...entity,
      SelectedEntity: { ...entity.SelectedEntity, [e.target.name]: e.target.value, StatusId: null }
    });
  };

  const isItemListingAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ENTITIES_HAZARDLIBRARY_LIST');
  const updateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ENTITIES_UPDATE');

  const breadcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 2, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <div>
      <div className={s.entity}>
        {entity.isLoading && <LoadingSpinner />}
        {entity.isRedirect ? <Redirect to={backUrl} /> : ''}
        <Banner
          key={uuidv4()}
          failureText={messageText}
          showBanner={entity.showBanner}
          status={entity.isOpSuccessful}
          successText={messageText}
        />
        <SiteDashboardControlsContentTemplate
          selectedPage={selectedTab}
          siteId={siteId}
          segmentName={segmentName}
          userProfileWidgets={userProfileWidgets}
        >
          <div className={s.contentWrapper}>
            <Breadcrumb data={breadcrumb} segmentName={segmentName} />
            <div className={s.entityContent}>
              <Form>
                <div className={s.topRow}>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="waterSupplyName">
                        <Form.Label>
                          <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                        </Form.Label>

                        <Dropdown
                          id="formwaterSupplyName"
                          dataArray={waterSupplyOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: ddlClassWaterSupply
                          }}
                          name="WaterSupplyDescriptionId"
                          onDropdownChange={e => onDropdownChange(e, 'WaterSupplyDescriptionId')}
                          selectedOption={waterSupplyOptions().filter(
                            option => option.value === entity.SelectedEntity.WaterSupplyDescriptionId
                          )}
                          disabled={IsEdit}
                        />
                        {localformErrors && localformErrors.WaterSupplyDescriptionId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.WaterSupplyDescriptionId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="sourceEntityType">
                        <Form.Label>
                          <FormattedMessage id="entities.sourceEntityType" defaultMessage="Source Entity Type" />
                        </Form.Label>

                        <Dropdown
                          id="formSourceEntityType"
                          dataArray={entityTypeOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: ddlClassEntityType
                          }}
                          name="EntityTypeId"
                          onDropdownChange={e => onDropdownChange(e, 'EntityTypeId')}
                          selectedOption={entityTypeOptions().filter(option => option.value === entity.SelectedEntity.EntityTypeId)}
                          disabled={IsEdit}
                        />
                        {localformErrors && localformErrors.EntityTypeId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.EntityTypeId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="sourceEntitySubType">
                        <Form.Label>
                          <FormattedMessage id="wqsp.common.entitySubType" defaultMessage="Entity Sub Type" />
                        </Form.Label>

                        <Dropdown
                          id="formSourceEntityType"
                          dataArray={entitySubTypeOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: ddlClassEntitySubType
                          }}
                          name="EntitySubTypeId"
                          onDropdownChange={e => onDropdownChange(e, 'EntitySubTypeId')}
                          selectedOption={entitySubTypeOptions().filter(option => option.value === entity.SelectedEntity.EntitySubTypeId)}
                          disabled={IsEdit}
                        />
                        {localformErrors && localformErrors.EntitySubTypeId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.EntitySubTypeId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formEntityName">
                        <Form.Label>
                          <FormattedMessage id="entities.name" defaultMessage="Name" />
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="Name"
                          onChange={onChange}
                          value={entity.SelectedEntity.Name}
                          className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                          placeholder={intl.formatMessage({
                            id: 'common.name',
                            defaultMessage: 'Name'
                          })}
                        />
                        {localformErrors && localformErrors.Name && (
                          <p role="alert" className={s.error}>
                            {localformErrors.Name}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formEntityDescription">
                        <Form.Label>
                          <FormattedMessage id="common.description" defaultMessage="Description" />
                        </Form.Label>

                        <Form.Control
                          className={s.textArea}
                          as="textarea"
                          rows="3"
                          name="Description"
                          onChange={onChange}
                          placeholder={intl.formatMessage({
                            id: 'common.description',
                            defaultMessage: 'Description'
                          })}
                          value={entity.SelectedEntity.Description}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formGroupCategoryOfWater">
                        <Form.Label>
                          <FormattedMessage id="entities.categoryOfWater" defaultMessage="Category of Water" />
                        </Form.Label>

                        <Dropdown
                          id="formCategoryOfWater"
                          dataArray={categoryOfWaterOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                          }}
                          name="CategoryOfWater"
                          onDropdownChange={e => onDropdownChange(e, 'CategoryOfWater')}
                          selectedOption={categoryOfWaterOptions().filter(option => option.value === entity.SelectedEntity.CategoryOfWater)}
                        />
                        {localformErrors && localformErrors.CategoryOfWater && (
                          <p role="alert" className={s.error}>
                            {localformErrors.CategoryOfWater}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formGroupInspectionFrequency">
                        <Form.Label>
                          <FormattedMessage id="entities.inspectionFrequency" defaultMessage="Inspection Frequency" />
                        </Form.Label>

                        <Dropdown
                          id="formInspectionFrequency"
                          dataArray={frequencyOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                          }}
                          name="InspectionFrequency"
                          onDropdownChange={e => onDropdownChange(e, 'InspectionFrequency')}
                          selectedOption={frequencyOptions().filter(option => option.value === entity.SelectedEntity.InspectionFrequency)}
                        />
                        {localformErrors && localformErrors.InspectionFrequency && (
                          <p role="alert" className={s.error}>
                            {localformErrors.InspectionFrequency}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formGroupEntityCondition">
                        <Form.Label>
                          <FormattedMessage id="entities.condition" defaultMessage="Condition" />
                        </Form.Label>

                        <Dropdown
                          id="formEntityCondition"
                          dataArray={entityConditionOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                          }}
                          name="EntityCondition"
                          onDropdownChange={e => onDropdownChange(e, 'EntityCondition')}
                          selectedOption={entityConditionOptions().filter(option => option.value === entity.SelectedEntity.EntityCondition)}
                        />
                        {localformErrors && localformErrors.EntityCondition && (
                          <p role="alert" className={s.error}>
                            {localformErrors.EntityCondition}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formGroupMaintenanceFrequency">
                        <Form.Label>
                          <FormattedMessage id="entities.maintenanceFrequency" defaultMessage="Maintenance Frequency" />
                        </Form.Label>

                        <Dropdown
                          id="formMaintenanceFrequency"
                          dataArray={frequencyOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                          }}
                          name="MaintenanceFrequency"
                          onDropdownChange={e => onDropdownChange(e, 'MaintenanceFrequency')}
                          selectedOption={frequencyOptions().filter(option => option.value === entity.SelectedEntity.MaintenanceFrequency)}
                        />
                        {localformErrors && localformErrors.MaintenanceFrequency && (
                          <p role="alert" className={s.error}>
                            {localformErrors.MaintenanceFrequency}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formDateOfLastInspection">
                        <Form.Label>
                          <FormattedMessage id="entities.dateOfLastInspection" defaultMessage="Date of Last Inspection" />
                        </Form.Label>
                        <DateTimePicker
                          isValidDate={disableFutureDates}
                          className={`${localformErrors.DateOfLastInspection ? s.formControlError : s.frmCalendar}`}
                          onChange={m => onDateChange(m, 'DateOfLastInspection')}
                          timeFormat={null}
                          dateFormat="DD-MMM-YYYY"
                          value={getFormattedate(entity.SelectedEntity.DateOfLastInspection)}
                          defaultValue={getFormattedate(entity.SelectedEntity.DateOfLastInspection)}
                          closeOnSelect={true}
                          showClear={false}
                        />
                        {localformErrors && localformErrors.DateOfLastInspection && (
                          <p role="alert" className={s.error}>
                            {localformErrors.DateOfLastInspection}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formDateOfLastService">
                        <Form.Label>
                          <FormattedMessage id="entities.lateOfLastService" defaultMessage="Date of Last Service" />
                        </Form.Label>
                        <DateTimePicker
                          isValidDate={disableFutureDates}
                          className={`${localformErrors.DateOfLastService ? s.formControlError : s.frmCalendar}`}
                          onChange={m => onDateChange(m, 'DateOfLastService')}
                          timeFormat={null}
                          dateFormat="DD-MMM-YYYY"
                          value={getFormattedate(entity.SelectedEntity.DateOfLastService)}
                          defaultValue={getFormattedate(entity.SelectedEntity.DateOfLastService)}
                          closeOnSelect={true}
                          showClear={false}
                        />
                        {localformErrors && localformErrors.DateOfLastService && (
                          <p role="alert" className={s.error}>
                            {localformErrors.DateOfLastService}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Form.Group controlId="formEnitityLatitude">
                        <Form.Label>
                          <FormattedMessage id="wqsp.common.gpsLatitude" defaultMessage="GPS Latitude" />
                        </Form.Label>
                        <InputNumber
                          name="GpsLatitude"
                          step=".01"
                          onInputChange={onChange}
                          customClassName={`${s.smallText}`}
                          value={entity.SelectedEntity.GpsLatitude?.toString()}
                          hideStepArrow={true}
                          placeholder={intl.formatMessage({
                            id: 'wqsp.common.gpsLatitude',
                            defaultMessage: 'GPS Latitude'
                          })}
                          id="GpsLatitude"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Form.Group controlId="formEnitityLongitude">
                        <Form.Label>
                          <FormattedMessage id="wqsp.common.gpsLongitude" defaultMessage="GPS Longitude" />
                        </Form.Label>
                        <InputNumber
                          name="GpsLongitude"
                          step=".01"
                          onInputChange={onChange}
                          customClassName={`${s.smallText}`}
                          value={entity.SelectedEntity.GpsLongitude?.toString()}
                          hideStepArrow={true}
                          placeholder={intl.formatMessage({
                            id: 'wqsp.common.gpsLongitude',
                            defaultMessage: 'GPS Longitude'
                          })}
                          id="GpsLongitude"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {entity.SelectedEntity.EntityTypeId === 2 && (
                    <EntitySource
                      entity={entity}
                      localformErrors={localformErrors}
                      onChange={onChange}
                      onDropdownChange={onDropdownChange}
                      generalData={generalData}
                    />
                  )}
                  {entity.SelectedEntity.EntityTypeId === 4 && (
                    <EntityStorage
                      entity={entity}
                      localformErrors={localformErrors}
                      onChange={onChange}
                      onDropdownChange={onDropdownChange}
                      generalData={generalData}
                    />
                  )}
                  {entity.SelectedEntity.EntityTypeId === 3 && (
                    <EntityTransfer
                      entity={entity}
                      localformErrors={localformErrors}
                      onChange={onChange}
                      onDropdownChange={onDropdownChange}
                      generalData={generalData}
                      intl={intl}
                    />
                  )}
                  {entity.SelectedEntity.EntityTypeId === 5 && (
                    <EntityAbstraction
                      entity={entity}
                      localformErrors={localformErrors}
                      onChange={onChange}
                      onDropdownChange={onDropdownChange}
                      generalData={generalData}
                    />
                  )}
                  {entity.SelectedEntity.EntityTypeId === 6 && (
                    <EntityTreatment
                      entity={entity}
                      localformErrors={localformErrors}
                      onDateChange={onDateChange}
                      onDropdownChange={onDropdownChange}
                      generalData={generalData}
                      getFormattedate={getFormattedate}
                    />
                  )}
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formLastReviewDate">
                        <Form.Label>
                          <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                        </Form.Label>
                        <DateTimePicker
                          isValidDate={disableFutureDates}
                          className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                          onChange={m => onDateChange(m, 'LastReviewDate')}
                          timeFormat={null}
                          dateFormat="DD-MMM-YYYY"
                          value={getFormattedate(entity.SelectedEntity.LastReviewDate)}
                          defaultValue={getFormattedate(entity.SelectedEntity.LastReviewDate)}
                          closeOnSelect={true}
                          showClear={false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formNextReviewDate">
                        <Form.Label>
                          <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                        </Form.Label>
                        <DateTimePicker
                          className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                          onChange={m => onDateChange(m, 'NextReviewDate')}
                          timeFormat={null}
                          dateFormat="DD-MMM-YYYY"
                          value={getFormattedate(entity.SelectedEntity.NextReviewDate)}
                          defaultValue={getFormattedate(entity.SelectedEntity.NextReviewDate)}
                          closeOnSelect={true}
                          showClear={false}
                        />
                        {localformErrors && localformErrors.NextReviewDate && (
                          <p role="alert" className={s.error}>
                            {localformErrors.NextReviewDate}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formGroupReviewFrequency">
                        <Form.Label>
                          <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                        </Form.Label>

                        <Dropdown
                          id="formMaintenanceFrequency"
                          dataArray={frequencyOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                          }}
                          name="ReviewFrequencyId"
                          onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                          selectedOption={frequencyOptions().filter(option => option.value === entity.SelectedEntity.ReviewFrequencyId)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Form.Group controlId="formActioned">
                        <Form.Label>
                          <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                        </Form.Label>

                        <ToggleSwitch
                          handleClick={() => {
                            onStatusChange({ target: { name: 'IsActioned', value: !entity.SelectedEntity.IsActioned } });
                          }}
                          classname={s.switch}
                          checked={entity.SelectedEntity.IsActioned}
                          labelChecked={yesText}
                          labelUnchecked={noText}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {!entity.SelectedEntity.IsActioned && (
                    <Row>
                      <Col lg={3}>
                        <Form.Group controlId="formStatus">
                          <Form.Label>
                            <FormattedMessage id="common.status" defaultMessage="Status" />
                          </Form.Label>

                          <Dropdown
                            id="formStatus"
                            dataArray={statusOptions()}
                            controlData={{ placeholderText: ddlInitText }}
                            onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                            data-unittest="formDdlRiskStatus"
                            selectedOption={statusOptions().filter(option => option.value === entity.SelectedEntity.StatusId)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <Form.Label>
                        <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                      </Form.Label>
                      <FileUploader
                        widgetCode={'WQSP_ENTITIES_ATTACH'}
                        allowedFileTypes={[
                          ['image/jpeg', 'jpeg'],
                          ['application/pdf', 'pdf'],
                          ['application/msword', 'doc']
                        ]}
                        location={`organisation/${orgId}/sites/${siteId}/entities`}
                        enableMultipleUpload={true}
                        maxFiles={5}
                        onUploadComplete={onUploadComplete}
                        onUploadStart={onUploadStart}
                      />
                    </Col>
                  </Row>
                  <FileList files={entity.SelectedEntity.Files} onRemoveFile={removeFile} />
                </div>
              </Form>
            </div>
          </div>
        </SiteDashboardControlsContentTemplate>
      </div>
      <div className={s.separator}>
        {isItemListingAllowed && (
          <EntityItemTable
            hazardEventTemplateItems={entity.SelectedEntity.HazardEventTemplateItems}
            hazardEventTemplates={hazardEventLibrary.HazardEventTemplates}
            setShowAddEdit={setShowAddEdit}
            showAddEdit={showAddEdit}
            disableMode={disableMode}
            setDisableMode={setDisableMode}
            onAddEntityItem={onAddEntityItem}
            onChangeEntityItem={onChangeEventItem}
            userProfileWidgets={userProfileWidgets}
            updateAllowed={updateAllowed}
            view={false}
          />
        )}
      </div>
      <Row>
        <Col>
          <Button
            variant="primary"
            className={classNames(s.margin5, s.btnSaveChanges)}
            onClick={submitForm}
            noValidate
            data-unittest="saveData"
          >
            <FormattedMessage id="common.save" defaultMessage="SAVE" />
          </Button>

          <Button
            variant="outline-secondary"
            className={classNames(s.btnCancel)}
            onClick={cancelHandler}
            noValidate
            data-unittest="saveCancel"
          >
            <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

Entity.defaultProps = {
  entity: {
    ...initialEntityState
  }
};

export default injectIntl(Entity);

const EntitySource = ({ entity, localformErrors, onChange, onDropdownChange, generalData }) => {
  const supplyTypeOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === SUPPLY_TYPE);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  return (
    <div>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formEntityName">
            <Form.Label>
              <FormattedMessage id="entities.volumeSupplied" defaultMessage="Volume Supplied" />
            </Form.Label>

            <InputNumber
              name="VolumeSupplied"
              step="0.1"
              min="0"
              onInputChange={onChange}
              customClassName={s.smallText}
              value={entity.SelectedEntity.VolumeSupplied?.toString()}
              id="volumeSuppliedNumber"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formTypeofSupply">
            <Form.Label>
              <FormattedMessage id="entities.typeofSupply" defaultMessage="Type of Supply" />
            </Form.Label>

            <Dropdown
              id="formTypeofSupply"
              dataArray={supplyTypeOptions()}
              controlData={{
                placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
              }}
              name="TypeofSupply"
              onDropdownChange={e => onDropdownChange(e, 'TypeofSupply')}
              selectedOption={supplyTypeOptions().filter(option => option.value === entity.SelectedEntity.TypeofSupply)}
            />
            {localformErrors && localformErrors.TypeofSupply && (
              <p role="alert" className={s.error}>
                {localformErrors.TypeofSupply}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

const EntityStorage = ({ entity, localformErrors, onChange, onDropdownChange, generalData }) => {
  const purposeOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === ENTITY_PURPOSE);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  return (
    <div>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formVolumeStored">
            <Form.Label>
              <FormattedMessage id="entities.volumeStored" defaultMessage="Volume Stored" />
            </Form.Label>

            <InputNumber
              name="VolumeStored"
              step="0.1"
              min="0"
              onInputChange={onChange}
              customClassName={s.smallText}
              value={entity.SelectedEntity.VolumeStored?.toString()}
              id="volumeSuppliedNumber"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formGroupStoragePurpose">
            <Form.Label>
              <FormattedMessage id="entities.purpose" defaultMessage="Purpose" />
            </Form.Label>

            <Dropdown
              id="formStoragePurpose"
              dataArray={purposeOptions()}
              controlData={{
                placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
              }}
              name="StoragePurpose"
              onDropdownChange={e => onDropdownChange(e, 'StoragePurpose')}
              selectedOption={purposeOptions().filter(option => option.value === entity.SelectedEntity.StoragePurpose)}
            />
            {localformErrors && localformErrors.StoragePurpose && (
              <p role="alert" className={s.error}>
                {localformErrors.StoragePurpose}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

const EntityTransfer = ({ entity, localformErrors, onChange, onDropdownChange, generalData, intl }) => {
  const entityNameOptions = () => {
    let opData = [];
    const data = generalData.entities.filter(x => x.WaterSupplyDescriptionId === entity.SelectedEntity.WaterSupplyDescriptionId);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntityId
      });
    });
    return opData;
  };

  return (
    <div>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formGroupLengthOfTransfer">
            <Form.Label>
              <FormattedMessage id="entities.lengthOfTransfer" defaultMessage="Length Of Transfer" />
            </Form.Label>

            <Form.Control
              type="text"
              name="LengthOfTransfer"
              onChange={onChange}
              className={s.smallText}
              value={entity.SelectedEntity.LengthOfTransfer?.toString()}
              id="volumeSuppliedNumber"
              placeholder={intl.formatMessage({
                id: 'entities.lengthOfTransfer',
                defaultMessage: 'Length Of Transfer'
              })}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formGroupSourceEntityId">
            <Form.Label>
              <FormattedMessage id="entities.sourceEntity" defaultMessage="Source Entity" />
            </Form.Label>

            <Dropdown
              id="formSourceEntityId"
              dataArray={entityNameOptions()}
              controlData={{
                placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
              }}
              name="SourceEntityId"
              onDropdownChange={e => onDropdownChange(e, 'SourceEntityId')}
              selectedOption={entityNameOptions().filter(option => option.value === entity.SelectedEntity.SourceEntityId)}
            />
            {localformErrors && localformErrors.SourceEntityId && (
              <p role="alert" className={s.error}>
                {localformErrors.SourceEntityId}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formGroupDestinationEntityId">
            <Form.Label>
              <FormattedMessage id="entities.destinationEntity" defaultMessage="Destination Entity" />
            </Form.Label>
            <Dropdown
              id="formDestinationEntityId"
              dataArray={entityNameOptions()}
              controlData={{
                placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
              }}
              name="DestinationEntityId"
              onDropdownChange={e => onDropdownChange(e, 'DestinationEntityId')}
              selectedOption={entityNameOptions().filter(option => option.value === entity.SelectedEntity.DestinationEntityId)}
            />
            {localformErrors && localformErrors.DestinationEntityId && (
              <p role="alert" className={s.error}>
                {localformErrors.DestinationEntityId}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

const EntityAbstraction = ({ entity, localformErrors, onChange, onDropdownChange, generalData }) => {
  const purposeOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === ENTITY_PURPOSE);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  return (
    <div>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formVolumeExtracted">
            <Form.Label>
              <FormattedMessage id="entities.volumeExtracted" defaultMessage="Volume Extracted" />
            </Form.Label>

            <InputNumber
              name="VolumeExtracted"
              step="0.1"
              min="0"
              onInputChange={onChange}
              customClassName={s.smallText}
              value={entity.SelectedEntity.VolumeExtracted?.toString()}
              id="volumeExtractedNumber"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formGroupPurpose">
            <Form.Label>
              <FormattedMessage id="entities.purpose" defaultMessage="Purpose" />
            </Form.Label>

            <Dropdown
              id="formPurpose"
              dataArray={purposeOptions()}
              controlData={{
                placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
              }}
              name="AbstractionPurpose"
              onDropdownChange={e => onDropdownChange(e, 'AbstractionPurpose')}
              selectedOption={purposeOptions().filter(option => option.value === entity.SelectedEntity.AbstractionPurpose)}
            />
            {localformErrors && localformErrors.AbstractionPurpose && (
              <p role="alert" className={s.error}>
                {localformErrors.AbstractionPurpose}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

const EntityTreatment = ({ entity, localformErrors, onDateChange, onDropdownChange, generalData, getFormattedate }) => {
  const treatmentTypeOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === TREATMENT_TYPE);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  return (
    <div>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formGroupDateofInstallation">
            <Form.Label>
              <FormattedMessage id="entities.dateofInstallation" defaultMessage="Date of Installation" />
            </Form.Label>
            <DateTimePicker
              className={`${localformErrors.DateofInstallation ? s.formControlError : s.frmCalendar}`}
              onChange={m => onDateChange(m, 'DateofInstallation')}
              timeFormat={null}
              dateFormat="DD-MMM-YYYY"
              value={getFormattedate(entity.SelectedEntity.DateofInstallation)}
              defaultValue={getFormattedate(entity.SelectedEntity.DateofInstallation)}
              closeOnSelect={true}
              showClear={false}
            />
            {localformErrors && localformErrors.DateofInstallation && (
              <p role="alert" className={s.error}>
                {localformErrors.DateofInstallation}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <Form.Group controlId="formGroupTreatmentType">
            <Form.Label>
              <FormattedMessage id="entities.treatmentType" defaultMessage="Treatment Type" />
            </Form.Label>

            <Dropdown
              id="formTreatmentType"
              dataArray={treatmentTypeOptions()}
              controlData={{
                placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
              }}
              name="TreatmentType"
              onDropdownChange={e => onDropdownChange(e, 'TreatmentType')}
              selectedOption={treatmentTypeOptions().filter(option => option.value === entity.SelectedEntity.TreatmentType)}
            />
            {localformErrors && localformErrors.TreatmentType && (
              <p role="alert" className={s.error}>
                {localformErrors.TreatmentType}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};
