import React from 'react';
import PropTypes from 'prop-types';
import MapContext from './MapContext';
import customStyles from './MapStyle.js';

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.googleMapRef = React.createRef();
    this.state = {
      showFullScreenIcon: true,
      isMapLoaded: false,
      isMarkerClusterLoaded: false
    };
  }

  componentDidMount() {
    this.setupComponent();
  }

  setupComponent = () => {
    if (!window.google) {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.props.apiKey}`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener('load', this.initMap);

      if (document.addEventListener) {
        document.addEventListener('webkitfullscreenchange', this.exitHandler, false);
        document.addEventListener('mozfullscreenchange', this.exitHandler, false);
        document.addEventListener('fullscreenchange', this.exitHandler, false);
        document.addEventListener('MSFullscreenChange', this.exitHandler, false);
      }
    } else {
      this.initMap();
    }

    if (!window.MarkerClusterer) {
      const googleMarkerScript = document.createElement('script');
      googleMarkerScript.src = `https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js`;
      window.document.body.appendChild(googleMarkerScript);
      googleMarkerScript.addEventListener('load', this.onMarkerClusterLoad);
    } else {
      this.onMarkerClusterLoad();
    }
  };

  exitHandler = () => {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.onFullscreenExit();
    }
  };

  onMarkerClusterLoad = () => {
    if (this.props.onMarkerClusterLoad) {
      this.props.onMarkerClusterLoad(window.MarkerClusterer);
    }
  };

  initMap = () => {
    this.map = this.createGoogleMap();

    this.map.set('styles', customStyles);

    if (this.props.onMapClick) {
      window.google.maps.event.addListener(this.map, 'click', () => {
        this.props.onMapClick(this.map);
      });
    }

    if (this.props.onMapLoad) {
      this.props.onMapLoad(this.map);
    }
  };

  fullScreen = id => {
    this.setState({
      showFullScreenIcon: false
    });
    if (this.props.onFullScreenButtonClick) {
      let mapElement = document.getElementById(id);
      this.props.onFullScreenButtonClick(mapElement);
    }
  };

  onFullscreenExit = () => {
    this.setState({
      showFullScreenIcon: true
    });
    if (this.props.onFullScreenExit) {
      this.props.onFullScreenExit();
    }
  };

  createGoogleMap = () => {
    let mapOptions = {
      ...this.props.mapOptions,
      zoom: 16,
      disableDefaultUI: true,
      scaleControl: true,
      zoomControl: window.innerWidth > 768,
      scrollwheel: true,
      scrollWheelZoom: 'center',
      mapTypeId: window.google.maps.MapTypeId.ROADMAP
      // fullscreenControl: (window.innerWidth < 769)
    };

    return new window.google.maps.Map(this.googleMapRef.current, mapOptions);
  };

  render() {
    let mapContainerCssStyle = {
      width: '100%',
      height: '80vh'
    };

    let fullScreenIconStyle = {
      marginLeft: '-20px',
      marginTop: '-50px',
      position: 'relative',
      marginBottom: '50px',
      zIndex: 100000,
      color: '#085488',
      img: {
        height: '15px',
        width: '15px',
        marginRight: '10px'
      }
    };

    return (
      <div id="mapContainer">
        <div
          id="google-map"
          ref={this.googleMapRef}
          className={this.state.showFullScreenIcon ? this.props.mapContainerCssStyle : this.props.mapContainerStyleFullScreen}
          style={mapContainerCssStyle}
        >
          <MapContext.Provider value={this.map}>{this.props.children}</MapContext.Provider>
        </div>
        {this.props.fullScreenIcon && this.state.showFullScreenIcon && (
          <div
            className={'d-md-none'}
            id="iconFullscreen"
            style={fullScreenIconStyle}
            onClick={() => {
              this.fullScreen('mapContainer');
            }}
          >
            <img src={this.props.fullScreenIcon} style={fullScreenIconStyle.img} alt="full screen" />
            <span>Click here to expand</span>
          </div>
        )}
      </div>
    );
  }
}

Map.defaultProps = {
  customStyles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    }
  ]
};

Map.propTypes = {
  customStyles: PropTypes.array,
  mapContainerCssStyle: PropTypes.string,
  mapOptions: PropTypes.object,
  apiKey: PropTypes.string.isRequired,
  onMapClick: PropTypes.func,
  onMapLoad: PropTypes.func
};

export default Map;
