import Cookies from 'js-cookie';
import {
  GET_ALL_UNITS,
  GET_ALL_UNITS_ERROR,
  UNIT_LOADING,
  SET_UNITS_CURRENT_PAGE,
  SET_UNITS_PAGE_FILTER,
  DESCRIBE_UNIT,
  SET_UNIT_SEARCH_RESULT,
  RESET_UNIT_MESSAGE,
  GET_ALL_UNIT_FILTERS,
  SET_UNIT_FILTERED_RESULTS
} from '../../constants/index';
import { apiAction, logEntry } from '../../utils/index';
import { UNIT_MANAGEMENT } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setUnitsError = value => ({
  type: GET_ALL_UNITS_ERROR,
  payload: { value }
});

export const getUnitsThunk = (limit, filter, orgId, widgetCode, profileId, region) => dispatch => {
  dispatch(getUnits(limit, filter, orgId, widgetCode, profileId, region));
};

export const getUnits = (limit, filter, orgId, widgetCode, profileId, region) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/unit`,
    data: {
      filter: filter,
      region: region,
      limit: limit,
      organisationId: orgId,
      profileId: profileId
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setUnitsThunk(retData),
    onFailure: error => setUnitErrorThunk(error, false),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setUnitsThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let units = (retData && retData.unit && retData.unit.units) || [];
  dispatch(setUnits(messageCode, units));
};

export const setUnits = (messageCode, units) => ({
  type: GET_ALL_UNITS,
  payload: {
    unit: {
      units: units,
      filteredResults: units,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setUnitsLoadingStatus = status => ({
  type: UNIT_LOADING,
  payload: { status }
});

export const setCurrentPage = currentPage => ({
  type: SET_UNITS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_UNITS_PAGE_FILTER,
  payload: { filter }
});

//Unit Management
export const setUnitErrorThunk = (err, isDescribedUnitFailed = false) => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setUnitError(messageCode, true, isDescribedUnitFailed));
  setTimeout(() => {
    //Hide the banner
    dispatch(setUnitError(messageCode, false, isDescribedUnitFailed));
  }, 5000);
};

export const setUnitError = (messageCode, status, isDescribedUnitFailed) => {
  return {
    type: GET_ALL_UNITS_ERROR,
    payload: {
      unit: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || '',
        isDataRendered: isDescribedUnitFailed
      }
    }
  };
};

//Report fetch
export const initSearchThunk = query => dispatch => {
  dispatch(initSearch(query));
};

export const initSearch = query =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/reports/unit`,
    data: {
      query
    },
    method: 'POST',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setSearchReportThunk(retData),
    onFailure: err => setUnitErrorThunk(err, false),
    accessToken: Cookies.get(`access-${envName}`)
  });

export const setSearchReportThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let searchResults = (retData && retData.unitReport) || {};
  dispatch(setSearchReport(messageCode, searchResults));
};

export const setSearchReport = (messageCode, searchResults) => ({
  type: SET_UNIT_SEARCH_RESULT,
  payload: {
    search: {
      results: searchResults,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setDefaultUnitMessage = () => ({
  type: RESET_UNIT_MESSAGE,
  payload: {
    message: ''
  }
});

//Unit Listing
export const getUnitsFilterThunk = widgetCode => dispatch => {
  dispatch(getUnitsFilter(widgetCode));
};

export const getUnitsFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fUnitRegion: true,
      filterFor: 'unitlisting'
    },
    method: 'GET',
    onLoad: status => setUnitsLoadingStatus(status),
    onSuccess: retData => setAllUnitsFilterThunk(retData),
    onFailure: err => setUnitErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllUnitsFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;

  dispatch(setAllUnitsFilter(messageCode, filterData, itemCount));
};

export const setAllUnitsFilter = (messageCode, eventActionFilter, itemCount) => ({
  type: GET_ALL_UNIT_FILTERS,
  payload: {
    data: {
      filterData: eventActionFilter,
      unitListCount: itemCount,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setUnitsFilteredDataThunk = retData => dispatch => {
  dispatch(setUnitsFilteredData(retData));
};

export const setUnitsFilteredData = retData => ({
  type: SET_UNIT_FILTERED_RESULTS,
  payload: retData
});
