import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './HazardEvent.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialHazardEventState, initialHazardEventLibraryState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { compareValues, goBack } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import HazardEventItemTable from './HazardEventItemTable';
import RiskAssessmentItemTable from './RiskAssessmentItemTable';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import 'react-datetime/css/react-datetime.css';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import moment from 'moment-timezone';
import {
  getUserProfileWidget,
  getGeneralData,
  getHazardEventLibrary,
  getHazardEvent,
  getHazard,
  getCurrentTimezone
} from '../../selectors/index';
import { describeHazardEventThunk, saveHazardEventThunk, setHazardEventChangeThunk } from '../../store/actions/hazardEvent/hazardEvent';
import {
  describeHazardEventTemplateThunk,
  getHazardEventTemplateThunk,
  setHazardEventTemplateChangeThunk
} from '../../store/actions/hazardEventLibrary';
import { getHazardThunk } from '../../store/actions/hazards';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import {
  HAZARD_CLASS,
  HAZARD_EVENT_CERTAINTY,
  HAZARD_EVENT_CATEGORY,
  RISK_STATUS,
  FREQUENCY,
  GENERAL_STATUS,
  DOCUMENT_TYPE_HAZARD_EVENT
} from '../../constants/index';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';
import GoBack from '../WSAControls/GoBack/GoBack';
import IncidentReportItemsTable from '../IncidentReport/IncidentReportItemsTable';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const HazardEvent = ({ intl, location }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  const history = useHistory();
  let { hazardEventId, siteId, segmentName, entityId, selectedTab } = useParams();
  let defaultReturnUrl = '/hazard-events';
  if (segmentName) {
    defaultReturnUrl = `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`;
  }

  const [localformErrors, setFormErrors] = useState({});
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [disableMode, setDisableMode] = useState(false);

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const hazardEvent = useSelector(state => getHazardEvent(state));
  const hazardEventLib = useSelector(state => getHazardEventLibrary(state));
  const hazard = useSelector(state => getHazard(state));
  const generalData = useSelector(state => getGeneralData(state));
  const riskStatuses = generalData.generalFieldTypes.filter(item => item.FieldType === RISK_STATUS);
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENT_UPDATE');
  const isItemListingAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENTITEM_LIST');
  const isRiskAssessmentListAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKASSESSMENT_LIST');

  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  const hazardClasses = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_CLASS);

  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const entityObj = generalData.entities.find(x => x.EntityId.toString() === entityId) || null;
  const gpsLatitude = (entityObj && entityObj.GpsLatitude) || null;
  const gpsLongitude = (entityObj && entityObj.GpsLongitude) || null;

  if (hazardEventId && hazardEventId > 0) {
    IsEdit = true;
  } else {
    hazardEventId = 0;
  }

  let waterSupplyId = null;
  let entityTypeId = null;
  let disableEntity = false;
  let disableSite = false;

  if (siteId) {
    disableSite = true;
  }

  if (entityId) {
    let selectedEntity = generalData.entities.find(x => x.EntityId == entityId) || {};
    if (Object.keys(selectedEntity).length > 0) {
      waterSupplyId = selectedEntity.WaterSupplyDescriptionId || null;
      entityTypeId = selectedEntity.EntityTypeId || null;
      disableEntity = true;
    }
  }

  let widgetCode = IsEdit ? 'WQSP_HAZARDEVENT_UPDATE' : 'WQSP_HAZARDEVENT_ADD';

  useEffect(() => {
    dispatch(getHazardThunk('WQSP_HAZARDS_LIST', {}));
  }, [getHazardThunk]);

  useEffect(() => {
    if (IsEdit) dispatch(describeHazardEventThunk(hazardEventId, widgetCode));
  }, [describeHazardEventThunk, hazardEventId]);

  useEffect(() => {
    if (!IsEdit) {
      dispatch(getHazardEventTemplateThunk(widgetCode));
    }
  }, [getHazardEventTemplateThunk]);

  useEffect(() => {
    if (!IsEdit) {
      if (hazardEvent.selectedHazardEvent.HazardEventLibraryId > 0) {
        dispatch(describeHazardEventTemplateThunk(hazardEvent.selectedHazardEvent.HazardEventLibraryId, widgetCode));
      } else {
        setLocalHazardEventTemplate({
          SelectedHazardEventTemplate: {
            ...initialHazardEventLibraryState.hazardEventTemplate.SelectedHazardEventTemplate,
            SelectedHazardEventTemplateItems: []
          }
        });
      }
    }
  }, [describeHazardEventTemplateThunk, hazardEvent.selectedHazardEvent.HazardEventLibraryId]);

  useEffect(() => {
    if (!IsEdit) {
      copyHazardEventTemplate();
    }
  }, [describeHazardEventTemplateThunk, hazardEventLib.SelectedHazardEventTemplate.HazardEventLibraryId]);

  useEffect(() => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        SiteId: (siteId && parseInt(siteId)) || 0,
        WaterSupplyDescriptionId: (waterSupplyId && parseInt(waterSupplyId)) || 0,
        EntityTypeId: (entityTypeId && parseInt(entityTypeId)) || 0,
        EntityId: (entityId && parseInt(entityId)) || 0,
        GpsLatitude: gpsLatitude,
        GpsLongitude: gpsLongitude
      }
    });
  }, [siteId, waterSupplyId, entityTypeId, entityId, gpsLatitude, gpsLongitude]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setHazardEventChangeThunk({
          ...initialHazardEventState.hazardEvent
        })
      );
    };
  }, []);

  const copyHazardEventTemplate = () => {
    const template = hazardEventLib.SelectedHazardEventTemplate;
    const hazardTemplateItems = template.SelectedHazardEventTemplateItems.map(item => {
      let objHazard = hazard.Hazards.find(h => h.HazardId === item.HazardId);
      return {
        HazardId: item.HazardId,
        HazardName: item.Name,
        Description: item.Description,
        HazardClassId: (objHazard && objHazard.HazardClassId) || 0,
        HazardEventItemId: 0,
        GuidIdentifier: uuidv4(),
        IsActive: true,
        IsDeleted: false
      };
    });
    setLocalHazardEvent({
      ...hazardEvent,
      selectedHazardEvent: {
        Name: template.Name || '',
        Description: template.Description || '',
        Basis: template.Basis || '',
        HazardClass: template.HazardClass || '',
        hazardEventItems: hazardTemplateItems
      }
    });
  };

  // Update redux store
  const setLocalHazardEvent = currentState => {
    dispatch(setHazardEventChangeThunk(currentState));
  };

  const setLocalHazardEventTemplate = currentState => {
    dispatch(setHazardEventTemplateChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = {
      ...hazardEvent.selectedHazardEvent,
      hazardEventItems: hazardEvent.selectedHazardEvent.hazardEventItems || [],
      selectedHazardEventItem: {},
      comments: []
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.HazardEventId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    dispatch(saveHazardEventThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!hazardEvent.selectedHazardEvent || !hazardEvent.selectedHazardEvent.SiteId) {
      formErrors.Site = intl.formatMessage({
        id: 'wqsp.common.siteMandatory',
        defaultMessage: 'Site name is mandatory field'
      });
      isValid = false;
    }

    if (!hazardEvent.selectedHazardEvent || !hazardEvent.selectedHazardEvent.WaterSupplyDescriptionId) {
      formErrors.WaterSupply = intl.formatMessage({
        id: 'wqsp.common.waterSupplyMandatory',
        defaultMessage: 'Water supply name is mandatory field'
      });
      isValid = false;
    }

    if (!hazardEvent.selectedHazardEvent || !hazardEvent.selectedHazardEvent.EntityTypeId) {
      formErrors.EntityType = intl.formatMessage({
        id: 'wqsp.common.entityTypeMandatory',
        defaultMessage: 'Entity type is mandatory field'
      });
      isValid = false;
    }

    if (!hazardEvent.selectedHazardEvent || !hazardEvent.selectedHazardEvent.EntityId) {
      formErrors.Entity = intl.formatMessage({
        id: 'wqsp.common.entityMandatory',
        defaultMessage: 'Entity is mandatory field'
      });
      isValid = false;
    }

    if (!hazardEvent.selectedHazardEvent || !hazardEvent.selectedHazardEvent.HazardCertaintyId) {
      formErrors.HazardCertainty = intl.formatMessage({
        id: 'hazardEvent.hazardCertaintyMandatory',
        defaultMessage: 'Hazard certainty is mandatory field'
      });
      isValid = false;
    }

    if (!hazardEvent.selectedHazardEvent || !hazardEvent.selectedHazardEvent.Name) {
      formErrors.Name = intl.formatMessage({
        id: 'hazardEvent.nameMandatory',
        defaultMessage: 'Name is mandatory field'
      });
      isValid = false;
    }

    if (!hazardEvent.selectedHazardEvent || !hazardEvent.selectedHazardEvent.DateIdentified) {
      formErrors.DateIdentified = intl.formatMessage({
        id: 'hazardEvent.dateIdentifiedMandatory',
        defaultMessage: 'Date Identified is mandatory field'
      });
      isValid = false;
    }

    if (!hazardEvent.selectedHazardEvent || !hazardEvent.selectedHazardEvent.HazardClass) {
      formErrors.HazardClass = intl.formatMessage({
        id: 'wqsp.common.hazardClassMandatory',
        defaultMessage: 'Hazard Class is mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalHazardEvent({
        ...hazardEvent,
        selectedHazardEvent: { ...hazardEvent.selectedHazardEvent, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalHazardEvent({
        ...hazardEvent,
        selectedHazardEvent: { ...hazardEvent.selectedHazardEvent, [e.target.name]: e.target.value }
      });
    }
  };

  const onAddEventItem = eventItem => {
    let hazardEventItems =
      (hazardEvent.selectedHazardEvent.hazardEventItems && [...hazardEvent.selectedHazardEvent.hazardEventItems]) || [];

    eventItem.GuidIdentifier = uuidv4();
    eventItem.HazardEventItemId = 0;
    eventItem.IsActive = true;
    eventItem.IsDeleted = false;
    hazardEventItems.push(eventItem);

    setShowAddEdit(false);
    dispatch(
      setHazardEventChangeThunk({
        selectedHazardEvent: {
          hazardEventItems: hazardEventItems
        }
      })
    );
  };

  const onChangeEventItem = hazardEventItems => {
    dispatch(
      setHazardEventChangeThunk({
        selectedHazardEvent: {
          hazardEventItems: hazardEventItems
        }
      })
    );
  };

  const siteOptions = () => {
    let siteData = [];
    generalData.sites.forEach(element => {
      siteData.push({
        ...element,
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onSiteDropdownChange = e => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        SiteId: e.value,
        WaterSupplyDescriptionId: 0,
        EntityId: 0
      }
    });
  };

  const waterSupplyOptions = () => {
    let waterSupplyData = [];
    const filteredData = generalData.waterSupplyDescriptions.filter(item => item.SiteId === hazardEvent.selectedHazardEvent.SiteId);
    filteredData.forEach(element => {
      waterSupplyData.push({
        ...element,
        label: element.Name,
        value: element.WaterSupplyDescriptionId
      });
    });

    let sortedData = waterSupplyData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onWaterSupplyDropdownChange = e => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        WaterSupplyDescriptionId: e.value
      }
    });
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];
    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityTypeDropdownChange = e => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        EntityTypeId: e.value,
        EntityId: 0,
        HazardEventLibraryId: 0
      }
    });
  };

  const entityOptions = () => {
    let entityData = [];
    let entitySubTypes = generalData.entitySubTypes.filter(item => item.EntityTypeId === hazardEvent.selectedHazardEvent.EntityTypeId);
    let entities = generalData.entities.filter(item =>
      entitySubTypes.find(
        type =>
          type.EntitySubTypeId === item.EntitySubTypeId &&
          item.WaterSupplyDescriptionId === hazardEvent.selectedHazardEvent.WaterSupplyDescriptionId
      )
    );
    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId,
        latValue: element.GpsLatitude || null,
        longValue: element.GpsLongitude || null
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityDropdownChange = e => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        EntityId: e.value,
        GpsLatitude: e.latValue,
        GpsLongitude: e.longValue
      }
    });
  };

  const certaintyOptions = () => {
    let certaintyData = [];
    const filteredData = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_EVENT_CERTAINTY);
    filteredData.forEach(element => {
      certaintyData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = certaintyData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const hazardCategoryOptions = () => {
    let hazardCategoryData = [];
    const filteredData = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_EVENT_CATEGORY);
    filteredData.forEach(element => {
      hazardCategoryData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = hazardCategoryData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onCertaintyDropdownChange = e => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        HazardCertaintyId: e.value
      }
    });
  };

  const onHazardCategoryDropdownChange = e => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        HazardCategoryId: e.value
      }
    });
  };

  const templateOptions = () => {
    let templateData = [];
    const filteredData = hazardEventLib.HazardEventTemplates.filter(
      item => item.EntityTypeId === hazardEvent.selectedHazardEvent.EntityTypeId
    );
    filteredData.forEach(element => {
      templateData.push({
        ...element,
        label: element.Name,
        value: element.HazardEventLibraryId
      });
    });

    let sortedData = templateData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onTemplateDropdownChange = e => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        HazardEventLibraryId: e.value
      }
    });
  };

  const hazardClassOptions = () => {
    let hazardClassData = [];
    hazardClasses.forEach(element => {
      let disable = hazardEvent.selectedHazardEvent.hazardEventItems.find(
        item => !item.IsDeleted && item.HazardClassId === element.GeneralFieldTypeId
      )
        ? true
        : false;
      let isChecked = hazardEvent.selectedHazardEvent.HazardClass.split(',').find(e => e === element.GeneralFieldTypeId.toString())
        ? true
        : false;
      if (disable && !isChecked) {
        disable = false;
      }
      hazardClassData.push({
        ...element,
        SKEY: element.GeneralFieldTypeId,
        target: { type: 'checkbox' },
        label: element.Name,
        disable: disable,
        isChecked: isChecked
      });
    });

    return hazardClassData.sort(compareValues('label'));
  };

  const onHazardClassChange = e => {
    if (e.target && e.target.name) {
      let hazardEventClass = hazardEvent.selectedHazardEvent.HazardClass;
      if (e.target.checked) {
        hazardEventClass = (hazardEventClass && `${hazardEventClass},${e.target.name}`) || e.target.name;
      } else {
        if (hazardEventClass) {
          hazardEventClass = hazardEventClass
            .split(',')
            .filter(elem => elem !== e.target.name)
            .join(',');
        }
      }
      setLocalHazardEvent({
        selectedHazardEvent: {
          ...hazardEvent.selectedHazardEvent,
          HazardClass: hazardEventClass
        }
      });
    }
  };

  const getHazards = () => {
    let hazardEventClass = hazardEvent.selectedHazardEvent.HazardClass.split(',');
    return hazard.Hazards.filter(item => hazardEventClass.find(e => e === item.HazardClassId.toString()));
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  //FILE UPLOAD
  const onUploadStart = files => {
    // setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    //setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          SiteId: 0,
          newFile: true,
          S3URL: item.S3URL,
          ImageId: item.fileGuid,
          Name: item.name
        };
      });
      let allFiles = [...hazardEvent.selectedHazardEvent.Files, ...newFiles];

      setLocalHazardEvent({
        selectedHazardEvent: {
          ...hazardEvent.selectedHazardEvent,
          Files: allFiles
        }
      });
    }
  };

  const removeFile = fileGuid => {
    let files = hazardEvent.selectedHazardEvent.Files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...hazardEvent.selectedHazardEvent.deletedFileIds];
    deletedFileIds.push(fileGuid);
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        Files: files,
        deletedFileIds: deletedFileIds
      }
    });
  };
  //END FILE UPLOAD

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };
  const onStatusChange = e => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        [e.target.name]: e.target.value,
        StatusId: null
      }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalHazardEvent({
      selectedHazardEvent: {
        ...hazardEvent.selectedHazardEvent,
        [fieldName]: e.value
      }
    });
  };

  let messageId = (hazardEvent && hazardEvent.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  if (IsEdit) {
    heading = intl.formatMessage({ id: 'hazardEvent.editHazardEvent', defaultMessage: 'Edit' });
    heading = `${heading} - ${hazardEvent.selectedHazardEvent.Name}`;
  } else {
    heading = intl.formatMessage({ id: 'hazardEvent.addNewHazardEvent', defaultMessage: 'Add new Hazard Event' });
  }

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="hazardEvent.hazardEventView" defaultMessage="Hazard Event View" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    {
      orderNo: 4,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  const identifyHazardCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEventView" defaultMessage="Hazard Event View" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    {
      orderNo: 3,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];
  let breadcrumb = [];

  if (segmentName === 'define') {
    breadcrumb = defineEntityCrumb;
  } else {
    breadcrumb = identifyHazardCrumb;
  }

  if (hazardEvent.isRedirect) {
    goBack(history, defaultReturnUrl);
  }

  return (
    <div className={s.hazardevent}>
      {(hazardEvent.isLoading || hazardEventLib.isLoading) && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={hazardEvent.showBanner}
        status={hazardEvent.isOpSuccessful}
        successText={messageText}
      />
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {segmentName ? (
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        ) : (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />}</h1>
          </div>
        )}

        <div className={s.contentWrapper}>
          {!segmentName && (
            <div className={s.testresultHeader}>
              <GoBack className={s.backLink}>
                &lt; &nbsp;
                <FormattedMessage id="common.back" defaultMessage="BACK" />
              </GoBack>
              <h3 data-unittest="headingLabel">{heading}</h3>
            </div>
          )}
          <div className={s.hazardeventContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formHazardEventSiteName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlSite"
                      dataArray={siteOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Site && s.ddlError }}
                      onDropdownChange={onSiteDropdownChange}
                      data-unittest="formDdlSite"
                      selectedOption={siteOptions().filter(option => option.value === hazardEvent.selectedHazardEvent.SiteId)}
                      disabled={IsEdit || disableSite}
                    />
                    {localformErrors && localformErrors.Site && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Site}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formHazardEventSupplyName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlWaterSupply"
                      dataArray={waterSupplyOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.WaterSupply && s.ddlError }}
                      onDropdownChange={onWaterSupplyDropdownChange}
                      data-unittest="formDdlWaterSupply"
                      selectedOption={waterSupplyOptions().filter(
                        option => option.value === hazardEvent.selectedHazardEvent.WaterSupplyDescriptionId
                      )}
                      disabled={IsEdit || disableEntity}
                    />
                    {localformErrors && localformErrors.WaterSupply && (
                      <p role="alert" className={s.error}>
                        {localformErrors.WaterSupply}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formHazardEventEntityType">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntityType"
                      dataArray={entityTypeOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.EntityType && s.ddlError }}
                      onDropdownChange={onEntityTypeDropdownChange}
                      data-unittest="formDdlEntityType"
                      selectedOption={entityTypeOptions().filter(option => option.value === hazardEvent.selectedHazardEvent.EntityTypeId)}
                      disabled={disableEntity}
                    />
                    {localformErrors && localformErrors.EntityType && (
                      <p role="alert" className={s.error}>
                        {localformErrors.EntityType}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formHazardEventTemplate">
                    <Form.Label>
                      <FormattedMessage id="hazardEvent.hazardEventTemplate" defaultMessage="Hazard Event Template" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlHazardEventTemplate"
                      dataArray={templateOptions()}
                      controlData={{ placeholderText: ddlInitText }}
                      onDropdownChange={onTemplateDropdownChange}
                      data-unittest="formDdlTemplate"
                      selectedOption={templateOptions().filter(
                        option => option.HazardEventLibraryId === hazardEvent.selectedHazardEvent.HazardEventLibraryId
                      )}
                      disabled={IsEdit}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formHazardEventEntity">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntity"
                      dataArray={entityOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Entity && s.ddlError }}
                      onDropdownChange={onEntityDropdownChange}
                      data-unittest="formDdlEntity"
                      selectedOption={entityOptions().filter(option => option.value === hazardEvent.selectedHazardEvent.EntityId)}
                      disabled={disableEntity}
                    />
                    {localformErrors && localformErrors.Entity && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Entity}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formHazardEventCertainty">
                    <Form.Label>
                      <FormattedMessage id="hazardEvent.hazardCertainty" defaultMessage="Hazard Certainty" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlCertainty"
                      dataArray={certaintyOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.HazardCertainty && s.ddlError }}
                      onDropdownChange={onCertaintyDropdownChange}
                      data-unittest="formDdlEntity"
                      selectedOption={certaintyOptions().filter(
                        option => option.value === hazardEvent.selectedHazardEvent.HazardCertaintyId
                      )}
                    />
                    {localformErrors && localformErrors.HazardCertainty && (
                      <p role="alert" className={s.error}>
                        {localformErrors.HazardCertainty}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formHazardEventName">
                    <Form.Label>
                      <FormattedMessage id="hazardEvent.name" defaultMessage="Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={hazardEvent.selectedHazardEvent.Name}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'hazardEvent.name',
                        defaultMessage: 'Name'
                      })}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formHazardEventDescription">
                    <Form.Label>
                      <FormattedMessage id="common.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      className={s.textArea}
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'common.description',
                        defaultMessage: 'Description'
                      })}
                      value={hazardEvent.selectedHazardEvent.Description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formHazardEventSource">
                    <Form.Label>
                      <FormattedMessage id="hazardEvent.hazardCategory" defaultMessage="Hazard Category" />
                    </Form.Label>
                    <Dropdown
                      id="formDdlHazardCategory"
                      dataArray={hazardCategoryOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.HazardCategory && s.ddlError }}
                      onDropdownChange={onHazardCategoryDropdownChange}
                      selectedOption={hazardCategoryOptions().filter(
                        option => option.value === hazardEvent.selectedHazardEvent.HazardCategoryId
                      )}
                    />
                    {localformErrors && localformErrors.HazardCategory && (
                      <p role="alert" className={s.error}>
                        {localformErrors.HazardCategory}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formHazardEventDateIdentified">
                    <Form.Label>
                      <FormattedMessage id="hazardEvent.dateIdentified" defaultMessage="Date Identified" />
                    </Form.Label>

                    <DateTimePicker
                      className={`${localformErrors.DateIdentified ? s.formControlError : s.frmCalendar}`}
                      onChange={m => {
                        onChange({ target: { name: 'DateIdentified', value: (m && m.unix && m.unix()) || null } });
                      }}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(hazardEvent.selectedHazardEvent.DateIdentified)}
                      defaultValue={getFormattedate(hazardEvent.selectedHazardEvent.DateIdentified)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                    {localformErrors && localformErrors.DateIdentified && (
                      <p role="alert" className={s.error}>
                        {localformErrors.DateIdentified}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={4}>
                  <Form.Group controlId="formHazardEventLatitude">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.gpsLatitude" defaultMessage="GPS Latitude" />
                    </Form.Label>
                    <InputNumber
                      name="GpsLatitude"
                      step=".01"
                      onInputChange={onChange}
                      customClassName={`${s.smallText}`}
                      value={hazardEvent.selectedHazardEvent.GpsLatitude?.toString()}
                      hideStepArrow={true}
                      placeholder={intl.formatMessage({
                        id: 'wqsp.common.gpsLatitude',
                        defaultMessage: 'GPS Latitude'
                      })}
                      id="GpsLatitude"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group controlId="formHazardEventLongitude">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.gpsLongitude" defaultMessage="GPS Longitude" />
                    </Form.Label>
                    <InputNumber
                      name="GpsLongitude"
                      step=".01"
                      onInputChange={onChange}
                      customClassName={`${s.smallText}`}
                      value={hazardEvent.selectedHazardEvent.GpsLongitude?.toString()}
                      hideStepArrow={true}
                      placeholder={intl.formatMessage({
                        id: 'wqsp.common.gpsLongitude',
                        defaultMessage: 'GPS Longitude'
                      })}
                      id="GpsLongitude"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formHazardEventBasis">
                    <Form.Label>
                      <FormattedMessage id="hazardEvent.basis" defaultMessage="Basis" />
                    </Form.Label>

                    <Form.Control
                      className={s.textArea}
                      as="textarea"
                      rows="3"
                      name="Basis"
                      onChange={onChange}
                      value={hazardEvent.selectedHazardEvent.Basis}
                      placeholder={intl.formatMessage({
                        id: 'hazardEvent.basis',
                        defaultMessage: 'Basis'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formHazardClass">
                    <Form.Label>
                      <FormattedMessage id="hazardEvent.hazardClass" defaultMessage="Hazard Class" />
                    </Form.Label>
                    <Checkbox key={uuidv4()} dataArray={hazardClassOptions()} onSelectionChange={onHazardClassChange} />
                    {localformErrors && localformErrors.HazardClass && (
                      <p role="alert" className={s.error}>
                        {localformErrors.HazardClass}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formHazardEventLastReviewDate">
                    <Form.Label>
                      <FormattedMessage id="common.lastReviewDate" defaultMessage="Date Of Last Review" />
                    </Form.Label>

                    <DateTimePicker
                      className={`${s.frmCalendar}`}
                      onChange={m => {
                        onChange({ target: { name: 'LastReviewDate', value: (m && m.unix && m.unix()) || null } });
                      }}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(hazardEvent.selectedHazardEvent.LastReviewDate)}
                      defaultValue={getFormattedate(hazardEvent.selectedHazardEvent.LastReviewDate)}
                      closeOnSelect={true}
                      showClear={false}
                      placeHolderText={intl.formatMessage({
                        id: 'common.lastReviewDate',
                        defaultMessage: 'Date Of Last Review'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formNextReviewDate">
                    <Form.Label>
                      <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                    </Form.Label>
                    <DateTimePicker
                      className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                      onChange={m => {
                        onChange({ target: { name: 'NextReviewDate', value: (m && m.unix && m.unix()) || null } });
                      }}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(hazardEvent.selectedHazardEvent.NextReviewDate)}
                      defaultValue={getFormattedate(hazardEvent.selectedHazardEvent.NextReviewDate)}
                      closeOnSelect={true}
                      showClear={false}
                      placeHolderText={intl.formatMessage({
                        id: 'common.nextReviewDate',
                        defaultMessage: 'Date Of Last Review'
                      })}
                    />
                    {localformErrors && localformErrors.NextReviewDate && (
                      <p role="alert" className={s.error}>
                        {localformErrors.NextReviewDate}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formGroupReviewFrequency">
                    <Form.Label>
                      <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                    </Form.Label>

                    <Dropdown
                      id="formMaintenanceFrequency"
                      dataArray={frequencyOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                      }}
                      name="ReviewFrequencyId"
                      onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                      selectedOption={frequencyOptions().filter(
                        option => option.value === hazardEvent.selectedHazardEvent.ReviewFrequencyId
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group controlId="formActioned">
                    <Form.Label>
                      <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                    </Form.Label>

                    <ToggleSwitch
                      handleClick={() => {
                        onStatusChange({ target: { name: 'IsActioned', value: !hazardEvent.selectedHazardEvent.IsActioned } });
                      }}
                      classname={s.switch}
                      checked={hazardEvent.selectedHazardEvent.IsActioned}
                      labelChecked={yesText}
                      labelUnchecked={noText}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {!hazardEvent.selectedHazardEvent.IsActioned && (
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formStatus">
                      <Form.Label>
                        <FormattedMessage id="common.status" defaultMessage="Status" />
                      </Form.Label>

                      <Dropdown
                        id="formStatus"
                        dataArray={statusOptions()}
                        controlData={{ placeholderText: ddlInitText }}
                        onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                        data-unittest="formDdlRiskStatus"
                        selectedOption={statusOptions().filter(option => option.value === hazardEvent.selectedHazardEvent.StatusId)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Form.Label>
                    <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                  </Form.Label>
                  <FileUploader
                    widgetCode={widgetCode}
                    allowedFileTypes={[
                      ['image/jpeg', 'jpeg'],
                      ['application/pdf', 'pdf'],
                      ['application/msword', 'doc']
                    ]}
                    location={`organisation/${orgId}/sites/${hazardEvent.selectedHazardEvent.SiteId}/hazardevents`}
                    enableMultipleUpload={true}
                    maxFiles={5}
                    onUploadComplete={onUploadComplete}
                    onUploadStart={onUploadStart}
                  />
                </Col>
              </Row>
              <FileList files={hazardEvent.selectedHazardEvent.Files} onRemoveFile={removeFile} />
            </Form>
          </div>
          <div>
            {isItemListingAllowed && (
              <HazardEventItemTable
                eventItems={hazardEvent.selectedHazardEvent.hazardEventItems}
                hazards={getHazards()}
                hazardClasses={hazardClasses}
                setShowAddEdit={setShowAddEdit}
                showAddEdit={showAddEdit}
                disableMode={disableMode}
                setDisableMode={setDisableMode}
                onAddEventItem={onAddEventItem}
                onChangeEventItem={onChangeEventItem}
                userProfileWidgets={userProfileWidgets}
                updateAllowed={isUpdateAllowed}
              />
            )}
          </div>
          <div>
            {isRiskAssessmentListAllowed && (
              <RiskAssessmentItemTable
                riskAssessmentItems={hazardEvent.selectedHazardEvent.riskAssessments}
                currentTimezone={currentTimezone}
                userProfileWidgets={userProfileWidgets}
                hazardEvent={hazardEvent.selectedHazardEvent}
                riskStatuses={riskStatuses}
              />
            )}
          </div>
          <div>
            {IsEdit && (
              <IncidentReportItemsTable
                referedId={hazardEventId}
                incidentReportItems={hazardEvent.selectedHazardEvent.incidentReports}
                docType={DOCUMENT_TYPE_HAZARD_EVENT}
                mainObj={hazardEvent.selectedHazardEvent}
                userProfileWidgets={userProfileWidgets}
              />
            )}
          </div>
          <div>
            <Row className={s.breakRow}>
              <Col sm={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                  disabled={disableMode || hazardEvent.isLoading}
                >
                  <FormattedMessage id="common.save" defaultMessage="SAVE" />
                </Button>

                <Button
                  variant="outline-secondary"
                  className={s.btnCancel}
                  disabled={disableMode}
                  onClick={() => goBack(history, defaultReturnUrl)}
                >
                  <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(HazardEvent);
