import React from 'react';
import s from './Entity.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import uuidv4 from 'uuid/v4';
import { getDateInDDMMYYYFormat } from '../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../selectors/index';
import MoreOption from '../WSAControls/MoreOption/MoreOption';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { useHistory, useParams } from 'react-router-dom';

const EntityListTable = ({ paginatedData, onSortOrder, asendingOrder, ChangeEntity, UpdateAllowed, userProfileWidgets, intl }) => {
  const history = useHistory();
  let addHazardEventAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_HAZARDEVENT_LIST`);
  let addQualityGoalsAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_QUALITYGOALSREPORT_LIST`);
  const { siteId, segmentName, selectedTab } = useParams('siteId');

  const onListHazardEventClick = entityId => {
    let selectedEntity = paginatedData.find(item => item.EntityId === entityId);
    if (selectedEntity) {
      let redirectUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`;
      history.push(redirectUrl);
    }
  };

  const onListQualityGoalClick = entityId => {
    let selectedEntity = paginatedData.find(item => item.EntityId === entityId);
    if (selectedEntity) {
      let redirectUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/quality-goals`;
      history.push(redirectUrl);
    }
  };

  const getMoreOptions = entityId => {
    let options = [];

    if (UpdateAllowed) {
      options.push({
        Name: <FormattedMessage id="common.edit" defaultMessage="EDIT" />,
        Click: () => {
          ChangeEntity(entityId);
        }
      });
    }

    if (addHazardEventAllowed) {
      options.push({
        Name: <FormattedMessage id="hazardEvent.hazardEventsUC" defaultMessage="HAZARD EVENTS" />,
        Click: () => {
          onListHazardEventClick(entityId);
        }
      });
    }

    if (addQualityGoalsAllowed) {
      options.push({
        Name: <FormattedMessage id="qualityGoals.qualityGoalsUC" defaultMessage="QUALITY GOALS" />,
        Click: () => {
          onListQualityGoalClick(entityId);
        }
      });
    }

    return options;
  };

  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="entity.entityName" defaultMessage="Entity Name" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entitySubType" defaultMessage="Entity Sub Type" />
            </th>
            <th>
              <FormattedMessage id="entities.lateOfLastService'" defaultMessage="Date Of Last Service" />
            </th>
            <th>
              <FormattedMessage id="entity.inspectionFrequency" defaultMessage="Inspection Frequency" />
            </th>

            {(UpdateAllowed || addHazardEventAllowed || addQualityGoalsAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.EntityId}>
                <td>{n.Name || ''}</td>
                <td>{n.EntityType || ''}</td>
                <td>{n.EntitySubType || ''}</td>
                <td>{getDateInDDMMYYYFormat(n.DateOfLastService, currentTimezone) || '-'}</td>
                <td>{n.InspectionFrequencyName || ''}</td>

                <td className={s.btnCol}>
                  {(UpdateAllowed || addHazardEventAllowed || addQualityGoalsAllowed) && (
                    <MoreOption key={uuidv4()} dataArray={getMoreOptions(n.EntityId)} />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

EntityListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(EntityListTable);
