import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './TasksAndChecklistsTabs.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const Tab = ({ selectedPage, pageComponent, url, title }) => (
  <Fragment>
    <Link className={classNames({ [s.active]: selectedPage === pageComponent })} to={url}>
      {title}
      {selectedPage === pageComponent && <span />}
    </Link>
  </Fragment>
);

const TasksAndChecklistsTabs = ({ selectedPage, userProfileWidgets }) => (
  <ul className={s.tabNav}>
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_TASKLIST') && (
      <li>
        <Tab
          url={`/tasks-and-checklists/tasklist`}
          title={<FormattedMessage id="tasksAndChecklistsManagement.myTasks" defaultMessage="My Tasks" />}
          selectedPage={selectedPage}
          pageComponent="myTasks"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_CHECKLISTLIST') && (
      <li>
        <Tab
          url={`/tasks-and-checklists/checklists`}
          title={<FormattedMessage id="tasksAndChecklistsManagement.myChecklists" defaultMessage="My Checklists" />}
          selectedPage={selectedPage}
          pageComponent="myChecklists"
        />
      </li>
    )}
  </ul>
);

TasksAndChecklistsTabs.propTypes = {
  selectedPage: PropTypes.string.isRequired
};

export default TasksAndChecklistsTabs;
