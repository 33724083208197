import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';

import {
  TEST_PURPOSE_ERROR,
  GET_ALL_TEST_PURPOSE_DETAILS,
  TEST_PURPOSE_IS_LOADING,
  SET_TEST_PURPOSE_CURRENT_PAGE,
  SET_TEST_PURPOSE_PAGE_FILTER,
  SET_TEST_PURPOSE_CHANGE,
  SET_TEST_PURPOSE,
  UPDATE_TEST_PURPOSE_SAVE_STATUS
} from '../../constants/index';

import { TEST_PURPOSE_MANAGEMENT, CREATE_TEST_PURPOSE, UPDATE_TEST_PURPOSE } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setTestPurposeErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setTestPurposeError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTestPurposeError(messageCode, false));
  }, 2500);
};

export const setTestPurposeError = (messageCode, status) => {
  return {
    type: TEST_PURPOSE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: TEST_PURPOSE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getTestPurposeThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getTestPurpose(widgetCode, orgId));
};

export const getTestPurpose = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/testresult/testpurposes`,
    method: 'GET',
    data: {
      OrganisationId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllTestPurposeListThunk(retData),
    onFailure: err => setTestPurposeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllTestPurposeListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let testPurposes = (retData && retData.retData.testPurpose) || [];
  dispatch(setAllTestPurposeList(messageCode, testPurposes));
};

export const setAllTestPurposeList = (messageCode, testPurposes) => ({
  type: GET_ALL_TEST_PURPOSE_DETAILS,
  payload: {
    data: {
      TestPurposes: testPurposes,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_TEST_PURPOSE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_TEST_PURPOSE_PAGE_FILTER,
  payload: { filter }
});

export const describeTestPurposeThunk = (TestPurposeId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeTestPurpose(TestPurposeId, widgetCode, orgId));
};

export const describeTestPurpose = (TestPurposeId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/testresult/testpurposes`,
    data: {
      TestPurposeId: TestPurposeId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTestPurposeThunk(retData, TestPurposeId),
    onFailure: err => setTestPurposeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setTestPurposeThunk = ({ retData, TestPurposeId }) => dispatch => {
  let data = retData.testPurpose;
  if (
    !data ||
    !data.selectedTestPurpose ||
    !data.selectedTestPurpose.TestPurposeId ||
    (TestPurposeId && TestPurposeId !== data.selectedTestPurpose.TestPurposeId)
  ) {
    redirectToPageNotFound(dispatch);
  }

  dispatch(setTestPurpose(data));
};

export const setTestPurpose = data => ({
  type: SET_TEST_PURPOSE,
  payload: data
});

export const saveTestPurposeThunk = (testPurpose, widgetCode) => dispatch => {
  let url = '/management/testresult/testpurposes/save';
  let actionName = testPurpose.TestPurposeId === 0 ? CREATE_TEST_PURPOSE : UPDATE_TEST_PURPOSE;
  let log = logEntry(TEST_PURPOSE_MANAGEMENT, actionName, testPurpose);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  testPurpose.OrganisationId = orgId;
  dispatch(saveTestPurpose(testPurpose, url, log, widgetCode));
};

export const saveTestPurpose = (testPurpose, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTestPurposeStatusThunk(retData, testPurpose),
    onFailure: err => setTestPurposeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      testPurpose: testPurpose,
      log: log
    },
    widgetcode: widgetCode
  });

export const setTestPurposeStatusThunk = (message, testPurpose) => dispatch => {
  dispatch(setTestPurposeStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !testPurpose.PerformDelete;
    dispatch(setTestPurposeStatus(message, false, isRedirect));
  }, 2500);
};

export const setTestPurposeStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_TEST_PURPOSE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const setTestPurposeChangeThunk = retData => dispatch => {
  dispatch(setTestPurposeChange(retData));
};

export const setTestPurposeChange = retData => ({
  type: SET_TEST_PURPOSE_CHANGE,
  payload: retData
});
