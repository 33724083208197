import React, { Fragment, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ChecklistTemplate.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import SearchBar from '../SearchBar/SearchBar';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { compareValues } from '../../utils';

const TaskTemplates = ({
  intl,
  onTaskSearch,
  onTaskAdd,
  onTaskRemove,
  onAddNewOrCancelClick,
  selectedTaskTemplates,
  taskTemplates,
  isView,
  isEdit,
  checklistTemplateId
}) => {
  const [selectedTaskId, setSelectedTaskId] = useState(0);
  const [enableAddTaskTemplate, setEnableAddTaskTemplate] = useState(false);

  const taskTemplatesOptions = () => {
    let taskTemplatesData = [];
    taskTemplates.forEach(element => {
      if (selectedTaskTemplates && selectedTaskTemplates.filter(x => x.TaskTemplateId === element.TaskTemplateId).length === 1) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
      taskTemplatesData.push({
        ...element,
        label: element.Name,
        value: element.TaskTemplateId
      });
    });
    return taskTemplatesData.sort(compareValues('label'));
  };

  const onDropdownChange = e => {
    setSelectedTaskId(e.value);
  };

  const onCancelClick = () => {
    setEnableAddTaskTemplate(false);
    onAddNewOrCancelClick(false);
  };

  const onTaskAddClick = () => {};

  const onAddNewBtnClick = () => {
    setEnableAddTaskTemplate(true);
    onAddNewOrCancelClick(true);
  };

  useLayoutEffect(() => {
    if (enableAddTaskTemplate) {
      const scrollPos = window.scrollY + window.innerHeight;
      window.scrollTo(0, scrollPos);
    }
  }, [enableAddTaskTemplate]);

  const onAddBtnClick = () => {
    onTaskAdd(selectedTaskId);
    setEnableAddTaskTemplate(false);
    onAddNewOrCancelClick(false);
  };

  return (
    <div className={s.taskContainer}>
      <div className={s.subHeading}>
        <h4>
          <FormattedMessage id="checklistTemplateTasks.tasks" defaultMessage="Tasks" />
        </h4>
        <span className={s.circle}>{selectedTaskTemplates.length}</span>
      </div>
      <div>
        <Row>
          <Col xs={6} lg={4}>
            <SearchBar
              searchHandler={onTaskSearch}
              clearSearchInVisible={false}
              //initialText={initialSearchText}
              placeHolderTextId="common.search"
            />
          </Col>
          <Col xs={6} lg={8} className={s.colAddButton}>
            {!isView && (
              <Button
                disabled={enableAddTaskTemplate}
                variant="primary"
                data-unittest="addnew"
                className={s.addButton}
                onClick={() => onAddNewBtnClick()}
              >
                +<FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <div>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="common.task" defaultMessage="Task" />
              </th>
              <th>
                <FormattedMessage id="checklistTemplateTasks.role" defaultMessage="Role" />
              </th>
              <th className="d-none d-md-table-cell">
                <FormattedMessage id="checklistTemplateTasks.estimatedEffort" defaultMessage="Estimated Effort" />
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {selectedTaskTemplates.length === 0 && (
              <tr className={s.emptyRow} data-unittest="templateData">
                <td colSpan="4">
                  <FormattedMessage id="checklistTemplateTasks.noData" defaultMessage="No Data" />
                </td>
              </tr>
            )}
            {selectedTaskTemplates.map((n, index) => {
              return (
                <Fragment key={index}>
                  <tr className={s.taskRow} data-unittest="templateData">
                    <td>{n.Name}</td>
                    <td>{n.ProfileName}</td>
                    <td className="d-none d-md-table-cell">
                      {n.EstimatedEffort + ' '}
                      <FormattedMessage id="checklistTemplateTasks.hours" defaultMessage="hour(s)" />
                    </td>
                    <td className={s.colBtn}>
                      {(isEdit || isView) && (
                        <Link
                          to={`/operational-setup/task-template-detail/${n.TaskTemplateId}?checklistTemplateId=${checklistTemplateId}${
                            isView ? '&view=1' : ''
                          }`}
                        >
                          <Button
                            disabled={enableAddTaskTemplate}
                            variant="outline-secondary"
                            data-unittest="view"
                            className={s.taskViewButton}
                          >
                            <FormattedMessage id="common.view" defaultMessage="VIEW" />
                          </Button>
                        </Link>
                      )}
                      {!isView && (
                        <Button
                          disabled={enableAddTaskTemplate}
                          variant="outline-secondary"
                          data-unittest="remove"
                          onClick={() => onTaskRemove(n.TaskTemplateId)}
                          className={s.taskRemoveButton}
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
            {enableAddTaskTemplate && (
              <Fragment>
                <tr data-unittest="templateData">
                  <td colSpan="4" className={s.taskRow}>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="formTaskTemplate">
                          <Form.Label>
                            <FormattedMessage
                              id="checklistTemplateTasks.selectTaskFromTaskTemplate"
                              defaultMessage="Select task template"
                            />
                          </Form.Label>
                          <Dropdown
                            id="formTaskTemplate"
                            dataArray={taskTemplatesOptions()}
                            controlData={{ placeholderText: 'Select', customClassName: s.ddlTaskTemplate }}
                            onDropdownChange={onDropdownChange}
                            data-unittest="formTaskTemplate"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          disabled={selectedTaskId === 0}
                          variant="primary"
                          data-unittest="view"
                          onClick={() => onAddBtnClick()}
                          className={s.taskButton}
                        >
                          <FormattedMessage id="common.add" defaultMessage="Add" />
                        </Button>

                        <Button variant="outline-secondary" data-unittest="cancel" onClick={() => onCancelClick()} className={s.taskButton}>
                          <FormattedMessage id="checklistTasksTemplates.cancel" defaultMessage="Cancel" />
                        </Button>
                        <Link disabled={!isEdit} to={`/operational-setup/task-template?checklistTemplateId=${checklistTemplateId}`}>
                          <Button
                            disabled={!isEdit}
                            variant="outline-secondary"
                            data-unittest="add"
                            onClick={onTaskAddClick()}
                            className={s.taskButton}
                          >
                            <FormattedMessage id="checklistTasksTemplates.createNewTask" defaultMessage="Create new task" />
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </td>
                </tr>
              </Fragment>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

TaskTemplates.defaultProps = {
  selectedTaskTemplates: [{ TaskTemplateId: 1, Name: 'Name', EstimatedEffort: 1, ProfileName: 'Admin' }],
  taskTemplates: []
};

TaskTemplates.propTypes = {
  onTaskSearch: PropTypes.func.isRequired,
  onTaskAdd: PropTypes.func.isRequired,
  onTaskRemove: PropTypes.func.isRequired
};

export default injectIntl(TaskTemplates);
