import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './Site.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import SiteListTable from './SiteListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';

import { getUserProfileWidget, getSite } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';

import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';

import { getSiteThunk, getFilterThunk, setFilteredDataThunk, setCurrentPage, setPageFilter } from '../../store/actions/sites';

const SiteList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const site = useSelector(state => getSite(state));

  const SEARCH_FILTER = 'SEARCH_FILTER';
  const [asendingOrder, setAsendingOrder] = useState(true);

  let filter = site.filterBy.filter || null;
  let offset = site.offset || 0;
  let limit = site.limit || 1000;

  const [searchFilter, setSearchFilter] = useState('');
  let disableControls = site.isLoading || false;

  useEffect(() => {
    let filterObj = {
      filter: filter,
      offset: offset,
      limit: limit
    };
    dispatch(getSiteThunk('WQSP_SITES_LIST', filterObj));
  }, [getSiteThunk, filter]);

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_SITES_LIST'));
  }, [getFilterThunk]);

  let messageId = (site && site.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = site.Sites;

    let apifilterObj = {
      filter: null
    };

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (site.siteListCount > site.Sites.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let siteObj = Object.values(site.filteredResults);

  if (asendingOrder) {
    siteObj = siteObj.sort(compareValues('Region'));
  } else {
    siteObj = siteObj.sort(compareValues('Region', 'desc'));
  }

  const onAddNewClick = () => {
    history.push('/sites/site');
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_SITES_UPDATE');
  const ConfigurationAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_SITES_CONFIG');

  return (
    <div className={s.siteList}>
      <div>
        <h1 className={s.tabHeader}>{<FormattedMessage id="site.sites" defaultMessage="Sites" />}</h1>
      </div>

      <div className={s.contentWrapper}>
        {site.isLoading && <LoadingSpinner />}
        <Banner failureText={messageText} showBanner={site.showBanner} status={site.isOpSuccessful} successText={messageText} />

        <Row>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              initialText={searchFilter}
              placeHolderTextId="site.searchSiteName"
            />
          </Col>

          <Col sm={6} lg={8}>
            <Button
              className={s.addbutton}
              style={WidgetVisibility(userProfileWidgets, 'WQSP_SITES_ADD')}
              onClick={onAddNewClick}
              disabled={disableControls}
              data-unittest="addSitesButton"
            >
              + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
            </Button>
          </Col>
        </Row>

        <Pagination data={siteObj} pageSize={DEFAULT_PAGE_SIZE} onPageChange={onPageChangeHandler} startingPage={site.currentPage}>
          <SiteListTable
            onSortOrder={onSortOrder}
            asendingOrder={asendingOrder}
            UpdateAllowed={isUpdateAllowed}
            ConfigurationAllowed={ConfigurationAllowed}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default injectIntl(SiteList);
