import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './QualityGoalsReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialQualityGoalsState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { useHistory, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { compareValues, goBack } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import QualityGoalsReportItemTable from './QualityGoalsReportItemTable';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import 'react-datetime/css/react-datetime.css';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';
import { getUserProfileWidget, getGeneralData, getQualityGoals, getCurrentTimezone } from '../../selectors/index';
import { describeQualityGoalsThunk, saveQualityGoalsThunk, setQualityGoalsChangeThunk } from '../../store/actions/qualityGoals';
import { FREQUENCY, GENERAL_STATUS } from '../../constants/index';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';
import GoBack from '../WSAControls/GoBack/GoBack';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const QualityGoalsReport = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  const history = useHistory();

  const [localformErrors, setFormErrors] = useState({});
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [disableMode, setDisableMode] = useState(false);

  const [fileUploadStarted, setFileUploadStarted] = useState(false);
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);
  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const qualityGoals = useSelector(state => getQualityGoals(state));
  const generalData = useSelector(state => getGeneralData(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);
  let { siteId, segmentName, selectedTab, entityId, qualityGoalReportId } = useParams();

  let defaultReturnUrl = '/quality-goals';
  if (segmentName && entityId) {
    defaultReturnUrl = `/site/${siteId}/${segmentName}/entities/entity/${entityId}/${selectedTab}/quality-goals`;
  } else if (segmentName) {
    defaultReturnUrl = `/site/${siteId}/${segmentName}/${selectedTab}/quality-goals`;
  }

  let waterSupplyId = null;
  let entityTypeId = null;
  let disableEntity = false;
  let disableSite = false;
  if (siteId) {
    disableSite = true;
  }
  if (entityId) {
    let selectedEntity = generalData.entities.find(x => x.EntityId == entityId) || {};
    if (Object.keys(selectedEntity).length > 0) {
      waterSupplyId = selectedEntity.WaterSupplyDescriptionId || null;
      entityTypeId = selectedEntity.EntityTypeId || null;
      disableEntity = true;
    }
  }
  useEffect(() => {
    setLocalQualityGoals({
      selectedQualityGoals: {
        ...qualityGoals.selectedQualityGoals,
        SiteId: (siteId && parseInt(siteId)) || 0,
        WaterSupplyDescriptionId: (waterSupplyId && parseInt(waterSupplyId)) || 0,
        EntityTypeId: (entityTypeId && parseInt(entityTypeId)) || 0,
        EntityId: (entityId && parseInt(entityId)) || 0
      }
    });
  }, [siteId, waterSupplyId, entityTypeId, entityId]);

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_QUALITYGOALSREPORT_EDIT');
  const ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  if (qualityGoalReportId && qualityGoalReportId > 0) {
    IsEdit = true;
  } else {
    qualityGoalReportId = 0;
  }
  let widgetCode = IsEdit ? 'WQSP_QUALITYGOALSREPORT_EDIT' : 'WQSP_QUALITYGOALSREPORT_ADDNEW';

  useEffect(() => {
    dispatch(describeQualityGoalsThunk(qualityGoalReportId, widgetCode));
  }, [describeQualityGoalsThunk, qualityGoalReportId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setQualityGoalsChangeThunk({
          ...initialQualityGoalsState.qualityGoals
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalQualityGoals = currentState => {
    dispatch(setQualityGoalsChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }
    let testCategories = [];
    qualityGoals.selectedQualityGoals.resultItems.map(item => {
      testCategories = [...testCategories, ...item.testCategoryTypes];
    });
    let saveData = {
      ...qualityGoals.selectedQualityGoals,
      resultItems: (qualityGoals.selectedQualityGoals.resultItems && testCategories) || [],
      testPurposes: [],
      testCategories: [],
      selectedQualityGoalReportItem: {}
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.QualityGoalReportId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    dispatch(saveQualityGoalsThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!qualityGoals.selectedQualityGoals || !qualityGoals.selectedQualityGoals.SiteId) {
      formErrors.Site = intl.formatMessage({
        id: 'wqsp.common.siteMandatory',
        defaultMessage: 'Site name is mandatory field'
      });
      isValid = false;
    }

    if (!qualityGoals.selectedQualityGoals || !qualityGoals.selectedQualityGoals.WaterSupplyDescriptionId) {
      formErrors.WaterSupply = intl.formatMessage({
        id: 'wqsp.common.waterSupplyMandatory',
        defaultMessage: 'Water supply name is mandatory field'
      });
      isValid = false;
    }

    if (!qualityGoals.selectedQualityGoals || !qualityGoals.selectedQualityGoals.EntityTypeId) {
      formErrors.EntityType = intl.formatMessage({
        id: 'wqsp.common.entityTypeMandatory',
        defaultMessage: 'Entity type is mandatory field'
      });
      isValid = false;
    }

    if (!qualityGoals.selectedQualityGoals || !qualityGoals.selectedQualityGoals.EntityId) {
      formErrors.Entity = intl.formatMessage({
        id: 'wqsp.common.entityMandatory',
        defaultMessage: 'Entity is mandatory field'
      });
      isValid = false;
    }

    if (!qualityGoals.selectedQualityGoals || !qualityGoals.selectedQualityGoals.GoalDate) {
      formErrors.GoalDate = intl.formatMessage({
        id: 'qualityGoals.goalDateMandatory',
        defaultMessage: 'Goal date is mandatory field'
      });
      isValid = false;
    }

    if (!qualityGoals.selectedQualityGoals || !qualityGoals.selectedQualityGoals.GoalPurposeId) {
      formErrors.GoalPurpose = intl.formatMessage({
        id: 'qualityGoals.goalPurposeMandatory',
        defaultMessage: 'Goal purpose is mandatory field'
      });
      isValid = false;
    }

    let testCategories = [];
    qualityGoals.selectedQualityGoals.resultItems.map(item => {
      testCategories = [...testCategories, ...item.testCategoryTypes];
    });

    if (testCategories.length === 0 || testCategories.filter(item => !item.IsDeleted).lenghth === 0) {
      formErrors.QualityGoals = intl.formatMessage({
        id: 'qualityGoals.testResultMandatory',
        defaultMessage: 'Test result is mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalQualityGoals({
        ...qualityGoals,
        selectedQualityGoals: { ...qualityGoals.selectedQualityGoals, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalQualityGoals({
        ...qualityGoals,
        selectedQualityGoals: { ...qualityGoals.selectedQualityGoals, [e.target.name]: e.target.value }
      });
    }
  };

  const onAddReportItem = testType => {
    let qualityGoalsItems = (qualityGoals.selectedQualityGoals.resultItems && [...qualityGoals.selectedQualityGoals.resultItems]) || [];

    let categoryFound = false;
    testType.GuidIdentifier = uuidv4();
    testType.QualityGoalReportItemId = 0;
    testType.IsActive = true;
    testType.IsDeleted = false;

    qualityGoalsItems = qualityGoalsItems.map(item => {
      if (!item.IsDeleted && item.TestCategoryId === testType.TestCategoryId) {
        categoryFound = true;
        if (!item.testCategoryTypes) item.testCategoryTypes = [];
        item.testCategoryTypes.push(testType);
      }
      return item;
    });

    if (!categoryFound) {
      qualityGoalsItems.push({
        TestCategoryId: testType.TestCategoryId,
        CategoryName: testType.CategoryName,
        testCategoryTypes: [
          {
            ...testType
          }
        ]
      });
    }
    setShowAddEdit(false);
    dispatch(
      setQualityGoalsChangeThunk({
        selectedQualityGoals: {
          resultItems: qualityGoalsItems
        }
      })
    );
  };

  const onChangeReportItem = qualityGoalsItems => {
    dispatch(
      setQualityGoalsChangeThunk({
        selectedQualityGoals: {
          resultItems: qualityGoalsItems
        }
      })
    );
  };

  const siteOptions = () => {
    let siteData = [];
    generalData.sites.forEach(element => {
      siteData.push({
        ...element,
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onSiteDropdownChange = e => {
    setLocalQualityGoals({
      selectedQualityGoals: {
        ...qualityGoals.selectedQualityGoals,
        SiteId: e.value,
        WaterSupplyDescriptionId: 0,
        EntityId: 0
      }
    });
  };

  const waterSupplyOptions = () => {
    let waterSupplyData = [];
    const filteredData = generalData.waterSupplyDescriptions.filter(item => item.SiteId === qualityGoals.selectedQualityGoals.SiteId);
    filteredData.forEach(element => {
      waterSupplyData.push({
        ...element,
        label: element.Name,
        value: element.WaterSupplyDescriptionId
      });
    });

    let sortedData = waterSupplyData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onWaterSupplyDropdownChange = e => {
    setLocalQualityGoals({
      selectedQualityGoals: {
        ...qualityGoals.selectedQualityGoals,
        WaterSupplyDescriptionId: e.value
      }
    });
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];
    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityTypeDropdownChange = e => {
    setLocalQualityGoals({
      selectedQualityGoals: {
        ...qualityGoals.selectedQualityGoals,
        EntityTypeId: e.value,
        EntityId: 0
      }
    });
  };

  const entityOptions = () => {
    let entityData = [];
    let entitySubTypes = generalData.entitySubTypes.filter(item => item.EntityTypeId === qualityGoals.selectedQualityGoals.EntityTypeId);
    let entities = generalData.entities.filter(item =>
      entitySubTypes.find(
        type =>
          type.EntitySubTypeId === item.EntitySubTypeId &&
          item.WaterSupplyDescriptionId === qualityGoals.selectedQualityGoals.WaterSupplyDescriptionId
      )
    );
    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityDropdownChange = e => {
    setLocalQualityGoals({
      selectedQualityGoals: {
        ...qualityGoals.selectedQualityGoals,
        EntityId: e.value
      }
    });
  };

  const testPurposeOptions = () => {
    let purposeData = [];
    let data = qualityGoals.selectedQualityGoals.testPurposes;
    data.forEach(element => {
      purposeData.push({
        ...element,
        label: element.Name,
        value: element.TestPurposeId
      });
    });

    let sortedData = purposeData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onGoalPurposeDropdownChange = e => {
    setLocalQualityGoals({
      selectedQualityGoals: {
        ...qualityGoals.selectedQualityGoals,
        GoalPurposeId: e.value
      }
    });
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();

    setLocalQualityGoals({
      selectedQualityGoals: {
        ...qualityGoals.selectedQualityGoals,
        [fieldName]: changeDate
      }
    });
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };
  const onStatusChange = e => {
    setLocalQualityGoals({
      selectedQualityGoals: {
        ...qualityGoals.selectedQualityGoals,
        [e.target.name]: e.target.value,
        StatusId: null
      }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalQualityGoals({
      selectedQualityGoals: {
        ...qualityGoals.selectedQualityGoals,
        [fieldName]: e.value
      }
    });
  };

  const onUploadStart = files => {
    setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          QualityGoalReportId: 0,
          ImageId: item.fileGuid,
          newFile: true,
          S3URL: item.S3URL,
          Name: item.name
        };
      });
      let allFiles = [...qualityGoals.selectedQualityGoals.files, ...newFiles];
      setLocalQualityGoals({
        selectedQualityGoals: {
          ...qualityGoals.selectedQualityGoals,
          files: allFiles
        }
      });
    }
  };

  const removeFile = fileGuid => {
    let files = qualityGoals.selectedQualityGoals.files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...qualityGoals.selectedQualityGoals.deletedFileIds];
    deletedFileIds.push(fileGuid);
    setLocalQualityGoals({
      ...qualityGoals,
      selectedQualityGoals: { ...qualityGoals.selectedQualityGoals, files: files, deletedFileIds: deletedFileIds }
    });
  };

  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  let messageId = (qualityGoals && qualityGoals.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  if (IsEdit) {
    heading = intl.formatMessage({ id: 'qualityGoals.editQualityGoals', defaultMessage: 'Edit Quality Goal' });
  } else {
    heading = intl.formatMessage({ id: 'qualityGoals.addNewTestResult', defaultMessage: 'Add new Quality Goal' });
  }

  const qualityGoalCrumbs = [
    {
      orderNo: 1,
      name: <FormattedMessage id="qualityGoals.listOfQualityGoals" defaultMessage="List of Quality Goals" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/quality-goals`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];
  const entityQualityGoalCrumbs = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="qualityGoals.qualityGoals" defaultMessage="Quality Goals" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/quality-goals`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  let breadcrumb = [];

  if (segmentName === 'define' && entityId) {
    breadcrumb = entityQualityGoalCrumbs;
  } else {
    breadcrumb = qualityGoalCrumbs;
  }

  if (qualityGoals.isRedirect) {
    goBack(history, defaultReturnUrl);
  }

  return (
    <div className={s.qualityGoals}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {segmentName ? (
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        ) : (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="qualityGoals.qualityGoals" defaultMessage="Quality Goals" />}</h1>
          </div>
        )}
        {qualityGoals.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={qualityGoals.showBanner}
          status={qualityGoals.isOpSuccessful}
          successText={messageText}
        />
        <div className={s.contentWrapper}>
          {!segmentName && (
            <div className={s.testresultHeader}>
              <GoBack className={s.backLink}>
                &lt; &nbsp;
                <FormattedMessage id="common.back" defaultMessage="BACK" />
              </GoBack>
              <h3 data-unittest="headingLabel">{heading}</h3>
            </div>
          )}
          <div className={s.testresultContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultSiteName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlSite"
                      dataArray={siteOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Site && s.ddlError }}
                      onDropdownChange={onSiteDropdownChange}
                      data-unittest="formDdlSite"
                      selectedOption={siteOptions().filter(option => option.value === qualityGoals.selectedQualityGoals.SiteId)}
                      disabled={IsEdit || disableSite}
                    />
                    {localformErrors && localformErrors.Site && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Site}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultSupplyName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlWaterSupply"
                      dataArray={waterSupplyOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.WaterSupply && s.ddlError }}
                      onDropdownChange={onWaterSupplyDropdownChange}
                      data-unittest="formDdlWaterSupply"
                      selectedOption={waterSupplyOptions().filter(
                        option => option.value === qualityGoals.selectedQualityGoals.WaterSupplyDescriptionId
                      )}
                      disabled={IsEdit || disableEntity}
                    />
                    {localformErrors && localformErrors.WaterSupply && (
                      <p role="alert" className={s.error}>
                        {localformErrors.WaterSupply}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formQualityGoalsEntityType">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntityType"
                      dataArray={entityTypeOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.EntityType && s.ddlError }}
                      onDropdownChange={onEntityTypeDropdownChange}
                      data-unittest="formDdlEntityType"
                      selectedOption={entityTypeOptions().filter(option => option.value === qualityGoals.selectedQualityGoals.EntityTypeId)}
                      disabled={IsEdit || disableEntity}
                    />
                    {localformErrors && localformErrors.EntityType && (
                      <p role="alert" className={s.error}>
                        {localformErrors.EntityType}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formQualityGoalsEntity">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntity"
                      dataArray={entityOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Entity && s.ddlError }}
                      onDropdownChange={onEntityDropdownChange}
                      data-unittest="formDdlEntity"
                      selectedOption={entityOptions().filter(option => option.value === qualityGoals.selectedQualityGoals.EntityId)}
                      disabled={IsEdit || disableEntity}
                    />
                    {localformErrors && localformErrors.Entity && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Entity}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formQualityGoalsDate">
                    <Form.Label>
                      <FormattedMessage id="qualityGoals.goalDateDate" defaultMessage="Goal Date" />
                    </Form.Label>

                    <DateTimePicker
                      className={`${localformErrors.GoalDate ? s.formControlError : s.frmCalendar}`}
                      onChange={m => {
                        onChange({ target: { name: 'GoalDate', value: (m && m.unix && m.unix()) || null } });
                      }}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(qualityGoals.selectedQualityGoals.GoalDate)}
                      defaultValue={getFormattedate(qualityGoals.selectedQualityGoals.GoalDate)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                    {localformErrors && localformErrors.GoalDate && (
                      <p role="alert" className={s.error}>
                        {localformErrors.GoalDate}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formQualityGoalsAuthorName">
                    <Form.Label>
                      <FormattedMessage id="qualityGoals.authorName" defaultMessage="Name of Author" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="AuthorName"
                      onChange={onChange}
                      value={qualityGoals.selectedQualityGoals.AuthorName}
                      className={`${s.formControl}`}
                      placeholder={intl.formatMessage({
                        id: 'qualityGoals.authorName',
                        defaultMessage: 'Name of Author'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formQualityGoalsGoalPurpose">
                    <Form.Label>
                      <FormattedMessage id="qualityGoals.goalPurpose" defaultMessage="Goal Purpose" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlGoalPurpose"
                      dataArray={testPurposeOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.GoalPurpose && s.ddlError }}
                      onDropdownChange={onGoalPurposeDropdownChange}
                      data-unittest="formDdlGoalPurpose"
                      selectedOption={testPurposeOptions().filter(
                        option => option.value === qualityGoals.selectedQualityGoals.GoalPurposeId
                      )}
                      disabled={IsEdit}
                    />
                    {localformErrors && localformErrors.GoalPurpose && (
                      <p role="alert" className={s.error}>
                        {localformErrors.GoalPurpose}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formLastReviewDate">
                    <Form.Label>
                      <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                    </Form.Label>
                    <DateTimePicker
                      isValidDate={disableFutureDates}
                      className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                      onChange={m => onDateChange(m, 'LastReviewDate')}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(qualityGoals.selectedQualityGoals.LastReviewDate)}
                      defaultValue={getFormattedate(qualityGoals.selectedQualityGoals.LastReviewDate)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formNextReviewDate">
                    <Form.Label>
                      <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                    </Form.Label>
                    <DateTimePicker
                      className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                      onChange={m => onDateChange(m, 'NextReviewDate')}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(qualityGoals.selectedQualityGoals.NextReviewDate)}
                      defaultValue={getFormattedate(qualityGoals.selectedQualityGoals.NextReviewDate)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                    {localformErrors && localformErrors.NextReviewDate && (
                      <p role="alert" className={s.error}>
                        {localformErrors.NextReviewDate}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formGroupReviewFrequency">
                    <Form.Label>
                      <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                    </Form.Label>

                    <Dropdown
                      id="formMaintenanceFrequency"
                      dataArray={frequencyOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                      }}
                      name="ReviewFrequencyId"
                      onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                      selectedOption={frequencyOptions().filter(
                        option => option.value === qualityGoals.selectedQualityGoals.ReviewFrequencyId
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group controlId="formActioned">
                    <Form.Label>
                      <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                    </Form.Label>

                    <ToggleSwitch
                      handleClick={() => {
                        onStatusChange({ target: { name: 'IsActioned', value: !qualityGoals.selectedQualityGoals.IsActioned } });
                      }}
                      classname={s.switch}
                      checked={qualityGoals.selectedQualityGoals.IsActioned}
                      labelChecked={yesText}
                      labelUnchecked={noText}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {!qualityGoals.selectedQualityGoals.IsActioned && (
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formStatus">
                      <Form.Label>
                        <FormattedMessage id="common.status" defaultMessage="Status" />
                      </Form.Label>

                      <Dropdown
                        id="formStatus"
                        dataArray={statusOptions()}
                        controlData={{ placeholderText: ddlInitText }}
                        onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                        data-unittest="formDdlRiskStatus"
                        selectedOption={statusOptions().filter(option => option.value === qualityGoals.selectedQualityGoals.StatusId)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
          <div className={s.testresultTypeContent}>
            <QualityGoalsReportItemTable
              resultItems={qualityGoals.selectedQualityGoals.resultItems}
              testCategories={qualityGoals.selectedQualityGoals.testCategories}
              setShowAddEdit={setShowAddEdit}
              showAddEdit={showAddEdit}
              disableMode={disableMode}
              setDisableMode={setDisableMode}
              onAddReportItem={onAddReportItem}
              onChangeReportItem={onChangeReportItem}
              userProfileWidgets={userProfileWidgets}
              updateAllowed={isUpdateAllowed}
              formError={localformErrors.QualityGoals}
              qualityReport={true}
            />
          </div>
          <div className={s.testresultContent}>
            <Form>
              <Row>
                <Col>
                  <h3>
                    <FormattedMessage id="qualityGoals.additionalInformation" defaultMessage="Additional Information" />
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formQualityGoalsComments">
                    <Form.Label>
                      <FormattedMessage id="qualityGoals.commentsSummary" defaultMessage="Comments/Summary" />
                    </Form.Label>

                    <Form.Control
                      className={s.textArea}
                      as="textarea"
                      rows="3"
                      name="Comments"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'qualityGoals.commentsSummary',
                        defaultMessage: 'Comments/Summary'
                      })}
                      value={qualityGoals.selectedQualityGoals.Comments}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>
                    <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                  </Form.Label>
                  <FileUploader
                    widgetCode={'WQSP_QUALITYGOALSREPORT_ATTACH'}
                    allowedFileTypes={[
                      ['image/jpeg', 'jpeg'],
                      ['application/pdf', 'pdf'],
                      ['application/msword', 'doc']
                    ]}
                    location={`organisation/${orgId}/sites/${qualityGoals.selectedQualityGoals.SiteId}/qualityGoals`}
                    enableMultipleUpload={true}
                    maxFiles={5}
                    onUploadComplete={onUploadComplete}
                    onUploadStart={onUploadStart}
                  />
                </Col>
              </Row>
              <FileList files={qualityGoals.selectedQualityGoals.files} onRemoveFile={removeFile} />
            </Form>
          </div>
          <div>
            <Row className={s.breakRow}>
              <Col sm={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                  disabled={disableMode || qualityGoals.isLoading}
                >
                  <FormattedMessage id="common.save" defaultMessage="SAVE" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className={s.btnCancel}
                  disabled={disableMode}
                  onClick={() => goBack(history, defaultReturnUrl)}
                >
                  <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(QualityGoalsReport);
