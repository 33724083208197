import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';

import {
  ORGANISATION_TRANSLATION_ERROR,
  GET_ALL_ORGANISATION_TRANSLATION_DETAILS,
  ORGANISATION_TRANSLATION_IS_LOADING,
  SET_ORGANISATION_TRANSLATION_CURRENT_PAGE,
  SET_ORGANISATION_TRANSLATION_PAGE_FILTER,
  SET_ORGANISATION_TRANSLATION_CHANGE,
  SET_ORGANISATION_TRANSLATION,
  UPDATE_ORGANISATION_TRANSLATION_SAVE_STATUS,
  SET_ORGANISATION_TRANSLATION_FILTERS,
  SET_ORGANISATION_TRANSLATION_RESULTS,
  SET_ORGANISATION_TRANSLATION_CLEANUP,
  REMOVE_ORGANISATION_TRANSLATION
} from '../../../constants/index';

import {
  ORGANISATION_TRANSLATION_MANAGEMENT,
  CREATE_ORGANISATION_TRANSLATION,
  UPDATE_ORGANISATION_TRANSLATION
} from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setOrganisationTranslationErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setOrganisationTranslationError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setOrganisationTranslationError(messageCode, false));
  }, 2500);
};

export const setOrganisationTranslationError = (messageCode, status) => {
  return {
    type: ORGANISATION_TRANSLATION_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: ORGANISATION_TRANSLATION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getOrganisationTranslationThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getOrganisationTranslation(widgetCode, orgId, filterObj));
};

export const getOrganisationTranslation = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/translations`,
    method: 'GET',
    data: {
      OrgId: orgId,
      filter: filterObj.filter,
      filterLocale: filterObj.filterLocale,
      filterTranslationKey: filterObj.filterTranslationKey,
      limit: filterObj.limit,
      offset: filterObj.offset
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllOrganisationTranslationListThunk(retData),
    onFailure: err => setOrganisationTranslationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllOrganisationTranslationListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let organisationTranslations = (retData && retData.retData.data) || [];
  dispatch(setAllOrganisationTranslationList(messageCode, organisationTranslations));
};

export const setAllOrganisationTranslationList = (messageCode, organisationTranslations) => ({
  type: GET_ALL_ORGANISATION_TRANSLATION_DETAILS,
  payload: {
    data: {
      OrganisationTranslations: organisationTranslations,
      filteredResults: organisationTranslations,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_ORGANISATION_TRANSLATION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_ORGANISATION_TRANSLATION_PAGE_FILTER,
  payload: { filter }
});

export const describeOrganisationTranslationThunk = (OrganisationTranslationId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeOrganisationTranslation(OrganisationTranslationId, widgetCode, orgId));
};

export const describeOrganisationTranslation = (OrganisationTranslationId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/translations`,
    data: {
      OrganisationTranslationId: OrganisationTranslationId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setOrganisationTranslationThunk(retData, OrganisationTranslationId),
    onFailure: err => setOrganisationTranslationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setOrganisationTranslationThunk = ({ retData }, OrganisationTranslationId) => dispatch => {
  if (
    OrganisationTranslationId &&
    (!retData || retData.data.length === 0 || retData.data[0].OrganisationTranslationId !== parseInt(OrganisationTranslationId))
  ) {
    redirectToPageNotFound(dispatch);
  }
  let data = retData.data[0];
  dispatch(setOrganisationTranslation(data));
};

export const setOrganisationTranslation = data => ({
  type: SET_ORGANISATION_TRANSLATION,
  payload: { selectedOrganisationTranslation: data }
});

export const saveOrganisationTranslationThunk = (organisationTranslation, widgetCode) => dispatch => {
  let url = '/management/translations/save';
  let actionName =
    organisationTranslation.OrganisationTranslationId === 0 ? CREATE_ORGANISATION_TRANSLATION : UPDATE_ORGANISATION_TRANSLATION;
  let log = logEntry(ORGANISATION_TRANSLATION_MANAGEMENT, actionName, organisationTranslation);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  organisationTranslation.OrgId = orgId;
  dispatch(saveOrganisationTranslation(organisationTranslation, url, log, widgetCode));
};

export const saveOrganisationTranslation = (organisationTranslation, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setOrganisationTranslationStatusThunk(retData, organisationTranslation),
    onFailure: err => setOrganisationTranslationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: organisationTranslation,
      log: log
    },
    widgetcode: widgetCode
  });

export const setOrganisationTranslationStatusThunk = (message, organisationTranslation) => dispatch => {
  dispatch(setOrganisationTranslationStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !organisationTranslation.PerformDelete;
    dispatch(setOrganisationTranslationStatus(message, false, isRedirect));
    if (organisationTranslation.PerformDelete) {
      dispatch(removeOrganisationTranslation(organisationTranslation.OrganisationTranslationId));
    }
  }, 2500);
};

export const setOrganisationTranslationStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_ORGANISATION_TRANSLATION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const removeOrganisationTranslation = data => ({
  type: REMOVE_ORGANISATION_TRANSLATION,
  payload: {
    organisationTranslationId: data
  }
});

export const setOrganisationTranslationChangeThunk = retData => dispatch => {
  dispatch(setOrganisationTranslationChange(retData));
};

export const setOrganisationTranslationChange = retData => ({
  type: SET_ORGANISATION_TRANSLATION_CHANGE,
  payload: retData
});

export const getFilterThunk = widgetCode => dispatch => {
  dispatch(getFilter(widgetCode));
};

export const getFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      filterFor: 'organisationTranslations'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setOrganisationTranslationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_ORGANISATION_TRANSLATION_FILTERS,
  payload: {
    data: {
      isLoading: false,
      organisationTranslationListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_ORGANISATION_TRANSLATION_RESULTS,
  payload: retData
});

export const setOrganisationTranslationCleanUpThunk = selectedOrganisationTranslation => dispatch => {
  dispatch({ type: SET_ORGANISATION_TRANSLATION_CLEANUP, payload: selectedOrganisationTranslation });
};
