import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../../utils/index';

import {
  SAFETY_PLAN_ERROR,
  GET_ALL_SAFETY_PLAN_DETAILS,
  SAFETY_PLAN_IS_LOADING,
  SET_SAFETY_PLAN_CURRENT_PAGE,
  SET_SAFETY_PLAN_PAGE_FILTER,
  SET_SAFETY_PLAN_CHANGE,
  SET_SAFETY_PLAN,
  UPDATE_SAFETY_PLAN_SAVE_STATUS,
  SET_SAFETY_PLAN_FILTERS,
  SET_SAFETY_PLAN_RESULTS
} from '../../../constants/index';

import { SAFETY_PLAN_MANAGEMENT, CREATE_SAFETY_PLAN, UPDATE_SAFETY_PLAN } from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setSafetyPlanErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setSafetyPlanError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setSafetyPlanError(messageCode, false));
  }, 2500);
};

export const setSafetyPlanError = (messageCode, status) => {
  return {
    type: SAFETY_PLAN_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: SAFETY_PLAN_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getSafetyPlanThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getSafetyPlan(widgetCode, orgId, filterObj));
};

export const getSafetyPlan = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      filter: filterObj.filter,
      filterSiteId: filterObj.filterSiteId,
      limit: filterObj.limit,
      offset: filterObj.offset
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllSafetyPlanListThunk(retData),
    onFailure: err => setSafetyPlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllSafetyPlanListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let safetyPlans = (retData && retData.retData.data) || [];
  dispatch(setAllSafetyPlanList(messageCode, safetyPlans));
};

export const setAllSafetyPlanList = (messageCode, safetyPlans) => ({
  type: GET_ALL_SAFETY_PLAN_DETAILS,
  payload: {
    data: {
      SafetyPlans: safetyPlans,
      filteredResults: safetyPlans,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_SAFETY_PLAN_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_SAFETY_PLAN_PAGE_FILTER,
  payload: { filter }
});

export const describeSafetyPlanThunk = (SafetyPlanId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeSafetyPlan(SafetyPlanId, widgetCode, orgId));
};

export const describeSafetyPlan = (SafetyPlanId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp`,
    data: {
      WaterQualitySafetyPlanId: SafetyPlanId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setSafetyPlanThunk(retData),
    onFailure: err => setSafetyPlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setSafetyPlanThunk = ({ retData }) => dispatch => {
  let data = retData.data[0];
  dispatch(setSafetyPlan(data));
};

export const setSafetyPlan = data => ({
  type: SET_SAFETY_PLAN,
  payload: { selectedSafetyPlan: data }
});

export const saveSafetyPlanThunk = (safetyPlan, widgetCode) => dispatch => {
  let url = '/waterqualitysafetyplans/wqsp/save';
  let actionName = safetyPlan.SafetyPlanId === 0 ? CREATE_SAFETY_PLAN : UPDATE_SAFETY_PLAN;
  let log = logEntry(SAFETY_PLAN_MANAGEMENT, actionName, safetyPlan);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  safetyPlan.OrganisationId = orgId;
  dispatch(saveSafetyPlan(safetyPlan, url, log, widgetCode));
};

export const saveSafetyPlan = (safetyPlan, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setSafetyPlanStatusThunk(retData, safetyPlan),
    onFailure: err => setSafetyPlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: safetyPlan,
      log: log
    },
    widgetcode: widgetCode
  });

export const setSafetyPlanStatusThunk = (message, safetyPlan) => dispatch => {
  dispatch(setSafetyPlanStatus(message.message, true, false));

  setTimeout(() => {
    //Hide the banner
    let isRedirect = !safetyPlan.PerformDelete;
    dispatch(setSafetyPlanStatus(message.message, false, isRedirect));
  }, 2500);
};

export const setSafetyPlanStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_SAFETY_PLAN_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const setSafetyPlanChangeThunk = retData => dispatch => {
  dispatch(setSafetyPlanChange(retData));
};

export const setSafetyPlanChange = retData => ({
  type: SET_SAFETY_PLAN_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      SiteId: siteId || null,
      filterFor: 'safetyPlanListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setSafetyPlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_SAFETY_PLAN_FILTERS,
  payload: {
    data: {
      isLoading: false,
      safetyPlanListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_SAFETY_PLAN_RESULTS,
  payload: retData
});
