import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './HazardEventLibrary.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SearchBar from '../SearchBar/SearchBar';
import { initialHazardEventLibraryState } from '../../store/reducers/initialState';
import Banner from '../Banner/Banner';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { compareValues } from '../../utils';
import HazardEventLibraryListTable from './HazardEventLibraryListTable';
import { HAZARD_CLASS } from '../../constants/index';
import Button from 'react-bootstrap/Button';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { getUserProfileWidget, getHazardEventLibrary, getGeneralData } from '../../selectors/index';
import { getHazardEventTemplateThunk, saveHazardEventTemplateThunk } from '../../store/actions/hazardEventLibrary';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const HazardEventLibraryList = ({ intl }) => {
  const ENTITY_TYPE = 'ENTITY_TYPE';
  const HAZARD_CLASS_TYPE = 'HAZARD_CLASS_TYPE';
  const SEARCH_NAME = 'SEARCH_NAME';

  const dispatch = useDispatch();
  const hazardEventTemplate = useSelector(state => getHazardEventLibrary(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const generalData = useSelector(state => getGeneralData(state));

  const hazardClasses = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_CLASS);

  let initialLimit = 6;
  let initialHazardEventTemplateRecords = hazardEventTemplate.HazardEventTemplates.slice(0, initialLimit);

  const [hazardEventTemplateOpen, setHazardEventTemplateOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [hazardEventLibraryId, setHazardEventLibraryId] = useState(0);
  const [localHazardEventTemplates, setLocalHazardEventTemplates] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filterEntityTypeId, setFilterEntityTypeId] = useState(0);
  const [filterHazardClassId, setFilterHazardClassId] = useState(0);

  const localHazardEventTemplatesLength = localHazardEventTemplates.length;
  const hazardEventTemplatesLength = hazardEventTemplate.HazardEventTemplates.length;

  useEffect(() => {
    dispatch(getHazardEventTemplateThunk('WQSP_HAZARDEVENTTEMPLATE_LIST'));
  }, [getHazardEventTemplateThunk, dispatch, hazardEventTemplate.isOpSuccessful]);

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalHazardEventTemplates(hazardEventTemplate.HazardEventTemplates);
    }
    setShowAll(!showAll);
  };

  const searchHandler = (value, type) => {
    let filteredList = hazardEventTemplate.HazardEventTemplates;

    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }

    if (type === ENTITY_TYPE) {
      setFilterEntityTypeId(value);

      if (value > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === value;
        });
        setIsFilter(true);
      }

      if (filterHazardClassId > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.HazardClass.split(',').find(e => e === filterHazardClassId.toString());
        });
        setIsFilter(true);
      }

      if (searchText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchText.toLowerCase());
        });
        setIsFilter(true);
      }
    } else if (type === HAZARD_CLASS_TYPE) {
      setFilterHazardClassId(value);

      if (value > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.HazardClass.split(',').find(e => e === value.toString());
        });
        setIsFilter(true);
      }

      if (filterEntityTypeId > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityTypeId;
        });
        setIsFilter(true);
      }

      if (searchText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchText.toLowerCase());
        });
        setIsFilter(true);
      }
    } else {
      setSearchText(value);
      if (searchText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
        setIsFilter(true);
      }

      if (filterEntityTypeId > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityTypeId;
        });
        setIsFilter(true);
      }

      if (filterHazardClassId > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.HazardClass.split(',').find(e => e === filterHazardClassId.toString());
        });
        setIsFilter(true);
      }
    }

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalHazardEventTemplates(filteredList);
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];

    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
      value: 0
    });
    return sortedData;
  };

  const hazardClassOptions = () => {
    let classData = [];
    hazardClasses.forEach(element => {
      classData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = classData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allHazardClasses" defaultMessage="All Hazard Classes" />,
      value: 0
    });

    return sortedData;
  };

  const filterData = () => {
    let filteredList = hazardEventTemplate.HazardEventTemplates;
    if (filterEntityTypeId > 0) {
      filteredList = filteredList.filter(function(item) {
        return item.EntityTypeId === filterEntityTypeId;
      });
    }

    if (filterHazardClassId > 0) {
      filteredList = filteredList.filter(function(item) {
        return item.HazardClass.split(',').find(e => e === filterHazardClassId.toString());
      });
    }

    if (searchText !== '') {
      filteredList = filteredList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const processHazardEventTemplates = () => {
    let hazardEventTemplates = [];
    let processedHazardEventTemplates = [];

    if (showAll) {
      hazardEventTemplates = filterData();
    } else {
      if (isFilter) {
        hazardEventTemplates = localHazardEventTemplatesLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        hazardEventTemplates = initialHazardEventTemplateRecords;
      }
    }

    hazardEventTemplates.forEach(element => {
      processedHazardEventTemplates.push({
        Name: element.Name,
        Id: element.HazardEventLibraryId,
        EntityTypeName: element.EntityTypeName,
        HazardClass: element.HazardClass,
        Link: `/operational-setup/hazard-event-template-details/${element.HazardEventLibraryId}`
      });
    });

    return processedHazardEventTemplates;
  };

  const onRemoveInspectionPurposeClick = hazardEventLibraryId => {
    setActive(true);
    setHazardEventLibraryId(hazardEventLibraryId);
  };

  const deleteHazardEventTemplate = () => {
    let saveData = hazardEventTemplate.SelectedHazardEventTemplate;
    saveData.PerformDelete = true;
    saveData.HazardEventLibraryId = hazardEventLibraryId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveHazardEventTemplateThunk(saveData, 'WQSP_HAZARDEVENTTEMPLATE_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setHazardEventLibraryId(0);
  };

  const handleConfirm = () => {
    deleteHazardEventTemplate();
    handleClose();
  };

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  let messageId = (hazardEventTemplate && hazardEventTemplate.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const dialogTitle = intl.formatMessage({
    id: 'hazardEventLibrary.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this hazard event template?'
  });

  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENTTEMPLATE_VIEW');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENTTEMPLATE_DELETE');
  let disableControls = hazardEventTemplate.isLoading || false;

  return (
    <div className={s.hazardEventTasksTemplates}>
      {hazardEventTemplate.isLoading && <LoadingSpinner />}
      <div className={s.contentWrapper}>
        <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, `WQSP_HAZARDEVENTTEMPLATE_LIST`)}>
          <Row>
            <Col>
              <Row>
                <img
                  src={hazardEventTemplateOpen ? collapseDown : collapseUp}
                  alt="Collapse section"
                  onClick={() => setHazardEventTemplateOpen(!hazardEventTemplateOpen)}
                  aria-controls="hazard-event-template-section"
                  aria-expanded={hazardEventTemplateOpen}
                ></img>
                <h4>
                  <FormattedMessage id="hazardEventLibrary.hazardEventLibrary" defaultMessage="Hazard Event Library" />
                </h4>
                <span className={s.circle}>
                  {(hazardEventTemplate.HazardEventTemplates && hazardEventTemplate.HazardEventTemplates.length) || 0}
                </span>
              </Row>
            </Col>
          </Row>
          <Collapse in={hazardEventTemplateOpen}>
            <div className={s.innerComponent} id="hazard-event-template-section">
              {hazardEventTemplate.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={hazardEventTemplate.showBanner}
                  status={hazardEventTemplate.isOpSuccessful}
                  successText={messageText}
                />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <Row className="pt-3" className={s.topRow}>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpEntityTypeId"
                    dataArray={entityTypeOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />,
                      customClassName: ''
                    }}
                    onDropdownChange={e => searchHandler(e.value, ENTITY_TYPE)}
                    selectedOption={entityTypeOptions().filter(option => option.value === filterEntityTypeId)}
                    disabled={disableControls}
                  />
                </Col>
                <Col sm={6} lg={2}>
                  <Dropdown
                    id="drpModelId"
                    dataArray={hazardClassOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="wqsp.common.hazardClass" defaultMessage="Hazard Class" />,
                      customClassName: ''
                    }}
                    onDropdownChange={e => searchHandler(e.value, HAZARD_CLASS_TYPE)}
                    selectedOption={hazardClassOptions().filter(option => option.value === filterHazardClassId)}
                    disabled={disableControls}
                  />
                </Col>
                <Col sm={6} lg={2} className="p-0">
                  <SearchBar
                    searchHandler={searchHandler}
                    clearSearchInVisible={false}
                    // initialText={filter}
                    placeHolderTextId="common.Search"
                    data-unittest="searchTaskTemplates"
                    disabled={disableControls}
                  />
                </Col>
                <Col
                  sm={6}
                  lg={6}
                  className={s.addbuttonCol}
                  style={WidgetVisibility(userProfileWidgets, `WQSP_HAZARDEVENTTEMPLATE_ITEM_ADDNEW`)}
                >
                  <Link to="/operational-setup/hazard-event-template">
                    <Button className={s.addbutton}>
                      + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <HazardEventLibraryListTable
                    tableData={processHazardEventTemplates()}
                    removeHazardEventTemplate={onRemoveInspectionPurposeClick}
                    viewName="WQSP_HAZARDEVENTTEMPLATE_VIEW"
                    hazardClasses={hazardClasses}
                    isViewAllowed={isViewAllowed}
                    isDeleteAllowed={isDeleteAllowed}
                  />

                  {((!isFilter && hazardEventTemplatesLength > initialLimit) ||
                    (isFilter && localHazardEventTemplatesLength > initialLimit)) && (
                    <Row className={s.topRow}>
                      <Col>
                        <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                          {!showAll
                            ? `${showAllText} (${!showAll && !isFilter ? hazardEventTemplatesLength : localHazardEventTemplatesLength})`
                            : showLessText}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

HazardEventLibraryList.defaultProps = {
  hazardEventTemplate: {
    ...initialHazardEventLibraryState.hazardEventTemplate
  }
};

export default injectIntl(HazardEventLibraryList);
