import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './UserList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialUserManagerState } from '../../store/reducers/initialState';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import UserListTable from '../UserList/UserListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import uuid from 'uuid';
import Cookies from 'js-cookie';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const UserList = ({ getUsers, setCurrentPage, setPageFilter, userManager, intl, userProfileWidgets }) => {
  let filter = userManager.filter || '';
  let limit = userManager.limit || 1000;
  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  useEffect(() => {
    getUsers(limit, filter, organisationId);
  }, [getUsers, limit, filter, organisationId]);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    filter = value;
    setCurrentPage(1);
    setPageFilter(filter);
  };

  const usersObj = Object.values(userManager.users);

  let messageId = (userManager && userManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'USERUPDATE');

  return (
    <div className={s.users}>
      {userManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={userManager.showBanner}
        status={userManager.isOpSuccessfull}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageUsers" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.userHeader}>
            <div className={s.subHeading}>
              <FormattedMessage id="profile.users" defaultMessage="Users" />
              <span className={s.circle}>{userManager.users.length}</span>
            </div>
          </div>

          <Row>
            <Col sm={6} lg={4}>
              <SearchBar searchHandler={searchHandler} clearSearchInVisible={false} initialText={filter} placeHolderTextId="common.email" />
            </Col>
            <Col sm={6} lg={8}>
              <Link to={`/admin-controls/user?rnd=${uuid()}`}>
                <Button className={s.addbutton} style={WidgetVisibility(userProfileWidgets, `USERINSERT`)}>
                  + <FormattedMessage id="usermanagement.addnewuser" defaultMessage="ADD NEW USER" />
                </Button>
              </Link>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={usersObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={userManager.currentPage}
            >
              <UserListTable UpdateAllowed={isUpdateAllowed} />
            </Pagination>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

UserList.defaultProps = {
  ...initialUserManagerState
};

UserList.propTypes = {
  getUsers: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired
};

export default injectIntl(UserList);
