import React from 'react';
import s from './RiskAssessment.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getDateInDDMMYYYFormat } from '../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../selectors/index';
import { VERY_HIGH_RISK, HIGH_RISK, MEDIUM_RISK, RISK_RATINGS } from '../../constants/index';

const RiskAssessmentListTable = ({
  paginatedData,
  onSortOrder,
  asendingOrder,
  viewRiskAssessment,
  viewAllowed,
  deleteAllowed,
  removeRiskAssessment
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="riskAssessment.riskCategory" defaultMessage="Risk Category" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
            </th>
            <th>
              <FormattedMessage id="common.status" defaultMessage="Status" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="riskAssessment.dateRaised" defaultMessage="Date Raised" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            {(viewAllowed || deleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr
                key={n.RiskAssessmentId}
                className={
                  RISK_RATINGS.find(x => x.name === n.InherentRiskRating).id === VERY_HIGH_RISK
                    ? s.veryHighRiskBorder
                    : RISK_RATINGS.find(x => x.name === n.InherentRiskRating).id === HIGH_RISK
                    ? s.highRiskBorder
                    : RISK_RATINGS.find(x => x.name === n.InherentRiskRating).id === MEDIUM_RISK
                    ? s.mediumRiskBorder
                    : s.lowRiskBorder
                }
              >
                <td>{n.RiskCategoryName || ''}</td>
                <td>{n.WaterSupplyDescriptionName || ''}</td>
                <td>{n.EntityTypeName || ''}</td>
                <td>{n.Entity || ''}</td>
                <td>{n.Status || ''}</td>
                <td>{getDateInDDMMYYYFormat(n.DateRaised, currentTimezone) || ''}</td>
                <td className={s.btnCol}>
                  {viewAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.viewButton}
                      href="#"
                      onClick={() => {
                        viewRiskAssessment(n.RiskAssessmentId, n.SiteId);
                      }}
                      data-unittest="viewData"
                    >
                      <FormattedMessage id="common.view" defaultMessage="VIEW" />
                    </Button>
                  )}
                  {deleteAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.removeButton}
                      href="#"
                      onClick={() => {
                        removeRiskAssessment(n.RiskAssessmentId);
                      }}
                      data-unittest="removeData"
                    >
                      <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

RiskAssessmentListTable.defaultProps = {
  paginatedData: [],
  hazardClasses: [],
  currentPage: 1
};

export default injectIntl(RiskAssessmentListTable);
