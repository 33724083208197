import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './IncidentReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import IncidentReportListTable from './IncidentReportListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import { getUserProfileWidget, getIncidentReports, getGeneralData, getCurrentTimezone } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { initialIncidentReportsState } from '../../store/reducers/initialState';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';

import {
  getIncidentReportsThunk,
  setIncidentReportsChangeThunk,
  getFilterThunk,
  setFilteredDataThunk,
  setCurrentPage,
  setPageFilter
} from '../../store/actions/incidentReport';

const SITE = 'SITE';
const ENTITY_TYPE = 'ENTITY_TYPE';
const ENTITY = 'ENTITY';

const IncidentReportList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { siteId, segmentName, selectedTab } = useParams();

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const incidentReports = useSelector(state => getIncidentReports(state));
  const generalData = useSelector(state => getGeneralData(state));

  // //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setIncidentReportsChangeThunk({
          ...initialIncidentReportsState.incidentReports
        })
      );
    };
  }, []);

  const [filterSiteId, setFilterSiteId] = useState(parseInt(siteId) || 0);
  const [filterEntityTypeId, setFilterEntityTypeId] = useState(0);
  const [filterEntityId, setFilterEntityId] = useState(0);

  const [entitySubTypes, setEntitySubTypes] = useState([]);
  const [asendingOrder, setAsendingOrder] = useState(true);
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let offset = incidentReports.offset || 0;
  let limit = incidentReports.limit || 1000;

  let filterLocalSiteId = siteId || incidentReports.filterBy.filterSiteId || null;
  let filterLocalEntityId = incidentReports.filterBy.filterEntityId || null;
  let filterLocalEntityTypeId = incidentReports.filterBy.filterEntityTypeId || null;

  let isOpSuccessful = incidentReports.isOpSuccessful || false;

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_INCIDENT_REPORT_LIST', filterLocalSiteId));
  }, [dispatch, getFilterThunk, filterLocalSiteId]);

  useEffect(() => {
    let filterObj = {
      filterSiteId: filterLocalSiteId,
      filterEntityId: filterLocalEntityId,
      filterEntityTypeId: filterLocalEntityTypeId,
      offset: offset,
      limit: limit
    };
    dispatch(getIncidentReportsThunk(filterObj, 'WQSP_INCIDENT_REPORT_LIST'));
  }, [getIncidentReportsThunk, filterLocalSiteId, filterLocalEntityId, filterLocalEntityTypeId]);

  let disableControls = (incidentReports && incidentReports.isLoading) || false;

  let messageId = (incidentReports && incidentReports.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const siteOptions = () => {
    let siteData = [];

    generalData.sites.forEach(element => {
      siteData.push({
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allSites" defaultMessage="All Sites" />,
      value: 0
    });
    return sortedData;
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];

    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
      value: 0
    });
    return sortedData;
  };

  const entityOptions = () => {
    let entityData = [];

    entitySubTypes.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntities" defaultMessage="All Entities" />,
      value: 0
    });
    return sortedData;
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onSiteChange = e => {
    setFilterSiteId(e.value);
    filteredResults(SITE, e.value);
    setCurrentPage(1);
  };

  const onEntityTypeChange = e => {
    setFilterEntityTypeId(e.value);
    filteredResults(ENTITY_TYPE, e.value);
    setEntitySubTypes(generalData.entities.filter(item => item.EntityTypeId === e.value));
    setCurrentPage(1);
  };

  const onEntityChange = e => {
    setFilterEntityId(e.value);
    filteredResults(ENTITY, e.value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = incidentReports.incidentReports;

    let apifilterObj = {
      filterSiteId: null,
      filterEntityId: null,
      filterEntityTypeId: null
    };

    if (filterType === SITE) {
      if (value !== 0) {
        apifilterObj.filterSiteId = value;
        filteredList = filteredList.filter(function(item) {
          return item.SiteId === value;
        });
      }
    } else {
      if (filterSiteId !== 0) {
        apifilterObj.filterSiteId = filterSiteId;
        filteredList = filteredList.filter(function(item) {
          return item.SiteId === filterSiteId;
        });
      }
    }

    if (filterType === ENTITY_TYPE) {
      if (value !== 0) {
        apifilterObj.filterEntityTypeId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === value;
        });
      }
    } else {
      if (filterEntityTypeId !== 0) {
        apifilterObj.filterEntityTypeId = filterEntityTypeId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityTypeId;
        });
      }
    }

    if (filterType === ENTITY) {
      if (value !== 0) {
        apifilterObj.filterEntityId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === value;
        });
      }
    } else {
      if (filterEntityId !== 0) {
        apifilterObj.filterEntityId = filterEntityId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === filterEntityId;
        });
      }
    }

    if (incidentReports.incidentReportsListCount > incidentReports.incidentReports.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let incidentReportsObj = (incidentReports && Object.values(incidentReports.filteredResults)) || [];

  if (asendingOrder) {
    incidentReportsObj = incidentReportsObj.sort(compareValues('IncidentDateTime'));
  } else {
    incidentReportsObj = incidentReportsObj.sort(compareValues('IncidentDateTime', 'desc'));
  }

  const onAddNewClick = () => {
    if (siteId) {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/incident-reports/incident-report`);
    } else {
      history.push(`/incident-reports/incident-report`);
    }
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_INCIDENT_REPORT_EDIT');

  return (
    <SiteDashboardControlsContentTemplate
      selectedPage={selectedTab}
      siteId={siteId}
      segmentName={segmentName}
      userProfileWidgets={userProfileWidgets}
    >
      <div className={s.incidentReportList}>
        {!segmentName && (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="incidentReports.incidentReports" defaultMessage="Incident Reports" />}</h1>
          </div>
        )}
        <div className={s.contentWrapper}>
          {incidentReports.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={incidentReports.showBanner}
            status={incidentReports.isOpSuccessful}
            successText={messageText}
          />

          <Row>
            <Col sm={12} lg={12}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_INCIDENT_REPORT_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addIncidentReportButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                {' '}
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row className="pb-2">
            {!siteId && (
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drp"
                  dataArray={siteOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onSiteChange(e)}
                  selectedOption={siteOptions().filter(option => option.value === filterSiteId)}
                  disabled={disableControls}
                />
              </Col>
            )}
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpModelId"
                dataArray={entityTypeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onEntityTypeChange(e)}
                selectedOption={entityTypeOptions().filter(option => option.value === filterEntityTypeId)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpUnitId"
                dataArray={entityOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onEntityChange(e)}
                selectedOption={entityOptions().filter(option => option.value === filterEntityId)}
                disabled={disableControls}
              />
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={incidentReportsObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={incidentReports.currentPage}
            >
              <IncidentReportListTable
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                UpdateAllowed={isUpdateAllowed}
                currentTimezone={currentTimezone}
              />
            </Pagination>
          </div>
        </div>
      </div>
    </SiteDashboardControlsContentTemplate>
  );
};

export default injectIntl(IncidentReportList);
