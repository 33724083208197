import React, { Fragment, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import s from './HazardEventLibrary.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import SearchBar from '../SearchBar/SearchBar';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { compareValues } from '../../utils';

const HazardEventLibraryItem = ({
  intl,
  onItemSearch,
  onItemAdd,
  onItemRemove,
  onAddNewOrCancelClick,
  selectedItems,
  hazards,
  hazardClasses,
  isView
}) => {
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [enableAddItemTemplate, setEnableAddItemTemplate] = useState(false);

  const hazardOptions = () => {
    let itemsData = [];
    hazards &&
      hazards.Hazards.forEach(element => {
        if (selectedItems && selectedItems.filter(x => x.HazardId === element.HazardId).length === 1) {
          element.isDisabled = true;
        } else {
          element.isDisabled = false;
        }
        itemsData.push({
          ...element,
          label: element.Name,
          value: element.HazardId
        });
      });
    return itemsData.sort(compareValues('label'));
  };

  const onDropdownChange = e => {
    setSelectedItemId(e.value);
  };

  const onCancelClick = () => {
    setEnableAddItemTemplate(false);
    onAddNewOrCancelClick(false);
  };

  const onAddNewBtnClick = () => {
    setEnableAddItemTemplate(true);
    onAddNewOrCancelClick(true);
  };

  useLayoutEffect(() => {
    if (enableAddItemTemplate) {
      const scrollPos = window.scrollY + window.innerHeight;
      window.scrollTo(0, scrollPos);
    }
  }, [enableAddItemTemplate]);

  const onAddBtnClick = () => {
    onItemAdd(selectedItemId);
    setEnableAddItemTemplate(false);
    onAddNewOrCancelClick(false);
  };

  const getHazardClassName = hazardClassId => {
    return hazardClasses.find(item => item.GeneralFieldTypeId === hazardClassId)?.Name;
  };

  return (
    <div className={s.itemsContainer}>
      <div className={s.subHeading}>
        <h4>
          <FormattedMessage id="hazardEventLibraryItems.items" defaultMessage="Hazard Event Library Items" />
        </h4>
        <span className={s.circle}>{selectedItems.length}</span>
      </div>
      <div>
        <Row>
          <Col xs={6} lg={4}>
            <SearchBar
              searchHandler={onItemSearch}
              clearSearchInVisible={false}
              //initialText={initialSearchText}
              placeHolderTextId="common.search"
            />
          </Col>
          <Col xs={6} lg={8} className={s.colAddButton}>
            {!isView && (
              <Button
                disabled={enableAddItemTemplate}
                variant="primary"
                data-unittest="addnew"
                className={s.addButton}
                onClick={() => onAddNewBtnClick()}
              >
                +<FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <div>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="common.name" defaultMessage="Name" />
              </th>
              <th>
                <FormattedMessage id="common.description" defaultMessage="Description" />
              </th>
              <th>
                <FormattedMessage id="wqsp.common.hazardClass" defaultMessage="Hazard Class" />
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {selectedItems.length === 0 && (
              <tr className={s.emptyRow} data-unittest="templateData">
                <td colSpan="4">
                  <FormattedMessage id="common.noData" defaultMessage="No Data" />
                </td>
              </tr>
            )}
            {selectedItems.map((n, index) => {
              return (
                <Fragment key={index}>
                  <tr className={s.itemRow} data-unittest="templateData">
                    <td>{n.Name}</td>
                    <td>{n.Description}</td>
                    <td>{getHazardClassName(n.HazardClassId)}</td>
                    <td className={s.colBtn}>
                      {!isView && (
                        <Button
                          disabled={enableAddItemTemplate}
                          variant="outline-secondary"
                          data-unittest="remove"
                          onClick={() => onItemRemove(n.HazardEventLibraryItemId, n.GuidIdentifier)}
                          className={s.itemRemoveButton}
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
            {enableAddItemTemplate && (
              <Fragment>
                <tr data-unittest="templateData">
                  <td colSpan="4" className={s.itemRow}>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="formItemTemplate">
                          <Form.Label>
                            <FormattedMessage id="hazardEventLibraryItems.selectHazard" defaultMessage="Select Hazard" />
                          </Form.Label>
                          <Dropdown
                            id="formItemTemplate"
                            dataArray={hazardOptions()}
                            controlData={{ placeholderText: 'Select', customClassName: s.ddlItemTemplate }}
                            onDropdownChange={onDropdownChange}
                            data-unittest="formItemTemplate"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          disabled={selectedItemId === 0}
                          variant="primary"
                          data-unittest="view"
                          onClick={() => onAddBtnClick()}
                          className={s.itemButton}
                        >
                          <FormattedMessage id="common.add" defaultMessage="Add" />
                        </Button>

                        <Button variant="outline-secondary" data-unittest="cancel" onClick={() => onCancelClick()} className={s.itemButton}>
                          <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                        </Button>
                      </Col>
                    </Row>
                  </td>
                </tr>
              </Fragment>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

HazardEventLibraryItem.defaultProps = {
  selectedItems: [],
  hazards: []
};

HazardEventLibraryItem.propTypes = {
  onItemSearch: PropTypes.func,
  onItemAdd: PropTypes.func,
  onItemRemove: PropTypes.func
};

export default injectIntl(HazardEventLibraryItem);
