import React from 'react';
import closeBtn from '../../../assets/error-red-filled.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import s from './FileList.module.scss';

const FileList = ({ files, colSize, onRemoveFile }) => {
  return (
    files &&
    files.length > 0 &&
    files.map(file => {
      return (
        <Row className={s.rowFile} sm={12} md={10} lg={colSize ?? 6} key={`${file.ImageId}`}>
          <Col xs={10} className={`${s.textMediumMessageAlignLeft} ${s.colFile}`}>
            <a className={s.link} target="_blank" href={`${process.env.REACT_APP_FILEUPLOAD_URL}/${file.S3URL}`}>
              {file.Name}
            </a>
          </Col>
          <Col className={s.colFile} xs={2}>
            {!file.isUploading && !file.uploadCompleted && onRemoveFile && (
              <img
                src={closeBtn}
                alt="icon"
                className={s.removeBtn}
                onClick={() => {
                  onRemoveFile(file.ImageId);
                }}
              />
            )}
          </Col>
        </Row>
      );
    })
  );
};
export default FileList;
