import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import s from './SiteTile.module.scss';
import bs from '../../../styles/bootstrap-overrides.scss';
import pin from '../../../assets/location.svg';
import calendar from '../../../assets/calendar.svg';
import calendar_red from '../../../assets/calendar.svg';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import Cookies from 'js-cookie';

const SiteTile = ({ siteId, siteName, handleClick, location, status, lastReviewDate, nextReviewDate, currentTimezone, languageObject }) => {
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const locale = Cookies.get(`defaultLanguage-${envName}`) || 'en';
  const siteActive = (nextReviewDate && nextReviewDate > moment().unix()) || false;
  return (
    <IntlProvider locale={locale} messages={languageObject}>
      <li key={siteId} className={`${siteActive ? '' : s.borderRed}`}>
        <a
          onClick={() => {
            handleClick(siteId);
          }}
          className={`${s.siteTile} `}
        >
          <div className={s.contentWrapper}>
            <div className={s.rightIcons}></div>
            <div className={s.siteInfo}>
              <h3 className={s.tileSiteId}>{siteName}</h3>
              <h6 className={s.tileSiteId}>{}</h6>
              <p className={s.content}>
                <img src={pin} alt="Location pin" />
                <span>{`${location}`}</span>
              </p>
              {/* <p className={s.content}>
                <img src={siteProcessing} alt="Site Processing" />
                <span>{'-'}</span>
              </p> */}
              <p className={s.content}>
                <img src={siteActive ? calendar : calendar_red} alt={siteActive ? 'Site Active' : 'siteActive'} />
                <span>
                  {nextReviewDate
                    ? moment
                        .unix(parseInt(nextReviewDate))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : 'N/A'}
                </span>
              </p>
            </div>
          </div>
        </a>
      </li>
    </IntlProvider>
  );
};

SiteTile.defaultProps = {
  location: 'unknown',
  siteId: null
};

SiteTile.propTypes = {
  handleClick: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired
};

export default SiteTile;
