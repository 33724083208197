import React from 'react';
import s from './OrganisationTranslations.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';

import Button from 'react-bootstrap/Button';

const OrganisationTranslationsListTable = ({
  paginatedData,
  ChangeOrganisationTranslation,
  RemoveOrganisationTranslation,
  UpdateAllowed,
  DeleteAllowed,
  intl
}) => {
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="organisationTranslations.language" defaultMessage="Language" />
            </th>
            <th>
              <FormattedMessage id="organisationTranslations.translationKey" defaultMessage="Translation Key" />
            </th>
            <th>
              <FormattedMessage id="organisationTranslations.translationValue" defaultMessage="Translation Value" />
            </th>

            {UpdateAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.OrganisationTranslationId}>
                <td>{n.Locale}</td>
                <td>{n.TranslationKey || ''}</td>
                <td>{n.TranslationValue || ''}</td>
                <td className={s.btnCol}>
                  {DeleteAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        RemoveOrganisationTranslation(n.OrganisationTranslationId);
                      }}
                      data-unittest="removeData"
                    >
                      <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                    </Button>
                  )}
                  {UpdateAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        ChangeOrganisationTranslation(n.OrganisationTranslationId);
                      }}
                      data-unittest="changeData"
                    >
                      <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

OrganisationTranslationsListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(OrganisationTranslationsListTable);
