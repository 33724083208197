import { initialRiskAssessmentState } from './initialState';

import {
  RISK_ASSESSMENT_ERROR,
  GET_ALL_RISK_ASSESSMENT_DETAILS,
  RISK_ASSESSMENT_IS_LOADING,
  SET_RISK_ASSESSMENT_CURRENT_PAGE,
  SET_RISK_ASSESSMENT_PAGE_FILTER,
  SET_RISK_ASSESSMENT_CHANGE,
  SET_RISK_ASSESSMENT,
  UPDATE_RISK_ASSESSMENT_SAVE_STATUS,
  SET_RISK_ASSESSMENT_FILTERS,
  SET_RISK_ASSESSMENT_RESULTS,
  SET_RISK_ASSESSMENT_COMMENTS,
  REMOVE_RISK_ASSESSMENT
} from '../../constants/index';

const riskAssessmentReducer = (state = initialRiskAssessmentState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_RISK_ASSESSMENT_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          ...data
        }
      };
    }

    case SET_RISK_ASSESSMENT_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          currentPage
        }
      };
    }

    case SET_RISK_ASSESSMENT_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_RISK_ASSESSMENT_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          ...data
        }
      };
    }

    case SET_RISK_ASSESSMENT_COMMENTS: {
      const { selectedRiskAssessment } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          selectedRiskAssessment: {
            ...state.riskAssessment.selectedRiskAssessment,
            ...selectedRiskAssessment
          }
        }
      };
    }

    case REMOVE_RISK_ASSESSMENT: {
      const { riskAssessmentId } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          riskAssessments: [...state.riskAssessment.riskAssessments.filter(item => item.RiskAssessmentId !== riskAssessmentId)],
          filteredResults: [...state.riskAssessment.filteredResults.filter(item => item.RiskAssessmentId !== riskAssessmentId)],
          riskAssessmentListCount: state.riskAssessment.riskAssessmentListCount - 1
        }
      };
    }

    case RISK_ASSESSMENT_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          isLoading: isLoading
        }
      };
    }

    case RISK_ASSESSMENT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          ...data
        }
      };
    }

    case SET_RISK_ASSESSMENT: {
      let { selectedRiskAssessment } = payload;

      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          isOpSuccessful: false,
          showBanner: false,
          selectedRiskAssessment: {
            ...state.riskAssessment.selectedRiskAssessment,
            ...selectedRiskAssessment
          }
        }
      };
    }

    case SET_RISK_ASSESSMENT_CHANGE: {
      let { selectedRiskAssessment } = payload;

      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedRiskAssessment: {
            ...state.riskAssessment.selectedRiskAssessment,
            ...selectedRiskAssessment
          }
        }
      };
    }

    case SET_RISK_ASSESSMENT_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          ...filteredResults
        }
      };
    }

    case SET_RISK_ASSESSMENT_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        riskAssessment: {
          ...state.riskAssessment,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default riskAssessmentReducer;
