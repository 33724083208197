import React from 'react';
import moment from 'moment-timezone';
import { compareValues } from '../../../../utils';
import { riskCalulator, getRiskName } from '../../../../utils/appUtils';

export const SectionMeasure = ({ sectionData, getImages, getActionName, currentTimezone, intl }) => {
  return (
    <div id="section6" className="pageBreak sectionContent">
      <h1>Section 6 - Measure</h1>
      <div id="inspections" className="level1">
        <h2>Inspections</h2>
      </div>
      {sectionData.Inspections &&
        sectionData.Inspections.map(n => {
          if (n.InspectionReportId) {
            return (
              <InspectionHtml
                key={n.InspectionReportId}
                item={n}
                id={n.InspectionReportId}
                getImages={getImages}
                getActionName={getActionName}
                currentTimezone={currentTimezone}
                intl={intl}
              />
            );
          }
        })}
      <div id="testSamples" className="level1">
        <h2>Test Samples</h2>
      </div>
      {sectionData.TestSamples &&
        sectionData.TestSamples.map(n => {
          if (n.TestResultReportId) {
            return (
              <TestSampleHtml
                key={n.TestResultReportId}
                item={n}
                id={n.TestResultReportId}
                getImages={getImages}
                getActionName={getActionName}
                currentTimezone={currentTimezone}
              />
            );
          }
        })}
    </div>
  );
};

const InspectionHtml = ({ item, getImages, getActionName, currentTimezone, intl }) => {
  let riskValue = 0;
  let riskText = '';
  if (item.Score && item.Score.TotalScore) {
    riskValue = riskCalulator(item.Score.TotalScore);
    if (riskValue) {
      riskText = getRiskName(riskValue.id, intl);
    }
  }

  return (
    <div>
      <h4>{`Inspection for ${item.EntityName} on ${
        item.InspectionDate
          ? moment
              .unix(parseInt(item.InspectionDate))
              .tz(currentTimezone)
              .format('DD-MMM-YYYY')
          : 'N/A'
      }`}</h4>
      <table className="level1-table">
        <tr>
          <td>Water Supply Name </td>
          <td>{item.WaterSupplyDescriptionName}</td>
        </tr>
        <tr>
          <td>Source Entity Type </td>
          <td>{item.SourceEntityTypeName}</td>
        </tr>
        <tr>
          <td>Element Name </td>
          <td>{item.EntityName}</td>
        </tr>
        <tr>
          <td>Inspection Survey Type</td>
          <td>{item.InspectionTypeName}</td>
        </tr>
        <tr>
          <td>Inspection Purpose </td>
          <td>{item.InspectionPurposeName}</td>
        </tr>
        <tr>
          <td>Inspection Date</td>
          <td>
            {item.InspectionDate
              ? moment
                  .unix(parseInt(item.InspectionDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Inspector Name </td>
          <td>{item.InspectorName}</td>
        </tr>

        <tr>
          <td>Last Review Date </td>
          <td>
            {item.LastReviewDate
              ? moment
                  .unix(parseInt(item.LastReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Next Review Date </td>
          <td>
            {item.NextReviewDate
              ? moment
                  .unix(parseInt(item.NextReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Review Frequency</td>
          <td>{item.ReviewFrequencyName}</td>
        </tr>
        <tr>
          <td>Actioned</td>
          <td>{getActionName(item.IsActioned)}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{item.StatusName}</td>
        </tr>
      </table>
      {item.Questions.length > 0 ? (
        <div id={`questions_${item.InspectionReportId}`} className="level2">
          <h3> {`Questions for ${item.EntityName}`} </h3>
          {item.Questions.map(n => {
            if (n.InspectionAssesmentQuestionId) {
              return (
                <InspectionQuestionHtml
                  key={n.InspectionAssesmentQuestionId}
                  item={n}
                  id={n.InspectionAssesmentQuestionId}
                  getImages={getImages}
                />
              );
            }
          })}
        </div>
      ) : (
        ''
      )}
      <div id={`score_${item.InspectionReportId}`} className="level2">
        <h3> {`Score for ${item.EntityName}`} </h3>
        <table className="level1-table">
          <tr>
            <td>Total Score </td>
            <td>{(item.Score && item.Score.TotalScore && item.Score.TotalScore + '%') || ''}</td>
          </tr>
          <tr>
            <td>Risk Rating </td>
            <td>{riskText}</td>
          </tr>
        </table>
      </div>
      <div id={`addtionalInfo_${item.InspectionReportId}`} className="level2">
        <h3> Additional </h3>
        <table className="level1-table">
          <tr>
            <td>Additional Observation</td>
            <td>{item.AdditionalObservation}</td>
          </tr>
          <tr>
            <td>General Information </td>
            <td>{item.GeneralInformation}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

const InspectionQuestionHtml = ({ item, getImages }) => {
  return (
    <div>
      <h4>{`Question ${item.QuestionNumber}`}</h4>
      <table className="level1-table">
        <tr>
          <td>Number </td>
          <td>{item.QuestionNumber}</td>
        </tr>
        <tr>
          <td>Question </td>
          <td>{item.QuestionText}</td>
        </tr>
        <tr>
          <td>Images</td>
          <td>{getImages(item.Images)}</td>
        </tr>
        <tr>
          <td>Score</td>
          <td>{item.Score}</td>
        </tr>
        <tr>
          <td>Answer </td>
          <td>{item.Result ? 'Yes' : 'No'}</td>
        </tr>
      </table>
    </div>
  );
};

const TestSampleHtml = ({ item, getImages, getActionName, currentTimezone }) => {
  return (
    <div>
      <h4>{`Test Sample for ${item.TestPurposeName}`}</h4>
      <table className="level1-table">
        <tr>
          <td>Water Supply Name </td>
          <td>{item.WaterSupplyDescriptionName}</td>
        </tr>
        <tr>
          <td>Source Entity Type </td>
          <td>{item.EntityTypeName}</td>
        </tr>
        <tr>
          <td>Element Name </td>
          <td>{item.EntityName}</td>
        </tr>
        <tr>
          <td>Sample Date </td>
          <td>
            {item.SampleDate
              ? moment
                  .unix(parseInt(item.SampleDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Sample Id #</td>
          <td>{item.SampleIdNumber}</td>
        </tr>
        <tr>
          <td>Name of Sampler </td>
          <td>{item.SamplerName}</td>
        </tr>
        <tr>
          <td>Purpose </td>
          <td>{item.TestPurposeName}</td>
        </tr>

        <tr>
          <td>Last Review Date </td>
          <td>
            {item.LastReviewDate
              ? moment
                  .unix(parseInt(item.LastReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Next Review Date </td>
          <td>
            {item.NextReviewDate
              ? moment
                  .unix(parseInt(item.NextReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Review Frequency</td>
          <td>{item.ReviewFrequencyName}</td>
        </tr>
        <tr>
          <td>Actioned</td>
          <td>{getActionName(item.IsActioned)}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{item.StatusName}</td>
        </tr>
        <tr>
          <td>Test Results</td>
          <td>
            <TestResultsHtml item={item.TestResults} />
          </td>
        </tr>
        <tr>
          <td>Analysis Completed By </td>
          <td>{item.AnalysisCompletedBy}</td>
        </tr>
        <tr>
          <td>AnalysisDate</td>
          <td>
            {item.AnalysisDate
              ? moment
                  .unix(parseInt(item.AnalysisDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Submitted Date</td>
          <td>
            {item.SubmissionDate
              ? moment
                  .unix(parseInt(item.SubmissionDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Analyser Name </td>
          <td>{item.AnalyserName}</td>
        </tr>
        <tr>
          <td>Address</td>
          <td>{item.Address}</td>
        </tr>
        <tr>
          <td>Comments</td>
          <td>{item.Comments}</td>
        </tr>
        <tr>
          <td>Images</td>
          <td>{getImages(item.Images)}</td>
        </tr>
      </table>
    </div>
  );
};

const TestResultsHtml = ({ item }) => {
  const getTestResults = resultItemsList => {
    let results = [];
    if (resultItemsList.length == 0) {
      results.push(
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    } else {
      resultItemsList.map((resultItem, index) => {
        if (resultItem.TestResultReportItemId) {
          results.push(
            <tr key={`TestResults_${index}`}>
              <td>{resultItem.TestCategoryName || ''}</td>
              <td>{resultItem.TestTypeName || ''}</td>
              <td>{resultItem.UnitOfMeasure || ''}</td>
              <td>{resultItem.Value}</td>
            </tr>
          );
        }
      });
    }

    return results;
  };

  return (
    <div>
      <table className="level2-table fixedTable">
        <tr>
          <th>Test Category</th>
          <th>Test Type</th>
          <th>Unit of Measure</th>
          <th>Value</th>
        </tr>
        {getTestResults(item.sort(compareValues('TestCategoryId')) || [])}
      </table>
    </div>
  );
};
