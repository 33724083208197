import { LOGIN_ERROR, USER_DETAILS, LOGIN_FORGOT_PASSWORD_STATUS, MFA_VERIFICATION_ERROR } from '../../constants/index';
import { initialUserState } from './initialState';

const userReducer = (state = initialUserState, action) => {
  const { payload, type } = action;
  switch (type) {
    case LOGIN_ERROR: {
      const { value, errorMessage } = payload;
      let errMessage =
        (errorMessage && errorMessage.response && errorMessage.response.data && errorMessage.response.data.errorMessage) || '';
      let msg = (errMessage && errMessage.replace('[400]=>', '')) || '';
      return {
        ...state,
        loginError: value,
        loginErrorMessage: msg,
        isforgotPassword: false
      };
    }
    case MFA_VERIFICATION_ERROR: {
      const { value } = payload;
      return {
        ...state,
        loginError: true,
        loginErrorMessage: value,
        isforgotPassword: false
      };
    }
    case USER_DETAILS: {
      const { user } = payload;
      return {
        ...state,
        ...user,
        loginError: false
      };
    }
    case LOGIN_FORGOT_PASSWORD_STATUS: {
      const { value } = payload;
      return {
        ...state,
        isforgotPassword: value
      };
    }

    default:
      return state;
  }
};

export default userReducer;
