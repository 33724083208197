import React, { useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import s from './HazardsTab.module.scss';
import { injectIntl } from 'react-intl';
import HazardsList from '../Hazards/HazardsList';
import HazardEventLibraryList from '../HazardEventLibrary/HazardEventLibraryList';

import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import { getQuerystring } from '../../utils/index';

const Hazards = ({ intl }) => {
  let posRef = useRef();
  let approvedRef = useRef();
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let list = getQuerystring('list');
  useLayoutEffect(() => {
    if (list === '1' && approvedRef.current) {
      window.scrollTo({
        top: approvedRef.current.offsetTop
      });
    } else if (list === '2' && posRef.current) {
      window.scrollTo({
        top: posRef.current.offsetTop
      });
    }
  }, [posRef, approvedRef, list]);

  return (
    <div className={s.mainComponent}>
      <OperationalSetupContentTemplate selectedPage="hazards" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.mainComponentContent}>
            <HazardsList userProfileWidgets={userProfileWidgets} />
            <HazardEventLibraryList userProfileWidgets={userProfileWidgets} />
            <div ref={approvedRef} />
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

Hazards.defaultProps = {};

export default injectIntl(Hazards);
