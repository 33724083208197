import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import User from './User';
import { getUserManager, getUnitManager, getUserProfileWidget } from '../../selectors/index';
import { postUserThunk, describeUserThunk, setSelectedUserThunk } from '../../store/actions/userManager';
import { setCurrentPage, setPageFilter } from '../../store/actions/unitManager';

const mapStateToProps = (state, props) => {
  return {
    userManager: getUserManager(state),
    unitManager: getUnitManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveUser: (user, widgetCode) => postUserThunk(user, widgetCode),
      describeUser: (userId, widgetCode) => describeUserThunk(userId, widgetCode),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter),
      setSelectedUser: user => setSelectedUserThunk(user)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(User);
