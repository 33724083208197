import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './RiskCategory.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getRiskCategory, getGeneralData } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialRiskCategoryState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import Banner from '../Banner/Banner';
import {
  describeRiskCategoryThunk,
  saveRiskCategoryThunk,
  setRiskCategoryChangeThunk,
  setRiskCategoryCleanUpThunk,
  setRiskCategoryMatrixChangeThunk
} from '../../store/actions/riskCategory';
import uuid, { validate } from 'uuid';
import RiskCategoryMatrixTable from './RiskCategoryMatrixTable';
import Table from 'react-bootstrap/Table';
import { LIKELIHOOD } from '../../constants/index';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const RiskCategory = ({ intl }) => {
  let history = useHistory();
  let { riskCategoryId } = useParams();

  const dispatch = useDispatch();
  const riskCategory = useSelector(state => getRiskCategory(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);
  const generalData = useSelector(state => getGeneralData(state));

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});

  const backUrl = '/operational-setup/risk-categories';
  let widgetCode = '';
  if (riskCategoryId) {
    IsEdit = true;
    widgetCode = 'WQSP_RISKCATEGORY_UPDATE';
  }

  useEffect(() => {
    if (riskCategoryId) {
      dispatch(describeRiskCategoryThunk(riskCategoryId, widgetCode));
    }
  }, [describeRiskCategoryThunk, dispatch, riskCategoryId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setRiskCategoryCleanUpThunk(initialRiskCategoryState.riskCategory.SelectedRiskCategory));
    };
  }, []);

  // Update redux store
  const setLocalRiskCategory = currentState => {
    dispatch(setRiskCategoryChangeThunk(currentState));
  };

  const matrixLength = riskCategory.SelectedRiskCategory.RiskMatrix.length;
  useEffect(() => {
    if (matrixLength === 0) {
      if (generalData.generalFieldTypes.length > 0) {
        const data = generalData.generalFieldTypes.filter(x => x.FieldType === LIKELIHOOD);
        let matrixData = [];
        data.forEach(item => {
          matrixData.push({
            RiskCategoryMatrixId: 0,
            RiskCategoryId: 0,
            GuidIdentifier: uuidv4(),
            RiskLikelihoodId: item.GeneralFieldTypeId,
            RiskLikelihood: item.Name,
            Description: '',
            InsignificantValue: null,
            MinorValue: null,
            ModerateValue: null,
            MajorValue: null,
            CatastrophicValue: null
          });
        });
        setLocalRiskCategory({
          ...riskCategory,
          SelectedRiskCategory: { ...riskCategory.SelectedRiskCategory, RiskMatrix: matrixData }
        });
      }
    }
  }, [matrixLength, setLocalRiskCategory]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = riskCategory.SelectedRiskCategory;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.RiskCategoryId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_RISKCATEGORY_ADD';
    }

    dispatch(saveRiskCategoryThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !riskCategory ||
      !riskCategory.SelectedRiskCategory ||
      !riskCategory.SelectedRiskCategory.Name ||
      isRequired(riskCategory.SelectedRiskCategory.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'riskCategory.riskCategoryTitleRequired',
        defaultMessage: 'Category title is a mandatory field'
      });
      isValid = false;
    }

    if (
      !riskCategory ||
      !riskCategory.SelectedRiskCategory ||
      !riskCategory.SelectedRiskCategory.LowRatingScore ||
      isRequired(riskCategory.SelectedRiskCategory.LowRatingScore, 1)
    ) {
      formErrors.LowRatingScore = intl.formatMessage({
        id: 'riskCategory.allRiskScoreDataRequired',
        defaultMessage: 'All risk scores are mandatory field'
      });
      isValid = false;
    }
    if (
      !riskCategory ||
      !riskCategory.SelectedRiskCategory ||
      !riskCategory.SelectedRiskCategory.MediumRatingScore ||
      isRequired(riskCategory.SelectedRiskCategory.MediumRatingScore, 1)
    ) {
      formErrors.MediumRatingScore = intl.formatMessage({
        id: 'riskCategory.allRiskScoreDataRequired',
        defaultMessage: 'All risk scores are mandatory field'
      });
      isValid = false;
    }

    if (
      !riskCategory ||
      !riskCategory.SelectedRiskCategory ||
      !riskCategory.SelectedRiskCategory.HighRatingScore ||
      isRequired(riskCategory.SelectedRiskCategory.HighRatingScore, 1)
    ) {
      formErrors.HighRatingScore = intl.formatMessage({
        id: 'riskCategory.allRiskScoreDataRequired',
        defaultMessage: 'All risk scores are mandatory field'
      });
      isValid = false;
    }

    const invalidRange = {
      id: 'riskCategory.invalidRange',
      defaultMessage: 'Invalid matrix range'
    };

    if (
      riskCategory.SelectedRiskCategory.LowRatingScore &&
      riskCategory.SelectedRiskCategory.MediumRatingScore &&
      riskCategory.SelectedRiskCategory.HighRatingScore
    ) {
      if (parseFloat(riskCategory.SelectedRiskCategory.LowRatingScore) > parseFloat(riskCategory.SelectedRiskCategory.MediumRatingScore)) {
        formErrors.LowRatingScore = intl.formatMessage(invalidRange);
        formErrors.MediumRatingScore = intl.formatMessage(invalidRange);
        isValid = false;
      }

      if (parseFloat(riskCategory.SelectedRiskCategory.MediumRatingScore) > parseFloat(riskCategory.SelectedRiskCategory.HighRatingScore)) {
        formErrors.HighRatingScore = intl.formatMessage(invalidRange);
        formErrors.MediumRatingScore = intl.formatMessage(invalidRange);
        isValid = false;
      }
    }

    let needsErrorUpdate = false;
    if (riskCategory.SelectedRiskCategory.RiskMatrix.length > 0) {
      const RiskMatrixErrorData = [];

      riskCategory.SelectedRiskCategory.RiskMatrix.forEach(itemData => {
        if (
          !itemData.InsignificantValue ||
          !itemData.MinorValue ||
          !itemData.ModerateValue ||
          !itemData.MajorValue ||
          !itemData.CatastrophicValue
        ) {
          itemData.hasError = true;
          needsErrorUpdate = true;
        }
        RiskMatrixErrorData.push(itemData);
      });

      if (needsErrorUpdate) {
        dispatch(
          setRiskCategoryMatrixChangeThunk({
            RiskMatrix: RiskMatrixErrorData
          })
        );
      }
    }

    if (
      !riskCategory ||
      !riskCategory.SelectedRiskCategory ||
      !riskCategory.SelectedRiskCategory.RiskMatrix ||
      !riskCategory.SelectedRiskCategory.RiskMatrix.length === 0 ||
      needsErrorUpdate
    ) {
      formErrors.RiskMatrix = intl.formatMessage({
        id: 'riskCategory.allRiskMatrixDataRequired',
        defaultMessage: 'All risk matrix data are mandatory field'
      });

      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalRiskCategory({
      ...riskCategory,
      SelectedRiskCategory: { ...riskCategory.SelectedRiskCategory, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (riskCategory && riskCategory.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const editName = (riskCategory.SelectedRiskCategory ? riskCategory.SelectedRiskCategory.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + editName;
  } else {
    heading = intl.formatMessage({ id: 'riskCategory.addRiskCategory', defaultMessage: 'Add new risk category' });
  }

  return (
    <div className={s.riskCategory}>
      {riskCategory.isLoading && <LoadingSpinner />}
      {riskCategory.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={riskCategory.showBanner}
        status={riskCategory.isOpSuccessful}
        successText={messageText}
      />
      <OperationalSetupContentTemplate selectedPage="riskCategories" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.riskCategoryHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.riskCategoryContent}>
            <Form>
              <div className={s.riskCategoryMainInfo}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formRiskCategoryName">
                      <Form.Label>
                        <FormattedMessage id="common.title" defaultMessage="Title" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={riskCategory.SelectedRiskCategory.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.title',
                          defaultMessage: 'Title'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formRiskCategoryDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={riskCategory.SelectedRiskCategory.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Form>
            <RiskCategoryMatrixTable RiskCategoryData={riskCategory.SelectedRiskCategory.RiskMatrix} />
          </div>
          <Consequences onChange={onChange} riskCategory={riskCategory} localformErrors={localformErrors} intl={intl} />
          <RiskRatingScore onChange={onChange} scoreRow={riskCategory.SelectedRiskCategory} localformErrors={localformErrors} />
          <Row>
            <Col>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChanges)}
                onClick={submitForm}
                noValidate
                data-unittest="saveData"
              >
                <FormattedMessage id="common.save" defaultMessage="SAVE" />
              </Button>

              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancel)}
                onClick={cancelHandler}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
          </Row>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

const Consequences = ({ onChange, riskCategory, localformErrors, intl }) => {
  return (
    <div className={s.riskCategoryContent}>
      <div className={s.riskCategoryMainInfo}>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="formInsignificantDescription">
              <Form.Label>
                <FormattedMessage id="riskCategory.insignificantDescription" defaultMessage="Insignificant Description" />
              </Form.Label>

              <Form.Control
                type="text"
                name="InsignificantDescription"
                onChange={onChange}
                value={riskCategory.SelectedRiskCategory.InsignificantDescription}
                maxLength="30"
                className={`${s.formControl} ${localformErrors && localformErrors.InsignificantDescription ? s.formControlError : ''}`}
                placeholder={intl.formatMessage({
                  id: 'riskCategory.insignificantDescription',
                  defaultMessage: 'Insignificant Description'
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="formMinorDescription">
              <Form.Label>
                <FormattedMessage id="riskCategory.minorDescription" defaultMessage="Minor Description" />
              </Form.Label>

              <Form.Control
                type="text"
                name="MinorDescription"
                onChange={onChange}
                maxLength="30"
                value={riskCategory.SelectedRiskCategory.MinorDescription}
                className={`${s.formControl} ${localformErrors && localformErrors.MinorDescription ? s.formControlError : ''}`}
                placeholder={intl.formatMessage({
                  id: 'riskCategory.minorDescription',
                  defaultMessage: 'Minor Description'
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="formModerateDescription">
              <Form.Label>
                <FormattedMessage id="riskCategory.moderateDescription" defaultMessage="Moderate Description" />
              </Form.Label>

              <Form.Control
                type="text"
                name="ModerateDescription"
                onChange={onChange}
                maxLength="30"
                value={riskCategory.SelectedRiskCategory.ModerateDescription}
                className={`${s.formControl} ${localformErrors && localformErrors.ModerateDescription ? s.formControlError : ''}`}
                placeholder={intl.formatMessage({
                  id: 'riskCategory.moderateDescription',
                  defaultMessage: 'Moderate Description'
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="formMajorDescription">
              <Form.Label>
                <FormattedMessage id="riskCategory.majorDescription" defaultMessage="Major Description" />
              </Form.Label>

              <Form.Control
                type="text"
                name="MajorDescription"
                onChange={onChange}
                maxLength="30"
                value={riskCategory.SelectedRiskCategory.MajorDescription}
                className={`${s.formControl} ${localformErrors && localformErrors.MajorDescription ? s.formControlError : ''}`}
                placeholder={intl.formatMessage({
                  id: 'riskCategory.majorDescription',
                  defaultMessage: 'Major Description'
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="formCatastrophicDescription">
              <Form.Label>
                <FormattedMessage id="riskCategory.catastrophicDescription" defaultMessage="Catastrophic Description" />
              </Form.Label>

              <Form.Control
                type="text"
                name="CatastrophicDescription"
                onChange={onChange}
                maxLength="30"
                value={riskCategory.SelectedRiskCategory.CatastrophicDescription}
                className={`${s.formControl} ${localformErrors && localformErrors.CatastrophicDescription ? s.formControlError : ''}`}
                placeholder={intl.formatMessage({
                  id: 'riskCategory.catastrophicDescription',
                  defaultMessage: 'Catastrophic Description'
                })}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const RiskRatingScore = ({ onChange, scoreRow, localformErrors }) => {
  return (
    <div className={s.riskCategoryContent}>
      <Table variant className={s.innerTable}>
        <tbody>
          <tr className={s.riskScoring}>
            <td width="48%">
              <FormattedMessage id="riskCategory.riskScore" defaultMessage="Risk Score" />
            </td>
            <td width="13%">
              <div className={s.container}>
                <div className={s.flexitem}>
                  <FormattedMessage id="riskCategory.low" defaultMessage="Low" />
                </div>
                <div className={s.fixedSmall}>
                  <span className={s.ratingSpace}> {'<= '} </span>
                </div>
                <div className={s.fixed}>
                  <InputNumber
                    name="LowRatingScore"
                    step=".1"
                    onInputChange={onChange}
                    value={scoreRow.LowRatingScore?.toString()}
                    customClassName={`${s.textRatingInput} ${localformErrors && localformErrors.LowRatingScore ? s.formControlError : ''}`}
                    id="formLowRatingScore"
                  />
                </div>
              </div>
            </td>
            <td width="13%">
              <div className={s.container}>
                <div className={s.flexitem}>
                  <FormattedMessage id="riskCategory.medium" defaultMessage="Medium" />
                </div>
                <div className={s.fixedSmall}>
                  <span className={s.ratingSpace}> {'<='} </span>
                </div>
                <div className={s.fixed}>
                  <InputNumber
                    name="MediumRatingScore"
                    step=".1"
                    onInputChange={onChange}
                    value={scoreRow.MediumRatingScore?.toString()}
                    customClassName={`${s.textRatingInput} ${
                      localformErrors && localformErrors.MediumRatingScore ? s.formControlError : ''
                    }`}
                    id="formMediumRatingScore"
                  />
                </div>
              </div>
            </td>
            <td width="13%">
              <div className={s.container}>
                <div className={s.flexitem}>
                  <FormattedMessage id="riskCategory.high" defaultMessage="High" />
                </div>
                <div className={s.fixedSmall}>
                  <span className={s.ratingSpace}> {'<='} </span>
                </div>
                <div className={s.fixed}>
                  <InputNumber
                    name="HighRatingScore"
                    step=".1"
                    onInputChange={onChange}
                    value={scoreRow.HighRatingScore?.toString()}
                    customClassName={`${s.textRatingInput} ${localformErrors && localformErrors.HighRatingScore ? s.formControlError : ''}`}
                    id="formHighRatingScore"
                  />
                </div>
              </div>
            </td>
            <td width="13%">
              <div className={s.container}>
                <div className={s.flexitem}>
                  <FormattedMessage id="riskCategory.veryHigh" defaultMessage="Very High" />
                </div>
                <div className={s.fixedSmall}>
                  <span className={s.ratingSpace}> {'>'} </span>
                </div>
                <div className={s.fixed}>{scoreRow.HighRatingScore?.toString()}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

RiskCategory.defaultProps = {
  riskCategory: {
    ...initialRiskCategoryState
  }
};

export default injectIntl(RiskCategory);
