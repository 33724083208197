import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Login from './Login';
import { submitLogin } from '../../store/actions/user';
import { getIsLoading, getUserLoginError, getUserLoginIsForgotPassword, getUserLoginErrorMessage } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    isLoading: getIsLoading(state),
    loginError: getUserLoginError(state),
    loginErrorMessage: getUserLoginErrorMessage(state),
    isForgotPassword: getUserLoginIsForgotPassword(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirect: () => push('/dashboard'),
      submitLogin: values => submitLogin(values)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
