import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './Personnel.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import PersonnelListTable from './PersonnelListTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getUserProfileWidget, getPersonnel } from '../../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import OperationalSetupContentTemplate from '../../OperationalSetup/OperationalSetupContentTemplate';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import SearchBar from '../../SearchBar/SearchBar';
import Cookies from 'js-cookie';

import {
  getPersonnelThunk,
  setFilteredDataThunk,
  setCurrentPage,
  savePersonnelThunk,
  setPageFilter,
  getFilterThunk
} from '../../../store/actions/admin/personnel';

const SEARCH_FILTER = 'SEARCH_FILTER';
const SEARCH_FILTER_TYPE_NAME = 'SEARCH_FILTER_TYPE_NAME';
const SEARCH_FILTER_TYPE_COMPANY = 'SEARCH_FILTER_TYPE_COMPANY';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const PersonnelList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const personnel = useSelector(state => getPersonnel(state));

  let disableControls = personnel.isLoading || false;

  const [searchFilter, setSearchFilter] = useState('');
  const [searchfilterType, setSearchFilterType] = useState(SEARCH_FILTER_TYPE_NAME);
  const [personnelId, setPersonnelId] = useState(0);
  const [active, setActive] = useState(false);

  let offset = personnel.offset || 0;
  let limit = personnel.limit || 1000;
  let filterName = personnel.filterBy.filterName || null;
  let filterLocalCompany = personnel.filterBy.filterCompany || null;

  useEffect(() => {
    let filterObj = {
      filterName: filterName,
      filterCompany: filterLocalCompany,
      offset: offset,
      limit: limit
    };
    dispatch(getPersonnelThunk('WQSP_PERSONNEL_LIST', filterObj));
  }, [getPersonnelThunk, filterLocalCompany, filterName]);

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_PERSONNEL_LIST'));
  }, [getFilterThunk]);

  let messageId = (personnel && personnel.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onTypeChange = e => {
    setSearchFilterType(e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onRemovePersonnelClick = personnelId => {
    setActive(true);
    setPersonnelId(personnelId);
  };

  const DeletePersonnel = () => {
    let saveData = personnel.selectedPersonnel;
    saveData.PerformDelete = true;
    saveData.PersonnelId = personnelId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(savePersonnelThunk(saveData, 'WQSP_PERSONNEL_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setPersonnelId(0);
  };

  const handleConfirm = () => {
    DeletePersonnel();
    handleClose();
  };

  let typePlaceHolder = intl.formatMessage({ id: 'common.name', defaultMessage: 'Name' });

  const typeOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="common.name" defaultMessage="Name" />,
      value: SEARCH_FILTER_TYPE_NAME
    });

    statusData.push({
      label: <FormattedMessage id="personnel.company" defaultMessage="Company" />,
      value: SEARCH_FILTER_TYPE_COMPANY
    });

    return statusData;
  };

  const filteredResults = (filterType, value) => {
    let filteredList = personnel.personnels;

    let apifilterObj = {
      filterName: null,
      filterCompany: null
    };

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_TYPE_COMPANY) {
      if (value !== '') {
        apifilterObj.filterCompany = value;
        filteredList = filteredList.filter(function(item) {
          return item.Company.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_TYPE_COMPANY) {
        apifilterObj.filterCompany = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Company.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_TYPE_NAME) {
      if (value !== '') {
        apifilterObj.filterName = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_TYPE_NAME) {
        apifilterObj.filterName = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (personnel.personnelListCount > personnel.personnels.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let personnelObj = Object.values(personnel.filteredResults);

  const onAddNewClick = () => {
    history.push('/operational-setup/personnels/personnel');
  };

  const onChangePersonnelClick = personnelId => {
    history.push(`/operational-setup/personnels/personnel/${personnelId}`);
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_PERSONNEL_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_PERSONNEL_DELETE');
  //const isUpdateAllowed = true;

  const dialogTitle = intl.formatMessage({
    id: 'personnel.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this personnel?'
  });

  return (
    <div className={s.personnelList}>
      <OperationalSetupContentTemplate selectedPage="personnels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
          {personnel.isLoading && <LoadingSpinner />}
          {personnel.showBanner && (
            <Banner
              failureText={messageText}
              showBanner={personnel.showBanner}
              status={personnel.isOpSuccessful}
              successText={messageText}
            />
          )}
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={6}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId={
                  searchfilterType === SEARCH_FILTER_TYPE_NAME ? 'personnel.searchLabelName' : 'personnel.searchLabelCompany'
                }
                id="txtSearchBar"
                showFilterType
                searchfilterType={searchfilterType}
                onFilterTypeChange={onTypeChange}
                filterTypeData={typeOptions()}
                filterPlaceholderText={typePlaceHolder}
              />
            </Col>
            <Col sm={6} lg={6}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_PERSONNEL_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addPersonnelButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={personnelObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={personnel.currentPage}
            >
              <PersonnelListTable
                ChangePersonnel={onChangePersonnelClick}
                RemovePersonnel={onRemovePersonnelClick}
                UpdateAllowed={isUpdateAllowed}
                DeleteAllowed={isDeleteAllowed}
              />
            </Pagination>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

export default injectIntl(PersonnelList);
