import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';

import {
  REGULATORY_REGISTER_ERROR,
  GET_ALL_REGULATORY_REGISTER_DETAILS,
  REGULATORY_REGISTER_IS_LOADING,
  SET_REGULATORY_REGISTER_CURRENT_PAGE,
  SET_REGULATORY_REGISTER_PAGE_FILTER,
  SET_REGULATORY_REGISTER_CHANGE,
  SET_REGULATORY_REGISTER,
  UPDATE_REGULATORY_REGISTER_SAVE_STATUS,
  SET_REGULATORY_REGISTER_FILTERS,
  SET_REGULATORY_REGISTER_RESULTS,
  REMOVE_REGULATORY_REGISTER
} from '../../../constants/index';

import { REGULATORY_REGISTER_MANAGEMENT, CREATE_REGULATORY_REGISTER, UPDATE_REGULATORY_REGISTER } from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setRegulatoryRegisterErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setRegulatoryRegisterError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setRegulatoryRegisterError(messageCode, false));
  }, 2500);
};

export const setRegulatoryRegisterError = (messageCode, status) => {
  return {
    type: REGULATORY_REGISTER_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: REGULATORY_REGISTER_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getRegulatoryRegisterThunk = (widgetCode, filterObj, safetyPlanId) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getRegulatoryRegister(widgetCode, orgId, filterObj, safetyPlanId));
};

export const getRegulatoryRegister = (widgetCode, orgId, filterObj, safetyPlanId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp/regulatoryregister`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      WaterQualitySafetyPlanId: safetyPlanId,
      filter: filterObj.filter,
      limit: filterObj.limit,
      offset: filterObj.offset,
      filterName: filterObj.filterName,
      filterJurisdiction: filterObj.filterJurisdiction,
      filterDocumentTypeId: filterObj.filterDocumentTypeId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllRegulatoryRegisterListThunk(retData),
    onFailure: err => setRegulatoryRegisterErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllRegulatoryRegisterListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let regulatoryRegisters = (retData && retData.retData.data) || [];
  dispatch(setAllRegulatoryRegisterList(messageCode, regulatoryRegisters));
};

export const setAllRegulatoryRegisterList = (messageCode, regulatoryRegisters) => ({
  type: GET_ALL_REGULATORY_REGISTER_DETAILS,
  payload: {
    data: {
      regulatoryRegisters: regulatoryRegisters,
      filteredResults: regulatoryRegisters,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_REGULATORY_REGISTER_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_REGULATORY_REGISTER_PAGE_FILTER,
  payload: { filter }
});

export const describeRegulatoryRegisterThunk = (RegulatoryRegisterId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeRegulatoryRegister(RegulatoryRegisterId, widgetCode, orgId));
};

export const describeRegulatoryRegister = (RegulatoryRegisterId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp/regulatoryregister`,
    data: {
      RegulatoryRegisterId: RegulatoryRegisterId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setRegulatoryRegisterThunk(retData, RegulatoryRegisterId),
    onFailure: err => setRegulatoryRegisterErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setRegulatoryRegisterThunk = ({ retData }, RegulatoryRegisterId) => dispatch => {
  let data = retData.data;
  if (RegulatoryRegisterId && (!data || data.length === 0 || parseInt(RegulatoryRegisterId) !== data.RegulatoryRegisterId)) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setRegulatoryRegister(data));
};

export const setRegulatoryRegister = data => ({
  type: SET_REGULATORY_REGISTER,
  payload: { selectedRegulatoryRegister: data }
});

export const saveRegulatoryRegisterThunk = (regulatoryRegister, widgetCode) => dispatch => {
  let url = '/waterqualitysafetyplans/wqsp/regulatoryregister/save';
  let actionName = regulatoryRegister.RegulatoryRegisterId === 0 ? CREATE_REGULATORY_REGISTER : UPDATE_REGULATORY_REGISTER;
  let log = logEntry(REGULATORY_REGISTER_MANAGEMENT, actionName, regulatoryRegister);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  regulatoryRegister.OrganisationId = orgId;
  dispatch(saveRegulatoryRegister(regulatoryRegister, url, log, widgetCode));
};

export const saveRegulatoryRegister = (regulatoryRegister, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setRegulatoryRegisterStatusThunk(retData, regulatoryRegister),
    onFailure: err => setRegulatoryRegisterErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: regulatoryRegister,
      log: log
    },
    widgetcode: widgetCode
  });

export const setRegulatoryRegisterStatusThunk = (message, regulatoryRegister) => dispatch => {
  dispatch(setRegulatoryRegisterStatus(message, true, false));

  setTimeout(() => {
    //Hide the banner
    let isRedirect = !regulatoryRegister.PerformDelete;
    dispatch(setRegulatoryRegisterStatus(message, false, isRedirect));
    if (regulatoryRegister.PerformDelete) {
      dispatch(removeRegulatoryRegister(regulatoryRegister.RegulatoryRegisterId));
    }
  }, 2500);
};

export const removeRegulatoryRegister = data => ({
  type: REMOVE_REGULATORY_REGISTER,
  payload: {
    RegulatoryRegisterId: data
  }
});

export const setRegulatoryRegisterStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_REGULATORY_REGISTER_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const setRegulatoryRegisterChangeThunk = retData => dispatch => {
  dispatch(setRegulatoryRegisterChange(retData));
};

export const setRegulatoryRegisterChange = retData => ({
  type: SET_REGULATORY_REGISTER_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, safetyPlanId) => dispatch => {
  dispatch(getFilter(widgetCode, safetyPlanId));
};

export const getFilter = (widgetCode, safetyPlanId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      WaterQualitySafetyPlanId: safetyPlanId,
      filterFor: 'regulatoryRegisterListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setRegulatoryRegisterErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_REGULATORY_REGISTER_FILTERS,
  payload: {
    data: {
      isLoading: false,
      regulatoryRegisterListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_REGULATORY_REGISTER_RESULTS,
  payload: retData
});
