import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import Files from 'react-files';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import s from './FileUploader.module.scss';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Alert from 'react-bootstrap/Alert';
import uploadFile from '../../../assets/cloud-upload-file-grey.svg';
import tick from '../../../assets/tick-green-filled.svg';
import error from '../../../assets/error-red-filled.svg';
import alert from '../../../assets/alert.svg';
import cross from '../../../assets/close-button-icon.svg';
import uuidv4 from 'uuid/v4';
import Cookies from 'js-cookie';
//Usage example
//<FileUploader widgetCode={code} allowedFileTypes={[['image/jpeg', 'jpeg'], ['application/pdf', 'pdf'], ['application/msword', 'doc']]} location={'test'} enableMultipleUpload={true} maxFiles={5} onUploadComplete={onUploadComplete} />
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const FileUploader = ({
  widgetCode,
  location,
  enableDragAndDrop,
  allowedFileTypes,
  enableMultipleUpload,
  maxFiles,
  onUploadStart,
  onUploadComplete,
  colSize,
  intl
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileTypeError, setFileTypeError] = useState(null);
  const [uploadComponent, setUploadComponent] = useState('preUpload');
  useEffect(() => {
    setSelectedFiles([]);
  }, []);

  const onFileSelection = (files, event) => {
    if (files.length > 0) {
      setSelectedFiles([...selectedFiles, ...files]);
      setComponent('uploadStatus');
    }
  };

  const onLocalUploadComplete = files => {
    onUploadComplete(files);
    setSelectedFiles([]);
  };

  const onFileSelectionError = error => {
    var regex = RegExp(/. is not a valid file type/);
    if (regex.test(error)) {
      let errorMessageText = intl.formatMessage({
        id: 'fileUpload.fileCouldNotBeUploaded',
        defaultMessage: 'Files could not be uploaded. Only files with the following extensions are allowed:'
      });
      errorMessageText = `${errorMessageText} ${allowedFileTypes.filter(type => type[1]).join()}`;
      setFileTypeError(errorMessageText);
    } else {
      setFileTypeError(null);
    }
  };

  const setComponent = component => {
    setUploadComponent(component);
  };

  const uploadFiles = () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    if (onUploadStart) onUploadStart(selectedFiles);

    const filesToUpload = [...selectedFiles];
    filesToUpload.map(async (file, index) => {
      if (file.uploadCompleted) {
        return true;
      }
      file.isUploading = true;
      file.fileGuid = uuidv4();
      let updatedFiles = findAndReplaceSelectedFile(file, index);
      setSelectedFiles(updatedFiles);

      let bucketInfo = await getS3BucketInfo({ file: file, location: location });
      if (!bucketInfo || !bucketInfo.uploadURL || !bucketInfo.UploadedFileId) {
        file.uploadError = true;
        file.isUploading = false;
        let updatedErrFiles = findAndReplaceSelectedFile(file, index);
        setSelectedFiles(updatedErrFiles);
        return true;
      }

      const options = {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: progressEvent => {
          const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          file.progress = progress;

          let updateProgressFiles = findAndReplaceSelectedFile(file, index);
          setSelectedFiles(updateProgressFiles);
        }
      };

      delete axios.defaults.headers.common['Authorization'];
      axios
        .put(bucketInfo.uploadURL, file, options)
        .then(result => {
          file.uploadCompleted = true;
          file.isUploading = false;
          file.S3URL = bucketInfo.S3URL;
          file.UploadedFileId = bucketInfo.UploadedFileId;
          let updatedFiles = findAndReplaceSelectedFile(file, index);
          setSelectedFiles(updatedFiles);
        })
        .catch(error => {
          file.uploadError = true;
          file.isUploading = false;
          let updatedErrFiles = findAndReplaceSelectedFile(file, index);
          setSelectedFiles(updatedErrFiles);
        });
    });

    //onUploadComplete(selectedFiles);
  };

  const findAndReplaceSelectedFile = (fileToReplace, selectedIndex) => {
    const retItem = selectedFiles.map((file, index) => {
      const item = selectedIndex === index ? fileToReplace : file;
      return item;
    });
    return retItem;
  };

  const getS3BucketInfo = async ({ file, location }) => {
    let reqData = {
      fileName: file.name,
      contentType: file.type,
      location: location,
      fileGuid: file.fileGuid,
      updatedBy: Cookies.get(`userid-${envName}`) || 0
    };

    try {
      let respData = await fetch(`${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/fileupload`, {
        method: 'post',
        body: JSON.stringify(reqData),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + Cookies.get(`access-${envName}`),
          widgetcode: widgetCode || 'GENERALACCESS',
          accessid: Cookies.get(`selectedprofileid-${envName}`) || ''
        }
      });

      //Reading from stream and converting to JSON
      let retData = await respData.json();

      if (!respData.ok) {
        //fetch do not throw exception for http error 400, so manually throwing exception
        throw retData;
      }

      let messageCode = retData && retData.message;
      let s3Info = (retData && retData.s3Info) || {};
      s3Info.status = true;
      s3Info.messageCode = messageCode;

      return s3Info;
    } catch (err) {
      let messageCode = (err && err.errorMessage) || null;
      messageCode = messageCode && messageCode.replace('[400]=>', '');
      return { status: false, messageCode: messageCode };
    }
  };

  const onSelectedFileRemove = (file, index) => {
    setSelectedFiles([...selectedFiles.slice(0, index), ...selectedFiles.slice(index + 1)]);
  };

  const allFilesUploaded = () => {
    if (selectedFiles.length === 0) {
      return false;
    }
    if (selectedFiles.filter(file => !file.uploadCompleted).length > 0) {
      return false;
    }
    return true;
  };

  const renderUploadComponent = () => {
    switch (uploadComponent) {
      case 'preUpload':
        return (
          <FileUpload
            enableDragAndDrop={enableDragAndDrop}
            allowedFileTypes={allowedFileTypes}
            enableMultipleUpload={enableMultipleUpload}
            maxFiles={maxFiles}
            onFileSelection={onFileSelection}
            onFileSelectionError={onFileSelectionError}
            selectedFiles={selectedFiles}
            setComponent={setComponent}
          />
        );
      case 'uploadStatus':
        return (
          <FileUploadStatus
            selectedFiles={selectedFiles}
            onSelectedFileRemove={onSelectedFileRemove}
            uploadFiles={uploadFiles}
            setComponent={setComponent}
          />
        );
      case 'inProgress':
        if (allFilesUploaded()) {
          setUploadComponent('uploadSaveStatus');
        }
        return <FileUploadInProgress selectedFiles={selectedFiles} setComponent={setComponent} uploadFiles={uploadFiles} />;
      case 'uploadSaveStatus':
        return <FileUploadSaveStatus selectedFiles={selectedFiles} onUploadComplete={onLocalUploadComplete} setComponent={setComponent} />;
      default:
        return (
          <FileUpload
            enableDragAndDrop={enableDragAndDrop}
            allowedFileTypes={allowedFileTypes}
            enableMultipleUpload={enableMultipleUpload}
            maxFiles={maxFiles}
            onFileSelection={onFileSelection}
            onFileSelectionError={onFileSelectionError}
          />
        );
    }
  };

  return (
    <div className={s.fileUploader}>
      <Row>
        <Col lg={colSize ?? 4}>
          <div className={s.messagebox}>
            {fileTypeError ? (
              <Alert variant="light">
                <Row>
                  <Col sm={1}>
                    <img src={alert} alt="icon" className={s.error}></img>
                  </Col>
                  <Col sm={11}>
                    <div className={s.messageText}>{fileTypeError && <FormattedMessage id={fileTypeError} />}</div>
                  </Col>
                </Row>
              </Alert>
            ) : (
              ''
            )}
          </div>
          <div className={s.uploadContent}>{renderUploadComponent()}</div>
        </Col>
      </Row>
    </div>
  );
};

const FileUpload = ({
  enableDragAndDrop,
  allowedFileTypes,
  enableMultipleUpload,
  maxFiles,
  selectedFiles,
  onFileSelectionError,
  setComponent,
  onFileSelection
}) => {
  const refFiles = useRef(null);

  const onNextClick = e => {
    e.preventDefault();
    setComponent('uploadStatus');
    return false;
  };

  return (
    <div>
      <Files
        className="files-dropzone"
        ref={refFiles}
        onChange={onFileSelection}
        onError={onFileSelectionError}
        accepts={allowedFileTypes.map(type => type[0])}
        multiple={enableMultipleUpload}
        maxFiles={enableMultipleUpload ? maxFiles || 10 : 1}
        maxFileSize={10000000}
        minFileSize={0}
        clickable
      >
        <Row>
          <Col>
            <div className="text-center">
              <Row>
                <Col>
                  <img src={uploadFile} alt="icon" className={s.uploadicon}></img>
                </Col>
              </Row>
              <Row>
                <Col className={s.textDrag}>
                  <FormattedMessage id="fileUpload.dragndrop" defaultMessage="Drag and drop file here" />
                </Col>
              </Row>
              <Row>
                <Col className={s.or}>
                  <FormattedMessage id="fileUpload.or" defaultMessage="or" />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button variant="outline-secondary" className={s.btnUpload}>
              <FormattedMessage id="fileUpload.browse" defaultMessage="BROWSE FILE" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className={s.texFile}>
            <FormattedMessage id="fileUpload.acceptedfile" defaultMessage="Accepted file formats:" />{' '}
            {allowedFileTypes.map(type => type[1]).join(',')}
          </Col>
        </Row>
      </Files>
      {/* <Row>
        <Col className="text-center">
          {selectedFiles.length > 0 && (
            <a variant="outline-secondary" className={s.btnUploadNext} onClick={onNextClick}>
              <FormattedMessage id="fileUpload.fileList" defaultMessage="File List" />
            </a>
          )}
        </Col>
      </Row> */}
    </div>
  );
};

const FileUploadStatus = ({ setComponent, selectedFiles, onSelectedFileRemove, uploadFiles }) => {
  const changeComponent = componentName => {
    setComponent(componentName);
  };

  const onBtnBackClick = () => {
    changeComponent('preUpload');
  };

  const onUploadClick = () => {
    changeComponent('inProgress');
    uploadFiles();
  };

  if (selectedFiles.length === 0) {
    onBtnBackClick();
  }

  return (
    <div className={s.fileupload}>
      <div>
        {selectedFiles.map((file, index) => {
          return (
            <Row key={`${index}`} className={s.rowFile}>
              <Col xs={10} className={s.textMediumMessageAlignLeft}>
                {file.name}
              </Col>
              <Col xs={2}>
                {!file.isUploading && !file.uploadCompleted && (
                  <img
                    src={error}
                    alt="icon"
                    className={s.removeBtn}
                    onClick={() => {
                      onSelectedFileRemove(file, index);
                    }}
                  />
                )}
              </Col>
            </Row>
          );
        })}
      </div>

      <Row>
        <Col>
          <Button variant="primary" className={s.btnUpload} onClick={onUploadClick}>
            <FormattedMessage id="fileUpload.upload" defaultMessage="UPLOAD" />
          </Button>

          <Button variant="outline-secondary" className={s.btnNext} onClick={onBtnBackClick}>
            <FormattedMessage id="fileUpload.addMore" defaultMessage="ADD MORE" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const FileUploadInProgress = ({ setComponent, selectedFiles, uploadFiles }) => {
  const onBtnRetryClick = () => {
    uploadFiles();
  };

  const onNextClick = () => {
    setComponent('uploadSaveStatus');
  };

  const errorInUploadingFiles = () => {
    if (selectedFiles.filter(file => file.uploadError).length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className={s.fileupload}>
      <Row>
        <Col className={s.textMediumMessage}>
          <FormattedMessage id="fileUpload.uploadingFiles" defaultMessage="Uploading files" />
        </Col>
      </Row>
      <div>
        {selectedFiles.map((file, index) => {
          return (
            <Fragment key={`${index}`}>
              <Row>
                <Col xs={10} className={s.textMediumMessageAlignLeft}>
                  {file.name}
                </Col>
                <Col xs={2}>
                  {file.uploadCompleted && <img src={tick} alt="icon" className={s.greenTick} />}
                  {file.uploadError && <img src={error} alt="icon" className={s.removeBtn} />}
                </Col>
              </Row>
              <Row>{!file.uploadCompleted && <ProgressBar key={`progress${index}`} completed={file.progress || 0} />}</Row>
            </Fragment>
          );
        })}
        {errorInUploadingFiles() && (
          <Row>
            <Col lg={6}>
              <Button variant="outline-secondary" className={s.btnfileUpload} onClick={onBtnRetryClick}>
                <FormattedMessage id="fileUpload.tryAgain" defaultMessage="TRY AGAIN" />
              </Button>
            </Col>
            <Col lg={6}>
              <Button variant="outline-secondary" className={s.btnfileUpload} onClick={onNextClick}>
                <FormattedMessage id="fileUpload.next" defaultMessage="NEXT" />
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

const FileUploadSaveStatus = ({ setComponent, selectedFiles, onUploadComplete }) => {
  const onCompleteClick = () => {
    onUploadComplete(selectedFiles);
    setComponent('preUpload');
  };

  const errorInUploadingFiles = () => {
    if (selectedFiles.filter(file => file.uploadError).length > 0) {
      return true;
    }
    return false;
  };

  const changeComponent = () => {
    setComponent('preUpload');
  };

  return (
    <div className={s.fileupload}>
      <Row>
        <Col>
          {errorInUploadingFiles() ? (
            <img src={error} alt="icon" className={s.errorCross}></img>
          ) : (
            <img src={tick} alt="icon" className={s.successTick}></img>
          )}
        </Col>
      </Row>
      <Row>
        <Col className={s.textMediumMessage}>
          {errorInUploadingFiles() ? (
            <FormattedMessage id="fileUpload.savefailed" defaultMessage="Please check the file and try again." />
          ) : (
            <FormattedMessage id="fileUpload.saveSuccess" defaultMessage="Upload Successful" />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {errorInUploadingFiles() ? (
            <Button
              variant="primary"
              className={s.btnAddUnit}
              onClick={() => {
                changeComponent('preUpload');
              }}
            >
              <FormattedMessage id="fileUpload.tryAgain" defaultMessage="TRY AGAIN" />
            </Button>
          ) : (
            <Button variant="primary" className={s.btnViewUnit} onClick={onCompleteClick}>
              <FormattedMessage id="fileUpload.viewUnit" defaultMessage="COMPLETE" />
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

const ProgressBar = ({ completed }) => {
  return (
    <div className={s.progressBar}>
      <div style={{ width: `${completed}%` }} className={s.fillerStyles}></div>
    </div>
  );
};

FileUploader.propTypes = {
  widgetCode: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  enableDragAndDrop: PropTypes.bool,
  allowedFileTypes: PropTypes.array.isRequired,
  enableMultipleUpload: PropTypes.bool,
  maxFiles: PropTypes.number,
  onUploadComplete: PropTypes.func.isRequired
};

export default injectIntl(FileUploader);
