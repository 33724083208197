import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './HazardEvent.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialHazardEventState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getDateInDDMMYYYFormat } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import HazardEventItemTable from './HazardEventItemTable';
import 'react-datetime/css/react-datetime.css';
import {
  getUserProfileWidget,
  getGeneralData,
  getHazardEvent,
  getHazard,
  getHazardEventLibrary,
  getCurrentTimezone
} from '../../selectors/index';
import { describeHazardEventThunk, saveHazardEventThunk, setHazardEventChangeThunk } from '../../store/actions/hazardEvent/hazardEvent';
import { saveHazardEventTemplateThunk } from '../../store/actions/hazardEventLibrary';
import { getHazardThunk } from '../../store/actions/hazards';
import { HAZARD_CLASS, RISK_STATUS, DOCUMENT_TYPE_HAZARD_EVENT } from '../../constants/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import MoreOption from '../WSAControls/MoreOption/MoreOption';
import classNames from 'classnames';
import HazardEventComments from './HazardEventComments';
import RiskAssessmentItemTable from './RiskAssessmentItemTable';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';
import IncidentReportItemsTable from '../IncidentReport/IncidentReportItemsTable';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const HazardEventView = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  const backUrl = '/hazard-events';
  const { hazardEventId, siteId, segmentName, entityId, selectedTab } = useParams();
  const history = useHistory();

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const hazardEvent = useSelector(state => getHazardEvent(state));
  const hazardEventTemplate = useSelector(state => getHazardEventLibrary(state));
  const hazard = useSelector(state => getHazard(state));
  const generalData = useSelector(state => getGeneralData(state));
  const riskStatuses = generalData.generalFieldTypes.filter(item => item.FieldType === RISK_STATUS);

  const hazardClasses = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_CLASS);
  let disableControls = hazardEvent.isLoading || false;

  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const actionName = hazardEvent.selectedHazardEvent.IsActioned ? yesText : noText;

  if (hazardEventId && hazardEventId > 0) {
    IsEdit = true;
  } else {
    hazardEventId = 0;
  }
  let widgetCode = 'WQSP_HAZARDEVENT_VIEW';

  let addCommentsAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_HAZARDEVENTCOMMENT_ADD`);
  let addEventToLibraryAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_HAZARDEVENTTEMPLATE_ADDNEW`);
  let addERPAllowed = false; // IsWidgetAccessible(userProfileWidgets, `WQSP_ERP_ADDNEW`);
  let addControlMeasureAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_CONTROLMEASURES_ADD`);
  let addRiskAssesmentAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_RISKASSESSMENT_ADDNEW`);
  let addImprovementActionAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_IMPROVEMENTACTION_ADD`);
  const isItemListingAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENTITEM_LIST');
  const isRiskAssessmentListAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKASSESSMENT_LIST');
  const allowIncidentReport = IsWidgetAccessible(userProfileWidgets, 'WQSP_INCIDENT_REPORT_ADDNEW');

  useEffect(() => {
    dispatch(getHazardThunk('WQSP_HAZARDS_LIST', {}));
  }, [getHazardThunk]);

  useEffect(() => {
    dispatch(describeHazardEventThunk(hazardEventId, widgetCode));
  }, [describeHazardEventThunk, hazardEventId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setHazardEventChangeThunk({
          ...initialHazardEventState.hazardEvent
        })
      );
    };
  }, []);

  const handleCommand = () => {};

  const saveHazardEventComment = comment => {
    let saveData = {
      ...comment
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.performCommentInsert = true;
    dispatch(saveHazardEventThunk(saveData, 'WQSP_HAZARDEVENTCOMMENT_ADD'));
  };

  const saveHazardEventTemplate = () => {
    let selHazardEvent = hazardEvent.selectedHazardEvent;
    let userId = Cookies.get(`userid-${envName}`) || 1;
    let eventItems = selHazardEvent.hazardEventItems.map(item => {
      return {
        HazardEventLibraryId: 0,
        HazardId: item.HazardId,
        GuidIdentifier: uuidv4(),
        UpdatedBy: userId
      };
    });
    let saveData = {
      Name: selHazardEvent.Name,
      Description: selHazardEvent.Description,
      Basis: selHazardEvent.Basis,
      HazardClass: selHazardEvent.HazardClass,
      EntityTypeId: selHazardEvent.EntityTypeId,
      SelectedHazardEventTemplateItems: eventItems
    };
    saveData.IsEdit = false;
    saveData.UpdatedBy = userId;
    saveData.HazardEventLibraryId = 0;
    saveData.GuidIdentifier = uuidv4();

    dispatch(saveHazardEventTemplateThunk(saveData, 'WQSP_HAZARDEVENTTEMPLATE_ADDNEW'));
  };

  const onChangeClick = id => {
    if (entityId) {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event/${hazardEventId}`);
    } else {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event/${hazardEventId}`);
    }
  };

  const onAddRiskAssessmentClick = () => {
    if (siteId && entityId) {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment`
      );
    } else {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment`
      );
    }
  };

  const onCreateIncidentReport = () => {
    history.push(`/site/${siteId}/respond/2/incident-reports/incident-report`, {
      docType: 'HAZARD_EVENT',
      referedId: hazardEventId,
      obj: hazardEvent.selectedHazardEvent
    });
  };

  const getMoreOptions = () => {
    let options = [];

    if (addEventToLibraryAllowed) {
      options.push({
        Name: <FormattedMessage id="hazardEvent.addHazardEventToLibrary" defaultMessage="Add Hazard Event to library" />,
        Click: saveHazardEventTemplate
      });
    }

    if (addControlMeasureAllowed) {
      options.push({
        Name: <FormattedMessage id="hazardEvent.addControlMeasures" defaultMessage="Add Control Measures" />,
        Url: ``
      });
    }

    if (addRiskAssesmentAllowed) {
      options.push({
        Name: <FormattedMessage id="hazardEvent.addRiskAssessment" defaultMessage="Add Risk Assessment" />,
        Click: onAddRiskAssessmentClick
      });
    }
    if (allowIncidentReport) {
      options.push({
        Name: <FormattedMessage id="incidentReports.createIncidentReport" defaultMessage="CREATE INCIDENT REPORT" />,
        Click: onCreateIncidentReport
      });
    }

    if (addImprovementActionAllowed) {
      options.push({
        Name: <FormattedMessage id="hazardEvent.addImprovementAction" defaultMessage="Add Improvement Action" />,
        Url: ``
      });
    }

    return options;
  };

  const getHazards = () => {
    let hazardEventClass = hazardEvent.selectedHazardEvent.HazardClass.split(',');
    return hazard.Hazards.filter(item => hazardEventClass.find(e => e === item.HazardClassId.toString()));
  };

  const getHazardClassNames = ids => {
    let splitIds = ids.split(',');
    let selClasses = hazardClasses.filter(item => splitIds.find(id => id === item.GeneralFieldTypeId.toString()));
    return (selClasses && selClasses.length && selClasses.map(item => item.Name).join(', ')) || '';
  };

  let messageId = (hazardEvent && hazardEvent.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let libMessageId = (hazardEventTemplate && hazardEventTemplate.displayMessageCode) || 'none';
  const libMessageText = intl.formatMessage({ id: libMessageId, defaultMessage: libMessageId });

  heading = intl.formatMessage({ id: 'common.viewTextCC', defaultMessage: 'View' });
  heading = `${heading} - ${hazardEvent.selectedHazardEvent.Name}`;

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  const identifyHazardCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];
  let breadcrumb = [];

  if (segmentName === 'define') {
    breadcrumb = defineEntityCrumb;
  } else {
    breadcrumb = identifyHazardCrumb;
  }

  return (
    <div className={s.hazardevent}>
      {(hazardEvent.isLoading || hazardEventTemplate.isLoading) && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={hazardEvent.showBanner}
        status={hazardEvent.isOpSuccessful}
        successText={messageText}
      />
      <Banner
        key={uuid()}
        failureText={libMessageText}
        showBanner={hazardEventTemplate.showBanner}
        status={hazardEventTemplate.isOpSuccessful}
        successText={libMessageText}
      />
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {segmentName ? (
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        ) : (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />}</h1>
          </div>
        )}
        <div className={s.contentWrapper}>
          <div>
            <Row>
              <Col>
                <Row className={s.colActionButton}>
                  <Button
                    className={classNames(s.margin5, s.changeButton)}
                    style={WidgetVisibility(userProfileWidgets, 'WQSP_HAZARDEVENT_UPDATE')}
                    onClick={() => {
                      onChangeClick(hazardEventId);
                    }}
                    disabled={disableControls}
                    data-unittest="changeHazardEvent"
                  >
                    <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                  </Button>
                  <MoreOption key={uuidv4()} dataArray={getMoreOptions()} />
                </Row>
              </Col>
            </Row>
          </div>
          <div className={s.hazardeventContent}>
            <Form>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.SiteName}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.WaterSupplyDescriptionName}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.EntityTypeName}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.Entity}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="hazardEvent.hazardCertainty" defaultMessage="Hazard Certainty" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.HazardCertainty}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="hazardEvent.name" defaultMessage="Name" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.Name}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.description" defaultMessage="Description" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.Description || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="hazardEvent.hazardCategory" defaultMessage="Hazard Category" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.HazardCategory || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="hazardEvent.dateIdentified" defaultMessage="Date Identified" />
                  </Form.Label>
                </Col>
                <Col>{getDateInDDMMYYYFormat(hazardEvent.selectedHazardEvent.DateIdentified, currentTimezone)}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.gpsLatitude" defaultMessage="GPS Latitude" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.GpsLatitude || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.gpsLongitude" defaultMessage="GPS Longitude" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.GpsLongitude || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="hazardEvent.basis" defaultMessage="Basis" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.Basis || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="hazardEvent.hazardClass" defaultMessage="Hazard Class" />
                  </Form.Label>
                </Col>
                <Col>{getHazardClassNames(hazardEvent.selectedHazardEvent.HazardClass)}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.lastReviewDate" defaultMessage="Date Of Last Review" />
                  </Form.Label>
                </Col>
                <Col>
                  {(hazardEvent.selectedHazardEvent.LastReviewDate &&
                    getDateInDDMMYYYFormat(hazardEvent.selectedHazardEvent.LastReviewDate, currentTimezone)) ||
                    '-'}
                </Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                  </Form.Label>
                </Col>
                <Col>
                  {(hazardEvent.selectedHazardEvent.NextReviewDate &&
                    getDateInDDMMYYYFormat(hazardEvent.selectedHazardEvent.NextReviewDate, currentTimezone)) ||
                    '-'}
                </Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                  </Form.Label>
                </Col>
                <Col>{hazardEvent.selectedHazardEvent.ReviewFrequencyName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                  </Form.Label>
                </Col>
                <Col>{actionName || '-'}</Col>
              </Row>

              {!hazardEvent.selectedHazardEvent.IsActioned && (
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="common.status" defaultMessage="Status" />
                    </Form.Label>
                  </Col>
                  <Col>{hazardEvent.selectedHazardEvent.StatusName || '-'}</Col>
                </Row>
              )}
            </Form>
          </div>
          <div>
            {isItemListingAllowed && (
              <HazardEventItemTable
                eventItems={hazardEvent.selectedHazardEvent.hazardEventItems}
                hazards={getHazards()}
                hazardClasses={hazardClasses}
                view={true}
                showAddEdit={false}
                userProfileWidgets={userProfileWidgets}
                updateAllowed={false}
              />
            )}
          </div>
          <div>
            {isRiskAssessmentListAllowed && (
              <RiskAssessmentItemTable
                riskAssessmentItems={hazardEvent.selectedHazardEvent.riskAssessments}
                currentTimezone={currentTimezone}
                userProfileWidgets={userProfileWidgets}
                hazardEvent={hazardEvent.selectedHazardEvent}
                riskStatuses={riskStatuses}
                view={true}
              />
            )}
          </div>
          <div>
            {IsEdit && (
              <IncidentReportItemsTable
                referedId={hazardEventId}
                incidentReportItems={hazardEvent.selectedHazardEvent.incidentReports}
                docType={DOCUMENT_TYPE_HAZARD_EVENT}
                mainObj={hazardEvent.selectedHazardEvent}
                userProfileWidgets={userProfileWidgets}
              />
            )}
          </div>
          <div className={s.hazardeventTypeContent}>
            <HazardEventComments
              hazardEvent={hazardEvent}
              saveHazardEventComment={saveHazardEventComment}
              UpdateAllowed={addCommentsAllowed}
              currentTimezone={currentTimezone}
            />
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(HazardEventView);
