import { connect } from 'react-redux';
import Layout from './Layout';
import { bindActionCreators } from 'redux';
import { getCurrentSidebarState, getUserProfileWidget } from '../../selectors/index';
import { toggleSidebar } from '../../store/actions/sidebar';

const mapStateToProps = state => {
  return {
    currentSidebarOpen: getCurrentSidebarState(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleViewSidebar: sidebarOpen => toggleSidebar(sidebarOpen)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
