import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OrganisationList from './OrganisationList';
import { getOrganisationManager, getUserProfileWidget } from '../../selectors/index';
import { getOrganisationsThunk, setCurrentPage, setPageFilter } from '../../store/actions/organisationManager';

const mapStateToProps = state => {
  return {
    organisationManager: getOrganisationManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrganisations: (offset, limit, filter) => getOrganisationsThunk(offset, limit, filter, 'ORGANISATION_TAB'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationList);
