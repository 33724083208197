import React from 'react';
import moment from 'moment-timezone';

export const SectionRespond = ({ sectionData, getImages, getActionName, currentTimezone }) => {
  return (
    <div id="section7" className="pageBreak sectionContent">
      <h1>Section 7 - Respond</h1>
      <div id="incidentReports" className="level1">
        <h2>Incident Reports</h2>
      </div>
      {sectionData.IncidentReports &&
        sectionData.IncidentReports.map(n => {
          if (n.IncidentReportId) {
            return (
              <IncidentReportHtml
                key={n.IncidentReportId}
                item={n}
                id={n.IncidentReportId}
                getImages={getImages}
                getActionName={getActionName}
                currentTimezone={currentTimezone}
              />
            );
          }
        })}
      <div id="emergencyResponses" className="level1">
        <h2>Emergency Responses</h2>
      </div>
      {sectionData.EmergencyResponses &&
        sectionData.EmergencyResponses.map(n => {
          if (n.EmergencyResponsePlanId) {
            return (
              <EmergencyResponseHtml
                key={n.EmergencyResponsePlanId}
                item={n}
                id={n.EmergencyResponsePlanId}
                getImages={getImages}
                getActionName={getActionName}
                currentTimezone={currentTimezone}
              />
            );
          }
        })}
      <div id="correctiveActions" className="level1">
        <h2>Corrective Actions</h2>
      </div>
      {sectionData.CorrectiveActions &&
        sectionData.CorrectiveActions.map(n => {
          if (n.CorrectiveActionId) {
            return <CorrectiveActionHtml key={n.CorrectiveActionId} item={n} id={n.CorrectiveActionId} />;
          }
        })}
    </div>
  );
};

const IncidentReportHtml = ({ item, getImages, getActionName, currentTimezone }) => {
  return (
    <div>
      <h4>{`Incident Report for ${item.Title}`}</h4>
      <table className="level1-table">
        <tr>
          <td>Water Supply Name </td>
          <td>{item.WaterSupplyDescriptionName}</td>
        </tr>
        <tr>
          <td>Source Entity Type </td>
          <td>{item.EntityTypeName}</td>
        </tr>
        <tr>
          <td>Element Name </td>
          <td>{item.EntityName}</td>
        </tr>
        <tr>
          <td>Title</td>
          <td>{item.Title}</td>
        </tr>
        <tr>
          <td>Description </td>
          <td>{item.Description}</td>
        </tr>
        <tr>
          <td>Incident Date/Time </td>
          <td>
            {item.IncidentDateTime
              ? moment
                  .unix(parseInt(item.IncidentDateTime))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Address </td>
          <td>{item.Address}</td>
        </tr>
        <tr>
          <td>Person Reporting </td>
          <td>{item.PersonReportingName}</td>
        </tr>
        <tr>
          <td>Person Investigating </td>
          <td>{item.PersonInvestigatingName}</td>
        </tr>
        <tr>
          <td>Actioned</td>
          <td>{getActionName(item.IsActioned)}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{item.StatusName}</td>
        </tr>
        <tr>
          <td>Incident Category </td>
          <td>{item.CategoryName}</td>
        </tr>
        <tr>
          <td>Is HACCP Critical </td>
          <td>{item.IsHACCPCritical ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <td>Images</td>
          <td>{getImages(item.Images)}</td>
        </tr>
      </table>
    </div>
  );
};

const EmergencyResponseHtml = ({ item, getImages, getActionName, currentTimezone }) => {
  return (
    <div>
      <h4>{`Emergency Response of ${item.Name}`}</h4>
      <table className="level1-table">
        <tr>
          <td>Name</td>
          <td>{item.Name}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{item.Description}</td>
        </tr>
        <tr>
          <td>Water Supply Name </td>
          <td>{item.WaterSupplyDescriptionName}</td>
        </tr>
        <tr>
          <td>Element Type </td>
          <td>{item.EntityTypeName}</td>
        </tr>
        <tr>
          <td>Element Name </td>
          <td>{item.EntityName}</td>
        </tr>
        <tr>
          <td>Hazard Event </td>
          <td>{item.HazardEventName}</td>
        </tr>
        <tr>
          <td>Checklist Template </td>
          <td>{item.ChecklistTemplateName}</td>
        </tr>
        <tr>
          <td>Stakeholder</td>
          <td>{item.StakeholderRegisterName}</td>
        </tr>
        <tr>
          <td>Last Review Date </td>
          <td>
            {item.LastReviewDate
              ? moment
                  .unix(parseInt(item.LastReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Next Review Date </td>
          <td>
            {item.NextReviewDate
              ? moment
                  .unix(parseInt(item.NextReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Review Frequency</td>
          <td>{item.ReviewFrequencyName}</td>
        </tr>
        <tr>
          <td>Actioned</td>
          <td>{getActionName(item.IsActioned)}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{item.StatusName}</td>
        </tr>
        <tr>
          <td>Images</td>
          <td>{getImages(item.Images)}</td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

const CorrectiveActionHtml = ({ item }) => {
  return (
    <div>
      <h4>{`Corrective Action of ${item.Title}`}</h4>
      <table className="level1-table">
        <tr>
          <td>ControlMonitoringName</td>
          <td>{item.ControlMonitoringName}</td>
        </tr>
        <tr>
          <td>Team</td>
          <td>{item.TeamName}</td>
        </tr>
        <tr>
          <td>Title</td>
          <td>{item.Title}</td>
        </tr>
        <tr>
          <td>When Action Triggered </td>
          <td>{item.WhenActionTriggered}</td>
        </tr>
        <tr>
          <td>Details</td>
          <td>{item.Details}</td>
        </tr>
        <tr>
          <td>Records </td>
          <td>{item.Records}</td>
        </tr>
      </table>
      <br />
      <br />
    </div>
  );
};
