import React from 'react';
import PropTypes from 'prop-types';
import s from './Timezone.module.scss';
import InputTemplate from '../InputTemplate/InputTemplate';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';
import { DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const Timezone = ({ updateTimezone, currentTimezone, intl }) => {
  const handleChange = selectedTimezone => {
    updateTimezone(selectedTimezone);
    Cookies.set(`defaultTimezone-${envName}`, selectedTimezone, { domain: DOMAIN_NAME, secure: IS_LOCALHOST ? false : true });
  };

  const defaultTimezone = Cookies.get(`defaultTimezone-${envName}`);
  if (defaultTimezone && defaultTimezone.length > 0 && defaultTimezone !== currentTimezone) {
    currentTimezone = defaultTimezone;
  }

  const translatedTimezone = intl.formatMessage({ id: 'settings.timezone', defaultMessage: 'Timezone' });
  const Timezones = moment.tz.names();

  return (
    <InputTemplate label={translatedTimezone}>
      <select className={s.timezoneSelect} onChange={e => handleChange(e.target.value)} value={currentTimezone}>
        {Object.keys(Timezones).map(key => (
          <option key={key} value={Timezones[key]}>
            {Timezones[key]}
          </option>
        ))}
      </select>
    </InputTemplate>
  );
};

Timezone.propTypes = {
  updateTimezone: PropTypes.func,
  currentTimezone: PropTypes.string
};

export default injectIntl(Timezone);
