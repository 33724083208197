import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from './Entity.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { compareValues } from '../../utils';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';

const EntityItem = ({ onAddEntityItem, hazardEventTemplates, onCancelEntityItem, hazardEventTemplateItems, intl }) => {
  const [localformErrors, setFormErrors] = useState({});
  const [localHazardTemplate, setLocalHazardTemplate] = useState({});

  const onCancelClick = () => {
    setLocalHazardTemplate({});
    onCancelEntityItem();
  };

  useEffect(() => {
    return () => {
      onCancelClick();
    };
  }, []);

  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }
    let hazard = { ...localHazardTemplate };

    onAddEntityItem(hazard);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!localHazardTemplate || !localHazardTemplate.HazardEventLibraryId) {
      formErrors.HazardEventLibrary = intl.formatMessage({
        id: 'entityItem.hazardEventLibraryRequired',
        defaultMessage: 'Hazard Event Library required'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const hazardEventLibraryOptions = () => {
    let filteredItems = hazardEventTemplateItems.filter(item => !item.IsDeleted);
    let hazardEventTemplateData = [];
    hazardEventTemplates.forEach(element => {
      hazardEventTemplateData.push({
        ...element,
        label: element.Name,
        value: element.HazardEventLibraryId,
        isDisabled: filteredItems.find(item => item.HazardEventLibraryId === element.HazardEventLibraryId) ? true : false
      });
    });

    let sortedData = hazardEventTemplateData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onHazardEventLibraryDropdownChange = e => {
    setLocalHazardTemplate({
      ...localHazardTemplate,
      HazardEventLibraryId: e.value,
      HazardEventLibraryName: e.label
    });
  };

  const onChange = e => {
    setLocalHazardTemplate({
      ...localHazardTemplate,
      [e.target.name]: e.target.value
    });
  };

  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });

  return (
    <div className={s.testcategory}>
      <div className={s.itemContent}>
        <div className={s.topRow}>
          <Row>
            <Col lg={4}>
              <Form.Group controlId="formEntityItem">
                <Form.Label>
                  <FormattedMessage id="entityItem.hazardEventLibrary" defaultMessage="Hazard Event Library" />
                </Form.Label>

                <Dropdown
                  id="formDdlEntityItemHazardEventLibrary"
                  dataArray={hazardEventLibraryOptions()}
                  controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.HazardEventLibrary && s.ddlError }}
                  onDropdownChange={onHazardEventLibraryDropdownChange}
                  data-unittest="formDdlHazardEventLibrary"
                  selectedOption={hazardEventLibraryOptions().filter(option => option.value === localHazardTemplate.HazardEventLibraryId)}
                />

                {localformErrors && localformErrors.HazardEventLibrary && (
                  <p role="alert" className={s.error}>
                    {localformErrors.HazardEventLibrary}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formDdlEntityItemDescription">
                <Form.Label>
                  <FormattedMessage id="entityItem.issueDetails" defaultMessage="Issue Details" />
                </Form.Label>

                <Form.Control
                  className={s.textArea}
                  as="textarea"
                  rows="3"
                  name="IssueDetails"
                  onChange={onChange}
                  placeholder={intl.formatMessage({
                    id: 'entityItem.issueDetails',
                    defaultMessage: 'Issue Details'
                  })}
                  value={localHazardTemplate.IssueDetails}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChangesSmall)}
                onClick={submitForm}
                noValidate
                data-unittest="add"
              >
                <FormattedMessage id="common.add" defaultMessage="ADD" />
              </Button>
              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancelSmall)}
                onClick={onCancelClick}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
            <Col lg={8}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(EntityItem);
