import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';
import _ from 'lodash';

import {
  RISK_ASSESSMENT_ERROR,
  GET_ALL_RISK_ASSESSMENT_DETAILS,
  RISK_ASSESSMENT_IS_LOADING,
  SET_RISK_ASSESSMENT_CURRENT_PAGE,
  SET_RISK_ASSESSMENT_PAGE_FILTER,
  SET_RISK_ASSESSMENT_CHANGE,
  SET_RISK_ASSESSMENT,
  UPDATE_RISK_ASSESSMENT_SAVE_STATUS,
  SET_RISK_ASSESSMENT_FILTERS,
  SET_RISK_ASSESSMENT_RESULTS,
  SET_RISK_ASSESSMENT_COMMENTS,
  REMOVE_RISK_ASSESSMENT
} from '../../constants/index';

import { RISK_ASSESSMENT_MANAGEMENT, CREATE_RISK_ASSESSMENT, UPDATE_RISK_ASSESSMENT } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setRiskAssessmentErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setRiskAssessmentError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setRiskAssessmentError(messageCode, false));
  }, 2500);
};

export const setRiskAssessmentError = (messageCode, status) => {
  return {
    type: RISK_ASSESSMENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: RISK_ASSESSMENT_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getRiskAssessmentThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getRiskAssessment(widgetCode, orgId, filterObj));
};

export const getRiskAssessment = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/riskassessments/riskassessment`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      filter: filterObj.filter,
      filterSiteId: filterObj.filterSiteId,
      filterWaterSupplyDescriptionId: filterObj.filterWaterSupplyDescriptionId,
      filterEntityTypeId: filterObj.filterEntityTypeId,
      filterEntityId: filterObj.filterEntityId,
      filterStatusId: filterObj.filterStatusId,
      filterHazardEventId: filterObj.filterHazardEventId,
      filterInherentRiskRating: filterObj.filterInherentRiskRating,
      filterResidualRiskRating: filterObj.filterResidualRiskRating
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllRiskAssessmentListThunk(retData),
    onFailure: err => setRiskAssessmentErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllRiskAssessmentListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let riskAssessments = (retData && retData.retData.data) || [];
  dispatch(setAllRiskAssessmentList(messageCode, riskAssessments));
};

export const setAllRiskAssessmentList = (messageCode, riskAssessments) => ({
  type: GET_ALL_RISK_ASSESSMENT_DETAILS,
  payload: {
    data: {
      riskAssessments: riskAssessments,
      filteredResults: riskAssessments,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_RISK_ASSESSMENT_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_RISK_ASSESSMENT_PAGE_FILTER,
  payload: { filter }
});

export const describeRiskAssessmentThunk = (RiskAssessmentId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeRiskAssessment(RiskAssessmentId, widgetCode, orgId));
};

export const describeRiskAssessment = (RiskAssessmentId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/riskassessments/riskassessment`,
    data: {
      RiskAssessmentId: RiskAssessmentId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setRiskAssessmentThunk(retData, RiskAssessmentId),
    onFailure: err => setRiskAssessmentErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setRiskAssessmentThunk = ({ retData }, RiskAssessmentId) => dispatch => {
  let selectedRiskAssessment = retData.data;
  if (
    RiskAssessmentId &&
    (!selectedRiskAssessment ||
      selectedRiskAssessment.length === 0 ||
      parseInt(RiskAssessmentId) !== selectedRiskAssessment.RiskAssessmentId)
  ) {
    redirectToPageNotFound(dispatch);
  }
  let details = retData.details || [];
  let comments = retData.comments || [];
  let riskAssessmentLibraries = retData.riskAssessmentLibraries || [];
  let mapData = {
    selectedRiskAssessment: {
      ...selectedRiskAssessment,
      riskAssessmentItems: details,
      details: details,
      comments: comments,
      riskAssessmentLibraries: riskAssessmentLibraries
    }
  };

  dispatch(setRiskAssessment(mapData));
};

export const setRiskAssessment = data => ({
  type: SET_RISK_ASSESSMENT,
  payload: data
});

export const saveRiskAssessmentThunk = (riskAssessment, widgetCode) => dispatch => {
  let url = '/riskassessments/riskassessment/save';
  let actionName = riskAssessment.RiskAssessmentId === 0 ? CREATE_RISK_ASSESSMENT : UPDATE_RISK_ASSESSMENT;
  let log = logEntry(RISK_ASSESSMENT_MANAGEMENT, actionName, riskAssessment);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  riskAssessment.OrganisationId = orgId;
  dispatch(saveRiskAssessment(riskAssessment, url, log, widgetCode));
};

export const saveRiskAssessment = (riskAssessment, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setRiskAssessmentStatusThunk(retData, riskAssessment),
    onFailure: err => setRiskAssessmentErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: riskAssessment,
      log: log
    },
    widgetcode: widgetCode
  });

export const setRiskAssessmentStatusThunk = (data, riskAssessment) => dispatch => {
  let message = data;
  if (riskAssessment.performCommentInsert) {
    dispatch(setRiskAssessmentComments(data.retData));
  } else {
    dispatch(setRiskAssessmentStatus(message, true));
    setTimeout(() => {
      //Hide the banner
      let isRedirect = !riskAssessment.PerformDelete;
      dispatch(setRiskAssessmentStatus(message, false, isRedirect));
      if (riskAssessment.PerformDelete) {
        dispatch(removeRiskAssessment(riskAssessment.RiskAssessmentId));
      }
    }, 2500);
  }
};

export const setRiskAssessmentStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_RISK_ASSESSMENT_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setRiskAssessmentComments = data => ({
  type: SET_RISK_ASSESSMENT_COMMENTS,
  payload: {
    selectedRiskAssessment: {
      comments: data
    }
  }
});

export const removeRiskAssessment = data => ({
  type: REMOVE_RISK_ASSESSMENT,
  payload: {
    riskAssessmentId: data
  }
});

export const setRiskAssessmentChangeThunk = retData => dispatch => {
  dispatch(setRiskAssessmentChange(retData));
};

export const setRiskAssessmentChange = retData => ({
  type: SET_RISK_ASSESSMENT_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId, hazardEventId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId, hazardEventId));
};

export const getFilter = (widgetCode, siteId, hazardEventId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      SiteId: siteId || null,
      HazardEventId: hazardEventId || null,
      filterFor: 'riskAssessmentListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setRiskAssessmentErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_RISK_ASSESSMENT_FILTERS,
  payload: {
    data: {
      isLoading: false,
      filterData: {
        TestPurposes: filterData.testPurposes
      },
      riskAssessmentListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_RISK_ASSESSMENT_RESULTS,
  payload: retData
});
