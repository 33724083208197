import { initialInspectionTypeState } from './initialState';

import {
  INSPECTION_TYPE_ERROR,
  GET_ALL_INSPECTION_TYPE_DETAILS,
  INSPECTION_TYPE_IS_LOADING,
  SET_INSPECTION_TYPE_CURRENT_PAGE,
  SET_INSPECTION_TYPE_PAGE_FILTER,
  SET_INSPECTION_TYPE_CHANGE,
  SET_INSPECTION_TYPE_CLEANUP,
  SET_INSPECTION_TYPE,
  UPDATE_INSPECTION_TYPE_SAVE_STATUS
} from '../../constants/index';

const inspectionTypeReducer = (state = initialInspectionTypeState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_INSPECTION_TYPE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        inspectionType: {
          ...state.inspectionType,
          ...data
        }
      };
    }

    case SET_INSPECTION_TYPE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        inspectionType: {
          ...state.inspectionType,
          currentPage
        }
      };
    }

    case SET_INSPECTION_TYPE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        inspectionType: {
          ...state.inspectionType,
          filter
        }
      };
    }

    case UPDATE_INSPECTION_TYPE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        inspectionType: {
          ...state.inspectionType,
          ...data
        }
      };
    }

    case INSPECTION_TYPE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        inspectionType: {
          ...state.inspectionType,
          isLoading: isLoading
        }
      };
    }

    case INSPECTION_TYPE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        inspectionType: {
          ...state.inspectionType,
          ...data
        }
      };
    }

    case SET_INSPECTION_TYPE: {
      let data = payload;

      return {
        ...state,
        inspectionType: {
          ...state.inspectionType,
          isOpSuccessful: false,
          showBanner: false,
          SelectedInspectionType: {
            ...state.inspectionType.SelectedInspectionType,
            ...data
          }
        }
      };
    }

    case SET_INSPECTION_TYPE_CHANGE: {
      let { SelectedInspectionType } = payload;

      return {
        ...state,
        inspectionType: {
          ...state.inspectionType,
          isOpSuccessful: false,
          showBanner: false,
          SelectedInspectionType: {
            ...state.inspectionType.SelectedInspectionType,
            ...SelectedInspectionType
          }
        }
      };
    }
    case SET_INSPECTION_TYPE_CLEANUP: {
      let SelectedInspectionType = payload;
      return {
        ...state,
        inspectionType: {
          ...state.inspectionType,
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          SelectedInspectionType: SelectedInspectionType
        }
      };
    }

    default:
      return state;
  }
};

export default inspectionTypeReducer;
