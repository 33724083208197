import { initialSafetyPlanReportState } from '../initialState';

import {
  SAFETY_PLAN_REPORT_ERROR,
  GET_ALL_SAFETY_PLAN_REPORT_DETAILS,
  SAFETY_PLAN_REPORT_IS_LOADING,
  SET_SAFETY_PLAN_REPORT_CURRENT_PAGE,
  DOWNLOAD_SAFETY_PLAN_REPORT,
  GENERATE_SAFETY_PLAN_REPORT,
  REMOVE_SAFETY_PLAN_REPORT
} from '../../../constants/index';

const safetyPlanReportReducer = (state = initialSafetyPlanReportState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_SAFETY_PLAN_REPORT_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        safetyPlanReport: {
          ...state.safetyPlanReport,
          ...data
        }
      };
    }

    case SET_SAFETY_PLAN_REPORT_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        safetyPlanReport: {
          ...state.safetyPlanReport,
          currentPage
        }
      };
    }

    case SAFETY_PLAN_REPORT_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        safetyPlanReport: {
          ...state.safetyPlanReport,
          isLoading: isLoading
        }
      };
    }

    case SAFETY_PLAN_REPORT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        safetyPlanReport: {
          ...state.safetyPlanReport,
          ...data
        }
      };
    }

    case DOWNLOAD_SAFETY_PLAN_REPORT: {
      const { data, downloadData } = payload;

      if (downloadData.PreSignedURL) {
        data.showNewDownloadLink = true;
        data.newDownloadLink = downloadData.PreSignedURL;
        data.downloadFetchComplete = true;
      }

      return {
        ...state,
        safetyPlanReport: {
          ...state.safetyPlanReport,
          ...data
        }
      };
    }

    case GENERATE_SAFETY_PLAN_REPORT: {
      const { data, generatedData } = payload;

      if (generatedData.DownloadURL) {
        data.showNewDownloadLink = true;
        data.newDownloadLink = generatedData.DownloadURL;
        let currentList = state.safetyPlanReport.safetyPlanDownloadReportList || [];
        currentList.push(generatedData.SafetyPlanReportList[0]);
        data.safetyPlanDownloadReportList = currentList;
      }
      return {
        ...state,
        safetyPlanReport: {
          ...state.safetyPlanReport,
          ...data
        }
      };
    }

    case REMOVE_SAFETY_PLAN_REPORT: {
      const { safetyPlanReportId } = payload;
      return {
        ...state,
        safetyPlanReport: {
          ...state.safetyPlanReport,
          isOpSuccessful: false,
          isRedirect: false,
          safetyPlanDownloadReportList: [
            ...state.safetyPlanReport.safetyPlanDownloadReportList.filter(item => item.SafetyPlanReportId !== safetyPlanReportId)
          ],
          safetyPlanDownloadReportListCount: state.safetyPlanReport.safetyPlanDownloadReportListCount - 1
        }
      };
    }

    default:
      return state;
  }
};

export default safetyPlanReportReducer;
