import React, { Fragment, useState } from 'react';
import s from './HazardEvent.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import EventItem from './EventItem';
import { WidgetVisibility } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';

const HazardEventItemTable = ({
  eventItems,
  hazardClasses,
  hazards,
  setShowAddEdit,
  showAddEdit,
  disableMode,
  setDisableMode,
  onAddEventItem,
  onChangeEventItem,
  userProfileWidgets,
  updateAllowed,
  view,
  intl
}) => {
  const [searchFilter, setSearchFilter] = useState('');

  const searchHandler = value => {
    setSearchFilter(value);
  };

  let eventItemsFiltered =
    (searchFilter && eventItems.filter(item => item.HazardName.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0)) || eventItems;

  const addNewEventItemHandler = () => {
    setShowAddEdit(true);
    setDisableMode(true);
  };

  const deleteDataHandler = (HazardEventItemId, identifer) => {
    let eventItemList = [...eventItemsFiltered] || [];
    if (HazardEventItemId) {
      eventItemList = eventItemList.map(item => {
        if (item.GuidIdentifier === identifer) {
          item.IsDeleted = true;
        }
        return item;
      });
    } else if (HazardEventItemId === 0) {
      eventItemList = eventItemList.filter(item => item.GuidIdentifier !== identifer);
    }
    onChangeEventItem(eventItemList);
  };

  const onCancelEventItem = () => {
    setShowAddEdit(false);
    setDisableMode(false);
  };

  const getHazardClassName = hazardClassId => {
    return hazardClasses.find(item => item.GeneralFieldTypeId === hazardClassId)?.Name;
  };

  const EventContent = (
    <EventItem onAddEventItem={onAddEventItem} hazards={hazards} eventItems={eventItems} onCancelEventItem={onCancelEventItem} />
  );

  let newRowClass = showAddEdit ? s.newRow : '';

  return (
    <div>
      <div className={s.hazardeventTypeContent}>
        <Row>
          <Col>
            <h4 className={s.subheading}>
              <FormattedMessage id="hazardEvent.hazardEventItems" defaultMessage="Hazard Event Items" />
              <span className={s.circle}>{eventItemsFiltered.filter(item => !item.IsDeleted).length || 0}</span>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              initialText={searchFilter}
              placeHolderTextId="hazardEvent.searchHazardEvent"
            />
          </Col>
          <Col xs={6} lg={8}>
            {(!view && (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_HAZARDEVENTITEM_ADD')}
                onClick={addNewEventItemHandler}
                disabled={disableMode}
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            )) ||
              ''}
          </Col>
        </Row>
      </div>
      <div>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="hazardEvent.hazard" defaultMessage="Hazard" />
              </th>
              <th>
                <FormattedMessage id="common.description" defaultMessage="Description" />
              </th>
              <th>
                <FormattedMessage id="wqsp.common.hazardClass" defaultMessage="Hazard Class" />
              </th>
              {updateAllowed && <th></th>}
            </tr>
          </thead>
          <tbody>
            {eventItemsFiltered.map(item => {
              return (
                !item.IsDeleted && (
                  <tr key={item.GuidIdentifier} data-unittest="EventItemList">
                    <td>{item.HazardName}</td>
                    <td>{item.Description}</td>
                    <td>{getHazardClassName(item.HazardClassId) || ''}</td>
                    {updateAllowed && (
                      <td>
                        <div className={s.buttonContainer}>
                          <Button
                            variant="outline-secondary"
                            className={s.removeButton}
                            href="#"
                            onClick={deleteDataHandler.bind(this, item.HazardEventItemId, item.GuidIdentifier)}
                            disabled={disableMode}
                            data-unittest="deleteData"
                            style={WidgetVisibility(userProfileWidgets, `WQSP_HAZARDEVENTITEM_DELETE`)}
                          >
                            <FormattedMessage id="common.remove" defaultMessage="Remove" />
                          </Button>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              );
            })}
            {showAddEdit && (
              <tr>
                <td colSpan={4}>
                  <div className={newRowClass}>{showAddEdit && EventContent}</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

HazardEventItemTable.defaultProps = {
  eventItems: {},
  hazards: [],
  hazardClasses: [],
  userProfileWidgets: [],
  showAddEdit: false,
  disableMode: false,
  updateAllowed: false
};

export default injectIntl(HazardEventItemTable);
