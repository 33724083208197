import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './HazardEventLibrary.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialHazardEventLibraryState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Redirect, Link, useParams, useHistory } from 'react-router-dom';
import HazardEventTemplateItems from './HazardEventLibraryItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import { HAZARD_CLASS } from '../../constants/index';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { getUserProfileWidget, getHazardEventLibrary, getGeneralData, getHazard } from '../../selectors/index';
import {
  describeHazardEventTemplateThunk,
  saveHazardEventTemplateThunk,
  setHazardEventTemplateChangeThunk
} from '../../store/actions/hazardEventLibrary';
import { getHazardThunk } from '../../store/actions/hazards';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const HazardEventLibrary = ({ intl }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hazardEventTemplate = useSelector(state => getHazardEventLibrary(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const generalData = useSelector(state => getGeneralData(state));
  const hazards = useSelector(state => getHazard(state));

  let IsEdit = false;
  let { hazardEventLibraryId } = useParams();

  const [disableHazardEventTemplateItem, setDisableHazardEventTemplateItem] = useState(false);
  const [formError, setFormError] = useState({});
  const [searchKeywords, setSearchKeywords] = useState('');

  IsEdit = hazardEventLibraryId && hazardEventLibraryId > 0 ? true : false;

  if (IsEdit && !IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENTTEMPLATE_EDIT')) {
    history.push('/unauth');
  }

  let widgetCode = '';

  if (IsEdit) {
    widgetCode = 'WQSP_HAZARDEVENTTEMPLATE_EDIT';
  } else {
    widgetCode = 'WQSP_HAZARDEVENTTEMPLATE_ADDNEW';
  }

  useEffect(() => {
    setLocalHazardEventTemplate({
      SelectedHazardEventTemplate: {
        ...initialHazardEventLibraryState.hazardEventTemplate.SelectedHazardEventTemplate,
        SelectedHazardEventTemplateItems: []
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getHazardThunk('WQSP_HAZARDS_LIST', {}));
  }, [getHazardThunk]);

  useEffect(() => {
    dispatch(describeHazardEventTemplateThunk(hazardEventLibraryId, widgetCode));
  }, [describeHazardEventTemplateThunk, hazardEventLibraryId]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = hazardEventTemplate.SelectedHazardEventTemplate;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;

    if (!IsEdit) {
      saveData.HazardEventLibraryId = 0;
      saveData.GuidIdentifier = uuidv4();
    }

    dispatch(saveHazardEventTemplateThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formError = {};

    let isValid = true;

    if (
      !hazardEventTemplate ||
      !hazardEventTemplate.SelectedHazardEventTemplate ||
      !hazardEventTemplate.SelectedHazardEventTemplate.EntityTypeId
    ) {
      formError.EntityTypeId = intl.formatMessage({
        id: 'wqsp.common.entityTypeMandatory',
        defaultMessage: 'Entity type is mandatory field'
      });
      isValid = false;
    }

    if (
      !hazardEventTemplate ||
      !hazardEventTemplate.SelectedHazardEventTemplate ||
      !hazardEventTemplate.SelectedHazardEventTemplate.Name ||
      isRequired(hazardEventTemplate.SelectedHazardEventTemplate.Name, 1)
    ) {
      formError.Name = intl.formatMessage({
        id: 'hazardEventLibrary.nameMandatory',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !hazardEventTemplate ||
      !hazardEventTemplate.SelectedHazardEventTemplate ||
      !hazardEventTemplate.SelectedHazardEventTemplate.HazardClass ||
      isRequired(hazardEventTemplate.SelectedHazardEventTemplate.HazardClass, 1)
    ) {
      formError.Task = intl.formatMessage({
        id: 'wqsp.common.hazardClassMandatory',
        defaultMessage: 'Hazard Class is mandatory field'
      });
      isValid = false;
    }
    setFormError(formError);

    return isValid;
  };

  const entityTypeOptions = () => {
    let opData = [];

    const data = generalData.entityTypes;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntityTypeId
      });
    });
    return opData.sort(compareValues('label'));
  };

  const hazardClasses = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_CLASS);

  const hazardClassOptions = () => {
    let hazardClassData = [];

    hazardClasses.forEach(element => {
      let disable = hazardEventTemplate.SelectedHazardEventTemplate.SelectedHazardEventTemplateItems.find(
        item => !item.IsDeleted && item.HazardClassId === element.GeneralFieldTypeId
      )
        ? true
        : false;
      hazardClassData.push({
        ...element,
        SKEY: element.GeneralFieldTypeId,
        target: { type: 'checkbox' },
        label: element.Name,
        disable: disable,
        isChecked: hazardEventTemplate.SelectedHazardEventTemplate.HazardClass.split(',').find(
          e => e === element.GeneralFieldTypeId.toString()
        )
          ? true
          : false
      });
    });

    return hazardClassData.sort(compareValues('label'));
  };

  const setLocalHazardEventTemplate = currentState => {
    dispatch(setHazardEventTemplateChangeThunk(currentState));
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalHazardEventTemplate({
      ...hazardEventTemplate,
      SelectedHazardEventTemplate: { ...hazardEventTemplate.SelectedHazardEventTemplate, [fieldName]: e.value }
    });
  };

  const onHazardClassChange = e => {
    if (e.target && e.target.name) {
      let hazardEventClass = hazardEventTemplate.SelectedHazardEventTemplate.HazardClass;
      if (e.target.checked) {
        hazardEventClass = (hazardEventClass && `${hazardEventClass},${e.target.name}`) || e.target.name;
      } else {
        if (hazardEventClass) {
          hazardEventClass = hazardEventClass
            .split(',')
            .filter(elem => elem !== e.target.name)
            .join(',');
        }
      }
      setLocalHazardEventTemplate({
        SelectedHazardEventTemplate: {
          ...hazardEventTemplate.SelectedHazardEventTemplate,
          HazardClass: hazardEventClass
        }
      });
    }
  };

  //on control value change
  const onChange = e => {
    hazardEventTemplate.SelectedHazardEventTemplate = {
      ...hazardEventTemplate.SelectedHazardEventTemplate,
      [e.target.name]: e.target.value
    };
    setLocalHazardEventTemplate(hazardEventTemplate);
  };

  const onItemAdd = hazardId => {
    let selectedHazard = hazards.Hazards.filter(x => x.HazardId === hazardId);

    if (selectedHazard && selectedHazard.length > 0) {
      let newItem = {
        HazardEventLibraryId: hazardEventLibraryId || 0,
        HazardId: hazardId,
        HazardClassId: selectedHazard[0].HazardClassId,
        GuidIdentifier: uuidv4(),
        UpdatedBy: Cookies.get(`userid-${envName}`) || 1,
        Name: selectedHazard[0].Name,
        Description: selectedHazard[0].Description
      };
      hazardEventTemplate.SelectedHazardEventTemplate.SelectedHazardEventTemplateItems.push(newItem);
      setLocalHazardEventTemplate(hazardEventTemplate);
    }
  };

  const onItemSearch = keyWords => {
    setSearchKeywords(keyWords);
  };

  const getSelectedHazardEventTemplateItems = () => {
    return hazardEventTemplate.SelectedHazardEventTemplate.SelectedHazardEventTemplateItems.filter(
      item => item.Name.toLowerCase().indexOf(searchKeywords.toLowerCase()) >= 0
    );
  };

  const getHazards = () => {
    let hazardEventClass = hazardEventTemplate.SelectedHazardEventTemplate.HazardClass.split(',');
    let filteredHazards = hazards.Hazards.filter(item => hazardEventClass.find(e => e === item.HazardClassId.toString()));

    return { Hazards: filteredHazards };
  };

  const onItemRemove = (hazardEventLibraryItemId, identifer) => {
    let selectedItems = [];
    if (hazardEventLibraryItemId && hazardEventLibraryItemId > 0) {
      selectedItems = hazardEventTemplate.SelectedHazardEventTemplate.SelectedHazardEventTemplateItems.filter(
        e => e.HazardEventLibraryItemId !== hazardEventLibraryItemId
      );
    } else {
      selectedItems = hazardEventTemplate.SelectedHazardEventTemplate.SelectedHazardEventTemplateItems.filter(
        e => e.GuidIdentifier !== identifer
      );
    }

    hazardEventTemplate.SelectedHazardEventTemplate.SelectedHazardEventTemplateItems = selectedItems;
    setLocalHazardEventTemplate(hazardEventTemplate);
  };

  const onAddNewOrCancelClick = status => {
    setDisableHazardEventTemplateItem(status);
  };

  let messageId = (hazardEventTemplate && hazardEventTemplate.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const hazardEventName =
    (hazardEventTemplate.SelectedHazardEventTemplate ? hazardEventTemplate.SelectedHazardEventTemplate.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + hazardEventName;
  } else {
    heading = intl.formatMessage({ id: 'hazardEventLibrary.newHazardEventTemplate', defaultMessage: 'New Hazard Event Template' });
  }

  let ddlClassEntityType = formError && formError.EntityTypeId ? s.ddlError : '';

  const backLink = !IsEdit ? '/operational-setup/hazards' : `/operational-setup/hazard-event-template-details/${hazardEventLibraryId}`;

  return (
    <div className={s.hazardEventTemplate}>
      {hazardEventTemplate.isLoading && <LoadingSpinner />}
      {hazardEventTemplate.isOpSuccessful && !hazardEventTemplate.showBanner && <Redirect to={backLink} />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={hazardEventTemplate.showBanner}
        status={hazardEventTemplate.isOpSuccessful}
        successText={messageText}
      />

      <OperationalSetupContentTemplate selectedPage="hazards" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.hazardEventTemplateHeader}>
            <Link className={s.backLink} to={backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </Link>
            <Row>
              <Col>
                <h3 data-unittest="headingLabel">{heading}</h3>
              </Col>
            </Row>
          </div>
          <div className={s.hazardEventTemplateContent}>
            <h4>
              <FormattedMessage id="common.details" defaultMessage="Details" />
            </h4>
            {/* EDIT SCREEN START */}
            <Form className={s.viewRow}>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="sourceEntityType">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                    </Form.Label>

                    <Dropdown
                      id="formSourceEntityType"
                      dataArray={entityTypeOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                        customClassName: ddlClassEntityType
                      }}
                      name="EntityTypeId"
                      onDropdownChange={e => onDropdownChange(e, 'EntityTypeId')}
                      selectedOption={entityTypeOptions().filter(
                        option => option.value === hazardEventTemplate.SelectedHazardEventTemplate.EntityTypeId
                      )}
                      disabled={IsEdit}
                    />
                    {formError && formError.EntityTypeId && (
                      <p role="alert" className={s.error}>
                        {formError.EntityTypeId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formHazardEventTemplateName">
                    <Form.Label>
                      <FormattedMessage id="common.name" defaultMessage="Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={hazardEventTemplate.SelectedHazardEventTemplate.Name}
                      className={`${s.formControl} ${formError && formError.Name ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'common.name',
                        defaultMessage: 'Name'
                      })}
                    />
                    {formError && formError.Name && (
                      <p role="alert" className={s.error}>
                        {formError.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formHazardEventTemplateDescription">
                    <Form.Label>
                      <FormattedMessage id="common.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      className={s.txtArea}
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'common.description',
                        defaultMessage: 'Description'
                      })}
                      value={hazardEventTemplate.SelectedHazardEventTemplate.Description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formHazardEventTemplateBasis">
                    <Form.Label>
                      <FormattedMessage id="hazardEventLibrary.basis" defaultMessage="Basis" />
                    </Form.Label>

                    <Form.Control
                      className={s.txtArea}
                      as="textarea"
                      rows="3"
                      name="Basis"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'hazardEventLibrary.basis',
                        defaultMessage: 'Basis'
                      })}
                      value={hazardEventTemplate.SelectedHazardEventTemplate.Basis}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formHazardClass">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.hazardClass" defaultMessage="Hazard Class" />
                    </Form.Label>

                    <Checkbox key={uuidv4()} dataArray={hazardClassOptions()} onSelectionChange={onHazardClassChange} />
                    {formError && formError.Task && (
                      <p role="alert" className={s.error}>
                        {formError.Task}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            {/* EDIT SCREEN END */}
          </div>
          <div>
            <HazardEventTemplateItems
              onItemSearch={onItemSearch}
              onItemAdd={onItemAdd}
              onItemRemove={onItemRemove}
              onAddNewOrCancelClick={onAddNewOrCancelClick}
              selectedItems={getSelectedHazardEventTemplateItems() || []}
              hazards={getHazards()}
              hazardClasses={hazardClasses}
              isView={false}
            />
          </div>
          <div>
            <Form>
              <Button
                variant="primary"
                data-unittest="saveData"
                className={classNames(s.margin5, s.btnSaveChanges)}
                onClick={submitForm}
                noValidate
                disabled={disableHazardEventTemplateItem}
              >
                <FormattedMessage id="common.saveChanges" defaultMessage="Save Changes" />
              </Button>

              <Link to={backLink}>
                <Button disabled={disableHazardEventTemplateItem} variant="outline-secondary" className={s.btnCancel}>
                  <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                </Button>
              </Link>
            </Form>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

HazardEventLibrary.defaultProps = {
  hazardEventTemplate: {
    ...initialHazardEventLibraryState.hazardEventTemplate
  }
};

export default injectIntl(HazardEventLibrary);
