import React, { useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import s from './InspectionReportAdmin.module.scss';
import { injectIntl } from 'react-intl';
import InspectionPurposeList from '../InspectionPurpose/InspectionPurposeList';
import InspectionTypeList from '../InspectionType/InspectionTypeList';
import InspectionTypeQuestionList from '../InspectionTypeQuestion/InspectionTypeQuestionList';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import { getQuerystring } from '../../utils/index';

const InspectionReportAdmin = ({ intl }) => {
  let posRef = useRef();
  let approvedRef = useRef();
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let list = getQuerystring('list');
  useLayoutEffect(() => {
    if (list === '1' && approvedRef.current) {
      window.scrollTo({
        top: approvedRef.current.offsetTop
      });
    } else if (list === '2' && posRef.current) {
      window.scrollTo({
        top: posRef.current.offsetTop
      });
    }
  }, [posRef, approvedRef, list]);

  return (
    <div className={s.mainComponent}>
      <OperationalSetupContentTemplate selectedPage="inspectionReport" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.mainComponentContent}>
            <InspectionPurposeList userProfileWidgets={userProfileWidgets} />
            <InspectionTypeList userProfileWidgets={userProfileWidgets} />
            <InspectionTypeQuestionList userProfileWidgets={userProfileWidgets} inspectionTypeSpecificQuestions={false} />
            {/* 
            <div ref={posRef} />
            <ModelComponentPositionList /> */}
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

InspectionReportAdmin.defaultProps = {};

export default injectIntl(InspectionReportAdmin);
