import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from './ControlMonitoring.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { compareValues } from '../../utils';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';

const CorrectiveAction = ({ onAddClick, team, onCancelClick, correctiveActions, intl }) => {
  const [localformErrors, setFormErrors] = useState({});
  const [localCorrectiveAction, setLocalCorrectiveAction] = useState({});

  const onCancelButtonClick = () => {
    setLocalCorrectiveAction({});
    onCancelClick();
  };

  useEffect(() => {
    return () => {
      onCancelClick();
    };
  }, []);

  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }
    let correctiveAction = { ...localCorrectiveAction };
    correctiveAction.CorrectiveActionId = 0;

    onAddClick(correctiveAction);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!localCorrectiveAction || !localCorrectiveAction.TeamId) {
      formErrors.Team = intl.formatMessage({
        id: 'correctiveAction.teamMandatory',
        defaultMessage: 'Team is mandatory field'
      });
      isValid = false;
    }

    if (!localCorrectiveAction || !localCorrectiveAction.Title) {
      formErrors.Title = intl.formatMessage({
        id: 'common.titleRequired',
        defaultMessage: 'Title is mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const teamOptions = () => {
    //let filteredItems = correctiveActions.filter(item => !item.IsDeleted);
    let categoryData = [];
    team.team.forEach(element => {
      categoryData.push({
        ...element,
        label: element.Name,
        value: element.TeamId,
        isDisabled: false
        //isDisabled: filteredItems.find(item => item.TeamId === element.TeamId) ? true : false
      });
    });

    let sortedData = categoryData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onTeamDropdownChange = e => {
    setLocalCorrectiveAction({
      ...localCorrectiveAction,
      TeamId: e.value,
      PersonnelName: e.label
    });
  };

  const onChange = e => {
    setLocalCorrectiveAction({
      ...localCorrectiveAction,
      [e.target.name]: e.target.value
    });
  };

  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });

  return (
    <div className={s.controlMonitoring}>
      <div className={s.controlMonitoringTypeContent}>
        <div className={s.topRow}>
          <Row>
            <Col lg={3}>
              <Form.Group controlId="formTeam">
                <Form.Label>
                  <FormattedMessage id="correctiveAction.team" defaultMessage="Team" />
                </Form.Label>

                <Dropdown
                  id="formDdTeam"
                  dataArray={teamOptions()}
                  controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Team && s.ddlError }}
                  onDropdownChange={onTeamDropdownChange}
                  data-unittest="formDdlHformDdTeamazard"
                  selectedOption={teamOptions().filter(option => option.value === localCorrectiveAction.TeamId)}
                />
                {localformErrors && localformErrors.Team && (
                  <p role="alert" className={s.error}>
                    {localformErrors.Team}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formTitle">
                <Form.Label>
                  <FormattedMessage id="common.title" defaultMessage="Title" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="Title"
                  onChange={onChange}
                  value={localCorrectiveAction.Title || ''}
                  className={`${s.formControl} ${localformErrors && localformErrors.Title ? s.formControlError : ''}`}
                  placeholder={intl.formatMessage({
                    id: 'common.title',
                    defaultMessage: 'Title'
                  })}
                />
                {localformErrors && localformErrors.Title && (
                  <p role="alert" className={s.error}>
                    {localformErrors.Title}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formWhenActionTriggered">
                <Form.Label>
                  <FormattedMessage id="correctiveAction.whenActionTriggered" defaultMessage="When Action Triggered" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="WhenActionTriggered"
                  onChange={onChange}
                  value={localCorrectiveAction.WhenActionTriggered || ''}
                  className={`${s.formControl}`}
                  placeholder={intl.formatMessage({
                    id: 'correctiveAction.whenActionTriggered',
                    defaultMessage: 'When Action Triggered'
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group controlId="formDetails">
                <Form.Label>
                  <FormattedMessage id="common.details" defaultMessage="Details" />
                </Form.Label>

                <Form.Control
                  className={s.textArea}
                  as="textarea"
                  rows="3"
                  name="Details"
                  onChange={onChange}
                  placeholder={intl.formatMessage({
                    id: 'common.details',
                    defaultMessage: 'Details'
                  })}
                  value={localCorrectiveAction.Details}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formRecords">
                <Form.Label>
                  <FormattedMessage id="controlMonitoring.records" defaultMessage="Records" />
                </Form.Label>

                <Form.Control
                  className={s.textArea}
                  as="textarea"
                  rows="3"
                  name="Records"
                  onChange={onChange}
                  placeholder={intl.formatMessage({
                    id: 'controlMonitoring.records',
                    defaultMessage: 'Records'
                  })}
                  value={localCorrectiveAction.Records}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChangesSmall)}
                onClick={submitForm}
                noValidate
                data-unittest="add"
              >
                <FormattedMessage id="common.add" defaultMessage="ADD" />
              </Button>
              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancelSmall)}
                onClick={onCancelButtonClick}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
            <Col lg={8}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CorrectiveAction);
