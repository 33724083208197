import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TaskList from './TaskList';
import { getTaskManager, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import { getTasksThunk, getFilterThunk, setCurrentPage, setPageFilter, setTaskFilteredDataThunk } from '../../store/actions/tasks';

const mapStateToProps = state => {
  return {
    tasksManager: getTaskManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTasks: filterObj => getTasksThunk(filterObj, 'WQSP_TASKLIST'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter),
      setFilterData: currentPage => setTaskFilteredDataThunk(currentPage),
      getFilter: () => getFilterThunk('WQSP_TASKLIST')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
