import { initialHazardState } from './initialState';

import {
  HAZARD_ERROR,
  GET_ALL_HAZARD_DETAILS,
  HAZARD_IS_LOADING,
  SET_HAZARD_CURRENT_PAGE,
  SET_HAZARD_PAGE_FILTER,
  SET_HAZARD_CHANGE,
  SET_HAZARD,
  UPDATE_HAZARD_SAVE_STATUS,
  SET_HAZARD_FILTERS,
  SET_HAZARD_RESULTS
} from '../../constants/index';

const hazardReducer = (state = initialHazardState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_HAZARD_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        hazard: {
          ...state.hazard,
          ...data
        }
      };
    }

    case SET_HAZARD_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        hazard: {
          ...state.hazard,
          currentPage
        }
      };
    }

    case SET_HAZARD_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        hazard: {
          ...state.hazard,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_HAZARD_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        hazard: {
          ...state.hazard,
          ...data
        }
      };
    }

    case HAZARD_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        hazard: {
          ...state.hazard,
          isLoading: isLoading
        }
      };
    }

    case HAZARD_ERROR: {
      const { data } = payload;
      return {
        ...state,
        hazard: {
          ...state.hazard,
          ...data
        }
      };
    }

    case SET_HAZARD: {
      let { selectedHazard } = payload;

      return {
        ...state,
        hazard: {
          ...state.hazard,
          isOpSuccessful: false,
          showBanner: false,
          selectedHazard: {
            ...state.hazard.selectedHazard,
            ...selectedHazard
          }
        }
      };
    }

    case SET_HAZARD_CHANGE: {
      let { selectedHazard } = payload;

      return {
        ...state,
        hazard: {
          ...state.hazard,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedHazard: {
            ...state.hazard.selectedHazard,
            ...selectedHazard
          }
        }
      };
    }

    case SET_HAZARD_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        hazard: {
          ...state.hazard,
          ...filteredResults
        }
      };
    }

    case SET_HAZARD_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        hazard: {
          ...state.hazard,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default hazardReducer;
