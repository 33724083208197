import React from 'react';
import equal from 'fast-deep-equal';
import s from './Checkbox.module.scss';

//Common Checkbox for WS project
//Properties
// dataArray:  [{SKEY:'', label:'', value:'', isChecked}, {...},.....],

//Events =>
//  onSelectionChange(selectedObjects)
//  componentDidMount() //is invoked immediately after a component is mounted (inserted into the tree).
//  componentDidUpdate() //is invoked immediately after updating occurs. This method is not called for the initial render
//  componentWillUnmount() // is invoked immediately before a component is unmounted and destroyed
//  componentDidCatch(error, info) //This lifecycle is invoked after an error has been thrown by a descendant component

const Checkbox = ({ controlData, dataArray, onSelectionChange, disable }) => {
  const onChange = (e, data) => {
    e.preventDefault();
    data.isChecked = e.target.checked;
    onSelectionChange(e, data);
  };

  //render method

  return (
    <ul className={`${s.wsaCheckbox} ${s.checkbox}`}>
      {dataArray.map(data => {
        let cssClasses = data.isChecked ? `selected ` : ` `;
        cssClasses += controlData ? controlData.customClassName : ``;
        return (
          <li key={'li-' + data.SKEY + data.isChecked} className={cssClasses}>
            <label className={s.container}>
              {data.label || ''}
              <input
                aria-label="test"
                type="checkbox"
                id={data.SKEY}
                value={data.value || data.SKEY}
                onChange={e => {
                  onChange(e, data);
                }}
                defaultChecked={data.isChecked}
                name={data.SKEY}
                disabled={disable || data.disable}
              />
              <span className={s.checkmark} htmlFor={data.SKEY}></span>
            </label>
          </li>
        );
      })}
    </ul>
  );
};

Checkbox.defaultProps = {
  dataArray: [],
  dropdownData: {
    placeholderText: '',
    customClassName: ''
  }
};

export default Checkbox;
