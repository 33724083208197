import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './ControlMonitoring.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialControlMeasureState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import uuid from 'uuid';
import {
  getUserProfileWidget,
  getGeneralData,
  getControlMeasure,
  getCurrentTimezone,
  getStakeholderRegister,
  getTeam,
  getSelectedHazardEvent
} from '../../selectors/index';
import {
  describeControlMeasureThunk,
  getControlMeasureThunk,
  describeControlMonitoringThunk,
  saveControlMonitoringThunk,
  setControlMonitoringChangeThunk,
  getModelComponentPositionThunk,
  setControlMeasureChangeThunk
} from '../../store/actions/hazardEvent/controlMeasures';
import { getStakeholderRegisterThunk } from '../../store/actions/safetyPlan/stakeholderRegister';
import { getTeamThunk } from '../../store/actions/safetyPlan/team';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import CorrectiveActionListTable from './CorrectiveActionListTable';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { FREQUENCY, GENERAL_STATUS } from '../../constants/index';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Breadcrumb from '../WSAControls/Breadcrumb';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const ControlMonitoring = ({ intl }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  let { controlMeasureId, hazardEventId, controlMonitoringId, entityId, siteId, segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'control';
  selectedTab = selectedTab || '2';
  let IsEdit = false;

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const controlMeasure = useSelector(state => getControlMeasure(state));
  const generalData = useSelector(state => getGeneralData(state));
  const stakeholderRegister = useSelector(state => getStakeholderRegister(state));
  let team = useSelector(state => getTeam(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const hazardEvent = useSelector(state => getSelectedHazardEvent(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);

  siteId = siteId || hazardEvent.SiteId || null;

  const currentTimestamp = moment().tz(currentTimezone);
  const [localformErrors, setFormErrors] = useState({});
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [disableMode, setDisableMode] = useState(false);

  const ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const disableFutureDates = current => current.isBefore(currentTimestamp);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  let widgetCode = 'WQSP_CONTROL_MONITORINGS_ADDNEW';
  if (controlMonitoringId) {
    IsEdit = true;
    widgetCode = 'WQSP_CONTROL_MONITORINGS_EDIT';
  }

  let backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure-view/${controlMeasureId}`;
  if (segmentName === 'define') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`;
  }
  if (segmentName === 'identify') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`;
  }
  if (segmentName === 'monitor') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings`;
  }

  if (IsEdit) {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`;
    if (segmentName === 'define') {
      backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`;
    }
    if (segmentName === 'identify') {
      backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`;
    }
    if (segmentName === 'monitor') {
      backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings/control-monitoring-view/${controlMonitoringId}`;
    }
  }

  useEffect(() => {
    if (controlMeasureId) {
      dispatch(getControlMeasureThunk(widgetCode, {}, null, siteId));
    }
  }, [describeControlMeasureThunk, segmentName, controlMeasureId]);

  useEffect(() => {
    if (!IsEdit && controlMeasureId) {
      dispatch(describeControlMeasureThunk(controlMeasureId, widgetCode));
    }
  }, [describeControlMeasureThunk, controlMeasureId]);

  useEffect(() => {
    if (controlMonitoringId) {
      dispatch(describeControlMonitoringThunk(controlMonitoringId, controlMeasureId, widgetCode));
    }
  }, [describeControlMonitoringThunk, controlMonitoringId, controlMeasureId]);

  useEffect(() => {
    let data = {
      offset: 0,
      limit: 1000,
      filter: ''
    };
    dispatch(getModelComponentPositionThunk(data, widgetCode));
  }, [getModelComponentPositionThunk]);

  useEffect(() => {
    let filterObj = {
      filterName: '',
      filterCompany: '',
      offset: 0,
      limit: 1000,
      siteId: siteId
    };
    if (siteId) {
      dispatch(getStakeholderRegisterThunk(widgetCode, filterObj, null));
    }
  }, [getStakeholderRegisterThunk, siteId, null]);

  useEffect(() => {
    let filterObj = {
      filterName: '',
      filterCompany: '',
      offset: 0,
      limit: 1000,
      siteId: siteId
    };
    if (siteId) {
      dispatch(getTeamThunk('WQSP_CORRECTIVE_ACTIONS_ADDNEW', filterObj, null));
    }
  }, [getTeamThunk, siteId, null]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setControlMonitoringChangeThunk({
          ...initialControlMeasureState.controlMeasure.selectedControlMeasure
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalControlMonitoring = currentState => {
    dispatch(setControlMonitoringChangeThunk(currentState));
  };

  const setLocalControlMeasure = currentState => {
    dispatch(setControlMeasureChangeThunk(currentState));
  };

  useEffect(() => {
    if (controlMeasureId && !controlMeasure.selectedControlMeasure.selectedControlMonitoring.ControlMeasureId) {
      setLocalControlMonitoring({
        ...controlMeasure,
        selectedControlMonitoring: {
          ...controlMeasure.selectedControlMeasure.selectedControlMonitoring,
          ControlMeasureId: parseInt(controlMeasureId)
        }
      });
    }
  }, [setLocalControlMonitoring, controlMeasureId]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = controlMeasure.selectedControlMeasure.selectedControlMonitoring;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.ControlMeasureId = saveData.ControlMeasureId || controlMeasureId;
    saveData.orgId = orgId || 1;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ControlMonitoringId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_CONTROL_MONITORINGS_ADDNEW';
    }
    dispatch(saveControlMonitoringThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !controlMeasure ||
      !controlMeasure.selectedControlMeasure ||
      !controlMeasure.selectedControlMeasure.selectedControlMonitoring ||
      !controlMeasure.selectedControlMeasure.selectedControlMonitoring.ControlMeasureId ||
      isRequired(controlMeasure.selectedControlMeasure.selectedControlMonitoring.ControlMeasureId, 1)
    ) {
      formErrors.ControlMeasure = intl.formatMessage({
        id: 'controlMonitoring.controlMeasureMandatory',
        defaultMessage: 'Control Measure is a mandatory field'
      });
      isValid = false;
    }

    if (
      !controlMeasure ||
      !controlMeasure.selectedControlMeasure ||
      !controlMeasure.selectedControlMeasure.selectedControlMonitoring ||
      !controlMeasure.selectedControlMeasure.selectedControlMonitoring.StakeholderRegisterId ||
      isRequired(controlMeasure.selectedControlMeasure.selectedControlMonitoring.StakeholderRegisterId, 1)
    ) {
      formErrors.StakeholderRegisterId = intl.formatMessage({
        id: 'controlMonitoring.stakeholderMandatory',
        defaultMessage: 'Stakeholder is a mandatory field'
      });
      isValid = false;
    }

    if (
      !controlMeasure ||
      !controlMeasure.selectedControlMeasure ||
      !controlMeasure.selectedControlMeasure.selectedControlMonitoring ||
      !controlMeasure.selectedControlMeasure.selectedControlMonitoring.MonitoredItem ||
      isRequired(controlMeasure.selectedControlMeasure.selectedControlMonitoring.MonitoredItem, 1)
    ) {
      formErrors.MonitoredItem = intl.formatMessage({
        id: 'controlMonitoring.monitoredItemMandatory',
        defaultMessage: 'Monitored Item is a mandatory field'
      });
      isValid = false;
    }

    if (
      !controlMeasure ||
      !controlMeasure.selectedControlMeasure ||
      !controlMeasure.selectedControlMeasure.selectedControlMonitoring ||
      !controlMeasure.selectedControlMeasure.selectedControlMonitoring.LastReviewDate ||
      isRequired(controlMeasure.selectedControlMeasure.selectedControlMonitoring.LastReviewDate, 1)
    ) {
      formErrors.LastReviewDate = intl.formatMessage({
        id: 'common.lastReviewDateMandatory',
        defaultMessage: 'Last Review Date is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const controlMeasureOptions = () => {
    let opData = [];
    const data = controlMeasure.controlMeasures;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.ControlMeasureId
      });
    });
    return opData;
  };

  const unitComponentPositionOptions = () => {
    let opData = [];
    const data = controlMeasure.selectedControlMeasure.unitComponentPositions;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return opData;
  };

  const stakeholderRegisterOptions = () => {
    let opData = [];

    const data = stakeholderRegister.stakeholderRegisters;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.StakeholderRegisterId
      });
    });
    return opData;
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };
  const onStatusChange = e => {
    setLocalControlMonitoring({
      ...controlMeasure,
      selectedControlMonitoring: {
        ...controlMeasure.selectedControlMeasure.selectedControlMonitoring,
        [e.target.name]: e.target.value,
        StatusId: null
      }
    });
  };

  //on control value change
  const onChange = e => {
    setLocalControlMonitoring({
      ...controlMeasure,
      selectedControlMonitoring: { ...controlMeasure.selectedControlMeasure.selectedControlMonitoring, [e.target.name]: e.target.value }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalControlMonitoring({
      ...controlMeasure,
      selectedControlMonitoring: { ...controlMeasure.selectedControlMeasure.selectedControlMonitoring, [fieldName]: e.value }
    });
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();

    setLocalControlMonitoring({
      ...controlMeasure,
      selectedControlMonitoring: { ...controlMeasure.selectedControlMeasure.selectedControlMonitoring, [fieldName]: changeDate }
    });
  };

  const onAddCorrectiveAction = correctiveAction => {
    let correctiveActions =
      (controlMeasure.selectedControlMeasure.selectedControlMonitoring.correctiveActions && [
        ...controlMeasure.selectedControlMeasure.selectedControlMonitoring.correctiveActions
      ]) ||
      [];

    correctiveAction.GuidIdentifier = uuidv4();
    correctiveAction.CorrectiveActionId = 0;
    correctiveAction.IsActive = true;
    correctiveAction.IsDeleted = false;
    correctiveActions.push(correctiveAction);

    setShowAddEdit(false);

    setLocalControlMonitoring({
      selectedControlMonitoring: {
        correctiveActions: correctiveActions
      }
    });
  };

  const onChangeCorrectiveAction = correctiveActions => {
    setLocalControlMonitoring({
      selectedControlMonitoring: {
        correctiveActions: correctiveActions
      }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  //FILE UPLOAD
  const onUploadStart = files => {
    // setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    //  setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          SiteId: 0,
          newFile: true,
          S3URL: item.S3URL,
          ImageId: item.fileGuid,
          Name: item.name
        };
      });
      let allFiles = [...controlMeasure.selectedControlMeasure.selectedControlMonitoring.Files, ...newFiles];

      setLocalControlMonitoring({
        ...controlMeasure,
        selectedControlMonitoring: { ...controlMeasure.selectedControlMeasure.selectedControlMonitoring, Files: allFiles }
      });
    }
  };

  const removeFile = fileGuid => {
    let files = controlMeasure.selectedControlMeasure.selectedControlMonitoring.Files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...controlMeasure.selectedControlMeasure.selectedControlMonitoring.deletedFileIds];
    deletedFileIds.push(fileGuid);

    setLocalControlMonitoring({
      ...controlMeasure,
      selectedControlMonitoring: {
        ...controlMeasure.selectedControlMeasure.selectedControlMonitoring,
        Files: files,
        deletedFileIds: deletedFileIds
      }
    });
  };
  //END FILE UPLOAD

  let messageId = (controlMeasure && controlMeasure.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let controlMeasureName = '';
  if (IsEdit) {
    controlMeasureName = controlMeasure.selectedControlMeasure.selectedControlMonitoring.ControlMeasureName || '';
  } else {
    controlMeasureName = controlMeasure.selectedControlMeasure.Name || '';
  }

  let headingControlMeasure =
    intl.formatMessage({ id: 'controlMeasure.controlMeasure', defaultMessage: 'Control Measure' }) + ' (' + controlMeasureName + ')';

  const controlMonitoringName =
    (controlMeasure.selectedControlMeasure.selectedControlMonitoring
      ? controlMeasure.selectedControlMeasure.selectedControlMonitoring.MonitoredItem
      : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + controlMonitoringName;
  } else {
    heading = intl.formatMessage({ id: 'controlMonitoring.addNewControlMonitoring', defaultMessage: 'Add new Control Monitoring' });
  }

  const isItemListingAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CORRECTIVE_ACTIONS_LIST');
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CORRECTIVE_ACTIONS_EDIT');
  let correctiveActionsObj = controlMeasure.selectedControlMeasure.selectedControlMonitoring.correctiveActions;
  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfControlMeasures', defaultMessage: 'List of Control Measures' });
  let controlMonitoringHeading = intl.formatMessage({
    id: 'controlMeasure.viewControlMonitoring',
    defaultMessage: 'View Control Monitoring'
  });
  let monitorBreadcrumbHeading = intl.formatMessage({
    id: 'siteControlsTabs.listOfControlMonitors',
    defaultMessage: 'List of Control Monitors'
  });

  let hazardEventName = controlMeasure.selectedControlMeasure.selectedControlMonitoring.HazardEventName;
  if (!IsEdit) {
    hazardEventName = controlMeasure.selectedControlMeasure.HazardName;
  }
  let hazardEventHeading =
    intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + ' (' + hazardEventName + ')';

  const breadcrumbControl = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: headingControlMeasure,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: controlMonitoringHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`,
      showlink: true,
      showCrumb: IsEdit
    },

    { orderNo: 4, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 5,
      name: headingControlMeasure,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 6,
      name: controlMonitoringHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 7, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const identifyHazardEventcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: headingControlMeasure,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 5,
      name: controlMonitoringHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 6, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const monitorBreadcrumb = [
    {
      orderNo: 1,
      name: monitorBreadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: controlMonitoringHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings/control-monitoring-view/${controlMonitoringId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const getBreadcrumbData = () => {
    if (segmentName === 'define') {
      return defineEntityCrumb;
    } else if (segmentName === 'identify') {
      return identifyHazardEventcrumb;
    } else if (segmentName === 'monitor') {
      return monitorBreadcrumb;
    } else {
      return breadcrumbControl;
    }
  };

  return (
    <div className={s.controlMonitoring}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {controlMeasure.isLoading && <LoadingSpinner />}
        {controlMeasure.isControlMonitoringRedirect ? <Redirect to={backUrl} /> : ''}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={controlMeasure.showBanner}
          status={controlMeasure.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          <Breadcrumb data={getBreadcrumbData()} segmentName={segmentName} />
          <div className={s.controlMonitoringContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formControlMeasures">
                      <Form.Label>
                        <FormattedMessage id="controlMonitoring.controlMeasure" defaultMessage="Control Measure" />
                      </Form.Label>

                      <Dropdown
                        id="ddlControlMeasures"
                        dataArray={controlMeasureOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: localformErrors.ControlMeasure && s.ddlError
                        }}
                        disabled={IsEdit || controlMeasureId !== undefined}
                        name="ControlMeasureId"
                        onDropdownChange={e => onDropdownChange(e, 'ControlMeasureId')}
                        selectedOption={controlMeasureOptions().filter(
                          option => option.value === controlMeasure.selectedControlMeasure.selectedControlMonitoring.ControlMeasureId
                        )}
                      />
                      {localformErrors && localformErrors.ControlMeasure && (
                        <p role="alert" className={s.error}>
                          {localformErrors.ControlMeasure}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formUnitComponentPosition">
                      <Form.Label>
                        <FormattedMessage id="controlMonitoring.unitComponentPosition" defaultMessage="Unit Component Position" />
                      </Form.Label>

                      <Dropdown
                        id="formUnitComponentPosition"
                        dataArray={unitComponentPositionOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: localformErrors.UnitComponentPositionId && s.ddlError
                        }}
                        name="UnitComponentPositionId"
                        onDropdownChange={e => onDropdownChange(e, 'UnitComponentPositionId')}
                        selectedOption={unitComponentPositionOptions().filter(
                          option => option.value === controlMeasure.selectedControlMeasure.selectedControlMonitoring.UnitComponentPositionId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formStakeholder">
                      <Form.Label>
                        <FormattedMessage id="controlMonitoring.stakeholder" defaultMessage="Stakeholder" />
                      </Form.Label>

                      <Dropdown
                        id="formStakeholderId"
                        dataArray={stakeholderRegisterOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: localformErrors.StakeholderRegisterId && s.ddlError
                        }}
                        name="StakeholderRegisterId"
                        onDropdownChange={e => onDropdownChange(e, 'StakeholderRegisterId')}
                        selectedOption={stakeholderRegisterOptions().filter(
                          option => option.value === controlMeasure.selectedControlMeasure.selectedControlMonitoring.StakeholderRegisterId
                        )}
                      />
                      {localformErrors && localformErrors.StakeholderRegisterId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.StakeholderRegisterId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formMonitoredItem">
                      <Form.Label>
                        <FormattedMessage id="controlMonitoring.monitoredItem" defaultMessage="Monitored Item" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="MonitoredItem"
                        onChange={onChange}
                        value={controlMeasure.selectedControlMeasure.selectedControlMonitoring.MonitoredItem}
                        className={`${s.formControl} ${localformErrors && localformErrors.MonitoredItem ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'controlMonitoring.monitoredItem',
                          defaultMessage: 'Monitored Item'
                        })}
                      />
                      {localformErrors && localformErrors.MonitoredItem && (
                        <p role="alert" className={s.error}>
                          {localformErrors.MonitoredItem}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={controlMeasure.selectedControlMeasure.selectedControlMonitoring.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formWhenMonitoredDetails">
                      <Form.Label>
                        <FormattedMessage id="controlMonitoring.whenMonitoredDetails" defaultMessage="When Monitored Details" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="WhenMonitoredDetails"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'controlMonitoring.whenMonitoredDetails',
                          defaultMessage: 'WhenMonitoredDetails'
                        })}
                        value={controlMeasure.selectedControlMeasure.selectedControlMonitoring.WhenMonitoredDetails}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formFrequency">
                      <Form.Label>
                        <FormattedMessage id="controlMonitoring.frequency" defaultMessage="Frequency" />
                      </Form.Label>
                      <Dropdown
                        id="formFrequencyId"
                        dataArray={frequencyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="FrequencyId"
                        onDropdownChange={e => onDropdownChange(e, 'FrequencyId')}
                        selectedOption={frequencyOptions().filter(
                          option => option.value === controlMeasure.selectedControlMeasure.selectedControlMonitoring.FrequencyId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formWhereMeasureTaken">
                      <Form.Label>
                        <FormattedMessage id="controlMonitoring.whereMeasureTaken" defaultMessage="Where Measure Taken" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="WhereMeasureTaken"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'controlMonitoring.whereMeasureTaken',
                          defaultMessage: 'Where Measure Taken'
                        })}
                        value={controlMeasure.selectedControlMeasure.selectedControlMonitoring.WhereMeasureTaken}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRecords">
                      <Form.Label>
                        <FormattedMessage id="controlMonitoring.records" defaultMessage="Records" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Records"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'controlMonitoring.records',
                          defaultMessage: 'Records'
                        })}
                        value={controlMeasure.selectedControlMeasure.selectedControlMonitoring.Records}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formLastReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.LastReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'LastReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(controlMeasure.selectedControlMeasure.selectedControlMonitoring.LastReviewDate)}
                        defaultValue={getFormattedate(controlMeasure.selectedControlMeasure.selectedControlMonitoring.LastReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.LastReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.LastReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formNextReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'NextReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(controlMeasure.selectedControlMeasure.selectedControlMonitoring.NextReviewDate)}
                        defaultValue={getFormattedate(controlMeasure.selectedControlMeasure.selectedControlMonitoring.NextReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.NextReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.NextReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formGroupReviewFrequency">
                      <Form.Label>
                        <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                      </Form.Label>

                      <Dropdown
                        id="formMaintenanceFrequency"
                        dataArray={frequencyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="ReviewFrequencyId"
                        onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                        selectedOption={frequencyOptions().filter(
                          option => option.value === controlMeasure.selectedControlMeasure.selectedControlMonitoring.ReviewFrequencyId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formActioned">
                      <Form.Label>
                        <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                      </Form.Label>

                      <ToggleSwitch
                        handleClick={() => {
                          onStatusChange({
                            target: {
                              name: 'IsActioned',
                              value: !controlMeasure.selectedControlMeasure.selectedControlMonitoring.IsActioned
                            }
                          });
                        }}
                        classname={s.switch}
                        checked={controlMeasure.selectedControlMeasure.selectedControlMonitoring.IsActioned}
                        labelChecked={yesText}
                        labelUnchecked={noText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!controlMeasure.selectedControlMeasure.selectedControlMonitoring.IsActioned && (
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formStatus">
                        <Form.Label>
                          <FormattedMessage id="common.status" defaultMessage="Status" />
                        </Form.Label>

                        <Dropdown
                          id="formStatus"
                          dataArray={statusOptions()}
                          controlData={{ placeholderText: ddlInitText }}
                          onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                          data-unittest="formDdlRiskStatus"
                          selectedOption={statusOptions().filter(
                            option => option.value === controlMeasure.selectedControlMeasure.selectedControlMonitoring.StatusId
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Label>
                      <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                    </Form.Label>
                    <FileUploader
                      widgetCode={widgetCode}
                      allowedFileTypes={[
                        ['image/jpeg', 'jpeg'],
                        ['application/pdf', 'pdf'],
                        ['application/msword', 'doc']
                      ]}
                      location={`organisation/${orgId}/sites/${siteId}/controlMonitoring`}
                      enableMultipleUpload={true}
                      maxFiles={5}
                      onUploadComplete={onUploadComplete}
                      onUploadStart={onUploadStart}
                    />
                  </Col>
                </Row>
                <FileList files={controlMeasure.selectedControlMeasure.selectedControlMonitoring.Files} onRemoveFile={removeFile} />
              </div>
            </Form>
          </div>
          <div>
            {IsEdit && isItemListingAllowed && (
              <CorrectiveActionListTable
                correctiveActions={correctiveActionsObj}
                team={team}
                onChangeClick={onChangeCorrectiveAction}
                setShowAddEdit={setShowAddEdit}
                showAddEdit={showAddEdit}
                disableMode={disableMode}
                setDisableMode={setDisableMode}
                onAddClick={onAddCorrectiveAction}
                userProfileWidgets={userProfileWidgets}
                updateAllowed={isUpdateAllowed}
              />
            )}
          </div>
          <div>
            <Row className={s.btnRow}>
              <Col>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                  disabled={disableMode || controlMeasure.isLoading}
                >
                  <FormattedMessage id="common.save" defaultMessage="SAVE" />
                </Button>

                <Button
                  variant="outline-secondary"
                  className={classNames(s.btnCancel)}
                  onClick={cancelHandler}
                  noValidate
                  data-unittest="saveCancel"
                  disabled={disableMode}
                >
                  <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(ControlMonitoring);
