import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import routes from './routes';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import languageObject from './translations/messages';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/polyfill-locales';
import { useSelector } from 'react-redux';
import { getTranslation } from './selectors/index';

const App = props => {
  const { locale } = props.locale;
  const translations = useSelector(state => getTranslation(state)).translations || [];
  const langObject = { ...languageObject[locale], ...translations };

  return (
    <IntlProvider locale={locale} messages={langObject}>
      <ConnectedRouter history={props.history}>{routes}</ConnectedRouter>
    </IntlProvider>
  );
};

App.propTypes = {
  history: PropTypes.object,
  locale: PropTypes.object
};

const mapStateToProps = state => ({ locale: state.locale });

export default connect(mapStateToProps)(App);
