import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from '../ControlMeasure.module.scss';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Activity from '../../../WSAControls/Activity/Activity';
import uuidv4 from 'uuid/v4';
import { initialControlMeasureState } from '../../../../store/reducers/initialState';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import { compareValues } from '../../../../utils';

const CorrectiveActionComments = ({ controlMeasure, saveCorrectiveActionComment, UpdateAllowed, currentTimezone }) => {
  const [correctiveActionComment, setCorrectiveActionComment] = useState('');

  const commentChangeHandler = e => {
    setCorrectiveActionComment(e.target.value);
  };

  const commentSaveHandler = () => {
    if (correctiveActionComment.length > 0) {
      const comment = {
        CorrectiveActionId: controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction.CorrectiveActionId,
        GuidIdentifier: uuidv4(),
        Description: correctiveActionComment
      };
      saveCorrectiveActionComment(comment);
      setCorrectiveActionComment('');
    }
  };

  const commentCancelHandler = () => {
    setCorrectiveActionComment('');
  };

  const comments = () => {
    let commentstData = controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction.comments || [];

    if (commentstData && commentstData.length > 0) {
      commentstData = commentstData.map(comment => ({
        ...comment,
        ActivityDate: comment.CreatedDateTime,
        CreatedByName: comment.FullName,
        Id: comment.CorrectiveActionCommentId
      }));
    }

    return commentstData.sort(compareValues('ActivityDate', 'desc'));
  };

  const headertext = '';

  return (
    <div className={s.controlMeasureContent}>
      {controlMeasure.isLoading && <LoadingSpinner />}
      <Row className={s.breakrow}>
        <Col sm={12}>
          <h4>
            <FormattedMessage id="common.comments" defaultMessage="Comments" />
            <span data-unittest="correctiveActionCommentsCount" className={s.circle}>
              {controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction.comments.length}
            </span>
          </h4>
        </Col>
      </Row>
      <Row className={s.breakrow}>
        <Col sm={12}>
          <Activity
            description={correctiveActionComment || ''}
            onActivityModified={commentChangeHandler}
            onSaveClick={commentSaveHandler}
            onCancelClick={commentCancelHandler}
            currentTimezone={currentTimezone}
            activities={comments()}
            isUpdateAllowed={UpdateAllowed}
            isViewAllowed={true}
            headingText={headertext}
          />
        </Col>
      </Row>
    </div>
  );
};

CorrectiveActionComments.defaultProps = {
  controlMeasure: {
    ...initialControlMeasureState
  }
};

CorrectiveActionComments.propTypes = {
  controlMeasure: PropTypes.object.isRequired,
  saveCorrectiveActionComment: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  UpdateAllowed: PropTypes.bool.isRequired
};

export default CorrectiveActionComments;
