import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import uiReducer from './ui';
import userReducer from './user';
import localeReducer from './locale';
import timezoneReducer from './timezone';
import appWidgetReducer from './appwidgets';
import { LOGOUT } from '../../constants/index';
import profilesReducer from './profiles';
import sidebarReducer from './sidebar';
import userProfileWidgetsReducer from './userProfileWidgets';
import userProfileUnitsReducer from './userProfileUnits';
import userManagerReducer from './userManager';
import organisationManagerReducer from './organisationManager';
import forgotPasswordReducer from './forgotPassword';
import unitManagerReducer from './unitManager';
import taskTemplateReducer from './taskTemplates';
import checklistTemplateReducer from './checklistTemplates';
import checklistReducer from './checklist';
import tasksReducer from './tasks';
import testPurposesReducer from './testPurposes';
import inspectionPurposesReducer from './inspectionPurpose';
import testCategoryReducer from './testCategories';
import inspectionTypeReducer from './inspectionType';
import inspectionTypeQuestionReducer from './InspectionTypeQuestion';
import generalDataReducer from './generalData';
import inspectionReportReducer from './inspectionReport';
import testResultReducer from './testResults';
import siteReducer from './sites';
import entityReducer from './entity';
import qualityGoalsReducer from './qualityGoals';
import waterSupplyDescriptionReducer from './waterSupplyDescriptions';
import generalFieldTypeReducer from './generalFieldType';
import riskCategoryReducer from './riskCategory';
import hazardEventReducer from './hazardEvent/hazardEvent';
import hazardEventLibraryReducer from './hazardEventLibrary';
import hazardReducer from './hazards';
import riskAssessmentReducer from './riskAssessment';
import safetyPlanReducer from './safetyPlan/safetyPlan';
import emergencyResponsePlanReducer from './safetyPlan/emergencyResponsePlan';
import personnelReducer from './admin/personnel';
import teamReducer from './safetyPlan/team';
import stakeholderRegisterReducer from './safetyPlan/stakeholderRegister';
import regulatoryRegisterReducer from './safetyPlan/regulatoryRegister';
import controlMeasureReducer from './hazardEvent/controlMeasures';
import improvementPlanReducer from './hazardEvent/improvementPlan';
import sitePathwayReducer from './sitePathway';
import siteSegmentsReducer from './siteSegments';
import organisationTranslationReducer from './admin/organisationTranslations';
import translationReducer from './translation';
import incidentReportsReducer from './incidentReport';
import correctiveActionReducer from './correctiveAction';
import entitySubTypeReducer from './admin/entitySubType';
import controlMonitoringReducer from './controlMonitoring';
import safetyPlanReportReducer from './safetyPlan/safetyPlanReport';

const appReducer = history =>
  combineReducers({
    user: userReducer,
    ui: uiReducer,
    router: connectRouter(history),
    locale: localeReducer,
    timezone: timezoneReducer,
    profile: profilesReducer,
    sidebarOpen: sidebarReducer,
    appWidgets: appWidgetReducer,
    userProfileWidgets: userProfileWidgetsReducer,
    userProfileUnits: userProfileUnitsReducer,
    userManager: userManagerReducer,
    organisationManager: organisationManagerReducer,
    unitManager: unitManagerReducer,
    forgotPassword: forgotPasswordReducer,
    taskTemplate: taskTemplateReducer,
    checklistTemplate: checklistTemplateReducer,
    tasks: tasksReducer,
    checklist: checklistReducer,
    testPurpose: testPurposesReducer,
    inspectionPurpose: inspectionPurposesReducer,
    testCategory: testCategoryReducer,
    inspectionType: inspectionTypeReducer,
    inspectionTypeQuestion: inspectionTypeQuestionReducer,
    generalData: generalDataReducer,
    inspectionReport: inspectionReportReducer,
    testResult: testResultReducer,
    site: siteReducer,
    entity: entityReducer,
    qualityGoals: qualityGoalsReducer,
    waterSupplyDescription: waterSupplyDescriptionReducer,
    generalFieldType: generalFieldTypeReducer,
    riskCategory: riskCategoryReducer,
    hazardEvent: hazardEventReducer,
    hazardEventLibrary: hazardEventLibraryReducer,
    hazard: hazardReducer,
    riskAssessment: riskAssessmentReducer,
    safetyPlan: safetyPlanReducer,
    emergencyResponsePlan: emergencyResponsePlanReducer,
    personnel: personnelReducer,
    safetyPlanTeam: teamReducer,
    stakeholderRegister: stakeholderRegisterReducer,
    regulatoryRegister: regulatoryRegisterReducer,
    safetyPlanReport: safetyPlanReportReducer,
    controlMeasure: controlMeasureReducer,
    improvementPlan: improvementPlanReducer,
    sitePathway: sitePathwayReducer,
    siteSegments: siteSegmentsReducer,
    organisationTranslation: organisationTranslationReducer,
    translation: translationReducer,
    incidentReports: incidentReportsReducer,
    correctiveAction: correctiveActionReducer,
    entitySubType: entitySubTypeReducer,
    controlMonitoring: controlMonitoringReducer
  });

const rootReducer = history => (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export default rootReducer;
