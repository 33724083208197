import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';

import {
  CONTROL_MEASURE_ERROR,
  GET_ALL_CONTROL_MEASURE_DETAILS,
  CONTROL_MEASURE_IS_LOADING,
  SET_CONTROL_MEASURE_CURRENT_PAGE,
  SET_CONTROL_MEASURE_PAGE_FILTER,
  SET_CONTROL_MEASURE_CHANGE,
  SET_CONTROL_MEASURE,
  UPDATE_CONTROL_MEASURE_SAVE_STATUS,
  SET_CONTROL_MEASURE_FILTERS,
  SET_CONTROL_MEASURE_RESULTS,
  REMOVE_CONTROL_MEASURE,
  SET_CONTROL_MONITORING_CHANGE,
  SET_CORRECTIVE_ACTION_CHANGE,
  SET_CONTROL_MONITORING,
  SET_CORRECTIVE_ACTION,
  SET_CORRECTIVE_ACTION_COMMENTS,
  UPDATE_CONTROL_MONITORING_SAVE_STATUS,
  UPDATE_CORRECTIVE_ACTION_SAVE_STATUS,
  GET_ALL_MODELCOMPONENTPOSITION_DETAILS,
  REMOVE_CONTROL_MONITORING
} from '../../../constants/index';

import {
  CONTROL_MEASURE_MANAGEMENT,
  CREATE_CONTROL_MEASURE,
  UPDATE_CONTROL_MEASURE,
  UPDATE_CONTROL_MONITORING,
  UPDATE_CORRECTIVE_ACTION
} from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setControlMeasureErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setControlMeasureError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setControlMeasureError(messageCode, false));
  }, 2500);
};

export const setControlMeasureError = (messageCode, status) => {
  return {
    type: CONTROL_MEASURE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: CONTROL_MEASURE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getControlMeasureThunk = (widgetCode, filterObj, hazardEventId, siteId) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getControlMeasure(widgetCode, orgId, filterObj, hazardEventId, siteId));
};

export const getControlMeasure = (widgetCode, orgId, filterObj, hazardEventId, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/hazardevents/hazardevent/controlmeasures`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      HazardEventId: hazardEventId,
      SiteId: siteId,
      filter: filterObj.filter,
      limit: filterObj.limit,
      offset: filterObj.offset,
      filterName: filterObj.filterName,
      filterStatusId: filterObj.filterStatusId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllControlMeasureListThunk(retData),
    onFailure: err => setControlMeasureErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllControlMeasureListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let controlMeasures = (retData && retData.retData.data) || [];
  dispatch(setAllControlMeasureList(messageCode, controlMeasures));
};

export const setAllControlMeasureList = (messageCode, controlMeasures) => ({
  type: GET_ALL_CONTROL_MEASURE_DETAILS,
  payload: {
    data: {
      controlMeasures: controlMeasures,
      filteredResults: controlMeasures,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_CONTROL_MEASURE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_CONTROL_MEASURE_PAGE_FILTER,
  payload: { filter }
});

export const describeControlMeasureThunk = (ControlMeasureId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeControlMeasure(ControlMeasureId, widgetCode, orgId));
};

export const describeControlMeasure = (ControlMeasureId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/hazardevents/hazardevent/controlmeasures`,
    data: {
      ControlMeasureId: ControlMeasureId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setControlMeasureThunk(ControlMeasureId, retData),
    onFailure: err => setControlMeasureErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setControlMeasureThunk = (ControlMeasureId, { retData }) => dispatch => {
  let selectedControlMeasure = retData.data;

  if (
    (!selectedControlMeasure && ControlMeasureId !== null && parseInt(ControlMeasureId) !== 0) ||
    (ControlMeasureId &&
      selectedControlMeasure &&
      Object.keys(selectedControlMeasure).length > 0 &&
      parseInt(ControlMeasureId) !== selectedControlMeasure.ControlMeasureId)
  ) {
    redirectToPageNotFound(dispatch);
  } else {
    let details = retData.details || [];
    let mapData = {
      selectedControlMeasure: {
        ...selectedControlMeasure,
        controlMonitorings: details
      }
    };
    dispatch(setControlMeasure(mapData));
  }
};

export const setControlMeasure = data => ({
  type: SET_CONTROL_MEASURE,
  payload: data
});

export const saveControlMeasureThunk = (controlMeasure, widgetCode) => dispatch => {
  let url = '/hazardevents/hazardevent/controlmeasures/save';
  let actionName = controlMeasure.ControlMeasureId === 0 ? CREATE_CONTROL_MEASURE : UPDATE_CONTROL_MEASURE;
  let log = logEntry(CONTROL_MEASURE_MANAGEMENT, actionName, controlMeasure);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  controlMeasure.OrganisationId = orgId;
  dispatch(saveControlMeasure(controlMeasure, url, log, widgetCode));
};

export const saveControlMeasure = (controlMeasure, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setControlMeasureStatusThunk(retData, controlMeasure),
    onFailure: err => setControlMeasureErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: controlMeasure,
      log: log
    },
    widgetcode: widgetCode
  });

export const setControlMeasureStatusThunk = (message, controlMeasure) => dispatch => {
  dispatch(setControlMeasureStatus(message, true, false));

  setTimeout(() => {
    //Hide the banner
    let isRedirect = !controlMeasure.PerformDelete;
    dispatch(setControlMeasureStatus(message, false, isRedirect));
    if (controlMeasure.PerformDelete) {
      dispatch(removeControlMeasure(controlMeasure.ControlMeasureId));
    }
  }, 2500);
};

export const removeControlMeasure = data => ({
  type: REMOVE_CONTROL_MEASURE,
  payload: {
    ControlMeasureId: data
  }
});

export const setControlMeasureStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_CONTROL_MEASURE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const setControlMeasureChangeThunk = retData => dispatch => {
  dispatch(setControlMeasureChange(retData));
};

export const setControlMeasureChange = retData => ({
  type: SET_CONTROL_MEASURE_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, hazardEventId, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, hazardEventId, siteId));
};

export const getFilter = (widgetCode, hazardEventId, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      HazardEventId: hazardEventId,
      SiteId: siteId,
      filterFor: 'controlMeasureListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setControlMeasureErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_CONTROL_MEASURE_FILTERS,
  payload: {
    data: {
      isLoading: false,
      controlMeasureListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_CONTROL_MEASURE_RESULTS,
  payload: retData
});

//Control Monitorings
export const describeControlMonitoringThunk = (ControlMonitoringId, ControlMeasureId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeControlMonitoring(ControlMonitoringId, ControlMeasureId, widgetCode, orgId));
};

export const describeControlMonitoring = (ControlMonitoringId, ControlMeasureId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/hazardevents/hazardevent/controlmeasures/controlmonitorings/correctiveactions`,
    data: {
      ControlMeasureId: ControlMeasureId,
      ControlMonitoringId: ControlMonitoringId,
      DescribeControlMonitoring: true,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setControlMonitoringThunk(retData),
    onFailure: err => setControlMeasureErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setControlMonitoringThunk = ({ retData }) => dispatch => {
  let selectedControlMonitoring = retData.controlMonitoring;
  let details = retData.correctiveActions || [];
  let mapData = {
    selectedControlMonitoring: {
      ...selectedControlMonitoring,
      correctiveActions: details
    }
  };
  dispatch(setControlMonitoring(mapData));
};

export const setControlMonitoring = data => ({
  type: SET_CONTROL_MONITORING,
  payload: data
});

export const saveControlMonitoringThunk = (controlMonitoring, widgetCode) => dispatch => {
  let url = '/hazardevents/hazardevent/controlmeasures/controlmonitorings/save';
  let actionName = UPDATE_CONTROL_MONITORING;
  let log = logEntry(CONTROL_MEASURE_MANAGEMENT, actionName, controlMonitoring);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  controlMonitoring.OrganisationId = orgId;
  dispatch(saveControlMonitoring(controlMonitoring, url, log, widgetCode));
};

export const saveControlMonitoring = (controlMonitoring, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setControlMonitoringStatusThunk(retData, controlMonitoring),
    onFailure: err => setControlMeasureErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: controlMonitoring,
      log: log
    },
    widgetcode: widgetCode
  });

export const setControlMonitoringStatusThunk = (message, controlMonitoring) => dispatch => {
  dispatch(setControlMonitoringStatus(message, true, false));

  setTimeout(() => {
    //Hide the banner
    let isRedirect = !controlMonitoring.PerformDelete;
    dispatch(setControlMonitoringStatus(message, false, isRedirect));
    if (controlMonitoring.PerformDelete) {
      dispatch(removeControlMonitoring(controlMonitoring.ControlMonitoringId));
    }
  }, 2500);
};

export const removeControlMonitoring = data => ({
  type: REMOVE_CONTROL_MONITORING,
  payload: {
    ControlMonitoringId: data
  }
});

export const setControlMonitoringStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_CONTROL_MONITORING_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isControlMonitoringRedirect: isRedirect
    }
  }
});

export const setControlMonitoringChangeThunk = retData => dispatch => {
  dispatch(setControlMonitoringChange(retData));
};

export const setControlMonitoringChange = retData => ({
  type: SET_CONTROL_MONITORING_CHANGE,
  payload: retData
});

//Corrective Actions
export const describeCorrectiveActionThunk = (CorrectiveActionId, ControlMonitoringId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeCorrectiveAction(CorrectiveActionId, ControlMonitoringId, widgetCode, orgId));
};

export const describeCorrectiveAction = (CorrectiveActionId, ControlMonitoringId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/hazardevents/hazardevent/controlmeasures/controlmonitorings/correctiveactions`,
    data: {
      CorrectiveActionId: CorrectiveActionId,
      ControlMonitoringId: ControlMonitoringId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCorrectiveActionThunk(retData),
    onFailure: err => setControlMeasureErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setCorrectiveActionThunk = ({ retData }) => dispatch => {
  let selectedCorrectiveAction = retData.correctiveActions;
  let comments = retData.comments || [];
  let mapData = {
    selectedCorrectiveAction: {
      ...selectedCorrectiveAction,
      comments: comments
    }
  };
  dispatch(setCorrectiveAction(mapData));
};

export const setCorrectiveAction = data => ({
  type: SET_CORRECTIVE_ACTION,
  payload: data
});

export const saveCorrectiveActionThunk = (correctiveAction, widgetCode) => dispatch => {
  let url = '/hazardevents/hazardevent/controlmeasures/controlmonitorings/correctiveactions/save';
  let actionName = UPDATE_CORRECTIVE_ACTION;
  let log = logEntry(CONTROL_MEASURE_MANAGEMENT, actionName, correctiveAction);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  correctiveAction.OrganisationId = orgId;
  dispatch(saveCorrectiveAction(correctiveAction, url, log, widgetCode));
};

export const saveCorrectiveAction = (correctiveAction, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCorrectiveActionStatusThunk(retData, correctiveAction),
    onFailure: err => setControlMeasureErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: correctiveAction,
      log: log
    },
    widgetcode: widgetCode
  });

export const setCorrectiveActionStatusThunk = (data, correctiveAction) => dispatch => {
  let message = data;
  if (correctiveAction.PerformCommentInsert) {
    dispatch(setCorrectiveActionComments(data.retData));
  } else {
    dispatch(setCorrectiveActionStatus(message, true, false));

    setTimeout(() => {
      //Hide the banner
      dispatch(setCorrectiveActionStatus(message, false, false));
    }, 2500);
  }
};

export const setCorrectiveActionStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_CORRECTIVE_ACTION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const setCorrectiveActionComments = data => ({
  type: SET_CORRECTIVE_ACTION_COMMENTS,
  payload: {
    selectedCorrectiveAction: {
      comments: data
    }
  }
});

export const setCorrectiveActionChangeThunk = retData => dispatch => {
  dispatch(setCorrectiveActionChange(retData));
};

export const setCorrectiveActionChange = retData => ({
  type: SET_CORRECTIVE_ACTION_CHANGE,
  payload: retData
});

export const getModelComponentPositionThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getModelComponentPosition(filterObj, widgetCode));
};

export const getModelComponentPosition = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/modelComponentPositions`,
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllModelComponentPositionListThunk(retData),
    onFailure: err => setControlMeasureErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllModelComponentPositionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let modelComponentPositions = (retData && retData.modelComponentPositions) || [];
  dispatch(setAllModelComponentPositionList(messageCode, modelComponentPositions));
};

export const setAllModelComponentPositionList = (messageCode, modelComponentPositions) => ({
  type: GET_ALL_MODELCOMPONENTPOSITION_DETAILS,
  payload: {
    data: {
      unitComponentPositions: modelComponentPositions,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});
