import { initialSafetyPlanTeamState } from '../initialState';

import {
  TEAM_ERROR,
  GET_ALL_TEAM_DETAILS,
  TEAM_IS_LOADING,
  SET_TEAM_CURRENT_PAGE,
  SET_TEAM_PAGE_FILTER,
  SET_TEAM_CHANGE,
  SET_TEAM,
  UPDATE_TEAM_SAVE_STATUS,
  SET_TEAM_FILTERS,
  SET_TEAM_RESULTS,
  REMOVE_TEAM
} from '../../../constants/index';

const teamReducer = (state = initialSafetyPlanTeamState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_TEAM_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          ...data
        }
      };
    }

    case SET_TEAM_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          currentPage
        }
      };
    }

    case SET_TEAM_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_TEAM_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          ...data
        }
      };
    }

    case TEAM_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          isLoading: isLoading
        }
      };
    }

    case TEAM_ERROR: {
      const { data } = payload;
      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          ...data
        }
      };
    }

    case SET_TEAM: {
      let data = payload;

      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          isOpSuccessful: false,
          showBanner: false,
          selectedTeam: {
            ...state.safetyPlanTeam.selectedTeam,
            ...data
          }
        }
      };
    }

    case REMOVE_TEAM: {
      const { teamId } = payload;
      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          isOpSuccessful: false,
          isRedirect: false,
          team: [...state.safetyPlanTeam.team.filter(item => item.TeamId !== teamId)],
          filteredResults: [...state.safetyPlanTeam.filteredResults.filter(item => item.TeamId !== teamId)],
          teamListCount: state.safetyPlanTeam.teamListCount - 1
        }
      };
    }

    case SET_TEAM_CHANGE: {
      let { selectedTeam } = payload;

      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          isOpSuccessful: false,
          showBanner: false,
          selectedTeam: {
            ...state.safetyPlanTeam.selectedTeam,
            ...selectedTeam
          }
        }
      };
    }

    case SET_TEAM_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          ...data
        }
      };
    }

    case SET_TEAM_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        safetyPlanTeam: {
          ...state.safetyPlanTeam,
          ...filteredResults
        }
      };
    }

    default:
      return state;
  }
};

export default teamReducer;
