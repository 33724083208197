import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './InspectionReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import InspectionReportListTable from './InspectionReportListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import { getUserProfileWidget, getInspectionReport, getGeneralData } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import {
  getInspectionReportThunk,
  getFilterThunk,
  setFilteredDataThunk,
  setCurrentPage,
  setPageFilter,
  saveInspectionReportThunk
} from '../../store/actions/inspectionReport';

const ENTITY_TYPE = 'ENTITY_TYPE';
const INSPECTION_TYPE = 'INSPECTION_TYPE';
const ENTITY = 'ENTITY';
const SEARCH_FILTER = 'SEARCH_FILTER';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const InspectionReportList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const inspectionReport = useSelector(state => getInspectionReport(state));
  const generalData = useSelector(state => getGeneralData(state));

  let { siteId, segmentName, selectedTab } = useParams();
  selectedTab = selectedTab || '2';

  let filter = inspectionReport.filterBy.filter || null;
  let offset = inspectionReport.offset || 0;
  let limit = inspectionReport.limit || 1000;

  let filterRedEntityType = inspectionReport.filterBy.filterEntityType || null;
  let filterRedEntity = inspectionReport.filterBy.filterEntity || null;
  let filterRedInspectionType = inspectionReport.filterBy.filterInspectionType || null;
  let disableControls = inspectionReport.isLoading || false;

  const [active, setActive] = useState(false);
  const [inspectionReportId, setInspectionReportId] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [filterEntityType, setFilterEntityType] = useState(0);
  const [filterEntity, setFilterEntity] = useState(0);
  const [filterInspectionType, setFilterInspectionType] = useState(0);

  const [asendingOrder, setAsendingOrder] = useState(true);

  useEffect(() => {
    if (siteId) {
      dispatch(getFilterThunk('WQSP_INSPECTIONREPORT_LIST', siteId));
    }
  }, [dispatch, getFilterThunk]);

  useEffect(() => {
    let filterObj = {
      filterEntityType: filterRedEntityType,
      filterEntity: filterRedEntity,
      filterInspectionType: filterRedInspectionType,
      filterSiteId: siteId,
      filter: filter,
      offset: offset,
      limit: limit
    };
    dispatch(getInspectionReportThunk('WQSP_INSPECTIONREPORT_LIST', filterObj));
  }, [getInspectionReportThunk, filter, filterRedEntityType, filterRedEntity, filterRedInspectionType]);

  let messageId = (inspectionReport && inspectionReport.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const entityTypeOptions = () => {
    let opData = [];

    opData.push({
      label: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
      value: 0
    });
    const data = generalData.entityTypes;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntityTypeId
      });
    });
    return opData;
  };

  const entityOptions = () => {
    let opData = [];
    const data = generalData.entities.filter(x => x.EntityTypeId === filterEntityType);
    opData.push({
      label: <FormattedMessage id="wqsp.filters.allEntities" defaultMessage="All Entities" />,
      value: 0
    });

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntityId
      });
    });
    return opData;
  };

  const inspectionTypesOptions = () => {
    let opData = [];
    const data = inspectionReport.filterData.InspectionTypes;

    opData.push({
      label: <FormattedMessage id="wqsp.filters.allInspectionTypes" defaultMessage="All Inspction Types" />,
      value: 0
    });

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.InspectionTypeId
      });
    });
    return opData;
  };

  const onDropdownChange = (e, fieldName) => {
    if (fieldName === ENTITY_TYPE) {
      setFilterEntityType(e.value);
    }

    if (fieldName === ENTITY) {
      setFilterEntity(e.value);
    }

    if (fieldName === INSPECTION_TYPE) {
      setFilterInspectionType(e.value);
    }

    filteredResults(fieldName, e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = inspectionReport.InspectionReports;

    let apifilterObj = {
      filterEntityType: null,
      filterEntity: null,
      filter: null
    };

    if (filterType === ENTITY_TYPE) {
      if (value !== 0) {
        apifilterObj.filterEntityType = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === value;
        });
      }
    } else {
      if (filterEntityType !== 0) {
        apifilterObj.filterEntityType = filterEntityType;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityType;
        });
      }
    }

    if (filterType === ENTITY) {
      if (value !== 0) {
        apifilterObj.filterEntity = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === value;
        });
      }
    } else {
      if (filterEntity !== 0) {
        apifilterObj.filterEntity = filterEntity;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === filterEntity;
        });
      }
    }

    if (filterType === INSPECTION_TYPE) {
      if (value !== 0) {
        apifilterObj.filterInspectionType = value;
        filteredList = filteredList.filter(function(item) {
          return item.InspectionTypeId === value;
        });
      }
    } else {
      if (filterInspectionType !== 0) {
        apifilterObj.filterInspectionType = filterInspectionType;
        filteredList = filteredList.filter(function(item) {
          return item.InspectionTypeId === filterInspectionType;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.InspectorName.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.InspectorName.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (inspectionReport.inspectionReportCount > inspectionReport.InspectionReports.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let inspectionReportObj = Object.values(inspectionReport.filteredResults);

  if (asendingOrder) {
    inspectionReportObj = inspectionReportObj.sort(compareValues('InspectionDate'));
  } else {
    inspectionReportObj = inspectionReportObj.sort(compareValues('InspectionDate', 'desc'));
  }

  const onAddNewClick = () => {
    if (segmentName === 'measure') {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/inspections/inspectionReport`);
    } else {
      history.push(`/inspections/inspectionReport`);
    }
  };

  const onChangeInspectionReportClick = (siteId, inspectionReportId) => {
    history.push(`/site/${siteId}/${segmentName || 'measure'}/${selectedTab}/inspections/inspectionReport/${inspectionReportId}`);
  };

  const onCreateIncidentReportClick = n => {
    history.push(`/site/${n.SiteId}/respond/2/incident-reports/incident-report`, {
      docType: 'INSPECTION_REPORT',
      referedId: n.InspectionReportId,
      obj: n
    });
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONREPORT_UPDATE');
  const allowIncidentReport = IsWidgetAccessible(userProfileWidgets, 'WQSP_INCIDENT_REPORT_ADDNEW');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONREPORT_DELETE');

  const onInspectionReportRemoveClick = inspectionReportId => {
    setActive(true);
    setInspectionReportId(inspectionReportId);
  };

  const handleClose = () => {
    setActive(false);
    setInspectionReportId(0);
  };

  const handleConfirm = () => {
    deleteInspectionReport();
    handleClose();
  };

  const deleteInspectionReport = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.InspectionReportId = inspectionReportId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveInspectionReportThunk(saveData, 'WQSP_INSPECTIONREPORT_DELETE'));
  };

  const dialogTitle = intl.formatMessage({
    id: 'inspectionReport.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this inspection report?'
  });

  //const isUpdateAllowed = true;

  return (
    <SiteDashboardControlsContentTemplate
      selectedPage={selectedTab}
      siteId={siteId}
      segmentName={segmentName}
      userProfileWidgets={userProfileWidgets}
    >
      <div className={s.inspectionReportList}>
        {!segmentName && (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="inspectionReport.inspections" defaultMessage="Inspections" />}</h1>
          </div>
        )}

        <div className={s.contentWrapper}>
          {inspectionReport.isLoading && <LoadingSpinner />}
          <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
          {inspectionReport.showBanner && (
            <Banner
              failureText={messageText}
              showBanner={inspectionReport.showBanner}
              status={inspectionReport.isOpSuccessful}
              successText={messageText}
            />
          )}
          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="inspectionReport.searchInspectionName"
              />
            </Col>
            <Col sm={12} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_INSPECTIONPURPOSE_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addInspectionPurposeButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col sm={6} lg={2}>
              <Dropdown
                id="formSourceEntityType"
                dataArray={entityTypeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
                  customClassName: ''
                }}
                name="EntityTypeId"
                onDropdownChange={e => onDropdownChange(e, ENTITY_TYPE)}
                selectedOption={entityTypeOptions().filter(option => option.value === filterEntityType)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="formSourceEntity"
                dataArray={entityOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.filters.allEntities" defaultMessage="All Entities" />,
                  customClassName: ''
                }}
                name="EntityId"
                onDropdownChange={e => onDropdownChange(e, ENTITY)}
                selectedOption={entityOptions().filter(option => option.value === filterEntity)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="formInspectionType"
                dataArray={inspectionTypesOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.filters.allInspectionTypes" defaultMessage="All Inspection Types" />,
                  customClassName: ''
                }}
                name="InspectionTypeId"
                onDropdownChange={e => onDropdownChange(e, INSPECTION_TYPE)}
                selectedOption={inspectionTypesOptions().filter(option => option.value === filterInspectionType)}
                disabled={disableControls}
              />
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={inspectionReportObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={inspectionReport.currentPage}
            >
              <InspectionReportListTable
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                ChangeInspectionReport={onChangeInspectionReportClick}
                UpdateAllowed={isUpdateAllowed}
                AllowCreateIncidentReport={allowIncidentReport}
                CreateIncidentReport={onCreateIncidentReportClick}
                deleteAllowed={isDeleteAllowed}
                removeInspectionReport={onInspectionReportRemoveClick}
              />
            </Pagination>
          </div>
        </div>
      </div>
    </SiteDashboardControlsContentTemplate>
  );
};

export default injectIntl(InspectionReportList);
