import React, { Fragment, useState } from 'react';
import s from './HazardEvent.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { getDateInDDMMYYYFormat, compareValues } from '../../utils';
import { getGeneralData } from '../../selectors/index';
import { useSelector } from 'react-redux';
import { RISK_RESPONSE } from '../../constants/index';
import { useHistory, useParams } from 'react-router-dom';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';

const RiskAssessmentItemTable = ({ hazardEvent, riskAssessmentItems, riskStatuses, currentTimezone, userProfileWidgets, view, intl }) => {
  const [statusId, setSearchFilter] = useState(0);
  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKASSESSMENT_VIEW');
  const history = useHistory();
  const { hazardEventId, siteId, segmentName, entityId, selectedTab } = useParams();

  const generalData = useSelector(state => getGeneralData(state));

  const riskResponses = generalData.generalFieldTypes.filter(item => item.FieldType === RISK_RESPONSE);
  let ddlInitText = intl.formatMessage({ id: 'filters.allstatus', defaultMessage: 'All Status' });

  const searchHandler = element => {
    setSearchFilter(element.value);
  };

  let riskAssessmentItemsFiltered = (statusId && riskAssessmentItems.filter(item => item.StatusId === statusId)) || riskAssessmentItems;

  const addNewEventItemHandler = () => {
    let addNewUrl = `/site/${siteId}/${segmentName}/${selectedTab}/risk-assessments/risk-assessment?hazardEventId=${hazardEventId}&entityId=${entityId}`;
    if ((segmentName = 'define' && entityId)) {
      addNewUrl = addNewUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment`;
    }
    history.push(addNewUrl);
  };

  const onViewClick = assessmentId => {
    history.push(
      `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment-view/${assessmentId}`
    );
  };

  const getRiskResponseNames = ids => {
    let splitIds = ids.split(',');
    let selResponses = riskResponses.filter(item => splitIds.find(id => id === item.GeneralFieldTypeId.toString()));
    return (selResponses && selResponses.length && selResponses.map(item => item.Name).join(', ')) || '';
  };

  const getTranslatedText = id => {
    if (id) return intl.formatMessage({ id: id, defaultMessage: id });
    return null;
  };

  const riskStatusOptions = () => {
    let statusData = [];
    riskStatuses.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  return (
    <div>
      <div className={s.hazardeventTypeContent}>
        <Row>
          <Col>
            <h4 className={s.subheading}>
              <FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />
              <span className={s.circle}>{riskAssessmentItemsFiltered.length || 0}</span>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4}>
            <Dropdown
              id="formDdlRiskStatus"
              dataArray={riskStatusOptions()}
              controlData={{ placeholderText: ddlInitText }}
              onDropdownChange={e => searchHandler(e)}
              data-unittest="formDdlRiskStatus"
              selectedOption={riskStatusOptions().filter(option => option.value === statusId)}
            />
          </Col>
          <Col xs={6} lg={8}>
            {(!view && (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_RISKASSESSMENT_ADDNEW')}
                onClick={() => {
                  addNewEventItemHandler();
                }}
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            )) ||
              ''}
          </Col>
        </Row>
      </div>
      <div className={s.paddingTop}>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="riskAssessment.riskNumber" defaultMessage="Risk Number" />
              </th>
              <th>
                <FormattedMessage id="common.status" defaultMessage="Status" />
              </th>
              <th>
                <FormattedMessage id="riskAssessment.inherentRiskRating" defaultMessage="Inherent Risk Rating" />
              </th>
              <th>
                <FormattedMessage id="riskAssessment.residualRiskRating" defaultMessage="Residual Risk Rating" />
              </th>
              <th>
                <FormattedMessage id="riskAssessment.riskResponse" defaultMessage="Risk Response" />
              </th>
              <th>
                <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
              </th>
              {isViewAllowed && <th></th>}
            </tr>
          </thead>
          <tbody>
            {riskAssessmentItemsFiltered.map(item => {
              return (
                !item.IsDeleted && (
                  <tr key={item.GuidIdentifier} data-unittest="EventItemList">
                    <td>{item.RiskNumber}</td>
                    <td>{item.Status}</td>
                    <td>{getTranslatedText(item.InherentRiskRating)}</td>
                    <td>{getTranslatedText(item.ResidualRiskRating)}</td>
                    <td>{(item.Response && getRiskResponseNames(item.Response)) || ''}</td>
                    <td>{getDateInDDMMYYYFormat(item.LastReviewDate, currentTimezone) || '-'}</td>
                    {isViewAllowed && (
                      <td>
                        <div className={s.buttonContainer}>
                          <Button
                            variant="outline-secondary"
                            className={s.removeButton}
                            href="#"
                            onClick={() => {
                              onViewClick(item.RiskAssessmentId);
                            }}
                            data-unittest="viewData"
                            style={WidgetVisibility(userProfileWidgets, `WQSP_RISKASSESSMENT_VIEW`)}
                          >
                            <FormattedMessage id="common.view" defaultMessage="View" />
                          </Button>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

RiskAssessmentItemTable.defaultProps = {
  riskAssessmentItems: [],
  hazardEvent: [],
  riskStatuses: [],
  userProfileWidgets: {},
  view: false
};

export default injectIntl(RiskAssessmentItemTable);
