import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Task from './Task';
import {
  getTaskManager,
  getTaskTemplateManager,
  getUserProfileWidget,
  getProfile,
  getUserManager,
  getCurrentTimezone
} from '../../selectors/index';
import { getProfileThunk } from '../../store/actions//profiles';
import { describeTasksThunk, saveTasksThunk, setTasksChangeThunk } from '../../store/actions//tasks';
import { getTaskTemplateThunk } from '../../store/actions//taskTemplates';
import { getUsersThunk } from '../../store/actions//userManager';

const mapStateToProps = state => {
  return {
    taskManager: getTaskManager(state),
    userProfileWidgets: getUserProfileWidget(state),
    profile: getProfile(state),
    taskTemplate: getTaskTemplateManager(state),
    userManager: getUserManager(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeTask: taskId => describeTasksThunk(taskId, 'WQSP_TASKEDIT'),
      saveTask: (task, widgetCode) => saveTasksThunk(task, widgetCode),
      changeTask: task => setTasksChangeThunk(task),
      getProfileInfo: widgetCode => getProfileThunk(widgetCode),
      getTaskTemplates: () => getTaskTemplateThunk('WQSP_TASKADDNEW'),
      getUsers: orgId => getUsersThunk(null, null, orgId, 'WQSP_TASKADDNEW')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Task);
