import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ChecklistTasksTemplates.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { WidgetVisibility } from '../../utils/widgetManager';
import { Link } from 'react-router-dom';

const ChecklistTasksTemplatesTable = ({ tableData, userProfileWidgets, viewName, intl }) => {
  return (
    <Table variant className={s.innerTable}>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="common.name" defaultMessage="Name" />
          </th>
          <th>
            <FormattedMessage id="emergencyPlan.emergencyResponsePlans" defaultMessage="Emergency Response Plan" />
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData.map(n => {
          return (
            <Fragment key={`${viewName}-${n.Id}`}>
              <tr key={`${viewName}-${n.Id}`} data-unittest="templateData">
                <td>{n.Name}</td>
                <td>
                  {n.IsERP ? (
                    <FormattedMessage id="common.yes" defaultMessage="Yes" />
                  ) : (
                    <FormattedMessage id="common.no" defaultMessage="No" />
                  )}
                </td>
                <td>
                  <Link to={n.Link}>
                    <Button
                      variant="outline-secondary"
                      data-unittest="view"
                      className={s.viewButton}
                      style={WidgetVisibility(userProfileWidgets, viewName)}
                    >
                      <FormattedMessage id="common.view" defaultMessage="VIEW" />
                    </Button>
                  </Link>
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

ChecklistTasksTemplatesTable.defaultProps = {
  tableData: [],
  userProfileWidgets: {}
};

ChecklistTasksTemplatesTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  viewName: PropTypes.string.isRequired
};

export default injectIntl(ChecklistTasksTemplatesTable);
