import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ChecklistDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment-timezone';
import SearchBar from '../SearchBar/SearchBar';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { CHECKLIST_STATUS_ACTIVE, CHECKLIST_STATUS_COMPLETE } from '../../constants/index';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import { getOverdueStatus } from '../../utils';

const ChecklistDetailTask = ({
  intl,
  onTaskSearch,
  onTaskStatusSearch,
  onTaskRemove,
  addNewBtnClick,
  viewBtnClick,
  selectedTasks,
  isView,
  isEdit,
  isChecklistCompleted,
  sortOrder,
  onSortOrder,
  currentTimezone
}) => {
  let sortIcon = sortOrder ? ascendingIcon : descendingIcon;

  const onDropdownChange = e => {
    onTaskStatusSearch(e.value);
  };

  const onAddNewBtnClick = () => {
    addNewBtnClick();
  };

  const onViewBtnClick = url => {
    viewBtnClick(url);
  };

  const taskStatusSearchOptions = () => {
    return [
      {
        label: intl.formatMessage({ id: 'common.showAll', defaultMessage: 'Show:All' }),
        value: 'ALL'
      },
      {
        label: intl.formatMessage({ id: 'checklistTask.showActive', defaultMessage: 'Show:Active' }),
        value: CHECKLIST_STATUS_ACTIVE
      },
      {
        label: intl.formatMessage({ id: 'checklistTask.showComplete', defaultMessage: 'Show:Complete' }),
        value: CHECKLIST_STATUS_COMPLETE
      }
    ];
  };

  return (
    <div className={s.taskContainer}>
      <div className={s.subHeading}>
        <h4>
          <FormattedMessage id="checklistTasks.tasks" defaultMessage="Tasks" />
        </h4>
        <span className={s.circle}>{selectedTasks.length}</span>
      </div>
      <div>
        <Row>
          <Col xs={4} lg={4}>
            <SearchBar searchHandler={onTaskSearch} clearSearchInVisible={false} placeHolderTextId="common.search" />
          </Col>
          <Col xs={4} lg={4}>
            <Dropdown
              id="formTaskStatusSearch"
              dataArray={taskStatusSearchOptions()}
              controlData={{
                placeholderText: intl.formatMessage({ id: 'common.showAll', defaultMessage: 'Show:All' }),
                customClassName: s.ddlTaskStatusSearch
              }}
              onDropdownChange={onDropdownChange}
              data-unittest="formTaskStatusSearch"
            />
          </Col>
          <Col xs={4} lg={4} className={s.colAddButton}>
            {!isView && (
              <Button
                disabled={!isEdit || isChecklistCompleted}
                variant="primary"
                data-unittest="addnew"
                className={s.addButton}
                onClick={() => onAddNewBtnClick()}
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <div>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="common.task" defaultMessage="Task" />
              </th>
              <th className={`d-none d-md-table-cell ${s.sortHeading}`} onClick={onSortOrder}>
                <FormattedMessage id="wqsp.common.dueDate" defaultMessage="Due Date" />
                <img className={s.sortIcon} src={sortIcon} alt={''} />{' '}
              </th>
              <th>
                <FormattedMessage id="common.status" defaultMessage="Status" />
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {selectedTasks.length === 0 && (
              <tr className={s.emptyRow} data-unittest="TaskData">
                <td colSpan="5">
                  <FormattedMessage id="checklistTasks.noData" defaultMessage="No Data" />
                </td>
              </tr>
            )}
            {selectedTasks.map((n, index) => {
              return (
                <Fragment key={index}>
                  <tr className={`${s.taskRow} ${n.Status !== CHECKLIST_STATUS_COMPLETE ? s.activeTaskRow : ''}`} data-unittest="TaskData">
                    <td>{n.Name}</td>
                    <td className="d-none d-md-table-cell">
                      {n.DueDateTime
                        ? moment
                            .unix(parseInt(n.DueDateTime))
                            .tz(currentTimezone)
                            .format('DD-MMM-YYYY HH:mm:ss z')
                        : '-'}
                      <span className={s.overDue}>{n.Status === CHECKLIST_STATUS_ACTIVE && getOverdueStatus(n.DueDateTime)}</span>
                    </td>
                    <td>
                      <div className={n.Status !== CHECKLIST_STATUS_COMPLETE ? s.statusActive : s.statusComplete}></div>{' '}
                      {n.Status || CHECKLIST_STATUS_ACTIVE}
                    </td>
                    <td>
                      {!isView && !isChecklistCompleted && (
                        <Button
                          variant="outline-secondary"
                          data-unittest="remove"
                          onClick={() => onTaskRemove({ TaskId: n.TaskId, TaskTemplateId: n.TaskTemplateId })}
                          className={s.taskRowButton}
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}

                      {(isEdit || isView) && (
                        <Button
                          onClick={() => {
                            onViewBtnClick(`/tasks-and-checklists/task-detail/${n.TaskId}`);
                          }}
                          variant="outline-secondary"
                          data-unittest="view"
                          className={s.taskRowButton}
                        >
                          <FormattedMessage id="common.view" defaultMessage="VIEW" />
                        </Button>
                      )}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

ChecklistDetailTask.defaultProps = {
  selectedTasks: [],
  tasks: []
};

ChecklistDetailTask.propTypes = {
  onTaskSearch: PropTypes.func.isRequired,
  onTaskRemove: PropTypes.func.isRequired
};

export default injectIntl(ChecklistDetailTask);
