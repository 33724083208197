import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import s from './SafetyPlanReportList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Pagination from '../../Pagination/Pagination';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import Breadcrumb from '../../WSAControls/Breadcrumb';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import { getUserProfileWidget, getSafetyPlanReport, getSelectedSafetyPlan } from '../../../selectors/index';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import SafetyPlanReportListTable from './SafetyPlanReportListTable';
import { getSafetyPlanReportThunk, generateDownloadSafetyPlanReportThunk } from '../../../store/actions/safetyPlan/safetyPlanReport';
import SafetyPlanHtmlGenerator from './HtmlReport/SafetyPlanHtmlGenerator';
import { getCurrentTimezone, getGeneralData } from '../../../selectors/index';
import moment from 'moment-timezone';
import { setCurrentPage } from '../../../store/actions/safetyPlan/safetyPlanReport';

const SafetyPlanReportList = ({ intl }) => {
  const dispatch = useDispatch();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const generalData = useSelector(state => getGeneralData(state));

  let { safetyPlanId, siteId, segmentName, selectedTab } = useParams();

  const safetyPlanReport = useSelector(state => getSafetyPlanReport(state));
  const safetyPlanReportData = safetyPlanReport.selectedSafetyPlanReport || null;
  const selectedSafetyPlan = useSelector(state => getSelectedSafetyPlan(state));
  const safetyPlanName = (selectedSafetyPlan.Name && ` (${selectedSafetyPlan.Name})`) || '';
  let disableControls = safetyPlanReport.isLoading || false;
  let offset = safetyPlanReport.offset || 0;
  let limit = safetyPlanReport.limit || 1000;

  useEffect(() => {
    let filterObj = {
      SafetyPlanId: safetyPlanId,
      SiteId: siteId,
      Offset: offset,
      Limit: limit,
      FetchHtml: true
    };
    dispatch(getSafetyPlanReportThunk('WQSP_SAFETYPLANREPORT_VIEW', filterObj));
  }, [getSafetyPlanReportThunk, siteId, safetyPlanId]);

  let heading = intl.formatMessage({ id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' });
  heading += `${safetyPlanName}`;

  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' });
  breadcrumbHeading += `${safetyPlanName}`;

  let messageId = (safetyPlanReport && safetyPlanReport.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isDownloadAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_SAFETYPLANREPORT_GENERATE');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_SAFETYPLANREPORT_DELETE');

  const breadcrumb = [
    {
      orderNo: 1,
      name: heading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="siteControlsTabs.safetyPlanReport" defaultMessage="Safety Plan Report" />,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  let safetyPlanReportList = Object.values(safetyPlanReport.safetyPlanDownloadReportList);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };
  let htmlToPDF = '';
  if (safetyPlanReportData) {
    htmlToPDF = renderToString(
      <SafetyPlanHtmlGenerator
        safetyPlanReportData={safetyPlanReportData}
        currentTimezone={currentTimezone}
        generalData={generalData}
        intl={intl}
      />
    );
  }

  const onGeneratePDFHandler = () => {
    let postObj = {
      PerformGenerate: true,
      SiteId: siteId,
      CurrentDate: moment.tz(currentTimezone).format('DD-MMM-YYYY'),
      Html: htmlToPDF,
      SafetyPlanId: safetyPlanId
    };
    dispatch(generateDownloadSafetyPlanReportThunk(postObj, 'WQSP_SAFETYPLANREPORT_GENERATE'));
  };

  const onDownloadClickHandler = safetyPlanReportId => {
    let postObj = {
      PerformDownload: true,
      SiteId: siteId,
      SafetyPlanId: safetyPlanId,
      SafetyPlanReportId: safetyPlanReportId
    };
    dispatch(generateDownloadSafetyPlanReportThunk(postObj, 'WQSP_SAFETYPLANREPORT_VIEW'));
  };

  const downloadPDFFile = fileName => {
    const element = document.createElement('a');
    element.href = fileName;
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  let downloadFetchComplete = safetyPlanReport.downloadFetchComplete;
  let hasDownloadFile = safetyPlanReport.newDownloadLink.length > 0;

  useEffect(() => {
    if (hasDownloadFile && downloadFetchComplete) {
      downloadPDFFile(safetyPlanReport.newDownloadLink);
    }
  }, [getSafetyPlanReportThunk, downloadFetchComplete, hasDownloadFile]);

  const onDeleteClickHandler = safetyPlanReportId => {};

  return (
    <div className={s.safetyPlanReportList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.contentWrapper}>
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
          {safetyPlanReport.isLoading && <LoadingSpinner />}
          {safetyPlanReport.showBanner && (
            <Banner
              failureText={messageText}
              showBanner={safetyPlanReport.showBanner}
              status={safetyPlanReport.isOpSuccessful}
              successText={messageText}
            />
          )}
          <Row>
            <Col sm={6} lg={6}>
              {safetyPlanReport.showNewDownloadLink && safetyPlanReport.newDownloadLink.length > 0 ? (
                <div className={s.downloadInfo}>
                  <FormattedMessage id="safetyPlan.reportReady" defaultMessage="Your generated report is now ready to be downloaded" />{' '}
                  <a href={safetyPlanReport.newDownloadLink}>
                    <FormattedMessage id="safetyPlan.downloadreport" defaultMessage="here" />
                  </a>{' '}
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col sm={6} lg={6}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_SAFETYPLANREPORT_GENERATE')}
                onClick={onGeneratePDFHandler}
                disabled={disableControls}
                data-unittest="generateSafetyPlanPDF"
              >
                <FormattedMessage id="safetyPlan.generateReport" defaultMessage="GENERATE REPORT" />
              </Button>
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={safetyPlanReportList}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={safetyPlanReport.currentPage}
            >
              <SafetyPlanReportListTable
                DownloadReport={onDownloadClickHandler}
                RemoveReport={onDeleteClickHandler}
                DownloadAllowed={isDownloadAllowed}
                DeleteAllowed={isDeleteAllowed}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(SafetyPlanReportList);
