import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './RiskCategory.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
//import RiskCategoryListTable from './RiskCategoryListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE, FIELD_TYPE_DATA } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import { getUserProfileWidget, getRiskCategory, getGeneralData } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import SearchBar from '../SearchBar/SearchBar';
import Cookies from 'js-cookie';
import RiskCategoryMatrixView from './RiskCategoryMatrixView';
import { getRiskCategoryThunk, setFilteredDataThunk, setCurrentPage, deleteRiskCategoryThunk } from '../../store/actions/riskCategory';

const FIELD_TYPE = 'FIELD_TYPE';
const SEARCH_FILTER = 'SEARCH_FILTER';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const RiskCategoryList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const riskCategory = useSelector(state => getRiskCategory(state));

  let disableControls = riskCategory.isLoading || false;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterFieldType, setFilterFieldType] = useState(0);
  const [riskCategoryId, setRiskCategoryId] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(getRiskCategoryThunk('WQSP_RISKCATEGORY_LIST'));
  }, [getRiskCategoryThunk]);

  let messageId = (riskCategory && riskCategory.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onRemoveRiskCategoryClick = riskCategoryId => {
    setActive(true);
    setRiskCategoryId(riskCategoryId);
  };

  const DeleteRiskCategory = () => {
    let saveData = riskCategory.SelectedRiskCategory;
    saveData.PerformDelete = true;
    saveData.RiskCategoryId = riskCategoryId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(deleteRiskCategoryThunk(saveData, 'WQSP_RISKCATEGORY_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setRiskCategoryId(0);
  };

  const handleConfirm = () => {
    DeleteRiskCategory();
    handleClose();
  };

  const filteredResults = (filterType, value) => {
    let filteredList = riskCategory.RiskCategories;

    if (filterType === FIELD_TYPE) {
      if (value !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.FieldType === value;
        });
      }
    } else {
      if (filterFieldType !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.FieldType === filterFieldType;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
  };

  let riskCategoryObj = Object.values(riskCategory.filteredResults);
  riskCategoryObj = riskCategoryObj.sort(compareValues('InspectionDate'));

  const onAddNewClick = () => {
    history.push('/operational-setup/risk-categories/risk-category');
  };

  const onChangeRiskCategoryClick = riskCategoryId => {
    history.push(`/operational-setup/risk-categories/risk-category/${riskCategoryId}`);
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKCATEGORY_UPDATE');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKCATEGORY_DELETE');
  //const isUpdateAllowed = true;

  const dialogTitle = intl.formatMessage({
    id: 'riskCategory.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this risk category?'
  });

  return (
    <div className={s.riskCategoryList}>
      <OperationalSetupContentTemplate selectedPage="riskCategories" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
          {riskCategory.isLoading && <LoadingSpinner />}
          {riskCategory.showBanner && (
            <Banner
              failureText={messageText}
              showBanner={riskCategory.showBanner}
              status={riskCategory.isOpSuccessful}
              successText={messageText}
            />
          )}
          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="riskCategory.searchRiskCategoryName"
              />
              <Col sm={6} lg={2}></Col>
            </Col>
            <Col sm={12} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_RISKCATEGORY_ADD')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addRiskCategoryButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>
          <div className="">
            <Pagination data={riskCategoryObj} pageSize={3} onPageChange={onPageChangeHandler} startingPage={riskCategory.currentPage}>
              <RiskCategoryListTable
                onChangeRiskCategoryClick={onChangeRiskCategoryClick}
                onRemoveRiskCategoryClick={onRemoveRiskCategoryClick}
                isUpdateAllowed={isUpdateAllowed}
                isDeleteAllowed={isDeleteAllowed}
                intl={intl}
              />
            </Pagination>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

export default injectIntl(RiskCategoryList);

const RiskCategoryListTable = ({
  paginatedData,
  onChangeRiskCategoryClick,
  onRemoveRiskCategoryClick,
  isUpdateAllowed,
  isDeleteAllowed,
  intl
}) => {
  return (
    paginatedData.length > 0 &&
    paginatedData.map(n => {
      return (
        <RiskCategoryMatrixView
          key={n.RiskCategoryId}
          RiskCategory={n}
          UpdateAllowed={isUpdateAllowed}
          DeleteAllowed={isDeleteAllowed}
          ChangeRiskCategory={onChangeRiskCategoryClick}
          RemoveRiskCategory={onRemoveRiskCategoryClick}
          intl={intl}
        />
      );
    })
  );
};
