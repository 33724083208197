import React from 'react';
import PropTypes from 'prop-types';
import s from './RiskCategory.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import { useDispatch } from 'react-redux';
import { setRiskCategoryMatrixChangeThunk } from '../../store/actions/riskCategory';
import { isRequired } from '../../utils';

const RiskCategoryMatrixTable = ({ RiskCategoryData, intl }) => {
  const dispatch = useDispatch();
  const saveMatrixHandler = matrixResult => {
    let listData = RiskCategoryData;
    const updatedData = listData.map(x => (x.RiskLikelihoodId === matrixResult.RiskLikelihoodId ? { ...x, ...matrixResult } : x));
    dispatch(
      setRiskCategoryMatrixChangeThunk({
        RiskMatrix: updatedData
      })
    );
  };

  return (
    <div>
      <Table variant className={s.innerTable}>
        <thead>
          <tr key="hd1">
            <th rowSpan="2" width="35%">
              <FormattedMessage id="riskCategory.likelihood" defaultMessage="Likelihood" />
            </th>
            <th colSpan="6">
              <FormattedMessage id="riskCategory.consequences" defaultMessage="Severity Descriptors" />
            </th>
          </tr>
          <tr key="hd2">
            <th width="13%">
              <FormattedMessage id="riskCategory.insignificant" defaultMessage="Insignificant" />
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.minor" defaultMessage="Minor" />
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.moderate" defaultMessage="Moderate" />
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.major" defaultMessage="Major" />
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.catastropic" defaultMessage="Catastropic" />
            </th>
          </tr>
        </thead>
        <tbody>
          {RiskCategoryData.map(n => {
            return <MatrixRow matrixRow={n} saveMatrix={saveMatrixHandler} intl={intl} key={n.RiskLikelihoodId} />;
          })}
        </tbody>
      </Table>
    </div>
  );
};

RiskCategoryMatrixTable.defaultProps = {
  RiskCategoryData: []
};

RiskCategoryMatrixTable.propTypes = {
  RiskCategoryData: PropTypes.array.isRequired
};

export default injectIntl(RiskCategoryMatrixTable);

const validateMatrixInput = matrixRow => {
  let localformErrors = {};
  if (!matrixRow || !matrixRow.InsignificantValue || isRequired(matrixRow.InsignificantValue, 1)) {
    localformErrors.InsignificantValue = 'isRequired';
  } else {
    localformErrors.InsignificantValue = null;
  }
  if (!matrixRow || !matrixRow.MinorValue || isRequired(matrixRow.MinorValue, 1)) {
    localformErrors.MinorValue = 'isRequired';
  } else {
    localformErrors.MinorValue = null;
  }

  if (!matrixRow || !matrixRow.ModerateValue || isRequired(matrixRow.ModerateValue, 1)) {
    localformErrors.ModerateValue = 'isRequired';
  } else {
    localformErrors.ModerateValue = null;
  }
  if (!matrixRow || !matrixRow.MajorValue || isRequired(matrixRow.MajorValue, 1)) {
    localformErrors.MajorValue = 'isRequired';
  } else {
    localformErrors.MajorValue = null;
  }
  if (!matrixRow || !matrixRow.CatastrophicValue || isRequired(matrixRow.CatastrophicValue, 1)) {
    localformErrors.CatastrophicValue = 'isRequired';
  } else {
    localformErrors.CatastrophicValue = null;
  }

  return localformErrors;
};

const MatrixRow = ({ matrixRow, saveMatrix, intl }) => {
  const onChange = e => {
    let hasError = false;

    if (e.target.value === null || e.target.value.trim() === '') {
      hasError = true;
    } else {
      if (e.target.name !== 'InsignificantValue' && !matrixRow.InsignificantValue) {
        hasError = true;
      } else if (e.target.name !== 'MinorValue' && !matrixRow.MinorValue) {
        hasError = true;
      } else if (e.target.name !== 'ModerateValue' && !matrixRow.ModerateValue) {
        hasError = true;
      } else if (e.target.name !== 'MajorValue' && !matrixRow.MajorValue) {
        hasError = true;
      } else if (e.target.name !== 'CatastrophicValue' && !matrixRow.CatastrophicValue) {
        hasError = true;
      }
    }

    saveMatrix({ ...matrixRow, [e.target.name]: e.target.value, hasError: hasError });
  };
  let localformErrors = {};

  if (matrixRow.hasError) {
    localformErrors = validateMatrixInput(matrixRow);
  }

  return (
    <tr>
      <td>
        <Form.Group controlId={'formgroupMatrix' + matrixRow.RiskLikelihoodId}>
          <Form.Label>{matrixRow.RiskLikelihood}</Form.Label>
          <Form.Control
            type="text"
            name="Description"
            onChange={onChange}
            value={matrixRow.Description}
            className={`${s.formControl} ${s.textInput}`}
            placeholder={intl.formatMessage({
              id: 'riskCategory.addDescription',
              defaultMessage: 'Add description'
            })}
          />
        </Form.Group>
      </td>
      <td>
        <InputNumber
          name="InsignificantValue"
          step=".1"
          onInputChange={onChange}
          value={matrixRow.InsignificantValue?.toString()}
          customClassName={`${s.textInput} ${localformErrors && localformErrors.InsignificantValue ? s.formControlError : ''}`}
          id="formInsignificantValue"
        />
      </td>
      <td>
        <InputNumber
          name="MinorValue"
          step=".1"
          onInputChange={onChange}
          customClassName={`${s.textInput} ${localformErrors && localformErrors.MinorValue ? s.formControlError : ''}`}
          value={matrixRow.MinorValue?.toString()}
          id="formMinorValue"
        />
      </td>
      <td>
        <InputNumber
          name="ModerateValue"
          step=".1"
          onInputChange={onChange}
          customClassName={`${s.textInput} ${localformErrors && localformErrors.ModerateValue ? s.formControlError : ''}`}
          value={matrixRow.ModerateValue?.toString()}
          id="formModerateValue"
        />
      </td>
      <td>
        <InputNumber
          name="MajorValue"
          step=".1"
          onInputChange={onChange}
          customClassName={`${s.textInput} ${localformErrors && localformErrors.MajorValue ? s.formControlError : ''}`}
          value={matrixRow.MajorValue?.toString()}
          id="formMajorValue"
        />
      </td>
      <td>
        <InputNumber
          name="CatastrophicValue"
          step=".1"
          onInputChange={onChange}
          customClassName={`${s.textInput} ${localformErrors && localformErrors.CatastrophicValue ? s.formControlError : ''}`}
          value={matrixRow.CatastrophicValue?.toString()}
          id="formCatastrophicValue"
        />
      </td>
    </tr>
  );
};
