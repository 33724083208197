import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './DashboardTasks.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialTasksState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import SearchBar from '../../SearchBar/SearchBar';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../../assets/collapse-down.svg';
import collapseUp from '../../../assets/collapse-up.svg';
import { IsWidgetAccessible } from '../../../utils/widgetManager';
import TaskListTable from '../../TaskList/TaskListTable';
import { TASK_STATUS_ACTIVE } from '../../../constants';
import { compareValues } from '../../../utils';
import { getTaskManager, getCurrentTimezone } from '../../../selectors/index';
import { getTasksThunk } from '../../../store/actions/tasks';

const DashboardTasks = ({ userProfileWidgets, intl }) => {
  let initialLimit = 6;
  const history = useHistory();
  const dispatch = useDispatch();

  const tasksManager = useSelector(state => getTaskManager(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  const [tasksOpen, setTasksOpen] = useState(true);
  const [asendingOrder, setAsendingOrder] = useState(false);
  const [localTasks, setLocalTasks] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  let initialRecords = tasksManager.taskList.slice(0, initialLimit);
  const localTasksLength = localTasks.length;
  const allTasksLength = tasksManager.taskList.length;

  useEffect(() => {
    let filterObj = {
      filterStatus: TASK_STATUS_ACTIVE
    };
    dispatch(getTasksThunk(filterObj, 'WQSP_MYTASKS_IN_DASHBOARD'));
  }, [getTasksThunk]);

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalTasks(tasksManager.taskList);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    let filteredList = tasksManager.taskList.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalTasks(filteredList);
  };

  const viewBtnClick = url => {
    history.push(`${url}?dashboardTasks=1`);
  };

  const getDashboardTasks = () => {
    let tasks = [];
    if (showAll) {
      tasks = localTasks;
    } else {
      if (isFilter) {
        tasks = localTasksLength > initialLimit ? initialFilteredRecords : localTasks;
      } else {
        tasks = initialRecords;
      }
    }
    if (asendingOrder) {
      return tasks.sort(compareValues('DueDateTime'));
    } else {
      return tasks.sort(compareValues('DueDateTime', 'desc'));
    }
  };

  let showAllText = intl.formatMessage({ id: 'modelVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelVersion.showLess', defaultMessage: 'SHOW LESS' });

  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'TASKVIEW');
  return (
    <div>
      <Row className={s.titleRows}>
        <Col>
          <Row>
            <img
              src={tasksOpen ? collapseDown : collapseUp}
              alt="Collapse section"
              onClick={() => setTasksOpen(!tasksOpen)}
              aria-controls="tasks-section"
              aria-expanded={tasksOpen}
            ></img>
            <h3>
              <FormattedMessage id="taskList.myTasks" defaultMessage="My Tasks" />
            </h3>
            <span className={s.circle}>{tasksManager.taskList.length}</span>
          </Row>
        </Col>
      </Row>

      <Collapse in={tasksOpen}>
        <div>
          {tasksManager.isLoading && <LoadingSpinner />}
          <Row className="pt-3">
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                placeHolderTextId="tasksManagement.search"
                data-unittest="searchTasks"
              />
            </Col>
          </Row>

          <div>
            <TaskListTable
              ViewAllowed={isViewAllowed}
              currentPage={tasksManager.currentPage}
              currentTimezone={currentTimezone}
              onSortOrder={onSortOrder}
              asendingOrder={asendingOrder}
              paginatedData={getDashboardTasks()}
              onViewBtnClick={viewBtnClick}
            />
          </div>
          {((!isFilter && allTasksLength > initialLimit) || (isFilter && localTasksLength > initialLimit)) && (
            <Row className={s.topRow}>
              <Col>
                <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                  {!showAll ? `${showAllText} (${!showAll && !isFilter ? allTasksLength : localTasksLength})` : showLessText}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Collapse>
    </div>
  );
};

DashboardTasks.defaultProps = {
  tasksManager: initialTasksState.tasks,
  devices: []
};

export default injectIntl(DashboardTasks);
