import { initialTestCategoryState } from './initialState';

import {
  TEST_CATEGORY_ERROR,
  GET_ALL_TEST_CATEGORY_DETAILS,
  TEST_CATEGORY_IS_LOADING,
  SET_TEST_CATEGORY_CURRENT_PAGE,
  SET_TEST_CATEGORY_PAGE_FILTER,
  SET_TEST_CATEGORY_CHANGE,
  SET_TEST_CATEGORY,
  UPDATE_TEST_CATEGORY_SAVE_STATUS,
  TEST_CATEGORY_ITEM_EXISTS_STATUS,
  SET_TEST_CATEGORY_CLEANUP
} from '../../constants/index';

const testCategoryReducer = (state = initialTestCategoryState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_TEST_CATEGORY_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          ...data
        }
      };
    }

    case SET_TEST_CATEGORY_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          currentPage
        }
      };
    }

    case SET_TEST_CATEGORY_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          filter
        }
      };
    }

    case UPDATE_TEST_CATEGORY_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          ...data
        }
      };
    }

    case TEST_CATEGORY_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          isLoading: isLoading
        }
      };
    }

    case TEST_CATEGORY_ERROR: {
      const { data } = payload;
      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          ...data
        }
      };
    }

    case SET_TEST_CATEGORY: {
      let { selectedTestCategory } = payload;

      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          isOpSuccessful: false,
          showBanner: false,
          selectedTestCategory: {
            ...state.testCategory.selectedTestCategory,
            ...selectedTestCategory
          }
        }
      };
    }

    case SET_TEST_CATEGORY_CHANGE: {
      let { selectedTestCategory } = payload;

      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedTestCategory: {
            ...state.testCategory.selectedTestCategory,
            ...selectedTestCategory
          }
        }
      };
    }

    case TEST_CATEGORY_ITEM_EXISTS_STATUS: {
      let { data, testCategoryItemId } = payload;

      let categoryTypes = state.testCategory.selectedTestCategory.testCategoryTypes.filter(item => {
        if (item.TestCategoryItemId !== testCategoryItemId) return item;
      });
      let removedIds = state.testCategory.selectedTestCategory.removedTestCategoryTypeIds;
      removedIds.push(testCategoryItemId);

      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          ...data,
          selectedTestCategory: {
            ...state.testCategory.selectedTestCategory,
            testCategoryTypes: categoryTypes,
            removedTestCategoryTypeIds: removedIds
          }
        }
      };
    }

    case SET_TEST_CATEGORY_CLEANUP: {
      let { selectedTestCategory } = payload;

      return {
        ...state,
        testCategory: {
          ...state.testCategory,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedTestCategory: {
            ...state.testCategory.selectedTestCategory,
            ...selectedTestCategory,
            removedTestCategoryTypeIds: []
          }
        }
      };
    }

    default:
      return state;
  }
};

export default testCategoryReducer;
