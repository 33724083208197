import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './UserOrganisations.module.scss';
import InputTemplate from '../InputTemplate/InputTemplate';
import Cookies from 'js-cookie';
import { injectIntl } from 'react-intl';
import { postUserThunk } from '../../store/actions/userManager';
import { useDispatch } from 'react-redux';
import { DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';
import { getSelectedUserProfileWidgetsThunk } from '../../store/actions/UserProfileWidgets';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const UserOrganisations = ({ intl }) => {
  const [selectedOrganisationId, setSelectedOrganisationId] = React.useState(0);
  const dispatch = useDispatch();
  //let organisations = (Cookies.get(`organisations-${envName}`) && JSON.parse(Cookies.get(`organisations-${envName}`))) || [];
  const organisations = localStorage.getItem(`organisations-${envName}`)
    ? JSON.parse(localStorage.getItem(`organisations-${envName}`))
    : [];
  useEffect(() => {
    setSelectedOrganisationId(Cookies.get(`selectedorganisationid-${envName}`) || 0);
  }, [setSelectedOrganisationId]);

  const handleChange = selectedId => {
    Cookies.set(`selectedorganisationid-${envName}`, selectedId, { expires: 7, domain: DOMAIN_NAME, secure: IS_LOCALHOST ? false : true });
    const userId = Cookies.get(`userid-${envName}`) || 0;
    setSelectedOrganisationId(selectedId);
    let userName = Cookies.get(`username-${envName}`) || '';
    dispatch(getSelectedUserProfileWidgetsThunk(null, userName, 'GENERALACCESS'));
    //Updating user table
    dispatch(postUserThunk({ CurrentOrganisationId: selectedId, UserId: userId }, 'GENERALACCESS', true));
  };

  const translatedOrganisation = intl.formatMessage({ id: 'settings.organisation', defaultMessage: 'Organisation' });

  return (
    <InputTemplate label={translatedOrganisation}>
      <select className={s.userorganisationsSelect} onChange={e => handleChange(e.target.value)} value={selectedOrganisationId}>
        {organisations.map(org => (
          <option key={org.OrganisationId} value={org.OrganisationId}>
            {org.Name}
          </option>
        ))}
      </select>
    </InputTemplate>
  );
};

UserOrganisations.propTypes = {
  updateTimezone: PropTypes.func,
  currentTimezone: PropTypes.string
};

export default injectIntl(UserOrganisations);
