import React from 'react';
import s from './InspectionReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getDateInDDMMYYYFormat } from '../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../selectors/index';
import { getRiskName } from '../../utils/appUtils';
import { VERY_HIGH_RISK, HIGH_RISK, MEDIUM_RISK, LOW_RISK } from '../../constants/index';
import MoreOption from '../WSAControls/MoreOption/MoreOption';

const InspectionReportListTable = ({
  paginatedData,
  onSortOrder,
  asendingOrder,
  ChangeInspectionReport,
  UpdateAllowed,
  CreateIncidentReport,
  AllowCreateIncidentReport,
  deleteAllowed,
  removeInspectionReport,
  intl
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="inspectionReport.inspectionName" defaultMessage="Inspection Name" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="inspectionReport.inspectionDate" defaultMessage="Inspection Date" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="inspectionReport.SiteName" defaultMessage="Site" />
            </th>
            <th>
              <FormattedMessage id="inspectionReport.inspectionType" defaultMessage="Inspection Type" />
            </th>

            <th>
              <FormattedMessage id="inspectionReport.inspectionPurpose" defaultMessage="Inspection Purpose" />
            </th>
            <th>
              <FormattedMessage id="inspectionReport.riskScore" defaultMessage="Risk Score" />
            </th>
            <th>
              <FormattedMessage id="inspectionReport.riskRating" defaultMessage="Risk Rating" />
            </th>
            {(UpdateAllowed || deleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            if (n.RiskRating)
              return (
                <tr
                  key={n.InspectionReportId}
                  className={
                    n.RiskRating === VERY_HIGH_RISK
                      ? s.veryHighRiskBorder
                      : n.RiskRating === HIGH_RISK
                      ? s.highRiskBorder
                      : n.RiskRating === MEDIUM_RISK
                      ? s.mediumRiskBorder
                      : s.lowRiskBorder
                  }
                >
                  <td>{n.InspectorName || ''}</td>
                  <td>{getDateInDDMMYYYFormat(n.InspectionDate, currentTimezone) || ''}</td>
                  <td>{n.SiteName || ''}</td>
                  <td>{n.InspectionType || ''}</td>
                  <td>{n.InspectionPurpose || ''}</td>
                  <td>{n.RiskScore || ''}</td>
                  <td>
                    <span
                      className={`${s.dot} ${
                        n.RiskRating === VERY_HIGH_RISK
                          ? s.veryHighRisk
                          : n.RiskRating === HIGH_RISK
                          ? s.highRisk
                          : n.RiskRating === MEDIUM_RISK
                          ? s.mediumRisk
                          : s.lowRisk
                      }`}
                    ></span>{' '}
                    {getRiskName(n.RiskRating, intl) || ''}
                  </td>
                  <td className={s.btnCol}>
                    <MoreOption
                      dataArray={[
                        UpdateAllowed
                          ? {
                              Click: () => ChangeInspectionReport(n.SiteId, n.InspectionReportId),
                              Name: <FormattedMessage id="common.edit" defaultMessage="EDIT" />
                            }
                          : {},
                        AllowCreateIncidentReport
                          ? {
                              Click: () => CreateIncidentReport(n),
                              Name: <FormattedMessage id="incidentReports.createIncidentReport" defaultMessage="CREATE INCIDENT REPORT" />
                            }
                          : {},
                        deleteAllowed
                          ? {
                              Click: () => removeInspectionReport(n.InspectionReportId),
                              Name: <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                            }
                          : {}
                      ]}
                    />
                  </td>
                </tr>
              );
          })}
        </tbody>
      </Table>
    </div>
  );
};

InspectionReportListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(InspectionReportListTable);
