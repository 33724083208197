import React from 'react';
import PropTypes from 'prop-types';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const TasksAndChecklists = ({ userProfileWidgets }) => (
  <div>
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_TASKLIST') ? (
      <Redirect to="/tasks-and-checklists/tasklist" />
    ) : IsWidgetAccessible(userProfileWidgets, 'WQSP_CHECKLISTLIST') ? (
      <Redirect to="/tasks-and-checklists/checklists" />
    ) : (
      ''
    )}
    <span>
      <FormattedMessage
        id="TasksAndChecklists.NoAccess"
        defaultMessage="You do not have access to checklist and tasks. Please contact your administrator."
      />
    </span>
  </div>
);

TasksAndChecklists.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired
};

export default TasksAndChecklists;
