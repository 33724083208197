import React from 'react';
import moment from 'moment-timezone';
import { HAZARD_CLASS, RISK_RESPONSE } from '../../../../constants/index';

export const SectionIdentify = ({ sectionData, getImages, getActionName, getTranslatedText, currentTimezone, generalData }) => {
  return (
    <div id="section3" className="pageBreak sectionContent">
      <h1>Section 3 - Identify</h1>
      <div id="hazardEvents" className="level1">
        <h2>Hazard Events</h2>
      </div>
      {sectionData.HazardEvents.map(n => {
        if (n.HazardEventId) {
          return (
            <HarzardEventsHtml
              item={n}
              key={n.HazardEventId}
              id={n.HazardEventId}
              getImages={getImages}
              getActionName={getActionName}
              getTranslatedText={getTranslatedText}
              currentTimezone={currentTimezone}
              generalData={generalData}
            />
          );
        }
      })}
    </div>
  );
};

const HarzardEventsHtml = ({ item, getImages, getActionName, getTranslatedText, currentTimezone, generalData }) => {
  const hazardClasses = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_CLASS);
  const riskResponses = generalData.generalFieldTypes.filter(item => item.FieldType === RISK_RESPONSE);
  const getHazardClassName = ids => {
    let splitIds = ids.split(',');
    let selClasses = hazardClasses.filter(item => splitIds.find(id => id === item.GeneralFieldTypeId.toString()));
    return (selClasses && selClasses.length && selClasses.map(item => item.Name).join(', ')) || '';
  };
  const getRiskResponseNames = ids => {
    let splitIds = ids.split(',');
    let selResponses = riskResponses.filter(item => splitIds.find(id => id === item.GeneralFieldTypeId.toString()));
    return (selResponses && selResponses.length && selResponses.map(item => item.Name).join(', ')) || '';
  };
  return (
    <div>
      <h4>{`Hazard Event for ${item.HazardName}`}</h4>
      <table className="level1-table">
        <tr>
          <td>Water Supply Name</td>
          <td>{item.WaterSupplyDescriptionName}</td>
        </tr>
        <tr>
          <td>Element Type</td>
          <td>{item.EntityTypeName}</td>
        </tr>
        <tr>
          <td>Element</td>
          <td>{item.EntityName}</td>
        </tr>
        <tr>
          <td>Hazard Certainty </td>
          <td>{item.HazardCertaintyName}</td>
        </tr>
        <tr>
          <td>Hazard Name</td>
          <td>{item.HazardName}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{item.Description}</td>
        </tr>
        <tr>
          <td>Date Identified </td>
          <td>{item.DateIdentified}</td>
        </tr>
        <tr>
          <td>Basis </td>
          <td>{item.Basis}</td>
        </tr>
        <tr>
          <td>Hazard Class </td>
          <td>{getHazardClassName(item.HazardClass)}</td>
        </tr>
        <tr>
          <td>GPS Latitude </td>
          <td>{item.GpsLatitude}</td>
        </tr>
        <tr>
          <td>GPS Longitude </td>
          <td>{item.GpsLongitude}</td>
        </tr>
        <tr>
          <td>Last Review Date </td>
          <td>
            {item.LastReviewDate
              ? moment
                  .unix(parseInt(item.LastReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Next Review Date </td>
          <td>
            {item.NextReviewDate
              ? moment
                  .unix(parseInt(item.NextReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Review Frequency</td>
          <td>{item.ReviewFrequencyName}</td>
        </tr>
        <tr>
          <td>Actioned</td>
          <td>{getActionName(item.IsActioned)}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{item.StatusName}</td>
        </tr>
        <tr>
          <td>Images</td>
          <td>{getImages(item.Images)}</td>
        </tr>
      </table>
      <HarzardEventsItemsHtml hazardEventItems={item.HazardEventItems} />
      <RiskAssessmentsHtml
        riskAssessmentItems={item.RiskAssessments}
        currentTimezone={currentTimezone}
        getTranslatedText={getTranslatedText}
        getRiskResponseNames={getRiskResponseNames}
      />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

const HarzardEventsItemsHtml = ({ hazardEventItems }) => {
  if (!hazardEventItems || hazardEventItems.length == 0) {
    return '';
  }

  return (
    <div className="level2">
      <h3>Hazard Event Items</h3>
      <table className="independent-table">
        <tr>
          <th>Hazard</th>
          <th>Description</th>
          <th>Hazard Class</th>
        </tr>
        {hazardEventItems.map((n, index) => {
          return (
            <tr key={index}>
              <td>{n.HazardName}</td>
              <td>{n.Description}</td>
              <td>{n.HazardClass}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const RiskAssessmentsHtml = ({ riskAssessmentItems, currentTimezone, getTranslatedText, getRiskResponseNames }) => {
  if (!riskAssessmentItems || riskAssessmentItems.length == 0) {
    return '';
  }

  return (
    <div className="level2">
      <h3>Risk Assessments</h3>
      <table className="independent-table">
        <tr>
          <th>Risk No</th>
          <th>Status</th>
          <th>Inherent Risk Rating </th>
          <th>Residual Risk Rating </th>
          <th>Risk Response </th>
          <th>Last Review Date </th>
        </tr>
        {riskAssessmentItems.map(n => {
          return (
            <tr key={n.RiskAssessmentId}>
              <td>{n.RiskNumber}</td>
              <td>{n.Status}</td>
              <td>{getTranslatedText(n.InherentRiskRating)}</td>
              <td>{getTranslatedText(n.ResidualRiskRating)}</td>
              <td>{(n.Response && getRiskResponseNames(n.Response)) || ''}</td>
              <td>
                {n.LastReviewDate
                  ? moment
                      .unix(parseInt(n.LastReviewDate))
                      .tz(currentTimezone)
                      .format('DD-MMM-YYYY')
                  : 'N/A'}
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};
