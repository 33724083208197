import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from '../ControlMeasure.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import { initialControlMeasureState } from '../../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import Banner from '../../../Banner/Banner';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import 'react-datetime/css/react-datetime.css';
import { getUserProfileWidget, getControlMeasure, getCurrentTimezone } from '../../../../selectors/index';
import {
  describeCorrectiveActionThunk,
  saveCorrectiveActionThunk,
  setCorrectiveActionChangeThunk,
  describeControlMonitoringThunk
} from '../../../../store/actions/hazardEvent/controlMeasures';
import { IsWidgetAccessible } from '../../../../utils/widgetManager';
import CorrectiveActionComments from './CorrectiveActionComments';
import Breadcrumb from '../../../WSAControls/Breadcrumb';
import SiteDashboardControlsContentTemplate from '../../../SiteDashboardControls/SiteDashboardControlsContentTemplate';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const CorrectiveActionView = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  let {
    controlMonitoringId,
    controlMeasureId,
    hazardEventId,
    correctiveActionId,
    entityId,
    siteId,
    segmentName,
    selectedTab
  } = useParams();
  segmentName = segmentName || 'control';
  selectedTab = selectedTab || '2';
  let backUrl = `/hazard-events/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`;
  if (segmentName === 'control') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`;
  }

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const controlMeasure = useSelector(state => getControlMeasure(state));

  useEffect(() => {
    dispatch(describeControlMonitoringThunk(controlMonitoringId, controlMeasureId, widgetCode));
  }, [describeControlMonitoringThunk, controlMonitoringId, controlMeasureId]);

  if (correctiveActionId && correctiveActionId > 0) {
    IsEdit = true;
  } else {
    correctiveActionId = 0;
  }
  let widgetCode = 'WQSP_CORRECTIVE_ACTIONS_VIEW';

  let addCommentsAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_CORRECTIVE_ACTIONS_COMMENT_ADD`);

  useEffect(() => {
    dispatch(describeCorrectiveActionThunk(correctiveActionId, controlMonitoringId, widgetCode));
  }, [describeCorrectiveActionThunk, correctiveActionId, controlMonitoringId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setCorrectiveActionChangeThunk({
          ...initialControlMeasureState.controlMeasure
        })
      );
    };
  }, []);

  const saveCorrectiveActionComment = comment => {
    let saveData = {
      ...comment
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.PerformCommentInsert = true;
    dispatch(saveCorrectiveActionThunk(saveData, 'WQSP_CORRECTIVE_ACTIONS_COMMENT_ADD'));
  };

  let messageId = (controlMeasure && controlMeasure.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  heading = intl.formatMessage({ id: 'correctiveAction.viewCorrectiveAction', defaultMessage: 'View Corrective Action' });
  heading = `${heading} - ${controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction.Title} `;
  let monitorBreadcrumbHeading = intl.formatMessage({
    id: 'siteControlsTabs.listOfControlMonitors',
    defaultMessage: 'List of Control Monitors'
  });

  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfControlMeasures', defaultMessage: 'List of Control Measures' });
  let controlMeasureName = controlMeasure.selectedControlMeasure.selectedControlMonitoring.ControlMeasureName || '';
  let headingControlMeasure =
    intl.formatMessage({ id: 'controlMeasure.controlMeasure', defaultMessage: 'Control Measure' }) + ' (' + controlMeasureName + ')';
  let controlMonitoringHeading = intl.formatMessage({ id: 'controlMeasure.controlMonitoring', defaultMessage: 'Control Monitoring' });
  controlMonitoringHeading += ' (' + controlMeasure.selectedControlMeasure.selectedControlMonitoring.MonitoredItem + ')';

  const hazardEventName = controlMeasure.selectedControlMeasure.selectedControlMonitoring.HazardEventName;
  let hazardEventHeading =
    intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + ' (' + hazardEventName + ')';

  const breadcrumbControl = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: headingControlMeasure,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: controlMonitoringHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 4, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 5,
      name: headingControlMeasure,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 6,
      name: controlMonitoringHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 7, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const identifyHazardEventcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: headingControlMeasure,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 5,
      name: controlMonitoringHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 6, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const monitorBreadcrumb = [
    {
      orderNo: 1,
      name: monitorBreadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: controlMonitoringHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings/control-monitoring-view/${controlMonitoringId}`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const getBreadcrumbData = () => {
    if (segmentName === 'define') {
      return defineEntityCrumb;
    } else if (segmentName === 'identify') {
      return identifyHazardEventcrumb;
    } else if (segmentName === 'monitor') {
      return monitorBreadcrumb;
    } else {
      return breadcrumbControl;
    }
  };

  return (
    <div className={s.controlMeasure}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {controlMeasure.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={controlMeasure.showBanner}
          status={controlMeasure.isOpSuccessful}
          successText={messageText}
        />
        <div className={s.contentWrapper}>
          <Breadcrumb data={getBreadcrumbData()} segmentName={segmentName} />
          <div className={s.controlMeasureContent}>
            <Form>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="correctiveAction.personnel" defaultMessage="Personnel" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction.PersonnelName}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.title" defaultMessage="Title" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction.Title}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="correctiveAction.whenActionTriggered" defaultMessage="When Action Triggered" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction.WhenActionTriggered}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.details" defaultMessage="Details" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction.Details}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="controlMonitoring.records" defaultMessage="Records" />
                  </Form.Label>
                </Col>
                <Col>{controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction.Records}</Col>
              </Row>
            </Form>
          </div>
          <div className={s.controlMeasureTypeContent}>
            <CorrectiveActionComments
              controlMeasure={controlMeasure}
              saveCorrectiveActionComment={saveCorrectiveActionComment}
              UpdateAllowed={addCommentsAllowed}
              currentTimezone={currentTimezone}
            />
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(CorrectiveActionView);
