import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';

import {
  HAZARD_EVENT_LIBRARY_ERROR,
  GET_ALL_HAZARD_EVENT_LIBRARY_DETAILS,
  HAZARD_EVENT_LIBRARY_IS_LOADING,
  SET_HAZARD_EVENT_LIBRARY_CURRENT_PAGE,
  SET_HAZARD_EVENT_LIBRARY_PAGE_FILTER,
  SET_HAZARD_EVENT_LIBRARY_CHANGE,
  SET_HAZARD_EVENT_LIBRARY,
  UPDATE_HAZARD_EVENT_LIBRARY_SAVE_STATUS
} from '../../constants/index';

import { HAZARD_EVENT_LIBRARY_MANAGEMENT, CREATE_HAZARD_EVENT_LIBRARY, UPDATE_HAZARD_EVENT_LIBRARY } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setHazardEventTemplateErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setHazardEventTemplateError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setHazardEventTemplateError(messageCode, false));
  }, 2500);
};

export const setHazardEventTemplateError = (messageCode, status) => {
  return {
    type: HAZARD_EVENT_LIBRARY_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: HAZARD_EVENT_LIBRARY_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getHazardEventTemplateThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getHazardEventTemplate(widgetCode, orgId));
};

export const getHazardEventTemplate = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/templates/hazardevent`,
    method: 'GET',
    data: {
      OrganisationId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllHazardEventTemplateListThunk(retData),
    onFailure: err => setHazardEventTemplateErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllHazardEventTemplateListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let HazardEventTemplates = (retData && retData.retData.HazardEventTemplate) || [];
  dispatch(setAllHazardEventTemplateList(messageCode, HazardEventTemplates));
};

export const setAllHazardEventTemplateList = (messageCode, HazardEventTemplates) => ({
  type: GET_ALL_HAZARD_EVENT_LIBRARY_DETAILS,
  payload: {
    data: {
      HazardEventTemplates: HazardEventTemplates,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_HAZARD_EVENT_LIBRARY_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_HAZARD_EVENT_LIBRARY_PAGE_FILTER,
  payload: { filter }
});

export const describeHazardEventTemplateThunk = (HazardEventLibraryId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeHazardEventTemplate(HazardEventLibraryId, widgetCode, orgId));
};

export const describeHazardEventTemplate = (HazardEventLibraryId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/templates/hazardevent`,
    data: {
      HazardEventLibraryId: HazardEventLibraryId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setHazardEventTemplateThunk(retData, HazardEventLibraryId),
    onFailure: err => setHazardEventTemplateErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setHazardEventTemplateThunk = ({ retData }, HazardEventLibraryId) => dispatch => {
  let data = retData.HazardEventTemplate;
  if (
    HazardEventLibraryId &&
    (!data || !data.SelectedHazardEventTemplate || data.SelectedHazardEventTemplate.HazardEventLibraryId !== parseInt(HazardEventLibraryId))
  ) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setHazardEventTemplate(data));
};

export const setHazardEventTemplate = data => ({
  type: SET_HAZARD_EVENT_LIBRARY,
  payload: data
});

export const saveHazardEventTemplateThunk = (HazardEventTemplate, widgetCode) => dispatch => {
  let url = '/management/templates/hazardevent/save';
  let actionName = HazardEventTemplate.HazardEventLibraryId === 0 ? CREATE_HAZARD_EVENT_LIBRARY : UPDATE_HAZARD_EVENT_LIBRARY;
  let log = logEntry(HAZARD_EVENT_LIBRARY_MANAGEMENT, actionName, HazardEventTemplate);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  HazardEventTemplate.OrganisationId = orgId;
  dispatch(saveHazardEventTemplate(HazardEventTemplate, url, log, widgetCode));
};

export const saveHazardEventTemplate = (HazardEventTemplate, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setHazardEventTemplateStatusThunk(retData),
    onFailure: err => setHazardEventTemplateErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      HazardEventTemplate: HazardEventTemplate,
      log: log
    },
    widgetcode: widgetCode
  });

export const setHazardEventTemplateStatusThunk = message => dispatch => {
  dispatch(setHazardEventTemplateStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setHazardEventTemplateStatus(message, false));
  }, 2500);
};

export const setHazardEventTemplateStatus = (messageCode, status) => ({
  type: UPDATE_HAZARD_EVENT_LIBRARY_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setHazardEventTemplateChangeThunk = retData => dispatch => {
  dispatch(setHazardEventTemplateChange(retData));
};

export const setHazardEventTemplateChange = retData => ({
  type: SET_HAZARD_EVENT_LIBRARY_CHANGE,
  payload: retData
});
