const languageObject = {
  en: {
    'common.regulatoryRegister': '{REGULATORY REGISTER}',
    'wqsp.common.entityType': '{Entity} Type',
    'wqsp.common.entityTypeMandatory': '{Entity} type is mandatory field',
    'wqsp.common.entity': '{Entity}',
    'wqsp.common.enities': '{Entities}',
    'wqsp.common.entitySubType': '{Entity} Sub Type',
    'wqsp.common.entityMandatory': '{Entity} is mandatory field',
    'wqsp.filters.allEntityTypes': 'All {Entity} Types',
    'wqsp.filters.allEntitySubTypes': 'All {Entity} Sub Types',
    'inspectionReport.sourceEntityType': 'Source {Entity} Type',
    'inspectionReports.sourceEntityName': '{Entity} Name',
    'inspectionReports.sourceEntityTypeRequired': 'Source {entity} type is a mandatory field',
    'inspectionReports.entityNameRequired': '{Entity} name is a mandatory field',
    'entities.sourceEntityType': 'Source {Entity} Type',
    'entities.sourceEntitySubType': '{Entity} Sub Type',
    'entities.entitySubTypeRequired': '{Entity} sub type is a mandatory field',
    'entities.addEntity': 'Add new {Entity}',
    'entities.sourceEntity': 'Source {Entity}',
    'entities.destinationEntity': 'Destination {Entity}',
    'entity.entityName': '{Entity} Name',
    'entity.searchEntityName': 'Search {Entity} Name',
    'riskAssessment.passedOnEntitiesMandatory': 'If risk is Passed-On, please select {Entity} to which it is being passed',
    'siteSegments.entityType': '{Entity} Type',
    'siteSegments.entitySubType': '{Entity} Subtype',
    'siteSegments.entity': '{Entity}',
    'wqsp.filters.allEntities': 'All {Entities}',
    'siteControlsTabs.entities': '{Entities}',
    'riskAssessment.passedOnEntities': '{Entities} Risk Passed-On',
    'siteSegments.entities': '{Entities}',
    'safetyPlanControlsTabs.regulatoryRegisters': '{Regulatory Registers}',
    'regulatoryRegister.addNewRegulatoryRegister': 'Add New {Regulatory Register}',
    'regulatoryRegister.confirmDelete': 'Are you sure you want to delete this {regulatory register}?',
    'generalFieldType.entityPurpose': '{Entity} Purpose',
    'siteControlsTabs.entityListing': 'List of {Entities}',
    'siteControlsTabs.regulatoryRegisters': '{Regulatory Registers}',
    'AdminControlsTabs.entitySubtypes': '{Entity} Sub Types',
    'entitySubTypes.confirmDelete': 'Are you sure you want to delete this {entity} sub type?',
    'entitySubTypes.addEntitySubType': 'Add new {Entity} Sub Type'
  },
  es: {
    'wqsp.common.entityType': 'Tipo de {entidad}',
    'wqsp.common.entityTypeMandatory': 'El tipo de {entidad} es un campo obligatorio',
    'wqsp.common.entity': '{Entidad}',
    'wqsp.common.enities': '{Entidades}',
    'wqsp.common.entitySubType': 'Subtipo de {entidad}',
    'wqsp.common.entityMandatory': 'La {entidad} es un campo obligatorio',
    'wqsp.filters.allEntityTypes': 'Todos los tipos de {entidades}',
    'wqsp.filters.allEntitySubTypes': 'Todos los subtipos de {entidades}',
    'inspectionReport.sourceEntityType': 'Tipo de {entidad} de origen',
    'inspectionReports.sourceEntityName': 'Nombre de la {entidad}',
    'inspectionReports.sourceEntityTypeRequired': 'El tipo de {entidad} de origen es un campo obligatorio',
    'inspectionReports.entityNameRequired': 'El nombre de la {entidad} es un campo obligatorio',
    'entities.sourceEntityType': 'Tipo de {entidad} de origen',
    'entities.sourceEntitySubType': 'Subtipo de {entidad}',
    'entities.entitySubTypeRequired': 'El subtipo de {entidad} es un campo obligatorio',
    'entities.addEntity': 'Agregar nueva {entidad}',
    'entities.sourceEntity': '{Entidad} fuente',
    'entities.destinationEntity': '{Entidad} de destino',
    'entity.searchEntityName': 'Nombre de la {entidad} de búsqueda',
    'riskAssessment.passedOnEntitiesMandatory': 'Si el riesgo se transfiere, seleccione la {entidad} a la que se transfiere',
    'siteSegments.entityType': 'Tipo de {entidad}',
    'siteSegments.entitySubType': 'Subtipo de {entidad}',
    'wqsp.filters.allEntities': 'Todas las {entidades}',
    'siteControlsTabs.entities': '{Entidades}',
    'riskAssessment.passedOnEntities': '{Entidades} Transferencia de riesgo',
    'siteSegments.entities': '{Entidades}',
    'safetyPlanControlsTabs.regulatoryRegisters': '{Registros regulatorios}',
    'regulatoryRegister.addNewRegulatoryRegister': 'Agregar nuevo {registro regulatorio}',
    'regulatoryRegister.confirmDelete': '¿Está seguro de que desea eliminar este {registro reglamentario}?',
    'generalFieldType.entityPurpose': 'Propósito de la {entidad}'
  },
  de: {
    'wqsp.common.entityType': '{Entitäts}typ',
    'wqsp.common.entityTypeMandatory': '{Entitäts}typ ist Pflichtfeld',
    'wqsp.common.entity': '{Entitäts}',
    'wqsp.common.enities': '{Entitäten}',
    'wqsp.common.entitySubType': '{Entitäts}untertyp',
    'wqsp.common.entityMandatory': '{Entitäts} ist Pflichtfeld',
    'wqsp.filters.allEntityTypes': 'Alle {Entitäts}typen',
    'wqsp.filters.allEntitySubTypes': 'Alle {Entitäts}untertypen',
    'inspectionReport.sourceEntityType': 'Quell-{Entitäts}typ',
    'inspectionReports.sourceEntityName': '{Entitäts}name',
    'inspectionReports.sourceEntityTypeRequired': 'Quell-{Entitäts}typ ist ein Pflichtfeld',
    'inspectionReports.entityNameRequired': '{Entitäts}name ist ein Pflichtfeld',
    'entities.sourceEntityType': 'Quell-{Entitäts}typ',
    'entities.sourceEntitySubType': '{Entitäts}untertyp',
    'entities.entitySubTypeRequired': '{Entitäts}untertyp ist ein Pflichtfeld',
    'entities.addEntity': 'Neue {Entitäts} hinzufügen',
    'entities.sourceEntity': 'Quell-{Entitäts}',
    'entities.destinationEntity': 'Ziel {Entitäts}',
    'entity.searchEntityName': '{Entitäts}name suchen',
    'riskAssessment.passedOnEntitiesMandatory':
      'Wenn das Risiko weitergegeben wird, wählen Sie bitte die {Entitäts}, an die es weitergegeben wird',
    'siteSegments.entityType': '{Entitäts}typ',
    'siteSegments.entitySubType': '{Entitäts}untertyp',
    'wqsp.filters.allEntities': 'Alle {Entitäten}',
    'siteControlsTabs.entities': '{Entitäten}',
    'riskAssessment.passedOnEntities': '{Entitäts}Risiko weitergegeben',
    'siteSegments.entities': '{Entitäten}',
    'safetyPlanControlsTabs.regulatoryRegisters': '{Regulierungsregister}',
    'regulatoryRegister.addNewRegulatoryRegister': 'Neues {Regulierungsregister} hinzufügen',
    'regulatoryRegister.confirmDelete': 'Möchten Sie dieses {Regulierungsregister} wirklich löschen?',
    'generalFieldType.entityPurpose': '{Entitäts} Zweck'
  },
  da: {
    'wqsp.common.entityType': '{Enhed}stype',
    'wqsp.common.entityTypeMandatory': '{Enhed}stype er obligatorisk felt',
    'wqsp.common.entity': '{Enhed}',
    'wqsp.common.enities': '{Enheder}',
    'wqsp.common.entitySubType': '{Enhed}ens undertype',
    'wqsp.common.entityMandatory': '{Enhed} er obligatorisk felt',
    'wqsp.filters.allEntityTypes': 'Alle {enhed}styper',
    'wqsp.filters.allEntitySubTypes': 'Alle {enhed}styper',
    'inspectionReport.sourceEntityType': 'Kilde{enhed}stype',
    'inspectionReports.sourceEntityName': '{Enhed}snavn',
    'inspectionReports.sourceEntityTypeRequired': 'Kilde{enhed}stype er et obligatorisk felt',
    'inspectionReports.entityNameRequired': '{Enhed}snavn er et obligatorisk felt',
    'entities.sourceEntityType': 'Kilde{enhed}stype',
    'entities.sourceEntitySubType': '{Enhed}ens undertype',
    'entities.entitySubTypeRequired': '{Enhed}stype er et obligatorisk felt',
    'entities.addEntity': 'Tilføj ny {enhed}',
    'entities.sourceEntity': 'Kilde{enhed}',
    'entities.destinationEntity': 'Destinations{enhed}',
    'entity.searchEntityName': 'Navn på søge{enhed}',
    'riskAssessment.passedOnEntitiesMandatory': 'Hvis risiko er videregivet, skal du vælge {Enhed}, som den videregives til',
    'siteSegments.entityType': '{Enhed}stype',
    'siteSegments.entitySubType': '{Enhed}ens undertype',
    'wqsp.filters.allEntities': 'Alle {enheder}',
    'siteControlsTabs.entities': 'Enheder',
    'riskAssessment.passedOnEntities': '{Enhed} risiko videregives',
    'siteSegments.entities': '{Enheder}',
    'safetyPlanControlsTabs.regulatoryRegisters': '{Lovregistre}',
    'regulatoryRegister.addNewRegulatoryRegister': 'Tilføj nyt {lovregister}',
    'regulatoryRegister.confirmDelete': 'Er du sikker på, at du vil slette dette {lovregister}?',
    'generalFieldType.entityPurpose': '{Enhed}ens formål'
  },
  fr: {
    'wqsp.common.entityType': "Type d'{entité}",
    'wqsp.common.entityTypeMandatory': "Le type d'{entité} est un champ obligatoire",
    'wqsp.common.entity': '{Entité}',
    'wqsp.common.enities': '{Entités}',
    'wqsp.common.entitySubType': "Sous-type d'{entité}",
    'wqsp.common.entityMandatory': "L'{entité} est un champ obligatoire",
    'wqsp.filters.allEntityTypes': "Tous les types d'{entités}",
    'wqsp.filters.allEntitySubTypes': "Tous les sous-types d'{entité}",
    'inspectionReport.sourceEntityType': "Type d'{entité} source",
    'inspectionReports.sourceEntityName': "Nom de l'{entité}",
    'inspectionReports.sourceEntityTypeRequired': "Le type d'{entité} source est un champ obligatoire",
    'inspectionReports.entityNameRequired': "Le nom de l'{entité} est un champ obligatoire",
    'entities.sourceEntityType': "Type d'{entité} source",
    'entities.sourceEntitySubType': "Sous-type d'{entité}",
    'entities.entitySubTypeRequired': "Le sous-type d'{entité} est un champ obligatoire",
    'entities.addEntity': 'Ajouter une nouvelle {entité}',
    'entities.sourceEntity': '{Entité} source',
    'entities.destinationEntity': '{Entité} de destination',
    'entity.searchEntityName': "Nom de l'{entité} de recherche",
    'riskAssessment.passedOnEntitiesMandatory': "Si le risque est transféré, veuillez sélectionner l'{entité} à laquelle il est transféré",
    'siteSegments.entityType': "Type d'{entité}",
    'siteSegments.entitySubType': "Sous-type d'{entité}",
    'wqsp.filters.allEntities': 'Toutes les {entités}',
    'siteControlsTabs.entities': 'Entités',
    'riskAssessment.passedOnEntities': 'Répercussions des risques sur les {entités}',
    'siteSegments.entities': 'Entités',
    'safetyPlanControlsTabs.regulatoryRegisters': 'Registres réglementaires',
    'regulatoryRegister.addNewRegulatoryRegister': 'Ajouter un nouveau {registre réglementaire}',
    'regulatoryRegister.confirmDelete': 'Êtes-vous sûr de vouloir supprimer ce {registre réglementaire} ?',
    'generalFieldType.entityPurpose': "Objet de l'{entité}"
  }
};

export default languageObject;
