import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';

import {
  WATERSUPPLYDESCRIPTION_ERROR,
  GET_ALL_WATERSUPPLYDESCRIPTION_DETAILS,
  WATERSUPPLYDESCRIPTION_IS_LOADING,
  SET_WATERSUPPLYDESCRIPTION_CURRENT_PAGE,
  SET_WATERSUPPLYDESCRIPTION_PAGE_FILTER,
  SET_WATERSUPPLYDESCRIPTION_CHANGE,
  SET_WATERSUPPLYDESCRIPTION,
  UPDATE_WATERSUPPLYDESCRIPTION_SAVE_STATUS,
  SET_WATERSUPPLYDESCRIPTION_FILTERS,
  SET_WATERSUPPLYDESCRIPTION_RESULTS,
  SET_WATERSUPPLYDESCRIPTION_COMMON_LIST
} from '../../constants/index';

import { WATERSUPPLYDESCRIPTION_MANAGEMENT, CREATE_WATERSUPPLYDESCRIPTION, UPDATE_WATERSUPPLYDESCRIPTION } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setWaterSupplyDescriptionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setWaterSupplyDescriptionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setWaterSupplyDescriptionError(messageCode, false));
  }, 2500);
};

export const setWaterSupplyDescriptionError = (messageCode, status) => {
  return {
    type: WATERSUPPLYDESCRIPTION_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: WATERSUPPLYDESCRIPTION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getWaterSupplyDescriptionThunk = (widgetCode, siteId, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getWaterSupplyDescription(widgetCode, orgId, siteId, filterObj));
};

export const getWaterSupplyDescription = (widgetCode, orgId, siteId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/watersupplydescriptions/watersupplydescription`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      SiteId: siteId,
      filter: filterObj.filter,
      limit: filterObj.limit,
      offset: filterObj.offset
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllWaterSupplyDescriptionListThunk(retData),
    onFailure: err => setWaterSupplyDescriptionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllWaterSupplyDescriptionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let waterSupplyDescriptions = (retData && retData.retData.data) || [];
  dispatch(setAllWaterSupplyDescriptionList(messageCode, waterSupplyDescriptions));
};

export const setAllWaterSupplyDescriptionList = (messageCode, waterSupplyDescriptions) => ({
  type: GET_ALL_WATERSUPPLYDESCRIPTION_DETAILS,
  payload: {
    data: {
      WaterSupplyDescriptions: waterSupplyDescriptions,
      filteredResults: waterSupplyDescriptions,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_WATERSUPPLYDESCRIPTION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_WATERSUPPLYDESCRIPTION_PAGE_FILTER,
  payload: { filter }
});

export const describeWaterSupplyDescriptionThunk = (WaterSupplyDescriptionId, siteId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeWaterSupplyDescription(WaterSupplyDescriptionId, siteId, widgetCode, orgId));
};

export const describeWaterSupplyDescription = (WaterSupplyDescriptionId, siteId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/watersupplydescriptions/watersupplydescription`,
    data: {
      WaterSupplyDescriptionId: WaterSupplyDescriptionId,
      SiteId: siteId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setWaterSupplyDescriptionThunk(retData),
    onFailure: err => setWaterSupplyDescriptionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setWaterSupplyDescriptionThunk = ({ retData }) => dispatch => {
  let data = retData.data;
  data.files = retData.files || [];
  dispatch(setWaterSupplyDescription(data));
};

export const setWaterSupplyDescription = data => ({
  type: SET_WATERSUPPLYDESCRIPTION,
  payload: { selectedWaterSupplyDescription: data }
});

export const saveWaterSupplyDescriptionThunk = (waterSupplyDescription, widgetCode) => dispatch => {
  let url = '/watersupplydescriptions/watersupplydescription/save';
  let actionName = waterSupplyDescription.WaterSupplyDescriptionId === 0 ? CREATE_WATERSUPPLYDESCRIPTION : UPDATE_WATERSUPPLYDESCRIPTION;
  let log = logEntry(WATERSUPPLYDESCRIPTION_MANAGEMENT, actionName, waterSupplyDescription);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  waterSupplyDescription.OrganisationId = orgId;
  dispatch(saveWaterSupplyDescription(waterSupplyDescription, url, log, widgetCode));
};

export const saveWaterSupplyDescription = (waterSupplyDescription, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setWaterSupplyDescriptionStatusThunk(retData, waterSupplyDescription),
    onFailure: err => setWaterSupplyDescriptionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: waterSupplyDescription,
      log: log
    },
    widgetcode: widgetCode
  });

export const setWaterSupplyDescriptionStatusThunk = (message, waterSupplyDescription) => dispatch => {
  dispatch(setWaterSupplyDescriptionStatus(message.message, true, false));
  dispatch(setGeneralData(message.data));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !waterSupplyDescription.PerformDelete;
    dispatch(setWaterSupplyDescriptionStatus(message.message, false, isRedirect));
  }, 2500);
};

export const setGeneralData = item => {
  return {
    type: SET_WATERSUPPLYDESCRIPTION_COMMON_LIST,
    payload: {
      data: {
        WaterSupplyDescriptionId: item.WaterSupplyDescriptionId,
        SiteId: item.SiteId,
        Name: item.Name
      }
    }
  };
};

export const setWaterSupplyDescriptionStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_WATERSUPPLYDESCRIPTION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const setWaterSupplyDescriptionChangeThunk = retData => dispatch => {
  dispatch(setWaterSupplyDescriptionChange(retData));
};

export const setWaterSupplyDescriptionChange = retData => ({
  type: SET_WATERSUPPLYDESCRIPTION_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      filterFor: 'waterSupplyDescriptions',
      SiteId: siteId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setWaterSupplyDescriptionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_WATERSUPPLYDESCRIPTION_FILTERS,
  payload: {
    data: {
      isLoading: false,
      waterSupplyDescriptionListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_WATERSUPPLYDESCRIPTION_RESULTS,
  payload: retData
});
