import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './Hazards.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const HazardsListTable = ({ HazardData, RemoveHazard, ChangeHazard, UpdateAllowed, DeleteAllowed, intl }) => {
  return (
    <div>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.hazardClass" defaultMessage="Hazard Class" />
            </th>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="common.description" defaultMessage="Description" />
            </th>
            {(UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {HazardData.map(n => {
            return (
              <Fragment key={n.HazardId}>
                <tr data-unittest="hazardData">
                  <td>{n.HazardClassName}</td>
                  <td>{n.Name}</td>
                  <td>{n.Description}</td>
                  {(UpdateAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={() => {
                            ChangeHazard(n.HazardId);
                          }}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.removeButton}
                          href="#"
                          onClick={() => {
                            RemoveHazard(n.HazardId);
                          }}
                          data-unittest="removeData"
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

HazardsListTable.defaultProps = {
  HazardData: []
};

HazardsListTable.propTypes = {
  HazardData: PropTypes.array.isRequired,
  RemoveHazard: PropTypes.func.isRequired,
  ChangeHazard: PropTypes.func.isRequired,
  UpdateAllowed: PropTypes.bool,
  DeleteAllowed: PropTypes.bool
};

export default injectIntl(HazardsListTable);
