import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';
import uuidv4 from 'uuid/v4';

import {
  ENTITY_ERROR,
  GET_ALL_ENTITY_DETAILS,
  ENTITY_IS_LOADING,
  SET_ENTITY_CURRENT_PAGE,
  SET_ENTITY_PAGE_FILTER,
  SET_ENTITY_CHANGE,
  SET_ENTITY,
  SET_ENTITY_CLEANUP,
  UPDATE_ENTITY_SAVE_STATUS,
  SET_ENTITY_FILTERS,
  SET_ENTITY_RESULTS,
  ENTITY_LISTING_CLEANUP,
  SET_ENTITY_COMMON_LIST
} from '../../constants/index';

import { ENTITY_MANAGEMENT, CREATE_ENTITY, UPDATE_ENTITY } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setEntityErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setEntityError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEntityError(messageCode, false));
  }, 2500);
};

export const setEntityError = (messageCode, status) => {
  return {
    type: ENTITY_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: ENTITY_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getEntityThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getEntity(widgetCode, filterObj, orgId));
};

export const getEntity = (widgetCode, filterObj, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/entities/entity`,
    method: 'GET',
    data: {
      OrgId: orgId,
      SiteId: filterObj.siteId,
      filterWaterSupplyDescription: filterObj.filterWaterSupplyDescription,
      filterEntityType: filterObj.filterEntityType,
      filterEntitySubType: filterObj.filterEntitySubType,
      filter: filterObj.filter,
      limit: filterObj.limit,
      offset: filterObj.offset
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllEntityListThunk(retData),
    onFailure: err => setEntityErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllEntityListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllEntityList(messageCode, data));
};

export const setAllEntityList = (messageCode, data) => ({
  type: GET_ALL_ENTITY_DETAILS,
  payload: {
    data: {
      Entities: data,
      filteredResults: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_ENTITY_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_ENTITY_PAGE_FILTER,
  payload: { filter }
});

export const describeEntityThunk = (EntityId, widgetCode, siteId) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeEntity(EntityId, widgetCode, orgId, siteId));
};

export const describeEntity = (EntityId, widgetCode, orgId, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/entities/entity`,
    data: {
      EntityId: EntityId,
      OrgId: orgId,
      SiteId: siteId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setEntityThunk(retData),
    onFailure: err => setEntityErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setEntityThunk = ({ retData }) => dispatch => {
  let data = retData.data;
  dispatch(setEntity(data));
};

export const setEntity = data => ({
  type: SET_ENTITY,
  payload: {
    data: data
  }
});

export const saveEntityThunk = (data, widgetCode) => dispatch => {
  let url = '/entities/entity/save';
  let actionName = data.EntityId === 0 ? CREATE_ENTITY : UPDATE_ENTITY;
  let log = logEntry(ENTITY_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveEntity(data, url, log, widgetCode));
};

export const saveEntity = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setEntityStatusThunk(retData),
    onFailure: err => setEntityErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setEntityStatusThunk = message => dispatch => {
  dispatch(setEntityStatus(message.message, true, false));
  dispatch(setGeneralData(message.data));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEntityStatus(message.message, false, true));
  }, 2500);
};

export const setGeneralData = item => {
  return {
    type: SET_ENTITY_COMMON_LIST,
    payload: {
      data: {
        EntityId: item.EntityId,
        WaterSupplyDescriptionId: item.WaterSupplyDescriptionId,
        EntitySubTypeId: item.EntitySubTypeId,
        Name: item.Name,
        EntityTypeId: item.EntityTypeId
      }
    }
  };
};

export const setEntityStatus = (messageCode, status, redirect) => ({
  type: UPDATE_ENTITY_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: status,
      showBanner: status,
      isLoading: false,
      isRedirect: redirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setEntityChangeThunk = retData => dispatch => {
  dispatch(setEntityChange(retData));
};

export const setEntityChange = retData => ({
  type: SET_ENTITY_CHANGE,
  payload: retData
});

export const setEntityCleanUpThunk = SelectedEntity => dispatch => {
  dispatch({ type: SET_ENTITY_CLEANUP, payload: SelectedEntity });
};

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      SiteId: siteId,
      filterFor: 'entities'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setEntityErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_ENTITY_FILTERS,
  payload: {
    data: {
      isLoading: false,
      entityListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_ENTITY_RESULTS,
  payload: retData
});

export const cleanUpThunk = retData => dispatch => {
  dispatch(cleanUp(retData));
};

export const cleanUp = retData => ({
  type: ENTITY_LISTING_CLEANUP,
  payload: retData
});
