import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './Entity.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import EntityListTable from './EntityListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import SearchBar from '../SearchBar/SearchBar';
import { getUserProfileWidget, getEntity, getGeneralData } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';

import { getEntityThunk, getFilterThunk, setFilteredDataThunk, setCurrentPage, setPageFilter } from '../../store/actions/entity';

const WATER_SUPPLY_DESCRIPTION = 'WATER_SUPPLY_DESCRIPTION';
const ENTITY_TYPE = 'ENTITY_TYPE';
const ENTITY_SUBTYPE = 'ENTITY_SUBTYPE';
const SEARCH_FILTER = 'SEARCH_FILTER';

const EntityList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const generalData = useSelector(state => getGeneralData(state));
  const entity = useSelector(state => getEntity(state));
  const { siteId, segmentName, selectedTab } = useParams('siteId');

  let filter = entity.filterBy.filter || null;
  let offset = entity.offset || 0;
  let limit = entity.limit || 1000;

  let filterRedWaterSupplyDescription = entity.filterBy.filterWaterSupplyDescription || null;
  let filterRedEntityType = entity.filterBy.filterEntityType || null;
  let filterRedEntitySubType = entity.filterBy.filterEntitySubType || null;
  let disableControls = entity.isLoading || false;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterWaterSupplyDescription, setFilterWaterSupplyDescription] = useState(0);
  const [filterEntityType, setFilterEntityType] = useState(0);
  const [filterEntitySubType, setFilterEntitySubType] = useState(0);

  const [asendingOrder, setAsendingOrder] = useState(true);

  useEffect(() => {
    let filterObj = {
      filterWaterSupplyDescription: filterRedWaterSupplyDescription,
      filterEntityType: filterRedEntityType,
      filterEntitySubType: filterRedEntitySubType,
      filter: filter,
      siteId: siteId,
      offset: offset,
      limit: limit
    };
    if (siteId) {
      dispatch(getEntityThunk('WQSP_ENTITIES_LIST', filterObj));
    }
  }, [getEntityThunk, siteId, filter, filterRedEntityType, filterRedEntitySubType, filterRedWaterSupplyDescription]);

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_ENTITIES_LIST', siteId));
  }, [getFilterThunk, siteId]);

  let messageId = (entity && entity.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const waterSupplyDescriptionOptions = () => {
    let waterSupplyDescriptionData = [];

    generalData.waterSupplyDescriptions.forEach(element => {
      if (element.SiteId === parseInt(siteId)) {
        waterSupplyDescriptionData.push({
          label: element.Name,
          value: element.WaterSupplyDescriptionId
        });
      }
    });

    let sortedData = waterSupplyDescriptionData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allWaterSupplies" defaultMessage="All Water Supplies" />,
      value: 0
    });
    return sortedData;
  };

  const entityTypeOptions = () => {
    let opData = [];

    opData.push({
      label: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
      value: 0
    });
    const data = generalData.entityTypes;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntityTypeId
      });
    });
    return opData;
  };

  const entitySubTypeOptions = () => {
    let opData = [];

    opData.push({
      label: <FormattedMessage id="wqsp.filters.allEntitySubTypes" defaultMessage="All Entity Sub Types" />,
      value: 0
    });

    const data = generalData.entitySubTypes.filter(x => x.EntityTypeId === filterEntityType);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntitySubTypeId
      });
    });
    return opData;
  };

  const onDropdownChange = (e, fieldName) => {
    if (fieldName === ENTITY_TYPE) {
      setFilterEntityType(e.value);
    }

    if (fieldName === ENTITY_SUBTYPE) {
      setFilterEntitySubType(e.value);
    }

    if (fieldName === WATER_SUPPLY_DESCRIPTION) {
      setFilterEntityType(0);
      setFilterEntitySubType(0);
      setFilterWaterSupplyDescription(e.value);
    }

    filteredResults(fieldName, e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = entity.Entities;

    let apifilterObj = {
      filterEntityType: null,
      filterEntitySubType: null,
      filterWaterSupplyDescription: null,
      filter: null
    };

    if (filterType === WATER_SUPPLY_DESCRIPTION) {
      if (value !== 0) {
        apifilterObj.filterWaterSupplyDescription = value;
        filteredList = filteredList.filter(function(item) {
          return item.WaterSupplyDescriptionId === value;
        });
      }
    } else {
      if (filterWaterSupplyDescription !== 0) {
        apifilterObj.filterWaterSupplyDescription = filterWaterSupplyDescription;
        filteredList = filteredList.filter(function(item) {
          return item.WaterSupplyDescriptionId === filterWaterSupplyDescription;
        });
      }
    }

    if (filterType === ENTITY_TYPE) {
      if (value !== 0) {
        apifilterObj.filterEntityType = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === value;
        });
      }
    } else {
      if (filterEntityType !== 0) {
        apifilterObj.filterEntityType = filterEntityType;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityType;
        });
      }
    }

    if (filterType === ENTITY_SUBTYPE) {
      if (value !== 0) {
        apifilterObj.filterEntitySubType = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntitySubTypeId === value;
        });
      }
    } else {
      if (filterEntitySubType !== 0) {
        apifilterObj.filterEntitySubType = filterEntitySubType;
        filteredList = filteredList.filter(function(item) {
          return item.EntitySubTypeId === filterEntitySubType;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (entity.entityListCount > entity.Entities.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let entityObj = Object.values(entity.filteredResults);

  if (asendingOrder) {
    entityObj = entityObj.sort(compareValues('EntityType'));
  } else {
    entityObj = entityObj.sort(compareValues('EntityType', 'desc'));
  }

  const onAddNewClick = () => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/entities/entity`);
  };

  const onChangeEntityClick = entityId => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}`);
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ENTITIES_UPDATE');
  //const isUpdateAllowed = true;

  return (
    <div className={s.entityList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.contentWrapper}>
          {entity.isLoading && <LoadingSpinner />}
          <Banner failureText={messageText} showBanner={entity.showBanner} status={entity.isOpSuccessful} successText={messageText} />
          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="entity.searchEntityName"
              />
            </Col>
            <Col sm={6} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_INSPECTIONPURPOSE_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addEntityButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpWaterSupllyDescriptionId"
                dataArray={waterSupplyDescriptionOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.common.waterSupplyDescription" defaultMessage="Water Supply Description" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onDropdownChange(e, WATER_SUPPLY_DESCRIPTION)}
                selectedOption={waterSupplyDescriptionOptions().filter(option => option.value === filterWaterSupplyDescription)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="formSourceEntityType"
                dataArray={entityTypeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
                  customClassName: ''
                }}
                name="EntityTypeId"
                onDropdownChange={e => onDropdownChange(e, ENTITY_TYPE)}
                selectedOption={entityTypeOptions().filter(option => option.value === filterEntityType)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="formSourceEntityType"
                dataArray={entitySubTypeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.filters.allEntitySubTypes" defaultMessage="All Entity Sub Types" />,
                  customClassName: ''
                }}
                name="EntitySubTypeId"
                onDropdownChange={e => onDropdownChange(e, ENTITY_SUBTYPE)}
                selectedOption={entitySubTypeOptions().filter(option => option.value === filterEntitySubType)}
                disabled={disableControls}
              />
            </Col>
          </Row>
          <div className="">
            <Pagination data={entityObj} pageSize={DEFAULT_PAGE_SIZE} onPageChange={onPageChangeHandler} startingPage={entity.currentPage}>
              <EntityListTable
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                ChangeEntity={onChangeEntityClick}
                UpdateAllowed={isUpdateAllowed}
                userProfileWidgets={userProfileWidgets}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(EntityList);
