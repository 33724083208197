import Cookies from 'js-cookie';
import { GET_ALL_WIDGET_DETAILS, WIDGET_IS_SAVING, UPDATE_WIDGET_SAVE_STATUS, WIDGET_IS_LOADING } from '../../constants/index';
import { apiAction, logEntry } from '../../utils/index';
import { WIDGET_MANAGEMENT, CREATE_WIDGET } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const fetchAppWidgetsThunk = widgetCode => dispatch => {
  dispatch(getAppWidgets(widgetCode));
};

export const getAppWidgets = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/widget`,
    data: {
      orgId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      isWQSP: true
    },
    method: 'GET',
    widgetcode: widgetCode,
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => processWidgetList(retData),
    onFailure: err => setWidgetFailedThunk(err),
    accessToken: Cookies.get(`access-${envName}`)
  });

export const setLoadingStatus = isLoading => ({
  type: WIDGET_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const processWidgetList = results => dispatch => {
  results.isLoading = false;
  results.isOpSuccessfull = false;
  results.showBanner = false;
  dispatch(setAllAppWidgetList(results));
};

export const setAllAppWidgetList = widgets => ({
  type: GET_ALL_WIDGET_DETAILS,
  payload: { Widgets: widgets }
});

export const createAppWidgetsThunk = () => (dispatch, getState) => {
  const { appWidgets } = getState().appWidgets;
  let log = logEntry(WIDGET_MANAGEMENT, CREATE_WIDGET, appWidgets.appWidgets);
  dispatch(createAppWidgets(appWidgets, log));
};

export const createAppWidgets = (widgets, log) => {
  //setAppWidgetSavingStatusThunk(true);
  return apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/widget/save`,
    method: 'POST',
    onLoad: () => setAppWidgetSavingStatusThunk(true),
    onSuccess: message => setWidgetSuccessThunk(message),
    onFailure: error => setWidgetFailedThunk(error),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      appWidgets: widgets.appWidgets,
      log: log
    }
  });
};

export const setAppWidgetSavingStatusThunk = isWidgetSaving => dispatch => {
  dispatch(setAppWidgetSavingStatus(isWidgetSaving));
};

export const setAppWidgetSavingStatus = isWidgetSaving => ({
  type: WIDGET_IS_SAVING,
  payload: { isLoading: isWidgetSaving }
});

export const setAppWidgetSaveStatus = (isWidgetSaved, showBanner = false) => ({
  type: UPDATE_WIDGET_SAVE_STATUS,
  payload: { isLoading: false, isOpSuccessfull: isWidgetSaved, showBanner: showBanner }
});

export const setWidgetSuccessThunk = message => dispatch => {
  dispatch(setAppWidgetSaveStatus(true, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setAppWidgetSaveStatus(true, false));
  }, 5000);
};

export const setWidgetFailedThunk = error => dispatch => {
  dispatch(setAppWidgetSaveStatus(false, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setAppWidgetSaveStatus(false, false));
  }, 5000);
};
