import React, { useState } from 'react';
import logoBlack from '../../assets/logo-black.svg';
import collapse from '../../assets/collapse.svg';
import expand from '../../assets/expand.svg';
import logoutIcon from '../../assets/logout-grey.svg';
import arrowDown from '../../assets/arrow-down-grey.svg';
import cross from '../../assets/cross.svg';
import defaultUser from '../../images/default-user.png';
import { Link } from 'react-router-dom';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import s from './Sidebar.module.scss';
import classNames from 'classnames';
import Image from 'react-bootstrap/Image';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import ProfileSwitch from '../ProfileSwitch/ProfileSwitch';
import OrganisationSwitch from '../OrganisationSwitch/OrganisationSwitch';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ENV, DEV_ENV, STAGING_ENV, DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { postUserThunk } from '../../store/actions/userManager';
import { redirectTo } from '../../store/actions/sidebar';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const NAVIGATION_ITEMS = [
  {
    name: 'home',
    label: <FormattedMessage id="sidebar.home" defaultMessage="Home" />,
    activeIcon: require('../../assets/home-blue.svg'),
    nonActiveIcon: require('../../assets/home-grey.svg'),
    link: '/dashboard',
    WidgetName: 'NAV_HOME'
  },
  {
    name: 'site',
    label: <FormattedMessage id="sidebar.sites" defaultMessage="Sites" />,
    activeIcon: require('../../assets/icon_site_blue.svg'),
    nonActiveIcon: require('../../assets/icon_site_grey.svg'),
    link: '/sites',
    WidgetName: 'NAV_SITES'
  },
  {
    name: 'safetyPlan',
    label: <FormattedMessage id="sidebar.safetyPlan" defaultMessage="Safety Plans" />,
    activeIcon: require('../../assets/safetyplan-blue.svg'),
    nonActiveIcon: require('../../assets/safetyplan-grey.svg'),
    link: '/safety-plans',
    WidgetName: 'NAV_SAFETY_PLAN'
  },
  {
    name: 'riskAssessment',
    label: <FormattedMessage id="sidebar.riskAssessments" defaultMessage="Risk Assessments" />,
    activeIcon: require('../../assets/risk-assessment-blue.svg'),
    nonActiveIcon: require('../../assets/risk-assessment-grey.svg'),
    link: '/risk-assessments',
    WidgetName: 'NAV_RISK_ASSESSMENT'
  },
  {
    name: 'tasksChecklists',
    label: <FormattedMessage id="sidebar.tasksChecklists" defaultMessage="Tasks" />,
    activeIcon: require('../../assets/tasks-blue.svg'),
    nonActiveIcon: require('../../assets/tasks-grey.svg'),
    link: '/tasks-and-checklists',
    WidgetName: 'NAV_TASKS'
  },
  {
    name: 'inspections',
    label: <FormattedMessage id="sidebar.inspections" defaultMessage="Inspections" />,
    activeIcon: require('../../assets/icon_inspections_lightblue.svg'),
    nonActiveIcon: require('../../assets/icon_inspections_grey.svg'),
    link: '/inspections',
    WidgetName: 'NAV_INSPECTIONS'
  },
  {
    name: 'testresult',
    label: <FormattedMessage id="sidebar.testResults" defaultMessage="Test Results" />,
    activeIcon: require('../../assets/icon_testresults_lightblue.svg'),
    nonActiveIcon: require('../../assets/icon_testresults_grey.svg'),
    link: '/test-results',
    WidgetName: 'NAV_TEST_RESULTS'
  },
  {
    name: 'qualityGoals',
    label: <FormattedMessage id="sidebar.qualityGoals" defaultMessage="Quality Goals" />,
    activeIcon: require('../../assets/icon_qualitygoals_blue.svg'),
    nonActiveIcon: require('../../assets/icon_qualitygoals_grey.svg'),
    link: '/quality-goals',
    WidgetName: 'NAV_QUALITY_GOALS'
  },
  {
    name: 'hazardEvents',
    label: <FormattedMessage id="sidebar.hazardEvents" defaultMessage="Hazard Events" />,
    activeIcon: require('../../assets/icon_hazard_blue.svg'),
    nonActiveIcon: require('../../assets/icon_hazard_grey.svg'),
    link: '/hazard-events',
    WidgetName: 'NAV_HAZARD_EVENTS'
  },
  {
    name: 'incidentReports',
    label: <FormattedMessage id="sidebar.incidentReports" defaultMessage="Incident Reports" />,
    activeIcon: require('../../assets/incident-blue.svg'),
    nonActiveIcon: require('../../assets/incident-grey.svg'),
    link: '/incident-reports',
    WidgetName: 'NAV_INCIDENT_REPORTS'
  },
  {
    name: 'operationalSetupControls',
    label: <FormattedMessage id="sidebar.operationalSetupControls" defaultMessage="Operational Setup" />,
    activeIcon: require('../../assets/admin-blue.svg'),
    nonActiveIcon: require('../../assets/admin-grey.svg'),
    link: '/operational-setup',
    WidgetName: 'NAV_OPERATIONAL_SETUP'
  },
  {
    name: 'adminControls',
    label: <FormattedMessage id="sidebar.adminControls" defaultMessage="Admin Controls" />,
    activeIcon: require('../../assets/admin-blue.svg'),
    nonActiveIcon: require('../../assets/admin-grey.svg'),
    link: '/admin-controls',
    WidgetName: 'ADMINCONTROLS'
  },
  {
    name: 'accountSettings',
    label: <FormattedMessage id="sidebar.accountSettings" defaultMessage="Account Settings" />,
    activeIcon: require('../../assets/settings-blue.svg'),
    nonActiveIcon: require('../../assets/settings-grey.svg'),
    link: '/account-settings',
    WidgetName: 'NAV_SETTINGS'
  },
  {
    name: 'help',
    label: <FormattedMessage id="sidebar.help" defaultMessage="Help" />,
    activeIcon: require('../../assets/help-blue.svg'),
    nonActiveIcon: require('../../assets/help-grey.svg'),
    link: '/Help',
    WidgetName: 'NAV_HELP'
  }
];

const Sidebar = ({
  logout,
  handleViewSidebar,
  currentSidebarOpen,
  location,
  getSelectedUserProfileWidgets,
  getTranslationData,
  isGeneralDataPresent,
  getGeneralData,
  userProfileWidgets,
  userProfileWidgetsPresent,
  translation
}) => {
  const dispatch = useDispatch();
  const arrowOrientation = currentSidebarOpen ? collapse : expand;
  const sidebarClass = currentSidebarOpen ? '' : s.active;
  const sidebarTopClass = currentSidebarOpen ? s.sidebarTop : s.sidebarTopClose;
  const width = window.innerWidth;
  const name = Cookies.get(`name-${envName}`);
  //const profiles = Cookies.get(`profiles-${envName}`) ? JSON.parse(Cookies.get(`profiles-${envName}`)) : {};
  const profiles = localStorage.getItem(`profiles-${envName}`) ? JSON.parse(localStorage.getItem(`profiles-${envName}`)) : {};
  //const organisations = Cookies.get(`organisations-${envName}`) ? JSON.parse(Cookies.get(`organisations-${envName}`)) : [];
  const organisations = localStorage.getItem(`organisations-${envName}`)
    ? JSON.parse(localStorage.getItem(`organisations-${envName}`))
    : [];
  let selectedProfile = Cookies.get(`selectedprofile-${envName}`) || '';
  let selectedProfileid = Cookies.get(`selectedprofileid-${envName}`) || 0;
  let selectedOrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  let userName = Cookies.get(`username-${envName}`) || '';
  let selectedOrganisation = organisations.filter(x => x.OrganisationId === parseInt(selectedOrganisationId));
  let selectedOrganisationName = selectedOrganisation.length > 0 ? selectedOrganisation[0].Name : '';
  const locale = Cookies.get(`defaultLanguage-${envName}`) || 'en';

  let organisationLogos = localStorage.getItem(`organisationslogos-${envName}`) || [];
  let organisationLogosJSONData = [];
  try {
    organisationLogosJSONData = JSON.parse(organisationLogos);
  } catch (e) {
    organisationLogosJSONData = [];
  }

  let selectedOrganisationLogoData = organisationLogosJSONData.filter(item => item.OrganisationId == parseInt(selectedOrganisationId));
  let selectedOrganisationLogo = logoBlack;
  if (selectedOrganisationLogoData.length > 0 && selectedOrganisationLogoData[0].OrganisationLogo) {
    selectedOrganisationLogo = process.env.REACT_APP_FILEUPLOAD_LOGO_URL + '/' + selectedOrganisationLogoData[0].OrganisationLogo;
  }

  const [showPopup, setShowPopup] = useState(false);
  const [showOrganisationPopup, setShowOrganisationPopup] = useState(false);

  const handleClick = () => {
    setShowPopup(!showPopup);
  };

  const handleOrganisationClick = () => {
    setShowOrganisationPopup(!showOrganisationPopup);
  };

  const [redirect, setredirect] = useState(false);

  useEffect(() => {
    if (!userProfileWidgetsPresent) {
      getSelectedUserProfileWidgets(selectedProfileid, userName);
    }
  }, [getSelectedUserProfileWidgets, selectedProfileid, userName]);

  //Loading translation data
  useEffect(() => {
    if (
      (!translation.isDataLoaded || translation.locale !== locale || translation.organisationId !== selectedOrganisationId) &&
      !translation.isLoading
    ) {
      getTranslationData(locale, userName);
    }
  }, [getTranslationData, translation.isDataLoaded, translation.isLoading]);

  useEffect(() => {
    if (!isGeneralDataPresent) {
      getGeneralData();
    }
  }, []);

  let onUserProfileChange = prof => {
    setredirect(true);
    Cookies.set(`selectedprofile-${envName}`, prof.Name, { expires: 7, domain: DOMAIN_NAME, secure: IS_LOCALHOST ? false : true });
    Cookies.set(`selectedprofileid-${envName}`, prof.ProfileId, { expires: 7, domain: DOMAIN_NAME, secure: IS_LOCALHOST ? false : true });
    selectedProfile = prof.Name;
    selectedProfileid = prof.ProfileId;

    getSelectedUserProfileWidgets(selectedProfileid, userName);
    handleClick();
  };

  let onOrganisationChange = org => {
    setredirect(true);
    Cookies.set(`selectedorganisationid-${envName}`, org.OrganisationId, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    selectedOrganisationName = org.Name;
    selectedOrganisationId = org.OrganisationId;
    getSelectedUserProfileWidgets(null, userName);
    handleOrganisationClick();
    const userId = Cookies.get(`userid-${envName}`) || 0;
    dispatch(postUserThunk({ CurrentOrganisationId: org.OrganisationId, UserId: userId }, 'GENERALACCESS', true));
    dispatch(redirectTo());
  };

  return (
    <nav className={classNames(s.sidebar, sidebarClass)}>
      {redirect ? <Redirect to="/dashboard" /> : ''}
      <div className={sidebarTopClass}>
        <div className={s.dismiss}>
          <img src={cross} alt="close" onClick={() => handleViewSidebar(true)} />
        </div>

        <div className={s.sidebarHeader}>
          <Link to="/" id="logo" className={s.logo}>
            <img src={selectedOrganisationLogo} alt="Logo" className={s.selectedLogoImg} />
          </Link>
          <img
            src={arrowOrientation}
            alt="Collapse"
            className={classNames(`${s.arrowCollapse} float-right`)}
            onClick={() => handleViewSidebar(!currentSidebarOpen)}
          />
        </div>
        <ul className={classNames(`${s.sidebarlist} list-unstyled components sidebarlist`)}>
          {NAVIGATION_ITEMS &&
            NAVIGATION_ITEMS.map((sidebarItem, index) =>
              IsWidgetAccessible(userProfileWidgets, sidebarItem.WidgetName) ? (
                <li className={matchPath(location.pathname, { path: sidebarItem.link }) ? s.activeLink : ''} key={index}>
                  <Link
                    to={sidebarItem.link}
                    onClick={
                      width <= 768
                        ? () => handleViewSidebar(true)
                        : () => {
                            return false;
                          }
                    }
                  >
                    <img
                      src={matchPath(location.pathname, { path: sidebarItem.link }) ? sidebarItem.activeIcon : sidebarItem.nonActiveIcon}
                      alt=""
                      width="20"
                      height="20"
                      className="mr-2"
                    ></img>
                    <span>{sidebarItem.label}</span>
                  </Link>
                </li>
              ) : (
                <span key={index} />
              )
            )}
          <li key="logout">
            <a href="#" onClick={logout}>
              <img src={logoutIcon} alt="" width="20" height="20" className="mr-2"></img>
              <span>
                <FormattedMessage id="sidebar.logout" defaultMessage="Log out" />
              </span>
            </a>
          </li>
        </ul>
      </div>

      <Row className={s.sidebarFooter}>
        <Col md={3}>
          <Image src={defaultUser} width="55px" height="55px" roundedCircle />
        </Col>
        <Col className={s.footerText} md={9}>
          <span className={s.userName}>{name ? name : ''}</span> <br />
          <span className={s.profileName}>
            {selectedProfile}
            <img src={arrowDown} alt="icon" onClick={handleClick}></img>
          </span>{' '}
          <br />
          <span className={s.profileName}>
            {selectedOrganisationName}
            <img src={arrowDown} alt="icon" onClick={handleOrganisationClick}></img>
          </span>
          {/* <span className={s.versionNo}>version : v1.1.0 </span> */}
          {profiles &&
            profiles.length > 0 &&
            profiles.map(prof => {
              let profName = prof.Name;
              if (!selectedProfile || !selectedProfileid) {
                selectedProfile = profName;
                selectedProfileid = prof.ProfileId;
                Cookies.set(`selectedprofile-${envName}`, profName, {
                  expires: 7,
                  domain: DOMAIN_NAME,
                  secure: IS_LOCALHOST ? false : true
                });
                Cookies.set(`selectedprofileid-${envName}`, selectedProfileid, {
                  expires: 7,
                  domain: DOMAIN_NAME,
                  secure: IS_LOCALHOST ? false : true
                });
              }
            })}
          {showPopup && (
            <ProfileSwitch
              selectedProfile={selectedProfile}
              profiles={profiles}
              onUserProfileChange={onUserProfileChange}
              closePopup={handleClick}
            ></ProfileSwitch>
          )}
          {organisations &&
            organisations.length > 0 &&
            organisations.map(org => {
              selectedOrganisation = org.Name;
              if (!selectedOrganisationId) {
                selectedOrganisationId = org.OrganisationId;
                Cookies.set(`selectedorganisationid-${envName}`, selectedOrganisationId, {
                  expires: 7,
                  domain: DOMAIN_NAME,
                  secure: !IS_LOCALHOST
                });
              }
            })}
          {showOrganisationPopup && (
            <OrganisationSwitch
              onOrganisationChange={onOrganisationChange}
              closePopup={handleOrganisationClick}
              selectedOrganisationId={selectedOrganisationId}
              organisations={organisations}
            ></OrganisationSwitch>
          )}
        </Col>
      </Row>
    </nav>
  );
};

Sidebar.propTypes = {
  handleViewSidebar: PropTypes.func,
  currentSidebarOpen: PropTypes.bool,
  logout: PropTypes.func,
  userProfileWidgets: PropTypes.object,
  getSelectedUserProfileWidgets: PropTypes.func,
  getGeneralData: PropTypes.func,
  isGeneralDataPresent: PropTypes.bool.isRequired,
  userProfileWidgetsPresent: PropTypes.bool
};

Sidebar.defaultProps = {
  userProfileWidgets: {},
  isGeneralDataPresent: false
};

export default Sidebar;
