import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Timezone from './Timezone';
import { changeTimezone } from '../../store/actions/timezone';
import { getCurrentTimezone } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTimezone: targetTimezone => changeTimezone(targetTimezone)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Timezone);
