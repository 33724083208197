import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from './HazardEvent.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { compareValues } from '../../utils';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';

const EventItem = ({ onAddEventItem, hazards, onCancelEventItem, eventItems, intl }) => {
  const [localformErrors, setFormErrors] = useState({});
  const [localHazard, setLocalHazard] = useState({});

  const onCancelClick = () => {
    setLocalHazard({});
    onCancelEventItem();
  };

  useEffect(() => {
    return () => {
      onCancelClick();
    };
  }, []);

  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }
    let hazard = { ...localHazard };
    hazard.HazardEventItemId = 0;

    onAddEventItem(hazard);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!localHazard || !localHazard.HazardId) {
      formErrors.Hazard = intl.formatMessage({
        id: 'hazardEventItem.hazardRequired',
        defaultMessage: 'Hazard is required'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const hazardOptions = () => {
    let filteredItems = eventItems.filter(item => !item.IsDeleted);
    let categoryData = [];
    hazards.forEach(element => {
      categoryData.push({
        ...element,
        label: element.Name,
        value: element.HazardId,
        isDisabled: filteredItems.find(item => item.HazardId === element.HazardId) ? true : false
      });
    });

    let sortedData = categoryData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onHazardDropdownChange = e => {
    setLocalHazard({
      ...localHazard,
      HazardId: e.value,
      HazardName: e.label,
      HazardClassId: e.HazardClassId,
      Description: e.Description
    });
  };

  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });

  return (
    <div className={s.testcategory}>
      <div className={s.hazardeventItemContent}>
        <div className={s.topRow}>
          <Row>
            <Col lg={4}>
              <Form.Group controlId="formHazard">
                <Form.Label>
                  <FormattedMessage id="hazardEventItem.hazard" defaultMessage="Hazard" />
                </Form.Label>

                <Dropdown
                  id="formDdlHazard"
                  dataArray={hazardOptions()}
                  controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Hazard && s.ddlError }}
                  onDropdownChange={onHazardDropdownChange}
                  data-unittest="formDdlHazard"
                  selectedOption={hazardOptions().filter(option => option.value === localHazard.HazardId)}
                />
                {localformErrors && localformErrors.Hazard && (
                  <p role="alert" className={s.error}>
                    {localformErrors.Hazard}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChangesSmall)}
                onClick={submitForm}
                noValidate
                data-unittest="add"
              >
                <FormattedMessage id="common.add" defaultMessage="ADD" />
              </Button>
              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancelSmall)}
                onClick={onCancelClick}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
            <Col lg={8}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(EventItem);
