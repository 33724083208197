import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import s from './TestCategory.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import CategoryItem from './CategoryItem';
import { WidgetVisibility } from '../../utils/widgetManager';

const TestCategoryItemTable = ({
  selectedTestCategoryType,
  testCategoryTypes,
  setEditHandler,
  setAddHandler,
  setTestCategoryItemExistsHandler,
  showAddEdit,
  disableMode,
  setDisableMode,
  selectedTestCategoryTypeId,
  selectedTestCategoryTypeIdentifier,
  onSaveCategoryType,
  onChangeCategoryType,
  onDeleteCategoryType,
  userProfileWidgets,
  updateAllowed,
  intl
}) => {
  let isEdit = false;

  if (selectedTestCategoryTypeId > 0 || selectedTestCategoryTypeIdentifier !== '') {
    isEdit = true;
  }

  const addNewContentTypeHandler = () => {
    setAddHandler();
    setDisableMode(true);
  };

  const fetchDataHandler = (testCategoryTypeId, identifier) => {
    setEditHandler(testCategoryTypeId, identifier);
    setDisableMode(true);
  };

  const deleteDataHandler = (testCategoryTypeId, identifer) => {
    if (testCategoryTypeId > -1) {
      if (testCategoryTypeId === 0) {
        const categoryTypeList = testCategoryTypes || [];
        let categoryTypes = categoryTypeList.filter(item => {
          if (item.GuidIdentifier !== identifer) return item;
        });
        onDeleteCategoryType(categoryTypes, testCategoryTypeId);
      } else {
        setTestCategoryItemExistsHandler(testCategoryTypeId);
      }
    }
  };

  const cancelCategoryItemHandler = () => {
    setEditHandler(-1, '');
    setDisableMode(false);
  };

  const CategoryContent = (
    <CategoryItem
      isEdit={isEdit}
      selectedTestCategoryType={selectedTestCategoryType}
      onChangeCategoryType={onChangeCategoryType}
      onSaveCategoryType={onSaveCategoryType}
      cancelCategoryItemHandler={cancelCategoryItemHandler}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{CategoryContent}</td>
    </tr>
  );

  let newRowClass = showAddEdit && selectedTestCategoryTypeId === 0 ? s.newRow : '';

  return (
    <div>
      <div className={s.contentHolder}>
        <Row>
          <Col sm={6} lg={8}>
            <h4 className={s.subheading}>
              <FormattedMessage id="categoryType.testTypes" defaultMessage="Test Types" />
            </h4>
          </Col>
          <Col sm={6} lg={4}>
            <Button
              className={s.addbutton}
              style={WidgetVisibility(userProfileWidgets, 'WQSP_TESTTYPE_ADDNEW')}
              onClick={addNewContentTypeHandler}
              disabled={disableMode}
            >
              + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col sm={12}>
            <Table variant className={s.innerTable}>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="wqsp.common.testType" defaultMessage="Test Type" />
                  </th>
                  <th>
                    <FormattedMessage id="categoryType.uOM" defaultMessage="Unit of Measure" />
                  </th>
                  <th>
                    <FormattedMessage id="categoryType.description" defaultMessage="Description" />
                  </th>
                  <th>
                    <FormattedMessage id="categoryType.realisticMean" defaultMessage="Default Realistic Mean" />
                  </th>
                  <th>
                    <FormattedMessage id="categoryType.realisticMax" defaultMessage="Default Realistic Max" />
                  </th>
                  <th>
                    <FormattedMessage id="categoryType.realisticLRV" defaultMessage="Default Realistic LRV" />
                  </th>
                  <th>
                    <FormattedMessage id="categoryType.aspirationalMean" defaultMessage="Default Aspirational Mean" />
                  </th>
                  <th>
                    <FormattedMessage id="categoryType.aspirationalMax" defaultMessage="Default Aspirational Max" />
                  </th>
                  <th>
                    <FormattedMessage id="categoryType.aspirationalLRV" defaultMessage="Default Aspirational LRV" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {testCategoryTypes.map(n => {
                  return (
                    <Fragment key={n.GuidIdentifier}>
                      <tr key={n.TestCategoryItemId} data-unittest="CategoryItemList">
                        <td>{n.Name}</td>
                        <td>{n.UnitOfMeasure}</td>
                        <td>{n.Description}</td>
                        <td>{n.RealisticMeanValue}</td>
                        <td>{n.RealisticMaxValue}</td>
                        <td>{n.RealisticLRV}</td>
                        <td>{n.AspirationalMeanValue}</td>
                        <td>{n.AspirationalMaxValue}</td>
                        <td>{n.AspirationalLRV}</td>
                        <td>
                          {updateAllowed && (
                            <div className={s.buttonContainer}>
                              <Button
                                variant="outline-secondary"
                                className={s.changeButton}
                                href="#"
                                onClick={fetchDataHandler.bind(this, n.TestCategoryItemId, n.GuidIdentifier)}
                                disabled={disableMode}
                                data-unittest="changeData"
                                style={WidgetVisibility(userProfileWidgets, `WQSP_TESTTYPE_EDIT`)}
                              >
                                <FormattedMessage id="common.change" defaultMessage="Change" />
                              </Button>
                              <Button
                                variant="outline-secondary"
                                className={s.removeButton}
                                href="#"
                                onClick={deleteDataHandler.bind(this, n.TestCategoryItemId, n.GuidIdentifier)}
                                disabled={disableMode}
                                data-unittest="deleteData"
                                style={WidgetVisibility(userProfileWidgets, `WQSP_TESTTYPE_DELETE`)}
                              >
                                <FormattedMessage id="common.remove" defaultMessage="Remove" />
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                      {showAddEdit && selectedTestCategoryTypeIdentifier === n.GuidIdentifier && editorMain}
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
            <div className={newRowClass}>{showAddEdit && selectedTestCategoryTypeIdentifier === '' && CategoryContent}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

TestCategoryItemTable.defaultProps = {
  selectedTestCategoryType: {},
  testCategoryTypes: [],
  userProfileWidgets: [],
  showAddEdit: false
};

TestCategoryItemTable.propTypes = {
  setAddHandler: PropTypes.func.isRequired,
  setEditHandler: PropTypes.func.isRequired,
  showAddEdit: PropTypes.bool,
  selectedTestCategoryTypeId: PropTypes.number,
  selectedTestCategoryTypeIdentifier: PropTypes.string,
  onSaveCategoryType: PropTypes.func.isRequired,
  onChangeCategoryType: PropTypes.func.isRequired,
  onDeleteCategoryType: PropTypes.func.isRequired,
  updateAllowed: PropTypes.bool,
  userProfileWidgets: PropTypes.object
};

export default injectIntl(TestCategoryItemTable);
