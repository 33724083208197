import React, { useState, useEffect } from 'react';
import s from './OrganisationTranslations.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getOrganisationTranslation } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialOrganisationTranslationState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../../utils';
import Cookies from 'js-cookie';
import OperationalSetupContentTemplate from '../../OperationalSetup/OperationalSetupContentTemplate';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import Banner from '../../Banner/Banner';
import {
  describeOrganisationTranslationThunk,
  saveOrganisationTranslationThunk,
  setOrganisationTranslationChangeThunk,
  setOrganisationTranslationCleanUpThunk
} from '../../../store/actions/admin/organisationTranslations';
import uuid from 'uuid';
import { LOCALE, TRASLATION_KEYS } from '../../../constants/organisationTranslations';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const OrganisationTranslation = ({ intl }) => {
  let history = useHistory();
  let { organisationTranslationId } = useParams();

  const dispatch = useDispatch();
  const organisationTranslation = useSelector(state => getOrganisationTranslation(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});

  const backUrl = '/operational-setup/organisation-translations';
  let widgetCode = '';
  if (organisationTranslationId) {
    IsEdit = true;
    widgetCode = 'WQSP_TRANSLATIONS_EDIT';
  }

  useEffect(() => {
    if (organisationTranslationId) {
      dispatch(describeOrganisationTranslationThunk(organisationTranslationId, widgetCode));
    }
  }, [describeOrganisationTranslationThunk, dispatch, organisationTranslationId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setOrganisationTranslationCleanUpThunk(initialOrganisationTranslationState.organisationTranslation.selectedOrganisationTranslation)
      );
    };
  }, []);

  // Update redux store
  const setLocalOrganisationTranslation = currentState => {
    dispatch(setOrganisationTranslationChangeThunk(currentState));
  };

  const localeOptions = () => {
    let opData = [];
    const data = LOCALE;
    data.forEach(element => {
      Object.keys(element).forEach(key => {
        opData.push({
          label: element[key],
          value: key
        });
      });
    });

    return opData.sort(compareValues('label'));
  };

  const translationKeyOptions = () => {
    let opData = [];
    let locale = organisationTranslation.selectedOrganisationTranslation.Locale;
    const data = TRASLATION_KEYS[locale];
    data.forEach(element => {
      opData.push({
        label: element,
        value: element
      });
    });

    return opData.sort(compareValues('label'));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = organisationTranslation.selectedOrganisationTranslation;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.OrganisationTranslationId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_TRANSLATIONS_ADDNEW';
    }

    dispatch(saveOrganisationTranslationThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !organisationTranslation ||
      !organisationTranslation.selectedOrganisationTranslation ||
      !organisationTranslation.selectedOrganisationTranslation.Locale ||
      isRequired(organisationTranslation.selectedOrganisationTranslation.Locale, 1)
    ) {
      formErrors.Locale = intl.formatMessage({
        id: 'organisationTranslations.localeRequired',
        defaultMessage: 'Locale is a mandatory field'
      });
      isValid = false;
    }

    if (
      !organisationTranslation ||
      !organisationTranslation.selectedOrganisationTranslation ||
      !organisationTranslation.selectedOrganisationTranslation.TranslationKey ||
      isRequired(organisationTranslation.selectedOrganisationTranslation.TranslationKey, 1)
    ) {
      formErrors.TranslationKey = intl.formatMessage({
        id: 'organisationTranslations.translationKeyRequired',
        defaultMessage: 'Translation key is a mandatory field'
      });
      isValid = false;
    }

    if (
      !organisationTranslation ||
      !organisationTranslation.selectedOrganisationTranslation ||
      !organisationTranslation.selectedOrganisationTranslation.TranslationValue ||
      isRequired(organisationTranslation.selectedOrganisationTranslation.TranslationValue, 1)
    ) {
      formErrors.TranslationValue = intl.formatMessage({
        id: 'organisationTranslations.translationValueRequired',
        defaultMessage: 'Translation value is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalOrganisationTranslation({
      ...organisationTranslation,
      selectedOrganisationTranslation: { ...organisationTranslation.selectedOrganisationTranslation, [fieldName]: e.value }
    });
  };

  //on control value change
  const onChange = e => {
    setLocalOrganisationTranslation({
      ...organisationTranslation,
      selectedOrganisationTranslation: { ...organisationTranslation.selectedOrganisationTranslation, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (organisationTranslation && organisationTranslation.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const title =
    (organisationTranslation.selectedOrganisationTranslation
      ? organisationTranslation.selectedOrganisationTranslation.TranslationKey
      : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + title;
  } else {
    heading = intl.formatMessage({ id: 'organisationTranslations.addTranslation', defaultMessage: 'Add new translation' });
  }

  let ddlClassLocale = localformErrors && localformErrors.Locale ? s.ddlError : '';
  let ddlClassKey = localformErrors && localformErrors.TranslationKey ? s.ddlError : '';

  return (
    <div className={s.organisationTranslation}>
      {organisationTranslation.isLoading && <LoadingSpinner />}
      {organisationTranslation.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={organisationTranslation.showBanner}
        status={organisationTranslation.isOpSuccessful}
        successText={messageText}
      />
      <OperationalSetupContentTemplate selectedPage="organisationTranslations" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.organisationTranslationHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.organisationTranslationContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="locale">
                      <Form.Label>
                        <FormattedMessage id="organisationTranslations.language" defaultMessage="Language" />
                      </Form.Label>

                      <Dropdown
                        id="ddlLocale"
                        dataArray={localeOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: ddlClassLocale
                        }}
                        name="Locale"
                        onDropdownChange={e => onDropdownChange(e, 'Locale')}
                        selectedOption={localeOptions().filter(
                          option => option.value === organisationTranslation.selectedOrganisationTranslation.Locale
                        )}
                        disabled={IsEdit}
                      />
                      {localformErrors && localformErrors.Locale && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Locale}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="translationKey">
                      <Form.Label>
                        <FormattedMessage id="organisationTranslations.translationKey" defaultMessage="Translation Key" />
                      </Form.Label>

                      <Dropdown
                        id="ddlTranslationKey"
                        dataArray={translationKeyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: ddlClassKey
                        }}
                        name="TranslationKey"
                        onDropdownChange={e => onDropdownChange(e, 'TranslationKey')}
                        selectedOption={translationKeyOptions().filter(
                          option => option.value === organisationTranslation.selectedOrganisationTranslation.TranslationKey
                        )}
                        disabled={IsEdit}
                      />
                      {localformErrors && localformErrors.TranslationKey && (
                        <p role="alert" className={s.error}>
                          {localformErrors.TranslationKey}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formOrganisationTranslationValue">
                      <Form.Label>
                        <FormattedMessage id="organisationTranslations.translationValue" defaultMessage="Translation Value" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="TranslationValue"
                        onChange={onChange}
                        value={organisationTranslation.selectedOrganisationTranslation.TranslationValue || ''}
                        className={`${s.formControl} ${localformErrors && localformErrors.TranslationValue ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'organisationTranslations.translationValue',
                          defaultMessage: 'Translation Value'
                        })}
                      />
                      {localformErrors && localformErrors.TranslationValue && (
                        <p role="alert" className={s.error}>
                          {localformErrors.TranslationValue}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

OrganisationTranslation.defaultProps = {
  organisationTranslation: {
    ...initialOrganisationTranslationState
  }
};

export default injectIntl(OrganisationTranslation);
