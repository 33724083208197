import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './IncidentReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import { getDateInDDMMYYYHHMMSSFormat } from '../../utils/index';
import { getCurrentTimezone } from '../../selectors/index';
import { useHistory } from 'react-router-dom';

const IncidentReportItemsTable = ({ incidentReportItems, userProfileWidgets, docType, mainObj, referedId }) => {
  const [searchFilter, setSearchFilter] = useState('');
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const history = useHistory();
  const allowIncidentReportAdd = IsWidgetAccessible(userProfileWidgets, 'WQSP_INCIDENT_REPORT_ADDNEW');
  const allowIncidentReportUpdate = IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONREPORT_UPDATE');

  const searchHandler = value => {
    setSearchFilter(value);
  };

  let incidentReportItemsFiltered =
    (searchFilter && incidentReportItems.filter(item => item.Title.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0)) ||
    incidentReportItems;

  const onAddClick = () => {
    history.push(`/site/${mainObj.SiteId}/respond/2/incident-reports/incident-report`, {
      docType: docType,
      referedId: referedId,
      obj: mainObj
    });
  };

  const onViewClick = id => {
    history.push(`/site/${mainObj.SiteId}/respond/2/incident-reports/incident-report/${id}`);
  };

  return (
    <div className={s.incidentReportTableItems}>
      <div>
        <Row>
          <Col>
            <h4 className={s.subheading}>
              <FormattedMessage id="incidentReports.incidentReports" defaultMessage="Incident Reports" />
              <span className={s.circle}>{incidentReportItemsFiltered.length || 0}</span>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              initialText={searchFilter}
              placeHolderTextId="incidentReports.searchIncidentReport"
            />
          </Col>
          <Col xs={6} lg={8}>
            {(allowIncidentReportAdd && (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_INCIDENT_REPORT_ADDNEW')}
                onClick={onAddClick}
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            )) ||
              ''}
          </Col>
        </Row>
      </div>
      <div>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="common.title" defaultMessage="Title" />
              </th>
              <th>
                <FormattedMessage id="incidentReports.incidentDateTime" defaultMessage="Incident Date & Time" />
              </th>
              <th>
                <FormattedMessage id="incidentReports.isHACCPCritical" defaultMessage="Is HACCP Critical" />
              </th>
              {allowIncidentReportUpdate && <th></th>}
            </tr>
          </thead>
          <tbody>
            {incidentReportItemsFiltered.map(item => {
              return (
                <tr key={item.IncidentReportId} data-unittest="incidentReportList">
                  <td>{item.Title}</td>
                  <td>{getDateInDDMMYYYHHMMSSFormat(item.IncidentDateTime, currentTimezone) || ''}</td>
                  <td>
                    {item.IsHACCPCritical ? (
                      <FormattedMessage id="common.yes" defaultMessage="Yes" />
                    ) : (
                      <FormattedMessage id="common.no" defaultMessage="No" /> || ''
                    )}
                  </td>
                  {allowIncidentReportUpdate && (
                    <td>
                      <div className={s.buttonContainer}>
                        <Button
                          variant="outline-secondary"
                          className={s.viewButton}
                          href="#"
                          onClick={() => {
                            onViewClick(item.IncidentReportId);
                          }}
                          data-unittest="viewData"
                        >
                          <FormattedMessage id="common.view" defaultMessage="View" />
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

IncidentReportItemsTable.defaultProps = {
  incidentReportItems: []
};

export default injectIntl(IncidentReportItemsTable);
