import React from 'react';
import './InfinityProgress.scss';

const InfinityProgressSvg = ({ sitePathwayData, onSitePathwayChange }) => {
  const getStatusColor = statusId => {
    let color = 'grey';
    switch (statusId) {
      case 4:
        color = 'red';
        break;
      case 3:
        color = 'amber';
        break;
      case 2:
        color = 'darkBlue';
        break;
      case 1:
        color = 'lightBlue';
        break;
    }
    return color;
  };

  let planSitePathway =
    (sitePathwayData && sitePathwayData.plan && sitePathwayData.plan.Status && getStatusColor(sitePathwayData.plan.Status)) || 'grey';
  let buildSitePathway =
    (sitePathwayData && sitePathwayData.build && sitePathwayData.build.Status && getStatusColor(sitePathwayData.build.Status)) || 'grey';
  let defineSitePathway =
    (sitePathwayData && sitePathwayData.define && sitePathwayData.define.Status && getStatusColor(sitePathwayData.define.Status)) || 'grey';
  let identifySitePathway =
    (sitePathwayData && sitePathwayData.identify && sitePathwayData.identify.Status && getStatusColor(sitePathwayData.identify.Status)) ||
    'grey';
  let controlSitePathway =
    (sitePathwayData && sitePathwayData.control && sitePathwayData.control.Status && getStatusColor(sitePathwayData.control.Status)) ||
    'grey';
  let monitorSitePathway =
    (sitePathwayData && sitePathwayData.monitor && sitePathwayData.monitor.Status && getStatusColor(sitePathwayData.monitor.Status)) ||
    'grey';
  let measureSitePathway =
    (sitePathwayData && sitePathwayData.measure && sitePathwayData.measure.Status && getStatusColor(sitePathwayData.measure.Status)) ||
    'grey';
  let respondSitePathway =
    (sitePathwayData && sitePathwayData.respond && sitePathwayData.respond.Status && getStatusColor(sitePathwayData.respond.Status)) ||
    'grey';

  const onSitePathwayClick = (data, segmentName) => {
    onSitePathwayChange({ selectedSitePathway: { segmentData: data, segmentName: segmentName } });
  };

  return (
    <svg version="1.1" x={0} y={0} xmlSpace="preserve" viewBox="0 0 841.89 595.28">
      <path
        onClick={() => onSitePathwayClick(sitePathwayData.plan, 'plan')}
        className={planSitePathway}
        d="M167.29,297.42L96.64,264.6l-70.67,32.82c0-31.4,6.17-61.9,18.33-90.65c11.73-27.73,28.51-52.63,49.87-73.99
        s46.25-38.14,73.99-49.87c28.75-12.16,59.25-18.33,90.65-18.33l32.79,71.12l-32.79,70.21C208.34,205.92,167.29,246.97,167.29,297.42
        z"
      />
      <path
        onClick={() => onSitePathwayClick(sitePathwayData.respond, 'respond')}
        className={respondSitePathway}
        d="M258.79,530.25c-31.4,0-61.9-6.17-90.65-18.33c-27.73-11.73-52.63-28.51-73.99-49.87s-38.14-46.25-49.87-73.99
        c-12.16-28.75-18.33-59.25-18.33-90.65l70.67-32.82l70.65,32.82c0,50.46,41.05,91.51,91.51,91.51l-32.63,70.96L258.79,530.25z"
      />

      <path
        onClick={() => onSitePathwayClick(sitePathwayData.measure, 'measure')}
        className={measureSitePathway}
        d="M258.79,530.25l-32.63-70.37l32.63-70.96c50.46,0,91.51-41.05,91.51-91.51h141.32c0,31.4-6.17,61.9-18.33,90.65
        c-11.73,27.73-28.51,52.63-49.87,73.99c-21.36,21.36-46.25,38.14-73.99,49.87C320.69,524.09,290.19,530.25,258.79,530.25z"
      />

      <path
        onClick={() => onSitePathwayClick(sitePathwayData.monitor, 'monitor')}
        className={monitorSitePathway}
        d="M491.62,297.42H350.3c0-31.4,6.17-61.9,18.33-90.65c11.73-27.73,28.51-52.63,49.87-73.99
        c21.36-21.36,46.25-38.14,73.99-49.87c28.75-12.16,59.25-18.33,90.65-18.33l-32.46,71.19l32.46,70.14
        C532.67,205.92,491.62,246.97,491.62,297.42z"
      />

      <path
        onClick={() => onSitePathwayClick(sitePathwayData.define, 'define')}
        className={defineSitePathway}
        d="M583.13,530.25c-31.4,0-61.9-6.17-90.65-18.33c-27.73-11.73-52.63-28.51-73.99-49.87
        c-21.36-21.36-38.14-46.25-49.87-73.99c-12.16-28.75-18.33-59.25-18.33-90.65h141.32c0,50.46,41.05,91.51,91.51,91.51l33.66,68.22
        L583.13,530.25z"
      />

      <path
        onClick={() => onSitePathwayClick(sitePathwayData.identify, 'identify')}
        className={identifySitePathway}
        d="M583.13,530.25l33.66-73.11l-33.66-68.22c50.46,0,91.51-41.05,91.51-91.51l70.4-32.3l70.92,32.3
        c0,31.4-6.17,61.9-18.33,90.65c-11.73,27.73-28.51,52.63-49.87,73.99c-21.36,21.36-46.25,38.14-73.99,49.87
        C645.03,524.09,614.53,530.25,583.13,530.25z"
      />

      <path
        onClick={() => onSitePathwayClick(sitePathwayData.control, 'control')}
        className={controlSitePathway}
        d="M815.96,297.42l-70.92-32.3l0,0l-70.4,32.3c0-50.46-41.05-91.51-91.51-91.51l-32.46-70.15l32.46-71.18
        c31.4,0,61.9,6.17,90.65,18.33c27.73,11.73,52.63,28.51,73.99,49.87s38.14,46.25,49.87,73.99
        C809.8,235.52,815.96,266.02,815.96,297.42z"
      />

      <path
        onClick={() => onSitePathwayClick(sitePathwayData.build, 'build')}
        className={buildSitePathway}
        d="M491.62,297.42l-70.66,32.51l-70.66-32.51c0-50.46-41.05-91.51-91.51-91.51l32.79-70.25l-32.79-71.07
        c31.4,0,61.9,6.17,90.65,18.33c27.73,11.73,52.63,28.51,73.99,49.87c21.36,21.36,38.14,46.25,49.87,73.99
        C485.46,235.52,491.62,266.02,491.62,297.42z"
      />

      <g>
        <path
          className="border"
          d="M110.25,383.7l2.89-4.52c0.03-0.05,0.03-0.09,0-0.13l-0.9-1.38c-0.03-0.05-0.07-0.06-0.12-0.03l-4.04,2.63
            c-0.05,0.03-0.1,0.04-0.16,0.03c-0.06-0.01-0.1-0.04-0.13-0.09l-1.13-1.73c-0.03-0.05-0.04-0.1-0.03-0.16
            c0.01-0.06,0.04-0.1,0.09-0.13l10-6.52c0.05-0.03,0.1-0.04,0.16-0.03c0.06,0.01,0.1,0.04,0.13,0.09l2.76,4.23
            c0.41,0.63,0.64,1.27,0.7,1.92c0.06,0.65-0.07,1.25-0.37,1.82c-0.3,0.57-0.77,1.06-1.39,1.46c-0.67,0.44-1.36,0.64-2.05,0.62
            s-1.34-0.27-1.93-0.75c-0.05-0.04-0.1-0.03-0.13,0.02l-2.88,4.74c-0.03,0.05-0.05,0.07-0.07,0.09c-0.08,0.05-0.15,0.02-0.22-0.09
            l-1.19-1.82C110.18,383.9,110.18,383.8,110.25,383.7z M116.4,374.87l-2.53,1.65c-0.05,0.03-0.06,0.07-0.03,0.12l1.26,1.94
            c0.27,0.41,0.61,0.67,1.03,0.77c0.42,0.1,0.82,0.02,1.22-0.23c0.39-0.26,0.63-0.6,0.71-1.02c0.08-0.43-0.01-0.85-0.28-1.26
            l-1.26-1.94C116.49,374.84,116.45,374.83,116.4,374.87z"
        />
        <path
          className="border"
          d="M126.17,388.4c-0.06-0.01-0.1-0.04-0.14-0.08l-3.48-4.63c-0.04-0.05-0.08-0.05-0.12-0.02l-2.19,1.65
            c-0.05,0.04-0.05,0.08-0.02,0.12l2.28,3.04c0.04,0.05,0.05,0.1,0.04,0.16c-0.01,0.06-0.04,0.1-0.08,0.14l-1.37,1.03
            c-0.05,0.04-0.1,0.05-0.16,0.04c-0.06-0.01-0.1-0.04-0.14-0.08l-2.28-3.04c-0.04-0.05-0.08-0.05-0.12-0.02l-2.29,1.72
            c-0.05,0.04-0.05,0.08-0.02,0.12l3.48,4.63c0.04,0.05,0.05,0.1,0.04,0.16c-0.01,0.06-0.04,0.1-0.08,0.14l-1.37,1.03
            c-0.05,0.04-0.1,0.05-0.16,0.04c-0.06-0.01-0.1-0.04-0.14-0.08l-4.9-6.52c-0.04-0.05-0.05-0.1-0.04-0.16
            c0.01-0.06,0.04-0.1,0.08-0.14l9.54-7.18c0.05-0.04,0.1-0.05,0.16-0.04c0.06,0.01,0.1,0.04,0.14,0.08l4.9,6.52
            c0.04,0.05,0.05,0.1,0.04,0.16c-0.01,0.06-0.04,0.1-0.08,0.14l-1.37,1.03C126.28,388.39,126.22,388.4,126.17,388.4z"
        />
        <path
          className="border"
          d="M120.78,397.5c-0.23-0.73-0.26-1.42-0.09-2.08c0.17-0.65,0.54-1.22,1.1-1.71l0.29-0.25
            c0.04-0.04,0.1-0.06,0.15-0.05c0.06,0,0.11,0.03,0.14,0.07l1.31,1.53c0.04,0.04,0.06,0.1,0.05,0.15c0,0.06-0.03,0.11-0.07,0.14
            l-0.2,0.17c-0.36,0.31-0.52,0.73-0.48,1.27c0.04,0.54,0.3,1.09,0.78,1.66c0.41,0.47,0.81,0.74,1.21,0.8
            c0.4,0.06,0.75-0.04,1.04-0.29c0.21-0.18,0.33-0.41,0.36-0.68c0.02-0.27-0.01-0.57-0.11-0.92c-0.1-0.34-0.28-0.86-0.55-1.57
            c-0.31-0.77-0.54-1.46-0.68-2.06c-0.14-0.6-0.15-1.2-0.02-1.8c0.13-0.6,0.47-1.13,1.01-1.6c0.54-0.46,1.12-0.73,1.76-0.79
            c0.64-0.07,1.28,0.05,1.91,0.36c0.64,0.31,1.23,0.78,1.79,1.42c0.58,0.68,0.98,1.39,1.2,2.13c0.22,0.74,0.24,1.44,0.06,2.12
            c-0.18,0.67-0.55,1.25-1.12,1.74l-0.2,0.17c-0.04,0.04-0.1,0.06-0.15,0.05c-0.06,0-0.11-0.03-0.14-0.07l-1.33-1.54
            c-0.04-0.04-0.06-0.1-0.05-0.15c0-0.06,0.03-0.11,0.07-0.14l0.11-0.09c0.37-0.32,0.56-0.75,0.56-1.29c0-0.53-0.23-1.07-0.69-1.61
            c-0.36-0.42-0.73-0.67-1.12-0.75s-0.73,0.01-1.04,0.28c-0.22,0.19-0.35,0.42-0.38,0.69c-0.03,0.27,0.01,0.59,0.12,0.97
            c0.11,0.38,0.31,0.94,0.6,1.69c0.31,0.83,0.54,1.51,0.67,2.02c0.13,0.52,0.15,1.07,0.04,1.66c-0.1,0.59-0.44,1.13-1,1.62
            c-0.84,0.72-1.77,0.98-2.78,0.79c-1.02-0.2-1.98-0.82-2.89-1.88C121.44,398.96,121.01,398.24,120.78,397.5z"
        />
        <path
          className="border"
          d="M140.15,401.41c0.19,0.64,0.18,1.28,0,1.92s-0.55,1.22-1.09,1.75c-0.53,0.52-1.11,0.86-1.75,1.02
            c-0.64,0.16-1.28,0.13-1.93-0.08c-0.65-0.21-1.25-0.6-1.8-1.16l-1.76-1.79c-0.04-0.04-0.08-0.04-0.12,0l-3.21,3.16
            c-0.04,0.04-0.09,0.06-0.15,0.06c-0.06,0-0.11-0.02-0.15-0.06l-1.45-1.47c-0.04-0.04-0.06-0.09-0.06-0.15
            c0-0.06,0.02-0.11,0.06-0.15l8.52-8.39c0.04-0.04,0.09-0.06,0.15-0.06c0.06,0,0.11,0.02,0.15,0.06l3.49,3.55
            C139.6,400.17,139.97,400.77,140.15,401.41z M136.07,403.87c0.45,0.02,0.86-0.15,1.22-0.51c0.37-0.36,0.55-0.77,0.55-1.23
            c0-0.46-0.2-0.88-0.58-1.27l-1.57-1.6c-0.04-0.04-0.08-0.04-0.12,0l-2.32,2.28c-0.04,0.04-0.04,0.08,0,0.12l1.57,1.6
            C135.2,403.65,135.61,403.85,136.07,403.87z"
        />
        <path
          className="border"
          d="M136.18,413.51c-0.27-0.75-0.33-1.5-0.17-2.27c0.16-0.76,0.53-1.48,1.12-2.14l2.51-2.83
            c0.58-0.65,1.24-1.1,1.97-1.34c0.74-0.24,1.49-0.27,2.26-0.09c0.77,0.19,1.5,0.59,2.19,1.2c0.7,0.62,1.18,1.3,1.46,2.04
            c0.28,0.74,0.34,1.49,0.18,2.25c-0.15,0.76-0.52,1.47-1.1,2.12l-2.51,2.83c-0.59,0.66-1.25,1.12-1.99,1.37
            c-0.74,0.25-1.5,0.29-2.27,0.1c-0.77-0.19-1.5-0.59-2.2-1.21C136.94,414.94,136.46,414.26,136.18,413.51z M140.63,414.53
            c0.57-0.04,1.09-0.32,1.54-0.83l2.58-2.91c0.45-0.51,0.67-1.05,0.65-1.63c-0.02-0.57-0.27-1.08-0.77-1.52
            c-0.49-0.43-1.01-0.62-1.59-0.57c-0.57,0.05-1.09,0.33-1.54,0.84l-2.58,2.91c-0.45,0.51-0.67,1.05-0.65,1.62
            c0.02,0.57,0.28,1.07,0.76,1.5S140.06,414.57,140.63,414.53z"
        />
        <path
          className="border"
          d="M156.29,414.64c0.06-0.01,0.11,0.01,0.16,0.04l1.64,1.26c0.05,0.04,0.07,0.08,0.08,0.14
            c0.01,0.06-0.01,0.11-0.04,0.16l-7.26,9.48c-0.04,0.05-0.08,0.07-0.14,0.08c-0.06,0.01-0.11-0.01-0.16-0.04l-1.58-1.21
            c-0.09-0.07-0.13-0.16-0.11-0.26l0.96-8.78c0-0.04-0.01-0.07-0.03-0.08c-0.02-0.01-0.05,0-0.08,0.04l-4.5,5.94
            c-0.04,0.05-0.08,0.07-0.14,0.08c-0.06,0.01-0.11-0.01-0.16-0.04l-1.64-1.26c-0.05-0.04-0.07-0.08-0.08-0.14
            c-0.01-0.06,0.01-0.11,0.04-0.16l7.26-9.48c0.04-0.05,0.08-0.07,0.14-0.08c0.06-0.01,0.11,0.01,0.16,0.04l1.59,1.21
            c0.09,0.07,0.13,0.16,0.11,0.26l-0.96,8.75c0,0.04,0.01,0.07,0.03,0.08c0.02,0.01,0.05,0,0.08-0.04l4.5-5.9
            C156.19,414.67,156.23,414.65,156.29,414.64z"
        />
        <path
          className="border"
          d="M152.94,427.35c-0.01-0.06,0-0.11,0.03-0.16l6.57-9.97c0.03-0.05,0.08-0.08,0.13-0.09
            c0.06-0.01,0.11,0,0.16,0.03l3.69,2.43c0.74,0.49,1.3,1.04,1.68,1.67c0.38,0.63,0.57,1.28,0.57,1.95
            c-0.01,0.67-0.21,1.32-0.62,1.94l-2.68,4.07c-0.41,0.62-0.92,1.06-1.54,1.34c-0.62,0.27-1.29,0.35-2.01,0.25
            c-0.73-0.1-1.46-0.4-2.2-0.89l-3.69-2.43C152.98,427.45,152.95,427.41,152.94,427.35z M156.18,427l1.79,1.18
            c0.45,0.3,0.92,0.39,1.4,0.27c0.48-0.12,0.9-0.43,1.24-0.94l2.23-3.39c0.34-0.51,0.47-1.01,0.39-1.5c-0.08-0.49-0.36-0.89-0.83-1.2
            l-1.77-1.17c-0.05-0.03-0.09-0.02-0.12,0.02l-4.35,6.61C156.13,426.93,156.14,426.97,156.18,427z"
        />
      </g>
      <g>
        <path
          className="border"
          d="M134.25,185.69c0.62-0.26,1.25-0.33,1.91-0.22c0.66,0.11,1.28,0.4,1.87,0.88c0.58,0.47,0.98,1,1.21,1.62
            c0.23,0.62,0.28,1.26,0.14,1.93c-0.14,0.67-0.45,1.31-0.95,1.93l-1.57,1.96c-0.04,0.05-0.03,0.09,0.01,0.12l3.51,2.82
            c0.05,0.04,0.07,0.08,0.08,0.14s-0.01,0.11-0.05,0.16l-1.29,1.61c-0.04,0.05-0.08,0.07-0.14,0.08c-0.06,0.01-0.11-0.01-0.16-0.05
            l-9.33-7.49c-0.05-0.04-0.07-0.08-0.08-0.14c-0.01-0.06,0.01-0.11,0.05-0.16l3.12-3.88C133.08,186.38,133.63,185.95,134.25,185.69z
             M137.16,189.46c-0.04-0.45-0.25-0.84-0.65-1.15c-0.4-0.32-0.83-0.46-1.29-0.4c-0.45,0.06-0.85,0.3-1.19,0.72l-1.4,1.75
            c-0.04,0.05-0.03,0.09,0.01,0.12l2.53,2.03c0.05,0.04,0.09,0.03,0.12-0.01l1.4-1.75C137.05,190.35,137.2,189.91,137.16,189.46z"
        />
        <path
          className="border"
          d="M145.59,190.59c-0.06,0-0.11-0.02-0.15-0.06l-8.84-8.03c-0.04-0.04-0.07-0.09-0.07-0.15
            c0-0.06,0.02-0.11,0.06-0.15l1.39-1.53c0.04-0.04,0.09-0.07,0.15-0.07c0.06,0,0.11,0.02,0.15,0.06l7.35,6.68
            c0.04,0.04,0.08,0.04,0.12-0.01l3.89-4.27c0.04-0.04,0.09-0.07,0.15-0.07c0.06,0,0.11,0.02,0.15,0.06l1.27,1.15
            c0.04,0.04,0.07,0.09,0.07,0.15c0,0.06-0.02,0.11-0.06,0.15l-5.48,6.03C145.7,190.56,145.65,190.59,145.59,190.59z"
        />
        <path
          className="border"
          d="M157.87,177.74l-1.58-0.88c-0.05-0.02-0.09-0.01-0.11,0.01l-3.13,3.01c-0.02,0.02-0.03,0.06-0.01,0.11
            l0.83,1.6c0.06,0.11,0.04,0.2-0.04,0.29l-1.62,1.56c-0.05,0.05-0.1,0.07-0.16,0.07c-0.05-0.01-0.1-0.05-0.14-0.12l-5.53-11.25
            c-0.06-0.11-0.04-0.2,0.04-0.29l2-1.92c0.08-0.08,0.18-0.09,0.29-0.03l11.03,5.96c0.02,0.01,0.05,0.03,0.07,0.05
            c0.07,0.08,0.06,0.16-0.03,0.25l-1.62,1.56C158.07,177.78,157.98,177.8,157.87,177.74z M152.15,178.06l2.24-2.16
            c0.05-0.05,0.04-0.09-0.02-0.13l-4.78-2.68c-0.04-0.03-0.07-0.03-0.08-0.01c-0.01,0.02-0.01,0.05,0,0.08l2.52,4.86
            C152.07,178.09,152.11,178.1,152.15,178.06z"
        />
        <path
          className="border"
          d="M158.54,161.09c0.01-0.06,0.03-0.11,0.08-0.14l1.59-1.33c0.04-0.04,0.1-0.05,0.15-0.05
            c0.06,0.01,0.11,0.03,0.14,0.08l7.66,9.16c0.04,0.05,0.05,0.1,0.05,0.15c-0.01,0.06-0.03,0.11-0.08,0.14l-1.53,1.28
            c-0.09,0.08-0.18,0.09-0.28,0.05l-8.36-2.87c-0.04-0.01-0.07-0.01-0.08,0.01c-0.01,0.02-0.01,0.05,0.02,0.08l4.81,5.7
            c0.04,0.05,0.05,0.1,0.05,0.15c-0.01,0.06-0.03,0.11-0.08,0.14l-1.59,1.33c-0.05,0.04-0.1,0.05-0.16,0.05
            c-0.06,0-0.11-0.03-0.14-0.08l-7.66-9.16c-0.04-0.05-0.05-0.1-0.05-0.16c0-0.06,0.03-0.11,0.08-0.14l1.53-1.28
            c0.09-0.08,0.18-0.09,0.28-0.05l8.32,2.85c0.04,0.01,0.07,0.01,0.08-0.01c0.01-0.02,0.01-0.05-0.02-0.08l-4.77-5.68
            C158.55,161.2,158.53,161.15,158.54,161.09z"
        />
      </g>
      <g>
        <path
          className="border"
          d="M359.9,178.1c0.63,1.09,0.58,2.2-0.15,3.33c-0.62,0.96-1.41,1.48-2.38,1.54c-0.97,0.07-1.98-0.24-3.04-0.92
            l-3.85-2.48c-0.05-0.03-0.08-0.08-0.09-0.13c-0.01-0.06,0-0.11,0.03-0.16l6.47-10.04c0.03-0.05,0.08-0.08,0.13-0.09
            s0.11,0,0.16,0.03l3.73,2.4c2.35,1.51,2.91,3.22,1.68,5.12c-0.64,0.99-1.51,1.43-2.63,1.31C359.87,178,359.85,178.03,359.9,178.1z
             M356.78,180.58c0.41-0.07,0.74-0.3,1-0.71c0.26-0.41,0.34-0.81,0.23-1.21s-0.39-0.75-0.86-1.05l-1.84-1.19
            c-0.05-0.03-0.09-0.02-0.12,0.03l-1.59,2.47c-0.03,0.05-0.02,0.09,0.03,0.12l1.86,1.2C355.94,180.53,356.38,180.64,356.78,180.58z
             M357.88,172.28l-1.49,2.32c-0.03,0.05-0.02,0.09,0.03,0.12l1.74,1.12c0.47,0.3,0.9,0.42,1.29,0.37s0.72-0.27,0.96-0.64
            c0.25-0.39,0.32-0.77,0.2-1.16c-0.11-0.39-0.4-0.73-0.87-1.03l-1.74-1.12C357.96,172.22,357.91,172.23,357.88,172.28z"
        />
        <path
          className="border"
          d="M361.22,186.27c-0.34-0.69-0.48-1.41-0.4-2.16c0.07-0.74,0.36-1.45,0.86-2.12l4.8-6.47
            c0.03-0.05,0.08-0.08,0.14-0.08c0.06-0.01,0.11,0,0.16,0.04l1.66,1.23c0.05,0.03,0.08,0.08,0.08,0.14c0.01,0.06,0,0.11-0.04,0.16
            l-4.81,6.49c-0.37,0.5-0.52,1.02-0.44,1.56c0.08,0.54,0.37,0.99,0.87,1.36c0.5,0.37,1.02,0.52,1.56,0.44
            c0.54-0.08,0.99-0.37,1.36-0.87l4.81-6.49c0.03-0.05,0.08-0.08,0.14-0.08c0.06-0.01,0.11,0,0.16,0.04l1.66,1.23
            c0.05,0.03,0.08,0.08,0.08,0.14c0.01,0.06,0,0.11-0.04,0.16l-4.8,6.47c-0.5,0.67-1.09,1.15-1.78,1.44
            c-0.69,0.29-1.42,0.37-2.18,0.24c-0.76-0.13-1.51-0.46-2.24-1S361.57,186.96,361.22,186.27z"
        />
        <path
          className="border"
          d="M368.13,191.95c-0.01-0.06,0.01-0.11,0.05-0.16l7.55-9.25c0.04-0.05,0.08-0.07,0.14-0.08
            c0.06-0.01,0.11,0.01,0.16,0.05l1.6,1.31c0.05,0.04,0.07,0.08,0.08,0.14c0.01,0.06-0.01,0.11-0.05,0.16l-7.55,9.25
            c-0.04,0.05-0.08,0.07-0.14,0.08c-0.06,0.01-0.11-0.01-0.15-0.05l-1.6-1.31C368.16,192.06,368.14,192.01,368.13,191.95z"
        />
        <path
          className="border"
          d="M371.65,194.81c0-0.06,0.01-0.11,0.05-0.15l7.95-8.91c0.04-0.04,0.09-0.07,0.15-0.07
            c0.06,0,0.11,0.01,0.15,0.05l1.54,1.38c0.04,0.04,0.07,0.09,0.07,0.15c0,0.06-0.01,0.11-0.05,0.15l-6.61,7.41
            c-0.04,0.04-0.04,0.09,0.01,0.12l4.31,3.84c0.04,0.04,0.07,0.09,0.07,0.15c0,0.06-0.01,0.11-0.05,0.15l-1.14,1.28
            c-0.04,0.04-0.09,0.07-0.15,0.07c-0.06,0-0.11-0.02-0.15-0.05l-6.08-5.42C371.68,194.91,371.65,194.86,371.65,194.81z"
        />
        <path
          className="border"
          d="M379.02,201.45c0-0.06,0.02-0.11,0.06-0.15l8.49-8.4c0.04-0.04,0.09-0.06,0.15-0.06
            c0.06,0,0.11,0.02,0.15,0.06l3.11,3.14c0.62,0.63,1.05,1.29,1.3,1.98c0.25,0.69,0.3,1.37,0.15,2.02c-0.15,0.66-0.48,1.25-1.01,1.77
            l-3.47,3.43c-0.53,0.52-1.12,0.85-1.78,0.99c-0.66,0.14-1.33,0.08-2.02-0.17c-0.69-0.25-1.35-0.69-1.97-1.32l-3.11-3.14
            C379.04,201.56,379.02,201.51,379.02,201.45z M382.27,201.78l1.5,1.52c0.38,0.38,0.82,0.57,1.31,0.55c0.5-0.02,0.97-0.24,1.41-0.66
            l2.89-2.86c0.43-0.43,0.66-0.89,0.69-1.39c0.02-0.5-0.16-0.94-0.56-1.35l-1.49-1.51c-0.04-0.04-0.08-0.04-0.12,0l-5.63,5.57
            C382.23,201.7,382.23,201.74,382.27,201.78z"
        />
      </g>
      <g>
        <path
          className="border"
          d="M450.3,393.42c0.01-0.06,0.04-0.1,0.08-0.14l9.61-7.08c0.05-0.03,0.1-0.05,0.16-0.04
            c0.06,0.01,0.1,0.04,0.14,0.08l2.62,3.56c0.52,0.71,0.86,1.43,1,2.15c0.14,0.72,0.1,1.4-0.14,2.03c-0.24,0.63-0.66,1.16-1.25,1.6
            l-3.93,2.89c-0.6,0.44-1.23,0.68-1.9,0.72c-0.67,0.04-1.33-0.11-1.98-0.46c-0.65-0.35-1.23-0.88-1.75-1.59l-2.62-3.56
            C450.31,393.53,450.29,393.47,450.3,393.42z M453.47,394.21l1.27,1.72c0.32,0.44,0.73,0.68,1.22,0.73c0.49,0.05,0.99-0.1,1.49-0.45
            l3.27-2.41c0.49-0.36,0.79-0.79,0.88-1.28c0.1-0.49-0.02-0.96-0.36-1.41l-1.26-1.71c-0.03-0.05-0.08-0.05-0.12-0.02l-6.37,4.69
            C453.44,394.12,453.43,394.16,453.47,394.21z"
        />
        <path
          className="border"
          d="M470.44,402.4c-0.06,0-0.11-0.03-0.14-0.08l-3.74-4.42c-0.04-0.05-0.08-0.05-0.12-0.01l-2.09,1.77
            c-0.05,0.04-0.05,0.08-0.01,0.12l2.45,2.9c0.04,0.04,0.05,0.1,0.05,0.15c0,0.06-0.03,0.11-0.07,0.14l-1.31,1.11
            c-0.04,0.04-0.1,0.05-0.15,0.05c-0.06,0-0.11-0.03-0.14-0.07l-2.45-2.9c-0.04-0.04-0.08-0.05-0.12-0.01l-2.18,1.85
            c-0.05,0.04-0.05,0.08-0.01,0.12l3.74,4.42c0.04,0.04,0.05,0.1,0.05,0.15c0,0.06-0.03,0.11-0.07,0.14l-1.31,1.11
            c-0.04,0.04-0.1,0.05-0.15,0.05c-0.06,0-0.11-0.03-0.14-0.07l-5.27-6.23c-0.04-0.05-0.05-0.1-0.05-0.15c0-0.06,0.03-0.11,0.07-0.14
            l9.12-7.71c0.04-0.04,0.1-0.05,0.15-0.05c0.06,0,0.11,0.03,0.14,0.07l5.27,6.23c0.04,0.04,0.05,0.1,0.05,0.15
            c0,0.06-0.03,0.11-0.07,0.14l-1.31,1.11C470.55,402.39,470.49,402.41,470.44,402.4z"
        />
        <path
          className="border"
          d="M477.43,409.72c-0.06,0-0.11-0.02-0.15-0.07l-4.01-4.16c-0.04-0.04-0.08-0.04-0.12,0l-1.97,1.9
            c-0.04,0.04-0.04,0.08,0,0.12l2.63,2.74c0.04,0.04,0.06,0.09,0.06,0.15c0,0.06-0.02,0.11-0.07,0.15l-1.23,1.19
            c-0.04,0.04-0.09,0.06-0.15,0.06c-0.06,0-0.11-0.02-0.15-0.07l-2.63-2.74c-0.04-0.04-0.08-0.04-0.12,0l-3.51,3.38
            c-0.04,0.04-0.09,0.06-0.15,0.06c-0.06,0-0.11-0.02-0.15-0.06l-1.43-1.49c-0.04-0.04-0.06-0.09-0.06-0.15
            c0-0.06,0.02-0.11,0.06-0.15l8.61-8.28c0.04-0.04,0.09-0.06,0.15-0.06c0.06,0,0.11,0.02,0.15,0.07l5.65,5.87
            c0.04,0.04,0.06,0.09,0.06,0.15c0,0.06-0.02,0.11-0.07,0.15l-1.23,1.19C477.54,409.7,477.49,409.72,477.43,409.72z"
        />
        <path
          className="border"
          d="M471.39,418.02c0-0.06,0.02-0.11,0.06-0.15l8.22-8.67c0.04-0.04,0.09-0.06,0.15-0.07
            c0.06,0,0.11,0.02,0.15,0.06l1.5,1.42c0.04,0.04,0.06,0.09,0.07,0.15c0,0.06-0.02,0.11-0.06,0.15l-8.22,8.67
            c-0.04,0.04-0.09,0.06-0.15,0.07c-0.06,0-0.11-0.02-0.15-0.06l-1.5-1.42C471.41,418.12,471.39,418.07,471.39,418.02z"
        />
        <path
          className="border"
          d="M488.38,416.81c0.06,0,0.11,0.01,0.15,0.05l1.57,1.34c0.04,0.04,0.07,0.09,0.07,0.14
            c0,0.06-0.01,0.11-0.05,0.15l-7.75,9.08c-0.04,0.04-0.09,0.07-0.14,0.07c-0.06,0-0.11-0.01-0.15-0.05l-1.52-1.3
            c-0.09-0.08-0.12-0.17-0.1-0.27l1.43-8.72c0.01-0.04,0-0.07-0.02-0.08c-0.02-0.01-0.05,0-0.08,0.04l-4.81,5.69
            c-0.04,0.05-0.09,0.07-0.14,0.07c-0.06,0-0.11-0.01-0.15-0.05l-1.57-1.34c-0.04-0.04-0.07-0.09-0.07-0.14
            c0-0.06,0.01-0.11,0.05-0.15l7.75-9.08c0.04-0.04,0.09-0.07,0.14-0.07c0.06,0,0.11,0.01,0.15,0.05l1.52,1.3
            c0.09,0.08,0.12,0.17,0.1,0.27l-1.42,8.68c0,0.04,0,0.07,0.03,0.08c0.02,0.01,0.05,0,0.08-0.04l4.8-5.65
            C488.28,416.83,488.32,416.81,488.38,416.81z"
        />
        <path
          className="border"
          d="M497.23,426.12c-0.06,0.01-0.11,0-0.16-0.04l-4.66-3.45c-0.05-0.04-0.09-0.03-0.12,0.02l-1.63,2.2
            c-0.03,0.05-0.03,0.09,0.02,0.12l3.05,2.26c0.05,0.04,0.07,0.08,0.08,0.14c0.01,0.06,0,0.11-0.04,0.16l-1.02,1.38
            c-0.04,0.05-0.08,0.08-0.14,0.08c-0.06,0.01-0.11,0-0.16-0.04l-3.05-2.26c-0.05-0.04-0.09-0.03-0.12,0.02l-1.7,2.3
            c-0.04,0.05-0.03,0.09,0.02,0.12l4.66,3.45c0.05,0.04,0.08,0.08,0.08,0.14c0.01,0.06,0,0.11-0.04,0.16l-1.02,1.38
            c-0.04,0.05-0.08,0.08-0.14,0.08c-0.06,0.01-0.11,0-0.16-0.04l-6.56-4.86c-0.05-0.03-0.08-0.08-0.08-0.14
            c-0.01-0.06,0-0.11,0.04-0.16l7.11-9.6c0.03-0.05,0.08-0.08,0.14-0.08c0.06-0.01,0.11,0,0.16,0.04l6.56,4.86
            c0.05,0.04,0.07,0.08,0.08,0.14c0.01,0.06,0,0.11-0.04,0.16l-1.02,1.38C497.34,426.08,497.29,426.11,497.23,426.12z"
        />
      </g>
      <g>
        <path
          className="border"
          d="M476.41,161.61l1.62-1.28c0.05-0.04,0.1-0.05,0.16-0.04c0.06,0.01,0.1,0.03,0.14,0.08l7.41,9.37
            c0.04,0.05,0.05,0.1,0.04,0.16c-0.01,0.06-0.03,0.11-0.08,0.14l-1.62,1.28c-0.05,0.04-0.1,0.05-0.16,0.04
            c-0.06-0.01-0.1-0.03-0.14-0.08l-4.83-6.11c-0.03-0.04-0.05-0.05-0.07-0.03c-0.02,0.02-0.03,0.04-0.02,0.09l0.35,3.46
            c0.01,0.11-0.02,0.2-0.11,0.26l-0.82,0.65c-0.08,0.07-0.18,0.08-0.28,0.04l-3.29-1.14c-0.04-0.01-0.07-0.01-0.08,0.01
            c-0.02,0.02-0.01,0.05,0.02,0.08l4.82,6.1c0.04,0.05,0.05,0.1,0.04,0.16c-0.01,0.06-0.03,0.1-0.08,0.14l-1.62,1.28
            c-0.05,0.04-0.1,0.05-0.16,0.04c-0.06-0.01-0.1-0.03-0.14-0.08l-7.41-9.36c-0.04-0.05-0.05-0.1-0.04-0.16
            c0.01-0.06,0.03-0.1,0.08-0.14l1.62-1.28c0.08-0.07,0.18-0.08,0.28-0.04l4.63,1.58c0.07,0.03,0.1,0.01,0.08-0.07l-0.46-4.88
            C476.29,161.76,476.32,161.67,476.41,161.61z"
        />
        <path
          className="border"
          d="M488.88,167.34c-0.79,0.08-1.54-0.04-2.24-0.38c-0.7-0.34-1.3-0.88-1.8-1.6l-2.13-3.12
            c-0.49-0.72-0.77-1.47-0.82-2.24c-0.06-0.77,0.1-1.51,0.47-2.21c0.37-0.7,0.93-1.31,1.69-1.83c0.77-0.52,1.55-0.83,2.33-0.92
            c0.79-0.09,1.53,0.03,2.23,0.37c0.7,0.34,1.29,0.86,1.79,1.58l2.13,3.12c0.5,0.73,0.78,1.49,0.84,2.27
            c0.06,0.78-0.09,1.53-0.46,2.23c-0.37,0.7-0.94,1.31-1.71,1.84C490.44,166.95,489.67,167.25,488.88,167.34z M490.95,163.27
            c0.1-0.57-0.04-1.13-0.43-1.7l-2.19-3.21c-0.39-0.56-0.86-0.91-1.42-1.03c-0.56-0.12-1.11,0-1.66,0.37
            c-0.54,0.37-0.85,0.83-0.94,1.4c-0.09,0.57,0.06,1.13,0.44,1.7l2.19,3.21c0.39,0.56,0.86,0.91,1.41,1.02
            c0.56,0.12,1.1-0.01,1.64-0.37C490.53,164.31,490.85,163.84,490.95,163.27z"
        />
        <path
          className="border"
          d="M496.31,148.49c0.02-0.06,0.05-0.1,0.1-0.13l1.78-1.05c0.05-0.03,0.1-0.04,0.16-0.02
            c0.06,0.02,0.1,0.05,0.13,0.1l6.05,10.3c0.03,0.05,0.04,0.1,0.02,0.16c-0.01,0.06-0.05,0.1-0.1,0.13l-1.72,1.01
            c-0.1,0.06-0.2,0.06-0.29,0l-7.78-4.2c-0.04-0.02-0.07-0.02-0.08,0c-0.02,0.02-0.01,0.05,0.01,0.09l3.81,6.41
            c0.03,0.05,0.04,0.1,0.02,0.16c-0.01,0.06-0.05,0.1-0.1,0.13l-1.78,1.05c-0.05,0.03-0.1,0.04-0.16,0.02
            c-0.06-0.01-0.1-0.05-0.13-0.1l-6.05-10.29c-0.03-0.05-0.04-0.1-0.02-0.16c0.01-0.06,0.05-0.1,0.1-0.13l1.72-1.01
            c0.1-0.06,0.2-0.06,0.29,0l7.74,4.18c0.04,0.02,0.07,0.02,0.08,0c0.02-0.02,0.01-0.05-0.01-0.09l-3.78-6.39
            C496.31,148.6,496.3,148.55,496.31,148.49z"
        />
        <path
          className="border"
          d="M506.47,156.89c-0.06-0.02-0.1-0.05-0.12-0.1l-5.54-10.58c-0.03-0.05-0.03-0.11-0.02-0.16
            c0.02-0.06,0.05-0.1,0.1-0.12l1.83-0.96c0.05-0.03,0.11-0.03,0.16-0.02c0.06,0.02,0.1,0.05,0.12,0.1l5.54,10.58
            c0.03,0.05,0.03,0.11,0.02,0.16s-0.05,0.1-0.1,0.12l-1.83,0.96C506.58,156.9,506.53,156.9,506.47,156.89z"
        />
        <path
          className="border"
          d="M513.09,140.03c0.06,0.02,0.1,0.06,0.12,0.11l0.73,1.57c0.02,0.05,0.03,0.11,0.01,0.16
            c-0.02,0.05-0.06,0.09-0.11,0.12l-2.86,1.34c-0.05,0.02-0.07,0.06-0.04,0.12l4.2,8.98c0.03,0.05,0.03,0.11,0.01,0.16
            c-0.02,0.06-0.06,0.1-0.11,0.12l-1.87,0.88c-0.05,0.02-0.11,0.03-0.16,0.01c-0.05-0.02-0.09-0.06-0.12-0.11l-4.2-8.98
            c-0.02-0.05-0.06-0.07-0.12-0.04l-2.78,1.3c-0.05,0.02-0.11,0.03-0.16,0.01c-0.05-0.02-0.09-0.06-0.12-0.11l-0.73-1.57
            c-0.02-0.05-0.03-0.11-0.01-0.16c0.02-0.05,0.06-0.09,0.11-0.12l8.06-3.77C512.98,140.01,513.04,140.01,513.09,140.03z"
        />
        <path
          className="border"
          d="M520.91,149.72c-0.79-0.09-1.49-0.38-2.1-0.87c-0.61-0.49-1.08-1.14-1.4-1.96l-1.39-3.52
            c-0.32-0.81-0.42-1.6-0.31-2.37s0.43-1.45,0.94-2.05c0.52-0.6,1.2-1.07,2.05-1.41c0.87-0.34,1.69-0.47,2.48-0.38
            c0.79,0.09,1.49,0.37,2.09,0.85c0.61,0.48,1.07,1.13,1.39,1.94l1.39,3.52c0.33,0.82,0.43,1.62,0.32,2.4
            c-0.11,0.78-0.42,1.47-0.94,2.07c-0.52,0.6-1.21,1.08-2.07,1.42C522.52,149.69,521.7,149.81,520.91,149.72z M523.83,146.21
            c0.22-0.53,0.21-1.11-0.05-1.75l-1.43-3.61c-0.25-0.64-0.64-1.08-1.16-1.32c-0.52-0.24-1.09-0.24-1.7,0
            c-0.6,0.24-1.01,0.62-1.22,1.16c-0.21,0.53-0.19,1.12,0.06,1.75l1.43,3.61c0.25,0.64,0.64,1.07,1.15,1.31s1.08,0.24,1.68,0
            C523.19,147.13,523.61,146.74,523.83,146.21z"
        />
        <path
          className="border"
          d="M535.43,144.74l-3.6-3.98c-0.04-0.04-0.08-0.05-0.12-0.03l-1.56,0.5c-0.06,0.02-0.08,0.06-0.06,0.11l1.48,4.59
            c0.02,0.06,0.01,0.11-0.01,0.16c-0.03,0.05-0.07,0.09-0.12,0.1l-1.97,0.63c-0.06,0.02-0.11,0.01-0.16-0.01
            c-0.05-0.03-0.09-0.07-0.1-0.12l-3.66-11.37c-0.02-0.06-0.01-0.11,0.01-0.16c0.03-0.05,0.07-0.09,0.12-0.1l4.81-1.55
            c0.72-0.23,1.4-0.29,2.03-0.17c0.64,0.12,1.19,0.4,1.66,0.84c0.47,0.44,0.82,1.02,1.05,1.72c0.24,0.76,0.26,1.48,0.06,2.14
            c-0.21,0.67-0.62,1.22-1.23,1.67c-0.05,0.04-0.05,0.09-0.01,0.13l3.82,4.02c0.04,0.04,0.06,0.07,0.07,0.09
            c0.03,0.09-0.02,0.15-0.14,0.19l-2.07,0.67C535.6,144.85,535.51,144.83,535.43,144.74z M528.52,136.47l0.92,2.88
            c0.02,0.06,0.05,0.08,0.11,0.06l2.2-0.71c0.47-0.15,0.81-0.41,1.02-0.79c0.21-0.38,0.24-0.79,0.09-1.24
            c-0.14-0.45-0.41-0.77-0.8-0.96c-0.39-0.19-0.82-0.21-1.29-0.06l-2.2,0.71C528.52,136.38,528.51,136.42,528.52,136.47z"
        />
      </g>
      <g>
        <path
          className="border"
          d="M652.99,160.39c-0.38-0.68-0.55-1.4-0.51-2.15c0.04-0.75,0.3-1.48,0.77-2.18l2.25-3.4
            c0.47-0.71,1.04-1.23,1.71-1.56c0.67-0.33,1.39-0.45,2.17-0.37c0.77,0.09,1.55,0.39,2.31,0.89c0.76,0.5,1.33,1.08,1.72,1.74
            c0.39,0.66,0.57,1.35,0.55,2.08c-0.02,0.73-0.26,1.43-0.71,2.11c-0.06,0.09-0.15,0.1-0.28,0.04l-1.79-1.04l-0.03-0.02
            c-0.1-0.06-0.11-0.15-0.04-0.26c0.34-0.52,0.46-1.04,0.35-1.57c-0.11-0.53-0.43-0.96-0.95-1.3c-0.53-0.35-1.06-0.47-1.59-0.37
            c-0.53,0.1-0.96,0.42-1.31,0.94l-2.35,3.55c-0.34,0.51-0.45,1.03-0.34,1.55c0.11,0.53,0.43,0.96,0.96,1.31
            c0.52,0.34,1.04,0.47,1.57,0.36c0.53-0.1,0.96-0.41,1.3-0.92c0.07-0.11,0.17-0.12,0.28-0.05l1.68,1.21
            c0.05,0.03,0.08,0.07,0.1,0.13c0.01,0.05,0.01,0.1-0.02,0.14c-0.45,0.68-1,1.17-1.67,1.48c-0.66,0.31-1.38,0.42-2.14,0.32
            c-0.76-0.1-1.52-0.39-2.28-0.89C653.94,161.67,653.37,161.07,652.99,160.39z"
        />
        <path
          className="border"
          d="M661.72,166.42c-0.33-0.72-0.45-1.47-0.35-2.25c0.1-0.77,0.42-1.51,0.95-2.22l2.28-3.01
            c0.53-0.69,1.15-1.19,1.86-1.49c0.72-0.3,1.46-0.39,2.25-0.26c0.78,0.13,1.54,0.47,2.27,1.02c0.74,0.56,1.28,1.2,1.61,1.92
            s0.45,1.46,0.36,2.23c-0.1,0.77-0.41,1.5-0.93,2.2l-2.28,3.01c-0.53,0.7-1.16,1.21-1.88,1.52c-0.72,0.31-1.48,0.4-2.26,0.27
            c-0.78-0.13-1.54-0.47-2.29-1.04C662.58,167.79,662.05,167.15,661.72,166.42z M666.23,167.1c0.57-0.09,1.06-0.4,1.47-0.95l2.35-3.1
            c0.41-0.54,0.59-1.1,0.53-1.67c-0.06-0.57-0.36-1.06-0.88-1.45c-0.52-0.39-1.06-0.54-1.62-0.45c-0.57,0.09-1.06,0.41-1.47,0.96
            l-2.35,3.1c-0.41,0.54-0.59,1.1-0.52,1.67c0.06,0.57,0.36,1.05,0.87,1.44C665.12,167.03,665.66,167.18,666.23,167.1z"
        />
        <path
          className="border"
          d="M681.96,167.51c0.06,0,0.11,0.01,0.15,0.05l1.56,1.36c0.04,0.04,0.07,0.09,0.07,0.15
            c0,0.06-0.01,0.11-0.05,0.15l-7.84,9.01c-0.04,0.04-0.09,0.07-0.15,0.07c-0.06,0-0.11-0.01-0.15-0.05l-1.51-1.31
            c-0.09-0.08-0.12-0.17-0.09-0.27l1.51-8.71c0.01-0.04,0-0.07-0.02-0.08c-0.02-0.01-0.05,0-0.08,0.04l-4.87,5.64
            c-0.04,0.04-0.09,0.07-0.15,0.07c-0.06,0-0.11-0.01-0.15-0.05l-1.56-1.36c-0.04-0.04-0.07-0.09-0.07-0.14
            c0-0.06,0.01-0.11,0.05-0.15l7.84-9.01c0.04-0.04,0.09-0.07,0.15-0.07c0.06,0,0.11,0.01,0.15,0.05l1.51,1.31
            c0.09,0.08,0.12,0.17,0.09,0.27l-1.51,8.67c-0.01,0.04,0,0.07,0.02,0.08c0.02,0.01,0.05,0,0.08-0.04l4.86-5.61
            C681.86,167.54,681.91,167.51,681.96,167.51z"
        />
        <path
          className="border"
          d="M691.96,177.12c0,0.06-0.02,0.11-0.06,0.15l-1.22,1.23c-0.04,0.04-0.09,0.06-0.15,0.06
            c-0.06,0-0.11-0.02-0.15-0.06l-2.24-2.23c-0.04-0.04-0.08-0.04-0.12,0l-6.98,7.03c-0.04,0.04-0.09,0.06-0.15,0.06
            s-0.11-0.02-0.15-0.06l-1.47-1.46c-0.04-0.04-0.06-0.09-0.06-0.15c0-0.06,0.02-0.11,0.06-0.15l6.98-7.03
            c0.04-0.04,0.04-0.08,0-0.12l-2.18-2.17c-0.04-0.04-0.06-0.09-0.06-0.15c0-0.06,0.02-0.11,0.06-0.15l1.22-1.23
            c0.04-0.04,0.09-0.06,0.15-0.06c0.06,0,0.11,0.02,0.15,0.06l6.32,6.27C691.94,177.01,691.96,177.06,691.96,177.12z"
        />
        <path
          className="border"
          d="M688.75,191.7l2.19-4.9c0.02-0.05,0.01-0.09-0.02-0.13l-1.09-1.23c-0.04-0.04-0.08-0.05-0.12-0.01l-3.61,3.2
            c-0.04,0.04-0.1,0.06-0.15,0.05c-0.06,0-0.11-0.03-0.15-0.07l-1.37-1.55c-0.04-0.04-0.06-0.09-0.05-0.15
            c0-0.06,0.03-0.11,0.07-0.15l8.93-7.93c0.04-0.04,0.09-0.06,0.15-0.05c0.06,0,0.11,0.03,0.15,0.07l3.36,3.78
            c0.5,0.56,0.83,1.16,0.98,1.79c0.15,0.63,0.12,1.25-0.1,1.86c-0.22,0.61-0.6,1.16-1.16,1.65c-0.6,0.53-1.24,0.84-1.94,0.92
            c-0.69,0.08-1.37-0.07-2.02-0.45c-0.06-0.03-0.1-0.01-0.13,0.04l-2.14,5.12c-0.02,0.05-0.04,0.08-0.06,0.1
            c-0.07,0.06-0.15,0.05-0.23-0.05l-1.44-1.63C688.72,191.9,688.7,191.8,688.75,191.7z M693.53,182.05l-2.26,2.01
            c-0.04,0.04-0.05,0.08-0.01,0.12l1.54,1.73c0.33,0.37,0.71,0.57,1.13,0.61c0.43,0.04,0.82-0.1,1.17-0.41
            c0.35-0.31,0.54-0.69,0.55-1.12c0.02-0.43-0.14-0.83-0.47-1.2l-1.54-1.73C693.61,182.01,693.57,182.01,693.53,182.05z"
        />
        <path
          className="border"
          d="M692.82,196.25c-0.13-0.78-0.05-1.54,0.24-2.26c0.3-0.72,0.79-1.36,1.49-1.9l2.98-2.32
            c0.69-0.54,1.42-0.86,2.18-0.96c0.77-0.11,1.51,0,2.24,0.33c0.72,0.33,1.37,0.85,1.93,1.57c0.57,0.73,0.93,1.49,1.06,2.27
            c0.14,0.78,0.06,1.53-0.23,2.25c-0.29,0.72-0.78,1.35-1.47,1.88l-2.98,2.32c-0.7,0.54-1.43,0.87-2.21,0.98
            c-0.78,0.11-1.53,0.01-2.25-0.32c-0.72-0.33-1.37-0.85-1.94-1.59C693.3,197.79,692.96,197.04,692.82,196.25z M697.01,198.07
            c0.57,0.06,1.13-0.12,1.67-0.54l3.07-2.39c0.54-0.42,0.85-0.91,0.94-1.48c0.09-0.57-0.07-1.11-0.48-1.63
            c-0.4-0.51-0.88-0.79-1.46-0.85c-0.57-0.05-1.13,0.13-1.67,0.55l-3.07,2.39c-0.54,0.42-0.85,0.91-0.93,1.48
            c-0.08,0.56,0.07,1.1,0.47,1.61C695.95,197.72,696.44,198,697.01,198.07z"
        />
        <path
          className="border"
          d="M697.98,203.51c0.01-0.06,0.04-0.1,0.09-0.14l9.87-6.73c0.05-0.03,0.1-0.04,0.16-0.03
            c0.06,0.01,0.1,0.04,0.14,0.09l1.16,1.71c0.03,0.05,0.04,0.1,0.03,0.16c-0.01,0.06-0.04,0.1-0.09,0.14l-8.2,5.59
            c-0.05,0.03-0.06,0.07-0.02,0.12l3.26,4.77c0.03,0.05,0.04,0.1,0.03,0.16c-0.01,0.06-0.04,0.1-0.09,0.14l-1.42,0.97
            c-0.05,0.03-0.1,0.04-0.16,0.03c-0.06-0.01-0.1-0.04-0.14-0.09l-4.59-6.73C697.98,203.62,697.97,203.57,697.98,203.51z"
        />
      </g>
      <g>
        <path
          className="border"
          d="M673.71,435.07c-0.06-0.01-0.1-0.04-0.13-0.09l-6.4-10.08c-0.03-0.05-0.04-0.1-0.03-0.16
            c0.01-0.06,0.04-0.1,0.09-0.13l1.75-1.11c0.05-0.03,0.1-0.04,0.16-0.03c0.06,0.01,0.1,0.04,0.13,0.09l6.4,10.08
            c0.03,0.05,0.04,0.1,0.03,0.16c-0.01,0.06-0.04,0.1-0.09,0.13l-1.75,1.11C673.82,435.08,673.76,435.09,673.71,435.07z"
        />
        <path
          className="border"
          d="M677.94,432.4c-0.06-0.01-0.1-0.04-0.14-0.09l-6.89-9.76c-0.03-0.05-0.05-0.1-0.04-0.16
            c0.01-0.06,0.04-0.1,0.09-0.14l3.61-2.55c0.72-0.51,1.44-0.83,2.17-0.96c0.72-0.13,1.4-0.07,2.02,0.18
            c0.62,0.25,1.15,0.68,1.58,1.29l2.81,3.98c0.43,0.61,0.66,1.25,0.68,1.92c0.03,0.67-0.14,1.33-0.5,1.97
            c-0.36,0.64-0.91,1.21-1.63,1.72l-3.61,2.55C678.05,432.4,678,432.41,677.94,432.4z M678.8,429.25l1.75-1.23
            c0.44-0.31,0.7-0.71,0.76-1.21c0.06-0.49-0.08-0.99-0.42-1.5l-2.34-3.32c-0.35-0.5-0.77-0.8-1.26-0.91
            c-0.49-0.1-0.96,0.01-1.42,0.33l-1.73,1.22c-0.05,0.03-0.06,0.08-0.02,0.12l4.56,6.46C678.71,429.28,678.75,429.28,678.8,429.25z"
        />
        <path
          className="border"
          d="M687.34,412.45c-0.01,0.06-0.03,0.11-0.08,0.14l-4.5,3.65c-0.05,0.04-0.05,0.08-0.01,0.12l1.73,2.13
            c0.04,0.05,0.08,0.05,0.12,0.01l2.95-2.39c0.05-0.04,0.1-0.05,0.16-0.05c0.06,0.01,0.11,0.03,0.14,0.08l1.08,1.33
            c0.04,0.05,0.05,0.1,0.05,0.16c-0.01,0.06-0.03,0.11-0.08,0.14l-2.95,2.39c-0.05,0.04-0.05,0.08-0.01,0.12l1.8,2.22
            c0.04,0.05,0.08,0.05,0.12,0.01l4.5-3.65c0.05-0.04,0.1-0.05,0.16-0.05c0.06,0.01,0.11,0.03,0.14,0.08l1.08,1.33
            c0.04,0.05,0.05,0.1,0.05,0.16c-0.01,0.06-0.03,0.11-0.08,0.14l-6.33,5.15c-0.05,0.04-0.1,0.05-0.16,0.05
            c-0.06-0.01-0.11-0.03-0.14-0.08l-7.53-9.27c-0.04-0.05-0.05-0.1-0.05-0.16c0.01-0.06,0.03-0.1,0.08-0.14l6.34-5.15
            c0.05-0.04,0.1-0.05,0.16-0.05c0.06,0.01,0.11,0.03,0.14,0.08l1.08,1.33C687.33,412.34,687.34,412.39,687.34,412.45z"
        />
        <path
          className="border"
          d="M692.4,405.05c0-0.06,0.02-0.11,0.07-0.15l1.51-1.41c0.04-0.04,0.09-0.06,0.15-0.06
            c0.06,0,0.11,0.02,0.15,0.07l8.16,8.72c0.04,0.04,0.06,0.09,0.06,0.15c0,0.06-0.02,0.11-0.07,0.15l-1.46,1.36
            c-0.09,0.08-0.18,0.1-0.28,0.07l-8.51-2.4c-0.04-0.01-0.07,0-0.08,0.02c-0.01,0.02,0,0.05,0.03,0.08l5.12,5.42
            c0.04,0.04,0.06,0.09,0.06,0.15c0,0.06-0.02,0.11-0.07,0.15l-1.51,1.41c-0.04,0.04-0.09,0.06-0.15,0.06
            c-0.06,0-0.11-0.02-0.15-0.07l-8.16-8.72c-0.04-0.04-0.06-0.09-0.06-0.15c0-0.06,0.02-0.11,0.07-0.15l1.46-1.36
            c0.09-0.08,0.18-0.1,0.28-0.07l8.47,2.38c0.04,0.01,0.07,0,0.08-0.02c0.01-0.02,0-0.05-0.03-0.08l-5.08-5.41
            C692.42,405.16,692.4,405.11,692.4,405.05z"
        />
        <path
          className="border"
          d="M701.37,395.72c0.06,0,0.11,0.02,0.15,0.06l1.27,1.18c0.04,0.04,0.07,0.09,0.07,0.15
            c0,0.06-0.02,0.11-0.06,0.15l-2.15,2.32c-0.04,0.04-0.04,0.08,0,0.12l7.26,6.75c0.04,0.04,0.07,0.09,0.07,0.15
            c0,0.06-0.02,0.11-0.06,0.15l-1.41,1.51c-0.04,0.04-0.09,0.07-0.15,0.07c-0.06,0-0.11-0.02-0.15-0.06l-7.26-6.75
            c-0.04-0.04-0.08-0.04-0.12,0l-2.09,2.25c-0.04,0.04-0.09,0.07-0.15,0.07c-0.06,0-0.11-0.02-0.15-0.06l-1.27-1.18
            c-0.04-0.04-0.07-0.09-0.07-0.15c0-0.06,0.02-0.11,0.06-0.15l6.06-6.52C701.26,395.74,701.31,395.72,701.37,395.72z"
        />
        <path
          className="border"
          d="M711.64,402.46c-0.06,0-0.11-0.01-0.15-0.05l-9.12-7.71c-0.04-0.04-0.07-0.09-0.08-0.14
            c0-0.06,0.01-0.11,0.05-0.15l1.33-1.58c0.04-0.04,0.09-0.07,0.14-0.07c0.06,0,0.11,0.01,0.15,0.05l9.12,7.71
            c0.04,0.04,0.07,0.09,0.07,0.14c0,0.06-0.01,0.11-0.05,0.15l-1.33,1.58C711.74,402.43,711.7,402.45,711.64,402.46z"
        />
        <path
          className="border"
          d="M711.9,385.72c0.01,0.06-0.01,0.11-0.04,0.16l-3.52,4.58c-0.04,0.05-0.03,0.09,0.02,0.12l2.17,1.67
            c0.05,0.04,0.09,0.03,0.12-0.02l2.32-3.01c0.04-0.05,0.08-0.07,0.14-0.08c0.06-0.01,0.11,0.01,0.16,0.04l1.36,1.04
            c0.05,0.04,0.07,0.08,0.08,0.14c0.01,0.06-0.01,0.11-0.04,0.16l-2.32,3.01c-0.04,0.05-0.03,0.09,0.02,0.12l3.86,2.97
            c0.05,0.04,0.07,0.08,0.08,0.14c0.01,0.06-0.01,0.11-0.04,0.16l-1.26,1.64c-0.04,0.05-0.08,0.07-0.14,0.08
            c-0.06,0.01-0.11-0.01-0.16-0.04l-9.47-7.28c-0.05-0.04-0.07-0.08-0.08-0.14c-0.01-0.06,0.01-0.11,0.04-0.16l4.97-6.45
            c0.04-0.05,0.08-0.07,0.14-0.08c0.06-0.01,0.11,0.01,0.16,0.04l1.36,1.04C711.87,385.61,711.89,385.66,711.9,385.72z"
        />
        <path
          className="border"
          d="M722.86,387.76c-0.06,0.01-0.11,0-0.16-0.03l-3.71-2.49c-0.04-0.03-0.07-0.04-0.1-0.04l-8.1-1.04
            c-0.05-0.01-0.09-0.02-0.11-0.03c-0.08-0.05-0.08-0.13-0.01-0.24l1.24-1.85c0.07-0.1,0.16-0.14,0.27-0.12l5.13,0.78
            c0.08,0.01,0.1-0.02,0.06-0.09l-2.68-4.45c-0.06-0.1-0.06-0.2,0.01-0.29l1.26-1.88c0.05-0.07,0.09-0.1,0.14-0.1
            c0.05,0,0.1,0.04,0.15,0.11l4.03,7.12c0.01,0.02,0.04,0.05,0.08,0.07l3.71,2.49c0.05,0.03,0.08,0.08,0.09,0.13
            c0.01,0.06,0,0.11-0.03,0.16l-1.14,1.7C722.96,387.72,722.91,387.75,722.86,387.76z"
        />
      </g>
      <g>
        <path
          className="border"
          d="M284.5,447.34l2.01-0.49c0.06-0.01,0.11-0.01,0.16,0.02c0.05,0.03,0.08,0.07,0.1,0.13l2.85,11.6
            c0.01,0.06,0.01,0.11-0.02,0.16c-0.03,0.05-0.07,0.08-0.13,0.1l-2.01,0.49c-0.06,0.01-0.11,0.01-0.16-0.02
            c-0.05-0.03-0.08-0.07-0.1-0.13l-1.86-7.57c-0.01-0.05-0.03-0.07-0.05-0.06c-0.02,0.01-0.04,0.03-0.06,0.07l-1.12,3.29
            c-0.03,0.11-0.1,0.17-0.21,0.2l-1.01,0.25c-0.1,0.03-0.19,0-0.27-0.08l-2.52-2.4c-0.03-0.03-0.06-0.04-0.08-0.03
            c-0.02,0.01-0.03,0.04-0.02,0.09l1.85,7.55c0.01,0.06,0.01,0.11-0.02,0.16c-0.03,0.05-0.07,0.08-0.13,0.1l-2.01,0.49
            c-0.06,0.01-0.11,0.01-0.16-0.02c-0.05-0.03-0.08-0.07-0.1-0.13l-2.85-11.6c-0.01-0.06-0.01-0.11,0.02-0.16
            c0.03-0.05,0.07-0.08,0.13-0.1l2.01-0.49c0.1-0.03,0.19,0,0.27,0.08l3.56,3.37c0.05,0.06,0.08,0.05,0.1-0.02l1.61-4.63
            C284.33,447.43,284.4,447.36,284.5,447.34z"
        />
        <path
          className="border"
          d="M297.18,445.6c-0.03,0.05-0.07,0.09-0.12,0.11l-5.51,1.79c-0.06,0.02-0.07,0.06-0.06,0.11l0.85,2.6
            c0.02,0.06,0.06,0.08,0.11,0.06l3.61-1.17c0.06-0.02,0.11-0.01,0.16,0.01c0.05,0.03,0.09,0.07,0.11,0.12l0.53,1.63
            c0.02,0.06,0.01,0.11-0.01,0.16c-0.03,0.05-0.07,0.09-0.12,0.11l-3.61,1.17c-0.06,0.02-0.08,0.06-0.06,0.11l0.88,2.72
            c0.02,0.06,0.06,0.08,0.11,0.06l5.51-1.79c0.06-0.02,0.11-0.01,0.16,0.01c0.05,0.03,0.09,0.07,0.11,0.12l0.53,1.63
            c0.02,0.06,0.01,0.11-0.01,0.16c-0.03,0.05-0.07,0.09-0.12,0.1l-7.76,2.52c-0.06,0.02-0.11,0.01-0.16-0.01
            c-0.05-0.03-0.09-0.07-0.11-0.12l-3.69-11.36c-0.02-0.06-0.01-0.11,0.01-0.16c0.03-0.05,0.07-0.09,0.12-0.1l7.76-2.52
            c0.06-0.02,0.11-0.01,0.16,0.01c0.05,0.03,0.09,0.07,0.11,0.12l0.53,1.63C297.21,445.49,297.21,445.54,297.18,445.6z"
        />
        <path
          className="border"
          d="M309.57,451.56l-1.14-1.4c-0.04-0.03-0.08-0.05-0.11-0.03l-4.03,1.63c-0.03,0.01-0.05,0.05-0.06,0.1l0.18,1.8
            c0.01,0.12-0.04,0.21-0.15,0.25l-2.08,0.84c-0.07,0.03-0.12,0.03-0.17,0s-0.08-0.08-0.09-0.17l-0.96-12.5
            c-0.01-0.12,0.04-0.21,0.15-0.25l2.57-1.04c0.11-0.04,0.2-0.02,0.28,0.08l8.03,9.63c0.02,0.02,0.04,0.04,0.05,0.08
            c0.04,0.1,0,0.17-0.12,0.22l-2.08,0.84C309.74,451.68,309.65,451.65,309.57,451.56z M304.14,449.74l2.88-1.17
            c0.07-0.03,0.07-0.07,0.03-0.12l-3.45-4.26c-0.03-0.04-0.05-0.05-0.07-0.04c-0.02,0.01-0.03,0.04-0.03,0.08l0.53,5.45
            C304.05,449.74,304.09,449.76,304.14,449.74z"
        />
        <path
          className="border"
          d="M314.7,449.12c-0.77,0.04-1.44-0.11-2.03-0.45s-1.04-0.84-1.37-1.51l-0.17-0.35
            c-0.03-0.05-0.03-0.11-0.01-0.16c0.02-0.05,0.05-0.1,0.11-0.12l1.81-0.88c0.05-0.03,0.11-0.03,0.16-0.01
            c0.05,0.02,0.1,0.06,0.12,0.11l0.12,0.24c0.21,0.42,0.58,0.69,1.11,0.79c0.53,0.1,1.13-0.01,1.8-0.33
            c0.56-0.27,0.92-0.59,1.08-0.97c0.16-0.37,0.15-0.73-0.02-1.08c-0.12-0.25-0.31-0.43-0.57-0.52c-0.25-0.09-0.56-0.13-0.92-0.13
            c-0.36,0.01-0.91,0.05-1.66,0.13c-0.83,0.1-1.55,0.14-2.17,0.13c-0.62-0.01-1.2-0.16-1.74-0.44c-0.55-0.28-0.98-0.74-1.29-1.39
            c-0.31-0.64-0.41-1.27-0.32-1.91c0.1-0.64,0.37-1.22,0.83-1.76c0.46-0.54,1.07-0.99,1.83-1.36c0.8-0.39,1.59-0.6,2.36-0.62
            c0.77-0.02,1.46,0.14,2.06,0.48s1.07,0.85,1.4,1.53l0.12,0.24c0.03,0.05,0.03,0.11,0.01,0.16c-0.02,0.06-0.06,0.1-0.11,0.12
            l-1.83,0.89c-0.05,0.03-0.11,0.03-0.16,0.01c-0.06-0.02-0.1-0.06-0.12-0.11l-0.06-0.13c-0.22-0.45-0.58-0.73-1.1-0.87
            c-0.52-0.13-1.09-0.05-1.73,0.26c-0.5,0.24-0.84,0.54-1.01,0.89c-0.18,0.35-0.18,0.71,0,1.08c0.13,0.26,0.32,0.45,0.57,0.55
            c0.25,0.1,0.57,0.14,0.97,0.13c0.39-0.01,0.99-0.06,1.78-0.15c0.89-0.09,1.59-0.13,2.13-0.13c0.53,0,1.07,0.13,1.62,0.38
            c0.55,0.25,0.98,0.71,1.31,1.38c0.48,1,0.5,1.96,0.05,2.89c-0.45,0.93-1.3,1.7-2.55,2.31C316.27,448.85,315.46,449.08,314.7,449.12
            z"
        />
        <path
          className="border"
          d="M324.75,443.89c-0.77,0.04-1.49-0.13-2.14-0.49c-0.65-0.37-1.19-0.91-1.6-1.64l-4.01-6.99
            c-0.03-0.05-0.04-0.1-0.02-0.16c0.02-0.06,0.05-0.1,0.1-0.13l1.79-1.03c0.05-0.03,0.1-0.04,0.16-0.02c0.06,0.02,0.1,0.05,0.13,0.1
            l4.02,7c0.31,0.54,0.73,0.88,1.25,1.02c0.52,0.14,1.05,0.06,1.6-0.25c0.54-0.31,0.88-0.73,1.02-1.25c0.14-0.52,0.06-1.05-0.25-1.6
            l-4.02-7c-0.03-0.05-0.04-0.1-0.02-0.16c0.02-0.06,0.05-0.1,0.1-0.13l1.79-1.03c0.05-0.03,0.1-0.04,0.16-0.02
            c0.06,0.02,0.1,0.05,0.13,0.1l4.01,6.99c0.42,0.73,0.62,1.46,0.61,2.21c-0.01,0.75-0.23,1.45-0.65,2.1
            c-0.42,0.65-1.03,1.2-1.81,1.65C326.31,443.61,325.53,443.86,324.75,443.89z"
        />
        <path
          className="border"
          d="M338.52,435.62l-4.56-2.83c-0.05-0.03-0.09-0.03-0.13,0l-1.36,0.92c-0.05,0.03-0.06,0.07-0.02,0.12l2.69,4.01
            c0.03,0.05,0.04,0.1,0.03,0.16c-0.01,0.06-0.04,0.1-0.09,0.14l-1.72,1.15c-0.05,0.03-0.1,0.04-0.16,0.03
            c-0.06-0.01-0.1-0.04-0.13-0.09l-6.65-9.92c-0.03-0.05-0.04-0.1-0.03-0.16c0.01-0.06,0.04-0.1,0.09-0.14l4.19-2.81
            c0.63-0.42,1.26-0.66,1.91-0.73c0.64-0.06,1.25,0.05,1.83,0.35c0.57,0.3,1.07,0.75,1.48,1.37c0.45,0.67,0.66,1.35,0.65,2.04
            c-0.01,0.7-0.25,1.34-0.72,1.94c-0.04,0.05-0.03,0.1,0.02,0.13l4.78,2.81c0.05,0.03,0.08,0.05,0.09,0.07
            c0.05,0.08,0.03,0.15-0.08,0.23l-1.8,1.21C338.71,435.68,338.62,435.68,338.52,435.62z M329.6,429.58l1.68,2.51
            c0.03,0.05,0.07,0.06,0.12,0.02l1.92-1.29c0.41-0.28,0.66-0.62,0.76-1.04c0.1-0.42,0.01-0.82-0.25-1.21
            c-0.26-0.39-0.61-0.62-1.03-0.7c-0.43-0.08-0.85,0.02-1.26,0.3l-1.92,1.29C329.57,429.5,329.57,429.54,329.6,429.58z"
        />
        <path
          className="border"
          d="M342.99,419.64c-0.01,0.06-0.03,0.1-0.08,0.14l-4.58,3.54c-0.05,0.04-0.05,0.08-0.02,0.12l1.67,2.17
            c0.04,0.05,0.08,0.05,0.12,0.02l3.01-2.32c0.05-0.04,0.1-0.05,0.16-0.04c0.06,0.01,0.1,0.03,0.14,0.08l1.05,1.36
            c0.04,0.05,0.05,0.1,0.04,0.16c-0.01,0.06-0.03,0.1-0.08,0.14l-3,2.32c-0.05,0.04-0.05,0.08-0.02,0.12l1.75,2.26
            c0.04,0.05,0.08,0.05,0.12,0.02l4.58-3.54c0.05-0.04,0.1-0.05,0.16-0.04c0.06,0.01,0.1,0.03,0.14,0.08l1.05,1.36
            c0.04,0.05,0.05,0.1,0.04,0.16c-0.01,0.06-0.03,0.1-0.08,0.14l-6.46,4.99c-0.05,0.04-0.1,0.05-0.16,0.04
            c-0.06-0.01-0.1-0.03-0.14-0.08l-7.3-9.45c-0.04-0.05-0.05-0.1-0.04-0.16c0.01-0.06,0.03-0.1,0.08-0.14l6.46-4.99
            c0.05-0.04,0.1-0.05,0.16-0.04c0.06,0.01,0.1,0.03,0.14,0.08l1.05,1.36C342.99,419.53,343,419.58,342.99,419.64z"
        />
      </g>
    </svg>
  );
};

export default InfinityProgressSvg;
