import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OperationalSetupTabs from './OperationalSetupTabs';

import s from './OperationalSetupContentTemplate.module.scss';

const OperationalSetupContentTemplate = ({ backLink, children, selectedPage, userProfileWidgets }) => (
  <Fragment>
    <div className={s.fullWidth}>
      <div className={s.fullWidthWrapper}>
        <h1 className={s.tabHeader}>{<FormattedMessage id="OperationalSetup.operationalSetup" defaultMessage="Operational Setup" />}</h1>
        {<OperationalSetupTabs selectedPage={selectedPage} userProfileWidgets={userProfileWidgets} />}
      </div>
    </div>

    <div className={s.pageContent}>{children}</div>
  </Fragment>
);

OperationalSetupContentTemplate.defaultProps = {
  backLink: '/',
  selectedPage: ''
};

OperationalSetupContentTemplate.propTypes = {
  backLink: PropTypes.string,
  children: PropTypes.node.isRequired,
  selectedPage: PropTypes.string,
  userProfileWidgets: PropTypes.object
};

export default OperationalSetupContentTemplate;
