import React from 'react';
import s from './RegulatoryRegister.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../../assets/arrow-up.png';
import descendingIcon from '../../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getDateInDDMMYYYFormat, getDateInYYYYFormat } from '../../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../../selectors/index';

const RegulatoryRegisterListTable = ({
  paginatedData,
  onSortOrder,
  asendingOrder,
  editRegulatoryRegister,
  editAllowed,
  deleteAllowed,
  removeRegulatoryRegister
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="regulatoryRegister.documentName" defaultMessage="Document Name" />
            </th>
            <th>
              <FormattedMessage id="regulatoryRegister.documentType" defaultMessage="Document Type" />
            </th>
            <th>
              <FormattedMessage id="regulatoryRegister.jurisdiction" defaultMessage="Jurisdiction" />
            </th>
            <th>
              <FormattedMessage id="regulatoryRegister.yearApplicable" defaultMessage="Year Applicable" />
            </th>
            <th>
              <FormattedMessage id="regulatoryRegister.lastReviewDate" defaultMessage="Last Review Date" />
            </th>
            <th>
              <FormattedMessage id="regulatoryRegister.reviewFrequency" defaultMessage="Review Frequency" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="regulatoryRegister.newxtReviewDate" defaultMessage="Next Review Date" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            {(editAllowed || deleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.RegulatoryRegisterId}>
                <td>{n.DocumentName || ''}</td>
                <td>{n.DocumentTypeName || ''}</td>
                <td>{n.Jurisdiction || ''}</td>
                <td>{getDateInYYYYFormat(n.YearApplicable, currentTimezone) || ''}</td>
                <td>{getDateInDDMMYYYFormat(n.LastReviewDate, currentTimezone) || ''}</td>
                <td>{n.ReviewFrequencyName || ''}</td>
                <td>{getDateInDDMMYYYFormat(n.NextReviewDate, currentTimezone) || ''}</td>
                <td className={s.btnCol}>
                  {deleteAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.changeButton}
                      href="#"
                      onClick={() => {
                        removeRegulatoryRegister(n.RegulatoryRegisterId);
                      }}
                      data-unittest="changeData"
                    >
                      <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                    </Button>
                  )}
                  {editAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.changeButton}
                      href="#"
                      onClick={() => {
                        editRegulatoryRegister(n.RegulatoryRegisterId);
                      }}
                      data-unittest="changeData"
                    >
                      <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

RegulatoryRegisterListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(RegulatoryRegisterListTable);
