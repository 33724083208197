import { initialTestPurposeState } from './initialState';

import {
  TEST_PURPOSE_ERROR,
  GET_ALL_TEST_PURPOSE_DETAILS,
  TEST_PURPOSE_IS_LOADING,
  SET_TEST_PURPOSE_CURRENT_PAGE,
  SET_TEST_PURPOSE_PAGE_FILTER,
  SET_TEST_PURPOSE_CHANGE,
  SET_TEST_PURPOSE,
  UPDATE_TEST_PURPOSE_SAVE_STATUS
} from '../../constants/index';

const testPurposeReducer = (state = initialTestPurposeState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_TEST_PURPOSE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        testPurpose: {
          ...state.testPurpose,
          ...data
        }
      };
    }

    case SET_TEST_PURPOSE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        testPurpose: {
          ...state.testPurpose,
          currentPage
        }
      };
    }

    case SET_TEST_PURPOSE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        testPurpose: {
          ...state.testPurpose,
          filter
        }
      };
    }

    case UPDATE_TEST_PURPOSE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        testPurpose: {
          ...state.testPurpose,
          ...data
        }
      };
    }

    case TEST_PURPOSE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        testPurpose: {
          ...state.testPurpose,
          isLoading: isLoading
        }
      };
    }

    case TEST_PURPOSE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        testPurpose: {
          ...state.testPurpose,
          ...data
        }
      };
    }

    case SET_TEST_PURPOSE: {
      let { selectedTestPurpose } = payload;

      return {
        ...state,
        testPurpose: {
          ...state.testPurpose,
          isOpSuccessful: false,
          showBanner: false,
          selectedTestPurpose: {
            ...state.testPurpose.selectedTestPurpose,
            ...selectedTestPurpose
          }
        }
      };
    }

    case SET_TEST_PURPOSE_CHANGE: {
      let { selectedTestPurpose } = payload;

      return {
        ...state,
        testPurpose: {
          ...state.testPurpose,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedTestPurpose: {
            ...state.testPurpose.selectedTestPurpose,
            ...selectedTestPurpose
          }
        }
      };
    }

    default:
      return state;
  }
};

export default testPurposeReducer;
