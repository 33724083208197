import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './TestCategory.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';

const TestCategoryListTable = ({ testCategoryTypeData, intl }) => {
  return (
    <div>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="wqsp.common.testType" defaultMessage="Test Type" />
            </th>
            <th>
              <FormattedMessage id="categoryType.uOM" defaultMessage="Test Type" />
            </th>
            <th>
              <FormattedMessage id="categoryType.description" defaultMessage="Description" />
            </th>
            <th>
              <FormattedMessage id="categoryType.realisticMean" defaultMessage="Default Realistic Mean" />
            </th>
            <th>
              <FormattedMessage id="categoryType.realisticMax" defaultMessage="Default Realistic Max" />
            </th>
            <th>
              <FormattedMessage id="categoryType.realisticLRV" defaultMessage="Default Realistic LRV" />
            </th>
            <th>
              <FormattedMessage id="categoryType.aspirationalMean" defaultMessage="Default Aspirational Mean" />
            </th>
            <th>
              <FormattedMessage id="categoryType.aspirationalMax" defaultMessage="Default Aspirational Max" />
            </th>
            <th>
              <FormattedMessage id="categoryType.aspirationalLRV" defaultMessage="Default Aspirational LRV" />
            </th>
          </tr>
        </thead>
        <tbody>
          {testCategoryTypeData.map(n => {
            return (
              <Fragment key={n.TestCategoryItemId}>
                <tr data-unittest="testCategoryTypeData">
                  <td>{n.Name}</td>
                  <td>{n.UnitOfMeasure}</td>
                  <td>{n.Description}</td>
                  <td>{n.RealisticMeanValue}</td>
                  <td>{n.RealisticMaxValue}</td>
                  <td>{n.RealisticLRV}</td>
                  <td>{n.AspirationalMeanValue}</td>
                  <td>{n.AspirationalMaxValue}</td>
                  <td>{n.AspirationalLRV}</td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

TestCategoryListTable.defaultProps = {
  testCategoryTypeData: []
};

TestCategoryListTable.propTypes = {
  testCategoryTypeData: PropTypes.array.isRequired
};

export default injectIntl(TestCategoryListTable);
