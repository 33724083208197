import { initialSiteState } from './initialState';

import {
  SITE_ERROR,
  GET_ALL_SITE_DETAILS,
  SITE_IS_LOADING,
  SET_SITE_CURRENT_PAGE,
  SET_SITE_PAGE_FILTER,
  SET_SITE_CHANGE,
  SET_SITE,
  UPDATE_SITE_SAVE_STATUS,
  SET_SITE_FILTERS,
  SET_SITE_RESULTS,
  SET_SITE_COMMON_LIST,
  SET_SELECTED_VIEW
} from '../../constants/index';

const siteReducer = (state = initialSiteState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_SITE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        site: {
          ...state.site,
          ...data
        }
      };
    }

    case SET_SITE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        site: {
          ...state.site,
          currentPage
        }
      };
    }

    case SET_SITE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        site: {
          ...state.site,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_SITE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        site: {
          ...state.site,
          ...data
        }
      };
    }

    case SITE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        site: {
          ...state.site,
          isLoading: isLoading
        }
      };
    }

    case SITE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        site: {
          ...state.site,
          ...data
        }
      };
    }

    case SET_SITE: {
      let { selectedSite } = payload;

      return {
        ...state,
        site: {
          ...state.site,
          isOpSuccessful: false,
          showBanner: false,
          selectedSite: {
            ...state.site.selectedSite,
            ...selectedSite
          }
        }
      };
    }

    case SET_SITE_CHANGE: {
      let { selectedSite } = payload;

      return {
        ...state,
        site: {
          ...state.site,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedSite: {
            ...state.site.selectedSite,
            ...selectedSite
          }
        }
      };
    }

    case SET_SITE_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        site: {
          ...state.site,
          ...filteredResults
        }
      };
    }

    case SET_SITE_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        site: {
          ...state.site,
          ...data
        }
      };
    }

    case SET_SELECTED_VIEW: {
      const { viewName } = payload;
      return {
        ...state,
        site: {
          ...state.site,
          selectedView: viewName
        }
      };
    }

    default:
      return state;
  }
};

export default siteReducer;
