import { decode } from 'jsonwebtoken';
import moment from 'moment';
import { get as getCookie } from 'js-cookie';

export const getUserState = state => state.user;
export const getUiState = state => state.ui;
export const getRouterState = state => state.router;

export const getLocaleState = state => state.locale;
export const getTimezoneState = state => state.timezone;
export const getProfileState = state => state.profile;
export const getSidebarState = state => state.sidebarOpen;

export const getUserManagerState = state => state.userManager;
export const getUserProfileWidgetState = state => state.userProfileWidgets;
export const getUserProfileUnitState = state => state.userProfileUnits;
export const getAppWidgetsState = state => state.appWidgets;

//organisations
export const getOrganisationManagerState = state => state.organisationManager;
export const getOrganisationManager = state => getOrganisationManagerState(state).organisationManager;

// USERS
export const getUserLoginError = state => getUserState(state).loginError;
export const getUserLoginErrorMessage = state => getUserState(state).loginErrorMessage;
export const getUserLoginIsForgotPassword = state => getUserState(state).isforgotPassword;

export const getUsername = state => getUserState(state).username;
export const getUserSessionId = state => getUserState(state).sessionId;
export const getIsUserAuthenticated = () => {
  const accessToken = getCookie(`access-${process.env.REACT_APP_ENV_NAME_SHORT}`);
  const decodedToken = decode(accessToken);

  if (!decodedToken) return false;

  return moment.unix(decodedToken.exp) > moment();
};

export const getUser = state => getUserState(state).user;
export const getUserManager = state => getUserManagerState(state).userManager;
export const getUserProfileWidget = state => getUserProfileWidgetState(state);
export const getUserProfileUnit = state => getUserProfileUnitState(state);
export const getUserProfileWidgetsPresent = state => getUserProfileWidget(state).isProfileWidgetsPresent;

export const getUserByUserName = (state, email) => {
  if (!email) {
    return {};
  }
  let userManager = getUserManager(state);
  let users = (userManager && userManager.users) || null;
  if (!users) {
    return {};
  }

  let retUsers =
    (users &&
      Array.isArray(users) &&
      users.length > 0 &&
      users.filter(e => e.Email === email).length > 0 &&
      users.filter(e => e.Email === email)) ||
    null;
  if (!retUsers) {
    return {};
  }
  return retUsers[0];
};

// UI
export const getIsLoading = state => getUiState(state).isLoading;

export const getRoutePathname = state => getRouterState(state).location.pathname;

export function getCurrentLocale(state) {
  return getLocaleState(state).locale;
}

export const getCurrentTimezone = state => getTimezoneState(state).timezone;

//Profile
export const getProfile = state => getProfileState(state).profile;

export const getProfileByIdInState = (state, Id) => {
  if (!Id) {
    return {};
  }
  let profiles = (getProfileState(state) && getProfileState(state).profile && getProfileState(state).profile.profiles) || null;
  if (!profiles) {
    return {};
  }

  let retProfiles =
    (profiles &&
      Array.isArray(profiles) &&
      profiles.length > 0 &&
      profiles.filter(e => e.Id === Id).length > 0 &&
      profiles.filter(e => e.Id === Id)) ||
    null;
  if (!retProfiles) {
    return {};
  }
  return retProfiles[0];
};

//Sidebar state
export const getCurrentSidebarState = state => getSidebarState(state).sidebarOpen;

export const getSelectedProfile = state => {
  return (getProfileState(state) && getProfileState(state).profile && getProfileState(state).profile.selectedProfile) || {};
};

//App widgets
export const getAppWidgets = state => getAppWidgetsState(state).appWidgets;

export const getForgotPasswordState = state => state.forgotPassword;
export const getForgotPassword = state => getForgotPasswordState(state).forgotPassword;

//units
export const getUnitManagerState = state => state.unitManager;
export const getUnitManager = state => {
  return getUnitManagerState(state).unitManager;
};

//Checklist Template
export const getChecklistTemplateState = state => state.checklistTemplate;
export const getChecklistState = state => state.checklist;

//Task Template
export const getTaskTemplateState = state => state.taskTemplate;
export const getTaskTemplateManager = state => getTaskTemplateState(state).taskTemplate;

//Tasks
export const getTasksState = state => state.tasks;
export const getTaskManager = state => getTasksState(state).tasks;

// get Checklist templates
export const getChecklistTemplate = state => getChecklistTemplateState(state).checklistTemplate;
export const getSelectedChecklistTemplate = state => {
  return (getChecklistTemplate(state) && getChecklistTemplate(state).SelectedChecklistTemplate) || {};
};

// get Checklist templates
export const getChecklist = state => getChecklistState(state).checklist;
export const getSelectedChecklist = state => {
  return (getChecklist(state) && getChecklist(state).SelectedChecklist) || {};
};

// get Test Purpose
export const getTestPurposeState = state => state.testPurpose;
export const getTestPurpose = state => getTestPurposeState(state).testPurpose;
export const getSelectedTestPurpose = state => {
  return (getTestPurpose(state) && getTestPurpose(state).selectedTestPurpose) || {};
};

// get Inspection Purpose
export const getInspectionPurposeState = state => state.inspectionPurpose;
export const getInspectionPurpose = state => getInspectionPurposeState(state).inspectionPurpose;
export const getSelectedInspectionPurpose = state => {
  return (getInspectionPurpose(state) && getInspectionPurpose(state).SelectedInspectionPurpose) || {};
};

// get Test Category
export const getTestCategoryState = state => state.testCategory;
export const getTestCategory = state => getTestCategoryState(state).testCategory;
export const getSelectedTestCategory = state => {
  return (getTestCategory(state) && getTestCategory(state).selectedTestCategory) || {};
};

// get Inspection Type
export const getInspectionTypeState = state => state.inspectionType;
export const getInspectionType = state => getInspectionTypeState(state).inspectionType;
export const getSelectedInspectionType = state => {
  return (getInspectionType(state) && getInspectionType(state).SelectedInspectionType) || {};
};

// get Inspection Type
export const getInspectionTypeQuestionState = state => state.inspectionTypeQuestion;
export const getInspectionTypeQuestion = state => getInspectionTypeQuestionState(state).inspectionTypeQuestion;
export const getSelectedInspectionTypeQuestion = state => {
  return (getInspectionTypeQuestion(state) && getInspectionTypeQuestion(state).SelectedInspectionTypeQuestion) || {};
};

export const getGeneralDataState = state => state.generalData;
export const getGeneralData = state => getGeneralDataState(state).generalData;
export const getGeneralDataPresentState = state => {
  return getGeneralData(state).isDataPresent;
};

// get Inspection Report
export const getInspectionReportState = state => state.inspectionReport;
export const getInspectionReport = state => getInspectionReportState(state).inspectionReport;
export const getSelectedInspectionReport = state => {
  return (getInspectionReport(state) && getInspectionReport(state).SelectedInspectionReport) || {};
};
//Test Result
export const getTestResultState = state => state.testResult;
export const getTestResult = state => getTestResultState(state).testResult;
export const getSelectedTestResult = state => {
  return (getTestResult(state) && getTestResult(state).selectedTestResult) || {};
};

//Sites
export const getSiteState = state => state.site;
export const getSite = state => getSiteState(state).site;
export const getSelectedSite = state => {
  return (getSite(state) && getSite(state).selectedSite) || {};
};

//Quality Goals
export const getQualityGoalsState = state => state.qualityGoals;
export const getQualityGoals = state => getQualityGoalsState(state).qualityGoals;
export const getSelectedQualityGoals = state => {
  return (getQualityGoals(state) && getQualityGoals(state).selectedQualityGoals) || {};
};

//Entities
export const getEntityState = state => state.entity;
export const getEntity = state => getEntityState(state).entity;
export const getSelectedEntity = state => {
  return (getEntity(state) && getEntity(state).SelectedEntity) || {};
};

//Entities
export const getWaterSupplyDescriptionState = state => state.waterSupplyDescription;
export const getWaterSupplyDescription = state => getWaterSupplyDescriptionState(state).waterSupplyDescription;
export const getSelectedWaterSupplyDescription = state => {
  return (getWaterSupplyDescription(state) && getWaterSupplyDescription(state).SelectedWaterSupplyDescription) || {};
};

// get General Field Type
export const getGeneralFieldTypeState = state => state.generalFieldType;
export const getGeneralFieldType = state => getGeneralFieldTypeState(state).generalFieldType;
export const getSelectedGeneralFieldType = state => {
  return (getGeneralFieldType(state) && getGeneralFieldType(state).SelectedGeneralFieldType) || {};
};

// get Risk Categories
export const getRiskCategoryState = state => state.riskCategory;
export const getRiskCategory = state => getRiskCategoryState(state).riskCategory;
export const getSelectedRiskCategory = state => {
  return (getRiskCategory(state) && getRiskCategory(state).SelectedRiskCategory) || {};
};

// get Hazard events
export const getHazardEventState = state => state.hazardEvent;
export const getHazardEvent = state => getHazardEventState(state).hazardEvent;
export const getSelectedHazardEvent = state => {
  return (getHazardEvent(state) && getHazardEvent(state).selectedHazardEvent) || {};
};

// get Hazard event library
export const getHazardEventLibraryState = state => state.hazardEventLibrary;
export const getHazardEventLibrary = state => getHazardEventLibraryState(state).hazardEventTemplate;
export const getSelectedHazardEventTemplate = state => {
  return (getHazardEventLibrary(state) && getHazardEventLibrary(state).SelectedHazardEventTemplate) || {};
};

// get Hazard event library
export const getHazardState = state => state.hazard;
export const getHazard = state => getHazardState(state).hazard;
export const getSelectedHazard = state => {
  return (getHazard(state) && getHazardEvent(state).selectedHazard) || {};
};

// get Risk Asssessment
export const getRiskAssessmentState = state => state.riskAssessment;
export const getRiskAssessment = state => getRiskAssessmentState(state).riskAssessment;
export const getSelectedRiskAssessment = state => {
  return (getRiskAssessment(state) && getRiskAssessment(state).selectedRiskAssessment) || {};
};

//Sites
export const getSafetyPlanState = state => state.safetyPlan;
export const getSafetyPlan = state => getSafetyPlanState(state).safetyPlan;
export const getSelectedSafetyPlan = state => {
  return (getSafetyPlan(state) && getSafetyPlan(state).selectedSafetyPlan) || {};
};

//Emergency Response plan
export const getEmergencyResponsePlanState = state => state.emergencyResponsePlan;
export const getEmergencyResponsePlan = state => getEmergencyResponsePlanState(state).emergencyResponsePlan;
export const getSelectedEmergencyResponsePlan = state => {
  return (getEmergencyResponsePlan(state) && getEmergencyResponsePlan(state).selectedEmergencyResponsePlan) || {};
};

//Personnel
export const getPersonnelState = state => state.personnel;
export const getPersonnel = state => getPersonnelState(state).personnel;
export const getSelectedPersonnel = state => {
  return (getPersonnel(state) && getPersonnel(state).selectedPersonnel) || {};
};

//Team
export const getTeamState = state => state.safetyPlanTeam;
export const getTeam = state => getTeamState(state).safetyPlanTeam;
export const getSelectedTeam = state => {
  return (getTeam(state) && getTeam(state).selectedTeam) || {};
};

//Stakeholder Register
export const getStakeholderRegisterState = state => state.stakeholderRegister;
export const getStakeholderRegister = state => getStakeholderRegisterState(state).stakeholderRegister;
export const getSelectedStakeholderRegister = state => {
  return (getStakeholderRegister(state) && getStakeholderRegister(state).selectedStakeholderRegister) || {};
};

//Regulatory Response
export const getRegulatoryRegisterState = state => state.regulatoryRegister;
export const getRegulatoryRegister = state => getRegulatoryRegisterState(state).regulatoryRegister;
export const getSelectedRegulatoryRegister = state => {
  return (getRegulatoryRegister(state) && getRegulatoryRegister(state).selectedRegulatoryRegister) || {};
};

//Safety plan report
export const getSafetyPlanReportState = state => state.safetyPlanReport;
export const getSafetyPlanReport = state => getSafetyPlanReportState(state).safetyPlanReport;

//Control Measures
export const getControlMeasureState = state => state.controlMeasure;
export const getControlMeasure = state => getControlMeasureState(state).controlMeasure;
export const getSelectedgetControlMeasure = state => {
  return (getControlMeasure(state) && getControlMeasure(state).selectedControlMeasure) || {};
};

//Improvement plans
export const getImprovementPlanState = state => state.improvementPlan;
export const getImprovementPlan = state => getImprovementPlanState(state).improvementPlan;
export const getSelectedgetImprovementPlan = state => {
  return (getImprovementPlan(state) && getImprovementPlan(state).selectedImprovementPlan) || {};
};

//SitePathway
export const getSitePathwayState = state => state.sitePathway;
export const getSitePathway = state => getSitePathwayState(state).sitePathway;
export const getSelectedSitePathway = state => {
  return (getSitePathway(state) && getSitePathway(state).selectedSitePathway) || null;
};

//Site Segment
export const getSiteSegmentsState = state => state.siteSegments;
export const getSiteSegments = state => getSiteSegmentsState(state).siteSegments;
export const getSelectedSiteSegmentsView = state => {
  return (getSiteSegments(state) && getSiteSegments(state).selectedView) || null;
};

//Organisation Translations
export const getOrganisationTranslationState = state => state.organisationTranslation;
export const getOrganisationTranslation = state => getOrganisationTranslationState(state).organisationTranslation;
export const getSelectedOrganisationTranslationView = state => {
  return (getOrganisationTranslation(state) && getOrganisationTranslation(state).selectedView) || null;
};

//Translation
export const getTranslationState = state => state.translation;
export const getTranslation = state => getTranslationState(state).translation;

//Incident Reports
export const getIncidentReportsState = state => state.incidentReports;
export const getIncidentReports = state => getIncidentReportsState(state).incidentReports;
export const getSelectedIncidentReports = state => {
  return (getIncidentReports(state) && getIncidentReports(state).selectedIncidentReport) || {};
};

export const getCorrectiveActionState = state => state.correctiveAction;
export const getCorrectiveAction = state => getCorrectiveActionState(state).correctiveAction;

export const getEntitySubTypeState = state => state.entitySubType;
export const getEntitySubType = state => getEntitySubTypeState(state).entitySubType;

export const getControlMonitoringState = state => state.controlMonitoring;
export const getControlMonitoring = state => getControlMonitoringState(state).controlMonitoring;
