import * as sidebarActionTypes from '../../constants/index';
import { push } from 'connected-react-router';

export const toggleSidebar = sidebarOpen => ({
  type: sidebarActionTypes.TOGGLE_SIDEBAR,
  sidebarOpen
});

export const redirectTo = (url = null) => dispatch => {
  let redirectURL = url;

  if (redirectURL) {
    dispatch(push(redirectURL));
  } else {
    dispatch(push('/dashboard'));
  }
};
