import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ChecklistTasksTemplates.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SearchBar from '../SearchBar/SearchBar';
import { initialTaskTemplatesState, initialChecklistTemplateState } from '../../store/reducers//initialState';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import ChecklistTasksTemplatesTable from './ChecklistTasksTemplatesTable';
import Button from 'react-bootstrap/Button';
import { WidgetVisibility } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';

const ChecklistTasksTemplates = ({
  userProfileWidgets,
  intl,
  taskTemplateManager,
  getTaskTemplates,
  checklistTemplate,
  getChecklistTemplate
}) => {
  let initialLimit = 6;
  let initialTasktemplateRecords = taskTemplateManager.TaskTemplates.slice(0, initialLimit);
  let initialChecklistTemplateRecords = checklistTemplate.ChecklistTemplates.slice(0, initialLimit);

  const [checklistTemplateOpen, setChecklistTemplateOpen] = useState(false);
  const [taskTemplateOpen, setTaskTemplateOpen] = useState(false);

  const [localChecklistTemplates, setLocalChecklistTemplates] = useState([]);
  const [showAllChecklistTemplates, setShowAllChecklistTemplates] = useState(false);
  const [initialChecklistFilteredRecords, setInitialChecklistFilteredRecords] = useState([]);
  const [isChecklistFilter, setIsChecklistFilter] = useState(false);
  const [searchChecklistText, setSearchChecklistText] = useState('');
  const [filterChecklistERP, setFilterChecklistERP] = useState('0');

  const localChecklistTemplatesLength = localChecklistTemplates.length;
  const checklistTemplatesLength = checklistTemplate.ChecklistTemplates.length;

  const [localTaskTemplates, setLocalTaskTemplates] = useState([]);
  const [showAllTaskTemplates, setShowAllTaskTemplates] = useState(false);
  const [initialTaskFilteredRecords, setInitialTaskFilteredRecords] = useState([]);
  const [isTaskFilter, setIsTaskFilter] = useState(false);
  const [searchTaskText, setSearchTaskText] = useState('');
  const [filterTaskERP, setFilterTaskERP] = useState('0');

  const localTaskTemplatesLength = localTaskTemplates.length;
  const taskTemplatesLength = taskTemplateManager.TaskTemplates.length;

  useEffect(() => {
    getTaskTemplates();
  }, [getTaskTemplates]);

  useEffect(() => {
    getChecklistTemplate();
  }, [getChecklistTemplate]);

  const showAllChecklistTemplateHandler = () => {
    if (!isChecklistFilter) {
      setLocalChecklistTemplates(checklistTemplate.ChecklistTemplates);
    }
    setShowAllChecklistTemplates(!showAllChecklistTemplates);
  };

  const showAllTaskTemplateHandler = () => {
    if (!isTaskFilter) {
      setLocalTaskTemplates(taskTemplateManager.TaskTemplates);
    }
    setShowAllTaskTemplates(!showAllTaskTemplates);
  };

  const searchTaskTemplateHandler = (value, isDropDown = false) => {
    if ((value === '' && filterTaskERP === '0') || (value === '0' && searchTaskText === '')) {
      setIsTaskFilter(false);
    } else {
      setIsTaskFilter(true);
    }

    let filteredList = taskTemplateManager.TaskTemplates;
    if (isDropDown) {
      setFilterTaskERP(value);
      if (value !== '0') {
        filteredList = filteredList.filter(function(item) {
          return item.IsERP === value;
        });
      }

      if (searchTaskText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchTaskText.toLowerCase());
        });
      }
    } else {
      setSearchTaskText(value);
      filteredList = filteredList.filter(function(item) {
        return item.Name.toLowerCase().includes(value.toLowerCase());
      });

      if (filterTaskERP !== '0') {
        filteredList = filteredList.filter(function(item) {
          return item.IsERP === filterTaskERP;
        });
      }
    }

    if (filteredList.length > initialLimit) {
      setInitialTaskFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalTaskTemplates(filteredList);
  };

  const searchChecklistTemplateHandler = (value, isDropDown = false) => {
    if ((value === '' && filterChecklistERP === '0') || (value === '0' && searchChecklistText === '')) {
      setIsChecklistFilter(false);
    } else {
      setIsChecklistFilter(true);
    }

    let filteredList = checklistTemplate.ChecklistTemplates;

    if (isDropDown) {
      setFilterChecklistERP(value);
      if (value !== '0') {
        filteredList = filteredList.filter(function(item) {
          return item.IsERP === value;
        });
      }

      if (searchChecklistText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchChecklistText.toLowerCase());
        });
      }
    } else {
      setSearchChecklistText(value);
      filteredList = filteredList.filter(function(item) {
        return item.Name.toLowerCase().includes(value.toLowerCase());
      });

      if (filterChecklistERP !== '0') {
        filteredList = filteredList.filter(function(item) {
          return item.IsERP === filterChecklistERP;
        });
      }
    }

    if (filteredList.length > initialLimit) {
      setInitialChecklistFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalChecklistTemplates(filteredList);
  };

  const processTaskTemplates = () => {
    let taskTemplates = [];
    let processedTaskTemplates = [];

    if (showAllTaskTemplates) {
      taskTemplates = localTaskTemplates;
    } else {
      if (isTaskFilter) {
        taskTemplates = localTaskTemplatesLength > initialLimit ? initialTaskFilteredRecords : localTaskTemplates;
      } else {
        taskTemplates = initialTasktemplateRecords;
      }
    }

    taskTemplates.forEach(element => {
      processedTaskTemplates.push({
        Name: element.Name,
        Id: element.TaskTemplateId,
        IsERP: element.IsERP,
        Link: `/operational-setup/task-template-detail/${element.TaskTemplateId}`
      });
    });

    return processedTaskTemplates;
  };

  const processChecklistTemplates = () => {
    let checklistTemplates = [];
    let processedChecklistTemplates = [];

    if (showAllChecklistTemplates) {
      checklistTemplates = localChecklistTemplates;
    } else {
      if (isChecklistFilter) {
        checklistTemplates = localChecklistTemplatesLength > initialLimit ? initialChecklistFilteredRecords : localChecklistTemplates;
      } else {
        checklistTemplates = initialChecklistTemplateRecords;
      }
    }

    checklistTemplates.forEach(element => {
      processedChecklistTemplates.push({
        Name: element.Name,
        Id: element.ChecklistTemplateId,
        IsERP: element.IsERP,
        Link: `/operational-setup/checklist-template/${element.ChecklistTemplateId}?view=1`
      });
    });

    return processedChecklistTemplates;
  };

  const eRPOptions = () => {
    let eRPData = [];

    eRPData.push({
      label: <FormattedMessage id="checklist.allEmergencyResponsePlan" defaultMessage="All Emergency Response Plan" />,
      value: '0'
    });
    eRPData.push({
      label: <FormattedMessage id="common.no" defaultMessage="No" />,
      value: false
    });
    eRPData.push({
      label: <FormattedMessage id="common.yes" defaultMessage="Yes" />,
      value: true
    });

    return eRPData;
  };

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  return (
    <div className={s.checklistTasksTemplates}>
      {checklistTemplate.isLoading && <LoadingSpinner />}

      <OperationalSetupContentTemplate selectedPage="checklistTaskTemplate" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, `WQSP_CHECKLISTTEMPLATELIST`)}>
            <Row>
              <Col>
                <Row>
                  <img
                    src={checklistTemplateOpen ? collapseDown : collapseUp}
                    alt="Collapse section"
                    onClick={() => setChecklistTemplateOpen(!checklistTemplateOpen)}
                    aria-controls="checklist-template-section"
                    aria-expanded={checklistTemplateOpen}
                  ></img>
                  <h4>
                    <FormattedMessage id="checklistTaskTemplateList.checklistTemplates" defaultMessage="Checklist Templates" />
                  </h4>
                  <span className={s.circle}>
                    {(checklistTemplate.ChecklistTemplates && checklistTemplate.ChecklistTemplates.length) || 0}
                  </span>
                </Row>
              </Col>
            </Row>
            <Collapse in={checklistTemplateOpen}>
              <div className={s.innerComponent} id="checklist-template-section">
                <Row className="p-0">
                  <Col sm={3} lg={2} className="p-0">
                    <Dropdown
                      id="drpERPId"
                      dataArray={eRPOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.showAll" defaultMessage="Show: All" />,
                        customClassName: ''
                      }}
                      selectedOption={eRPOptions().filter(option => option.value === filterChecklistERP)}
                      onDropdownChange={e => searchChecklistTemplateHandler(e.value, true)}
                      disabled={false}
                    />
                  </Col>
                  <Col sm={3} lg={3}>
                    <SearchBar
                      searchHandler={searchChecklistTemplateHandler}
                      clearSearchInVisible={false}
                      // initialText={filter}
                      placeHolderTextId="checklistTemplateManagement.Search"
                      data-unittest="searchTaskTemplates"
                    />
                  </Col>
                  <Col className={s.addbuttonCol} style={WidgetVisibility(userProfileWidgets, `WQSP_CHECKLISTTEMPLATEADDNEW`)}>
                    <Link to="/operational-setup/checklist-template/0">
                      <Button className={s.addbutton}>
                        + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ChecklistTasksTemplatesTable
                      tableData={processChecklistTemplates()}
                      userProfileWidgets={userProfileWidgets}
                      viewName="WQSP_CHECKLISTTEMPLATEVIEW"
                    />

                    {((!isChecklistFilter && checklistTemplatesLength > initialLimit) ||
                      (isChecklistFilter && localChecklistTemplatesLength > initialLimit)) && (
                      <Button
                        variant="outline-secondary"
                        className="w-100"
                        onClick={showAllChecklistTemplateHandler}
                        data-unittest="showButton"
                      >
                        {!showAllChecklistTemplates
                          ? `${showAllText} (${
                              !showAllChecklistTemplates && !isChecklistFilter ? checklistTemplatesLength : localChecklistTemplatesLength
                            })`
                          : showLessText}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
          <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, `WQSP_TASKTEMPLATELIST`)}>
            {taskTemplateManager.isLoading && <LoadingSpinner />}
            <Row>
              <Col>
                <Row>
                  <img
                    src={taskTemplateOpen ? collapseDown : collapseUp}
                    alt="Collapse section"
                    onClick={() => setTaskTemplateOpen(!taskTemplateOpen)}
                    aria-controls="task-template-section"
                    aria-expanded={taskTemplateOpen}
                  ></img>
                  <h4>
                    <FormattedMessage id="checklistTaskTemplateList.tasksTemplates" defaultMessage="Tasks Templates" />
                  </h4>
                  <span className={s.circle}>{taskTemplateManager.TaskTemplates && taskTemplateManager.TaskTemplates.length}</span>
                </Row>
              </Col>
            </Row>

            <Collapse in={taskTemplateOpen}>
              <div className={s.innerComponent} id="task-template-section">
                <Row className="pt-3">
                  <Col sm={3} lg={2} className="p-0">
                    <Dropdown
                      id="drpERPId"
                      dataArray={eRPOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.showAll" defaultMessage="Show: All" />,
                        customClassName: ''
                      }}
                      selectedOption={eRPOptions().filter(option => option.value === filterTaskERP)}
                      onDropdownChange={e => searchTaskTemplateHandler(e.value, true)}
                      disabled={false}
                    />
                  </Col>
                  <Col sm={3} lg={3}>
                    <SearchBar
                      searchHandler={searchTaskTemplateHandler}
                      clearSearchInVisible={false}
                      // initialText={filter}
                      placeHolderTextId="checklistTemplateManagement.Search"
                      data-unittest="searchTaskTemplates"
                    />
                  </Col>
                  <Col className={s.addbuttonCol} style={WidgetVisibility(userProfileWidgets, `WQSP_TASKTEMPLATEADDNEW`)}>
                    <Link to="/operational-setup/task-template">
                      <Button className={s.addbutton}>
                        + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <ChecklistTasksTemplatesTable
                  tableData={processTaskTemplates()}
                  userProfileWidgets={userProfileWidgets}
                  viewName="WQSP_TASKTEMPLATEVIEW"
                />

                {((!isTaskFilter && taskTemplatesLength > initialLimit) || (isTaskFilter && localTaskTemplatesLength > initialLimit)) && (
                  <Button variant="outline-secondary" className="w-100" onClick={showAllTaskTemplateHandler} data-unittest="showButton">
                    {!showAllTaskTemplates
                      ? `${showAllText} (${!showAllTaskTemplates && !isTaskFilter ? taskTemplatesLength : localTaskTemplatesLength})`
                      : showLessText}
                  </Button>
                )}
              </div>
            </Collapse>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

ChecklistTasksTemplates.defaultProps = {
  taskTemplateManager: {
    ...initialTaskTemplatesState.taskTemplate
  },
  checklistTemplate: {
    ...initialChecklistTemplateState.checklistTemplate
  }
};

ChecklistTasksTemplates.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired,
  taskTemplateManager: PropTypes.object.isRequired,
  getTaskTemplates: PropTypes.func.isRequired,
  checklistTemplate: PropTypes.object.isRequired,
  getChecklistTemplate: PropTypes.func.isRequired
};

export default injectIntl(ChecklistTasksTemplates);
