import React from 'react';
import s from './QualityGoalsReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getDateInDDMMYYYHHMMSSFormat } from '../../utils/index';
import MoreOption from '../WSAControls/MoreOption/MoreOption';

const QualityGoalsReportListTable = ({
  paginatedData,
  onSortOrder,
  asendingOrder,
  ChangeQualityGoalsReport,
  UpdateAllowed,
  currentTimezone,
  deleteAllowed,
  removeQualityGoals
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
            </th>
            <th>
              <FormattedMessage id="qualityGoals.entity" defaultMessage="Entity" />
            </th>
            <th>
              <FormattedMessage id="qualityGoals.goalPurpose" defaultMessage="Goal Purpose" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="qualityGoals.goalDate" defaultMessage="Goal Date" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="qualityGoals.authorName" defaultMessage="Name of Author" />
            </th>

            {(UpdateAllowed || deleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.QualityGoalReportId}>
                <td>{n.SiteName || ''}</td>
                <td>{n.WaterSupplyDescription || ''}</td>
                <td>{n.EntityTypeName || ''}</td>
                <td>{n.EntityName || ''}</td>
                <td>{n.GoalPurpose || ''}</td>
                <td>{getDateInDDMMYYYHHMMSSFormat(n.GoalDate, currentTimezone) || ''}</td>
                <td>{n.AuthorName || ''}</td>
                <td className={s.btnCol}>
                  {(UpdateAllowed || deleteAllowed) && (
                    <MoreOption
                      dataArray={[
                        UpdateAllowed
                          ? {
                              Click: () => {
                                ChangeQualityGoalsReport(n.QualityGoalReportId, n.SiteId);
                              },
                              Name: <FormattedMessage id="common.edit" defaultMessage="EDIT" />
                            }
                          : {},
                        deleteAllowed
                          ? {
                              Click: () => removeQualityGoals(n.QualityGoalReportId),
                              Name: <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                            }
                          : {}
                      ]}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

QualityGoalsReportListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(QualityGoalsReportListTable);
