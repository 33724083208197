import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Checklists.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { initialChecklistState } from '../../store/reducers//initialState';
import ChecklistTable from './ChecklistTable';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE, CHECKLIST_STATUS_ACTIVE, CHECKLIST_STATUS_COMPLETE } from '../../constants';
import { IsWidgetAccessible, WidgetVisibility } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { compareValues } from '../../utils';
import TasksAndChecklistsContentTemplate from '../TasksAndChecklists/TasksAndChecklistsContentTemplate';

const STATUS = 'STATUS';
const ERP = 'ERP';
const SEARCH_FILTER = 'SEARCH_FILTER';

const Checklists = ({
  getChecklists,
  getFilter,
  setFilterData,
  setCurrentPage,
  setPageFilter,
  checklist,
  redirect,
  intl,
  userProfileWidgets,
  currentTimezone
}) => {
  let filter = checklist.filterBy.filter || '';
  let offset = checklist.offset || 0;
  let limit = checklist.limit || 1000;

  let filterByStatus = checklist.filterBy.filterStatus || null;
  let filterByERP = checklist.filterBy.filterERP === null ? null : checklist.filterBy.filterERP;

  const [searchFilter, setSearchFilter] = useState(filter === null ? '' : filter);
  const [filterStatus, setFilterStatus] = useState(filterByStatus === null ? '0' : filterByStatus);
  const [filterERP, setFilterERP] = useState(filterByERP === null ? '0' : filterByERP);
  const [asendingOrder, setAsendingOrder] = useState(false);

  useEffect(() => {
    getFilter();
  }, [getFilter]);

  useEffect(() => {
    let filterObj = {
      filterStatus: filterByStatus,
      filterERP: filterByERP,
      offset: offset,
      limit: limit,
      filter: filter
    };
    getChecklists(filterObj);
  }, [getChecklists, offset, limit, filter, filterByStatus, filterByERP]);

  const statusOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="filters.allstatus" defaultMessage="All Status" />,
      value: '0'
    });
    statusData.push({
      label: CHECKLIST_STATUS_ACTIVE,
      value: CHECKLIST_STATUS_ACTIVE
    });
    statusData.push({
      label: CHECKLIST_STATUS_COMPLETE,
      value: CHECKLIST_STATUS_COMPLETE
    });

    return statusData;
  };

  const onStatusChange = e => {
    setFilterStatus(e.value);
    filteredResults(STATUS, e.value);
    setCurrentPage(1);
  };

  const eRPOptions = () => {
    let eRPData = [];

    eRPData.push({
      label: <FormattedMessage id="checklist.allEmergencyResponsePlan" defaultMessage="All Emergency Response Plan" />,
      value: '0'
    });
    eRPData.push({
      label: <FormattedMessage id="common.no" defaultMessage="No" />,
      value: false
    });
    eRPData.push({
      label: <FormattedMessage id="common.yes" defaultMessage="Yes" />,
      value: true
    });

    return eRPData;
  };

  const onERPChange = e => {
    setFilterERP(e.value);
    filteredResults(ERP, e.value);
    setCurrentPage(1);
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  let checklistObj = Object.values(checklist.filteredResults);

  if (asendingOrder) {
    checklistObj = checklistObj.sort(compareValues('DueDateTime', 'desc'));
  } else {
    checklistObj = checklistObj.sort(compareValues('DueDateTime'));
  }

  const filteredResults = (filterType, value) => {
    let filteredList = checklist.Checklists;

    let apifilterObj = {
      filter: null,
      filterStatus: null,
      filterERP: null
    };

    if (filterType === STATUS) {
      if (value !== '0') {
        apifilterObj.filterStatus = value;
        filteredList = filteredList.filter(function(item) {
          return item.Status === value;
        });
      }
    } else {
      if (filterStatus !== '0') {
        apifilterObj.filterStatus = filterStatus;
        filteredList = filteredList.filter(function(item) {
          return item.Status === filterStatus;
        });
      }
    }

    if (filterType === ERP) {
      if (value !== '0') {
        apifilterObj.filterERP = value;
        filteredList = filteredList.filter(function(item) {
          return item.IsERP === value;
        });
      }
    } else {
      if (filterERP !== '0') {
        apifilterObj.filterERP = filterERP;
        filteredList = filteredList.filter(function(item) {
          return item.IsERP === filterERP;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (checklist.checklistCount > checklist.Checklists.length) {
      setPageFilter(apifilterObj);
    } else {
      setFilterData({ filteredResults: { filteredResults: filteredList } });
    }
  };

  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CHECKLISTVIEW');

  return (
    <TasksAndChecklistsContentTemplate selectedPage="myChecklists" userProfileWidgets={userProfileWidgets}>
      <div style={WidgetVisibility(userProfileWidgets, `WQSP_CHECKLISTLIST`)}>
        <div className={s.pageContent}>
          <div className={s.checklists}>
            {checklist.isLoading && <LoadingSpinner />}
            <div className={s.contentWrapper}>
              <Row>
                <Col sm={6} lg={4}>
                  <div className={s.topColumns}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={true}
                      initialText={searchFilter}
                      placeHolderTextId="checklist.searchChecklist"
                    />
                  </div>
                </Col>
                <Col sm={6} lg={8} style={WidgetVisibility(userProfileWidgets, `WQSP_CHECKLISTADDNEW`)}>
                  <Link to="/tasks-and-checklists/checklist-detail/0">
                    <Button className={s.addbutton}>
                      + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <span className={s.textHeading}>
                    {' '}
                    <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={4} lg={2}>
                  <div className={s.topColumns}>
                    <Dropdown
                      id="drpStatusId"
                      dataArray={statusOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.showAll" defaultMessage="Show: All" />,
                        customClassName: ''
                      }}
                      onDropdownChange={onStatusChange}
                      selectedOption={statusOptions().filter(option => option.value === filterStatus)}
                      disabled={false}
                    />
                  </div>
                </Col>
                <Col sm={4} lg={2}>
                  <div className={s.topColumns}>
                    <Dropdown
                      id="drpERPId"
                      dataArray={eRPOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.showAll" defaultMessage="Show: All" />,
                        customClassName: ''
                      }}
                      onDropdownChange={onERPChange}
                      selectedOption={eRPOptions().filter(option => option.value === filterERP)}
                      disabled={false}
                    />
                  </div>
                </Col>
              </Row>
              <div className="">
                <Pagination
                  data={checklistObj}
                  pageSize={DEFAULT_PAGE_SIZE}
                  onPageChange={onPageChangeHandler}
                  startingPage={checklist.currentPage}
                >
                  <ChecklistTable
                    redirect={redirect}
                    ViewAllowed={isViewAllowed}
                    currentPage={checklist.currentPage}
                    currentTimezone={currentTimezone}
                    onSortOrder={onSortOrder}
                    asendingOrder={asendingOrder}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TasksAndChecklistsContentTemplate>
  );
};

Checklists.defaultProps = {
  checklist: initialChecklistState.checklist
};

Checklists.propTypes = {
  setFilterData: PropTypes.func.isRequired,
  getChecklists: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  checklist: PropTypes.object.isRequired
};

export default injectIntl(Checklists);
