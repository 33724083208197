import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import s from './MapView.module.scss';
import bs from '../../../styles/bootstrap-overrides.scss';
import SiteTile from '../SiteTile/SiteTile';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import droplet from '../../../assets/droplet-icon.svg';
import pendingAlarm from '../../../assets/alarm-outline-small.svg';
import dropletClusterMarker from '../../../assets/droplet-with-number.png';
import fullScreen from '../../../assets/fullscreen-icon.svg';
import SearchBar from '../../SearchBar/SearchBar';
import Map from '../Map/Map';
import Markers from '../Map/Markers';
import MapInfoWindow from '../Map/MapInfoWindow';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

class MapView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapLoaded: false,
      markerClustererLoaded: false,
      markerDrawn: false,
      showMapOverlay: false,
      siteId: null,
      location: '',
      siteName: '',
      country: '',
      position: {},
      searchQuery: {
        keywords: '',
        type: 0
      },
      isFullScreen: false
    };
    this.mapOverlay = {};
    this.defaultLocation = { lat: -37.840935, lng: 144.946457 };
  }

  googleMapRef = React.createRef();

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  onMapClick = map => {
    this.removeMapOverlay(map);
  };

  onMapLoad = map => {
    this.setState({ mapLoaded: true });
  };

  onMarkerClusterLoad = markerCluster => {
    this.markerCluster = markerCluster;
    this.setState({ markerClustererLoaded: true });
  };

  handleTileClick = siteId => {
    if (this.props.handleClick) {
      this.props.handleClick(siteId);
    }
  };

  onMarkerClick = marker => {
    if (marker && marker.element && marker.element.SiteId) {
      this.setState({
        ...this.state,
        showMapOverlay: true,
        location: marker.element.Location,
        position: marker.position,
        siteName: marker.element.Name,
        siteId: marker.element.SiteId,
        nextReviewDate: marker.element.NextReviewDate
      });
    }
  };

  onMarkerReDrawn = () => {
    //Code to execute on marker redrawn
    if (!this.state.markerDrawn) {
      this.setState({ markerDrawn: true });
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  onMarkerDrawn = markerObj => {
    if (!this.state.markerDrawn) {
      this.setState({ markerDrawn: true });
    }
    this.removeMapOverlay();
  };

  onMarkerRemoved = markerObj => {
    this.removeMapOverlay();
  };

  removeMapOverlay = map => {
    this.setState({
      ...this.state,
      showMapOverlay: false,
      location: '',
      position: {},
      siteName: '',
      siteId: null
    });

    if (this.mapOverlay && this.mapOverlay.overlay && this.mapOverlay.overlay.setMap) {
      this.mapOverlay.overlay.setMap(null);
    }
  };

  getFormattedSites = () => {
    let sitesObj = Object.values(this.props.sites);
    let formattedSites = sitesObj.map(site => {
      let address = `${site.Location || ''}`;
      site.address = address;
      site.elementId = site.SiteId;
      let icon = site.NextReviewDate < moment().unix() ? pendingAlarm : droplet;
      site.icon = icon;
      //position
      if (site.GpsLatitude && site.GpsLongitude) {
        let newLat = parseFloat(site.GpsLatitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        let newLng = parseFloat(site.GpsLongitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        site.position = { lat: newLat, lng: newLng };
      } else {
        site.position = {
          lat: -37.840935 + (Math.random() * (0.00004 - 0.00001) + 0.00001),
          lng: 144.946457 + (Math.random() * (0.00004 - 0.00001) + 0.00001)
        };
      }
      return site;
    });
    return formattedSites;
  };

  onSearchKeywordChange = keyword => {
    this.removeMapOverlay();

    this.setState({
      searchQuery: {
        ...this.state.searchQuery,
        keywords: keyword
      }
    });
  };

  onSearchTypeChange = type => {
    this.removeMapOverlay();

    this.setState({
      searchQuery: {
        ...this.state.searchQuery,
        type: type.value
      }
    });
  };

  performSearch = sites => {
    if (this.state.searchQuery.keywords) {
      sites = sites.filter(site => site.address.toLowerCase().indexOf(this.state.searchQuery.keywords.toLowerCase()) >= 0);
    }
    return sites;
  };

  setOverlay = overlay => {
    this.mapOverlay = overlay;
  };

  onFullscreenButtonClick = id => {
    this.setState({ isFullScreen: true });

    let element = document.getElementById('mapViewContainer');
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
    if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
    if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    }
  };

  onFullScreenCloseButtonClick = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  onFullScreenExit = () => {
    this.setState({ isFullScreen: false });
  };

  render() {
    let formattedSites = this.getFormattedSites();
    formattedSites = this.performSearch(formattedSites);

    return (
      <div className={s.mapContainer} id="mapViewContainer">
        {!this.state.markerDrawn && <LoadingSpinner centeredLoading={false} />}
        <Row className={this.state.isFullScreen ? `d-md-flex ${s.fullScreenSearch}` : 'd-none d-md-flex'}>
          <div className={s.searchBarContainer}>
            <SearchBar searchHandler={this.onSearchKeywordChange} clearSearchInVisible={true} placeHolderTextId="site.location" />
          </div>
          <Col xs={12} md={4} lg={3} xl={2} className={s.mapDropdown}></Col>
        </Row>

        <Map
          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          mapContainerCssStyle={s.mapContainerStyle}
          onMapClick={this.onMapClick}
          onMapLoad={this.onMapLoad}
          onMarkerClusterLoad={this.onMarkerClusterLoad}
          fullScreenIcon={fullScreen}
          onFullScreenButtonClick={this.onFullscreenButtonClick}
          onFullScreenExit={this.onFullScreenExit}
          mapContainerStyleFullScreen={s.mapContainerStyleFullScreen}
        >
          <MapInfoWindow
            handleClick={siteId => {
              this.handleTileClick(siteId);
            }}
            position={this.state.position}
            onOverlayDrawn={this.setOverlay}
            hideOverlay={!this.state.showMapOverlay}
          >
            <ul className={s.siteWrapper} elementid={this.state.siteId} location={this.state.location}>
              <SiteTile
                location={this.state.location}
                siteName={this.state.siteName}
                nextReviewDate={this.state.nextReviewDate}
                siteId={this.state.siteId}
                handleClick={this.handleTileClick}
                currentTimezone={this.props.currentTimezone}
                languageObject={this.props.languageObject}
              />
            </ul>
          </MapInfoWindow>

          {this.state.mapLoaded && this.state.markerClustererLoaded && (
            <Markers
              onClick={this.onMarkerClick}
              onMarkerDrawn={this.onMarkerDrawn}
              onMarkerReDrawn={this.onMarkerReDrawn}
              onMarkerRemoved={this.onMarkerRemoved}
              elements={formattedSites}
              markerCluster={this.markerCluster}
              markerClusterIcon={dropletClusterMarker}
              defaultLocation={this.defaultLocation}
            />
          )}
        </Map>
        {!this.state.isFullScreen && (
          <div className={`container-fluid ${s.seeAllSites}`}>
            <Row className={s.alignCenter}>
              <Col md={5}>
                <hr />
              </Col>
              <Col md={2} xs={12}>
                <Link to={'/sites'}>
                  <Button>
                    <FormattedMessage id="mapview.seeallsites" defaultMessage="SEE ALL SITES" />
                  </Button>
                </Link>
              </Col>
              <Col md={5}>
                <hr />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

MapView.defaultProps = {
  sites: {}
};

MapView.propTypes = {
  sites: PropTypes.array,
  handleClick: PropTypes.func.isRequired
};

export default injectIntl(MapView);
