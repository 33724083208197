import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';

import {
  HAZARD_ERROR,
  GET_ALL_HAZARD_DETAILS,
  HAZARD_IS_LOADING,
  SET_HAZARD_CURRENT_PAGE,
  SET_HAZARD_PAGE_FILTER,
  SET_HAZARD_CHANGE,
  SET_HAZARD,
  UPDATE_HAZARD_SAVE_STATUS,
  SET_HAZARD_FILTERS,
  SET_HAZARD_RESULTS
} from '../../constants/index';

import { HAZARD_MANAGEMENT, CREATE_HAZARD, UPDATE_HAZARD } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setHazardErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setHazardError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setHazardError(messageCode, false));
  }, 2500);
};

export const setHazardError = (messageCode, status) => {
  return {
    type: HAZARD_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: HAZARD_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getHazardThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getHazard(widgetCode, orgId, filterObj));
};

export const getHazard = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/templates/hazard`,
    method: 'GET',
    data: {
      OrgId: orgId,
      filter: filterObj.filter,
      limit: filterObj.limit,
      offset: filterObj.offset
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllHazardListThunk(retData),
    onFailure: err => setHazardErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllHazardListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let hazards = (retData && retData.retData.data) || [];
  dispatch(setAllHazardList(messageCode, hazards));
};

export const setAllHazardList = (messageCode, hazards) => ({
  type: GET_ALL_HAZARD_DETAILS,
  payload: {
    data: {
      Hazards: hazards,
      filteredResults: hazards,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_HAZARD_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_HAZARD_PAGE_FILTER,
  payload: { filter }
});

export const describeHazardThunk = (HazardId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeHazard(HazardId, widgetCode, orgId));
};

export const describeHazard = (HazardId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/templates/hazard`,
    data: {
      HazardId: HazardId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setHazardThunk(retData, HazardId),
    onFailure: err => setHazardErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setHazardThunk = ({ retData }, HazardId) => dispatch => {
  let data = retData.data[0];
  if (HazardId && (!data || data.HazardId !== parseInt(HazardId))) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setHazard(data));
};

export const setHazard = data => ({
  type: SET_HAZARD,
  payload: { selectedHazard: data }
});

export const saveHazardThunk = (hazard, widgetCode) => dispatch => {
  let url = '/management/templates/hazard/save';
  let actionName = hazard.HazardId === 0 ? CREATE_HAZARD : UPDATE_HAZARD;
  let log = logEntry(HAZARD_MANAGEMENT, actionName, hazard);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  hazard.OrgId = orgId;
  dispatch(saveHazard(hazard, url, log, widgetCode));
};

export const saveHazard = (hazard, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setHazardStatusThunk(retData, hazard),
    onFailure: err => setHazardErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: hazard,
      log: log
    },
    widgetcode: widgetCode
  });

export const setHazardStatusThunk = (message, hazard) => dispatch => {
  dispatch(setHazardStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !hazard.PerformDelete;
    dispatch(setHazardStatus(message.message, false, isRedirect));
  }, 2500);
};

export const setHazardStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_HAZARD_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const setHazardChangeThunk = retData => dispatch => {
  dispatch(setHazardChange(retData));
};

export const setHazardChange = retData => ({
  type: SET_HAZARD_CHANGE,
  payload: retData
});

export const getFilterThunk = widgetCode => dispatch => {
  dispatch(getFilter(widgetCode));
};

export const getFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      filterFor: 'hazards'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setHazardErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_HAZARD_FILTERS,
  payload: {
    data: {
      isLoading: false,
      hazardListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_HAZARD_RESULTS,
  payload: retData
});
