import React from 'react';
import s from './ControlMonitoring.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import MoreOption from '../WSAControls/MoreOption/MoreOption';
import { useParams } from 'react-router-dom';
import { getDateInDDMMYYYFormat } from '../../utils/';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';

const ControlMonitoringListTable = ({
  paginatedData,
  deleteAllowed,
  removeControlMonitoring,
  asendingOrder,
  viewAllowed,
  onSortOrder,
  currentTimezone
}) => {
  let { segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'respond';
  selectedTab = selectedTab || '2';
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="controlMonitoring.unitComponentPosition" defaultMessage="Unit Component Position" />
            </th>
            <th>
              <FormattedMessage id="controlMonitoring.stakeholder" defaultMessage="Stakeholder" />
            </th>
            <th>
              <FormattedMessage id="controlMonitoring.monitoredItem" defaultMessage="Monitored Item" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            {(deleteAllowed || viewAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.ModelComponentPositionName}</td>
                <td>{item.PersonnelName}</td>
                <td>{item.MonitoredItem}</td>
                <td>{getDateInDDMMYYYFormat(item.LastReviewDate, currentTimezone) || ''}</td>

                {deleteAllowed && (
                  <td className={s.btnCol}>
                    <MoreOption
                      dataArray={[
                        deleteAllowed
                          ? {
                              Click: () => {
                                removeControlMonitoring(item.ControlMonitoringId);
                              },
                              Name: <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                            }
                          : {},
                        viewAllowed
                          ? {
                              Url: `/site/${item.SiteId}/${segmentName}/${selectedTab}/control-monitorings/control-monitoring-view/${item.ControlMonitoringId}`,
                              Name: <FormattedMessage id="common.view" defaultMessage="VIEW" />
                            }
                          : {}
                      ]}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ControlMonitoringListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(ControlMonitoringListTable);
