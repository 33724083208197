import { initialControlMeasureState } from '../initialState';

import {
  CONTROL_MEASURE_ERROR,
  GET_ALL_CONTROL_MEASURE_DETAILS,
  CONTROL_MEASURE_IS_LOADING,
  SET_CONTROL_MEASURE_CURRENT_PAGE,
  SET_CONTROL_MEASURE_PAGE_FILTER,
  SET_CONTROL_MEASURE_CHANGE,
  SET_CONTROL_MEASURE,
  UPDATE_CONTROL_MEASURE_SAVE_STATUS,
  SET_CONTROL_MEASURE_FILTERS,
  SET_CONTROL_MEASURE_RESULTS,
  REMOVE_CONTROL_MEASURE,
  SET_CONTROL_MONITORING_CHANGE,
  SET_CORRECTIVE_ACTION_CHANGE,
  SET_CONTROL_MONITORING,
  SET_CORRECTIVE_ACTION,
  SET_CORRECTIVE_ACTION_COMMENTS,
  UPDATE_CONTROL_MONITORING_SAVE_STATUS,
  UPDATE_CORRECTIVE_ACTION_SAVE_STATUS,
  GET_ALL_MODELCOMPONENTPOSITION_DETAILS,
  REMOVE_CONTROL_MONITORING
} from '../../../constants/index';

const controlMeasureReducer = (state = initialControlMeasureState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_CONTROL_MEASURE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          ...data
        }
      };
    }

    case SET_CONTROL_MEASURE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          currentPage
        }
      };
    }

    case SET_CONTROL_MEASURE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_CONTROL_MEASURE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          ...data
        }
      };
    }

    case CONTROL_MEASURE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          isLoading: isLoading
        }
      };
    }

    case CONTROL_MEASURE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          ...data
        }
      };
    }

    case SET_CONTROL_MEASURE: {
      let { selectedControlMeasure } = payload;

      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          isOpSuccessful: false,
          showBanner: false,
          selectedControlMeasure: {
            ...state.controlMeasure.selectedControlMeasure,
            ...selectedControlMeasure
          }
        }
      };
    }

    case SET_CONTROL_MEASURE_CHANGE: {
      let { selectedControlMeasure } = payload;

      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedControlMeasure: {
            ...state.controlMeasure.selectedControlMeasure,
            ...selectedControlMeasure
          }
        }
      };
    }

    case SET_CONTROL_MEASURE_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          ...filteredResults
        }
      };
    }

    case REMOVE_CONTROL_MEASURE: {
      const { ControlMeasureId } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          controlMeasures: [...state.controlMeasure.controlMeasures.filter(item => item.ControlMeasureId !== ControlMeasureId)],
          filteredResults: [...state.controlMeasure.filteredResults.filter(item => item.ControlMeasureId !== ControlMeasureId)],
          controlMeasureListCount: state.controlMeasure.controlMeasureListCount - 1
        }
      };
    }

    case SET_CONTROL_MEASURE_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          ...data
        }
      };
    }

    //Control Montorings
    case SET_CONTROL_MONITORING: {
      let { selectedControlMonitoring } = payload;

      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          isOpSuccessful: false,
          showBanner: false,
          isControlMonitoringRedirect: false,
          selectedControlMeasure: {
            ...state.controlMeasure.selectedControlMeasure,
            selectedControlMonitoring: {
              ...state.controlMeasure.selectedControlMeasure.selectedControlMonitoring,
              ...selectedControlMonitoring
            }
          }
        }
      };
    }

    case UPDATE_CONTROL_MONITORING_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          ...data
        }
      };
    }

    case SET_CONTROL_MONITORING_CHANGE: {
      let { selectedControlMonitoring } = payload;

      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          isOpSuccessful: false,
          showBanner: false,
          isControlMonitoringRedirect: false,
          selectedControlMeasure: {
            ...state.controlMeasure.selectedControlMeasure,
            selectedControlMonitoring: {
              ...state.controlMeasure.selectedControlMeasure.selectedControlMonitoring,
              ...selectedControlMonitoring
            }
          }
        }
      };
    }

    case REMOVE_CONTROL_MONITORING: {
      const { ControlMonitoringId } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          selectedControlMeasure: {
            ...state.controlMeasure.selectedControlMeasure,
            controlMonitorings: [
              ...state.controlMeasure.selectedControlMeasure.controlMonitorings.filter(
                item => item.ControlMonitoringId !== ControlMonitoringId
              )
            ]
          }
        }
      };
    }

    //Corrective Actions
    case SET_CORRECTIVE_ACTION: {
      let { selectedCorrectiveAction } = payload;

      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          isOpSuccessful: false,
          showBanner: false,
          selectedControlMeasure: {
            ...state.controlMeasure.selectedControlMeasure,
            selectedControlMonitoring: {
              ...state.controlMeasure.selectedControlMeasure.selectedControlMonitoring,
              selectedCorrectiveAction: {
                ...state.controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction,
                ...selectedCorrectiveAction
              }
            }
          }
        }
      };
    }

    case UPDATE_CORRECTIVE_ACTION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          ...data
        }
      };
    }

    case SET_CORRECTIVE_ACTION_COMMENTS: {
      const { selectedCorrectiveAction } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          selectedControlMeasure: {
            ...state.controlMeasure.selectedControlMeasure,
            selectedControlMonitoring: {
              ...state.controlMeasure.selectedControlMeasure.selectedControlMonitoring,
              selectedCorrectiveAction: {
                ...state.controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction,
                ...selectedCorrectiveAction
              }
            }
          }
        }
      };
    }

    case SET_CORRECTIVE_ACTION_CHANGE: {
      let { selectedCorrectiveAction } = payload;

      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedControlMeasure: {
            ...state.controlMeasure.selectedControlMeasure,
            selectedControlMonitoring: {
              ...state.controlMeasure.selectedControlMeasure.selectedControlMonitoring,
              selectedCorrectiveAction: {
                ...state.controlMeasure.selectedControlMeasure.selectedControlMonitoring.selectedCorrectiveAction,
                ...selectedCorrectiveAction
              }
            }
          }
        }
      };
    }

    case GET_ALL_MODELCOMPONENTPOSITION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        controlMeasure: {
          ...state.controlMeasure,
          selectedControlMeasure: {
            ...state.controlMeasure.selectedControlMeasure,
            ...data
          }
        }
      };
    }

    default:
      return state;
  }
};

export default controlMeasureReducer;
