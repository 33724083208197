import React, { useEffect, useState } from 'react';
import s from './TestCategory.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getTestCategory } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialTestCategoryState } from '../../store/reducers/initialState';
import { getTestCategoryThunk, saveTestCategoryThunk } from '../../store/actions/testCategories';
import TestCategoryListTable from './TestCategoryListTable';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const TestCategoryList = ({ userProfileWidgets, intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [testCategoryOpen, setTestCategoryOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [testCategoryId, setTestCategoryId] = useState(0);
  const [isFilter, setIsFilter] = useState(false);
  const [searchText, setSearchText] = useState('');

  const testCategory = useSelector(state => getTestCategory(state));

  useEffect(() => {
    dispatch(getTestCategoryThunk('WQSP_ADMINTESTCATEGORY_LIST'));
  }, [getTestCategoryThunk, dispatch, testCategory.isOpSuccessful]);

  const DeleteTestCategory = () => {
    let saveData = testCategory.selectedTestCategory;
    saveData.PerformDelete = true;
    saveData.TestCategoryId = testCategoryId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveTestCategoryThunk(saveData, 'WQSP_TESTCATEGORY_DELETE'));
  };

  const onRemoveTestCategory = testCategoryId => {
    setActive(true);
    setTestCategoryId(testCategoryId);
  };

  const handleClose = () => {
    setActive(false);
    setTestCategoryId(0);
  };

  const handleConfirm = () => {
    DeleteTestCategory();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push('/operational-setup/test-results/test-category');
  };

  const dialogTitle = intl.formatMessage({
    id: 'testCategories.confirmDelete',
    defaultMessage: 'Are you sure you want to delete test category?'
  });

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);
  };

  let messageId = (testCategory && testCategory.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.testCategoryList}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'WQSP_ADMINTESTCATEGORY_LIST')}>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={testCategoryOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setTestCategoryOpen(!testCategoryOpen)}
                aria-controls="testCategory-type-section"
                aria-expanded={testCategoryOpen}
              ></img>
              <h4>
                <FormattedMessage id="testCategory.testCategories" defaultMessage="Test Categories" />
              </h4>
              <span className={s.circle}>{testCategory.TestCategories.length}</span>
            </Row>
          </Col>
        </Row>
        <Collapse in={testCategoryOpen}>
          <div className={s.innerComponent} id="testCategory-type-section">
            <div className={s.component}>
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />

              {testCategory.isLoading && <LoadingSpinner />}
              {testCategory.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={testCategory.showBanner}
                  status={testCategory.isOpSuccessful}
                  successText={messageText}
                />
              )}

              <div className={s.contentWrapper}>
                <Row className={s.subSection}>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={testCategory.showAddEditScreen}
                      placeHolderTextId="common.search"
                      data-unittest="searchTestCategories"
                    />
                  </Col>
                  <Col sm={6} lg={8}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'WQSP_TESTCATEGORY_ADDNEW')}
                      onClick={onAddNewClick}
                      disabled={testCategory.showAddEditScreen}
                      data-unittest="addTestCategoryButton"
                    >
                      + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className={s.testCategoryTypeList}>
                {testCategory.TestCategories.map(cat => {
                  return (
                    <div key={cat.TestCategoryId}>
                      <TestCategoryTypeList
                        testCategory={cat}
                        searchText={searchText}
                        onRemoveTestCategory={onRemoveTestCategory}
                        isFilter={isFilter}
                        userProfileWidgets={userProfileWidgets}
                        intl={intl}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

const TestCategoryTypeList = ({ testCategory, searchText, isFilter, userProfileWidgets, onRemoveTestCategory, intl }) => {
  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;
  const history = useHistory();
  let testCategoryTypes = testCategory.testCategoryTypes;
  let testCategoryId = testCategory.TestCategoryId;

  let initialRecords = testCategoryTypes.slice(0, initialLimit);

  const [localTestCategoryTypes, setLocalTestCategoryTypes] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);

  const localTestCategoryTypeLength = localTestCategoryTypes.length;
  const testCategoryTypeLength = testCategoryTypes.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalTestCategoryTypes(testCategoryTypes);
    }
    setShowAll(!showAll);
  };

  useEffect(() => {
    let filteredList = testCategoryTypes;
    if (isFilter) {
      filteredList = filteredList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, initialLimit));
    }
    setLocalTestCategoryTypes(filteredList);
  }, [searchText]);

  const filterData = () => {
    let filteredList = testCategoryTypes;
    if (isFilter) {
      filteredList = filteredList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }
    return filteredList;
  };

  const getTestCategoryTypeData = () => {
    let testCategory = [];
    if (showAll) {
      testCategory = filterData();
    } else {
      if (isFilter) {
        testCategory = localTestCategoryTypeLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        testCategory = initialRecords;
      }
    }
    return testCategory.sort(compareValues('Name'));
  };

  const onChangeTestCategoryClick = testCategoryId => {
    history.push(`/operational-setup/test-results/test-category/${testCategoryId}`);
  };

  const onRemoveTestCategoryClick = testCategoryId => {
    onRemoveTestCategory(testCategoryId);
  };

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  return (
    <div className={s.contentWrapper} key={testCategoryId}>
      <Row className={s.subSection}>
        <Col sm={9} lg={8}>
          <h4>{testCategory.Name}</h4>
          <p>{testCategory.Description}</p>
        </Col>
        <Col sm={3} lg={4}>
          {false && (
            <Button
              className={s.changeButton}
              style={WidgetVisibility(userProfileWidgets, 'WQSP_TESTCATEGORY_DELETE')}
              onClick={() => {
                onRemoveTestCategoryClick(testCategoryId);
              }}
              data-unittest="editTestCategoryButton"
            >
              <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
            </Button>
          )}

          <Button
            className={s.changeButton}
            style={WidgetVisibility(userProfileWidgets, 'WQSP_TESTCATEGORY_EDIT')}
            onClick={() => {
              onChangeTestCategoryClick(testCategoryId);
            }}
            data-unittest="editTestCategoryButton"
          >
            <FormattedMessage id="testCategory.change" defaultMessage="CHANGE" />
          </Button>
        </Col>
      </Row>

      <TestCategoryListTable key={`listTable${testCategoryId}`} testCategoryTypeData={getTestCategoryTypeData()} />
      <Row className={s.topRow}>
        {((!isFilter && testCategoryTypeLength > initialLimit) || (isFilter && localTestCategoryTypeLength > initialLimit)) && (
          <Col>
            <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
              {!showAll ? `${showAllText} (${!showAll && !isFilter ? testCategoryTypeLength : localTestCategoryTypeLength})` : showLessText}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

TestCategoryTypeList.defaultProps = {
  testCategoryTypes: []
};

TestCategoryList.defaultProps = {
  ...initialTestCategoryState
};

export default injectIntl(TestCategoryList);
