import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';

import { GET_GENERAL_DATA, GENERAL_DATA_IS_LOADING, GENERAL_DATA_ERROR } from '../../constants/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const getGeneralDataThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  dispatch(getGeneralData(widgetCode, orgId));
};

export const getGeneralData = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/listing`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAlllGeneralDataListThunk(retData),
    onFailure: err => setGeneralDataErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAlllGeneralDataListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.data) || [];
  dispatch(setAllGeneralDataList(messageCode, data));
};

export const setAllGeneralDataList = (messageCode, data) => ({
  type: GET_GENERAL_DATA,
  payload: {
    data: {
      generalData: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setLoadingStatus = isLoading => ({
  type: GENERAL_DATA_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const setGeneralDataErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setGeneralDataError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setGeneralDataError(messageCode, false));
  }, 2500);
};

export const setGeneralDataError = (messageCode, status) => {
  return {
    type: GENERAL_DATA_ERROR,
    payload: {
      data: {
        isOpSuccessfull: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};
