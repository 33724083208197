import React from 'react';
import PropTypes from 'prop-types';
import s from './User.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import trashWhite from '../../assets/trash-can-white.svg';
import trashGrey from '../../assets/trash-can-grey.svg';
import uuidv4 from 'uuid/v4';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import equal from 'fast-deep-equal';

import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Cookies from 'js-cookie';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

class UserProfiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableAddButton: true,
      selectedProfile: {
        ProfileId: 0
      }
    };
  }

  profileDropdownSelectedVal = {};
  activeProfiles = [];
  selectedOrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  componentDidMount() {
    this.setAssignedProfiles();
  }

  setAssignedProfiles() {
    if (this.props.profiles && Array.isArray(this.props.profiles)) {
      this.activeProfiles = this.props.profiles.filter(p => p.IsActive && !p.IsDeleted);

      //setting isDisabled=true for already selected profiles
      this.activeProfiles = this.activeProfiles.filter(profile => {
        if (
          this.props.selectedProfiles &&
          this.props.selectedProfiles.filter(selProfile => selProfile.ProfileId === profile.ProfileId).length === 1
        )
          profile.isDisabled = true;
        else profile.isDisabled = false;
        return profile;
      });
    }
    this.setState({ id: uuidv4() });
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.selectedProfiles, prevProps.selectedProfiles) || !equal(this.props.profiles, prevProps.profiles)) {
      this.setAssignedProfiles();
    }
  }

  onProfileSelected = e => {
    this.setState({ selectedProfile: e });
    this.props.onSelectedProfileClick(e);
  };

  onProfileDropdownChange = e => {
    this.profileDropdownSelectedVal = e;
    this.setState({ disableAddButton: false });
  };

  onAddProfileButtonClick = e => {
    if (
      this.profileDropdownSelectedVal &&
      this.profileDropdownSelectedVal.ProfileId &&
      this.props.selectedProfiles.filter(prof => prof.ProfileId === this.profileDropdownSelectedVal.ProfileId).length === 0
    ) {
      let { selectedProfiles } = { ...this.props };
      let newProfile = {
        ...this.profileDropdownSelectedVal,
        Id: uuidv4(),
        IsActive: true,
        IsDeleted: false
      };
      selectedProfiles.push(newProfile);
      this.props.onSelectedProfileListUpdated(selectedProfiles);
      this.setAssignedProfiles();
      this.onProfileSelected(e);
      this.onProfileSelected(newProfile);
    }
    this.profileDropdownSelectedVal = {};
  };

  onRemoveProfileButtonClick = e => {
    if (e && e.ProfileId) {
      let selectedProfiles = this.props.selectedProfiles.filter(prof => prof.ProfileId !== e.ProfileId);
      this.props.onSelectedProfileListUpdated(selectedProfiles);
      this.setAssignedProfiles();
    }
  };

  render() {
    return (
      <div key={this.state.id} className={s.userProfiles}>
        <Row className={s.row}>
          <Col sm={9} xs={12}>
            <Dropdown
              id="ddlProfiles"
              dataArray={this.activeProfiles.map(profile => {
                return {
                  ...profile,
                  label: profile.Name,
                  value: profile.ProfileId
                };
              })}
              controlData={{ placeholderText: 'Select Profile', customClassName: '' }}
              onDropdownChange={this.onProfileDropdownChange}
              defaultValue={this.profileDropdownSelectedVal}
            />
          </Col>
          <Col sm={3} xs={12}>
            <Button
              disabled={this.state.disableAddButton}
              onClick={this.onAddProfileButtonClick}
              variant="primary"
              className={`${s.btnAdd}`}
              noValidate
            >
              <FormattedMessage id="common.add" defaultMessage="Add" />
            </Button>
          </Col>
        </Row>
        <Row className={s.selectedProfilesNUnits}>
          <Col sm={12} className={s.selectedCols}>
            <Table variant className={s.table}>
              <tbody>
                {this.props.selectedProfiles.map((elem, index) => {
                  let selectedCssClass = elem.ProfileId === this.state.selectedProfile.ProfileId ? s.selected : null;
                  let trashIcon = elem.ProfileId === this.state.selectedProfile.ProfileId ? trashWhite : trashGrey;
                  return (
                    <tr
                      data-unittest="uerprofile"
                      key={index}
                      onClick={() => {
                        this.onProfileSelected(elem);
                      }}
                    >
                      <td
                        className={`d-none d-sm-table-cell ${selectedCssClass}`}
                        onClick={() => {
                          this.onRemoveProfileButtonClick(elem);
                        }}
                      >
                        <img src={trashIcon} alt="icon"></img>
                      </td>
                      <td className={selectedCssClass}> {elem.Name}</td>
                      <td className={selectedCssClass}>
                        <span className="float-right"></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}

UserProfiles.defaultProps = {
  profiles: [],
  selectedProfiles: [],
  id: uuidv4(),
  disableAddButton: true,
  selectedProfile: {
    ProfileId: 0
  }
};

UserProfiles.propTypes = {
  onProfileChange: PropTypes.func
};

export default injectIntl(UserProfiles);
