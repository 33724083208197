import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './TestPurpose.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const TestPurposeListTable = ({ testPurposeData, removeTestPurpose, changeTestPurpose, updateAllowed, deleteAllowed, intl }) => {
  return (
    <div>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="common.description" defaultMessage="Description" />
            </th>
            {(updateAllowed || deleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {testPurposeData.map(n => {
            return (
              <Fragment key={n.TestPurposeId}>
                <tr data-unittest="testPurposeData">
                  <td>{n.Name}</td>
                  <td>{n.Description}</td>
                  {(updateAllowed || deleteAllowed) && (
                    <td className={s.btnCol}>
                      {updateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={() => {
                            changeTestPurpose(n.TestPurposeId);
                          }}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                        </Button>
                      )}
                      {deleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.removeButton}
                          href="#"
                          onClick={() => {
                            removeTestPurpose(n.TestPurposeId);
                          }}
                          data-unittest="removeData"
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

TestPurposeListTable.defaultProps = {
  testPurposeData: []
};

TestPurposeListTable.propTypes = {
  testPurposeData: PropTypes.array.isRequired,
  removeTestPurpose: PropTypes.func.isRequired,
  changeTestPurpose: PropTypes.func.isRequired,
  updateAllowed: PropTypes.bool,
  deleteAllowed: PropTypes.bool
};

export default injectIntl(TestPurposeListTable);
