import { initialEmergencyResponsePlanState } from '../initialState';

import {
  EMERGENCY_RESPONSE_PLAN_ERROR,
  GET_ALL_EMERGENCY_RESPONSE_PLAN_DETAILS,
  EMERGENCY_RESPONSE_PLAN_IS_LOADING,
  SET_EMERGENCY_RESPONSE_PLAN_CURRENT_PAGE,
  SET_EMERGENCY_RESPONSE_PLAN_PAGE_FILTER,
  SET_EMERGENCY_RESPONSE_PLAN_CHANGE,
  SET_EMERGENCY_RESPONSE_PLAN,
  UPDATE_EMERGENCY_RESPONSE_PLAN_SAVE_STATUS,
  SET_EMERGENCY_RESPONSE_PLAN_FILTERS,
  SET_EMERGENCY_RESPONSE_PLAN_RESULTS,
  SET_EMERGENCY_RESPONSE_PLAN_COMMENTS,
  REMOVE_EMERGENCY_RESPONSE_PLAN
} from '../../../constants/index';

const emergencyResponsePlanReducer = (state = initialEmergencyResponsePlanState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_EMERGENCY_RESPONSE_PLAN_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          ...data
        }
      };
    }

    case SET_EMERGENCY_RESPONSE_PLAN_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          currentPage
        }
      };
    }

    case SET_EMERGENCY_RESPONSE_PLAN_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_EMERGENCY_RESPONSE_PLAN_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          ...data
        }
      };
    }

    case SET_EMERGENCY_RESPONSE_PLAN_COMMENTS: {
      const { selectedEmergencyResponsePlan } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          selectedEmergencyResponsePlan: {
            ...state.emergencyResponsePlan.selectedEmergencyResponsePlan,
            ...selectedEmergencyResponsePlan
          }
        }
      };
    }

    case REMOVE_EMERGENCY_RESPONSE_PLAN: {
      const { emergencyResponsePlanId } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          emergencyResponsePlans: [
            ...state.emergencyResponsePlan.emergencyResponsePlans.filter(item => item.EmergencyResponsePlanId !== emergencyResponsePlanId)
          ],
          filteredResults: [
            ...state.emergencyResponsePlan.filteredResults.filter(item => item.EmergencyResponsePlanId !== emergencyResponsePlanId)
          ],
          emergencyResponsePlanListCount: state.emergencyResponsePlan.emergencyResponsePlanListCount - 1
        }
      };
    }

    case EMERGENCY_RESPONSE_PLAN_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          isLoading: isLoading
        }
      };
    }

    case EMERGENCY_RESPONSE_PLAN_ERROR: {
      const { data } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          ...data
        }
      };
    }

    case SET_EMERGENCY_RESPONSE_PLAN: {
      let { selectedEmergencyResponsePlan } = payload;

      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          isOpSuccessful: false,
          showBanner: false,
          selectedEmergencyResponsePlan: {
            ...state.emergencyResponsePlan.selectedEmergencyResponsePlan,
            ...selectedEmergencyResponsePlan
          }
        }
      };
    }

    case SET_EMERGENCY_RESPONSE_PLAN_CHANGE: {
      let { selectedEmergencyResponsePlan } = payload;

      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedEmergencyResponsePlan: {
            ...state.emergencyResponsePlan.selectedEmergencyResponsePlan,
            ...selectedEmergencyResponsePlan
          }
        }
      };
    }

    case SET_EMERGENCY_RESPONSE_PLAN_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          ...filteredResults
        }
      };
    }

    case SET_EMERGENCY_RESPONSE_PLAN_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        emergencyResponsePlan: {
          ...state.emergencyResponsePlan,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default emergencyResponsePlanReducer;
