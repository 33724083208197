import React from 'react';
import PropTypes from 'prop-types';
import s from './LanguageSelector.module.scss';
import InputTemplate from '../InputTemplate/InputTemplate';
import Cookies from 'js-cookie';
import { LANGUAGE_TYPES, DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';
import { injectIntl } from 'react-intl';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const LanguageSelector = ({ updateLanguage, currentLocale, intl }) => {
  const handleChange = selectedLocale => {
    updateLanguage(selectedLocale);
    Cookies.set(`defaultLanguage-${envName}`, selectedLocale, { domain: DOMAIN_NAME, secure: IS_LOCALHOST ? false : true });
  };

  const translatedLanguage = intl.formatMessage({ id: 'settings.language', defaultMessage: 'Language  ' });

  return (
    <InputTemplate label={translatedLanguage}>
      <select className={s.languageSelect} onChange={e => handleChange(e.target.value)} value={currentLocale}>
        {Object.keys(LANGUAGE_TYPES).map(key => (
          <option key={key} value={key}>
            {LANGUAGE_TYPES[key].name}
          </option>
        ))}
      </select>
    </InputTemplate>
  );
};

LanguageSelector.propTypes = {
  updateLanguage: PropTypes.func,
  currentLocale: PropTypes.string
};

export default injectIntl(LanguageSelector);
