import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './InspectionType.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const InspectionTypeListTable = ({
  InspectionTypeData,
  RemoveInspectionType,
  ChangeInspectionType,
  UpdateAllowed,
  DeleteAllowed,
  intl
}) => {
  return (
    <div>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.title" defaultMessage="Title" />
            </th>
            <th>
              <FormattedMessage id="common.description" defaultMessage="Description" />
            </th>
            {(UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {InspectionTypeData.map(n => {
            return (
              <Fragment key={n.InspectionTypeId}>
                <tr data-unittest="inspectionTypeData">
                  <td>{n.Name}</td>
                  <td>{n.Description}</td>
                  {(UpdateAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={() => {
                            ChangeInspectionType(n.InspectionTypeId);
                          }}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.removeButton}
                          href="#"
                          onClick={() => {
                            RemoveInspectionType(n.InspectionTypeId);
                          }}
                          data-unittest="removeData"
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

InspectionTypeListTable.defaultProps = {
  InspectionTypeData: []
};

InspectionTypeListTable.propTypes = {
  InspectionTypeData: PropTypes.array.isRequired,
  RemoveInspectionType: PropTypes.func.isRequired,
  ChangeInspectionType: PropTypes.func.isRequired,
  UpdateAllowed: PropTypes.bool,
  DeleteAllowed: PropTypes.bool
};

export default injectIntl(InspectionTypeListTable);
