import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './AdminControlsTabs.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const Tab = ({ selectedPage, pageComponent, url, title }) => (
  <Fragment>
    <Link className={classNames({ [s.active]: selectedPage === pageComponent })} to={url}>
      {title}
      {selectedPage === pageComponent && <span />}
    </Link>
  </Fragment>
);

const AdminControlsTabs = ({ selectedPage, userProfileWidgets }) => (
  <ul className={s.tabNav}>
    {IsWidgetAccessible(userProfileWidgets, 'ORGANISATION_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/organisation-list`}
          title={<FormattedMessage id="AdminControlsTabs.manageOrganisations" defaultMessage="Organisations" />}
          selectedPage={selectedPage}
          pageComponent="manageOrganisations"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'USERS_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/user-list`}
          title={<FormattedMessage id="AdminControlsTabs.manageUsers" defaultMessage="Users" />}
          selectedPage={selectedPage}
          pageComponent="manageUsers"
        />
      </li>
    )}

    {IsWidgetAccessible(userProfileWidgets, 'PROFILES_TAB') && (
      <li>
        <Tab
          url={`/admin-controls/profile-list`}
          title={<FormattedMessage id="AdminControlsTabs.manageProfiles" defaultMessage="Profiles" />}
          selectedPage={selectedPage}
          pageComponent="manageProfiles"
        />
      </li>
    )}
  </ul>
);

AdminControlsTabs.propTypes = {
  selectedPage: PropTypes.string.isRequired
};

export default AdminControlsTabs;
