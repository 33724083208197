import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';

import {
  TASK_TEMPLATE_ERROR,
  GET_ALL_TASK_TEMPLATE_DETAILS,
  TASK_TEMPLATE_IS_LOADING,
  SET_TASK_TEMPLATE_CURRENT_PAGE,
  SET_TASK_TEMPLATE_PAGE_FILTER,
  SET_TASK_TEMPLATE_CHANGE,
  SET_TASK_TEMPLATE,
  UPDATE_TASK_TEMPLATE_SAVE_STATUS
} from '../../constants/index';

import { TASK_TEMPLATE_MANAGEMENT, CREATE_TASK_TEMPLATE, UPDATE_TASK_TEMPLATE } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setTaskTemplateErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setTaskTemplateError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTaskTemplateError(messageCode, false));
  }, 2500);
};

export const setTaskTemplateError = (messageCode, status) => {
  return {
    type: TASK_TEMPLATE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: TASK_TEMPLATE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getTaskTemplateThunk = widgetCode => dispatch => {
  dispatch(getTaskTemplate(widgetCode));
};

export const getTaskTemplate = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/templates/tasks`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllTaskTemplateListThunk(retData),
    onFailure: err => setTaskTemplateErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllTaskTemplateListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let taskTemplates = (retData && retData.retData.taskTemplate) || [];
  dispatch(setAllTaskTemplateList(messageCode, taskTemplates));
};

export const setAllTaskTemplateList = (messageCode, taskTemplates) => ({
  type: GET_ALL_TASK_TEMPLATE_DETAILS,
  payload: {
    data: {
      TaskTemplates: taskTemplates,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_TASK_TEMPLATE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_TASK_TEMPLATE_PAGE_FILTER,
  payload: { filter }
});

export const describeTaskTemplateThunk = (TaskTemplateId, widgetCode) => dispatch => {
  dispatch(describeTaskTemplate(TaskTemplateId, widgetCode));
};

export const describeTaskTemplate = (TaskTemplateId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/templates/tasks`,
    data: {
      TaskTemplateId: TaskTemplateId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTaskTemplateThunk(retData),
    onFailure: err => setTaskTemplateErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setTaskTemplateThunk = ({ retData }) => dispatch => {
  let data = retData.taskTemplate.length > 0 ? retData.taskTemplate[0] : [];
  dispatch(setTaskTemplate(data));
};

export const setTaskTemplate = data => ({
  type: SET_TASK_TEMPLATE,
  payload: {
    SelectedTaskTemplate: {
      TaskTemplateId: data.TaskTemplateId || 0,
      OrganisationId: data.OrganisationId || 0,
      Name: data.Name || '',
      Description: data.Description || '',
      IsERP: data.IsERP || false,
      GuidIdentifier: data.GuidIdentifier || '',
      Activities: data.Activities || '',
      EstimatedEffort: data.EstimatedEffort || 0,
      AssignedToProfileId: data.AssignedToProfileId || null,
      ProfileName: data.ProfileName || '',
      IsActive: data.IsActive || '',
      CreatedDateTime: data.CreatedDateTime || 0,
      ModifiedDateTime: data.ModifiedDateTime || 0
    },
    isOpSuccessful: false
  }
});

export const saveTaskTemplateThunk = (taskTemplate, widgetCode) => dispatch => {
  let url = '/management/templates/tasks/save';
  let actionName = taskTemplate.TaskTemplateId === 0 ? CREATE_TASK_TEMPLATE : UPDATE_TASK_TEMPLATE;
  let log = logEntry(TASK_TEMPLATE_MANAGEMENT, actionName, taskTemplate);
  dispatch(saveTaskTemplate(taskTemplate, url, log, widgetCode));
};

export const saveTaskTemplate = (taskTemplate, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTaskTemplateStatusThunk(retData),
    onFailure: err => setTaskTemplateErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      taskTemplate: taskTemplate,
      log: log
    },
    widgetcode: widgetCode
  });

export const setTaskTemplateStatusThunk = message => dispatch => {
  dispatch(setTaskTemplateStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTaskTemplateStatus('', false));
  }, 2500);
};

export const setTaskTemplateStatus = (messageCode, status) => ({
  type: UPDATE_TASK_TEMPLATE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setTaskTemplateChangeThunk = retData => dispatch => {
  dispatch(setTaskTemplateChange(retData));
};

export const setTaskTemplateChange = retData => ({
  type: SET_TASK_TEMPLATE_CHANGE,
  payload: retData
});
