import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './HazardEvent.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import HazardEventListTable from './HazardEventListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE, HAZARD_EVENT_CERTAINTY, HAZARD_CLASS } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import { getUserProfileWidget, getHazardEvent, getGeneralData } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import {
  getHazardEventThunk,
  getFilterThunk,
  setFilteredDataThunk,
  saveHazardEventThunk,
  setCurrentPage,
  setPageFilter
} from '../../store/actions/hazardEvent/hazardEvent';
import Breadcrumb from '../WSAControls/Breadcrumb';

const SITE = 'SITE';
const WATERSUPPLY_DESCRIPTION = 'WATERSUPPLY_DESCRIPTION';
const ENTITY_TYPE = 'ENTITY_TYPE';
const ENTITY = 'ENTITY';
const HAZARD_CLASS_TYPE = 'HAZARD_CLASS_TYPE';
const HAZARD_CERTAINTY_TYPE = 'HAZARD_CERTAINTY_TYPE';
const SEARCH_FILTER = 'SEARCH_FILTER';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const HazardEventList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { siteId, segmentName, entityId, selectedTab } = useParams();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const hazardEvent = useSelector(state => getHazardEvent(state));
  const generalData = useSelector(state => getGeneralData(state));

  const [searchFilter, setSearchFilter] = useState('');
  const [filterSiteId, setFilterSiteId] = useState(siteId || 0);

  const [filterWaterSupplyDescriptionId, setFilterWaterSupplyDescriptionId] = useState(0);
  const [filterEntityTypeId, setFilterEntityTypeId] = useState(0);
  const [entities, setEntities] = useState([]);
  const [filterEntityId, setFilterEntityId] = useState(entityId || 0);
  const [filterHazardCertaintyId, setFilterHazardCertaintyId] = useState(0);
  const [filterHazardClassId, setFilterHazardClassId] = useState(0);

  const [asendingOrder, setAsendingOrder] = useState(true);

  const [active, setActive] = useState(false);
  const [hazardEventId, setHazardEventId] = useState(0);

  let offset = hazardEvent.offset || 0;
  let limit = hazardEvent.limit || 1000;
  let filter = hazardEvent.filterBy.filter || null;

  let filterLocalSiteId = siteId || hazardEvent.filterBy.filterSiteId || null;
  let filterLocalWaterSupplyDescriptionId = hazardEvent.filterBy.filterWaterSupplyDescriptionId || null;
  let filterLocalEntityTypeId = hazardEvent.filterBy.filterEntityTypeId || null;
  let filterLocalEntityId = entityId || hazardEvent.filterBy.filterEntityId || null;
  let filterLocalHazardCertaintyId = hazardEvent.filterBy.filterHazardCertaintyId || null;
  let filterLocalHazardClassId = hazardEvent.filterBy.filterHazardClassId || null;

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_HAZARDEVENT_LIST', siteId));
  }, [dispatch, getFilterThunk, siteId]);

  useEffect(() => {
    let filterObj = {
      filterSiteId: filterLocalSiteId,
      filterWaterSupplyDescriptionId: filterLocalWaterSupplyDescriptionId,
      filterEntityTypeId: filterLocalEntityTypeId,
      filterEntityId: filterLocalEntityId,
      filterHazardCertaintyId: filterLocalHazardCertaintyId,
      filterHazardClassId: filterLocalHazardClassId,
      filter: filter,
      offset: offset,
      limit: limit
    };
    dispatch(getHazardEventThunk('WQSP_HAZARDEVENT_LIST', filterObj));
  }, [
    getHazardEventThunk,
    filter,
    filterLocalSiteId,
    filterLocalWaterSupplyDescriptionId,
    filterLocalEntityTypeId,
    filterLocalEntityId,
    filterLocalHazardCertaintyId,
    filterLocalHazardClassId,
    siteId,
    entityId
  ]);

  let disableControls = hazardEvent.isLoading || false;
  let messageId = (hazardEvent && hazardEvent.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let hazardClasses = generalData.generalFieldTypes.filter(element => element.FieldType === HAZARD_CLASS) || [];

  const siteOptions = () => {
    let siteData = [];

    generalData.sites.forEach(element => {
      siteData.push({
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allSites" defaultMessage="All Sites" />,
      value: 0
    });
    return sortedData;
  };

  const waterSupplyDescriptionOptions = () => {
    let waterSupplyDescriptionData = [];
    generalData.waterSupplyDescriptions.forEach(element => {
      if (element.SiteId === parseInt(filterSiteId)) {
        waterSupplyDescriptionData.push({
          label: element.Name,
          value: element.WaterSupplyDescriptionId
        });
      }
    });

    let sortedData = waterSupplyDescriptionData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allWaterSupplies" defaultMessage="All Water Supplies" />,
      value: 0
    });
    return sortedData;
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];

    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
      value: 0
    });
    return sortedData;
  };

  const entityOptions = () => {
    let entityData = [];

    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntities" defaultMessage="All Entities" />,
      value: 0
    });
    return sortedData;
  };

  const hazardCertaintyOptions = () => {
    let certaintyData = [];

    generalData.generalFieldTypes.forEach(element => {
      if (element.FieldType === HAZARD_EVENT_CERTAINTY) {
        certaintyData.push({
          label: element.Name,
          value: element.GeneralFieldTypeId
        });
      }
    });

    let sortedData = certaintyData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allHazardCertainty" defaultMessage="All Hazard Certainty" />,
      value: 0
    });
    return sortedData;
  };

  const hazardClassOptions = () => {
    let classData = [];
    hazardClasses.forEach(element => {
      classData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = classData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allHazardClasses" defaultMessage="All Hazard Classes" />,
      value: 0
    });

    return sortedData;
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onSiteChange = e => {
    setFilterSiteId(e.value);
    setFilterWaterSupplyDescriptionId(0);
    setFilterEntityTypeId(0);
    setFilterEntityId(0);
    filteredResults(SITE, e.value);
    setCurrentPage(1);
  };

  const onWaterSupplyDescriptionChange = e => {
    setFilterWaterSupplyDescriptionId(e.value);
    setFilterEntityTypeId(0);
    setFilterEntityId(0);
    filteredResults(WATERSUPPLY_DESCRIPTION, e.value);
    setCurrentPage(1);
  };

  const onEntityTypeChange = e => {
    setFilterEntityTypeId(e.value);
    setFilterEntityId(0);
    filteredResults(ENTITY_TYPE, e.value);
    let locFilteredOption = generalData.entities.filter(item => item.EntityTypeId === e.value);
    if (filterWaterSupplyDescriptionId) {
      locFilteredOption = locFilteredOption.filter(item => item.WaterSupplyDescriptionId === filterWaterSupplyDescriptionId);
    }
    setEntities(locFilteredOption);
    setCurrentPage(1);
  };

  const onEntityChange = e => {
    setFilterEntityId(e.value);
    filteredResults(ENTITY, e.value);
    setCurrentPage(1);
  };

  const onHazardCertaintyChange = e => {
    setFilterHazardCertaintyId(e.value);
    filteredResults(HAZARD_CERTAINTY_TYPE, e.value);
    setCurrentPage(1);
  };

  const onHazardClassChange = e => {
    setFilterHazardClassId(e.value);
    filteredResults(HAZARD_CLASS_TYPE, e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = hazardEvent.hazardEvents;

    let apifilterObj = {
      filter: null,
      filterSiteId: null,
      filterWaterSupplyDescriptionId: null,
      filterEntityTypeId: null,
      filterEntityId: null,
      filterHazardCertaintyId: null,
      filterHazardClassId: null
    };

    if (filterType === SITE) {
      if (value !== 0) {
        apifilterObj.filterSiteId = value;
        filteredList = filteredList.filter(function(item) {
          return item.SiteId === value;
        });
      }
    } else {
      if (filterSiteId && parseInt(filterSiteId) !== 0) {
        apifilterObj.filterSiteId = parseInt(filterSiteId);
        filteredList = filteredList.filter(function(item) {
          return item.SiteId === parseInt(filterSiteId);
        });
      }
    }
    if (filterType === WATERSUPPLY_DESCRIPTION) {
      if (value !== 0) {
        apifilterObj.filterWaterSupplyDescriptionId = parseInt(value);
        filteredList = filteredList.filter(function(item) {
          return item.WaterSupplyDescriptionId === parseInt(value);
        });
      }
    } else {
      if (filterWaterSupplyDescriptionId !== 0 && filterType !== SITE) {
        apifilterObj.filterWaterSupplyDescriptionId = filterWaterSupplyDescriptionId;
        filteredList = filteredList.filter(function(item) {
          return item.WaterSupplyDescriptionId === filterWaterSupplyDescriptionId;
        });
      }
    }

    if (filterType === ENTITY_TYPE) {
      if (value !== 0) {
        apifilterObj.filterEntityTypeId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === value;
        });
      }
    } else {
      if (filterEntityTypeId !== 0) {
        apifilterObj.filterEntityTypeId = filterEntityTypeId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityTypeId;
        });
      }
    }

    if (filterType === ENTITY) {
      if (value !== 0) {
        apifilterObj.filterEntityId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === value;
        });
      }
    } else {
      if (filterEntityId !== 0 && filterType !== ENTITY_TYPE) {
        apifilterObj.filterEntityId = filterEntityId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === filterEntityId;
        });
      }
    }

    if (filterType === HAZARD_CERTAINTY_TYPE) {
      if (value !== 0) {
        apifilterObj.filterHazardCertaintyId = value;
        filteredList = filteredList.filter(function(item) {
          return item.HazardCertaintyId === value;
        });
      }
    } else {
      if (filterHazardCertaintyId !== 0) {
        apifilterObj.filterHazardCertaintyId = filterHazardCertaintyId;
        filteredList = filteredList.filter(function(item) {
          return item.HazardCertaintyId === filterHazardCertaintyId;
        });
      }
    }

    if (filterType === HAZARD_CLASS_TYPE) {
      if (value !== 0) {
        apifilterObj.filterHazardClassId = value;
        filteredList = filteredList.filter(function(item) {
          return item.HazardClass.split(',').find(e => e === value.toString());
        });
      }
    } else {
      if (filterHazardClassId !== 0) {
        apifilterObj.filterHazardClassId = filterHazardClassId;
        filteredList = filteredList.filter(function(item) {
          return item.HazardClass.split(',').find(e => e === filterHazardClassId.toString());
        });
      }
    }
    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (hazardEvent.hazardEventListCount > hazardEvent.hazardEvents.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let hazardEventObj = Object.values(hazardEvent.filteredResults);

  if (asendingOrder) {
    hazardEventObj = hazardEventObj.sort(compareValues('NoOfRiskAssessments'));
  } else {
    hazardEventObj = hazardEventObj.sort(compareValues('NoOfRiskAssessments', 'desc'));
  }

  const onAddNewClick = () => {
    if (segmentName === 'define' && entityId) {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event`);
    } else if (segmentName) {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event`);
    } else {
      history.push('/hazard-events/hazard-event');
    }
  };

  const onViewHazardEventClick = (hazardEventId, selectedSiteId) => {
    if (segmentName === 'define' && entityId) {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`
      );
    } else {
      history.push(`/site/${selectedSiteId}/identify/2/hazard-events/hazard-event-view/${hazardEventId}`);
    }
  };

  const onViewRiskManagement = (selectedhazardEventId, selectedSiteId) => {
    if (segmentName === 'define' && entityId) {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${selectedhazardEventId}/risk-assessments`
      );
    } else if (segmentName === 'identify') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${selectedhazardEventId}/risk-assessments`
      );
    } else {
      history.push(`/site/${selectedSiteId}/identify/3/risk-assessments`);
    }
  };

  const onViewControlMeasure = (hazardEventId, selectedSiteId) => {
    if (segmentName === 'define' && entityId) {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`
      );
    } else if (segmentName === 'identify') {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`);
    } else {
      history.push(`/site/${selectedSiteId}/control/2/control-measures`);
    }
  };

  const onViewImprovementPlan = (hazardEventId, selectedSiteId) => {
    if (segmentName === 'define' && entityId) {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans`
      );
    } else if (segmentName === 'identify') {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans`);
    } else {
      history.push(`/site/${selectedSiteId}/control/3/improvement-plans`);
    }
  };

  const onCreateIncidentReportClick = n => {
    history.push(`/site/${n.SiteId}/respond/2/incident-reports/incident-report`, {
      docType: 'HAZARD_EVENT',
      referedId: n.HazardEventId,
      obj: n
    });
  };

  const onHazardEventRemoveClick = hazardEventId => {
    setActive(true);
    setHazardEventId(hazardEventId);
  };

  const handleClose = () => {
    setActive(false);
    setHazardEventId(0);
  };

  const handleConfirm = () => {
    deleteHazardEvent();
    handleClose();
  };

  const deleteHazardEvent = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.HazardEventId = hazardEventId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveHazardEventThunk(saveData, 'WQSP_HAZARDEVENT_DELETE'));
  };

  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENT_VIEW');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENT_DELETE');
  const isControlMeasureAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MEASURES_LIST');
  const isImprovementPlanAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_IMPROVEMENT_PLAN_LIST');
  const isRiskAssessmentAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKASSESSMENT_LIST');
  const allowIncidentReport = IsWidgetAccessible(userProfileWidgets, 'WQSP_INCIDENT_REPORT_ADDNEW');

  const dialogTitle = intl.formatMessage({
    id: 'hazardEvent.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this hazard event?'
  });

  const breadcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  return (
    <div className={s.hazardEventList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {segmentName ? (
          entityId && <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        ) : (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />}</h1>
          </div>
        )}
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
        <div className={s.contentWrapper}>
          {hazardEvent.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={hazardEvent.showBanner}
            status={hazardEvent.isOpSuccessful}
            successText={messageText}
          />
          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="hazardEvent.searchHazardEvent"
              />
            </Col>
            <Col sm={6} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_HAZARDEVENT_ADD')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addHazardEvent"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                {' '}
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row className="pb-2">
            {!siteId && (
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drp"
                  dataArray={siteOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onSiteChange(e)}
                  selectedOption={siteOptions().filter(option => option.value === filterSiteId)}
                  disabled={disableControls || siteId}
                />
              </Col>
            )}
            {!entityId && (
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpWSD"
                  dataArray={waterSupplyDescriptionOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="hazardEvent.waterSupply" defaultMessage="Water Supply" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onWaterSupplyDescriptionChange(e)}
                  selectedOption={waterSupplyDescriptionOptions().filter(option => option.value === filterWaterSupplyDescriptionId)}
                  disabled={disableControls || entityId}
                />
              </Col>
            )}
            {!entityId && (
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpModelId"
                  dataArray={entityTypeOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onEntityTypeChange(e)}
                  selectedOption={entityTypeOptions().filter(option => option.value === filterEntityTypeId)}
                  disabled={disableControls || entityId}
                />
              </Col>
            )}
            {!entityId && (
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drpEntityId"
                  dataArray={entityOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onEntityChange(e)}
                  selectedOption={entityOptions().filter(option => option.value === filterEntityId)}
                  disabled={disableControls || entityId}
                />
              </Col>
            )}
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpHazardCertainty"
                dataArray={hazardCertaintyOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="hazardEvent.hazardCertainty" defaultMessage="Hazard Certainty" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onHazardCertaintyChange(e)}
                selectedOption={hazardCertaintyOptions().filter(option => option.value === filterHazardCertaintyId)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpHazardClass"
                dataArray={hazardClassOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="hazardEvent.hazardClass" defaultMessage="Hazard Class" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onHazardClassChange(e)}
                selectedOption={hazardClassOptions().filter(option => option.value === filterHazardClassId)}
                disabled={disableControls}
              />
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={hazardEventObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={hazardEvent.currentPage}
            >
              <HazardEventListTable
                hazardClasses={hazardClasses}
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                viewHazardEvent={onViewHazardEventClick}
                viewAllowed={isViewAllowed}
                deleteAllowed={isDeleteAllowed}
                controlMeasuresAllowed={isControlMeasureAllowed}
                improvementPlansAllowed={isImprovementPlanAllowed}
                viewRiskManagementAllowed={isRiskAssessmentAllowed}
                viewControlMeasure={onViewControlMeasure}
                viewImprovementPlan={onViewImprovementPlan}
                viewRiskManagement={onViewRiskManagement}
                removeHazardEvent={onHazardEventRemoveClick}
                AllowCreateIncidentReport={allowIncidentReport}
                CreateIncidentReport={onCreateIncidentReportClick}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(HazardEventList);
