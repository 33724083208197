import { initialRegulatoryRegisterState } from '../initialState';

import {
  REGULATORY_REGISTER_ERROR,
  GET_ALL_REGULATORY_REGISTER_DETAILS,
  REGULATORY_REGISTER_IS_LOADING,
  SET_REGULATORY_REGISTER_CURRENT_PAGE,
  SET_REGULATORY_REGISTER_PAGE_FILTER,
  SET_REGULATORY_REGISTER_CHANGE,
  SET_REGULATORY_REGISTER,
  UPDATE_REGULATORY_REGISTER_SAVE_STATUS,
  SET_REGULATORY_REGISTER_FILTERS,
  SET_REGULATORY_REGISTER_RESULTS,
  SET_REGULATORY_REGISTER_COMMON_LIST,
  REMOVE_REGULATORY_REGISTER
} from '../../../constants/index';

const regulatoryRegisterReducer = (state = initialRegulatoryRegisterState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_REGULATORY_REGISTER_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          ...data
        }
      };
    }

    case SET_REGULATORY_REGISTER_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          currentPage
        }
      };
    }

    case SET_REGULATORY_REGISTER_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_REGULATORY_REGISTER_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          ...data
        }
      };
    }

    case REGULATORY_REGISTER_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          isLoading: isLoading
        }
      };
    }

    case REGULATORY_REGISTER_ERROR: {
      const { data } = payload;
      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          ...data
        }
      };
    }

    case SET_REGULATORY_REGISTER: {
      let { selectedRegulatoryRegister } = payload;

      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          isOpSuccessful: false,
          showBanner: false,
          selectedRegulatoryRegister: {
            ...state.regulatoryRegister.selectedRegulatoryRegister,
            ...selectedRegulatoryRegister
          }
        }
      };
    }

    case SET_REGULATORY_REGISTER_CHANGE: {
      let { selectedRegulatoryRegister } = payload;

      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedRegulatoryRegister: {
            ...state.regulatoryRegister.selectedRegulatoryRegister,
            ...selectedRegulatoryRegister
          }
        }
      };
    }

    case SET_REGULATORY_REGISTER_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          ...filteredResults
        }
      };
    }

    case REMOVE_REGULATORY_REGISTER: {
      const { RegulatoryRegisterId } = payload;
      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          regulatoryRegisters: [
            ...state.regulatoryRegister.regulatoryRegisters.filter(item => item.RegulatoryRegisterId !== RegulatoryRegisterId)
          ],
          filteredResults: [...state.regulatoryRegister.filteredResults.filter(item => item.RegulatoryRegisterId !== RegulatoryRegisterId)],
          regulatoryRegisterListCount: state.regulatoryRegister.regulatoryRegisterListCount - 1
        }
      };
    }

    case SET_REGULATORY_REGISTER_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        regulatoryRegister: {
          ...state.regulatoryRegister,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default regulatoryRegisterReducer;
