const languageObject = {
  en: {
    en: 'English',
    es: 'español',
    de: 'Deutsche',
    fr: 'Française',
    da: 'dansk',

    'dashboard.hello': 'Hello',
    'dashboard.lastLoggedIn': 'Last logged in at',

    'sidebar.home': 'Home',
    'sidebar.units': 'Units',
    'sidebar.tasksChecklists': 'Tasks & Checklists',
    'sidebar.adminControls': 'Admin Controls',
    'sidebar.operationalSetupControls': 'Operational Setup',
    'sidebar.accountSettings': 'Account Settings',
    'sidebar.help': 'Help',
    'sidebar.logout': 'Log out',
    'sidebar.switchProfiles': 'Switch profiles',
    'sidebar.switchOrganisations': 'Switch organisations',
    'sidebar.parts': 'Parts',
    'sidebar.inspections': 'Inspections',
    'sidebar.testResults': 'Test Results',
    'sidebar.qualityGoals': 'Quality Goals',
    'sidebar.sites': 'Sites',
    'sidebar.riskAssessments': 'Risk Assessments',
    'sidebar.incidentReports': 'Incident Reports',

    'common.yes': 'Yes',
    'common.no': 'No',
    'common.requiredParamsMissing': 'Required parameter missing',
    'common.createdSuccessfully': 'Record created successfully',
    'common.updatedSuccessfully': 'Record updated successfully',
    'common.creationFailed': 'Record creation failed',
    'common.updationFailed': 'Record updating failed',
    'common.deletionSuccessfull': 'Record deleted successfully',
    'common.deletionFailed': 'Record deletion failed ',
    'common.duplicateName': 'Duplicate entry found',
    'common.fetchFailed': 'Record fetch failed',
    'common.fetchSuccessfull': 'Record fetch successful',
    'common.uploadFailed': 'Upload failed',
    'common.selectOne': 'Please select one',
    'common.save': 'SAVE',
    'common.saveChanges': 'SAVE CHANGES',
    'common.cancel': 'CANCEL',
    'common.addNew': 'ADD NEW',
    'common.back': 'BACK',
    'common.add': 'ADD',
    'common.change': 'CHANGE',
    'common.remove': 'REMOVE',
    'common.view': 'VIEW',
    'common.edit': 'EDIT',
    'common.configuration': 'CONFIGURATION',
    'common.manage': 'MANAGE',
    'common.showAll': 'SHOW ALL',
    'common.showLess': 'SHOW LESS',
    'common.attachFile': 'Attach File',
    'common.search': 'Search',
    'common.clear': 'Clear',
    'common.filterBy': 'Filter By',
    'common.allFieldType': 'All Field Types',
    'common.details': 'Details',
    'common.name': 'Name',
    'common.title': 'Title',
    'common.description': 'Description',
    'common.value': 'Value',
    'common.email': 'Email',
    'common.task': 'Task',
    'common.status': 'Status',
    'common.actioned': 'Is Actioned',
    'common.active': 'Active',
    'common.address': 'Address',
    'common.phone': 'Phone',
    'common.comments': 'Comments',
    'common.phoneRequired': 'Phone is a mandatory field',
    'common.titleRequired': 'Title is a mandatory field',
    'common.nameRequired': 'Name is a mandatory field',
    'common..invalidEmail': 'Please provide a valid email',
    'common.isRefered': "Record is being referred at other places, can't be removed",
    'common.lastReviewDate': 'Last Review Date',
    'common.nextReviewDate': 'Next Review Date',
    'common.reviewFrequency': 'Review Frequency',
    'common.lastReviewDateMandatory': 'Last Review Date is a mandatory field',
    'common.allLanguages': 'All Languages',
    'common.stakeholders': 'STAKEHOLDERS',
    'common.hazardEventRequired': 'Hazard Event is a mandatory field',
    'common.editTextCC': 'Edit',
    'common.viewTextCC': 'View',
    'common.downloadFailed': 'Download Failed',
    'common.downloadSuccessful': 'Download Successful',
    'common.download': 'Download',

    'wqsp.common.hazardClass': 'Hazard Class',
    'wqsp.common.hazardClassMandatory': 'Hazard Class is mandatory field',

    'wqsp.common.siteName': 'Site Name',
    'wqsp.common.siteOnlyMandatory': 'Site is a mandatory field',
    'wqsp.common.waterSupplyName': 'Water Supply Name',

    'wqsp.common.waterSupplyDescription': 'Water Supply Description',
    'wqsp.common.dueDate': 'Due Date',
    'wqsp.common.pleaseSelectOne': 'Please select one',
    'wqsp.common.testType': 'Test Type',
    'wqsp.common.waterSupplyMandatory': 'Water supply name is mandatory field',
    'wqsp.common.siteMandatory': 'Site name is mandatory field',

    'wqsp.common.testPurposeMandatory': 'Test purpose is mandatory field',
    'wqsp.common.emergencyResponsePlan': 'Emergency Response Plan',
    'wqsp.common.gpsLatitude': 'GPS Latitude',
    'wqsp.common.gpsLongitude': 'GPS Longitude',
    'wqsp.common.gpsLongitudeRequired': 'GPS Longitude is a mandatory field',
    'wqsp.common.gpsLatitudeRequired': 'GPS Latitude is a mandatory field',

    'wqsp.filters.allSites': 'All Sites',

    'wqsp.filters.allTestPurposes': 'All Test Purposes',
    'wqsp.filters.isRefered': 'Can not remove while in use.',

    'wqsp.filters.allInspectionTypes': 'All Inspection Types',
    'wqsp.filters.allWaterSupplies': 'All Water Supplies',
    'wqsp.filters.allHazardCertainty': 'All Hazard Certainties',
    'wqsp.filters.allHazardClasses': 'All Hazard Classes',
    'wqsp.filters.allStatus': 'All Status',

    'layout.tagline': 'At Water Source Australia we believe clean drinking water should be available for all.',

    'settings.language': 'Language  ',
    'settings.timezone': 'Timezone',

    'layout.noticeBoard': 'NOTICE BOARD',

    'toggleSwitch.lableView': 'Switch to label view',

    'settings.accountSettings': 'Account Settings',
    'accountSettingsTab.settings': 'Settings',
    'accountSettingsTab.profile': 'Profile',
    'accountSettings.generalSettings': 'General Account Settings',
    'accountSettings.resetCacheConfirmation': 'Are you sure you want to reset the cache?',
    'accountSettings.resetCache': 'RESET CACHE',
    'accountSettings.clearCookiesConfirmation': 'Are you sure you want to clear the cookies?',
    'accountSettings.clearCookies': 'CLEAR COOKIES',
    'accountSettings.clearCookiesNote': 'Please note clearing the cookies would log you out of the application',
    'AdminControlsTabs.manageUsers': 'Users',
    'AdminControlsTabs.manageProfiles': 'Profiles',
    'AdminControlsTabs.manageOrganisations': 'Organisations',
    'AdminControls.adminControls': 'Admin Controls',
    'AdminControlsTabs.hazards': 'Hazards',
    'AdminControlsTabs.generalFieldTypes': 'General Field Types',
    'AdminControlsTabs.riskCategories': 'Risk Categories',
    'AdminControlsTabs.personnel': 'Personnel',
    'AdminControls.NoAccess': 'You do not have access to Admin Controls. Please contact your administrator.',
    'AdminControlsTabs.translations': 'Translations',

    'profile.alreadyExists': 'A profile with this name already exists',
    'profile.defaultProfileError': 'The default profile cannot be edited!',
    'profile.saveSuccessfull': 'Profile sucessfully saved',
    'profile.unableToSave': 'Profile saving failed',
    'profile.errorInFetching': 'Unable to retrieve the data',
    'profile.invalidData': 'Unable to save. Invalid data format!',
    'profile.fillRequiredData': 'Please fill the required data!',
    'profile.validationNameLength': 'Profile Name must be atleast 2 characters long!',
    'profile.validationName': 'Profile Name is required!',
    'profile.validationDescriptionLength': 'Profile Description is required!',
    'profile.minimumWidget': 'Minumum required profile widget',
    'profile.EnableMFA': 'Multi Factor Authentication (MFA)',

    'settings.settings': 'Settings',
    'settings.profile': 'Profile',
    'settings.appwidgets': 'Application Widgets',
    'settings.addwidget': 'Add Widget',
    'settings.userManagement': 'User Management',
    'settings.applicationusers': 'Application Users',
    'settings.organisation': 'Organisation',

    'profileSettings.personal': 'Personal',
    'profileSettings.contactNumber': 'Contact Number',
    'profileSettings.emailAddress': 'Username/Email Address',
    'profileSettings.password': 'Password',

    'profile.profiles': 'Profiles',
    'profile.addProfile': 'Add Profile',
    'profile.profileEdit': 'Edit Profile',
    'profile.addNewProfile': 'ADD NEW PROFILE',
    'profile.Edit': 'EDIT',
    'profile.View': 'VIEW',
    'profile.profileName': 'Profile Name',
    'profile.profileDescription': 'Profile Description',
    'profile.profileActive': 'Active',
    'profile.profileSave': 'Save Changes',
    'profile.profileCancel': 'Cancel',
    'profile.selectWidget': 'Select',
    'profile.widgetAssigned': 'Widgets',
    'profile.requiredParamsMissing': 'Missing parameters',
    'profile.fetchFailed': 'Unable to retrieve data',
    'profile.fetchSuccessfull': 'Records fetched successfully',
    'profile.enterProfileName': 'Enter profile name',
    'profile.enterProfileDescription': 'Enter profile Description',
    'profile.search': 'Search',
    'profile.searchclear': 'CLEAR',
    'profile.back': '< BACK TO PROFILES',
    'profile.widgetselection': 'Select the widgets that this profile will have access to.',
    'profile.widgetsCount': 'Widgets',
    'profile.userCount': 'Users',
    'profile.selectPage': 'Select page',
    'profile.selectAllPages': 'Select All Pages',
    'profile.filterBy': 'Filter by',

    'organisation.organisation': 'Organisations',
    'organisation.createdSuccessfully': 'Organisation sucessfully created',
    'organisation.updatedSuccessfully': 'Organisation sucessfully saved',
    'organisation.creationFailed': 'Organisation creation failed',
    'organisation.updationFailed': 'Organisation update failed',
    'organisation.requiredParamsMissing': 'Missing parameters',
    'organisation.fetchFailed': 'Unable to retrieve organisation data',
    'organisation.profilefetchFailed': 'Unable to retrieve organisation profile data',
    'organisation.fetchSuccessfull': 'Records fetched successfully',
    'organisation.users.fetchFailed': 'Unable to retrieve organisation users',
    'organisation.units.fetchFailed': 'Unable to retrieve organisation units',
    'organisation.organisationName': 'Organisation name',
    'organisation.inActiveError': 'Organisation can not be marked as inactive as it may contain units or sub-organisation.',
    'organisation.location': 'Location',
    'organisation.users': 'Users',
    'organisation.units': 'Units',
    'organisation.addNewOrganisation': 'Add new organisation',
    'organisation.editOrganisation': 'Edit organisation details',
    'organisationManagement.nameMandatory': 'Organisation name is mandatory field',
    'organisationManagement.parentMandatory': 'Parent group is mandatory field',
    'organisationManagement.defaultProfile': 'Default Profile',
    'organisationManagement.defaultProfileMandatory': 'Default profile is mandatory field',
    'organisationManagement.countryMandatory': 'Location Country is mandatory field',
    'organisationManagement.backToOrganisations': 'BACK TO ORGANISATIONS',
    'organisationManagement.organisationName': 'Organisation name',
    'organisationManagement.organisationLogo': 'Organisation logo',
    'organisationManagement.dragndrop': 'Drag and drop file here',
    'organisationManagement.browse': 'BROWSE FILE',
    'organisationManagement.or': 'or',
    'organisationManagement.acceptedfile': 'Accepted file formats: .jpg, .jpeg, .png, .svg',
    'organisationManagement.organisationLogoType': 'Only files with the following extensions are allowed: .jpg, .jpeg, .png, .svg',
    'organisationManagement.parentGroup': 'Parent group',
    'organisationManagement.location': 'Location',
    'organisationManagement.town': 'Town',
    'organisationManagement.enterTown': 'Enter town',
    'organisationManagement.region': 'Region',
    'organisationManagement.entrrRegion': 'Enter region',
    'organisationManagement.country': 'Country',
    'organisationManagement.selectCountry': 'Select country',
    'organisationManagement.postCode': 'Postcode',
    'organisationManagement.enterPostcode': 'Enter postcode',
    'organisationManagement.users': 'Users',
    'organisationManagement.addUsersToOrganisation': 'Add users to your organisation',
    'organisationManagement.units': 'Units',
    'organisationManagement.addUnitsToOrganisation': 'Add units to your organisation',
    'organisationManagement.organisationActive': 'Organisation is active',
    'organisationManagement.saveOrganisation': 'Save Organisation',
    'organisationManagement.userSearch': 'Search',
    'organisationManagement.defaultSupervisor': 'Default Supervisors',
    'organisationManagement.SupervisingManager': 'Supervising Manager',
    'organisationManagement.supervisingTechnician': 'Supervising Technician',
    'appwidget.id': 'Id',
    'appwidget.name': 'Name',
    'appwidgets.syncdata': 'Sync Data',

    'usermanagement.userList': 'User List',
    'usermanagement.users': 'Users',
    'usermanagement.addUser': 'Add User',
    'usermanagement.addnewuser': 'ADD NEW USER',
    'usermanagement.updateUser': 'Update User',
    'usermanagement.email': 'Email',
    'usermanagement.enteremail': 'Enter email',
    'usermanagment.email': 'Email',
    'usermanagment.contactno': 'Contact number',
    'usermanagment.firstname': 'First Name',
    'usermanagment.enterfirstname': 'Enter First Name',
    'usermanagment.enterlastname': 'Enter Last Name',
    'usermanagment.enterphoneno': 'Enter Phone No',
    'usermanagment.enteremail': 'Enter Email',
    'usermanagment.profilename': 'Profile Name',
    'usermanagement.edituserdetails': 'Edit user details',
    'usermanagement.linkaddnewuser': 'Add new user',
    'usermanagment.select': 'Select',
    'usermanagement.repeatPassword': 'Repeat password',
    'usermanagement.unitname': 'Unit Name',
    'usermanagement.selectprofilethisuser': '1. Select a profile(s) for this user',
    'usermanagement.selectunits': '2. Select a unit(s) for ',
    'usermanagment.firstnamemandatory': 'First name is mandatory',
    'usermanagment.emailmandatory': 'Email is mandatory',
    'usermanagment.entervalidemail': 'Please enter valid email',
    'usermanagment.entervalidphoneno': 'Please enter valid phone number (minimum 7 digits including country code)',
    'usermanagment.addatleaseoneprofile': 'Please add at least one profile',
    'user.createdSuccessfully': 'User created successfully',
    'user.updatedSuccessfully': 'User updated successfully',
    'user.creationFailed': 'User creation failed',
    'user.updationFailed': 'User updation failed',
    'user.emailAlreadyExist': 'User email already exists',
    'user.couldNotbeLocated': 'User could not be located',
    'user.requiredParamsMissing': 'Required data missing',
    'user.fetchFailed': 'User data fetch failed',
    'user.backtousers': 'BACK TO USERS',
    'user.passwordUpdatedSuccessfully': 'Password changed successfully',
    'user.passwordUpdateFailed': 'Error updating password',

    'userprofile.fetchFailed': 'User profile fetch failed',
    'userprofile.fetchSuccessfull': 'User profile fetch successful',
    'userprofile.updatefailed': 'User Profile update failed',

    'pagination.next': '>',
    'pagination.prev': '<',
    'pagination.showing': 'Showing',
    'pagination.of': 'of',

    'passwordUpdate.errorUpdatePassword': 'Error updating password',
    'passwordUpdate.currentpassword': 'Current password',
    'passwordUpdate.passwordError': 'Password must contain at least 1 uppercase, 1 lowercase and 10 characters',
    'passwordUpdate.newPassword': 'New password',
    'passwordUpdate.confirmPassword': 'Confirm password',
    'passwordUpdate.confirmPasswordError': 'Password does not match',

    'unauthorised.youAreNotAuthorised': 'You are not authorised to view this page',
    'unauthorised.error401': 'Error 401',
    'unauthorised.returnHome': 'Return Home',

    'forgotpassword.requiredParamsMissing': 'Invalid request',
    'forgotpassword.unabletoChangePassword': 'Unable to change your password. Please contact your administrator',
    'forgotpassword.invalidEmail': 'Please provide a valid email',
    'forgotpassword.verificationSent': 'A verification code has been sent',
    'forgotpassword.changeConfirmed': 'You password has been changed successfully',
    'forgotpassword.invalidConfirmationCode': 'Invalid verification code',
    'forgotpassword.codeExpire': 'Your verification has expired. Please request a new code',
    'forgotpassword.tooManyAttempts': 'You have exceeded the maximum attempts for the code. Please contact your administrator',
    'forgotpassword.invalidPassword': 'Invalid password',
    'forgotpassword.codeVerificationFailed': 'Verification code failed. Please contact your administrator',
    'forgotpassword.codeVerificationCodeSendFailed': 'The verification code could not be sent. Please contact your administrator',
    'forgotpassword.invalidUser': 'Your requested email is invalid',
    'forgotpassword.forgotyourpassword': 'Forgot your password?',
    'forgotpassword.iForgotMyPassword': 'I FORGOT MY PASSWORD',
    'forgotpassword.pleaseEnterVerificationEmail': 'Please enter your verification email address',
    'forgotpassword.resetMyPassord': 'RESET MY PASSWORD',
    'forgotpassword.verificatiionEmailSentEnterCode':
      'A verification code has been sent to your inbox. Please enter the verification code below.',
    'forgotpassword.enterCode': 'Enter Code',
    'forgotpassword.codeRequirement': 'Invalid verification code',
    'forgotpassword.resendCode': 'RESEND CODE',

    'general.passwordRequirement': 'Password must contain at least 1 uppercase, 1 lowercase and 10 characters',
    'general.passwordDoNotMatch': 'Password does not match',
    'general.enterpassword': 'Enter password',
    'general.ConfirmPassword': 'Confirm password',

    'general.contactUsNow': 'CONTACT US NOW',
    'general.needHelp': 'Need help?',
    'login.login': 'Log In',
    'login.error': 'Error logging in',
    'login.forgotPasswordSuccess':
      'Your password has been successfully reset. You can now use your new password to log in to your account.',
    'login.emailAddress': 'Email address',
    'login.enterValidEmail': 'Please enter a valid email',
    'login.password': 'Password',
    'login.enterValidPassword': 'Please enter a valid password',
    'login.logInButton': 'LOG IN',
    'mfa.verificationSentEnterCode': 'A verification code has been sent to your mobile. Please enter the verification code below.',
    'mFa.codeRequirement': 'Invalid verification code',
    'mFa.confirm': 'Confirm',
    'mFA.resendCode': 'RESEND CODE',
    'mFA.enterCode': 'Enter Code',
    'login.missingRequiredFields': 'Missing required fields in request body',
    'login.errorInLoginAttempt': 'Error when attempting to login with supplied username and password.',
    'login.errorInMFAAttempt': 'Error when attempting to authenticate code. Please try log in again!',
    'login.unableToFetchData': 'Unable to fetch data',
    'login.unableToFetchProfiles': 'Unable to fetch profiles',
    'login.unableToFetchOrganisations': 'Unable to fetch organisations',
    'login.unableToLoginContactAdmin': 'Unable to login! Please contact the administrator',
    'login.expiredCodeException': 'Authentication code is expired, Please try log in again',
    'login.errorCodeMismatchException': 'Authentication code could not be verified, Please try log in again',
    'login.userNotConfirmedException': 'User not confirmed, Please contact the administrator',

    'filter.fetchFailed': 'Filter data fetch failed',
    'filter.fetchSuccessfull': 'Filter data fetch successfull',
    'filter.requiredParamsMissing': 'Filter data missing parameters',
    'filters.allUnits': 'All Units',
    'filters.allBrands': 'All Brands',
    'filters.allpositions': 'All Positions',
    'filters.allComponentTypes': 'All Component Types',
    'filters.allTelemetryTypes': 'All Types',
    'filters.allstatus': 'All Status',

    'checklistTaskTemplateList.checklistTemplates': 'Checklist Templates',
    'checklistTaskTemplateList.tasksTemplates': 'Tasks Templates',
    'checklistTemplate.checklistTemplate': 'Checklist Template',
    'checklistTemplateManagement.Search': 'Search',
    'checklistTemplate.newChecklistTemplate': 'New Checklist Template',
    'checklistTemplate.createdSuccessfully': 'Checklist template created successfully',
    'checklistTemplate.updatedSuccessfully': 'Checklist template updated successfully',
    'checklistTemplate.creationFailed': 'Checklist template creation failed',
    'checklistTemplate.updationFailed': 'Checklist template updation failed',
    'checklistTemplate.requiredParamsMissing': 'Required parameter missing',
    'checklistTemplate.fetchFailed': 'Data fetch failed',
    'checklistTemplate.fetchSuccessfull': 'Data fetch successfull',
    'checklistTemplate.duplicateName': 'Duplicate template name',
    'checklistTemplateManagement.nameMandatory': 'Checklist Name is a mandatory field',
    'checklistTemplateManagement.taskTemplateMandatory': 'Task template is mandatory',

    'taskTemplate.createdSuccessfully': 'Task Template created successfully',
    'taskTemplate.updatedSuccessfully': 'Task Template updated successfully',
    'taskTemplate.creationFailed': 'Task Template creation failed',
    'taskTemplate.updationFailed': 'Task Template updation failed',
    'taskTemplate.requiredParamsMissing': 'Required parameters missing',
    'taskTemplate.fetchFailed': 'Task Template fetch failed',
    'taskTemplate.fetchSuccessfull': 'Task Template fetch successful',
    'taskTemplate.duplicateName': 'Duplicate Task Template Name',
    'taskTemplateManagement.activities': 'Activities',
    'taskTemplateManagement.role': 'Role',
    'taskTemplateManagement.effort': 'Effort',
    'taskTemplateManagement.estimatedEffort': 'Estimated effort',
    'taskTemplateManagement.enterTask': 'Enter task',
    'taskTemplateManagement.description': 'Description / Objective',
    'taskTemplateManagement.enterDescription': 'Enter description',
    'taskTemplateManagement.enterActivities': 'Enter activities',
    'taskTemplateManagement.newTaskTemplate': 'New Task Template',
    'taskTemplateManagement.nameMandatory': 'Task Name is a mandatory field',

    'checklistTemplateTasks.role': 'Role',
    'checklistTemplateTasks.estimatedEffort': 'Estimated Effort',
    'checklistTemplateTasks.tasks': 'Tasks',
    'checklistTemplateTasks.selectTaskFromTaskTemplate': 'Select task from a task template',
    'checklistTemplateTasks.select': 'Select',
    'checklistTemplateTasks.createNewTask': 'CREATE NEW TASK',
    'checklistTemplateTasks.hours': 'hours',
    'checklistTemplateTasks.noData': 'No Data',

    'tasksAndChecklistsManagement.tasksChecklists': 'Tasks & Checklists',
    'tasksAndChecklistsManagement.myTasks': 'My Tasks',
    'tasksAndChecklistsManagement.myChecklists': 'My Checklists',

    'checklist.createdSuccessfully': 'Checklist created successfully',
    'checklist.updatedSuccessfully': 'Checklist updated successfully',
    'checklist.creationFailed': 'Checklist creation failed',
    'checklist.updationFailed': 'Checklist updation failed',
    'checklist.requiredParamsMissing': 'Required parameter missing',
    'checklist.fetchFailed': 'Data fetch failed',
    'checklist.fetchSuccessfull': 'Data fetch successfull',
    'checklist.duplicateName': 'Duplicate template name',
    'checklist.deletedSuccessfully': 'Checklist deleted successfully',
    'checklist.searchChecklist': 'Search checklist',
    'checklist.allEmergencyResponsePlan': 'All Emergency Response Plan',
    'checklistDetail.selectFromChecklistTemplates': 'Select from checklist templates',
    'checklistDetail.dueDateTime': 'Due date & time',
    'checklistDetail.checklist': 'Checklist',
    'checklistDetail.newChecklist': 'New Checklist',
    'checklistDetail.details': 'Details',

    'checklistDetail.markAsComplete': 'MARK AS COMPLETE',
    'checklistDetail.delete': 'Delete',
    'checklistDetail.tasksCompleted': 'tasks completed',
    'checklistDetail.progress': 'Progress',
    'checklistDetail.createServiceRecord': 'Create service record',
    'checklistDetail.viewServiceRecord': 'View service record',
    'checklistDetail.checklistComplete': 'CHECKLIST IS COMPLETE',
    'checklist.activeTaskExistInChecklist': 'Active task exist in checklist',
    'checklistTask.showActive': 'Active',
    'checklistTask.showComplete': 'Complete',

    'checklistManagement.tasksCompleted': 'Tasks Completed',
    'checklistManagement.backtoChecklist': 'BACK TO CHECKLIST',
    'checklistManagement.confirmDeleteChecklist': 'Are you sure you want to delete the checklist?',

    'tasksManagement.newTask': 'New Task',
    'tasksManagement.complete': 'Complete',
    'tasksManagement.people': 'People',
    'tasksManagement.assignedTo': 'Assigned to',
    'tasksManagement.assignedBy': 'Assigned by',
    'tasksManagement.dates': 'Dates',
    'tasksManagement.completedOn': 'Completed On',
    'tasksManagement.actualEffort': 'Actual Effort',
    'taskManagement.dates': 'Dates',
    'tasksManagement.unit': 'Unit',
    'tasksManagement.unitName': 'Name',
    'tasksManagement.markAsComplete': 'Mark As Complete',
    'tasksManagement.selectTask': 'Select from task templates',
    'tasksManagement.assignTo': 'Assign to',
    'tasksManagement.createTask': 'Create Task',
    'tasksManagement.dueDateTime': 'Due date & time',
    'tasksManagement.confirmDeleteTask': 'Are you sure you want to delete the task?',
    'tasksManagement.backtoTasks': 'BACK TO TASKS',
    'task.createdSuccessfully': 'Task created successfully',
    'task.updatedSuccessfully': 'Task updated successfully',
    'task.creationFailed': 'Task creation failed',
    'task.updationFailed': 'Task updation failed',
    'task.requiredParamsMissing': 'Required parameters missing',
    'task.fetchFailed': 'Tasks fetch failed',
    'task.fetchSuccessfull': 'Tasks fetch successful',
    'task.duplicateName': 'Duplicate Task Name',
    'task.deletionFailed': 'Task deletion failed',
    'task.deletedSuccessfully': 'Task deleted successfully',
    'tasksManagement.addedAComment': 'added a comment',
    'tasksManagement.actualEffortMandatory': 'Actual effort is mandatory',
    'task.comment.requiredParamsMissing': 'Missing parameters',
    'task.comment.createdSuccessfully': 'Task comment sucessfully added',
    'task.comment.creationFailed': 'Unable to add comment',
    'task.comment.fetchFailed': 'Task comment fetch failed',
    'task.searchTask': 'Search Task',
    'task.all': 'All',

    'fileUpload.dragndrop': 'Drag and drop file here',
    'fileUpload.or': 'or',
    'fileUpload.upload': 'UPLOAD',
    'fileUpload.hasBeenUploaded': 'has been uploaded',
    'fileUpload.browse': 'BROWSE FILE',
    'fileUpload.acceptedfile': 'Accepted file formats:',
    'unitUpload.invalidfileformat': 'Invalid file format',
    'unitUpload.fileCouldNotBeUploaded': 'Files could not be uploaded. Only files with the following extensions are allowed:',
    'fileUpload.uploadingFiles': 'Uploading files',
    'fileUpload.tryAgain': 'TRY AGAIN',
    'fileUpload.next': 'NEXT',
    'fileUpload.savefailed': 'Please check the file and try again.',
    'fileUpload.saveSuccess': 'Upload Successful',
    'fileUpload.addMore': 'ADD MORE',
    'fileUpload.fileList': 'File List',
    'inspectionPurposes.addInspectionPurpose': 'Add New Inspection Purpose',
    'inspectionPurposes.confirmDelete': 'Are you sure you want to delete this inspection purpose?',
    'inspectionPurposes.inspectionPurpose': 'Inspection Purposes',
    'inspectionTypes.generalInformation': 'General Information',
    'inspectionTypes.addInspectionType': 'Add New Inspection Survey Type',
    'inspectionTypes.confirmDelete': 'Are you sure you want to delete this inspection type?',
    'inspectionTypes.inspectionType': 'Types of Inspection Surveys',
    'inspectionTypeQuestions.inspectionTypeQuestion': 'Inspection Questions',
    'inspectionTypeQuestions.addInspectionTypeQuestion': 'Add New Inspection Question',
    'inspectionTypeQuestions.confirmDelete': 'Are you sure you want to delete this inspection type?',
    'inspectionTypeQuestions.inspectionType': 'Inspection Survey Type',
    'inspectionTypeQuestions.questionNumber': 'Question Number',
    'inspectionTypeQuestions.score': 'Score',
    'inspectionTypeQuestions.explanatoryNote': 'Explanatory Note',
    'inspectionTypeQuestions.questionText': 'Question',
    'inspectionTypes.inspectionTypeRequired': 'Inspection survey type is a mandatory field',
    'inspectionTypeQuestions.questionTextRequired': 'Question text is a mandatory field',
    'inspectionTypeQuestions.questionNumberRequired': 'Question number is a mandatory field',
    'inspectionTypeQuestions.scoreRequired': 'Score is a mandatory field',
    'inspectionTypeQuestions.allInspectionType': 'All Inspection Survey Types',
    'inspectionTypeQuestions.selectInspectionType': 'Select Inspection Survey Type',
    'inspectionTypeQuestions.tooltipQuestionNumber': 'The order number of the question for the survey type',
    'inspectionTypeQuestions.tooltipScore': 'The weight of the question for the survey type',
    'inspectionTypeQuestions.questionNumberInvalid': 'Invalid question number',
    'inspectionTypeQuestions.scoreInvalid': 'Invalid score',

    'testPurpose.createdSuccessfully': 'Test Purpose created successfully',
    'testPurpose.updatedSuccessfully': 'Test Purpose updated successfully',
    'testPurpose.creationFailed': 'Failed to create Test Purpose',
    'testPurpose.updationFailed': 'Failed to update Test Purpose',
    'testPurpose.requiredParamsMissing': 'Required parameter missing',
    'testPurpose.fetchFailed': 'Data fetch failed',
    'testPurpose.fetchSuccessfull': 'Data fetch successful',
    'testPurpose.duplicateName': 'Duplicate Test Purpose name',
    'adminControlsTabs.testResults': 'Test Results',
    'testPurpose.addNewTestPurpose': 'Add New Test Purpose',
    'testPurposes.confirmDelete': 'Are you sure you want to delete test purpose?',

    'testCategory.createdSuccessfully': 'Test Category created successfully',
    'testCategory.updatedSuccessfully': 'Test Category updated successfully',
    'testCategory.creationFailed': 'Failed to create Test Category',
    'testCategory.updationFailed': 'TFailed to update Test Category',
    'testCategory.requiredParamsMissing': 'Required parameter missing',
    'testCategory.fetchFailed': 'Data fetch failed',
    'testCategory.fetchSuccessfull': 'Data fetch successful',
    'testCategory.duplicateName': 'Duplicate Test Category name',
    'testCategoryType.parameterInUse': 'Test category type parameter is in use',
    'testCategory.nameMandatory': 'Test category name is mandatory field',
    'testCategory.testResults': 'TEST RESULTS',
    'testCategory.addNewTestCategory': 'Add  new Test Category',
    'testCategory.categoryName': 'Category Name',
    'testCategory.enterDescription': 'Enter description',
    'testCategory.active': 'Test Category is active',
    'testCategory.enterName': 'Enter name',
    'testCategory.testCategories': 'Test Categories',

    'categoryType.testTypes': 'Test Types',
    'categoryType.uOM': 'Unit of Measure',
    'categoryType.realisticMean': 'Default Realistic Mean',
    'categoryType.realisticMax': 'Default Realistic Max',
    'categoryType.realisticLRV': 'Default Realistic LRV',
    'categoryType.aspirationalMean': 'Default Aspirational Mean',
    'categoryType.aspirationalMax': 'Default Aspirational Max',
    'categoryType.aspirationalLRV': 'Default Aspirational LRV',
    'categoryType.nameMandatory': 'Test Type name is required',
    'categoryType.uOMMandatory': 'Unit of measure is required',
    'categoryType.enterDescription': 'Enter description',
    'categoryType.testCategory': 'Test Category',

    'inspectionReport.inspections': 'Inspections',
    'inspectionReport.inspectionReports': 'Inspection Reports',
    'inspectionReport.inspectionReport': 'Inspection Report',
    'inspectionReport.inspectionName': 'Inspection Name',
    'inspectionReport.Site': 'Site',
    'inspectionReport.inspectionType': 'Inspection Type',
    'inspectionReport.inspectionPurpose': 'Inspection Purpose',
    'inspectionReport.riskScore': 'Risk Score',
    'inspectionReport.riskRating': 'Risk Rating',
    'inspectionReport.inspectionDate': 'Inspection Date',
    'inspectionReport.confirmDelete': 'Are you sure you want to delete this inspection report?',

    'inspectionReports.inspectionPurpose': 'Inspection Purpose',
    'inspectionReports.dateOfInspection': 'Date of Inspection',
    'inspectionReports.nameofInspection': 'Name of Inspection',
    'inspectionReports.questions': 'Questions',
    'inspectionReports.question': 'Question',
    'inspectionReports.questionNo': '#',
    'inspectionReports.score': 'Score',
    'inspectionReports.answer': 'Answer',
    'inspectionReports.totalScore': 'Total Score :',
    'inspectionReports.riskRating': 'Risk Rating :',
    'inspectionReports.siteRequired': 'Site is a mandatory field',

    'inspectionReports.inspectionTypeRequired': 'Inspection type is a mandatory field',
    'inspectionReports.inspectionPurposeRequired': 'Inspection purpose is a mandatory field',
    'inspectionReports.inspectionDateRequired': 'Inspection date is a mandatory field',
    'inspectionReports.inspectionNameRequired': 'Inspection name is a mandatory field',
    'inspectionReports.addInspectionReport': 'Add new Inspection Survey',
    'inspectionReports.additionalObservation': 'Additional Observation',
    'inspectionReports.addComment': '+ Add Comments',
    'inspectionReports.removeComment': '- Remove Comments',
    'inspectionReport.searchInspectionName': 'Search Inspections',

    'inspectionReports.mainInfo':
      'Total Risk Score is the Total score of all the Questions with a "Yes" answer as a percentage of the Total Score of all "Yes" questions.',
    'inspectionReports.riskInfo': 'Risk Rating is calculated from the Risk Score: ',
    'inspectionReports.verHighRiskDetail': '90% <= Score = Very high risk',
    'inspectionReports.highRiskDetail': '90% > Score >= 60%   = High Risk ',
    'inspectionReports.mediumRiskDetail': '60% > Score >= 30% = Medium Risk',
    'inspectionReports.lowRiskDetail': '30% > Score> 0% = Low Risk',
    'inspectionReports.generalInformation': 'General Information',

    'testResult.testResults': 'Test Results',
    'testResult.testResult': 'Test Result',
    'testResult.sampleDate': 'Sample Date',
    'testResult.sampleIDNumber': 'Sample Id #',
    'testResult.nameOfSampler': 'Name of Sampler',
    'testResult.purpose': 'Purpose',
    'testResult.analysis': 'Analysis',
    'testResult.analysisCompletedBy': 'Analysis Completed By',
    'testResult.dateAnalysed': 'Date Analysed',
    'testResult.dateSubmitted': 'Date Submitted',
    'testResult.nameOfAnalyser': 'Name of Analyser',
    'testResult.additionalInformation': 'Additional Information',
    'testResult.commentsSummary': 'Comments/Summary',
    'testResult.attachFile': 'Attach File',
    'testResult.sampleDateMandatory': 'Sample date is mandatory field',
    'testResult.sampleIdNumberMandatory': 'Sample Id number is mandatory field',
    'testResult.samplerNameMandatory': 'Sampler name is mandatory field',
    'testResult.valueMandatory': 'Value is mandatory field',
    'testResult.testResultMandatory': 'Test result is mandatory field',
    'testResult.editTestResult': 'Edit Test Result',
    'testResultItem.uOM': 'Unit of Measure',
    'testResultItem.testCategoryRequired': 'Test Category is required',
    'testResultItem.testTypeRequired': 'Test type is required',
    'testResult.confirmDelete': 'Are you sure you want to delete this test result?',

    'qualityGoals.qualityGoals': 'Quality Goals',
    'qualityGoals.listOfQualityGoals': 'List of Quality Goals',
    'qualityGoals.qualityGoalsUC': 'QUALITY GOALS',
    'qualityGoals.goals': 'Goals',
    'qualityGoals.goalDate': 'Goal Date',
    'qualityGoals.authorName': 'Name of Author',
    'qualityGoals.purpose': 'Test Purpose',
    'qualityGoals.goalPurpose': 'Goal Purpose',
    'qualityGoals.additionalInformation': 'Additional Information',
    'qualityGoals.commentsSummary': 'Comments/Summary',
    'qualityGoals.goalDateMandatory': 'Goal date is mandatory field',
    'qualityGoals.testResultMandatory': 'Test result is mandatory field',
    'qualityGoals.editQualityGoals': 'Edit Quality Goal',
    'qualityGoals.addNewTestResult': 'Add new Quality Goal',
    'qualityGoals.goalPurposeMandatory': 'Goal purpose is mandatory field',
    'qualityGoals.goalPurpose': 'Goal Purpose',
    'qualityGoals.allTestPurposes': 'All Goal Purposes',
    'qualityGoalsItem.uOM': 'Unit of Measure',
    'qualityGoalsItem.realisticMean': 'Realistic Mean',
    'qualityGoalsItem.realisticMax': 'Realistic Max',
    'qualityGoalsItem.realisticLRV': 'Realistic LRV',
    'qualityGoalsItem.aspirationalMean': 'Aspirational Mean',
    'qualityGoalsItem.aspirationalMax': 'Aspirational Max',
    'qualityGoalsItem.aspirationalLRV': 'Aspirational LRV',
    'qualityGoalsItem.testCategoryRequired': 'Test Category is required',
    'qualityGoalsItem.testTypeRequired': 'Test type is required',
    'qualityGoals.confirmDelete': 'Are you sure you want to delete this quality goals report?',

    'entity.inspectionFrequency': 'Inspection Frequency',
    'entities.inspectionFrequency': 'Inspection Frequency',
    'entities.condition': 'Condition',
    'entities.categoryOfWater': 'Category of Water',
    'entities.maintenanceFrequency': 'Maintenance Frequency',
    'entities.dateOfLastInspection': 'Date of Last Inspection',
    'entities.lateOfLastService': 'Date of Last Service',
    'entities.NameRequired': 'Name is a mandatory field',
    'entities.volumeSupplied': 'Volume Supplied',
    'entities.typeofSupply': 'Type of Supply',
    'entities.volumeStored': 'Volume Stored',
    'entities.purpose': 'Purpose',
    'entities.lengthOfTransfer': 'Length of Transfer',
    'entities.volumeExtracted': 'Volume Extracted',
    'entities.dateofInstallation': 'Date of Installation',
    'entities.treatmentType': 'Treatment Type',

    'siteControlsTabs.waterSupplyDescription': 'Water Supply  Description',
    'siteControls.NoAccess': 'You do not have access to Site Controls. Please contact your administrator.',
    'siteControls.siteControls': 'Site Controls',
    'siteControls.manageSite': 'Manage Site',
    'siteControls.site': 'Site',
    'siteControlsTabs.sites': 'Sites',
    'siteControlsTabs.siteDashboard': 'Site Dashboard',
    'siteControlsTabs.siteDetails': 'Site Details',
    'siteControlsTabs.listOfSafetyPlans': 'List of Safety Plans',
    'siteControlsTabs.waterSupplyDescriptionList': 'List of Water Supply Descriptions',
    'siteControlsTabs.qualityGoalList': 'List of Quality Goals',
    'siteControlsTabs.stakeholders': 'Stakeholders',
    'siteControlsTabs.teams': 'Teams',
    'siteControlsTabs.listOfIncidentReports': 'List of Incident Reports',
    'siteControlsTabs.listOfEmergencyResponses': 'List of Emergency Responses',
    'siteControlsTabs.listOfCorrectiveActions': 'List of Corrective Actions',
    'siteControlsTabs.listOfControlMeasures': 'List of Control Measures',
    'siteControlsTabs.listOfImprovementPlans': 'List of Improvement Plans',
    'siteControlsTabs.listOfHazardEvents': 'List of Hazard Events',
    'siteControlsTabs.listOfRiskAssessments': 'List of Risk Assessments',
    'siteControlsTabs.listOfInspections': 'List of Inspections',
    'siteControlsTabs.listOfTestSamples': 'List of Test Samples',
    'siteControlsTabs.listOfControlMonitors': 'List of Control Monitors',
    'siteControlsTabs.define': 'Define',
    'siteControlsTabs.build': 'Build',
    'siteControlsTabs.identify': 'Identify',
    'siteControlsTabs.control': 'Control',
    'siteControlsTabs.monitor': 'Monitor',
    'siteControlsTabs.measure': 'Measure',
    'siteControlsTabs.respond': 'Respond',
    'siteControlsTabs.plan': 'Plan',
    'siteControlsTabs.safetyPlanReport': 'Safety Plan Report',

    'site.households': 'Households',
    'site.inhabitants': 'Inhabitants',
    'site.region': 'Region',
    'site.site': 'Site',

    'site.householdsRequired': 'Household is a mandatory field',
    'site.inhabitantsRequired': 'Inhabitants is a mandatory field',
    'site.addNewSite': 'Add New Site',
    'site.searchSiteName': 'Search Site Name',
    'site.locationRequired': 'Location is a mandatory field',
    'site.location': 'Location',
    'common.nextReviewDateShouldBeGrater': 'Next review date must be grater than last review date',
    'common.teams': 'TEAMS',
    'common.controlMeassures': 'CONTROL MEASURES',
    'common.improvementPlans': 'IMPROVEMENT PLANS',

    'siteList.mySites': 'My Sites',
    'siteList.sortByName': 'Sort by: Name',
    'siteList.sortByStatus': 'Sort by: Status',
    'siteList.tileView': 'Tile view',
    'siteList.mapView': 'Map view',
    'siteList.viewOnTile': 'TILE VIEW',
    'siteList.viewOnMap': 'VIEW ON MAP',
    'mapview.seeallsites': 'SEE ALL SITES',

    'waterSupplyDescription.addNewWaterSupplyDescription': 'Add new Water Supply Description',
    'waterSupplyDescription.suppliedWaterVolume': 'Supplied Water Volume',
    'waterSupplyDescription.communityManaged': 'Community Managed',
    'waterSupplyDescription.waterFeesCollected': 'Water Fees Collected',
    'waterSupplyDescription.waterFeesDetails': 'Water Fees Details',
    'site.searchName': 'Search Name',

    'generalFieldTypes.fieldTypeRequired': 'Field type is a mandatory field',
    'generalFieldTypes.addGeneralFieldType': 'Add new field type item',
    'generalFieldTypes.value': 'Value',
    'generalFieldTypes.confirmDelete': 'Are you sure you want to delete this field type item?',
    'generalFieldTypes.generalFieldTypes': 'General Field Types',
    'generalFieldTypes.fieldType': 'Field Type',

    'hazardEventLibrary.hazardEventLibrary': 'Hazard Event Library',
    'hazardEventLibrary.hazardEventTemplate': 'Hazard Event Template',
    'hazardEventLibrary.newHazardEventTemplate': 'New Hazard Event Template',
    'hazardEventLibrary.basis': 'Basis',
    'hazardEventLibrary.hazardClass': 'HazardClass',
    'hazardEventLibrary.confirmDelete': 'Are you sure you want to delete this hazard event template?',
    'hazardEventLibraryItems.items': 'Hazard Event Library Items',
    'hazardEventLibraryItems.selectHazard': 'Select Hazard',

    'riskCategory.confirmDelete': 'Are you sure you want to delete this risk category?',
    'riskCategory.riskCategories': 'Risk Categories',
    'riskCategory.searchRiskCategoryName': 'Risk category name',
    'riskCategory.riskScore': 'Risk Score',
    'riskCategory.riskRating': 'Risk Rating',
    'riskCategory.low': 'Low',
    'riskCategory.medium': 'Medium',
    'riskCategory.high': 'High',
    'riskCategory.veryHigh': 'Very High',
    'riskCategory.riskCategoryTitleRequired': 'Category title is a mandatory field',
    'riskCategory.allRiskScoreDataRequired': 'All risk scores are mandatory field',
    'riskCategory.allRiskMatrixDataRequired': 'All risk matrix data are mandatory field',
    'riskCategory.addRiskCategory': 'Add new risk category',
    'riskCategory.likelihood': 'Likelihood',
    'riskCategory.consequences': 'Consequences',
    'riskCategory.insignificant': 'Insignificant',
    'riskCategory.minor': 'Minor',
    'riskCategory.moderate': 'Moderate',
    'riskCategory.major': 'Major',
    'riskCategory.catastropic': 'Catastropic',
    'riskCategory.addDescription': 'Add description',
    'riskCategory.invalidRange': 'Invalid matrix range',
    'riskCategory.insignificantDescription': 'Insignificant Description',
    'riskCategory.minorDescription': 'Minor Description',
    'riskCategory.moderateDescription': 'Moderate Description',
    'riskCategory.majorDescription': 'Major Description',
    'riskCategory.catastrophicDescription': 'Catastrophic Description',

    'hazardEvent.hazardCertainty': 'Hazard Certainty',
    'hazardEvent.hazardSource': 'Hazard Source',
    'hazardEvent.dateIdentified': 'Date Identified',
    'hazardEvent.dateOfLastReview': 'Date of Last Review',
    'hazardEvent.basis': 'Basis',
    'hazardEvent.hazardClass': 'Hazard Class',
    'hazardEvent.hazardItems': 'Hazard Items',
    'hazardEvent.addNewHazardEvent': 'Add new Hazard Event',
    'hazardEvent.editHazardEvent': 'Edit ',
    'hazardEvent.hazardCertaintyMandatory': 'Hazard certainty is mandatory field',
    'hazardEvent.nameMandatory': 'Name is mandatory field',
    'hazardEvent.dateIdentifiedMandatory': 'Date Identified is mandatory field',
    'hazardEvent.hazardEventItems': 'Hazard Event Items',
    'hazardEvent.hazardEvents': 'Hazard Events',
    'hazardEvent.hazardEvent': 'Hazard Event',
    'hazardEvent.hazardEventView': 'View Hazard Event',
    'hazardEvent.hazardEventsUC': 'HAZARD EVENTS',
    'hazardEvent.waterSupply': 'Water Supply',
    'hazardEvent.searchHazardEvent': 'Search Hazard Event',
    'hazardEvent.addHazardEventToLibrary': 'Add Hazard Event to library',
    'hazardEvent.addControlMeasures': 'Add Control Measures',
    'hazardEvent.addRiskAssessment': 'Add Risk Assessment',
    'hazardEvent.addImprovementAction': 'Add Improvement Action',
    'hazardEvent.riskAssesments': 'Risk Assesments',
    'hazardEvent.confirmDelete': 'Are you sure you want to delete this hazard event?',
    'hazardEventItem.hazardRequired': 'Hazard is required',
    'hazardEventItem.hazard': 'Hazard',

    'hazards.confirmDelete': 'Are you sure you want to delete this hazard?',
    'hazards.hazards': 'Hazards',
    'hazards.addHazard': 'Add new hazard',

    'riskAssessment.riskAssessments': 'Risk Assessments',
    'riskAssessment.viewRiskAssessment': 'View Risk Assessment',
    'riskAssessment.riskAssessmentsUC': 'RISK ASSESSMENTS',
    'riskAssessment.confirmDelete': 'Are you sure you want to delete this risk assessment?',
    'riskAssessment.riskCategory': 'Risk Category',
    'riskAssessment.dateRaised': 'Date Raised',
    'riskAssessment.hazardEvent': 'Hazard Event',
    'riskAssessment.allInherentRiskRatings': 'All Inherent Risk Ratings',
    'riskAssessment.allResidualRiskRatings': 'All Residual Risk Ratings',
    'riskAssessment.searchRiskCategory': 'Search Risk Category',
    'riskAssessment.addRiskAssessment': 'Add new Risk Assessment',
    'riskAssessment.riskCertainty': 'Risk Certainty',
    'riskAssessment.inherentLikelihood': 'Inherent Likelihood',
    'riskAssessment.inherentConsequence': 'Inherent Consequence',
    'riskAssessment.inherentRiskRating': 'Inherent Risk Rating',
    'riskAssessment.inherentRiskScore': 'Inherent Risk Score',
    'riskAssessment.residualLikelihood': 'Residual Likelihood',
    'riskAssessment.residualConsequence': 'Residual Consequence',
    'riskAssessment.residualRiskRating': 'Residual Risk Rating',
    'riskAssessment.residualRiskScore': 'Residual Risk Score',
    'riskAssessment.basis': 'Basis',
    'riskAssessment.riskNumber': 'Risk Number',
    'riskAssessment.passedOn': 'Passed-On',
    'riskAssessment.riskResponseDetails': 'Risk Response Details',
    'riskAssessment.riskResponse': 'Risk Response',
    'riskAssessment.inherentRiskCategory': 'Inherent Risk Category',
    'riskAssessment.residualRiskCategory': 'Residual Risk Category',
    'riskAssessment.hazardEventRequired': 'Hazard event is a mandatory field',
    'riskAssessment.riskCategoryRequired': 'Risk category is a mandatory field',
    'riskAssessment.inherentRiskFieldsRequired': 'Inherent risk fields are mandatory',
    'riskAssessment.residualRiskFieldsRequired': 'Residual risk fields are mandatory',
    'riskAssessment.riskCertaintyMandatory': 'Risk certainty is a mandatory field',
    'riskAssessment.dateRaisedMandatory': 'Date raised is mandatory field',
    'riskAssessment.riskAssessment': 'Risk Assessment',
    'riskAssessment.editRiskAssessment': 'Edit Risk Assessment',
    'riskAssessment.riskNumber': 'Risk Number',
    'riskAssessment.searchRiskAssessment': 'Search Risk Assessment',
    'riskAssessment.duplicateRiskNumber': 'Duplicate Risk Number',
    'riskAssessment.riskAssessmentsForHazardEvent': 'Risk Assessments for Hazard Event',

    'safetyPlanControls.NoAccess': 'You do not have access to Safety Plan Controls. Please contact your administrator.',
    'safetyPlanControls.manageSafetyPlan': 'Manage Safety Plan',
    'safetyPlanControlsTabs.teams': 'Teams',
    'safetyPlanControlsTabs.stakeholderRegisters': 'Stakeholder Registers',
    'safetyPlanControlsTabs.emergencyReponsePlan': 'Emergency Response Plan',

    'safetyPlan.addNewSafetyPlan': 'Add new Safety Plan',
    'safetyPlan.planNumber': 'Plan Number',
    'safetyPlan.safetyPlans': 'Safety Plans',
    'safetyPlan.lastReviewDate': 'Last Review Date',
    'safetyPlan.nextReviewDate': 'Next Review Date',
    'safetyPlan.reviewFrequency': 'Review Frequency',
    'safetyPlan.searchSafetyPlanName': 'Search by Plan Name',

    'personnel.company': 'Company',
    'personnel.companyMandatory': 'Company is a mandatory field',
    'personnel.jobTitle': 'Job Title',
    'personnels.addPersonnel': 'Add new personnel',
    'personnel.preferredContactMethod': 'Preferred Contact Method',
    'personnel.confirmDelete': 'Are you sure you want to delete this personnel?',
    'personnel.searchLabelName': 'Search Name',
    'personnel.searchLabelCompany': 'Search Company',

    'regulatoryRegister.stakeholderRegisterMandatory': 'Stakeholder register is a mandatory field',
    'regulatoryRegister.documentNameRequired': 'Document name is a mandatory field',

    'regulatoryRegister.documentName': 'Document Name',
    'regulatoryRegister.jurisdiction': 'Jurisdiction',
    'regulatoryRegister.location': 'Location',
    'regulatoryRegister.yearApplicable': 'Year Applicable',
    'regulatoryRegister.lastReviewDate': 'Last Review Date',
    'regulatoryRegister.nextReviewDate': 'Next Review Date',
    'regulatoryRegister.reviewFrequency': 'Review Frequency',
    'regulatoryRegister.documentType': 'Document Type',
    'regulatoryRegister.allDocumentType': 'All Document Type',
    'regulatoryRegister.documentTypeMandatory': 'Document type is a mandatory field',
    'regulatoryRegister.subjectCategory': 'Subject Category',
    'regulatoryRegister.subjectCategoryMandatory': 'Subject category is a mandatory field',

    'team.roleRequired': 'Role is a mandatory field',
    'team.PersonnelMandatory': 'Personnel is a mandatory field',
    'team.addTeam': 'Add new Team',
    'team.role': 'Role',
    'team.personnel': 'Personnel',
    'team.responsibilities': 'Responsibilities',
    'team.confirmDelete': 'Are you sure you want to delete this team?',
    'team.searchLabelRole': 'Search Role',

    'stakeholderRegister.issuesRelationshipRequired': 'Issues Relationship is a mandatory field',
    'stakeholderRegister.PersonnelMandatory': 'Personnel is a mandatory field',
    'stakeholderRegister.addStakeholder': 'Add new Stakeholder',
    'stakeholderRegister.issuesRelationship': 'Issues Relationship',
    'stakeholderRegister.personnel': 'Personnel',
    'stakeholderRegister.keyPoint': 'Key Point',
    'stakeholderRegister.confirmDelete': 'Are you sure you want to delete this stakeholder?',

    'siteControlsTabs.safetyPlanReport': 'Safety Plan Report',
    'safetyPlan.safetyPlanReport': 'VIEW REPORTS',
    'safetyPlan.generateReport': 'GENERATE REPORT',
    'safetyPlan.generatedBy': 'Generated By',
    'safetyPlan.generatedDate': 'Generated Date',

    'emergencyPlan.confirmDelete': 'Are you sure you want to delete this Emergency Response Plan?',
    'emergencyPlan.emergencyResponsePlans': 'Emergency Response Plan',
    'emergencyPlan.hazardEvent': 'Hazard Event',
    'emergencyPlan.reviewFrequency': 'Review Frequency',
    'emergencyPlan.nextReviewDate': 'Next Review Date',
    'emergencyPlan.hazardEventMandatory': 'Hazard Event Mandatory',
    'emergencyPlan.nameMandatory': 'Name is mandatory field',
    'emergencyPlan.stakeholderMandatory': 'Stakeholder is mandatory field',
    'emergencyPlan.checklistTemplateMandatory': 'Checklist Template is mandatory field',
    'emergencyPlan.editEmergencyPlan': 'Edit',
    'emergencyPlan.addNewEmergencyPlan': 'Add new Emergency Response',
    'emergencyPlan.checklistTemplate': 'Checklist Template',
    'emergencyPlan.stakeholder': 'Stakeholder',
    'emergencyResponse.initiate': 'INITIATE',
    'emergencyPlan.attachedFiles': 'Attached Files',
    'emergencyPlan.checklists': 'Checklists',
    'emergencyPlan.searchChecklist': 'Search checklist',
    'emergencyResponse.dueDateTime': 'Due date time',
    'emergencyResponse.taskCompleted': 'Tasks Completed',
    'emergencyPlan.viewEmergencyPlan': 'View Emergency Response',

    'hazardEventControls.NoAccess': 'You do not have access to Hazard Event Controls. Please contact your administrator.',
    'hazardEventControls.manageHazardEvent': 'Manage Hazard Event',
    'hazardEventControlsTabs.controlMeasures': 'Control Measures',
    'hazardEventControlsTabs.improvementPlans': 'Improvement Plans',

    'improvementPlan.confirmDelete': 'Are you sure you want to delete this improvement plan?',
    'improvementPlan.dateRaised': 'Date Raised',
    'improvementPlan.dateDue': 'Date Due',
    'improvementPlan.hazardEventRequired': 'Hazard event name is a mandatory field',
    'improvementPlan.statusRequired': 'Status is a mandatory field',
    'improvementPlan.editImprovementPlan': 'Edit Improvement Plan',
    'improvementPlan.addImprovementPlan': 'Add new Improvement Plan',
    'improvementPlan.dateRaisedRequired': 'Date Raised is a mandatory field',
    'improvementPlan.improvementPlan': 'Improvement Plan',
    'improvementPlan.viewImprovementPlan': 'View Improvement Plan',
    'improvementPlan.improvementPlanForHazardEvents': 'Improvement Plans For Hazard Events',

    'controlMeasure.dateImplemented': 'Date Implemented',
    'controlMeasure.targetCriteria': 'Target Criteria',
    'controlMeasure.targetCriteriaType': 'Target Criteria Type',
    'controlMeasure.alertTargetTrigger': 'Alert Criteria Trigger',
    'controlMeasure.alertCriteria': 'Alert Criteria Type',
    'controlMeasure.criticalLimitTrigger': 'Critical Limit Trigger',
    'controlMeasure.criticalLimitType': 'Critical Limit Type',
    'controlMeasure.unitOfMeasure': 'Unit Of Measure',
    'controlMeasure.status': 'Status',
    'controlMeasure.hazardEvent': 'Hazard Event',
    'controlMeasure.dateImplementedRequired': 'Date implemented is a mandatory field',
    'controlMeasure.addNewControlMeasure': 'Add new Control Measure',
    'controlMeasure.confirmDelete': 'Are you sure you want to delete this control measure?',
    'controlMeasure.controlMeasure': 'Control Measure',
    'controlMeasure.controlMeasureView': 'View Control Measure',
    'controlMeasure.controlMeasures': 'Control Measures',
    'controlMeasure.controlMeasuresForHazardEvent': 'Control Measures for Hazard Events',
    'entity.knownhazardEventLibraryItems': 'Known Hazard Event Library Items',
    'entityItem.hazardEventLibraryRequired': 'Hazard Event Library required',
    'entityItem.hazardEventLibrary': 'Hazard Event Library',
    'entityItem.issueDetails': 'Issue Details',
    'controlMonitoring.controlMonitorings': 'Control Monitorings',
    'controlMonitoring.searchMonitoredItem': 'Search Monitored Item',
    'controlMonitoring.unitComponentPosition': 'Unit Component Position',
    'controlMonitoring.stakeholder': 'Stakeholder',
    'controlMonitoring.frequency': 'Frequency',
    'controlMonitoring.monitoredItem': 'Monitored Item',
    'controlMonitoring.whenMonitoredDetails': 'When Monitored Details',
    'controlMonitoring.whereMeasureTaken': 'Where Measure Taken',
    'controlMonitoring.records': 'Records',
    'controlMonitoring.unitComponentPositionMandatory': 'Unit Component Position is a mandatory field',
    'controlMonitoring.stakeholderMandatory': 'Stakeholder is a mandatory field',
    'controlMonitoring.monitoredItemMandatory': 'Monitored Item is a mandatory field',
    'controlMonitoring.addNewControlMonitoring': 'Add new Control Monitoring',
    'controlMonitoring.confirmDelete': 'Are you sure you want to delete this control monitoring?',
    'controlMonitoring.viewControlMonitoring': 'View Control Monitoring',
    'controlMonitoring.confirmDelete': 'Are you sure you want to delete this control monitor?',
    'controlMonitoring.controlMeasure': 'Control Measure',
    'controlMonitoring.controlMeasureMandatory': 'Control Measure is a mandatory field',
    'correctiveAction.correctiveActions': 'Corrective Actions',
    'correctiveAction.personnel': 'Personnel',
    'correctiveAction.whenActionTriggered': 'When Action Triggered',
    'correctiveAction.teamMandatory': 'Team is mandatory field',
    'correctiveAction.team': 'Team',
    'correctiveAction.viewCorrectiveAction': 'View Corrective Action',
    'correctiveAction.confirmDelete': 'Are you sure you want to delete this Corrective Action?',
    'correctiveAction.addNewCorrectiveAction': 'Add new Corrective Action',
    'correctiveAction.controlMonitoring': 'Control Monitoring',
    'correctiveAction.controlMonitoringMandatory': 'Control Monitoring is mandatory field',

    'sitePathway.SiteAssessedDate': 'Site Assessed Date',
    'sitePathway.SiteReviewDate': 'Site Review Date',
    'sitePathway.TotalContacts': 'Total Contacts',
    'sitePathway.TeamRoles': 'Team Roles',
    'sitePathway.Stakeholders': 'Stakeholders',
    'sitePathway.DocumentsRegistered': 'Documents Registered',
    'sitePathway.DocumentsDueForReview': 'Documents Due for Review',

    'sitePathway.TotalControlMeasures': 'Total Control Measures',
    'sitePathway.UnAssessedControlMeasures': 'Unassessed Control Measures',
    'sitePathway.NewControlMeasures': 'New Control Measures',
    'sitePathway.WIPControlMeasures': 'Work In Progress Control Measures',
    'sitePathway.InActiveControlMeasures': 'Inactive Control Measures',
    'sitePathway.TotalImprovementPlans': 'Total Improvement Plans',
    'sitePathway.NewImprovementPlans': 'New Improvement Plans',
    'sitePathway.PlannedImprovementPlans': 'Planned Improvement Plans',
    'sitePathway.CompletedImprovementPlans': 'Completed Improvement Plans',
    'sitePathway.ScheduledImprovementPlans3Mths': 'Improvement Plans Scheduled for <=3 Months',
    'sitePathway.ScheduledImprovementPlans6Mths': 'Improvement Plans Scheduled for <=6 Months',
    'sitePathway.ScheduledImprovementPlans12Mths': 'Improvement Plans Scheduled for <=12 Months',
    'sitePathway.ScheduledImprovementPlans18Mths': 'Scheduled Improvement Plans Less than 18 Months',

    'sitePathway.TotalSupplyDescriptions': 'Total Supply Descriptions',
    'sitePathway.SupplyDescriptionsAwaitingReview': 'Supply Descriptions Awaiting Review',
    'sitePathway.TotalElements': 'Total Elements',
    'sitePathway.ElementsAwaitingReview': 'Elements Awaiting Review',
    'sitePathway.TotalInspections': 'Total Inspections',
    'sitePathway.AvergageInspectionScore': 'Average Inspection Score',
    'sitePathway.LowScoreInspections': 'Low Score Inspections',
    'sitePathway.MediumScoreInspections': 'Medium Score Inspections',
    'sitePathway.HighScoreInspections': 'High Score Inspections',
    'sitePathway.VeryHighScoreInspections': 'Very High Score Inspections',
    'sitePathway.SamplesCompleted': 'Samples Completed',
    'sitePathway.SamplesScheduled': 'Samples Scheduled',

    'sitePathway.TotalSurveys': 'Total Surveys',
    'sitePathway.ActionedSurveys': 'Actioned Surveys',
    'sitePathway.LowScoreSurveys': 'Low Score Surveys',
    'sitePathway.MediumScoreSurveys': 'Medium Score Surveys',
    'sitePathway.HighScoreSurveys': 'High Score Surveys',
    'sitePathway.VeryHighScoreSurveys': 'Very High Score Surveys',
    'sitePathway.ScheduledSurveys1Mths': 'Surveys <=1 Month',
    'sitePathway.ScheduledSurveys3Mths': 'Surveys Scheduled <=3 Months',
    'sitePathway.AverageSurveyScore': 'Average Survey Score',
    'sitePathway.TotalSamples': 'Total Samples',
    'sitePathway.ActionedSamples': 'Actioned Samples',
    'sitePathway.ScheduledSamples1Mths': 'Samples Scheduled for <=1 Month',
    'sitePathway.Scheduledsamples3Mths': 'Samples Scheduled for <=3 Months',

    'sitePathway.TotalControlMonitors': 'Total Control Monitors',
    'sitePathway.ControlMonitorsLessThan6Mths': 'Control Monitors for within the last 6 months',
    'sitePathway.ControlMonitorsLessThan12Mths': 'Control Monitors for within the last 12 months',
    'sitePathway.ControlMonitorsLessThan18Mths': 'Control Monitors for within the last 18 months',
    'sitePathway.ControlMonitorsLessThan24Mths': 'Control Monitors for within the last 24 months',
    'sitePathway.ControlMonitorsLessThan36Mths': 'Control Monitors for within the last 36 months',
    'sitePathway.ControlMonitorsMoreThan36Mths': 'Control Monitors more than 36 months ago',

    'sitePathway.TotalCorrectiveActions': 'Total Corrective Actions',
    'sitePathway.TotalEmergencyResponsePlans': 'Total Emergency Response Plans',
    'sitePathway.TotalUnactionedEmergencyResponsePlans': 'Total Unactioned Emergency Response Plans',
    'sitePathway.TotalIncidentReports': 'Total Incident Reports',
    'sitePathway.TotalUnactionedIncidentReports': 'Total Unactioned Incident Reports',

    'sitePathway.TotalHazardEvents': 'Total Hazard Events',
    'sitePathway.RiskAssessedHazardEvents': 'Risk Assessed Hazard Events',
    'sitePathway.MicrobiologicalHazardEvents': 'Microbiological Hazard Events',
    'sitePathway.ChemicalHazardEvents': 'Chemical Hazard Events',
    'sitePathway.PhysicalHazardEvents': 'Physical Hazard Events',
    'sitePathway.RadiologicalHazardEvents': 'Radiological Hazard Events',
    'sitePathway.TotalRisks': 'Total Risks',
    'sitePathway.LowRisks': 'Low Risks',
    'sitePathway.MediumRisks': 'Medium Risks',
    'sitePathway.HighRisks': 'High Risks',
    'sitePathway.VeryHighRisks': 'Very Hish Risks',
    'sitePathway.NewRisks': 'New Risks',
    'sitePathway.OpenRisks': 'Open Risks',
    'sitePathway.ActionedRisks': 'Actioned Risks',
    'sitePathway.AverageInherentRiskScore': 'Average Inherent Risk Score',
    'sitePathway.AverageResidualRiskScore': 'Average Residual Risk Score',
    'sitePathway.HazardEvents': 'Hazard Events',
    'sitePathway.Risks': 'Risks',
    'sitePathway.ImprovementPlans': 'Improvement Plans',
    'sitePathway.ControlMeasures': 'Control Measures',

    'sitePathway.buildSegment': 'Build Segment',
    'sitePathway.defineSegment': 'Define Segment',
    'sitePathway.identifySegment': 'Identify Segment',
    'sitePathway.controlSegment': 'Control Segment',
    'sitePathway.monitorSegment': 'Monitor Segment',
    'sitePathway.measureSegment': 'Measure Segment',
    'sitePathway.respondSegment': 'Respond Segment',
    'sitePathway.planSegment': 'Plan Segment',

    'siteSegments.sitePath': 'Site Path',
    'siteSegments.mapView': 'Map View',
    'siteSegments.location': 'Location',
    'siteSegments.nextReviewDate': 'Next Review Date',
    'siteSegments.site': 'Site',
    'siteSegments.waterSupplyDescription': 'Water Supply Description',
    'siteSegments.hazardEvent': 'Hazard Event',
    'siteSegments.hazardCertainty': 'Hazard Certainty',

    'incidentReport.duplicatePersonReportingEmail': 'The Person Reporting Email already exists in People Register',
    'incidentReports.incidentReports': 'Incident Reports',
    'incidentReports.incidentDateTime': 'Incident Date & Time',
    'incidentReports.isHACCPCritical': 'Is HACCP Critical',
    'incidentReports.incidentDateRequired': 'Incident Date & Time is mandatory',
    'incidentReport.editIncidentReports': 'Edit Incident Report',
    'incidentReport.addNewIncidentReport': 'Add new Incident Report',
    'incidentReports.incidentReport': 'Incident Report',
    'incidentReports.personReporting': 'Person Reporting',
    'incidentReports.personReportingRequired': 'Person Reporting is mandatory',
    'incidentReports.personInvestigating': 'Person Investigating',
    'incidentReports.incidentCategory': 'Incident Category',
    'incidentReports.incidentConsequence': 'Incident Consequence',
    'incidentReports.personReportingDetails': 'Person Reporting Details',
    'incidentReports.saveInPeopleRegister': 'Save in People Register',
    'incidentReports.sourceOfIncidentFrom': 'Source of Incident',
    'incidentReports.sourceOfIncident': 'Source of Incident',
    'incidentReports.createIncidentReport': 'CREATE INCIDENT REPORT',
    'incidentReports.searchIncidentReport': 'Search Incident Report',

    'organisationTranslations.language': 'Language',
    'organisationTranslations.translationKey': 'Translation Key',
    'organisationTranslations.translationValue': 'Translation Value',
    'organisationTranslations.localeRequired': 'Locale is a mandatory field',
    'organisationTranslations.translationKeyRequired': 'Translation key is a mandatory field',
    'organisationTranslations.translationValueRequired': 'Translation value is a mandatory field',
    'organisationTranslations.addTranslation': 'Add new translation',
    'organisationTranslation.searchTranslationValue': 'Search translation value',
    'common.allTranslationKeys': 'All Translation Keys',
    'organisationTranslations.confirmDelete': 'Are you sure you want to delete translation item?',

    'generalFieldType.condition': 'Condition',
    'generalFieldType.frequency': 'Frequency',
    'generalFieldType.supplyType': 'Supply Type',
    'generalFieldType.treatmentType': 'Treatement Type',
    'generalFieldType.hazardClass': 'Hazard Class',
    'generalFieldType.hHazardEventCertainty': 'Hazard Event Certainty',
    'generalFieldType.hazardCategory': 'Hazard Category',
    'generalFieldType.riskResponse': 'Risk Response',
    'generalFieldType.riskCertainty': 'Risk Certainty',
    'generalFieldType.likelihood': 'Likelihood',
    'generalFieldType.riskStatus': 'Risk Status',
    'generalFieldType.controlMeasureCriteria': 'Control Measure Criteria',
    'generalFieldType.criticalLimitType': 'Critical Limit Type',
    'generalFieldType.generalStatus': 'General Status',
    'generalFieldType.documentType': 'Document Type',
    'generalFieldType.documentSubjectCategory': 'Document Subject Category',
    'generalFieldType.incidentCategory': 'Incident Category',
    'generalFieldType.incidentConsequence': 'Incident Consequence',
    'safetyReport.generatedSuccessfully': 'Report generated successfully',
    'safetyReport.generationFailed': 'Report generation Failed',
    null: ' ',
    save: 'Save'
  },
  es: {
    en: 'English',
    es: 'español',
    de: 'Deutsche',
    fr: 'Française',
    da: 'dansk',

    'common.yes': 'si',
    'common.no': 'No',

    'toggleSwitch.lableView': 'Cambiar a vista de etiqueta',

    'settings.language': 'Idioma  ',
    'settings.settings': 'ajustes',
    'settings.profile': 'Perfil'
  },
  de: {
    en: 'English',
    es: 'español',
    de: 'Deutsche',
    fr: 'Française',
    da: 'dansk',

    'common.yes': 'Ja',
    'common.no': 'Nein',

    'settings.language': 'Sprache  ',
    'settings.settings': 'Die Einstellungen',
    'settings.profile': 'Profil',

    'toggleSwitch.lableView': 'Zur Etikettenansicht wechseln'
  },
  da: {
    en: 'English',
    es: 'español',
    fr: 'Française',
    de: 'Deutsche',
    da: 'dansk',

    'common.yes': 'Ja',
    'common.no': 'Ingen',

    'settings.language': 'Sprog  ',
    'settings.settings': 'Indstillinger',
    'settings.profile': 'Profil',

    'toggleSwitch.lableView': 'Skift til etikettevisning'
  },
  fr: {
    en: 'English',
    es: 'español',
    fr: 'Française',
    de: 'Deutsche',
    da: 'dansk',

    'common.yes': 'Oui',
    'common.no': 'Non',

    'settings.language': 'Langue  ',
    'settings.settings': 'Réglages',
    'settings.profile': 'Profil',

    'toggleSwitch.lableView': 'Passer en vue d`étiquette'
  }
};

export default languageObject;
