import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './RegulatoryRegister.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import RegulatoryRegisterListTable from './RegulatoryRegisterListTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE, FREQUENCY, DOCUMENT_TYPE } from '../../../constants';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils/';
import { getUserProfileWidget, getRegulatoryRegister, getGeneralData, getSelectedSafetyPlan } from '../../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from '../../SearchBar/SearchBar';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';
import {
  getRegulatoryRegisterThunk,
  getFilterThunk,
  setFilteredDataThunk,
  saveRegulatoryRegisterThunk,
  setCurrentPage,
  setPageFilter
} from '../../../store/actions/safetyPlan/regulatoryRegister';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import { describeSafetyPlanThunk } from '../../../store/actions/safetyPlan/safetyPlan';
import Breadcrumb from '../../WSAControls/Breadcrumb';

const SEARCH_FILTER = 'SEARCH_FILTER';
const SEARCH_FILTER_DOCUMENT_NAME = 'SEARCH_FILTER_DOCUMENT_NAME';
const SEARCH_FILTER_JURISDICTION = 'SEARCH_FILTER_JURISDICTION';
const SEARCH_FILTER_DOCUMENT_TYPE = 'SEARCH_FILTER_DOCUMENT_TYPE';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const RegulatoryRegisterList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { safetyPlanId, siteId, segmentName, selectedTab } = useParams();

  segmentName = segmentName || 'build';
  selectedTab = selectedTab || '2';

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const regulatoryRegister = useSelector(state => getRegulatoryRegister(state));
  const generalData = useSelector(state => getGeneralData(state));
  const [searchFilter, setSearchFilter] = useState('');
  const [searchfilterType, setSearchFilterType] = useState(SEARCH_FILTER_DOCUMENT_NAME);
  const [filterDocumentTypeId, setFitlerDocumentTypeId] = useState('0');
  const [asendingOrder, setAsendingOrder] = useState(true);
  const [active, setActive] = useState(false);
  const [regulatoryRegisterId, setRegulatoryRegisterId] = useState(0);

  let offset = regulatoryRegister.offset || 0;
  let limit = regulatoryRegister.limit || 1000;
  let filterName = regulatoryRegister.filterBy.filterName || null;
  let filterJurisdiction = regulatoryRegister.filterBy.filterJurisdiction || null;
  let filterDocumentType = regulatoryRegister.filterBy.filterDocumentTypeId || null;

  useEffect(() => {
    dispatch(describeSafetyPlanThunk(safetyPlanId, 'WQSP_SAFETY_PLAN_LIST'));
  }, [safetyPlanId]);

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_REGULATORY_REGISTER_LIST', safetyPlanId));
  }, [dispatch, getFilterThunk, safetyPlanId]);

  useEffect(() => {
    let filterObj = {
      filterName: filterName,
      filterJurisdiction: filterJurisdiction,
      filterDocumentTypeId: filterDocumentType,
      offset: offset,
      limit: limit
    };
    dispatch(getRegulatoryRegisterThunk('WQSP_REGULATORY_REGISTER_LIST', filterObj, safetyPlanId));
  }, [getRegulatoryRegisterThunk, filterName, filterJurisdiction, safetyPlanId, filterDocumentType]);

  const selectedSafetyPlan = useSelector(state => getSelectedSafetyPlan(state));
  const safetyPlanName = (selectedSafetyPlan.Name && ` (${selectedSafetyPlan.Name})`) || '';

  let disableControls = regulatoryRegister.isLoading || false;
  let messageId = (regulatoryRegister && regulatoryRegister.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });
  let ddlInitText = intl.formatMessage({ id: 'regulatoryRegister.allDocumentType', defaultMessage: 'All Document Type' });

  let isEditAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_REGULATORY_REGISTER_EDIT');
  const documentTypes = generalData.generalFieldTypes.filter(item => item.FieldType === DOCUMENT_TYPE);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const searchDcoumentTypeIdHandler = e => {
    setFitlerDocumentTypeId(e.value);
    filteredResults(SEARCH_FILTER_DOCUMENT_TYPE, e.value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = regulatoryRegister.regulatoryRegisters;

    let apifilterObj = {
      filterName: null,
      filterJurisdiction: null,
      filterDocumentTypeId: null
    };

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_JURISDICTION) {
      if (value !== '') {
        apifilterObj.filterJurisdiction = value;
        filteredList = filteredList.filter(function(item) {
          if (item.Jurisdiction) {
            return item.Jurisdiction.toLowerCase().includes(value.toLowerCase());
          }
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_JURISDICTION) {
        apifilterObj.filterJurisdiction = searchFilter;
        filteredList = filteredList.filter(function(item) {
          if (item.Jurisdiction) {
            return item.Jurisdiction.toLowerCase().includes(searchFilter.toLowerCase());
          }
        });
      }
    }

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_DOCUMENT_NAME) {
      if (value !== '') {
        apifilterObj.filterName = value;
        filteredList = filteredList.filter(function(item) {
          return item.DocumentName.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_DOCUMENT_NAME) {
        apifilterObj.filterName = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.DocumentName.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (filterType === 'SEARCH_FILTER_DOCUMENT_TYPE') {
      if (value !== '0') {
        apifilterObj.filterDocumentTypeId = value;
        filteredList = filteredList.filter(function(item) {
          return item.DocumentTypeId === value;
        });
      }
    } else {
      if (filterDocumentTypeId !== '0') {
        apifilterObj.filterDocumentTypeId = filterDocumentTypeId;
        filteredList = filteredList.filter(function(item) {
          return item.DocumentTypeId === filterDocumentTypeId;
        });
      }
    }

    if (regulatoryRegister.regulatoryRegisterListCount > regulatoryRegister.regulatoryRegisters.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let typePlaceHolder = intl.formatMessage({ id: 'regulatoryRegister.documentName', defaultMessage: 'Document Name' });

  const typeOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="regulatoryRegister.documentName" defaultMessage="Document Name" />,
      value: SEARCH_FILTER_DOCUMENT_NAME
    });

    statusData.push({
      label: <FormattedMessage id="regulatoryRegister.jurisdiction" defaultMessage="Jurisdiction" />,
      value: SEARCH_FILTER_JURISDICTION
    });

    return statusData;
  };

  const documentTypeOptions = () => {
    let documentTypesData = [];
    documentTypes.forEach(element => {
      documentTypesData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = documentTypesData.sort(compareValues('label'));
    return [{ value: '0', label: ddlInitText }, ...sortedData];
  };

  const onTypeChange = e => {
    setSearchFilterType(e.value);
    setCurrentPage(1);
  };

  let regulatoryRegisterObj = Object.values(regulatoryRegister.filteredResults);

  if (asendingOrder) {
    regulatoryRegisterObj = regulatoryRegisterObj.sort(compareValues('NextReviewDate'));
  } else {
    regulatoryRegisterObj = regulatoryRegisterObj.sort(compareValues('NextReviewDate', 'desc'));
  }

  const onAddNewClick = () => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/regulatory-registers/regulatory-register`);
  };

  const onChangeRegulatoryRegisterClick = regulatoryRegisterId => {
    history.push(
      `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/regulatory-registers/regulatory-register/${regulatoryRegisterId}`
    );
  };

  const onRegulatoryRegisterRemoveClick = regulatoryRegisterId => {
    setActive(true);
    setRegulatoryRegisterId(regulatoryRegisterId);
  };

  const handleClose = () => {
    setActive(false);
    setRegulatoryRegisterId(0);
  };

  const handleConfirm = () => {
    deleteRegulatoryRegister();
    handleClose();
  };

  const deleteRegulatoryRegister = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.RegulatoryRegisterId = regulatoryRegisterId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveRegulatoryRegisterThunk(saveData, 'WQSP_REGULATORY_REGISTER_DELETE'));
  };

  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_REGULATORY_REGISTER_DELETE');
  const dialogTitle = intl.formatMessage({
    id: 'regulatoryRegister.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this regulatory register?'
  });

  let heading = intl.formatMessage({ id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' });
  heading += `${safetyPlanName}`;

  const breadcrumb = [
    {
      orderNo: 1,
      name: heading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="siteControlsTabs.regulatoryRegisters" defaultMessage="Regulatory Registers" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/regulatory-registers`,
      showlink: false,
      showCrumb: true
    }
  ];

  return (
    <div className={s.regulatoryRegisterList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />

        <div className={s.contentWrapper}>
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
          {regulatoryRegister.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={regulatoryRegister.showBanner}
            status={regulatoryRegister.isOpSuccessful}
            successText={messageText}
          />

          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={6}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId={
                  searchfilterType === SEARCH_FILTER_DOCUMENT_NAME ? 'regulatoryRegister.documentName' : 'regulatoryRegister.jurisdiction'
                }
                disabledDrp={disableControls}
                id="txtSearchBar"
                showFilterType
                searchfilterType={searchfilterType}
                onFilterTypeChange={onTypeChange}
                filterTypeData={typeOptions()}
                filterPlaceholderText={typePlaceHolder}
              />
            </Col>
            <Col sm={6} lg={6}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_REGULATORY_REGISTER_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addRegulatoryRegister"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={3}>
              <Dropdown
                id="formDdlDocumentType"
                dataArray={documentTypeOptions()}
                controlData={{ placeholderText: ddlInitText }}
                onDropdownChange={searchDcoumentTypeIdHandler}
                data-unittest="formDdlDocumentType"
                selectedOption={documentTypeOptions().filter(option => option.value === filterDocumentTypeId)}
              />
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={regulatoryRegisterObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={regulatoryRegister.currentPage}
            >
              <RegulatoryRegisterListTable
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                editAllowed={isEditAllowed}
                deleteAllowed={isDeleteAllowed}
                editRegulatoryRegister={onChangeRegulatoryRegisterClick}
                removeRegulatoryRegister={onRegulatoryRegisterRemoveClick}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(RegulatoryRegisterList);
