import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './EmergencyPlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialEmergencyResponsePlanState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import { Redirect, Link, useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { compareValues } from '../../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import { IsWidgetAccessible } from '../../../utils/widgetManager';
import 'react-datetime/css/react-datetime.css';
import DateTimePicker from '../../WSAControls/DateTimePicker/DateTimePicker';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import FileUploader from '../../WSAControls/FileUploader/FileUploader';
import FileList from '../../WSAControls/FileList/FileList';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';

import {
  getUserProfileWidget,
  getGeneralData,
  getCurrentTimezone,
  getEmergencyResponsePlan,
  getStakeholderRegister,
  getHazardEvent,
  getChecklistTemplate
} from '../../../selectors/index';

import {
  describeEmergencyResponsePlanThunk,
  saveEmergencyResponsePlanThunk,
  setEmergencyResponsePlanChangeThunk
} from '../../../store/actions/safetyPlan/emergencyResponsePlan';

import { getHazardEventThunk } from '../../../store/actions/hazardEvent/hazardEvent';
import { getChecklistTemplateThunk } from '../../../store/actions/checklistTemplates';
import { getStakeholderRegisterThunk } from '../../../store/actions/safetyPlan/stakeholderRegister';
import GoBack from '../../WSAControls/GoBack/GoBack';
import { FREQUENCY, GENERAL_STATUS } from '../../../constants/index';
import { getQuerystring } from '../../../utils/index';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const EmergencyPlan = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  const history = useHistory();
  let { emergencyResponsePlanId, siteId, segmentName, selectedTab } = useParams();
  siteId = (siteId && parseInt(siteId)) || '0';

  const [localformErrors, setFormErrors] = useState({});
  const [disableMode, setDisableMode] = useState(false);
  const [fileUploadStarted, setFileUploadStarted] = useState(false);

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);
  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const emergencyPlan = useSelector(state => getEmergencyResponsePlan(state));
  const hazardEvent = useSelector(state => getHazardEvent(state));
  const checklistTemplate = useSelector(state => getChecklistTemplate(state));
  const generalData = useSelector(state => getGeneralData(state));
  const stakeholderRegister = useSelector(state => getStakeholderRegister(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ERP_EDIT');
  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  const reviewFrequencies = generalData.generalFieldTypes.filter(item => item.FieldType === FREQUENCY);

  if (emergencyResponsePlanId && emergencyResponsePlanId > 0) {
    IsEdit = true;
  } else {
    emergencyResponsePlanId = 0;
  }

  let widgetCode = 'WQSP_ERP_ADDNEW';
  let backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/emergency-response-plans`;
  if (IsEdit) {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/emergency-response-plans/emergency-response-plan-view/${emergencyResponsePlanId}`;
    widgetCode = 'WQSP_ERP_EDIT';
  }

  useEffect(() => {
    dispatch(getHazardEventThunk(widgetCode, {}));
  }, [getHazardEventThunk]);

  useEffect(() => {
    dispatch(getChecklistTemplateThunk(widgetCode, {}));
  }, [getChecklistTemplateThunk]);

  useEffect(() => {
    let filterObj = {
      siteId: siteId
    };
    dispatch(getStakeholderRegisterThunk(widgetCode, filterObj, null));
  }, [getStakeholderRegisterThunk]);

  useEffect(() => {
    if (IsEdit) dispatch(describeEmergencyResponsePlanThunk(emergencyResponsePlanId, widgetCode));
  }, [describeEmergencyResponsePlanThunk, emergencyResponsePlanId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setEmergencyResponsePlanChangeThunk({
          ...initialEmergencyResponsePlanState.emergencyResponsePlan
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalEmergencyPlan = currentState => {
    dispatch(setEmergencyResponsePlanChangeThunk(currentState));
  };

  useEffect(() => {
    if (siteId && !emergencyPlan.selectedEmergencyResponsePlan.SiteId) {
      setLocalEmergencyPlan({
        ...emergencyPlan,
        selectedEmergencyResponsePlan: { ...emergencyPlan.selectedEmergencyResponsePlan, SiteId: siteId }
      });
    }
  }, [setLocalEmergencyPlan, siteId]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = {
      ...emergencyPlan.selectedEmergencyResponsePlan
      // WaterQualitySafetyPlanId: safetyPlanId
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.EmergencyResponsePlanId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    dispatch(saveEmergencyResponsePlanThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!emergencyPlan.selectedEmergencyResponsePlan || !emergencyPlan.selectedEmergencyResponsePlan.WaterSupplyDescriptionId) {
      formErrors.WaterSupply = intl.formatMessage({
        id: 'wqsp.common.waterSupplyMandatory',
        defaultMessage: 'Water supply name is mandatory field'
      });
      isValid = false;
    }

    if (!emergencyPlan.selectedEmergencyResponsePlan || !emergencyPlan.selectedEmergencyResponsePlan.EntityTypeId) {
      formErrors.EntityType = intl.formatMessage({
        id: 'wqsp.common.entityTypeMandatory',
        defaultMessage: 'Entity type is mandatory field'
      });
      isValid = false;
    }

    if (!emergencyPlan.selectedEmergencyResponsePlan || !emergencyPlan.selectedEmergencyResponsePlan.EntityId) {
      formErrors.Entity = intl.formatMessage({
        id: 'wqsp.common.entityMandatory',
        defaultMessage: 'Entity is mandatory field'
      });
      isValid = false;
    }

    if (!emergencyPlan.selectedEmergencyResponsePlan || !emergencyPlan.selectedEmergencyResponsePlan.HazardEventId) {
      formErrors.HazardEvent = intl.formatMessage({
        id: 'emergencyPlan.hazardEventMandatory',
        defaultMessage: 'Hazard Event is mandatory field'
      });
      isValid = false;
    }

    if (!emergencyPlan.selectedEmergencyResponsePlan || !emergencyPlan.selectedEmergencyResponsePlan.Name) {
      formErrors.Name = intl.formatMessage({
        id: 'emergencyPlan.nameMandatory',
        defaultMessage: 'Name is mandatory field'
      });
      isValid = false;
    }

    if (!emergencyPlan.selectedEmergencyResponsePlan || !emergencyPlan.selectedEmergencyResponsePlan.StakeholderRegisterId) {
      formErrors.StakeholderRegister = intl.formatMessage({
        id: 'emergencyPlan.stakeholderMandatory',
        defaultMessage: 'Stakeholder is mandatory field'
      });
      isValid = false;
    }

    if (!emergencyPlan.selectedEmergencyResponsePlan || !emergencyPlan.selectedEmergencyResponsePlan.ChecklistTemplateId) {
      formErrors.ChecklistTemplate = intl.formatMessage({
        id: 'emergencyPlan.checklistTemplateMandatory',
        defaultMessage: 'Checklist Template is mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalEmergencyPlan({
        ...emergencyPlan,
        selectedEmergencyResponsePlan: { ...emergencyPlan.selectedEmergencyResponsePlan, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalEmergencyPlan({
        ...emergencyPlan,
        selectedEmergencyResponsePlan: { ...emergencyPlan.selectedEmergencyResponsePlan, [e.target.name]: e.target.value }
      });
    }
  };

  const siteOptions = () => {
    let siteData = [];
    generalData.sites.forEach(element => {
      siteData.push({
        ...element,
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onSiteDropdownChange = e => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        SiteId: e.value,
        WaterSupplyDescriptionId: 0,
        EntityId: 0
      }
    });
  };

  const waterSupplyOptions = () => {
    let waterSupplyData = [];
    const filteredData = generalData.waterSupplyDescriptions.filter(item => item.SiteId === siteId);
    filteredData.forEach(element => {
      waterSupplyData.push({
        ...element,
        label: element.Name,
        value: element.WaterSupplyDescriptionId
      });
    });

    let sortedData = waterSupplyData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onWaterSupplyDropdownChange = e => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        WaterSupplyDescriptionId: e.value
      }
    });
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];
    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityTypeDropdownChange = e => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        EntityTypeId: e.value,
        EntityId: 0,
        EmergencyPlanLibraryId: 0
      }
    });
  };

  const entityOptions = () => {
    let entityData = [];
    let entitySubTypes = generalData.entitySubTypes.filter(
      item => item.EntityTypeId === emergencyPlan.selectedEmergencyResponsePlan.EntityTypeId
    );
    let entities = generalData.entities.filter(item =>
      entitySubTypes.find(
        type =>
          type.EntitySubTypeId === item.EntitySubTypeId &&
          item.WaterSupplyDescriptionId === emergencyPlan.selectedEmergencyResponsePlan.WaterSupplyDescriptionId
      )
    );
    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityDropdownChange = e => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        EntityId: e.value
      }
    });
  };

  const hazardEventOptions = () => {
    let hzardEventData = [];
    hazardEvent.hazardEvents.forEach(element => {
      if (element.EntityId === emergencyPlan.selectedEmergencyResponsePlan.EntityId) {
        hzardEventData.push({
          ...element,
          label: element.Name,
          value: element.HazardEventId
        });
      }
    });

    let sortedData = hzardEventData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onHazardEventDropdownChange = e => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        HazardEventId: e.value
      }
    });
  };

  const reviewFrequencyOptions = () => {
    let reviewFrequencyData = [];
    reviewFrequencies.forEach(element => {
      reviewFrequencyData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = reviewFrequencyData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };
  const onStatusChange = e => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        [e.target.name]: e.target.value,
        StatusId: null
      }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        [fieldName]: e.value
      }
    });
  };

  const onReviewFrequencyDropdownChange = e => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        ReviewFrequencyId: e.value
      }
    });
  };

  const checklistTemplateOptions = () => {
    let templateData = [];
    const filteredData = checklistTemplate.ChecklistTemplates.filter(item => item.IsERP);
    filteredData.forEach(element => {
      templateData.push({
        ...element,
        label: element.Name,
        value: element.ChecklistTemplateId
      });
    });

    let sortedData = templateData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onChecklistTemplateDropdownChange = e => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        ChecklistTemplateId: e.value
      }
    });
  };

  const stakeholderOptions = () => {
    let stakeholderData = [];
    stakeholderRegister.stakeholderRegisters.forEach(element => {
      stakeholderData.push({
        ...element,
        label: element.Name,
        value: element.StakeholderRegisterId
      });
    });

    let sortedData = stakeholderData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onStakeholderDropdownChange = e => {
    setLocalEmergencyPlan({
      selectedEmergencyResponsePlan: {
        ...emergencyPlan.selectedEmergencyResponsePlan,
        StakeholderRegisterId: e.value
      }
    });
  };

  const onUploadStart = files => {
    setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          EmergencyResponsePlanId: 0,
          newFile: true,
          ImageId: item.fileGuid,
          Name: item.name,
          S3URL: item.S3URL
        };
      });
      let allFiles = [...emergencyPlan.selectedEmergencyResponsePlan.files, ...newFiles];
      setLocalEmergencyPlan({
        selectedEmergencyResponsePlan: {
          ...emergencyPlan.selectedEmergencyResponsePlan,
          files: allFiles
        }
      });
    }
  };

  const removeFile = fileGuid => {
    let files = emergencyPlan.selectedEmergencyResponsePlan.files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...emergencyPlan.selectedEmergencyResponsePlan.deletedFileIds];
    deletedFileIds.push(fileGuid);

    setLocalEmergencyPlan({
      ...emergencyPlan,
      selectedEmergencyResponsePlan: { ...emergencyPlan.selectedEmergencyResponsePlan, files: files, deletedFileIds: deletedFileIds }
    });
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  let messageId = (emergencyPlan && emergencyPlan.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' });
    heading = `${heading} - ${emergencyPlan.selectedEmergencyResponsePlan.Name}`;
  } else {
    heading = intl.formatMessage({ id: 'emergencyPlan.addNewEmergencyPlan', defaultMessage: 'Add new Emergency Response' });
  }

  const breadcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.listOfEmergencyResponses" defaultMessage="List of Emergency Responses" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/emergency-response-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="emergencyPlan.viewEmergencyPlan" defaultMessage="View Emergency Response" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/emergency-response-plans/emergency-response-plan-view/${emergencyResponsePlanId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <div className={s.emergencyplan}>
      {(emergencyPlan.isLoading || hazardEvent.isLoading || checklistTemplate.isLoading) && <LoadingSpinner />}
      {emergencyPlan.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={emergencyPlan.showBanner}
        status={emergencyPlan.isOpSuccessful}
        successText={messageText}
      />
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.contentWrapper}>
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
          <div className={s.emergencyplanContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEmergencyPlanName">
                    <Form.Label>
                      <FormattedMessage id="emergencyPlan.name" defaultMessage="Name" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={emergencyPlan.selectedEmergencyResponsePlan.Name}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'emergencyPlan.name',
                        defaultMessage: 'Name'
                      })}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formEmergencyPlanDescription">
                    <Form.Label>
                      <FormattedMessage id="common.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      className={s.textArea}
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'common.description',
                        defaultMessage: 'Description'
                      })}
                      value={emergencyPlan.selectedEmergencyResponsePlan.Description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEmergencyPlanSiteName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlSite"
                      dataArray={siteOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Site && s.ddlError }}
                      onDropdownChange={onSiteDropdownChange}
                      data-unittest="formDdlSite"
                      selectedOption={siteOptions().filter(option => option.value === emergencyPlan.selectedEmergencyResponsePlan.SiteId)}
                      disabled={true}
                    />
                    {localformErrors && localformErrors.Site && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Site}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEmergencyPlanSupplyName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlWaterSupply"
                      dataArray={waterSupplyOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.WaterSupply && s.ddlError }}
                      onDropdownChange={onWaterSupplyDropdownChange}
                      data-unittest="formDdlWaterSupply"
                      selectedOption={waterSupplyOptions().filter(
                        option => option.value === emergencyPlan.selectedEmergencyResponsePlan.WaterSupplyDescriptionId
                      )}
                      disabled={IsEdit}
                    />
                    {localformErrors && localformErrors.WaterSupply && (
                      <p role="alert" className={s.error}>
                        {localformErrors.WaterSupply}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEmergencyPlanEntityType">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntityType"
                      dataArray={entityTypeOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.EntityType && s.ddlError }}
                      onDropdownChange={onEntityTypeDropdownChange}
                      data-unittest="formDdlEntityType"
                      selectedOption={entityTypeOptions().filter(
                        option => option.value === emergencyPlan.selectedEmergencyResponsePlan.EntityTypeId
                      )}
                    />
                    {localformErrors && localformErrors.EntityType && (
                      <p role="alert" className={s.error}>
                        {localformErrors.EntityType}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEmergencyPlanEntity">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntity"
                      dataArray={entityOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Entity && s.ddlError }}
                      onDropdownChange={onEntityDropdownChange}
                      data-unittest="formDdlEntity"
                      selectedOption={entityOptions().filter(
                        option => option.value === emergencyPlan.selectedEmergencyResponsePlan.EntityId
                      )}
                    />
                    {localformErrors && localformErrors.Entity && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Entity}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEmergencyPlanHazardEvent">
                    <Form.Label>
                      <FormattedMessage id="emergencyPlan.hazardEvent" defaultMessage="Hazard Event" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEmergencyPlanHazardEvent"
                      dataArray={hazardEventOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.HazardEvent && s.ddlError }}
                      onDropdownChange={onHazardEventDropdownChange}
                      data-unittest="formDdlTemplate"
                      selectedOption={hazardEventOptions().filter(
                        option => option.HazardEventId === emergencyPlan.selectedEmergencyResponsePlan.HazardEventId
                      )}
                    />
                    {localformErrors && localformErrors.HazardEvent && (
                      <p role="alert" className={s.error}>
                        {localformErrors.HazardEvent}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEmergencyPlanChecklistTemplate">
                    <Form.Label>
                      <FormattedMessage id="emergencyPlan.checklistTemplate" defaultMessage="Checklist Template" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEmergencyPlanChecklistTemplate"
                      dataArray={checklistTemplateOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.ChecklistTemplate && s.ddlError }}
                      onDropdownChange={onChecklistTemplateDropdownChange}
                      data-unittest="formDdlChecklistTemplate"
                      selectedOption={checklistTemplateOptions().filter(
                        option => option.ChecklistTemplateId === emergencyPlan.selectedEmergencyResponsePlan.ChecklistTemplateId
                      )}
                    />
                    {localformErrors && localformErrors.ChecklistTemplate && (
                      <p role="alert" className={s.error}>
                        {localformErrors.ChecklistTemplate}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEmergencyPlanStakeholder">
                    <Form.Label>
                      <FormattedMessage id="emergencyPlan.stakeholder" defaultMessage="Stakeholder" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlStakeholder"
                      dataArray={stakeholderOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.StakeholderRegister && s.ddlError }}
                      onDropdownChange={onStakeholderDropdownChange}
                      data-unittest="formDdlStakeholder"
                      selectedOption={stakeholderOptions().filter(
                        option => option.value === emergencyPlan.selectedEmergencyResponsePlan.StakeholderRegisterId
                      )}
                    />
                    {localformErrors && localformErrors.StakeholderRegister && (
                      <p role="alert" className={s.error}>
                        {localformErrors.StakeholderRegister}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formLastReviewDate">
                    <Form.Label>
                      <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                    </Form.Label>
                    <DateTimePicker
                      isValidDate={disableFutureDates}
                      className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                      onChange={m => {
                        onChange({ target: { name: 'LastReviewDate', value: (m && m.unix && m.unix()) || null } });
                      }}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(emergencyPlan.selectedEmergencyResponsePlan.LastReviewDate)}
                      defaultValue={getFormattedate(emergencyPlan.selectedEmergencyResponsePlan.LastReviewDate)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formEmergencyNextReviewDate">
                    <Form.Label>
                      <FormattedMessage id="emergencyPlan.nextReviewDate" defaultMessage="Next Review Date" />
                    </Form.Label>

                    <DateTimePicker
                      className={s.frmCalendar}
                      onChange={m => {
                        onChange({ target: { name: 'NextReviewDate', value: (m && m.unix && m.unix()) || null } });
                      }}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(emergencyPlan.selectedEmergencyResponsePlan.NextReviewDate)}
                      defaultValue={getFormattedate(emergencyPlan.selectedEmergencyResponsePlan.NextReviewDate)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formEmergencyPlanReviewFrequency">
                    <Form.Label>
                      <FormattedMessage id="emergencyPlan.reviewFrequency" defaultMessage="Review Frequency" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlReviewFrequency"
                      dataArray={reviewFrequencyOptions()}
                      controlData={{ placeholderText: ddlInitText }}
                      onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                      data-unittest="formDdlReviewFrequency"
                      selectedOption={reviewFrequencyOptions().filter(
                        option => option.value === emergencyPlan.selectedEmergencyResponsePlan.ReviewFrequencyId
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group controlId="formActioned">
                    <Form.Label>
                      <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                    </Form.Label>

                    <ToggleSwitch
                      handleClick={() => {
                        onStatusChange({ target: { name: 'IsActioned', value: !emergencyPlan.selectedEmergencyResponsePlan.IsActioned } });
                      }}
                      classname={s.switch}
                      checked={emergencyPlan.selectedEmergencyResponsePlan.IsActioned}
                      labelChecked={yesText}
                      labelUnchecked={noText}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {!emergencyPlan.selectedEmergencyResponsePlan.IsActioned && (
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formStatus">
                      <Form.Label>
                        <FormattedMessage id="common.status" defaultMessage="Status" />
                      </Form.Label>

                      <Dropdown
                        id="formStatus"
                        dataArray={statusOptions()}
                        controlData={{ placeholderText: ddlInitText }}
                        onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                        data-unittest="formDdlRiskStatus"
                        selectedOption={statusOptions().filter(
                          option => option.value === emergencyPlan.selectedEmergencyResponsePlan.StatusId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Form.Label>
                    <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                  </Form.Label>
                  <FileUploader
                    widgetCode={widgetCode}
                    allowedFileTypes={[
                      ['image/jpeg', 'jpeg'],
                      ['application/pdf', 'pdf'],
                      ['application/msword', 'doc']
                    ]}
                    location={`organisation/${orgId}/sites/${siteId}/emergencyresponseplan`}
                    enableMultipleUpload={true}
                    maxFiles={5}
                    onUploadComplete={onUploadComplete}
                    onUploadStart={onUploadStart}
                  />
                </Col>
              </Row>
              <FileList files={emergencyPlan.selectedEmergencyResponsePlan.files} onRemoveFile={removeFile} />
            </Form>
          </div>
          <div>
            <Row className={s.breakRow}>
              <Col sm={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                  disabled={disableMode || emergencyPlan.isLoading || fileUploadStarted}
                >
                  <FormattedMessage id="common.save" defaultMessage="SAVE" />
                </Button>
                <Link to={backUrl}>
                  <Button variant="outline-secondary" className={s.btnCancel} disabled={disableMode}>
                    <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(EmergencyPlan);
