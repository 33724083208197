//UI
export const IS_LOADING = 'IS_LOADING';
// export const REFRESH_TIME = 10000; //10 seconds
export const REFRESH_TIME = 120000; //120 seconds
export const REFRESH_TIME_OTHER = 60000; //60 seconds
export const DEFAULT_PAGE_SIZE = 20;

//domain name to set cookies
export const DOMAIN_NAME =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' ? 'localhost' : process.env.REACT_APP_DOMAIN_URL;

export const IS_LOCALHOST = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' ? true : false;

export const API = 'API';

//USERS
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_FORGOT_PASSWORD_STATUS = 'LOGIN_FORGOT_PASSWORD_STATUS';
export const USER_DETAILS = 'USER_DETAILS';
export const LOGOUT = 'LOGOUT';
export const MFA_VERIFICATION_ERROR = 'MFA_VERIFICATION_ERROR';

//User Management -
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_ALL_USER_DETAILS = 'GET_ALL_USER_DETAILS';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_MANAGEMENT_ERROR = 'USER_MANAGEMENT_ERROR';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';
export const USER_UI_DETAILS = 'USER_UI_DETAILS';
export const SET_USER_CURRENT_PAGE = 'SET_USER_CURRENT_PAGE';
export const SET_USER_PAGE_FILTER = 'SET_USER_PAGE_FILTER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const UPDATE_USERPROFILE_SAVE_STATUS = 'UPDATE_USERPROFILE_SAVE_STATUS';
export const UPDATE_CHANGEPASSWORD_STATUS = 'UPDATE_CHANGEPASSWORD_STATUS';
export const USERDETAILS_CLEANUP = 'USERDETAILS_CLEANUP';

export const LINE_COLOURS = [
  '#0081b3',
  '#5B995A',
  '#D9196C',
  '#FFB300',
  '#3D4A74',
  '#3BABFD',
  '#96D65E',
  '#3A506B',
  '#8D5524',
  '#F95F41',
  '#EFB0C9',
  '#8746F1',
  '#D03A67'
];

//Language Selectors
export const LANGUAGE_TYPES = {
  en: { name: 'English' },
  de: { name: 'Deutsche' },
  fr: { name: 'Française' },
  es: { name: 'Español' },
  da: { name: 'Dansk' }
};

export const CHANGE_LOCALE_LANGUAGE_SUCCESS = 'CHANGE_LOCALE_LANGUAGE_SUCCESS';
export const SET_TIMEZONE = 'CHANGE_TIMEZONE_SUCCESS';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

//Table Key Naming Prefix

export const TABLEKEY_NAMES = {
  profile: 'PROF#',
  unit: 'UNIT#',
  user: 'USER#',
  userprofile: 'USER#PROF#',
  widget: 'WDGT#',
  metadata: '#METADATA#'
};

//Profile
export const ADD_PROFILE = 'ADD_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_ALL_PROFILE_DETAILS = 'GET_ALL_PROFILE_DETAILS';
export const PROFILE_DETAIL = 'PROFILE_DETAIL';
export const PROFILE_IS_LOADING = 'PROFILE_IS_LOADING';
export const PROFILE_ERROR = 'ERROR_PROFILE';
export const UPDATE_SAVE_STATUS = 'UPDATE_SAVE_STATUS';
export const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE';
export const PROFILE_BANNER = 'PROFILE_BANNER';
export const UPDATE_ERROR_STATUS = 'UPDATE_ERROR_STATUS';
export const SET_SELECTED_PROFILE_WIDGET = 'SET_SELECTED_PROFILE_WIDGET';

//Widgets
export const ADD_WIDGET = 'ADD_WIDGET';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const GET_ALL_WIDGET_DETAILS = 'GET_ALL_WIDGET_DETAILS';
export const WIDGET_DETAIL = 'WIDGET_DETAIL';
export const WIDGET_IS_SAVING = 'WIDGET_IS_SAVING';
export const WIDGET_ERROR = 'ERROR_WIDGET';
export const UPDATE_WIDGET_SAVE_STATUS = 'UPDATE_WIDGET_SAVE_STATUS';
export const WIDGET_IS_LOADING = 'WIDGET_IS_LOADING';

//User profile Widgets
export const GET_ALL_USER_PROFILE_WIDGET_DETAILS = 'GET_ALL_PROFILE_WIDGET_DETAILS';
export const GET_ALL_USER_PROFILE_WIDGET_ERROR = 'GET_ALL_USER_PROFILE_WIDGET_ERROR';
export const SET_USER_PROFILE_WIDGET_DETAILS = 'SET_USER_PROFILE_WIDGET_DETAILS';
export const GET_ALL_USER_PROFILE_UNITS = 'GET_ALL_USER_PROFILE_UNIT';
export const SET_USER_PROFILE_WIDGET_DETAILS_LOADING = 'SET_USER_PROFILE_WIDGET_DETAILS_LOADING';
export const DEVICE_LOADING = 'DEVICE_LOADING';
export const SET_DEVICES = 'SET_DEVICES';
export const SELECT_ALL_PAGES = 'Select All Pages';

//Organisation Management -
export const GET_ALL_ORGANISATION_DETAILS = 'GET_ALL_ORGANISATION_DETAILS';
export const ORGANISATION_IS_LOADING = 'ORGANISATION_IS_LOADING';
export const ORGANISATION_MANAGEMENT_ERROR = 'ORGANISATION_MANAGEMENT_ERROR';
export const UPDATE_ORGANISATION_ATTRIBUTES = 'UPDATE_ORGANISATION_ATTRIBUTES';
export const SET_ORGANISATION_DETAILS = 'SET_ORGANISATION_DETAILS';
export const UPDATE_ORGANISATION_USER_SAVE_STATUS = 'UPDATE_ORGANISATION_USER_SAVE_STATUS';
export const UPDATE_ORGANISATION_DETAILS = 'UPDATE_ORGANISATION_DETAILS';
export const SET_PARENT_ORGANISATION_DETAILS = 'SET_PARENT_ORGANISATION_DETAILS';
export const UPDATE_ORGANISATION_USERS = 'UPDATE_ORGANISATION_USERS';
export const UPDATE_ORGANISATION_UNITS = 'UPDATE_ORGANISATION_UNITS';
export const SET_ORGANISATION_CURRENT_PAGE = 'SET_ORGANISATION_CURRENT_PAGE';
export const SET_ORGANISATION_PAGE_FILTER = 'SET_ORGANISATION_PAGE_FILTER';

//Environment Names
export const DEV_ENV = 'Development';
export const TEST_ENV = 'Testing';
export const STAGING_ENV = 'Staging';
export const PRODUCTION_ENV = 'Production';

//Forgot Password
export const RESET_VERIFICATION_CODE = 'RESET_VERIFICATION_CODE';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_COMPLETE = 'FORGOT_PASSWORD_COMPLETE';
export const SET_FORGOT_PASSWORD_CONFIRMATION_SENT = 'SET_FORGOT_PASSWORD_CONFIRMATION_SENT';

//Units
export const GET_ALL_UNITS = 'GET_ALL_UNITS';
export const GET_ALL_UNITS_ERROR = 'GET_ALL_UNITS_ERROR';
export const UNIT_LOADING = 'UNIT_LOADING';
export const SET_UNITS_CURRENT_PAGE = 'SET_UNITS_CURRENT_PAGE';
export const SET_UNITS_PAGE_FILTER = 'SET_UNITS_PAGE_FILTER';
export const DESCRIBE_UNIT = 'DESCRIBE_UNIT';
export const SET_UNIT_SEARCH_RESULT = 'SET_UNIT_SEARCH_RESULT';
export const RESET_UNIT_MESSAGE = 'RESET_UNIT_MESSAGE';
export const GET_ALL_UNIT_FILTERS = 'GET_ALL_UNIT_FILTERS';
export const SET_UNIT_FILTERED_RESULTS = 'SET_UNIT_FILTERED_RESULTS';

//Task Templates:
export const TASK_TEMPLATE_ERROR = 'TASK_TEMPLATE_ERROR';
export const GET_ALL_TASK_TEMPLATE_DETAILS = 'GET_ALL_TASK_TEMPLATE_DETAILS';
export const TASK_TEMPLATE_IS_LOADING = 'TASK_TEMPLATE_IS_LOADING';
export const SET_TASK_TEMPLATE_CURRENT_PAGE = 'SET_TASK_TEMPLATE_CURRENT_PAGE';
export const SET_TASK_TEMPLATE_PAGE_FILTER = 'SET_TASK_TEMPLATE_PAGE_FILTER';
export const SET_TASK_TEMPLATE_CHANGE = 'SET_TASK_TEMPLATE_CHANGE';
export const SET_TASK_TEMPLATE = 'SET_TASK_TEMPLATE';
export const UPDATE_TASK_TEMPLATE_SAVE_STATUS = 'UPDATE_TASK_TEMPLATE_SAVE_STATUS';

//Checklist Templates:
export const CHECKLIST_TEMPLATE_ERROR = 'CHECKLIST_TEMPLATE_ERROR';
export const GET_ALL_CHECKLIST_TEMPLATE_DETAILS = 'GET_ALL_CHECKLIST_TEMPLATE_DETAILS';
export const CHECKLIST_TEMPLATE_IS_LOADING = 'CHECKLIST_TEMPLATE_IS_LOADING';
export const SET_CHECKLIST_TEMPLATE_CURRENT_PAGE = 'SET_CHECKLIST_TEMPLATE_CURRENT_PAGE';
export const SET_CHECKLIST_TEMPLATE_PAGE_FILTER = 'SET_CHECKLIST_TEMPLATE_PAGE_FILTER';
export const SET_CHECKLIST_TEMPLATE_CHANGE = 'SET_CHECKLIST_TEMPLATE_CHANGE';
export const SET_CHECKLIST_TEMPLATE = 'SET_CHECKLIST_TEMPLATE';
export const UPDATE_CHECKLIST_TEMPLATE_SAVE_STATUS = 'UPDATE_CHECKLIST_TEMPLATE_SAVE_STATUS';

//Checklist
export const CHECKLIST_ERROR = 'CHECKLIST_ERROR';
export const GET_ALL_CHECKLIST_DETAILS = 'GET_ALL_CHECKLIST_DETAILS';
export const CHECKLIST_IS_LOADING = 'CHECKLIST_IS_LOADING';
export const SET_CHECKLIST_CURRENT_PAGE = 'SET_CHECKLIST_CURRENT_PAGE';
export const SET_CHECKLIST_PAGE_FILTER = 'SET_CHECKLIST_PAGE_FILTER';
export const SET_CHECKLIST_CHANGE = 'SET_CHECKLIST_CHANGE';
export const SET_CHECKLIST = 'SET_CHECKLIST';
export const SET_CHECKLIST_FILTERS = 'SET_CHECKLIST_FILTERS';
export const UPDATE_CHECKLIST_SAVE_STATUS = 'UPDATE_CHECKLIST_SAVE_STATUS';
export const SET_CHECKLIST_FILTERED_RESULTS = 'SET_CHECKLIST_FILTERED_RESULTS';

//Tasks:
export const TASKS_ERROR = 'TASKS_ERROR';
export const GET_ALL_TASKS_DETAILS = 'GET_ALL_TASKS_DETAILS';
export const TASKS_IS_LOADING = 'TASKS_IS_LOADING';
export const SET_TASKS_CURRENT_PAGE = 'SET_TASKS_CURRENT_PAGE';
export const SET_TASKS_PAGE_FILTER = 'SET_TASKS_PAGE_FILTER';
export const SET_TASKS_FILTERS = 'SET_TASKS_FILTERS';
export const SET_TASKS_CHANGE = 'SET_TASKS_CHANGE';
export const SET_TASK = 'SET_TASK';
export const UPDATE_TASKS_SAVE_STATUS = 'UPDATE_TASKS_SAVE_STATUS';
export const SET_TASKS_FILTERED_RESULTS = 'SET_TASKS_FILTERED_RESULTS';
export const RESET_TASKS = 'RESET_TASKS ';
export const SET_TASKS_COMMENT_CHANGE = 'SET_TASKS_COMMENT_CHANGE';

// Task Status
export const TASK_STATUS_ACTIVE = 'Active';
export const TASK_STATUS_COMPLETE = 'Complete';

export const TASK_ASSIGNED_TO_ME = 'Assigned To Me';
export const TASK_CREATED_BY_ME = 'Created By Me';

// Checklist Status
export const CHECKLIST_STATUS_ACTIVE = 'Active';
export const CHECKLIST_STATUS_COMPLETE = 'Complete';

//Test purpose
export const TEST_PURPOSE_ERROR = 'TEST_PURPOSE_ERROR';
export const GET_ALL_TEST_PURPOSE_DETAILS = 'GET_ALL_TEST_PURPOSE_DETAILS';
export const TEST_PURPOSE_IS_LOADING = 'TEST_PURPOSE_IS_LOADING';
export const SET_TEST_PURPOSE_CURRENT_PAGE = 'SET_TEST_PURPOSE_CURRENT_PAGE';
export const SET_TEST_PURPOSE_PAGE_FILTER = 'SET_TEST_PURPOSE_PAGE_FILTER';
export const SET_TEST_PURPOSE_CHANGE = 'SET_TEST_PURPOSE_CHANGE';
export const SET_TEST_PURPOSE = 'SET_TEST_PURPOSE';
export const UPDATE_TEST_PURPOSE_SAVE_STATUS = 'UPDATE_TEST_PURPOSE_SAVE_STATUS';

//Inspection purpose
export const INSPECTION_PURPOSE_ERROR = 'INSPECTION_PURPOSE_ERROR';
export const GET_ALL_INSPECTION_PURPOSE_DETAILS = 'GET_ALL_INSPECTION_PURPOSE_DETAILS';
export const INSPECTION_PURPOSE_IS_LOADING = 'INSPECTION_PURPOSE_IS_LOADING';
export const SET_INSPECTION_PURPOSE_CURRENT_PAGE = 'SET_INSPECTION_PURPOSE_CURRENT_PAGE';
export const SET_INSPECTION_PURPOSE_PAGE_FILTER = 'SET_INSPECTION_PURPOSE_PAGE_FILTER';
export const SET_INSPECTION_PURPOSE_CHANGE = 'SET_INSPECTION_PURPOSE_CHANGE';
export const SET_INSPECTION_PURPOSE = 'SET_INSPECTION_PURPOSE';
export const SET_INSPECTION_PURPOSE_CLEANUP = 'SET_INSPECTION_PURPOSE_CLEANUP';
export const UPDATE_INSPECTION_PURPOSE_SAVE_STATUS = 'UPDATE_INSPECTION_PURPOSE_SAVE_STATUS';

//Test Categories
export const TEST_CATEGORY_ERROR = 'TEST_CATEGORY_ERROR';
export const GET_ALL_TEST_CATEGORY_DETAILS = 'GET_ALL_TEST_CATEGORY_DETAILS';
export const TEST_CATEGORY_IS_LOADING = 'TEST_CATEGORY_IS_LOADING';
export const SET_TEST_CATEGORY_CURRENT_PAGE = 'SET_TEST_CATEGORY_CURRENT_PAGE';
export const SET_TEST_CATEGORY_PAGE_FILTER = 'SET_TEST_CATEGORY_PAGE_FILTER';
export const SET_TEST_CATEGORY_CHANGE = 'SET_TEST_CATEGORY_CHANGE';
export const SET_TEST_CATEGORY = 'SET_TEST_CATEGORY';
export const UPDATE_TEST_CATEGORY_SAVE_STATUS = 'UPDATE_TEST_CATEGORY_SAVE_STATUS';
export const TEST_CATEGORY_ITEM_EXISTS_STATUS = 'TEST_CATEGORY_ITEM_EXISTS_STATUS';
export const SET_TEST_CATEGORY_CLEANUP = 'SET_TEST_CATEGORY_CLEANUP';

//Inspection type
export const INSPECTION_TYPE_ERROR = 'INSPECTION_TYPE_ERROR';
export const GET_ALL_INSPECTION_TYPE_DETAILS = 'GET_ALL_INSPECTION_TYPE_DETAILS';
export const INSPECTION_TYPE_IS_LOADING = 'INSPECTION_TYPE_IS_LOADING';
export const SET_INSPECTION_TYPE_CURRENT_PAGE = 'SET_INSPECTION_TYPE_CURRENT_PAGE';
export const SET_INSPECTION_TYPE_PAGE_FILTER = 'SET_INSPECTION_TYPE_PAGE_FILTER';
export const SET_INSPECTION_TYPE_CHANGE = 'SET_INSPECTION_TYPE_CHANGE';
export const SET_INSPECTION_TYPE = 'SET_INSPECTION_TYPE';
export const SET_INSPECTION_TYPE_CLEANUP = 'SET_INSPECTION_TYPE_CLEANUP';
export const UPDATE_INSPECTION_TYPE_SAVE_STATUS = 'UPDATE_INSPECTION_TYPE_SAVE_STATUS';

//Inspection Type question
export const INSPECTION_TYPE_QUESTION_ERROR = 'INSPECTION_TYPE_QUESTION_ERROR';
export const GET_ALL_INSPECTION_TYPE_QUESTION_DETAILS = 'GET_ALL_INSPECTION_TYPE_QUESTION_DETAILS';
export const INSPECTION_TYPE_QUESTION_IS_LOADING = 'INSPECTION_TYPE_QUESTION_IS_LOADING';
export const SET_INSPECTION_TYPE_QUESTION_CURRENT_PAGE = 'SET_INSPECTION_TYPE_QUESTION_CURRENT_PAGE';
export const SET_INSPECTION_TYPE_QUESTION_PAGE_FILTER = 'SET_INSPECTION_TYPE_QUESTION_PAGE_FILTER';
export const SET_INSPECTION_TYPE_QUESTION_CHANGE = 'SET_INSPECTION_TYPE_QUESTION_CHANGE';
export const SET_INSPECTION_TYPE_QUESTION = 'SET_INSPECTION_TYPE_QUESTION';
export const SET_INSPECTION_TYPE_QUESTIONS = 'SET_INSPECTION_TYPE_QUESTIONS';
export const SET_INSPECTION_TYPE_QUESTION_CLEANUP = 'SET_INSPECTION_TYPE_QUESTION_CLEANUP';
export const UPDATE_INSPECTION_TYPE_QUESTION_SAVE_STATUS = 'UPDATE_INSPECTION_TYPE_QUESTION_SAVE_STATUS';

// General DAta
export const GET_GENERAL_DATA = 'GET_GENERAL_DATA';
export const GENERAL_DATA_IS_LOADING = 'GENERAL_DATA_IS_LOADING';
export const GENERAL_DATA_ERROR = 'GENERAL_DATA_ERROR';

//Inspection report
export const INSPECTION_REPORT_ERROR = 'INSPECTION_REPORT_ERROR';
export const GET_ALL_INSPECTION_REPORT_DETAILS = 'GET_ALL_INSPECTION_REPORT_DETAILS';
export const INSPECTION_REPORT_IS_LOADING = 'INSPECTION_REPORT_IS_LOADING';
export const SET_INSPECTION_REPORT_CURRENT_PAGE = 'SET_INSPECTION_REPORT_CURRENT_PAGE';
export const SET_INSPECTION_REPORT_PAGE_FILTER = 'SET_INSPECTION_REPORT_PAGE_FILTER';
export const SET_INSPECTION_REPORT_CHANGE = 'SET_INSPECTION_REPORT_CHANGE';
export const SET_INSPECTION_REPORT = 'SET_INSPECTION_REPORT';
export const SET_INSPECTION_REPORT_CLEANUP = 'SET_INSPECTION_REPORT_CLEANUP';
export const UPDATE_INSPECTION_REPORT_SAVE_STATUS = 'UPDATE_INSPECTION_REPORT_SAVE_STATUS';
export const SET_INSPECTION_REPORT_FILTERS = 'SET_INSPECTION_REPORT_FILTERS';
export const INSPECTION_REPORT_LISTING_CLEANUP = 'INSPECTION_REPORT_LISTING_CLEANUP';
export const SET_INSPECTION_REPORT_RESULTS = 'SET_INSPECTION_REPORT_RESULTS';
export const SET_INSPECTION_REPORT_QUESTIONS = 'SET_INSPECTION_REPORT_QUESTIONS';
export const SET_INSPECTION_REPORT_QUESTIONS_CHANGE = 'SET_INSPECTION_REPORT_QUESTIONS_CHANGE';
export const REMOVE_INSPECTION_REPORT = 'REMOVE_INSPECTION_REPORT';

export const VERY_HIGH_RISK = 'VERY_HIGH_RISK';
export const HIGH_RISK = 'HIGH_RISK';
export const MEDIUM_RISK = 'MEDIUM_RISK';
export const LOW_RISK = 'LOW_RISK';

export const RISK_INFOS = [
  { id: VERY_HIGH_RISK, name: 'risk.veryHighRisk', defaultText: 'Very High Risk', low: 90, high: 100 },
  { id: HIGH_RISK, name: 'risk.highRisk', defaultText: 'High Risk', low: 60, high: 89 },
  { id: MEDIUM_RISK, name: 'risk.mediumRisk', defaultText: 'Medium Risk', low: 30, high: 59 },
  { id: LOW_RISK, name: 'risk.lowRisk', defaultText: 'Low Risk', low: 0, high: 29 }
];

//Test result
export const TEST_RESULT_ERROR = 'TEST_RESULT_ERROR';
export const GET_ALL_TEST_RESULT_DETAILS = 'GET_ALL_TEST_RESULT_DETAILS';
export const TEST_RESULT_IS_LOADING = 'TEST_RESULT_IS_LOADING';
export const SET_TEST_RESULT_CURRENT_PAGE = 'SET_TEST_RESULT_CURRENT_PAGE';
export const SET_TEST_RESULT_PAGE_FILTER = 'SET_TEST_RESULT_PAGE_FILTER';
export const SET_TEST_RESULT_CHANGE = 'SET_TEST_RESULT_CHANGE';
export const SET_TEST_RESULT = 'SET_TEST_RESULT';
export const UPDATE_TEST_RESULT_SAVE_STATUS = 'UPDATE_TEST_RESULT_SAVE_STATUS';
export const SET_TEST_RESULT_FILTERS = 'SET_TEST_RESULT_FILTERS';
export const SET_TEST_RESULT_RESULTS = 'SET_TEST_RESULT_RESULTS';
export const REMOVE_TEST_RESULT = 'REMOVE_TEST_RESULT';

//Sites
export const SITE_ERROR = 'SITE_ERROR';
export const GET_ALL_SITE_DETAILS = 'GET_ALL_SITE_DETAILS';
export const SITE_IS_LOADING = 'SITE_IS_LOADING';
export const SET_SITE_CURRENT_PAGE = 'SET_SITE_CURRENT_PAGE';
export const SET_SITE_PAGE_FILTER = 'SET_SITE_PAGE_FILTER';
export const SET_SITE_CHANGE = 'SET_SITE_CHANGE';
export const SET_SITE = 'SET_SITE';
export const UPDATE_SITE_SAVE_STATUS = 'UPDATE_SITE_SAVE_STATUS';
export const SET_SITE_FILTERS = 'SET_SITE_FILTERS';
export const SET_SITE_RESULTS = 'SET_SITE_RESULTS';
export const SET_SITE_COMMON_LIST = 'SET_SITE_COMMON_LIST';

//Entities
export const ENTITY_ERROR = 'ENTITY_ERROR';
export const GET_ALL_ENTITY_DETAILS = 'GET_ALL_ENTITY_DETAILS';
export const ENTITY_IS_LOADING = 'ENTITY_IS_LOADING';
export const SET_ENTITY_CURRENT_PAGE = 'SET_ENTITY_CURRENT_PAGE';
export const SET_ENTITY_PAGE_FILTER = 'SET_ENTITY_PAGE_FILTER';
export const SET_ENTITY_CHANGE = 'SET_ENTITY_CHANGE';
export const SET_ENTITY = 'SET_ENTITY';
export const SET_ENTITY_CLEANUP = 'SET_ENTITY_CLEANUP';
export const UPDATE_ENTITY_SAVE_STATUS = 'UPDATE_ENTITY_SAVE_STATUS';
export const SET_ENTITY_FILTERS = 'SET_ENTITY_FILTERS';
export const SET_ENTITY_RESULTS = 'SET_ENTITY_RESULTS';
export const ENTITY_LISTING_CLEANUP = 'ENTITY_LISTING_CLEANUP';
export const SET_ENTITY_COMMON_LIST = 'SET_ENTITY_COMMON_LIST';

//Quality Goals
export const QUALITY_GOALS_ERROR = 'Quality_GOALS_ERROR';
export const GET_ALL_QUALITY_GOALS_DETAILS = 'GET_ALL_QUALITY_GOALS_DETAILS';
export const QUALITY_GOALS_IS_LOADING = 'QUALITY_GOALS_IS_LOADING';
export const SET_QUALITY_GOALS_CURRENT_PAGE = 'SET_QUALITY_GOALS_CURRENT_PAGE';
export const SET_QUALITY_GOALS_PAGE_FILTER = 'SET_QUALITY_GOALS_PAGE_FILTER';
export const SET_QUALITY_GOALS_CHANGE = 'SET_QUALITY_GOALS_CHANGE';
export const SET_QUALITY_GOALS = 'SET_QUALITY_GOALS';
export const UPDATE_QUALITY_GOALS_SAVE_STATUS = 'UPDATE_QUALITY_GOALS_SAVE_STATUS';
export const SET_QUALITY_GOALS_FILTERS = 'SET_QUALITY_GOALS_FILTERS';
export const SET_QUALITY_GOALS_RESULTS = 'SET_QUALITY_GOALS_RESULTS';
export const REMOVE_QUALITY_GOALS = 'REMOVE_QUALITY_GOALS';

//General Field Types

export const FREQUENCY = 'FREQUENCY';
export const CONDITION = 'CONDITION';
export const SUPPLY_TYPE = 'SUPPLY_TYPE';
export const ENTITY_PURPOSE = 'ENTITY_PURPOSE';
export const TREATMENT_TYPE = 'TREATMENT_TYPE';
export const HAZARD_CLASS = 'HAZARD_CLASS';
export const HAZARD_EVENT_CERTAINTY = 'HAZARD_EVENT_CERTAINTY';
export const HAZARD_EVENT_CATEGORY = 'HAZARD_EVENT_CATEGORY';
export const RISK_RESPONSE = 'RISK_RESPONSE';
export const RISK_CERTAINTY = 'RISK_CERTAINTY';
export const LIKELIHOOD = 'LIKELIHOOD';
export const RISK_STATUS = 'RISK_STATUS';
export const CONTROL_MEASURE_CRITERIA = 'CONTROL_MEASURE_CRITERIA';
export const CRITICAL_LIMIT_TYPE = 'CRITICAL_LIMIT_TYPE';
export const GENERAL_STATUS = 'GENERAL_STATUS';
export const DOCUMENT_TYPE = 'DOCUMENT_TYPE';
export const DOCUMENT_SUBJECT_CATEGORY = 'DOCUMENT_SUBJECT_CATEGORY';
export const INCIDENT_CATEGORY = 'INCIDENT_CATEGORY';
export const INCIDENT_CONSEQUENCE = 'INCIDENT_CONSEQUENCE';

//Water supply description

export const WATERSUPPLYDESCRIPTION_ERROR = 'WATERSUPPLYDESCRIPTION_ERROR';
export const GET_ALL_WATERSUPPLYDESCRIPTION_DETAILS = 'GET_ALL_WATERSUPPLYDESCRIPTION_DETAILS';
export const WATERSUPPLYDESCRIPTION_IS_LOADING = 'WATERSUPPLYDESCRIPTION_IS_LOADING';
export const SET_WATERSUPPLYDESCRIPTION_CURRENT_PAGE = 'SET_WATERSUPPLYDESCRIPTION_CURRENT_PAGE';
export const SET_WATERSUPPLYDESCRIPTION_PAGE_FILTER = 'SET_WATERSUPPLYDESCRIPTION_PAGE_FILTER';
export const SET_WATERSUPPLYDESCRIPTION_CHANGE = 'SET_WATERSUPPLYDESCRIPTION_CHANGE';
export const SET_WATERSUPPLYDESCRIPTION = 'SET_WATERSUPPLYDESCRIPTION';
export const UPDATE_WATERSUPPLYDESCRIPTION_SAVE_STATUS = 'UPDATE_WATERSUPPLYDESCRIPTION_SAVE_STATUS';
export const SET_WATERSUPPLYDESCRIPTION_FILTERS = 'SET_WATERSUPPLYDESCRIPTION_FILTERS';
export const SET_WATERSUPPLYDESCRIPTION_RESULTS = 'SET_WATERSUPPLYDESCRIPTION_RESULTS';
export const SET_WATERSUPPLYDESCRIPTION_COMMON_LIST = 'SET_WATERSUPPLYDESCRIPTION_COMMON_LIST';

export const CATEGORY_OF_WATER = [
  { id: 1, name: 'Category 1' },
  { id: 2, name: 'Category 2' },
  { id: 3, name: 'Category 3' },
  { id: 4, name: 'Category 4' }
];

//General field types
export const GENERAL_FIELDTYPE_ERROR = 'GENERAL_FIELDTYPE_ERROR';
export const GET_ALL_GENERAL_FIELDTYPE_DETAILS = 'GET_ALL_GENERAL_FIELDTYPE_DETAILS';
export const GENERAL_FIELDTYPE_IS_LOADING = 'GENERAL_FIELDTYPE_IS_LOADING';
export const SET_GENERAL_FIELDTYPE_CURRENT_PAGE = 'SET_GENERAL_FIELDTYPE_CURRENT_PAGE';
export const SET_GENERAL_FIELDTYPE_PAGE_FILTER = 'SET_GENERAL_FIELDTYPE_PAGE_FILTER';
export const SET_GENERAL_FIELDTYPE_CHANGE = 'SET_GENERAL_FIELDTYPE_CHANGE';
export const SET_GENERAL_FIELDTYPE_CLEANUP = 'SET_GENERAL_FIELDTYPE_CLEANUP';
export const SET_GENERAL_FIELDTYPE = 'SET_GENERAL_FIELDTYPE';
export const UPDATE_GENERAL_FIELDTYPE_SAVE_STATUS = 'UPDATE_GENERAL_FIELDTYPE_SAVE_STATUS';
export const SET_GENERAL_FIELDTYPE_RESULTS = 'SET_GENERAL_FIELDTYPE_RESULTS';

export const FIELD_TYPE_DATA = [
  { text: 'generalFieldType.condition', value: CONDITION },
  { text: 'generalFieldType.entityPurpose', value: ENTITY_PURPOSE },
  { text: 'generalFieldType.frequency', value: FREQUENCY },
  { text: 'generalFieldType.supplyType', value: SUPPLY_TYPE },
  { text: 'generalFieldType.treatmentType', value: TREATMENT_TYPE },
  { text: 'generalFieldType.hazardClass', value: HAZARD_CLASS },
  { text: 'generalFieldType.hHazardEventCertainty', value: HAZARD_EVENT_CERTAINTY },
  { text: 'generalFieldType.hazardCategory', value: HAZARD_EVENT_CATEGORY },
  { text: 'generalFieldType.riskResponse', value: RISK_RESPONSE },
  { text: 'generalFieldType.riskCertainty', value: RISK_CERTAINTY },
  { text: 'generalFieldType.likelihood', value: LIKELIHOOD },
  { text: 'generalFieldType.riskStatus', value: RISK_STATUS },
  { text: 'generalFieldType.controlMeasureCriteria', value: CONTROL_MEASURE_CRITERIA },
  { text: 'generalFieldType.criticalLimitType', value: CRITICAL_LIMIT_TYPE },
  { text: 'generalFieldType.generalStatus', value: GENERAL_STATUS },
  { text: 'generalFieldType.documentType', value: DOCUMENT_TYPE },
  { text: 'generalFieldType.documentSubjectCategory', value: DOCUMENT_SUBJECT_CATEGORY },
  { text: 'generalFieldType.incidentCategory', value: INCIDENT_CATEGORY },
  { text: 'generalFieldType.incidentConsequence', value: INCIDENT_CONSEQUENCE }
];

//Risk Categories

export const RISK_CATEGORY_ERROR = 'RISK_CATEGORY_ERROR';
export const GET_ALL_RISK_CATEGORY_DETAILS = 'GET_ALL_RISK_CATEGORY_DETAILS';
export const RISK_CATEGORY_IS_LOADING = 'RISK_CATEGORY_IS_LOADING';
export const SET_RISK_CATEGORY_CURRENT_PAGE = 'SET_RISK_CATEGORY_CURRENT_PAGE';
export const SET_RISK_CATEGORY_PAGE_FILTER = 'SET_RISK_CATEGORY_PAGE_FILTER';
export const SET_RISK_CATEGORY_CHANGE = 'SET_RISK_CATEGORY_CHANGE';
export const SET_RISK_CATEGORY_MATRIX_CHANGE = 'SET_RISK_CATEGORY_MATRIX_CHANGE';
export const SET_RISK_CATEGORY = 'SET_RISK_CATEGORY';
export const SET_RISK_CATEGORY_CLEANUP = 'SET_RISK_CATEGORY_CLEANUP';
export const UPDATE_RISK_CATEGORY_SAVE_STATUS = 'UPDATE_RISK_CATEGORY_SAVE_STATUS';
export const RISK_CATEGORY_LISTING_CLEANUP = 'RISK_CATEGORY_LISTING_CLEANUP';
export const RISK_CATEGORY_FILTER_DATA = 'RISK_CATEGORY_FILTER_DATA';

//Hazard events
export const HAZARD_EVENT_ERROR = 'HAZARD_EVENT_ERROR';
export const GET_ALL_HAZARD_EVENT_DETAILS = 'GET_ALL_HAZARD_EVENT_DETAILS';
export const HAZARD_EVENT_IS_LOADING = 'HAZARD_EVENT_IS_LOADING';
export const SET_HAZARD_EVENT_CURRENT_PAGE = 'SET_HAZARD_EVENT_CURRENT_PAGE';
export const SET_HAZARD_EVENT_PAGE_FILTER = 'SET_HAZARD_EVENT_PAGE_FILTER';
export const SET_HAZARD_EVENT_CHANGE = 'SET_HAZARD_EVENT_CHANGE';
export const SET_HAZARD_EVENT = 'SET_HAZARD_EVENT';
export const UPDATE_HAZARD_EVENT_SAVE_STATUS = 'UPDATE_HAZARD_EVENT_SAVE_STATUS';
export const SET_HAZARD_EVENT_FILTERS = 'SET_HAZARD_EVENT_FILTERS';
export const SET_HAZARD_EVENT_RESULTS = 'SET_HAZARD_EVENT_RESULTS';
export const SET_HAZARD_EVENT_COMMENTS = 'SET_HAZARD_EVENT_COMMENTS';
export const REMOVE_HAZARD_EVENT = 'REMOVE_HAZARD_EVENT';

//Hazard event Library
export const HAZARD_EVENT_LIBRARY_ERROR = 'HAZARD_EVENT_LIBRARY_ERROR';
export const GET_ALL_HAZARD_EVENT_LIBRARY_DETAILS = 'GET_ALL_HAZARD_EVENT_LIBRARY_DETAILS';
export const HAZARD_EVENT_LIBRARY_IS_LOADING = 'HAZARD_EVENT_LIBRARY_IS_LOADING';
export const SET_HAZARD_EVENT_LIBRARY_CURRENT_PAGE = 'SET_HAZARD_EVENT_LIBRARY_CURRENT_PAGE';
export const SET_HAZARD_EVENT_LIBRARY_PAGE_FILTER = 'SET_HAZARD_EVENT_LIBRARY_PAGE_FILTER';
export const SET_HAZARD_EVENT_LIBRARY_CHANGE = 'SET_HAZARD_EVENT_LIBRARY_CHANGE';
export const SET_HAZARD_EVENT_LIBRARY = 'SET_HAZARD_EVENT_LIBRARY';
export const UPDATE_HAZARD_EVENT_LIBRARY_SAVE_STATUS = 'UPDATE_HAZARD_EVENT_LIBRARY_SAVE_STATUS';

//Hazards
export const HAZARD_ERROR = 'HAZARD_ERROR';
export const GET_ALL_HAZARD_DETAILS = 'GET_ALL_HAZARD_DETAILS';
export const HAZARD_IS_LOADING = 'HAZARD_IS_LOADING';
export const SET_HAZARD_CURRENT_PAGE = 'SET_HAZARD_CURRENT_PAGE';
export const SET_HAZARD_PAGE_FILTER = 'SET_HAZARD_PAGE_FILTER';
export const SET_HAZARD_CHANGE = 'SET_HAZARD_CHANGE';
export const SET_HAZARD = 'SET_HAZARD';
export const UPDATE_HAZARD_SAVE_STATUS = 'UPDATE_HAZARD_SAVE_STATUS';
export const SET_HAZARD_FILTERS = 'SET_HAZARD_FILTERS';
export const SET_HAZARD_RESULTS = 'SET_HAZARD_RESULTS';
export const SET_HAZARD_COMMON_LIST = 'SET_HAZARD_COMMON_LIST';

//Risk Assessments
export const RISK_ASSESSMENT_ERROR = 'RISK_ASSESSMENT_ERROR';
export const GET_ALL_RISK_ASSESSMENT_DETAILS = 'GET_ALL_RISK_ASSESSMENT_DETAILS';
export const RISK_ASSESSMENT_IS_LOADING = 'RISK_ASSESSMENT_IS_LOADING';
export const SET_RISK_ASSESSMENT_CURRENT_PAGE = 'SET_RISK_ASSESSMENT_CURRENT_PAGE';
export const SET_RISK_ASSESSMENT_PAGE_FILTER = 'SET_RISK_ASSESSMENT_PAGE_FILTER';
export const SET_RISK_ASSESSMENT_CHANGE = 'SET_RISK_ASSESSMENT_CHANGE';
export const SET_RISK_ASSESSMENT = 'SET_RISK_ASSESSMENT';
export const UPDATE_RISK_ASSESSMENT_SAVE_STATUS = 'UPDATE_RISK_ASSESSMENT_SAVE_STATUS';
export const SET_RISK_ASSESSMENT_FILTERS = 'SET_RISK_ASSESSMENT_FILTERS';
export const SET_RISK_ASSESSMENT_RESULTS = 'SET_RISK_ASSESSMENT_RESULTS';
export const SET_RISK_ASSESSMENT_COMMENTS = 'SET_RISK_ASSESSMENT_COMMENTS';
export const REMOVE_RISK_ASSESSMENT = 'REMOVE_RISK_ASSESSMENT';

export const RISK_RATINGS = [
  { id: VERY_HIGH_RISK, name: 'riskCategory.veryHigh', defaultText: 'Very High' },
  { id: HIGH_RISK, name: 'riskCategory.high', defaultText: 'High' },
  { id: MEDIUM_RISK, name: 'riskCategory.medium', defaultText: 'Medium' },
  { id: LOW_RISK, name: 'riskCategory.low', defaultText: 'Low' }
];

//safety plan
export const SAFETY_PLAN_ERROR = 'SAFETY_PLAN_ERROR';
export const GET_ALL_SAFETY_PLAN_DETAILS = 'GET_ALL_SAFETY_PLAN_DETAILS';
export const SAFETY_PLAN_IS_LOADING = 'SAFETY_PLAN_IS_LOADING';
export const SET_SAFETY_PLAN_CURRENT_PAGE = 'SET_SAFETY_PLAN_CURRENT_PAGE';
export const SET_SAFETY_PLAN_PAGE_FILTER = 'SET_SAFETY_PLAN_PAGE_FILTER';
export const SET_SAFETY_PLAN_CHANGE = 'SET_SAFETY_PLAN_CHANGE';
export const SET_SAFETY_PLAN = 'SET_SAFETY_PLAN';
export const UPDATE_SAFETY_PLAN_SAVE_STATUS = 'UPDATE_SAFETY_PLAN_SAVE_STATUS';
export const SET_SAFETY_PLAN_FILTERS = 'SET_SAFETY_PLAN_FILTERS';
export const SET_SAFETY_PLAN_RESULTS = 'SET_SAFETY_PLAN_RESULTS';
export const SET_SAFETY_PLAN_COMMON_LIST = 'SET_SAFETY_PLAN_COMMON_LIST';

//Emergency Response plan
export const EMERGENCY_RESPONSE_PLAN_ERROR = 'EMERGENCY_RESPONSE_PLAN_ERROR';
export const GET_ALL_EMERGENCY_RESPONSE_PLAN_DETAILS = 'GET_ALL_EMERGENCY_RESPONSE_PLAN_DETAILS';
export const EMERGENCY_RESPONSE_PLAN_IS_LOADING = 'EMERGENCY_RESPONSE_PLAN_IS_LOADING';
export const SET_EMERGENCY_RESPONSE_PLAN_CURRENT_PAGE = 'SET_EMERGENCY_RESPONSE_PLAN_CURRENT_PAGE';
export const SET_EMERGENCY_RESPONSE_PLAN_PAGE_FILTER = 'SET_EMERGENCY_RESPONSE_PLAN_PAGE_FILTER';
export const SET_EMERGENCY_RESPONSE_PLAN_CHANGE = 'SET_EMERGENCY_RESPONSE_PLAN_CHANGE';
export const SET_EMERGENCY_RESPONSE_PLAN = 'SET_EMERGENCY_RESPONSE_PLAN';
export const UPDATE_EMERGENCY_RESPONSE_PLAN_SAVE_STATUS = 'UPDATE_EMERGENCY_RESPONSE_PLAN_SAVE_STATUS';
export const SET_EMERGENCY_RESPONSE_PLAN_FILTERS = 'SET_EMERGENCY_RESPONSE_PLAN_FILTERS';
export const SET_EMERGENCY_RESPONSE_PLAN_RESULTS = 'SET_EMERGENCY_RESPONSE_PLAN_RESULTS';
export const SET_EMERGENCY_RESPONSE_PLAN_COMMENTS = 'SET_EMERGENCY_RESPONSE_PLAN_COMMENTS';
export const REMOVE_EMERGENCY_RESPONSE_PLAN = 'REMOVE_EMERGENCY_RESPONSE_PLAN';

//Personnel
export const PERSONNEL_ERROR = 'PERSONNEL_ERROR';
export const GET_ALL_PERSONNEL_DETAILS = 'GET_ALL_PERSONNEL_DETAILS';
export const PERSONNEL_IS_LOADING = 'PERSONNEL_IS_LOADING';
export const SET_PERSONNEL_CURRENT_PAGE = 'SET_PERSONNEL_CURRENT_PAGE';
export const SET_PERSONNEL_PAGE_FILTER = 'SET_PERSONNEL_PAGE_FILTER';
export const SET_PERSONNEL_CHANGE = 'SET_PERSONNEL_CHANGE';
export const SET_PERSONNEL = 'SET_PERSONNEL';
export const UPDATE_PERSONNEL_SAVE_STATUS = 'UPDATE_PERSONNEL_SAVE_STATUS';
export const SET_PERSONNEL_FILTERS = 'SET_PERSONNEL_FILTERS';
export const SET_PERSONNEL_RESULTS = 'SET_PERSONNEL_RESULTS';
export const REMOVE_PERSONNEL = 'REMOVE_PERSONNEL';

export const CONTACT_METHOD = [
  { text: 'Email', value: 'Email' },
  { text: 'Phone', value: 'Phone' }
];

//Safety Plan Team
export const TEAM_ERROR = 'TEAM_ERROR';
export const GET_ALL_TEAM_DETAILS = 'GET_ALL_TEAM_DETAILS';
export const TEAM_IS_LOADING = 'TEAM_IS_LOADING';
export const SET_TEAM_CURRENT_PAGE = 'SET_TEAM_CURRENT_PAGE';
export const SET_TEAM_PAGE_FILTER = 'SET_TEAM_PAGE_FILTER';
export const SET_TEAM_CHANGE = 'SET_TEAM_CHANGE';
export const SET_TEAM = 'SET_TEAM';
export const UPDATE_TEAM_SAVE_STATUS = 'UPDATE_TEAM_SAVE_STATUS';
export const SET_TEAM_FILTERS = 'SET_TEAM_FILTERS';
export const SET_TEAM_RESULTS = 'SET_TEAM_RESULTS';
export const REMOVE_TEAM = 'REMOVE_TEAM';

//Stakeholder Register
export const STAKEHOLDER_REGISTER_ERROR = 'STAKEHOLDER_REGISTER_ERROR';
export const GET_ALL_STAKEHOLDER_REGISTER_DETAILS = 'GET_ALL_STAKEHOLDER_REGISTER_DETAILS';
export const STAKEHOLDER_REGISTER_IS_LOADING = 'STAKEHOLDER_REGISTER_IS_LOADING';
export const SET_STAKEHOLDER_REGISTER_CURRENT_PAGE = 'SET_STAKEHOLDER_REGISTER_CURRENT_PAGE';
export const SET_STAKEHOLDER_REGISTER_PAGE_FILTER = 'SET_STAKEHOLDER_REGISTER_PAGE_FILTER';
export const SET_STAKEHOLDER_REGISTER_CHANGE = 'SET_STAKEHOLDER_REGISTER_CHANGE';
export const SET_STAKEHOLDER_REGISTER = 'SET_STAKEHOLDER_REGISTER';
export const UPDATE_STAKEHOLDER_REGISTER_SAVE_STATUS = 'UPDATE_STAKEHOLDER_REGISTER_SAVE_STATUS';
export const SET_STAKEHOLDER_REGISTER_FILTERS = 'SET_STAKEHOLDER_REGISTER_FILTERS';
export const SET_STAKEHOLDER_REGISTER_RESULTS = 'SET_STAKEHOLDER_REGISTER_RESULTS';
export const REMOVE_STAKEHOLDER_REGISTER = 'REMOVE_STAKEHOLDER_REGISTER';

// Regulatory Register
export const REGULATORY_REGISTER_ERROR = 'REGULATORY_REGISTER_ERROR';
export const GET_ALL_REGULATORY_REGISTER_DETAILS = 'GET_ALL_REGULATORY_REGISTER_DETAILS';
export const REGULATORY_REGISTER_IS_LOADING = 'REGULATORY_REGISTER_IS_LOADING';
export const SET_REGULATORY_REGISTER_CURRENT_PAGE = 'SET_REGULATORY_REGISTER_CURRENT_PAGE';
export const SET_REGULATORY_REGISTER_PAGE_FILTER = 'SET_REGULATORY_REGISTER_PAGE_FILTER';
export const SET_REGULATORY_REGISTER_CHANGE = 'SET_REGULATORY_REGISTER_CHANGE';
export const SET_REGULATORY_REGISTER = 'SET_REGULATORY_REGISTER';
export const UPDATE_REGULATORY_REGISTER_SAVE_STATUS = 'UPDATE_REGULATORY_REGISTER_SAVE_STATUS';
export const SET_REGULATORY_REGISTER_FILTERS = 'SET_REGULATORY_REGISTER_FILTERS';
export const SET_REGULATORY_REGISTER_RESULTS = 'SET_REGULATORY_REGISTER_RESULTS';
export const REMOVE_REGULATORY_REGISTER = 'REMOVE_REGULATORY_REGISTER';

//Safety Plan Report
export const SAFETY_PLAN_REPORT_ERROR = 'SAFETY_PLAN_REPORT_ERROR';
export const GET_ALL_SAFETY_PLAN_REPORT_DETAILS = 'GET_ALL_SAFETY_PLAN_REPORT_DETAILS';
export const SAFETY_PLAN_REPORT_IS_LOADING = 'SAFETY_PLAN_REPORT_IS_LOADING';
export const SET_SAFETY_PLAN_REPORT_CURRENT_PAGE = 'SET_SAFETY_PLAN_REPORT_CURRENT_PAGE';
export const DOWNLOAD_SAFETY_PLAN_REPORT = 'DOWNLOAD_SAFETY_PLAN_REPORT';
export const GENERATE_SAFETY_PLAN_REPORT = 'GENERATE_SAFETY_PLAN_REPORT';
export const REMOVE_SAFETY_PLAN_REPORT = 'REMOVE_SAFETY_PLAN_REPORT';

//Control Measures
export const CONTROL_MEASURE_ERROR = 'CONTROL_MEASURE_ERROR';
export const GET_ALL_CONTROL_MEASURE_DETAILS = 'GET_ALL_CONTROL_MEASURE_DETAILS';
export const CONTROL_MEASURE_IS_LOADING = 'CONTROL_MEASURE_IS_LOADING';
export const SET_CONTROL_MEASURE_CURRENT_PAGE = 'SET_CONTROL_MEASURE_CURRENT_PAGE';
export const SET_CONTROL_MEASURE_PAGE_FILTER = 'SET_CONTROL_MEASURE_PAGE_FILTER';
export const SET_CONTROL_MEASURE_CHANGE = 'SET_CONTROL_MEASURE_CHANGE';
export const SET_CONTROL_MEASURE = 'SET_CONTROL_MEASURE';
export const UPDATE_CONTROL_MEASURE_SAVE_STATUS = 'UPDATE_CONTROL_MEASURE_SAVE_STATUS';
export const SET_CONTROL_MEASURE_FILTERS = 'SET_CONTROL_MEASURE_FILTERS';
export const SET_CONTROL_MEASURE_RESULTS = 'SET_CONTROL_MEASURE_RESULTS';
export const REMOVE_CONTROL_MEASURE = 'REMOVE_CONTROL_MEASURE';
export const SET_CONTROL_MONITORING_CHANGE = 'SET_CONTROL_MONITORING_CHANGE';
export const SET_CORRECTIVE_ACTION_CHANGE = 'SET_CORRECTIVE_ACTION_CHANGE';
export const SET_CONTROL_MONITORING = 'SET_CONTROL_MONITORING';
export const SET_CORRECTIVE_ACTION = 'SET_CORRECTIVE_ACTION';
export const UPDATE_CONTROL_MONITORING_SAVE_STATUS = 'UPDATE_CONTROL_MONITORING_SAVE_STATUS';
export const UPDATE_CORRECTIVE_ACTION_SAVE_STATUS = 'UPDATE_CORRECTIVE_ACTION_SAVE_STATUS';
export const GET_ALL_MODELCOMPONENTPOSITION_DETAILS = 'GET_ALL_MODELCOMPONENTPOSITION_DETAILS';
export const REMOVE_CONTROL_MONITORING = 'REMOVE_CONTROL_MONITORING';
export const SET_CORRECTIVE_ACTION_COMMENTS = 'SET_CORRECTIVE_ACTION_COMMENTS';

//Improvement Plans
export const IMPROVEMENT_PLAN_ERROR = 'IMPROVEMENT_PLAN_ERROR';
export const GET_ALL_IMPROVEMENT_PLAN_DETAILS = 'GET_ALL_IMPROVEMENT_PLAN_DETAILS';
export const IMPROVEMENT_PLAN_IS_LOADING = 'IMPROVEMENT_PLAN_IS_LOADING';
export const SET_IMPROVEMENT_PLAN_CURRENT_PAGE = 'SET_IMPROVEMENT_PLAN_CURRENT_PAGE';
export const SET_IMPROVEMENT_PLAN_PAGE_FILTER = 'SET_IMPROVEMENT_PLAN_PAGE_FILTER';
export const SET_IMPROVEMENT_PLAN_CHANGE = 'SET_IMPROVEMENT_PLAN_CHANGE';
export const SET_IMPROVEMENT_PLAN = 'SET_IMPROVEMENT_PLAN';
export const UPDATE_IMPROVEMENT_PLAN_SAVE_STATUS = 'UPDATE_IMPROVEMENT_PLAN_SAVE_STATUS';
export const SET_IMPROVEMENT_PLAN_FILTERS = 'SET_IMPROVEMENT_PLAN_FILTERS';
export const SET_IMPROVEMENT_PLAN_RESULTS = 'SET_IMPROVEMENT_PLAN_RESULTS';
export const SET_IMPROVEMENT_PLAN_COMMENTS = 'SET_IMPROVEMENT_PLAN_COMMENTS';
export const REMOVE_IMPROVEMENT_PLAN = 'REMOVE_IMPROVEMENT_PLAN';

export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';

//Site Pathway
export const SITEPATHWAY_ERROR = 'SITEPATHWAY_ERROR';
export const SITEPATHWAY_IS_LOADING = 'SITEPATHWAY_IS_LOADING';
export const SET_SELECTED_SITEPATHWAY = 'SET_SELECTED_SITEPATHWAY';
export const SET_SITEPATHWAY = 'SET_SITEPATHWAY';

//Site Segments
export const SET_SITESEGMENTS = 'SET_SITESEGMENTS';
export const SITESEGMENTS_IS_LOADING = 'SITESEGMENTS_IS_LOADING';
export const SITESEGMENTS_ERROR = 'SITESEGMENTS_ERROR';
export const SET_SELECTED_SITESEGMENTS_VIEW = 'SET_SELECTED_SITESEGMENTS_VIEW';

//Organisation Translations
export const ORGANISATION_TRANSLATION_ERROR = 'ORGANISATION_TRANSLATION_ERROR';
export const GET_ALL_ORGANISATION_TRANSLATION_DETAILS = 'GET_ALL_ORGANISATION_TRANSLATION_DETAILS';
export const ORGANISATION_TRANSLATION_IS_LOADING = 'ORGANISATION_TRANSLATION_IS_LOADING';
export const SET_ORGANISATION_TRANSLATION_CURRENT_PAGE = 'SET_ORGANISATION_TRANSLATION_CURRENT_PAGE';
export const SET_ORGANISATION_TRANSLATION_PAGE_FILTER = 'SET_ORGANISATION_TRANSLATION_PAGE_FILTER';
export const SET_ORGANISATION_TRANSLATION_CHANGE = 'SET_ORGANISATION_TRANSLATION_CHANGE';
export const SET_ORGANISATION_TRANSLATION = 'SET_ORGANISATION_TRANSLATION';
export const UPDATE_ORGANISATION_TRANSLATION_SAVE_STATUS = 'UPDATE_ORGANISATION_TRANSLATION_SAVE_STATUS';
export const SET_ORGANISATION_TRANSLATION_FILTERS = 'SET_ORGANISATION_TRANSLATION_FILTERS';
export const SET_ORGANISATION_TRANSLATION_RESULTS = 'SET_ORGANISATION_TRANSLATION_RESULTS';
export const SET_ORGANISATION_TRANSLATION_CLEANUP = 'SET_ORGANISATION_TRANSLATION_CLEANUP';
export const REMOVE_ORGANISATION_TRANSLATION = 'REMOVE_ORGANISATION_TRANSLATION';

//Translations
export const TRANSLATION_ERROR = 'TRANSLATION_ERROR';
export const GET_ALL_TRANSLATION_DETAILS = 'GET_ALL_TRANSLATION_DETAILS';
export const TRANSLATION_IS_LOADING = 'TRANSLATION_IS_LOADING';

//Incident Reports
export const INCIDENT_REPORTS_ERROR = 'INCIDENT_REPORTS_ERROR';
export const GET_ALL_INCIDENT_REPORTS_DETAILS = 'GET_ALL_INCIDENT_REPORTS_DETAILS';
export const INCIDENT_REPORTS_IS_LOADING = 'INCIDENT_REPORTS_IS_LOADING';
export const SET_INCIDENT_REPORTS_CURRENT_PAGE = 'SET_INCIDENT_REPORTS_CURRENT_PAGE';
export const SET_INCIDENT_REPORTS_PAGE_FILTER = 'SET_INCIDENT_REPORTS_PAGE_FILTER';
export const SET_INCIDENT_REPORTS_CHANGE = 'SET_INCIDENT_REPORTS_CHANGE';
export const SET_INCIDENT_REPORTS = 'SET_INCIDENT_REPORTS';
export const UPDATE_INCIDENT_REPORTS_SAVE_STATUS = 'UPDATE_INCIDENT_REPORTS_SAVE_STATUS';
export const SET_INCIDENT_REPORTS_FILTERS = 'SET_INCIDENT_REPORTS_FILTERS';
export const SET_INCIDENT_REPORTS_RESULTS = 'SET_INCIDENT_REPORTS_RESULTS';

//Corrective Actions
export const CORRECTIVE_ACTIONS_ERROR = 'CORRECTIVE_ACTIONS_ERROR';
export const GET_ALL_CORRECTIVE_ACTIONS_DETAILS = 'GET_ALL_CORRECTIVE_ACTIONS_DETAILS';
export const CORRECTIVE_ACTIONS_IS_LOADING = 'CORRECTIVE_ACTIONS_IS_LOADING';
export const SET_CORRECTIVE_ACTIONS_CURRENT_PAGE = 'SET_CORRECTIVE_ACTIONS_CURRENT_PAGE';
export const SET_CORRECTIVE_ACTIONS_PAGE_FILTER = 'SET_CORRECTIVE_ACTIONS_PAGE_FILTER';
export const SET_CORRECTIVE_ACTIONS_CHANGE = 'SET_CORRECTIVE_ACTIONS_CHANGE';
export const SET_CORRECTIVE_ACTIONS = 'SET_CORRECTIVE_ACTIONS';
export const UPDATE_CORRECTIVE_ACTIONS_SAVE_STATUS = 'UPDATE_CORRECTIVE_ACTIONS_SAVE_STATUS';
export const SET_CORRECTIVE_ACTIONS_FILTERS = 'SET_CORRECTIVE_ACTIONS_FILTERS';
export const SET_CORRECTIVE_ACTIONS_RESULTS = 'SET_CORRECTIVE_ACTIONS_RESULTS';
export const SET_CORRECTIVE_ACTIONS_COMMENTS = 'SET_CORRECTIVE_ACTIONS_COMMENTS';
export const REMOVE_CORRECTIVE_ACTIONS = 'REMOVE_CORRECTIVE_ACTIONS';
export const GET_ALL_CONTROL_MONITORINGS_BY_SITE = 'GET_ALL_CONTROL_MONITORINGS_BY_SITE';

//Entity Sub Type
export const ENTITY_SUBTYPE_ERROR = 'ENTITY_SUBTYPE_ERROR';
export const GET_ALL_ENTITY_SUBTYPE_DETAILS = 'GET_ALL_ENTITY_SUBTYPE_DETAILS';
export const ENTITY_SUBTYPE_IS_LOADING = 'ENTITY_SUBTYPE_IS_LOADING';
export const SET_ENTITY_SUBTYPE_CURRENT_PAGE = 'SET_ENTITY_SUBTYPE_CURRENT_PAGE';
export const SET_ENTITY_SUBTYPE_PAGE_FILTER = 'SET_ENTITY_SUBTYPE_PAGE_FILTER';
export const SET_ENTITY_SUBTYPE_CHANGE = 'SET_ENTITY_SUBTYPE_CHANGE';
export const SET_ENTITY_SUBTYPE_CLEANUP = 'SET_ENTITY_SUBTYPE_CLEANUP';
export const SET_ENTITY_SUBTYPE = 'SET_ENTITY_SUBTYPE';
export const UPDATE_ENTITY_SUBTYPE_SAVE_STATUS = 'UPDATE_ENTITY_SUBTYPE_SAVE_STATUS';
export const SET_ENTITY_SUBTYPE_RESULTS = 'SET_ENTITY_SUBTYPE_RESULTS';
export const REMOVE_ENTITY_SUBTYPE = 'REMOVE_ENTITY_SUBTYPE';

//Control Monitoring
export const CONTROL_MONITORINGS_ERROR = 'CONTROL_MONITORINGS_ERROR';
export const GET_ALL_CONTROL_MONITORINGS_DETAILS = 'GET_ALL_CONTROL_MONITORINGS_DETAILS';
export const CONTROL_MONITORINGS_IS_LOADING = 'CONTROL_MONITORINGS_IS_LOADING';
export const SET_CONTROL_MONITORINGS_CURRENT_PAGE = 'SET_CONTROL_MONITORINGS_CURRENT_PAGE';
export const SET_CONTROL_MONITORINGS_PAGE_FILTER = 'SET_CONTROL_MONITORINGS_PAGE_FILTER';
export const SET_CONTROL_MONITORINGS = 'SET_CONTROL_MONITORINGS';
export const UPDATE_CONTROL_MONITORINGS_SAVE_STATUS = 'UPDATE_CONTROL_MONITORINGS_SAVE_STATUS';
export const SET_CONTROL_MONITORINGS_FILTERS = 'SET_CONTROL_MONITORINGS_FILTERS';
export const SET_CONTROL_MONITORINGS_RESULTS = 'SET_CONTROL_MONITORINGS_RESULTS';
export const REMOVE_CONTROL_MONITORINGS = 'REMOVE_CONTROL_MONITORINGS';
export const SET_CONTROL_MONITORINGS_CHANGE = 'SET_CONTROL_MONITORINGS_CHANGE';

// Control Document TYPE

export const DOCUMENT_TYPE_INSPECTION_REPORT = 'INSPECTION_REPORT';
export const DOCUMENT_TYPE_HAZARD_EVENT = 'HAZARD_EVENT';
export const DOCUMENT_TYPE_TEST_RESULT = 'TEST_RESULT';

export const INCIDENT_DOCUMENT_TYPES = [
  { text: 'hazardEvent.hazardEvent', value: DOCUMENT_TYPE_HAZARD_EVENT },
  { text: 'inspectionReport.inspectionReport', value: DOCUMENT_TYPE_INSPECTION_REPORT },
  { text: 'testResult.testResult', value: DOCUMENT_TYPE_TEST_RESULT }
];

//Notification delivery types
export const NOTIFICATION_DELIVERY_TYPES = {
  0: { name: 'Email', value: 'Email' },
  1: { name: 'SMS', value: 'SMS' }
};
