import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import s from './SiteSegmentsTile.module.scss';
import bs from '../../../../../styles/bootstrap-overrides.scss';
import { IntlProvider } from 'react-intl';
import Cookies from 'js-cookie';

const SiteSegmentsTile = ({ elementId, element, handleClick, currentTimezone, languageObject }) => {
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const locale = Cookies.get(`defaultLanguage-${envName}`) || 'en';

  return (
    <IntlProvider locale={locale} messages={languageObject}>
      <li key={elementId} className={`${element.status ? '' : s.borderRed}`}>
        <a
          onClick={() => {
            if (element.url) handleClick(element.url);
          }}
          className={`${s.siteSegmentTile} `}
        >
          <div className={s.contentWrapper}>
            <div className={s.rightIcons}></div>
            <div className={s.siteSegmentInfo}>
              <h5>{element?.name}</h5>
              <h6>{}</h6>

              {element.items &&
                element.items.map((item, index) => {
                  return (
                    <p key={`index-${index}`} className={s.content}>
                      <img src={item.icon} alt={`${item.name}`} />
                      <span>{`${item.value}`}</span>
                    </p>
                  );
                })}
            </div>
          </div>
        </a>
      </li>
    </IntlProvider>
  );
};

SiteSegmentsTile.defaultProps = {
  location: 'unknown',
  elementId: null
};

SiteSegmentsTile.propTypes = {
  handleClick: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired
};

export default SiteSegmentsTile;
