import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './ProfileSettings.module.scss';
import LoadingSpinner from '..//LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import alert from '../../assets/alert.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isRequired } from '../../utils/';
import Cookies from 'js-cookie';
import { DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const UserDetailsUpdate = ({ updateError, saveUser, editUser, userManager, intl }) => {
  const [form, setForm] = useState({
    firstName: userManager.selectedUser.FirstName,
    lastName: userManager.selectedUser.LastName,
    phoneNumber: userManager.selectedUser.PhoneNumber
  });
  const [formErrors, setFormErrors] = useState({ firstName: false, phoneNumber: false });

  const handleInput = (e, data) => {
    if (data && data.countryCode) {
      e = { target: { name: 'phoneNumber', value: e } };
    }
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const firstNameError = isRequired(form.firstName, 1);
    const phoneNumberError = isRequired(form.phoneNumber.replace(/ /g, ''), 8);
    let LoggedInUserId = Cookies.get(`userid-${envName}`) || 1;
    setFormErrors({
      firstName: firstNameError,
      phoneNumber: phoneNumberError
    });

    if (!firstNameError && !phoneNumberError) {
      let saveData = { ...userManager.selectedUser };
      saveData.IsEdit = true;
      saveData.FirstName = form.firstName;
      saveData.LastName = form.lastName;
      saveData.PhoneNumber = form.phoneNumber;

      saveData.ModifiedByUserId = LoggedInUserId;

      //saving User
      saveUser(saveData);
    }
  };

  let messageId = (userManager && userManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  if (userManager.isOpSuccessfull) {
    Cookies.set(`name-${envName}`, `${form.firstName} ${form.lastName}`, {
      expires: 7,
      domain: DOMAIN_NAME,
      secure: IS_LOCALHOST ? false : true
    });
  }

  return (
    <div className="p-5">
      {userManager.isLoading && <LoadingSpinner />}
      <Banner
        key="banner"
        failureText={messageText}
        showBanner={userManager.showBanner}
        status={userManager.isOpSuccessfull}
        successText={messageText}
      />

      <Form onSubmit={handleSubmit}>
        {updateError && (
          <Alert variant="light">
            <img src={alert} alt="icon" /> Error updating user
          </Alert>
        )}

        <Row>
          <Col>
            <h4>
              <FormattedMessage id="profileSettings.personal" defaultMessage="Personal" />
            </h4>
          </Col>
          <Button variant="outline-secondary" className={s.button} onClick={editUser}>
            <FormattedMessage id="profile.profileCancel" defaultMessage="Cancel" />
          </Button>
          <Button type="submit" disabled={userManager.isLoading} className={s.saveButton}>
            <FormattedMessage id="save" defaultMessage="Save" />
          </Button>
        </Row>

        <FormattedMessage id="usermanagement.firstname" defaultMessage="First Name" />
        <Form.Control
          type="text"
          name="firstName"
          value={form.firstName}
          onChange={handleInput}
          className={`col-md-6 col-xs-12 ${formErrors.firstName ? s.formControlError : ''}`}
        />

        {formErrors.firstName && (
          <p role="alert" className={s.error}>
            <FormattedMessage id="usermanagement.firstnamemandatory" defaultMessage="First name is mandatory field" />
          </p>
        )}

        <FormattedMessage id="usermanagement.lastname" defaultMessage="Last Name" />
        <Form.Control type="text" name="lastName" value={form.lastName} onChange={handleInput} className="col-md-6 col-xs-12" />

        <Form.Label>
          <FormattedMessage id="usermanagement.contactno" defaultMessage="Contact number" />
        </Form.Label>
        <PhoneInput
          country={'au'}
          inputStyle={{ width: '100%' }}
          value={form.phoneNumber}
          onChange={handleInput}
          inputProps={{ name: 'phoneNumber' }}
          inputClass={`col-md-6 col-xs-12 ${formErrors.phoneNumber ? s.formControlError : ''}`}
          buttonClass={formErrors.phoneNumber ? s.formControlError : ''}
        />
        {formErrors.phoneNumber && (
          <p role="alert" className={s.error}>
            <FormattedMessage
              id="usermanagment.entervalidphoneno"
              defaultMessage="Please enter valid phone number (minimum 7 digits including country code)"
            />
          </p>
        )}
      </Form>
    </div>
  );
};

UserDetailsUpdate.propTypes = {
  updateError: PropTypes.bool,
  saveUser: PropTypes.func
};

export default injectIntl(UserDetailsUpdate);
