import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './SafetyPlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialSafetyPlanState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { goBack, isRequired } from '../../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import uuid from 'uuid';
import { getUserProfileWidget, getGeneralData, getSafetyPlan, getCurrentTimezone } from '../../../selectors/index';
import { describeSafetyPlanThunk, saveSafetyPlanThunk, setSafetyPlanChangeThunk } from '../../../store/actions/safetyPlan/safetyPlan';
import DateTimePicker from '../../WSAControls/DateTimePicker/DateTimePicker';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import { FREQUENCY } from '../../../constants/index';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;
const SafetyPlan = ({ intl }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  let { siteId, segmentName, selectedTab, safetyPlanId } = useParams();

  siteId = (siteId && parseInt(siteId)) || '0';
  selectedTab = selectedTab || '2';

  let IsEdit = false;

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const safetyPlan = useSelector(state => getSafetyPlan(state));
  const generalData = useSelector(state => getGeneralData(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);
  const [localformErrors, setFormErrors] = useState({});

  const disableFutureDates = current => current.isBefore(currentTimestamp);

  const backUrl = '/safety-plans';
  let widgetCode = '';
  if (safetyPlanId) {
    IsEdit = true;
    widgetCode = 'WQSP_SAFETY_PLAN_UPDATE';
  }

  // Update redux store
  const setLocalSafetyPlan = currentState => {
    dispatch(setSafetyPlanChangeThunk(currentState));
  };

  useEffect(() => {
    if (safetyPlanId) {
      dispatch(describeSafetyPlanThunk(safetyPlanId, widgetCode));
    }
  }, [describeSafetyPlanThunk, safetyPlanId]);

  useEffect(() => {
    if (siteId && !safetyPlan.selectedSafetyPlan.SiteId) {
      setLocalSafetyPlan({
        ...safetyPlan,
        selectedSafetyPlan: { ...safetyPlan.selectedSafetyPlan, SiteId: siteId }
      });
    }
  }, [setLocalSafetyPlan, siteId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setSafetyPlanChangeThunk({
          ...initialSafetyPlanState.safetyPlan
        })
      );
    };
  }, []);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = safetyPlan.selectedSafetyPlan;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.SafetyPlanId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_SAFETY_PLAN_ADD';
    }

    dispatch(saveSafetyPlanThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !safetyPlan ||
      !safetyPlan.selectedSafetyPlan ||
      !safetyPlan.selectedSafetyPlan.SiteId ||
      isRequired(safetyPlan.selectedSafetyPlan.SiteId, 1)
    ) {
      formErrors.SiteId = intl.formatMessage({
        id: 'wqsp.common.siteOnlyMandatory',
        defaultMessage: 'Site is a mandatory field'
      });
      isValid = false;
    }

    if (
      !safetyPlan ||
      !safetyPlan.selectedSafetyPlan ||
      !safetyPlan.selectedSafetyPlan.Name ||
      isRequired(safetyPlan.selectedSafetyPlan.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'common.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const siteOptions = () => {
    let opData = [];

    const data = generalData.sites;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.SiteId
      });
    });
    return opData;
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  //on control value change
  const onChange = e => {
    setLocalSafetyPlan({
      ...safetyPlan,
      selectedSafetyPlan: { ...safetyPlan.selectedSafetyPlan, [e.target.name]: e.target.value }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalSafetyPlan({
      ...safetyPlan,
      selectedSafetyPlan: { ...safetyPlan.selectedSafetyPlan, [fieldName]: e.value }
    });
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();

    setLocalSafetyPlan({
      ...safetyPlan,
      selectedSafetyPlan: { ...safetyPlan.selectedSafetyPlan, [fieldName]: changeDate }
    });
  };

  const cancelHandler = () => {
    goBack(history, backUrl);
  };

  let ddlClassSensor = localformErrors && localformErrors.SiteId ? s.ddlError : '';

  let messageId = (safetyPlan && safetyPlan.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const safetyPlanName = (safetyPlan.selectedSafetyPlan ? safetyPlan.selectedSafetyPlan.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + safetyPlanName;
  } else {
    heading = intl.formatMessage({ id: 'safetyPlan.addNewSafetyPlan', defaultMessage: 'Add new Safety Plan' });
  }

  if (safetyPlan.isRedirect) goBack(history, backUrl);

  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' });

  const breadcrumb = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <SiteDashboardControlsContentTemplate
      selectedPage={selectedTab}
      siteId={siteId}
      segmentName={segmentName}
      userProfileWidgets={userProfileWidgets}
    >
      <div className={s.safetyPlan}>
        {safetyPlan.isLoading && <LoadingSpinner />}
        {siteId === '0' ? (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="safetyPlan.safetyPlans" defaultMessage="Safety Plans" />}</h1>
          </div>
        ) : (
          ''
        )}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={safetyPlan.showBanner}
          status={safetyPlan.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          {segmentName && <Breadcrumb data={breadcrumb} segmentName={segmentName} />}
          <div className={s.safetyPlanHeader}>
            {siteId === '0' ? (
              <GoBack className={s.backLink}>
                &lt; &nbsp;
                <FormattedMessage id="common.back" defaultMessage="BACK" />
              </GoBack>
            ) : (
              ''
            )}
            {siteId === '0' && <h3>{heading}</h3>}
          </div>
          <div className={s.safetyPlanContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="siteName">
                      <Form.Label>
                        <FormattedMessage id="inspectionReports.Site" defaultMessage="Site" />
                      </Form.Label>

                      <Dropdown
                        id="formSiteName"
                        dataArray={siteOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: ddlClassSensor
                        }}
                        name="SiteId"
                        onDropdownChange={e => onDropdownChange(e, 'SiteId')}
                        selectedOption={siteOptions().filter(option => option.value === safetyPlan.selectedSafetyPlan.SiteId)}
                        disabled={IsEdit || siteId !== '0'}
                      />
                      {localformErrors && localformErrors.SiteId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.SiteId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formSafetyPlanName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={safetyPlan.selectedSafetyPlan.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formSafetyPlanPlanNumber">
                      <Form.Label>
                        <FormattedMessage id="safetyPlan.planNumber" defaultMessage="Plan Number" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="PlanNumber"
                        onChange={onChange}
                        value={safetyPlan.selectedSafetyPlan.PlanNumber}
                        className={`${s.formControl} ${localformErrors && localformErrors.PlanNumber ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'safetyPlan.planNumber',
                          defaultMessage: 'Plan Number'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formSafetyPlanDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={safetyPlan.selectedSafetyPlan.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formLastReviewDate">
                      <Form.Label>
                        <FormattedMessage id="safetyPlan.lastReviewDate" defaultMessage="Last Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        isValidDate={disableFutureDates}
                        className={`${localformErrors.LastReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'LastReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(safetyPlan.selectedSafetyPlan.LastReviewDate)}
                        defaultValue={getFormattedate(safetyPlan.selectedSafetyPlan.LastReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.LastReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.LastReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formNextReviewDate">
                      <Form.Label>
                        <FormattedMessage id="safetyPlan.nextReviewDate" defaultMessage="Next Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'NextReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(safetyPlan.selectedSafetyPlan.NextReviewDate)}
                        defaultValue={getFormattedate(safetyPlan.selectedSafetyPlan.NextReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.NextReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.NextReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formGroupReviewFrequency">
                      <Form.Label>
                        <FormattedMessage id="safetyPlan.reviewFrequency" defaultMessage="Review Frequency" />
                      </Form.Label>

                      <Dropdown
                        id="formMaintenanceFrequency"
                        dataArray={frequencyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="ReviewFrequencyId"
                        onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                        selectedOption={frequencyOptions().filter(
                          option => option.value === safetyPlan.selectedSafetyPlan.ReviewFrequencyId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className={s.btnRow}>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </SiteDashboardControlsContentTemplate>
  );
};

export default injectIntl(SafetyPlan);
