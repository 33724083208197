import {
  RESET_VERIFICATION_CODE,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_COMPLETE,
  SET_FORGOT_PASSWORD_CONFIRMATION_SENT
} from '../../constants/index';
import { initialForgotPasswordState } from './initialState';

const forgotPassword = (state = initialForgotPasswordState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_FORGOT_PASSWORD_CONFIRMATION_SENT: {
      const { data } = payload;
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          ...data
        }
      };
    }

    case FORGOT_PASSWORD_ERROR: {
      const { data } = payload;
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          ...data
        }
      };
    }

    case FORGOT_PASSWORD_COMPLETE: {
      const { data } = payload;
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          ...data
        }
      };
    }

    case RESET_VERIFICATION_CODE: {
      const { data } = payload;
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default forgotPassword;
