import { initialQualityGoalsState } from './initialState';

import {
  QUALITY_GOALS_ERROR,
  GET_ALL_QUALITY_GOALS_DETAILS,
  QUALITY_GOALS_IS_LOADING,
  SET_QUALITY_GOALS_CURRENT_PAGE,
  SET_QUALITY_GOALS_PAGE_FILTER,
  SET_QUALITY_GOALS_CHANGE,
  SET_QUALITY_GOALS,
  UPDATE_QUALITY_GOALS_SAVE_STATUS,
  SET_QUALITY_GOALS_FILTERS,
  SET_QUALITY_GOALS_RESULTS,
  REMOVE_QUALITY_GOALS
} from '../../constants/index';

const qualityGoalsReducer = (state = initialQualityGoalsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_QUALITY_GOALS_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          ...data
        }
      };
    }

    case SET_QUALITY_GOALS_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          ...data
        }
      };
    }

    case SET_QUALITY_GOALS_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          ...filteredResults
        }
      };
    }

    case SET_QUALITY_GOALS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          currentPage
        }
      };
    }

    case SET_QUALITY_GOALS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          filter
        }
      };
    }

    case UPDATE_QUALITY_GOALS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          ...data
        }
      };
    }

    case QUALITY_GOALS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          isLoading: isLoading
        }
      };
    }

    case QUALITY_GOALS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          ...data
        }
      };
    }

    case SET_QUALITY_GOALS: {
      let { selectedQualityGoals } = payload;

      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          isOpSuccessful: false,
          showBanner: false,
          selectedQualityGoals: {
            ...state.qualityGoals.selectedQualityGoals,
            ...selectedQualityGoals
          }
        }
      };
    }

    case REMOVE_QUALITY_GOALS: {
      const { qualityGoalReportId } = payload;
      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          showBanner: false,
          qualityGoals: [...state.qualityGoals.qualityGoals.filter(item => item.QualityGoalReportId !== qualityGoalReportId)],
          filteredResults: [...state.qualityGoals.filteredResults.filter(item => item.QualityGoalReportId !== qualityGoalReportId)],
          qualityGoalsListCount: state.qualityGoals.qualityGoalsListCount - 1
        }
      };
    }

    case SET_QUALITY_GOALS_CHANGE: {
      let { selectedQualityGoals } = payload;

      return {
        ...state,
        qualityGoals: {
          ...state.qualityGoals,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedQualityGoals: {
            ...state.qualityGoals.selectedQualityGoals,
            ...selectedQualityGoals
          }
        }
      };
    }

    default:
      return state;
  }
};

export default qualityGoalsReducer;
