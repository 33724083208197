import React, { useState, useEffect } from 'react';
import s from './StakeholderRegister.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getPersonnel, getStakeholderRegister, getSelectedSafetyPlan } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'react-phone-input-2/lib/style.css';
import { initialStakeholderRegisterState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import Banner from '../../Banner/Banner';
import {
  describeStakeholderRegisterThunk,
  saveStakeholderRegisterThunk,
  setStakeholderRegisterChangeThunk
} from '../../../store/actions/safetyPlan/stakeholderRegister';
import { getPersonnelThunk } from '../../../store/actions/admin/personnel';
import uuid from 'uuid';
import { describeSafetyPlanThunk } from '../../../store/actions/safetyPlan/safetyPlan';
import Breadcrumb from '../../WSAControls/Breadcrumb';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const StakeholderRegister = ({ intl }) => {
  let history = useHistory();
  let { stakeholderRegisterId, safetyPlanId, siteId, segmentName, selectedTab } = useParams();
  const dispatch = useDispatch();
  const stakeholderRegister = useSelector(state => getStakeholderRegister(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);
  const personnel = useSelector(state => getPersonnel(state));
  const [localformErrors, setFormErrors] = useState({});
  let IsEdit = false;

  segmentName = segmentName || 'build';
  selectedTab = selectedTab || '2';

  const backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/stakeholders`;
  let widgetCode = '';
  if (stakeholderRegisterId) {
    IsEdit = true;
    widgetCode = 'WQSP_STAKEHOLDER_REGISTER_EDIT';
  }

  let offset = personnel.offset || 0;
  let limit = personnel.limit || 1000;

  useEffect(() => {
    let filterObj = {
      offset: offset,
      limit: limit
    };
    dispatch(getPersonnelThunk('WQSP_PERSONNEL_LIST', filterObj));
  }, [getPersonnelThunk]);

  useEffect(() => {
    if (stakeholderRegisterId) {
      dispatch(describeStakeholderRegisterThunk(stakeholderRegisterId, widgetCode));
    }
  }, [describeStakeholderRegisterThunk, dispatch, stakeholderRegisterId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setStakeholderRegisterChangeThunk({
          ...initialStakeholderRegisterState.stakeholderRegister
        })
      );
    };
  }, []);

  useEffect(() => {
    dispatch(describeSafetyPlanThunk(safetyPlanId, 'WQSP_SAFETY_PLAN_UPDATE'));
  }, [safetyPlanId]);

  const selectedSafetyPlan = useSelector(state => getSelectedSafetyPlan(state));
  const safetyPlanName = (selectedSafetyPlan.Name && ` (${selectedSafetyPlan.Name})`) || '';

  // Update redux store
  const setLocalStakeholderRegister = currentState => {
    dispatch(setStakeholderRegisterChangeThunk(currentState));
  };

  const personnelOptions = () => {
    let opData = [];

    personnel &&
      personnel.personnels.forEach(element => {
        opData.push({
          label: element.Name,
          value: element.PersonnelId
        });
      });
    return opData.sort(compareValues('label'));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = stakeholderRegister.selectedStakeholderRegister;
    saveData.WaterQualitySafetyPlanId = safetyPlanId;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.StakeholderRegisterId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_STAKEHOLDER_REGISTER_ADDNEW';
    }

    dispatch(saveStakeholderRegisterThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !stakeholderRegister ||
      !stakeholderRegister.selectedStakeholderRegister ||
      !stakeholderRegister.selectedStakeholderRegister.IssuesRelationship ||
      isRequired(stakeholderRegister.selectedStakeholderRegister.IssuesRelationship, 1)
    ) {
      formErrors.IssuesRelationship = intl.formatMessage({
        id: 'stakeholderRegister.issuesRelationshipRequired',
        defaultMessage: 'Issues Relationship is a mandatory field'
      });
      isValid = false;
    }

    if (
      !stakeholderRegister ||
      !stakeholderRegister.selectedStakeholderRegister ||
      !stakeholderRegister.selectedStakeholderRegister.PersonnelId
    ) {
      formErrors.PersonnelId = intl.formatMessage({
        id: 'stakeholderRegister.PersonnelMandatory',
        defaultMessage: 'Personnel is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalStakeholderRegister({
      ...stakeholderRegister,
      selectedStakeholderRegister: { ...stakeholderRegister.selectedStakeholderRegister, [fieldName]: e.value }
    });
  };

  //on control value change
  const onChange = (e, data) => {
    setLocalStakeholderRegister({
      ...stakeholderRegister,
      selectedStakeholderRegister: { ...stakeholderRegister.selectedStakeholderRegister, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (stakeholderRegister && stakeholderRegister.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const title = (stakeholderRegister.selectedStakeholderRegister ? stakeholderRegister.selectedStakeholderRegister.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + title;
  } else {
    heading = intl.formatMessage({ id: 'stakeholderRegister.addStakeholder', defaultMessage: 'Add new stakeholder' });
  }

  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' });
  breadcrumbHeading += `${safetyPlanName}`;

  const breadcrumb = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="siteControlsTabs.stakeholders" defaultMessage="Stakeholders" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/stakeholders`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <div className={s.stakeholderRegister}>
      {stakeholderRegister.isLoading && <LoadingSpinner />}
      {stakeholderRegister.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={stakeholderRegister.showBanner}
        status={stakeholderRegister.isOpSuccessful}
        successText={messageText}
      />
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.contentWrapper}>
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
          <div className={s.stakeholderRegisterContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="stakeholderRegisterPersonnel">
                      <Form.Label>
                        <FormattedMessage id="stakeholderRegister.personnel" defaultMessage="Personnel" />
                      </Form.Label>

                      <Dropdown
                        id="formPersonnel"
                        dataArray={personnelOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: localformErrors.PersonnelId && s.ddlError
                        }}
                        name="PersonnelId"
                        onDropdownChange={e => onDropdownChange(e, 'PersonnelId')}
                        selectedOption={personnelOptions().filter(
                          option => option.value === stakeholderRegister.selectedStakeholderRegister.PersonnelId
                        )}
                      />
                      {localformErrors && localformErrors.PersonnelId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.PersonnelId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formStakeholderRegisterIssuesRelationship">
                      <Form.Label>
                        <FormattedMessage id="stakeholderRegister.issuesRelationship" defaultMessage="Issues Relationship" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="IssuesRelationship"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'stakeholderRegister.issuesRelationship',
                          defaultMessage: 'Issues Relationship'
                        })}
                        value={stakeholderRegister.selectedStakeholderRegister.IssuesRelationship}
                      />
                      {localformErrors && localformErrors.IssuesRelationship && (
                        <p role="alert" className={s.error}>
                          {localformErrors.IssuesRelationship}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formStakeholderRegisterKeyPoint">
                      <Form.Label>
                        <FormattedMessage id="stakeholderRegister.keyPoint" defaultMessage="Key Point" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="KeyPoint"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'stakeholderRegister.keyPoint',
                          defaultMessage: 'Key Point'
                        })}
                        value={stakeholderRegister.selectedStakeholderRegister.KeyPoint}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                      disabled={stakeholderRegister.isLoading}
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

StakeholderRegister.defaultProps = {
  stakeholderRegister: {
    ...initialStakeholderRegisterState
  }
};

export default injectIntl(StakeholderRegister);
