import React from 'react';
import s from './TestResultReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getDateInDDMMYYYFormat } from '../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../selectors/index';
import MoreOption from '../WSAControls/MoreOption/MoreOption';

const TestResultReportListTable = ({
  paginatedData,
  onSortOrder,
  asendingOrder,
  ChangeTestResultReport,
  UpdateAllowed,
  AllowCreateIncidentReport,
  CreateIncidentReport,
  deleteAllowed,
  removeTestResult
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
            </th>
            <th>
              <FormattedMessage id="testResult.testPurpose" defaultMessage="Test Purpose" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="testResult.sampleDate" defaultMessage="Sample Date" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="testResult.sampleIDNumber" defaultMessage="Sample ID Number" />
            </th>

            {(UpdateAllowed || deleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.TestResultReportId}>
                <td>{n.SiteName || ''}</td>
                <td>{n.WaterSupplyDescription || ''}</td>
                <td>{n.EntityTypeName || ''}</td>
                <td>{n.Entity || ''}</td>
                <td>{n.TestPurpose || ''}</td>
                <td>{getDateInDDMMYYYFormat(n.SampleDate, currentTimezone) || ''}</td>
                <td>{n.SampleIdNumber || ''}</td>
                <td className={s.btnCol}>
                  <MoreOption
                    dataArray={[
                      UpdateAllowed
                        ? {
                            Click: () => ChangeTestResultReport(n.SiteId, n.TestResultReportId),
                            Name: <FormattedMessage id="common.edit" defaultMessage="EDIT" />
                          }
                        : {},
                      AllowCreateIncidentReport
                        ? {
                            Click: () => CreateIncidentReport(n),
                            Name: <FormattedMessage id="incidentReports.createIncidentReport" defaultMessage="CREATE INCIDENT REPORT" />
                          }
                        : {},
                      deleteAllowed
                        ? {
                            Click: () => removeTestResult(n.TestResultReportId),
                            Name: <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                          }
                        : {}
                    ]}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

TestResultReportListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(TestResultReportListTable);
