import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';

import {
  RISK_CATEGORY_ERROR,
  GET_ALL_RISK_CATEGORY_DETAILS,
  RISK_CATEGORY_IS_LOADING,
  SET_RISK_CATEGORY_CURRENT_PAGE,
  SET_RISK_CATEGORY_PAGE_FILTER,
  SET_RISK_CATEGORY_CHANGE,
  SET_RISK_CATEGORY_MATRIX_CHANGE,
  SET_RISK_CATEGORY,
  SET_RISK_CATEGORY_CLEANUP,
  UPDATE_RISK_CATEGORY_SAVE_STATUS,
  RISK_CATEGORY_LISTING_CLEANUP,
  RISK_CATEGORY_FILTER_DATA
} from '../../constants/index';

import { RISK_CATEGORY_MANAGEMENT, CREATE_RISK_CATEGORY, UPDATE_RISK_CATEGORY } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setRiskCategoryErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setRiskCategoryError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setRiskCategoryError(messageCode, false));
  }, 2500);
};

export const setRiskCategoryError = (messageCode, status) => {
  return {
    type: RISK_CATEGORY_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: RISK_CATEGORY_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getRiskCategoryThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getRiskCategory(widgetCode, orgId, filterObj));
};

export const getRiskCategory = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/riskcategory`,
    method: 'GET',
    data: {
      OrgId: orgId,
      filter: null,
      limit: 1000,
      offset: 0
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllRiskCategoryListThunk(retData),
    onFailure: err => setRiskCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllRiskCategoryListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllRiskCategoryList(messageCode, data));
};

export const setAllRiskCategoryList = (messageCode, data) => ({
  type: GET_ALL_RISK_CATEGORY_DETAILS,
  payload: {
    data: {
      RiskCategories: data,
      filteredResults: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_RISK_CATEGORY_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_RISK_CATEGORY_PAGE_FILTER,
  payload: { filter }
});

export const describeRiskCategoryThunk = (RiskCategoryId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeRiskCategory(RiskCategoryId, widgetCode, orgId));
};

export const describeRiskCategory = (RiskCategoryId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/riskcategory`,
    data: {
      RiskCategoryId: RiskCategoryId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setRiskCategoryThunk(retData, RiskCategoryId),
    onFailure: err => setRiskCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setRiskCategoryThunk = ({ retData }, RiskCategoryId) => dispatch => {
  let data = retData.data;
  if (RiskCategoryId && (!data || data.RiskCategoryId !== parseInt(RiskCategoryId))) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setRiskCategory(data));
};

export const setRiskCategory = data => ({
  type: SET_RISK_CATEGORY,
  payload: {
    data: data
  }
});

export const deleteRiskCategoryThunk = (data, widgetCode) => dispatch => {
  let url = '/management/riskcategory/save';
  let actionName = data.RiskCategoryId === 0 ? CREATE_RISK_CATEGORY : UPDATE_RISK_CATEGORY;
  let log = logEntry(RISK_CATEGORY_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrganisationId = orgId;
  dispatch(deleteRiskCategory(data, url, log, widgetCode));
};

export const deleteRiskCategory = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setDeleteRiskCategoryStatusThunk(retData),
    onFailure: err => setRiskCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setDeleteRiskCategoryStatusThunk = message => dispatch => {
  dispatch(setRiskCategoryStatus(message, true, false));

  setTimeout(() => {
    //Hide the banner
    dispatch(setRiskCategoryStatus('', false, false));
    dispatch(getRiskCategoryThunk('WQSP_RISKCATEGORY_LIST', null));
  }, 2500);
};

export const saveRiskCategoryThunk = (data, widgetCode) => dispatch => {
  let url = '/management/riskcategory/save';
  let actionName = data.RiskCategoryId === 0 ? CREATE_RISK_CATEGORY : UPDATE_RISK_CATEGORY;
  let log = logEntry(RISK_CATEGORY_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrganisationId = orgId;
  dispatch(saveRiskCategory(data, url, log, widgetCode));
};

export const saveRiskCategory = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setRiskCategoryStatusThunk(retData),
    onFailure: err => setRiskCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setRiskCategoryStatusThunk = message => dispatch => {
  dispatch(setRiskCategoryStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    dispatch(setRiskCategoryStatus(message, false, true));
  }, 2500);
};

export const setRiskCategoryStatus = (messageCode, status, redirect) => ({
  type: UPDATE_RISK_CATEGORY_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: status,
      showBanner: status,
      isLoading: false,
      isRedirect: redirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setRiskCategoryChangeThunk = retData => dispatch => {
  dispatch(setRiskCategoryChange(retData));
};

export const setRiskCategoryChange = retData => ({
  type: SET_RISK_CATEGORY_CHANGE,
  payload: retData
});

export const setRiskCategoryMatrixChangeThunk = retData => dispatch => {
  dispatch(setRiskCategoryMatrixChange(retData));
};

export const setRiskCategoryMatrixChange = retData => ({
  type: SET_RISK_CATEGORY_MATRIX_CHANGE,
  payload: retData
});

export const setRiskCategoryCleanUpThunk = SelectedRiskCategory => dispatch => {
  dispatch({ type: SET_RISK_CATEGORY_CLEANUP, payload: SelectedRiskCategory });
};

export const cleanUpThunk = retData => dispatch => {
  dispatch(cleanUp(retData));
};

export const cleanUp = retData => ({
  type: RISK_CATEGORY_LISTING_CLEANUP,
  payload: retData
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: RISK_CATEGORY_FILTER_DATA,
  payload: retData
});
