import React from 'react';
import s from './UserList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

const UserListTable = ({ paginatedData, currentPage, UpdateAllowed }) => {
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell">
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="common.email" defaultMessage="Email" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="usermanagment.usermanagment.contactno" defaultMessage="Contact No" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="common.active" defaultMessage="Active" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr data-unittest="userlistdata" key={n.UserId}>
                <td className="d-none d-sm-table-cell">{`${n.FullName || ''}`}</td>
                <td>{n.Email || ''}</td>
                <td className="d-none d-md-table-cell">{n.PhoneNumber || ''}</td>
                <td className="d-none d-md-table-cell">{n.IsActive ? 'Yes' : 'No'}</td>
                <td>
                  {UpdateAllowed ? (
                    <Link className={s.selectLink} to={`/admin-controls/user/${n.UserId}`}>
                      <FormattedMessage id="common.edit" defaultMessage="EDIT" />
                    </Link>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

UserListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(UserListTable);
