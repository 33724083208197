import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './ControlMeasure.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialControlMeasureState } from '../../../store/reducers/initialState';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import uuid from 'uuid';
import { getUserProfileWidget, getControlMeasure, getCurrentTimezone } from '../../../selectors/index';
import { getDateInDDMMYYYFormat, compareValues } from '../../../utils';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import { describeControlMeasureThunk, setControlMeasureChangeThunk } from '../../../store/actions/hazardEvent/controlMeasures';
import ControlMonitoringListTable from './ControlMonitoringListTable';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../../WSAControls/Breadcrumb';

const ControlMeasureView = ({ intl }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const controlMeasure = useSelector(state => getControlMeasure(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let { controlMeasureId, hazardEventId, siteId, entityId, segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'control';
  selectedTab = selectedTab || '2';
  const [asendingOrder, setAsendingOrder] = useState(true);
  let IsEdit = false;

  let disableControls = controlMeasure.isLoading || false;

  let backUrl = `/hazard-events/${hazardEventId}/control-measures`;
  if (segmentName === 'control') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/control-measures`;
  }

  let widgetCode = 'WQSP_CONTROL_MEASURES_EDIT';
  if (controlMeasureId) {
    IsEdit = true;
  }

  useEffect(() => {
    if (controlMeasureId) {
      dispatch(describeControlMeasureThunk(controlMeasureId, widgetCode));
    }
  }, [describeControlMeasureThunk, controlMeasureId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setControlMeasureChangeThunk({
          ...initialControlMeasureState.controlMeasure
        })
      );
    };
  }, []);

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onChangeControlMeasureClick = controlMeasureId => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}`
      );
    } else if (segmentName === 'identify') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}`
      );
    } else {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure/${controlMeasureId}`);
    }
  };

  const onViewControlMonitoringClick = controlMonitoringId => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`
      );
    } else if (segmentName === 'identify') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`
      );
    } else {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure/${controlMeasureId}/control-monitoring-view/${controlMonitoringId}`
      );
    }
  };

  let messageId = (controlMeasure && controlMeasure.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const controlMeasureName = (controlMeasure.selectedControlMeasure ? controlMeasure.selectedControlMeasure.Name : '') || '';
  let heading = intl.formatMessage({ id: 'common.viewTextCC', defaultMessage: 'View' }) + ' - ' + controlMeasureName;
  const isItemListingAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MONITORINGS_LIST');
  const isControlMonitoringViewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MONITORINGS_VIEW');

  let controlMonitoringsObj = controlMeasure.selectedControlMeasure.controlMonitorings;
  if (asendingOrder) {
    controlMonitoringsObj = controlMonitoringsObj.sort(compareValues('LastReviewDate'));
  } else {
    controlMonitoringsObj = controlMonitoringsObj.sort(compareValues('LastReviewDate', 'desc'));
  }

  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const actionName = controlMeasure.selectedControlMeasure.IsActioned ? yesText : noText;

  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfControlMeasures', defaultMessage: 'List of Control Measures' });

  const hazardEventName = controlMeasure.selectedControlMeasure.HazardName;
  let hazardEventHeading =
    intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + ' (' + hazardEventName + ')';

  const breadcrumbControl = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/control-measures`,
      showlink: true,
      showCrumb: true
    },

    { orderNo: 2, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 5, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const identifyHazardEventcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 4, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const getBreadcrumbData = () => {
    if (segmentName === 'define') {
      return defineEntityCrumb;
    } else if (segmentName === 'identify') {
      return identifyHazardEventcrumb;
    } else {
      return breadcrumbControl;
    }
  };

  return (
    <div className={s.controlMeasure}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {controlMeasure.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={controlMeasure.showBanner}
          status={controlMeasure.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          <Breadcrumb data={getBreadcrumbData()} segmentName={segmentName} />
          <div>
            <Row>
              <Col>
                <Row className={s.colActionButton}>
                  <Button
                    className={classNames(s.margin5, s.changeButton)}
                    style={WidgetVisibility(userProfileWidgets, 'WQSP_CONTROL_MEASURES_EDIT')}
                    onClick={() => {
                      onChangeControlMeasureClick(controlMeasureId);
                    }}
                    disabled={disableControls}
                  >
                    <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={s.controlMeasureContent}>
            <Form>
              <div className={s.topRow}>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="controlMeasure.hazardEvent" defaultMessage="Hazard Event" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.HazardName || '-'}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="common.name" defaultMessage="Name" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.Name || '-'}</Col>
                </Row>
                <Row>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="common.description" defaultMessage="Description" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.Description || '-'}</Col>
                </Row>
                <Row>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="dateImplemented" defaultMessage="Date Implemented" />
                    </Form.Label>
                  </Col>
                  <Col>
                    {(controlMeasure.selectedControlMeasure.DateImplemented &&
                      getDateInDDMMYYYFormat(controlMeasure.selectedControlMeasure.DateImplemented, currentTimezone)) ||
                      '-'}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="controlMeasure.targetCriteria" defaultMessage="Target Criteria" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.TargetCriteria || '-'}</Col>
                </Row>
                <Row>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="controlMeasure.targetCriteriaType" defaultMessage="Target Criteria Type" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.TargetCriteriaTypeName || '-'}</Col>
                </Row>
                <Row>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="controlMeasure.alertTargetTrigger" defaultMessage="Alert Criteria Trigger" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.AlertCriteriaTrigger || '-'}</Col>
                </Row>
                <Row>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="controlMeasure.alertCriteria" defaultMessage="Alert Criteria" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.AlertCriteriaName || '-'}</Col>
                </Row>
                <Row>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="controlMeasure.criticalLimitTrigger" defaultMessage="Critical Limit Trigger" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.CriticalLimitTrigger || '-'}</Col>
                </Row>
                <Row>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="controlMeasure.criticalLimitType" defaultMessage="Critical Limit Type" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.CriticalLimitTypeName || '-'}</Col>
                </Row>
                <Row>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="controlMeasure.unitOfMeasure" defaultMessage="Unit Of Measure" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.UnitOfMeasure || '-'}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="common.lastReviewDate" defaultMessage="Date Of Last Review" />
                    </Form.Label>
                  </Col>
                  <Col>
                    {(controlMeasure.selectedControlMeasure.LastReviewDate &&
                      getDateInDDMMYYYFormat(controlMeasure.selectedControlMeasure.LastReviewDate, currentTimezone)) ||
                      '-'}
                  </Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                    </Form.Label>
                  </Col>
                  <Col>
                    {(controlMeasure.selectedControlMeasure.NextReviewDate &&
                      getDateInDDMMYYYFormat(controlMeasure.selectedControlMeasure.NextReviewDate, currentTimezone)) ||
                      '-'}
                  </Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                    </Form.Label>
                  </Col>
                  <Col>{controlMeasure.selectedControlMeasure.ReviewFrequencyName || '-'}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={2}>
                    <Form.Label>
                      <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                    </Form.Label>
                  </Col>
                  <Col>{actionName || '-'}</Col>
                </Row>

                {!controlMeasure.selectedControlMeasure.IsActioned && (
                  <Row className={s.viewRow}>
                    <Col xs={12} lg={2}>
                      <Form.Label>
                        <FormattedMessage id="common.status" defaultMessage="Status" />
                      </Form.Label>
                    </Col>
                    <Col>{controlMeasure.selectedControlMeasure.StatusName || '-'}</Col>
                  </Row>
                )}
              </div>
            </Form>
          </div>
          <div>
            {isItemListingAllowed && (
              <ControlMonitoringListTable
                controlMonitorings={controlMonitoringsObj}
                view={true}
                userProfileWidgets={userProfileWidgets}
                deleteAllowed={false}
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                viewAllowed={isControlMonitoringViewAllowed}
                onViewClick={onViewControlMonitoringClick}
              />
            )}
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(ControlMeasureView);
