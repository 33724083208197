import Cookies from 'js-cookie';
import { apiAction } from '../../utils/index';

import { SITEPATHWAY_ERROR, SITEPATHWAY_IS_LOADING, SET_SELECTED_SITEPATHWAY, SET_SITEPATHWAY } from '../../constants/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setSitePathwayErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setSitePathwayError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setSitePathwayError(messageCode, false));
  }, 2500);
};

export const setSitePathwayError = (messageCode, status) => {
  return {
    type: SITEPATHWAY_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: SITEPATHWAY_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const describeSitePathwayThunk = (siteId, widgetCode, timestamp) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeSitePathway(siteId, widgetCode, timestamp, orgId));
};

export const describeSitePathway = (siteId, widgetCode, timestamp, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/sites/segments`,
    data: {
      siteId: siteId,
      timestamp: timestamp,
      orgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setSitePathwayThunk(retData),
    onFailure: err => setSitePathwayErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setSitePathwayThunk = ({ retData }) => dispatch => {
  let data = retData.data;
  dispatch(setSitePathway(data));
};

export const setSitePathway = data => ({
  type: SET_SITEPATHWAY,
  payload: { sitePathway: data }
});

export const setSitePathwayChangeThunk = retData => dispatch => {
  dispatch(setSitePathwayChange(retData));
};

export const setSitePathwayChange = retData => ({
  type: SET_SELECTED_SITEPATHWAY,
  payload: retData
});
