import React from 'react';
import s from './RiskAssessment.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import tick from '../../assets/tick-blue-filled.svg';

const RiskCategoryMatrixView = ({
  RiskCategory,
  ChangeRiskScore,
  changeRiskCategoryType,
  selectedCategoryColumn,
  isInherentRiskCategory,
  localformErrors
}) => {
  const RiskMatrixData = RiskCategory.RiskMatrix || [];
  return (
    <div className={s.riskCategoryContent}>
      <Row className={s.spacedRow}>
        <div className={s.formCheck}>
          <label className={s.container}>
            <input
              type="radio"
              name="riskType"
              className={localformErrors.Inherent && s.formControlError}
              value={isInherentRiskCategory}
              defaultChecked={true}
              onClick={() => {
                changeRiskCategoryType(true);
              }}
            />
            <span className={s.checkmark}></span>
            <span>
              <FormattedMessage id="riskAssessment.inherentRiskCategory" defaultMessage="Inherent Risk Category" />
            </span>
          </label>
          <label className={s.container}>
            <input
              type="radio"
              name="riskType"
              className={localformErrors.Residual && s.formControlError}
              value={!isInherentRiskCategory}
              defaultChecked={false}
              onClick={() => {
                changeRiskCategoryType(false);
              }}
            />
            <span className={s.checkmark}></span>
            <span>
              <FormattedMessage id="riskAssessment.residualRiskCategory" defaultMessage="Residual Risk Category" />
            </span>
          </label>
        </div>
      </Row>

      <Table variant className={s.innerTable}>
        <thead>
          <tr key="hd1">
            <th rowSpan="2" width="35%">
              <FormattedMessage id="riskCategory.likelihood" defaultMessage="Likelihood" />
            </th>
            <th colSpan="6">
              <FormattedMessage id="riskCategory.consequences" defaultMessage="Severity Descriptors" />
            </th>
          </tr>
          <tr key="hd2">
            <th width="13%">
              <FormattedMessage id="riskCategory.insignificant" defaultMessage="Insignificant" />
              <p>{RiskCategory.InsignificantDescription}</p>
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.minor" defaultMessage="Minor" />
              <p>{RiskCategory.MinorDescription}</p>
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.moderate" defaultMessage="Moderate" />
              <p>{RiskCategory.ModerateDescription}</p>
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.major" defaultMessage="Major" />
              <p>{RiskCategory.MajorDescription}</p>
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.catastropic" defaultMessage="Catastropic" />
              <p>{RiskCategory.CatastrophicDescription}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {RiskMatrixData.map(n => {
            return (
              <MatrixRow
                matrixRow={n}
                riskRating={RiskCategory}
                key={n.RiskLikelihoodId}
                onCellClick={ChangeRiskScore}
                selectedCategoryColumn={selectedCategoryColumn}
              />
            );
          })}
        </tbody>
      </Table>
      <RiskRatingScore scoreRow={RiskCategory} />
    </div>
  );
};

export default injectIntl(RiskCategoryMatrixView);

const getCellColor = (value, riskRating) => {
  if (riskRating.LowRatingScore >= value) {
    return 'tdGreen';
  }
  if (riskRating.MediumRatingScore >= value) {
    return 'tdYellow';
  }
  if (riskRating.HighRatingScore >= value) {
    return 'tdOrange';
  }
  if (riskRating.HighRatingScore < value) {
    return 'tdRed';
  }
};

const MatrixRow = ({ matrixRow, riskRating, selectedCategoryColumn, onCellClick }) => {
  const isColumnSelected = (value, consequence, likelihood) => {
    if (!selectedCategoryColumn || !consequence || !likelihood) return false;
    if (
      selectedCategoryColumn.RiskScore === value &&
      selectedCategoryColumn.Consequence === consequence &&
      selectedCategoryColumn.Likelihood === likelihood
    ) {
      return true;
    }
    return false;
  };

  return (
    <tr>
      <td>
        <div>{matrixRow.RiskLikelihood}</div>
        <div className={s.normalItalicText}>{matrixRow.Description}</div>
      </td>
      <td
        className={s[getCellColor(matrixRow.InsignificantValue, riskRating)]}
        onClick={() => {
          onCellClick(matrixRow.InsignificantValue, 'riskCategory.insignificant', matrixRow.RiskLikelihood, riskRating);
        }}
      >
        {isColumnSelected(matrixRow.InsignificantValue, 'riskCategory.insignificant', matrixRow.RiskLikelihood) && (
          <img src={tick} alt="icon" className={s.blueTick} />
        )}
        <span>{matrixRow.InsignificantValue}</span>
      </td>
      <td
        className={s[getCellColor(matrixRow.MinorValue, riskRating)]}
        onClick={() => {
          onCellClick(matrixRow.MinorValue, 'riskCategory.minor', matrixRow.RiskLikelihood, riskRating);
        }}
      >
        {isColumnSelected(matrixRow.MinorValue, 'riskCategory.minor', matrixRow.RiskLikelihood) && (
          <img src={tick} alt="icon" className={s.blueTick} />
        )}
        <span>{matrixRow.MinorValue}</span>
      </td>
      <td
        className={s[getCellColor(matrixRow.ModerateValue, riskRating)]}
        onClick={() => {
          onCellClick(matrixRow.ModerateValue, 'riskCategory.moderate', matrixRow.RiskLikelihood, riskRating);
        }}
      >
        {isColumnSelected(matrixRow.ModerateValue, 'riskCategory.moderate', matrixRow.RiskLikelihood) && (
          <img src={tick} alt="icon" className={s.blueTick} />
        )}
        <span>{matrixRow.ModerateValue}</span>
      </td>
      <td
        className={s[getCellColor(matrixRow.MajorValue, riskRating)]}
        onClick={() => {
          onCellClick(matrixRow.MajorValue, 'riskCategory.major', matrixRow.RiskLikelihood, riskRating);
        }}
      >
        {isColumnSelected(matrixRow.MajorValue, 'riskCategory.major', matrixRow.RiskLikelihood) && (
          <img src={tick} alt="icon" className={s.blueTick} />
        )}
        <span>{matrixRow.MajorValue}</span>
      </td>
      <td
        className={s[getCellColor(matrixRow.CatastrophicValue, riskRating)]}
        onClick={() => {
          onCellClick(matrixRow.CatastrophicValue, 'riskCategory.catastropic', matrixRow.RiskLikelihood, riskRating);
        }}
      >
        {isColumnSelected(matrixRow.CatastrophicValue, 'riskCategory.catastropic', matrixRow.RiskLikelihood) && (
          <img src={tick} alt="icon" className={s.blueTick} />
        )}
        <span>{matrixRow.CatastrophicValue}</span>
      </td>
    </tr>
  );
};

const RiskRatingScore = ({ scoreRow }) => {
  return (
    <Table variant className={s.innerTable}>
      <tbody>
        <tr className={s.riskScoring}>
          <td width="48%">
            <FormattedMessage id="riskCategory.riskRating" defaultMessage="Risk Rating" />
          </td>
          <td width="13%" className={`${s.tdGreen} ${s.cursorNone}`}>
            <FormattedMessage id="riskCategory.low" defaultMessage="Low" />
            <span className={s.ratingSpace}> {' <= '} </span> <span className={s.ratingSpace}> {scoreRow.LowRatingScore}</span>
          </td>
          <td width="13%" className={`${s.tdYellow} ${s.cursorNone}`}>
            <FormattedMessage id="riskCategory.medium" defaultMessage="Medium" />
            <span className={s.ratingSpace}> {' <= '} </span> <span className={s.ratingSpace}> {scoreRow.MediumRatingScore}</span>
          </td>
          <td width="13%" className={`${s.tdOrange} ${s.cursorNone}`}>
            <FormattedMessage id="riskCategory.high" defaultMessage="High" />
            <span className={s.ratingSpace}> {' <= '} </span> <span className={s.ratingSpace}> {scoreRow.HighRatingScore}</span>
          </td>
          <td width="13%" className={`${s.tdRed} ${s.cursorNone}`}>
            <FormattedMessage id="riskCategory.veryHigh" defaultMessage="Very High" />
            <span className={s.ratingSpace}> {' > '} </span> <span className={s.ratingSpace}> {scoreRow.HighRatingScore}</span>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
