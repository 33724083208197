import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './ImprovementPlan.module.scss';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Activity from '../../WSAControls/Activity/Activity';
import uuidv4 from 'uuid/v4';
import { initialImprovementPlanState } from '../../../store/reducers/initialState';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { compareValues } from '../../../utils';

const ImprovementPlanComments = ({ improvementPlan, saveImprovementPlanComment, UpdateAllowed, currentTimezone }) => {
  const [improvementPlanComment, setImprovementPlanComment] = useState('');

  const commentChangeHandler = e => {
    setImprovementPlanComment(e.target.value);
  };

  const commentSaveHandler = () => {
    if (improvementPlanComment.length > 0) {
      const comment = {
        ImprovementPlanId: improvementPlan.selectedImprovementPlan.ImprovementPlanId,
        GuidIdentifier: uuidv4(),
        Description: improvementPlanComment
      };
      saveImprovementPlanComment(comment);
      setImprovementPlanComment('');
    }
  };

  const commentCancelHandler = () => {
    setImprovementPlanComment('');
  };

  const comments = () => {
    let commentstData = improvementPlan.selectedImprovementPlan.comments || [];

    if (commentstData && commentstData.length > 0) {
      commentstData = commentstData.map(comment => ({
        ...comment,
        ActivityDate: comment.CreatedDateTime,
        CreatedByName: comment.FullName,
        Id: comment.ImprovementPlanCommentId
      }));
    }

    return commentstData.sort(compareValues('ActivityDate', 'desc'));
  };

  const headertext = '';

  return (
    <div className={s.improvementPlanComment}>
      {improvementPlan.isLoading && <LoadingSpinner />}
      <Row className={s.breakrow}>
        <Col sm={12}>
          <h4>
            <FormattedMessage id="common.comments" defaultMessage="Comments" />
            <span data-unittest="hazardDataCount" className={s.circle}>
              {improvementPlan.selectedImprovementPlan.comments.length}
            </span>
          </h4>
        </Col>
      </Row>
      <Row className={s.breakrow}>
        <Col sm={12}>
          <Activity
            description={improvementPlanComment || ''}
            onActivityModified={commentChangeHandler}
            onSaveClick={commentSaveHandler}
            onCancelClick={commentCancelHandler}
            currentTimezone={currentTimezone}
            activities={comments()}
            isUpdateAllowed={UpdateAllowed}
            isViewAllowed={true}
            headingText={headertext}
          />
        </Col>
      </Row>
    </div>
  );
};

ImprovementPlanComments.defaultProps = {
  improvementPlan: {
    ...initialImprovementPlanState
  }
};

ImprovementPlanComments.propTypes = {
  improvementPlan: PropTypes.object.isRequired,
  saveImprovementPlanComment: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  UpdateAllowed: PropTypes.bool.isRequired
};

export default ImprovementPlanComments;
