import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';

import {
  TEAM_ERROR,
  GET_ALL_TEAM_DETAILS,
  TEAM_IS_LOADING,
  SET_TEAM_CURRENT_PAGE,
  SET_TEAM_PAGE_FILTER,
  SET_TEAM_CHANGE,
  SET_TEAM,
  UPDATE_TEAM_SAVE_STATUS,
  SET_TEAM_FILTERS,
  SET_TEAM_RESULTS,
  REMOVE_TEAM
} from '../../../constants/index';

import { TEAM_MANAGEMENT, CREATE_TEAM, UPDATE_TEAM } from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setTeamErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setTeamError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTeamError(messageCode, false));
  }, 2500);
};

export const setTeamError = (messageCode, status) => {
  return {
    type: TEAM_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: TEAM_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getTeamThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getTeam(widgetCode, orgId, filterObj));
};

export const getTeam = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp/team`,
    method: 'GET',
    data: {
      OrgId: orgId,
      WaterQualitySafetyPlanId: filterObj.waterQualitySafetyPlanId,
      filterName: filterObj.filterName,
      filterCompany: filterObj.filterCompany,
      filterRole: filterObj.filterRole,
      limit: filterObj.limit,
      offset: filterObj.offset,
      SiteId: filterObj.siteId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllTeamListThunk(retData),
    onFailure: err => setTeamErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllTeamListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllTeamList(messageCode, data));
};

export const setAllTeamList = (messageCode, data) => ({
  type: GET_ALL_TEAM_DETAILS,
  payload: {
    data: {
      team: data,
      filteredResults: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_TEAM_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_TEAM_PAGE_FILTER,
  payload: { filter }
});

export const describeTeamThunk = (teamId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeTeam(teamId, widgetCode, orgId));
};

export const describeTeam = (teamId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp/team`,
    data: {
      TeamId: teamId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTeamThunk(retData, teamId),
    onFailure: err => setTeamErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setTeamThunk = ({ retData }, teamId) => dispatch => {
  let data = retData.data;
  if (teamId && (!data || data.length === 0 || parseInt(teamId) !== data[0].TeamId)) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setTeam(data));
};

export const setTeam = data => ({
  type: SET_TEAM,
  payload: data[0]
});

export const saveTeamThunk = (data, widgetCode) => dispatch => {
  let url = '/waterqualitysafetyplans/wqsp/team/save';
  let actionName = data.TeamId === 0 ? CREATE_TEAM : UPDATE_TEAM;
  let log = logEntry(TEAM_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveTeam(data, url, log, widgetCode));
};

export const saveTeam = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTeamStatusThunk(retData, data),
    onFailure: err => setTeamErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setTeamStatusThunk = (message, data) => dispatch => {
  dispatch(setTeamStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTeamStatus(message, false, true));
    if (data.PerformDelete) {
      dispatch(removeEmergencyResponsePlan(data.TeamId));
    }
  }, 2500);
};

export const setTeamStatus = (messageCode, status, redirect) => ({
  type: UPDATE_TEAM_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: status,
      showBanner: status,
      isLoading: false,
      isRedirect: redirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const removeEmergencyResponsePlan = data => ({
  type: REMOVE_TEAM,
  payload: {
    teamId: data
  }
});

export const setTeamChangeThunk = retData => dispatch => {
  dispatch(setTeamChange(retData));
};

export const setTeamChange = retData => ({
  type: SET_TEAM_CHANGE,
  payload: retData
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_TEAM_RESULTS,
  payload: retData
});

export const getFilterThunk = (widgetCode, waterQualitySafetyPlanId) => dispatch => {
  dispatch(getFilter(widgetCode, waterQualitySafetyPlanId));
};

export const getFilter = (widgetCode, waterQualitySafetyPlanId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      WaterQualitySafetyPlanId: waterQualitySafetyPlanId,
      filterFor: 'personnelListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setTeamErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_TEAM_FILTERS,
  payload: {
    data: {
      isLoading: false,
      teamListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});
