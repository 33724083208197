import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';

import {
  INSPECTION_TYPE_QUESTION_ERROR,
  GET_ALL_INSPECTION_TYPE_QUESTION_DETAILS,
  INSPECTION_TYPE_QUESTION_IS_LOADING,
  SET_INSPECTION_TYPE_QUESTION_CURRENT_PAGE,
  SET_INSPECTION_TYPE_QUESTION_PAGE_FILTER,
  SET_INSPECTION_TYPE_QUESTION_CHANGE,
  SET_INSPECTION_TYPE_QUESTION,
  SET_INSPECTION_TYPE_QUESTIONS,
  SET_INSPECTION_TYPE_QUESTION_CLEANUP,
  UPDATE_INSPECTION_TYPE_QUESTION_SAVE_STATUS
} from '../../constants/index';

import {
  INSPECTION_TYPE_QUESTION_MANAGEMENT,
  CREATE_INSPECTION_TYPE_QUESTION,
  UPDATE_INSPECTION_TYPE_QUESTION
} from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setInspectionTypeQuestionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setInspectionTypeQuestionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setInspectionTypeQuestionError(messageCode, false));
  }, 2500);
};

export const setInspectionTypeQuestionError = (messageCode, status) => {
  return {
    type: INSPECTION_TYPE_QUESTION_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: INSPECTION_TYPE_QUESTION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getInspectionTypeQuestionThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getInspectionTypeQuestion(widgetCode, orgId));
};

export const getInspectionTypeQuestion = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/inspection/inspectiontypequestion`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllInspectionTypeQuestionListThunk(retData),
    onFailure: err => setInspectionTypeQuestionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllInspectionTypeQuestionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  let inspectionTypes = (retData && retData.retData.inspectionTypes) || [];
  dispatch(setAllInspectionTypeQuestionList(messageCode, data, inspectionTypes));
};

export const setAllInspectionTypeQuestionList = (messageCode, data, inspectionTypes) => ({
  type: GET_ALL_INSPECTION_TYPE_QUESTION_DETAILS,
  payload: {
    data: {
      InspectionTypeQuestions: data,
      InspectionTypes: inspectionTypes,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_INSPECTION_TYPE_QUESTION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_INSPECTION_TYPE_QUESTION_PAGE_FILTER,
  payload: { filter }
});

export const describeInspectionTypeQuestionThunk = (InspectionTypeQuestionId, widgetCode, InspectionTypeId = null) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeInspectionTypeQuestion(InspectionTypeQuestionId, widgetCode, orgId, InspectionTypeId));
};

export const describeInspectionTypeQuestion = (InspectionTypeQuestionId, widgetCode, orgId, InspectionTypeId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/inspection/inspectiontypequestion`,
    data: {
      InspectionTypeQuestionId: InspectionTypeQuestionId,
      OrgId: orgId,
      InspectionTypeId: InspectionTypeId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setInspectionTypeQuestionThunk(retData, InspectionTypeId, InspectionTypeQuestionId),
    onFailure: err => setInspectionTypeQuestionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setInspectionTypeQuestionThunk = (retData, InspectionTypeId, InspectionTypeQuestionId) => dispatch => {
  let data = retData.retData.data || [];
  let inspectionTypes = retData.retData.inspectionTypes || [];
  if (InspectionTypeId) {
    dispatch(setInspectionTypeQuestions(data, inspectionTypes));
  } else {
    if (
      InspectionTypeQuestionId &&
      (!data || data.length === 0 || data[0].InspectionTypeQuestionId !== parseInt(InspectionTypeQuestionId))
    ) {
      redirectToPageNotFound(dispatch);
    }
    dispatch(setInspectionTypeQuestion(data, inspectionTypes));
  }
};

export const setInspectionTypeQuestion = (data, inspectionTypes) => ({
  type: SET_INSPECTION_TYPE_QUESTION,
  payload: {
    data: data,
    inspectionTypes: inspectionTypes
  }
});

export const setInspectionTypeQuestions = (data, inspectionTypes) => ({
  type: SET_INSPECTION_TYPE_QUESTIONS,
  payload: {
    data: data,
    inspectionTypes: inspectionTypes
  }
});

export const saveInspectionTypeQuestionThunk = (data, widgetCode) => dispatch => {
  let url = '/management/inspection/inspectiontypequestion/save';
  let actionName = data.InspectionTypeQuestionId === 0 ? CREATE_INSPECTION_TYPE_QUESTION : UPDATE_INSPECTION_TYPE_QUESTION;
  let log = logEntry(INSPECTION_TYPE_QUESTION_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveInspectionTypeQuestion(data, url, log, widgetCode));
};

export const saveInspectionTypeQuestion = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setInspectionTypeQuestionStatusThunk(retData),
    onFailure: err => setInspectionTypeQuestionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setInspectionTypeQuestionStatusThunk = message => dispatch => {
  dispatch(setInspectionTypeQuestionStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setInspectionTypeQuestionStatus(message, false));
  }, 2500);
};

export const setInspectionTypeQuestionStatus = (messageCode, status) => ({
  type: UPDATE_INSPECTION_TYPE_QUESTION_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: true,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setInspectionTypeQuestionChangeThunk = retData => dispatch => {
  dispatch(setInspectionTypeQuestionChange(retData));
};

export const setInspectionTypeQuestionChange = retData => ({
  type: SET_INSPECTION_TYPE_QUESTION_CHANGE,
  payload: retData
});

export const setInspectionTypeQuestionCleanUpThunk = SelectedInspectionTypeQuestion => dispatch => {
  dispatch({ type: SET_INSPECTION_TYPE_QUESTION_CLEANUP, payload: SelectedInspectionTypeQuestion });
};
