import { initialSitePathwayState } from './initialState';

import { SITEPATHWAY_ERROR, SITEPATHWAY_IS_LOADING, SET_SELECTED_SITEPATHWAY, SET_SITEPATHWAY } from '../../constants/index';

const sitePathwayReducer = (state = initialSitePathwayState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SITEPATHWAY_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        sitePathway: {
          ...state.sitePathway,
          isLoading: isLoading
        }
      };
    }

    case SITEPATHWAY_ERROR: {
      const { data } = payload;
      return {
        ...state,
        sitePathway: {
          ...state.sitePathway,
          ...data
        }
      };
    }

    case SET_SITEPATHWAY: {
      let { sitePathway } = payload;

      return {
        ...state,
        sitePathway: {
          ...state.sitePathway,
          ...sitePathway,
          build: {
            ...state.sitePathway.build,
            ...sitePathway.build
          },
          plan: {
            ...state.sitePathway.plan,
            ...sitePathway.plan
          },
          define: {
            ...state.sitePathway.define,
            ...sitePathway.define
          },
          identify: {
            ...state.sitePathway.identify,
            ...sitePathway.identify
          },
          control: {
            ...state.sitePathway.control,
            ...sitePathway.control
          },
          monitor: {
            ...state.sitePathway.monitor,
            ...sitePathway.monitor
          },
          measure: {
            ...state.sitePathway.measure,
            ...sitePathway.measure
          },
          respond: {
            ...state.sitePathway.respond,
            ...sitePathway.respond
          },
          selectedSitePathway: null,
          isOpSuccessful: false,
          showBanner: false
        }
      };
    }

    case SET_SELECTED_SITEPATHWAY: {
      let { selectedSitePathway } = payload;

      return {
        ...state,
        sitePathway: {
          ...state.sitePathway,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedSitePathway: {
            ...selectedSitePathway
          }
        }
      };
    }

    default:
      return state;
  }
};

export default sitePathwayReducer;
