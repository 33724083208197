import { initialStakeholderRegisterState } from '../initialState';

import {
  STAKEHOLDER_REGISTER_ERROR,
  GET_ALL_STAKEHOLDER_REGISTER_DETAILS,
  STAKEHOLDER_REGISTER_IS_LOADING,
  SET_STAKEHOLDER_REGISTER_CURRENT_PAGE,
  SET_STAKEHOLDER_REGISTER_PAGE_FILTER,
  SET_STAKEHOLDER_REGISTER_CHANGE,
  SET_STAKEHOLDER_REGISTER,
  UPDATE_STAKEHOLDER_REGISTER_SAVE_STATUS,
  SET_STAKEHOLDER_REGISTER_FILTERS,
  SET_STAKEHOLDER_REGISTER_RESULTS,
  REMOVE_STAKEHOLDER_REGISTER
} from '../../../constants/index';

const stakeholderRegisterReducer = (state = initialStakeholderRegisterState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_STAKEHOLDER_REGISTER_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          ...data
        }
      };
    }

    case SET_STAKEHOLDER_REGISTER_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          currentPage
        }
      };
    }

    case SET_STAKEHOLDER_REGISTER_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_STAKEHOLDER_REGISTER_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          ...data
        }
      };
    }

    case STAKEHOLDER_REGISTER_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          isLoading: isLoading
        }
      };
    }

    case STAKEHOLDER_REGISTER_ERROR: {
      const { data } = payload;
      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          ...data
        }
      };
    }

    case SET_STAKEHOLDER_REGISTER: {
      let data = payload;

      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          isOpSuccessful: false,
          showBanner: false,
          selectedStakeholderRegister: {
            ...state.stakeholderRegister.selectedStakeholderRegister,
            ...data
          }
        }
      };
    }

    case REMOVE_STAKEHOLDER_REGISTER: {
      const { stakeholderRegisterId } = payload;
      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          isOpSuccessful: false,
          isRedirect: false,
          stakeholderRegisters: [
            ...state.stakeholderRegister.stakeholderRegisters.filter(item => item.StakeholderRegisterId !== stakeholderRegisterId)
          ],
          filteredResults: [
            ...state.stakeholderRegister.filteredResults.filter(item => item.StakeholderRegisterId !== stakeholderRegisterId)
          ],
          stakeholderRegisterListCount: state.stakeholderRegister.stakeholderRegisterListCount - 1
        }
      };
    }

    case SET_STAKEHOLDER_REGISTER_CHANGE: {
      let { selectedStakeholderRegister } = payload;

      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          isOpSuccessful: false,
          showBanner: false,
          selectedStakeholderRegister: {
            ...state.stakeholderRegister.selectedStakeholderRegister,
            ...selectedStakeholderRegister
          }
        }
      };
    }

    case SET_STAKEHOLDER_REGISTER_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          ...data
        }
      };
    }

    case SET_STAKEHOLDER_REGISTER_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        stakeholderRegister: {
          ...state.stakeholderRegister,
          ...filteredResults
        }
      };
    }

    default:
      return state;
  }
};

export default stakeholderRegisterReducer;
