import { initialWaterSupplyDescriptionState } from './initialState';

import {
  WATERSUPPLYDESCRIPTION_ERROR,
  GET_ALL_WATERSUPPLYDESCRIPTION_DETAILS,
  WATERSUPPLYDESCRIPTION_IS_LOADING,
  SET_WATERSUPPLYDESCRIPTION_CURRENT_PAGE,
  SET_WATERSUPPLYDESCRIPTION_PAGE_FILTER,
  SET_WATERSUPPLYDESCRIPTION_CHANGE,
  SET_WATERSUPPLYDESCRIPTION,
  UPDATE_WATERSUPPLYDESCRIPTION_SAVE_STATUS,
  SET_WATERSUPPLYDESCRIPTION_RESULTS,
  SET_WATERSUPPLYDESCRIPTION_FILTERS
} from '../../constants/index';

const waterSupplyDescriptionReducer = (state = initialWaterSupplyDescriptionState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_WATERSUPPLYDESCRIPTION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          ...data
        }
      };
    }

    case SET_WATERSUPPLYDESCRIPTION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          currentPage
        }
      };
    }

    case SET_WATERSUPPLYDESCRIPTION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_WATERSUPPLYDESCRIPTION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          ...data
        }
      };
    }

    case WATERSUPPLYDESCRIPTION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          isLoading: isLoading
        }
      };
    }

    case WATERSUPPLYDESCRIPTION_ERROR: {
      const { data } = payload;
      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          ...data
        }
      };
    }

    case SET_WATERSUPPLYDESCRIPTION: {
      let { selectedWaterSupplyDescription } = payload;

      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          isOpSuccessful: false,
          showBanner: false,
          selectedWaterSupplyDescription: {
            ...state.waterSupplyDescription.selectedWaterSupplyDescription,
            ...selectedWaterSupplyDescription
          }
        }
      };
    }

    case SET_WATERSUPPLYDESCRIPTION_CHANGE: {
      let { selectedWaterSupplyDescription } = payload;

      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedWaterSupplyDescription: {
            ...state.waterSupplyDescription.selectedWaterSupplyDescription,
            ...selectedWaterSupplyDescription
          }
        }
      };
    }

    case SET_WATERSUPPLYDESCRIPTION_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          ...filteredResults
        }
      };
    }

    case SET_WATERSUPPLYDESCRIPTION_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        waterSupplyDescription: {
          ...state.waterSupplyDescription,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default waterSupplyDescriptionReducer;
