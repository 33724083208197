import React from 'react';
import s from './StakeholderRegister.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';

import Button from 'react-bootstrap/Button';

const StakeholderRegisterListTable = ({
  paginatedData,
  ChangeStakeholderRegister,
  RemoveStakeholderRegister,
  UpdateAllowed,
  DeleteAllowed,
  intl
}) => {
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />{' '}
            </th>
            <th>
              <FormattedMessage id="personnel.company" defaultMessage="Company" />
            </th>
            <th>
              <FormattedMessage id="personnel.jobTitle" defaultMessage="Job Title" />
            </th>
            <th>
              <FormattedMessage id="common.phone" defaultMessage="Phone" />
            </th>
            <th>
              <FormattedMessage id="stakeholderRegister.issuesRelationship" defaultMessage="Issues Relationship" />
            </th>
            {(UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.StakeholderRegisterId}>
                <td>{n.Name || ''}</td>
                <td>{n.Company || ''}</td>
                <td>{n.JobTitle || ''}</td>
                <td>{n.Phone || ''}</td>
                <td className={s.normalItalicText}>{n.IssuesRelationship || ''}</td>
                <td className={s.btnCol}>
                  {DeleteAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        RemoveStakeholderRegister(n.StakeholderRegisterId);
                      }}
                      data-unittest="removeData"
                    >
                      <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                    </Button>
                  )}
                  {UpdateAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        ChangeStakeholderRegister(n.StakeholderRegisterId);
                      }}
                      data-unittest="changeData"
                    >
                      <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

StakeholderRegisterListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(StakeholderRegisterListTable);
