import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './TaskTemplate.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialTaskTemplatesState } from '../../store/reducers//initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Redirect, Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import { getQuerystring } from '../../utils';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const TaskTemplate = ({
  saveTaskTemplate,
  describeTaskTemplate,
  taskTemplateManager,
  changeTaskTemplate,
  profile,
  getProfileInfo,
  userProfileWidgets,
  intl
}) => {
  let IsEdit = false;
  let heading = '';
  const [localformErrors, setFormErrors] = useState({});

  let { TaskTemplateId } = useParams();
  let checklistTemplateId = getQuerystring('checklistTemplateId') || 0;
  checklistTemplateId = parseInt(checklistTemplateId);

  let fromView = getQuerystring('view') || 0;
  fromView = parseInt(fromView);
  let widgetCode = '';

  if (TaskTemplateId) {
    IsEdit = true;
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' });
    widgetCode = 'WQSP_TASKTEMPLATEEDIT';
  } else {
    heading = intl.formatMessage({ id: 'taskTemplateManagement.newTaskTemplate', defaultMessage: 'New Task Template' });
    widgetCode = 'WQSP_TASKTEMPLATEADDNEW';
  }

  useEffect(() => {
    if (TaskTemplateId) {
      describeTaskTemplate(TaskTemplateId, widgetCode);
    } else {
      setLocalTaskTemplate({
        ...taskTemplateManager,
        SelectedTaskTemplate: { ...initialTaskTemplatesState.taskTemplate.SelectedTaskTemplate }
      });
    }
  }, [describeTaskTemplate, TaskTemplateId]);

  useEffect(() => {
    getProfileInfo(widgetCode);
  }, [getProfileInfo, widgetCode]);

  const profilesObj = (profile.profiles && Object.values(profile.profiles.filter(p => p.IsActive && !p.IsDeleted))) || [];

  // Update redux store
  const setLocalTaskTemplate = currentState => {
    changeTaskTemplate(currentState);
  };
  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = taskTemplateManager.SelectedTaskTemplate;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.TaskTemplateId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    saveTaskTemplate(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !taskTemplateManager ||
      !taskTemplateManager.SelectedTaskTemplate ||
      !taskTemplateManager.SelectedTaskTemplate.Name ||
      isRequired(taskTemplateManager.SelectedTaskTemplate.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'taskTemplateManagement.nameMandatory',
        defaultMessage: 'Task Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalTaskTemplate({
        ...taskTemplateManager,
        SelectedTaskTemplate: { ...taskTemplateManager.SelectedTaskTemplate, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalTaskTemplate({
        ...taskTemplateManager,
        SelectedTaskTemplate: { ...taskTemplateManager.SelectedTaskTemplate, [e.target.name]: e.target.value }
      });
    }
  };

  const profileOptions = () => {
    let profileData = [];
    profilesObj.forEach(element => {
      profileData.push({
        ...element,
        label: element.Name,
        value: element.ProfileId
      });
    });
    return profileData.sort(compareValues('label'));
  };

  const onDropdownChange = e => {
    setLocalTaskTemplate({
      ...taskTemplateManager,
      SelectedTaskTemplate: { ...taskTemplateManager.SelectedTaskTemplate, AssignedToProfileId: e.value }
    });
  };

  let messageId = (taskTemplateManager && taskTemplateManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const taskName = (taskTemplateManager.SelectedTaskTemplate ? taskTemplateManager.SelectedTaskTemplate.Name : '') || '';

  let backLink = !IsEdit ? '/operational-setup/checklist-task-templates' : `/operational-setup/task-template-detail/${TaskTemplateId}`;

  if (checklistTemplateId) {
    if (TaskTemplateId) {
      backLink = `/operational-setup/task-template-detail/${TaskTemplateId}?checklistTemplateId=${checklistTemplateId}${
        fromView ? '&view=1' : ''
      }`;
    } else {
      backLink = `/operational-setup/checklist-template/${checklistTemplateId}`;
    }
  }

  return (
    <div className={s.taskTemplate}>
      {taskTemplateManager.isLoading && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={taskTemplateManager.showBanner}
        status={taskTemplateManager.isOpSuccessful}
        successText={messageText}
      />
      {taskTemplateManager.isOpSuccessful && !taskTemplateManager.showBanner ? <Redirect to={backLink} /> : ''}
      <OperationalSetupContentTemplate selectedPage="checklistTaskTemplate" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.tasktemplateHeader}>
            <Link className={s.backLink} to={backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </Link>
            <h3 data-unittest="headingLabel">{!IsEdit ? heading : heading + ' ' + taskName}</h3>
          </div>
          <div className={s.taskTemplateContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTaskTemplateName">
                    <Form.Label>
                      <FormattedMessage id="common.task" defaultMessage="Task" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={taskTemplateManager.SelectedTaskTemplate.Name}
                      placeholder={intl.formatMessage({
                        id: 'taskTemplateManagement.enterTask',
                        defaultMessage: 'Enter task'
                      })}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTaskTemplateDescription">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.description" defaultMessage="Description / Objective" />
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'taskTemplateManagement.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={taskTemplateManager.SelectedTaskTemplate.Description}
                      className={s.textArea}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTaskTemplateIsERP">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsERP',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'wqsp.common.emergencyResponsePlan',
                            defaultMessage: 'Emergency Response Plan'
                          }),
                          isChecked: taskTemplateManager.SelectedTaskTemplate.IsERP
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTaskTemplateRole">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.role" defaultMessage="Role" />
                    </Form.Label>

                    <Dropdown
                      id="formTaskTemplateRole"
                      dataArray={profileOptions()}
                      controlData={{ placeholderText: 'Select Role' }}
                      onDropdownChange={onDropdownChange}
                      selectedOption={profileOptions().filter(
                        option => option.value === taskTemplateManager.SelectedTaskTemplate.AssignedToProfileId
                      )}
                      data-unittest="formRole"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formTaskTemplateEffort">
                    <div>
                      <Form.Label>
                        <FormattedMessage id="taskTemplateManagement.estimatedEffort" defaultMessage="Estimated effort" />
                        <br />
                      </Form.Label>
                    </div>
                    <div className="float-left">
                      <InputNumber
                        name="EstimatedEffort"
                        step="1"
                        onInputChange={onChange}
                        value={taskTemplateManager.SelectedTaskTemplate.EstimatedEffort}
                        id="formTaskTemplateEffort"
                        placeholder="Enter number"
                      />
                    </div>
                    <div className={s.hours}>hours</div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTaskTemplateActivities">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.activities" defaultMessage="Activities" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Activities"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'taskTemplateManagement.enterActivities',
                        defaultMessage: 'Enter activities'
                      })}
                      value={taskTemplateManager.SelectedTaskTemplate.Activities}
                      className={s.textArea}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="common.saveChanges" defaultMessage="SAVE CHANGES" />
                </Button>
                <Link to={backLink}>
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

TaskTemplate.defaultProps = {
  taskTemplateManager: {
    ...initialTaskTemplatesState.taskTemplate
  },
  profile: { profiles: [] }
};

TaskTemplate.propTypes = {
  saveTaskTemplate: PropTypes.func.isRequired,
  taskTemplateManager: PropTypes.object.isRequired,
  describeTaskTemplate: PropTypes.func.isRequired,
  changeTaskTemplate: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  getProfileInfo: PropTypes.func.isRequired,
  userProfileWidgets: PropTypes.object.isRequired
};

export default injectIntl(TaskTemplate);
