import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './HazardEventLibrary.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialHazardEventLibraryState } from '../../store/reducers/initialState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Link, useParams, useHistory } from 'react-router-dom';
import HazardEventTemplateItems from './HazardEventLibraryItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import classNames from 'classnames';
import { HAZARD_CLASS } from '../../constants/index';
import uuid from 'uuid';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { getUserProfileWidget, getHazardEventLibrary, getGeneralData, getHazard } from '../../selectors/index';
import { describeHazardEventTemplateThunk, setHazardEventTemplateChangeThunk } from '../../store/actions/hazardEventLibrary';
import { getHazardThunk } from '../../store/actions/hazards';

const HazardEventLibrary = ({ intl }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hazardEventTemplate = useSelector(state => getHazardEventLibrary(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const generalData = useSelector(state => getGeneralData(state));
  const hazards = useSelector(state => getHazard(state));

  let { hazardEventLibraryId } = useParams();
  const [searchKeywords, setSearchKeywords] = useState('');

  if (!IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENTTEMPLATE_VIEW')) {
    history.push('/unauth');
  }

  let widgetCode = 'WQSP_HAZARDEVENTTEMPLATE_VIEW';

  useEffect(() => {
    setLocalHazardEventTemplate({
      SelectedHazardEventTemplate: {
        ...initialHazardEventLibraryState.hazardEventTemplate.SelectedHazardEventTemplate,
        SelectedHazardEventTemplateItems: []
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getHazardThunk('WQSP_HAZARDS_LIST', {}));
  }, [getHazardThunk]);

  useEffect(() => {
    dispatch(describeHazardEventTemplateThunk(hazardEventLibraryId, widgetCode));
  }, [describeHazardEventTemplateThunk, hazardEventLibraryId]);

  const hazardClasses = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_CLASS);

  const setLocalHazardEventTemplate = currentState => {
    dispatch(setHazardEventTemplateChangeThunk(currentState));
  };

  const onItemSearch = keyWords => {
    setSearchKeywords(keyWords);
  };

  const getSelectedHazardEventTemplateItems = () => {
    return hazardEventTemplate.SelectedHazardEventTemplate.SelectedHazardEventTemplateItems.filter(
      item => item.Name.toLowerCase().indexOf(searchKeywords.toLowerCase()) >= 0
    );
  };

  let messageId = (hazardEventTemplate && hazardEventTemplate.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const hazardEventName =
    (hazardEventTemplate.SelectedHazardEventTemplate ? hazardEventTemplate.SelectedHazardEventTemplate.Name : '') || '';
  let heading =
    hazardEventName + ' ' + intl.formatMessage({ id: 'hazardEventLibrary.hazardEventTemplate', defaultMessage: 'Hazard Event Template' });

  const backLink = '/operational-setup/hazards';

  return (
    <div className={s.hazardEventTemplate}>
      {hazardEventTemplate.isLoading && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={hazardEventTemplate.showBanner}
        status={hazardEventTemplate.isOpSuccessful}
        successText={messageText}
      />

      <OperationalSetupContentTemplate selectedPage="hazards" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.hazardEventTemplateHeader}>
            <Link className={s.backLink} to={backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </Link>
            <Row>
              <Col>
                <h3 data-unittest="headingLabel">{heading}</h3>
              </Col>
              <Col className={s.colChangeButton}>
                <Link
                  to={`/operational-setup/hazard-event-template/${hazardEventLibraryId}`}
                  style={WidgetVisibility(userProfileWidgets, `WQSP_HAZARDEVENTTEMPLATE_EDIT`)}
                >
                  <Button variant="primary" data-unittest="saveData" className={classNames(s.margin5, s.btnSaveChanges)} noValidate>
                    <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
          <div className={s.hazardEventTemplateContent}>
            <h4>
              <FormattedMessage id="common.details" defaultMessage="Details" />
            </h4>

            {/* VIEW SCREEN START */}

            <Form>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.name" defaultMessage="Name" />
                  </Form.Label>
                </Col>
                <Col>{hazardEventTemplate.SelectedHazardEventTemplate.Name}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formHazardEventEntityType">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{hazardEventTemplate.SelectedHazardEventTemplate.EntityTypeName}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formHazardEventDescription">
                    <Form.Label>
                      <FormattedMessage id="common.description" defaultMessage="Description" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{hazardEventTemplate.SelectedHazardEventTemplate.Description}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formHazardEventBasis">
                    <Form.Label>
                      <FormattedMessage id="hazardEventLibrary.basis" defaultMessage="Basis" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>{hazardEventTemplate.SelectedHazardEventTemplate.Basis}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formHazardEventHazardClass">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.hazardClass" defaultMessage="Hazard Class" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col>
                  {hazardEventTemplate.SelectedHazardEventTemplate.HazardClass.split(',')
                    .map(id => {
                      return hazardClasses.find(item => item.GeneralFieldTypeId === parseInt(id))?.Name;
                    })
                    .join(', ')}
                </Col>
              </Row>
            </Form>
            {/* VIEW SCREEN END */}
          </div>
          <div>
            <HazardEventTemplateItems
              onItemSearch={onItemSearch}
              selectedItems={getSelectedHazardEventTemplateItems() || []}
              hazards={hazards}
              isView={true}
              hazardClasses={hazardClasses}
            />
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

HazardEventLibrary.defaultProps = {
  hazardEventTemplate: {
    ...initialHazardEventLibraryState.hazardEventTemplate
  }
};

export default injectIntl(HazardEventLibrary);
