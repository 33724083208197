import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';

import {
  INSPECTION_PURPOSE_ERROR,
  GET_ALL_INSPECTION_PURPOSE_DETAILS,
  INSPECTION_PURPOSE_IS_LOADING,
  SET_INSPECTION_PURPOSE_CURRENT_PAGE,
  SET_INSPECTION_PURPOSE_PAGE_FILTER,
  SET_INSPECTION_PURPOSE_CHANGE,
  SET_INSPECTION_PURPOSE,
  SET_INSPECTION_PURPOSE_CLEANUP,
  UPDATE_INSPECTION_PURPOSE_SAVE_STATUS
} from '../../constants/index';

import { INSPECTION_PURPOSE_MANAGEMENT, CREATE_INSPECTION_PURPOSE, UPDATE_INSPECTION_PURPOSE } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setInspectionPurposeErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setInspectionPurposeError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setInspectionPurposeError(messageCode, false));
  }, 2500);
};

export const setInspectionPurposeError = (messageCode, status) => {
  return {
    type: INSPECTION_PURPOSE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: INSPECTION_PURPOSE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getInspectionPurposeThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getInspectionPurpose(widgetCode, orgId));
};

export const getInspectionPurpose = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/inspection/inspectionpurpose`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllInspectionPurposeListThunk(retData),
    onFailure: err => setInspectionPurposeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllInspectionPurposeListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllInspectionPurposeList(messageCode, data));
};

export const setAllInspectionPurposeList = (messageCode, data) => ({
  type: GET_ALL_INSPECTION_PURPOSE_DETAILS,
  payload: {
    data: {
      InspectionPurposes: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_INSPECTION_PURPOSE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_INSPECTION_PURPOSE_PAGE_FILTER,
  payload: { filter }
});

export const describeInspectionPurposeThunk = (InspectionPurposeId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeInspectionPurpose(InspectionPurposeId, widgetCode, orgId));
};

export const describeInspectionPurpose = (InspectionPurposeId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/inspection/inspectionpurpose`,
    data: {
      InspectionPurposeId: InspectionPurposeId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setInspectionPurposeThunk(retData),
    onFailure: err => setInspectionPurposeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setInspectionPurposeThunk = ({ retData, InspectionPurposeId }) => dispatch => {
  let data = retData.data;
  if (!data || data.length === 0 || (InspectionPurposeId && data[0].InspectionPurposeId !== InspectionPurposeId)) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setInspectionPurpose(data));
};

export const setInspectionPurpose = data => ({
  type: SET_INSPECTION_PURPOSE,
  payload: data[0]
});

export const saveInspectionPurposeThunk = (data, widgetCode) => dispatch => {
  let url = '/management/inspection/inspectionpurpose/save';
  let actionName = data.InspectionPurposeId === 0 ? CREATE_INSPECTION_PURPOSE : UPDATE_INSPECTION_PURPOSE;
  let log = logEntry(INSPECTION_PURPOSE_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveInspectionPurpose(data, url, log, widgetCode));
};

export const saveInspectionPurpose = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setInspectionPurposeStatusThunk(retData),
    onFailure: err => setInspectionPurposeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setInspectionPurposeStatusThunk = message => dispatch => {
  dispatch(setInspectionPurposeStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setInspectionPurposeStatus(message, false));
  }, 2500);
};

export const setInspectionPurposeStatus = (messageCode, status) => ({
  type: UPDATE_INSPECTION_PURPOSE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: true,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setInspectionPurposeChangeThunk = retData => dispatch => {
  dispatch(setInspectionPurposeChange(retData));
};

export const setInspectionPurposeChange = retData => ({
  type: SET_INSPECTION_PURPOSE_CHANGE,
  payload: retData
});

export const setInspectionPurposeCleanUpThunk = SelectedInspectionPurpose => dispatch => {
  dispatch({ type: SET_INSPECTION_PURPOSE_CLEANUP, payload: SelectedInspectionPurpose });
};
