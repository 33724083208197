import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './IncidentReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialIncidentReportsState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { useHistory, Link, useParams, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { compareValues, isRequired, isValidEmail, goBack, getQuerystring } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import 'react-datetime/css/react-datetime.css';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';
import PhoneInput from 'react-phone-input-2';
import { getGeneralData, getIncidentReports, getCurrentTimezone, getPersonnel, getUserProfileWidget } from '../../selectors/index';
import { describeIncidentReportsThunk, saveIncidentReportsThunk, setIncidentReportsChangeThunk } from '../../store/actions/incidentReport';
import { getPersonnelThunk } from '../../store/actions/admin/personnel';
import {
  INCIDENT_CATEGORY,
  INCIDENT_CONSEQUENCE,
  GENERAL_STATUS,
  DOCUMENT_TYPE_INSPECTION_REPORT,
  DOCUMENT_TYPE_HAZARD_EVENT,
  DOCUMENT_TYPE_TEST_RESULT,
  INCIDENT_DOCUMENT_TYPES
} from '../../constants/index';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import GoBack from '../WSAControls/GoBack/GoBack';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const IncidentReport = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const backUrl = '/incident-reports';
  let { incidentReportId, siteId, segmentName, selectedTab } = useParams();

  let sourceName = '';
  let docType = (location.state && location.state.docType) || '';
  let referedId = (location.state && location.state.referedId) || 0;
  let waterSupplyDescriptionId = (location.state && location.state.obj && location.state.obj.WaterSupplyDescriptionId) || 0;
  let entityTypeId = (location.state && location.state.obj && location.state.obj.EntityTypeId) || 0;
  let entityId = (location.state && location.state.obj && location.state.obj.EntityId) || 0;

  siteId = (siteId && parseInt(siteId)) || '0';
  const [localformErrors, setFormErrors] = useState({});
  const [disableMode, setDisableMode] = useState(false);
  const [showNewPersonReporting, setNewPersonReporting] = useState(false);

  const [fileUploadStarted, setFileUploadStarted] = useState(false);
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const incidentReport = useSelector(state => getIncidentReports(state));
  const personnel = useSelector(state => getPersonnel(state));
  const generalData = useSelector(state => getGeneralData(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);
  const currentTimestamp = moment().tz(currentTimezone);
  const disableFutureDates = current => current.isBefore(currentTimestamp);

  const ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  let offset = personnel.offset || 0;
  let limit = personnel.limit || 1000;

  if (incidentReportId && incidentReportId > 0) {
    IsEdit = true;
    sourceName = incidentReport.selectedIncidentReport.SourceName ? ' - ' + incidentReport.selectedIncidentReport.SourceName : '';
  } else {
    incidentReportId = 0;
  }
  let widgetCode = IsEdit ? 'WQSP_INCIDENT_REPORT_EDIT' : 'WQSP_INCIDENT_REPORT_ADDNEW';
  let incidentSourcefrom = '';
  let incidentSourceLink = '';

  if (incidentReport.selectedIncidentReport.SourceOfIncident && incidentReport.selectedIncidentReport.SourceLinkId) {
    if (incidentReport.selectedIncidentReport.SourceOfIncident === DOCUMENT_TYPE_INSPECTION_REPORT) {
      if (location.state && location.state.obj && location.state.obj.InspectorName) {
        sourceName = ' - ' + location.state.obj.InspectorName;
      }
      incidentSourcefrom =
        intl.formatMessage({ id: 'inspectionReport.inspectionReport', defaultMessage: DOCUMENT_TYPE_INSPECTION_REPORT }) + sourceName;
      incidentSourceLink = `/site/${incidentReport.selectedIncidentReport.SiteId}/measure/2/inspections/inspectionReport/${incidentReport.selectedIncidentReport.SourceLinkId}`;
    } else if (incidentReport.selectedIncidentReport.SourceOfIncident === DOCUMENT_TYPE_HAZARD_EVENT) {
      if (location.state && location.state.obj && location.state.obj.Name) {
        sourceName = ' - ' + location.state.obj.Name;
      }
      incidentSourcefrom = intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: DOCUMENT_TYPE_HAZARD_EVENT }) + sourceName;
      incidentSourceLink = `/site/${incidentReport.selectedIncidentReport.SiteId}/identify/2/hazard-events/hazard-event-view/${incidentReport.selectedIncidentReport.SourceLinkId}`;
    } else if (incidentReport.selectedIncidentReport.SourceOfIncident === DOCUMENT_TYPE_TEST_RESULT) {
      if (location.state && location.state.obj && location.state.obj.SampleIdNumber) {
        sourceName =
          ' - ' +
          intl.formatMessage({ id: 'testResult.sampleIDNumber', defaultMessage: 'Sample Id Number' }) +
          ' ' +
          location.state.obj.SampleIdNumber;
      }
      incidentSourcefrom = intl.formatMessage({ id: 'testResult.testResult', defaultMessage: DOCUMENT_TYPE_TEST_RESULT }) + sourceName;
      incidentSourceLink = `/site/${incidentReport.selectedIncidentReport.SiteId}/measure/3/test-results/test-result-report/${incidentReport.selectedIncidentReport.SourceLinkId}`;
    } else {
      incidentSourcefrom = '';
    }
  }

  // Update redux store
  const setLocalIncidentReports = currentState => {
    dispatch(setIncidentReportsChangeThunk(currentState));
  };

  const cancelHandler = () => {
    goBack(history, backUrl);
  };

  useEffect(() => {
    let filterObj = {
      offset: offset,
      limit: limit
    };
    dispatch(getPersonnelThunk('WQSP_PERSONNEL_LIST', filterObj));
  }, [getPersonnelThunk]);

  useEffect(() => {
    dispatch(describeIncidentReportsThunk(incidentReportId, widgetCode));
  }, [describeIncidentReportsThunk, incidentReportId]);

  useEffect(() => {
    let changeObj = {};
    if (siteId && !incidentReport.selectedIncidentReport.SiteId) {
      changeObj.SiteId = siteId;
    }
    if (
      docType &&
      !incidentReport.selectedIncidentReport.SourceOfIncident &&
      referedId &&
      !incidentReport.selectedIncidentReport.SourceLinkId
    ) {
      changeObj.SourceOfIncident = docType;
      changeObj.SourceLinkId = referedId;
      changeObj.WaterSupplyDescriptionId = waterSupplyDescriptionId;
      changeObj.EntityTypeId = entityTypeId;
      changeObj.EntityId = entityId;
    }
    if (Object.keys(changeObj).length > 0) {
      setLocalIncidentReports({
        ...incidentReport,
        selectedIncidentReport: { ...incidentReport.selectedIncidentReport, ...changeObj }
      });
    }
  }, [setLocalIncidentReports, siteId, referedId, docType, waterSupplyDescriptionId, entityTypeId, entityId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setIncidentReportsChangeThunk({
          ...incidentReport,
          selectedIncidentReport: initialIncidentReportsState.incidentReports.selectedIncidentReport
        })
      );
    };
  }, []);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = {
      ...incidentReport.selectedIncidentReport
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (saveData.SaveInPeopleRegister) {
      saveData.PersonReportingGuidIdentifier = uuidv4();
    }

    if (!IsEdit) {
      saveData.IncidentReportId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    dispatch(saveIncidentReportsThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!incidentReport.selectedIncidentReport || !incidentReport.selectedIncidentReport.SiteId) {
      formErrors.Site = intl.formatMessage({
        id: 'wqsp.common.siteMandatory',
        defaultMessage: 'Site name is mandatory field'
      });
      isValid = false;
    }

    if (!incidentReport.selectedIncidentReport || !incidentReport.selectedIncidentReport.WaterSupplyDescriptionId) {
      formErrors.WaterSupply = intl.formatMessage({
        id: 'wqsp.common.waterSupplyMandatory',
        defaultMessage: 'Water supply name is mandatory field'
      });
      isValid = false;
    }

    if (!incidentReport.selectedIncidentReport || !incidentReport.selectedIncidentReport.EntityTypeId) {
      formErrors.EntityType = intl.formatMessage({
        id: 'wqsp.common.entityTypeMandatory',
        defaultMessage: 'Entity type is mandatory field'
      });
      isValid = false;
    }

    if (!incidentReport.selectedIncidentReport || !incidentReport.selectedIncidentReport.EntityId) {
      formErrors.Entity = intl.formatMessage({
        id: 'wqsp.common.entityMandatory',
        defaultMessage: 'Entity is mandatory field'
      });
      isValid = false;
    }

    if (!incidentReport.selectedIncidentReport || !incidentReport.selectedIncidentReport.Title) {
      formErrors.Title = intl.formatMessage({
        id: 'common.titleRequired',
        defaultMessage: 'Title is a mandatory field'
      });
      isValid = false;
    }

    if (!incidentReport.selectedIncidentReport || !incidentReport.selectedIncidentReport.IncidentDateTime) {
      formErrors.IncidentDateTime = intl.formatMessage({
        id: 'incidentReports.incidentDateRequired',
        defaultMessage: 'Incident Date & Time is mandatory'
      });
      isValid = false;
    }

    if (!incidentReport.selectedIncidentReport || !incidentReport.selectedIncidentReport.PersonReportingId) {
      formErrors.PersonReportingId = intl.formatMessage({
        id: 'incidentReports.personReportingRequired',
        defaultMessage: 'Person Reporting is mandatory'
      });
      isValid = false;
    }

    if (showNewPersonReporting) {
      if (
        !incidentReport.selectedIncidentReport ||
        !incidentReport.selectedIncidentReport.PersonReportingName ||
        isRequired(incidentReport.selectedIncidentReport.PersonReportingName, 1)
      ) {
        formErrors.PersonReportingName = intl.formatMessage({
          id: 'common.nameRequired',
          defaultMessage: 'Name is a mandatory field'
        });
        isValid = false;
      }

      if (
        !incidentReport.selectedIncidentReport ||
        !incidentReport.selectedIncidentReport.PersonReportingCompany ||
        isRequired(incidentReport.selectedIncidentReport.PersonReportingCompany, 1)
      ) {
        formErrors.PersonReportingCompany = intl.formatMessage({
          id: 'personnel.companyMandatory',
          defaultMessage: 'Company is a mandatory field'
        });
        isValid = false;
      }

      if (
        !incidentReport.selectedIncidentReport ||
        !incidentReport.selectedIncidentReport.PersonReportingPhone ||
        isRequired(incidentReport.selectedIncidentReport.PersonReportingPhone, 1)
      ) {
        formErrors.PersonReportingPhone = intl.formatMessage({
          id: 'common.phoneRequired',
          defaultMessage: 'Phone is a mandatory field'
        });
        isValid = false;
      }

      if (
        !incidentReport.selectedIncidentReport ||
        !incidentReport.selectedIncidentReport.PersonReportingEmail ||
        !isValidEmail(incidentReport.selectedIncidentReport.PersonReportingEmail, 1)
      ) {
        formErrors.PersonReportingEmail = intl.formatMessage({
          id: 'common.invalidEmail',
          defaultMessage: 'Please provide a valid email'
        });
        isValid = false;
      }
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = (e, data) => {
    if (data && data.countryCode) {
      e = { target: { type: 'text', name: 'PersonReportingPhone', value: e } };
    }

    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalIncidentReports({
        ...incidentReport,
        selectedIncidentReport: { ...incidentReport.selectedIncidentReport, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalIncidentReports({
        ...incidentReport,
        selectedIncidentReport: { ...incidentReport.selectedIncidentReport, [e.target.name]: e.target.value }
      });
    }
  };

  const siteOptions = () => {
    let siteData = [];
    generalData.sites.forEach(element => {
      siteData.push({
        ...element,
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onSiteDropdownChange = e => {
    setLocalIncidentReports({
      selectedIncidentReport: {
        ...incidentReport.selectedIncidentReport,
        SiteId: e.value,
        WaterSupplyDescriptionId: 0,
        EntityId: 0
      }
    });
  };

  const waterSupplyOptions = () => {
    let waterSupplyData = [];
    const filteredData = generalData.waterSupplyDescriptions.filter(item => item.SiteId === incidentReport.selectedIncidentReport.SiteId);
    filteredData.forEach(element => {
      waterSupplyData.push({
        ...element,
        label: element.Name,
        value: element.WaterSupplyDescriptionId
      });
    });

    let sortedData = waterSupplyData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onWaterSupplyDropdownChange = e => {
    setLocalIncidentReports({
      selectedIncidentReport: {
        ...incidentReport.selectedIncidentReport,
        WaterSupplyDescriptionId: e.value
      }
    });
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];
    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityTypeDropdownChange = e => {
    setLocalIncidentReports({
      selectedIncidentReport: {
        ...incidentReport.selectedIncidentReport,
        EntityTypeId: e.value,
        EntityId: 0
      }
    });
  };

  const entityOptions = () => {
    let entityData = [];
    let entitySubTypes = generalData.entitySubTypes.filter(
      item => item.EntityTypeId === incidentReport.selectedIncidentReport.EntityTypeId
    );
    let entities = generalData.entities.filter(item =>
      entitySubTypes.find(
        type =>
          type.EntitySubTypeId === item.EntitySubTypeId &&
          item.WaterSupplyDescriptionId === incidentReport.selectedIncidentReport.WaterSupplyDescriptionId
      )
    );
    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityDropdownChange = e => {
    setLocalIncidentReports({
      selectedIncidentReport: {
        ...incidentReport.selectedIncidentReport,
        EntityId: e.value
      }
    });
  };

  const personnelOptions = type => {
    let opData = [];

    personnel &&
      personnel.personnels.forEach(element => {
        opData.push({
          label: element.Name,
          value: element.PersonnelId
        });
      });
    let sortedData = opData.sort(compareValues('label'));
    if (type === 0) {
      sortedData.push({
        label: <FormattedMessage id="common.addNew" defaultMessage="Add New" />,
        value: -1
      });
    }
    return sortedData;
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();

    setLocalIncidentReports({
      selectedIncidentReport: {
        ...incidentReport.selectedIncidentReport,
        [fieldName]: changeDate
      }
    });
  };

  const incidentCategoryOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === INCIDENT_CATEGORY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const incidentConsequenceOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === INCIDENT_CONSEQUENCE);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onDropdownChange = (e, fieldName) => {
    if (fieldName === 'PersonReportingId') {
      if (e.value === -1) {
        setNewPersonReporting(true);
      } else {
        setNewPersonReporting(false);
      }
    }

    setLocalIncidentReports({
      selectedIncidentReport: {
        ...incidentReport.selectedIncidentReport,
        [fieldName]: e.value
      }
    });
  };

  const onStatusChange = e => {
    setLocalIncidentReports({
      ...incidentReport,
      selectedIncidentReport: { ...incidentReport.selectedIncidentReport, [e.target.name]: e.target.value, StatusId: null }
    });
  };

  const onUploadStart = files => {
    setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          IncidentReportId: 0,
          ImageId: item.fileGuid,
          newFile: true,
          S3URL: item.S3URL,
          Name: item.name
        };
      });
      let allFiles = [...incidentReport.selectedIncidentReport.files, ...newFiles];
      setLocalIncidentReports({
        selectedIncidentReport: {
          ...incidentReport.selectedIncidentReport,
          files: allFiles
        }
      });
    }
  };

  const removeFile = fileGuid => {
    let files = incidentReport.selectedIncidentReport.files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...incidentReport.selectedIncidentReport.deletedFileIds];
    deletedFileIds.push(fileGuid);
    setLocalIncidentReports({
      ...incidentReport,
      selectedIncidentReport: { ...incidentReport.selectedIncidentReport, files: files, deletedFileIds: deletedFileIds }
    });
  };

  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  let messageId = (incidentReport && incidentReport.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const selectedPersonReportingValue = IsEdit
    ? incidentReport.selectedIncidentReport.PersonReportingId > 0
      ? incidentReport.selectedIncidentReport.PersonReportingId
      : -1
    : incidentReport.selectedIncidentReport.PersonReportingId;

  const title = (incidentReport.selectedIncidentReport ? incidentReport.selectedIncidentReport.Title : '') || '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + title;
  } else {
    heading = intl.formatMessage({ id: 'incidentReport.addNewIncidentReport', defaultMessage: 'Add new Incident Report' });
  }

  if (incidentReport.isRedirect) goBack(history, backUrl);

  const breadcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.listOfIncidentReports" defaultMessage="List of Incident Reports" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/incident-reports`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 2, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <SiteDashboardControlsContentTemplate
      selectedPage={selectedTab}
      siteId={siteId}
      segmentName={segmentName}
      userProfileWidgets={userProfileWidgets}
    >
      <div className={s.incidentReports}>
        {segmentName ? (
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        ) : (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="incidentReports.incidentReports" defaultMessage="Incident Reports" />}</h1>
          </div>
        )}
        {incidentReport.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={incidentReport.showBanner}
          status={incidentReport.isOpSuccessful}
          successText={messageText}
        />
        <div className={s.contentWrapper}>
          {!segmentName && (
            <div className={s.testresultHeader}>
              <GoBack className={s.backLink}>
                &lt; &nbsp;
                <FormattedMessage id="common.back" defaultMessage="BACK" />
              </GoBack>
              <h3 data-unittest="headingLabel">{heading}</h3>
            </div>
          )}
          <div className={s.incidentReportContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formSiteName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlSite"
                      dataArray={siteOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Site && s.ddlError }}
                      onDropdownChange={onSiteDropdownChange}
                      data-unittest="formDdlSite"
                      name="SiteId"
                      selectedOption={siteOptions().filter(option => option.value === incidentReport.selectedIncidentReport.SiteId)}
                      disabled={IsEdit || siteId !== '0'}
                    />
                    {localformErrors && localformErrors.Site && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Site}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formSupplyName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlWaterSupply"
                      dataArray={waterSupplyOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.WaterSupply && s.ddlError }}
                      onDropdownChange={onWaterSupplyDropdownChange}
                      data-unittest="formDdlWaterSupply"
                      selectedOption={waterSupplyOptions().filter(
                        option => option.value === incidentReport.selectedIncidentReport.WaterSupplyDescriptionId
                      )}
                      disabled={IsEdit || waterSupplyDescriptionId}
                    />
                    {localformErrors && localformErrors.WaterSupply && (
                      <p role="alert" className={s.error}>
                        {localformErrors.WaterSupply}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formIncidentReportsEntityType">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntityType"
                      dataArray={entityTypeOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.EntityType && s.ddlError }}
                      onDropdownChange={onEntityTypeDropdownChange}
                      data-unittest="formDdlEntityType"
                      selectedOption={entityTypeOptions().filter(
                        option => option.value === incidentReport.selectedIncidentReport.EntityTypeId
                      )}
                      disabled={IsEdit || entityTypeId}
                    />
                    {localformErrors && localformErrors.EntityType && (
                      <p role="alert" className={s.error}>
                        {localformErrors.EntityType}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formIncidentReportsEntity">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntity"
                      dataArray={entityOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Entity && s.ddlError }}
                      onDropdownChange={onEntityDropdownChange}
                      data-unittest="formDdlEntity"
                      selectedOption={entityOptions().filter(option => option.value === incidentReport.selectedIncidentReport.EntityId)}
                      disabled={IsEdit || entityId}
                    />
                    {localformErrors && localformErrors.Entity && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Entity}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              {incidentReport.selectedIncidentReport.SourceOfIncident && (
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formGroupCategoryOfWater">
                      <Form.Label>
                        <FormattedMessage id="incidentReports.sourceOfIncidentFrom" defaultMessage="'Source of Incident From" />
                      </Form.Label>
                      <br />
                      <Form.Label>
                        <Link to={incidentSourceLink} target="_blank">
                          {incidentSourcefrom}
                        </Link>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formIncidentReportsTitle">
                    <Form.Label>
                      <FormattedMessage id="common.title" defaultMessage="Title" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Title"
                      onChange={onChange}
                      value={incidentReport.selectedIncidentReport.Title}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'common.title',
                        defaultMessage: 'Title'
                      })}
                    />
                    {localformErrors && localformErrors.Title && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Title}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formDescription">
                    <Form.Label>
                      <FormattedMessage id="common.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      className={s.textArea}
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'common.description',
                        defaultMessage: 'Description'
                      })}
                      value={incidentReport.selectedIncidentReport.Description}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formDate">
                    <Form.Label>
                      <FormattedMessage id="incidentReports.incidentDateTime" defaultMessage="Incident Date & Time" />
                    </Form.Label>

                    <DateTimePicker
                      className={`${localformErrors.IncidentDateTime ? s.formControlError : s.frmCalendar}`}
                      isValidDate={disableFutureDates}
                      onChange={m => onDateChange(m, 'IncidentDateTime')}
                      timeFormat="HH:mm ss"
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(incidentReport.selectedIncidentReport.IncidentDateTime)}
                      defaultValue={getFormattedate(incidentReport.selectedIncidentReport.IncidentDateTime)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                    {localformErrors && localformErrors.IncidentDateTime && (
                      <p role="alert" className={s.error}>
                        {localformErrors.IncidentDateTime}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formIncidentReportsAddress">
                    <Form.Label>
                      <FormattedMessage id="common.Address" defaultMessage="Address" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Address"
                      onChange={onChange}
                      value={incidentReport.selectedIncidentReport.Address}
                      className={`${s.formControl}`}
                      placeholder={intl.formatMessage({
                        id: 'common.address',
                        defaultMessage: 'Address'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formPersonReporting">
                    <Form.Label>
                      <FormattedMessage id="incidentReports.personReporting" defaultMessage="Person Reporting" />
                    </Form.Label>

                    <Dropdown
                      id="formPersonReporting"
                      dataArray={personnelOptions(0)}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                        customClassName: localformErrors.PersonReportingId && s.ddlError
                      }}
                      name="PersonReportingId"
                      onDropdownChange={e => onDropdownChange(e, 'PersonReportingId')}
                      selectedOption={personnelOptions(0).filter(option => option.value == selectedPersonReportingValue)}
                      // disabled={IsEdit && incidentReport.selectedIncidentReport.PersonReportingName.length > 0}
                    />
                    {localformErrors && localformErrors.PersonReportingId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.PersonReportingId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {(showNewPersonReporting || incidentReport.selectedIncidentReport.PersonReportingName.length > 0) && (
                <div className="pl-5">
                  <h5>
                    <FormattedMessage id="incidentReports.personReportingDetails" defaultMessage="Person Reporting Details" />
                  </h5>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formPersonnelName">
                        <Form.Label>
                          <FormattedMessage id="common.name" defaultMessage="Name" />
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="PersonReportingName"
                          onChange={onChange}
                          value={incidentReport.selectedIncidentReport.PersonReportingName}
                          className={`${s.formControl} ${localformErrors && localformErrors.PersonReportingName ? s.formControlError : ''}`}
                          placeholder={intl.formatMessage({
                            id: 'common.name',
                            defaultMessage: 'Name'
                          })}
                        />
                        {localformErrors && localformErrors.PersonReportingName && (
                          <p role="alert" className={s.error}>
                            {localformErrors.PersonReportingName}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formPersonnelCompany">
                        <Form.Label>
                          <FormattedMessage id="personnel.company" defaultMessage="Company" />
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="PersonReportingCompany"
                          onChange={onChange}
                          value={incidentReport.selectedIncidentReport.PersonReportingCompany}
                          className={`${s.formControl} ${
                            localformErrors && localformErrors.PersonReportingCompany ? s.formControlError : ''
                          }`}
                          placeholder={intl.formatMessage({
                            id: 'personnel.company',
                            defaultMessage: 'Company'
                          })}
                        />
                        {localformErrors && localformErrors.PersonReportingCompany && (
                          <p role="alert" className={s.error}>
                            {localformErrors.PersonReportingCompany}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formPersonnelJobTitle">
                        <Form.Label>
                          <FormattedMessage id="personnel.jobTitle" defaultMessage="Job Title" />
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="PersonReportingJobTitle"
                          onChange={onChange}
                          value={incidentReport.selectedIncidentReport.PersonReportingJobTitle}
                          className={`${s.formControl}`}
                          placeholder={intl.formatMessage({
                            id: 'personnel.jobTitle',
                            defaultMessage: 'Job Title'
                          })}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formPersonnelAddress">
                        <Form.Label>
                          <FormattedMessage id="common.address" defaultMessage="Address" />
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="PersonReportingAddress"
                          onChange={onChange}
                          value={incidentReport.selectedIncidentReport.PersonReportingAddress}
                          className={`${s.formControl}`}
                          placeholder={intl.formatMessage({
                            id: 'common.address',
                            defaultMessage: 'Address'
                          })}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formPersonnelPhone">
                        <Form.Label>
                          <FormattedMessage id="common.phone" defaultMessage="Phone" />
                        </Form.Label>

                        <PhoneInput
                          country={'au'}
                          inputStyle={{ width: '100%' }}
                          value={incidentReport.selectedIncidentReport.PersonReportingPhone}
                          onChange={onChange}
                          inputProps={{ name: 'PersonReportingPhone' }}
                          inputClass={`${s.formControl} ${
                            localformErrors && localformErrors.PersonReportingPhone ? s.formControlError : ''
                          }`}
                          buttonClass={localformErrors && localformErrors.Phone ? s.formControlError : ''}
                        />
                        {localformErrors && localformErrors.PersonReportingPhone && (
                          <p role="alert" className={s.error}>
                            {localformErrors.PersonReportingPhone}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formPersonnelEmail">
                        <Form.Label>
                          <FormattedMessage id="common.email" defaultMessage="Email" />
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="PersonReportingEmail"
                          onChange={onChange}
                          value={incidentReport.selectedIncidentReport.PersonReportingEmail}
                          className={`${s.formControl} ${
                            localformErrors && localformErrors.PersonReportingEmail ? s.formControlError : ''
                          }`}
                          placeholder={intl.formatMessage({
                            id: 'common.email',
                            defaultMessage: 'Email'
                          })}
                        />
                        {localformErrors && localformErrors.PersonReportingEmail && (
                          <p role="alert" className={s.error}>
                            {localformErrors.PersonReportingEmail}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formSaveInPeopleRegister">
                        <Form.Label>
                          <FormattedMessage id="incidentReports.saveInPeopleRegister" defaultMessage="Save in People Register" />
                        </Form.Label>

                        <ToggleSwitch
                          handleClick={() => {
                            onChange({
                              target: { name: 'SaveInPeopleRegister', value: !incidentReport.selectedIncidentReport.SaveInPeopleRegister }
                            });
                          }}
                          classname={s.switch}
                          checked={incidentReport.selectedIncidentReport.SaveInPeopleRegister}
                          labelChecked={yesText}
                          labelUnchecked={noText}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              )}
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formPersonInvestigating">
                    <Form.Label>
                      <FormattedMessage id="incidentReports.personInvestigating" defaultMessage="Person Investigating" />
                    </Form.Label>

                    <Dropdown
                      id="formPersonInvestigating"
                      dataArray={personnelOptions(1)}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                        customClassName: localformErrors.PersonInvestigatingId && s.ddlError
                      }}
                      name="PersonInvestigatingId"
                      onDropdownChange={e => onDropdownChange(e, 'PersonInvestigatingId')}
                      selectedOption={personnelOptions(1).filter(
                        option => option.value === incidentReport.selectedIncidentReport.PersonInvestigatingId
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group controlId="formActioned">
                    <Form.Label>
                      <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                    </Form.Label>

                    <ToggleSwitch
                      handleClick={() => {
                        onStatusChange({ target: { name: 'IsActioned', value: !incidentReport.selectedIncidentReport.IsActioned } });
                      }}
                      classname={s.switch}
                      checked={incidentReport.selectedIncidentReport.IsActioned}
                      labelChecked={yesText}
                      labelUnchecked={noText}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {!incidentReport.selectedIncidentReport.IsActioned && (
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formStatus">
                      <Form.Label>
                        <FormattedMessage id="common.status" defaultMessage="Status" />
                      </Form.Label>

                      <Dropdown
                        id="formStatus"
                        dataArray={statusOptions()}
                        controlData={{ placeholderText: ddlInitText }}
                        onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                        data-unittest="formDdlRiskStatus"
                        selectedOption={statusOptions().filter(option => option.value === incidentReport.selectedIncidentReport.StatusId)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formIncidentCategory">
                    <Form.Label>
                      <FormattedMessage id="incidentReports.incidentCategory" defaultMessage="Incident Category" />
                    </Form.Label>

                    <Dropdown
                      id="formIncidentCategory"
                      dataArray={incidentCategoryOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                      }}
                      name="CategoryId"
                      onDropdownChange={e => onDropdownChange(e, 'CategoryId')}
                      selectedOption={incidentCategoryOptions().filter(
                        option => option.value === incidentReport.selectedIncidentReport.CategoryId
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formIncidentConsequence">
                    <Form.Label>
                      <FormattedMessage id="incidentReports.incidentConsequence" defaultMessage="Incident Consequence" />
                    </Form.Label>

                    <Dropdown
                      id="formIncidentConsequence"
                      dataArray={incidentConsequenceOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                      }}
                      name="ConsequenceId"
                      onDropdownChange={e => onDropdownChange(e, 'ConsequenceId')}
                      selectedOption={incidentConsequenceOptions().filter(
                        option => option.value === incidentReport.selectedIncidentReport.ConsequenceId
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group controlId="formIsHACCPCritical">
                    <Form.Label>
                      <FormattedMessage id="incidentReports.isHACCPCritical" defaultMessage="Is HACCP Critical" />
                    </Form.Label>

                    <ToggleSwitch
                      handleClick={() => {
                        onChange({ target: { name: 'IsHACCPCritical', value: !incidentReport.selectedIncidentReport.IsHACCPCritical } });
                      }}
                      classname={s.switch}
                      checked={incidentReport.selectedIncidentReport.IsHACCPCritical}
                      labelChecked={yesText}
                      labelUnchecked={noText}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={s.incidentReportContent}>
            <Form>
              <Row>
                <Col>
                  <h3>
                    <FormattedMessage id="incidentReport.additionalInformation" defaultMessage="Additional Information" />
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>
                    <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                  </Form.Label>
                  <FileUploader
                    widgetCode={'WQSP_INCIDENT_REPORT_ATTACH'}
                    allowedFileTypes={[
                      ['image/jpeg', 'jpeg'],
                      ['application/pdf', 'pdf'],
                      ['application/msword', 'doc']
                    ]}
                    location={`organisation/${orgId}/sites/${incidentReport.selectedIncidentReport.SiteId}/incidentReport`}
                    enableMultipleUpload={true}
                    maxFiles={5}
                    onUploadComplete={onUploadComplete}
                    onUploadStart={onUploadStart}
                  />
                </Col>
              </Row>
              <FileList files={incidentReport.selectedIncidentReport.files} onRemoveFile={removeFile} />
            </Form>
          </div>
          <div>
            <Row className={s.breakRow}>
              <Col sm={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                  disabled={disableMode || incidentReport.isLoading}
                >
                  <FormattedMessage id="common.save" defaultMessage="SAVE" />
                </Button>
                <Button onClick={cancelHandler} variant="outline-secondary" className={s.btnCancel} disabled={disableMode}>
                  <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </SiteDashboardControlsContentTemplate>
  );
};

export default injectIntl(IncidentReport);
