import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './Personnel.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getPersonnel } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { initialPersonnelState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired, compareValues, isValidEmail } from '../../../utils';
import Cookies from 'js-cookie';
import OperationalSetupContentTemplate from '../../OperationalSetup/OperationalSetupContentTemplate';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import Banner from '../../Banner/Banner';
import { describePersonnelThunk, savePersonnelThunk, setPersonnelChangeThunk } from '../../../store/actions/admin/personnel';
import uuid from 'uuid';

import { CONTACT_METHOD } from '../../../constants';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const Personnel = ({ intl }) => {
  let history = useHistory();
  let { personnelId } = useParams();

  const dispatch = useDispatch();
  const personnel = useSelector(state => getPersonnel(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});

  const backUrl = '/operational-setup/personnels';
  let widgetCode = '';
  if (personnelId) {
    IsEdit = true;
    widgetCode = 'WQSP_PERSONNEL_EDIT';
  }

  useEffect(() => {
    if (personnelId) {
      dispatch(describePersonnelThunk(personnelId, widgetCode));
    }
  }, [describePersonnelThunk, dispatch, personnelId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setPersonnelChangeThunk({
          ...initialPersonnelState.personnel
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalPersonnel = currentState => {
    dispatch(setPersonnelChangeThunk(currentState));
  };

  const ContactMethodOptions = () => {
    let opData = [];

    const data = CONTACT_METHOD;

    data.forEach(element => {
      opData.push({
        label: element.text,
        value: element.value
      });
    });
    return opData.sort(compareValues('label'));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = personnel.selectedPersonnel;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.PersonnelId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_PERSONNEL_ADDNEW';
    }

    dispatch(savePersonnelThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !personnel ||
      !personnel.selectedPersonnel ||
      !personnel.selectedPersonnel.Name ||
      isRequired(personnel.selectedPersonnel.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'common.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !personnel ||
      !personnel.selectedPersonnel ||
      !personnel.selectedPersonnel.Company ||
      isRequired(personnel.selectedPersonnel.Company, 1)
    ) {
      formErrors.Company = intl.formatMessage({
        id: 'personnel.companyMandatory',
        defaultMessage: 'Company is a mandatory field'
      });
      isValid = false;
    }

    if (
      !personnel ||
      !personnel.selectedPersonnel ||
      !personnel.selectedPersonnel.Phone ||
      isRequired(personnel.selectedPersonnel.Phone, 1)
    ) {
      formErrors.Phone = intl.formatMessage({
        id: 'common.phoneRequired',
        defaultMessage: 'Phone is a mandatory field'
      });
      isValid = false;
    }

    if (
      !personnel ||
      !personnel.selectedPersonnel ||
      !personnel.selectedPersonnel.Email ||
      !isValidEmail(personnel.selectedPersonnel.Email, 1)
    ) {
      formErrors.Email = intl.formatMessage({
        id: 'common.invalidEmail',
        defaultMessage: 'Please provide a valid email'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalPersonnel({
      ...personnel,
      selectedPersonnel: { ...personnel.selectedPersonnel, [fieldName]: e.value }
    });
  };

  //on control value change
  const onChange = (e, data) => {
    if (data && data.countryCode) {
      e = { target: { type: 'text', name: 'Phone', value: e } };
    }

    setLocalPersonnel({
      ...personnel,
      selectedPersonnel: { ...personnel.selectedPersonnel, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (personnel && personnel.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const title = (personnel.selectedPersonnel ? personnel.selectedPersonnel.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + title;
  } else {
    heading = intl.formatMessage({ id: 'personnels.addPersonnel', defaultMessage: 'Add new personnel' });
  }

  return (
    <div className={s.personnel}>
      {personnel.isLoading && <LoadingSpinner />}
      {personnel.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={personnel.showBanner}
        status={personnel.isOpSuccessful}
        successText={messageText}
      />
      <OperationalSetupContentTemplate selectedPage="personnels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.personnelHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.personnelContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formPersonnelName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={personnel.selectedPersonnel.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formPersonnelCompany">
                      <Form.Label>
                        <FormattedMessage id="personnel.company" defaultMessage="Company" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Company"
                        onChange={onChange}
                        value={personnel.selectedPersonnel.Company}
                        className={`${s.formControl} ${localformErrors && localformErrors.Company ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'personnel.company',
                          defaultMessage: 'Company'
                        })}
                      />
                      {localformErrors && localformErrors.Company && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Company}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formPersonnelJobTitle">
                      <Form.Label>
                        <FormattedMessage id="personnel.jobTitle" defaultMessage="Job Title" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="JobTitle"
                        onChange={onChange}
                        value={personnel.selectedPersonnel.JobTitle}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'personnel.jobTitle',
                          defaultMessage: 'Job Title'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formPersonnelAddress">
                      <Form.Label>
                        <FormattedMessage id="common.address" defaultMessage="Address" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Address"
                        onChange={onChange}
                        value={personnel.selectedPersonnel.Address}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'common.address',
                          defaultMessage: 'Address'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formPersonnelPhone">
                      <Form.Label>
                        <FormattedMessage id="common.phone" defaultMessage="Phone" />
                      </Form.Label>

                      <PhoneInput
                        country={'au'}
                        inputStyle={{ width: '100%' }}
                        value={personnel.selectedPersonnel.Phone}
                        onChange={onChange}
                        inputProps={{ name: 'Phone' }}
                        inputClass={`${s.formControl} ${localformErrors && localformErrors.Phone ? s.formControlError : ''}`}
                        buttonClass={localformErrors && localformErrors.Phone ? s.formControlError : ''}
                      />
                      {localformErrors && localformErrors.Phone && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Phone}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formPersonnelEmail">
                      <Form.Label>
                        <FormattedMessage id="common.email" defaultMessage="Email" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Email"
                        onChange={onChange}
                        value={personnel.selectedPersonnel.Email}
                        className={`${s.formControl} ${localformErrors && localformErrors.Email ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.email',
                          defaultMessage: 'Email'
                        })}
                      />
                      {localformErrors && localformErrors.Email && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Email}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="personnelContactMethod">
                      <Form.Label>
                        <FormattedMessage id="personnel.preferredContactMethod" defaultMessage="Preferred Contact Method" />
                      </Form.Label>

                      <Dropdown
                        id="formSiteName"
                        dataArray={ContactMethodOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: ''
                        }}
                        name="PrefferedContactMethod"
                        onDropdownChange={e => onDropdownChange(e, 'PrefferedContactMethod')}
                        selectedOption={ContactMethodOptions().filter(
                          option => option.value === personnel.selectedPersonnel.PrefferedContactMethod
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                      disabled={personnel.isLoading}
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

Personnel.defaultProps = {
  personnel: {
    ...initialPersonnelState
  }
};

export default injectIntl(Personnel);
