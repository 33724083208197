import { initialGeneralFieldTypeState } from './initialState';

import {
  GENERAL_FIELDTYPE_ERROR,
  GET_ALL_GENERAL_FIELDTYPE_DETAILS,
  GENERAL_FIELDTYPE_IS_LOADING,
  SET_GENERAL_FIELDTYPE_CURRENT_PAGE,
  SET_GENERAL_FIELDTYPE_PAGE_FILTER,
  SET_GENERAL_FIELDTYPE_CHANGE,
  SET_GENERAL_FIELDTYPE_CLEANUP,
  SET_GENERAL_FIELDTYPE,
  UPDATE_GENERAL_FIELDTYPE_SAVE_STATUS,
  SET_GENERAL_FIELDTYPE_RESULTS
} from '../../constants/index';

const generalFieldTypeReducer = (state = initialGeneralFieldTypeState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_GENERAL_FIELDTYPE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          ...data
        }
      };
    }

    case SET_GENERAL_FIELDTYPE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          currentPage
        }
      };
    }

    case SET_GENERAL_FIELDTYPE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          filter
        }
      };
    }

    case UPDATE_GENERAL_FIELDTYPE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          ...data
        }
      };
    }

    case GENERAL_FIELDTYPE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          isLoading: isLoading
        }
      };
    }

    case GENERAL_FIELDTYPE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          ...data
        }
      };
    }

    case SET_GENERAL_FIELDTYPE: {
      let data = payload;

      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          isOpSuccessful: false,
          showBanner: false,
          SelectedGeneralFieldType: {
            ...state.generalFieldType.SelectedGeneralFieldType,
            ...data
          }
        }
      };
    }

    case SET_GENERAL_FIELDTYPE_CHANGE: {
      let { SelectedGeneralFieldType } = payload;

      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          isOpSuccessful: false,
          showBanner: false,
          SelectedGeneralFieldType: {
            ...state.generalFieldType.SelectedGeneralFieldType,
            ...SelectedGeneralFieldType
          }
        }
      };
    }
    case SET_GENERAL_FIELDTYPE_CLEANUP: {
      let SelectedGeneralFieldType = payload;
      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          SelectedGeneralFieldType: SelectedGeneralFieldType
        }
      };
    }

    case SET_GENERAL_FIELDTYPE_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        generalFieldType: {
          ...state.generalFieldType,
          ...filteredResults
        }
      };
    }

    default:
      return state;
  }
};

export default generalFieldTypeReducer;
