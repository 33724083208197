import React from 'react';
import s from './HazardEvent.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../selectors/index';
import MoreOption from '../WSAControls/MoreOption/MoreOption';
import { useParams } from 'react-router-dom';

const HazardEventListTable = ({
  paginatedData,
  onSortOrder,
  asendingOrder,
  viewHazardEvent,
  viewAllowed,
  deleteAllowed,
  viewRiskManagementAllowed,
  viewRiskManagement,
  viewControlMeasure,
  viewImprovementPlan,
  controlMeasuresAllowed,
  improvementPlansAllowed,
  removeHazardEvent,
  hazardClasses,
  AllowCreateIncidentReport,
  CreateIncidentReport
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  let { segmentName, selectedTab } = useParams();

  segmentName = segmentName || 'identify';

  const getHazardClassNames = ids => {
    let splitIds = ids.split(',');
    let selClasses = hazardClasses.filter(item => splitIds.find(id => id === item.GeneralFieldTypeId.toString()));
    return (selClasses && selClasses.length && selClasses.map(item => item.Name).join(', ')) || '';
  };

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
            </th>
            <th>
              <FormattedMessage id="hazardEvent.hazardCertainty" defaultMessage="Hazard Certainty" />
            </th>
            <th>
              <FormattedMessage id="hazardEvent.hazardClass" defaultMessage="Hazard Class" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="hazardEvent.riskAssesments" defaultMessage="Risk Assesments" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            {(viewAllowed || deleteAllowed || controlMeasuresAllowed || improvementPlansAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.HazardEventId}>
                <td>{n.Name || ''}</td>
                <td>{n.SiteName || ''}</td>
                <td>{n.WaterSupplyDescriptionName || ''}</td>
                <td>{n.EntityTypeName || ''}</td>
                <td>{n.Entity || ''}</td>
                <td>{n.HazardCertainty || ''}</td>
                <td>{getHazardClassNames(n.HazardClass)}</td>
                <td>{n.NoOfRiskAssessments}</td>
                {(viewAllowed || deleteAllowed || controlMeasuresAllowed || improvementPlansAllowed) && (
                  <td>
                    <MoreOption
                      dataArray={[
                        controlMeasuresAllowed
                          ? {
                              Click: () => viewControlMeasure(n.HazardEventId, n.SiteId),
                              Name: <FormattedMessage id="common.controlMeassures" defaultMessage="CONTROL MEASURES" />
                            }
                          : {},
                        improvementPlansAllowed
                          ? {
                              Click: () => viewImprovementPlan(n.HazardEventId, n.SiteId),
                              Name: <FormattedMessage id="common.improvementPlans" defaultMessage="IMPROVEMENT PLANS" />
                            }
                          : {},
                        viewRiskManagementAllowed
                          ? {
                              Click: () => viewRiskManagement(n.HazardEventId, n.SiteId),
                              Name: <FormattedMessage id="riskAssessment.riskAssessmentsUC" defaultMessage="RISK ASSESSMENTS" />
                            }
                          : {},
                        AllowCreateIncidentReport
                          ? {
                              Click: () => CreateIncidentReport(n),
                              Name: <FormattedMessage id="incidentReports.createIncidentReport" defaultMessage="CREATE INCIDENT REPORT" />
                            }
                          : {},
                        viewAllowed
                          ? {
                              Click: () => viewHazardEvent(n.HazardEventId, n.SiteId),
                              Name: <FormattedMessage id="common.view" defaultMessage="VIEW" />
                            }
                          : {},
                        deleteAllowed
                          ? {
                              Click: () => removeHazardEvent(n.HazardEventId),
                              Name: <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                            }
                          : {}
                      ]}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

HazardEventListTable.defaultProps = {
  paginatedData: [],
  hazardClasses: [],
  currentPage: 1
};

export default injectIntl(HazardEventListTable);
