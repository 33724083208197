import React, { useEffect } from 'react';
import s from './RiskAssessment.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialRiskAssessmentState } from '../../store/reducers/initialState';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { getDateInDDMMYYYFormat } from '../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import uuid from 'uuid';
import { RISK_RESPONSE } from '../../constants/index';
import { getRiskAssessment, getUserProfileWidget, getGeneralData, getCurrentTimezone } from '../../selectors/index';
import { describeRiskAssessmentThunk, saveRiskAssessmentThunk, setRiskAssessmentChangeThunk } from '../../store/actions/riskAssessment';
import RiskAssessmentComments from './RiskAssessmentComments';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const RiskAssessmentView = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  let { riskAssessmentId, siteId, entityId, segmentName, selectedTab, hazardEventId } = useParams();
  const history = useHistory();

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const riskAssessment = useSelector(state => getRiskAssessment(state));
  const generalData = useSelector(state => getGeneralData(state));

  const riskResponses = generalData.generalFieldTypes.filter(item => item.FieldType === RISK_RESPONSE);
  let disableControls = riskAssessment.isLoading || false;

  if (riskAssessmentId && riskAssessmentId > 0) {
    IsEdit = true;
  } else {
    riskAssessmentId = 0;
  }
  let widgetCode = 'WQSP_RISKASSESSMENT_VIEW';
  let addCommentsAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_RISKASSESSMENT_COMMENT_ADD`);

  useEffect(() => {
    dispatch(describeRiskAssessmentThunk(riskAssessmentId, widgetCode));
  }, [describeRiskAssessmentThunk, riskAssessmentId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setRiskAssessmentChangeThunk({
          ...initialRiskAssessmentState.riskAssessment
        })
      );
    };
  }, []);

  const saveRiskAssessmentComment = comment => {
    let saveData = {
      ...comment
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.performCommentInsert = true;
    dispatch(saveRiskAssessmentThunk(saveData, 'WQSP_RISKASSESSMENT_COMMENT_ADD'));
  };

  const onChangeClick = () => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment/${riskAssessmentId}`
      );
    } else {
      if (hazardEventId) {
        history.push(
          `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment/${riskAssessmentId}`
        );
      } else {
        history.push(`/site/${siteId}/${segmentName}/${selectedTab}/risk-assessments/risk-assessment/${riskAssessmentId}`);
      }
    }
  };

  const getRiskResponseNames = ids => {
    let splitIds = ids.split(',');
    let selResponses = riskResponses.filter(item => splitIds.find(id => id === item.GeneralFieldTypeId.toString()));
    return (selResponses && selResponses.length && selResponses.map(item => item.Name).join(', ')) || '';
  };

  const getPassedOnEntityNames = entities => {
    return (entities && entities.length && entities.map(item => item.Name).join(', ')) || '-';
  };
  const getTranslatedText = id => {
    if (id) return intl.formatMessage({ id: id, defaultMessage: id });
    return null;
  };

  const onHazardEventClick = selectedhazardEventId => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${selectedhazardEventId}`
      );
    } else {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${selectedhazardEventId}`);
    }
  };

  let messageId = (riskAssessment && riskAssessment.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let hazardEventHeading =
    intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) +
    ' (' +
    riskAssessment.selectedRiskAssessment.HazardEventName +
    ')';

  heading = intl.formatMessage({ id: 'riskAssessment.viewRiskAssessment', defaultMessage: 'View Risk Assessment' });
  // heading = `${heading} - ${riskAssessment.selectedRiskAssessment.Name}`;

  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const actionName = riskAssessment.selectedRiskAssessment.IsActioned ? yesText : noText;

  const defineHazardEventCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 5,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  const identifyHazardEventCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];
  const identifyRiskAssessmentCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/risk-assessments`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  let breadcrumb = identifyRiskAssessmentCrumb;

  if (segmentName === 'define') {
    breadcrumb = defineHazardEventCrumb;
  } else {
    if (hazardEventId) {
      breadcrumb = identifyHazardEventCrumb;
    }
  }

  return (
    <div className={s.riskAssessment}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {segmentName ? (
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        ) : (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />}</h1>
          </div>
        )}
        {riskAssessment.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={riskAssessment.showBanner}
          status={riskAssessment.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          <div>
            <Row>
              <Col>
                <Row className={s.colActionButton}>
                  <Button
                    className={classNames(s.margin5, s.changeButton)}
                    style={WidgetVisibility(userProfileWidgets, 'WQSP_RISKASSESSMENT_EDIT')}
                    onClick={() => {
                      onChangeClick();
                    }}
                    disabled={disableControls}
                    data-unittest="changeRiskAssessment"
                  >
                    <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={s.riskAssessmentContent}>
            <Form>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.SiteName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.WaterSupplyDescriptionName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.EntityTypeName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.Entity || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.hazardEvent" defaultMessage="Hazard Event" />
                  </Form.Label>
                </Col>
                <Col>
                  <a
                    className={s.link}
                    onClick={() => {
                      onHazardEventClick(riskAssessment.selectedRiskAssessment.HazardEventId);
                    }}
                  >
                    {riskAssessment.selectedRiskAssessment.HazardEventName || '-'}
                  </a>
                </Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.riskCategory" defaultMessage="Risk Category" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.RiskCategoryName || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.riskNumber" defaultMessage="Risk Number" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.RiskNumber || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.inherentLikelihood" defaultMessage="Inherent Likelihood" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.InherentLikelihood}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.inherentConsequence" defaultMessage="Inherent Consequence" />
                  </Form.Label>
                </Col>
                <Col>{getTranslatedText(riskAssessment.selectedRiskAssessment.InherentConsequence) || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.inherentRiskScore" defaultMessage="Inherent  Risk Score" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.InherentRiskScore}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.inherentRiskRating" defaultMessage="Inherent  Risk Rating" />
                  </Form.Label>
                </Col>
                <Col>{getTranslatedText(riskAssessment.selectedRiskAssessment.InherentRiskRating) || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.riskResponse" defaultMessage="Risk Response" />
                  </Form.Label>
                </Col>
                <Col>{getRiskResponseNames(riskAssessment.selectedRiskAssessment.Response) || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.passedOn" defaultMessage="Passed-On" />
                  </Form.Label>
                </Col>
                <Col>
                  {riskAssessment.selectedRiskAssessment.IsPassedOn ? (
                    <FormattedMessage id="common.yes" defaultMessage="Yes" />
                  ) : (
                    <FormattedMessage id="common.no" defaultMessage="No" /> || '-'
                  )}
                </Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.passedOnEntities" defaultMessage="Entities Risk Passed-On" />
                  </Form.Label>
                </Col>
                <Col>{getPassedOnEntityNames(riskAssessment.selectedRiskAssessment.Entities)}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.riskResponseDetails" defaultMessage="Risk Response Details" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.ResponseDetails || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.residualLikelihood" defaultMessage="Residual Likelihood" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.ResidualLikelihood}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.residualConsequence" defaultMessage="Residual Consequence" />
                  </Form.Label>
                </Col>
                <Col>{getTranslatedText(riskAssessment.selectedRiskAssessment.ResidualConsequence) || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.residualRiskScore" defaultMessage="Residual  Risk Score" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.ResidualRiskScore}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.residualRiskRating" defaultMessage="Residual  Risk Rating" />
                  </Form.Label>
                </Col>
                <Col>{getTranslatedText(riskAssessment.selectedRiskAssessment.ResidualRiskRating) || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.riskCertainty" defaultMessage="Risk Certainty" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.RiskCertainty || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.basis" defaultMessage="Basis" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.Basis || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="riskAssessment.dateRaised" defaultMessage="Date Raised" />
                  </Form.Label>
                </Col>
                <Col>{getDateInDDMMYYYFormat(riskAssessment.selectedRiskAssessment.DateRaised, currentTimezone)}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                  </Form.Label>
                </Col>
                <Col>{getDateInDDMMYYYFormat(riskAssessment.selectedRiskAssessment.LastReviewDate, currentTimezone) || '-'}</Col>
              </Row>

              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                  </Form.Label>
                </Col>
                <Col>
                  {(riskAssessment.selectedRiskAssessment.NextReviewDate &&
                    getDateInDDMMYYYFormat(riskAssessment.selectedRiskAssessment.NextReviewDate, currentTimezone)) ||
                    '-'}
                </Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                  </Form.Label>
                </Col>
                <Col>{riskAssessment.selectedRiskAssessment.ReviewFrequencyName || '-'}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                  </Form.Label>
                </Col>
                <Col>{actionName || '-'}</Col>
              </Row>

              {!riskAssessment.selectedRiskAssessment.IsActioned && (
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="common.status" defaultMessage="Status" />
                    </Form.Label>
                  </Col>
                  <Col>{riskAssessment.selectedRiskAssessment.Status || '-'}</Col>
                </Row>
              )}
            </Form>
          </div>

          <div className={s.riskAssessmentContent}>
            <RiskAssessmentComments
              riskAssessment={riskAssessment}
              saveRiskAssessmentComment={saveRiskAssessmentComment}
              UpdateAllowed={addCommentsAllowed}
              currentTimezone={currentTimezone}
            />
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(RiskAssessmentView);
