import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './GeneralFieldType.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getGeneralFieldType } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialGeneralFieldTypeState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Banner from '../Banner/Banner';
import {
  describeGeneralFieldTypeThunk,
  saveGeneralFieldTypeThunk,
  setGeneralFieldTypeChangeThunk,
  setGeneralFieldTypeCleanUpThunk
} from '../../store/actions/generalFieldType';
import uuid from 'uuid';

import { FIELD_TYPE_DATA } from '../../constants';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const GeneralFieldType = ({ intl }) => {
  let history = useHistory();
  let { generalFieldTypeId } = useParams();

  const dispatch = useDispatch();
  const generalFieldType = useSelector(state => getGeneralFieldType(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});

  const backUrl = '/operational-setup/general-field-types';
  let widgetCode = '';
  if (generalFieldTypeId) {
    IsEdit = true;
    widgetCode = 'WQSP_GENERAL_FIELDTYPES_EDIT';
  }

  useEffect(() => {
    if (generalFieldTypeId) {
      dispatch(describeGeneralFieldTypeThunk(generalFieldTypeId, widgetCode));
    }
  }, [describeGeneralFieldTypeThunk, dispatch, generalFieldTypeId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setGeneralFieldTypeCleanUpThunk(initialGeneralFieldTypeState.generalFieldType.SelectedGeneralFieldType));
    };
  }, []);

  // Update redux store
  const setLocalGeneralFieldType = currentState => {
    dispatch(setGeneralFieldTypeChangeThunk(currentState));
  };

  const fieldTypeOptions = () => {
    let opData = [];

    const data = FIELD_TYPE_DATA;

    data.forEach(element => {
      opData.push({
        label: intl.formatMessage({ id: element.text, defaultMessage: element.text }),
        value: element.value
      });
    });
    return opData.sort(compareValues('label'));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = generalFieldType.SelectedGeneralFieldType;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.GeneralFieldTypeId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_GENERAL_FIELDTYPES_ADDNEW';
    }

    dispatch(saveGeneralFieldTypeThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !generalFieldType ||
      !generalFieldType.SelectedGeneralFieldType ||
      !generalFieldType.SelectedGeneralFieldType.FieldType ||
      isRequired(generalFieldType.SelectedGeneralFieldType.FieldType, 1)
    ) {
      formErrors.FieldType = intl.formatMessage({
        id: 'generalFieldTypes.fieldTypeRequired',
        defaultMessage: 'Field type is a mandatory field'
      });
      isValid = false;
    }

    if (
      !generalFieldType ||
      !generalFieldType.SelectedGeneralFieldType ||
      !generalFieldType.SelectedGeneralFieldType.Name ||
      isRequired(generalFieldType.SelectedGeneralFieldType.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'common.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalGeneralFieldType({
      ...generalFieldType,
      SelectedGeneralFieldType: { ...generalFieldType.SelectedGeneralFieldType, [fieldName]: e.value }
    });
  };

  //on control value change
  const onChange = e => {
    setLocalGeneralFieldType({
      ...generalFieldType,
      SelectedGeneralFieldType: { ...generalFieldType.SelectedGeneralFieldType, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (generalFieldType && generalFieldType.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const title = (generalFieldType.SelectedGeneralFieldType ? generalFieldType.SelectedGeneralFieldType.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + title;
  } else {
    heading = intl.formatMessage({ id: 'generalFieldTypes.addGeneralFieldType', defaultMessage: 'Add new field type item' });
  }

  let ddlClassSensor = localformErrors && localformErrors.FieldType ? s.ddlError : '';

  return (
    <div className={s.generalFieldType}>
      {generalFieldType.isLoading && <LoadingSpinner />}
      {generalFieldType.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={generalFieldType.showBanner}
        status={generalFieldType.isOpSuccessful}
        successText={messageText}
      />
      <OperationalSetupContentTemplate selectedPage="generalFieldTypes" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.generalFieldTypeHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.generalFieldTypeContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="siteName">
                      <Form.Label>
                        <FormattedMessage id="common.fieldType" defaultMessage="Field Type" />
                      </Form.Label>

                      <Dropdown
                        id="formSiteName"
                        dataArray={fieldTypeOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: ddlClassSensor
                        }}
                        name="FieldType"
                        onDropdownChange={e => onDropdownChange(e, 'FieldType')}
                        selectedOption={fieldTypeOptions().filter(
                          option => option.value === generalFieldType.SelectedGeneralFieldType.FieldType
                        )}
                        disabled={IsEdit}
                      />
                      {localformErrors && localformErrors.FieldType && (
                        <p role="alert" className={s.error}>
                          {localformErrors.FieldType}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formGeneralFieldTypeName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={generalFieldType.SelectedGeneralFieldType.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formGeneralFieldTypeValue">
                      <Form.Label>
                        <FormattedMessage id="generalFieldTypes.value" defaultMessage="Value" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Value"
                        onChange={onChange}
                        value={generalFieldType.SelectedGeneralFieldType.Value}
                        className={`${s.formControl} ${localformErrors && localformErrors.Value ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'generalFieldTypes.value',
                          defaultMessage: 'Value'
                        })}
                      />
                      {localformErrors && localformErrors.Value && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Value}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formGeneralFieldTypeDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={generalFieldType.SelectedGeneralFieldType.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

GeneralFieldType.defaultProps = {
  generalFieldType: {
    ...initialGeneralFieldTypeState
  }
};

export default injectIntl(GeneralFieldType);
