import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { push } from 'connected-react-router';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import Banner from '../../../Banner/Banner';
import uuid from 'uuid';
import { getSiteSegments, getTranslation } from '../../../../selectors/index';
import { describeSiteSegmentsThunk } from '../../../../store/actions/siteSegments';
import MapView from './MapView/MapView';
import droplet from '../../../../assets/droplet-icon.svg';
import wsdIcon from '../../../../assets/water-desc.svg';
import hazardIcon from '../../../../assets/hazard-event.svg';
import catchement from '../../../../assets/catchement.svg';
import sourcePoint from '../../../../assets/source-point.svg';
import transfer from '../../../../assets/transfer.svg';
import storage from '../../../../assets/storage.svg';
import abstractionPoint from '../../../../assets/abstraction-point.svg';
import node from '../../../../assets/node.svg';
import treatement from '../../../../assets/treatement.svg';
import hazardCertainty from '../../../../assets/hazard-certainty.svg';
import Cookies from 'js-cookie';
import moment from 'moment';
import calendar from '../../../../assets/calendar.svg';
import calendar_red from '../../../../assets/calendar.svg';
import location from '../../../../assets/location.svg';
import { getCurrentTimezone } from '../../../../selectors/index';
import languageObject from '../../../../translations/messages';

const Map = ({ intl }) => {
  const dispatch = useDispatch();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let { siteId } = useParams();
  siteId = siteId && parseInt(siteId);

  const siteSegments = useSelector(state => getSiteSegments(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const translations = useSelector(state => getTranslation(state)).translations || [];
  const locale = Cookies.get(`defaultLanguage-${envName}`) || 'en';
  const langObject = { ...languageObject[locale], ...translations };

  let widgetCode = 'WQSP_SITE_ELEMENTS_MAP_VIEW';

  useEffect(() => {
    if (siteId) {
      dispatch(describeSiteSegmentsThunk(siteId, widgetCode));
    }
  }, [describeSiteSegmentsThunk, siteId, widgetCode]);

  let messageId = (siteSegments && siteSegments.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const formatSiteSegments = () => {
    let formattedSegments = [];
    if (siteSegments.segments.length > 0) {
      let sites = siteSegments.segments.filter(element => element.Type === 'SITE');
      let formattedSites = formatSites(sites);
      formattedSegments = formattedSites;

      let wsds = siteSegments.segments.filter(element => element.Type === 'WSD');
      let formattedWSDs = formatWSD(wsds);
      formattedSegments = [...formattedSegments, ...formattedWSDs];

      let entities = siteSegments.segments.filter(element => element.Type === 'ENTITY');
      let formattedEntities = formatEntities(entities);
      formattedSegments = [...formattedSegments, ...formattedEntities];

      let hazardEvents = siteSegments.segments.filter(element => element.Type === 'HAZARDEVENT');
      let formattedHazardEvents = formatHazardEvents(hazardEvents);
      formattedSegments = [...formattedSegments, ...formattedHazardEvents];
    }

    return formattedSegments;
  };

  const formatSites = sites => {
    return sites.map(site => {
      let element = {};
      element.elementId = 'SITE-' + site.SiteId;
      element.type = 'SITE';
      element.searchName = site.Name;
      element.name = 'Site - ' + site.Name;
      element.icon = droplet;
      let address = `${element.Location || ''}`;
      element.address = address;
      element.status = (site.NextReviewDate && site.NextReviewDate > moment().unix()) || false;
      element.items = [
        {
          name: intl.formatMessage({ id: 'siteSegments.location', defaultMessage: 'Location' }),
          value: site.Location,
          icon: location
        },
        {
          name: intl.formatMessage({ id: 'siteSegments.nextReviewDate', defaultMessage: 'Next Review Date' }),
          value: site.nextReviewDate
            ? moment
                .unix(parseInt(site.nextReviewDate))
                .tz(currentTimezone)
                .format('DD-MMM-YYYY HH:mm:ss z')
            : 'N/A',
          icon: element.status ? calendar : calendar_red
        }
      ];

      if (site.GpsLatitude && site.GpsLongitude) {
        let newLat = parseFloat(site.GpsLatitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        let newLng = parseFloat(site.GpsLongitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        element.position = { lat: newLat, lng: newLng };
      } else {
        element.position = {
          lat: -37.840935 + (Math.random() * (0.00004 - 0.00001) + 0.00001),
          lng: 144.946457 + (Math.random() * (0.00004 - 0.00001) + 0.00001)
        };
      }

      return element;
    });
  };

  //Watersupply descriptions
  const formatWSD = wSDs => {
    return wSDs.map(wsd => {
      let element = {};
      element.elementId = 'WSD-' + wsd.WaterSupplyDescriptionId;
      element.type = 'WSD';
      element.status = true;
      element.url = `/sites/${wsd.SiteId}/water-supply-descriptions/water-supply-description/${wsd.WaterSupplyDescriptionId}`;
      element.searchName = wsd.Name;
      element.name = `${intl.formatMessage({ id: 'siteSegments.waterSupplyDescription', defaultMessage: 'Water Supply Description' })} - ${
        wsd.Name
      }`;
      element.icon = wsdIcon;

      element.items = [
        {
          name: wsd.NoOfEntities || 0,
          value: `${intl.formatMessage({ id: 'siteSegments.entities', defaultMessage: 'Entities' })}: ${wsd.NoOfEntities || 0}`,
          icon: catchement
        }
      ];

      if (wsd.GpsLatitude && wsd.GpsLongitude) {
        let newLat = parseFloat(wsd.GpsLatitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        let newLng = parseFloat(wsd.GpsLongitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        element.position = { lat: newLat, lng: newLng };
      } else {
        element.position = {
          lat: -37.840935 + (Math.random() * (0.00004 - 0.00001) + 0.00001),
          lng: 144.946457 + (Math.random() * (0.00004 - 0.00001) + 0.00001)
        };
      }

      return element;
    });
  };

  //Entities
  const formatEntities = entities => {
    return entities.map(entity => {
      let element = {};
      element.elementId = 'ENTITY-' + entity.EntityId;
      element.type = 'ENTITY';
      element.siteId = entity.SiteId;
      element.searchName = entity.Name;
      element.status = true;
      element.url = `/sites/${entity.SiteId}/entities/entity/${entity.EntityId}`;
      element.name = `${intl.formatMessage({ id: 'siteSegments.entity', defaultMessage: 'Entity1' })} - ${entity.Name}`;
      element.icon = sourcePoint; //getIconFromEntityType(entity.EntityTypeId);

      element.items = [
        {
          name: intl.formatMessage({ id: 'siteSegments.waterSupplyDescription', defaultMessage: 'Water Supply Description' }),
          value: `${entity.WaterSupplyDescription}`,
          icon: wsdIcon
        },
        {
          name: intl.formatMessage({ id: 'siteSegments.entityType', defaultMessage: 'Entity Type' }),
          value: `${entity.EntityType}`,
          icon: transfer
        },
        {
          name: intl.formatMessage({ id: 'siteSegments.entitySubType', defaultMessage: 'Entity Subtype' }),
          value: `${entity.EntitySubType}`,
          icon: node
        }
      ];

      if (entity.GpsLatitude && entity.GpsLongitude) {
        let newLat = parseFloat(entity.GpsLatitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        let newLng = parseFloat(entity.GpsLongitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        element.position = { lat: newLat, lng: newLng };
      } else {
        element.position = {
          lat: -37.840935 + (Math.random() * (0.00004 - 0.00001) + 0.00001),
          lng: 144.946457 + (Math.random() * (0.00004 - 0.00001) + 0.00001)
        };
      }

      return element;
    });
  };

  const getIconFromEntityType = entityTypeId => {
    let icon = droplet;
    switch (entityTypeId) {
      case 1:
        icon = catchement;
        break;
      case 2:
        icon = sourcePoint;
        break;
      case 3:
        icon = transfer;
        break;
      case 4:
        icon = storage;
        break;
      case 5:
        icon = abstractionPoint;
        break;
      case 6:
        icon = treatement;
        break;
      case 7:
        icon = node;
        break;
    }
    return icon;
  };

  const formatHazardEvents = hazardEvents => {
    return hazardEvents.map(event => {
      let element = {};
      element.elementId = 'HAZARDEVENT-' + event.HazardEventId;
      element.type = 'HAZARDEVENT';
      element.siteId = event.SithazardEventeId;
      element.status = true;
      element.searchName = event.Name;
      element.url = `/hazard-events/hazard-event-view/${event.HazardEventId}`;
      element.name = `${intl.formatMessage({ id: 'siteSegments.hazardEvent', defaultMessage: 'Hazard Event' })} - ${event.Name}`;
      element.icon = hazardIcon;

      element.items = [
        {
          name: intl.formatMessage({ id: 'siteSegments.waterSupplyDescription', defaultMessage: 'Water Supply Description' }),
          value: `${event.WaterSupplyDescriptionName}`,
          icon: wsdIcon
        },
        {
          name: intl.formatMessage({ id: 'siteSegments.entity', defaultMessage: 'Entity' }),
          value: `${event.Entity}`,
          icon: sourcePoint
        },
        {
          name: intl.formatMessage({ id: 'siteSegments.entityType', defaultMessage: 'Entity Type' }),
          value: `${event.EntityTypeName}`,
          icon: transfer
        },
        {
          name: intl.formatMessage({ id: 'siteSegments.hazardCertainty', defaultMessage: 'Hazard Certainty' }),
          value: `${event.HazardCertainty}`,
          icon: hazardCertainty
        }
      ];

      if (event.GpsLatitude && event.GpsLongitude) {
        let newLat = parseFloat(event.GpsLatitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        let newLng = parseFloat(event.GpsLongitude) + (Math.random() * (0.00004 - 0.00001) + 0.00001); // * (Math.random() * (max - min) + min);
        element.position = { lat: newLat, lng: newLng };
      } else {
        element.position = {
          lat: -37.840935 + (Math.random() * (0.00004 - 0.00001) + 0.00001),
          lng: 144.946457 + (Math.random() * (0.00004 - 0.00001) + 0.00001)
        };
      }

      return element;
    });
  };

  const handleClick = url => {
    dispatch(push(url));
  };

  return (
    <IntlProvider locale={locale} messages={langObject}>
      <div>
        {siteSegments.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={siteSegments.showBanner}
          status={siteSegments.isOpSuccessful}
          successText={messageText}
        />
        <div>
          <MapView elements={formatSiteSegments()} handleClick={handleClick} languageObject={langObject} />
        </div>
      </div>
    </IntlProvider>
  );
};

export default injectIntl(Map);
