import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';
import _ from 'lodash';

import {
  QUALITY_GOALS_ERROR,
  GET_ALL_QUALITY_GOALS_DETAILS,
  QUALITY_GOALS_IS_LOADING,
  SET_QUALITY_GOALS_CURRENT_PAGE,
  SET_QUALITY_GOALS_PAGE_FILTER,
  SET_QUALITY_GOALS_CHANGE,
  SET_QUALITY_GOALS,
  UPDATE_QUALITY_GOALS_SAVE_STATUS,
  SET_QUALITY_GOALS_FILTERS,
  SET_QUALITY_GOALS_RESULTS,
  REMOVE_QUALITY_GOALS
} from '../../constants/index';

import { QUALITY_GOALS_MANAGEMENT, CREATE_QUALITY_GOALS, UPDATE_QUALITY_GOALS } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setQualityGoalsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setQualityGoalsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setQualityGoalsError(messageCode, false));
  }, 2500);
};

export const setQualityGoalsError = (messageCode, status) => {
  return {
    type: QUALITY_GOALS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: QUALITY_GOALS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getQualityGoalsThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getQualityGoals(filterObj, widgetCode));
};

export const getQualityGoals = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/qualitygoals/qualitygoalsreport`,
    method: 'GET',
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      FilterSiteId: filterObj.filterSiteId,
      FilterEntityId: filterObj.filterEntityId,
      FilterEntityTypeId: filterObj.filterEntityTypeId,
      FilterTestPurposeId: filterObj.filterTestPurposeId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllQualityGoalsListThunk(retData),
    onFailure: err => setQualityGoalsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllQualityGoalsListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let qualityGoals = (retData && retData.retData.data) || [];
  dispatch(setAllQualityGoalsList(messageCode, qualityGoals));
};

export const setAllQualityGoalsList = (messageCode, qualityGoals) => ({
  type: GET_ALL_QUALITY_GOALS_DETAILS,
  payload: {
    data: {
      qualityGoals: qualityGoals,
      filteredResults: qualityGoals,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_QUALITY_GOALS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_QUALITY_GOALS_PAGE_FILTER,
  payload: { filter }
});

export const describeQualityGoalsThunk = (QualityGoalReportId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeQualityGoals(QualityGoalReportId, widgetCode, orgId));
};

export const describeQualityGoals = (QualityGoalReportId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/qualitygoals/qualitygoalsreport`,
    data: {
      QualityGoalReportId: QualityGoalReportId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setQualityGoalsThunk(QualityGoalReportId, retData),
    onFailure: err => setQualityGoalsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setQualityGoalsThunk = (QualityGoalReportId, { retData }) => dispatch => {
  let selectedQualityGoals = retData.data;
  if (
    (!selectedQualityGoals && QualityGoalReportId !== null && parseInt(QualityGoalReportId) !== 0) ||
    (QualityGoalReportId &&
      selectedQualityGoals &&
      Object.keys(selectedQualityGoals).length > 0 &&
      parseInt(QualityGoalReportId) !== selectedQualityGoals.QualityGoalReportId)
  ) {
    redirectToPageNotFound(dispatch);
  } else {
    let details = retData.details || [];
    let testPurposes = retData.testPurposes || [];
    let testCategories = retData.testCategories || [];
    let files = retData.files || [];

    //Grouping TestCategories based on TestCategoryId
    let groupData = _.groupBy(testCategories, item => {
      return item.TestCategoryId;
    });
    let groupMapTestCategories = _.map(groupData, function(group) {
      return {
        TestCategoryId: group[0].TestCategoryId,
        Name: group[0].CategoryName,
        Description: group[0].CategoryDescription,
        IsActive: group[0].IsActive,
        testCategoryTypes:
          group && group[0].TestCategoryItemId
            ? group.map(item => {
                return {
                  TestCategoryItemId: item.TestCategoryItemId,
                  Name: item.Name,
                  Description: item.Description,
                  UnitOfMeasure: item.UnitOfMeasure,
                  RealisticMeanValue: item.RealisticMeanValue || '',
                  RealisticMaxValue: item.RealisticMaxValue || '',
                  RealisticLRV: item.RealisticLRV || '',
                  AspirationalMeanValue: item.AspirationalMeanValue || '',
                  AspirationalMaxValue: item.AspirationalMaxValue || '',
                  AspirationalLRV: item.AspirationalLRV || '',
                  Guidentifier: item.Guidentifier
                };
              })
            : []
      };
    });

    //Grouping QualityGoals Details based on TestCategoryId
    let groupQualityGoalsData = _.groupBy(details, item => {
      return item.TestCategoryId;
    });
    let groupMapResultItems = _.map(groupQualityGoalsData, function(group) {
      return {
        TestCategoryId: group[0].TestCategoryId,
        CategoryName: group[0].CategoryName,
        testCategoryTypes:
          group && group[0].QualityGoalReportItemId
            ? group.map(item => {
                return {
                  QualityGoalReportItemId: item.QualityGoalReportItemId,
                  TestCategoryItemId: item.TestCategoryItemId,
                  Name: item.Name,
                  RealisticMeanValue: item.RealisticMeanValue,
                  RealisticMaxValue: item.RealisticMaxValue,
                  RealisticLRV: item.RealisticLRV,
                  AspirationalMeanValue: item.AspirationalMeanValue,
                  AspirationalMaxValue: item.AspirationalMaxValue,
                  AspirationalLRV: item.AspirationalLRV,
                  Description: item.Description,
                  UnitOfMeasure: item.UnitOfMeasure,
                  GuidIdentifier: item.GuidIdentifier,
                  IsActive: item.IsActive,
                  IsDeleted: item.IsDeleted
                };
              })
            : []
      };
    });

    let mapData = {
      selectedQualityGoals: {
        ...selectedQualityGoals,
        resultItems: groupMapResultItems,
        testPurposes: testPurposes,
        testCategories: groupMapTestCategories,
        files: files
      }
    };

    dispatch(setQualityGoals(mapData));
  }
};

export const setQualityGoals = data => ({
  type: SET_QUALITY_GOALS,
  payload: data
});

export const saveQualityGoalsThunk = (qualityGoal, widgetCode) => dispatch => {
  let url = '/qualitygoals/qualitygoalsreport/save';
  let actionName = qualityGoal.QualityGoalReportId === 0 ? CREATE_QUALITY_GOALS : UPDATE_QUALITY_GOALS;
  let log = logEntry(QUALITY_GOALS_MANAGEMENT, actionName, qualityGoal);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  qualityGoal.OrganisationId = orgId;
  dispatch(saveQualityGoals(qualityGoal, url, log, widgetCode));
};

export const saveQualityGoals = (qualityGoal, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setQualityGoalsStatusThunk(retData, qualityGoal),
    onFailure: err => setQualityGoalsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: qualityGoal,
      log: log
    },
    widgetcode: widgetCode
  });

export const setQualityGoalsStatusThunk = (message, qualityGoals) => dispatch => {
  dispatch(setQualityGoalsStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !qualityGoals.PerformDelete;
    dispatch(setQualityGoalsStatus(message, false, isRedirect));
    if (qualityGoals.PerformDelete) {
      dispatch(removeQualityGoals(qualityGoals.QualityGoalReportId));
    }
  }, 2500);
};

export const setQualityGoalsStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_QUALITY_GOALS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const removeQualityGoals = data => ({
  type: REMOVE_QUALITY_GOALS,
  payload: {
    qualityGoalReportId: data
  }
});

export const setQualityGoalsChangeThunk = retData => dispatch => {
  dispatch(setQualityGoalsChange(retData));
};

export const setQualityGoalsChange = retData => ({
  type: SET_QUALITY_GOALS_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      SiteId: siteId || null,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fTestPurposes: true,
      filterFor: 'qualityGoalsListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setQualityGoalsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_QUALITY_GOALS_FILTERS,
  payload: {
    data: {
      filterData: {
        TestPurposes: filterData.testPurposes
      },
      isLoading: false,
      qualityGoalsListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_QUALITY_GOALS_RESULTS,
  payload: retData
});
