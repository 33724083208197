import React, { useState, useEffect } from 'react';
import s from './ImprovementPlan.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialImprovementPlanState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { compareValues, isRequired } from '../../../utils';
import Cookies from 'js-cookie';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import uuid from 'uuid';
import { GENERAL_STATUS, FREQUENCY } from '../../../constants/index';
import DateTimePicker from '../../WSAControls/DateTimePicker/DateTimePicker';
import moment from 'moment-timezone';
import { getImprovementPlan, getHazardEvent, getGeneralData, getCurrentTimezone } from '../../../selectors/index';
import {
  describeImprovementPlanThunk,
  saveImprovementPlanThunk,
  setImprovementPlanChangeThunk
} from '../../../store/actions/hazardEvent/improvementPlan';
import { getHazardEventThunk } from '../../../store/actions/hazardEvent/hazardEvent';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';
import Breadcrumb from '../../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const ImprovementPlan = ({ intl }) => {
  let history = useHistory();
  let { hazardEventId, improvementPlanId, siteId, entityId, segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'control';
  selectedTab = selectedTab || '3';
  const dispatch = useDispatch();

  const improvementPlan = useSelector(state => getImprovementPlan(state));
  const hazardEvent = useSelector(state => getHazardEvent(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);
  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const generalData = useSelector(state => getGeneralData(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);

  let IsEdit = false;
  let isLoading = generalData.isLoading || improvementPlan.isLoading || hazardEvent.isLoading;

  const [localformErrors, setFormErrors] = useState({});

  let backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/improvement-plans`;
  let widgetCode = 'WQSP_IMPROVEMENT_PLAN_ADDNEW';
  if (segmentName === 'define') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans`;
  }
  if (segmentName === 'identify') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans`;
  }

  if (improvementPlanId) {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/improvement-plans/improvement-plan-view/${improvementPlanId}`;
    IsEdit = true;
    widgetCode = 'WQSP_IMPROVEMENT_PLAN_EDIT';
    if (segmentName === 'define') {
      backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans/improvement-plan-view/${improvementPlanId}`;
    }
    if (segmentName === 'identify') {
      backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans/improvement-plan-view/${improvementPlanId}`;
    }
  }

  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  useEffect(() => {
    if (improvementPlanId) {
      dispatch(describeImprovementPlanThunk(improvementPlanId, widgetCode));
    }
  }, [describeImprovementPlanThunk, dispatch, improvementPlanId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setImprovementPlanChangeThunk({
          ...initialImprovementPlanState.improvementPlan
        })
      );
    };
  }, []);

  useEffect(() => {
    if (hazardEventId) {
      setLocalImprovementPlan({
        ...improvementPlan,
        selectedImprovementPlan: { ...improvementPlan.selectedImprovementPlan, HazardEventId: parseInt(hazardEventId) }
      });
    }
  }, [hazardEventId]);

  useEffect(() => {
    if (siteId) {
      let filterObj = {
        filterSiteId: siteId
      };
      dispatch(getHazardEventThunk('WQSP_HAZARDEVENT_LIST', filterObj));
    }
  }, [getHazardEventThunk, siteId]);

  // Update redux store
  const setLocalImprovementPlan = currentState => {
    dispatch(setImprovementPlanChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = improvementPlan.selectedImprovementPlan;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ImprovementPlanId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_IMPROVEMENT_PLAN_ADDNEW';
    }

    dispatch(saveImprovementPlanThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !improvementPlan.selectedImprovementPlan ||
      !improvementPlan.selectedImprovementPlan.HazardEventId ||
      isRequired(improvementPlan.selectedImprovementPlan.HazardEventId, 1)
    ) {
      formErrors.HazardEvent = intl.formatMessage({
        id: 'common.hazardEventRequired',
        defaultMessage: 'Hazard Event is a mandatory field'
      });
      isValid = false;
    }

    if (!improvementPlan.selectedImprovementPlan || !improvementPlan.selectedImprovementPlan.HazardEventId) {
      formErrors.HazardEvent = intl.formatMessage({
        id: 'improvementPlan.hazardEventRequired',
        defaultMessage: 'Hazard event is a mandatory field'
      });
      isValid = false;
    }

    if (!improvementPlan.selectedImprovementPlan || !improvementPlan.selectedImprovementPlan.Name) {
      formErrors.Name = intl.formatMessage({
        id: 'improvementPlan.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    if (!improvementPlan.selectedImprovementPlan || !improvementPlan.selectedImprovementPlan.DateRaised) {
      formErrors.DateRaised = intl.formatMessage({
        id: 'improvementPlan.dateRaisedRequired',
        defaultMessage: 'Date Raised is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalImprovementPlan({
      ...improvementPlan,
      selectedImprovementPlan: { ...improvementPlan.selectedImprovementPlan, [e.target.name]: e.target.value }
    });
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();
    setLocalImprovementPlan({
      ...improvementPlan,
      selectedImprovementPlan: { ...improvementPlan.selectedImprovementPlan, [fieldName]: changeDate }
    });
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const hazardEventOptions = () => {
    let opData = [];
    const data = hazardEvent.hazardEvents.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.HazardEventId
      });
    });
    return opData;
  };

  const onStatusChange = e => {
    setLocalImprovementPlan({
      ...improvementPlan,
      selectedImprovementPlan: { ...improvementPlan.selectedImprovementPlan, [e.target.name]: e.target.value, StatusId: null }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalImprovementPlan({
      ...improvementPlan,
      selectedImprovementPlan: { ...improvementPlan.selectedImprovementPlan, [fieldName]: e.value }
    });
  };

  let messageId = (improvementPlan && improvementPlan.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const editName = (improvementPlan.selectedImprovementPlan ? improvementPlan.selectedImprovementPlan.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'improvementPlan.editTextCC', defaultMessage: 'Edit' }) + ' - ' + editName;
  } else {
    heading = intl.formatMessage({ id: 'improvementPlan.addImprovementPlan', defaultMessage: 'Add new Improvement Plan' });
  }

  let breadcrumbHeading = intl.formatMessage({
    id: 'siteControlsTabs.listOfImprovementPlans',
    defaultMessage: 'List of Improvement Plans'
  });

  let hazardEventName = improvementPlan.selectedImprovementPlan.HazardEventName;
  if (hazardEventId) {
    let selectedItem = hazardEvent.hazardEvents.find(x => x.HazardEventId == hazardEventId) || {};
    if (Object.keys(selectedItem).length > 0) {
      hazardEventName = selectedItem.Name;
    }
  }
  let hazardEventHeading =
    intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + ' (' + hazardEventName + ')';
  let improvementPlanHeading = intl.formatMessage({ id: 'improvementPlan.viewImprovementPlan', defaultMessage: 'View Improvement Plan' });

  const breadcrumbControl = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/improvement-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: improvementPlanHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/improvement-plans/improvement-plan-view/${improvementPlanId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="improvementPlan.improvementPlans" defaultMessage="Improvement Plans" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 5,
      name: improvementPlanHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans/improvement-plan-view/${improvementPlanId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 6, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const identifyHazardEventcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="improvementPlan.improvementPlans" defaultMessage="Improvement Plans" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: improvementPlanHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/improvement-plans/improvement-plan-view/${improvementPlanId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    { orderNo: 5, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const getBreadcrumbData = () => {
    if (segmentName === 'define') {
      return defineEntityCrumb;
    } else if (segmentName === 'identify') {
      return identifyHazardEventcrumb;
    } else {
      return breadcrumbControl;
    }
  };

  return (
    <div className={s.improvementPlan}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {isLoading && <LoadingSpinner />}
        {improvementPlan.isRedirect ? <Redirect to={backUrl} /> : ''}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={improvementPlan.showBanner}
          status={improvementPlan.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          <Breadcrumb data={getBreadcrumbData()} segmentName={segmentName} />
          <div className={s.improvementPlanContent}>
            <Form>
              <div className={s.improvementPlanMainInfo}>
                {!hazardEventId && (
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formHazardEvent">
                        <Form.Label>
                          <FormattedMessage id="controlMeasure.hazardEvent" defaultMessage="Hazard Event" />
                        </Form.Label>

                        <Dropdown
                          id="formHazardEvent"
                          dataArray={hazardEventOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: localformErrors.HazardEvent ? s.ddlError : ''
                          }}
                          name="HazardEventId"
                          onDropdownChange={e => onDropdownChange(e, 'HazardEventId')}
                          selectedOption={hazardEventOptions().filter(
                            option => option.value === improvementPlan.selectedImprovementPlan.HazardEventId
                          )}
                          disabled={IsEdit}
                        />
                        {localformErrors && localformErrors.HazardEvent && (
                          <p role="alert" className={s.error}>
                            {localformErrors.HazardEvent}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formImprovementPlanName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={improvementPlan.selectedImprovementPlan.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formImprovementPlanDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={improvementPlan.selectedImprovementPlan.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formImprovementPlanDateRaised">
                      <Form.Label>
                        <FormattedMessage id="improvementPlan.dateRaised" defaultMessage="Date Raised" />
                      </Form.Label>

                      <DateTimePicker
                        className={`${localformErrors.DateRaised ? s.formControlError : s.frmCalendar}`}
                        onChange={m => {
                          onChange({ target: { name: 'DateRaised', value: (m && m.unix && m.unix()) || null } });
                        }}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(improvementPlan.selectedImprovementPlan.DateRaised)}
                        defaultValue={getFormattedate(improvementPlan.selectedImprovementPlan.DateRaised)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.DateRaised && (
                        <p role="alert" className={s.error}>
                          {localformErrors.DateRaised}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formImprovementPlanDateDue">
                      <Form.Label>
                        <FormattedMessage id="improvementPlan.dateDue" defaultMessage="Date Due" />
                      </Form.Label>

                      <DateTimePicker
                        className={s.frmCalendar}
                        onChange={m => {
                          onChange({ target: { name: 'DateDue', value: (m && m.unix && m.unix()) || null } });
                        }}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(improvementPlan.selectedImprovementPlan.DateDue)}
                        defaultValue={getFormattedate(improvementPlan.selectedImprovementPlan.DateDue)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formLastReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        isValidDate={disableFutureDates}
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'LastReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(improvementPlan.selectedImprovementPlan.LastReviewDate)}
                        defaultValue={getFormattedate(improvementPlan.selectedImprovementPlan.LastReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formNextReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'NextReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(improvementPlan.selectedImprovementPlan.NextReviewDate)}
                        defaultValue={getFormattedate(improvementPlan.selectedImprovementPlan.NextReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.NextReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.NextReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formGroupReviewFrequency">
                      <Form.Label>
                        <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                      </Form.Label>

                      <Dropdown
                        id="formMaintenanceFrequency"
                        dataArray={frequencyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="ReviewFrequencyId"
                        onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                        selectedOption={frequencyOptions().filter(
                          option => option.value === improvementPlan.selectedImprovementPlan.ReviewFrequencyId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formActioned">
                      <Form.Label>
                        <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                      </Form.Label>

                      <ToggleSwitch
                        handleClick={() => {
                          onStatusChange({ target: { name: 'IsActioned', value: !improvementPlan.selectedImprovementPlan.IsActioned } });
                        }}
                        classname={s.switch}
                        checked={improvementPlan.selectedImprovementPlan.IsActioned}
                        labelChecked={yesText}
                        labelUnchecked={noText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!improvementPlan.selectedImprovementPlan.IsActioned && (
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formStatus">
                        <Form.Label>
                          <FormattedMessage id="common.status" defaultMessage="Status" />
                        </Form.Label>

                        <Dropdown
                          id="formStatus"
                          dataArray={statusOptions()}
                          controlData={{ placeholderText: ddlInitText }}
                          onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                          data-unittest="formDdlRiskStatus"
                          selectedOption={statusOptions().filter(
                            option => option.value === improvementPlan.selectedImprovementPlan.StatusId
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            </Form>
          </div>
          <Row>
            <Col>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChanges)}
                onClick={submitForm}
                noValidate
                data-unittest="saveData"
                disabled={isLoading}
              >
                <FormattedMessage id="common.save" defaultMessage="SAVE" />
              </Button>

              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancel)}
                onClick={cancelHandler}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
          </Row>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

ImprovementPlan.defaultProps = {
  improvementPlan: {
    ...initialImprovementPlanState
  }
};

export default injectIntl(ImprovementPlan);
