import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './TestResultReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialTestResultState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Link, useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { compareValues, goBack } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import TestResultReportItemTable from './TestResultReportItemTable';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import 'react-datetime/css/react-datetime.css';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import { getUserProfileWidget, getGeneralData, getTestResult, getCurrentTimezone } from '../../selectors/index';
import { describeTestResultThunk, saveTestResultThunk, setTestResultChangeThunk } from '../../store/actions/testResults';
import GoBack from '../WSAControls/GoBack/GoBack';
import FileList from '../WSAControls/FileList/FileList';
import { FREQUENCY, GENERAL_STATUS, DOCUMENT_TYPE_TEST_RESULT } from '../../constants/index';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';
import IncidentReportItemsTable from '../IncidentReport/IncidentReportItemsTable';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const TestResultReport = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  const history = useHistory();
  let { testResultReportId, siteId, segmentName, selectedTab } = useParams();

  let backUrl = '/test-results';
  if (segmentName === 'measure') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/test-results`;
  }

  const [localformErrors, setFormErrors] = useState({});
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [disableMode, setDisableMode] = useState(false);
  const [fileUploadStarted, setFileUploadStarted] = useState(false);

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);
  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const testResult = useSelector(state => getTestResult(state));
  const generalData = useSelector(state => getGeneralData(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TESTRESULTSREPORT_EDIT');
  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  if (testResultReportId && testResultReportId > 0) {
    IsEdit = true;
  } else {
    testResultReportId = 0;
  }
  let widgetCode = IsEdit ? 'WQSP_TESTRESULTSREPORT_EDIT' : 'WQSP_TESTRESULTSREPORT_ADDNEW';

  useEffect(() => {
    dispatch(describeTestResultThunk(testResultReportId, widgetCode));
  }, [describeTestResultThunk, testResultReportId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setTestResultChangeThunk({
          ...initialTestResultState.testResult
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalTestResult = currentState => {
    dispatch(setTestResultChangeThunk(currentState));
  };

  useEffect(() => {
    if (siteId && !testResult.selectedTestResult.SiteId)
      setLocalTestResult({
        ...testResult,
        selectedTestResult: { ...testResult.selectedTestResult, SiteId: parseInt(siteId) }
      });
  }, [setLocalTestResult, siteId]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }
    let testCategories = [];
    testResult.selectedTestResult.resultItems.map(item => {
      testCategories = [...testCategories, ...item.testCategoryTypes];
    });
    let saveData = {
      ...testResult.selectedTestResult,
      resultItems: (testResult.selectedTestResult.resultItems && testCategories) || [],
      testPurposes: [],
      testCategories: [],
      selectedTestResultItem: {}
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.TestResultReportId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    dispatch(saveTestResultThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!testResult.selectedTestResult || !testResult.selectedTestResult.SiteId) {
      formErrors.Site = intl.formatMessage({
        id: 'wqsp.common.siteMandatory',
        defaultMessage: 'Site name is mandatory field'
      });
      isValid = false;
    }

    if (!testResult.selectedTestResult || !testResult.selectedTestResult.WaterSupplyDescriptionId) {
      formErrors.WaterSupply = intl.formatMessage({
        id: 'wqsp.common.waterSupplyMandatory',
        defaultMessage: 'Water supply name is mandatory field'
      });
      isValid = false;
    }

    if (!testResult.selectedTestResult || !testResult.selectedTestResult.EntityTypeId) {
      formErrors.EntityType = intl.formatMessage({
        id: 'wqsp.common.entityTypeMandatory',
        defaultMessage: 'Entity type is mandatory field'
      });
      isValid = false;
    }

    if (!testResult.selectedTestResult || !testResult.selectedTestResult.EntityId) {
      formErrors.Entity = intl.formatMessage({
        id: 'wqsp.common.entityMandatory',
        defaultMessage: 'Entity is mandatory field'
      });
      isValid = false;
    }

    if (!testResult.selectedTestResult || !testResult.selectedTestResult.SampleDate) {
      formErrors.SampleDate = intl.formatMessage({
        id: 'testResult.sampleDateMandatory',
        defaultMessage: 'Sample date is mandatory field'
      });
      isValid = false;
    }

    if (!testResult.selectedTestResult || !testResult.selectedTestResult.SampleIdNumber) {
      formErrors.SampleIdNumber = intl.formatMessage({
        id: 'testResult.sampleIdNumberMandatory',
        defaultMessage: 'Sample ID number is mandatory field'
      });
      isValid = false;
    }

    if (!testResult.selectedTestResult || !testResult.selectedTestResult.SamplerName) {
      formErrors.SamplerName = intl.formatMessage({
        id: 'testResult.samplerNameMandatory',
        defaultMessage: 'Sampler name is mandatory field'
      });
      isValid = false;
    }

    if (!testResult.selectedTestResult || !testResult.selectedTestResult.TestPurposeId) {
      formErrors.TestPurpose = intl.formatMessage({
        id: 'wqsp.common.testPurposeMandatory',
        defaultMessage: 'Test purpose is mandatory field'
      });
      isValid = false;
    }

    let testCategories = [];
    testResult.selectedTestResult.resultItems.map(item => {
      testCategories = [...testCategories, ...item.testCategoryTypes];
    });

    if (testCategories.length === 0 || testCategories.filter(item => !item.IsDeleted).lenghth === 0) {
      formErrors.TestResult = intl.formatMessage({
        id: 'testResult.testResultMandatory',
        defaultMessage: 'Test result is mandatory field'
      });
      isValid = false;
    }

    if (testCategories.length > 0) {
      if (testCategories.filter(item => !item.IsDeleted && (!item.Value || item.Value === '')).length > 0) {
        formErrors.TestResult = intl.formatMessage({
          id: 'testResult.valueMandatory',
          defaultMessage: 'Value is mandatory field'
        });
        isValid = false;
      }
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalTestResult({
        ...testResult,
        selectedTestResult: { ...testResult.selectedTestResult, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalTestResult({
        ...testResult,
        selectedTestResult: { ...testResult.selectedTestResult, [e.target.name]: e.target.value }
      });
    }
  };

  const onAddReportItem = testType => {
    let testResultItems = (testResult.selectedTestResult.resultItems && [...testResult.selectedTestResult.resultItems]) || [];

    let categoryFound = false;
    testType.GuidIdentifier = uuidv4();
    testType.TestResultReportItemId = 0;
    testType.IsActive = true;
    testType.IsDeleted = false;

    testResultItems = testResultItems.map(item => {
      if (!item.IsDeleted && item.TestCategoryId === testType.TestCategoryId) {
        categoryFound = true;
        if (!item.testCategoryTypes) item.testCategoryTypes = [];
        item.testCategoryTypes.push(testType);
      }
      return item;
    });

    if (!categoryFound) {
      testResultItems.push({
        TestCategoryId: testType.TestCategoryId,
        CategoryName: testType.CategoryName,
        testCategoryTypes: [
          {
            ...testType
          }
        ]
      });
    }
    setShowAddEdit(false);
    dispatch(
      setTestResultChangeThunk({
        selectedTestResult: {
          resultItems: testResultItems
        }
      })
    );
  };

  const onChangeReportItem = testResultItems => {
    dispatch(
      setTestResultChangeThunk({
        selectedTestResult: {
          resultItems: testResultItems
        }
      })
    );
  };

  const siteOptions = () => {
    let siteData = [];
    generalData.sites.forEach(element => {
      siteData.push({
        ...element,
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onSiteDropdownChange = e => {
    setLocalTestResult({
      selectedTestResult: {
        ...testResult.selectedTestResult,
        SiteId: e.value,
        WaterSupplyDescriptionId: 0,
        EntityId: 0
      }
    });
  };

  const waterSupplyOptions = () => {
    let waterSupplyData = [];
    const filteredData = generalData.waterSupplyDescriptions.filter(item => item.SiteId === testResult.selectedTestResult.SiteId);
    filteredData.forEach(element => {
      waterSupplyData.push({
        ...element,
        label: element.Name,
        value: element.WaterSupplyDescriptionId
      });
    });

    let sortedData = waterSupplyData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onWaterSupplyDropdownChange = e => {
    setLocalTestResult({
      selectedTestResult: {
        ...testResult.selectedTestResult,
        WaterSupplyDescriptionId: e.value
      }
    });
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];
    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityTypeDropdownChange = e => {
    setLocalTestResult({
      selectedTestResult: {
        ...testResult.selectedTestResult,
        EntityTypeId: e.value,
        EntityId: 0
      }
    });
  };

  const entityOptions = () => {
    let entityData = [];
    let entitySubTypes = generalData.entitySubTypes.filter(item => item.EntityTypeId === testResult.selectedTestResult.EntityTypeId);
    let entities = generalData.entities.filter(item =>
      entitySubTypes.find(
        type =>
          type.EntitySubTypeId === item.EntitySubTypeId &&
          item.WaterSupplyDescriptionId === testResult.selectedTestResult.WaterSupplyDescriptionId
      )
    );
    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityDropdownChange = e => {
    setLocalTestResult({
      selectedTestResult: {
        ...testResult.selectedTestResult,
        EntityId: e.value
      }
    });
  };

  const testPurposeOptions = () => {
    let purposeData = [];
    let data = testResult.selectedTestResult.testPurposes;
    data.forEach(element => {
      purposeData.push({
        ...element,
        label: element.Name,
        value: element.TestPurposeId
      });
    });

    let sortedData = purposeData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onTestPurposeDropdownChange = e => {
    setLocalTestResult({
      selectedTestResult: {
        ...testResult.selectedTestResult,
        TestPurposeId: e.value
      }
    });
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  const onUploadStart = files => {
    setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          TestResultReportId: 0,
          ImageId: item.fileGuid,
          newFile: true,
          S3URL: item.S3URL,
          Name: item.name
        };
      });
      let allFiles = [...testResult.selectedTestResult.files, ...newFiles];
      setLocalTestResult({
        selectedTestResult: {
          ...testResult.selectedTestResult,
          files: allFiles
        }
      });
    }
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();
    setLocalTestResult({
      selectedTestResult: {
        ...testResult.selectedTestResult,
        [fieldName]: changeDate
      }
    });
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };
  const onStatusChange = e => {
    setLocalTestResult({
      selectedTestResult: {
        ...testResult.selectedTestResult,
        [e.target.name]: e.target.value,
        StatusId: null
      }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalTestResult({
      selectedTestResult: {
        ...testResult.selectedTestResult,
        [fieldName]: e.value
      }
    });
  };

  const removeFile = fileGuid => {
    let files = testResult.selectedTestResult.files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...testResult.selectedTestResult.deletedFileIds];
    deletedFileIds.push(fileGuid);
    setLocalTestResult({
      ...testResult,
      selectedTestResult: { ...testResult.selectedTestResult, files: files, deletedFileIds: deletedFileIds }
    });
  };

  let messageId = (testResult && testResult.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' });
    heading = `${heading} - ${testResult.selectedTestResult.SampleIdNumber}`;
  } else {
    heading = intl.formatMessage({ id: 'testResult.addNewTestResult', defaultMessage: 'Add new Test Result' });
  }

  let breadcrumbHeading = intl.formatMessage({
    id: 'siteControlsTabs.listOfTestSamples',
    defaultMessage: 'List of Test Samples'
  });

  const breadcrumbMeasure = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/test-results`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const getBreadcrumbData = () => {
    if (segmentName === 'measure') {
      return breadcrumbMeasure;
    }
    return breadcrumbMeasure;
  };

  if (testResult.isRedirect) goBack(history, backUrl);

  return (
    <SiteDashboardControlsContentTemplate
      selectedPage={selectedTab}
      siteId={siteId}
      segmentName={segmentName}
      userProfileWidgets={userProfileWidgets}
    >
      <div className={s.testresult}>
        {!segmentName && (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="testResult.testResults" defaultMessage="Test Results" />}</h1>
          </div>
        )}
        {testResult.isLoading && <LoadingSpinner />}

        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={testResult.showBanner}
          status={testResult.isOpSuccessful}
          successText={messageText}
        />
        <div className={s.contentWrapper}>
          {segmentName && <Breadcrumb data={getBreadcrumbData()} segmentName={segmentName} />}
          <div className={s.testresultHeader}>
            {!segmentName && (
              <GoBack className={s.backLink}>
                &lt; &nbsp;
                <FormattedMessage id="common.back" defaultMessage="BACK" />
              </GoBack>
            )}
            {!segmentName && <h3 data-unittest="headingLabel">{heading}</h3>}
          </div>
          <div className={s.testresultContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultSiteName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlSite"
                      dataArray={siteOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Site && s.ddlError }}
                      onDropdownChange={onSiteDropdownChange}
                      data-unittest="formDdlSite"
                      selectedOption={siteOptions().filter(option => option.value === testResult.selectedTestResult.SiteId)}
                      disabled={IsEdit || siteId}
                    />
                    {localformErrors && localformErrors.Site && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Site}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultSupplyName">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlWaterSupply"
                      dataArray={waterSupplyOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.WaterSupply && s.ddlError }}
                      onDropdownChange={onWaterSupplyDropdownChange}
                      data-unittest="formDdlWaterSupply"
                      selectedOption={waterSupplyOptions().filter(
                        option => option.value === testResult.selectedTestResult.WaterSupplyDescriptionId
                      )}
                      disabled={IsEdit}
                    />
                    {localformErrors && localformErrors.WaterSupply && (
                      <p role="alert" className={s.error}>
                        {localformErrors.WaterSupply}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultEntityType">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntityType"
                      dataArray={entityTypeOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.EntityType && s.ddlError }}
                      onDropdownChange={onEntityTypeDropdownChange}
                      data-unittest="formDdlEntityType"
                      selectedOption={entityTypeOptions().filter(option => option.value === testResult.selectedTestResult.EntityTypeId)}
                      disabled={IsEdit}
                    />
                    {localformErrors && localformErrors.EntityType && (
                      <p role="alert" className={s.error}>
                        {localformErrors.EntityType}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultEntity">
                    <Form.Label>
                      <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlEntity"
                      dataArray={entityOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Entity && s.ddlError }}
                      onDropdownChange={onEntityDropdownChange}
                      data-unittest="formDdlEntity"
                      selectedOption={entityOptions().filter(option => option.value === testResult.selectedTestResult.EntityId)}
                      disabled={IsEdit}
                    />
                    {localformErrors && localformErrors.Entity && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Entity}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultSampleDate">
                    <Form.Label>
                      <FormattedMessage id="testResult.sampleDate" defaultMessage="Sample Date" />
                    </Form.Label>

                    <DateTimePicker
                      className={`${localformErrors.SampleDate ? s.formControlError : s.frmCalendar}`}
                      onChange={m => {
                        onChange({ target: { name: 'SampleDate', value: (m && m.unix && m.unix()) || null } });
                      }}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(testResult.selectedTestResult.SampleDate)}
                      defaultValue={getFormattedate(testResult.selectedTestResult.SampleDate)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                    {localformErrors && localformErrors.SampleDate && (
                      <p role="alert" className={s.error}>
                        {localformErrors.SampleDate}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultSampleIDNumber">
                    <Form.Label>
                      <FormattedMessage id="testResult.sampleIDNumber" defaultMessage="Sample ID Number" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="SampleIdNumber"
                      onChange={onChange}
                      value={testResult.selectedTestResult.SampleIdNumber}
                      className={`${s.formControl} ${localformErrors && localformErrors.SampleIdNumber ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'testResult.sampleIDNumber',
                        defaultMessage: 'Sample ID Number'
                      })}
                    />
                    {localformErrors && localformErrors.SampleIdNumber && (
                      <p role="alert" className={s.error}>
                        {localformErrors.SampleIdNumber}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultSamplerName">
                    <Form.Label>
                      <FormattedMessage id="testResult.nameOfSampler" defaultMessage="Name of Sampler" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="SamplerName"
                      onChange={onChange}
                      value={testResult.selectedTestResult.SamplerName}
                      className={`${s.formControl} ${localformErrors && localformErrors.SamplerName ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'testResult.nameOfSampler',
                        defaultMessage: 'Name of Sampler'
                      })}
                    />
                    {localformErrors && localformErrors.SamplerName && (
                      <p role="alert" className={s.error}>
                        {localformErrors.SamplerName}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultTestPurpose">
                    <Form.Label>
                      <FormattedMessage id="testResult.purpose" defaultMessage="Purpose" />
                    </Form.Label>

                    <Dropdown
                      id="formDdlTestPurpose"
                      dataArray={testPurposeOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.TestPurpose && s.ddlError }}
                      onDropdownChange={onTestPurposeDropdownChange}
                      data-unittest="formDdlTestPurpose"
                      selectedOption={testPurposeOptions().filter(option => option.value === testResult.selectedTestResult.TestPurposeId)}
                      disabled={IsEdit}
                    />
                    {localformErrors && localformErrors.TestPurpose && (
                      <p role="alert" className={s.error}>
                        {localformErrors.TestPurpose}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formLastReviewDate">
                    <Form.Label>
                      <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                    </Form.Label>
                    <DateTimePicker
                      isValidDate={disableFutureDates}
                      className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                      onChange={m => onDateChange(m, 'LastReviewDate')}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(testResult.selectedTestResult.LastReviewDate)}
                      defaultValue={getFormattedate(testResult.selectedTestResult.LastReviewDate)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formNextReviewDate">
                    <Form.Label>
                      <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                    </Form.Label>
                    <DateTimePicker
                      className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                      onChange={m => onDateChange(m, 'NextReviewDate')}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(testResult.selectedTestResult.NextReviewDate)}
                      defaultValue={getFormattedate(testResult.selectedTestResult.NextReviewDate)}
                      closeOnSelect={true}
                      showClear={false}
                    />
                    {localformErrors && localformErrors.NextReviewDate && (
                      <p role="alert" className={s.error}>
                        {localformErrors.NextReviewDate}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formGroupReviewFrequency">
                    <Form.Label>
                      <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                    </Form.Label>

                    <Dropdown
                      id="formMaintenanceFrequency"
                      dataArray={frequencyOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                      }}
                      name="ReviewFrequencyId"
                      onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                      selectedOption={frequencyOptions().filter(option => option.value === testResult.selectedTestResult.ReviewFrequencyId)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group controlId="formActioned">
                    <Form.Label>
                      <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                    </Form.Label>

                    <ToggleSwitch
                      handleClick={() => {
                        onStatusChange({ target: { name: 'IsActioned', value: !testResult.selectedTestResult.IsActioned } });
                      }}
                      classname={s.switch}
                      checked={testResult.selectedTestResult.IsActioned}
                      labelChecked={yesText}
                      labelUnchecked={noText}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {!testResult.selectedTestResult.IsActioned && (
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formStatus">
                      <Form.Label>
                        <FormattedMessage id="common.status" defaultMessage="Status" />
                      </Form.Label>

                      <Dropdown
                        id="formStatus"
                        dataArray={statusOptions()}
                        controlData={{ placeholderText: ddlInitText }}
                        onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                        data-unittest="formDdlRiskStatus"
                        selectedOption={statusOptions().filter(option => option.value === testResult.selectedTestResult.StatusId)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
          <div className={s.testresultTypeContent}>
            <TestResultReportItemTable
              resultItems={testResult.selectedTestResult.resultItems}
              testCategories={testResult.selectedTestResult.testCategories}
              setShowAddEdit={setShowAddEdit}
              showAddEdit={showAddEdit}
              disableMode={disableMode}
              setDisableMode={setDisableMode}
              onAddReportItem={onAddReportItem}
              onChangeReportItem={onChangeReportItem}
              userProfileWidgets={userProfileWidgets}
              updateAllowed={isUpdateAllowed}
              formError={localformErrors.TestResult}
              qualityReport={false}
            />
          </div>
          <div className={s.testresultContent}>
            <Form>
              <Row>
                <Col>
                  <h3>
                    <FormattedMessage id="testResult.analysis" defaultMessage="Analysis" />
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultAnalysisCompletedBy">
                    <Form.Label>
                      <FormattedMessage id="testResult.analysisCompletedBy" defaultMessage="Analysis Completed By" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="AnalysisCompletedBy"
                      onChange={onChange}
                      value={testResult.selectedTestResult.AnalysisCompletedBy}
                      className={`${s.formControl}`}
                      placeholder={intl.formatMessage({
                        id: 'testResult.analysisCompletedBy',
                        defaultMessage: 'Analysis Completed By'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultDateAnalysed">
                    <Form.Label>
                      <FormattedMessage id="testResult.dateAnalysed" defaultMessage="Date Analysed" />
                    </Form.Label>

                    <DateTimePicker
                      className={`${s.frmCalendar}`}
                      onChange={m => {
                        onChange({ target: { name: 'AnalysisDate', value: (m && m.unix && m.unix()) || null } });
                      }}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(testResult.selectedTestResult.AnalysisDate)}
                      defaultValue={getFormattedate(testResult.selectedTestResult.AnalysisDate)}
                      closeOnSelect={true}
                      showClear={false}
                      placeHolderText={intl.formatMessage({
                        id: 'testResult.dateAnalysed',
                        defaultMessage: 'Date Analysed'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultDateSubmitted">
                    <Form.Label>
                      <FormattedMessage id="testResult.dateSubmitted" defaultMessage="Date Submitted" />
                    </Form.Label>

                    <DateTimePicker
                      className={`${s.frmCalendar}`}
                      onChange={m => {
                        onChange({ target: { name: 'SubmissionDate', value: (m && m.unix && m.unix()) || null } });
                      }}
                      timeFormat={null}
                      dateFormat="DD-MMM-YYYY"
                      value={getFormattedate(testResult.selectedTestResult.SubmissionDate)}
                      defaultValue={getFormattedate(testResult.selectedTestResult.SubmissionDate)}
                      closeOnSelect={true}
                      showClear={false}
                      placeHolderText={intl.formatMessage({
                        id: 'testResult.dateSubmitted',
                        defaultMessage: 'Date Submitted'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultAnalyserName">
                    <Form.Label>
                      <FormattedMessage id="testResult.nameOfAnalyser" defaultMessage="Name of Analyser" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="AnalyserName"
                      onChange={onChange}
                      value={testResult.selectedTestResult.AnalyserName}
                      className={`${s.formControl}`}
                      placeholder={intl.formatMessage({
                        id: 'testResult.nameOfAnalyser',
                        defaultMessage: 'Name of Analyser'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestResultAddress">
                    <Form.Label>
                      <FormattedMessage id="common.address" defaultMessage="Address" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Address"
                      onChange={onChange}
                      value={testResult.selectedTestResult.Address}
                      className={`${s.formControl}`}
                      placeholder={intl.formatMessage({
                        id: 'common.address',
                        defaultMessage: 'Address'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={s.testresultContent}>
            <Form>
              <Row>
                <Col>
                  <h3>
                    <FormattedMessage id="testResult.additionalInformation" defaultMessage="Additional Information" />
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTestResultComments">
                    <Form.Label>
                      <FormattedMessage id="testResult.commentsSummary" defaultMessage="Comments/Summary" />
                    </Form.Label>

                    <Form.Control
                      className={s.textArea}
                      as="textarea"
                      rows="3"
                      name="Comments"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'testResult.commentsSummary',
                        defaultMessage: 'Comments/Summary'
                      })}
                      value={testResult.selectedTestResult.Comments}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Label>
                    <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                  </Form.Label>
                  <FileUploader
                    widgetCode={'WQSP_TESTRESULTSREPORT_ATTACH'}
                    allowedFileTypes={[
                      ['image/jpeg', 'jpeg'],
                      ['application/pdf', 'pdf'],
                      ['application/msword', 'doc']
                    ]}
                    location={`organisation/${orgId}/sites/${testResult.selectedTestResult.SiteId}/testresults`}
                    enableMultipleUpload={true}
                    maxFiles={5}
                    onUploadStart={onUploadStart}
                    onUploadComplete={onUploadComplete}
                  />
                </Col>
              </Row>
              <FileList files={testResult.selectedTestResult.files} onRemoveFile={removeFile} />
            </Form>
          </div>
          {IsEdit && (
            <IncidentReportItemsTable
              referedId={testResultReportId}
              incidentReportItems={testResult.selectedTestResult.incidentReports}
              docType={DOCUMENT_TYPE_TEST_RESULT}
              mainObj={testResult.selectedTestResult}
              userProfileWidgets={userProfileWidgets}
            />
          )}
          <div>
            <Row className={s.breakRow}>
              <Col sm={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                  disabled={disableMode || testResult.isLoading || fileUploadStarted}
                >
                  <FormattedMessage id="common.save" defaultMessage="SAVE" />
                </Button>
                <Link to={backUrl}>
                  <Button variant="outline-secondary" className={s.btnCancel} disabled={disableMode}>
                    <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </SiteDashboardControlsContentTemplate>
  );
};

export default injectIntl(TestResultReport);
