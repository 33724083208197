import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';

import {
  CHECKLIST_TEMPLATE_ERROR,
  GET_ALL_CHECKLIST_TEMPLATE_DETAILS,
  CHECKLIST_TEMPLATE_IS_LOADING,
  SET_CHECKLIST_TEMPLATE_CURRENT_PAGE,
  SET_CHECKLIST_TEMPLATE_PAGE_FILTER,
  SET_CHECKLIST_TEMPLATE_CHANGE,
  SET_CHECKLIST_TEMPLATE,
  UPDATE_CHECKLIST_TEMPLATE_SAVE_STATUS
} from '../../constants/index';

import { CHECKLIST_TEMPLATE_MANAGEMENT, CREATE_CHECKLIST_TEMPLATE, UPDATE_CHECKLIST_TEMPLATE } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setChecklistTemplateErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setChecklistTemplateError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setChecklistTemplateError(messageCode, false));
  }, 2500);
};

export const setChecklistTemplateError = (messageCode, status) => {
  return {
    type: CHECKLIST_TEMPLATE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: CHECKLIST_TEMPLATE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getChecklistTemplateThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getChecklistTemplate(widgetCode, orgId));
};

export const getChecklistTemplate = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/templates/checklists`,
    method: 'GET',
    data: {
      OrganisationId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllChecklistTemplateListThunk(retData),
    onFailure: err => setChecklistTemplateErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllChecklistTemplateListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let checklistTemplates = (retData && retData.retData.checklistTemplate) || [];
  dispatch(setAllChecklistTemplateList(messageCode, checklistTemplates));
};

export const setAllChecklistTemplateList = (messageCode, checklistTemplates) => ({
  type: GET_ALL_CHECKLIST_TEMPLATE_DETAILS,
  payload: {
    data: {
      ChecklistTemplates: checklistTemplates,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_CHECKLIST_TEMPLATE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_CHECKLIST_TEMPLATE_PAGE_FILTER,
  payload: { filter }
});

export const describeChecklistTemplateThunk = (ChecklistTemplateId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeChecklistTemplate(ChecklistTemplateId, widgetCode, orgId));
};

export const describeChecklistTemplate = (ChecklistTemplateId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/templates/checklists`,
    data: {
      ChecklistTemplateId: ChecklistTemplateId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setChecklistTemplateThunk(retData),
    onFailure: err => setChecklistTemplateErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setChecklistTemplateThunk = ({ retData }) => dispatch => {
  let data = retData.checklistTemplate;
  dispatch(setChecklistTemplate(data));
};

export const setChecklistTemplate = data => ({
  type: SET_CHECKLIST_TEMPLATE,
  payload: data
});

export const saveChecklistTemplateThunk = (checklistTemplate, widgetCode) => dispatch => {
  let url = '/management/templates/checklists/save';
  let actionName = checklistTemplate.ChecklistTemplateId === 0 ? CREATE_CHECKLIST_TEMPLATE : UPDATE_CHECKLIST_TEMPLATE;
  let log = logEntry(CHECKLIST_TEMPLATE_MANAGEMENT, actionName, checklistTemplate);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  checklistTemplate.OrganisationId = orgId;
  dispatch(saveChecklistTemplate(checklistTemplate, url, log, widgetCode));
};

export const saveChecklistTemplate = (checklistTemplate, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setChecklistTemplateStatusThunk(retData),
    onFailure: err => setChecklistTemplateErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      checklistTemplate: checklistTemplate,
      log: log
    },
    widgetcode: widgetCode
  });

export const setChecklistTemplateStatusThunk = message => dispatch => {
  dispatch(setChecklistTemplateStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setChecklistTemplateStatus(message, false));
  }, 2500);
};

export const setChecklistTemplateStatus = (messageCode, status) => ({
  type: UPDATE_CHECKLIST_TEMPLATE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setChecklistTemplateChangeThunk = retData => dispatch => {
  dispatch(setChecklistTemplateChange(retData));
};

export const setChecklistTemplateChange = retData => ({
  type: SET_CHECKLIST_TEMPLATE_CHANGE,
  payload: retData
});
