import React from 'react';
import s from './EmergencyPlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../../assets/arrow-up.png';
import descendingIcon from '../../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getDateInDDMMYYYFormat } from '../../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../../selectors/index';
import MoreOption from '../../WSAControls/MoreOption/MoreOption';

const EmergencyPlanListTable = ({
  paginatedData,
  onSortOrder,
  asendingOrder,
  viewEmergencyPlan,
  viewAllowed,
  deleteAllowed,
  removeEmergencyPlan
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
            </th>
            <th>
              <FormattedMessage id="hazardEvent.hazardEvent" defaultMessage="Hazard Event" />
            </th>
            <th>
              <FormattedMessage id="emergencyPlan.stakeholder" defaultMessage="Stakeholder" />
            </th>
            <th>
              <FormattedMessage id="emergencyPlan.reviewFrequency" defaultMessage="Review Frequency" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="emergencyPlan.newxtReviewDate" defaultMessage="Next Review Date" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            {(viewAllowed || deleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.EmergencyResponsePlanId}>
                <td>{n.Name || ''}</td>
                <td>{n.WaterSupplyDescriptionName || ''}</td>
                <td>{n.EntityTypeName || ''}</td>
                <td>{n.EntityName || ''}</td>
                <td>{n.HazardEventName || ''}</td>
                <td>{n.PersonnelName}</td>
                <td>{n.ReviewFrequencyName}</td>
                <td>{getDateInDDMMYYYFormat(n.NextReviewDate, currentTimezone) || ''}</td>

                {(deleteAllowed || viewAllowed) && (
                  <td className={s.btnCol}>
                    <MoreOption
                      dataArray={[
                        deleteAllowed
                          ? {
                              Click: () => {
                                removeEmergencyPlan(n.EmergencyResponsePlanId);
                              },
                              Name: <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                            }
                          : {},
                        viewAllowed
                          ? {
                              Click: () => {
                                viewEmergencyPlan(n.EmergencyResponsePlanId);
                              },
                              Name: <FormattedMessage id="common.view" defaultMessage="VIEW" />
                            }
                          : {}
                      ]}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

EmergencyPlanListTable.defaultProps = {
  paginatedData: [],
  hazardClasses: [],
  currentPage: 1
};

export default injectIntl(EmergencyPlanListTable);
