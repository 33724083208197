import React, { useState } from 'react';
import s from './ControlMonitoring.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import CorrectiveAction from './CorrectiveAction';
import { WidgetVisibility } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';

const CorrectiveActionListTable = ({
  correctiveActions,
  team,
  setShowAddEdit,
  showAddEdit,
  disableMode,
  setDisableMode,
  onAddClick,
  onChangeClick,
  userProfileWidgets,
  updateAllowed,
  view,
  viewAllowed,
  onViewClick,
  intl
}) => {
  const [searchFilter, setSearchFilter] = useState('');

  const searchHandler = value => {
    setSearchFilter(value);
  };

  let correctiveActionsFiltered =
    (searchFilter && correctiveActions.filter(item => item.Title.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0)) ||
    correctiveActions;

  const addNewEventItemHandler = () => {
    setShowAddEdit(true);
    setDisableMode(true);
  };

  const deleteDataHandler = (CorrectiveActionId, identifer) => {
    let correctiveActions = [...correctiveActionsFiltered] || [];
    if (CorrectiveActionId) {
      correctiveActions = correctiveActions.map(item => {
        if (item.GuidIdentifier === identifer) {
          item.IsDeleted = true;
        }
        return item;
      });
    } else if (CorrectiveActionId === 0) {
      correctiveActions = correctiveActions.filter(item => item.GuidIdentifier !== identifer);
    }
    onChangeClick(correctiveActions);
  };

  const onCancelClick = () => {
    setShowAddEdit(false);
    setDisableMode(false);
  };

  const CorrectiveActionContent = (
    <CorrectiveAction onAddClick={onAddClick} correctiveActions={correctiveActions} team={team} onCancelClick={onCancelClick} />
  );

  let newRowClass = showAddEdit ? s.newRow : '';

  return (
    <div>
      <div className={s.controlMonitoringTypeContent}>
        <Row>
          <Col>
            <h4 className={s.subheading}>
              <FormattedMessage id="correctiveAction.correctiveActions" defaultMessage="Corrective Actions" />
              <span className={s.circle}>{correctiveActionsFiltered.filter(item => !item.IsDeleted).length || 0}</span>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              initialText={searchFilter}
              placeHolderTextId="common.search"
            />
          </Col>
          <Col xs={6} lg={8}>
            {(!view && (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_CORRECTIVE_ACTIONS_ADDNEW')}
                onClick={addNewEventItemHandler}
                disabled={disableMode}
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            )) ||
              ''}
          </Col>
        </Row>
      </div>
      <div>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="correctiveAction.personnel" defaultMessage="Personnel" />
              </th>
              <th>
                <FormattedMessage id="common.title" defaultMessage="Title" />
              </th>
              <th>
                <FormattedMessage id="correctiveAction.whenActionTriggered" defaultMessage="When Action Triggered" />
              </th>
              {updateAllowed && <th></th>}
              {view && <th></th>}
            </tr>
          </thead>
          <tbody>
            {correctiveActionsFiltered.map(item => {
              return (
                !item.IsDeleted && (
                  <tr key={item.GuidIdentifier} data-unittest="CorrectiveActions">
                    <td>{item.PersonnelName}</td>
                    <td>{item.Title}</td>
                    <td>{item.WhenActionTriggered || ''}</td>
                    {updateAllowed && (
                      <td>
                        <div className={s.buttonContainer}>
                          <Button
                            variant="outline-secondary"
                            className={s.removeButton}
                            href="#"
                            onClick={deleteDataHandler.bind(this, item.CorrectiveActionId, item.GuidIdentifier)}
                            disabled={disableMode}
                            data-unittest="deleteData"
                            style={WidgetVisibility(userProfileWidgets, `WQSP_CORRECTIVE_ACTIONS_DELETE`)}
                          >
                            <FormattedMessage id="common.remove" defaultMessage="Remove" />
                          </Button>
                        </div>
                      </td>
                    )}
                    {view && viewAllowed && (
                      <td>
                        <div className={s.buttonContainer}>
                          <Button
                            variant="outline-secondary"
                            className={s.removeButton}
                            href="#"
                            onClick={() => {
                              onViewClick(item.CorrectiveActionId);
                            }}
                            disabled={disableMode}
                            data-unittest="viewData"
                          >
                            <FormattedMessage id="common.view" defaultMessage="View" />
                          </Button>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              );
            })}
            {showAddEdit && (
              <tr>
                <td colSpan={4}>
                  <div className={newRowClass}>{showAddEdit && CorrectiveActionContent}</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

CorrectiveActionListTable.defaultProps = {
  correctiveActions: {},
  userProfileWidgets: [],
  showAddEdit: false,
  disableMode: false,
  updateAllowed: false
};

export default injectIntl(CorrectiveActionListTable);
