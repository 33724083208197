import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './EntitySubType.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import EntitySubTypeListTable from './EntitySubTypeListTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils';
import { getUserProfileWidget, getEntitySubType, getGeneralData } from '../../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import OperationalSetupContentTemplate from '../../OperationalSetup/OperationalSetupContentTemplate';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import SearchBar from '../../SearchBar/SearchBar';
import Cookies from 'js-cookie';

import {
  getEntitySubTypeThunk,
  setFilteredDataThunk,
  setCurrentPage,
  saveEntitySubTypeThunk
} from '../../../store/actions/admin/entitySubType';

const ENTITY_TYPE = 'ENTITY_TYPE';
const SEARCH_FILTER = 'SEARCH_FILTER';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const EntitySubTypeList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const entitySubType = useSelector(state => getEntitySubType(state));
  const generalData = useSelector(state => getGeneralData(state));

  let disableControls = entitySubType.isLoading || false;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterEntityType, setFilterEntityType] = useState(0);
  const [entitySubTypeId, setEntitySubTypeId] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(getEntitySubTypeThunk('WQSP_ENTITY_SUB_TYPES_LIST'));
  }, [getEntitySubTypeThunk]);

  let messageId = (entitySubType && entitySubType.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const entityTypeOptions = () => {
    let opData = [];

    opData.push({
      label: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
      value: 0
    });
    const data = generalData.entityTypes;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntityTypeId
      });
    });
    return opData.sort(compareValues('label'));
  };

  const onDropdownChange = (e, fieldName) => {
    if (fieldName === ENTITY_TYPE) {
      setFilterEntityType(e.value);
    }

    filteredResults(fieldName, e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onRemoveEntitySubTypeClick = entitySubTypeId => {
    setActive(true);
    setEntitySubTypeId(entitySubTypeId);
  };

  const DeleteEntitySubType = () => {
    let saveData = entitySubType.SelectedEntitySubType;
    saveData.PerformDelete = true;
    saveData.EntitySubTypeId = entitySubTypeId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveEntitySubTypeThunk(saveData, 'WQSP_ENTITY_SUB_TYPES_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setEntitySubTypeId(0);
  };

  const handleConfirm = () => {
    DeleteEntitySubType();
    handleClose();
  };

  const filteredResults = (filterType, value) => {
    let filteredList = entitySubType.entitySubTypes;

    if (filterType === ENTITY_TYPE) {
      if (value !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === value;
        });
      }
    } else {
      if (filterEntityType !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityType;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
  };

  let entitySubTypeObj = Object.values(entitySubType.filteredResults);
  entitySubTypeObj = entitySubTypeObj.sort(compareValues('Name'));

  const onAddNewClick = () => {
    history.push('/operational-setup/entity-sub-types/entity-sub-type');
  };

  const onChangeEntitySubTypeClick = entitySubTypeId => {
    history.push(`/operational-setup/entity-sub-types/entity-sub-type/${entitySubTypeId}`);
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ENTITY_SUB_TYPES_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ENTITY_SUB_TYPES_DELETE');
  //const isUpdateAllowed = true;

  const dialogTitle = intl.formatMessage({
    id: 'entitySubTypes.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this entity sub type?'
  });

  return (
    <div className={s.entitySubTypeList}>
      <OperationalSetupContentTemplate selectedPage="entitySubTypes" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
          {entitySubType.isLoading && <LoadingSpinner />}
          {entitySubType.showBanner && (
            <Banner
              failureText={messageText}
              showBanner={entitySubType.showBanner}
              status={entitySubType.isOpSuccessful}
              successText={messageText}
            />
          )}
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={2}>
              <Dropdown
                id="formEntityType"
                dataArray={entityTypeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="common.allEntityTypes" defaultMessage="All Field Types" />,
                  customClassName: ''
                }}
                name="EntityType"
                onDropdownChange={e => onDropdownChange(e, ENTITY_TYPE)}
                selectedOption={entityTypeOptions().filter(option => option.value === filterEntityType)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="common.searchName"
              />
            </Col>
            <Col sm={12} lg={6}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_ENTITY_SUB_TYPES_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addEntitySubTypeButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={entitySubTypeObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={entitySubType.currentPage}
            >
              <EntitySubTypeListTable
                ChangeEntitySubType={onChangeEntitySubTypeClick}
                RemoveEntitySubType={onRemoveEntitySubTypeClick}
                UpdateAllowed={isUpdateAllowed}
                DeleteAllowed={isDeleteAllowed}
              />
            </Pagination>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

export default injectIntl(EntitySubTypeList);
