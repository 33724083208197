import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './SafetyPlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import SafetyPlanListTable from './SafetyPlanListTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils/';

import { getUserProfileWidget, getSafetyPlan } from '../../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';

import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from '../../SearchBar/SearchBar';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';

import {
  getSafetyPlanThunk,
  getFilterThunk,
  setFilteredDataThunk,
  setCurrentPage,
  setPageFilter
} from '../../../store/actions/safetyPlan/safetyPlan';

const SafetyPlanList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  let { siteId, segmentName, selectedTab } = useParams();
  selectedTab = selectedTab || '2';
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const safetyPlan = useSelector(state => getSafetyPlan(state));

  const SEARCH_FILTER = 'SEARCH_FILTER';
  const [asendingOrder, setAsendingOrder] = useState(true);

  let filter = safetyPlan.filterBy.filter || null;
  let offset = safetyPlan.offset || 0;
  let limit = safetyPlan.limit || 1000;

  const [searchFilter, setSearchFilter] = useState('');
  let disableControls = safetyPlan.isLoading || false;

  useEffect(() => {
    let filterObj = {
      filter: filter,
      filterSiteId: siteId,
      offset: offset,
      limit: limit
    };
    dispatch(getSafetyPlanThunk('WQSP_SAFETY_PLAN_LIST', filterObj));
  }, [getSafetyPlanThunk, filter, siteId]);

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_SAFETY_PLAN_LIST', siteId));
  }, [getFilterThunk, siteId]);

  let messageId = (safetyPlan && safetyPlan.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = safetyPlan.SafetyPlans;

    let apifilterObj = {
      filter: null
    };

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (safetyPlan.safetyPlanListCount > safetyPlan.SafetyPlans.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let safetyPlanObj = Object.values(safetyPlan.filteredResults);

  if (asendingOrder) {
    safetyPlanObj = safetyPlanObj.sort(compareValues('LastReviewDate'));
  } else {
    safetyPlanObj = safetyPlanObj.sort(compareValues('LastReviewDate', 'desc'));
  }

  const onAddNewClick = () => {
    if (siteId) history.push(`/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/safety-plan`);
    else history.push(`/safety-plans/safety-plan`);
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_SAFETY_PLAN_UPDATE');
  const StakeholderRegisterAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_STAKEHOLDER_REGISTER_LIST');
  const TeamAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TEAM_LIST');
  const RegulatoryRegisterAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_REGULATORY_REGISTER_LIST');
  const ReportViewingAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_SAFETYPLANREPORT_VIEW');

  return (
    <SiteDashboardControlsContentTemplate
      selectedPage={selectedTab}
      siteId={siteId}
      segmentName={segmentName}
      userProfileWidgets={userProfileWidgets}
    >
      <div className={s.safetyPlanList}>
        {!segmentName && (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="safetyPlan.safetyPlans" defaultMessage="Safety Plans" />}</h1>
          </div>
        )}

        <div className={s.contentWrapper}>
          {safetyPlan.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={safetyPlan.showBanner}
            status={safetyPlan.isOpSuccessful}
            successText={messageText}
          />

          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="safetyPlan.searchSafetyPlanName"
              />
            </Col>

            <Col sm={6} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_SAFETY_PLAN_ADD')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addSafetyPlansButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <Pagination
            data={safetyPlanObj}
            pageSize={DEFAULT_PAGE_SIZE}
            onPageChange={onPageChangeHandler}
            startingPage={safetyPlan.currentPage}
          >
            <SafetyPlanListTable
              onSortOrder={onSortOrder}
              asendingOrder={asendingOrder}
              UpdateAllowed={isUpdateAllowed}
              StakeholderRegisterAllowed={StakeholderRegisterAllowed}
              TeamAllowed={TeamAllowed}
              RegulatoryRegisterAllowed={RegulatoryRegisterAllowed}
              ReportViewingAllowed={ReportViewingAllowed}
            />
          </Pagination>
        </div>
      </div>
    </SiteDashboardControlsContentTemplate>
  );
};

export default injectIntl(SafetyPlanList);
