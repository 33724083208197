import { initialCorrectiveActionsState } from './initialState';

import {
  CORRECTIVE_ACTIONS_ERROR,
  GET_ALL_CORRECTIVE_ACTIONS_DETAILS,
  CORRECTIVE_ACTIONS_IS_LOADING,
  SET_CORRECTIVE_ACTIONS_CURRENT_PAGE,
  SET_CORRECTIVE_ACTIONS_PAGE_FILTER,
  SET_CORRECTIVE_ACTIONS_CHANGE,
  SET_CORRECTIVE_ACTIONS,
  UPDATE_CORRECTIVE_ACTIONS_SAVE_STATUS,
  SET_CORRECTIVE_ACTIONS_FILTERS,
  SET_CORRECTIVE_ACTIONS_RESULTS,
  SET_CORRECTIVE_ACTIONS_COMMENTS,
  REMOVE_CORRECTIVE_ACTIONS,
  GET_ALL_CONTROL_MONITORINGS_BY_SITE
} from '../../constants/index';

const correctiveActionReducer = (state = initialCorrectiveActionsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_CORRECTIVE_ACTIONS_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          ...data
        }
      };
    }

    case GET_ALL_CONTROL_MONITORINGS_BY_SITE: {
      const { data } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          ...data
        }
      };
    }

    case SET_CORRECTIVE_ACTIONS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          currentPage
        }
      };
    }

    case SET_CORRECTIVE_ACTIONS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_CORRECTIVE_ACTIONS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          ...data
        }
      };
    }

    case SET_CORRECTIVE_ACTIONS_COMMENTS: {
      const { selectedCorrectiveAction } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          selectedCorrectiveAction: {
            ...state.correctiveAction.selectedCorrectiveAction,
            ...selectedCorrectiveAction
          }
        }
      };
    }

    case REMOVE_CORRECTIVE_ACTIONS: {
      const { correctiveActionId } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          correctiveActions: [...state.correctiveAction.correctiveActions.filter(item => item.CorrectiveActionId !== correctiveActionId)],
          filteredResults: [...state.correctiveAction.filteredResults.filter(item => item.CorrectiveActionId !== correctiveActionId)],
          correctiveActionListCount: state.correctiveAction.correctiveActionListCount - 1
        }
      };
    }

    case CORRECTIVE_ACTIONS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          isLoading: isLoading
        }
      };
    }

    case CORRECTIVE_ACTIONS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          ...data
        }
      };
    }

    case SET_CORRECTIVE_ACTIONS: {
      let { selectedCorrectiveAction } = payload;

      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          isOpSuccessful: false,
          showBanner: false,
          selectedCorrectiveAction: {
            ...state.correctiveAction.selectedCorrectiveAction,
            ...selectedCorrectiveAction
          }
        }
      };
    }

    case SET_CORRECTIVE_ACTIONS_CHANGE: {
      let { selectedCorrectiveAction } = payload;

      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedCorrectiveAction: {
            ...state.correctiveAction.selectedCorrectiveAction,
            ...selectedCorrectiveAction
          }
        }
      };
    }

    case SET_CORRECTIVE_ACTIONS_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          ...filteredResults
        }
      };
    }

    case SET_CORRECTIVE_ACTIONS_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        correctiveAction: {
          ...state.correctiveAction,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default correctiveActionReducer;
