import React from 'react';
import ReactDOMServer from 'react-dom/server';

import MapContext from './MapContext';

class MapInfoWindow extends React.Component {
  overlay = null;
  el = null;

  removeOverlay() {
    this.overlay.setMap(null);
  }

  onOverlayDrawn = () => {
    if (this.props.onOverlayDrawn) {
      this.props.onOverlayDrawn(this);
    }
  };

  handleTileClick = unitSerialNumber => {
    this.props.handleClick(unitSerialNumber);
  };

  render() {
    return (
      <MapContext.Consumer>
        {map => {
          if (this.props.hideOverlay) {
            if (this.overlay) {
              this.overlay.setMap(null);
            }
            return null;
          }
          if (map) {
            this.el = this.el || document.createElement('div');
            let content = ReactDOMServer.renderToStaticMarkup(this.props.children);

            import('./BubbleOverlay').then(bubbleOverlay => {
              if (this.overlay) {
                this.overlay.setMap(null);
              }
              this.overlay = new bubbleOverlay.default({
                location: this.props.position,
                object: this.props.children.props.elementid,
                map: map,
                onClick: () => {
                  this.handleTileClick(this.props.children.props.elementid);
                },
                content: content
              });
              this.overlay.setMap(map);
              this.onOverlayDrawn(this);
            });
          } else {
            return null;
          }
        }}
      </MapContext.Consumer>
    );
  }
}

export default MapInfoWindow;
