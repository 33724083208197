import { initialImprovementPlanState } from '../initialState';

import {
  IMPROVEMENT_PLAN_ERROR,
  GET_ALL_IMPROVEMENT_PLAN_DETAILS,
  IMPROVEMENT_PLAN_IS_LOADING,
  SET_IMPROVEMENT_PLAN_CURRENT_PAGE,
  SET_IMPROVEMENT_PLAN_PAGE_FILTER,
  SET_IMPROVEMENT_PLAN_CHANGE,
  SET_IMPROVEMENT_PLAN,
  UPDATE_IMPROVEMENT_PLAN_SAVE_STATUS,
  SET_IMPROVEMENT_PLAN_FILTERS,
  SET_IMPROVEMENT_PLAN_RESULTS,
  SET_IMPROVEMENT_PLAN_COMMENTS,
  REMOVE_IMPROVEMENT_PLAN
} from '../../../constants/index';

const improvementPlanReducer = (state = initialImprovementPlanState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_IMPROVEMENT_PLAN_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          ...data
        }
      };
    }

    case SET_IMPROVEMENT_PLAN_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          currentPage
        }
      };
    }

    case SET_IMPROVEMENT_PLAN_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_IMPROVEMENT_PLAN_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          ...data
        }
      };
    }

    case SET_IMPROVEMENT_PLAN_COMMENTS: {
      const { selectedImprovementPlan } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          selectedImprovementPlan: {
            ...state.improvementPlan.selectedImprovementPlan,
            ...selectedImprovementPlan
          }
        }
      };
    }

    case REMOVE_IMPROVEMENT_PLAN: {
      const { improvementPlanId } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          improvementPlans: [...state.improvementPlan.improvementPlans.filter(item => item.ImprovementPlanId !== improvementPlanId)],
          filteredResults: [...state.improvementPlan.filteredResults.filter(item => item.ImprovementPlanId !== improvementPlanId)],
          improvementPlanListCount: state.improvementPlan.improvementPlanListCount - 1
        }
      };
    }

    case IMPROVEMENT_PLAN_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          isLoading: isLoading
        }
      };
    }

    case IMPROVEMENT_PLAN_ERROR: {
      const { data } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          ...data
        }
      };
    }

    case SET_IMPROVEMENT_PLAN: {
      let { selectedImprovementPlan } = payload;

      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          isOpSuccessful: false,
          showBanner: false,
          selectedImprovementPlan: {
            ...state.improvementPlan.selectedImprovementPlan,
            ...selectedImprovementPlan
          }
        }
      };
    }

    case SET_IMPROVEMENT_PLAN_CHANGE: {
      let { selectedImprovementPlan } = payload;

      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedImprovementPlan: {
            ...state.improvementPlan.selectedImprovementPlan,
            ...selectedImprovementPlan
          }
        }
      };
    }

    case SET_IMPROVEMENT_PLAN_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          ...filteredResults
        }
      };
    }

    case SET_IMPROVEMENT_PLAN_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        improvementPlan: {
          ...state.improvementPlan,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default improvementPlanReducer;
