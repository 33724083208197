import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Task.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialTasksState, initialUserManagerState, initialTaskTemplatesState } from '../../store/reducers//initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import TasksAndChecklistsContentTemplate from '../TasksAndChecklists/TasksAndChecklistsContentTemplate';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import uuid from 'uuid';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import { TASK_STATUS_COMPLETE, TASK_STATUS_ACTIVE } from '../../constants';
import { getQuerystring } from '../../utils';
import GoBack from '../WSAControls/GoBack/GoBack';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const Task = ({
  saveTask,
  describeTask,
  taskManager,
  changeTask,
  profile,
  getProfileInfo,
  intl,
  currentTimezone,
  getTaskTemplates,
  taskTemplate,
  getUsers,
  userManager,
  userProfileWidgets
}) => {
  let IsEdit = false;
  let heading = '';
  let OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  let userId = Cookies.get(`userid-${envName}`) || 0;
  const STATUS_UNRESOLVED = 'Unresolved';

  let { TaskId } = useParams();
  const [localformErrors, setFormErrors] = useState({});
  const [users, setUsers] = useState([]);

  let checklistId = getQuerystring('checklistId') || 0;
  checklistId = parseInt(checklistId);

  let prevPath = getQuerystring('prevPath') || '';
  let history = useHistory();

  if (TaskId) {
    IsEdit = true;
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' });
  } else {
    heading = intl.formatMessage({ id: 'tasksManagement.newTask', defaultMessage: 'New Task' });
  }

  let widgetCode = '';

  if (TaskId) {
    widgetCode = 'WQSP_TASKEDIT';
  } else {
    widgetCode = 'WQSP_TASKADDNEW';
  }

  useEffect(() => {
    if (TaskId) {
      describeTask(TaskId);
    } else {
      setLocalTask({
        ...taskManager,
        selectedTask: { ...initialTasksState.tasks.selectedTask }
      });
    }
  }, [describeTask, TaskId]);

  useEffect(() => {
    return () => {
      setLocalTask({
        ...taskManager,
        selectedTask: { ...initialTasksState.tasks.selectedTask },
        isOpSuccessful: false
      });
    };
  }, []);

  useEffect(() => {
    getProfileInfo(widgetCode);
  }, [getProfileInfo, widgetCode]);

  useEffect(() => {
    getTaskTemplates();
  }, [getTaskTemplates]);

  useEffect(() => {
    getUsers(OrganisationId);
  }, [getUsers, OrganisationId]);

  const profilesObj = (profile.profiles && Object.values(profile.profiles.filter(p => p.IsActive && !p.IsDeleted))) || [];

  // Update redux store
  const setLocalTask = currentState => {
    changeTask(currentState);
  };

  const disablePastDates = current =>
    current.isAfter(
      moment()
        .tz(currentTimezone)
        .subtract(1, 'day')
    );

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = taskManager.selectedTask;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = userId;
    saveData.OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
    if (saveData.Status !== TASK_STATUS_COMPLETE) {
      saveData.Status = TASK_STATUS_ACTIVE;
    }

    if (!IsEdit) {
      saveData.TaskId = 0;
      saveData.GuidIdentifier = uuidv4();
      if (checklistId) {
        saveData.ChecklistId = checklistId;
      }
    }
    //saving Model Version
    saveTask(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!taskManager || !taskManager.selectedTask || !taskManager.selectedTask.Name || isRequired(taskManager.selectedTask.Name, 1)) {
      formErrors.Name = intl.formatMessage({
        id: 'taskTemplateManagement.nameMandatory',
        defaultMessage: 'Task Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalTask({
        ...taskManager,
        selectedTask: { ...taskManager.selectedTask, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalTask({
        ...taskManager,
        selectedTask: { ...taskManager.selectedTask, [e.target.name]: e.target.value }
      });
    }
  };

  const profileOptions = () => {
    let profileData = [];
    profilesObj.forEach(element => {
      profileData.push({
        ...element,
        label: element.Name,
        value: element.ProfileId
      });
    });
    return profileData.sort(compareValues('label'));
  };

  const templateOptions = () => {
    let templateData = [];
    taskTemplate.TaskTemplates.forEach(element => {
      templateData.push({
        ...element,
        label: element.Name,
        value: element.TaskTemplateId
      });
    });

    //Add none option
    templateData.push({
      label: 'None',
      value: 0
    });
    return templateData.sort(compareValues('label'));
  };

  const userOptions = () => {
    let userData = [];
    users &&
      users.forEach(element => {
        userData.push({
          ...element,
          label: element.FullName,
          value: element.UserId
        });
      });
    return userData.sort(compareValues('label'));
  };

  const onRoleDropdownChange = e => {
    setLocalTask({
      ...taskManager,
      selectedTask: { ...taskManager.selectedTask, AssignedToProfileId: e.value }
    });

    let userObj = userManager && userManager.users && userManager.users.filter(x => x.selectedProfiles.includes(e.value));
    setUsers(userObj);
  };

  let assignedToProfileId = taskManager.selectedTask.AssignedToProfileId;
  useEffect(() => {
    if (IsEdit) {
      let userObj = userManager && userManager.users && userManager.users.filter(x => x.selectedProfiles.includes(assignedToProfileId));
      setUsers(userObj);
    }
  }, [assignedToProfileId]);

  const onTemplateDropdownChange = e => {
    const selectedTemplate = taskTemplate.TaskTemplates.filter(x => x.TaskTemplateId === e.value);

    if (selectedTemplate.length > 0) {
      setLocalTask({
        ...taskManager,
        selectedTask: {
          ...taskManager.selectedTask,
          Name: selectedTemplate[0].Name,
          Description: selectedTemplate[0].Description,
          IsERP: selectedTemplate[0].IsERP,
          Activities: selectedTemplate[0].Activities,
          EstimatedEffort: selectedTemplate[0].EstimatedEffort || 0,
          AssignedToProfileId: selectedTemplate[0].AssignedToProfileId || null
        }
      });
      if (selectedTemplate[0].AssignedToProfileId) {
        let userObj =
          userManager &&
          userManager.users &&
          userManager.users.filter(x => x.selectedProfiles.includes(selectedTemplate[0].AssignedToProfileId));
        setUsers(userObj);
      }
    } else {
      setLocalTask({
        ...taskManager,
        selectedTask: {
          ...taskManager.selectedTask,
          Name: '',
          Description: '',
          Activities: '',
          EstimatedEffort: 0,
          AssignedToProfileId: null
        }
      });
    }
  };

  const onAssignToDropdownChange = e => {
    setLocalTask({
      ...taskManager,
      selectedTask: { ...taskManager.selectedTask, AssignedToUserId: e.value, AssignedByUserId: userId }
    });
  };

  const onDueDateChange = newVal => {
    let dateTime = null;
    if (newVal && newVal.unix) {
      dateTime = newVal.unix();
    }

    setLocalTask({
      ...taskManager,
      selectedTask: { ...taskManager.selectedTask, DueDateTime: dateTime }
    });
  };

  let messageId = (taskManager && taskManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const taskName = (taskManager.selectedTask ? taskManager.selectedTask.Name : '') || '';

  let backToMessage = intl.formatMessage({ id: 'tasksManagement.backtoTasks', defaultMessage: 'BACK TO TASKS' });
  if (checklistId) {
    backToMessage = intl.formatMessage({ id: 'checklistManagement.backtoChecklist', defaultMessage: 'BACK TO CHECKLIST' });
  }

  const redirect = () => {
    if (taskManager.isOpSuccessful && !taskManager.showBanner) {
      return <Redirect to={prevPath} />;
    }
  };

  return (
    <div className={s.task}>
      {taskManager.isLoading && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={taskManager.showBanner}
        status={taskManager.isOpSuccessful}
        successText={messageText}
      />

      {redirect()}
      <TasksAndChecklistsContentTemplate selectedPage="myTasks" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.taskHeader}>
            <GoBack>
              &lt; &nbsp;
              {backToMessage}
            </GoBack>
            <h3 data-unittest="headingLabel">{!IsEdit ? heading : heading + ' ' + taskName}</h3>
          </div>
          <div className={s.taskContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTaskTemplate">
                    <Form.Label>
                      <FormattedMessage id="tasksManagement.selectTask" defaultMessage="Select from task templates" />
                    </Form.Label>

                    <Dropdown
                      id="formTaskTemplate"
                      dataArray={templateOptions()}
                      controlData={{ placeholderText: 'Select task template' }}
                      onDropdownChange={onTemplateDropdownChange}
                      // selectedOption={templateOptions().filter(option => option.value === taskManager.selectedTask.AssignedToProfileId)}
                      data-unittest="formTemplate"
                      disabled={IsEdit}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTaskName">
                    <Form.Label>
                      <FormattedMessage id="common.task" defaultMessage="Task" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={taskManager.selectedTask.Name}
                      placeholder={intl.formatMessage({
                        id: 'taskTemplateManagement.enterTask',
                        defaultMessage: 'Enter task'
                      })}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTaskDescription">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.description" defaultMessage="Description / Objective" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'taskTemplateManagement.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={taskManager.selectedTask.Description}
                      className={s.textArea}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTaskIsERP">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsERP',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'wqsp.common.emergencyResponsePlan',
                            defaultMessage: 'Emergency Response Plan'
                          }),
                          isChecked: taskManager.selectedTask.IsERP
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTaskRole">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.role" defaultMessage="Role" />
                    </Form.Label>

                    <Dropdown
                      id="formTaskRole"
                      dataArray={profileOptions()}
                      controlData={{ placeholderText: 'Select role' }}
                      onDropdownChange={onRoleDropdownChange}
                      selectedOption={profileOptions().filter(option => option.value === taskManager.selectedTask.AssignedToProfileId)}
                      data-unittest="formRole"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formAssignTo">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.assignTo" defaultMessage="Assign to" />
                    </Form.Label>

                    <Dropdown
                      id="formAssignTo"
                      dataArray={userOptions()}
                      controlData={{ placeholderText: 'Select user' }}
                      onDropdownChange={onAssignToDropdownChange}
                      selectedOption={userOptions().filter(option => option.value === taskManager.selectedTask.AssignedToUserId)}
                      data-unittest="formUser"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formTaskEffort">
                    <div>
                      <Form.Label>
                        <FormattedMessage id="taskTemplateManagement.estimatedEffort" defaultMessage="Estimated effort" />
                      </Form.Label>
                    </div>
                    <div className="float-left">
                      <InputNumber
                        name="EstimatedEffort"
                        step="1"
                        onInputChange={onChange}
                        value={taskManager.selectedTask.EstimatedEffort}
                        id="formTaskEffort"
                        placeholder="Enter number"
                      />
                    </div>
                    <div className={s.hours}>hours</div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={3}>
                  <Form.Group controlId="formDueDate">
                    <Form.Label>
                      <FormattedMessage id="tasksManagement.dueDateTime" defaultMessage="Due date & time" />
                    </Form.Label>

                    <DateTime
                      isValidDate={disablePastDates}
                      onChange={m => onDueDateChange(m)}
                      timeFormat="HH:mm:ss"
                      dateFormat="DD-MMM-YYYY"
                      value={
                        taskManager.selectedTask.DueDateTime ? moment.unix(taskManager.selectedTask.DueDateTime).tz(currentTimezone) : null
                      }
                      // defaultValue={moment().tz(currentTimezone)}
                      closeOnSelect={true}
                      className={s.dueDateTime}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formTaskActivities">
                    <Form.Label>
                      <FormattedMessage id="taskTemplateManagement.activities" defaultMessage="Activities" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Activities"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'taskTemplateManagement.enterActivities',
                        defaultMessage: 'Enter activities'
                      })}
                      value={taskManager.selectedTask.Activities}
                      className={s.textArea}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  {IsEdit ? (
                    <FormattedMessage id="common.saveChanges" defaultMessage="SAVE CHANGES" />
                  ) : (
                    <FormattedMessage id="tasksManagement.createTask" defaultMessage="Create Task" />
                  )}
                </Button>
                <Button variant="outline-secondary" className={s.btnCancel} onClick={() => history.goBack()}>
                  <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </TasksAndChecklistsContentTemplate>
    </div>
  );
};

Task.defaultProps = {
  taskManager: {
    ...initialTasksState.tasks
  },
  profile: { profiles: [] },
  userManager: {
    ...initialUserManagerState.userManager
  },
  taskTemplate: {
    ...initialTaskTemplatesState.taskTemplate
  }
};

Task.propTypes = {
  saveTask: PropTypes.func.isRequired,
  taskManager: PropTypes.object.isRequired,
  describeTask: PropTypes.func.isRequired,
  changeTask: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  getProfileInfo: PropTypes.func.isRequired,
  getTaskTemplates: PropTypes.func.isRequired,
  taskTemplate: PropTypes.object.isRequired,
  userManager: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(Task);
