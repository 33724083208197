import React from 'react';
import PropTypes from 'prop-types';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const AdminControls = ({ userProfileWidgets }) => (
  <div>
    {IsWidgetAccessible(userProfileWidgets, 'ORGANISATION_TAB') ? (
      <Redirect to="/admin-controls/organisation-list" />
    ) : IsWidgetAccessible(userProfileWidgets, 'USERS_TAB') ? (
      <Redirect to="/admin-controls/user-list" />
    ) : IsWidgetAccessible(userProfileWidgets, 'PROFILES_TAB') ? (
      <Redirect to="/admin-controls/profile-list" />
    ) : (
      ''
    )}
    <span>
      <FormattedMessage
        id="AdminControls.NoAccess"
        defaultMessage="You do not have access to Admin Controls. Please contact your administrator."
      />
    </span>
  </div>
);

AdminControls.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired
};

export default AdminControls;
