import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';

import {
  PERSONNEL_ERROR,
  GET_ALL_PERSONNEL_DETAILS,
  PERSONNEL_IS_LOADING,
  SET_PERSONNEL_CURRENT_PAGE,
  SET_PERSONNEL_PAGE_FILTER,
  SET_PERSONNEL_CHANGE,
  SET_PERSONNEL,
  UPDATE_PERSONNEL_SAVE_STATUS,
  SET_PERSONNEL_FILTERS,
  SET_PERSONNEL_RESULTS,
  REMOVE_PERSONNEL
} from '../../../constants/index';

import { PERSONNEL_MANAGEMENT, CREATE_PERSONNEL, UPDATE_PERSONNEL } from '../../../constants/logs';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setPersonnelErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setPersonnelError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setPersonnelError(messageCode, false));
  }, 2500);
};

export const setPersonnelError = (messageCode, status) => {
  return {
    type: PERSONNEL_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: PERSONNEL_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getPersonnelThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getPersonnel(widgetCode, orgId, filterObj));
};

export const getPersonnel = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/personnel`,
    method: 'GET',
    data: {
      OrgId: orgId,
      filterName: filterObj.filterName,
      filterCompany: filterObj.filterCompany,
      limit: filterObj.limit,
      offset: filterObj.offset
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllPersonnelListThunk(retData),
    onFailure: err => setPersonnelErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllPersonnelListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllPersonnelList(messageCode, data));
};

export const setAllPersonnelList = (messageCode, data) => ({
  type: GET_ALL_PERSONNEL_DETAILS,
  payload: {
    data: {
      personnels: data,
      filteredResults: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_PERSONNEL_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_PERSONNEL_PAGE_FILTER,
  payload: { filter }
});

export const describePersonnelThunk = (personnelId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describePersonnel(personnelId, widgetCode, orgId));
};

export const describePersonnel = (personnelId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/personnel`,
    data: {
      PersonnelId: personnelId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setPersonnelThunk(retData, personnelId),
    onFailure: err => setPersonnelErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setPersonnelThunk = ({ retData }, personnelId) => dispatch => {
  let data = retData.data;
  if (personnelId && (!data || data.length === 0 || data[0].PersonnelId !== parseInt(personnelId))) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setPersonnel(data));
};

export const setPersonnel = data => ({
  type: SET_PERSONNEL,
  payload: data[0]
});

export const savePersonnelThunk = (data, widgetCode) => dispatch => {
  let url = '/management/personnel/save';
  let actionName = data.PersonnelId === 0 ? CREATE_PERSONNEL : UPDATE_PERSONNEL;
  let log = logEntry(PERSONNEL_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(savePersonnel(data, url, log, widgetCode));
};

export const savePersonnel = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setPersonnelStatusThunk(retData, data),
    onFailure: err => setPersonnelErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setPersonnelStatusThunk = (message, data) => dispatch => {
  dispatch(setPersonnelStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    dispatch(setPersonnelStatus(message, false, true));
    if (data.PerformDelete) {
      dispatch(removeEmergencyResponsePlan(data.PersonnelId));
    }
  }, 2500);
};

export const setPersonnelStatus = (messageCode, status, redirect) => ({
  type: UPDATE_PERSONNEL_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: status,
      showBanner: status,
      isLoading: false,
      isRedirect: redirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const removeEmergencyResponsePlan = data => ({
  type: REMOVE_PERSONNEL,
  payload: {
    personnelId: data
  }
});

export const setPersonnelChangeThunk = retData => dispatch => {
  dispatch(setPersonnelChange(retData));
};

export const setPersonnelChange = retData => ({
  type: SET_PERSONNEL_CHANGE,
  payload: retData
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_PERSONNEL_RESULTS,
  payload: retData
});

export const getFilterThunk = widgetCode => dispatch => {
  dispatch(getFilter(widgetCode));
};

export const getFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      filterFor: 'personnelListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setPersonnelErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_PERSONNEL_FILTERS,
  payload: {
    data: {
      isLoading: false,
      personnelListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});
