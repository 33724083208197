import React from 'react';
import s from './GeneralFieldType.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';

import Button from 'react-bootstrap/Button';

const GeneralFieldTypeListTable = ({
  paginatedData,
  ChangeGeneralFieldType,
  RemoveGeneralFieldType,
  UpdateAllowed,
  DeleteAllowed,
  intl,
  fieldTypeData
}) => {
  const getFieldType = fieldtype => {
    const retData = fieldTypeData.find(x => x.value === fieldtype);
    if (retData != null) return (retData.text && intl.formatMessage({ id: retData.text, defaultMessage: retData.text })) || '-';
    else return '-';
  };

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="generalFieldTypes.fieldType" defaultMessage="Field Type" />
            </th>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />{' '}
            </th>
            <th>
              <FormattedMessage id="common.description" defaultMessage="Description" />
            </th>
            <th>
              <FormattedMessage id="generalFieldTypes.value" defaultMessage="Value" />
            </th>

            {UpdateAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.GeneralFieldTypeId}>
                <td>{getFieldType(n.FieldType) || ''}</td>
                <td>{n.Name || ''}</td>
                <td>{n.Description || ''}</td>
                <td>{n.Value || ''}</td>
                <td className={s.btnCol}>
                  {DeleteAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        RemoveGeneralFieldType(n.GeneralFieldTypeId);
                      }}
                      data-unittest="removeData"
                    >
                      <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                    </Button>
                  )}
                  {UpdateAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        ChangeGeneralFieldType(n.GeneralFieldTypeId);
                      }}
                      data-unittest="changeData"
                    >
                      <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

GeneralFieldTypeListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(GeneralFieldTypeListTable);
