import React from 'react';
import PropTypes from 'prop-types';
import s from './TaskList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import { TASK_STATUS_ACTIVE } from '../../constants/index';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getOverdueStatus } from '../../utils';

const TaskListTable = ({ paginatedData, ViewAllowed, onSortOrder, asendingOrder, currentTimezone, onViewBtnClick }) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.task" defaultMessage="Task" />
            </th>
            <th className={`d-none d-md-table-cell ${s.sortHeading}`}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="wqsp.common.duedate" defaultMessage="Due Date" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="emergencyPlan.emergencyResponsePlans" defaultMessage="Emergency Response Plan" />
            </th>
            <th>
              <FormattedMessage id="common.status" defaultMessage="Status" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.TaskId} data-unittest="tasksData" className={n.Status === TASK_STATUS_ACTIVE ? s.active : s.complete}>
                <td>{n.Name || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.DueDateTime
                    ? moment
                        .unix(n.DueDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                  <span className={s.overDue}>{n.Status === TASK_STATUS_ACTIVE && getOverdueStatus(n.DueDateTime)}</span>
                </td>
                <td>
                  {n.IsERP ? (
                    <FormattedMessage id="common.yes" defaultMessage="Yes" />
                  ) : (
                    <FormattedMessage id="common.no" defaultMessage="No" />
                  )}
                </td>
                <td className={s.status}>
                  <span className={`${s.dot} ${n.Status === TASK_STATUS_ACTIVE ? s.dotActive : s.dotComplete}`}></span> {n.Status || ''}
                </td>
                <td>
                  {ViewAllowed && (
                    <div className={s.btnView}>
                      <Button
                        variant="outline-secondary"
                        data-unittest="view"
                        className={s.viewButton}
                        onClick={() => {
                          onViewBtnClick(`/tasks-and-checklists/task-detail/${n.TaskId}`);
                        }}
                      >
                        <FormattedMessage id="common.view" defaultMessage="VIEW" />
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

TaskListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

TaskListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired,
  onViewBtnClick: PropTypes.func.isRequired
};

export default injectIntl(TaskListTable);
