import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './CorrectiveAction.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import { initialCorrectiveActionsState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import 'react-datetime/css/react-datetime.css';
import { getUserProfileWidget, getCorrectiveAction, getCurrentTimezone } from '../../selectors/index';
import {
  describeCorrectiveActionThunk,
  saveCorrectiveActionThunk,
  setCorrectiveActionChangeThunk
} from '../../store/actions/correctiveAction';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import CorrectiveActionComments from './CorrectiveActionComments';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const CorrectiveActionView = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  let { correctiveActionId, siteId, segmentName, selectedTab } = useParams();

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const correctiveAction = useSelector(state => getCorrectiveAction(state));

  if (correctiveActionId && correctiveActionId > 0) {
    IsEdit = true;
  } else {
    correctiveActionId = 0;
  }
  let widgetCode = 'WQSP_CORRECTIVE_ACTIONS_VIEW';

  let addCommentsAllowed = IsWidgetAccessible(userProfileWidgets, `WQSP_CORRECTIVE_ACTIONS_COMMENT_ADD`);

  useEffect(() => {
    dispatch(describeCorrectiveActionThunk(correctiveActionId, widgetCode));
  }, [describeCorrectiveActionThunk, correctiveActionId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setCorrectiveActionChangeThunk({
          ...initialCorrectiveActionsState.correctiveAction
        })
      );
    };
  }, []);

  const saveCorrectiveActionComment = comment => {
    let saveData = {
      ...comment
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.PerformCommentInsert = true;
    dispatch(saveCorrectiveActionThunk(saveData, 'WQSP_CORRECTIVE_ACTIONS_COMMENT_ADD'));
  };

  let messageId = (correctiveAction && correctiveAction.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  heading = intl.formatMessage({ id: 'common.viewTextCC', defaultMessage: 'View' });
  heading = `${heading} - ${correctiveAction.selectedCorrectiveAction.Title} `;

  const breadcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.listOfCorrectiveActions" defaultMessage="List of Corrective Actions" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/corrective-actions`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 2, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <div className={s.correctiveAction}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {correctiveAction.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={correctiveAction.showBanner}
          status={correctiveAction.isOpSuccessful}
          successText={messageText}
        />
        <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        <div className={s.contentWrapper}>
          <div className={s.correctiveActionContent}>
            <Form>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="correctiveAction.personnel" defaultMessage="Personnel" />
                  </Form.Label>
                </Col>
                <Col>{correctiveAction.selectedCorrectiveAction.PersonnelName}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.title" defaultMessage="Title" />
                  </Form.Label>
                </Col>
                <Col>{correctiveAction.selectedCorrectiveAction.Title}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="correctiveAction.whenActionTriggered" defaultMessage="When Action Triggered" />
                  </Form.Label>
                </Col>
                <Col>{correctiveAction.selectedCorrectiveAction.WhenActionTriggered}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="common.details" defaultMessage="Details" />
                  </Form.Label>
                </Col>
                <Col>{correctiveAction.selectedCorrectiveAction.Details}</Col>
              </Row>
              <Row className={s.viewRow}>
                <Col xs={12} lg={3}>
                  <Form.Label>
                    <FormattedMessage id="controlMonitoring.records" defaultMessage="Records" />
                  </Form.Label>
                </Col>
                <Col>{correctiveAction.selectedCorrectiveAction.Records}</Col>
              </Row>
            </Form>
          </div>
          <div className={s.correctiveActionTypeContent}>
            <CorrectiveActionComments
              correctiveAction={correctiveAction}
              saveCorrectiveActionComment={saveCorrectiveActionComment}
              UpdateAllowed={addCommentsAllowed}
              currentTimezone={currentTimezone}
            />
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(CorrectiveActionView);
