import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';

import {
  STAKEHOLDER_REGISTER_ERROR,
  GET_ALL_STAKEHOLDER_REGISTER_DETAILS,
  STAKEHOLDER_REGISTER_IS_LOADING,
  SET_STAKEHOLDER_REGISTER_CURRENT_PAGE,
  SET_STAKEHOLDER_REGISTER_PAGE_FILTER,
  SET_STAKEHOLDER_REGISTER_CHANGE,
  SET_STAKEHOLDER_REGISTER,
  UPDATE_STAKEHOLDER_REGISTER_SAVE_STATUS,
  SET_STAKEHOLDER_REGISTER_FILTERS,
  SET_STAKEHOLDER_REGISTER_RESULTS,
  REMOVE_STAKEHOLDER_REGISTER
} from '../../../constants/index';

import { STAKEHOLDER_REGISTER_MANAGEMENT, CREATE_STAKEHOLDER_REGISTER, UPDATE_STAKEHOLDER_REGISTER } from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setStakeholderRegisterErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setStakeholderRegisterError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setStakeholderRegisterError(messageCode, false));
  }, 2500);
};

export const setStakeholderRegisterError = (messageCode, status) => {
  return {
    type: STAKEHOLDER_REGISTER_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: STAKEHOLDER_REGISTER_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getStakeholderRegisterThunk = (widgetCode, filterObj, safetyPlanId) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getStakeholderRegister(widgetCode, orgId, filterObj, safetyPlanId));
};

export const getStakeholderRegister = (widgetCode, orgId, filterObj, safetyPlanId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp/stakeholderregister`,
    method: 'GET',
    data: {
      OrgId: orgId,
      WaterQualitySafetyPlanId: safetyPlanId,
      filterName: filterObj.filterName,
      filterCompany: filterObj.filterCompany,
      filterRole: filterObj.filterRole,
      limit: filterObj.limit,
      offset: filterObj.offset,
      SiteId: filterObj.siteId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllStakeholderRegisterListThunk(retData),
    onFailure: err => setStakeholderRegisterErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllStakeholderRegisterListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllStakeholderRegisterList(messageCode, data));
};

export const setAllStakeholderRegisterList = (messageCode, data) => ({
  type: GET_ALL_STAKEHOLDER_REGISTER_DETAILS,
  payload: {
    data: {
      stakeholderRegisters: data,
      filteredResults: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_STAKEHOLDER_REGISTER_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_STAKEHOLDER_REGISTER_PAGE_FILTER,
  payload: { filter }
});

export const describeStakeholderRegisterThunk = (stakeholderRegisterId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeStakeholderRegister(stakeholderRegisterId, widgetCode, orgId));
};

export const describeStakeholderRegister = (stakeholderRegisterId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp/stakeholderregister`,
    data: {
      StakeholderRegisterId: stakeholderRegisterId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setStakeholderRegisterThunk(retData, stakeholderRegisterId),
    onFailure: err => setStakeholderRegisterErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setStakeholderRegisterThunk = ({ retData }, stakeholderRegisterId) => dispatch => {
  let data = retData.data;
  if (stakeholderRegisterId && (!data || data.length === 0 || parseInt(stakeholderRegisterId) !== data[0].StakeholderRegisterId)) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setStakeholderRegister(data));
};

export const setStakeholderRegister = data => ({
  type: SET_STAKEHOLDER_REGISTER,
  payload: data[0]
});

export const saveStakeholderRegisterThunk = (data, widgetCode) => dispatch => {
  let url = '/waterqualitysafetyplans/wqsp/stakeholderregister/save';
  let actionName = data.StakeholderRegisterId === 0 ? CREATE_STAKEHOLDER_REGISTER : UPDATE_STAKEHOLDER_REGISTER;
  let log = logEntry(STAKEHOLDER_REGISTER_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveStakeholderRegister(data, url, log, widgetCode));
};

export const saveStakeholderRegister = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setStakeholderRegisterStatusThunk(retData, data),
    onFailure: err => setStakeholderRegisterErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setStakeholderRegisterStatusThunk = (message, data) => dispatch => {
  dispatch(setStakeholderRegisterStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    dispatch(setStakeholderRegisterStatus(message, false, true));
    if (data.PerformDelete) {
      dispatch(removeEmergencyResponsePlan(data.StakeholderRegisterId));
    }
  }, 2500);
};

export const setStakeholderRegisterStatus = (messageCode, status, redirect) => ({
  type: UPDATE_STAKEHOLDER_REGISTER_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: status,
      showBanner: status,
      isLoading: false,
      isRedirect: redirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const removeEmergencyResponsePlan = data => ({
  type: REMOVE_STAKEHOLDER_REGISTER,
  payload: {
    stakeholderRegisterId: data
  }
});

export const setStakeholderRegisterChangeThunk = retData => dispatch => {
  dispatch(setStakeholderRegisterChange(retData));
};

export const setStakeholderRegisterChange = retData => ({
  type: SET_STAKEHOLDER_REGISTER_CHANGE,
  payload: retData
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_STAKEHOLDER_REGISTER_RESULTS,
  payload: retData
});

export const getFilterThunk = (widgetCode, waterQualitySafetyPlanId) => dispatch => {
  dispatch(getFilter(widgetCode, waterQualitySafetyPlanId));
};

export const getFilter = (widgetCode, waterQualitySafetyPlanId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      WaterQualitySafetyPlanId: waterQualitySafetyPlanId,
      filterFor: 'personnelListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setStakeholderRegisterErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_STAKEHOLDER_REGISTER_FILTERS,
  payload: {
    data: {
      isLoading: false,
      stakeholderRegisterListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});
