import { initialTranslationState } from './initialState';

import { TRANSLATION_ERROR, GET_ALL_TRANSLATION_DETAILS, TRANSLATION_IS_LOADING } from '../../constants/index';

const translationReducer = (state = initialTranslationState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_TRANSLATION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        translation: {
          ...state.translation,
          ...data
        }
      };
    }

    case TRANSLATION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        translation: {
          ...state.translation,
          isLoading: isLoading
        }
      };
    }

    case TRANSLATION_ERROR: {
      const { data } = payload;
      return {
        ...state,
        translation: {
          ...state.translation,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default translationReducer;
