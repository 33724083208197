import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './InspectionTypeQuestion.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

const InspectionTypeQuestionListTable = ({
  InspectionTypeQuestionData,
  RemoveInspectionTypeQuestion,
  ChangeInspectionTypeQuestion,
  UpdateAllowed,
  DeleteAllowed,
  inspectionTypeSpecificQuestions,
  intl
}) => {
  return (
    <div>
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            {!inspectionTypeSpecificQuestions && (
              <th>
                <FormattedMessage id="inspectionTypeQuestions.inspectionType" defaultMessage="Inspection Type" />
              </th>
            )}
            <th>
              <FormattedMessage id="inspectionTypeQuestions.questionNumber" defaultMessage="Question Number" />
            </th>
            <th>
              <FormattedMessage id="inspectionTypeQuestions.questionText" defaultMessage="Question Text" />
            </th>
            <th>
              <FormattedMessage id="inspectionTypeQuestions.score" defaultMessage="Score" />
            </th>
            {(UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {InspectionTypeQuestionData.map(n => {
            return (
              <Fragment key={n.InspectionTypeQuestionId}>
                <tr data-unittest="inspectionTypeQuestionData">
                  {!inspectionTypeSpecificQuestions && <td>{n.InspectionType}</td>}
                  <td>{n.QuestionNumber}</td>
                  <td>
                    <QuestionDetails item={n} />{' '}
                  </td>
                  <td>{n.Score}</td>
                  {(UpdateAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={() => {
                            ChangeInspectionTypeQuestion(n.InspectionTypeQuestionId);
                          }}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="inspectionTypeQuestions.change" defaultMessage="CHANGE" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.removeButton}
                          href="#"
                          onClick={() => {
                            RemoveInspectionTypeQuestion(n.InspectionTypeQuestionId);
                          }}
                          data-unittest="removeData"
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

InspectionTypeQuestionListTable.defaultProps = {
  InspectionTypeQuestionData: []
};

InspectionTypeQuestionListTable.propTypes = {
  InspectionTypeQuestionData: PropTypes.array.isRequired,
  RemoveInspectionTypeQuestion: PropTypes.func.isRequired,
  ChangeInspectionTypeQuestion: PropTypes.func.isRequired,
  UpdateAllowed: PropTypes.bool,
  DeleteAllowed: PropTypes.bool
};

export default injectIntl(InspectionTypeQuestionListTable);

const QuestionDetails = ({ item }) => {
  return (
    <div>
      <div className={s.questionText}>{item.QuestionText}</div>
      <div className={s.normalItalicText}>{item.ExplanatoryNote}</div>
    </div>
  );
};
