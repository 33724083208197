import { initialInspectionTypeQuestionState } from './initialState';

import {
  INSPECTION_TYPE_QUESTION_ERROR,
  GET_ALL_INSPECTION_TYPE_QUESTION_DETAILS,
  INSPECTION_TYPE_QUESTION_IS_LOADING,
  SET_INSPECTION_TYPE_QUESTION_CURRENT_PAGE,
  SET_INSPECTION_TYPE_QUESTION_PAGE_FILTER,
  SET_INSPECTION_TYPE_QUESTION_CHANGE,
  SET_INSPECTION_TYPE_QUESTION_CLEANUP,
  SET_INSPECTION_TYPE_QUESTION,
  SET_INSPECTION_TYPE_QUESTIONS,
  UPDATE_INSPECTION_TYPE_QUESTION_SAVE_STATUS
} from '../../constants/index';

const inspectionTypeQuestionReducer = (state = initialInspectionTypeQuestionState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_INSPECTION_TYPE_QUESTION_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          ...data
        }
      };
    }

    case SET_INSPECTION_TYPE_QUESTION_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          currentPage
        }
      };
    }

    case SET_INSPECTION_TYPE_QUESTION_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          filter
        }
      };
    }

    case UPDATE_INSPECTION_TYPE_QUESTION_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          ...data
        }
      };
    }

    case INSPECTION_TYPE_QUESTION_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          isLoading: isLoading
        }
      };
    }

    case INSPECTION_TYPE_QUESTION_ERROR: {
      const { data } = payload;
      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          ...data
        }
      };
    }

    case SET_INSPECTION_TYPE_QUESTION: {
      let { data, inspectionTypes } = payload;

      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          isOpSuccessful: false,
          showBanner: false,
          InspectionTypes: inspectionTypes,
          SelectedInspectionTypeQuestion: {
            ...state.inspectionTypeQuestion.SelectedInspectionTypeQuestion,
            ...data[0]
          }
        }
      };
    }

    case SET_INSPECTION_TYPE_QUESTIONS: {
      let { data, inspectionTypes } = payload;

      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          isOpSuccessful: false,
          showBanner: false,
          InspectionTypes: inspectionTypes,
          InspectionTypeQuestions: data,
          isRedirect: false
        }
      };
    }

    case SET_INSPECTION_TYPE_QUESTION_CHANGE: {
      let { SelectedInspectionTypeQuestion } = payload;

      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          isOpSuccessful: false,
          showBanner: false,
          SelectedInspectionTypeQuestion: {
            ...state.inspectionTypeQuestion.SelectedInspectionTypeQuestion,
            ...SelectedInspectionTypeQuestion
          }
        }
      };
    }
    case SET_INSPECTION_TYPE_QUESTION_CLEANUP: {
      let SelectedInspectionTypeQuestion = payload;
      return {
        ...state,
        inspectionTypeQuestion: {
          ...state.inspectionTypeQuestion,
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          SelectedInspectionTypeQuestion: SelectedInspectionTypeQuestion
        }
      };
    }

    default:
      return state;
  }
};

export default inspectionTypeQuestionReducer;
