import React from 'react';
import s from './CorrectiveAction.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import MoreOption from '../WSAControls/MoreOption/MoreOption';
import { useParams } from 'react-router-dom';

const CorrectiveActionListTable = ({ paginatedData, deleteAllowed, removeCorrectiveAction, updateAllowed, viewAllowed }) => {
  let { segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'respond';
  selectedTab = selectedTab || '2';

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="correctiveAction.personnel" defaultMessage="Personnel" />
            </th>
            <th>
              <FormattedMessage id="common.title" defaultMessage="Title" />
            </th>
            <th>
              <FormattedMessage id="correctiveAction.whenActionTriggered" defaultMessage="When Action Triggered" />
            </th>
            {deleteAllowed || (updateAllowed && <th></th>)}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(item => {
            return (
              <tr key={item.CorrectiveActionId}>
                <td>{item.PersonnelName}</td>
                <td>{item.Title}</td>
                <td>{item.WhenActionTriggered || ''}</td>

                {deleteAllowed && (
                  <td className={s.btnCol}>
                    <MoreOption
                      dataArray={[
                        deleteAllowed
                          ? {
                              Click: () => {
                                removeCorrectiveAction(item.CorrectiveActionId);
                              },
                              Name: <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                            }
                          : {},
                        updateAllowed
                          ? {
                              Url: `/site/${item.SiteId}/${segmentName}/${selectedTab}/corrective-actions/corrective-action/${item.CorrectiveActionId}`,
                              Name: <FormattedMessage id="common.edit" defaultMessage="EDIT" />
                            }
                          : {},
                        viewAllowed
                          ? {
                              Url: `/site/${item.SiteId}/${segmentName}/${selectedTab}/corrective-actions/corrective-action-view/${item.CorrectiveActionId}`,
                              Name: <FormattedMessage id="common.view" defaultMessage="VIEW" />
                            }
                          : {}
                      ]}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

CorrectiveActionListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(CorrectiveActionListTable);
