import React from 'react';
import s from './ControlMeasure.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../../assets/arrow-up.png';
import descendingIcon from '../../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getDateInDDMMYYYFormat, getDateInYYYYFormat } from '../../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../../selectors/index';

const ControlMeasureListTable = ({
  paginatedData,
  onSortOrder,
  asendingOrder,
  editControlMeasure,
  editAllowed,
  deleteAllowed,
  removeControlMeasure
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="common.description" defaultMessage="Description" />
            </th>
            <th>
              <FormattedMessage id="controlMeasure.dateImplemented" defaultMessage="Date Implemented" />
            </th>
            <th>
              <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="controlMeasure.status" defaultMessage="Status" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            {(editAllowed || deleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.ControlMeasureId}>
                <td>{n.Name || ''}</td>
                <td>{n.Description || ''}</td>
                <td>{getDateInDDMMYYYFormat(n.DateImplemented, currentTimezone) || ''}</td>
                <td>{getDateInDDMMYYYFormat(n.LastReviewDate, currentTimezone) || ''}</td>
                <td>{n.StatusName || ''}</td>
                <td className={s.btnCol}>
                  {deleteAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.changeButton}
                      href="#"
                      onClick={() => {
                        removeControlMeasure(n.ControlMeasureId);
                      }}
                      data-unittest="changeData"
                    >
                      <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                    </Button>
                  )}
                  {editAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.changeButton}
                      href="#"
                      onClick={() => {
                        editControlMeasure(n.ControlMeasureId);
                      }}
                      data-unittest="changeData"
                    >
                      <FormattedMessage id="common.view" defaultMessage="VIEW" />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ControlMeasureListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(ControlMeasureListTable);
