import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';
import _ from 'lodash';

import {
  IMPROVEMENT_PLAN_ERROR,
  GET_ALL_IMPROVEMENT_PLAN_DETAILS,
  IMPROVEMENT_PLAN_IS_LOADING,
  SET_IMPROVEMENT_PLAN_CURRENT_PAGE,
  SET_IMPROVEMENT_PLAN_PAGE_FILTER,
  SET_IMPROVEMENT_PLAN_CHANGE,
  SET_IMPROVEMENT_PLAN,
  UPDATE_IMPROVEMENT_PLAN_SAVE_STATUS,
  SET_IMPROVEMENT_PLAN_FILTERS,
  SET_IMPROVEMENT_PLAN_RESULTS,
  SET_IMPROVEMENT_PLAN_COMMENTS,
  REMOVE_IMPROVEMENT_PLAN
} from '../../../constants/index';

import { IMPROVEMENT_PLAN_MANAGEMENT, CREATE_IMPROVEMENT_PLAN, UPDATE_IMPROVEMENT_PLAN } from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setImprovementPlanErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setImprovementPlanError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setImprovementPlanError(messageCode, false));
  }, 2500);
};

export const setImprovementPlanError = (messageCode, status) => {
  return {
    type: IMPROVEMENT_PLAN_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: IMPROVEMENT_PLAN_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getImprovementPlanThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getImprovementPlan(widgetCode, orgId, filterObj));
};

export const getImprovementPlan = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/hazardevents/hazardevent/improvementplans`,
    method: 'GET',
    data: {
      OrgId: orgId,
      HazardEventId: filterObj.hazardEventId,
      offset: filterObj.offset,
      limit: filterObj.limit,
      filter: filterObj.filter,
      filterSiteId: filterObj.filterSiteId,
      filterWaterSupplyDescriptionId: filterObj.filterWaterSupplyDescriptionId,
      filterEntityTypeId: filterObj.filterEntityTypeId,
      filterEntityId: filterObj.filterEntityId,
      filterStatusId: filterObj.filterStatusId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllImprovementPlanListThunk(retData),
    onFailure: err => setImprovementPlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllImprovementPlanListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let improvementPlans = (retData && retData.retData.data) || [];
  dispatch(setAllImprovementPlanList(messageCode, improvementPlans));
};

export const setAllImprovementPlanList = (messageCode, improvementPlans) => ({
  type: GET_ALL_IMPROVEMENT_PLAN_DETAILS,
  payload: {
    data: {
      improvementPlans: improvementPlans,
      filteredResults: improvementPlans,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_IMPROVEMENT_PLAN_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_IMPROVEMENT_PLAN_PAGE_FILTER,
  payload: { filter }
});

export const describeImprovementPlanThunk = (ImprovementPlanId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeImprovementPlan(ImprovementPlanId, widgetCode, orgId));
};

export const describeImprovementPlan = (ImprovementPlanId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/hazardevents/hazardevent/improvementplans`,
    data: {
      ImprovementPlanId: ImprovementPlanId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setImprovementPlanThunk(ImprovementPlanId, retData),
    onFailure: err => setImprovementPlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setImprovementPlanThunk = (ImprovementPlanId, { retData }) => dispatch => {
  let selectedImprovementPlan = retData.data;
  if (
    (!selectedImprovementPlan && ImprovementPlanId !== null && parseInt(ImprovementPlanId) !== 0) ||
    (ImprovementPlanId &&
      selectedImprovementPlan &&
      Object.keys(selectedImprovementPlan).length > 0 &&
      parseInt(ImprovementPlanId) !== selectedImprovementPlan.ImprovementPlanId)
  ) {
    redirectToPageNotFound(dispatch);
  } else {
    let details = retData.details || [];
    let comments = retData.comments || [];
    let mapData = {
      selectedImprovementPlan: {
        ...selectedImprovementPlan,
        details: details,
        comments: comments
      }
    };
    dispatch(setImprovementPlan(mapData));
  }
};

export const setImprovementPlan = data => ({
  type: SET_IMPROVEMENT_PLAN,
  payload: data
});

export const saveImprovementPlanThunk = (improvementPlan, widgetCode) => dispatch => {
  let url = '/hazardevents/hazardevent/improvementplans/save';
  let actionName = improvementPlan.ImprovementPlanId === 0 ? CREATE_IMPROVEMENT_PLAN : UPDATE_IMPROVEMENT_PLAN;
  let log = logEntry(IMPROVEMENT_PLAN_MANAGEMENT, actionName, improvementPlan);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  improvementPlan.OrgId = orgId;
  dispatch(saveImprovementPlan(improvementPlan, url, log, widgetCode));
};

export const saveImprovementPlan = (improvementPlan, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setImprovementPlanStatusThunk(retData, improvementPlan),
    onFailure: err => setImprovementPlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: improvementPlan,
      log: log
    },
    widgetcode: widgetCode
  });

export const setImprovementPlanStatusThunk = (data, improvementPlan) => dispatch => {
  let message = data;
  if (improvementPlan.performCommentInsert) {
    dispatch(setImprovementPlanComments(data.retData));
  } else {
    dispatch(setImprovementPlanStatus(message, true));
    setTimeout(() => {
      //Hide the banner
      let isRedirect = !improvementPlan.PerformDelete;
      dispatch(setImprovementPlanStatus(message, false, isRedirect));
      if (improvementPlan.PerformDelete) {
        dispatch(removeImprovementPlan(improvementPlan.ImprovementPlanId));
      }
    }, 2500);
  }
};

export const setImprovementPlanStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_IMPROVEMENT_PLAN_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setImprovementPlanComments = data => ({
  type: SET_IMPROVEMENT_PLAN_COMMENTS,
  payload: {
    selectedImprovementPlan: {
      comments: data
    }
  }
});

export const removeImprovementPlan = data => ({
  type: REMOVE_IMPROVEMENT_PLAN,
  payload: {
    improvementPlanId: data
  }
});

export const setImprovementPlanChangeThunk = retData => dispatch => {
  dispatch(setImprovementPlanChange(retData));
};

export const setImprovementPlanChange = retData => ({
  type: SET_IMPROVEMENT_PLAN_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, hazardEventId, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, hazardEventId, siteId));
};

export const getFilter = (widgetCode, hazardEventId, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      HazardEventId: hazardEventId,
      SiteId: siteId,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      filterFor: 'improvementPlanListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setImprovementPlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_IMPROVEMENT_PLAN_FILTERS,
  payload: {
    data: {
      isLoading: false,
      improvementPlanListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_IMPROVEMENT_PLAN_RESULTS,
  payload: retData
});
