import { initialGeneralDataState } from './initialState';

import {
  GET_GENERAL_DATA,
  GENERAL_DATA_IS_LOADING,
  GENERAL_DATA_ERROR,
  SET_ENTITY_COMMON_LIST,
  SET_SITE_COMMON_LIST,
  SET_WATERSUPPLYDESCRIPTION_COMMON_LIST
} from '../../constants/index';

const generalDataReducer = (state = initialGeneralDataState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_GENERAL_DATA: {
      const { data } = payload;
      return {
        ...state,
        generalData: {
          ...state.generalData,
          ...data.generalData,
          isDataPresent: true,
          isLoading: data.isLoading,
          redirect: false
        }
      };
    }

    case GENERAL_DATA_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        generalData: {
          ...state.generalData,
          isLoading: isLoading
        }
      };
    }
    case GENERAL_DATA_ERROR: {
      const { data } = payload;
      return {
        ...state,
        generalData: {
          ...state.generalData,
          ...data
        }
      };
    }

    case SET_ENTITY_COMMON_LIST: {
      const { data } = payload;

      let entitiesData = state.generalData.entities;

      var foundIndex = entitiesData.findIndex(x => x.EntityId == data.EntityId);

      if (foundIndex === -1) {
        entitiesData.push(data);
      } else {
        entitiesData[foundIndex] = data;
      }

      return {
        ...state,
        generalData: {
          ...state.generalData,
          entities: entitiesData
        }
      };
    }

    case SET_SITE_COMMON_LIST: {
      const { data } = payload;

      let sitesData = state.generalData.sites;

      var foundIndex = sitesData.findIndex(x => x.SiteId == data.SiteId);

      if (foundIndex === -1) {
        sitesData.push(data);
      } else {
        sitesData[foundIndex] = data;
      }

      return {
        ...state,
        generalData: {
          ...state.generalData,
          sites: sitesData
        }
      };
    }

    case SET_WATERSUPPLYDESCRIPTION_COMMON_LIST: {
      const { data } = payload;

      let wSPDData = state.generalData.waterSupplyDescriptions;

      var foundIndex = wSPDData.findIndex(x => x.WaterSupplyDescriptionId == data.WaterSupplyDescriptionId);

      if (foundIndex === -1) {
        wSPDData.push(data);
      } else {
        wSPDData[foundIndex] = data;
      }

      return {
        ...state,
        generalData: {
          ...state.generalData,
          waterSupplyDescriptions: wSPDData
        }
      };
    }

    default:
      return state;
  }
};

export default generalDataReducer;
