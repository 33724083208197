import { initialIncidentReportsState } from './initialState';

import {
  INCIDENT_REPORTS_ERROR,
  GET_ALL_INCIDENT_REPORTS_DETAILS,
  INCIDENT_REPORTS_IS_LOADING,
  SET_INCIDENT_REPORTS_CURRENT_PAGE,
  SET_INCIDENT_REPORTS_PAGE_FILTER,
  SET_INCIDENT_REPORTS_CHANGE,
  SET_INCIDENT_REPORTS,
  UPDATE_INCIDENT_REPORTS_SAVE_STATUS,
  SET_INCIDENT_REPORTS_FILTERS,
  SET_INCIDENT_REPORTS_RESULTS
} from '../../constants/index';

const incidentReportReducer = (state = initialIncidentReportsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_INCIDENT_REPORTS_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          ...data
        }
      };
    }

    case SET_INCIDENT_REPORTS_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          ...data
        }
      };
    }

    case SET_INCIDENT_REPORTS_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          ...filteredResults
        }
      };
    }

    case SET_INCIDENT_REPORTS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          currentPage
        }
      };
    }

    case SET_INCIDENT_REPORTS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_INCIDENT_REPORTS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          ...data
        }
      };
    }

    case INCIDENT_REPORTS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          isLoading: isLoading
        }
      };
    }

    case INCIDENT_REPORTS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          ...data
        }
      };
    }

    case SET_INCIDENT_REPORTS: {
      let { selectedIncidentReport } = payload;

      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          isOpSuccessful: false,
          showBanner: false,
          selectedIncidentReport: {
            ...state.incidentReports.selectedIncidentReport,
            ...selectedIncidentReport
          }
        }
      };
    }

    case SET_INCIDENT_REPORTS_CHANGE: {
      let { selectedIncidentReport } = payload;

      return {
        ...state,
        incidentReports: {
          ...state.incidentReports,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedIncidentReport: {
            ...state.incidentReports.selectedIncidentReport,
            ...selectedIncidentReport
          }
        }
      };
    }

    default:
      return state;
  }
};

export default incidentReportReducer;
