import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './Team.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import TeamListTable from './TeamListTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getUserProfileWidget, getTeam, getSelectedSafetyPlan } from '../../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import SearchBar from '../../SearchBar/SearchBar';
import Cookies from 'js-cookie';

import {
  getTeamThunk,
  setFilteredDataThunk,
  setCurrentPage,
  saveTeamThunk,
  setPageFilter,
  getFilterThunk
} from '../../../store/actions/safetyPlan/team';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../../WSAControls/Breadcrumb';
import { describeSafetyPlanThunk } from '../../../store/actions/safetyPlan/safetyPlan';

const SEARCH_FILTER = 'SEARCH_FILTER';
const SEARCH_FILTER_TYPE_NAME = 'SEARCH_FILTER_TYPE_NAME';
const SEARCH_FILTER_TYPE_COMPANY = 'SEARCH_FILTER_TYPE_COMPANY';
const SEARCH_FILTER_TYPE_ROLE = 'SEARCH_FILTER_TYPE_ROLE';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const TeamList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const team = useSelector(state => getTeam(state));

  let { safetyPlanId, siteId, segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'build';
  selectedTab = selectedTab || '2';

  let disableControls = team.isLoading || false;

  const [searchFilter, setSearchFilter] = useState('');
  const [searchfilterType, setSearchFilterType] = useState(SEARCH_FILTER_TYPE_NAME);
  const [teamId, setTeamId] = useState(0);
  const [active, setActive] = useState(false);

  let offset = team.offset || 0;
  let limit = team.limit || 1000;
  let filterLocalName = team.filterBy.filterName || null;
  let filterLocalCompany = team.filterBy.filterCompany || null;
  let filterLocalRole = team.filterBy.filterRole || null;

  useEffect(() => {
    dispatch(describeSafetyPlanThunk(safetyPlanId, 'WQSP_SAFETY_PLAN_UPDATE'));
  }, [safetyPlanId]);

  const selectedSafetyPlan = useSelector(state => getSelectedSafetyPlan(state));
  const safetyPlanName = (selectedSafetyPlan.Name && ` (${selectedSafetyPlan.Name})`) || '';

  useEffect(() => {
    let filterObj = {
      filterName: filterLocalName,
      filterCompany: filterLocalCompany,
      filterRole: filterLocalRole,
      waterQualitySafetyPlanId: safetyPlanId,
      offset: offset,
      limit: limit
    };
    dispatch(getTeamThunk('WQSP_TEAM_LIST', filterObj));
  }, [getTeamThunk, filterLocalCompany, filterLocalName, filterLocalRole, safetyPlanId]);

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_TEAM_LIST', safetyPlanId));
  }, [getFilterThunk, safetyPlanId]);

  let messageId = (team && team.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onTypeChange = e => {
    setSearchFilterType(e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onRemoveTeamClick = teamId => {
    setActive(true);
    setTeamId(teamId);
  };

  const DeleteTeam = () => {
    let saveData = team.selectedTeam;
    saveData.PerformDelete = true;
    saveData.TeamId = teamId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveTeamThunk(saveData, 'WQSP_TEAM_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setTeamId(0);
  };

  const handleConfirm = () => {
    DeleteTeam();
    handleClose();
  };

  let typePlaceHolder = intl.formatMessage({ id: 'common.name', defaultMessage: 'Name' });

  const typeOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="common.name" defaultMessage="Name" />,
      value: SEARCH_FILTER_TYPE_NAME
    });

    statusData.push({
      label: <FormattedMessage id="personnel.company" defaultMessage="Company" />,
      value: SEARCH_FILTER_TYPE_COMPANY
    });

    statusData.push({
      label: <FormattedMessage id="team.role" defaultMessage="Role" />,
      value: SEARCH_FILTER_TYPE_ROLE
    });

    return statusData;
  };

  const filteredResults = (filterType, value) => {
    let filteredList = team.team;

    let apifilterObj = {
      filterName: null,
      filterCompany: null,
      filterRole: null
    };

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_TYPE_COMPANY) {
      if (value !== '') {
        apifilterObj.filterCompany = value;
        filteredList = filteredList.filter(function(item) {
          return item.Company.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_TYPE_COMPANY) {
        apifilterObj.filterCompany = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Company.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_TYPE_NAME) {
      if (value !== '') {
        apifilterObj.filterName = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_TYPE_NAME) {
        apifilterObj.filterName = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_TYPE_ROLE) {
      if (value !== '') {
        apifilterObj.filterRole = value;
        filteredList = filteredList.filter(function(item) {
          return item.Role.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_TYPE_ROLE) {
        apifilterObj.filterRole = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Role.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (team.teamListCount > team.team.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  const getPlaceHolder = searchfilterType => {
    if (searchfilterType === SEARCH_FILTER_TYPE_NAME) {
      return 'personnel.searchLabelName';
    } else if (searchfilterType === SEARCH_FILTER_TYPE_ROLE) {
      return 'team.searchLabelRole';
    } else {
      return 'personnel.searchLabelCompany';
    }
  };

  let teamObj = Object.values(team.filteredResults);

  const onAddNewClick = () => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/teams/team`);
  };

  const onChangeTeamClick = teamId => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/teams/team/${teamId}`);
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TEAM_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TEAM_DELETE');
  //const isUpdateAllowed = true;

  const dialogTitle = intl.formatMessage({
    id: 'team.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this team?'
  });

  let heading = intl.formatMessage({ id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' });
  heading += `${safetyPlanName}`;

  const breadcrumb = [
    {
      orderNo: 1,
      name: heading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="siteControlsTabs.teams" defaultMessage="Teams" />,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  return (
    <div className={s.teamList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.contentWrapper}>
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
          <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
          {team.isLoading && <LoadingSpinner />}
          {team.showBanner && (
            <Banner failureText={messageText} showBanner={team.showBanner} status={team.isOpSuccessful} successText={messageText} />
          )}
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={6}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId={getPlaceHolder(searchfilterType)}
                id="txtSearchBar"
                showFilterType
                searchfilterType={searchfilterType}
                onFilterTypeChange={onTypeChange}
                filterTypeData={typeOptions()}
                filterPlaceholderText={typePlaceHolder}
              />
            </Col>
            <Col sm={6} lg={6}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_TEAM_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addTeamButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination data={teamObj} pageSize={DEFAULT_PAGE_SIZE} onPageChange={onPageChangeHandler} startingPage={team.currentPage}>
              <TeamListTable
                ChangeTeam={onChangeTeamClick}
                RemoveTeam={onRemoveTeamClick}
                UpdateAllowed={isUpdateAllowed}
                DeleteAllowed={isDeleteAllowed}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(TeamList);
