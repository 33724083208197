import React from 'react';
import s from './IncidentReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import { getDateInDDMMYYYHHMMSSFormat } from '../../utils/index';
import MoreOption from '../WSAControls/MoreOption/MoreOption';
import { useParams } from 'react-router-dom';

const IncidentReportListTable = ({ paginatedData, onSortOrder, asendingOrder, UpdateAllowed, currentTimezone }) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  let { segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'respond';
  selectedTab = selectedTab || '2';

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
            </th>
            <th>
              <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
            </th>
            <th>
              <FormattedMessage id="common.title" defaultMessage="Title" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="incidentReports.incidentDateTime" defaultMessage="Incident Date & Time" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="incidentReports.isHACCPCritical" defaultMessage="Is HACCP Critical" />
            </th>

            {UpdateAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.IncidentReportId}>
                <td>{n.SiteName || ''}</td>
                <td>{n.WaterSupplyDescription || ''}</td>
                <td>{n.EntityTypeName || ''}</td>
                <td>{n.Entity || ''}</td>
                <td>{n.Title || ''}</td>
                <td>{getDateInDDMMYYYHHMMSSFormat(n.IncidentDateTime, currentTimezone) || ''}</td>
                <td>
                  {n.IsHACCPCritical ? (
                    <FormattedMessage id="common.yes" defaultMessage="Yes" />
                  ) : (
                    <FormattedMessage id="common.no" defaultMessage="No" /> || ''
                  )}
                </td>
                <td className={s.btnCol}>
                  {UpdateAllowed && (
                    <MoreOption
                      dataArray={[
                        UpdateAllowed
                          ? {
                              Url: `/site/${n.SiteId}/${segmentName}/${selectedTab}/incident-reports/incident-report/${n.IncidentReportId}`,
                              Name: <FormattedMessage id="common.edit" defaultMessage="EDIT" />
                            }
                          : {}
                      ]}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

IncidentReportListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(IncidentReportListTable);
