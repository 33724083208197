import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';

import {
  TASKS_ERROR,
  GET_ALL_TASKS_DETAILS,
  TASKS_IS_LOADING,
  SET_TASKS_CURRENT_PAGE,
  SET_TASKS_PAGE_FILTER,
  SET_TASKS_FILTERS,
  SET_TASKS_CHANGE,
  SET_TASK,
  UPDATE_TASKS_SAVE_STATUS,
  SET_TASKS_FILTERED_RESULTS,
  RESET_TASKS,
  SET_TASKS_COMMENT_CHANGE
} from '../../constants/index';

import { TASKS_MANAGEMENT, CREATE_TASK, UPDATE_TASK, CREATE_TASK_COMMENT } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setTasksErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setTasksError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTasksError(messageCode, false));
  }, 2500);
};

export const setTasksError = (messageCode, status) => {
  return {
    type: TASKS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: TASKS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getTasksThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getTasks(filterObj, widgetCode));
};

export const getTasks = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/management/tasks`,
    data: {
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      Filter: filterObj.filter,
      FilterStatus: filterObj.filterStatus,
      FilterERP: filterObj.filterERP,
      FilterAssignedUserId: filterObj.filterAssignedUser,
      FilterCreatedUserId: filterObj.filterCreatedUser,
      UserId: Cookies.get(`userid-${envName}`),
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0
    },
    widgetcode: widgetCode,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllTasksListThunk(retData),
    onFailure: err => setTasksErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`)
  });

export const setAllTasksListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let tasks = (retData && retData.retData.task) || [];
  dispatch(setAllTasksList(messageCode, tasks));
};

export const setAllTasksList = (messageCode, tasks) => ({
  type: GET_ALL_TASKS_DETAILS,
  payload: {
    data: {
      taskList: tasks,
      filteredResults: tasks,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setTaskFilteredDataThunk = retData => dispatch => {
  dispatch(setTaskFilteredData(retData));
};

export const setTaskFilteredData = retData => ({
  type: SET_TASKS_FILTERED_RESULTS,
  payload: retData
});

export const setCurrentPage = currentPage => ({
  type: SET_TASKS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_TASKS_PAGE_FILTER,
  payload: { filter }
});

export const describeTasksThunk = (TaskId, widgetCode) => dispatch => {
  dispatch(describeTasks(TaskId, widgetCode));
};

export const describeTasks = (TaskId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/management/tasks`,
    data: {
      TaskId: TaskId || undefined
    },
    widgetcode: widgetCode,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTasksThunk(retData, TaskId),
    onFailure: err => setTasksErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`)
  });

export const setTasksThunk = ({ retData }, TaskId) => dispatch => {
  let task = retData.task.length > 0 ? retData.task[0] : [];
  if (TaskId && (task.length === 0 || parseInt(TaskId) !== task.TaskId)) {
    redirectToPageNotFound(dispatch);
  }
  let comments = retData.comments;
  let alarms = retData.alarms;
  dispatch(setTasks(task, comments, alarms));
};

export const setTasks = (data, comments, alarms) => ({
  type: SET_TASK,
  payload: {
    selectedTask: {
      TaskId: data.TaskId || 0,
      OrganisationId: data.OrganisationId || 0,
      ChecklistId: data.ChecklistId || null,
      Name: data.Name || '',
      Description: data.Description || '',
      IsERP: data.IsERP || false,
      GuidIdentifier: data.GuidIdentifier || '',
      Activities: data.Activities || '',
      EstimatedEffort: data.EstimatedEffort || 0,
      ActualEffort: data.ActualEffort || 0,
      AssignedToProfileId: data.AssignedToProfileId || null,
      AssignedToUserId: data.AssignedToUserId || null,
      AssignedByUserId: data.AssignedByUserId || null,
      ProfileName: data.ProfileName || '',
      AssignedUsername: data.AssignedUsername || '',
      AssignedByUsername: data.AssignedByUsername || '',
      Status: data.Status || '',
      IsActive: data.IsActive || '',
      DueDateTime: data.DueDateTime || 0,
      CompletedOnDateTime: data.CompletedOnDateTime || 0,
      CreatedDateTime: data.CreatedDateTime || 0,
      ModifiedDateTime: data.ModifiedDateTime || 0,
      CreatedByUserId: data.CreatedByUserId || null,
      Comments: comments || []
    },
    isOpSuccessful: false,
    showBanner: false
  }
});

export const saveTasksThunk = (task, widgetCode) => dispatch => {
  let url = '/management/tasks/save';
  let actionName = task.TaskId === 0 ? CREATE_TASK : UPDATE_TASK;
  let log = logEntry(TASKS_MANAGEMENT, actionName, task);
  dispatch(saveTasks(task, url, log, widgetCode));
};

export const saveTasks = (task, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTasksStatusThunk(retData, task),
    onFailure: err => setTasksErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      task: task,
      log: log
    },
    widgetcode: widgetCode
  });

export const setTasksStatusThunk = (message, task) => dispatch => {
  dispatch(setTasksStatus(message, true, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTasksStatus('', false, task.MarkAsComplete || task.UpdateActualEffort ? false : true));
  }, 2500);
};

export const setTasksStatus = (messageCode, status, isOpSuccessful) => ({
  type: UPDATE_TASKS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: isOpSuccessful,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setTasksChangeThunk = retData => dispatch => {
  dispatch(setTasksChange(retData));
};

export const setTasksChange = retData => ({
  type: SET_TASKS_CHANGE,
  payload: retData
});

export const saveTasksCommentThunk = (task, widgetCode) => dispatch => {
  let url = `/management/tasks/save/comment`;
  let actionName = CREATE_TASK_COMMENT;
  let log = logEntry(TASKS_MANAGEMENT, actionName, task);
  dispatch(saveTasksComments(task, url, log, widgetCode));
};

export const saveTasksComments = (task, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTasksCommentsStatusThunk(retData),
    onFailure: err => setTasksErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      task: task,
      log: log
    },
    widgetcode: widgetCode
  });

export const setTasksCommentsStatusThunk = ({ message, Comments }) => dispatch => {
  dispatch(setTasksCommentsStatus(Comments));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTasksStatus('', false, false));
  }, 2500);
};

export const setTasksCommentsStatus = retData => ({
  type: SET_TASKS_COMMENT_CHANGE,
  payload: { Comments: retData }
});

export const resetTasksThunk = retData => dispatch => {
  dispatch(resetTasks(retData));
};

export const resetTasks = retData => ({
  type: RESET_TASKS,
  payload: retData
});

export const getFilterThunk = widgetCode => dispatch => {
  dispatch(getFilter(widgetCode));
};

export const getFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      filterFor: 'taskListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setTasksErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_TASKS_FILTERS,
  payload: {
    data: {
      isLoading: false,
      taskListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});
