import { initialInspectionPurposeState } from './initialState';

import {
  INSPECTION_PURPOSE_ERROR,
  GET_ALL_INSPECTION_PURPOSE_DETAILS,
  INSPECTION_PURPOSE_IS_LOADING,
  SET_INSPECTION_PURPOSE_CURRENT_PAGE,
  SET_INSPECTION_PURPOSE_PAGE_FILTER,
  SET_INSPECTION_PURPOSE_CHANGE,
  SET_INSPECTION_PURPOSE_CLEANUP,
  SET_INSPECTION_PURPOSE,
  UPDATE_INSPECTION_PURPOSE_SAVE_STATUS
} from '../../constants/index';

const inspectionPurposeReducer = (state = initialInspectionPurposeState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_INSPECTION_PURPOSE_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        inspectionPurpose: {
          ...state.inspectionPurpose,
          ...data
        }
      };
    }

    case SET_INSPECTION_PURPOSE_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        inspectionPurpose: {
          ...state.inspectionPurpose,
          currentPage
        }
      };
    }

    case SET_INSPECTION_PURPOSE_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        inspectionPurpose: {
          ...state.inspectionPurpose,
          filter
        }
      };
    }

    case UPDATE_INSPECTION_PURPOSE_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        inspectionPurpose: {
          ...state.inspectionPurpose,
          ...data
        }
      };
    }

    case INSPECTION_PURPOSE_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        inspectionPurpose: {
          ...state.inspectionPurpose,
          isLoading: isLoading
        }
      };
    }

    case INSPECTION_PURPOSE_ERROR: {
      const { data } = payload;
      return {
        ...state,
        inspectionPurpose: {
          ...state.inspectionPurpose,
          ...data
        }
      };
    }

    case SET_INSPECTION_PURPOSE: {
      let data = payload;

      return {
        ...state,
        inspectionPurpose: {
          ...state.inspectionPurpose,
          isOpSuccessful: false,
          showBanner: false,
          SelectedInspectionPurpose: {
            ...state.inspectionPurpose.SelectedInspectionPurpose,
            ...data
          }
        }
      };
    }

    case SET_INSPECTION_PURPOSE_CHANGE: {
      let { SelectedInspectionPurpose } = payload;

      return {
        ...state,
        inspectionPurpose: {
          ...state.inspectionPurpose,
          isOpSuccessful: false,
          showBanner: false,
          SelectedInspectionPurpose: {
            ...state.inspectionPurpose.SelectedInspectionPurpose,
            ...SelectedInspectionPurpose
          }
        }
      };
    }
    case SET_INSPECTION_PURPOSE_CLEANUP: {
      let SelectedInspectionPurpose = payload;
      return {
        ...state,
        inspectionPurpose: {
          ...state.inspectionPurpose,
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          SelectedInspectionPurpose: SelectedInspectionPurpose
        }
      };
    }

    default:
      return state;
  }
};

export default inspectionPurposeReducer;
