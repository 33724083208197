import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './CorrectiveAction.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import CorrectiveActionListTable from './CorrectiveActionsListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import { getUserProfileWidget, getCorrectiveAction, getGeneralData } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';
import {
  getCorrectiveActionThunk,
  getFilterThunk,
  setFilteredDataThunk,
  saveCorrectiveActionThunk,
  setCurrentPage,
  setPageFilter
} from '../../store/actions/correctiveAction';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';

const SEARCH_FILTER = 'SEARCH_FILTER';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const CorrectiveActionList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { siteId, segmentName, selectedTab } = useParams();
  siteId = (siteId && parseInt(siteId)) || '0';

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const correctiveAction = useSelector(state => getCorrectiveAction(state));
  const generalData = useSelector(state => getGeneralData(state));
  const [searchFilter, setSearchFilter] = useState('');
  const [active, setActive] = useState(false);
  const [correctiveActionId, setCorrectiveActionId] = useState(0);

  let offset = correctiveAction.offset || 0;
  let limit = correctiveAction.limit || 1000;
  let filter = correctiveAction.filterBy.filter || null;

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_CORRECTIVE_ACTIONS_LIST', siteId));
  }, [dispatch, getFilterThunk, siteId]);

  useEffect(() => {
    let filterObj = {
      siteId: siteId,
      filter: filter,
      offset: offset,
      limit: limit
    };
    dispatch(getCorrectiveActionThunk(filterObj, 'WQSP_CORRECTIVE_ACTIONS_LIST'));
  }, [getCorrectiveActionThunk, siteId]);

  let disableControls = correctiveAction.isLoading || false;
  let messageId = (correctiveAction && correctiveAction.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const deleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CORRECTIVE_ACTIONS_DELETE');
  const updateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CORRECTIVE_ACTIONS_EDIT');
  const viewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CORRECTIVE_ACTIONS_VIEW');

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = correctiveAction.correctiveActions;

    let apifilterObj = {
      filter: null
    };

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Title.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Title.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (correctiveAction.correctiveActionListCount > correctiveAction.correctiveActions.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let correctiveActionObj = Object.values(correctiveAction.filteredResults);

  const onAddNewClick = () => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/corrective-actions/corrective-action`);
  };

  const onCorrectiveActionRemoveClick = correctiveActionId => {
    setActive(true);
    setCorrectiveActionId(correctiveActionId);
  };

  const handleClose = () => {
    setActive(false);
    setCorrectiveActionId(0);
  };

  const handleConfirm = () => {
    deleteCorrectiveAction();
    handleClose();
  };

  const deleteCorrectiveAction = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.CorrectiveActionId = correctiveActionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveCorrectiveActionThunk(saveData, 'WQSP_CORRECTIVE_ACTIONS_DELETE'));
  };

  const dialogTitle = intl.formatMessage({
    id: 'correctiveAction.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this Corrective Action?'
  });

  return (
    <div className={s.correctiveActionList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />

        <div className={s.contentWrapper}>
          {correctiveAction.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={correctiveAction.showBanner}
            status={correctiveAction.isOpSuccessful}
            successText={messageText}
          />
          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="common.search"
              />
            </Col>
            <Col sm={6} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_CORRECTIVE_ACTIONS_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addCorrectiveAction"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={correctiveActionObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={correctiveAction.currentPage}
            >
              <CorrectiveActionListTable
                deleteAllowed={deleteAllowed}
                removeCorrectiveAction={onCorrectiveActionRemoveClick}
                updateAllowed={updateAllowed}
                viewAllowed={viewAllowed}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(CorrectiveActionList);
