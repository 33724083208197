import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';

import {
  INSPECTION_TYPE_ERROR,
  GET_ALL_INSPECTION_TYPE_DETAILS,
  INSPECTION_TYPE_IS_LOADING,
  SET_INSPECTION_TYPE_CURRENT_PAGE,
  SET_INSPECTION_TYPE_PAGE_FILTER,
  SET_INSPECTION_TYPE_CHANGE,
  SET_INSPECTION_TYPE,
  SET_INSPECTION_TYPE_CLEANUP,
  UPDATE_INSPECTION_TYPE_SAVE_STATUS
} from '../../constants/index';

import { INSPECTION_TYPE_MANAGEMENT, CREATE_INSPECTION_TYPE, UPDATE_INSPECTION_TYPE } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setInspectionTypeErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setInspectionTypeError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setInspectionTypeError(messageCode, false));
  }, 2500);
};

export const setInspectionTypeError = (messageCode, status) => {
  return {
    type: INSPECTION_TYPE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: INSPECTION_TYPE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getInspectionTypeThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getInspectionType(widgetCode, orgId));
};

export const getInspectionType = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/inspection/inspectiontype`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllInspectionTypeListThunk(retData),
    onFailure: err => setInspectionTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllInspectionTypeListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllInspectionTypeList(messageCode, data));
};

export const setAllInspectionTypeList = (messageCode, data) => ({
  type: GET_ALL_INSPECTION_TYPE_DETAILS,
  payload: {
    data: {
      InspectionTypes: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_INSPECTION_TYPE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_INSPECTION_TYPE_PAGE_FILTER,
  payload: { filter }
});

export const describeInspectionTypeThunk = (InspectionTypeId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeInspectionType(InspectionTypeId, widgetCode, orgId));
};

export const describeInspectionType = (InspectionTypeId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/inspection/inspectiontype`,
    data: {
      InspectionTypeId: InspectionTypeId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setInspectionTypeThunk(retData, InspectionTypeId),
    onFailure: err => setInspectionTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setInspectionTypeThunk = ({ retData, InspectionTypeId }) => dispatch => {
  let data = retData.data;
  if (!data || data.length === 0 || (InspectionTypeId && data[0].InspectionTypeId !== InspectionTypeId)) {
    redirectToPageNotFound(dispatch);
  }
  dispatch(setInspectionType(data));
};

export const setInspectionType = data => ({
  type: SET_INSPECTION_TYPE,
  payload: data[0]
});

export const saveInspectionTypeThunk = (data, widgetCode) => dispatch => {
  let url = '/management/inspection/inspectiontype/save';
  let actionName = data.InspectionTypeId === 0 ? CREATE_INSPECTION_TYPE : UPDATE_INSPECTION_TYPE;
  let log = logEntry(INSPECTION_TYPE_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveInspectionType(data, url, log, widgetCode));
};

export const saveInspectionType = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setInspectionTypeStatusThunk(retData),
    onFailure: err => setInspectionTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setInspectionTypeStatusThunk = message => dispatch => {
  dispatch(setInspectionTypeStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setInspectionTypeStatus(message, false));
  }, 2500);
};

export const setInspectionTypeStatus = (messageCode, status) => ({
  type: UPDATE_INSPECTION_TYPE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: true,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setInspectionTypeChangeThunk = retData => dispatch => {
  dispatch(setInspectionTypeChange(retData));
};

export const setInspectionTypeChange = retData => ({
  type: SET_INSPECTION_TYPE_CHANGE,
  payload: retData
});

export const setInspectionTypeCleanUpThunk = SelectedInspectionType => dispatch => {
  dispatch({ type: SET_INSPECTION_TYPE_CLEANUP, payload: SelectedInspectionType });
};
