import React from 'react';
import s from './SafetyPlanReportList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../../selectors/index';
import { getDateInDDMMYYYHHMMSSFormat } from '../../../utils/index';
import Button from 'react-bootstrap/Button';

const SafetyPlanReportListTable = ({ paginatedData, DownloadReport, RemoveReport, DownloadAllowed, DeleteAllowed, intl }) => {
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="safetyPlan.generatedBy" defaultMessage="Generated By" />
            </th>
            <th>
              <FormattedMessage id="safetyPlan.generatedDate" defaultMessage="Generated Date" />
            </th>
            {(DownloadAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.SafetyPlanReportId}>
                <td>{n.GeneratedBy || ''}</td>
                <td>{getDateInDDMMYYYHHMMSSFormat(n.CreatedDateTime, currentTimezone) || ''}</td>
                <td className={s.btnCol}>
                  {DeleteAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        RemoveReport(n.SafetyPlanReportId);
                      }}
                      data-unittest="removeReport"
                    >
                      <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                    </Button>
                  )}
                  {DownloadAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        DownloadReport(n.SafetyPlanReportId);
                      }}
                      data-unittest="downloadReport"
                    >
                      <FormattedMessage id="common.download" defaultMessage="DOWNLOAD" />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

SafetyPlanReportListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(SafetyPlanReportListTable);
