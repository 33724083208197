import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './InspectionTypeQuestion.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getInspectionTypeQuestion } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialInspectionTypeQuestionState } from '../../store/reducers/initialState';
import {
  getInspectionTypeQuestionThunk,
  saveInspectionTypeQuestionThunk,
  describeInspectionTypeQuestionThunk,
  setInspectionTypeQuestions
} from '../../store/actions/inspectionTypeQuestion';
import InspectionTypeQuestionListTable from './InspectionTypeQuestionListTable';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const InspectionTypeQuestionList = ({ userProfileWidgets, inspectionTypeSpecificQuestions, inspectionSurveyTypeId, intl }) => {
  const history = useHistory();

  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;

  const dispatch = useDispatch();
  const inspectionTypeQuestion = useSelector(state => getInspectionTypeQuestion(state));

  let initialRecords = inspectionTypeQuestion.InspectionTypeQuestions.slice(0, initialLimit);
  const [inspectionTypeQuestionOpen, setInspectionTypeQuestionOpen] = useState(false);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setInspectionTypeQuestions([], []));
    };
  }, []);

  useEffect(() => {
    if (!inspectionTypeSpecificQuestions) {
      dispatch(getInspectionTypeQuestionThunk('WQSP_INSPECTIONTYPEQUESTION_LIST'));
    } else if (inspectionSurveyTypeId) {
      dispatch(describeInspectionTypeQuestionThunk(null, 'WQSP_INSPECTIONTYPEQUESTION_LIST', inspectionSurveyTypeId));
    }
  }, [getInspectionTypeQuestionThunk, dispatch, inspectionTypeQuestion.isOpSuccessful, inspectionSurveyTypeId]);

  const [localInspectionTypeQuestions, setLocalInspectionTypeQuestions] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [inspectionTypeQuestionId, setInspectionTypeQuestionId] = useState(0);
  const [inspectionTypeId, setInspectionTypeId] = useState(0);
  const [searchText, setSearchText] = useState('');

  const localInspectionTypeQuestionLength = localInspectionTypeQuestions.length;
  const globalSettingLength = inspectionTypeQuestion.InspectionTypeQuestions.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalInspectionTypeQuestions(inspectionTypeQuestion.InspectionTypeQuestions);
    }
    setShowAll(!showAll);
  };

  const searchHandler = (value, isDropDown = false) => {
    if (value === '' || value === 0) {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }

    let filteredList = inspectionTypeQuestion.InspectionTypeQuestions;

    if (isDropDown) {
      setInspectionTypeId(value);
      filteredList = filteredList.filter(function(item) {
        return item.InspectionTypeId === value;
      });
      if (searchText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.QuestionText.toLowerCase().includes(searchText.toLowerCase());
        });
      }
    } else {
      setSearchText(value);
      filteredList = filteredList.filter(function(item) {
        return item.QuestionText.toLowerCase().includes(value.toLowerCase());
      });

      if (inspectionTypeId) {
        filteredList = filteredList.filter(function(item) {
          return item.InspectionTypeId === inspectionTypeId;
        });
      }
    }

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalInspectionTypeQuestions(filteredList);
  };

  const filterData = () => {
    let filteredList = inspectionTypeQuestion.InspectionTypeQuestions;
    if (isFilter) {
      if (searchText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.QuestionText.toLowerCase().includes(searchText.toLowerCase());
        });
      }
      if (inspectionTypeId) {
        filteredList = filteredList.filter(function(item) {
          return item.InspectionTypeId === inspectionTypeId;
        });
      }
    }

    return filteredList;
  };

  const getInspectionTypeQuestionData = () => {
    let inspectionTypeQuestion = [];
    if (showAll) {
      inspectionTypeQuestion = filterData();
    } else {
      if (isFilter) {
        inspectionTypeQuestion = localInspectionTypeQuestionLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        inspectionTypeQuestion = initialRecords;
      }
    }
    return inspectionTypeQuestion.sort(compareValues('InspectionTypeId'));
  };

  const onRemoveInspectionTypeQuestionClick = inspectionTypeQuestionId => {
    setActive(true);
    setInspectionTypeQuestionId(inspectionTypeQuestionId);
  };

  const DeleteInspectionTypeQuestion = () => {
    let saveData = inspectionTypeQuestion.SelectedInspectionTypeQuestion;
    saveData.PerformDelete = true;
    saveData.InspectionTypeQuestionId = inspectionTypeQuestionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveInspectionTypeQuestionThunk(saveData, 'WQSP_INSPECTIONTYPEQUESTION_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setInspectionTypeQuestionId(0);
  };

  const handleConfirm = () => {
    DeleteInspectionTypeQuestion();
    handleClose();
  };

  const onAddNewClick = () => {
    if (inspectionTypeSpecificQuestions) {
      history.push(`/operational-setup/inspection-report/inspection-type-question/0/${inspectionSurveyTypeId}`);
    } else {
      history.push(`/operational-setup/inspection-report/inspection-type-question/`);
    }
  };

  const onChangeInspectionTypeQuestionClick = inspectionTypeQuestionId => {
    if (inspectionTypeSpecificQuestions) {
      history.push(`/operational-setup/inspection-report/inspection-type-question/${inspectionTypeQuestionId}/${inspectionSurveyTypeId}`);
    } else {
      history.push(`/operational-setup/inspection-report/inspection-type-question/${inspectionTypeQuestionId}`);
    }
  };

  let messageId = (inspectionTypeQuestion && inspectionTypeQuestion.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONTYPEQUESTION_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONTYPEQUESTION_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'inspectionTypeQuestions.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this inspection purpose?'
  });

  const inspectionTypeOptions = () => {
    let inspectionTypesData = [];

    const data = inspectionTypeQuestion.InspectionTypes;

    data.forEach(element => {
      inspectionTypesData.push({
        label: element.Name,
        value: element.InspectionTypeId
      });
    });

    inspectionTypesData.push({
      label: <FormattedMessage id="inspectionTypeQuestions.allInspectionType" defaultMessage="All inspection types" />,
      value: 0
    });

    return inspectionTypesData.sort(compareValues('label'));
  };

  const component = (
    <div className={s.innerComponent} id="model-component-type-section">
      <div className={s.component}>
        {inspectionTypeQuestion.isLoading && <LoadingSpinner />}
        {inspectionTypeQuestion.showBanner && (
          <Banner
            failureText={messageText}
            showBanner={inspectionTypeQuestion.showBanner}
            status={inspectionTypeQuestion.isOpSuccessful}
            successText={messageText}
          />
        )}
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
        <div className={s.contentWrapper}>
          <Row className={s.topRow}>
            {!inspectionTypeSpecificQuestions && (
              <Col sm={3} lg={3}>
                <Dropdown
                  id="formInspectionType"
                  dataArray={inspectionTypeOptions()}
                  controlData={{
                    placeholderText: (
                      <FormattedMessage id="inspectionTypeQuestions.selectInspectionType" defaultMessage="Select Survey Type" />
                    )
                  }}
                  name="InspectionTypeId"
                  onDropdownChange={e => searchHandler(e.value, true)}
                  selectedOption={inspectionTypeOptions().filter(option => option.value === inspectionTypeId)}
                  data-unittest="formSensor"
                />
              </Col>
            )}
            <Col sm={3} lg={3}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                disabled={inspectionTypeQuestion.showAddEditScreen}
                // initialText={filter}
                placeHolderTextId="common.search"
                data-unittest="searchInspectionTypeQuestions"
              />
            </Col>
            <Col sm={!inspectionTypeSpecificQuestions ? 6 : 9} lg={!inspectionTypeSpecificQuestions ? 6 : 9}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_INSPECTIONTYPEQUESTION_ADDNEW')}
                onClick={onAddNewClick}
                disabled={inspectionTypeQuestion.showAddEditScreen}
                data-unittest="addInspectionTypeQuestionButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div>
            <InspectionTypeQuestionListTable
              RemoveInspectionTypeQuestion={onRemoveInspectionTypeQuestionClick}
              InspectionTypeQuestionData={getInspectionTypeQuestionData()}
              ChangeInspectionTypeQuestion={onChangeInspectionTypeQuestionClick}
              UpdateAllowed={isUpdateAllowed}
              DeleteAllowed={isDeleteAllowed}
              inspectionTypeSpecificQuestions={inspectionTypeSpecificQuestions}
            />
          </div>
          {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localInspectionTypeQuestionLength > initialLimit)) && (
            <Row className={s.topRow}>
              <Col>
                <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                  {!showAll
                    ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localInspectionTypeQuestionLength})`
                    : showLessText}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={s.inspectionTypeQuestionList}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'WQSP_INSPECTIONTYPEQUESTION_LIST')}>
        {!inspectionTypeSpecificQuestions && (
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <img
                  src={inspectionTypeQuestionOpen ? collapseDown : collapseUp}
                  alt="Collapse section"
                  onClick={() => setInspectionTypeQuestionOpen(!inspectionTypeQuestionOpen)}
                  aria-controls="model-component-type-section"
                  aria-expanded={inspectionTypeQuestionOpen}
                ></img>
                <h4>
                  <FormattedMessage id="inspectionTypeQuestions.inspectionTypeQuestion" defaultMessage="Inspection Questions" />
                </h4>
                <span className={s.circle}>{inspectionTypeQuestion.InspectionTypeQuestions.length}</span>
              </Row>
            </Col>
          </Row>
        )}
        {!inspectionTypeSpecificQuestions && <Collapse in={inspectionTypeQuestionOpen}>{component}</Collapse>}
        {inspectionTypeSpecificQuestions && <div>{component}</div>}
      </div>
    </div>
  );
};

InspectionTypeQuestionList.defaultProps = {
  ...initialInspectionTypeQuestionState
};

export default injectIntl(InspectionTypeQuestionList);
