import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TaskTemplateDetail from './TaskTemplateDetail';
import { getTaskTemplateManager, getUserProfileWidget } from '../../selectors/index';
import { describeTaskTemplateThunk } from '../../store/actions//taskTemplates';

const mapStateToProps = state => {
  return {
    taskTemplateManager: getTaskTemplateManager(state),
    userProfileWidgets: getUserProfileWidget(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      describeTaskTemplate: (taskTemplateId, widgetCode) => describeTaskTemplateThunk(taskTemplateId, widgetCode)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TaskTemplateDetail);
