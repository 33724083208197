import { connect } from 'react-redux';
import Sidebar from './Sidebar';
import { bindActionCreators } from 'redux';
import {
  getCurrentSidebarState,
  getUserProfileWidget,
  getUserProfileWidgetsPresent,
  getGeneralDataPresentState,
  getTranslation
} from '../../selectors/index';
import { toggleSidebar } from '../../store/actions/sidebar';
import { getSelectedUserProfileWidgetsThunk } from '../../store/actions/UserProfileWidgets';
import { getTranslationThunk } from '../../store/actions/translation';
import { getGeneralDataThunk } from '../../store/actions/generalData';

const mapStateToProps = state => {
  return {
    currentSidebarOpen: getCurrentSidebarState(state),
    userProfileWidgets: getUserProfileWidget(state),
    isGeneralDataPresent: getGeneralDataPresentState(state),
    userProfileWidgetsPresent: getUserProfileWidgetsPresent(state),
    translation: getTranslation(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleViewSidebar: sidebarOpen => toggleSidebar(sidebarOpen),
      getSelectedUserProfileWidgets: (profileId, userName) => getSelectedUserProfileWidgetsThunk(profileId, userName, 'GENERALACCESS'),
      getTranslationData: locale => getTranslationThunk('GENERALACCESS', locale),
      getGeneralData: () => getGeneralDataThunk('GENERALACCESS')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
