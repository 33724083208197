import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './OrganisationTranslations.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import OrganisationTranslationListTable from './OrganisationTranslationsListTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { LOCALE, TRASLATION_KEYS } from '../../../constants/organisationTranslations';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils/';
import { getUserProfileWidget, getOrganisationTranslation, getGeneralData } from '../../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import OperationalSetupContentTemplate from '../../OperationalSetup/OperationalSetupContentTemplate';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import SearchBar from '../../SearchBar/SearchBar';
import Cookies from 'js-cookie';

import {
  getOrganisationTranslationThunk,
  setFilteredDataThunk,
  setCurrentPage,
  saveOrganisationTranslationThunk,
  getFilterThunk,
  setPageFilter
} from '../../../store/actions/admin/organisationTranslations';

const FIELD_LOCALE = 'FIELD_LOCALE';
const FIELD_KEY = 'FIELD_KEY';
const SEARCH_FILTER = 'SEARCH_FILTER';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const OrganisationTranslationList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const organisationTranslation = useSelector(state => getOrganisationTranslation(state));
  let disableControls = organisationTranslation.isLoading || false;

  let filter = organisationTranslation.filterBy.filter || null;
  let filterLocalLocale = organisationTranslation.filterBy.filterLocale || null;
  let filterLocalKey = organisationTranslation.filterBy.filterTranslationKey || null;

  let offset = organisationTranslation.offset || 0;
  let limit = organisationTranslation.limit || 1000;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterLocale, setFilterLocale] = useState('');
  const [filterKey, setFilterKey] = useState('');
  const [organisationTranslationId, setOrganisationTranslationId] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    let filterObj = {
      filter: filter,
      filterLocale: filterLocalLocale,
      filterTranslationKey: filterLocalKey,
      offset: offset,
      limit: limit
    };
    dispatch(getOrganisationTranslationThunk('WQSP_TRANSLATION_TAB', filterObj));
  }, [getOrganisationTranslationThunk, filter, filterLocalLocale, filterLocalKey]);

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_TRANSLATION_TAB'));
  }, [getFilterThunk]);

  let messageId = (organisationTranslation && organisationTranslation.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const localeOptions = () => {
    let opData = [];
    const data = LOCALE;
    data.forEach(element => {
      Object.keys(element).forEach(key => {
        opData.push({
          label: element[key],
          value: key
        });
      });
    });
    opData = opData.sort(compareValues('label'));
    opData = [
      {
        label: <FormattedMessage id="common.allLanguages" defaultMessage="All Languages" />,
        value: ''
      },
      ...opData
    ];

    return opData;
  };

  const translationKeyOptions = () => {
    let opData = [];

    if (filterLocale) {
      const data = TRASLATION_KEYS[filterLocale];
      data.forEach(element => {
        opData.push({
          label: element,
          value: element
        });
      });
    }

    opData = opData.sort(compareValues('label'));
    opData = [
      {
        label: <FormattedMessage id="common.allTranslationKeys" defaultMessage="All Translation Keys" />,
        value: ''
      },
      ...opData
    ];

    return opData;
  };

  const onDropdownChange = (e, fieldName) => {
    if (fieldName === FIELD_LOCALE) {
      setFilterLocale(e.value);
      setFilterKey('');
    }

    if (fieldName === FIELD_KEY) {
      setFilterKey(e.value);
    }

    filteredResults(fieldName, e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onRemoveOrganisationTranslationClick = organisationTranslationId => {
    setActive(true);
    setOrganisationTranslationId(organisationTranslationId);
  };

  const DeleteOrganisationTranslation = () => {
    let saveData = organisationTranslation.selectedOrganisationTranslation;
    saveData.PerformDelete = true;
    saveData.OrganisationTranslationId = organisationTranslationId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveOrganisationTranslationThunk(saveData, 'WQSP_TRANSLATIONS_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setOrganisationTranslationId(0);
  };

  const handleConfirm = () => {
    DeleteOrganisationTranslation();
    handleClose();
  };

  const filteredResults = (filterType, value) => {
    let filteredList = organisationTranslation.OrganisationTranslations;

    let apifilterObj = {
      filter: null,
      filterLocale: null,
      filterTranslationKey: null
    };

    if (filterType === FIELD_LOCALE) {
      if (value !== '') {
        apifilterObj.filterLocale = value;
        filteredList = filteredList.filter(function(item) {
          return item.Locale === value;
        });
      }
    } else {
      if (filterLocale !== '') {
        apifilterObj.filterLocale = filterLocale;
        filteredList = filteredList.filter(function(item) {
          return item.Locale === filterLocale;
        });
      }
    }

    if (filterType === FIELD_KEY) {
      if (value !== '') {
        apifilterObj.filterTranslationKey = value;
        filteredList = filteredList.filter(function(item) {
          return item.TranslationKey === value;
        });
      }
    } else {
      if (filterKey !== '' && filterType !== FIELD_LOCALE) {
        apifilterObj.filterTranslationKey = filterKey;
        filteredList = filteredList.filter(function(item) {
          return item.TranslationKey === filterKey;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.TranslationValue.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.TranslationValue.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (organisationTranslation.organisationTranslationListCount > organisationTranslation.OrganisationTranslations.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let organisationTranslationObj = Object.values(organisationTranslation.filteredResults);
  organisationTranslationObj = organisationTranslationObj.sort(compareValues('InspectionDate'));

  const onAddNewClick = () => {
    history.push('/operational-setup/organisation-translations/translation');
  };

  const onChangeOrganisationTranslationClick = organisationTranslationId => {
    history.push(`/operational-setup/organisation-translations/translation/${organisationTranslationId}`);
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_GENERAL_FIELDTYPES_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_GENERAL_FIELDTYPES_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'organisationTranslations.confirmDelete',
    defaultMessage: 'Are you sure you want to delete translation item?'
  });

  return (
    <div className={s.organisationTranslationList}>
      <OperationalSetupContentTemplate selectedPage="organisationTranslations" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
          {organisationTranslation.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={organisationTranslation.showBanner}
            status={organisationTranslation.isOpSuccessful}
            successText={messageText}
          />
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={3}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="organisationTranslations.translationValue"
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="formLocale"
                dataArray={localeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="common.allLanguages" defaultMessage="All Languages" />,
                  customClassName: ''
                }}
                name="Locale"
                onDropdownChange={e => onDropdownChange(e, FIELD_LOCALE)}
                selectedOption={localeOptions().filter(option => option.value === filterLocale)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="formTranslationKeys"
                dataArray={translationKeyOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="common.allTranslationKeys" defaultMessage="All Translation Keys" />,
                  customClassName: ''
                }}
                name="TranslationKey"
                onDropdownChange={e => onDropdownChange(e, FIELD_KEY)}
                selectedOption={translationKeyOptions().filter(option => option.value === filterKey)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={12} lg={5}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_GENERAL_FIELDTYPES_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addOrganisationTranslationButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={organisationTranslationObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={organisationTranslation.currentPage}
            >
              <OrganisationTranslationListTable
                ChangeOrganisationTranslation={onChangeOrganisationTranslationClick}
                RemoveOrganisationTranslation={onRemoveOrganisationTranslationClick}
                UpdateAllowed={isUpdateAllowed}
                DeleteAllowed={isDeleteAllowed}
              />
            </Pagination>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

export default injectIntl(OrganisationTranslationList);
