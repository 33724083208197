import React from 'react';
import s from './EntitySubType.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';

import Button from 'react-bootstrap/Button';

const EntitySubTypeListTable = ({ paginatedData, ChangeEntitySubType, RemoveEntitySubType, UpdateAllowed, DeleteAllowed, intl }) => {
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
            </th>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />{' '}
            </th>
            <th>
              <FormattedMessage id="common.description" defaultMessage="Description" />
            </th>

            {UpdateAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.EntitySubTypeId}>
                <td>{n.EntityType || ''}</td>
                <td>{n.Name || ''}</td>
                <td>{n.Description || ''}</td>
                <td className={s.btnCol}>
                  {DeleteAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        RemoveEntitySubType(n.EntitySubTypeId);
                      }}
                      data-unittest="removeData"
                    >
                      <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                    </Button>
                  )}
                  {UpdateAllowed && (
                    <Button
                      variant="outline-secondary"
                      className={s.opButton}
                      href="#"
                      onClick={() => {
                        ChangeEntitySubType(n.EntitySubTypeId);
                      }}
                      data-unittest="changeData"
                    >
                      <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

EntitySubTypeListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(EntitySubTypeListTable);
