import { initialControlMonitoringState } from './initialState';

import {
  CONTROL_MONITORINGS_ERROR,
  CONTROL_MONITORINGS_IS_LOADING,
  SET_CONTROL_MONITORINGS_CURRENT_PAGE,
  SET_CONTROL_MONITORINGS_PAGE_FILTER,
  SET_CONTROL_MONITORINGS,
  UPDATE_CONTROL_MONITORINGS_SAVE_STATUS,
  SET_CONTROL_MONITORINGS_FILTERS,
  SET_CONTROL_MONITORINGS_RESULTS,
  REMOVE_CONTROL_MONITORINGS,
  SET_CONTROL_MONITORINGS_CHANGE,
  GET_ALL_CONTROL_MONITORINGS_BY_SITE,
  GET_ALL_CONTROL_MONITORINGS_DETAILS
} from '../../constants/index';

const controlMonitoringReducer = (state = initialControlMonitoringState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_CONTROL_MONITORINGS_BY_SITE: {
      const { data } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          ...data
        }
      };
    }

    case GET_ALL_CONTROL_MONITORINGS_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          ...data
        }
      };
    }

    case SET_CONTROL_MONITORINGS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          currentPage
        }
      };
    }

    case SET_CONTROL_MONITORINGS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_CONTROL_MONITORINGS_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          ...data
        }
      };
    }

    case REMOVE_CONTROL_MONITORINGS: {
      const { controlMonitoringId } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          controlMonitorings: [
            ...state.controlMonitoring.controlMonitorings.filter(item => item.ControlMonitoringId !== controlMonitoringId)
          ],
          filteredResults: [...state.controlMonitoring.filteredResults.filter(item => item.ControlMonitoringId !== controlMonitoringId)],
          controlMonitoringListCount: state.controlMonitoring.controlMonitoringListCount - 1
        }
      };
    }

    case CONTROL_MONITORINGS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          isLoading: isLoading
        }
      };
    }

    case CONTROL_MONITORINGS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          ...data
        }
      };
    }

    case SET_CONTROL_MONITORINGS: {
      let { selectedControlMonitoring } = payload;

      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          isOpSuccessful: false,
          showBanner: false,
          selectedControlMonitoring: {
            ...state.controlMonitoring.selectedControlMonitoring,
            ...selectedControlMonitoring
          }
        }
      };
    }

    case SET_CONTROL_MONITORINGS_CHANGE: {
      let { selectedControlMonitoring } = payload;

      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedControlMonitoring: {
            ...state.controlMonitoring.selectedControlMonitoring,
            ...selectedControlMonitoring
          }
        }
      };
    }

    case SET_CONTROL_MONITORINGS_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          ...filteredResults
        }
      };
    }

    case SET_CONTROL_MONITORINGS_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        controlMonitoring: {
          ...state.controlMonitoring,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default controlMonitoringReducer;
