import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ChecklistTemplate.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialChecklistTemplateState } from '../../store/reducers//initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Redirect, Link, useParams, useHistory } from 'react-router-dom';
import TaskTemplates from './TaskTemplates';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import classNames from 'classnames';
import { isRequired, getQuerystring } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const ChecklistTemplate = ({
  intl,
  userProfileWidgets,
  checklistTemplate,
  describeChecklistTemplate,
  saveChecklistTemplate,
  changeChecklistTemplate
}) => {
  const history = useHistory();

  let IsEdit = false;
  let IsView = false;

  let { checklistTemplateId } = useParams();
  let view = getQuerystring('view');

  const [disableTaskTemplate, setDisableTaskTemplate] = useState(false);
  const [formError, setFormError] = useState({});
  const [searchKeywords, setSearchKeywords] = useState('');

  checklistTemplateId = parseInt(checklistTemplateId);

  IsEdit = checklistTemplateId !== 0 && !view;
  IsView = checklistTemplateId !== 0 && view === '1';

  if (IsEdit && !IsWidgetAccessible(userProfileWidgets, 'WQSP_CHECKLISTTEMPLATEEDIT')) {
    history.push('/unauth');
  }

  if (IsView && !IsWidgetAccessible(userProfileWidgets, 'WQSP_CHECKLISTTEMPLATEVIEW')) {
    history.push('/unauth');
  }

  if (checklistTemplateId === 0 && !IsWidgetAccessible(userProfileWidgets, 'WQSP_CHECKLISTTEMPLATEADDNEW')) {
    history.push('/unauth');
  }

  let widgetCode = '';

  if (IsEdit) {
    widgetCode = 'WQSP_CHECKLISTTEMPLATEEDIT';
  } else if (IsView) {
    widgetCode = 'WQSP_CHECKLISTTEMPLATEVIEW';
  } else {
    widgetCode = 'WQSP_CHECKLISTTEMPLATEADDNEW';
  }

  useEffect(() => {
    changeChecklistTemplate({
      SelectedChecklistTemplate: {
        ...initialChecklistTemplateState.checklistTemplate.SelectedChecklistTemplate,
        SelectedTaskTemplates: []
      }
    });
    describeChecklistTemplate(checklistTemplateId, widgetCode);
  }, [describeChecklistTemplate, changeChecklistTemplate, checklistTemplateId, IsView]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = checklistTemplate.SelectedChecklistTemplate;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;

    if (!IsEdit) {
      saveData.TaskTemplateId = 0;
      saveData.GuidIdentifier = uuidv4();
    }

    saveChecklistTemplate(saveData, widgetCode);
  };

  const validateInput = () => {
    let formError = {};

    let isValid = true;

    if (
      !checklistTemplate ||
      !checklistTemplate.SelectedChecklistTemplate ||
      !checklistTemplate.SelectedChecklistTemplate.Name ||
      isRequired(checklistTemplate.SelectedChecklistTemplate.Name, 1)
    ) {
      formError.Name = intl.formatMessage({
        id: 'checklistTemplateManagement.nameMandatory',
        defaultMessage: 'Checklist Name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !checklistTemplate ||
      !checklistTemplate.SelectedChecklistTemplate ||
      !checklistTemplate.SelectedChecklistTemplate.SelectedTaskTemplates ||
      checklistTemplate.SelectedChecklistTemplate.SelectedTaskTemplates.length === 0
    ) {
      formError.Task = intl.formatMessage({
        id: 'checklistTemplateManagement.taskTemplateMandatory',
        defaultMessage: 'Task Tamplate is a mandatory field'
      });
      isValid = false;
    }
    setFormError(formError);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      checklistTemplate.SelectedChecklistTemplate = {
        ...checklistTemplate.SelectedChecklistTemplate,
        [e.target.name]: e.target.checked
      };
    } else {
      checklistTemplate.SelectedChecklistTemplate = {
        ...checklistTemplate.SelectedChecklistTemplate,
        [e.target.name]: e.target.value
      };
    }
    changeChecklistTemplate(checklistTemplate);
  };

  const onTaskAdd = taskTemplateId => {
    let selectedTask = checklistTemplate.TaskTemplates.filter(e => e.TaskTemplateId === taskTemplateId);
    if (selectedTask && selectedTask.length > 0) {
      selectedTask[0].UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
      checklistTemplate.SelectedChecklistTemplate.SelectedTaskTemplates.push(selectedTask[0]);
      changeChecklistTemplate(checklistTemplate);
    }
  };

  const onTaskSearch = keyWords => {
    setSearchKeywords(keyWords);
  };

  const getSelectedTaskTemplates = () => {
    return checklistTemplate.SelectedChecklistTemplate.SelectedTaskTemplates.filter(
      task => task.Name.toLowerCase().indexOf(searchKeywords.toLowerCase()) >= 0
    );
  };

  const onTaskRemove = taskTemplateId => {
    let selectedTasks = checklistTemplate.SelectedChecklistTemplate.SelectedTaskTemplates.filter(e => e.TaskTemplateId !== taskTemplateId);
    checklistTemplate.SelectedChecklistTemplate.SelectedTaskTemplates = selectedTasks;
    changeChecklistTemplate(checklistTemplate);
  };

  const onAddNewOrCancelClick = status => {
    setDisableTaskTemplate(status);
  };

  let messageId = (checklistTemplate && checklistTemplate.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const checklistName = (checklistTemplate.SelectedChecklistTemplate ? checklistTemplate.SelectedChecklistTemplate.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + checklistName;
  } else if (IsView) {
    heading = checklistName + ' ' + intl.formatMessage({ id: 'checklistTemplate.checklistTemplate', defaultMessage: 'Checklist Template' });
  } else {
    heading = intl.formatMessage({ id: 'checklistTemplate.newChecklistTemplate', defaultMessage: 'New Checklist Template' });
  }

  const backLink = !IsEdit
    ? '/operational-setup/checklist-task-templates'
    : `/operational-setup/checklist-template/${checklistTemplateId}?view=1`;

  const redirect = () => {
    if (checklistTemplate.isOpSuccessful && !checklistTemplate.showBanner) {
      changeChecklistTemplate({
        SelectedChecklistTemplate: {
          ...initialChecklistTemplateState.checklistTemplate.SelectedChecklistTemplate,
          SelectedTaskTemplates: []
        }
      });
      return <Redirect to={backLink} />;
    }
  };

  return (
    <div className={s.checklistTemplate}>
      {checklistTemplate.isLoading && <LoadingSpinner />}
      {redirect()}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={checklistTemplate.showBanner}
        status={checklistTemplate.isOpSuccessful}
        successText={messageText}
      />

      <OperationalSetupContentTemplate selectedPage="checklistTaskTemplate" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.checklistTemplateHeader}>
            <Link className={s.backLink} to={backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </Link>
            <Row>
              <Col>
                <h3 data-unittest="headingLabel">{heading}</h3>
              </Col>
              <Col className={s.colChangeButton}>
                {IsView && (
                  <Link
                    to={`/operational-setup/checklist-template/${checklistTemplateId}`}
                    style={WidgetVisibility(userProfileWidgets, `WQSP_CHECKLISTTEMPLATEEDIT`)}
                  >
                    <Button
                      variant="primary"
                      data-unittest="saveData"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      noValidate
                      disabled={disableTaskTemplate}
                    >
                      <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
          </div>
          <div className={s.checklistTemplateContent}>
            <h4>
              <FormattedMessage id="checklistTemplate.details" defaultMessage="Details" />
            </h4>
            {/* EDIT SCREEN START */}
            {!IsView && (
              <Form className={s.viewRow}>
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group controlId="formChecklistTemplateName">
                      <Form.Label>
                        <FormattedMessage id="checklistTemplateManagement.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={checklistTemplate.SelectedChecklistTemplate.Name}
                        className={`${s.formControl} ${formError && formError.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {formError && formError.Name && (
                        <p role="alert" className={s.error}>
                          {formError.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formChecklistTemplateDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>
                      <Form.Control
                        className={s.txtArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={checklistTemplate.SelectedChecklistTemplate.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formChecklistTemplateIsERP">
                      <Checkbox
                        key={uuidv4()}
                        dataArray={[
                          {
                            SKEY: 'IsERP',
                            target: { type: 'checkbox' },
                            label: intl.formatMessage({
                              id: 'wqsp.common.emergencyResponsePlan',
                              defaultMessage: 'Emergency Response Plan'
                            }),
                            isChecked: checklistTemplate.SelectedChecklistTemplate.IsERP
                          }
                        ]}
                        onSelectionChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
            {/* EDIT SCREEN END */}

            {/* VIEW SCREEN START */}
            {IsView && (
              <Form>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="common.name" defaultMessage="Name" />
                    </Form.Label>
                  </Col>
                  <Col>{checklistTemplate.SelectedChecklistTemplate.Name}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Group controlId="formChecklistTemplateDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>{checklistTemplate.SelectedChecklistTemplate.Description}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Group controlId="formChecklistTemplateIsERP">
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.emergencyResponsePlan" defaultMessage="Emergency Response Plan" />
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col>
                    {checklistTemplate.SelectedChecklistTemplate.IsERP ? (
                      <FormattedMessage id="common.yes" defaultMessage="Yes" />
                    ) : (
                      <FormattedMessage id="common.no" defaultMessage="No" />
                    )}
                  </Col>
                </Row>
              </Form>
            )}
            {/* VIEW SCREEN END */}
          </div>
          <div>
            <TaskTemplates
              onTaskSearch={onTaskSearch}
              onTaskAdd={onTaskAdd}
              onTaskRemove={onTaskRemove}
              onAddNewOrCancelClick={onAddNewOrCancelClick}
              selectedTaskTemplates={getSelectedTaskTemplates() || []}
              taskTemplates={checklistTemplate.TaskTemplates}
              isView={IsView}
              isEdit={IsEdit}
              checklistTemplateId={checklistTemplateId}
            />
          </div>
          {formError && formError.Task && (
            <p role="alert" className={s.error}>
              {formError.Task}
            </p>
          )}
          <div>
            {!IsView && (
              <Form>
                <Button
                  variant="primary"
                  data-unittest="saveData"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  disabled={disableTaskTemplate}
                >
                  <FormattedMessage id="common.saveChanges" defaultMessage="Save Changes" />
                </Button>

                <Link to={backLink}>
                  <Button disabled={disableTaskTemplate} variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                  </Button>
                </Link>
              </Form>
            )}
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

ChecklistTemplate.defaultProps = {
  checklistTemplate: {
    ...initialChecklistTemplateState.checklistTemplate
  }
};

ChecklistTemplate.propTypes = {
  saveChecklistTemplate: PropTypes.func.isRequired,
  checklistTemplate: PropTypes.object.isRequired,
  describeChecklistTemplate: PropTypes.func.isRequired,
  changeChecklistTemplate: PropTypes.func.isRequired
};

export default injectIntl(ChecklistTemplate);
