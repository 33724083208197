import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './WaterSupplyDescription.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import WaterSupplyDescriptionListTable from './WaterSupplyDescriptionListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues, downloadCsvFile, flattenObject } from '../../utils/';
import { getUserProfileWidget, getWaterSupplyDescription, getCurrentTimezone } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';

import {
  getWaterSupplyDescriptionThunk,
  getFilterThunk,
  setFilteredDataThunk,
  setCurrentPage,
  setPageFilter
} from '../../store/actions/waterSupplyDescriptions';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import SearchBar from '../SearchBar/SearchBar';

const SEARCH_FILTER = 'SEARCH_FILTER';

const WaterSupplyDescriptionList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const waterSupplyDescription = useSelector(state => getWaterSupplyDescription(state));
  let { siteId, segmentName, selectedTab } = useParams();

  const [asendingOrder, setAsendingOrder] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');

  let disableControls = waterSupplyDescription.isLoading || false;

  let filter = waterSupplyDescription.filterBy.filter || null;
  let offset = waterSupplyDescription.offset || 0;
  let limit = waterSupplyDescription.limit || 1000;

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_WATERSUPPLYDESCRIPTIONS_LIST', siteId));
  }, [dispatch, getFilterThunk]);

  useEffect(() => {
    let filterObj = {
      filter: filter,
      offset: offset,
      limit: limit
    };

    dispatch(getWaterSupplyDescriptionThunk('WQSP_WATERSUPPLYDESCRIPTIONS_LIST', siteId, filterObj));
  }, [getWaterSupplyDescriptionThunk, siteId, filter]);

  let messageId = (waterSupplyDescription && waterSupplyDescription.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = waterSupplyDescription.WaterSupplyDescriptions;

    let apifilterObj = {
      filter: null
    };

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (waterSupplyDescription.waterSupplyDescriptionListCount > waterSupplyDescription.WaterSupplyDescriptions.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let waterSupplyDescriptionObj = Object.values(waterSupplyDescription.filteredResults);

  if (asendingOrder) {
    waterSupplyDescriptionObj = waterSupplyDescriptionObj.sort(compareValues('SuppliedWaterVolume'));
  } else {
    waterSupplyDescriptionObj = waterSupplyDescriptionObj.sort(compareValues('SuppliedWaterVolume', 'desc'));
  }

  const onAddNewClick = () => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/water-supply-descriptions/water-supply-description`);
  };

  const onChangeWaterSupplyDescriptionClick = waterSupplyDescriptionId => {
    history.push(
      `/site/${siteId}/${segmentName}/${selectedTab}/water-supply-descriptions/water-supply-description/${waterSupplyDescriptionId}`
    );
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_WATERSUPPLYDESCRIPTIONS_UPDATE');

  return (
    <div className={s.waterSupplyDescriptionList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.contentWrapper}>
          {waterSupplyDescription.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={waterSupplyDescription.showBanner}
            status={waterSupplyDescription.isOpSuccessful}
            successText={messageText}
          />

          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="site.searchName"
              />
            </Col>
            <Col sm={6} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_WATERSUPPLYDESCRIPTIONS_ADD')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addWaterSupplyDescriptionsButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={waterSupplyDescriptionObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={waterSupplyDescription.currentPage}
            >
              <WaterSupplyDescriptionListTable
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                ChangeWaterSupplyDescription={onChangeWaterSupplyDescriptionClick}
                UpdateAllowed={isUpdateAllowed}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(WaterSupplyDescriptionList);
