import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';
import _ from 'lodash';

import {
  TEST_CATEGORY_ERROR,
  GET_ALL_TEST_CATEGORY_DETAILS,
  TEST_CATEGORY_IS_LOADING,
  SET_TEST_CATEGORY_CURRENT_PAGE,
  SET_TEST_CATEGORY_PAGE_FILTER,
  SET_TEST_CATEGORY_CHANGE,
  SET_TEST_CATEGORY,
  UPDATE_TEST_CATEGORY_SAVE_STATUS,
  TEST_CATEGORY_ITEM_EXISTS_STATUS,
  SET_TEST_CATEGORY_CLEANUP
} from '../../constants/index';

import { TEST_CATEGORY_MANAGEMENT, CREATE_TEST_CATEGORY, UPDATE_TEST_CATEGORY } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setTestCategoryErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setTestCategoryError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTestCategoryError(messageCode, false));
  }, 2500);
};

export const setTestCategoryError = (messageCode, status) => {
  return {
    type: TEST_CATEGORY_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: TEST_CATEGORY_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getTestCategoryThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getTestCategory(widgetCode, orgId));
};

export const getTestCategory = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/testresult/testcategories`,
    method: 'GET',
    data: {
      OrganisationId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllTestCategoryListThunk(retData),
    onFailure: err => setTestCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllTestCategoryListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let testCategories = (retData && retData.retData.testCategory) || [];
  let groupData = _.groupBy(testCategories, item => {
    return item.TestCategoryId + ',' + item.CategoryName + ',' + item.CategoryDescription + ',' + item.OrganisationId + ',' + item.IsActive;
  });
  let groupMapData = _.map(groupData, function(group) {
    return {
      TestCategoryId: group[0].TestCategoryId,
      Name: group[0].CategoryName,
      Description: group[0].CategoryDescription,
      OrganisationId: group[0].OrganisationId,
      IsActive: group[0].IsActive,
      testCategoryTypes:
        group && group[0].TestCategoryItemId
          ? group.map(item => {
              return {
                TestCategoryItemId: item.TestCategoryItemId,
                Name: item.Name,
                Description: item.Description,
                UnitOfMeasure: item.UnitOfMeasure,
                RealisticMeanValue: item.RealisticMeanValue || '',
                RealisticMaxValue: item.RealisticMaxValue || '',
                RealisticLRV: item.RealisticLRV || '',
                AspirationalMeanValue: item.AspirationalMeanValue || '',
                AspirationalMaxValue: item.AspirationalMaxValue || '',
                AspirationalLRV: item.AspirationalLRV || '',
                Guidentifier: item.Guidentifier
              };
            })
          : []
    };
  });

  dispatch(setAllTestCategoryList(messageCode, groupMapData));
};

export const setAllTestCategoryList = (messageCode, testCategories) => ({
  type: GET_ALL_TEST_CATEGORY_DETAILS,
  payload: {
    data: {
      TestCategories: testCategories,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_TEST_CATEGORY_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_TEST_CATEGORY_PAGE_FILTER,
  payload: { filter }
});

export const describeTestCategoryThunk = (TestCategoryId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeTestCategory(TestCategoryId, widgetCode, orgId));
};

export const describeTestCategory = (TestCategoryId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/testresult/testcategories`,
    data: {
      TestCategoryId: TestCategoryId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTestCategoryThunk(retData),
    onFailure: err => setTestCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setTestCategoryThunk = ({ retData }) => dispatch => {
  let data = retData.testCategory && retData.testCategory.selectedTestCategory;
  let mapData = {
    selectedTestCategory: {
      TestCategoryId: data[0].TestCategoryId,
      Name: data[0].CategoryName,
      Description: data[0].CategoryDescription,
      OrganisationId: data[0].OrganisationId,
      IsActive: data[0].IsActive,
      testCategoryTypes:
        data && data[0].TestCategoryItemId
          ? data.map(item => {
              return {
                TestCategoryItemId: item.TestCategoryItemId,
                Name: item.Name,
                Description: item.Description,
                UnitOfMeasure: item.UnitOfMeasure,
                RealisticMeanValue: item.RealisticMeanValue || '',
                RealisticMaxValue: item.RealisticMaxValue || '',
                RealisticLRV: item.RealisticLRV || '',
                AspirationalMeanValue: item.AspirationalMeanValue || '',
                AspirationalMaxValue: item.AspirationalMaxValue || '',
                AspirationalLRV: item.AspirationalLRV || '',
                GuidIdentifier: item.GuidIdentifier
              };
            })
          : []
    }
  };

  dispatch(setTestCategory(mapData));
};

export const setTestCategory = data => ({
  type: SET_TEST_CATEGORY,
  payload: data
});

export const saveTestCategoryThunk = (testCategory, widgetCode) => dispatch => {
  let url = '/management/testresult/testcategories/save';
  let actionName = testCategory.TestCategoryId === 0 ? CREATE_TEST_CATEGORY : UPDATE_TEST_CATEGORY;
  let log = logEntry(TEST_CATEGORY_MANAGEMENT, actionName, testCategory);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  testCategory.OrganisationId = orgId;
  dispatch(saveTestCategory(testCategory, url, log, widgetCode));
};

export const saveTestCategory = (testCategory, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTestCategoryStatusThunk(retData, testCategory),
    onFailure: err => setTestCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      testCategory: testCategory,
      log: log
    },
    widgetcode: widgetCode
  });

export const setTestCategoryStatusThunk = (message, testCategory) => dispatch => {
  dispatch(setTestCategoryStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !testCategory.PerformDelete;
    dispatch(setTestCategoryStatus(message, false, isRedirect));
  }, 2500);
};

export const setTestCategoryStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_TEST_CATEGORY_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setTestCategoryChangeThunk = retData => dispatch => {
  dispatch(setTestCategoryChange(retData));
};

export const setTestCategoryChange = retData => ({
  type: SET_TEST_CATEGORY_CHANGE,
  payload: retData
});

export const checkTestCategoryItemExistsThunk = (testCategory, widgetCode) => dispatch => {
  let url = '/management/testresult/testcategories/save';
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  testCategory.OrganisationId = orgId;
  dispatch(checkTestCategoryItemExists(testCategory, url, widgetCode));
};

export const checkTestCategoryItemExists = (testCategory, url, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTestCategoryItemExistsStatusThunk(retData, testCategory),
    onFailure: err => setTestCategoryErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      testCategory: testCategory
    },
    widgetcode: widgetCode
  });

export const setTestCategoryItemExistsStatusThunk = (retData, testCategory) => dispatch => {
  dispatch(setTestCategoryItemExistsStatus(retData.message, false, testCategory));
};

export const setTestCategoryItemExistsStatus = (messageCode, status, testCategory) => ({
  type: TEST_CATEGORY_ITEM_EXISTS_STATUS,
  payload: {
    data: {
      isOpSuccessful: false,
      showBanner: status,
      isLoading: false,
      isRedirect: false,
      displayMessageCode: messageCode || ''
    },
    testCategoryItemId: testCategory.TestCategoryItemId
  }
});

export const setTestCategoryCleanUpThunk = retData => dispatch => {
  dispatch(setTestCategoryCleanUp(retData));
};

export const setTestCategoryCleanUp = retData => ({
  type: SET_TEST_CATEGORY_CLEANUP,
  payload: retData
});
