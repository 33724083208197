import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './CorrectiveAction.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialCorrectiveActionsState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Redirect, Link, useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { compareValues } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import 'react-datetime/css/react-datetime.css';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Breadcrumb from '../WSAControls/Breadcrumb';
import { getUserProfileWidget, getCorrectiveAction, getTeam } from '../../selectors/index';

import {
  describeCorrectiveActionThunk,
  saveCorrectiveActionThunk,
  setCorrectiveActionChangeThunk,
  getControlMonitoringsThunk
} from '../../store/actions/correctiveAction';

import { getTeamThunk } from '../../store/actions/safetyPlan/team';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const CorrectiveAction = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  const history = useHistory();
  let { correctiveActionId, siteId, segmentName, selectedTab } = useParams();
  siteId = (siteId && parseInt(siteId)) || '0';

  const [localformErrors, setFormErrors] = useState({});
  const [disableMode, setDisableMode] = useState(false);

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const correctiveAction = useSelector(state => getCorrectiveAction(state));
  const team = useSelector(state => getTeam(state));

  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });

  if (correctiveActionId && correctiveActionId > 0) {
    IsEdit = true;
  } else {
    correctiveActionId = 0;
  }

  let widgetCode = 'WQSP_CORRECTIVE_ACTIONS_ADDNEW';
  let backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/corrective-actions`;
  if (IsEdit) {
    widgetCode = 'WQSP_CORRECTIVE_ACTIONS_EDIT';
  }

  useEffect(() => {
    let filterObj = {
      filterName: '',
      filterCompany: '',
      offset: 0,
      limit: 1000
    };
    dispatch(getTeamThunk('WQSP_CORRECTIVE_ACTIONS_ADDNEW', filterObj, null));
  }, [getTeamThunk, null]);

  useEffect(() => {
    if (IsEdit) dispatch(describeCorrectiveActionThunk(correctiveActionId, widgetCode));
  }, [describeCorrectiveActionThunk, correctiveActionId]);

  useEffect(() => {
    let filterObj = {
      siteId: siteId,
      filter: '',
      offset: 0,
      limit: 1000,
      getControlMonitoringsBySiteId: true
    };
    dispatch(getControlMonitoringsThunk(filterObj, widgetCode));
  }, [getControlMonitoringsThunk, siteId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setCorrectiveActionChangeThunk({
          ...initialCorrectiveActionsState.correctiveAction
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalCorrectiveAction = currentState => {
    dispatch(setCorrectiveActionChangeThunk(currentState));
  };

  useEffect(() => {
    if (siteId && !correctiveAction.selectedCorrectiveAction.SiteId) {
      setLocalCorrectiveAction({
        ...correctiveAction,
        selectedCorrectiveAction: { ...correctiveAction.selectedCorrectiveAction, SiteId: siteId }
      });
    }
  }, [setLocalCorrectiveAction, siteId]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = {
      ...correctiveAction.selectedCorrectiveAction
      // WaterQualitySafetyPlanId: safetyPlanId
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.CorrectiveActionId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    dispatch(saveCorrectiveActionThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!correctiveAction.selectedCorrectiveAction || !correctiveAction.selectedCorrectiveAction.ControlMonitoringId) {
      formErrors.ControlMonitoringId = intl.formatMessage({
        id: 'correctiveAction.controlMonitoringMandatory',
        defaultMessage: 'Control Monitoring is mandatory field'
      });
      isValid = false;
    }

    if (!correctiveAction.selectedCorrectiveAction || !correctiveAction.selectedCorrectiveAction.TeamId) {
      formErrors.Team = intl.formatMessage({
        id: 'correctiveAction.teamMandatory',
        defaultMessage: 'Team is mandatory field'
      });
      isValid = false;
    }

    if (!correctiveAction.selectedCorrectiveAction || !correctiveAction.selectedCorrectiveAction.Title) {
      formErrors.Title = intl.formatMessage({
        id: 'common.titleRequired',
        defaultMessage: 'Title is mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalCorrectiveAction({
        ...correctiveAction,
        selectedCorrectiveAction: { ...correctiveAction.selectedCorrectiveAction, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalCorrectiveAction({
        ...correctiveAction,
        selectedCorrectiveAction: { ...correctiveAction.selectedCorrectiveAction, [e.target.name]: e.target.value }
      });
    }
  };

  const controlMonitoringOptions = () => {
    let opData = [];
    correctiveAction.controlMonitorings.forEach(element => {
      opData.push({
        ...element,
        label: element.MonitoredItem,
        value: element.ControlMonitoringId,
        isDisabled: false
        //isDisabled: filteredItems.find(item => item.TeamId === element.TeamId) ? true : false
      });
    });

    let sortedData = opData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const teamOptions = () => {
    //let filteredItems = correctiveActions.filter(item => !item.IsDeleted);
    let categoryData = [];
    team.team.forEach(element => {
      categoryData.push({
        ...element,
        label: element.Name,
        value: element.TeamId,
        isDisabled: false
        //isDisabled: filteredItems.find(item => item.TeamId === element.TeamId) ? true : false
      });
    });

    let sortedData = categoryData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onControlMonitoringDropdownChange = e => {
    setLocalCorrectiveAction({
      ...correctiveAction,
      selectedCorrectiveAction: { ...correctiveAction.selectedCorrectiveAction, ControlMonitoringId: e.value }
    });
  };

  const onTeamDropdownChange = e => {
    setLocalCorrectiveAction({
      ...correctiveAction,
      selectedCorrectiveAction: { ...correctiveAction.selectedCorrectiveAction, TeamId: e.value, PersonnelName: e.label }
    });
  };

  let messageId = (correctiveAction && correctiveAction.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' });
    heading = `${heading} - ${correctiveAction.selectedCorrectiveAction.Title}`;
  } else {
    heading = intl.formatMessage({ id: 'correctiveAction.addNewCorrectiveAction', defaultMessage: 'Add new Corrective Action' });
  }

  const breadcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.listOfCorrectiveActions" defaultMessage="List of Corrective Actions" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/corrective-actions`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 2, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <div className={s.correctiveAction}>
      {correctiveAction.isLoading && <LoadingSpinner />}
      {correctiveAction.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={correctiveAction.showBanner}
        status={correctiveAction.isOpSuccessful}
        successText={messageText}
      />
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        <div className={s.contentWrapper}>
          <div className={s.correctiveActionContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formControlMonitoring">
                    <Form.Label>
                      <FormattedMessage id="correctiveAction.controlMonitoring" defaultMessage="Control Monitoring" />
                    </Form.Label>

                    <Dropdown
                      id="formDdControlMonitoring"
                      dataArray={controlMonitoringOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.ControlMonitoringId && s.ddlError }}
                      onDropdownChange={onControlMonitoringDropdownChange}
                      data-unittest="formDdlHformDdTeamazard"
                      selectedOption={controlMonitoringOptions().filter(
                        option => option.value === correctiveAction.selectedCorrectiveAction.ControlMonitoringId
                      )}
                      disabled={IsEdit}
                    />
                    {localformErrors && localformErrors.ControlMonitoringId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.ControlMonitoringId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTeam">
                    <Form.Label>
                      <FormattedMessage id="correctiveAction.team" defaultMessage="Team" />
                    </Form.Label>

                    <Dropdown
                      id="formDdTeam"
                      dataArray={teamOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Team && s.ddlError }}
                      onDropdownChange={onTeamDropdownChange}
                      data-unittest="formDdlHformDdTeamazard"
                      selectedOption={teamOptions().filter(option => option.value === correctiveAction.selectedCorrectiveAction.TeamId)}
                    />
                    {localformErrors && localformErrors.Team && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Team}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTitle">
                    <Form.Label>
                      <FormattedMessage id="common.title" defaultMessage="Title" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Title"
                      onChange={onChange}
                      value={correctiveAction.selectedCorrectiveAction.Title || ''}
                      className={`${s.formControl} ${localformErrors && localformErrors.Title ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'common.title',
                        defaultMessage: 'Title'
                      })}
                    />
                    {localformErrors && localformErrors.Title && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Title}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formWhenActionTriggered">
                    <Form.Label>
                      <FormattedMessage id="correctiveAction.whenActionTriggered" defaultMessage="When Action Triggered" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="WhenActionTriggered"
                      onChange={onChange}
                      value={correctiveAction.selectedCorrectiveAction.WhenActionTriggered || ''}
                      className={`${s.formControl}`}
                      placeholder={intl.formatMessage({
                        id: 'correctiveAction.whenActionTriggered',
                        defaultMessage: 'When Action Triggered'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formDetails">
                    <Form.Label>
                      <FormattedMessage id="common.details" defaultMessage="Details" />
                    </Form.Label>

                    <Form.Control
                      className={s.textArea}
                      as="textarea"
                      rows="3"
                      name="Details"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'common.details',
                        defaultMessage: 'Details'
                      })}
                      value={correctiveAction.selectedCorrectiveAction.Details}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formRecords">
                    <Form.Label>
                      <FormattedMessage id="controlMonitoring.records" defaultMessage="Records" />
                    </Form.Label>

                    <Form.Control
                      className={s.textArea}
                      as="textarea"
                      rows="3"
                      name="Records"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'controlMonitoring.records',
                        defaultMessage: 'Records'
                      })}
                      value={correctiveAction.selectedCorrectiveAction.Records}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div>
            <Row className={s.breakRow}>
              <Col sm={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                  disabled={disableMode || correctiveAction.isLoading}
                >
                  <FormattedMessage id="common.save" defaultMessage="SAVE" />
                </Button>
                <Link to={backUrl}>
                  <Button variant="outline-secondary" className={s.btnCancel} disabled={disableMode}>
                    <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(CorrectiveAction);
