import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';
import _ from 'lodash';

import {
  CONTROL_MONITORINGS_ERROR,
  GET_ALL_CONTROL_MONITORINGS_DETAILS,
  CONTROL_MONITORINGS_IS_LOADING,
  SET_CONTROL_MONITORINGS_CURRENT_PAGE,
  SET_CONTROL_MONITORINGS_PAGE_FILTER,
  SET_CONTROL_MONITORINGS,
  UPDATE_CONTROL_MONITORINGS_SAVE_STATUS,
  SET_CONTROL_MONITORINGS_FILTERS,
  SET_CONTROL_MONITORINGS_RESULTS,
  REMOVE_CONTROL_MONITORINGS,
  SET_CONTROL_MONITORINGS_CHANGE
} from '../../constants/index';

import { CONTROL_MONITORING_MANAGEMENT, CREATE_CONTROL_MONITORING, UPDATE_CONTROL_MONITORING } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setControlMonitoringErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setControlMonitoringError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setControlMonitoringError(messageCode, false));
  }, 2500);
};

export const setControlMonitoringError = (messageCode, status) => {
  return {
    type: CONTROL_MONITORINGS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: CONTROL_MONITORINGS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getControlMonitoringThunk = (widgetCode, filterObj, siteId) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getControlMonitoring(widgetCode, orgId, filterObj, siteId));
};

export const getControlMonitoring = (widgetCode, orgId, filterObj, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/controlmonitorings/controlmonitoring`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      offset: filterObj.offset,
      limit: filterObj.limit,
      filter: filterObj.filter,
      SiteId: siteId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllControlMonitoringListThunk(retData),
    onFailure: err => setControlMonitoringErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllControlMonitoringListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let controlMonitorings = (retData && retData.retData.controlMonitorings) || [];
  dispatch(setAllControlMonitoringList(messageCode, controlMonitorings));
};

export const setAllControlMonitoringList = (messageCode, controlMonitorings) => ({
  type: GET_ALL_CONTROL_MONITORINGS_DETAILS,
  payload: {
    data: {
      controlMonitorings: controlMonitorings,
      filteredResults: controlMonitorings,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_CONTROL_MONITORINGS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_CONTROL_MONITORINGS_PAGE_FILTER,
  payload: { filter }
});

export const setControlMonitoringThunk = ({ retData }) => dispatch => {
  let selectedControlMonitoring = retData.controlMonitorings;
  let comments = retData.comments || [];
  let mapData = {
    selectedControlMonitoring: {
      ...selectedControlMonitoring,
      comments: comments
    }
  };

  dispatch(setControlMonitoring(mapData));
};

export const setControlMonitoring = data => ({
  type: SET_CONTROL_MONITORINGS,
  payload: data
});

export const saveControlMonitoringThunk = (controlMonitoring, widgetCode) => dispatch => {
  let url = '/controlmonitorings/controlmonitoring/save';
  let actionName = controlMonitoring.ControlMonitoringId === 0 ? CREATE_CONTROL_MONITORING : UPDATE_CONTROL_MONITORING;
  let log = logEntry(CONTROL_MONITORING_MANAGEMENT, actionName, controlMonitoring);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  controlMonitoring.OrgId = orgId;
  dispatch(saveControlMonitoring(controlMonitoring, url, log, widgetCode));
};

export const saveControlMonitoring = (controlMonitoring, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setControlMonitoringStatusThunk(retData, controlMonitoring),
    onFailure: err => setControlMonitoringErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: controlMonitoring,
      log: log
    },
    widgetcode: widgetCode
  });

export const setControlMonitoringStatusThunk = (data, controlMonitoring) => dispatch => {
  let message = data;
  dispatch(setControlMonitoringStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !controlMonitoring.PerformDelete;
    dispatch(setControlMonitoringStatus(message, false, isRedirect));
    if (controlMonitoring.PerformDelete) {
      dispatch(removeControlMonitoring(controlMonitoring.ControlMonitoringId));
    }
  }, 2500);
};

export const setControlMonitoringStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_CONTROL_MONITORINGS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const removeControlMonitoring = data => ({
  type: REMOVE_CONTROL_MONITORINGS,
  payload: {
    controlMonitoringId: data
  }
});

export const setControlMonitoringChangeThunk = retData => dispatch => {
  dispatch(setControlMonitoringChange(retData));
};

export const setControlMonitoringChange = retData => ({
  type: SET_CONTROL_MONITORINGS_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      SiteId: siteId,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      filterFor: 'controlMonitoringListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setControlMonitoringErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_CONTROL_MONITORINGS_FILTERS,
  payload: {
    data: {
      isLoading: false,
      controlMonitoringListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_CONTROL_MONITORINGS_RESULTS,
  payload: retData
});

export const getControlMonitoringsThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getControlMonitorings(widgetCode, orgId, filterObj));
};

export const getControlMonitorings = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/controlMonitorings/correctiveaction`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      offset: filterObj.offset,
      limit: filterObj.limit,
      filter: filterObj.filter,
      SiteId: filterObj.siteId,
      GetControlMonitoringsBySiteId: filterObj.getControlMonitoringsBySiteId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllControlMonitoringListThunk(retData),
    onFailure: err => setControlMonitoringErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });
