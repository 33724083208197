import React from 'react';
import { Route, Switch } from 'react-router';
import Dashboard from '../components/Dashboard/Dashboard';
import NoRoute from '../components/NoRoute/NoRoute';
import Login from '../components/Login/LoginContainer';
import UpdatePassword from '../components/UpdatePassword/UpdatePasswordContainer';
import auth from '../hocs/auth';
import noAuth from '../hocs/noAuth';
import GeneralSettings from '../components/Settings/Settings';
import ProfileSettings from '../components/ProfileSettings/ProfileSettingsContainer';
import AdminControls from '../components/AdminControls/AdminControlsContainer';
import OperationalSetup from '../components/OperationalSetup/OperationalSetupContainer';
import OrganisationList from '../components/OrganisationList/OrganisationListContainer';
import Organisation from '../components/Organisation/OrganisationContainer';
import ProfileList from '../components/ProfileList/ProfileListContainer';
import Profile from '../components/Profile/ProfileContainer';
import UserList from '../components/UserList/UserListContainer';
import User from '../components/User/UserContainer';
import CustomRoute from './CustomRoute';
import UnAuth401 from '../components/Unauthorised/Unauthorised';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import MFAuthentication from '../components/MFAuthentication/MFAuthentication';
import ChecklistTaskTemplates from '../components/ChecklistTasksTemplates/ChecklistTasksTemplatesContainer';
import ChecklistTemplate from '../components/ChecklistTemplate/ChecklistTemplateContainer';
import TaskTemplate from '../components/TaskTemplate/TaskTemplateContainer';
import TaskTemplateDetail from '../components/TaskTemplateDetail/TaskTemplateDetailContainer';
import Checklists from '../components/Checklists/ChecklistsContainer';
import ChecklistDetail from '../components/ChecklistDetail/ChecklistDetailContainer';
import ChecklistTaskList from '../components/TasksAndChecklists/TasksAndChecklistsContainer';
import TaskList from '../components/TaskList/TasksContainer';
import Task from '../components/Task/TaskContainer';
import TaskDetail from '../components/TaskDetail/TaskDetailContainer';
import InspectionReportAdmin from '../components/InspectionReportAdmin/InspectionReportAdmin';
import InspectionPurpose from '../components/InspectionPurpose/InspectionPurpose';
import TestPurpose from '../components/TestPurpose/TestPurpose';
import TestCategory from '../components/TestCategory/TestCategory';
import TestResultAdmin from '../components/TestResultAdmin/TestResultAdmin';
import InspectionType from '../components/InspectionType/InspectionType';
import InspectionTypeQuestion from '../components/InspectionTypeQuestion/InspectionTypeQuestion';
import InspectionReportList from '../components/InspectionReport/InspectionReportList';
import InspectionReport from '../components/InspectionReport/InspectionReport';
import TestResultReport from '../components/TestResultReport/TestResultReport';
import TestResultReportList from '../components/TestResultReport/TestResultReportList';
import SiteList from '../components/Sites/SiteList';
import QualityGoalsReport from '../components/QualityGoalsReport/QualityGoalsReport';
import QualityGoalsReportList from '../components/QualityGoalsReport/QualityGoalsReportList';
import EntityList from '../components/Entity/EntityList';
import Site from '../components/Sites/Site';
import Entity from '../components/Entity/Entity';
import WaterSupplyDescription from '../components/WaterSupplyDescriptions/WaterSupplyDescription';
import WaterSupplyDescriptionList from '../components/WaterSupplyDescriptions/WaterSupplyDescriptionList';
import GeneralFieldType from '../components/GeneralFieldType/GeneralFieldType';
import GeneralFieldTypeList from '../components/GeneralFieldType/GeneralFieldTypeList';
import RiskCategory from '../components/RiskCategory/RiskCategory';
import RiskCategoryList from '../components/RiskCategory/RiskCategoryList';
import HazardEvent from '../components/HazardEvent/HazardEvent';
import HazardEventView from '../components/HazardEvent/HazardEventView';
import HazardEventList from '../components/HazardEvent/HazardEventList';
import HazardsTab from '../components/HazardsTab/HazardsTab';
import HazardEventLibrary from '../components/HazardEventLibrary/HazardEventLibrary';
import HazardEventLibraryDetails from '../components/HazardEventLibrary/HazardEventLibraryDetails';
import Hazard from '../components/Hazards/Hazards';

import RiskAssessment from '../components/RiskAssessment/RiskAssessment';
import RiskAssessmentView from '../components/RiskAssessment/RiskAssessmentView';
import RiskAssessmentList from '../components/RiskAssessment/RiskAssessmentList';

import SafetyPlanList from '../components/SafetyPlan/WQSP/SafetyPlanList';
import SafetyPlan from '../components/SafetyPlan/WQSP/SafetyPlan';

import EmergencyPlanList from '../components/SafetyPlan/EmergencyResponsePlan/EmergencyPlanList';
import EmergencyPlan from '../components/SafetyPlan/EmergencyResponsePlan/EmergencyPlan';
import EmergencyPlanView from '../components/SafetyPlan/EmergencyResponsePlan/EmergencyPlanView';

import Personnel from '../components/Admin/Personnel/Personnel';
import PersonnelList from '../components/Admin/Personnel/PersonnelList';

import RegulatoryRegisterList from '../components/SafetyPlan/RegulatoryRegister/RegulatoryRegisterList';
import RegulatoryRegister from '../components/SafetyPlan/RegulatoryRegister/RegulatoryRegister';

import TeamList from '../components/SafetyPlan/Team/TeamList';
import Team from '../components/SafetyPlan/Team/Team';

import StakeholderRegisterList from '../components/SafetyPlan/StakeholderRegister/StakeholderRegisterList';
import StakeholderRegister from '../components/SafetyPlan/StakeholderRegister/StakeholderRegister';

import SafetyPlanReportList from '../components/SafetyPlan/SafetyPlanReport/SafetyPlanReportList';

import ControlMeasureList from '../components/HazardEvent/ControlMeasures/ControlMeasureList';
import ControlMeasureView from '../components/HazardEvent/ControlMeasures/ControlMeasureView';
import ControlMeasure from '../components/HazardEvent/ControlMeasures/ControlMeasure';

import ImprovementPlanList from '../components/HazardEvent/ImprovementPlans/ImprovementPlanList';
import ImprovementPlanView from '../components/HazardEvent/ImprovementPlans/ImprovementPlanView';
import ImprovementPlan from '../components/HazardEvent/ImprovementPlans/ImprovementPlan';

import ControlMonitoring from '../components/ControlMonitorings/ControlMonitoring';
import ControlMonitoringView from '../components/ControlMonitorings/ControlMonitoringView';

import CorrectiveActionView from '../components/HazardEvent/ControlMeasures/CorrectiveActions/CorrectiveActionView';
import SiteSegments from '../components/Dashboard/SiteSegments/SiteSegments';
import OrganisationTranslations from '../components/Admin/OrganisationTranslations/OrganisationTranslations';
import OrganisationTranslationsList from '../components/Admin/OrganisationTranslations/OrganisationTranslationsList';

import IncidentReport from '../components/IncidentReport/IncidentReport';
import IncidentReportList from '../components/IncidentReport/IncidentReportList';

import CorrectiveAction from '../components/CorrectiveActions/CorrectiveAction';
import CorrectiveActionViewSegment from '../components/CorrectiveActions/CorrectiveActionView';
import CorrectiveActionList from '../components/CorrectiveActions/CorrectiveActionsList';
import ControlMonitoringList from '../components/ControlMonitorings/ControlMonitoringList';

import EntitySubType from '../components/Admin/EntitySubType/EntitySubType';
import EntitySubTypeList from '../components/Admin/EntitySubType/EntitySubTypeList';

const routes = (
  <Switch>
    <Route exact path="/login" component={noAuth(Login)} />
    <Route exact path="/update-password" component={noAuth(UpdatePassword)} />

    <CustomRoute exact path="/" component={auth(Dashboard)} pageAccessCode={'NAV_HOME'} accessCodes={['NAV_HOME']} />

    <CustomRoute exact path="/dashboard" component={auth(Dashboard)} pageAccessCode={'NAV_HOME'} accessCodes={['NAV_HOME']} />

    <CustomRoute
      exact
      path="/operational-setup"
      component={auth(OperationalSetup)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['NAV_OPERATIONAL_SETUP']}
    />

    <CustomRoute
      exact
      path="/admin-controls"
      component={auth(AdminControls)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['ADMINCONTROLS']}
    />
    <CustomRoute
      exact
      path="/admin-controls/organisation-list"
      component={auth(OrganisationList)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['ORGANISATION_TAB']}
    />
    <CustomRoute
      exact
      path="/admin-controls/organisation"
      component={auth(Organisation)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['ORGANISATIONINSERT']}
    />
    <CustomRoute
      exact
      path="/admin-controls/organisation/:OrganisationId/:ParentOrganisationId"
      component={auth(Organisation)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['ORGANISATIONUPDATE']}
    />
    <CustomRoute
      exact
      path="/account-settings/settings"
      component={auth(GeneralSettings)}
      pageAccessCode={'NAV_SETTINGS'}
      accessCodes={['NAV_SETTINGS']}
    />
    <CustomRoute
      exact
      path="/account-settings"
      component={auth(ProfileSettings)}
      pageAccessCode={'NAV_SETTINGS'}
      accessCodes={['NAV_SETTINGS']}
    />
    <CustomRoute
      exact
      path="/admin-controls/profile-list"
      component={auth(ProfileList)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['PROFILES_TAB']}
    />
    <CustomRoute
      exact
      path="/admin-controls/profile/:profileId"
      component={auth(Profile)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['PROFILEUPDATE']}
    />
    <CustomRoute
      exact
      path="/admin-controls/profile"
      component={auth(Profile)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['PROFILEINSERT']}
    />

    <CustomRoute
      exact
      path="/admin-controls/user-list"
      component={auth(UserList)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['USERS_TAB']}
    />
    <CustomRoute exact path="/admin-controls/user" component={auth(User)} pageAccessCode={'ADMINCONTROLS'} accessCodes={['USERINSERT']} />
    <CustomRoute
      exact
      path="/admin-controls/user/:userId"
      component={auth(User)}
      pageAccessCode={'ADMINCONTROLS'}
      accessCodes={['USERUPDATE']}
    />

    <Route exact path="/unauth" component={auth(UnAuth401)} />
    <Route exact path="/forgot-password" component={noAuth(ForgotPassword)} />
    <Route exact path="/multi-factor-auth" component={noAuth(MFAuthentication)} />

    <CustomRoute
      exact
      path="/operational-setup/checklist-task-templates"
      component={auth(ChecklistTaskTemplates)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TASKSCHECKLISTS_TAB']}
    />
    <CustomRoute
      exact
      path="/operational-setup/checklist-template/:checklistTemplateId"
      component={auth(ChecklistTemplate)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_CHECKLISTTEMPLATEVIEW', 'WQSP_CHECKLISTTEMPLATEEDIT', 'WQSP_CHECKLISTTEMPLATEADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/task-template"
      component={auth(TaskTemplate)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TASKTEMPLATEADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/task-template/:TaskTemplateId"
      component={auth(TaskTemplate)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TASKTEMPLATEEDIT']}
    />
    <CustomRoute
      exact
      path="/operational-setup/task-template-detail/:TaskTemplateId"
      component={auth(TaskTemplateDetail)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TASKTEMPLATEVIEW']}
    />
    <CustomRoute
      exact
      path="/tasks-and-checklists"
      component={auth(ChecklistTaskList)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['NAV_TASKS']}
    />
    <CustomRoute
      exact
      path="/tasks-and-checklists/tasklist"
      component={auth(TaskList)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['WQSP_TASKLIST']}
    />
    <CustomRoute
      exact
      path="/tasks-and-checklists/task"
      component={auth(Task)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['WQSP_TASKADDNEW']}
    />

    <CustomRoute
      exact
      path="/tasks-and-checklists/task/:TaskId"
      component={auth(Task)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['WQSP_TASKEDIT']}
    />
    <CustomRoute
      exact
      path="/tasks-and-checklists/task-detail/:TaskId"
      component={auth(TaskDetail)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['WQSP_TASKVIEW']}
    />

    <CustomRoute
      exact
      path="/tasks-and-checklists/checklists"
      component={auth(Checklists)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['WQSP_CHECKLISTLIST']}
    />
    <CustomRoute
      exact
      path="/tasks-and-checklists/checklist-detail/:checklistId"
      component={auth(ChecklistDetail)}
      pageAccessCode={'NAV_TASKS'}
      accessCodes={['WQSP_CHECKLISTADDNEW', 'WQSP_CHECKLISTEDIT', 'WQSP_CHECKLISTVIEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/test-results/test-purpose"
      component={auth(TestPurpose)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TESTPURPOSES_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/test-results/test-purpose/:testPurposeId"
      component={auth(TestPurpose)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TESTPURPOSES_EDIT']}
    />
    <CustomRoute
      exact
      path="/operational-setup/inspection-report"
      component={auth(InspectionReportAdmin)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_INSPECTIONRESULTS_TAB']}
    />
    <CustomRoute
      exact
      path="/operational-setup/inspection-report/inspection-purpose"
      component={auth(InspectionPurpose)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_INSPECTIONPURPOSE_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/inspection-report/inspection-purpose/:inspectionPurposeId"
      component={auth(InspectionPurpose)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_INSPECTIONPURPOSE_EDIT']}
    />
    <CustomRoute
      exact
      path="/operational-setup/inspection-report/inspection-type"
      component={auth(InspectionType)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_INSPECTIONTYPE_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/inspection-report/inspection-type/:inspectionTypeId"
      component={auth(InspectionType)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_INSPECTIONPURPOSE_EDIT']}
    />
    <CustomRoute
      exact
      path="/operational-setup/inspection-report/inspection-type-question"
      component={auth(InspectionTypeQuestion)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_INSPECTIONTYPEQUESTION_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/inspection-report/inspection-type-question/:inspectionTypeQuestionId/:inspectionSurveyTypeId"
      component={auth(InspectionTypeQuestion)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_INSPECTIONTYPEQUESTION_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/inspection-report/inspection-type-question/:inspectionTypeQuestionId"
      component={auth(InspectionTypeQuestion)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_INSPECTIONTYPEQUESTION_EDIT']}
    />
    <CustomRoute
      exact
      path="/operational-setup/test-results/test-category"
      component={auth(TestCategory)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TESTCATEGORY_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/test-results/test-category/:testCategoryId"
      component={auth(TestCategory)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TESTCATEGORY_EDIT']}
    />
    <CustomRoute
      exact
      path="/operational-setup/test-results"
      component={auth(TestResultAdmin)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TESTRESULTS_TAB']}
    />
    <CustomRoute
      exact
      path="/inspections"
      component={auth(InspectionReportList)}
      pageAccessCode={'NAV_INSPECTIONS'}
      accessCodes={['WQSP_INSPECTIONREPORT_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/inspections"
      component={auth(InspectionReportList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_INSPECTIONREPORT_LIST']}
    />
    <CustomRoute
      exact
      path="/inspections/inspectionReport"
      component={auth(InspectionReport)}
      pageAccessCode={'NAV_INSPECTIONS'}
      accessCodes={['WQSP_INSPECTIONREPORT_ADD']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/inspections/inspectionReport"
      component={auth(InspectionReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_INSPECTIONREPORT_ADD']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/inspections/inspectionReport/:inspectionReportId"
      component={auth(InspectionReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_INSPECTIONREPORT_UPDATE']}
    />

    <CustomRoute
      exact
      path="/test-results/test-result-report"
      component={auth(TestResultReport)}
      pageAccessCode={'NAV_TEST_RESULTS'}
      accessCodes={['WQSP_TESTRESULTSREPORT_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/test-results/test-result-report"
      component={auth(TestResultReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_TESTRESULTSREPORT_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/test-results/test-result-report/:testResultReportId"
      component={auth(TestResultReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_TESTRESULTSREPORT_EDIT']}
    />

    <CustomRoute
      exact
      path="/test-results"
      component={auth(TestResultReportList)}
      pageAccessCode={'NAV_TEST_RESULTS'}
      accessCodes={['WQSP_TESTRESULTSREPORT_LIST']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/test-results"
      component={auth(TestResultReportList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_TESTRESULTSREPORT_LIST']}
    />

    <CustomRoute exact path="/sites" component={auth(SiteList)} pageAccessCode={'NAV_SITES'} accessCodes={['WQSP_SITES_LIST']} />

    <CustomRoute exact path="/sites/site" component={auth(Site)} pageAccessCode={'NAV_SITES'} accessCodes={['WQSP_SITES_ADD']} />

    <CustomRoute
      exact
      path="/sites/site/:siteId/:segmentName/:selectedTab"
      component={auth(Site)}
      pageAccessCode={'NAV_SITES'}
      accessCodes={['WQSP_SITES_UPDATE']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/quality-goals/quality-goals-report"
      component={auth(QualityGoalsReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_QUALITYGOALSREPORT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/quality-goals/quality-goals-report/:qualityGoalReportId"
      component={auth(QualityGoalsReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_QUALITYGOALSREPORT_EDIT']}
    />
    <CustomRoute
      exact
      path="/quality-goals"
      component={auth(QualityGoalsReportList)}
      pageAccessCode={'NAV_QUALITY_GOALS'}
      accessCodes={['WQSP_QUALITYGOALSREPORT_LIST']}
    />
    <CustomRoute
      exact
      path="/quality-goals/quality-goals-report"
      component={auth(QualityGoalsReport)}
      pageAccessCode={'NAV_QUALITY_GOALS'}
      accessCodes={['WQSP_QUALITYGOALSREPORT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/quality-goals"
      component={auth(QualityGoalsReportList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_QUALITYGOALSREPORT_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/quality-goals/quality-goals-report"
      component={auth(QualityGoalsReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_QUALITYGOALSREPORT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities"
      component={auth(EntityList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_ENTITIES_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/quality-goals"
      component={auth(QualityGoalsReportList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_QUALITYGOALSREPORT_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/quality-goals/quality-goals-report"
      component={auth(QualityGoalsReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_QUALITYGOALSREPORT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/quality-goals/quality-goals-report/:qualityGoalReportId"
      component={auth(QualityGoalsReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_QUALITYGOALSREPORT_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity"
      component={auth(Entity)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_ENTITIES_ADD']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId"
      component={auth(Entity)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_ENTITIES_UPDATE']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events"
      component={auth(HazardEventList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_HAZARDEVENT_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId"
      component={auth(HazardEventView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_HAZARDEVENT_VIEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event"
      component={auth(HazardEvent)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_HAZARDS_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event/:hazardEventId"
      component={auth(HazardEvent)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_HAZARDEVENT_UPDATE']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/risk-assessments"
      component={auth(RiskAssessmentList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/risk-assessments/risk-assessment"
      component={auth(RiskAssessment)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/risk-assessments/risk-assessment-view/:riskAssessmentId"
      component={auth(RiskAssessmentView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_VIEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/risk-assessments/risk-assessment/:riskAssessmentId"
      component={auth(RiskAssessment)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/improvement-plans"
      component={auth(ImprovementPlanList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/improvement-plans/improvement-plan"
      component={auth(ImprovementPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/improvement-plans/improvement-plan-view/:improvementPlanId"
      component={auth(ImprovementPlanView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_VIEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/improvement-plans/improvement-plan/:improvementPlanId"
      component={auth(ImprovementPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_EDIT']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/improvement-plans"
      component={auth(ImprovementPlanList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/improvement-plans/improvement-plan"
      component={auth(ImprovementPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/improvement-plans/improvement-plan-view/:improvementPlanId"
      component={auth(ImprovementPlanView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_VIEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/improvement-plans/improvement-plan/:improvementPlanId"
      component={auth(ImprovementPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/control-measures"
      component={auth(ControlMeasureList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/control-measures"
      component={auth(ControlMeasureList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_LIST']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId"
      component={auth(ControlMeasure)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_EDIT']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId"
      component={auth(ControlMeasure)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_EDIT']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure"
      component={auth(ControlMeasure)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure"
      component={auth(ControlMeasure)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure-view/:controlMeasureId"
      component={auth(ControlMeasureView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure-view/:controlMeasureId"
      component={auth(ControlMeasureView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId/control-monitoring"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId/control-monitoring/:controlMonitoringId"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId/control-monitoring-view/:controlMonitoringId"
      component={auth(ControlMonitoringView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/entities/entity/:entityId/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId/control-monitoring-view/:controlMonitoringId/correction-action-view/:correctiveActionId"
      component={auth(CorrectiveActionView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CORRECTIVE_ACTIONS_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId/control-monitoring"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId/control-monitoring/:controlMonitoringId"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId/control-monitoring-view/:controlMonitoringId"
      component={auth(ControlMonitoringView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/control-measures/control-measure/:controlMeasureId/control-monitoring-view/:controlMonitoringId/correction-action-view/:correctiveActionId"
      component={auth(CorrectiveActionView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CORRECTIVE_ACTIONS_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/water-supply-descriptions"
      component={auth(WaterSupplyDescriptionList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_WATERSUPPLYDESCRIPTIONS_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/water-supply-descriptions/water-supply-description"
      component={auth(WaterSupplyDescription)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_WATERSUPPLYDESCRIPTIONS_ADD']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/water-supply-descriptions/water-supply-description/:waterSupplyDescriptionId"
      component={auth(WaterSupplyDescription)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_WATERSUPPLYDESCRIPTIONS_UPDATE']}
    />
    <CustomRoute
      exact
      path="/operational-setup/general-field-types"
      component={auth(GeneralFieldTypeList)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_GENERAL_FIELDTYPES_LIST']}
    />
    <CustomRoute
      exact
      path="/operational-setup/general-field-types/fieldtype"
      component={auth(GeneralFieldType)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_GENERAL_FIELDTYPES_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/general-field-types/fieldtype/:generalFieldTypeId"
      component={auth(GeneralFieldType)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_GENERAL_FIELDTYPES_EDIT']}
    />
    <CustomRoute
      exact
      path="/operational-setup/risk-categories"
      component={auth(RiskCategoryList)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_RISKCATEGORY_LIST']}
    />
    <CustomRoute
      exact
      path="/operational-setup/risk-categories/risk-category"
      component={auth(RiskCategory)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_RISKCATEGORY_ADD']}
    />
    <CustomRoute
      exact
      path="/operational-setup/risk-categories/risk-category/:riskCategoryId"
      component={auth(RiskCategory)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_RISKCATEGORY_UPDATE']}
    />

    <CustomRoute
      exact
      path="/hazard-events"
      component={auth(HazardEventList)}
      pageAccessCode={'NAV_HAZARD_EVENTS'}
      accessCodes={['WQSP_HAZARDEVENT_LIST']}
    />
    <CustomRoute
      exact
      path="/hazard-events/hazard-event"
      component={auth(HazardEvent)}
      pageAccessCode={'NAV_HAZARD_EVENTS'}
      accessCodes={['WQSP_HAZARDEVENT_ADD']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events"
      component={auth(HazardEventList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_HAZARDEVENT_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event"
      component={auth(HazardEvent)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_HAZARDEVENT_ADD']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId"
      component={auth(HazardEventView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_HAZARDEVENT_VIEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event/:hazardEventId"
      component={auth(HazardEvent)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_HAZARDEVENT_UPDATE']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/risk-assessments"
      component={auth(RiskAssessmentList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/risk-assessments/risk-assessment"
      component={auth(RiskAssessment)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/risk-assessments/risk-assessment-view/:riskAssessmentId"
      component={auth(RiskAssessmentView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_VIEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/hazard-events/hazard-event-view/:hazardEventId/risk-assessments/risk-assessment/:riskAssessmentId"
      component={auth(RiskAssessment)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_EDIT']}
    />

    <CustomRoute
      exact
      path="/operational-setup/hazards"
      component={auth(HazardsTab)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_HAZARDS_TAB']}
    />

    <CustomRoute
      exact
      path="/operational-setup/hazard-event-template"
      component={auth(HazardEventLibrary)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_HAZARDEVENTTEMPLATE_ITEM_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/hazard-event-template/:hazardEventLibraryId"
      component={auth(HazardEventLibrary)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_HAZARDEVENTTEMPLATE_EDIT']}
    />
    <CustomRoute
      exact
      path="/operational-setup/hazard-event-template-details/:hazardEventLibraryId"
      component={auth(HazardEventLibraryDetails)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_HAZARDEVENTTEMPLATE_VIEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/hazards/hazard"
      component={auth(Hazard)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_HAZARDS_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/hazards/hazard/:hazardId"
      component={auth(Hazard)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_HAZARDS_EDIT']}
    />

    <CustomRoute
      exact
      path="/risk-assessments"
      component={auth(RiskAssessmentList)}
      pageAccessCode={'NAV_RISK_ASSESSMENT'}
      accessCodes={['WQSP_RISKASSESSMENT_LIST']}
    />
    <CustomRoute
      exact
      path="/risk-assessments/risk-assessment"
      component={auth(RiskAssessment)}
      pageAccessCode={'NAV_RISK_ASSESSMENT'}
      accessCodes={['WQSP_RISKASSESSMENT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/risk-assessments"
      component={auth(RiskAssessmentList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/risk-assessments/risk-assessment"
      component={auth(RiskAssessment)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/risk-assessments/risk-assessment-view/:riskAssessmentId"
      component={auth(RiskAssessmentView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_VIEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/risk-assessments/risk-assessment/:riskAssessmentId"
      component={auth(RiskAssessment)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_RISKASSESSMENT_EDIT']}
    />

    <CustomRoute
      exact
      path="/safety-plans"
      component={auth(SafetyPlanList)}
      pageAccessCode={'NAV_SAFETY_PLAN'}
      accessCodes={['WQSP_SAFETY_PLAN_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans"
      component={auth(SafetyPlanList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_SAFETY_PLAN_LIST']}
    />
    <CustomRoute
      exact
      path="/safety-plans/safety-plan"
      component={auth(SafetyPlan)}
      pageAccessCode={'NAV_SAFETY_PLAN'}
      accessCodes={['WQSP_SAFETY_PLAN_ADD']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/safety-plan"
      component={auth(SafetyPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_SAFETY_PLAN_ADD']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/safety-plan/:safetyPlanId"
      component={auth(SafetyPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_SAFETY_PLAN_UPDATE']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/emergency-response-plans"
      component={auth(EmergencyPlanList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_ERP_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/emergency-response-plans/emergency-response-plan"
      component={auth(EmergencyPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_ERP_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/emergency-response-plans/emergency-response-plan/:emergencyResponsePlanId"
      component={auth(EmergencyPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_ERP_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/emergency-response-plans/emergency-response-plan-view/:emergencyResponsePlanId"
      component={auth(EmergencyPlanView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_ERP_VIEW']}
    />

    <CustomRoute
      exact
      path="/operational-setup/personnels"
      component={auth(PersonnelList)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_PERSONNEL_LIST']}
    />
    <CustomRoute
      exact
      path="/operational-setup/personnels/personnel"
      component={auth(Personnel)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_PERSONNEL_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/personnels/personnel/:personnelId"
      component={auth(Personnel)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_PERSONNEL_EDIT']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/regulatory-registers"
      component={auth(RegulatoryRegisterList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_REGULATORY_REGISTER_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/regulatory-registers/regulatory-register"
      component={auth(RegulatoryRegister)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_REGULATORY_REGISTER_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/regulatory-registers/regulatory-register/:regulatoryRegisterId"
      component={auth(RegulatoryRegister)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_REGULATORY_REGISTER_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/teams"
      component={auth(TeamList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_TEAM_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/teams/team"
      component={auth(Team)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_TEAM_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/teams/team/:teamId"
      component={auth(Team)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_TEAM_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/stakeholders"
      component={auth(StakeholderRegisterList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_STAKEHOLDER_REGISTER_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/safety-plan-report"
      component={auth(SafetyPlanReportList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_SAFETYPLANREPORT_VIEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/stakeholders/stakeholder"
      component={auth(StakeholderRegister)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_STAKEHOLDER_REGISTER_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/safety-plans/:safetyPlanId/stakeholders/stakeholder/:stakeholderRegisterId"
      component={auth(StakeholderRegister)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_STAKEHOLDER_REGISTER_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/control-measures"
      component={auth(ControlMeasureList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_LIST']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/control-measures/control-measure"
      component={auth(ControlMeasure)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/control-measures/control-measure-view/:controlMeasureId"
      component={auth(ControlMeasureView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_VIEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/control-measures/control-measure/:controlMeasureId"
      component={auth(ControlMeasure)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-monitorings"
      component={auth(ControlMonitoringList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_LIST']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-monitorings/control-monitoring"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-monitorings/control-monitoring/:controlMonitoringId"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-monitorings/control-monitoring-view/:controlMonitoringId"
      component={auth(ControlMonitoringView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-monitorings/control-monitoring/:controlMonitoringId/correction-action-view/:correctiveActionId"
      component={auth(CorrectiveActionView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CORRECTIVE_ACTIONS_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure/:controlMeasureId/control-monitoring"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure/:controlMeasureId/control-monitoring/:controlMonitoringId"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure/:controlMeasureId/control-monitoring-view/:controlMonitoringId"
      component={auth(ControlMonitoringView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure/:controlMeasureId/control-monitoring-view/:controlMonitoringId/correction-action-view/:correctiveActionId"
      component={auth(CorrectiveActionView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CORRECTIVE_ACTIONS_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures"
      component={auth(ControlMeasureList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_LIST']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure"
      component={auth(ControlMeasure)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure/:controlMeasureId"
      component={auth(ControlMeasure)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure-view/:controlMeasureId"
      component={auth(ControlMeasureView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MEASURES_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure/:controlMeasureId/control-monitoring"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure/:controlMeasureId/control-monitoring/:controlMonitoringId"
      component={auth(ControlMonitoring)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/control-measures/control-measure/:controlMeasureId/control-monitoring-view/:controlMonitoringId"
      component={auth(ControlMonitoringView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CONTROL_MONITORINGS_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/improvement-plans"
      component={auth(ImprovementPlanList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_LIST']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/improvement-plans/improvement-plan"
      component={auth(ImprovementPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/improvement-plans/improvement-plan-view/:improvementPlanId"
      component={auth(ImprovementPlanView)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_VIEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/improvement-plans/improvement-plan/:improvementPlanId"
      component={auth(ImprovementPlan)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_IMPROVEMENT_PLAN_VIEW']}
    />

    <CustomRoute
      exact
      path="/dashboard/:siteId/site-segments"
      component={auth(SiteSegments)}
      pageAccessCode={'NAV_HOME'}
      accessCodes={['WQSP_SITE_PATHWAY', 'WQSP_SITE_ELEMENTS_MAP_VIEW']}
    />

    <CustomRoute
      exact
      path="/dashboard/:siteId/site-segments/:segmentName"
      component={auth(SiteSegments)}
      pageAccessCode={'NAV_HOME'}
      accessCodes={['WQSP_SITE_PATHWAY', 'WQSP_SITE_ELEMENTS_MAP_VIEW']}
    />

    <CustomRoute
      exact
      path="/operational-setup/organisation-translations"
      component={auth(OrganisationTranslationsList)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TRANSLATION_TAB']}
    />

    <CustomRoute
      exact
      path="/operational-setup/organisation-translations/translation"
      component={auth(OrganisationTranslations)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TRANSLATIONS_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/operational-setup/organisation-translations/translation/:organisationTranslationId"
      component={auth(OrganisationTranslations)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_TRANSLATIONS_EDIT']}
    />

    <CustomRoute
      exact
      path="/incident-reports"
      component={auth(IncidentReportList)}
      pageAccessCode={'NAV_INCIDENT_REPORTS'}
      accessCodes={['WQSP_INCIDENT_REPORT_LIST']}
    />

    <CustomRoute
      exact
      path="/incident-reports/incident-report"
      component={auth(IncidentReport)}
      pageAccessCode={'NAV_INCIDENT_REPORTS'}
      accessCodes={['WQSP_INCIDENT_REPORT_ADDNEW']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/incident-reports"
      component={auth(IncidentReportList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_INCIDENT_REPORT_LIST']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/incident-reports/incident-report"
      component={auth(IncidentReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_INCIDENT_REPORT_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/incident-reports/incident-report/:incidentReportId"
      component={auth(IncidentReport)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_INCIDENT_REPORT_EDIT']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/corrective-actions"
      component={auth(CorrectiveActionList)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CORRECTIVE_ACTIONS_LIST']}
    />

    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/corrective-actions/corrective-action"
      component={auth(CorrectiveAction)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CORRECTIVE_ACTIONS_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/corrective-actions/corrective-action/:correctiveActionId"
      component={auth(CorrectiveAction)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CORRECTIVE_ACTIONS_EDIT']}
    />
    <CustomRoute
      exact
      path="/site/:siteId/:segmentName/:selectedTab/corrective-actions/corrective-action-view/:correctiveActionId"
      component={auth(CorrectiveActionViewSegment)}
      pageAccessCode={'WQSP_SITE_PATHWAY'}
      accessCodes={['WQSP_CORRECTIVE_ACTIONS_VIEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/entity-sub-types"
      component={auth(EntitySubTypeList)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_ENTITY_SUB_TYPES_LIST']}
    />
    <CustomRoute
      exact
      path="/operational-setup/entity-sub-types/entity-sub-type"
      component={auth(EntitySubType)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_ENTITY_SUB_TYPES_ADDNEW']}
    />
    <CustomRoute
      exact
      path="/operational-setup/entity-sub-types/entity-sub-type/:entitySubTypeId"
      component={auth(EntitySubType)}
      pageAccessCode={'NAV_OPERATIONAL_SETUP'}
      accessCodes={['WQSP_ENTITY_SUB_TYPES_EDIT']}
    />

    <Route component={noAuth(NoRoute)} />
  </Switch>
);

export default routes;
