import { GET_ALL_USER_PROFILE_UNITS } from '../../constants/index';

const userProfileUnits = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_USER_PROFILE_UNITS: {
      const { profile } = payload;
      return {
        ...state,
        userProfileUnits: profile.profile.units
      };
    }

    default:
      return state;
  }
};

export default userProfileUnits;
