import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './RegulatoryRegister.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialRegulatoryRegisterState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import uuid from 'uuid';
import {
  getUserProfileWidget,
  getSelectedSafetyPlan,
  getGeneralData,
  getRegulatoryRegister,
  getStakeholderRegister,
  getCurrentTimezone
} from '../../../selectors/index';
import {
  describeRegulatoryRegisterThunk,
  saveRegulatoryRegisterThunk,
  setRegulatoryRegisterChangeThunk
} from '../../../store/actions/safetyPlan/regulatoryRegister';
import { getStakeholderRegisterThunk } from '../../../store/actions/safetyPlan/stakeholderRegister';
import DateTimePicker from '../../WSAControls/DateTimePicker/DateTimePicker';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import { FREQUENCY, GENERAL_STATUS, DOCUMENT_TYPE, DOCUMENT_SUBJECT_CATEGORY } from '../../../constants/index';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import { describeSafetyPlanThunk } from '../../../store/actions/safetyPlan/safetyPlan';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';
import Breadcrumb from '../../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const RegulatoryRegister = ({ intl }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  let { regulatoryRegisterId, safetyPlanId, siteId, segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'build';
  selectedTab = selectedTab || '2';

  let IsEdit = false;

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const regulatoryRegister = useSelector(state => getRegulatoryRegister(state));
  const stakeholderRegister = useSelector(state => getStakeholderRegister(state));
  const generalData = useSelector(state => getGeneralData(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);
  const [localformErrors, setFormErrors] = useState({});

  const disableFutureDates = current => current.isBefore(currentTimestamp);

  const documentTypes = generalData.generalFieldTypes.filter(item => item.FieldType === DOCUMENT_TYPE);
  const subjectCatgories = generalData.generalFieldTypes.filter(item => item.FieldType === DOCUMENT_SUBJECT_CATEGORY);
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);

  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/regulatory-registers`;
  let widgetCode = 'WQSP_REGULATORY_REGISTER_ADDNEW';
  if (regulatoryRegisterId) {
    IsEdit = true;
    widgetCode = 'WQSP_REGULATORY_REGISTER_EDIT';
  }

  useEffect(() => {
    dispatch(describeSafetyPlanThunk(safetyPlanId, 'WQSP_SAFETY_PLAN_UPDATE'));
  }, [safetyPlanId]);

  const selectedSafetyPlan = useSelector(state => getSelectedSafetyPlan(state));
  const safetyPlanName = (selectedSafetyPlan.Name && ` (${selectedSafetyPlan.Name})`) || '';

  useEffect(() => {
    if (regulatoryRegisterId) {
      dispatch(describeRegulatoryRegisterThunk(regulatoryRegisterId, widgetCode));
    }
  }, [describeRegulatoryRegisterThunk, regulatoryRegisterId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setRegulatoryRegisterChangeThunk({
          ...initialRegulatoryRegisterState.regulatoryRegister
        })
      );
    };
  }, []);

  useEffect(() => {
    let filterObj = {
      filterName: '',
      filterCompany: '',
      offset: 0,
      limit: 1000
    };
    dispatch(getStakeholderRegisterThunk(widgetCode, filterObj, safetyPlanId));
  }, [getStakeholderRegisterThunk, safetyPlanId]);

  // Update redux store
  const setLocalRegulatoryRegister = currentState => {
    dispatch(setRegulatoryRegisterChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = regulatoryRegister.selectedRegulatoryRegister;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.RegulatoryRegisterId = 0;
      saveData.WaterQualitySafetyPlanId = safetyPlanId;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_REGULATORY_REGISTER_ADDNEW';
    }

    dispatch(saveRegulatoryRegisterThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !regulatoryRegister ||
      !regulatoryRegister.selectedRegulatoryRegister ||
      !regulatoryRegister.selectedRegulatoryRegister.StakeholderRegisterId ||
      isRequired(regulatoryRegister.selectedRegulatoryRegister.StakeholderRegisterId, 1)
    ) {
      formErrors.StakeholderRegisterId = intl.formatMessage({
        id: 'regulatoryRegister.stakeholderRegisterMandatory',
        defaultMessage: 'Stakeholder register is a mandatory field'
      });
      isValid = false;
    }

    if (
      !regulatoryRegister ||
      !regulatoryRegister.selectedRegulatoryRegister ||
      !regulatoryRegister.selectedRegulatoryRegister.DocumentTypeId ||
      isRequired(regulatoryRegister.selectedRegulatoryRegister.DocumentTypeId, 1)
    ) {
      formErrors.DocumentTypeId = intl.formatMessage({
        id: 'regulatoryRegister.documentTypeMandatory',
        defaultMessage: 'Document type is a mandatory field'
      });
      isValid = false;
    }

    if (
      !regulatoryRegister ||
      !regulatoryRegister.selectedRegulatoryRegister ||
      !regulatoryRegister.selectedRegulatoryRegister.SubjectCategoryId ||
      isRequired(regulatoryRegister.selectedRegulatoryRegister.SubjectCategoryId, 1)
    ) {
      formErrors.SubjectCategoryId = intl.formatMessage({
        id: 'regulatoryRegister.subjectCategoryMandatory',
        defaultMessage: 'Subject Category is a mandatory field'
      });
      isValid = false;
    }

    if (
      !regulatoryRegister ||
      !regulatoryRegister.selectedRegulatoryRegister ||
      !regulatoryRegister.selectedRegulatoryRegister.DocumentName ||
      isRequired(regulatoryRegister.selectedRegulatoryRegister.DocumentName, 1)
    ) {
      formErrors.DocumentName = intl.formatMessage({
        id: 'regulatoryRegister.documentNameRequired',
        defaultMessage: 'Document name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const stakeholderRegisterOptions = () => {
    let opData = [];

    const data = stakeholderRegister.stakeholderRegisters;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.StakeholderRegisterId
      });
    });
    return opData;
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  //on control value change
  const onChange = e => {
    setLocalRegulatoryRegister({
      ...regulatoryRegister,
      selectedRegulatoryRegister: { ...regulatoryRegister.selectedRegulatoryRegister, [e.target.name]: e.target.value }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalRegulatoryRegister({
      ...regulatoryRegister,
      selectedRegulatoryRegister: { ...regulatoryRegister.selectedRegulatoryRegister, [fieldName]: e.value }
    });
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();

    setLocalRegulatoryRegister({
      ...regulatoryRegister,
      selectedRegulatoryRegister: { ...regulatoryRegister.selectedRegulatoryRegister, [fieldName]: changeDate }
    });
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };
  const onStatusChange = e => {
    setLocalRegulatoryRegister({
      ...regulatoryRegister,
      selectedRegulatoryRegister: { ...regulatoryRegister.selectedRegulatoryRegister, [e.target.name]: e.target.value, StatusId: null }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const documentTypeOptions = () => {
    let documentTypesData = [];
    documentTypes.forEach(element => {
      documentTypesData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = documentTypesData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onDocumentTypeDropdownChange = e => {
    setLocalRegulatoryRegister({
      ...regulatoryRegister,
      selectedRegulatoryRegister: { ...regulatoryRegister.selectedRegulatoryRegister, DocumentTypeId: e.value }
    });
  };

  const subjectCategoryOptions = () => {
    let subjectCategoryData = [];
    subjectCatgories.forEach(element => {
      subjectCategoryData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = subjectCategoryData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onSubjectCategoryDropdownChange = e => {
    setLocalRegulatoryRegister({
      ...regulatoryRegister,
      selectedRegulatoryRegister: { ...regulatoryRegister.selectedRegulatoryRegister, SubjectCategoryId: e.value }
    });
  };

  let ddlClassSensor = localformErrors && localformErrors.SiteId ? s.ddlError : '';
  let ddlClassDocumentType = localformErrors && localformErrors.DocumentTypeId ? s.ddlError : '';
  let ddlClassSubjectCategory = localformErrors && localformErrors.SubjectCategoryId ? s.ddlError : '';

  let messageId = (regulatoryRegister && regulatoryRegister.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const regulatoryRegisterName =
    (regulatoryRegister.selectedRegulatoryRegister ? regulatoryRegister.selectedRegulatoryRegister.DocumentName : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + regulatoryRegisterName;
  } else {
    heading = intl.formatMessage({ id: 'regulatoryRegister.addNewRegulatoryRegister', defaultMessage: 'Add New Regulatory Register' });
  }

  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' });
  breadcrumbHeading += `${safetyPlanName}`;

  const breadcrumb = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="siteControlsTabs.regulatoryRegisters" defaultMessage="Regulatory Registers" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/regulatory-registers`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <div className={s.regulatoryRegister}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {regulatoryRegister.isLoading && <LoadingSpinner />}
        {regulatoryRegister.isRedirect ? <Redirect to={backUrl} /> : ''}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={regulatoryRegister.showBanner}
          status={regulatoryRegister.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
          <div className={s.regulatoryRegisterContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="stakeholderRegister">
                      <Form.Label>
                        <FormattedMessage id="regulatoryRegister.stakeholderRegister" defaultMessage="Stakeholder Register" />
                      </Form.Label>

                      <Dropdown
                        id="formStakeholderRegister"
                        dataArray={stakeholderRegisterOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: ddlClassSensor
                        }}
                        name="StakeholderRegisterId"
                        onDropdownChange={e => onDropdownChange(e, 'StakeholderRegisterId')}
                        selectedOption={stakeholderRegisterOptions().filter(
                          option => option.value === regulatoryRegister.selectedRegulatoryRegister.StakeholderRegisterId
                        )}
                        disabled={false}
                      />
                      {localformErrors && localformErrors.StakeholderRegisterId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.StakeholderRegisterId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Label>
                      <FormattedMessage id="regulatoryRegister.documentType" defaultMessage="Document Type" />
                    </Form.Label>
                    <Dropdown
                      id="formDdlDocumentType"
                      dataArray={documentTypeOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: ddlClassDocumentType }}
                      onDropdownChange={onDocumentTypeDropdownChange}
                      data-unittest="formDdlDocumentType"
                      selectedOption={documentTypeOptions().filter(
                        option => option.value === regulatoryRegister.selectedRegulatoryRegister.DocumentTypeId
                      )}
                    />
                    {localformErrors && localformErrors.DocumentTypeId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.DocumentTypeId}
                      </p>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg={3}>
                    <Form.Label>
                      <FormattedMessage id="regulatoryRegister.subjectCategory" defaultMessage="Subject Category" />
                    </Form.Label>
                    <Dropdown
                      id="formDdlSubjectCategory"
                      dataArray={subjectCategoryOptions()}
                      controlData={{ placeholderText: ddlInitText, customClassName: ddlClassSubjectCategory }}
                      onDropdownChange={onSubjectCategoryDropdownChange}
                      data-unittest="formDdlSubjectCategory"
                      selectedOption={subjectCategoryOptions().filter(
                        option => option.value === regulatoryRegister.selectedRegulatoryRegister.SubjectCategoryId
                      )}
                    />
                    {localformErrors && localformErrors.SubjectCategoryId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.SubjectCategoryId}
                      </p>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formRegulatoryRegisterName">
                      <Form.Label>
                        <FormattedMessage id="regulatoryRegister.documentName" defaultMessage="Document Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="DocumentName"
                        onChange={onChange}
                        value={regulatoryRegister.selectedRegulatoryRegister.DocumentName}
                        className={`${s.formControl} ${localformErrors && localformErrors.DocumentName ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'regulatoryRegister.documentName',
                          defaultMessage: 'Document Name'
                        })}
                      />
                      {localformErrors && localformErrors.DocumentName && (
                        <p role="alert" className={s.error}>
                          {localformErrors.DocumentName}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formRegulatoryRegisterJurisdiction">
                      <Form.Label>
                        <FormattedMessage id="regulatoryRegister.jurisdiction" defaultMessage="Jurisdiction" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Jurisdiction"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'regulatoryRegister.jurisdiction',
                          defaultMessage: 'Jurisdiction'
                        })}
                        value={regulatoryRegister.selectedRegulatoryRegister.Jurisdiction}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formRegulatoryLocation">
                      <Form.Label>
                        <FormattedMessage id="regulatoryRegister.location" defaultMessage="Location" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Location"
                        onChange={onChange}
                        value={regulatoryRegister.selectedRegulatoryRegister.Location}
                        className={`${s.formControl} ${localformErrors && localformErrors.Location ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'regulatoryRegister.location',
                          defaultMessage: 'Location'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formYearApplicable">
                      <Form.Label>
                        <FormattedMessage id="regulatoryRegister.yearApplicable" defaultMessage="Year Applicable" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.YearApplicable ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'YearApplicable')}
                        timeFormat={null}
                        dateFormat="YYYY"
                        value={getFormattedate(regulatoryRegister.selectedRegulatoryRegister.YearApplicable)}
                        defaultValue={getFormattedate(regulatoryRegister.selectedRegulatoryRegister.YearApplicable)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.YearApplicable && (
                        <p role="alert" className={s.error}>
                          {localformErrors.YearApplicable}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formLastReviewDate">
                      <Form.Label>
                        <FormattedMessage id="regulatoryRegister.lastReviewDate" defaultMessage="Last Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        isValidDate={disableFutureDates}
                        className={`${localformErrors.LastReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'LastReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(regulatoryRegister.selectedRegulatoryRegister.LastReviewDate)}
                        defaultValue={getFormattedate(regulatoryRegister.selectedRegulatoryRegister.LastReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.LastReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.LastReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formNextReviewDate">
                      <Form.Label>
                        <FormattedMessage id="regulatoryRegister.nextReviewDate" defaultMessage="Next Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'NextReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(regulatoryRegister.selectedRegulatoryRegister.NextReviewDate)}
                        defaultValue={getFormattedate(regulatoryRegister.selectedRegulatoryRegister.NextReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.NextReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.NextReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formGroupReviewFrequency">
                      <Form.Label>
                        <FormattedMessage id="regulatoryRegister.reviewFrequency" defaultMessage="Review Frequency" />
                      </Form.Label>

                      <Dropdown
                        id="formMaintenanceFrequency"
                        dataArray={frequencyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="ReviewFrequencyId"
                        onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                        selectedOption={frequencyOptions().filter(
                          option => option.value === regulatoryRegister.selectedRegulatoryRegister.ReviewFrequencyId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formActioned">
                      <Form.Label>
                        <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                      </Form.Label>

                      <ToggleSwitch
                        handleClick={() => {
                          onStatusChange({
                            target: { name: 'IsActioned', value: !regulatoryRegister.selectedRegulatoryRegister.IsActioned }
                          });
                        }}
                        classname={s.switch}
                        checked={regulatoryRegister.selectedRegulatoryRegister.IsActioned}
                        labelChecked={yesText}
                        labelUnchecked={noText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!regulatoryRegister.selectedRegulatoryRegister.IsActioned && (
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formStatus">
                        <Form.Label>
                          <FormattedMessage id="common.status" defaultMessage="Status" />
                        </Form.Label>

                        <Dropdown
                          id="formStatus"
                          dataArray={statusOptions()}
                          controlData={{ placeholderText: ddlInitText }}
                          onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                          data-unittest="formDdlRiskStatus"
                          selectedOption={statusOptions().filter(
                            option => option.value === regulatoryRegister.selectedRegulatoryRegister.StatusId
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row className={s.btnRow}>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(RegulatoryRegister);
