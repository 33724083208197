import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';
import _ from 'lodash';

import {
  EMERGENCY_RESPONSE_PLAN_ERROR,
  GET_ALL_EMERGENCY_RESPONSE_PLAN_DETAILS,
  EMERGENCY_RESPONSE_PLAN_IS_LOADING,
  SET_EMERGENCY_RESPONSE_PLAN_CURRENT_PAGE,
  SET_EMERGENCY_RESPONSE_PLAN_PAGE_FILTER,
  SET_EMERGENCY_RESPONSE_PLAN_CHANGE,
  SET_EMERGENCY_RESPONSE_PLAN,
  UPDATE_EMERGENCY_RESPONSE_PLAN_SAVE_STATUS,
  SET_EMERGENCY_RESPONSE_PLAN_FILTERS,
  SET_EMERGENCY_RESPONSE_PLAN_RESULTS,
  SET_EMERGENCY_RESPONSE_PLAN_COMMENTS,
  REMOVE_EMERGENCY_RESPONSE_PLAN
} from '../../../constants/index';

import {
  EMERGENCY_RESPONSE_PLAN_MANAGEMENT,
  CREATE_EMERGENCY_RESPONSE_PLAN,
  UPDATE_EMERGENCY_RESPONSE_PLAN
} from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setEmergencyResponsePlanErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setEmergencyResponsePlanError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEmergencyResponsePlanError(messageCode, false));
  }, 2500);
};

export const setEmergencyResponsePlanError = (messageCode, status) => {
  return {
    type: EMERGENCY_RESPONSE_PLAN_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: EMERGENCY_RESPONSE_PLAN_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getEmergencyResponsePlanThunk = (widgetCode, filterObj, siteId) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getEmergencyResponsePlan(widgetCode, orgId, filterObj, siteId));
};

export const getEmergencyResponsePlan = (widgetCode, orgId, filterObj, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp/emergencyresponseplan`,
    method: 'GET',
    data: {
      SiteId: siteId,
      OrganisationId: orgId,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      filter: filterObj.filter,
      filterSiteId: filterObj.filterSiteId,
      filterWaterSupplyDescriptionId: filterObj.filterWaterSupplyDescriptionId,
      filterEntityTypeId: filterObj.filterEntityTypeId,
      filterEntityId: filterObj.filterEntityId,
      filterHazardCertaintyId: filterObj.filterHazardCertaintyId,
      filterHazardClassId: filterObj.filterHazardClassId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllEmergencyResponsePlanListThunk(retData),
    onFailure: err => setEmergencyResponsePlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllEmergencyResponsePlanListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let emergencyResponsePlans = (retData && retData.retData.data) || [];
  dispatch(setAllEmergencyResponsePlanList(messageCode, emergencyResponsePlans));
};

export const setAllEmergencyResponsePlanList = (messageCode, emergencyResponsePlans) => ({
  type: GET_ALL_EMERGENCY_RESPONSE_PLAN_DETAILS,
  payload: {
    data: {
      emergencyResponsePlans: emergencyResponsePlans,
      filteredResults: emergencyResponsePlans,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_EMERGENCY_RESPONSE_PLAN_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_EMERGENCY_RESPONSE_PLAN_PAGE_FILTER,
  payload: { filter }
});

export const describeEmergencyResponsePlanThunk = (EmergencyResponsePlanId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeEmergencyResponsePlan(EmergencyResponsePlanId, widgetCode, orgId));
};

export const describeEmergencyResponsePlan = (EmergencyResponsePlanId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/waterqualitysafetyplans/wqsp/emergencyresponseplan`,
    data: {
      EmergencyResponsePlanId: EmergencyResponsePlanId,
      OrganisationId: orgId,
      isView: widgetCode === 'WQSP_ERP_VIEW'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setEmergencyResponsePlanThunk(retData, EmergencyResponsePlanId),
    onFailure: err => setEmergencyResponsePlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setEmergencyResponsePlanThunk = ({ retData }, EmergencyResponsePlanId) => dispatch => {
  let selectedEmergencyResponsePlan = retData.data;
  if (
    EmergencyResponsePlanId &&
    (!selectedEmergencyResponsePlan ||
      selectedEmergencyResponsePlan.length === 0 ||
      parseInt(EmergencyResponsePlanId) !== selectedEmergencyResponsePlan.EmergencyResponsePlanId)
  ) {
    redirectToPageNotFound(dispatch);
  }
  let files = retData.files || [];
  let checklists = retData.checklists || [];
  let mapData = {
    selectedEmergencyResponsePlan: {
      ...selectedEmergencyResponsePlan,
      files: files,
      checklists: checklists
    }
  };

  dispatch(setEmergencyResponsePlan(mapData));
};

export const setEmergencyResponsePlan = data => ({
  type: SET_EMERGENCY_RESPONSE_PLAN,
  payload: data
});

export const saveEmergencyResponsePlanThunk = (emergencyResponsePlan, widgetCode) => dispatch => {
  let url = '/waterqualitysafetyplans/wqsp/emergencyresponseplan/save';
  let actionName = emergencyResponsePlan.EmergencyResponsePlanId === 0 ? CREATE_EMERGENCY_RESPONSE_PLAN : UPDATE_EMERGENCY_RESPONSE_PLAN;
  let log = logEntry(EMERGENCY_RESPONSE_PLAN_MANAGEMENT, actionName, emergencyResponsePlan);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  emergencyResponsePlan.OrganisationId = orgId;
  dispatch(saveEmergencyResponsePlan(emergencyResponsePlan, url, log, widgetCode));
};

export const saveEmergencyResponsePlan = (emergencyResponsePlan, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setEmergencyResponsePlanStatusThunk(retData, emergencyResponsePlan),
    onFailure: err => setEmergencyResponsePlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: emergencyResponsePlan,
      log: log
    },
    widgetcode: widgetCode
  });

export const setEmergencyResponsePlanStatusThunk = (data, emergencyResponsePlan) => dispatch => {
  let message = data;
  if (emergencyResponsePlan.performCommentInsert) {
    dispatch(setEmergencyResponsePlanComments(data.retData));
  } else {
    dispatch(setEmergencyResponsePlanStatus(message, true));
    setTimeout(() => {
      //Hide the banner
      let isRedirect = !emergencyResponsePlan.PerformDelete;
      dispatch(setEmergencyResponsePlanStatus(message, false, isRedirect));
      if (emergencyResponsePlan.PerformDelete) {
        dispatch(removeEmergencyResponsePlan(emergencyResponsePlan.EmergencyResponsePlanId));
      }
    }, 2500);
  }
};

export const setEmergencyResponsePlanStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_EMERGENCY_RESPONSE_PLAN_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setEmergencyResponsePlanComments = data => ({
  type: SET_EMERGENCY_RESPONSE_PLAN_COMMENTS,
  payload: {
    selectedEmergencyResponsePlan: {
      comments: data
    }
  }
});

export const removeEmergencyResponsePlan = data => ({
  type: REMOVE_EMERGENCY_RESPONSE_PLAN,
  payload: {
    emergencyResponsePlanId: data
  }
});

export const setEmergencyResponsePlanChangeThunk = retData => dispatch => {
  dispatch(setEmergencyResponsePlanChange(retData));
};

export const setEmergencyResponsePlanChange = retData => ({
  type: SET_EMERGENCY_RESPONSE_PLAN_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      SiteId: siteId,
      filterFor: 'emergencyResponsePlanListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setEmergencyResponsePlanErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_EMERGENCY_RESPONSE_PLAN_FILTERS,
  payload: {
    data: {
      isLoading: false,
      filterData: {
        TestPurposes: filterData.testPurposes
      },
      emergencyResponsePlanListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_EMERGENCY_RESPONSE_PLAN_RESULTS,
  payload: retData
});
