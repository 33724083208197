import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import bs from '../../styles/bootstrap-overrides.scss';
import s from './Dashboard.module.scss';
import { FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import SitesList from './SitesList/SitesList';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { getUserProfileWidget, getSite } from '../../selectors/index';
import { getSiteThunk } from '../../store/actions/sites';
import DashboardTasks from './DashboardTasks/DashboardTasks';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const Dashboard = () => {
  const name = Cookies.get(`name-${envName}`);
  let history = useHistory();
  const dispatch = useDispatch();

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const site = useSelector(state => getSite(state));

  useEffect(() => {
    if (IsWidgetAccessible(userProfileWidgets, 'WQSP_MYSITESINDASHBOARD')) {
      dispatch(getSiteThunk('WQSP_MYSITESINDASHBOARD', {}));
    }
  }, [getSiteThunk]);

  return (
    <div className={s.sites}>
      <h1>
        <FormattedMessage id="dashboard.hello" defaultMessage="Hello" />, {name}
      </h1>

      <div style={WidgetVisibility(userProfileWidgets, 'WQSP_MYSITESINDASHBOARD')}>
        <SitesList site={site} userProfileWidgets={userProfileWidgets} />
      </div>
      <div style={WidgetVisibility(userProfileWidgets, 'WQSP_MYTASKS_IN_DASHBOARD')}>
        <DashboardTasks userProfileWidgets={userProfileWidgets} />
      </div>
    </div>
  );
};

export default Dashboard;
