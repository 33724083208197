import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';
import _ from 'lodash';

import {
  INCIDENT_REPORTS_ERROR,
  GET_ALL_INCIDENT_REPORTS_DETAILS,
  INCIDENT_REPORTS_IS_LOADING,
  SET_INCIDENT_REPORTS_CURRENT_PAGE,
  SET_INCIDENT_REPORTS_PAGE_FILTER,
  SET_INCIDENT_REPORTS_CHANGE,
  SET_INCIDENT_REPORTS,
  UPDATE_INCIDENT_REPORTS_SAVE_STATUS,
  SET_INCIDENT_REPORTS_FILTERS,
  SET_INCIDENT_REPORTS_RESULTS
} from '../../constants/index';

import { INCIDENT_REPORTS_MANAGEMENT, CREATE_INCIDENT_REPORTS, UPDATE_INCIDENT_REPORTS } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setIncidentReportsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setIncidentReportsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setIncidentReportsError(messageCode, false));
  }, 2500);
};

export const setIncidentReportsError = (messageCode, status) => {
  return {
    type: INCIDENT_REPORTS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: INCIDENT_REPORTS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getIncidentReportsThunk = (filterObj, widgetCode) => dispatch => {
  dispatch(getIncidentReports(filterObj, widgetCode));
};

export const getIncidentReports = (filterObj, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/incidents/incidentreport`,
    method: 'GET',
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      FilterSiteId: filterObj.filterSiteId,
      FilterEntityId: filterObj.filterEntityId,
      FilterEntityTypeId: filterObj.filterEntityTypeId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllIncidentReportsListThunk(retData),
    onFailure: err => setIncidentReportsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllIncidentReportsListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let incidentReports = (retData && retData.retData.data) || [];
  dispatch(setAllIncidentReportsList(messageCode, incidentReports));
};

export const setAllIncidentReportsList = (messageCode, incidentReports) => ({
  type: GET_ALL_INCIDENT_REPORTS_DETAILS,
  payload: {
    data: {
      incidentReports: incidentReports,
      filteredResults: incidentReports,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_INCIDENT_REPORTS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_INCIDENT_REPORTS_PAGE_FILTER,
  payload: { filter }
});

export const describeIncidentReportsThunk = (incidentReportId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeIncidentReports(incidentReportId, widgetCode, orgId));
};

export const describeIncidentReports = (incidentReportId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/incidents/incidentreport`,
    data: {
      IncidentReportId: incidentReportId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setIncidentReportsThunk(retData),
    onFailure: err => setIncidentReportsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setIncidentReportsThunk = ({ retData }) => dispatch => {
  let selectedIncidentReport = retData.data;
  let files = retData.files || [];

  let mapData = {
    selectedIncidentReport: {
      ...selectedIncidentReport,
      files: files
    }
  };

  dispatch(setIncidentReports(mapData));
};

export const setIncidentReports = data => ({
  type: SET_INCIDENT_REPORTS,
  payload: data
});

export const saveIncidentReportsThunk = (incidentReport, widgetCode) => dispatch => {
  let url = '/incidents/incidentreport/save';
  let actionName = incidentReport.IncidentReportId === 0 ? CREATE_INCIDENT_REPORTS : UPDATE_INCIDENT_REPORTS;
  let log = logEntry(INCIDENT_REPORTS_MANAGEMENT, actionName, incidentReport);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  incidentReport.OrganisationId = orgId;
  dispatch(saveIncidentReports(incidentReport, url, log, widgetCode));
};

export const saveIncidentReports = (incidentReport, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setIncidentReportsStatusThunk(retData, incidentReport),
    onFailure: err => setIncidentReportsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: incidentReport,
      log: log
    },
    widgetcode: widgetCode
  });

export const setIncidentReportsStatusThunk = (message, incidentReport) => dispatch => {
  dispatch(setIncidentReportsStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !incidentReport.PerformDelete;
    dispatch(setIncidentReportsStatus(message, false, isRedirect));
  }, 2500);
};

export const setIncidentReportsStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_INCIDENT_REPORTS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setIncidentReportsChangeThunk = retData => dispatch => {
  dispatch(setIncidentReportsChange(retData));
};

export const setIncidentReportsChange = retData => ({
  type: SET_INCIDENT_REPORTS_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      SiteId: siteId,
      filterFor: 'incidentReportsListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setIncidentReportsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_INCIDENT_REPORTS_FILTERS,
  payload: {
    data: {
      isLoading: false,
      incidentReportsListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_INCIDENT_REPORTS_RESULTS,
  payload: retData
});
