import { initialSafetyPlanState } from '../initialState';

import {
  SAFETY_PLAN_ERROR,
  GET_ALL_SAFETY_PLAN_DETAILS,
  SAFETY_PLAN_IS_LOADING,
  SET_SAFETY_PLAN_CURRENT_PAGE,
  SET_SAFETY_PLAN_PAGE_FILTER,
  SET_SAFETY_PLAN_CHANGE,
  SET_SAFETY_PLAN,
  UPDATE_SAFETY_PLAN_SAVE_STATUS,
  SET_SAFETY_PLAN_FILTERS,
  SET_SAFETY_PLAN_RESULTS,
  SET_SAFETY_PLAN_COMMON_LIST
} from '../../../constants/index';

const safetyPlanReducer = (state = initialSafetyPlanState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_SAFETY_PLAN_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          ...data
        }
      };
    }

    case SET_SAFETY_PLAN_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          currentPage
        }
      };
    }

    case SET_SAFETY_PLAN_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_SAFETY_PLAN_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          ...data
        }
      };
    }

    case SAFETY_PLAN_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          isLoading: isLoading
        }
      };
    }

    case SAFETY_PLAN_ERROR: {
      const { data } = payload;
      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          ...data
        }
      };
    }

    case SET_SAFETY_PLAN: {
      let { selectedSafetyPlan } = payload;

      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          isOpSuccessful: false,
          showBanner: false,
          selectedSafetyPlan: {
            ...state.safetyPlan.selectedSafetyPlan,
            ...selectedSafetyPlan
          }
        }
      };
    }

    case SET_SAFETY_PLAN_CHANGE: {
      let { selectedSafetyPlan } = payload;

      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedSafetyPlan: {
            ...state.safetyPlan.selectedSafetyPlan,
            ...selectedSafetyPlan
          }
        }
      };
    }

    case SET_SAFETY_PLAN_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          ...filteredResults
        }
      };
    }

    case SET_SAFETY_PLAN_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        safetyPlan: {
          ...state.safetyPlan,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default safetyPlanReducer;
