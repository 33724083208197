import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './Site.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialSiteState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired, compareValues, goBack } from '../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import uuid from 'uuid';
import { getUserProfileWidget, getSite, getGeneralData, getCurrentTimezone } from '../../selectors/index';
import { describeSiteThunk, saveSiteThunk, setSiteChangeThunk } from '../../store/actions/sites';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import { FREQUENCY, GENERAL_STATUS } from '../../constants/index';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const Site = ({ intl }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  let { siteId, segmentName, selectedTab } = useParams();

  let IsEdit = false;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);
  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const generalData = useSelector(state => getGeneralData(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const site = useSelector(state => getSite(state));

  const [localformErrors, setFormErrors] = useState({});
  const [fileUploadStarted, setFileUploadStarted] = useState(false);

  const ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  const backUrl = '/sites';
  let widgetCode = '';
  if (siteId) {
    IsEdit = true;
    widgetCode = 'WQSP_SITES_UPDATE';
  }

  useEffect(() => {
    if (siteId) {
      dispatch(describeSiteThunk(siteId, widgetCode));
    }
  }, [describeSiteThunk, siteId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setSiteChangeThunk({
          ...initialSiteState.site
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalSite = currentState => {
    dispatch(setSiteChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = { ...site.selectedSite };
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.GpsLatitude = parseFloat(saveData.GpsLatitude);
    saveData.GpsLongitude = parseFloat(saveData.GpsLongitude);

    if (!IsEdit) {
      saveData.SiteId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_SITES_ADD';
    }

    dispatch(saveSiteThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!site || !site.selectedSite || !site.selectedSite.Name || isRequired(site.selectedSite.Name, 1)) {
      formErrors.Name = intl.formatMessage({
        id: 'common.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    if (!site || !site.selectedSite || !site.selectedSite.Households || parseInt(site.selectedSite.Households) === 0) {
      formErrors.Households = intl.formatMessage({
        id: 'site.householdsRequired',
        defaultMessage: 'Household is a mandatory field'
      });
      isValid = false;
    }

    if (!site || !site.selectedSite || !site.selectedSite.Inhabitants || parseInt(site.selectedSite.Inhabitants) === 0) {
      formErrors.Inhabitants = intl.formatMessage({
        id: 'site.inhabitantsRequired',
        defaultMessage: 'Inhabitants is a mandatory field'
      });
      isValid = false;
    }

    if (!site || !site.selectedSite || !site.selectedSite.GpsLatitude || isNaN(parseFloat(site.selectedSite.GpsLatitude))) {
      formErrors.GpsLatitude = intl.formatMessage({
        id: 'wqsp.common.gpsLatitudeRequired',
        defaultMessage: 'GPS Latitude is a mandatory field'
      });
      isValid = false;
    }

    if (!site || !site.selectedSite || !site.selectedSite.GpsLongitude || isNaN(parseFloat(site.selectedSite.GpsLongitude))) {
      formErrors.GpsLongitude = intl.formatMessage({
        id: 'wqsp.common.gpsLongitudeRequired',
        defaultMessage: 'GPS Longitude is a mandatory field'
      });
      isValid = false;
    }

    if (site.selectedSite.LastReviewDate && site.selectedSite.NextReviewDate) {
      if (site.selectedSite.LastReviewDate > site.selectedSite.NextReviewDate) {
        formErrors.NextReviewDate = intl.formatMessage({
          id: 'common.nextReviewDateShouldBeGrater',
          defaultMessage: 'Next review date must be grater than last review date'
        });
        isValid = false;
      }
    }

    if (!site || !site.selectedSite || !site.selectedSite.Location || isRequired(site.selectedSite.Location, 1)) {
      formErrors.Location = intl.formatMessage({
        id: 'site.locationRequired',
        defaultMessage: 'Location is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalSite({
      ...site,
      selectedSite: { ...site.selectedSite, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    goBack(history, backUrl);
  };

  const onUploadStart = files => {
    setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          SiteId: 0,
          newFile: true,
          ImageId: item.fileGuid,
          Name: item.name,
          S3URL: item.S3URL
        };
      });
      let allFiles = [...site.selectedSite.files, ...newFiles];
      setLocalSite({
        selectedSite: {
          ...site.selectedSite,
          files: allFiles
        }
      });
    }
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();

    setLocalSite({
      ...site,
      selectedSite: { ...site.selectedSite, [fieldName]: changeDate }
    });
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };
  const onStatusChange = e => {
    setLocalSite({
      ...site,
      selectedSite: { ...site.selectedSite, [e.target.name]: e.target.value, StatusId: null }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalSite({
      ...site,
      selectedSite: { ...site.selectedSite, [fieldName]: e.value }
    });
  };

  const removeFile = fileGuid => {
    let files = site.selectedSite.files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...site.selectedSite.deletedFileIds];
    deletedFileIds.push(fileGuid);

    setLocalSite({
      ...site,
      selectedSite: { ...site.selectedSite, files: files, deletedFileIds: deletedFileIds }
    });
  };

  const testSpike = () => {
    console.log('test');
  };

  let messageId = (site && site.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const siteName = (site.selectedSite ? site.selectedSite.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' ' + siteName;
  } else {
    heading = intl.formatMessage({ id: 'site.addNewSite', defaultMessage: 'Add New Site' });
  }

  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  if (site.isRedirect) goBack(history, backUrl);

  return (
    <SiteDashboardControlsContentTemplate
      selectedPage={selectedTab}
      siteId={siteId}
      segmentName={segmentName}
      userProfileWidgets={userProfileWidgets}
    >
      <div className={s.site}>
        {site.isLoading && <LoadingSpinner />}

        {!siteId && (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="site.Site" defaultMessage="Site" />}</h1>
          </div>
        )}

        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={site.showBanner}
          status={site.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          <div className={s.siteHeader}>
            {!siteId && (
              <GoBack className={s.backLink}>
                &lt; &nbsp;
                <FormattedMessage id="common.back" defaultMessage="BACK" />
              </GoBack>
            )}
            <h3>{heading}</h3>
          </div>
          <div className={s.siteContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formSiteName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={site.selectedSite.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formSiteDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={site.selectedSite.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formSiteHouseholds">
                      <Form.Label>
                        <FormattedMessage id="site.households" defaultMessage="Households" />
                      </Form.Label>

                      <InputNumber
                        name="Households"
                        step="1"
                        min="1"
                        max="1000"
                        onInputChange={onChange}
                        customClassName={`${s.smallText} ${localformErrors && localformErrors.Households ? s.formControlError : ''}`}
                        value={site.selectedSite.Households?.toString()}
                        id="Households"
                      />
                      {localformErrors && localformErrors.Households && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Households}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formSiteInhabitants">
                      <Form.Label>
                        <FormattedMessage id="site.inhabitants" defaultMessage="Inhabitants" />
                      </Form.Label>

                      <InputNumber
                        name="Inhabitants"
                        step="1"
                        min="1"
                        max="1000"
                        onInputChange={onChange}
                        customClassName={`${s.smallText} ${localformErrors && localformErrors.Inhabitants ? s.formControlError : ''}`}
                        value={site.selectedSite.Inhabitants?.toString()}
                        id="Inhabitants"
                      />
                      {localformErrors && localformErrors.Inhabitants && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Inhabitants}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formSiteRegion">
                      <Form.Label>
                        <FormattedMessage id="site.region" defaultMessage="Region" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Region"
                        onChange={onChange}
                        value={site.selectedSite.Region}
                        className={`${s.formControl}`}
                        placeholder={intl.formatMessage({
                          id: 'site.region',
                          defaultMessage: 'Region'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formSiteLatitude">
                      <Form.Label>
                        <FormattedMessage id="site.gpsLatitude" defaultMessage="GPS Latitude" />
                      </Form.Label>
                      <InputNumber
                        name="GpsLatitude"
                        step=".01"
                        onInputChange={onChange}
                        value={site.selectedSite.GpsLatitude?.toString()}
                        customClassName={`${s.smallText} ${localformErrors && localformErrors.GpsLatitude ? s.formControlError : ''}`}
                        hideStepArrow={true}
                        placeholder={intl.formatMessage({
                          id: 'wqsp.common.gpsLatitude',
                          defaultMessage: 'GPS Latitude'
                        })}
                        id="GpsLatitude"
                      />
                      {localformErrors && localformErrors.GpsLatitude && (
                        <p role="alert" className={s.error}>
                          {localformErrors.GpsLatitude}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formSiteLongitude">
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.gpsLongitude" defaultMessage="GPS Longitude" />
                      </Form.Label>
                      <InputNumber
                        name="GpsLongitude"
                        step=".01"
                        onInputChange={onChange}
                        value={site.selectedSite.GpsLongitude?.toString()}
                        customClassName={`${s.smallText} ${localformErrors && localformErrors.GpsLongitude ? s.formControlError : ''}`}
                        hideStepArrow={true}
                        placeholder={intl.formatMessage({
                          id: 'wqsp.common.gpsLongitude',
                          defaultMessage: 'GPS Longitude'
                        })}
                        id="GpsLongitude"
                      />

                      {localformErrors && localformErrors.GpsLongitude && (
                        <p role="alert" className={s.error}>
                          {localformErrors.GpsLongitude}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formSiteLocation">
                      <Form.Label>
                        <FormattedMessage id="site.location" defaultMessage="Location" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Location"
                        onChange={onChange}
                        value={site.selectedSite.Location || ''}
                        className={`${s.formControl} ${localformErrors && localformErrors.Location ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'site.Location',
                          defaultMessage: 'Location'
                        })}
                      />

                      {localformErrors && localformErrors.Location && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Location}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formLastReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        isValidDate={disableFutureDates}
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'LastReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(site.selectedSite.LastReviewDate)}
                        defaultValue={getFormattedate(site.selectedSite.LastReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formNextReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'NextReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(site.selectedSite.NextReviewDate)}
                        defaultValue={getFormattedate(site.selectedSite.NextReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.NextReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.NextReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formGroupReviewFrequency">
                      <Form.Label>
                        <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                      </Form.Label>

                      <Dropdown
                        id="formMaintenanceFrequency"
                        dataArray={frequencyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="ReviewFrequencyId"
                        onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                        selectedOption={frequencyOptions().filter(option => option.value === site.selectedSite.ReviewFrequencyId)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formActioned">
                      <Form.Label>
                        <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                      </Form.Label>

                      <ToggleSwitch
                        handleClick={() => {
                          onStatusChange({ target: { name: 'IsActioned', value: !site.selectedSite.IsActioned } });
                        }}
                        classname={s.switch}
                        checked={site.selectedSite.IsActioned}
                        labelChecked={yesText}
                        labelUnchecked={noText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!site.selectedSite.IsActioned && (
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formStatus">
                        <Form.Label>
                          <FormattedMessage id="common.status" defaultMessage="Status" />
                        </Form.Label>

                        <Dropdown
                          id="formStatus"
                          dataArray={statusOptions()}
                          controlData={{ placeholderText: ddlInitText }}
                          onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                          data-unittest="formDdlRiskStatus"
                          selectedOption={statusOptions().filter(option => option.value === site.selectedSite.StatusId)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Label>
                      <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                    </Form.Label>
                    <FileUploader
                      widgetCode={'WQSP_TESTRESULTSREPORT_ATTACH'}
                      allowedFileTypes={[
                        ['image/jpeg', 'jpeg'],
                        ['application/pdf', 'pdf'],
                        ['application/msword', 'doc']
                      ]}
                      location={`organisation/${orgId}/sites`}
                      enableMultipleUpload={true}
                      maxFiles={5}
                      onUploadComplete={onUploadComplete}
                      onUploadStart={onUploadStart}
                    />
                  </Col>
                </Row>
                <FileList files={site.selectedSite.files} onRemoveFile={removeFile} />
                <Row className={s.btnRow}>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                      disabled={fileUploadStarted}
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </SiteDashboardControlsContentTemplate>
  );
};

export default injectIntl(Site);
