import React, { useState, useEffect } from 'react';
import s from './InspectionTypeQuestion.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getInspectionTypeQuestion } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialInspectionTypeQuestionState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import { compareValues } from '../../utils/';
import Banner from '../Banner/Banner';
import help from '../../assets/help-grey.svg';
import {
  describeInspectionTypeQuestionThunk,
  saveInspectionTypeQuestionThunk,
  setInspectionTypeQuestionChangeThunk,
  setInspectionTypeQuestionCleanUpThunk
} from '../../store/actions/inspectionTypeQuestion';
import uuid from 'uuid';
import ReactTooltip from 'react-tooltip';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const InspectionTypeQuestion = ({ intl }) => {
  let history = useHistory();
  let { inspectionTypeQuestionId, inspectionSurveyTypeId } = useParams();

  const dispatch = useDispatch();
  const inspectionTypeQuestion = useSelector(state => getInspectionTypeQuestion(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});

  let backUrl = '/operational-setup/inspection-report';
  if (inspectionSurveyTypeId && inspectionSurveyTypeId > 0) {
    backUrl = `/operational-setup/inspection-report/inspection-type/${inspectionSurveyTypeId}`;
  }

  let widgetCode = '';
  if (inspectionTypeQuestionId && inspectionTypeQuestionId > 0) {
    IsEdit = true;
    widgetCode = 'WQSP_INSPECTIONTYPEQUESTION_EDIT';
  } else {
    widgetCode = 'WQSP_INSPECTIONTYPEQUESTION_ADDNEW';
    inspectionTypeQuestionId = 0;
  }

  useEffect(() => {
    if (inspectionTypeQuestionId || inspectionTypeQuestionId === 0) {
      dispatch(describeInspectionTypeQuestionThunk(inspectionTypeQuestionId, widgetCode));
    }
  }, [describeInspectionTypeQuestionThunk, dispatch, inspectionTypeQuestionId]);

  useEffect(() => {
    if (inspectionSurveyTypeId) {
      setLocalInspectionTypeQuestion({
        ...inspectionTypeQuestion,
        SelectedInspectionTypeQuestion: {
          ...inspectionTypeQuestion.SelectedInspectionTypeQuestion,
          InspectionTypeId: inspectionSurveyTypeId
        }
      });
    }
  }, [inspectionSurveyTypeId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setInspectionTypeQuestionCleanUpThunk(initialInspectionTypeQuestionState.inspectionTypeQuestion.SelectedInspectionTypeQuestion)
      );
    };
  }, []);

  // Update redux store
  const setLocalInspectionTypeQuestion = currentState => {
    dispatch(setInspectionTypeQuestionChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = inspectionTypeQuestion.SelectedInspectionTypeQuestion;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.InspectionTypeQuestionId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_INSPECTIONTYPEQUESTION_ADDNEW';
    }

    dispatch(saveInspectionTypeQuestionThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !inspectionTypeQuestion ||
      !inspectionTypeQuestion.SelectedInspectionTypeQuestion ||
      !inspectionTypeQuestion.SelectedInspectionTypeQuestion.InspectionTypeId ||
      isRequired(inspectionTypeQuestion.SelectedInspectionTypeQuestion.InspectionTypeId, 1)
    ) {
      formErrors.InspectionTypeId = intl.formatMessage({
        id: 'inspectionTypeQuestions.inspectionTypeRequired',
        defaultMessage: 'Inspection type is a mandatory field'
      });
      isValid = false;
    }
    if (
      !inspectionTypeQuestion ||
      !inspectionTypeQuestion.SelectedInspectionTypeQuestion ||
      !inspectionTypeQuestion.SelectedInspectionTypeQuestion.QuestionText ||
      isRequired(inspectionTypeQuestion.SelectedInspectionTypeQuestion.QuestionText, 1)
    ) {
      formErrors.QuestionText = intl.formatMessage({
        id: 'inspectionTypeQuestions.questionTextRequired',
        defaultMessage: 'Question text is a mandatory field'
      });
      isValid = false;
    }
    if (
      !inspectionTypeQuestion ||
      !inspectionTypeQuestion.SelectedInspectionTypeQuestion ||
      !inspectionTypeQuestion.SelectedInspectionTypeQuestion.QuestionNumber ||
      isRequired(inspectionTypeQuestion.SelectedInspectionTypeQuestion.QuestionNumber, 1)
    ) {
      formErrors.QuestionNumber = intl.formatMessage({
        id: 'inspectionTypeQuestions.questionNumberRequired',
        defaultMessage: 'Question number is a mandatory field'
      });
      isValid = false;
    } else if (
      inspectionTypeQuestion.SelectedInspectionTypeQuestion.QuestionNumber < 1 ||
      inspectionTypeQuestion.SelectedInspectionTypeQuestion.QuestionNumber > 1000
    ) {
      formErrors.QuestionNumber = intl.formatMessage({
        id: 'inspectionTypeQuestions.questionNumberInvalid',
        defaultMessage: 'Invalid Question number'
      });
      isValid = false;
    }
    if (
      !inspectionTypeQuestion ||
      !inspectionTypeQuestion.SelectedInspectionTypeQuestion ||
      !inspectionTypeQuestion.SelectedInspectionTypeQuestion.Score ||
      isRequired(inspectionTypeQuestion.SelectedInspectionTypeQuestion.Score, 1)
    ) {
      formErrors.Score = intl.formatMessage({
        id: 'inspectionTypeQuestions.scoreRequired',
        defaultMessage: 'Score is a mandatory field'
      });
      isValid = false;
    } else if (
      inspectionTypeQuestion.SelectedInspectionTypeQuestion.Score < -1 ||
      inspectionTypeQuestion.SelectedInspectionTypeQuestion.Score > 100
    ) {
      formErrors.Score = intl.formatMessage({
        id: 'inspectionTypeQuestions.scoreInvalid',
        defaultMessage: 'Invalid score'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };
  const onDropdownChange = e => {
    setLocalInspectionTypeQuestion({
      ...inspectionTypeQuestion,
      SelectedInspectionTypeQuestion: { ...inspectionTypeQuestion.SelectedInspectionTypeQuestion, InspectionTypeId: e.value }
    });
  };

  //on control value change
  const onChange = e => {
    setLocalInspectionTypeQuestion({
      ...inspectionTypeQuestion,
      SelectedInspectionTypeQuestion: { ...inspectionTypeQuestion.SelectedInspectionTypeQuestion, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.goBack();
  };

  let messageId = (inspectionTypeQuestion && inspectionTypeQuestion.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelCompTypeName =
    (inspectionTypeQuestion.SelectedInspectionTypeQuestion ? inspectionTypeQuestion.SelectedInspectionTypeQuestion.QuestionText : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + modelCompTypeName;
  } else {
    heading = intl.formatMessage({
      id: 'inspectionTypeQuestions.addInspectionTypeQuestion',
      defaultMessage: 'Add new inspection type survey question'
    });
  }

  const inspectionTypeOptions = () => {
    let inspectionTypesData = [];

    const data = inspectionTypeQuestion.InspectionTypes;

    data.forEach(element => {
      inspectionTypesData.push({
        label: element.Name,
        value: element.InspectionTypeId
      });
    });
    return inspectionTypesData.sort(compareValues('label'));
  };

  const inspectionTypeSelectedValue = () => {
    if (inspectionTypeQuestionId && inspectionTypeQuestionId > 0) {
      return inspectionTypeOptions().filter(
        option => option.value === inspectionTypeQuestion.SelectedInspectionTypeQuestion.InspectionTypeId
      );
    } else if (inspectionSurveyTypeId) {
      return inspectionTypeOptions().filter(option => option.value === parseInt(inspectionSurveyTypeId));
    }
  };

  let ddlClassSensor = localformErrors && localformErrors.InspectionTypeId ? s.ddlError : '';
  let tooltipQuestion = intl.formatMessage({
    id: 'inspectionTypeQuestions.tooltipQuestionNumber',
    defaultMessage: 'The order number of the question for the survey type'
  });
  let tooltipScore = intl.formatMessage({
    id: 'inspectionTypeQuestions.tooltipScore',
    defaultMessage: 'The weight of the question for the survey type'
  });

  return (
    <div className={s.inspectionTypeQuestion}>
      {inspectionTypeQuestion.isLoading && <LoadingSpinner />}
      {inspectionTypeQuestion.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={inspectionTypeQuestion.showBanner}
        status={inspectionTypeQuestion.isOpSuccessful}
        successText={messageText}
      />
      <OperationalSetupContentTemplate selectedPage="inspectionReport" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.inspectionTypeQuestionHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.inspectionTypeQuestionContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formInspectionType">
                      <Form.Label>
                        <FormattedMessage id="inspectionTypeQuestions.inspectionType" defaultMessage="Inspection Survey Type" />
                      </Form.Label>

                      <Dropdown
                        id="formInspectionType"
                        dataArray={inspectionTypeOptions()}
                        controlData={{
                          placeholderText: (
                            <FormattedMessage id="inspectionTypeQuestions.selectInspectionType" defaultMessage="Select inspection type" />
                          ),
                          customClassName: ddlClassSensor
                        }}
                        name="InspectionTypeId"
                        onDropdownChange={onDropdownChange}
                        selectedOption={inspectionTypeSelectedValue()}
                        disabled={IsEdit || inspectionSurveyTypeId}
                        data-unittest="formSensor"
                      />
                      {localformErrors && localformErrors.InspectionTypeId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.InspectionTypeId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formInspectionTypeQuestionText">
                      <Form.Label>
                        <FormattedMessage id="inspectionTypeQuestions.questionText" defaultMessage="Question" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="QuestionText"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'inspectionTypeQuestions.questionText',
                          defaultMessage: 'Question Text'
                        })}
                        value={inspectionTypeQuestion.SelectedInspectionTypeQuestion.QuestionText}
                      />
                      {localformErrors && localformErrors.QuestionText && (
                        <p role="alert" className={s.error}>
                          {localformErrors.QuestionText}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formInspectionTypeExplanatoryNote">
                      <Form.Label>
                        <FormattedMessage id="inspectionTypeQuestions.explanatoryNote" defaultMessage="Explanatory Note" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="5"
                        name="ExplanatoryNote"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'inspectionTypeQuestions.explanatoryNote',
                          defaultMessage: 'Explanatory Note'
                        })}
                        value={inspectionTypeQuestion.SelectedInspectionTypeQuestion.ExplanatoryNote}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2}>
                    <Form.Group controlId="formInspectionTypeQuestionNumber">
                      <Form.Label>
                        <FormattedMessage id="inspectionTypeQuestions.questionNumber" defaultMessage="Question Number" />
                        <img src={help} alt="icon" className={s.icon} data-tip={tooltipQuestion}></img>
                      </Form.Label>
                      <InputNumber
                        name="QuestionNumber"
                        step="1"
                        min="1"
                        max="1000"
                        onInputChange={onChange}
                        customClassName={s.smallText}
                        value={inspectionTypeQuestion.SelectedInspectionTypeQuestion.QuestionNumber?.toString()}
                        id="inspectionTypeQuestionNumber"
                      />
                      {localformErrors && localformErrors.QuestionNumber && (
                        <p role="alert" className={s.error}>
                          {localformErrors.QuestionNumber}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2}>
                    <Form.Group controlId="formInspectionTypeQuestionScore">
                      <Form.Label>
                        <FormattedMessage id="inspectionTypeQuestions.score" defaultMessage="Question Score" />
                        <img src={help} alt="icon" className={s.icon} data-tip={tooltipScore}></img>
                      </Form.Label>
                      <InputNumber
                        name="Score"
                        step="1"
                        min="0"
                        max="100"
                        onInputChange={onChange}
                        customClassName={s.smallText}
                        value={inspectionTypeQuestion.SelectedInspectionTypeQuestion.Score?.toString()}
                        id="inspectionTypeQuestionScore"
                      />
                      {localformErrors && localformErrors.Score && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Score}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
        <ReactTooltip />
      </OperationalSetupContentTemplate>
    </div>
  );
};

InspectionTypeQuestion.defaultProps = {
  inspectionTypeQuestion: {
    ...initialInspectionTypeQuestionState
  }
};

export default injectIntl(InspectionTypeQuestion);
