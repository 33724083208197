import React from 'react';
import PropTypes from 'prop-types';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const OperationalSetup = ({ userProfileWidgets }) => (
  <div>
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_TESTRESULTS_TAB') ? (
      <Redirect to="/operational-setup/test-results" />
    ) : IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONRESULTS_TAB') ? (
      <Redirect to="/operational-setup/inspection-report" />
    ) : IsWidgetAccessible(userProfileWidgets, 'WQSP_TASKSCHECKLISTS_TAB') ? (
      <Redirect to="/operational-setup/checklist-task-templates" />
    ) : IsWidgetAccessible(userProfileWidgets, 'WQSP_GENERAL_FIELDTYPES_LIST') ? (
      <Redirect to="/operational-setup/general-fieldtypes" />
    ) : IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKCATEGORY_LIST') ? (
      <Redirect to="/operational-setup/risk-categories" />
    ) : IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDEVENTTEMPLATE_LIST') ? (
      <Redirect to="/operational-setup/hazards" />
    ) : IsWidgetAccessible(userProfileWidgets, 'PERSONNEL_TAB') ? (
      <Redirect to="/operational-setup/personnel" />
    ) : (
      ''
    )}
    <span>
      <FormattedMessage
        id="OperationalSetup.NoAccess"
        defaultMessage="You do not have access to operational setup controls. Please contact your administrator."
      />
    </span>
  </div>
);

OperationalSetup.propTypes = {
  userProfileWidgets: PropTypes.object.isRequired
};

export default OperationalSetup;
