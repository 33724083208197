import { initialHazardEventLibraryState } from './initialState';

import {
  HAZARD_EVENT_LIBRARY_ERROR,
  GET_ALL_HAZARD_EVENT_LIBRARY_DETAILS,
  HAZARD_EVENT_LIBRARY_IS_LOADING,
  SET_HAZARD_EVENT_LIBRARY_CURRENT_PAGE,
  SET_HAZARD_EVENT_LIBRARY_PAGE_FILTER,
  SET_HAZARD_EVENT_LIBRARY_CHANGE,
  SET_HAZARD_EVENT_LIBRARY,
  UPDATE_HAZARD_EVENT_LIBRARY_SAVE_STATUS
} from '../../constants/index';

const hazardEventLibraryReducer = (state = initialHazardEventLibraryState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_HAZARD_EVENT_LIBRARY_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        hazardEventTemplate: {
          ...state.hazardEventTemplate,
          ...data
        }
      };
    }

    case SET_HAZARD_EVENT_LIBRARY_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        hazardEventTemplate: {
          ...state.hazardEventTemplate,
          currentPage
        }
      };
    }

    case SET_HAZARD_EVENT_LIBRARY_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        hazardEventTemplate: {
          ...state.hazardEventTemplate,
          filter
        }
      };
    }

    case UPDATE_HAZARD_EVENT_LIBRARY_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        hazardEventTemplate: {
          ...state.hazardEventTemplate,
          ...data
        }
      };
    }

    case HAZARD_EVENT_LIBRARY_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        hazardEventTemplate: {
          ...state.hazardEventTemplate,
          isLoading: isLoading
        }
      };
    }

    case HAZARD_EVENT_LIBRARY_ERROR: {
      const { data } = payload;
      return {
        ...state,
        hazardEventTemplate: {
          ...state.hazardEventTemplate,
          ...data
        }
      };
    }

    case SET_HAZARD_EVENT_LIBRARY: {
      let { SelectedHazardEventTemplate } = payload;

      return {
        ...state,
        hazardEventTemplate: {
          ...state.hazardEventTemplate,
          isOpSuccessful: false,
          showBanner: false,
          SelectedHazardEventTemplate: {
            ...state.hazardEventTemplate.SelectedHazardEventTemplate,
            ...SelectedHazardEventTemplate
          }
        }
      };
    }

    case SET_HAZARD_EVENT_LIBRARY_CHANGE: {
      let { SelectedHazardEventTemplate } = payload;

      return {
        ...state,
        hazardEventTemplate: {
          ...state.hazardEventTemplate,
          isOpSuccessful: false,
          showBanner: false,
          SelectedHazardEventTemplate: {
            ...state.hazardEventTemplate.SelectedHazardEventTemplate,
            ...SelectedHazardEventTemplate
          }
        }
      };
    }

    default:
      return state;
  }
};

export default hazardEventLibraryReducer;
