import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './SitePathway.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import { initialSitePathwayState } from '../../../../store/reducers/initialState';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import Banner from '../../../Banner/Banner';
import uuid from 'uuid';
import { getSelectedSitePathway, getSitePathway, getCurrentTimezone } from '../../../../selectors/index';
import { describeSitePathwayThunk, setSitePathwayThunk, setSitePathwayChangeThunk } from '../../../../store/actions/sitePathway';
import InfinityProgressSvg from './InfinityProgressSvg';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const SitePathway = ({ intl }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  let { siteId, segmentName } = useParams();
  segmentName = segmentName || 'build';
  siteId = siteId && parseInt(siteId);

  const sitePathway = useSelector(state => getSitePathway(state));
  const selectedSitePathway = useSelector(state => getSelectedSitePathway(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let widgetCode = 'WQSP_SITE_PATHWAY';

  useEffect(() => {
    if (siteId) {
      let timestamp = moment().unix();
      dispatch(describeSitePathwayThunk(siteId, widgetCode, timestamp));
    }
  }, [describeSitePathwayThunk, siteId, widgetCode]);

  useEffect(() => {
    dispatch(setSitePathwayChangeThunk({ selectedSitePathway: { segmentData: sitePathway[segmentName], segmentName: segmentName } }));
  }, [setSitePathwayChangeThunk, sitePathway[segmentName]]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setSitePathwayThunk({
          retData: {
            data: {
              ...initialSitePathwayState.sitePathway
            }
          }
        })
      );
    };
  }, []);

  const onSitePathwayChange = sitePathwayData => {
    dispatch(setSitePathwayChangeThunk(sitePathwayData));
  };

  const getDateTime = value => {
    if (value && value.toString().length === 10) {
      return moment
        .unix(value)
        .tz(currentTimezone)
        .format('DD-MMM-YYYY z');
    }
    if (!value && value !== 0 && value !== '0') {
      value = 'N/A';
    }
    return value;
  };

  const getAuxTableRows = sitePathwayData => {
    let segmentPropNames = Object.getOwnPropertyNames(sitePathwayData);

    return segmentPropNames.map(propName => {
      if (propName === 'Status') {
        return true;
      }

      let descriptionId = 'sitePathway.';
      let descriptionName = '';
      if (propName.indexOf('_') >= 0) {
        let propNames = propName.split('_');
        descriptionId += propNames.splice(-1)[0];
        descriptionName = propNames.join(' ');
      } else {
        descriptionId += propName;
      }

      let description = descriptionName + ' ' + intl.formatMessage({ id: descriptionId, defaultMessage: '' });
      return (
        <tr className={s.tr} key={propName}>
          <td>{description}</td>
          <td>{getDateTime(sitePathwayData[propName])}</td>
        </tr>
      );
    });
  };

  let messageId = (sitePathway && sitePathway.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.sitePathway}>
      {sitePathway.isLoading && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={sitePathway.showBanner}
        status={sitePathway.isOpSuccessful}
        successText={messageText}
      />
      <div className={s.contentWrapper}>
        <div className={s.sitePathwayContent}>
          <Form>
            <div className={s.topRow}>
              <Row>
                <Col md={12} lg={7}>
                  <InfinityProgressSvg sitePathwayData={sitePathway} onSitePathwayChange={onSitePathwayChange} />
                </Col>
                <Col lg={5}>
                  {selectedSitePathway && selectedSitePathway.segmentData && (
                    <div>
                      <Row>
                        <Col>
                          <h4>
                            <FormattedMessage
                              id={`sitePathway.${selectedSitePathway.segmentName}Segment`}
                              defaultMessage={`${selectedSitePathway.segmentName} Segment`}
                            />
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Table>
                          <thead>
                            <tr>
                              <th>
                                <FormattedMessage id="common.description" defaultMessage="Description" />
                              </th>
                              <th>
                                <FormattedMessage id="common.value" defaultMessage="Value" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>{getAuxTableRows(selectedSitePathway.segmentData)}</tbody>
                        </Table>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SitePathway);
