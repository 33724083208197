import { initialPersonnelState } from '../initialState';

import {
  PERSONNEL_ERROR,
  GET_ALL_PERSONNEL_DETAILS,
  PERSONNEL_IS_LOADING,
  SET_PERSONNEL_CURRENT_PAGE,
  SET_PERSONNEL_PAGE_FILTER,
  SET_PERSONNEL_CHANGE,
  SET_PERSONNEL,
  UPDATE_PERSONNEL_SAVE_STATUS,
  SET_PERSONNEL_FILTERS,
  SET_PERSONNEL_RESULTS,
  REMOVE_PERSONNEL
} from '../../../constants/index';

const personnelReducer = (state = initialPersonnelState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_PERSONNEL_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        personnel: {
          ...state.personnel,
          ...data
        }
      };
    }

    case SET_PERSONNEL_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        personnel: {
          ...state.personnel,
          currentPage
        }
      };
    }

    case SET_PERSONNEL_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        personnel: {
          ...state.personnel,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_PERSONNEL_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        personnel: {
          ...state.personnel,
          ...data
        }
      };
    }

    case PERSONNEL_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        personnel: {
          ...state.personnel,
          isLoading: isLoading
        }
      };
    }

    case PERSONNEL_ERROR: {
      const { data } = payload;
      return {
        ...state,
        personnel: {
          ...state.personnel,
          ...data
        }
      };
    }

    case SET_PERSONNEL: {
      let data = payload;

      return {
        ...state,
        personnel: {
          ...state.personnel,
          isOpSuccessful: false,
          showBanner: false,
          selectedPersonnel: {
            ...state.personnel.selectedPersonnel,
            ...data
          }
        }
      };
    }

    case REMOVE_PERSONNEL: {
      const { personnelId } = payload;
      return {
        ...state,
        personnel: {
          ...state.personnel,
          isOpSuccessful: false,
          isRedirect: false,
          personnels: [...state.personnel.personnels.filter(item => item.PersonnelId !== personnelId)],
          filteredResults: [...state.personnel.filteredResults.filter(item => item.PersonnelId !== personnelId)],
          personnelListCount: state.personnel.personnelListCount - 1
        }
      };
    }

    case SET_PERSONNEL_CHANGE: {
      let { selectedPersonnel } = payload;

      return {
        ...state,
        personnel: {
          ...state.personnel,
          isOpSuccessful: false,
          showBanner: false,
          selectedPersonnel: {
            ...state.personnel.selectedPersonnel,
            ...selectedPersonnel
          }
        }
      };
    }

    case SET_PERSONNEL_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        personnel: {
          ...state.personnel,
          ...data
        }
      };
    }

    case SET_PERSONNEL_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        personnel: {
          ...state.personnel,
          ...filteredResults
        }
      };
    }

    default:
      return state;
  }
};

export default personnelReducer;
