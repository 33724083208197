import React from 'react';
import s from './OrganisationList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

const OrganisationListTable = ({ paginatedData, currentPage, UpdateAllowed }) => {
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell">
              <FormattedMessage id="organisation.organisationName" defaultMessage="Organisation Name" />
            </th>
            <th>
              <FormattedMessage id="usermanagment.location" defaultMessage="Location" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="usermanagment.users" defaultMessage="Users" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="common.active" defaultMessage="Active" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.OrganisationId} data-unittest="organisationsData">
                <td className="d-none d-sm-table-cell">{n.Name || ''}</td>
                <td>{`${n.Town || ''} ${n.Region || ''} ${n.Country || ''} ${n.PostCode || ''}`}</td>
                <td className="d-none d-md-table-cell">{n.NumberOfUsers || 0}</td>
                <td className="d-none d-md-table-cell">{n.IsActive ? 'Yes' : 'No'}</td>
                <td>
                  {UpdateAllowed ? (
                    <Link className={s.selectLink} to={`/admin-controls/organisation/${n.OrganisationId}/${n.ParentOrganisationId}`}>
                      <FormattedMessage id="common.edit" defaultMessage="EDIT" />
                    </Link>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

OrganisationListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(OrganisationListTable);
