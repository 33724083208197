import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Checklists from './Checklists';
import { getChecklist, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import {
  getChecklistThunk,
  setCurrentPage,
  setPageFilter,
  setChecklistFilteredDataThunk,
  getFilterThunk
} from '../../store/actions/checklist';

const mapStateToProps = state => {
  return {
    checklist: getChecklist(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getChecklists: filterObj => getChecklistThunk(filterObj, 'WQSP_CHECKLISTLIST'),
      setCurrentPage: currentPage => setCurrentPage(currentPage),
      setPageFilter: filter => setPageFilter(filter),
      setFilterData: currentPage => setChecklistFilteredDataThunk(currentPage),
      getFilter: () => getFilterThunk('WQSP_CHECKLISTLIST')
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Checklists);
