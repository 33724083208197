import React from 'react';
import s from './InputNumber.module.scss';

const InputNumber = props => {
  const wrapperClass = props.hideStepArrow ? s.inputWrapperNoNumber : s.inputWrapper;

  return (
    <div className={wrapperClass}>
      <input
        type="number"
        step={props.step}
        value={props.value || ''}
        min={props.min}
        max={props.max}
        className={`${s.wsaInput} ${props.customClassName}`}
        onChange={props.onInputChange || null}
        id={props.id || undefined}
        name={props.name || undefined}
        placeholder={props.placeholder || ''}
        disabled={props.disabled || false}
      />
    </div>
  );
};

InputNumber.defaultProps = {
  value: 0,
  step: 1,
  customClassName: ''
};

export default InputNumber;
