import React from 'react';
import moment from 'moment-timezone';
import { compareValues } from '../../../../utils';
import { CATEGORY_OF_WATER } from '../../../../constants/';

export const SectionDefine = ({ sectionData, getImages, getActionName, currentTimezone }) => {
  let supplyDescriptionData = sectionData.SupplyDescriptions || [];
  let elementsData = sectionData.Elements || [];
  let qualityGoalData = sectionData.QualityGoals || [];

  const getSupplyDescrptionElements = () => {
    let supplyDescriptionelements = [];
    supplyDescriptionData.map((supplyDesc, index) => {
      if (supplyDesc.WaterSupplyDescriptionId) {
        supplyDescriptionelements.push(
          <div key={supplyDesc.WaterSupplyDescriptionId} id={supplyDesc.WaterSupplyDescriptionId || 'SupplyDescription'} className="level1">
            <h3>Supply Description for {`${supplyDesc.Name || ''}`}</h3>
            <table className="level1-table">
              <tr>
                <td>Name</td>
                <td>{supplyDesc.Name || ''}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{supplyDesc.Description || ''}</td>
              </tr>
              <tr>
                <td>Supplied Water Volume</td>
                <td>{supplyDesc.SuppliedWaterVolume || ''}</td>
              </tr>
              <tr>
                <td>Community Managed</td>
                <td>{getActionName(supplyDesc.CommunityManaged)}</td>
              </tr>
              <tr>
                <td>Water Fees Collected</td>
                <td>{getActionName(supplyDesc.WaterFeesCollected)}</td>
              </tr>
              <tr>
                <td>Water Fees Details</td>
                <td>{supplyDesc.WaterFeesDetails || ''}</td>
              </tr>
              <tr>
                <td>GPS Latitude </td>
                <td>{supplyDesc.GpsLatitude || ''}</td>
              </tr>
              <tr>
                <td>GPS Longitude </td>
                <td>{supplyDesc.GpsLongitude || ''}</td>
              </tr>
              <tr>
                <td>Last Review Date </td>
                <td>
                  {supplyDesc.LastReviewDate
                    ? moment
                        .unix(parseInt(supplyDesc.LastReviewDate))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY')
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Next Review Date </td>
                <td>
                  {supplyDesc.NextReviewDate
                    ? moment
                        .unix(parseInt(supplyDesc.NextReviewDate))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY')
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Review Frequency</td>
                <td>{supplyDesc.Name || ''}</td>
              </tr>
              <tr>
                <td>Actioned</td>
                <td>{getActionName(supplyDesc.IsActioned)}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{supplyDesc.StatusName || ''}</td>
              </tr>
              <tr>
                <td>Images</td>
                <td>{getImages(supplyDesc.Images)}</td>
              </tr>
            </table>
            {elementsData.filter(item => item.WaterSupplyDescriptionId == supplyDesc.WaterSupplyDescriptionId).length > 0 ? (
              <div id="elements" className="level2">
                <h3>Elements </h3> {getElements(supplyDesc.WaterSupplyDescriptionId)}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      }
    });
    return supplyDescriptionelements;
  };

  const getElements = supplyDescritionId => {
    let elements = [];
    let wsElementData = elementsData.filter(item => item.WaterSupplyDescriptionId == supplyDescritionId);
    if (wsElementData.length > 0) {
      wsElementData.map((element, index) => {
        if (element.EntityId) {
          elements.push(
            <div key={`element_${element.WaterSupplyDescriptionId}_${element.EntityId}`}>
              <h4>Element Details for {`${element.Name || ''}`}</h4>
              <table className="level1-table">
                <tr>
                  <td>Name</td>
                  <td>{element.Name || ''}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{element.Description || ''}</td>
                </tr>
                <tr>
                  <td>Source Element Type</td>
                  <td>{element.EntityType || ''}</td>
                </tr>
                <tr>
                  <td>Sub Type</td>
                  <td>{element.EntitySubType || ''}</td>
                </tr>
                <tr>
                  <td>Category of Water</td>
                  <td>
                    {CATEGORY_OF_WATER.find(item => item.id == element.CategoryOfWater)
                      ? CATEGORY_OF_WATER.find(item => item.id == element.CategoryOfWater).name
                      : ''}
                  </td>
                </tr>
                <tr>
                  <td>Inspection Frequency</td>
                  <td>{element.InspectionFrequencyName || ''}</td>
                </tr>
                <tr>
                  <td>Condition</td>
                  <td>{element.EntityConditionName || ''}</td>
                </tr>
                <tr>
                  <td>Maintenance Frequency</td>
                  <td>{element.MaintenanceFrequencyName || ''}</td>
                </tr>
                <tr>
                  <td>Last Inspection Date</td>
                  <td>
                    {element.DateOfLastInspection
                      ? moment
                          .unix(parseInt(element.DateOfLastInspection))
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY')
                      : 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td>Last Service Date</td>
                  <td>
                    {element.DateOfLastService
                      ? moment
                          .unix(parseInt(element.DateOfLastService))
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY')
                      : 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td>GPS Latitude </td>
                  <td>{element.GpsLatitude || ''}</td>
                </tr>
                <tr>
                  <td>GPS Longitude </td>
                  <td>{element.GpsLongitude || ''}</td>
                </tr>
                <tr>
                  <td>Last Review Date </td>
                  <td>
                    {element.LastReviewDate
                      ? moment
                          .unix(parseInt(element.LastReviewDate))
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY')
                      : 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td>Next Review Date </td>
                  <td>
                    {element.NextReviewDate
                      ? moment
                          .unix(parseInt(element.NextReviewDate))
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY')
                      : 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td>Review Frequency</td>
                  <td>{element.ReviewFrequencyName || ''}</td>
                </tr>
                <tr>
                  <td>Actioned</td>
                  <td>{getActionName(element.IsActioned)}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{element.StatusName || ''}</td>
                </tr>
                <tr>
                  <td>Images</td>
                  <td>{getImages(element.Images)}</td>
                </tr>
              </table>
              {qualityGoalData.filter(
                item =>
                  item.WaterSupplyDescriptionId == supplyDescritionId && item.EntityId == element.EntityId && item.QualityGoalReportId != 0
              ).length > 0 ? (
                <div id="qualityGoals" className="level3">
                  <h4>Quality Goals</h4>
                  {getQualityGoals(supplyDescritionId, element.EntityId)}
                </div>
              ) : (
                ''
              )}
            </div>
          );
        }
      });
    }
    return elements;
  };

  const getQualityGoals = (supplyDescritionId, elementId) => {
    let goals = [];
    let goalData = qualityGoalData.filter(
      item => item.WaterSupplyDescriptionId == supplyDescritionId && item.EntityId == elementId && item.QualityGoalReportId != 0
    );

    goalData.map((goal, index) => {
      if (goal.QualityGoalReportId) {
        goals.push(
          <div key={`quality_goal_${index}`}>
            <h5>{`Quality Goal - ${index + 1}`}</h5>
            <table className="level1-table">
              <tr>
                <td>Goal Date</td>
                <td>
                  {goal.GoalDate
                    ? moment
                        .unix(parseInt(goal.GoalDate))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY')
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Name of Author</td>
                <td>{goal.AuthorName || ''}</td>
              </tr>
              <tr>
                <td>Goal Purpose</td>
                <td>{goal.GoalPurposeName || ''}</td>
              </tr>
              <tr>
                <td>Last Review Date </td>
                <td>
                  {goal.LastReviewDate
                    ? moment
                        .unix(parseInt(goal.LastReviewDate))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY')
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Next Review Date </td>
                <td>
                  {goal.NextReviewDate
                    ? moment
                        .unix(parseInt(goal.NextReviewDate))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY')
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Review Frequency</td>
                <td>{goal.ReviewFrequencyName || ''}</td>
              </tr>
              <tr>
                <td>Actioned</td>
                <td>{getActionName(goal.IsActioned)}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{goal.StatusName || ''}</td>
              </tr>
              <tr>
                <td>Goals</td>
                <td>
                  <table className="level2-table">
                    <tr>
                      <th>Test Category</th>
                      <th>Test Type</th>
                      <th>Unit of Measure</th>
                      <th>Realistic Mean</th>
                      <th>Realistic Max</th>
                      <th>Realistic LRV</th>
                      <th>Aspirational Mean</th>
                      <th>Aspirational Max</th>
                      <th>Aspirational LRV</th>
                    </tr>
                    {getGoals(goal.Goals.sort(compareValues('TestCategoryId')) || [])}
                  </table>
                </td>
              </tr>

              <tr>
                <td>Comments/Summary</td>
                <td>{goal.Comments}</td>
              </tr>
              {goal.Images && goal.Images.length > 0 ? (
                <tr>
                  <td>Images</td>
                  <td> {getImages(goal.Images)}</td>
                </tr>
              ) : (
                ''
              )}
            </table>
          </div>
        );
      }
    });
    return goals;
  };
  const getGoals = goalData => {
    let goals = [];
    if (goalData.length == 0) {
      goals.push(
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    } else {
      goalData.map((goal, index) => {
        if (goal.QualityGoalReportItemId) {
          goals.push(
            <tr key={`goal_${index}`}>
              <td>{goal.TestCategoryName || ''}</td>
              <td>{goal.TestTypeName || ''}</td>
              <td>{goal.UnitOfMeasure || ''}</td>
              <td>{goal.RealisticMeanValue}</td>
              <td>{goal.RealisticMaxValue}</td>
              <td>{goal.RealisticLRV}</td>
              <td>{goal.AspirationalMeanValue}</td>
              <td>{goal.AspirationalMaxValue}</td>
              <td>{goal.AspirationalLRV}</td>
            </tr>
          );
        }
      });
    }

    return goals;
  };
  return (
    <div id="section2" className="pageBreak sectionContent">
      <h1>Section 2 - Define</h1>
      <div>
        {supplyDescriptionData && supplyDescriptionData.length > 0 ? (
          <div>
            <h2>Supply Descriptions</h2>
            {getSupplyDescrptionElements()}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
