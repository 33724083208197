import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './TestCategory.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialTestCategoryState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Redirect, Link, useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import uuid from 'uuid';
import Cookies from 'js-cookie';
import TestCategoryItemTable from './TestCategoryItemTable';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { getUserProfileWidget, getTestCategory } from '../../selectors/index';
import {
  describeTestCategoryThunk,
  saveTestCategoryThunk,
  setTestCategoryChangeThunk,
  checkTestCategoryItemExistsThunk,
  setTestCategoryCleanUpThunk
} from '../../store/actions/testCategories';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const TestCategory = ({ intl }) => {
  let IsEdit = false;
  let heading = '';
  const dispatch = useDispatch();
  const history = useHistory();
  const backUrl = '/operational-setup/test-results';

  const [localformErrors, setFormErrors] = useState({});
  const [currentTestCategoryTypeId, setCurrentTestCategoryTypeId] = useState(-1);
  const [currentTestCategoryTypeIdentifier, setCurrentTestCategoryTypeIdentifier] = useState('');
  const [showAddEdit, showAddEditScreen] = useState(false);
  const [disableMode, setDisableMode] = useState(false);

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const testCategory = useSelector(state => getTestCategory(state));

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TESTCATEGORY_EDIT');

  let { testCategoryId } = useParams();

  if (testCategoryId && testCategoryId > 0) {
    IsEdit = true;
  }

  let widgetCode = IsEdit ? 'WQSP_TESTCATEGORY_EDIT' : 'WQSP_TESTCATEGORY_ADDNEW';

  useEffect(() => {
    if (testCategoryId && parseInt(testCategoryId) > 0) {
      dispatch(describeTestCategoryThunk(testCategoryId, widgetCode));
    } else {
      setLocalTestCategory({
        ...testCategory,
        selectedTestCategory: {
          ...initialTestCategoryState.testCategory.selectedTestCategory
        }
      });
    }
  }, [describeTestCategoryThunk, testCategoryId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setTestCategoryCleanUpThunk({
          ...initialTestCategoryState.testCategory
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalTestCategory = currentState => {
    dispatch(setTestCategoryChangeThunk(currentState));
  };
  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = {
      TestCategoryId: testCategory.selectedTestCategory.TestCategoryId,
      Name: testCategory.selectedTestCategory.Name,
      Description: testCategory.selectedTestCategory.Description,
      IsActive: testCategory.selectedTestCategory.IsActive,
      removedTestCategoryTypeIds: testCategory.selectedTestCategory.removedTestCategoryTypeIds || [],
      testCategoryTypes: testCategory.selectedTestCategory.testCategoryTypes || []
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.TestCategoryId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    dispatch(saveTestCategoryThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !testCategory ||
      !testCategory.selectedTestCategory ||
      !testCategory.selectedTestCategory.Name ||
      isRequired(testCategory.selectedTestCategory.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'testCategory.nameMandatory',
        defaultMessage: 'Test category name is mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalTestCategory({
        ...testCategory,
        selectedTestCategory: { ...testCategory.selectedTestCategory, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalTestCategory({
        ...testCategory,
        selectedTestCategory: { ...testCategory.selectedTestCategory, [e.target.name]: e.target.value }
      });
    }
  };

  const setTestCategoryItemExistsHandler = testCategoryTypeId => {
    let data = {
      TestCategoryId: testCategoryId,
      TestCategoryItemId: testCategoryTypeId,
      CheckIfTestCategoryItemInUse: true,
      UpdatedBy: Cookies.get(`userid-${envName}`) || 0
    };
    dispatch(checkTestCategoryItemExistsThunk(data, 'WQSP_TESTCATEGORY_EDIT'));
  };

  const setEditHandler = (categoryTypeId, identifer) => {
    if (categoryTypeId > -1) {
      setCurrentTestCategoryTypeId(categoryTypeId);
      setCurrentTestCategoryTypeIdentifier(identifer);

      const categoryTypeList = (testCategory.selectedTestCategory && testCategory.selectedTestCategory.testCategoryTypes) || [];
      let categoryType = categoryTypeList.filter(item => {
        if (item.GuidIdentifier === identifer) return item;
      });
      if (categoryType.length > 0) {
        dispatch(
          setTestCategoryChangeThunk({
            selectedTestCategory: {
              selectedTestCategoryType: categoryType[0]
            }
          })
        );
        showAddEditScreen(true);
      }
    } else {
      let data = initialTestCategoryState.testCategory.selectedTestCategory.selectedTestCategoryType;
      setCurrentTestCategoryTypeId(-1);
      setCurrentTestCategoryTypeIdentifier('');
      showAddEditScreen(false);
      dispatch(
        setTestCategoryChangeThunk({
          selectedTestCategory: {
            selectedTestCategoryType: data
          }
        })
      );
    }
  };

  const setAddHandler = () => {
    let data = initialTestCategoryState.testCategory.selectedTestCategory.selectedTestCategoryType;
    //data.TestCategoryId = parseInt(TestCategoryId);
    showAddEditScreen(true);
    setTestCategoryChangeThunk({
      selectedTestCategory: {
        selectedTestCategoryType: data
      }
    });
    setCurrentTestCategoryTypeId(0);
    setCurrentTestCategoryTypeIdentifier('');
  };

  const onChangeCategoryType = e => {
    setLocalTestCategory({
      ...testCategory,
      selectedTestCategory: {
        ...testCategory.selectedTestCategory,
        selectedTestCategoryType: {
          ...testCategory.selectedTestCategory.selectedTestCategoryType,
          [e.target.name]: e.target.value
        }
      }
    });
  };

  const onSaveCategoryType = () => {
    if (testCategory && testCategory.selectedTestCategory.selectedTestCategoryType && testCategory.selectedTestCategory.testCategoryTypes) {
      let selectedCategoryType = testCategory.selectedTestCategory.selectedTestCategoryType;
      let testCategoryTypes = [...testCategory.selectedTestCategory.testCategoryTypes];

      let categoryFound = false;
      if (selectedCategoryType.GuidIdentifier) {
        testCategoryTypes = testCategoryTypes.map(type => {
          if (type.GuidIdentifier === selectedCategoryType.GuidIdentifier) {
            categoryFound = true;
            return selectedCategoryType;
          }
          return type;
        });
      }
      if (!categoryFound || !selectedCategoryType.GuidIdentifier) {
        selectedCategoryType.TestCategoryTypeId = 0;
        selectedCategoryType.GuidIdentifier = uuidv4();
        testCategoryTypes.push({ ...selectedCategoryType });
      }

      setCurrentTestCategoryTypeId(-1);
      setCurrentTestCategoryTypeIdentifier('');
      showAddEditScreen(false);
      dispatch(
        setTestCategoryChangeThunk({
          selectedTestCategory: {
            testCategoryTypes: testCategoryTypes
          }
        })
      );
    }
  };

  const onDeleteCategoryType = (testCategoryTypes, removedId) => {
    let removedIds = (testCategory.selectedTestCategory && testCategory.selectedTestCategory.removedTestCategoryTypeIds) || [];
    if (removedId) {
      removedIds.push(removedId);
    }

    dispatch(
      setTestCategoryChangeThunk({
        selectedTestCategory: {
          testCategoryTypes: testCategoryTypes,
          removedTestCategoryTypeIds: removedIds
        }
      })
    );
  };

  let messageId = (testCategory && testCategory.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const testCategoryName = (testCategory.selectedTestCategory ? testCategory.selectedTestCategory.Name : '') || '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + testCategoryName;
  } else {
    heading = intl.formatMessage({ id: 'testCategory.addNewTestCategory', defaultMessage: 'Add new Test Category' });
  }

  return (
    <div className={s.testcategory}>
      {testCategory.isLoading && <LoadingSpinner />}
      {testCategory.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={testCategory.showBanner}
        status={testCategory.isOpSuccessful}
        successText={messageText}
      />

      <OperationalSetupContentTemplate selectedPage="testResults" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.testcategoryHeader}>
            <Link className={s.backLink} to={backUrl}>
              &lt; &nbsp;
              <FormattedMessage id="testCategory.testResults" defaultMessage="TEST RESULTS" />
            </Link>
            <h3 data-unittest="headingLabel">{heading}</h3>
          </div>
          <div className={s.testcategoryContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTestCategoryName">
                    <Form.Label>
                      <FormattedMessage id="testCategory.categoryName" defaultMessage="Category Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'testCategory.enterName',
                        defaultMessage: 'Enter name'
                      })}
                      value={testCategory.selectedTestCategory.Name}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formTestCategoryDescription">
                    <Form.Label>
                      <FormattedMessage id="common.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'testCategory.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={testCategory.selectedTestCategory.Description}
                      className={`${s.textArea} ${localformErrors && localformErrors.Description ? s.formControlError : ''}`}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formActiveTestCategory">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsActive',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'testCategory.active',
                            defaultMessage: 'Test Category is active'
                          }),
                          isChecked: testCategory.selectedTestCategory.IsActive
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={s.testcategoryTypeContent}>
            <Form>
              <Row>
                <Col sm={12}>
                  <div>
                    <TestCategoryItemTable
                      selectedTestCategoryType={testCategory.selectedTestCategory.selectedTestCategoryType}
                      testCategoryTypes={testCategory.selectedTestCategory.testCategoryTypes}
                      setEditHandler={setEditHandler}
                      setAddHandler={setAddHandler}
                      setTestCategoryItemExistsHandler={setTestCategoryItemExistsHandler}
                      showAddEdit={showAddEdit}
                      disableMode={disableMode}
                      setDisableMode={setDisableMode}
                      selectedTestCategoryTypeId={currentTestCategoryTypeId}
                      selectedTestCategoryTypeIdentifier={currentTestCategoryTypeIdentifier}
                      onChangeCategoryType={onChangeCategoryType}
                      onDeleteCategoryType={onDeleteCategoryType}
                      onSaveCategoryType={onSaveCategoryType}
                      userProfileWidgets={userProfileWidgets}
                      updateAllowed={isUpdateAllowed}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <div>
            <Row className={s.breakRow}>
              <Col sm={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                  disabled={disableMode}
                >
                  <FormattedMessage id="common.save" defaultMessage="SAVE" />
                </Button>
                <Link to={backUrl}>
                  <Button variant="outline-secondary" className={s.btnCancel} disabled={disableMode}>
                    <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

export default injectIntl(TestCategory);
