import { initialInspectionReportState } from './initialState';

import {
  INSPECTION_REPORT_ERROR,
  GET_ALL_INSPECTION_REPORT_DETAILS,
  INSPECTION_REPORT_IS_LOADING,
  SET_INSPECTION_REPORT_CURRENT_PAGE,
  SET_INSPECTION_REPORT_PAGE_FILTER,
  SET_INSPECTION_REPORT_CHANGE,
  SET_INSPECTION_REPORT_CLEANUP,
  SET_INSPECTION_REPORT,
  SET_INSPECTION_REPORT_QUESTIONS,
  UPDATE_INSPECTION_REPORT_SAVE_STATUS,
  SET_INSPECTION_REPORT_QUESTIONS_CHANGE,
  SET_INSPECTION_REPORT_FILTERS,
  SET_INSPECTION_REPORT_RESULTS,
  REMOVE_INSPECTION_REPORT
} from '../../constants/index';

const inspectionReportReducer = (state = initialInspectionReportState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_INSPECTION_REPORT_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          ...data
        }
      };
    }

    case SET_INSPECTION_REPORT_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          currentPage
        }
      };
    }

    case SET_INSPECTION_REPORT_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_INSPECTION_REPORT_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          ...data
        }
      };
    }

    case REMOVE_INSPECTION_REPORT: {
      const { inspectionReportId } = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          showBanner: false,
          InspectionReports: [...state.inspectionReport.InspectionReports.filter(item => item.InspectionReportId !== inspectionReportId)],
          filteredResults: [...state.inspectionReport.filteredResults.filter(item => item.InspectionReportId !== inspectionReportId)],
          inspectionReportCount: state.inspectionReport.inspectionReportCount - 1
        }
      };
    }

    case INSPECTION_REPORT_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          isLoading: isLoading
        }
      };
    }

    case INSPECTION_REPORT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          ...data
        }
      };
    }

    case SET_INSPECTION_REPORT: {
      let { data, inspectionTypes, inspectionPurposes } = payload;

      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          isOpSuccessful: false,
          showBanner: false,
          InspectionTypes: inspectionTypes,
          InspectionPurposes: inspectionPurposes,
          SelectedInspectionReport: {
            ...state.inspectionReport.SelectedInspectionReport,
            ...data
          }
        }
      };
    }

    case SET_INSPECTION_REPORT_QUESTIONS: {
      let { data } = payload;

      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          isOpSuccessful: false,
          showBanner: false,
          SelectedInspectionReport: {
            ...state.inspectionReport.SelectedInspectionReport,
            ...data
          }
        }
      };
    }

    case SET_INSPECTION_REPORT_CHANGE: {
      let { SelectedInspectionReport } = payload;

      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          isOpSuccessful: false,
          showBanner: false,
          SelectedInspectionReport: {
            ...state.inspectionReport.SelectedInspectionReport,
            ...SelectedInspectionReport
          }
        }
      };
    }
    case SET_INSPECTION_REPORT_CLEANUP: {
      let SelectedInspectionReport = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          SelectedInspectionReport: SelectedInspectionReport
        }
      };
    }

    case SET_INSPECTION_REPORT_QUESTIONS_CHANGE: {
      let { assesmentQuestions, riskScore, riskRating } = payload;

      if (!riskScore & (riskScore !== 0)) {
        riskScore = state.inspectionReport.SelectedInspectionReport.RiskScore;
      }

      if (!riskRating) {
        riskRating = state.inspectionReport.SelectedInspectionReport.RiskRating;
      }

      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,

          SelectedInspectionReport: {
            ...state.inspectionReport.SelectedInspectionReport,
            RiskScore: riskScore,
            RiskRating: riskRating,
            AssesmentQuestions: assesmentQuestions
          }
        }
      };
    }
    case SET_INSPECTION_REPORT_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          ...data
        }
      };
    }

    case SET_INSPECTION_REPORT_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        inspectionReport: {
          ...state.inspectionReport,
          ...filteredResults
        }
      };
    }

    default:
      return state;
  }
};

export default inspectionReportReducer;
