import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './SiteDashboardControlsTabs.module.scss';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const Tab = ({ selectedPage, pageComponent, url, title, intl }) => (
  <Fragment>
    <Link className={classNames({ [s.active]: selectedPage === pageComponent })} to={url}>
      {title}
      {selectedPage === pageComponent && <span />}
    </Link>
  </Fragment>
);

const SiteDashboardControlsTabs = ({ selectedPage, userProfileWidgets, siteId, segmentName, intl }) => {
  let siteFormatMessage = intl.formatMessage({ id: 'siteControlsTabs.siteDashboard', defaultMessage: 'Site Dashboard' });
  let formattedSegmentName =
    (segmentName && intl.formatMessage({ id: `siteControlsTabs.${segmentName}`, defaultMessage: segmentName })) || '';
  siteFormatMessage = (segmentName && `${formattedSegmentName} ${siteFormatMessage}`) || siteFormatMessage;
  const SITE_DASHBOARD_TAB = [
    {
      id: 1,
      name: siteFormatMessage,
      url: '/dashboard/{siteId}/site-segments/{segmentName}',
      widgetName: 'WQSP_SITE_PATHWAY',
      pageComponent: 'siteDashboard'
    }
  ];

  const SITE_SEGMENTS_TAB = {
    build: [
      ...SITE_DASHBOARD_TAB,
      {
        id: 2,
        name: { id: 'siteControlsTabs.siteDetails', defaultMessage: 'Site Details' },
        url: '/sites/site/{siteId}/{segmentName}/2',
        widgetName: 'WQSP_SITES_UPDATE',
        pageComponent: '2'
      },
      {
        id: 3,
        name: { id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' },
        url: '/site/{siteId}/{segmentName}/3/safety-plans',
        widgetName: 'WQSP_SAFETY_PLAN_LIST',
        pageComponent: '3'
      }
    ],
    define: [
      ...SITE_DASHBOARD_TAB,
      {
        id: 2,
        name: { id: 'siteControlsTabs.waterSupplyDescriptionList', defaultMessage: 'List of Water Supply Descriptions' },
        url: '/site/{siteId}/{segmentName}/2/water-supply-descriptions',
        widgetName: 'WQSP_WATERSUPPLYDESCRIPTIONS_LIST',
        pageComponent: '2'
      },
      {
        id: 3,
        name: { id: 'siteControlsTabs.entityListing', defaultMessage: 'List of Entities' },
        url: '/site/{siteId}/{segmentName}/3/entities',
        widgetName: 'WQSP_ENTITIES_LIST',
        pageComponent: '3'
      },
      {
        id: 4,
        name: { id: 'siteControlsTabs.qualityGoalList', defaultMessage: 'List of Quality Goals' },
        url: '/site/{siteId}/{segmentName}/4/quality-goals',
        widgetName: 'WQSP_QUALITYGOALSREPORT_LIST',
        pageComponent: '4'
      }
    ],
    control: [
      ...SITE_DASHBOARD_TAB,
      {
        id: 2,
        name: { id: 'siteControlsTabs.listOfControlMeasures', defaultMessage: 'List of Control Measures' },
        url: '/site/{siteId}/{segmentName}/2/control-measures',
        widgetName: 'WQSP_CONTROL_MONITORINGS_LIST',
        pageComponent: '2'
      },
      {
        id: 3,
        name: { id: 'siteControlsTabs.listOfImprovementPlans', defaultMessage: 'List of Improvement Plans' },
        url: '/site/{siteId}/{segmentName}/3/improvement-plans',
        widgetName: 'WQSP_IMPROVEMENT_PLAN_LIST',
        pageComponent: '3'
      }
    ],
    identify: [
      ...SITE_DASHBOARD_TAB,
      {
        id: 2,
        name: { id: 'siteControlsTabs.listOfHazardEvents', defaultMessage: 'List of Hazard Events' },
        url: '/site/{siteId}/{segmentName}/2/hazard-events',
        widgetName: 'WQSP_HAZARDEVENT_LIST',
        pageComponent: '2'
      },
      {
        id: 3,
        name: { id: 'siteControlsTabs.listOfRiskAssessments', defaultMessage: 'List of Risk Assessments' },
        url: '/site/{siteId}/{segmentName}/3/risk-assessments',
        widgetName: 'WQSP_RISKASSESSMENT_LIST',
        pageComponent: '3'
      }
    ],
    plan: [...SITE_DASHBOARD_TAB],
    respond: [
      ...SITE_DASHBOARD_TAB,
      {
        id: 2,
        name: { id: 'siteControlsTabs.listOfIncidentReports', defaultMessage: 'List of Incident Reports' },
        url: '/site/{siteId}/{segmentName}/2/incident-reports',
        widgetName: 'WQSP_INCIDENT_REPORT_LIST',
        pageComponent: '2'
      },
      {
        id: 3,
        name: { id: 'siteControlsTabs.listOfEmergencyResponses', defaultMessage: 'List of Emergency Responses' },
        url: '/site/{siteId}/{segmentName}/3/emergency-response-plans',
        widgetName: 'WQSP_ERP_LIST',
        pageComponent: '3'
      },
      {
        id: 4,
        name: { id: 'siteControlsTabs.listOfCorrectiveActions', defaultMessage: 'List of Corrective Actions' },
        url: '/site/{siteId}/{segmentName}/4/corrective-actions',
        widgetName: 'WQSP_CORRECTIVE_ACTIONS_LIST',
        pageComponent: '4'
      }
    ],
    measure: [
      ...SITE_DASHBOARD_TAB,
      {
        id: 2,
        name: { id: 'siteControlsTabs.listOfInspections', defaultMessage: 'List of Inspections' },
        url: '/site/{siteId}/{segmentName}/2/inspections',
        widgetName: 'WQSP_HAZARDEVENT_LIST',
        pageComponent: '2'
      },
      {
        id: 3,
        name: { id: 'siteControlsTabs.listOfTestSamples', defaultMessage: 'List of Test Samples' },
        url: '/site/{siteId}/{segmentName}/3/test-results',
        widgetName: 'WQSP_RISKASSESSMENT_LIST',
        pageComponent: '3'
      }
    ],
    monitor: [
      ...SITE_DASHBOARD_TAB,
      {
        id: 2,
        name: { id: 'siteControlsTabs.listOfControlMonitors', defaultMessage: 'List of Control Monitors' },
        url: '/site/{siteId}/{segmentName}/2/control-monitorings',
        widgetName: 'WQSP_CONTROL_MONITORINGS_LIST',
        pageComponent: '2'
      }
    ]
  };

  return segmentName ? (
    <ul className={s.tabNav}>
      {(IsWidgetAccessible(userProfileWidgets, 'WQSP_SITE_PATHWAY') ||
        IsWidgetAccessible(userProfileWidgets, 'WQSP_SITE_ELEMENTS_MAP_VIEW')) && (
        <Fragment>
          {segmentName &&
            SITE_SEGMENTS_TAB[segmentName].map((tab, index) => {
              let tabName = tab.name;
              let tabUrl = tab.url.replace('{siteId}', siteId).replace('{segmentName}', segmentName);
              if (IsWidgetAccessible(userProfileWidgets, tab.widgetName)) {
                return (
                  <li key={index}>
                    <Tab
                      url={tabUrl}
                      title={(tabName.id && <FormattedMessage id={tabName.id} defaultMessage={tabName.defaultMessage} />) || tabName}
                      selectedPage={selectedPage}
                      pageComponent={tab.pageComponent}
                    />
                  </li>
                );
              }
            })}
        </Fragment>
      )}
    </ul>
  ) : (
    ''
  );
};

SiteDashboardControlsTabs.propTypes = {
  selectedPage: PropTypes.string.isRequired
};

export default injectIntl(SiteDashboardControlsTabs);
