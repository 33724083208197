import React from 'react';
import { SectionBuild } from './SectionBuild.jsx';
import { SectionDefine } from './SectionDefine.jsx';
import { SectionIdentify } from './SectionIdentify.jsx';
import { SectionControl } from './SectionControl.jsx';
import { SectionMonitor } from './SectionMonitor.jsx';
import { SectionMeasure } from './SectionMeasure.jsx';
import { SectionRespond } from './SectionRespond.jsx';
import moment from 'moment-timezone';

const SafetyPlanHtmlGenerator = ({ safetyPlanReportData, currentTimezone, generalData, intl }) => {
  let buildData = safetyPlanReportData.Build || null;
  let defineData = safetyPlanReportData.Define || null;
  let identifyData = safetyPlanReportData.Identify || null;
  let controlData = safetyPlanReportData.Control || null;
  let monitorData = safetyPlanReportData.Monitor || null;
  let measureData = safetyPlanReportData.Measure || null;
  let respondData = safetyPlanReportData.Respond || null;

  let siteName =
    (safetyPlanReportData.Build && safetyPlanReportData.Build.SiteDetails && safetyPlanReportData.Build.SiteDetails.Name) || '';
  let safetyPlanName =
    (safetyPlanReportData.Build && safetyPlanReportData.Build.SafetyPlan && safetyPlanReportData.Build.SafetyPlan.Name) || '';

  const getImages = images => {
    let elements = [];
    images.map((item, index) => {
      if (item.ImageId) {
        elements.push(<img key={index} className="uploadedImg" src={`${process.env.REACT_APP_FILEUPLOAD_URL}/${item.S3URL}`} />);
      }
    });
    return elements;
  };

  const getTranslatedText = id => {
    if (id) return intl.formatMessage({ id: id, defaultMessage: id });
    return null;
  };

  const getActionName = isActioned => {
    if (isActioned === undefined) {
      return 'N/A';
    } else if (isActioned) {
      return 'YES';
    }
    return 'NO';
  };

  const cssString = `body{font-family:Arial,Helvetica,sans-serif;width:1110px;margin:auto}.pageBreak{page-break-after:always}h2{padding-top:20px}.divLogo{padding-top:80px;text-align:center}.smallCenterText{padding-top:60px;width:100%;text-align:center;color:#000;font-size:12px}p{font-size:12px}.frontPage h1{text-align:center;padding-top:90px;padding-bottom:50px}.frontPage h2{text-align:center;padding-top:60px}.sectionContent{padding-top:20px;padding-bottom:100px}.level1{padding-left:0}.level2{padding-left:30px}.level3{padding-left:30px}.level1-table{width:100%}.level1-table td:nth-child(1){width:18%}.level1-table td:nth-child(2){width:82%;background-color:#c0cfd6}.level1-table,.level1-table td,.level1-table th{border-collapse:collapse;border:20px solid #fff;height:25px}table.level1-table td{width:100%}table.level2-table th{font-weight:400;font-size:11px}table.level2-table,table.level2-table td,table.level2-table th{border-collapse:collapse;border:1px solid #fff;height:25px;width:100%}.independent-table{width:100%;border-collapse:collapse;border:1px solid #000;height:25px}.independent-table th{background-color:#c0cfd6}.independent-table table,.independent-table td,.independent-table th,.independent-table tr{border-collapse:collapse;border:1px solid #000;height:25px}.uploadedImg{width:20%;height:200px;padding:10px}.fixedTable{table-layout:fixed;text-align:left;}`;
  return (
    <html>
      <head>
        <style>{cssString}</style>
      </head>
      <body>
        <div className="frontPage pageBreak">
          <div className="divLogo">
            <img src="https://s3.amazonaws.com/test.uploadedfiles.wqsp.watersourceaustralia.com.au/test/logo.jpeg" />
          </div>
          <h1>Water Quality Safety Plan</h1>
          <h2>{siteName}</h2>
          <h2>{safetyPlanName}</h2>
          <p className="smallCenterText">
            <b>Current as at: {moment.tz(currentTimezone).format('DD-MMM-YYYY')}</b>
          </p>
        </div>
        <SectionBuild sectionData={buildData} getImages={getImages} getActionName={getActionName} currentTimezone={currentTimezone} />
        <SectionDefine sectionData={defineData} getImages={getImages} getActionName={getActionName} currentTimezone={currentTimezone} />
        <SectionIdentify
          sectionData={identifyData}
          getImages={getImages}
          getActionName={getActionName}
          getTranslatedText={getTranslatedText}
          currentTimezone={currentTimezone}
          generalData={generalData}
        />
        <SectionControl sectionData={controlData} getImages={getImages} getActionName={getActionName} currentTimezone={currentTimezone} />
        <SectionMonitor
          sectionData={monitorData}
          getImages={getImages}
          getActionName={getActionName}
          currentTimezone={currentTimezone}
          intl={intl}
        />
        <SectionMeasure
          sectionData={measureData}
          getImages={getImages}
          getActionName={getActionName}
          currentTimezone={currentTimezone}
          intl={intl}
        />
        <SectionRespond sectionData={respondData} getImages={getImages} getActionName={getActionName} currentTimezone={currentTimezone} />
      </body>
    </html>
  );
};

export default SafetyPlanHtmlGenerator;
