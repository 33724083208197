import React from 'react';
import s from './WaterSupplyDescription.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { getDateInDDMMYYYFormat } from '../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../selectors/index';
import MoreOption from '../WSAControls/MoreOption/MoreOption';

const WaterSupplyDescriptionListTable = ({ paginatedData, onSortOrder, asendingOrder, ChangeWaterSupplyDescription, UpdateAllowed }) => {
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="waterSupplyDescription.suppliedWaterVolume" defaultMessage="Supplied Water Volume" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="waterSupplyDescription.communityManaged" defaultMessage="Community Managed" />
            </th>
            <th>
              <FormattedMessage id="waterSupplyDescription.waterFeesCollected" defaultMessage="Water Fees Collected" />
            </th>

            {UpdateAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.WaterSupplyDescriptionId}>
                <td>{n.Name || ''}</td>
                <td>{n.SuppliedWaterVolume || ''}</td>
                <td>{(n.CommunityManaged && yesText) || noText}</td>
                <td>{(n.WaterFeesCollected && yesText) || noText}</td>

                {UpdateAllowed && (
                  <td>
                    <Button
                      variant="outline-secondary"
                      className={s.changeButton}
                      href="#"
                      onClick={() => {
                        ChangeWaterSupplyDescription(n.WaterSupplyDescriptionId);
                      }}
                      data-unittest="changeData"
                    >
                      <FormattedMessage id="common.change" defaultMessage="CHANGE" />
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

WaterSupplyDescriptionListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(WaterSupplyDescriptionListTable);
