//Locale
export const LOCALE = [
  { en: 'English' }
  // { es: 'español' },
  // { de: 'Deutsche' },
  // { fr: 'Française' },
  // { da: 'dansk' }
];

//Translation Key
export const TRASLATION_KEYS = {
  en: ['Entities', 'Entity', 'Regulatory Register', 'Regulatory Registers'],
  es: ['Entidades', 'Entidad', 'Registro reglamentario', 'Registros regulatorios'],
  de: ['Entitäten', 'Entitäts', 'Regulierungsregister'],
  fr: ['Entités', 'Entité', 'Registre réglementaire', 'Registres réglementaires'],
  da: ['Enheder', 'Enhed', 'Lovregister']
};
