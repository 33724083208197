import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import s from './SitesList.module.scss';
import bs from '../../../styles/bootstrap-overrides.scss';
import SiteTile from '../SiteTile/SiteTile';
import MapView from '../MapView/MapView';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import collapseDown from '../../../assets/collapse-down.svg';
import collapseUp from '../../../assets/collapse-up.svg';
import tableBlue from '../../../assets/table-blue.svg';
import tableGrey from '../../../assets/table-grey.svg';
import mapGrey from '../../../assets/map-grey.svg';
import mapBlue from '../../../assets/map-blue.svg';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { IsWidgetAccessible } from '../../../utils/widgetManager';
import languageObject from '../../../translations/messages';
import blueDownArrow from '../../../assets/blue-down-arrow.svg';
import { getSite } from '../../../selectors/index';
import { setSelectedViewThunk } from '../../../store/actions/sites';
import { useSelector, useDispatch } from 'react-redux';
import { changeTimezone } from '../../../store/actions/timezone';
import { push } from 'connected-react-router';
import { getCurrentTimezone, getTranslation } from '../../../selectors/index';

const SitesList = ({ site, userProfileWidgets, intl }) => {
  let sitesObj = site.Sites;
  const dispatch = useDispatch();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  let activeView = useSelector(state => getSite(state)).selectedView;
  const locale = Cookies.get(`defaultLanguage-${envName}`) || 'en';

  const translations = useSelector(state => getTranslation(state)).translations || [];
  const langObject = { ...languageObject[locale], ...translations };

  const SITESTILEVIEW = 'SITESTILEVIEW';
  const SITESMAPVIEW = 'SITESMAPVIEW';
  const UNITSCOUNTFORMAPVIEW = 10;

  const [sortOrder, setSortOrder] = useState('name');
  const [sortSelection, setSortSelection] = useState(false);
  const [isUserSelectedView, setIsUserSelectedView] = useState(false);

  let isTileViewAvailable = IsWidgetAccessible(userProfileWidgets, 'WQSP_MYSITESTILEVIEW');
  let isMapViewAvailable = IsWidgetAccessible(userProfileWidgets, 'WQSP_MYSITESMAPVIEW');

  let selectedView = activeView ? activeView : sitesObj.length >= UNITSCOUNTFORMAPVIEW ? SITESMAPVIEW : SITESTILEVIEW;

  if (selectedView !== activeView && !isUserSelectedView) {
    dispatch(setSelectedViewThunk(selectedView));
  }

  //reset the timezone back to the selected timezone in account settings or local timezone
  let defaultTimezone = Cookies.get(`defaultTimezone-${envName}`);
  if (defaultTimezone === undefined) {
    defaultTimezone = moment.tz.guess();
  }

  const handleClick = siteId => {
    changeTimezone(defaultTimezone);
    dispatch(push(`/dashboard/${siteId}/site-segments`));
  };

  const [sitesOpen, setSitesOpen] = useState(true);

  const handleTabClick = tab => {
    dispatch(setSelectedViewThunk(tab));
    setIsUserSelectedView(true);
  };

  const onSortItemChange = item => {
    setSortOrder(item.Value);
    setSortSelection(false);
  };

  const setActiveView = viewName => {
    dispatch(setSelectedViewThunk(viewName));
  };

  const sortSites = sites => {
    let retData = [];
    switch (sortOrder) {
      case 'name': {
        retData = sites.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        break;
      }
      default: {
      }
    }
    return retData;
  };

  return (
    <IntlProvider locale={locale} messages={langObject}>
      <div>
        {site.isLoading && <LoadingSpinner />}

        <Row className={s.titleRows}>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={sitesOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setSitesOpen(!sitesOpen)}
                aria-controls="sites-section"
                aria-expanded={sitesOpen}
              ></img>
              <h3>
                <FormattedMessage id="siteList.mySites" defaultMessage="My Sites" />
              </h3>
              <span className={s.circle}>{sitesObj.length}</span>
              {/* UNCOMMENT TO IMPLEMENT TILE SORTING */}
              {/* {activeView === SITESTILEVIEW ? (
                <Row className={s.sortOption}>
                  <h4 className={s.subHeading} onClick={() => setSortSelection(sortSelection ? false : true)}>
                    {sortOrder === 'name' ? (
                      <FormattedMessage id="siteList.sortByName" defaultMessage="Sort by: Name" />
                    ) : (
                      <FormattedMessage id="siteList.sortByStatus" defaultMessage="Sort by: Status" />
                    )}
                  </h4>
                  <img src={blueDownArrow} onClick={() => setSortSelection(sortSelection ? false : true)}></img>
                </Row>
              ) : (
                ''
              )} */}
            </Row>
            {sortSelection && (
              <Row>
                <Col>
                  <SortOptions
                    onSortItemChange={onSortItemChange}
                    intl={intl}
                    setSortSelection={setSortSelection}
                    sortItems={[
                      { Name: 'Name', Value: 'name' }
                      // { Name: 'Status', Value: 'status' }
                    ]}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col md={6} className={sitesOpen ? 'd-none d-md-block' : 'd-none'}>
            {isTileViewAvailable && isMapViewAvailable ? (
              <div className="float-right row">
                <div
                  className={`mr-2 ${s.link}`}
                  onClick={() => {
                    handleTabClick(SITESTILEVIEW);
                  }}
                >
                  <img src={activeView === SITESTILEVIEW ? tableBlue : tableGrey} alt="Table view"></img>
                  <span className={activeView === SITESTILEVIEW ? s.active : s.nonActive}>
                    <FormattedMessage id="siteList.tileView" defaultMessage="Tile view" />
                  </span>
                </div>
                <div className={s.pipeSeparator}></div>
                <div
                  className={`ml-2 ${s.link}`}
                  onClick={() => {
                    handleTabClick(SITESMAPVIEW);
                  }}
                >
                  <img src={activeView === SITESTILEVIEW ? mapGrey : mapBlue} alt="Map view"></img>
                  <span className={activeView === SITESTILEVIEW ? s.nonActive : s.active}>
                    <FormattedMessage id="siteList.mapView" defaultMessage="Map view" />
                  </span>
                </div>
              </div>
            ) : (
              <div />
            )}
          </Col>
        </Row>
        <Collapse in={sitesOpen}>
          <div id="sites-section">
            {/* MOBILE VIEW START */}
            {isTileViewAvailable && isMapViewAvailable && (
              <div>
                <Row className={s.hideDesktop}>
                  <Col xs={6} className={s.viewMap}></Col>
                  <Col xs={6} className={s.viewMap}>
                    {activeView !== SITESTILEVIEW && (
                      <Button
                        className={s.mapButton}
                        variant="outline-secondary"
                        onClick={() => {
                          handleTabClick(SITESTILEVIEW);
                        }}
                      >
                        <FormattedMessage id="siteList.viewOnTile" defaultMessage="TILE VIEW" />
                      </Button>
                    )}
                    {activeView === SITESTILEVIEW && (
                      <Button
                        className={s.mapButton}
                        variant="outline-secondary"
                        onClick={() => {
                          handleTabClick(SITESMAPVIEW);
                        }}
                      >
                        <FormattedMessage id="siteList.viewOnMap" defaultMessage="VIEW ON MAP" />
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className={s.hideDesktop}>
                  <Col className={s.horizontalLine}>
                    <hr />
                  </Col>
                </Row>
              </div>
            )}
            {/* MOBILE VIEW END */}

            {activeView === SITESTILEVIEW ? (
              <ul className={s.siteWrapper}>
                {sitesObj.length > 0 &&
                  sortSites(sitesObj).map(({ SiteId, Location, Name, LastReviewDate, NextReviewDate }) => (
                    <SiteTile
                      key={SiteId}
                      siteId={SiteId}
                      handleClick={handleClick}
                      lastReviewDate={LastReviewDate}
                      nextReviewDate={NextReviewDate}
                      siteName={Name}
                      location={Location}
                      intl={intl}
                      currentTimezone={currentTimezone}
                      languageObject={langObject}
                    />
                  ))}
              </ul>
            ) : (
              <span />
            )}

            {activeView !== SITESTILEVIEW ? (
              <div className={s.siteWrapper}>
                {sitesObj.length > 0 && (
                  <MapView
                    sites={sitesObj}
                    handleClick={handleClick}
                    intl={intl}
                    currentTimezone={currentTimezone}
                    languageObject={langObject}
                  />
                )}
              </div>
            ) : (
              <div />
            )}
          </div>
        </Collapse>
      </div>
    </IntlProvider>
  );
};

const SortOptions = ({ intl, sortItems, onSortItemChange, setSortSelection }) => {
  const clickRef = useRef();

  const handleClickOutside = e => {
    if (!clickRef.current.contains(e.target)) {
      setSortSelection(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  return (
    <div className={s.options} ref={clickRef}>
      {sortItems.map((elem, index) => {
        return (
          <Row
            onClick={() => {
              onSortItemChange(elem);
            }}
            key={index}
            className={[s.rows, index === 0 ? s.noBorder : '']}
          >
            <Col>{elem.Name}</Col>
          </Row>
        );
      })}
    </div>
  );
};

SitesList.defaultProps = {
  site: {},
  userProfileWidgets: {}
};

SitesList.propTypes = {
  site: PropTypes.object,
  userProfileWidgets: PropTypes.object
};

export default injectIntl(SitesList);
