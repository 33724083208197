import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './EmergencyPlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import EmergencyPlanListTable from './EmergencyPlanListTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE, FREQUENCY } from '../../../constants';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils/';
import { getUserProfileWidget, getEmergencyResponsePlan, getGeneralData } from '../../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from '../../SearchBar/SearchBar';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';
import {
  getEmergencyResponsePlanThunk,
  getFilterThunk,
  setFilteredDataThunk,
  saveEmergencyResponsePlanThunk,
  setCurrentPage,
  setPageFilter
} from '../../../store/actions/safetyPlan/emergencyResponsePlan';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';

const WATERSUPPLY_DESCRIPTION = 'WATERSUPPLY_DESCRIPTION';
const ENTITY_TYPE = 'ENTITY_TYPE';
const ENTITY = 'ENTITY';
const SEARCH_FILTER = 'SEARCH_FILTER';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const EmergencyPlanList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { siteId, segmentName, selectedTab } = useParams();
  siteId = (siteId && parseInt(siteId)) || '0';

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const emergencyPlan = useSelector(state => getEmergencyResponsePlan(state));
  const generalData = useSelector(state => getGeneralData(state));
  const [searchFilter, setSearchFilter] = useState('');
  const [filterWaterSupplyDescriptionId, setFilterWaterSupplyDescriptionId] = useState(0);
  const [filterEntityTypeId, setFilterEntityTypeId] = useState(0);
  const [entities, setEntities] = useState([]);
  const [filterEntityId, setFilterEntityId] = useState(0);
  const [asendingOrder, setAsendingOrder] = useState(true);
  const [active, setActive] = useState(false);
  const [emergencyPlanId, setEmergencyPlanId] = useState(0);

  let offset = emergencyPlan.offset || 0;
  let limit = emergencyPlan.limit || 1000;
  let filter = emergencyPlan.filterBy.filter || null;

  let filterLocalWaterSupplyDescriptionId = emergencyPlan.filterBy.filterWaterSupplyDescriptionId || null;
  let filterLocalEntityTypeId = emergencyPlan.filterBy.filterEntityTypeId || null;
  let filterLocalEntityId = emergencyPlan.filterBy.filterEntityId || null;

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_ERP_LIST', siteId));
  }, [dispatch, getFilterThunk, siteId]);

  useEffect(() => {
    let filterObj = {
      filterWaterSupplyDescriptionId: filterLocalWaterSupplyDescriptionId,
      filterEntityTypeId: filterLocalEntityTypeId,
      filterEntityId: filterLocalEntityId,
      filter: filter,
      offset: offset,
      limit: limit
    };
    dispatch(getEmergencyResponsePlanThunk('WQSP_ERP_LIST', filterObj, siteId));
  }, [getEmergencyResponsePlanThunk, filter, siteId, filterLocalWaterSupplyDescriptionId, filterLocalEntityTypeId, filterLocalEntityId]);

  let disableControls = emergencyPlan.isLoading || false;
  let messageId = (emergencyPlan && emergencyPlan.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let viewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ERP_VIEW');
  const deleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_ERP_DELETE');

  const waterSupplyDescriptionOptions = () => {
    let waterSupplyDescriptionData = [];

    generalData.waterSupplyDescriptions.forEach(element => {
      if (element.SiteId === siteId) {
        waterSupplyDescriptionData.push({
          label: element.Name,
          value: element.WaterSupplyDescriptionId
        });
      }
    });

    let sortedData = waterSupplyDescriptionData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allWaterSupplies" defaultMessage="All Water Supplies" />,
      value: 0
    });
    return sortedData;
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];

    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
      value: 0
    });
    return sortedData;
  };

  const entityOptions = () => {
    let entityData = [];

    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntities" defaultMessage="All Entities" />,
      value: 0
    });
    return sortedData;
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onWaterSupplyDescriptionChange = e => {
    setFilterWaterSupplyDescriptionId(e.value);
    setFilterEntityTypeId(0);
    setFilterEntityId(0);
    filteredResults(WATERSUPPLY_DESCRIPTION, e.value);
    setCurrentPage(1);
  };

  const onEntityTypeChange = e => {
    setFilterEntityTypeId(e.value);
    setFilterEntityId(0);
    filteredResults(ENTITY_TYPE, e.value);
    let locFilteredOption = generalData.entities.filter(item => item.EntityTypeId === e.value);
    if (filterWaterSupplyDescriptionId) {
      locFilteredOption = locFilteredOption.filter(item => item.WaterSupplyDescriptionId === filterWaterSupplyDescriptionId);
    }
    setEntities(locFilteredOption);
    setCurrentPage(1);
  };

  const onEntityChange = e => {
    setFilterEntityId(e.value);
    filteredResults(ENTITY, e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = emergencyPlan.emergencyResponsePlans;

    let apifilterObj = {
      filter: null,
      filterWaterSupplyDescriptionId: null,
      filterEntityTypeId: null,
      filterEntityId: null
    };

    if (filterType === WATERSUPPLY_DESCRIPTION) {
      if (value !== 0) {
        apifilterObj.filterWaterSupplyDescriptionId = value;
        filteredList = filteredList.filter(function(item) {
          return item.WaterSupplyDescriptionId === value;
        });
      }
    } else {
      if (filterWaterSupplyDescriptionId !== 0) {
        apifilterObj.filterWaterSupplyDescriptionId = filterWaterSupplyDescriptionId;
        filteredList = filteredList.filter(function(item) {
          return item.WaterSupplyDescriptionId === filterWaterSupplyDescriptionId;
        });
      }
    }

    if (filterType === ENTITY_TYPE) {
      if (value !== 0) {
        apifilterObj.filterEntityTypeId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === value;
        });
      }
    } else {
      if (filterEntityTypeId !== 0) {
        apifilterObj.filterEntityTypeId = filterEntityTypeId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityTypeId;
        });
      }
    }

    if (filterType === ENTITY) {
      if (value !== 0) {
        apifilterObj.filterEntityId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === value;
        });
      }
    } else {
      if (filterEntityId !== 0 && filterType !== ENTITY_TYPE) {
        apifilterObj.filterEntityId = filterEntityId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === filterEntityId;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (emergencyPlan.emergencyResponsePlanListCount > emergencyPlan.emergencyResponsePlans.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let emergencyPlanObj = Object.values(emergencyPlan.filteredResults);

  if (asendingOrder) {
    emergencyPlanObj = emergencyPlanObj.sort(compareValues('NextReviewDate'));
  } else {
    emergencyPlanObj = emergencyPlanObj.sort(compareValues('NextReviewDate', 'desc'));
  }

  const onAddNewClick = () => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/emergency-response-plans/emergency-response-plan`);
  };

  const onViewEmergencyPlanClick = emergencyPlanId => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/emergency-response-plans/emergency-response-plan-view/${emergencyPlanId}`);
  };

  const onEmergencyPlanRemoveClick = emergencyPlanId => {
    setActive(true);
    setEmergencyPlanId(emergencyPlanId);
  };

  const handleClose = () => {
    setActive(false);
    setEmergencyPlanId(0);
  };

  const handleConfirm = () => {
    deleteEmergencyPlan();
    handleClose();
  };

  const deleteEmergencyPlan = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.EmergencyResponsePlanId = emergencyPlanId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveEmergencyResponsePlanThunk(saveData, 'WQSP_ERP_DELETE'));
  };

  const dialogTitle = intl.formatMessage({
    id: 'emergencyPlan.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this Emergency Response Plan?'
  });

  return (
    <div className={s.emergencyPlanList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />

        <div className={s.contentWrapper}>
          {emergencyPlan.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={emergencyPlan.showBanner}
            status={emergencyPlan.isOpSuccessful}
            successText={messageText}
          />
          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="common.search"
              />
            </Col>
            <Col sm={6} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_ERP_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addEmergencyPlan"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                {' '}
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpWSD"
                dataArray={waterSupplyDescriptionOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onWaterSupplyDescriptionChange(e)}
                selectedOption={waterSupplyDescriptionOptions().filter(option => option.value === filterWaterSupplyDescriptionId)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpModelId"
                dataArray={entityTypeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onEntityTypeChange(e)}
                selectedOption={entityTypeOptions().filter(option => option.value === filterEntityTypeId)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpEntityId"
                dataArray={entityOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onEntityChange(e)}
                selectedOption={entityOptions().filter(option => option.value === filterEntityId)}
                disabled={disableControls}
              />
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={emergencyPlanObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={emergencyPlan.currentPage}
            >
              <EmergencyPlanListTable
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                viewEmergencyPlan={onViewEmergencyPlanClick}
                viewAllowed={viewAllowed}
                deleteAllowed={deleteAllowed}
                removeEmergencyPlan={onEmergencyPlanRemoveClick}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(EmergencyPlanList);
