import { initialTestResultState } from './initialState';

import {
  TEST_RESULT_ERROR,
  GET_ALL_TEST_RESULT_DETAILS,
  TEST_RESULT_IS_LOADING,
  SET_TEST_RESULT_CURRENT_PAGE,
  SET_TEST_RESULT_PAGE_FILTER,
  SET_TEST_RESULT_CHANGE,
  SET_TEST_RESULT,
  UPDATE_TEST_RESULT_SAVE_STATUS,
  SET_TEST_RESULT_FILTERS,
  SET_TEST_RESULT_RESULTS,
  REMOVE_TEST_RESULT
} from '../../constants/index';

const testResultReducer = (state = initialTestResultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_TEST_RESULT_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        testResult: {
          ...state.testResult,
          ...data
        }
      };
    }

    case SET_TEST_RESULT_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        testResult: {
          ...state.testResult,
          currentPage
        }
      };
    }

    case SET_TEST_RESULT_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        testResult: {
          ...state.testResult,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_TEST_RESULT_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        testResult: {
          ...state.testResult,
          ...data
        }
      };
    }

    case TEST_RESULT_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        testResult: {
          ...state.testResult,
          isLoading: isLoading
        }
      };
    }

    case TEST_RESULT_ERROR: {
      const { data } = payload;
      return {
        ...state,
        testResult: {
          ...state.testResult,
          ...data
        }
      };
    }

    case SET_TEST_RESULT: {
      let { selectedTestResult } = payload;

      return {
        ...state,
        testResult: {
          ...state.testResult,
          isOpSuccessful: false,
          showBanner: false,
          selectedTestResult: {
            ...state.testResult.selectedTestResult,
            ...selectedTestResult
          }
        }
      };
    }

    case REMOVE_TEST_RESULT: {
      const { testResultReportId } = payload;
      return {
        ...state,
        testResult: {
          ...state.testResult,
          showBanner: false,
          testResults: [...state.testResult.testResults.filter(item => item.TestResultReportId !== testResultReportId)],
          filteredResults: [...state.testResult.filteredResults.filter(item => item.TestResultReportId !== testResultReportId)],
          testResultListCount: state.testResult.testResultListCount - 1
        }
      };
    }

    case SET_TEST_RESULT_CHANGE: {
      let { selectedTestResult } = payload;

      return {
        ...state,
        testResult: {
          ...state.testResult,
          isOpSuccessful: false,
          showBanner: false,
          isRedirect: false,
          selectedTestResult: {
            ...state.testResult.selectedTestResult,
            ...selectedTestResult
          }
        }
      };
    }

    case SET_TEST_RESULT_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        testResult: {
          ...state.testResult,
          ...filteredResults
        }
      };
    }

    case SET_TEST_RESULT_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        testResult: {
          ...state.testResult,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default testResultReducer;
