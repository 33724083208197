import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './StakeholderRegister.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import StakeholderRegisterListTable from './StakeholderRegisterListTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import { useParams, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getUserProfileWidget, getStakeholderRegister, getSelectedSafetyPlan } from '../../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import SearchBar from '../../SearchBar/SearchBar';
import Cookies from 'js-cookie';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import { describeSafetyPlanThunk } from '../../../store/actions/safetyPlan/safetyPlan';

import {
  getStakeholderRegisterThunk,
  setFilteredDataThunk,
  setCurrentPage,
  saveStakeholderRegisterThunk,
  setPageFilter,
  getFilterThunk
} from '../../../store/actions/safetyPlan/stakeholderRegister';
import Breadcrumb from '../../WSAControls/Breadcrumb';

const SEARCH_FILTER = 'SEARCH_FILTER';
const SEARCH_FILTER_TYPE_NAME = 'SEARCH_FILTER_TYPE_NAME';
const SEARCH_FILTER_TYPE_COMPANY = 'SEARCH_FILTER_TYPE_COMPANY';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const StakeholderRegisterList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const stakeholderRegister = useSelector(state => getStakeholderRegister(state));

  let { safetyPlanId, siteId, segmentName, selectedTab } = useParams();
  let disableControls = stakeholderRegister.isLoading || false;

  segmentName = segmentName || 'build';
  selectedTab = selectedTab || '2';

  const [searchFilter, setSearchFilter] = useState('');
  const [searchfilterType, setSearchFilterType] = useState(SEARCH_FILTER_TYPE_NAME);
  const [stakeholderRegisterId, setStakeholderRegisterId] = useState(0);
  const [active, setActive] = useState(false);

  let offset = stakeholderRegister.offset || 0;
  let limit = stakeholderRegister.limit || 1000;
  let filterLocalName = stakeholderRegister.filterBy.filterName || null;
  let filterLocalCompany = stakeholderRegister.filterBy.filterCompany || null;

  useEffect(() => {
    dispatch(describeSafetyPlanThunk(safetyPlanId, 'WQSP_SAFETY_PLAN_UPDATE'));
  }, [safetyPlanId]);

  const selectedSafetyPlan = useSelector(state => getSelectedSafetyPlan(state));
  const safetyPlanName = (selectedSafetyPlan.Name && ` (${selectedSafetyPlan.Name})`) || '';

  useEffect(() => {
    let filterObj = {
      filterName: filterLocalName,
      filterCompany: filterLocalCompany,
      waterQualitySafetyPlanId: safetyPlanId,
      offset: offset,
      limit: limit
    };
    dispatch(getStakeholderRegisterThunk('WQSP_STAKEHOLDER_REGISTER_LIST', filterObj, safetyPlanId));
  }, [getStakeholderRegisterThunk, filterLocalCompany, filterLocalName, safetyPlanId]);

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_STAKEHOLDER_REGISTER_LIST', safetyPlanId));
  }, [getFilterThunk, safetyPlanId]);

  let messageId = (stakeholderRegister && stakeholderRegister.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onTypeChange = e => {
    setSearchFilterType(e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onRemoveStakeholderRegisterClick = stakeholderRegisterId => {
    setActive(true);
    setStakeholderRegisterId(stakeholderRegisterId);
  };

  const DeleteStakeholderRegister = () => {
    let saveData = stakeholderRegister.selectedStakeholderRegister;
    saveData.PerformDelete = true;
    saveData.StakeholderRegisterId = stakeholderRegisterId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveStakeholderRegisterThunk(saveData, 'WQSP_STAKEHOLDER_REGISTER_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setStakeholderRegisterId(0);
  };

  const handleConfirm = () => {
    DeleteStakeholderRegister();
    handleClose();
  };

  let typePlaceHolder = intl.formatMessage({ id: 'common.name', defaultMessage: 'Name' });

  const typeOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="common.name" defaultMessage="Name" />,
      value: SEARCH_FILTER_TYPE_NAME
    });

    statusData.push({
      label: <FormattedMessage id="personnel.company" defaultMessage="Company" />,
      value: SEARCH_FILTER_TYPE_COMPANY
    });

    return statusData;
  };

  const filteredResults = (filterType, value) => {
    let filteredList = stakeholderRegister.stakeholderRegisters;

    let apifilterObj = {
      filterName: null,
      filterCompany: null,
      filterRole: null
    };

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_TYPE_COMPANY) {
      if (value !== '') {
        apifilterObj.filterCompany = value;
        filteredList = filteredList.filter(function(item) {
          return item.Company.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_TYPE_COMPANY) {
        apifilterObj.filterCompany = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Company.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (filterType === SEARCH_FILTER && searchfilterType === SEARCH_FILTER_TYPE_NAME) {
      if (value !== '') {
        apifilterObj.filterName = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '' && searchfilterType === SEARCH_FILTER_TYPE_NAME) {
        apifilterObj.filterName = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (stakeholderRegister.stakeholderRegisterListCount > stakeholderRegister.stakeholderRegisters.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  const getPlaceHolder = searchfilterType => {
    if (searchfilterType === SEARCH_FILTER_TYPE_NAME) {
      return 'personnel.searchLabelName';
    } else {
      return 'personnel.searchLabelCompany';
    }
  };

  let stakeholderRegisterObj = Object.values(stakeholderRegister.filteredResults);

  const onAddNewClick = () => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/stakeholders/stakeholder`);
  };

  const onChangeStakeholderRegisterClick = stakeholderRegisterId => {
    history.push(
      `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/stakeholders/stakeholder/${stakeholderRegisterId}`
    );
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_STAKEHOLDER_REGISTER_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_STAKEHOLDER_REGISTER_DELETE');
  //const isUpdateAllowed = true;

  const dialogTitle = intl.formatMessage({
    id: 'stakeholderRegister.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this stakeholder?'
  });

  let heading = intl.formatMessage({ id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' });
  heading += `${safetyPlanName}`;

  const breadcrumb = [
    {
      orderNo: 1,
      name: heading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="siteControlsTabs.stakeholders" defaultMessage="Stakeholders" />,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  return (
    <div className={s.stakeholderRegisterList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.contentWrapper}>
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
          <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
          {stakeholderRegister.isLoading && <LoadingSpinner />}
          {stakeholderRegister.showBanner && (
            <Banner
              failureText={messageText}
              showBanner={stakeholderRegister.showBanner}
              status={stakeholderRegister.isOpSuccessful}
              successText={messageText}
            />
          )}
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={6}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId={getPlaceHolder(searchfilterType)}
                id="txtSearchBar"
                showFilterType
                searchfilterType={searchfilterType}
                onFilterTypeChange={onTypeChange}
                filterTypeData={typeOptions()}
                filterPlaceholderText={typePlaceHolder}
              />
            </Col>
            <Col sm={6} lg={6}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_STAKEHOLDER_REGISTER_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addStakeholderRegisterButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={stakeholderRegisterObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={stakeholderRegister.currentPage}
            >
              <StakeholderRegisterListTable
                ChangeStakeholderRegister={onChangeStakeholderRegisterClick}
                RemoveStakeholderRegister={onRemoveStakeholderRegisterClick}
                UpdateAllowed={isUpdateAllowed}
                DeleteAllowed={isDeleteAllowed}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(StakeholderRegisterList);
