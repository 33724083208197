import Cookies from 'js-cookie';
import { LANGUAGE_TYPES, DOMAIN_NAME, IS_LOCALHOST } from '../constants/index';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export default source => {
  //check if the defaultLanguage cookie exists
  const defaultLanguage = Cookies.get(`defaultLanguage-${envName}`);

  if (defaultLanguage !== undefined && defaultLanguage.length > 0) {
    return defaultLanguage;
  } else {
    // Attempt to obtain language from browser navigator
    const language = (source.languages && source.languages[0]) || source.language || source.userLanguage;

    // Split locales with a region code
    const locale = language.toLowerCase().split(/[_-]+/)[0];

    //check if locale exists in the supported language types
    const localeExists = Object.keys(LANGUAGE_TYPES).some(key => key === locale);

    const defaultLocale = localeExists ? locale : 'en';
    Cookies.set(`defaultLanguage-${envName}`, defaultLocale, { domain: DOMAIN_NAME, secure: IS_LOCALHOST ? false : true });
    return defaultLocale;
  }
};
