import React, { useState, useEffect } from 'react';
import s from './RiskAssessment.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialRiskAssessmentState, initialRiskCategoryState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import classNames from 'classnames';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import uuid from 'uuid';
import RiskCategoryMatrixView from './RiskCategoryMatrixView';
import { RISK_CERTAINTY, RISK_RESPONSE, RISK_STATUS, FREQUENCY } from '../../constants/index';
import { getRiskCategoryThunk, describeRiskCategoryThunk, setRiskCategoryChangeThunk } from '../../store/actions/riskCategory';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import moment from 'moment-timezone';
import {
  getRiskAssessment,
  getHazardEvent,
  getGeneralData,
  getRiskCategory,
  getCurrentTimezone,
  getUserProfileWidget
} from '../../selectors/index';
import { describeRiskAssessmentThunk, saveRiskAssessmentThunk, setRiskAssessmentChangeThunk } from '../../store/actions/riskAssessment';
import { getHazardEventThunk } from '../../store/actions/hazardEvent/hazardEvent';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';
import GoBack from '../WSAControls/GoBack/GoBack';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const RiskAssessment = ({ intl }) => {
  let history = useHistory();
  let { riskAssessmentId, siteId, entityId, segmentName, selectedTab, hazardEventId } = useParams();
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  const dispatch = useDispatch();
  const riskAssessment = useSelector(state => getRiskAssessment(state));
  const hazardEvent = useSelector(state => getHazardEvent(state));
  const riskCategory = useSelector(state => getRiskCategory(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const generalData = useSelector(state => getGeneralData(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));

  let IsEdit = false;
  let isLoading = generalData.isLoading || riskAssessment.isLoading || hazardEvent.isLoading || riskCategory.isLoading;

  const [localformErrors, setFormErrors] = useState({});

  let widgetCode = 'WQSP_RISKASSESSMENT_ADDNEW';

  if (riskAssessmentId) {
    IsEdit = true;
    widgetCode = 'WQSP_RISKASSESSMENT_EDIT';
  }

  const riskCertainties = generalData.generalFieldTypes.filter(item => item.FieldType === RISK_CERTAINTY);
  const riskResponses = generalData.generalFieldTypes.filter(item => item.FieldType === RISK_RESPONSE);
  const riskStatuses = generalData.generalFieldTypes.filter(item => item.FieldType === RISK_STATUS);
  let disableEntity = false;
  let disableSite = false;
  let disableHazardEvent = false;

  if (siteId) {
    disableSite = true;
  }

  if (hazardEventId) {
    disableHazardEvent = true;
  }

  let waterSupplyId = null;
  let entityTypeId = null;
  let hazardEventName = riskAssessment.selectedRiskAssessment.HazardEventName;

  if (hazardEventId) {
    let selectedItem = hazardEvent.hazardEvents.find(x => x.HazardEventId == hazardEventId) || {};
    if (Object.keys(selectedItem).length > 0) {
      waterSupplyId = selectedItem.WaterSupplyDescriptionId || null;
      entityTypeId = selectedItem.EntityTypeId || null;
      entityId = selectedItem.EntityId || null;
      disableEntity = true;
      hazardEventName = selectedItem.Name;
    }
  }

  let ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  useEffect(() => {
    dispatch(getHazardEventThunk(widgetCode, {}));
  }, [getHazardEventThunk, dispatch]);

  useEffect(() => {
    dispatch(getRiskCategoryThunk(widgetCode));
  }, [getRiskCategoryThunk, dispatch]);

  useEffect(() => {
    if (riskAssessment.selectedRiskAssessment.RiskCategoryId) {
      dispatch(describeRiskCategoryThunk(riskAssessment.selectedRiskAssessment.RiskCategoryId, widgetCode));
    } else {
      dispatch(
        setRiskCategoryChangeThunk({
          ...initialRiskCategoryState.riskCategory
        })
      );
    }
  }, [describeRiskCategoryThunk, riskAssessment.selectedRiskAssessment.RiskCategoryId]);

  useEffect(() => {
    if (riskAssessmentId) {
      dispatch(describeRiskAssessmentThunk(riskAssessmentId, widgetCode));
    }
  }, [describeRiskAssessmentThunk, dispatch, riskAssessmentId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setRiskAssessmentChangeThunk({
          ...initialRiskAssessmentState.riskAssessment
        })
      );
    };
  }, []);

  useEffect(() => {
    if (hazardEventId) {
      setLocalRiskAssessment({
        ...riskAssessment,
        selectedRiskAssessment: { ...riskAssessment.selectedRiskAssessment, HazardEventId: parseInt(hazardEventId) }
      });
    }
  }, [hazardEventId]);

  useEffect(() => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...hazardEvent.selectedRiskAssessment,
        SiteId: (siteId && parseInt(siteId)) || 0,
        WaterSupplyDescriptionId: (waterSupplyId && parseInt(waterSupplyId)) || 0,
        EntityTypeId: (entityTypeId && parseInt(entityTypeId)) || 0,
        EntityId: (entityId && parseInt(entityId)) || 0,
        HazardEventId: (hazardEventId && parseInt(hazardEventId)) || 0
      }
    });
  }, [siteId, entityId, hazardEventId, waterSupplyId, entityTypeId]);

  // Update redux store
  const setLocalRiskAssessment = currentState => {
    dispatch(setRiskAssessmentChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = riskAssessment.selectedRiskAssessment;
    saveData.PerformDelete = false;
    saveData.Entities = riskAssessment.selectedRiskAssessment.Entities.map(item => item.EntityId);
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.RiskAssessmentId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_RISKASSESSMENT_ADDNEW';
    }

    dispatch(saveRiskAssessmentThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!riskAssessment.selectedRiskAssessment || !riskAssessment.selectedRiskAssessment.SiteId) {
      formErrors.Site = intl.formatMessage({
        id: 'wqsp.common.siteMandatory',
        defaultMessage: 'Site name is mandatory field'
      });
      isValid = false;
    }

    if (!riskAssessment.selectedRiskAssessment || !riskAssessment.selectedRiskAssessment.WaterSupplyDescriptionId) {
      formErrors.WaterSupply = intl.formatMessage({
        id: 'wqsp.common.waterSupplyMandatory',
        defaultMessage: 'Water supply name is mandatory field'
      });
      isValid = false;
    }

    if (!riskAssessment.selectedRiskAssessment || !riskAssessment.selectedRiskAssessment.EntityTypeId) {
      formErrors.EntityType = intl.formatMessage({
        id: 'wqsp.common.entityTypeMandatory',
        defaultMessage: 'Entity type is mandatory field'
      });
      isValid = false;
    }

    if (!riskAssessment.selectedRiskAssessment || !riskAssessment.selectedRiskAssessment.EntityId) {
      formErrors.Entity = intl.formatMessage({
        id: 'wqsp.common.entityMandatory',
        defaultMessage: 'Entity is mandatory field'
      });
      isValid = false;
    }

    if (!riskAssessment.selectedRiskAssessment || !riskAssessment.selectedRiskAssessment.HazardEventId) {
      formErrors.HazardEvent = intl.formatMessage({
        id: 'riskAssessment.hazardEvetRequired',
        defaultMessage: 'Hazard event is a mandatory field'
      });
      isValid = false;
    }

    if (!riskAssessment.selectedRiskAssessment || !riskAssessment.selectedRiskAssessment.RiskCategoryId) {
      formErrors.RiskCategory = intl.formatMessage({
        id: 'riskAssessment.riskCategoryRequired',
        defaultMessage: 'Risk category is a mandatory field'
      });
      isValid = false;
    }

    if (!riskAssessment.selectedRiskAssessment || !riskAssessment.selectedRiskAssessment.InherentLikelihood) {
      formErrors.Inherent = intl.formatMessage({
        id: 'riskAssessment.inherentRiskFieldsRequired',
        defaultMessage: 'Inherent risk fields are mandatory'
      });
      isValid = false;
    }

    if (riskAssessment.selectedRiskAssessment.IsPassedOn && riskAssessment.selectedRiskAssessment.Entities.length === 0) {
      formErrors.IsPassedOn = intl.formatMessage({
        id: 'riskAssessment.passedOnEntitiesMandatory',
        defaultMessage: 'If risk is Passed-On, please select Entity to which it is being passed'
      });
      isValid = false;
    }

    if (!riskAssessment.selectedRiskAssessment || !riskAssessment.selectedRiskAssessment.RiskCertaintyId) {
      formErrors.RiskCertainty = intl.formatMessage({
        id: 'riskAssessment.riskCertaintyMandatory',
        defaultMessage: 'Risk certainty is a mandatory field'
      });
      isValid = false;
    }

    if (!riskAssessment.selectedRiskAssessment || !riskAssessment.selectedRiskAssessment.DateRaised) {
      formErrors.DateRaised = intl.formatMessage({
        id: 'riskAssessment.dateRaisedMandatory',
        defaultMessage: 'Date raised is mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target.name === 'IsPassedOn' && e.target.value === false) {
      setLocalRiskAssessment({
        ...riskAssessment,
        selectedRiskAssessment: { ...riskAssessment.selectedRiskAssessment, IsPassedOn: e.target.value, Entities: [] }
      });
    } else {
      setLocalRiskAssessment({
        ...riskAssessment,
        selectedRiskAssessment: { ...riskAssessment.selectedRiskAssessment, [e.target.name]: e.target.value }
      });
    }
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  const cancelHandler = () => {
    goBack();
  };

  const siteOptions = () => {
    let siteData = [];
    generalData.sites.forEach(element => {
      siteData.push({
        ...element,
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onSiteDropdownChange = e => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        SiteId: e.value,
        WaterSupplyDescriptionId: 0,
        EntityId: 0,
        HazardEventId: 0
      }
    });
  };

  const waterSupplyOptions = () => {
    let waterSupplyData = [];
    const filteredData = generalData.waterSupplyDescriptions.filter(item => item.SiteId === riskAssessment.selectedRiskAssessment.SiteId);
    filteredData.forEach(element => {
      waterSupplyData.push({
        ...element,
        label: element.Name,
        value: element.WaterSupplyDescriptionId
      });
    });

    let sortedData = waterSupplyData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onWaterSupplyDropdownChange = e => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        WaterSupplyDescriptionId: e.value
      }
    });
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];
    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onEntityTypeDropdownChange = e => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        EntityTypeId: e.value,
        EntityId: 0,
        HazardEventId: 0
      }
    });
  };

  const entityOptions = () => {
    let entityData = [];
    let entitySubTypes = generalData.entitySubTypes.filter(
      item => item.EntityTypeId === riskAssessment.selectedRiskAssessment.EntityTypeId
    );
    let entities = generalData.entities.filter(item =>
      entitySubTypes.find(
        type =>
          type.EntitySubTypeId === item.EntitySubTypeId &&
          item.WaterSupplyDescriptionId === riskAssessment.selectedRiskAssessment.WaterSupplyDescriptionId
      )
    );
    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const passedOnEntityOptions = () => {
    let entityData = [];

    let entities = generalData.entities.filter(
      item => item.WaterSupplyDescriptionId === riskAssessment.selectedRiskAssessment.WaterSupplyDescriptionId
    );

    entities.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    return sortedData;
  };

  const onPassedOnEntityDropdownChange = e => {
    let passedOnEntities = riskAssessment.selectedRiskAssessment.Entities;
    if (!passedOnEntities.some(x => x.EntityId === e.value)) {
      passedOnEntities.push({
        Name: e.label,
        EntityId: e.value
      });
    }
    setLocalRiskAssessment({
      ...riskAssessment,
      selectedRiskAssessment: { ...riskAssessment.selectedRiskAssessment, Entities: passedOnEntities }
    });
  };

  const removeLocalPassedOnEntity = id => {
    let passedOnEntities = riskAssessment.selectedRiskAssessment.Entities.filter(item => item.EntityId !== id);
    setLocalRiskAssessment({
      ...riskAssessment,
      selectedRiskAssessment: { ...riskAssessment.selectedRiskAssessment, Entities: passedOnEntities }
    });
  };

  const onEntityDropdownChange = e => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        EntityId: e.value,
        HazardEventId: 0
      }
    });
  };

  const hazardEventOptions = () => {
    let hazardEventData = [];
    hazardEvent.hazardEvents.forEach(element => {
      if (element.EntityId === riskAssessment.selectedRiskAssessment.EntityId) {
        hazardEventData.push({
          ...element,
          label: element.Name,
          value: element.HazardEventId
        });
      }
    });

    let sortedData = hazardEventData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onHazardEventDropdownChange = e => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        HazardEventId: e.value
      }
    });
  };

  const riskCategoryOptions = () => {
    let riskCategoryData = [];
    riskCategory.RiskCategories.forEach(element => {
      riskCategoryData.push({
        ...element,
        label: element.Name,
        value: element.RiskCategoryId
      });
    });

    let sortedData = riskCategoryData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onRiskCategoryDropdownChange = e => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        RiskCategoryId: e.value,
        InherentLikelihood: '',
        InherentConsequence: '',
        InherentRiskRating: '',
        InherentRiskScore: null,
        ResidualLikelihood: '',
        ResidualConsequence: '',
        ResidualRiskRating: '',
        ResidualRiskScore: null
      }
    });
  };

  const riskCertaintyOptions = () => {
    let certaintyData = [];
    riskCertainties.forEach(element => {
      certaintyData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = certaintyData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const onRiskCertaintyDropdownChange = e => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        RiskCertaintyId: e.value
      }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        [fieldName]: e.value
      }
    });
  };

  const riskResponseOptions = () => {
    let responseData = [];
    riskResponses.forEach(element => {
      let isChecked = riskAssessment.selectedRiskAssessment.Response.split(',').find(e => e === element.GeneralFieldTypeId.toString())
        ? true
        : false;

      responseData.push({
        ...element,
        SKEY: element.GeneralFieldTypeId,
        target: { type: 'checkbox' },
        label: element.Name,
        isChecked: isChecked
      });
    });

    return responseData.sort(compareValues('label'));
  };

  const onRiskResponseChange = e => {
    if (e.target && e.target.name) {
      let riskResponse = riskAssessment.selectedRiskAssessment.Response;
      if (e.target.checked) {
        riskResponse = (riskResponse && `${riskResponse},${e.target.name}`) || e.target.name;
      } else {
        if (riskResponse) {
          riskResponse = riskResponse
            .split(',')
            .filter(elem => elem !== e.target.name)
            .join(',');
        }
      }
      setLocalRiskAssessment({
        selectedRiskAssessment: {
          ...riskAssessment.selectedRiskAssessment,
          Response: riskResponse
        }
      });
    }
  };

  const riskStatusOptions = () => {
    let statusData = [];
    riskStatuses.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const onRiskStatusDropdownChange = e => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        StatusId: e.value
      }
    });
  };

  const onChangeRiskScore = (score, consequence, likelihood, riskRatings) => {
    const riskRating = getRiskRating(score, riskRatings);
    if (riskAssessment.selectedRiskAssessment.IsInherentRiskCategory) {
      setLocalRiskAssessment({
        selectedRiskAssessment: {
          ...riskAssessment.selectedRiskAssessment,
          InherentLikelihood: likelihood,
          InherentConsequence: consequence,
          InherentRiskRating: riskRating,
          InherentRiskScore: score
        }
      });
    } else {
      setLocalRiskAssessment({
        selectedRiskAssessment: {
          ...riskAssessment.selectedRiskAssessment,
          ResidualLikelihood: likelihood,
          ResidualConsequence: consequence,
          ResidualRiskRating: riskRating,
          ResidualRiskScore: score
        }
      });
    }
  };

  const onChangeRiskCategoryType = value => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        IsInherentRiskCategory: value
      }
    });
  };

  const getRiskRating = (value, riskRating) => {
    if (riskRating.LowRatingScore >= value) {
      return 'riskCategory.low';
    }
    if (riskRating.MediumRatingScore >= value) {
      return 'riskCategory.medium';
    }
    if (riskRating.HighRatingScore >= value) {
      return 'riskCategory.high';
    }
    if (riskRating.HighRatingScore < value) {
      return 'riskCategory.veryHigh';
    }
  };

  const getTranslatedText = id => {
    if (id) return intl.formatMessage({ id: id, defaultMessage: id });
    return null;
  };

  const getselectedCategoryColumn = () => {
    if (riskAssessment.selectedRiskAssessment.IsInherentRiskCategory) {
      return {
        Likelihood: riskAssessment.selectedRiskAssessment.InherentLikelihood,
        Consequence: riskAssessment.selectedRiskAssessment.InherentConsequence,
        RiskRating: riskAssessment.selectedRiskAssessment.InherentRiskRating,
        RiskScore: riskAssessment.selectedRiskAssessment.InherentRiskScore
      };
    } else {
      return {
        Likelihood: riskAssessment.selectedRiskAssessment.ResidualLikelihood,
        Consequence: riskAssessment.selectedRiskAssessment.ResidualConsequence,
        RiskRating: riskAssessment.selectedRiskAssessment.ResidualRiskRating,
        RiskScore: riskAssessment.selectedRiskAssessment.ResidualRiskScore
      };
    }
  };

  const onStatusChange = e => {
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        [e.target.name]: e.target.value,
        StatusId: null
      }
    });
  };

  //FILE UPLOAD
  const onUploadStart = files => {};

  const onUploadComplete = data => {
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          SiteId: 0,
          newFile: true,
          S3URL: item.S3URL,
          ImageId: item.fileGuid,
          Name: item.name
        };
      });
      let allFiles = [...riskAssessment.selectedRiskAssessment.Files, ...newFiles];

      setLocalRiskAssessment({
        selectedRiskAssessment: {
          ...riskAssessment.selectedRiskAssessment,
          Files: allFiles
        }
      });
    }
  };

  const removeFile = fileGuid => {
    let files = riskAssessment.selectedRiskAssessment.Files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...riskAssessment.selectedRiskAssessment.deletedFileIds];
    deletedFileIds.push(fileGuid);
    setLocalRiskAssessment({
      selectedRiskAssessment: {
        ...riskAssessment.selectedRiskAssessment,
        Files: files,
        deletedFileIds: deletedFileIds
      }
    });
  };
  //END FILE UPLOAD

  let messageId = (riskAssessment && riskAssessment.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const editName = (riskAssessment.selectedRiskAssessment ? riskAssessment.selectedRiskAssessment.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'riskAssessment.editRiskAssessment', defaultMessage: 'Edit Risk Assessment' }) + ' ' + editName;
  } else {
    heading = intl.formatMessage({ id: 'riskAssessment.addRiskAssessment', defaultMessage: 'Add new Risk Assessment' });
  }
  let hazardEventHeading =
    intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + ' (' + hazardEventName + ')';

  const defineHazardEventCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 5,
      name: <FormattedMessage id="riskAssessment.viewRiskAssessment" defaultMessage="View Risk Assessment" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment-view/${riskAssessmentId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    {
      orderNo: 6,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  const identifyHazardEventCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="riskAssessment.viewRiskAssessment" defaultMessage="View Risk Assessment" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/risk-assessments/risk-assessment-view/${riskAssessmentId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    {
      orderNo: 5,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];
  const identifyRiskAssessmentCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/risk-assessments`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="riskAssessment.viewRiskAssessment" defaultMessage="View Risk Assessment" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/risk-assessments/risk-assessment-view/${riskAssessmentId}`,
      showlink: true,
      showCrumb: IsEdit
    },
    {
      orderNo: 3,
      name: heading,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  let breadcrumb = identifyRiskAssessmentCrumb;

  if (segmentName === 'define') {
    breadcrumb = defineHazardEventCrumb;
  } else {
    if (hazardEventId) {
      breadcrumb = identifyHazardEventCrumb;
    }
  }

  const goBack = () => {
    history.goBack();
  };

  if (riskAssessment.isRedirect) {
    goBack();
  }

  return (
    <div className={s.riskAssessment}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {segmentName ? (
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
        ) : (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="riskAssessment.riskAssessments" defaultMessage="Risk Assessments" />}</h1>
          </div>
        )}
        {isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={riskAssessment.showBanner}
          status={riskAssessment.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          {!segmentName && (
            <div className={s.testresultHeader}>
              <GoBack className={s.backLink}>
                &lt; &nbsp;
                <FormattedMessage id="common.back" defaultMessage="BACK" />
              </GoBack>
              <h3 data-unittest="headingLabel">{heading}</h3>
            </div>
          )}
          <div className={s.riskAssessmentContent}>
            <Form>
              <div className={s.riskAssessmentMainInfo}>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskAssesmentSiteName">
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
                      </Form.Label>

                      <Dropdown
                        id="formDdlSite"
                        dataArray={siteOptions()}
                        controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Site && s.ddlError }}
                        onDropdownChange={onSiteDropdownChange}
                        data-unittest="formDdlSite"
                        selectedOption={siteOptions().filter(option => option.value === riskAssessment.selectedRiskAssessment.SiteId)}
                        disabled={IsEdit || disableSite}
                      />
                      {localformErrors && localformErrors.Site && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Site}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskAssessmentSupplyName">
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                      </Form.Label>

                      <Dropdown
                        id="formDdlWaterSupply"
                        dataArray={waterSupplyOptions()}
                        controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.WaterSupply && s.ddlError }}
                        onDropdownChange={onWaterSupplyDropdownChange}
                        data-unittest="formDdlWaterSupply"
                        selectedOption={waterSupplyOptions().filter(
                          option => option.value === riskAssessment.selectedRiskAssessment.WaterSupplyDescriptionId
                        )}
                        disabled={IsEdit || disableEntity}
                      />
                      {localformErrors && localformErrors.WaterSupply && (
                        <p role="alert" className={s.error}>
                          {localformErrors.WaterSupply}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskAssessmentEntityType">
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />
                      </Form.Label>

                      <Dropdown
                        id="formDdlEntityType"
                        dataArray={entityTypeOptions()}
                        controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.EntityType && s.ddlError }}
                        onDropdownChange={onEntityTypeDropdownChange}
                        data-unittest="formDdlEntityType"
                        selectedOption={entityTypeOptions().filter(
                          option => option.value === riskAssessment.selectedRiskAssessment.EntityTypeId
                        )}
                        disabled={disableEntity}
                      />
                      {localformErrors && localformErrors.EntityType && (
                        <p role="alert" className={s.error}>
                          {localformErrors.EntityType}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskAssessmentEntity">
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                      </Form.Label>

                      <Dropdown
                        id="formDdlEntity"
                        dataArray={entityOptions()}
                        controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Entity && s.ddlError }}
                        onDropdownChange={onEntityDropdownChange}
                        data-unittest="formDdlEntity"
                        selectedOption={entityOptions().filter(option => option.value === riskAssessment.selectedRiskAssessment.EntityId)}
                        disabled={disableEntity}
                      />
                      {localformErrors && localformErrors.Entity && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Entity}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskAssesmentHazardEvent">
                      <Form.Label>
                        <FormattedMessage id="riskAssessment.hazardEvent" defaultMessage="Hazard Event" />
                      </Form.Label>

                      <Dropdown
                        id="formDdlHazardEvent"
                        dataArray={hazardEventOptions()}
                        controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.HazardEvent && s.ddlError }}
                        onDropdownChange={onHazardEventDropdownChange}
                        data-unittest="formDdlHazardEvent"
                        selectedOption={hazardEventOptions().filter(
                          option => option.value === riskAssessment.selectedRiskAssessment.HazardEventId
                        )}
                        disabled={disableHazardEvent}
                      />
                      {localformErrors && localformErrors.HazardEvent && (
                        <p role="alert" className={s.error}>
                          {localformErrors.HazardEvent}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskAssesmentRiskCategory">
                      <Form.Label>
                        <FormattedMessage id="riskAssessment.riskCategory" defaultMessage="Risk Category" />
                      </Form.Label>

                      <Dropdown
                        id="formDdlRiskCategory"
                        dataArray={riskCategoryOptions()}
                        controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.RiskCategory && s.ddlError }}
                        onDropdownChange={onRiskCategoryDropdownChange}
                        data-unittest="formDdlRiskCategory"
                        selectedOption={riskCategoryOptions().filter(
                          option => option.value === riskAssessment.selectedRiskAssessment.RiskCategoryId
                        )}
                      />
                      {localformErrors && localformErrors.RiskCategory && (
                        <p role="alert" className={s.error}>
                          {localformErrors.RiskCategory}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskNumber">
                      <Form.Label>
                        <FormattedMessage id="riskAssessment.riskNumber" defaultMessage="Risk Number" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="RiskNumber"
                        onChange={onChange}
                        value={riskAssessment.selectedRiskAssessment.RiskNumber}
                        placeholder={intl.formatMessage({
                          id: 'riskAssessment.riskNumber',
                          defaultMessage: 'Risk Number'
                        })}
                        className={`${s.formControl}`}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {riskCategory.SelectedRiskCategory.RiskCategoryId > 0 && riskAssessment.selectedRiskAssessment.RiskCategoryId > 0 && (
                  <RiskCategoryMatrixView
                    RiskCategory={riskCategory.SelectedRiskCategory}
                    ChangeRiskScore={onChangeRiskScore}
                    isInherentRiskCategory={riskAssessment.selectedRiskAssessment.IsInherentRiskCategory}
                    selectedCategoryColumn={getselectedCategoryColumn()}
                    changeRiskCategoryType={onChangeRiskCategoryType}
                    localformErrors={localformErrors}
                  />
                )}

                <Row className={`${s.viewRow}`}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.inherentLikelihood" defaultMessage="Inherent Likelihood" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>{riskAssessment.selectedRiskAssessment.InherentLikelihood || '-'}</Form.Label>
                  </Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.inherentConsequence" defaultMessage="Inherent Consequence" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>{getTranslatedText(riskAssessment.selectedRiskAssessment.InherentConsequence) || '-'}</Form.Label>
                  </Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.inherentRiskRating" defaultMessage="Inherent Risk Rating" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>{getTranslatedText(riskAssessment.selectedRiskAssessment.InherentRiskRating) || '-'}</Form.Label>
                  </Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.inherentRiskScore" defaultMessage="Inherent Risk Score" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>
                      {riskAssessment.selectedRiskAssessment.InherentRiskScore === null
                        ? '-'
                        : riskAssessment.selectedRiskAssessment.InherentRiskScore}
                    </Form.Label>
                  </Col>
                </Row>

                {localformErrors && !localformErrors.RiskCategory && localformErrors.Inherent && (
                  <p role="alert" className={s.error}>
                    {localformErrors.Inherent}
                  </p>
                )}

                <Row className="mt-2">
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.riskResponse" defaultMessage="Risk Response" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Checkbox key={uuidv4()} dataArray={riskResponseOptions()} onSelectionChange={onRiskResponseChange} />
                  </Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col lg={6}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.passedOn" defaultMessage="Passed-On" />
                    </Form.Label>

                    <ToggleSwitch
                      handleClick={() =>
                        onChange({ target: { name: 'IsPassedOn', value: !riskAssessment.selectedRiskAssessment.IsPassedOn } })
                      }
                      classname={s.switch}
                      checked={riskAssessment.selectedRiskAssessment.IsPassedOn}
                      labelChecked={yesText}
                      labelUnchecked={noText}
                    />
                  </Col>
                </Row>
                {riskAssessment.selectedRiskAssessment.IsPassedOn && (
                  <Row className={s.viewRow}>
                    <Col lg={6}>
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />
                      </Form.Label>

                      <Dropdown
                        id="formDdlEntity"
                        dataArray={passedOnEntityOptions()}
                        controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.Entity && s.ddlError }}
                        onDropdownChange={onPassedOnEntityDropdownChange}
                        data-unittest="formDdlPassedOnEntity"
                        selectedOption={passedOnEntityOptions().find(
                          option => option.value === riskAssessment.selectedRiskAssessment.Entities
                        )}
                      />
                      {localformErrors && localformErrors.IsPassedOn && (
                        <p role="alert" className={s.error}>
                          {localformErrors.IsPassedOn}
                        </p>
                      )}
                    </Col>
                  </Row>
                )}

                {riskAssessment.selectedRiskAssessment.Entities.map(item => {
                  return (
                    <Row key={item.EntityId}>
                      <Col lg={6}>
                        <Alert variant="light" className={`d-flex flex-row align-items-center ${s.entityAlert}`}>
                          <span>
                            <span>{item.Name}</span>
                          </span>
                        </Alert>
                      </Col>
                      <Col lg={1} className="pl-0">
                        <h6
                          className={`${s.removeLink}`}
                          onClick={() => {
                            removeLocalPassedOnEntity(item.EntityId);
                          }}
                        >
                          <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
                        </h6>
                      </Col>
                    </Row>
                  );
                })}

                <Row className={s.viewRow}>
                  <Col>
                    <Form.Group controlId="formRiskResponseDetails">
                      <Form.Label>
                        <FormattedMessage id="riskAssessment.riskResponseDetails" defaultMessage="Risk Response Details" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="ResponseDetails"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'riskAssessment.riskResponseDetails',
                          defaultMessage: 'Risk Response Details'
                        })}
                        value={riskAssessment.selectedRiskAssessment.ResponseDetails}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.residualLikelihood" defaultMessage="Residual Likelihood" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>{riskAssessment.selectedRiskAssessment.ResidualLikelihood || '-'}</Form.Label>
                  </Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.residualConsequence" defaultMessage="Residual Consequence" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>{getTranslatedText(riskAssessment.selectedRiskAssessment.ResidualConsequence) || '-'}</Form.Label>
                  </Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.residualRiskRating" defaultMessage="Residual Risk Rating" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>{getTranslatedText(riskAssessment.selectedRiskAssessment.ResidualRiskRating) || '-'}</Form.Label>
                  </Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="riskAssessment.residualRiskScore" defaultMessage="Residual Risk Score" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Label>
                      {riskAssessment.selectedRiskAssessment.ResidualRiskScore === null
                        ? '-'
                        : riskAssessment.selectedRiskAssessment.ResidualRiskScore}
                    </Form.Label>
                  </Col>
                </Row>

                {localformErrors && !localformErrors.RiskCategory && localformErrors.Residual && (
                  <p role="alert" className={s.error}>
                    {localformErrors.Residual}
                  </p>
                )}

                <Row className={s.viewRow}>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskAssesmentCertainty">
                      <Form.Label>
                        <FormattedMessage id="riskAssessment.riskCertainty" defaultMessage="Risk Certainty" />
                      </Form.Label>

                      <Dropdown
                        id="formDdlRiskCertainty"
                        dataArray={riskCertaintyOptions()}
                        controlData={{ placeholderText: ddlInitText, customClassName: localformErrors.RiskCertainty && s.ddlError }}
                        onDropdownChange={onRiskCertaintyDropdownChange}
                        data-unittest="formDdlRiskCertainty"
                        selectedOption={riskCertaintyOptions().filter(
                          option => option.value === riskAssessment.selectedRiskAssessment.RiskCertaintyId
                        )}
                      />
                      {localformErrors && localformErrors.RiskCertainty && (
                        <p role="alert" className={s.error}>
                          {localformErrors.RiskCertainty}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formRiskAssessmentBasis">
                      <Form.Label>
                        <FormattedMessage id="riskAssessment.basis" defaultMessage="Basis" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Basis"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'riskAssessment.basis',
                          defaultMessage: 'basis'
                        })}
                        value={riskAssessment.selectedRiskAssessment.Basis}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskAssessmentDateRaised">
                      <Form.Label>
                        <FormattedMessage id="riskAssessment.dateRaised" defaultMessage="Date Raised" />
                      </Form.Label>

                      <DateTimePicker
                        className={`${localformErrors.DateRaised ? s.formControlError : s.frmCalendar}`}
                        onChange={m => {
                          onChange({ target: { name: 'DateRaised', value: (m && m.unix && m.unix()) || null } });
                        }}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(riskAssessment.selectedRiskAssessment.DateRaised)}
                        defaultValue={getFormattedate(riskAssessment.selectedRiskAssessment.DateRaised)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.DateRaised && (
                        <p role="alert" className={s.error}>
                          {localformErrors.DateRaised}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formRiskAssessmentLastReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                      </Form.Label>

                      <DateTimePicker
                        className={s.frmCalendar}
                        onChange={m => {
                          onChange({ target: { name: 'LastReviewDate', value: (m && m.unix && m.unix()) || null } });
                        }}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(riskAssessment.selectedRiskAssessment.LastReviewDate)}
                        defaultValue={getFormattedate(riskAssessment.selectedRiskAssessment.LastReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formNextReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => {
                          onChange({ target: { name: 'NextReviewDate', value: (m && m.unix && m.unix()) || null } });
                        }}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(riskAssessment.selectedRiskAssessment.NextReviewDate)}
                        defaultValue={getFormattedate(riskAssessment.selectedRiskAssessment.NextReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.NextReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.NextReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formGroupReviewFrequency">
                      <Form.Label>
                        <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                      </Form.Label>

                      <Dropdown
                        id="formMaintenanceFrequency"
                        dataArray={frequencyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="ReviewFrequencyId"
                        onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                        selectedOption={frequencyOptions().filter(
                          option => option.value === riskAssessment.selectedRiskAssessment.ReviewFrequencyId
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formActioned">
                      <Form.Label>
                        <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                      </Form.Label>

                      <ToggleSwitch
                        handleClick={() => {
                          onStatusChange({ target: { name: 'IsActioned', value: !riskAssessment.selectedRiskAssessment.IsActioned } });
                        }}
                        classname={s.switch}
                        checked={riskAssessment.selectedRiskAssessment.IsActioned}
                        labelChecked={yesText}
                        labelUnchecked={noText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!riskAssessment.selectedRiskAssessment.IsActioned && (
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formRiskAssesmentStatus">
                        <Form.Label>
                          <FormattedMessage id="common.status" defaultMessage="Status" />
                        </Form.Label>

                        <Dropdown
                          id="formDdlRiskStatus"
                          dataArray={riskStatusOptions()}
                          controlData={{ placeholderText: ddlInitText }}
                          onDropdownChange={onRiskStatusDropdownChange}
                          data-unittest="formDdlRiskStatus"
                          selectedOption={riskStatusOptions().filter(
                            option => option.value === riskAssessment.selectedRiskAssessment.StatusId
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Label>
                      <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                    </Form.Label>
                    <FileUploader
                      widgetCode={widgetCode}
                      allowedFileTypes={[
                        ['image/jpeg', 'jpeg'],
                        ['application/pdf', 'pdf'],
                        ['application/msword', 'doc']
                      ]}
                      location={`organisation/${orgId}/sites/${riskAssessment.selectedRiskAssessment.SiteId}/riskassesment`}
                      enableMultipleUpload={true}
                      maxFiles={5}
                      onUploadComplete={onUploadComplete}
                      onUploadStart={onUploadStart}
                    />
                  </Col>
                </Row>
                <FileList files={riskAssessment.selectedRiskAssessment.Files} onRemoveFile={removeFile} />
              </div>
            </Form>
          </div>
          <Row>
            <Col>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChanges)}
                onClick={submitForm}
                noValidate
                data-unittest="saveData"
              >
                <FormattedMessage id="common.save" defaultMessage="SAVE" />
              </Button>

              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancel)}
                onClick={cancelHandler}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
          </Row>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

RiskAssessment.defaultProps = {
  riskAssessment: {
    ...initialRiskAssessmentState
  }
};

export default injectIntl(RiskAssessment);
