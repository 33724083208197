import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';

import {
  SITE_ERROR,
  GET_ALL_SITE_DETAILS,
  SITE_IS_LOADING,
  SET_SITE_CURRENT_PAGE,
  SET_SITE_PAGE_FILTER,
  SET_SITE_CHANGE,
  SET_SITE,
  UPDATE_SITE_SAVE_STATUS,
  SET_SITE_FILTERS,
  SET_SITE_RESULTS,
  SET_SITE_COMMON_LIST,
  SET_SELECTED_VIEW
} from '../../constants/index';

import { SITE_MANAGEMENT, CREATE_SITE, UPDATE_SITE } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setSiteErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setSiteError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setSiteError(messageCode, false));
  }, 2500);
};

export const setSiteError = (messageCode, status) => {
  return {
    type: SITE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: SITE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getSiteThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getSite(widgetCode, orgId, filterObj));
};

export const getSite = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/sites/site`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      filter: filterObj.filter || null,
      limit: filterObj.limit || null,
      offset: filterObj.offset || null
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllSiteListThunk(retData),
    onFailure: err => setSiteErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllSiteListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let sites = (retData && retData.retData.data) || [];
  dispatch(setAllSiteList(messageCode, sites));
};

export const setAllSiteList = (messageCode, sites) => ({
  type: GET_ALL_SITE_DETAILS,
  payload: {
    data: {
      Sites: sites,
      filteredResults: sites,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_SITE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_SITE_PAGE_FILTER,
  payload: { filter }
});

export const describeSiteThunk = (SiteId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeSite(SiteId, widgetCode, orgId));
};

export const describeSite = (SiteId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/sites/site`,
    data: {
      SiteId: SiteId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setSiteThunk(retData),
    onFailure: err => setSiteErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setSiteThunk = ({ retData }) => dispatch => {
  let data = retData.data;
  data.files = retData.files || [];
  dispatch(setSite(data));
};

export const setSite = data => ({
  type: SET_SITE,
  payload: { selectedSite: data }
});

export const saveSiteThunk = (site, widgetCode) => dispatch => {
  let url = '/sites/site/save';
  let actionName = site.SiteId === 0 ? CREATE_SITE : UPDATE_SITE;
  let log = logEntry(SITE_MANAGEMENT, actionName, site);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  site.OrganisationId = orgId;
  dispatch(saveSite(site, url, log, widgetCode));
};

export const saveSite = (site, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setSiteStatusThunk(retData, site),
    onFailure: err => setSiteErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: site,
      log: log
    },
    widgetcode: widgetCode
  });

export const setSiteStatusThunk = (message, site) => dispatch => {
  dispatch(setSiteStatus(message.message, true, false));
  dispatch(setGeneralData(message.data));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !site.PerformDelete;
    dispatch(setSiteStatus(message.message, false, isRedirect));
  }, 2500);
};

export const setGeneralData = item => {
  return {
    type: SET_SITE_COMMON_LIST,
    payload: {
      data: {
        SiteId: item.SiteId,
        Name: item.Name
      }
    }
  };
};

export const setSiteStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_SITE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const setSiteChangeThunk = retData => dispatch => {
  dispatch(setSiteChange(retData));
};

export const setSiteChange = retData => ({
  type: SET_SITE_CHANGE,
  payload: retData
});

export const getFilterThunk = widgetCode => dispatch => {
  dispatch(getFilter(widgetCode));
};

export const getFilter = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      filterFor: 'sites'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setSiteErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_SITE_FILTERS,
  payload: {
    data: {
      isLoading: false,
      siteListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_SITE_RESULTS,
  payload: retData
});

export const setSelectedViewThunk = viewName => dispatch => {
  dispatch(setSelectedView(viewName));
};

export const setSelectedView = viewName => ({
  type: SET_SELECTED_VIEW,
  payload: { viewName }
});
