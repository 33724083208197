import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import s from './Banner.module.scss';
import bs from '../../styles/bootstrap-overrides.scss';
import tick from '../../assets/tick.svg';
import error from '../../assets/error.svg';
import Alert from 'react-bootstrap/Alert';

const Banner = ({ failureText, status, showBanner, successText }) => {
  const statusText = status ? successText : failureText;
  const iconSrc = status ? tick : error;
  const [show, setShow] = useState(true);

  if (!statusText || statusText === '') {
    return <div></div>;
  }

  return (
    <Transition mountOnEnter unmountOnExit timeout={2500} in={showBanner}>
      {state => {
        if (show) {
          return (
            <div className={classNames(s.banner, { [s.enter]: state === 'entered' })}>
              <Alert variant={!status ? 'danger' : 'success'} onClose={() => setShow(false)} dismissible>
                <img src={iconSrc} alt="icon" />
                {statusText}
              </Alert>
            </div>
          );
        }
      }}
    </Transition>
  );
};

Banner.propTypes = {
  status: PropTypes.bool,
  failureText: PropTypes.string,
  showBanner: PropTypes.bool.isRequired,
  successText: PropTypes.string
};

export default Banner;
