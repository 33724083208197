import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LanguageSelector from './LanguageSelector';
import { changeLocale } from '../../store/actions/locale';
import { getCurrentLocale } from '../../selectors/index';

const mapStateToProps = state => {
  return {
    currentLocale: getCurrentLocale(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateLanguage: targetLocale => changeLocale(targetLocale)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
