import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';

import {
  GENERAL_FIELDTYPE_ERROR,
  GET_ALL_GENERAL_FIELDTYPE_DETAILS,
  GENERAL_FIELDTYPE_IS_LOADING,
  SET_GENERAL_FIELDTYPE_CURRENT_PAGE,
  SET_GENERAL_FIELDTYPE_PAGE_FILTER,
  SET_GENERAL_FIELDTYPE_CHANGE,
  SET_GENERAL_FIELDTYPE,
  SET_GENERAL_FIELDTYPE_CLEANUP,
  UPDATE_GENERAL_FIELDTYPE_SAVE_STATUS,
  SET_GENERAL_FIELDTYPE_RESULTS
} from '../../constants/index';

import { GENERAL_FIELDTYPE_MANAGEMENT, CREATE_GENERAL_FIELDTYPE, UPDATE_GENERAL_FIELDTYPE } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setGeneralFieldTypeErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setGeneralFieldTypeError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setGeneralFieldTypeError(messageCode, false));
  }, 2500);
};

export const setGeneralFieldTypeError = (messageCode, status) => {
  return {
    type: GENERAL_FIELDTYPE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: GENERAL_FIELDTYPE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getGeneralFieldTypeThunk = widgetCode => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getGeneralFieldType(widgetCode, orgId));
};

export const getGeneralFieldType = (widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/fieldtype`,
    method: 'GET',
    data: {
      OrgId: orgId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllGeneralFieldTypeListThunk(retData),
    onFailure: err => setGeneralFieldTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllGeneralFieldTypeListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllGeneralFieldTypeList(messageCode, data));
};

export const setAllGeneralFieldTypeList = (messageCode, data) => ({
  type: GET_ALL_GENERAL_FIELDTYPE_DETAILS,
  payload: {
    data: {
      GeneralFieldTypes: data,
      filteredResults: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_GENERAL_FIELDTYPE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_GENERAL_FIELDTYPE_PAGE_FILTER,
  payload: { filter }
});

export const describeGeneralFieldTypeThunk = (GeneralFieldTypeId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeGeneralFieldType(GeneralFieldTypeId, widgetCode, orgId));
};

export const describeGeneralFieldType = (GeneralFieldTypeId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/fieldtype`,
    data: {
      GeneralFieldTypeId: GeneralFieldTypeId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setGeneralFieldTypeThunk(GeneralFieldTypeId, retData),
    onFailure: err => setGeneralFieldTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setGeneralFieldTypeThunk = (GeneralFieldTypeId, { retData }) => dispatch => {
  let data = retData.data;
  if ((!data && GeneralFieldTypeId !== null && parseInt(GeneralFieldTypeId) !== 0) || (GeneralFieldTypeId && data.length === 0)) {
    redirectToPageNotFound(dispatch);
  } else {
    dispatch(setGeneralFieldType(data));
  }
};

export const setGeneralFieldType = data => ({
  type: SET_GENERAL_FIELDTYPE,
  payload: data[0]
});

export const saveGeneralFieldTypeThunk = (data, widgetCode) => dispatch => {
  let url = '/management/general/fieldtype/save';
  let actionName = data.GeneralFieldTypeId === 0 ? CREATE_GENERAL_FIELDTYPE : UPDATE_GENERAL_FIELDTYPE;
  let log = logEntry(GENERAL_FIELDTYPE_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveGeneralFieldType(data, url, log, widgetCode));
};

export const saveGeneralFieldType = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setGeneralFieldTypeStatusThunk(retData),
    onFailure: err => setGeneralFieldTypeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setGeneralFieldTypeStatusThunk = message => dispatch => {
  dispatch(setGeneralFieldTypeStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    dispatch(setGeneralFieldTypeStatus(message, false, true));
  }, 2500);
};

export const setGeneralFieldTypeStatus = (messageCode, status, redirect) => ({
  type: UPDATE_GENERAL_FIELDTYPE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: status,
      showBanner: status,
      isLoading: false,
      isRedirect: redirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setGeneralFieldTypeChangeThunk = retData => dispatch => {
  dispatch(setGeneralFieldTypeChange(retData));
};

export const setGeneralFieldTypeChange = retData => ({
  type: SET_GENERAL_FIELDTYPE_CHANGE,
  payload: retData
});

export const setGeneralFieldTypeCleanUpThunk = SelectedGeneralFieldType => dispatch => {
  dispatch({ type: SET_GENERAL_FIELDTYPE_CLEANUP, payload: SelectedGeneralFieldType });
};

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_GENERAL_FIELDTYPE_RESULTS,
  payload: retData
});
