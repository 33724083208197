import {
  GET_ALL_UNITS,
  GET_ALL_UNITS_ERROR,
  UNIT_LOADING,
  SET_UNITS_CURRENT_PAGE,
  SET_UNITS_PAGE_FILTER,
  DESCRIBE_UNIT,
  RESET_UNIT_MESSAGE,
  GET_ALL_UNIT_FILTERS,
  SET_UNIT_FILTERED_RESULTS
} from '../../constants/index';

import { initialUnitManagerState } from './initialState';

const unitManager = (state = initialUnitManagerState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ALL_UNITS: {
      const { unit } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          ...unit
        }
      };
    }

    case SET_UNITS_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          currentPage
        }
      };
    }

    case SET_UNITS_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          filter
        }
      };
    }

    case UNIT_LOADING: {
      const { status } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          isLoading: status
        }
      };
    }

    case GET_ALL_UNITS_ERROR: {
      const { unit } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          ...unit
        }
      };
    }

    case DESCRIBE_UNIT: {
      const { unit } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          selectedUnit: {
            ...state.unitManager.selectedUnit,
            ...unit.selectedUnit
          },
          isDataRendered: unit.isDataRendered ?? false,
          showBanner: unit.showBanner ?? false,
          displayMessageCode: unit.displayMessageCode ?? '',
          detailsSubmitted: unit.detailsSubmitted ?? false
        }
      };
    }

    case RESET_UNIT_MESSAGE: {
      const { message } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          isLoading: false,
          isOpSuccessful: false,
          showBanner: false,
          displayMessageCode: message
        }
      };
    }

    case GET_ALL_UNIT_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          ...data
        }
      };
    }
    case SET_UNIT_FILTERED_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        unitManager: {
          ...state.unitManager,
          ...filteredResults
        }
      };
    }

    default:
      return state;
  }
};

export default unitManager;
