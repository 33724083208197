import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChecklistDetail from './ChecklistDetail';
import { getChecklistTemplate, getChecklist, getUserProfileWidget, getCurrentTimezone } from '../../selectors/index';
import {
  describeChecklistTemplateThunk,
  getChecklistTemplateThunk,
  setChecklistTemplateChangeThunk
} from '../../store/actions//checklistTemplates';
import { saveChecklistThunk, describeChecklistThunk, setChecklistChangeThunk } from '../../store/actions//checklist';
import { resetTasksThunk } from '../../store/actions//tasks';

const mapStateToProps = state => {
  return {
    checklistTemplate: getChecklistTemplate(state),
    checklist: getChecklist(state),
    userProfileWidgets: getUserProfileWidget(state),
    currentTimezone: getCurrentTimezone(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveChecklist: (checklist, widgetCode) => saveChecklistThunk(checklist, widgetCode),
      describeChecklist: (checklistId, widgetCode) => describeChecklistThunk(checklistId, widgetCode),
      getChecklistTemplate: widgetCode => getChecklistTemplateThunk(widgetCode),
      describeChecklistTemplate: (checklistTemplateId, widgetCode) => describeChecklistTemplateThunk(checklistTemplateId, widgetCode),
      changeChecklist: checklist => setChecklistChangeThunk(checklist),
      resetTasks: tasks => resetTasksThunk(tasks),
      changeChecklistTemplate: checklistTemplate => setChecklistTemplateChangeThunk(checklistTemplate)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDetail);
