import React from 'react';
import moment from 'moment-timezone';

export const SectionMonitor = ({ sectionData, getImages, getActionName, currentTimezone }) => {
  return (
    <div id="section5" className="pageBreak sectionContent">
      <h1>Section 5 - Monitor</h1>
      <div id="monitors" className="level1">
        <h2>Control Monitors</h2>
      </div>
      {sectionData.ControlMonitors.map(n => {
        if (n.ControlMonitoringId) {
          return (
            <ControlMonitorsHtml
              key={n.ControlMonitoringId}
              item={n}
              id={n.ControlMonitoringId}
              getImages={getImages}
              getActionName={getActionName}
              currentTimezone={currentTimezone}
            />
          );
        }
      })}
    </div>
  );
};

const ControlMonitorsHtml = ({ item, getImages, getActionName, currentTimezone }) => {
  return (
    <div>
      <h4>{`Control Monitor for ${item.MonitoredItem}`}</h4>
      <table className="level1-table">
        <tr>
          <td>Control Measure</td>
          <td>{item.ControlMeasureName}</td>
        </tr>
        <tr>
          <td>Stakeholder</td>
          <td>{item.StakeholderRegisterName}</td>
        </tr>
        <tr>
          <td>Monitored Item</td>
          <td>{item.MonitoredItem}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{item.Description}</td>
        </tr>
        <tr>
          <td>When Monitored Details</td>
          <td>{item.WhenMonitoredDetails}</td>
        </tr>
        <tr>
          <td>Frequency</td>
          <td>{item.FrequencyName}</td>
        </tr>
        <tr>
          <td>Where Measure Taken</td>
          <td>{item.WhereMeasureTaken}</td>
        </tr>
        <tr>
          <td>Records</td>
          <td>{item.Records}</td>
        </tr>
        <tr>
          <td>Last Review Date </td>
          <td>
            {item.LastReviewDate
              ? moment
                  .unix(parseInt(item.LastReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Next Review Date </td>
          <td>
            {item.NextReviewDate
              ? moment
                  .unix(parseInt(item.NextReviewDate))
                  .tz(currentTimezone)
                  .format('DD-MMM-YYYY')
              : 'N/A'}
          </td>
        </tr>
        <tr>
          <td>Review Frequency</td>
          <td>{item.ReviewFrequencyName}</td>
        </tr>
        <tr>
          <td>Actioned</td>
          <td>{getActionName(item.IsActioned)}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{item.StatusName}</td>
        </tr>
        <tr>
          <td>Images</td>
          <td>{getImages(item.Images)}</td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
