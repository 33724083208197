import { initialEntityState } from './initialState';

import {
  ENTITY_ERROR,
  GET_ALL_ENTITY_DETAILS,
  ENTITY_IS_LOADING,
  SET_ENTITY_CURRENT_PAGE,
  SET_ENTITY_PAGE_FILTER,
  SET_ENTITY_CHANGE,
  SET_ENTITY_CLEANUP,
  SET_ENTITY,
  SET_ENTITY_RESULTS,
  UPDATE_ENTITY_SAVE_STATUS,
  SET_ENTITY_FILTERS
} from '../../constants/index';

const entityReducer = (state = initialEntityState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_ENTITY_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          ...data
        }
      };
    }

    case SET_ENTITY_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          currentPage
        }
      };
    }

    case SET_ENTITY_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_ENTITY_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          ...data
        }
      };
    }

    case ENTITY_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          isLoading: isLoading
        }
      };
    }

    case ENTITY_ERROR: {
      const { data } = payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          ...data
        }
      };
    }

    case SET_ENTITY: {
      let { data } = payload;

      return {
        ...state,
        entity: {
          ...state.entity,
          isOpSuccessful: false,
          showBanner: false,
          SelectedEntity: {
            ...state.entity.SelectedEntity,
            ...data
          }
        }
      };
    }

    case SET_ENTITY_CHANGE: {
      let { SelectedEntity } = payload;

      return {
        ...state,
        entity: {
          ...state.entity,
          isOpSuccessful: false,
          showBanner: false,
          SelectedEntity: {
            ...state.entity.SelectedEntity,
            ...SelectedEntity
          }
        }
      };
    }
    case SET_ENTITY_CLEANUP: {
      let SelectedEntity = payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          SelectedEntity: SelectedEntity
        }
      };
    }

    case SET_ENTITY_RESULTS: {
      const { filteredResults } = payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          ...filteredResults
        }
      };
    }

    case SET_ENTITY_FILTERS: {
      const { data } = payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          ...data
        }
      };
    }

    default:
      return state;
  }
};

export default entityReducer;
