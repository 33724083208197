import { initialSiteSegmentsState } from './initialState';

import { SITESEGMENTS_IS_LOADING, SET_SELECTED_SITESEGMENTS_VIEW, SITESEGMENTS_ERROR, SET_SITESEGMENTS } from '../../constants/index';

const siteSegmentsReducer = (state = initialSiteSegmentsState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SITESEGMENTS_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        siteSegments: {
          ...state.siteSegments,
          isLoading: isLoading
        }
      };
    }

    case SITESEGMENTS_ERROR: {
      const { data } = payload;
      return {
        ...state,
        siteSegments: {
          ...state.siteSegments,
          ...data
        }
      };
    }

    case SET_SELECTED_SITESEGMENTS_VIEW: {
      let { siteSegments } = payload;

      return {
        ...state,
        siteSegments: {
          ...state.siteSegments,
          ...siteSegments
        }
      };
    }

    case SET_SITESEGMENTS: {
      let { siteSegments } = payload;

      return {
        ...state,
        siteSegments: {
          ...state.siteSegments,
          segments: siteSegments
        }
      };
    }

    default:
      return state;
  }
};

export default siteSegmentsReducer;
