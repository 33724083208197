import React, { useState, useEffect } from 'react';
import s from './Team.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getPersonnel, getTeam, getSelectedSafetyPlan } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import 'react-phone-input-2/lib/style.css';
import { initialSafetyPlanTeamState } from '../../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../../utils';
import Cookies from 'js-cookie';
import { describeSafetyPlanThunk } from '../../../store/actions/safetyPlan/safetyPlan';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import Banner from '../../Banner/Banner';
import { describeTeamThunk, saveTeamThunk, setTeamChangeThunk } from '../../../store/actions/safetyPlan/team';
import { getPersonnelThunk } from '../../../store/actions/admin/personnel';
import uuid from 'uuid';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const Team = ({ intl }) => {
  let history = useHistory();
  let { teamId, safetyPlanId, siteId, segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'build';
  selectedTab = selectedTab || '2';

  const dispatch = useDispatch();
  const team = useSelector(state => getTeam(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);
  const personnel = useSelector(state => getPersonnel(state));
  const [localformErrors, setFormErrors] = useState({});
  let IsEdit = false;

  const backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/teams`;
  let widgetCode = '';
  if (teamId) {
    IsEdit = true;
    widgetCode = 'WQSP_TEAM_EDIT';
  }

  let offset = personnel.offset || 0;
  let limit = personnel.limit || 1000;

  useEffect(() => {
    let filterObj = {
      offset: offset,
      limit: limit
    };
    dispatch(getPersonnelThunk('WQSP_PERSONNEL_LIST', filterObj));
  }, [getPersonnelThunk]);

  useEffect(() => {
    if (teamId) {
      dispatch(describeTeamThunk(teamId, widgetCode));
    }
  }, [describeTeamThunk, dispatch, teamId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setTeamChangeThunk({
          ...initialSafetyPlanTeamState.safetyPlanTeam
        })
      );
    };
  }, []);

  useEffect(() => {
    dispatch(describeSafetyPlanThunk(safetyPlanId, 'WQSP_SAFETY_PLAN_UPDATE'));
  }, [safetyPlanId]);

  // Update redux store
  const setLocalTeam = currentState => {
    dispatch(setTeamChangeThunk(currentState));
  };

  const personnelOptions = () => {
    let opData = [];

    personnel &&
      personnel.personnels.forEach(element => {
        opData.push({
          label: element.Name,
          value: element.PersonnelId
        });
      });
    return opData.sort(compareValues('label'));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = team.selectedTeam;
    saveData.WaterQualitySafetyPlanId = safetyPlanId;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.TeamId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_TEAM_ADDNEW';
    }

    dispatch(saveTeamThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!team || !team.selectedTeam || !team.selectedTeam.Role || isRequired(team.selectedTeam.Role, 1)) {
      formErrors.Role = intl.formatMessage({
        id: 'team.roleRequired',
        defaultMessage: 'Role is a mandatory field'
      });
      isValid = false;
    }

    if (!team || !team.selectedTeam || !team.selectedTeam.PersonnelId) {
      formErrors.PersonnelId = intl.formatMessage({
        id: 'team.PersonnelMandatory',
        defaultMessage: 'Personnel is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalTeam({
      ...team,
      selectedTeam: { ...team.selectedTeam, [fieldName]: e.value }
    });
  };

  //on control value change
  const onChange = (e, data) => {
    setLocalTeam({
      ...team,
      selectedTeam: { ...team.selectedTeam, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (team && team.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const title = (team.selectedTeam ? team.selectedTeam.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + title;
  } else {
    heading = intl.formatMessage({ id: 'team.addTeam', defaultMessage: 'Add new Team' });
  }

  const selectedSafetyPlan = useSelector(state => getSelectedSafetyPlan(state));
  const safetyPlanName = (selectedSafetyPlan.Name && ` (${selectedSafetyPlan.Name})`) || '';

  let breadcrumbHeading = intl.formatMessage({ id: 'siteControlsTabs.listOfSafetyPlans', defaultMessage: 'List of Safety Plans' });
  breadcrumbHeading += `${safetyPlanName}`;

  const breadcrumb = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="siteControlsTabs.teams" defaultMessage="Teams" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/safety-plans/${safetyPlanId}/teams`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <div className={s.team}>
      {team.isLoading && <LoadingSpinner />}
      {team.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner key={uuid()} failureText={messageText} showBanner={team.showBanner} status={team.isOpSuccessful} successText={messageText} />
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.contentWrapper}>
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
          <div className={s.teamContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="teamPersonnel">
                      <Form.Label>
                        <FormattedMessage id="team.personnel" defaultMessage="Personnel" />
                      </Form.Label>

                      <Dropdown
                        id="formPersonnel"
                        dataArray={personnelOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: localformErrors.PersonnelId && s.ddlError
                        }}
                        name="PersonnelId"
                        onDropdownChange={e => onDropdownChange(e, 'PersonnelId')}
                        selectedOption={personnelOptions().filter(option => option.value === team.selectedTeam.PersonnelId)}
                      />
                      {localformErrors && localformErrors.PersonnelId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.PersonnelId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formTeamRole">
                      <Form.Label>
                        <FormattedMessage id="team.role" defaultMessage="Role" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Role"
                        onChange={onChange}
                        value={team.selectedTeam.Role}
                        className={`${s.formControl} ${localformErrors && localformErrors.Role ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'team.role',
                          defaultMessage: 'Role'
                        })}
                      />
                      {localformErrors && localformErrors.Role && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Role}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formTeamDescription">
                      <Form.Label>
                        <FormattedMessage id="team.responsibilities" defaultMessage="Responsibilities" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Responsibilities"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'team.responsibilities',
                          defaultMessage: 'Responsibilities'
                        })}
                        value={team.selectedTeam.Responsibilities}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                      disabled={team.isLoading}
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

Team.defaultProps = {
  team: {
    ...initialSafetyPlanTeamState
  }
};

export default injectIntl(Team);
