import Cookies from 'js-cookie';
import {
  ORGANISATION_MANAGEMENT_ERROR,
  GET_ALL_ORGANISATION_DETAILS,
  ORGANISATION_IS_LOADING,
  UPDATE_ORGANISATION_USER_SAVE_STATUS,
  SET_ORGANISATION_CURRENT_PAGE,
  SET_ORGANISATION_PAGE_FILTER,
  SET_ORGANISATION_DETAILS,
  UPDATE_ORGANISATION_DETAILS,
  SET_PARENT_ORGANISATION_DETAILS,
  UPDATE_ORGANISATION_USERS,
  UPDATE_ORGANISATION_UNITS
} from '../../constants/index';
import { ORGANISATION_MANAGEMENT, CREATE_ORGANISATION, UPDATE_ORGANISATION } from '../../constants/logs';
import { apiAction, logEntry } from '../../utils/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

//Organisation Management
export const setOrganisationErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setOrganisationError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setOrganisationError(messageCode, false));
  }, 2500);
};

export const setOrganisationError = (messageCode, status) => {
  return {
    type: ORGANISATION_MANAGEMENT_ERROR,
    payload: {
      organisation: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getOrganisationsThunk = (offset, limit, filter, widgetCode) => dispatch => {
  let OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  dispatch(getOrganisations(offset, limit, filter, widgetCode, OrganisationId));
};

export const setLoadingStatus = isLoading => ({
  type: ORGANISATION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getOrganisations = (offset, limit, filter, widgetCode, organisationId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/organisation`,
    data: {
      Offset: offset,
      OrganisationId: organisationId,
      Limit: limit,
      Filter: filter
    },
    widgetcode: widgetCode,
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllOrganisationListThunk(retData),
    onFailure: err => setOrganisationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`)
  });

export const setAllOrganisationListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let organisations = (retData && retData.retData.organisations) || [];
  let defaultProfiles = (retData && retData.retData.defaultProfiles) || [];
  dispatch(setAllOrganisationList(messageCode, organisations, defaultProfiles));
};

export const setAllOrganisationList = (messageCode, organisations, defaultProfiles = []) => ({
  type: GET_ALL_ORGANISATION_DETAILS,
  payload: {
    organisation: {
      organisations: organisations,
      defaultProfiles: defaultProfiles,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_ORGANISATION_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_ORGANISATION_PAGE_FILTER,
  payload: { filter }
});

export const postOrganisationThunk = (organisation, widgetCode) => dispatch => {
  let url = '/management/organisation/save';
  let actionName = organisation.OrganisationId === 0 ? CREATE_ORGANISATION : UPDATE_ORGANISATION;
  let log = logEntry(ORGANISATION_MANAGEMENT, actionName, organisation);
  dispatch(saveOrganisation(organisation, url, log, widgetCode));
};

export const saveOrganisation = (organisation, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    widgetcode: widgetCode,
    onLoad: status => setLoadingStatus(status),
    onSuccess: message => setOrganisationStatusThunk(message),
    onFailure: err => setOrganisationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      organisation,
      log
    }
  });

export const setOrganisationStatusThunk = message => dispatch => {
  dispatch(setOrganisationStatus(message, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setOrganisationStatus('', false));
  }, 2500);
};

export const setOrganisationStatus = (messageCode, status) => ({
  type: UPDATE_ORGANISATION_USER_SAVE_STATUS,
  payload: {
    organisation: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const describeOrganisationThunk = (organisationId, widgetCode, IsUnitTransfer = null) => dispatch => {
  let currentOrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  dispatch(describeOrganisation(organisationId, widgetCode, IsUnitTransfer, currentOrganisationId));
};

export const describeOrganisation = (organisationId, widgetCode, IsUnitTransfer = null, currentOrganisationId = 0) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/organisation/describe`,
    data: {
      OrganisationId: organisationId || undefined,
      IsUnitTransfer: IsUnitTransfer,
      CurrentOrganisationId: currentOrganisationId,
      isWQSP: true
    },
    widgetcode: widgetCode,
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setDescribeOrganisationThunk(retData),
    onFailure: err => setOrganisationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`)
  });

export const setDescribeOrganisationThunk = retData => dispatch => {
  dispatch(setDescribeOrganisation(retData));
};

export const setDescribeOrganisation = ({ retData }) => ({
  type: SET_ORGANISATION_DETAILS,
  payload: retData
});

export const updateSelectedOrganisationThunk = retData => dispatch => {
  dispatch(updateSelectedOrganisation(retData));
};

export const updateSelectedOrganisation = retData => ({
  type: UPDATE_ORGANISATION_DETAILS,
  payload: retData
});

export const setParentOrganisationDataThunk = retData => dispatch => {
  dispatch(setParentOrganisationData(retData));
};

export const setParentOrganisationData = retData => ({
  type: SET_PARENT_ORGANISATION_DETAILS,
  payload: retData
});

export const setOrganisationUsersThunk = retData => dispatch => {
  dispatch(setOrganisationUsers(retData));
};

export const setOrganisationUsers = retData => ({
  type: UPDATE_ORGANISATION_USERS,
  payload: retData
});

export const setOrganisationUnitsThunk = retData => dispatch => {
  dispatch(setOrganisationUnits(retData));
};

export const setOrganisationUnits = retData => ({
  type: UPDATE_ORGANISATION_UNITS,
  payload: retData
});
