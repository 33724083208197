import React from 'react';
import s from './RiskCategory.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const RiskCategoryMatrixView = ({ RiskCategory, ChangeRiskCategory, RemoveRiskCategory, UpdateAllowed, DeleteAllowed, intl }) => {
  const RiskMatrixData = RiskCategory.RiskMatrix || [];
  return (
    <div className={s.riskCategoryContent}>
      <Row className={s.spacedRow}>
        <Col sm={6}>
          <h3 className={s.subHeading}>{RiskCategory.Name}</h3>
        </Col>
        <Col sm={6}>
          {DeleteAllowed && (
            <Button
              variant="outline-secondary"
              className={s.removeButton}
              href="#"
              onClick={() => {
                RemoveRiskCategory(RiskCategory.RiskCategoryId);
              }}
              data-unittest="removeData"
            >
              <FormattedMessage id="common.remove" defaultMessage="REMOVE" />
            </Button>
          )}
          {UpdateAllowed && (
            <Button
              variant="outline-secondary"
              className={s.changeButton}
              href="#"
              onClick={() => {
                ChangeRiskCategory(RiskCategory.RiskCategoryId);
              }}
              data-unittest="changeData"
            >
              <FormattedMessage id="common.change" defaultMessage="CHANGE" />
            </Button>
          )}
        </Col>
      </Row>

      <Table variant className={s.innerTable}>
        <thead>
          <tr key="hd1">
            <th rowSpan="2" width="35%">
              <FormattedMessage id="riskCategory.likelihood" defaultMessage="Likelihood" />
            </th>
            <th colSpan="6">
              <FormattedMessage id="riskCategory.consequences" defaultMessage="Severity Descriptors" />
            </th>
          </tr>
          <tr key="hd2">
            <th width="13%">
              <FormattedMessage id="riskCategory.insignificant" defaultMessage="Insignificant" />
              <p>{RiskCategory.InsignificantDescription}</p>
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.minor" defaultMessage="Minor" />
              <p>{RiskCategory.MinorDescription}</p>
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.moderate" defaultMessage="Moderate" />
              <p>{RiskCategory.ModerateDescription}</p>
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.major" defaultMessage="Major" />
              <p>{RiskCategory.MajorDescription}</p>
            </th>
            <th width="13%">
              <FormattedMessage id="riskCategory.catastropic" defaultMessage="Catastropic" />
              <p>{RiskCategory.CatastrophicDescription}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {RiskMatrixData.map(n => {
            return <MatrixRow matrixRow={n} riskRating={RiskCategory} key={n.RiskLikelihoodId} />;
          })}
        </tbody>
      </Table>
      <RiskRatingScore scoreRow={RiskCategory} />
    </div>
  );
};

export default injectIntl(RiskCategoryMatrixView);

const getCellColor = (value, riskRating) => {
  if (riskRating.LowRatingScore >= value) {
    return 'tdGreen';
  }
  if (riskRating.MediumRatingScore >= value) {
    return 'tdYellow';
  }
  if (riskRating.HighRatingScore >= value) {
    return 'tdOrange';
  }
  if (riskRating.HighRatingScore < value) {
    return 'tdRed';
  }
};

const MatrixRow = ({ matrixRow, riskRating }) => {
  return (
    <tr>
      <td>
        <div>{matrixRow.RiskLikelihood}</div>
        <div className={s.normalItalicText}>{matrixRow.Description}</div>
      </td>
      <td className={s[getCellColor(matrixRow.InsignificantValue, riskRating)]}>
        <span>{matrixRow.InsignificantValue}</span>
      </td>
      <td className={s[getCellColor(matrixRow.MinorValue, riskRating)]}>
        <span>{matrixRow.MinorValue}</span>
      </td>
      <td className={s[getCellColor(matrixRow.ModerateValue, riskRating)]}>
        <span>{matrixRow.ModerateValue}</span>
      </td>
      <td className={s[getCellColor(matrixRow.MajorValue, riskRating)]}>
        <span>{matrixRow.MajorValue}</span>
      </td>
      <td className={s[getCellColor(matrixRow.CatastrophicValue, riskRating)]}>
        <span>{matrixRow.CatastrophicValue}</span>
      </td>
    </tr>
  );
};

// const ConsequenceDescription = ({ descriptionData, intl }) => {

//   let content = null;

//   if (descriptionData.InsignificantDescription) {
//     content += <p><b> <FormattedMessage id="riskCategory.insignificant" defaultMessage="Insignificant" /></b> : {descriptionData.InsignificantDescription} </p>
//   }

//   if (descriptionData.MinorDescription) {
//     content += <p><b>{intl.formatMessage({ id: 'riskCategory.minor', defaultMessage: 'Minor' })}</b> : {descriptionData.MinorDescription} </p>
//   }
//   if (descriptionData.ModerateDescription) {
//     content += <p><b>{intl.formatMessage({ id: 'riskCategory.moderate', defaultMessage: 'Moderate' })}</b> : {descriptionData.ModerateDescription} </p>
//   }
//   if (descriptionData.MajorDescription) {
//     content += <p><b>{intl.formatMessage({ id: 'riskCategory.major', defaultMessage: 'Major' })}</b> : {descriptionData.MajorDescription} </p>
//   }
//   if (descriptionData.CatastrophicDescription) {
//     content += <p><b>{intl.formatMessage({ id: 'riskCategory.catastropic', defaultMessage: 'Catastropic' })}</b> : {descriptionData.CatastrophicDescription} </p>
//   }

//   return (
//     <div className={s.consequnceInfo}>
//       <ul className={s.listStyling}>
//         {descriptionData.InsignificantDescription && <li><b> <FormattedMessage id="riskCategory.insignificant" defaultMessage="Insignificant" /></b> : {descriptionData.InsignificantDescription} </li>}
//         {descriptionData.MinorDescription && <li><b> <FormattedMessage id="riskCategory.minor" defaultMessage="Minor" /></b> : {descriptionData.MinorDescription} </li>}
//         {descriptionData.ModerateDescription && <li><b> <FormattedMessage id="riskCategory.moderate" defaultMessage="Moderate" /></b> : {descriptionData.ModerateDescription} </li>}
//         {descriptionData.MajorDescription && <li><b> <FormattedMessage id="riskCategory.major" defaultMessage="Major" /></b> : {descriptionData.MajorDescription} </li>}
//         {descriptionData.CatastrophicDescription && <li><b> <FormattedMessage id="riskCategory.catastropic" defaultMessage="Catastropic" /></b> : {descriptionData.CatastrophicDescription} </li>}
//       </ul>
//     </div>
//   );
// };

const RiskRatingScore = ({ scoreRow }) => {
  return (
    <Table variant className={s.innerTable}>
      <tbody>
        <tr className={s.riskScoring}>
          <td width="48%">
            <FormattedMessage id="riskCategory.riskRating" defaultMessage="Risk Rating" />
          </td>
          <td width="13%" className={s.tdGreen}>
            <FormattedMessage id="riskCategory.low" defaultMessage="Low" />
            <span className={s.ratingSpace}> {' <= '} </span> <span className={s.ratingSpace}> {scoreRow.LowRatingScore}</span>
          </td>
          <td width="13%" className={s.tdYellow}>
            <FormattedMessage id="riskCategory.medium" defaultMessage="Medium" />
            <span className={s.ratingSpace}> {' <= '} </span> <span className={s.ratingSpace}> {scoreRow.MediumRatingScore}</span>
          </td>
          <td width="13%" className={s.tdOrange}>
            <FormattedMessage id="riskCategory.high" defaultMessage="High" />
            <span className={s.ratingSpace}> {' <= '} </span> <span className={s.ratingSpace}> {scoreRow.HighRatingScore}</span>
          </td>
          <td width="13%" className={s.tdRed}>
            <FormattedMessage id="riskCategory.veryHigh" defaultMessage="Very High" />
            <span className={s.ratingSpace}> {' > '} </span> <span className={s.ratingSpace}> {scoreRow.HighRatingScore}</span>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
