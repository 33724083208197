import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './ProfileSettings.module.scss';
import LoadingSpinner from '..//LoadingSpinner/LoadingSpinner';
import { isValidPassword } from '../../utils/';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Banner from '../Banner/Banner';
import { FormattedMessage, injectIntl } from 'react-intl';
import Cookies from 'js-cookie';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const PasswordChange = ({ submitPassword, editPassword, userManager, intl }) => {
  const [form, setForm] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
  const [formErrors, setFormErrors] = useState({ currentPassword: false, newPassword: false, confirmPassword: false });
  const accessToken = Cookies.get(`access-${envName}`) || '';

  let messageId = (userManager && userManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const handleInput = e => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const currentPasswordError = !isValidPassword(form.currentPassword);
    const passwordError = !isValidPassword(form.newPassword);
    const confirmPasswordError = form.newPassword !== form.confirmPassword;

    setFormErrors({
      currentPassword: currentPasswordError,
      newPassword: passwordError,
      confirmPassword: confirmPasswordError
    });

    if (!currentPasswordError && !passwordError && !confirmPasswordError && accessToken !== '') {
      const item = {
        accessToken: accessToken,
        previousPassword: form.currentPassword,
        proposedPassword: form.newPassword
      };
      submitPassword(item);
    }
  };

  return (
    <div className="p-5">
      <Banner
        key="banner"
        failureText={messageText}
        showBanner={userManager.showBanner}
        status={userManager.isOpSuccessfull}
        successText={messageText}
      />
      {userManager.isLoading && <LoadingSpinner />}
      {userManager.isOpSuccessfull && !userManager.showBanner ? editPassword() : ''}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <h4>
              <FormattedMessage id="profileSettings.password" defaultMessage="Password" />
            </h4>
          </Col>
          <Button variant="outline-secondary" className={s.button} onClick={editPassword}>
            <FormattedMessage id="profile.profileCancel" defaultMessage="Cancel" />
          </Button>
          <Button disabled={userManager.isLoading} type="submit" className={s.saveButton}>
            <FormattedMessage id="save" defaultMessage="Save" />
          </Button>
        </Row>

        <Form.Label>
          <FormattedMessage id="passwordUpdate.currentpassword" defaultMessage="Current password" />
        </Form.Label>
        <Form.Control
          type="password"
          name="currentPassword"
          value={form.currentPassword}
          onChange={handleInput}
          className={`col-md-6 col-xs-12 ${formErrors.currentPassword ? s.formControlError : ''}`}
        />
        {formErrors.currentPassword && (
          <p role="alert" className={s.error}>
            <FormattedMessage
              id="passwordUpdate.passwordError"
              defaultMessage="Password must contain at least 1 uppercase, 1 lowercase and 10 characters"
            />
          </p>
        )}

        <Form.Label>
          <FormattedMessage id="passwordUpdate.newPassword" defaultMessage="New password" />
        </Form.Label>
        <Form.Control
          type="password"
          name="newPassword"
          value={form.newPassword}
          onChange={handleInput}
          className={`col-md-6 col-xs-12 ${formErrors.newPassword ? s.formControlError : ''}`}
        />

        {formErrors.newPassword && (
          <p role="alert" className={s.error}>
            <FormattedMessage
              id="passwordUpdate.passwordError"
              defaultMessage="Password must contain at least 1 uppercase, 1 lowercase and 10 characters"
            />
          </p>
        )}

        <Form.Label>
          <FormattedMessage id="passwordUpdate.confirmPassword" defaultMessage="Confirm password" />{' '}
        </Form.Label>
        <Form.Control
          type="password"
          name="confirmPassword"
          value={form.confirmPassword}
          onChange={handleInput}
          className={`col-md-6 col-xs-12 ${formErrors.confirmPassword ? s.formControlError : ''}`}
        />

        {formErrors.confirmPassword && (
          <p role="alert" className={s.error}>
            <FormattedMessage id="passwordUpdate.confirmPasswordError" defaultMessage="Password does not match" />
          </p>
        )}
      </Form>
    </div>
  );
};

PasswordChange.propTypes = {
  submitPassword: PropTypes.func.isRequired
};

export default injectIntl(PasswordChange);
