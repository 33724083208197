import React from 'react';
import PropTypes from 'prop-types';
import s from './Layout.module.scss';
import expandBlue from '../../assets/expand-blue.svg';
import { TEST_ENV, STAGING_ENV, PRODUCTION_ENV } from '../../constants/index';
import Sidebar from '../Sidebar/SidebarContainer';
import classNames from 'classnames';

const Layout = ({ isAuthenticated, children, logout, currentSidebarOpen, handleViewSidebar, location, userProfileWidgets }) => {
  const sidebarClass = currentSidebarOpen ? '' : s.active;

  const envName = process.env.REACT_APP_ENV_NAME;
  let envClass = s.bgColorDev;
  if (envName === TEST_ENV) {
    envClass = s.bgColorTest;
  } else if (envName === STAGING_ENV) {
    envClass = s.bgColorStage;
  }

  return (
    <div>
      {envName !== PRODUCTION_ENV && envName !== STAGING_ENV && (
        <div className={`${s.envBar} ${envClass}`}>You are in {process.env.REACT_APP_ENV_NAME} environment</div>
      )}

      <div className={s.wrapper}>
        <Sidebar logout={logout} location={location} />
        <div className={s.contentWrapper}>
          <nav className={classNames(`${s.hideDesktop} navbar navbar-expand-lg`)}></nav>
          <div className={classNames(sidebarClass, s.overlay)} onClick={() => handleViewSidebar(true)}></div>
          {children}

          <div className="container-fluid">
            <img
              src={expandBlue}
              alt="Collapse"
              className={classNames(s.sidebarCollapse, s.hideDesktop)}
              onClick={() => handleViewSidebar(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Layout.defaultProps = {
  isAuthenticated: false
};

Layout.propTypes = {
  isAuthenticated: PropTypes.bool,
  logout: PropTypes.func,
  handleViewSidebar: PropTypes.func,
  currentSidebarOpen: PropTypes.bool,
  userProfileWidgets: PropTypes.object
};

export default Layout;
