import { initialRiskCategoryState } from './initialState';

import {
  RISK_CATEGORY_ERROR,
  GET_ALL_RISK_CATEGORY_DETAILS,
  RISK_CATEGORY_IS_LOADING,
  SET_RISK_CATEGORY_CURRENT_PAGE,
  SET_RISK_CATEGORY_PAGE_FILTER,
  SET_RISK_CATEGORY_CHANGE,
  SET_RISK_CATEGORY_MATRIX_CHANGE,
  SET_RISK_CATEGORY_CLEANUP,
  SET_RISK_CATEGORY,
  UPDATE_RISK_CATEGORY_SAVE_STATUS,
  RISK_CATEGORY_FILTER_DATA
} from '../../constants/index';

const riskCategoryReducer = (state = initialRiskCategoryState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ALL_RISK_CATEGORY_DETAILS: {
      const { data } = payload;
      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          ...data
        }
      };
    }

    case SET_RISK_CATEGORY_CURRENT_PAGE: {
      const { currentPage } = payload;
      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          currentPage
        }
      };
    }

    case SET_RISK_CATEGORY_PAGE_FILTER: {
      const { filter } = payload;
      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          filterBy: {
            ...filter
          }
        }
      };
    }

    case UPDATE_RISK_CATEGORY_SAVE_STATUS: {
      const { data } = payload;
      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          ...data
        }
      };
    }

    case RISK_CATEGORY_IS_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          isLoading: isLoading
        }
      };
    }

    case RISK_CATEGORY_ERROR: {
      const { data } = payload;
      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          ...data
        }
      };
    }

    case SET_RISK_CATEGORY: {
      let { data } = payload;

      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          isOpSuccessful: false,
          showBanner: false,
          SelectedRiskCategory: {
            ...state.riskCategory.SelectedRiskCategory,
            ...data
          }
        }
      };
    }

    case SET_RISK_CATEGORY_CHANGE: {
      let { SelectedRiskCategory } = payload;

      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          isOpSuccessful: false,
          showBanner: false,
          SelectedRiskCategory: {
            ...state.riskCategory.SelectedRiskCategory,
            ...SelectedRiskCategory
          }
        }
      };
    }

    case SET_RISK_CATEGORY_MATRIX_CHANGE: {
      let { RiskMatrix } = payload;

      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          isOpSuccessful: false,
          showBanner: false,
          SelectedRiskCategory: {
            ...state.riskCategory.SelectedRiskCategory,
            RiskMatrix: RiskMatrix
          }
        }
      };
    }

    case SET_RISK_CATEGORY_CLEANUP: {
      let SelectedRiskCategory = payload;
      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          isOpSuccessful: false,
          showBanner: false,
          isLoading: false,
          isRedirect: false,
          SelectedRiskCategory: SelectedRiskCategory
        }
      };
    }

    case RISK_CATEGORY_FILTER_DATA: {
      const { filteredResults } = payload;
      return {
        ...state,
        riskCategory: {
          ...state.riskCategory,
          ...filteredResults
        }
      };
    }

    default:
      return state;
  }
};

export default riskCategoryReducer;
