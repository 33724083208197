import React from 'react';
import PropTypes from 'prop-types';
import s from './Checklists.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';
import { CHECKLIST_STATUS_ACTIVE } from '../../constants/index';
import ascendingIcon from '../../assets/arrow-up.png';
import descendingIcon from '../../assets/arrow-down.png';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { getOverdueStatus } from '../../utils';

const ChecklistTable = ({ paginatedData, ViewAllowed, onSortOrder, asendingOrder, currentTimezone }) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.name" defaultMessage="Name" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="checklistManagement.tasksCompleted" defaultMessage="Tasks Completed" />
            </th>
            <th className={`d-none d-md-table-cell ${s.sortHeading}`}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="wqsp.common.duedate" defaultMessage="Due Date" />{' '}
                <img className={s.sortIcon} src={sortIcon} alt={''} />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="emergencyPlan.emergencyResponsePlans" defaultMessage="Emergency Response Plan" />
            </th>
            <th>
              <FormattedMessage id="common.status" defaultMessage="Status" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr
                key={n.ChecklistId}
                data-unittest="checklistData"
                className={n.Status === CHECKLIST_STATUS_ACTIVE ? s.active : s.complete}
              >
                <td>{n.Name || ''}</td>
                <td className="d-none d-md-table-cell">
                  {n.NoOfTask}/{n.NoOfCompletedTasks}
                </td>
                <td className="d-none d-md-table-cell">
                  {n.DueDateTime
                    ? moment
                        .unix(parseInt(n.DueDateTime))
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                  <span className={s.overDue}>{n.Status === CHECKLIST_STATUS_ACTIVE && getOverdueStatus(n.DueDateTime)}</span>
                </td>
                <td>
                  {n.IsERP ? (
                    <FormattedMessage id="common.yes" defaultMessage="Yes" />
                  ) : (
                    <FormattedMessage id="common.no" defaultMessage="No" />
                  )}
                </td>
                <td className={s.status}>
                  <span className={`${s.dot} ${n.Status === CHECKLIST_STATUS_ACTIVE ? s.dotActive : s.dotComplete}`}></span>{' '}
                  {n.Status || ''}
                </td>

                <td>
                  {ViewAllowed && (
                    <div className={s.btnView}>
                      <Link to={`/tasks-and-checklists/checklist-detail/${n.ChecklistId}?view=1`}>
                        <Button variant="outline-secondary" data-unittest="view" className={s.viewButton}>
                          <FormattedMessage id="common.view" defaultMessage="VIEW" />
                        </Button>
                      </Link>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

ChecklistTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

ChecklistTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(ChecklistTable);
