import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import s from './MapView.module.scss';
import bs from '../../../../../styles/bootstrap-overrides.scss';
import SiteSegmentTile from '../SiteSegmentsTile/SiteSegmetsTile';
import LoadingSpinner from '../../../../LoadingSpinner/LoadingSpinner';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dropletClusterMarker from '../../../../../assets/droplet-with-number.png';
import fullScreen from '../../../../../assets/fullscreen-icon.svg';
import SearchBar from '../../../../SearchBar/SearchBar';
import Map from '../../../Map/Map';
import Markers from '../../../Map/Markers';
import MapInfoWindow from '../../../Map/MapInfoWindow';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Dropdown from '../../../../WSAControls/DroprdownContainer/Dropdown';
import closeButton from '../../../../../assets/close-button-icon.svg';

class MapView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapLoaded: false,
      markerClustererLoaded: false,
      markerDrawn: false,
      showMapOverlay: false,
      elementId: null,
      element: null,
      position: {},
      searchQuery: {
        keywords: '',
        type: '0'
      },
      isFullScreen: false
    };

    this.mapOverlay = {};

    this.dropDownData = [
      {
        label: 'Show:all',
        value: '0',
        isDisabled: false
      },
      {
        label: this.props.intl.formatMessage({ id: 'siteSegments.site', defaultMessage: 'Site' }),
        value: 'SITE',
        isDisabled: false
      },
      {
        label: this.props.intl.formatMessage({ id: 'siteSegments.waterSupplyDescription', defaultMessage: 'Water Supply Description' }),
        value: 'WSD',
        isDisabled: false
      },
      {
        label: this.props.intl.formatMessage({ id: 'siteSegments.entity', defaultMessage: 'Entity1' }),
        value: 'ENTITY',
        isDisabled: false
      },
      {
        label: this.props.intl.formatMessage({ id: 'siteSegments.hazardEvent', defaultMessage: 'Hazard Event' }),
        value: 'HAZARDEVENT',
        isDisabled: false
      }
    ];

    this.defaultLocation = { lat: -37.840935, lng: 144.946457 };
  }

  googleMapRef = React.createRef();

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  onMapClick = map => {
    this.removeMapOverlay(map);
  };

  onMapLoad = map => {
    this.setState({ mapLoaded: true });
  };

  onMarkerClusterLoad = markerCluster => {
    this.markerCluster = markerCluster;
    this.setState({ markerClustererLoaded: true });
  };

  handleTileClick = () => {
    if (this.props.handleClick && this.state.element.url) {
      this.props.handleClick(this.state.element.url);
    }
  };

  onMarkerClick = marker => {
    if (marker && marker.element && marker.element.elementId) {
      this.setState({
        ...this.state,
        showMapOverlay: true,
        position: marker.position,
        element: marker.element,
        elementId: marker.element.elementId
      });
    }
  };

  onMarkerReDrawn = () => {
    //Code to execute on marker redrawn
    if (!this.state.markerDrawn) {
      this.setState({ markerDrawn: true });
    }
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  onMarkerDrawn = markerObj => {
    if (!this.state.markerDrawn) {
      this.setState({ markerDrawn: true });
    }
    this.removeMapOverlay();
  };

  onMarkerRemoved = markerObj => {
    this.removeMapOverlay();
  };

  removeMapOverlay = map => {
    this.setState({
      ...this.state,
      showMapOverlay: false,
      element: null,
      position: {},
      elementId: null
    });

    if (this.mapOverlay && this.mapOverlay.overlay && this.mapOverlay.overlay.setMap) {
      this.mapOverlay.overlay.setMap(null);
    }
  };

  onSearchKeywordChange = keyword => {
    this.removeMapOverlay();

    this.setState({
      searchQuery: {
        ...this.state.searchQuery,
        keywords: keyword
      }
    });
  };

  onSearchTypeChange = type => {
    this.removeMapOverlay();

    this.setState({
      searchQuery: {
        ...this.state.searchQuery,
        type: type.value
      }
    });
  };

  performSearch = elements => {
    if (this.state.searchQuery.keywords) {
      elements = elements.filter(element => element.searchName.toLowerCase().indexOf(this.state.searchQuery.keywords.toLowerCase()) >= 0);
    }
    let type = this.state.searchQuery.type;
    if (type !== '0') {
      elements = elements.filter(element => element.type === type);
    }

    return elements;
  };

  setOverlay = overlay => {
    this.mapOverlay = overlay;
  };

  onFullscreenButtonClick = id => {
    this.setState({ isFullScreen: true });

    let element = document.getElementById('mapViewContainer');
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
    if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
    if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    }
  };

  onFullScreenCloseButtonClick = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  onFullScreenExit = () => {
    this.setState({ isFullScreen: false });
  };

  render() {
    let formattedElements = this.props.elements;
    formattedElements = this.performSearch(formattedElements);

    return (
      <div className={s.mapContainer} id="mapViewContainer">
        {!this.state.markerDrawn && <LoadingSpinner centeredLoading={false} />}
        <Row className={this.state.isFullScreen ? `d-md-flex ${s.fullScreenSearch}` : 'd-none d-md-flex'}>
          <div className={s.searchBarContainer}>
            <SearchBar searchHandler={this.onSearchKeywordChange} clearSearchInVisible={true} placeHolderTextId="element.location" />
          </div>
          <Col xs={12} md={4} lg={3} xl={2} className={s.mapDropdown}>
            {this.state.isFullScreen ? (
              <Row>
                <Col xs={6} className={'d-md-none'}>
                  <img
                    src={closeButton}
                    onClick={this.onFullScreenCloseButtonClick}
                    alt="close"
                    style={{ height: '30px', marginLeft: '10px' }}
                  />
                </Col>
                <Col xs={6} className={`d-md-none ${s.mapSearchDropdown}`}>
                  <Dropdown
                    dataArray={this.dropDownData}
                    controlData={{ placeholderText: 'Show: All', customClassName: s.dropdownClass }}
                    onDropdownChange={this.onSearchTypeChange}
                  />
                </Col>
              </Row>
            ) : (
              <Dropdown
                dataArray={this.dropDownData}
                controlData={{ placeholderText: 'Show: All', customClassName: '' }}
                onDropdownChange={this.onSearchTypeChange}
              />
            )}
          </Col>
        </Row>

        <Map
          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          mapContainerCssStyle={s.mapContainerStyle}
          onMapClick={this.onMapClick}
          onMapLoad={this.onMapLoad}
          onMarkerClusterLoad={this.onMarkerClusterLoad}
          fullScreenIcon={fullScreen}
          onFullScreenButtonClick={this.onFullscreenButtonClick}
          onFullScreenExit={this.onFullScreenExit}
          mapContainerStyleFullScreen={s.mapContainerStyleFullScreen}
        >
          <MapInfoWindow
            handleClick={() => {
              this.handleTileClick();
            }}
            position={this.state.position}
            onOverlayDrawn={this.setOverlay}
            hideOverlay={!this.state.showMapOverlay}
          >
            <ul className={s.elementWrapper} elementid={this.state.elementId}>
              <SiteSegmentTile
                element={this.state.element}
                elementId={this.state.elementId}
                handleClick={this.handleTileClick}
                currentTimezone={this.props.currentTimezone}
                languageObject={this.props.languageObject}
              />
            </ul>
          </MapInfoWindow>

          {this.state.mapLoaded && this.state.markerClustererLoaded && (
            <Markers
              onClick={this.onMarkerClick}
              onMarkerDrawn={this.onMarkerDrawn}
              onMarkerReDrawn={this.onMarkerReDrawn}
              onMarkerRemoved={this.onMarkerRemoved}
              elements={formattedElements}
              markerCluster={this.markerCluster}
              markerClusterIcon={dropletClusterMarker}
              defaultLocation={this.defaultLocation}
            />
          )}
        </Map>
        {!this.state.isFullScreen && (
          <div className={`container-fluid ${s.seeAllSites}`}>
            <Row className={s.alignCenter}>
              <Col md={5}>
                <hr />
              </Col>
              <Col md={2} xs={12}>
                <Link to={'/sites'}>
                  <Button>
                    <FormattedMessage id="mapview.seeallelements" defaultMessage="SEE ALL SITES" />
                  </Button>
                </Link>
              </Col>
              <Col md={5}>
                <hr />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

MapView.defaultProps = {
  elements: []
};

MapView.propTypes = {
  elements: PropTypes.array,
  handleClick: PropTypes.func.isRequired
};

export default injectIntl(MapView);
