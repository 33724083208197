import defaultLocaleProvider from '../../services/defaultLocaleProvider';
import defaultTimezoneProvider from '../../services/defaultTimezoneProvider';
import moment from 'moment-timezone';
import { LOW_RISK } from '../../constants/index';

export const initialUserState = {
  loginError: false,
  loginErrorMessage: '',
  isforgotPassword: false
};

export const initialUserManagerState = {
  userManager: {
    users: [],
    selectedUser: {
      UserId: '',
      GuidIdentifier: '',
      Email: '',
      PhoneNumber: '',
      FirstName: '',
      LastName: '',
      FullName: '',
      DeliveryType: '',
      OrganisationId: 0,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      IsActive: true,
      IsDeleted: false,
      selectedProfiles: []
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialOrganisationManagerState = {
  organisationManager: {
    organisations: [],
    defaultProfiles: [],
    selectedOrganisation: {
      OrganisationId: '',
      GuidIdentifier: '',
      ParentOrganisationId: '',
      DefaultProfileId: '',
      OrganisationLogo: '',
      Name: '',
      Town: '',
      Region: '',
      Country: '',
      PostCode: '',
      CreatedDate: '',
      UpdatedDate: '',
      CreateddByUserId: '',
      ModifiedByUserId: '',
      UnitManagerId: 0,
      UnitTechnicianId: 0,
      UpdatedBy: '',
      IsActive: true,
      IsDeleted: false,
      Users: [],
      Units: [],
      SubOrganisations: [],
      TransferOrganisationId: 0,
      TransferUnits: [],
      SelectedTransferUnits: []
    },
    parentOrganisationData: {
      parentOrganisationUsers: [],
      parentOrganisationUnits: [],
      toFilterParentOrgUsers: [],
      toFilterParentOrgUnits: []
    },
    users: [],
    units: [],
    orgModelVersions: [],
    orgModels: [],
    toFilterUsers: [],
    toFilterUnits: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialUiState = {
  isLoading: false
};

export const initialProfileState = {
  profile: {
    profiles: [],
    selectedProfile: {
      ProfileId: 0,
      Name: '',
      Description: '',
      GuidIdentifier: '',
      IsMFAEnabled: false,
      IsActive: true,
      IsDefault: false,
      ProfileWigets: [],
      formValid: false,
      errorCount: null,
      errors: {
        Name: { required: true, error: '', value: '' },
        Description: { required: true, error: '', value: '' }
      }
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: true,
    errorMessage: '',
    isError: true,
    redirect: false
  },
  eventsError: false
};

export const initialLocaleState = {
  locale: defaultLocaleProvider(navigator)
};

export const initialTimezoneState = {
  timezone: defaultTimezoneProvider()
};

export const initialAppWidgetState = {
  appWidgets: {
    appWidgets: [],
    isLoading: false,
    isOpSuccessfull: false,
    showBanner: false
  }
};

export const initialSidebarState = {
  sidebarOpen: true
};

export const initialForgotPasswordState = {
  forgotPassword: {
    isLoading: false,
    verificationSent: false,
    isOpSuccessful: false,
    showMessage: false,
    displayMessageCode: ''
  }
};

export const initialUnitManagerState = {
  unitManager: {
    units: [],
    selectedUnit: {
      UnitId: 0,
      ModelId: 0,
      OrganisationId: 0,
      GuidIdentifier: '',
      Name: '',
      UnitNumber: '',
      Description: '',
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      IsTaggedOut: 0,
      ModifiedByUserId: 0,
      CommissionGpsLatitude: null,
      CommissionGpsLongitude: null,
      WarrantyStartDate: null,
      WarrantyEndDate: null,
      IsActive: true,
      IsDeleted: false,
      unitComponentPositions: [],
      tagoutDetails: {
        IsTaggedOut: 0,
        UnitTagOutId: 0
      },
      InstallationStreetAddress: '',
      InstallationCity: '',
      InstallationRegion: '',
      InstallationPostCode: '',
      InstallationCountry: ''
    },
    unitIotInfo: {
      privateKeyData: {
        filename: '',
        data: ''
      },
      certificateData: {
        filename: '',
        data: ''
      }
    },
    isLoading: false,
    isDataRendered: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 20,
    currentPage: 1,
    filteredResults: [],
    filterData: {
      unitRegions: []
    },
    filterRegion: null
  }
};

export const initialChecklistTemplateState = {
  checklistTemplate: {
    ChecklistTemplates: [],
    TaskTemplates: [],
    SelectedChecklistTemplate: {
      ChecklistTemplateId: 0,
      Name: '',
      OrganisationId: 0,
      Description: '',
      IsERP: false,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      SelectedTaskTemplates: []
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialChecklistState = {
  checklist: {
    Checklists: [],
    Tasks: [],
    SelectedChecklist: {
      ChecklistId: 0,
      Name: '',
      OrganisationId: 0,
      Description: '',
      IsERP: false,
      DueDateTime: 0,
      Status: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      SelectedTasks: []
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    checklistCount: 0,
    filteredResults: [],
    filterBy: {
      filter: null,
      filterStatus: null,
      filterERP: null
    },
    limit: 1000,
    currentPage: 1
  }
};

export const initialTaskTemplatesState = {
  taskTemplate: {
    TaskTemplates: [],
    SelectedTaskTemplate: {
      TaskTemplateId: 0,
      OrganisationId: 0,
      Name: '',
      Description: '',
      IsERP: false,
      GuidIdentifier: '',
      Activities: '',
      EstimatedEffort: 0,
      AssignedToProfileId: null,
      ProfileName: '',
      IsActive: '',
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      UpdatedBy: ''
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialTasksState = {
  tasks: {
    taskList: [],
    selectedTask: {
      TaskId: 0,
      OrganisationId: 0,
      ChecklistId: null,
      Name: '',
      Description: '',
      IsERP: false,
      GuidIdentifier: '',
      Activities: '',
      EstimatedEffort: 0,
      ActualEffort: 0,
      AssignedToProfileId: null,
      AssignedToUserId: null,
      AssignedByUserId: null,
      AssignedUsername: '',
      AssignedByUsername: '',
      ProfileName: '',
      Status: '',
      IsActive: '',
      DueDateTime: 0,
      CompletedOnDateTime: 0,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: null,
      UpdatedBy: '',
      Comments: [],
      MarkAsComplete: false,
      MarkAsDeleted: false,
      UpdateActualEffort: false
    },
    taskListCount: 0,
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filteredResults: [],
    filterBy: {
      filter: null,
      filterStatus: null,
      filterAssignedByUser: null,
      filterCreatedByUser: null,
      filterERP: null
    },
    limit: 1000,
    currentPage: 1
  }
};

export const initialTestPurposeState = {
  testPurpose: {
    TestPurposes: [],
    selectedTestPurpose: {
      TestPurposeId: 0,
      Name: '',
      OrganisationId: 0,
      Description: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    isRedirect: false,
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialInspectionPurposeState = {
  inspectionPurpose: {
    InspectionPurposes: [],
    SelectedInspectionPurpose: {
      InspectionPurposeId: 0,
      Name: '',
      Description: '',
      OrganisationId: 0,
      Description: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialTestCategoryState = {
  testCategory: {
    TestCategories: [],
    selectedTestCategory: {
      TestCategoryId: 0,
      Name: '',
      Description: '',
      OrganisationId: 0,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      removedTestCategoryTypeIds: [],
      testCategoryTypes: [],
      selectedTestCategoryType: {
        TestCategoryItemId: 0,
        Name: '',
        Description: '',
        UnitOfMeasure: '',
        RealisticMeanValue: '',
        RealisticMaxValue: '',
        RealisticLRV: '',
        AspirationalMeanValue: '',
        AspirationalMaxValue: '',
        AspirationalLRV: '',
        GuidIdentifier: ''
      }
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    isRedirect: false,
    displayMessageCode: '',
    filter: undefined,
    isRedirect: false,
    limit: 1000,
    currentPage: 1
  }
};

export const initialInspectionTypeState = {
  inspectionType: {
    InspectionTypes: [],
    SelectedInspectionType: {
      InspectionTypeId: 0,
      Name: '',
      GeneralInformation: '',
      Description: '',
      OrganisationId: 0,
      Description: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialInspectionTypeQuestionState = {
  inspectionTypeQuestion: {
    InspectionTypeQuestions: [],
    InspectionTypes: [],
    SelectedInspectionTypeQuestion: {
      InspectionTypeQuestionId: 0,
      InspectionTypeId: 0,
      QuestionNumber: 1,
      QuestionText: '',
      Score: 0,
      ExplanatoryNote: '',
      OrganisationId: 0,
      Description: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialGeneralDataState = {
  generalData: {
    sites: [],
    waterSupplyDescriptions: [],
    entityTypes: [],
    entitySubTypes: [],
    generalFieldTypes: [],
    entities: [],
    isDataPresent: false,
    isLoading: false,
    isOpSuccessfull: false,
    showBanner: false,
    redirect: false
  }
};

export const initialInspectionReportState = {
  inspectionReport: {
    InspectionTypes: [],
    InspectionPurposes: [],
    InspectionReports: [],
    SelectedInspectionReport: {
      InspectionReportId: 0,
      InspectionPurposeId: 0,
      EntityTypeId: 0,
      EntityId: 0,
      InspectionTypeId: 0,
      InspectionDate: 0,
      GuidIdentifier: '',
      Title: '',
      InspectorName: '',
      ExplanatoryNote: '',
      WeatherCondition: '',
      GeneralInformation: '',
      RiskScore: 0,
      RiskRating: LOW_RISK,
      AdditionalObservation: '',
      OrganisationId: 0,
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      ReviewFrequencyName: '',
      StatusName: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      AssesmentQuestions: [],
      IncidentReports: []
    },
    filteredResults: [],
    filterBy: {
      filterEntityType: null,
      filterEntity: null,
      filterInspectionType: null,
      filter: null
    },
    inspectionReportCount: 0,
    filterData: {
      InspectionTypes: []
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialTestResultState = {
  testResult: {
    testResults: [],
    selectedTestResult: {
      TestResultReportId: 0,
      TestPurposeId: 0,
      TestPurpose: '',
      SiteId: 0,
      SiteName: '',
      WaterSupplyDescriptionId: 0,
      WaterSupplyDescription: '',
      EntityTypeId: 0,
      EntityId: 0,
      Entity: '',
      EntitySubTypeId: 0,
      SampleDate: moment().unix(),
      SampleIdNumber: '',
      SamplerName: '',
      AnalysisCompletedBy: '',
      AnalysisDate: 0,
      SubmissionDate: 0,
      AnalyserName: '',
      Address: '',
      Comments: '',
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      ReviewFrequencyName: '',
      StatusName: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      resultItems: [],
      files: [],
      deletedFileIds: [],
      testCategories: [],
      testPurposes: [],
      incidentReports: [],
      selectedTestResultItem: {
        TestResultReportItemId: 0,
        TestCategoryItemId: 0,
        GuidIdentifier: '',
        Name: '',
        UnitOfMeasure: '',
        Value: ''
      }
    },
    filteredResults: [],
    testResultListCount: 0,
    filterBy: {
      filterSiteId: null,
      filterEntityId: null,
      filterEntityTypeId: null,
      filterTestPurposeId: null
    },
    filterData: {
      TestPurposes: []
    },
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialSiteState = {
  site: {
    Sites: [],
    selectedView: null,
    selectedSite: {
      SiteId: 0,
      Name: '',
      OrganisationId: 0,
      Households: 0,
      Inhabitants: 0,
      Region: '',
      GpsLatitude: null,
      GpsLongitude: null,
      Location: '',
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      Description: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      deletedFileIds: [],
      files: []
    },
    filteredResults: [],
    filterBy: {
      filter: null
    },
    siteListCount: 0,
    filterData: {},
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    isRedirect: false,
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialEntityState = {
  entity: {
    Entities: [],
    SelectedEntity: {
      SiteId: 0,
      EntityId: 0,
      WaterSupplyDescriptionId: 0,
      Name: '',
      EntityTypeId: 0,
      EntitySubTypeId: 0,
      EntityType: '',
      InspectionFrequency: 0,
      InspectionFrequencyName: '',
      DateOfLastInspection: 0,
      DateOfLastService: 0,
      EntityCondition: 0,
      EntityConditionName: '',
      MaintenanceFrequency: 0,
      MaintenanceFrequencyName: '',
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      CategoryOfWater: 0,
      Description: '',
      GuidIdentifier: '',
      OrganisationId: 0,
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      VolumeSupplied: 0,
      TypeofSupply: 0,
      VolumeStored: 0,
      AbstractionPurpose: 0,
      StoragePurpose: 0,
      LengthOfTransfer: '',
      SourceEntityId: 0,
      DestinationEntityId: 0,
      VolumeExtracted: 0,
      DateofInstallation: 0,
      TreatmentType: 0,
      GpsLatitude: null,
      GpsLongitude: null,
      HazardEventTemplateItems: [],
      Files: [],
      deletedFileIds: []
    },
    filteredResults: [],
    filterBy: {
      filter: null,
      filterEntityType: null,
      filterEntitySubType: null,
      filterWaterSupplyDescription: null
    },
    entityListCount: 0,
    filterData: {},
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialQualityGoalsState = {
  qualityGoals: {
    qualityGoals: [],
    selectedQualityGoals: {
      QualityGoalReportId: 0,
      GoalPurposeId: 0,
      GoalPurpose: '',
      SiteId: 0,
      SiteName: '',
      WaterSupplyDescriptionId: 0,
      WaterSupplyDescription: '',
      EntityTypeId: 0,
      EntityId: 0,
      Entity: '',
      EntitySubTypeId: 0,
      GoalDate: moment().unix(),
      AuthorName: '',
      Comments: '',
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      resultItems: [],
      files: [],
      deletedFileIds: [],
      testCategories: [],
      testPurposes: [],
      selectedQualityGoalReportItem: {
        QualityGoalReportItemId: 0,
        TestCategoryItemId: 0,
        GuidIdentifier: '',
        Name: '',
        UnitOfMeasure: '',
        RealisticMeanValue: '',
        RealisticMaxValue: '',
        RealisticLRV: '',
        AspirationalMeanValue: '',
        AspirationalMaxValue: '',
        AspirationalLRV: ''
      }
    },
    filteredResults: [],
    qualityGoalsListCount: 0,
    filterBy: {
      filterEntityId: null,
      filterEntityTypeId: null,
      filterSiteId: null,
      filterGoalPurposeId: null
    },
    filterData: {
      TestPurposes: []
    },
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialWaterSupplyDescriptionState = {
  waterSupplyDescription: {
    WaterSupplyDescriptions: [],
    selectedWaterSupplyDescription: {
      WaterSupplyDescriptionId: 0,
      SiteId: 0,
      Name: '',
      SuppliedWaterVolume: 0.0,
      CommunityManaged: false,
      Description: '',
      WaterFeesCollected: false,
      WaterFeesDetails: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      GpsLatitude: null,
      GpsLongitude: null,
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      files: [],
      deletedFileIds: []
    },
    filteredResults: [],
    filterBy: {
      filter: null
    },
    waterSupplyDescriptionListCount: 0,
    filterData: {},
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    isRedirect: false,
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialGeneralFieldTypeState = {
  generalFieldType: {
    GeneralFieldTypes: [],
    SelectedGeneralFieldType: {
      GeneralFieldTypeId: 0,
      Name: '',
      Description: '',
      FieldType: '',
      Value: '',
      OrganisationId: 0,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialRiskCategoryState = {
  riskCategory: {
    RiskCategories: [],
    SelectedRiskCategory: {
      RiskCategoryId: 0,
      Name: '',
      Description: '',
      InsignificantDescription: '',
      MinorDescription: '',
      ModerateDescription: '',
      MajorDescription: '',
      CatastrophicDescription: '',
      LowRatingScore: 0,
      MediumRatingScore: 0,
      HighRatingScore: 0,
      OrganisationId: 0,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      RiskMatrix: []
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialHazardEventState = {
  hazardEvent: {
    hazardEvents: [],
    selectedHazardEvent: {
      HazardEventId: 0,
      SiteId: 0,
      WaterSupplyDescriptionId: 0,
      EntityTypeId: 0,
      HazardEventLibraryId: 0,
      EntityId: 0,
      Entity: '',
      HazardCertaintyId: 0,
      OrganisationId: 0,
      Name: '',
      Description: '',
      Basis: '',
      HazardCategoryId: 0,
      HazardCategory: '',
      DateIdentified: moment().unix(),
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      ReviewFrequencyName: '',
      StatusName: '',
      HazardClass: '',
      TemplateId: 0,
      EntitySubTypeId: 0,
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      GpsLatitude: null,
      GpsLongitude: null,
      hazardEventItems: [],
      riskAssessments: [],
      comments: [],
      Files: [],
      incidentReports: [],
      deletedFileIds: [],
      selectedHazardEventItem: {
        HazardEventItemId: 0,
        HazardEventId: 0,
        HazardId: 0,
        GuidIdentifier: '',
        IsActive: true,
        IsDeleted: false,
        CreatedDateTime: 0,
        ModifiedDateTime: 0,
        CreatedByUserId: 0,
        ModifiedByUserId: 0
      }
    },
    hazardEventListCount: 0,
    filterBy: {
      filter: null,
      filterSiteId: null,
      filterWaterSupplyDescriptionId: null,
      filterEntityId: null,
      filterEntityTypeId: null,
      filterHazardCertaintyId: null,
      filterHazardClassId: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialHazardEventLibraryState = {
  hazardEventTemplate: {
    HazardEventTemplates: [],
    Hazards: [],
    SelectedHazardEventTemplate: {
      HazardEventLibraryId: 0,
      Name: '',
      OrganisationId: 0,
      Description: '',
      Basis: '',
      HazardClass: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      SelectedHazardEventTemplateItems: []
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialHazardState = {
  hazard: {
    Hazards: [],
    selectedHazard: {
      HazardId: 0,
      HazardClassId: 0,
      OrganisationId: 0,
      Name: '',
      Description: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    filteredResults: [],
    filterBy: {
      filter: null
    },
    hazardListCount: 0,
    filterData: {},
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    isRedirect: false,
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialRiskAssessmentState = {
  riskAssessment: {
    riskAssessments: [],
    selectedRiskAssessment: {
      RiskAssessmentId: 0,
      SiteId: 0,
      WaterSupplyDescriptionId: 0,
      EntityTypeId: 0,
      EntityId: 0,
      HazardEventId: 0,
      RiskCategoryId: 0,
      RiskCertaintyId: 0,
      RiskNumber: '',
      InherentLikelihood: '',
      InherentConsequence: '',
      InherentRiskRating: '',
      InherentRiskScore: null,
      ResidualLikelihood: '',
      ResidualConsequence: '',
      ResidualRiskRating: '',
      ResidualRiskScore: null,
      IsInherentRiskCategory: true,
      Basis: '',
      HazardCategory: '',
      DateRaised: moment().unix(),
      Response: '',
      ResponseDetails: '',
      IsPassedOn: false,
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      ReviewFrequencyName: '',
      StatusName: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      comments: [],
      Files: [],
      deletedFileIds: [],
      Entities: []
    },
    riskAssessmentListCount: 0,
    filterBy: {
      filter: null,
      filterSiteId: null,
      filterWaterSupplyDescriptionId: null,
      filterEntityTypeId: null,
      filterEntityId: null,
      filterInherentRiskRating: null,
      filterResidualRiskRating: null,
      filterStatusId: null,
      filterHazardEventId: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    offset: 0,
    currentPage: 1
  }
};

export const initialSafetyPlanState = {
  safetyPlan: {
    SafetyPlans: [],
    selectedSafetyPlan: {
      WaterQualitySafetyPlanId: 0,
      SiteId: 0,
      Name: '',
      PlanNumber: '',
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      Description: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    filteredResults: [],
    filterBy: {
      filter: null,
      filterSiteId: null
    },
    safetyPlanListCount: 0,
    filterData: {},
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    isRedirect: false,
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialEmergencyResponsePlanState = {
  emergencyResponsePlan: {
    emergencyResponsePlans: [],
    selectedEmergencyResponsePlan: {
      EmergencyResponsePlanId: 0,
      Name: '',
      Description: '',
      WaterQualitySafetyPlanId: 0,
      WQSPName: '',
      StakeholderRegisterId: 0,
      PersonnelName: '',
      PersonnelId: 0,
      HazardEventId: 0,
      HazardEventName: '',
      ChecklistTemplateId: 0,
      ChecklistTemplateName: '',
      ReviewFrequencyId: 0,
      ReviewFrequencyName: '',
      NextReviewDate: 0,
      LastReviewDate: 0,
      IsActioned: false,
      StatusId: null,
      StatusName: '',
      SiteId: 0,
      SiteName: '',
      WaterSupplyDescriptionId: 0,
      WaterSupplyDescriptionName: '',
      EntityTypeId: 0,
      EntityTypeName: '',
      EntitySubTypeId: 0,
      EntitySubTypeName: '',
      EntityId: 0,
      EntityName: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      deletedFileIds: [],
      files: [],
      checklists: []
    },
    emergencyResponsePlanListCount: 0,
    filterBy: {
      filter: null,
      filterWaterSupplyDescriptionId: null,
      filterEntityTypeId: null,
      filterEntityId: null,
      filterReviewFrequencyId: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    offset: 0,
    currentPage: 1
  }
};

export const initialPersonnelState = {
  personnel: {
    personnels: [],
    selectedPersonnel: {
      PersonnelId: 0,
      Name: '',
      Company: '',
      JobTitle: '',
      Address: '',
      Phone: '',
      Email: '',
      PrefferedContactMethod: '',
      OrganisationId: 0,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    personnelListCount: 0,
    filterBy: {
      filterName: null,
      filterCompany: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialSafetyPlanTeamState = {
  safetyPlanTeam: {
    team: [],
    selectedTeam: {
      TeamId: 0,
      Name: '',
      Company: '',
      JobTitle: '',
      Address: '',
      Phone: '',
      Email: '',
      PrefferedContactMethod: '',
      Role: '',
      Responsibilities: '',
      WaterQualitySafetyPlanId: 0,
      OrganisationId: 0,
      PersonnelId: 0,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    teamListCount: 0,
    filterBy: {
      filterName: null,
      filterCompany: null,
      filterRole: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialStakeholderRegisterState = {
  stakeholderRegister: {
    stakeholderRegisters: [],
    selectedStakeholderRegister: {
      StakeholderRegisterId: 0,
      Name: '',
      Company: '',
      JobTitle: '',
      Address: '',
      Phone: '',
      Email: '',
      PrefferedContactMethod: '',
      IssuesRelationship: '',
      KeyPoint: '',
      WaterQualitySafetyPlanId: 0,
      OrganisationId: 0,
      PersonnelId: 0,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    stakeholderRegisterListCount: 0,
    filterBy: {
      filterName: null,
      filterCompany: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialSafetyPlanReportState = {
  safetyPlanReport: {
    safetyPlanDownloadReportList: [],
    selectedSafetyPlanReport: {
      Build: {
        SiteDetails: {
          SiteId: 0,
          Name: '',
          Households: 0,
          Inhabitants: 0,
          GpsLongitude: 0,
          GpsLatitude: 0,
          Location: '',
          LastReviewDate: 0,
          NextReviewDate: 0,
          Description: '',
          Region: '',
          ReviewFrequencyId: 0,
          IsActive: false,
          IsActioned: false,
          ReviewFrequencyName: '',
          StatusName: '',
          Images: []
        },
        SafetyPlan: {
          WaterQualitySafetyPlanId: 0,
          Name: '',
          PlanNumber: '',
          LastReviewDate: 0,
          NextReviewDate: 0,
          ReviewFrequencyId: 0,
          ReviewFrequencyName: '',
          Description: '',
          GuidIdentifier: ''
        }
      },
      Define: {
        SupplyDescriptions: [
          {
            WaterSupplyDescriptionId: 0,
            Name: '',
            Description: '',
            SuppliedWaterVolume: 0.0,
            CommunityManaged: false,
            WaterFeesCollected: false,
            WaterFeesDetails: '',
            GpsLatitude: 0,
            GpsLongitude: 0,
            LastReviewDate: 0,
            NextReviewDate: 0,
            ReviewFrequencyId: 0,
            ReviewFrequencyName: '',
            IsActioned: false,
            StatusId: 0,
            StatusName: '',
            Images: []
          }
        ],
        Elements: [
          {
            EntityId: 0,
            WaterSupplyDescriptionId: 0,
            EntityTypeId: 0,
            EntityType: '',
            EntitySubTypeId: 0,
            EntitySubType: '',
            Name: '',
            Description: '',
            InspectionFrequency: 0,
            InspectionFrequencyName: '',
            CategoryOfWater: 0,
            CategoryOfWaterName: '',
            EntityCondition: 0,
            EntityConditionName: '',
            MaintenanceFrequency: 0,
            MaintenanceFrequencyName: '',
            DateOfLastInspection: 0,
            DateOfLastService: 0,
            GpsLatitude: 0.0,
            GpsLongitude: 0.0,
            LastReviewDate: 0,
            NextReviewDate: 0,
            ReviewFrequencyId: 0,
            ReviewFrequencyName: '',
            IsActioned: false,
            StatusId: 0,
            StatusName: '',
            Images: []
          }
        ],
        QualityGoals: [
          {
            QualityGoalReportId: 0,
            WaterSupplyDescriptionId: 0,
            GoalPurposeId: 0,
            GoalPurposeName: '',
            EntityId: 0,
            GoalDate: 0,
            AuthorName: '',
            LastReviewDate: 0,
            NextReviewDate: 0,
            ReviewFrequencyId: 0,
            ReviewFrequencyName: '',
            Comments: '',
            Goals: [
              {
                QualityGoalReportItemId: 0,
                TestCategoryItemId: 0,
                TestTypeName: '',
                RealisticMeanValue: 0.0,
                RealisticMaxValue: 0.0,
                RealisticLRV: 0.0,
                AspirationalMeanValue: 0.0,
                AspirationalMaxValue: 0.0,
                AspirationalLRV: 0.0,
                UnitOfMeasure: '',
                TestCategoryId: 0,
                TestCategoryName: '',
                Description: '',
                Images: []
              }
            ],
            IsActioned: false,
            StatusId: 0,
            StatusName: '',
            Images: []
          }
        ]
      },
      Identify: {
        HazardEvents: [
          {
            HazardEventId: 0,
            WaterSupplyDescriptionId: 0,
            WaterSupplyDescriptionName: '',
            EntityTypeId: 0,
            EntityTypeName: '',
            EntityId: 0,
            EntityName: '',
            HazardCertaintyId: 0,
            HazardCertaintyName: '',
            HazardName: '',
            Description: '',
            HazardCategoryId: 0,
            HazardCategoryName: '',
            DateIdentified: 0,
            Basis: '',
            HazardClass: '',
            GpsLatitude: '',
            GpsLongitude: '',
            LastReviewDate: 0,
            NextReviewDate: 0,
            ReviewFrequencyId: 0,
            ReviewFrequencyName: '',
            IsActioned: false,
            StatusId: 0,
            StatusName: '',
            HazardEventItems: [
              {
                HazardEventItemId: 0,
                HazardId: 0,
                HazardName: '',
                HazardEventId: 0,
                Description: '',
                HazardClassId: 0,
                HarzardClass: ''
              }
            ],
            RiskAssessments: [
              {
                RiskAssessmentId: 0,
                HazardEventId: 0,
                StatusId: 0,
                Status: '',
                RiskNumber: '',
                InherentRiskRating: '',
                ResidualRiskRating: '',
                Response: '',
                LastReviewDate: 0
              }
            ],
            Images: [
              {
                ImageId: '',
                S3URL: ''
              }
            ]
          }
        ]
      },
      Control: {
        ControlMeasures: [
          {
            ControlMeasureId: 0,
            HazardEventName: '',
            HazardEventId: 0,
            ControlMeasureName: '',
            Description: '',
            DateImplemented: 0,
            TargetCriteria: '',
            TargetCriteriaTypeId: 0,
            TargetCriteriaTypeName: '',
            AlertCriteriaTrigger: '',
            AlertCriteriaId: 0,
            AlertCriteriaName: '',
            CriticalLimitTrigger: '',
            CriticalLimitTypeId: 0,
            CriticalLimitTypeName: '',
            UnitOfMeasure: '',
            LastReviewDate: 0,
            NextReviewDate: 0,
            ReviewFrequencyId: 0,
            ReviewFrequencyName: '',
            IsActioned: false,
            StatusId: 0,
            StatusName: '',
            ControlMonitors: [
              {
                ControlMonitoringId: 0,
                ControlMeasureId: 0,
                StakeholderRegisterId: 0,
                StakeholderRegisterName: '',
                MonitoredItem: '',
                Description: '',
                LastReviewDate: 0
              }
            ],
            ImprovementPlans: [
              {
                ImprovementPlanId: 0,
                HazardEventId: 0,
                ImprovementPlanName: '',
                HazardEventName: '',
                Description: '',
                DateRaised: 0,
                DateDue: 0,
                LastReviewDate: 0,
                NextReviewDate: 0,
                ReviewFrequencyId: 0,
                ReviewFrequencyName: '',
                IsActioned: '',
                StatusId: 0,
                StatusName: ''
              }
            ],
            Images: [
              {
                ImageId: '',
                S3URL: ''
              }
            ]
          }
        ]
      },
      Monitor: {
        ControlMonitors: [
          {
            ControlMonitoringId: 0,
            ControlMeasureName: '',
            StakeholderRegisterId: 0,
            StakeholderRegisterName: '',
            MonitoredItem: '',
            Description: '',
            WhenMonitoredDetails: '',
            FrequencyId: 0,
            FrequencyName: '',
            WhereMeasureTaken: '',
            Records: '',
            LastReviewDate: 0,
            NextReviewDate: 0,
            ReviewFrequencyId: 0,
            ReviewFrequencyName: '',
            IsActioned: '',
            StatusId: 0,
            StatusName: '',
            Images: [
              {
                ImageId: '',
                S3URL: ''
              }
            ]
          }
        ]
      },
      Measure: {
        Inspections: [
          {
            InspectionReportId: 0,
            WaterSupplyDescriptionId: 0,
            WaterSupplyDescriptionName: '',
            SourceEntityTypeId: 0,
            SourceEntityTypeName: '',
            EntityId: 0,
            EntityName: '',
            InspectionTypeId: 0,
            InspectionTypeName: '',
            InspectionPurposeId: 0,
            InspectionPurposeName: '',
            InspectionDate: 0,
            InspectorName: '',
            LastReviewDate: 0,
            NextReviewDate: 0,
            ReviewFrequencyId: 0,
            ReviewFrequencyName: '',
            IsActioned: '',
            StatusId: 0,
            StatusName: '',
            AdditionalObservation: '',
            GeneralInformation: '',
            Questions: [
              {
                InspectionAssesmentQuestionId: 0,
                InspectionReportId: 0,
                InspectionTypeQuestionId: 0,
                QuestionNumber: 0,
                QuestionText: '',
                Score: 0,
                Result: false,
                Images: [
                  {
                    ImageId: '',
                    S3URL: ''
                  }
                ]
              }
            ],
            Score: {
              TotalScore: '',
              RiskRating: ''
            }
          }
        ],
        TestSamples: [
          {
            TestResultReportId: 0,
            WaterSupplyDescriptionId: 0,
            WaterSupplyDescriptionName: '',
            EntityTypeId: 0,
            EntityTypeName: '',
            EntityId: 0,
            EntityName: '',
            SampleDate: 0,
            SampleIdNumber: 0,
            SamplerName: '',
            TestPurposeId: 0,
            TestPurposeName: '',
            LastReviewDate: 0,
            NextReviewDate: 0,
            ReviewFrequencyId: 0,
            ReviewFrequencyName: '',
            IsActioned: '',
            StatusId: 0,
            StatusName: '',
            AnalysisCompletedBy: 0,
            AnalysisDate: 0,
            SubmissionDate: 0,
            AnalyserName: 0,
            Address: '',
            Comments: '',
            Images: [
              {
                ImageId: '',
                S3URL: ''
              }
            ],
            TestResults: [
              {
                TestResultReportItemId: 0,
                TestResultReportId: 0,
                TestCategoryId: 0,
                TestCategoryName: '',
                TestTypeName: '',
                TestCategoryItemId: 0,
                Value: '',
                UnitOfMeasure: ''
              }
            ]
          }
        ]
      },
      Respond: {
        IncidentReports: [
          {
            IncidentReportId: 0,
            WaterSupplyDescriptionId: 0,
            WaterSupplyDescriptionName: '',
            EntityTypeId: 0,
            EntityTypeName: '',
            EntityId: 0,
            EntityName: '',
            Title: '',
            Description: '',
            IncidentDateTime: 0,
            Address: '',
            PersonReportingId: 0,
            PersonReportingName: '',
            PersonInvestigatingId: 0,
            PersonInvestigatingName: '',
            IsActioned: '',
            StatusId: 0,
            StatusName: '',
            CategoryId: 0,
            CategoryName: '',
            ConsequenceId: 0,
            ConsequenceName: '',
            IsHACCPCritical: false,
            Images: [
              {
                ImageId: '',
                S3URL: ''
              }
            ]
          }
        ],
        EmergencyResponses: [
          {
            EmergencyResponsePlanId: 0,
            Name: '',
            Description: '',
            WaterSupplyDescriptionId: 0,
            WaterSupplyDescriptionName: '',
            EntityTypeId: 0,
            EntityTypeName: '',
            EntityId: 0,
            EntityName: '',
            HazardEventId: 0,
            HazardEventName: '',
            ChecklistTemplateId: 0,
            ChecklistTemplateName: '',
            StakeholderRegisterId: 0,
            StakeholderRegisterName: '',
            LastReviewDate: 0,
            NextReviewDate: 0,
            ReviewFrequencyId: 0,
            ReviewFrequencyName: '',
            IsActioned: false,
            StatusId: 0,
            StatusName: '',
            Images: [
              {
                ImageId: '',
                S3URL: ''
              }
            ]
          }
        ],
        CorrectiveActions: [
          {
            CorrectiveActionId: 0,
            ControlMonitoringId: 0,
            ControlMonitoringName: '',
            TeamId: 0,
            TeamName: '',
            Title: '',
            WhenActionTriggered: '',
            Details: '',
            Records: ''
          }
        ]
      }
    },
    safetyPlanDownloadReportListCount: 0,
    filterBy: {},
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    showNewDownloadLink: false,
    downloadFetchComplete: false,
    newDownloadLink: '',
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialRegulatoryRegisterState = {
  regulatoryRegister: {
    regulatoryRegisters: [],
    selectedRegulatoryRegister: {
      RegulatoryRegisterId: 0,
      DocumentTypeId: 0,
      SubjectCategoryId: 0,
      DocumentName: '',
      Jurisdiction: '',
      WaterQualitySafetyPlanId: 0,
      WQSPName: '',
      StakeholderRegisterId: 1,
      YearApplicable: '',
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      ReviewFrequencyName: '',
      StatusName: '',
      Location: '',
      SiteId: 0,
      SiteName: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    regulatoryRegisterListCount: 0,
    filterBy: {
      filterName: null,
      filterJurisdiction: null,
      filterReviewFrequencyId: null,
      filterDocumentTypeId: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    offset: 0,
    currentPage: 1
  }
};

export const initialControlMeasureState = {
  controlMeasure: {
    controlMeasures: [],
    selectedControlMeasure: {
      ControlMeasureId: 0,
      HazardEventId: 0,
      HazardName: '',
      Name: '',
      Description: '',
      DateImplemented: 0,
      TargetCriteria: 0,
      TargetCriteriaTypeId: 0,
      AlertCriteriaTrigger: 0,
      AlertCriteriaId: 0,
      CriticalLimitTrigger: 0,
      CriticalLimitTypeId: 0,
      CriticalLimitTimer: 0,
      UnitOfMeasure: '',
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      ReviewFrequencyName: '',
      StatusName: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      controlMonitorings: [],
      unitComponentPositions: [],
      Files: [],
      deletedFileIds: [],
      selectedControlMonitoring: {
        ControlMonitoringId: 0,
        ControlMeasureId: 0,
        UnitComponentPositionId: 0,
        StakeholderRegisterId: 0,
        MonitoredItem: '',
        Description: '',
        WhenMonitoredDetails: '',
        FrequencyId: 0,
        WhereMeasureTaken: '',
        Records: '',
        LastReviewDate: 0,
        NextReviewDate: 0,
        ReviewFrequencyId: 0,
        IsActioned: false,
        StatusId: null,
        ReviewFrequencyName: '',
        StatusName: '',
        GuidIdentifier: '',
        IsActive: true,
        CreatedDateTime: 0,
        ModifiedDateTime: 0,
        CreatedByUserId: 0,
        ModifiedByUserId: 0,
        ModelComponentPositionName: '',
        correctiveActions: [],
        Files: [],
        deletedFileIds: [],
        selectedCorrectiveAction: {
          CorrectiveActionId: 0,
          ControlMonitoringId: 0,
          TeamId: 0,
          Title: '',
          WhenActionTriggered: '',
          Details: '',
          Records: '',
          GuidIdentifier: '',
          IsActive: true,
          CreatedDateTime: 0,
          ModifiedDateTime: 0,
          CreatedByUserId: 0,
          ModifiedByUserId: 0,
          comments: []
        }
      }
    },
    controlMeasureListCount: 0,
    filterBy: {
      filterName: null,
      filterStatusId: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isControlMonitoringRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    offset: 0,
    currentPage: 1
  }
};

export const initialImprovementPlanState = {
  improvementPlan: {
    improvementPlans: [],
    selectedImprovementPlan: {
      ImprovementPlanId: 0,
      SiteId: 0,
      WaterSupplyDescriptionId: 0,
      EntityTypeId: 0,
      EntityId: 0,
      HazardEventId: 0,
      Name: '',
      Description: '',
      DateRaised: moment().unix(),
      DateDue: 0,
      LastReviewDate: 0,
      NextReviewDate: 0,
      ReviewFrequencyId: 0,
      IsActioned: false,
      StatusId: null,
      ReviewFrequencyName: '',
      StatusName: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      comments: []
    },
    improvementPlanListCount: 0,
    filterBy: {
      filter: null,
      filterSiteId: null,
      filterWaterSupplyDescriptionId: null,
      filterEntityTypeId: null,
      filterEntityId: null,
      filterStatusId: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    offset: 0,
    currentPage: 1
  }
};

export const initialSitePathwayState = {
  sitePathway: {
    build: {
      Status: 0,
      SiteAssessedDate: null,
      SiteReviewDate: null,
      TotalContacts: 0,
      TeamRoles: 0,
      Stakeholders: 0,
      DocumentsRegistered: 0,
      DocumentsDueForReview: 0
    },
    define: {
      Status: 0,
      TotalSupplyDescriptions: 0,
      SupplyDescriptionsAwaitingReview: 0,
      TotalElements: 0,
      ElementsAwaitingReview: 0,
      TotalInspections: 0,
      AvergageInspectionScore: 0,
      LowScoreInspections: 0,
      MediumScoreInspections: 0,
      HighScoreInspections: 0,
      VeryHighScoreInspections: 0,
      SamplesCompleted: 0,
      SamplesScheduled: 0
    },
    identify: {
      Status: 0,
      TotalHazardEvents: 0,
      RiskAssessedHazardEvents: 0,
      TotalRisks: 0,
      LowRisks: 0,
      MediumRisks: 0,
      HighRisks: 0,
      VeryHighRisks: 0,
      AverageInherentRiskScore: 0,
      AverageResidualRiskScore: 0
    },
    control: {
      Status: 0,
      TotalControlMeasures: 0,
      UnAssessedControlMeasures: 0,
      TotalImprovementPlans: 0,
      ScheduledImprovementPlans3Mths: 0,
      ScheduledImprovementPlans6Mths: 0,
      ScheduledImprovementPlans12Mths: 0,
      ScheduledImprovementPlans18Mths: 0
    },
    measure: {
      Status: 0,
      TotalSurveys: 0,
      ActionedSurveys: 0,
      LowScoreSurveys: 0,
      MediumScoreSurveys: 0,
      HighScoreSurveys: 0,
      VeryHighScoreSurveys: 0,
      ScheduledSurveys1Mths: 0,
      ScheduledSurveys3Mths: 0,
      AverageSurveyScore: 0,
      TotalSamples: 0,
      ActionedSamples: 0,
      ScheduledSamples1Mths: 0,
      Scheduledsamples3Mths: 0
    },
    monitor: {
      Status: 0,
      TotalControlMonitors: 0,
      ControlMonitorsLessThan6Mths: 0,
      ControlMonitorsLessThan12Mths: 0,
      ControlMonitorsLessThan18Mths: 0,
      ControlMonitorsLessThan24Mths: 0,
      ControlMonitorsLessThan36Mths: 0,
      ControlMonitorsMoreThan36Mths: 0
    },
    respond: {
      Status: 0,
      TotalCorrectiveActions: 0,
      TotalEmergencyResponsePlans: 0,
      TotalUnactionedEmergencyResponsePlans: 0,
      TotalIncidentReports: 0,
      TotalUnactionedIncidentReports: 0
    },
    selectedSitePathway: null,

    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialSiteSegmentsState = {
  siteSegments: {
    segments: [],
    selectedView: null,
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialOrganisationTranslationState = {
  organisationTranslation: {
    OrganisationTranslations: [],
    selectedOrganisationTranslation: {
      OrganisationTranslationId: 0,
      OrganisationId: null,
      Locale: 'en',
      TranslationKey: null,
      TranslationValue: null,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    filteredResults: [],
    filterBy: {
      filter: null,
      filterLocale: null,
      filterTranslationKey: null
    },
    organisationTranslationListCount: 0,
    filterData: {},
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    isRedirect: false,
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialTranslationState = {
  translation: {
    isDataLoaded: false,
    locale: null,
    organisationId: null,
    translations: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialIncidentReportsState = {
  incidentReports: {
    incidentReports: [],
    selectedIncidentReport: {
      IncidentReportId: 0,
      SiteId: 0,
      SiteName: '',
      WaterSupplyDescriptionId: 0,
      WaterSupplyDescription: '',
      EntityTypeId: 0,
      EntityId: 0,
      Entity: '',
      EntitySubTypeId: 0,
      OrganisationId: 0,
      SourceOfIncident: '',
      SourceName: '',
      SourceLinkId: 0,
      PersonReportingId: 0,
      PersonInvestigatingId: 0,
      PersonReportingName: '',
      PersonReportingCompany: '',
      PersonReportingJobTitle: '',
      PersonReportingAddress: '',
      PersonReportingPhone: '',
      PersonReportingEmail: '',
      Title: '',
      Address: '',
      Description: '',
      IncidentDateTime: 0,
      IsHACCPCritical: false,
      SaveInPeopleRegister: false,
      StatusId: null,
      IsActioned: false,
      CategoryId: null,
      ConsequenceId: null,
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      files: [],
      deletedFileIds: []
    },
    filteredResults: [],
    incidentReportsListCount: 0,
    filterBy: {
      filterEntityId: null,
      filterEntityTypeId: null,
      filterSiteId: null
    },
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialCorrectiveActionsState = {
  correctiveAction: {
    correctiveActions: [],
    controlMonitorings: [],
    selectedCorrectiveAction: {
      CorrectiveActionId: 0,
      ControlMonitoringId: 0,
      TeamId: 0,
      Title: '',
      WhenActionTriggered: '',
      Details: '',
      Records: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      comments: []
    },
    correctiveActionListCount: 0,
    filterBy: {
      filter: null
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    offset: 0,
    currentPage: 1
  }
};

export const initialEntitySubTypeState = {
  entitySubType: {
    entitySubTypes: [],
    SelectedEntitySubType: {
      EntitySubTypeId: 0,
      Name: '',
      Description: '',
      EntityTypeId: 0,
      OrganisationId: 0,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0
    },
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000
  }
};

export const initialControlMonitoringState = {
  controlMonitoring: {
    controlMonitorings: [],
    filteredResults: [],
    controlMonitoringListCount: 0,
    filterBy: {
      filter: null,
      filterHazardEventId: null
    },
    isLoading: false,
    showBanner: false,
    isRedirect: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    offset: 0,
    currentPage: 1
  }
};
