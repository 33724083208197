import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './OperationalSetupTabs.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const Tab = ({ selectedPage, pageComponent, url, title }) => (
  <Fragment>
    <Link className={classNames({ [s.active]: selectedPage === pageComponent })} to={url}>
      {title}
      {selectedPage === pageComponent && <span />}
    </Link>
  </Fragment>
);

const OperationalSetupTabs = ({ selectedPage, userProfileWidgets }) => (
  <ul className={s.tabNav}>
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_TESTRESULTS_TAB') && (
      <li>
        <Tab
          url={`/operational-setup/test-results`}
          title={<FormattedMessage id="operationalSetupTabs.testResults" defaultMessage="Test Results" />}
          selectedPage={selectedPage}
          pageComponent="testResults"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONRESULTS_TAB') && (
      <li>
        <Tab
          url={`/operational-setup/inspection-report`}
          title={<FormattedMessage id="operationalSetupTabs.inspectionReport" defaultMessage="Inspection Report" />}
          selectedPage={selectedPage}
          pageComponent="inspectionReport"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_TASKSCHECKLISTS_TAB') && (
      <li>
        <Tab
          url={`/operational-setup/checklist-task-templates`}
          title={<FormattedMessage id="operationalSetupTabs.checklistsTasks" defaultMessage="Checklists & Tasks" />}
          selectedPage={selectedPage}
          pageComponent="checklistTaskTemplate"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_RISKCATEGORY_LIST') && (
      <li>
        <Tab
          url={`/operational-setup/risk-categories`}
          title={<FormattedMessage id="operationalSetupTabs.riskCategories" defaultMessage="Risk Categories" />}
          selectedPage={selectedPage}
          pageComponent="riskCategories"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDS_TAB') && (
      <li>
        <Tab
          url={`/operational-setup/hazards`}
          title={<FormattedMessage id="operationalSetupTabs.hazards" defaultMessage="Hazards" />}
          selectedPage={selectedPage}
          pageComponent="hazards"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_GENERAL_FIELDTYPES_LIST') && (
      <li>
        <Tab
          url={`/operational-setup/general-field-types`}
          title={<FormattedMessage id="operationalSetupTabs.generalFieldTypes" defaultMessage="General Field Types" />}
          selectedPage={selectedPage}
          pageComponent="generalFieldTypes"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'PERSONNEL_TAB') && (
      <li>
        <Tab
          url={`/operational-setup/personnels`}
          title={<FormattedMessage id="operationalSetupTabs.personnel" defaultMessage="Personnel" />}
          selectedPage={selectedPage}
          pageComponent="personnels"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_TRANSLATION_TAB') && (
      <li>
        <Tab
          url={`/operational-setup/organisation-translations`}
          title={<FormattedMessage id="operationalSetupTabs.translations" defaultMessage="Translations" />}
          selectedPage={selectedPage}
          pageComponent="organisationTranslations"
        />
      </li>
    )}
    {IsWidgetAccessible(userProfileWidgets, 'WQSP_ENTITY_SUB_TYPES_LIST') && (
      <li>
        <Tab
          url={`/operational-setup/entity-sub-types`}
          title={<FormattedMessage id="operationalSetupTabs.entitySubtypes" defaultMessage="Entity Sub Types" />}
          selectedPage={selectedPage}
          pageComponent="entitySubTypes"
        />
      </li>
    )}
  </ul>
);

OperationalSetupTabs.propTypes = {
  selectedPage: PropTypes.string.isRequired
};

export default OperationalSetupTabs;
