import React, { useState, useEffect } from 'react';
import s from './Hazards.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getHazard, getGeneralData } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialHazardState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { describeHazardThunk, saveHazardThunk, setHazardChangeThunk } from '../../store/actions/hazards';
import uuid from 'uuid';
import { HAZARD_CLASS } from '../../constants/index';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const Hazards = ({ intl }) => {
  let history = useHistory();
  let { hazardId } = useParams();
  let formErrors = {};
  const dispatch = useDispatch();
  const hazard = useSelector(state => getHazard(state));
  const generalData = useSelector(state => getGeneralData(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});

  const backUrl = '/operational-setup/hazards';
  let widgetCode = '';
  if (hazardId) {
    IsEdit = true;
    widgetCode = 'WQSP_HAZARDS_EDIT';
  }

  useEffect(() => {
    if (hazardId) {
      dispatch(describeHazardThunk(hazardId, widgetCode));
    }
  }, [describeHazardThunk, dispatch, hazardId]);

  //clean up
  useEffect(() => {
    return () => {
      setLocalHazard({
        selectedHazard: {
          ...initialHazardState.hazard.selectedHazard
        }
      });
    };
  }, []);

  // Update redux store
  const setLocalHazard = currentState => {
    dispatch(setHazardChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = hazard.selectedHazard;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.HazardId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_HAZARDS_ADDNEW';
    }

    dispatch(saveHazardThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let isValid = true;

    if (!hazard || !hazard.selectedHazard || !hazard.selectedHazard.HazardClassId) {
      formErrors.HazardClassId = intl.formatMessage({
        id: 'wqsp.common.hazardClassMandatory',
        defaultMessage: 'Hazard Class is mandatory field'
      });
      isValid = false;
    }

    if (!hazard || !hazard.selectedHazard || !hazard.selectedHazard.Name || isRequired(hazard.selectedHazard.Name, 1)) {
      formErrors.Name = intl.formatMessage({
        id: 'common.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalHazard({
      ...hazard,
      selectedHazard: { ...hazard.selectedHazard, [e.target.name]: e.target.value }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalHazard({
      ...hazard,
      selectedHazard: { ...hazard.selectedHazard, [fieldName]: e.value }
    });
  };

  const hazardClassOptions = () => {
    let hazardClassData = [];

    const hazardClasses = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_CLASS);

    hazardClasses.forEach(element => {
      hazardClassData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    return hazardClassData.sort(compareValues('label'));
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (hazard && hazard.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const hazardName = (hazard.selectedHazard ? hazard.selectedHazard.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + hazardName;
  } else {
    heading = intl.formatMessage({ id: 'hazards.addHazard', defaultMessage: 'Add new hazard' });
  }

  let ddlClass = formErrors && formErrors.HazardClassId ? s.ddlError : '';

  return (
    <div className={s.hazard}>
      {hazard.isLoading && <LoadingSpinner />}
      {hazard.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={hazard.showBanner}
        status={hazard.isOpSuccessful}
        successText={messageText}
      />
      <OperationalSetupContentTemplate selectedPage="hazards" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.hazardHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.hazardContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col xs={12} lg={4}>
                    <Form.Group controlId="hazardClass">
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.hazardClass" defaultMessage="Hazard Class" />
                      </Form.Label>

                      <Dropdown
                        id="formHazardClass"
                        dataArray={hazardClassOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                          customClassName: ddlClass
                        }}
                        name="HazardClassId"
                        onDropdownChange={e => onDropdownChange(e, 'HazardClassId')}
                        selectedOption={hazardClassOptions().filter(option => option.value === hazard.selectedHazard.HazardClassId)}
                        disabled={IsEdit}
                      />
                      {localformErrors && localformErrors.HazardClassId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.HazardClassId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formHazardName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={hazard.selectedHazard.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formHazardDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={hazard.selectedHazard.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

Hazards.defaultProps = {
  hazard: {
    ...initialHazardState
  }
};

export default injectIntl(Hazards);
