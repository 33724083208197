import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './InspectionPurpose.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getInspectionPurpose } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialInspectionPurposeState } from '../../store/reducers/initialState';
import { getInspectionPurposeThunk, saveInspectionPurposeThunk } from '../../store/actions/inspectionPurpose';
import InspectionPurposeListTable from './InspectionPurposeListTable';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const InspectionPurposeList = ({ userProfileWidgets, intl }) => {
  const history = useHistory();

  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;

  const dispatch = useDispatch();
  const inspectionPurpose = useSelector(state => getInspectionPurpose(state));

  let initialRecords = inspectionPurpose.InspectionPurposes.slice(0, initialLimit);
  const [inspectionPurposeOpen, setInspectionPurposeOpen] = useState(false);

  useEffect(() => {
    dispatch(getInspectionPurposeThunk('WQSP_INSPECTIONPURPOSE_LIST'));
  }, [getInspectionPurposeThunk, dispatch, inspectionPurpose.isOpSuccessful]);

  const [localInspectionPurposes, setLocalInspectionPurposes] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [inspectionPurposeId, setInspectionPurposeId] = useState(0);

  const [searchText, setSearchText] = useState('');

  const localInspectionPurposeLength = localInspectionPurposes.length;
  const globalSettingLength = inspectionPurpose.InspectionPurposes.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalInspectionPurposes(inspectionPurpose.InspectionPurposes);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = inspectionPurpose.InspectionPurposes.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalInspectionPurposes(filteredList);
  };

  const filterData = () => {
    let filteredList = inspectionPurpose.InspectionPurposes;
    if (isFilter) {
      filteredList = inspectionPurpose.InspectionPurposes.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getInspectionPurposeData = () => {
    let inspectionPurpose = [];
    if (showAll) {
      inspectionPurpose = filterData();
    } else {
      if (isFilter) {
        inspectionPurpose = localInspectionPurposeLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        inspectionPurpose = initialRecords;
      }
    }
    return inspectionPurpose.sort(compareValues('Name'));
  };

  const onRemoveInspectionPurposeClick = inspectionPurposeId => {
    setActive(true);
    setInspectionPurposeId(inspectionPurposeId);
  };

  const DeleteInspectionPurpose = () => {
    let saveData = inspectionPurpose.SelectedInspectionPurpose;
    saveData.PerformDelete = true;
    saveData.InspectionPurposeId = inspectionPurposeId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveInspectionPurposeThunk(saveData, 'WQSP_INSPECTIONPURPOSE_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setInspectionPurposeId(0);
  };

  const handleConfirm = () => {
    DeleteInspectionPurpose();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push('/operational-setup/inspection-report/inspection-purpose');
  };

  const onChangeInspectionPurposeClick = inspectionPurposeId => {
    history.push(`/operational-setup/inspection-report/inspection-purpose/${inspectionPurposeId}`);
  };

  let messageId = (inspectionPurpose && inspectionPurpose.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'inspectionPurposes.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'inspectionPurposes.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONPURPOSE_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_INSPECTIONPURPOSE_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'inspectionPurposes.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this inspection purpose?'
  });

  return (
    <div className={s.inspectionPurposeList}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'WQSP_INSPECTIONPURPOSE_LIST')}>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={inspectionPurposeOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setInspectionPurposeOpen(!inspectionPurposeOpen)}
                aria-controls="model-component-type-section"
                aria-expanded={inspectionPurposeOpen}
              ></img>
              <h4>
                <FormattedMessage id="inspectionPurposes.inspectionPurpose" defaultMessage="Inspection Purpose" />
              </h4>
              <span className={s.circle}>{inspectionPurpose.InspectionPurposes.length}</span>
            </Row>
          </Col>
        </Row>
        <Collapse in={inspectionPurposeOpen}>
          <div className={s.innerComponent} id="model-component-type-section">
            <div className={s.component}>
              {inspectionPurpose.isLoading && <LoadingSpinner />}
              {inspectionPurpose.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={inspectionPurpose.showBanner}
                  status={inspectionPurpose.isOpSuccessful}
                  successText={messageText}
                />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <div className={s.contentWrapper}>
                <Row className={s.topRow}>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={inspectionPurpose.showAddEditScreen}
                      // initialText={filter}
                      placeHolderTextId="common.search"
                      data-unittest="searchInspectionPurposes"
                    />
                  </Col>
                  <Col sm={6} lg={8}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'WQSP_INSPECTIONPURPOSE_ADDNEW')}
                      onClick={onAddNewClick}
                      disabled={inspectionPurpose.showAddEditScreen}
                      data-unittest="addInspectionPurposeButton"
                    >
                      + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>

                <div>
                  <InspectionPurposeListTable
                    RemoveInspectionPurpose={onRemoveInspectionPurposeClick}
                    InspectionPurposeData={getInspectionPurposeData()}
                    ChangeInspectionPurpose={onChangeInspectionPurposeClick}
                    UpdateAllowed={isUpdateAllowed}
                    DeleteAllowed={isDeleteAllowed}
                  />
                </div>
                {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localInspectionPurposeLength > initialLimit)) && (
                  <Row className={s.topRow}>
                    <Col>
                      <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                        {!showAll
                          ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localInspectionPurposeLength})`
                          : showLessText}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

InspectionPurposeList.defaultProps = {
  ...initialInspectionPurposeState
};

export default injectIntl(InspectionPurposeList);
