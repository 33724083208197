import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './ControlMeasure.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import ControlMeasureListTable from './ControlMeasureListTable';
import Pagination from '../../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils';
import { getUserProfileWidget, getControlMeasure, getGeneralData } from '../../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from '../../SearchBar/SearchBar';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';
import SiteDashboardControlsContentTemplate from '../../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import {
  getControlMeasureThunk,
  getFilterThunk,
  setFilteredDataThunk,
  saveControlMeasureThunk,
  setCurrentPage,
  setPageFilter
} from '../../../store/actions/hazardEvent/controlMeasures';
import { GENERAL_STATUS } from '../../../constants/index';
import Breadcrumb from '../../WSAControls/Breadcrumb';

const SEARCH_FILTER = 'SEARCH_FILTER';
const STATUS = 'STATUS';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const ControlMeasureList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { hazardEventId, siteId, entityId, segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'control';
  selectedTab = selectedTab || '2';

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const controlMeasure = useSelector(state => getControlMeasure(state));
  const generalData = useSelector(state => getGeneralData(state));
  const [searchFilter, setSearchFilter] = useState('');
  const [filterStatusId, setFilterStatusId] = useState(0);
  const [asendingOrder, setAsendingOrder] = useState(true);
  const [active, setActive] = useState(false);
  const [controlMeasureId, setControlMeasureId] = useState(0);

  let offset = controlMeasure.offset || 0;
  let limit = controlMeasure.limit || 1000;
  let filterName = controlMeasure.filterBy.filterName || null;
  let filterStatusRedId = controlMeasure.filterBy.filterStatusId || null;

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_CONTROL_MEASURES_LIST', hazardEventId, siteId));
  }, [dispatch, getFilterThunk, hazardEventId, siteId]);

  useEffect(() => {
    let filterObj = {
      filterName: filterName,
      filterStatusId: filterStatusRedId,
      offset: offset,
      limit: limit
    };
    dispatch(getControlMeasureThunk('WQSP_CONTROL_MEASURES_LIST', filterObj, hazardEventId, siteId));
  }, [getControlMeasureThunk, filterName, filterStatusRedId, hazardEventId, siteId]);

  let disableControls = controlMeasure.isLoading || false;
  let messageId = (controlMeasure && controlMeasure.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let isEditAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MEASURES_EDIT');

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = controlMeasure.controlMeasures;

    let apifilterObj = {
      filterName: null,
      filterStatusId: null
    };

    if (filterType === STATUS) {
      if (value !== 0) {
        apifilterObj.filterStatusId = value;
        filteredList = filteredList.filter(function(item) {
          return item.StatusId === value;
        });
      }
    } else {
      if (filterStatusId !== 0) {
        apifilterObj.filterStatusId = filterStatusId;
        filteredList = filteredList.filter(function(item) {
          return item.StatusId === filterStatusId;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filterName = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filterName = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (controlMeasure.controlMeasureListCount > controlMeasure.controlMeasures.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  const statusOptions = () => {
    let statusData = [];
    const controlMeasureStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);

    controlMeasureStatus.forEach(element => {
      statusData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allStatus" defaultMessage="All Status" />,
      value: 0
    });
    return sortedData;
  };

  const onStatusChange = e => {
    setFilterStatusId(e.value);
    filteredResults(STATUS, e.value);
    setCurrentPage(1);
  };
  let controlMeasureObj = Object.values(controlMeasure.filteredResults);

  if (asendingOrder) {
    controlMeasureObj = controlMeasureObj.sort(compareValues('StatusId'));
  } else {
    controlMeasureObj = controlMeasureObj.sort(compareValues('StatusId', 'desc'));
  }

  const onAddNewClick = () => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure`
      );
    } else if (segmentName === 'identify') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure`
      );
    } else {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure`);
    }
  };

  const onChangeControlMeasureViewClick = controlMeasureId => {
    if (segmentName === 'define') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`
      );
    } else if (segmentName === 'identify') {
      history.push(
        `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}/control-measures/control-measure-view/${controlMeasureId}`
      );
    } else {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/control-measures/control-measure-view/${controlMeasureId}`);
    }
  };

  const onControlMeasureRemoveClick = controlMeasureId => {
    setActive(true);
    setControlMeasureId(controlMeasureId);
  };

  const handleClose = () => {
    setActive(false);
    setControlMeasureId(0);
  };

  const handleConfirm = () => {
    deleteControlMeasure();
    handleClose();
  };

  const deleteControlMeasure = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.ControlMeasureId = controlMeasureId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveControlMeasureThunk(saveData, 'WQSP_CONTROL_MEASURES_DELETE'));
  };

  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MEASURES_DELETE');
  const dialogTitle = intl.formatMessage({
    id: 'controlMeasure.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this control measure?'
  });

  const hazardEventName = controlMeasureObj.length > 0 ? ' (' + controlMeasureObj[0].HazardName + ')' : '';
  let hazardEventHeading = intl.formatMessage({ id: 'hazardEvent.hazardEvent', defaultMessage: 'Hazard Event' }) + hazardEventName;

  const defineEntityCrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.entityListing" defaultMessage="List of Entities" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/entities/entity/${entityId}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 4,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  const identifyHazardEventcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="hazardEvent.hazardEvents" defaultMessage="Hazard Events" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 2,
      name: hazardEventHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/hazard-events/hazard-event-view/${hazardEventId}`,
      showlink: true,
      showCrumb: true
    },
    {
      orderNo: 3,
      name: <FormattedMessage id="controlMeasure.controlMeasures" defaultMessage="Control Measures" />,
      url: ``,
      showlink: false,
      showCrumb: true
    }
  ];

  let breadcrumb = identifyHazardEventcrumb;

  if (segmentName === 'define') {
    breadcrumb = defineEntityCrumb;
  }

  return (
    <div className={s.controlMeasureList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        {segmentName !== 'control' && <Breadcrumb data={breadcrumb} segmentName={segmentName} />}

        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />

        <div className={s.contentWrapper}>
          {controlMeasure.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={controlMeasure.showBanner}
            status={controlMeasure.isOpSuccessful}
            successText={messageText}
          />

          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpStatus"
                dataArray={statusOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="common.status" defaultMessage="Status" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onStatusChange(e)}
                selectedOption={statusOptions().filter(option => option.value === filterStatusId)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="common.search"
              />
            </Col>
            <Col sm={12} lg={6}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_CONTROL_MEASURES_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={controlMeasureObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={controlMeasure.currentPage}
            >
              <ControlMeasureListTable
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                editAllowed={isEditAllowed}
                deleteAllowed={isDeleteAllowed}
                editControlMeasure={onChangeControlMeasureViewClick}
                removeControlMeasure={onControlMeasureRemoveClick}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(ControlMeasureList);
