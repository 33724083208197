import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../../utils/index';

import {
  SAFETY_PLAN_REPORT_ERROR,
  GET_ALL_SAFETY_PLAN_REPORT_DETAILS,
  SAFETY_PLAN_REPORT_IS_LOADING,
  SET_SAFETY_PLAN_REPORT_CURRENT_PAGE,
  DOWNLOAD_SAFETY_PLAN_REPORT,
  GENERATE_SAFETY_PLAN_REPORT,
  REMOVE_SAFETY_PLAN_REPORT
} from '../../../constants/index';

import { SAFETY_PLAN_REPORT_MANAGEMENT, GENERATE_SAFETY_PLAN_REPORT_LOG, DOWNLOAD_SAFETY_PLAN_REPORT_LOG } from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setSafetyPlanReportErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setSafetyPlanReportError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setSafetyPlanReportError(messageCode, false));
  }, 2500);
};

export const setSafetyPlanReportError = (messageCode, status) => {
  return {
    type: SAFETY_PLAN_REPORT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || '',
        showNewDownloadLink: false,
        newDownloadLink: '',
        downloadFetchComplete: false
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: SAFETY_PLAN_REPORT_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const generateDownloadSafetyPlanReportThunk = (data, widgetCode) => dispatch => {
  let url = `${process.env.REACT_APP_API_ENDPOINT_WQSP}/reporting/safetyplan`;
  let actionName = 'Unknown safety report action';
  if (data.PerformGenerate) {
    actionName = GENERATE_SAFETY_PLAN_REPORT_LOG;
  } else if (data.PerformDownload) {
    actionName = DOWNLOAD_SAFETY_PLAN_REPORT_LOG;
  }

  //empty the html as the size could be big
  data.html = '';
  let log = logEntry(SAFETY_PLAN_REPORT_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  let updatedBy = Cookies.get(`userid-${envName}`) || 0;
  data.UpdatedBy = updatedBy;
  data.OrganisationId = orgId;
  dispatch(generateDownloadSafetyPlanReport(data, url, log, widgetCode));
};

export const generateDownloadSafetyPlanReport = (data, url, log, widgetCode) =>
  apiAction({
    url: url,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setGenerateDownloadSafetyPlanReportThunk(retData, data),
    onFailure: err => setSafetyPlanReportErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setGenerateDownloadSafetyPlanReportThunk = (retData, data) => dispatch => {
  if (data.PerformDownload) {
    dispatch(setDownloadSafetyPlanReport(retData, true));
  }
  if (data.PerformGenerate) {
    dispatch(setGenerateSafetyPlanReport(retData, true));
  }
  setTimeout(() => {
    if (data.PerformDelete) {
      dispatch(removeSafetyPlanReport(data.SafetyPlanReportId));
    }
  }, 2500);
};

export const setDownloadSafetyPlanReport = (retData, status) => ({
  type: DOWNLOAD_SAFETY_PLAN_REPORT,
  payload: {
    data: {
      isOpSuccessful: status,
      isLoading: false,
      showBanner: false,
      isRedirect: false,
      showNewDownloadLink: false,
      downloadFetchComplete: false,
      newDownloadLink: '',
      displayMessageCode: retData.message
    },
    downloadData: retData.downloadData
  }
});

export const setGenerateSafetyPlanReport = (retData, status) => ({
  type: GENERATE_SAFETY_PLAN_REPORT,
  payload: {
    data: {
      isOpSuccessful: status,
      isLoading: false,
      showBanner: false,
      isRedirect: false,
      showNewDownloadLink: false,
      downloadFetchComplete: false,
      newDownloadLink: '',
      displayMessageCode: retData.message
    },
    generatedData: retData.reportDetails
  }
});

export const removeSafetyPlanReport = data => ({
  type: REMOVE_SAFETY_PLAN_REPORT,
  payload: {
    safetyPlanReportId: data
  }
});

export const getSafetyPlanReportThunk = (widgetCode, reportData) => dispatch => {
  let postData = {
    OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 1,
    SiteId: reportData.SiteId,
    SafetyPlanId: reportData.SafetyPlanId,
    Offset: reportData.Offset,
    Limit: reportData.Limit,
    FetchHtml: reportData.FetchHtml
  };
  dispatch(getSafetyPlanReport(widgetCode, postData));
};

export const getSafetyPlanReport = (widgetCode, postData) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/reporting/safetyplan`,
    method: 'POST',
    data: {
      data: postData
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setSafetyPlanReportThunk(retData),
    onFailure: err => setSafetyPlanReportErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setSafetyPlanReportThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.reportDetails) || [];
  dispatch(setSafetyPlanReport(messageCode, data));
};

export const setSafetyPlanReport = (messageCode, data) => ({
  type: GET_ALL_SAFETY_PLAN_REPORT_DETAILS,
  payload: {
    data: {
      safetyPlanDownloadReportList: data.SafetyPlanReportList,
      selectedSafetyPlanReport: data.SafetyPlanReport,
      safetyPlanDownloadReportListCount: data.SafetyPlanReportList.length,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      downloadFetchComplete: false,
      isOpSuccessful: false,
      showNewDownloadLink: '',
      newDownloadLink: ''
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_SAFETY_PLAN_REPORT_CURRENT_PAGE,
  payload: { currentPage }
});
