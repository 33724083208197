import React, { Fragment, useState } from 'react';
import s from './Entity.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import EntityItem from './EntityItem';
import { WidgetVisibility } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';

const EntityItemTable = ({
  hazardEventTemplateItems,
  hazardEventTemplates,
  setShowAddEdit,
  showAddEdit,
  disableMode,
  setDisableMode,
  onAddEntityItem,
  onChangeEntityItem,
  userProfileWidgets,
  updateAllowed,
  view,
  intl
}) => {
  const [searchFilter, setSearchFilter] = useState('');

  const searchHandler = value => {
    setSearchFilter(value);
  };

  let hazardEventTemplateItemsFiltered =
    (searchFilter &&
      hazardEventTemplateItems.filter(item => item.HazardEventLibraryName.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0)) ||
    hazardEventTemplateItems;

  const addNewEntityItemHandler = () => {
    setShowAddEdit(true);
    setDisableMode(true);
  };

  const deleteDataHandler = (entityHazardId, identifer) => {
    let entityItemList = [...hazardEventTemplateItemsFiltered] || [];
    if (entityHazardId) {
      entityItemList = entityItemList.map(item => {
        if (item.GuidIdentifier === identifer) {
          item.IsDeleted = true;
        }
        return item;
      });
    } else if (entityHazardId === 0) {
      entityItemList = entityItemList.filter(item => item.GuidIdentifier !== identifer);
    }
    onChangeEntityItem(entityItemList);
  };

  const onCancelEntityItem = () => {
    setShowAddEdit(false);
    setDisableMode(false);
  };

  const EventContent = (
    <EntityItem
      onAddEntityItem={onAddEntityItem}
      hazardEventTemplates={hazardEventTemplates}
      hazardEventTemplateItems={hazardEventTemplateItems}
      onCancelEntityItem={onCancelEntityItem}
    />
  );

  let newRowClass = showAddEdit ? s.newRow : '';

  return (
    <div className={s.entity}>
      <div className={s.itemContent}>
        <Row>
          <Col>
            <h4 className={s.subheading}>
              <FormattedMessage id="entity.knownhazardEventLibraryItems" defaultMessage="Known Hazard Event Library Items" />
              <span className={s.circle}>{hazardEventTemplateItemsFiltered.filter(item => !item.IsDeleted).length || 0}</span>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              initialText={searchFilter}
              placeHolderTextId="common.search"
            />
          </Col>
          <Col xs={6} lg={8}>
            {(!view && (
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_ENTITIES_HAZARDLIBRARY_ADD')}
                onClick={addNewEntityItemHandler}
                disabled={disableMode}
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            )) ||
              ''}
          </Col>
        </Row>
      </div>
      <div>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="common.name" defaultMessage="Name" />
              </th>
              <th>
                <FormattedMessage id="common.description" defaultMessage="Description" />
              </th>
              {updateAllowed && <th></th>}
            </tr>
          </thead>
          <tbody>
            {showAddEdit && (
              <tr>
                <td colSpan={4}>
                  <div className={newRowClass}>{showAddEdit && EventContent}</div>
                </td>
              </tr>
            )}
            {hazardEventTemplateItemsFiltered.map(item => {
              return (
                !item.IsDeleted && (
                  <tr key={item.GuidIdentifier} data-unittest="EntityItemList">
                    <td>{item.HazardEventLibraryName}</td>
                    <td>{item.IssueDetails}</td>
                    {updateAllowed && (
                      <td>
                        <div className={s.buttonContainer}>
                          <Button
                            variant="outline-secondary"
                            className={s.removeButton}
                            href="#"
                            onClick={deleteDataHandler.bind(this, item.EntityHazardId, item.GuidIdentifier)}
                            disabled={disableMode}
                            data-unittest="deleteData"
                            style={WidgetVisibility(userProfileWidgets, `WQSP_ENTITIES_HAZARDLIBRARY_DELETE`)}
                          >
                            <FormattedMessage id="common.remove" defaultMessage="Remove" />
                          </Button>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

EntityItemTable.defaultProps = {
  hazardEventTemplateItems: {},
  hazards: [],
  hazardClasses: [],
  userProfileWidgets: [],
  showAddEdit: false,
  disableMode: false,
  updateAllowed: false
};

export default injectIntl(EntityItemTable);
