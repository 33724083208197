import React, { useState } from 'react';
import PropTypes from 'prop-types';
import s from './RiskAssessment.module.scss';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Activity from '../WSAControls/Activity/Activity';
import uuidv4 from 'uuid/v4';
import { initialRiskAssessmentState } from '../../store/reducers/initialState';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { compareValues } from '../../utils';

const RiskAssessmentComments = ({ riskAssessment, saveRiskAssessmentComment, UpdateAllowed, currentTimezone }) => {
  const [riskAssessmentComment, setRiskAssessmentComment] = useState('');

  const commentChangeHandler = e => {
    setRiskAssessmentComment(e.target.value);
  };

  const commentSaveHandler = () => {
    if (riskAssessmentComment.length > 0) {
      const comment = {
        RiskAssessmentId: riskAssessment.selectedRiskAssessment.RiskAssessmentId,
        GuidIdentifier: uuidv4(),
        Description: riskAssessmentComment
      };
      saveRiskAssessmentComment(comment);
      setRiskAssessmentComment('');
    }
  };

  const commentCancelHandler = () => {
    setRiskAssessmentComment('');
  };

  const comments = () => {
    let commentstData = riskAssessment.selectedRiskAssessment.comments || [];

    if (commentstData && commentstData.length > 0) {
      commentstData = commentstData.map(comment => ({
        ...comment,
        ActivityDate: comment.CreatedDateTime,
        CreatedByName: comment.FullName,
        Id: comment.RiskAssessmentCommentId
      }));
    }

    return commentstData.sort(compareValues('ActivityDate', 'desc'));
  };

  const headertext = '';

  return (
    <div className={s.riskAssessmentComment}>
      {riskAssessment.isLoading && <LoadingSpinner />}
      <Row className={s.breakrow}>
        <Col sm={12}>
          <h4>
            <FormattedMessage id="common.comments" defaultMessage="Comments" />
            <span data-unittest="hazardDataCount" className={s.circle}>
              {riskAssessment.selectedRiskAssessment.comments.length}
            </span>
          </h4>
        </Col>
      </Row>
      <Row className={s.breakrow}>
        <Col sm={12}>
          <Activity
            description={riskAssessmentComment || ''}
            onActivityModified={commentChangeHandler}
            onSaveClick={commentSaveHandler}
            onCancelClick={commentCancelHandler}
            currentTimezone={currentTimezone}
            activities={comments()}
            isUpdateAllowed={UpdateAllowed}
            isViewAllowed={true}
            headingText={headertext}
          />
        </Col>
      </Row>
    </div>
  );
};

RiskAssessmentComments.defaultProps = {
  riskAssessment: {
    ...initialRiskAssessmentState
  }
};

RiskAssessmentComments.propTypes = {
  riskAssessment: PropTypes.object.isRequired,
  saveRiskAssessmentComment: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  UpdateAllowed: PropTypes.bool.isRequired
};

export default RiskAssessmentComments;
