import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './WaterSupplyDescription.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialWaterSupplyDescriptionState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import uuid from 'uuid';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import { getUserProfileWidget, getWaterSupplyDescription, getGeneralData, getCurrentTimezone } from '../../selectors/index';
import {
  describeWaterSupplyDescriptionThunk,
  saveWaterSupplyDescriptionThunk,
  setWaterSupplyDescriptionChangeThunk
} from '../../store/actions/waterSupplyDescriptions';
import moment from 'moment-timezone';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { FREQUENCY, GENERAL_STATUS } from '../../constants/index';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Breadcrumb from '../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const WaterSupplyDescription = ({ intl }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  let { waterSupplyDescriptionId, siteId, segmentName, selectedTab } = useParams();
  waterSupplyDescriptionId = waterSupplyDescriptionId && parseInt(waterSupplyDescriptionId);
  siteId = siteId && parseInt(siteId);

  const ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  let IsEdit = false;

  const generalData = useSelector(state => getGeneralData(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);
  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const waterSupplyDescription = useSelector(state => getWaterSupplyDescription(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);

  let selectedDesc = waterSupplyDescription.selectedWaterSupplyDescription;

  const [localformErrors, setFormErrors] = useState({});
  const [fileUploadStarted, setFileUploadStarted] = useState(false);

  const backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/water-supply-descriptions`;
  let widgetCode = '';
  if (waterSupplyDescriptionId) {
    IsEdit = true;
    widgetCode = 'WQSP_WATERSUPPLYDESCRIPTIONS_UPDATE';
  }

  useEffect(() => {
    if (waterSupplyDescriptionId) {
      dispatch(describeWaterSupplyDescriptionThunk(waterSupplyDescriptionId, siteId, widgetCode));
    }
  }, [describeWaterSupplyDescriptionThunk, waterSupplyDescriptionId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setWaterSupplyDescriptionChangeThunk({
          ...initialWaterSupplyDescriptionState.waterSupplyDescription
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalWaterSupplyDescription = currentState => {
    dispatch(setWaterSupplyDescriptionChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = selectedDesc;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.WaterSupplyDescriptionId = 0;
      saveData.SiteId = siteId;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_WATERSUPPLYDESCRIPTIONS_ADD';
    }

    dispatch(saveWaterSupplyDescriptionThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!waterSupplyDescription || !selectedDesc || !selectedDesc.Name || isRequired(selectedDesc.Name, 1)) {
      formErrors.Name = intl.formatMessage({
        id: 'common.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalWaterSupplyDescription({
      ...waterSupplyDescription,
      selectedWaterSupplyDescription: { ...selectedDesc, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const onUploadStart = files => {
    setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          WaterSupplyDescriptionId: 0,
          ImageId: item.fileGuid,
          newFile: true,
          S3URL: item.S3URL,
          Name: item.name
        };
      });
      let allFiles = [...selectedDesc.files, ...newFiles];
      setLocalWaterSupplyDescription({
        selectedWaterSupplyDescription: {
          ...selectedDesc,
          files: allFiles
        }
      });
    }
  };

  const removeFile = fileGuid => {
    let files = selectedDesc.files.filter(file => file.ImageId !== fileGuid);
    let deletedFileIds = [...waterSupplyDescription.selectedWaterSupplyDescription.deletedFileIds];
    deletedFileIds.push(fileGuid);
    setLocalWaterSupplyDescription({
      ...waterSupplyDescription,
      selectedWaterSupplyDescription: { ...selectedDesc, files: files, deletedFileIds: deletedFileIds }
    });
  };

  let messageId = (waterSupplyDescription && waterSupplyDescription.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const waterSupplyDescriptionName = (selectedDesc ? selectedDesc.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + waterSupplyDescriptionName;
  } else {
    heading = intl.formatMessage({
      id: 'waterSupplyDescription.addNewWaterSupplyDescription',
      defaultMessage: 'Add new Water Supply Description'
    });
  }
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();

    setLocalWaterSupplyDescription({
      selectedWaterSupplyDescription: {
        ...selectedDesc,
        [fieldName]: changeDate
      }
    });
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };
  const onStatusChange = e => {
    setLocalWaterSupplyDescription({
      selectedWaterSupplyDescription: {
        ...selectedDesc,
        [e.target.name]: e.target.value,
        StatusId: null
      }
    });
  };

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalWaterSupplyDescription({
      selectedWaterSupplyDescription: {
        ...selectedDesc,
        [fieldName]: e.value
      }
    });
  };

  const breadcrumb = [
    {
      orderNo: 1,
      name: <FormattedMessage id="siteControlsTabs.waterSupplyDescriptionList" defaultMessage="List of Water Supply Descriptions" />,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/water-supply-descriptions`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 2, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  return (
    <div className={s.waterSupplyDescription}>
      {waterSupplyDescription.isLoading && <LoadingSpinner />}
      {waterSupplyDescription.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={waterSupplyDescription.showBanner}
        status={waterSupplyDescription.isOpSuccessful}
        successText={messageText}
      />
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <div className={s.contentWrapper}>
          <Breadcrumb data={breadcrumb} segmentName={segmentName} />
          <div className={s.waterSupplyDescriptionContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formWaterSupplyDescriptionName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={selectedDesc.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formWaterSupplyDescriptionDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={selectedDesc.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formWaterSupplyDescriptionSuppliedWaterVolume">
                      <Form.Label>
                        <FormattedMessage id="waterSupplyDescription.suppliedWaterVolume" defaultMessage="Supplied Water Volume" />
                      </Form.Label>

                      <InputNumber
                        name="SuppliedWaterVolume"
                        step=".01"
                        onInputChange={onChange}
                        customClassName={`${s.smallText}`}
                        value={selectedDesc.SuppliedWaterVolume?.toString()}
                        id="SuppliedWaterVolume"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formWaterSupplyDescriptionCommunityManaged">
                      <Form.Label>
                        <FormattedMessage id="waterSupplyDescription.communityManaged" defaultMessage="Community Managed" />
                      </Form.Label>

                      <ToggleSwitch
                        handleClick={() => {
                          onChange({ target: { name: 'CommunityManaged', value: !selectedDesc.CommunityManaged } });
                        }}
                        classname={s.switch}
                        checked={selectedDesc.CommunityManaged}
                        labelChecked={yesText}
                        labelUnchecked={noText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formWaterSupplyDescriptionWaterFeesCollected">
                      <Form.Label>
                        <FormattedMessage id="waterSupplyDescription.waterFeesCollected" defaultMessage="Water Fees Collected" />
                      </Form.Label>

                      <ToggleSwitch
                        handleClick={() => {
                          onChange({ target: { name: 'WaterFeesCollected', value: !selectedDesc.WaterFeesCollected } });
                        }}
                        classname={s.switch}
                        checked={selectedDesc.WaterFeesCollected}
                        labelChecked={yesText}
                        labelUnchecked={noText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formWaterSupplyDescriptionWaterFeesDetails">
                      <Form.Label>
                        <FormattedMessage id="waterSupplyDescription.waterFeesDetails" defaultMessage="Water Fees Details" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="WaterFeesDetails"
                        onChange={onChange}
                        value={selectedDesc.WaterFeesDetails}
                        className={s.formControl}
                        placeholder={intl.formatMessage({
                          id: 'waterSupplyDescription.waterFeesDetails',
                          defaultMessage: 'Water Fees Details'
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formWaterSupplyDescriptionLatitude">
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.gpsLatitude" defaultMessage="GPS Latitude" />
                      </Form.Label>
                      <InputNumber
                        name="GpsLatitude"
                        step=".01"
                        onInputChange={onChange}
                        customClassName={`${s.smallText}`}
                        value={selectedDesc.GpsLatitude?.toString()}
                        hideStepArrow={true}
                        placeholder={intl.formatMessage({
                          id: 'wqsp.common.gpsLatitude',
                          defaultMessage: 'GPS Latitude'
                        })}
                        id="GpsLatitude"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formWaterSupplyDescriptionLongitude">
                      <Form.Label>
                        <FormattedMessage id="wqsp.common.gpsLongitude" defaultMessage="GPS Longitude" />
                      </Form.Label>
                      <InputNumber
                        name="GpsLongitude"
                        step=".01"
                        onInputChange={onChange}
                        customClassName={`${s.smallText}`}
                        value={selectedDesc.GpsLongitude?.toString()}
                        hideStepArrow={true}
                        placeholder={intl.formatMessage({
                          id: 'wqsp.common.gpsLongitude',
                          defaultMessage: 'GPS Longitude'
                        })}
                        id="GpsLongitude"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formLastReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        isValidDate={disableFutureDates}
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'LastReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(selectedDesc.LastReviewDate)}
                        defaultValue={getFormattedate(selectedDesc.LastReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formNextReviewDate">
                      <Form.Label>
                        <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                      </Form.Label>
                      <DateTimePicker
                        className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                        onChange={m => onDateChange(m, 'NextReviewDate')}
                        timeFormat={null}
                        dateFormat="DD-MMM-YYYY"
                        value={getFormattedate(selectedDesc.NextReviewDate)}
                        defaultValue={getFormattedate(selectedDesc.NextReviewDate)}
                        closeOnSelect={true}
                        showClear={false}
                      />
                      {localformErrors && localformErrors.NextReviewDate && (
                        <p role="alert" className={s.error}>
                          {localformErrors.NextReviewDate}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <Form.Group controlId="formGroupReviewFrequency">
                      <Form.Label>
                        <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                      </Form.Label>

                      <Dropdown
                        id="formMaintenanceFrequency"
                        dataArray={frequencyOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        name="ReviewFrequencyId"
                        onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                        selectedOption={frequencyOptions().filter(option => option.value === selectedDesc.ReviewFrequencyId)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formActioned">
                      <Form.Label>
                        <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                      </Form.Label>

                      <ToggleSwitch
                        handleClick={() => {
                          onStatusChange({ target: { name: 'IsActioned', value: !selectedDesc.IsActioned } });
                        }}
                        classname={s.switch}
                        checked={selectedDesc.IsActioned}
                        labelChecked={yesText}
                        labelUnchecked={noText}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!selectedDesc.IsActioned && (
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formStatus">
                        <Form.Label>
                          <FormattedMessage id="common.status" defaultMessage="Status" />
                        </Form.Label>

                        <Dropdown
                          id="formStatus"
                          dataArray={statusOptions()}
                          controlData={{ placeholderText: ddlInitText }}
                          onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                          data-unittest="formDdlRiskStatus"
                          selectedOption={statusOptions().filter(option => option.value === selectedDesc.StatusId)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Label>
                      <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                    </Form.Label>
                    <FileUploader
                      widgetCode={'WQSP_WATERSUPPLYDESCRIPTIONS_ATTACH'}
                      allowedFileTypes={[
                        ['image/jpeg', 'jpeg'],
                        ['application/pdf', 'pdf'],
                        ['application/msword', 'doc']
                      ]}
                      location={`organisation/${orgId}/sites/${siteId}/waterSupplyDescriptions`}
                      enableMultipleUpload={true}
                      maxFiles={5}
                      onUploadComplete={onUploadComplete}
                      onUploadStart={onUploadStart}
                    />
                  </Col>
                </Row>
                <FileList files={selectedDesc.files} onRemoveFile={removeFile} />
                <Row className={s.btnRow}>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                      disabled={fileUploadStarted}
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(WaterSupplyDescription);
