import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';
import uuidv4 from 'uuid/v4';

import {
  INSPECTION_REPORT_ERROR,
  GET_ALL_INSPECTION_REPORT_DETAILS,
  INSPECTION_REPORT_IS_LOADING,
  SET_INSPECTION_REPORT_CURRENT_PAGE,
  SET_INSPECTION_REPORT_PAGE_FILTER,
  SET_INSPECTION_REPORT_CHANGE,
  SET_INSPECTION_REPORT,
  SET_INSPECTION_REPORT_CLEANUP,
  UPDATE_INSPECTION_REPORT_SAVE_STATUS,
  SET_INSPECTION_REPORT_FILTERS,
  SET_INSPECTION_REPORT_RESULTS,
  INSPECTION_REPORT_LISTING_CLEANUP,
  SET_INSPECTION_REPORT_QUESTIONS,
  SET_INSPECTION_REPORT_QUESTIONS_CHANGE,
  REMOVE_INSPECTION_REPORT
} from '../../constants/index';

import { INSPECTION_REPORT_MANAGEMENT, CREATE_INSPECTION_REPORT, UPDATE_INSPECTION_REPORT } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setInspectionReportErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setInspectionReportError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setInspectionReportError(messageCode, false));
  }, 2500);
};

export const setInspectionReportError = (messageCode, status) => {
  return {
    type: INSPECTION_REPORT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: INSPECTION_REPORT_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getInspectionReportThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getInspectionReport(widgetCode, orgId, filterObj));
};

export const getInspectionReport = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/inspection/inspectionreport`,
    method: 'GET',
    data: {
      OrgId: orgId,
      filterEntityType: filterObj.filterEntityType,
      filterEntity: filterObj.filterEntity,
      filterSiteId: filterObj.filterSiteId,
      filterInspectionType: filterObj.filterInspectionType,
      filter: filterObj.filter,
      limit: filterObj.limit,
      offset: filterObj.offset
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllInspectionReportListThunk(retData),
    onFailure: err => setInspectionReportErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllInspectionReportListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  dispatch(setAllInspectionReportList(messageCode, data));
};

export const setAllInspectionReportList = (messageCode, data) => ({
  type: GET_ALL_INSPECTION_REPORT_DETAILS,
  payload: {
    data: {
      InspectionReports: data,
      filteredResults: data,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_INSPECTION_REPORT_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_INSPECTION_REPORT_PAGE_FILTER,
  payload: { filter }
});

export const describeInspectionReportThunk = (InspectionReportId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeInspectionReport(InspectionReportId, widgetCode, orgId));
};

export const describeInspectionReport = (InspectionReportId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/inspection/inspectionreport`,
    data: {
      InspectionReportId: InspectionReportId,
      OrgId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setInspectionReportThunk(retData, InspectionReportId),
    onFailure: err => setInspectionReportErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setInspectionReportThunk = ({ retData }, InspectionReportId) => dispatch => {
  let data = retData.data;
  if (InspectionReportId > 0 && (!data || data.length === 0 || parseInt(InspectionReportId) !== data.InspectionReportId)) {
    redirectToPageNotFound(dispatch);
  }
  const inspectionTypes = retData.inspectionTypes;
  const inspectionPurposes = retData.inspectionPurposes;
  dispatch(setInspectionReport(data, inspectionTypes, inspectionPurposes));
};

export const setInspectionReport = (data, inspectionTypes, inspectionPurposes) => ({
  type: SET_INSPECTION_REPORT,
  payload: {
    data: data,
    inspectionTypes: inspectionTypes,
    inspectionPurposes: inspectionPurposes
  }
});

export const saveInspectionReportThunk = (data, widgetCode) => dispatch => {
  let url = '/inspection/inspectionreport/save';
  let actionName = data.InspectionReportId === 0 ? CREATE_INSPECTION_REPORT : UPDATE_INSPECTION_REPORT;
  let log = logEntry(INSPECTION_REPORT_MANAGEMENT, actionName, data);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  data.OrgId = orgId;
  dispatch(saveInspectionReport(data, url, log, widgetCode));
};

export const saveInspectionReport = (data, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setInspectionReportStatusThunk(retData, data),
    onFailure: err => setInspectionReportErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: data,
      log: log
    },
    widgetcode: widgetCode
  });

export const setInspectionReportStatusThunk = (message, data) => dispatch => {
  dispatch(setInspectionReportStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !data.PerformDelete;
    dispatch(setInspectionReportStatus(message, false, isRedirect));
    if (data.PerformDelete) {
      dispatch(removeInspectionReport(data.InspectionReportId));
    }
  }, 2500);
};

export const setInspectionReportStatus = (messageCode, status, redirect) => ({
  type: UPDATE_INSPECTION_REPORT_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: status,
      showBanner: status,
      isLoading: false,
      isRedirect: redirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const removeInspectionReport = data => ({
  type: REMOVE_INSPECTION_REPORT,
  payload: {
    inspectionReportId: data
  }
});

export const setInspectionReportChangeThunk = retData => dispatch => {
  dispatch(setInspectionReportChange(retData));
};

export const setInspectionReportChange = retData => ({
  type: SET_INSPECTION_REPORT_CHANGE,
  payload: retData
});

export const setInspectionReportCleanUpThunk = SelectedInspectionReport => dispatch => {
  dispatch({ type: SET_INSPECTION_REPORT_CLEANUP, payload: SelectedInspectionReport });
};

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fInspectionTypes: true,
      SiteId: siteId,
      filterFor: 'inspectionReportListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setInspectionReportErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_INSPECTION_REPORT_FILTERS,
  payload: {
    data: {
      filterData: {
        InspectionTypes: filterData.inspectionTypes
      },
      isLoading: false,
      inspectionReportCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_INSPECTION_REPORT_RESULTS,
  payload: retData
});

export const cleanUpThunk = retData => dispatch => {
  dispatch(cleanUp(retData));
};

export const cleanUp = retData => ({
  type: INSPECTION_REPORT_LISTING_CLEANUP,
  payload: retData
});

export const getInspectionTypeQuestionsThunk = (InspectionTypeId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getInspectionTypeQuestions(InspectionTypeId, widgetCode, orgId));
};

export const getInspectionTypeQuestions = (InspectionTypeId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/inspection/inspectiontypequestion`,
    data: {
      InspectionTypeId: InspectionTypeId,
      OrgId: orgId,
      QuestionsOnly: true
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setInspectionTypeQuestionsThunk(retData),
    onFailure: err => setInspectionReportErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setInspectionTypeQuestionsThunk = ({ retData }) => dispatch => {
  let data = retData.data || [];
  const questionsList = [];
  if (data.length > 0) {
    data = data.filter(x => x.IsActive);
    data.forEach(item => {
      questionsList.push({
        InspectionAssesmentQuestionId: 0,
        InspectionReportId: 0,
        InspectionTypeQuestionId: item.InspectionTypeQuestionId,
        GuidIdentifier: uuidv4(),
        QuestionNumber: item.QuestionNumber,
        QuestionText: item.QuestionText,
        Score: item.Score,
        IsActive: true,
        Result: false,
        Comment: '',
        ExplanatoryNote: item.ExplanatoryNote
      });
    });
  }

  dispatch(setInspectionTypeQuestion(questionsList));
};

export const setInspectionTypeQuestion = questionsList => ({
  type: SET_INSPECTION_REPORT_QUESTIONS,
  payload: {
    data: {
      AssesmentQuestions: questionsList
    }
  }
});

export const setInspectionTypeQuestionChangeThunk = retData => dispatch => {
  dispatch(setInspectionTypeQuestionChange(retData));
};

export const setInspectionTypeQuestionChange = retData => ({
  type: SET_INSPECTION_REPORT_QUESTIONS_CHANGE,
  payload: retData
});
