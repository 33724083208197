import React, { useEffect, useState } from 'react';
import s from './Hazards.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getHazard, getGeneralData } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialHazardState } from '../../store/reducers/initialState';
import { getHazardThunk, saveHazardThunk } from '../../store/actions/hazards';
import HazardsListTable from './HazardsListTable';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';
import { HAZARD_CLASS } from '../../constants/index';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const HazardsList = ({ userProfileWidgets, intl }) => {
  const history = useHistory();
  const HAZARD_CLASS_FILTER = 'HAZARD_CLASS_FILTER';
  let initialLimit = 6;

  const dispatch = useDispatch();
  const hazard = useSelector(state => getHazard(state));
  const generalData = useSelector(state => getGeneralData(state));

  const hazardClasses = generalData.generalFieldTypes.filter(item => item.FieldType === HAZARD_CLASS);

  let initialRecords = hazard.Hazards.slice(0, initialLimit);
  const [hazardOpen, setHazardOpen] = useState(false);

  useEffect(() => {
    dispatch(getHazardThunk('WQSP_HAZARDS_LIST', {}));
  }, [getHazardThunk, dispatch, hazard.isOpSuccessful]);

  const [localhazards, setLocalhazards] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [hazardId, setHazardId] = useState(0);
  const [filterHazardClassId, setFilterHazardClassId] = useState(0);
  const [searchText, setSearchText] = useState('');

  const localHazardLength = localhazards.length;
  const globalSettingLength = hazard.Hazards.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalhazards(hazard.Hazards);
    }
    setShowAll(!showAll);
  };

  const searchHandler = (value, type) => {
    let filteredList = hazard.Hazards;

    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }

    if (type === HAZARD_CLASS_FILTER) {
      setFilterHazardClassId(value);
      if (value > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.HazardClassId === value;
        });
        setIsFilter(true);
      }

      if (searchText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchText.toLowerCase());
        });
        setIsFilter(true);
      }
    } else {
      setSearchText(value);
      if (value !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
        setIsFilter(true);
      }

      if (filterHazardClassId > 0) {
        filteredList = filteredList.filter(function(item) {
          return item.HazardClassId === filterHazardClassId;
        });
        setIsFilter(true);
      }
    }

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalhazards(filteredList);
  };

  const hazardClassOptions = () => {
    let classData = [];
    hazardClasses.forEach(element => {
      classData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = classData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allHazardClasses" defaultMessage="All Hazard Classes" />,
      value: 0
    });

    return sortedData;
  };

  const filterData = () => {
    let filteredList = hazard.Hazards;

    if (filterHazardClassId > 0) {
      filteredList = filteredList.filter(function(item) {
        return item.HazardClassId === filterHazardClassId;
      });
    }

    if (searchText !== '') {
      filteredList = filteredList.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getHazardData = () => {
    let hazard = [];
    if (showAll) {
      hazard = filterData();
    } else {
      if (isFilter) {
        hazard = localHazardLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        hazard = initialRecords;
      }
    }
    return hazard.sort(compareValues('Name'));
  };

  const onRemoveHazardClick = hazardId => {
    setActive(true);
    setHazardId(hazardId);
  };

  const DeleteHazard = () => {
    let saveData = hazard.selectedHazard;
    saveData.PerformDelete = true;
    saveData.HazardId = hazardId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveHazardThunk(saveData, 'WQSP_HAZARDS_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setHazardId(0);
  };

  const handleConfirm = () => {
    DeleteHazard();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push('/operational-setup/hazards/hazard');
  };

  const onChangeHazardClick = hazardId => {
    history.push(`/operational-setup/hazards/hazard/${hazardId}`);
  };

  let messageId = (hazard && hazard.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDS_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_HAZARDS_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'hazards.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this hazard?'
  });

  let disableControls = hazard.isLoading || false;

  return (
    <div className={s.hazardList}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'WQSP_HAZARDS_LIST')}>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={hazardOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setHazardOpen(!hazardOpen)}
                aria-controls="hazard-section"
                aria-expanded={hazardOpen}
              ></img>
              <h4>
                <FormattedMessage id="hazards.hazards" defaultMessage="Hazards" />
              </h4>
              <span className={s.circle}>{hazard.Hazards.length}</span>
            </Row>
          </Col>
        </Row>
        <Collapse in={hazardOpen}>
          <div className={s.innerComponent} id="hazard-section">
            <div className={s.component}>
              {hazard.isLoading && <LoadingSpinner />}
              {hazard.showBanner && (
                <Banner failureText={messageText} showBanner={hazard.showBanner} status={hazard.isOpSuccessful} successText={messageText} />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <div className={s.contentWrapper}>
                <Row className={s.topRow}>
                  <Col sm={6} lg={2}>
                    <Dropdown
                      id="drpModelId"
                      dataArray={hazardClassOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="wqsp.common.hazardClass" defaultMessage="Hazard Class" />,
                        customClassName: ''
                      }}
                      onDropdownChange={e => searchHandler(e.value, HAZARD_CLASS_FILTER)}
                      selectedOption={hazardClassOptions().filter(option => option.value === filterHazardClassId)}
                      disabled={disableControls}
                    />
                  </Col>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={disableControls}
                      // initialText={filter}
                      placeHolderTextId="common.search"
                      data-unittest="searchhazards"
                    />
                  </Col>
                  <Col sm={6} lg={6}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'WQSP_HAZARDS_ADDNEW')}
                      onClick={onAddNewClick}
                      disabled={disableControls}
                      data-unittest="addHazardButton"
                    >
                      + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>

                <div>
                  <HazardsListTable
                    RemoveHazard={onRemoveHazardClick}
                    HazardData={getHazardData()}
                    ChangeHazard={onChangeHazardClick}
                    UpdateAllowed={isUpdateAllowed}
                    DeleteAllowed={isDeleteAllowed}
                  />
                </div>
                {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localHazardLength > initialLimit)) && (
                  <Row className={s.topRow}>
                    <Col>
                      <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                        {!showAll ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localHazardLength})` : showLessText}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

HazardsList.defaultProps = {
  ...initialHazardState
};

export default injectIntl(HazardsList);
