import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../../utils/index';
import uuidv4 from 'uuid/v4';
import { processOrganisationTranslation } from '../../utils/appUtils';

import { TRANSLATION_ERROR, GET_ALL_TRANSLATION_DETAILS, TRANSLATION_IS_LOADING } from '../../constants/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setTranslationErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setTranslationError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTranslationError(messageCode, false));
  }, 2500);
};

export const setTranslationError = (messageCode, status) => {
  return {
    type: TRANSLATION_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: TRANSLATION_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getTranslationThunk = (widgetCode, locale) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getTranslation(widgetCode, locale, orgId));
};

export const getTranslation = (widgetCode, locale, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/translations`,
    method: 'GET',
    data: {
      OrgId: orgId,
      filterLocale: locale,
      fromSideNav: true
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllTranslationListThunk(retData, orgId, locale),
    onFailure: err => setTranslationErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllTranslationListThunk = (retData, orgId, locale) => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.retData.data) || [];
  data = processOrganisationTranslation(data, locale);

  dispatch(setAllTranslationList(messageCode, data, orgId, locale));
};

export const setAllTranslationList = (messageCode, data, orgId, locale) => ({
  type: GET_ALL_TRANSLATION_DETAILS,
  payload: {
    data: {
      isDataLoaded: true,
      translations: data,
      organisationId: orgId,
      locale: locale,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isRedirect: false,
      isOpSuccessful: false
    }
  }
});
