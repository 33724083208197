import { SITESEGMENTS_ERROR, SET_SELECTED_SITESEGMENTS_VIEW, SITESEGMENTS_IS_LOADING, SET_SITESEGMENTS } from '../../constants/index';
import Cookies from 'js-cookie';
import { apiAction } from '../../utils/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setSiteSegmentsErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setSiteSegmentsError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setSiteSegmentsError(messageCode, false));
  }, 2500);
};

export const setSiteSegmentsError = (messageCode, status) => {
  return {
    type: SITESEGMENTS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: SITESEGMENTS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const describeSiteSegmentsThunk = (siteId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeSiteSegments(siteId, widgetCode, orgId));
};

export const describeSiteSegments = (siteId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/sites/site`,
    data: {
      SiteId: siteId,
      OrganisationId: orgId,
      RequireSegmentData: true
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setSiteSegmentsThunk(retData),
    onFailure: err => setSiteSegmentsErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setSiteSegmentsThunk = data => dispatch => {
  dispatch(setSiteSegments(data));
};

export const setSiteSegments = ({ retData }) => ({
  type: SET_SITESEGMENTS,
  payload: {
    siteSegments: retData.data
  }
});

export const setSelectedSiteSegmentsViewThunk = data => dispatch => {
  dispatch(setSelectedSiteSegmentsView(data));
};

export const setSelectedSiteSegmentsView = data => ({
  type: SET_SELECTED_SITESEGMENTS_VIEW,
  payload: {
    siteSegments: {
      selectedView: data
    }
  }
});
