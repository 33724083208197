import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';
import _ from 'lodash';

import {
  CORRECTIVE_ACTIONS_ERROR,
  GET_ALL_CORRECTIVE_ACTIONS_DETAILS,
  CORRECTIVE_ACTIONS_IS_LOADING,
  SET_CORRECTIVE_ACTIONS_CURRENT_PAGE,
  SET_CORRECTIVE_ACTIONS_PAGE_FILTER,
  SET_CORRECTIVE_ACTIONS_CHANGE,
  SET_CORRECTIVE_ACTIONS,
  UPDATE_CORRECTIVE_ACTIONS_SAVE_STATUS,
  SET_CORRECTIVE_ACTIONS_FILTERS,
  SET_CORRECTIVE_ACTIONS_RESULTS,
  SET_CORRECTIVE_ACTIONS_COMMENTS,
  REMOVE_CORRECTIVE_ACTIONS,
  GET_ALL_CONTROL_MONITORINGS_BY_SITE
} from '../../constants/index';

import { CORRECTIVE_ACTION_MANAGEMENT, CREATE_CORRECTIVE_ACTION, UPDATE_CORRECTIVE_ACTION } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setCorrectiveActionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setCorrectiveActionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setCorrectiveActionError(messageCode, false));
  }, 2500);
};

export const setCorrectiveActionError = (messageCode, status) => {
  return {
    type: CORRECTIVE_ACTIONS_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: CORRECTIVE_ACTIONS_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getCorrectiveActionThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getCorrectiveAction(widgetCode, orgId, filterObj));
};

export const getCorrectiveAction = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/correctiveActions/correctiveaction`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      offset: filterObj.offset,
      limit: filterObj.limit,
      filter: filterObj.filter,
      SiteId: filterObj.siteId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllCorrectiveActionListThunk(retData),
    onFailure: err => setCorrectiveActionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllCorrectiveActionListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let correctiveActions = (retData && retData.retData.correctiveActions) || [];
  dispatch(setAllCorrectiveActionList(messageCode, correctiveActions));
};

export const setAllCorrectiveActionList = (messageCode, correctiveActions) => ({
  type: GET_ALL_CORRECTIVE_ACTIONS_DETAILS,
  payload: {
    data: {
      correctiveActions: correctiveActions,
      filteredResults: correctiveActions,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_CORRECTIVE_ACTIONS_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_CORRECTIVE_ACTIONS_PAGE_FILTER,
  payload: { filter }
});

export const describeCorrectiveActionThunk = (correctiveActionId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeCorrectiveAction(correctiveActionId, widgetCode, orgId));
};

export const describeCorrectiveAction = (correctiveActionId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/correctiveActions/correctiveaction`,
    data: {
      CorrectiveActionId: correctiveActionId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCorrectiveActionThunk(retData, correctiveActionId),
    onFailure: err => setCorrectiveActionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setCorrectiveActionThunk = ({ retData }, correctiveActionId) => dispatch => {
  let selectedCorrectiveAction = retData.correctiveActions;
  if (
    correctiveActionId &&
    (!selectedCorrectiveAction ||
      selectedCorrectiveAction.length === 0 ||
      parseInt(correctiveActionId) !== selectedCorrectiveAction.CorrectiveActionId)
  ) {
    redirectToPageNotFound(dispatch);
  }
  let comments = retData.comments || [];
  let mapData = {
    selectedCorrectiveAction: {
      ...selectedCorrectiveAction,
      comments: comments
    }
  };

  dispatch(setCorrectiveAction(mapData));
};

export const setCorrectiveAction = data => ({
  type: SET_CORRECTIVE_ACTIONS,
  payload: data
});

export const saveCorrectiveActionThunk = (correctiveAction, widgetCode) => dispatch => {
  let url = '/correctiveActions/correctiveaction/save';
  let actionName = correctiveAction.CorrectiveActionId === 0 ? CREATE_CORRECTIVE_ACTION : UPDATE_CORRECTIVE_ACTION;
  let log = logEntry(CORRECTIVE_ACTION_MANAGEMENT, actionName, correctiveAction);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  correctiveAction.OrgId = orgId;
  dispatch(saveCorrectiveAction(correctiveAction, url, log, widgetCode));
};

export const saveCorrectiveAction = (correctiveAction, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setCorrectiveActionStatusThunk(retData, correctiveAction),
    onFailure: err => setCorrectiveActionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: correctiveAction,
      log: log
    },
    widgetcode: widgetCode
  });

export const setCorrectiveActionStatusThunk = (data, correctiveAction) => dispatch => {
  let message = data;
  if (correctiveAction.PerformCommentInsert) {
    dispatch(setCorrectiveActionComments(data.retData));
  } else {
    dispatch(setCorrectiveActionStatus(message, true));
    setTimeout(() => {
      //Hide the banner
      let isRedirect = !correctiveAction.PerformDelete;
      dispatch(setCorrectiveActionStatus(message, false, isRedirect));
      if (correctiveAction.PerformDelete) {
        dispatch(removeCorrectiveAction(correctiveAction.CorrectiveActionId));
      }
    }, 2500);
  }
};

export const setCorrectiveActionStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_CORRECTIVE_ACTIONS_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setCorrectiveActionComments = data => ({
  type: SET_CORRECTIVE_ACTIONS_COMMENTS,
  payload: {
    selectedCorrectiveAction: {
      comments: data
    }
  }
});

export const removeCorrectiveAction = data => ({
  type: REMOVE_CORRECTIVE_ACTIONS,
  payload: {
    correctiveActionId: data
  }
});

export const setCorrectiveActionChangeThunk = retData => dispatch => {
  dispatch(setCorrectiveActionChange(retData));
};

export const setCorrectiveActionChange = retData => ({
  type: SET_CORRECTIVE_ACTIONS_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      SiteId: siteId,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      filterFor: 'correctiveActionsListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setCorrectiveActionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_CORRECTIVE_ACTIONS_FILTERS,
  payload: {
    data: {
      isLoading: false,
      correctiveActionListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_CORRECTIVE_ACTIONS_RESULTS,
  payload: retData
});

export const getControlMonitoringsThunk = (filterObj, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getControlMonitorings(widgetCode, orgId, filterObj));
};

export const getControlMonitorings = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/correctiveActions/correctiveaction`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      offset: filterObj.offset,
      limit: filterObj.limit,
      filter: filterObj.filter,
      SiteId: filterObj.siteId,
      GetControlMonitoringsBySiteId: filterObj.getControlMonitoringsBySiteId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllControlMonitoringListThunk(retData),
    onFailure: err => setCorrectiveActionErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllControlMonitoringListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let controlMonitorings = (retData && retData.retData.controlMonitorings) || [];
  dispatch(setAllControlMonitoringList(messageCode, controlMonitorings));
};

export const setAllControlMonitoringList = (messageCode, controlMonitorings) => ({
  type: GET_ALL_CONTROL_MONITORINGS_BY_SITE,
  payload: {
    data: {
      controlMonitorings: controlMonitorings,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});
