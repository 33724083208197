//Organisation Management
export const ORGANISATION_MANAGEMENT = 'ORGANISATION_MANAGEMENT';
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';

//User Management
export const USER_MANAGEMENT = 'USER_MANAGEMENT';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_USER_PASSWORD_FIRST_LOGIN = 'UPDATE_USER_PASSWORD_FIRST_LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_CONFIRMATION = 'FORGOT_PASSWORD_CONFIRMATION';
export const USER_MFA = 'USER_MFA';

//Profile Management
export const PROFILE_MANAGEMENT = 'PROFILE_MANAGEMENT';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

//Widget Management
export const WIDGET_MANAGEMENT = 'WIDGET_MANAGEMENT';
export const CREATE_WIDGET = 'CREATE_WIDGET';

//Unit Management
export const UNIT_MANAGEMENT = 'UNIT_MANAGEMENT';

// Task Template
export const TASK_TEMPLATE_MANAGEMENT = 'TASK_TEMPLATE_MANAGEMENT';
export const CREATE_TASK_TEMPLATE = 'CREATE_TASK_TEMPLATE';
export const UPDATE_TASK_TEMPLATE = 'UPDATE_TASK_TEMPLATE';
export const TASKS_MANAGEMENT = 'TASKS_MANAGEMENT';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const CREATE_TASK_COMMENT = 'CREATE_TASK_COMMENT';

// Checklist Template
export const CHECKLIST_TEMPLATE_MANAGEMENT = 'CHECKLIST_TEMPLATE_MANAGEMENT';
export const CREATE_CHECKLIST_TEMPLATE = 'CREATE_CHECKLIST_TEMPLATE';
export const UPDATE_CHECKLIST_TEMPLATE = 'UPDATE_CHECKLIST_TEMPLATE';

// Checklist
export const CHECKLIST_MANAGEMENT = 'CHECKLIST_MANAGEMENT';
export const CREATE_CHECKLIST = 'CREATE_CHECKLIST';
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST';

//Test purpose
export const TEST_PURPOSE_MANAGEMENT = 'TEST_PURPOSE_MANAGEMENT';
export const CREATE_TEST_PURPOSE = 'CREATE_TEST_PURPOSE';
export const UPDATE_TEST_PURPOSE = 'UPDATE_TEST_PURPOSE';

//Inspection purpose
export const INSPECTION_PURPOSE_MANAGEMENT = 'INSPECTION_PURPOSE_MANAGEMENT';
export const CREATE_INSPECTION_PURPOSE = 'CREATE_INSPECTION_PURPOSE';
export const UPDATE_INSPECTION_PURPOSE = 'UPDATE_INSPECTION_PURPOSE';

//Test Categories
export const TEST_CATEGORY_MANAGEMENT = 'TEST_CATEGORY_MANAGEMENT';
export const CREATE_TEST_CATEGORY = 'CREATE_TEST_CATEGORY';
export const UPDATE_TEST_CATEGORY = 'UPDATE_TEST_CATEGORY';

//Inspection Type
export const INSPECTION_TYPE_MANAGEMENT = 'INSPECTION_TYPE_MANAGEMENT';
export const CREATE_INSPECTION_TYPE = 'CREATE_INSPECTION_TYPE';
export const UPDATE_INSPECTION_TYPE = 'UPDATE_INSPECTION_TYPE';

//Inspection QUESTION
export const INSPECTION_TYPE_QUESTION_MANAGEMENT = 'INSPECTION_TYPE_QUESTION_MANAGEMENT';
export const CREATE_INSPECTION_TYPE_QUESTION = 'CREATE_INSPECTION_TYPE_QUESTION';
export const UPDATE_INSPECTION_TYPE_QUESTION = 'UPDATE_INSPECTION_TYPE_QUESTION';

//Inspection Report
export const INSPECTION_REPORT_MANAGEMENT = 'INSPECTION_REPORT_MANAGEMENT';
export const CREATE_INSPECTION_REPORT = 'CREATE_INSPECTION_REPORT';
export const UPDATE_INSPECTION_REPORT = 'UPDATE_INSPECTION_REPORT';

//Test Result
export const TEST_RESULT_MANAGEMENT = 'TEST_RESULT_MANAGEMENT';
export const CREATE_TEST_RESULT = 'CREATE_TEST_RESULT';
export const UPDATE_TEST_RESULT = 'UPDATE_TEST_RESULT';

//Sites
export const SITE_MANAGEMENT = 'SITE_MANAGEMENT';
export const CREATE_SITE = 'CREATE_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';

//Entities
export const ENTITY_MANAGEMENT = 'ENTITY_MANAGEMENT';
export const CREATE_ENTITY = 'CREATE_ENTITY';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';

//Quality Goals
export const QUALITY_GOALS_MANAGEMENT = 'QUALITY_GOALS_MANAGEMENT';
export const CREATE_QUALITY_GOALS = 'CREATE_QUALITY_GOALS';
export const UPDATE_QUALITY_GOALS = 'UPDATE_QUALITY_GOALS';

//Water supply description
export const WATERSUPPLYDESCRIPTION_MANAGEMENT = 'WATERSUPPLYDESCRIPTION_MANAGEMENT';
export const CREATE_WATERSUPPLYDESCRIPTION = 'CREATE_WATERSUPPLYDESCRIPTION';
export const UPDATE_WATERSUPPLYDESCRIPTION = 'UPDATE_WATERSUPPLYDESCRIPTION';

//General Field Type
export const GENERAL_FIELDTYPE_MANAGEMENT = 'GENERAL_FIELDTYPE_MANAGEMENT';
export const CREATE_GENERAL_FIELDTYPE = 'CREATE_GENERAL_FIELDTYPE';
export const UPDATE_GENERAL_FIELDTYPE = 'UPDATE_GENERAL_FIELDTYPE';

//Risk Category
export const RISK_CATEGORY_MANAGEMENT = 'RISK_CATEGORY_MANAGEMENT';
export const CREATE_RISK_CATEGORY = 'CREATE_RISK_CATEGORY';
export const UPDATE_RISK_CATEGORY = 'UPDATE_RISK_CATEGORY';

//Hazard Events
export const HAZARD_EVENT_MANAGEMENT = 'HAZARD_EVENT_MANAGEMENT';
export const CREATE_HAZARD_EVENT = 'CREATE_HAZARD_EVENT';
export const UPDATE_HAZARD_EVENT = 'UPDATE_HAZARD_EVENT';

//Hazard Event Library
export const HAZARD_EVENT_LIBRARY_MANAGEMENT = 'HAZARD_EVENT_LIBRARY_MANAGEMENT';
export const CREATE_HAZARD_EVENT_LIBRARY = 'CREATE_HAZARD_EVENT_LIBRARY';
export const UPDATE_HAZARD_EVENT_LIBRARY = 'UPDATE_HAZARD_EVENT_LIBRARY';

//Hazards
export const HAZARD_MANAGEMENT = 'HAZARD_MANAGEMENT';
export const CREATE_HAZARD = 'CREATE_HAZARD';
export const UPDATE_HAZARD = 'UPDATE_HAZARD';

//Risk Assessments
export const RISK_ASSESSMENT_MANAGEMENT = 'RISK_ASSESSMENT_MANAGEMENT';
export const CREATE_RISK_ASSESSMENT = 'CREATE_RISK_ASSESSMENT';
export const UPDATE_RISK_ASSESSMENT = 'UPDATE_RISK_ASSESSMENT';

//Safety Plan
export const SAFETY_PLAN_MANAGEMENT = 'SAFETY_PLAN_MANAGEMENT';
export const CREATE_SAFETY_PLAN = 'CREATE_SAFETY_PLAN';
export const UPDATE_SAFETY_PLAN = 'UPDATE_SAFETY_PLAN';

//Safety Plan report
export const SAFETY_PLAN_REPORT_MANAGEMENT = 'SAFETY_PLAN_REPORT_MANAGEMENT';
export const GENERATE_SAFETY_PLAN_REPORT_LOG = 'GENERATE_SAFETY_PLAN_REPORT_LOG';
export const DOWNLOAD_SAFETY_PLAN_REPORT_LOG = 'DOWNLOAD_SAFETY_PLAN_REPORT_LOG';

//Emergency Response Plan
export const EMERGENCY_RESPONSE_PLAN_MANAGEMENT = 'EMERGENCY_RESPONSE_PLAN_MANAGEMENT';
export const CREATE_EMERGENCY_RESPONSE_PLAN = 'CREATE_EMERGENCY_RESPONSE_PLAN';
export const UPDATE_EMERGENCY_RESPONSE_PLAN = 'UPDATE_EMERGENCY_RESPONSE_PLAN';

//Personnel
export const PERSONNEL_MANAGEMENT = 'PERSONNEL_MANAGEMENT';
export const CREATE_PERSONNEL = 'CREATE_PERSONNEL';
export const UPDATE_PERSONNEL = 'UPDATE_PERSONNEL';

//Team
export const TEAM_MANAGEMENT = 'TEAM_MANAGEMENT';
export const CREATE_TEAM = 'CREATE_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';

//Stakeholder Register
export const STAKEHOLDER_REGISTER_MANAGEMENT = 'STAKEHOLDER_REGISTER_MANAGEMENT';
export const CREATE_STAKEHOLDER_REGISTER = 'CREATE_STAKEHOLDER_REGISTER';
export const UPDATE_STAKEHOLDER_REGISTER = 'UPDATE_STAKEHOLDER_REGISTER';

//Regulatory Register
export const REGULATORY_REGISTER_MANAGEMENT = 'REGULATORY_REGISTER_MANAGEMENT';
export const CREATE_REGULATORY_REGISTER = 'CREATE_REGULATORY_REGISTER';
export const UPDATE_REGULATORY_REGISTER = 'UPDATE_REGULATORY_REGISTER';

//Control Measure
export const CONTROL_MEASURE_MANAGEMENT = 'CONTROL_MEASURE_MANAGEMENT';
export const CREATE_CONTROL_MEASURE = 'CREATE_CONTROL_MEASURE';
export const UPDATE_CONTROL_MEASURE = 'UPDATE_CONTROL_MEASURE';
export const UPDATE_CONTROL_MONITORING = 'UPDATE_CONTROL_MONITORING';
export const CORRECTIVE_ACTION_MANAGEMENT = 'CORRECTIVE_ACTION_MANAGEMENT';
export const CREATE_CORRECTIVE_ACTION = 'CREATE_CORRECTIVE_ACTION';
export const UPDATE_CORRECTIVE_ACTION = 'UPDATE_CORRECTIVE_ACTION';
export const CONTROL_MONITORING_MANAGEMENT = 'CONTROL_MONITORING_MANAGEMENT';
export const CREATE_CONTROL_MONITORING = 'CREATE_CONTROL_MONITORING';

//Improvement Plans
export const IMPROVEMENT_PLAN_MANAGEMENT = 'IMPROVEMENT_PLAN_MANAGEMENT';
export const CREATE_IMPROVEMENT_PLAN = 'CREATE_IMPROVEMENT_PLAN';
export const UPDATE_IMPROVEMENT_PLAN = 'UPDATE_IMPROVEMENT_PLAN';

//Organisation translations
export const ORGANISATION_TRANSLATION_MANAGEMENT = 'ORGANISATION_TRANSLATION_MANAGEMENT';
export const CREATE_ORGANISATION_TRANSLATION = 'CREATE_ORGANISATION_TRANSLATION';
export const UPDATE_ORGANISATION_TRANSLATION = 'UPDATE_ORGANISATION_TRANSLATION';

//Incident Reports
export const INCIDENT_REPORTS_MANAGEMENT = 'INCIDENT_REPORTS_MANAGEMENT';
export const CREATE_INCIDENT_REPORTS = 'CREATE_INCIDENT_REPORTS';
export const UPDATE_INCIDENT_REPORTS = 'UPDATE_INCIDENT_REPORTS';

export const ENTITY_SUBTYPE_MANAGEMENT = 'ENTITY_SUBTYPE_MANAGEMENT';
export const CREATE_ENTITY_SUBTYPE = 'CREATE_ENTITY_SUBTYPE';
export const UPDATE_ENTITY_SUBTYPE = 'UPDATE_ENTITY_SUBTYPE';
