import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SiteDashboardControlsTabs from './SiteDashboardControlsTabs';
import { describeSiteThunk } from '../../store/actions/sites';
import { getSelectedSite } from '../../selectors/index';
import s from './SiteDashboardControlsContentTemplate.module.scss';

const SiteDashboardControlsContentTemplate = ({ children, selectedPage, userProfileWidgets, siteId, segmentName }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(describeSiteThunk(siteId, 'WQSP_SITES_UPDATE'));
  }, [siteId]);
  const selectedSite = useSelector(state => getSelectedSite(state));
  const siteName = (selectedSite.Name && ` - ${selectedSite.Name}`) || '';
  return (
    <Fragment>
      {segmentName && (
        <div className={s.fullWidth}>
          <div className={s.fullWidthWrapper}>
            <h1 className={s.tabHeader}>
              {<FormattedMessage id="siteControls.site" defaultMessage="Site" />}
              {siteName}
            </h1>
            <SiteDashboardControlsTabs
              selectedPage={selectedPage}
              userProfileWidgets={userProfileWidgets}
              segmentName={segmentName}
              siteId={siteId}
            />
          </div>
        </div>
      )}
      <div className={s.pageContent}>{children}</div>
    </Fragment>
  );
};

SiteDashboardControlsContentTemplate.defaultProps = {
  backLink: '/',
  selectedPage: ''
};

SiteDashboardControlsContentTemplate.propTypes = {
  backLink: PropTypes.string,
  children: PropTypes.node.isRequired,
  selectedPage: PropTypes.string,
  userProfileWidgets: PropTypes.object
};

export default SiteDashboardControlsContentTemplate;
