import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../utils/index';
import _ from 'lodash';

import {
  TEST_RESULT_ERROR,
  GET_ALL_TEST_RESULT_DETAILS,
  TEST_RESULT_IS_LOADING,
  SET_TEST_RESULT_CURRENT_PAGE,
  SET_TEST_RESULT_PAGE_FILTER,
  SET_TEST_RESULT_CHANGE,
  SET_TEST_RESULT,
  UPDATE_TEST_RESULT_SAVE_STATUS,
  SET_TEST_RESULT_FILTERS,
  SET_TEST_RESULT_RESULTS,
  REMOVE_TEST_RESULT
} from '../../constants/index';

import { TEST_RESULT_MANAGEMENT, CREATE_TEST_RESULT, UPDATE_TEST_RESULT } from '../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setTestResultErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setTestResultError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setTestResultError(messageCode, false));
  }, 2500);
};

export const setTestResultError = (messageCode, status) => {
  return {
    type: TEST_RESULT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: TEST_RESULT_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getTestResultThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getTestResult(widgetCode, orgId, filterObj));
};

export const getTestResult = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/testresult/testresultreport`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      FilterSiteId: filterObj.filterSiteId,
      FilterEntityId: filterObj.filterEntityId,
      FilterEntityTypeId: filterObj.filterEntityTypeId,
      FilterTestPurposeId: filterObj.filterTestPurposeId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllTestResultListThunk(retData),
    onFailure: err => setTestResultErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllTestResultListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let testResults = (retData && retData.retData.data) || [];
  dispatch(setAllTestResultList(messageCode, testResults));
};

export const setAllTestResultList = (messageCode, testResults) => ({
  type: GET_ALL_TEST_RESULT_DETAILS,
  payload: {
    data: {
      testResults: testResults,
      filteredResults: testResults,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false,
      isRedirect: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_TEST_RESULT_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_TEST_RESULT_PAGE_FILTER,
  payload: { filter }
});

export const describeTestResultThunk = (TestResultReportId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeTestResult(TestResultReportId, widgetCode, orgId));
};

export const describeTestResult = (TestResultReportId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/testresult/testresultreport`,
    data: {
      TestResultReportId: TestResultReportId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTestResultThunk(TestResultReportId, retData),
    onFailure: err => setTestResultErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setTestResultThunk = (TestResultReportId, { retData }) => dispatch => {
  let selectedTestResult = retData.data;

  if (
    (!selectedTestResult && TestResultReportId !== null && parseInt(TestResultReportId) !== 0) ||
    (TestResultReportId &&
      selectedTestResult &&
      Object.keys(selectedTestResult).length > 0 &&
      parseInt(TestResultReportId) !== selectedTestResult.TestResultReportId)
  ) {
    redirectToPageNotFound(dispatch);
  } else {
    let details = retData.details || [];
    let testPurposes = retData.testPurposes || [];
    let testCategories = retData.testCategories || [];
    let files = retData.files || [];
    let incidentReports = retData.incidentReports || [];

    //Grouping TestCategories based on TestCategoryId
    let groupData = _.groupBy(testCategories, item => {
      return item.TestCategoryId;
    });
    let groupMapTestCategories = _.map(groupData, function(group) {
      return {
        TestCategoryId: group[0].TestCategoryId,
        Name: group[0].CategoryName,
        Description: group[0].CategoryDescription,
        IsActive: group[0].IsActive,
        testCategoryTypes:
          group && group[0].TestCategoryItemId
            ? group.map(item => {
                return {
                  TestCategoryItemId: item.TestCategoryItemId,
                  Name: item.Name,
                  Description: item.Description,
                  UnitOfMeasure: item.UnitOfMeasure,
                  Guidentifier: item.Guidentifier
                };
              })
            : []
      };
    });

    //Grouping TestResult Details based on TestCategoryId
    let groupTestResultData = _.groupBy(details, item => {
      return item.TestCategoryId;
    });
    let groupMapResultItems = _.map(groupTestResultData, function(group) {
      return {
        TestCategoryId: group[0].TestCategoryId,
        CategoryName: group[0].CategoryName,
        testCategoryTypes:
          group && group[0].TestResultReportItemId
            ? group.map(item => {
                return {
                  TestResultReportItemId: item.TestResultReportItemId,
                  TestCategoryItemId: item.TestCategoryItemId,
                  Name: item.Name,
                  Value: item.Value,
                  Description: item.Description,
                  UnitOfMeasure: item.UnitOfMeasure,
                  GuidIdentifier: item.GuidIdentifier,
                  IsActive: item.IsActive,
                  IsDeleted: item.IsDeleted
                };
              })
            : []
      };
    });

    let mapData = {
      selectedTestResult: {
        ...selectedTestResult,
        resultItems: groupMapResultItems,
        testPurposes: testPurposes,
        testCategories: groupMapTestCategories,
        files: files,
        incidentReports: incidentReports
      }
    };

    dispatch(setTestResult(mapData));
  }
};

export const setTestResult = data => ({
  type: SET_TEST_RESULT,
  payload: data
});

export const saveTestResultThunk = (testResult, widgetCode) => dispatch => {
  let url = '/testresult/testresultreport/save';
  let actionName = testResult.TestResultReportId === 0 ? CREATE_TEST_RESULT : UPDATE_TEST_RESULT;
  let log = logEntry(TEST_RESULT_MANAGEMENT, actionName, testResult);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  testResult.OrganisationId = orgId;
  dispatch(saveTestResult(testResult, url, log, widgetCode));
};

export const saveTestResult = (testResult, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setTestResultStatusThunk(retData, testResult),
    onFailure: err => setTestResultErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: testResult,
      log: log
    },
    widgetcode: widgetCode
  });

export const setTestResultStatusThunk = (message, testResult) => dispatch => {
  dispatch(setTestResultStatus(message, true, false));
  setTimeout(() => {
    //Hide the banner
    let isRedirect = !testResult.PerformDelete;
    dispatch(setTestResultStatus(message, false, isRedirect));
    if (testResult.PerformDelete) {
      dispatch(removeTestResult(testResult.TestResultReportId));
    }
  }, 2500);
};

export const setTestResultStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_TEST_RESULT_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: status,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const removeTestResult = data => ({
  type: REMOVE_TEST_RESULT,
  payload: {
    testResultReportId: data
  }
});

export const setTestResultChangeThunk = retData => dispatch => {
  dispatch(setTestResultChange(retData));
};

export const setTestResultChange = retData => ({
  type: SET_TEST_RESULT_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      fTestPurposes: true,
      SiteId: siteId,
      filterFor: 'testResultReportListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setTestResultErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_TEST_RESULT_FILTERS,
  payload: {
    data: {
      isLoading: false,
      filterData: {
        TestPurposes: filterData.testPurposes
      },
      testResultListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_TEST_RESULT_RESULTS,
  payload: retData
});
