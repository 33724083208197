import React, { useState } from 'react';
import s from './InspectionReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { useDispatch } from 'react-redux';
import { setInspectionTypeQuestionChangeThunk } from '../../store/actions/inspectionReport';
import { VERY_HIGH_RISK, HIGH_RISK, MEDIUM_RISK } from '../../constants/index';
import { getRiskName, riskCalulator } from '../../utils/appUtils';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';
import Cookies from 'js-cookie';

const roundToTwo = num => {
  return +(Math.round(num + 'e+2') + 'e-2');
};
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const InspectionReportQuestions = ({ inspectionReport, isLoading, widgetCode, intl }) => {
  const dispatch = useDispatch();
  const assessmentQuestions = inspectionReport.AssesmentQuestions;
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

  let totalValue = assessmentQuestions.map(item => item.Score).reduce((prev, curr) => prev + curr, 0);

  let currentValue = assessmentQuestions
    .filter(x => x.Result)
    .map(item => item.Score)
    .reduce((prev, curr) => prev + curr, 0);

  totalValue = totalValue === 0 ? (totalValue = 1) : totalValue;

  const saveQuestionHandler = assessmentQuestionResult => {
    const updatedData = assessmentQuestions.map(x =>
      x.InspectionTypeQuestionId === assessmentQuestionResult.InspectionTypeQuestionId ? { ...x, ...assessmentQuestionResult } : x
    );

    if (assessmentQuestionResult.Result) {
      currentValue = currentValue + assessmentQuestionResult.Score;
    } else {
      currentValue = currentValue - assessmentQuestionResult.Score;
    }

    let percentageValue = roundToTwo(currentValue / totalValue) * 100;

    const riskInfo = riskCalulator(percentageValue);

    dispatch(
      setInspectionTypeQuestionChangeThunk({
        assesmentQuestions: updatedData,
        riskScore: percentageValue,
        riskRating: riskInfo.id
      })
    );
  };

  const onChangeCommentHandler = assessmentQuestionResult => {
    const updatedData = assessmentQuestions.map(x =>
      x.InspectionTypeQuestionId === assessmentQuestionResult.InspectionTypeQuestionId ? { ...x, ...assessmentQuestionResult } : x
    );
    dispatch(
      setInspectionTypeQuestionChangeThunk({
        assesmentQuestions: updatedData
      })
    );
  };

  return (
    <div className={s.inspectionReport}>
      <div className={s.contentWrapper}>
        <div className={s.inpsectionReportQuestions}>
          {isLoading && <LoadingSpinner />}
          <Row className={s.standardRow}>
            <Col sm={6} lg={8}>
              <h4 className={s.subheading}>
                <FormattedMessage id="inspectionReports.questions" defaultMessage="Questions" />
              </h4>
            </Col>
            <Col sm={6} lg={4}></Col>
          </Row>
          <Row>
            <Col>
              <Table variant className={s.innerTable}>
                <thead>
                  <tr>
                    <th width={'5%'}>
                      <FormattedMessage id="inspectionReports.questionNo" defaultMessage="#" />
                    </th>
                    <th width={'60%'}>
                      <FormattedMessage id="inspectionReports.question" defaultMessage="Question" />
                    </th>
                    <th width={'22%'}>
                      <FormattedMessage id="inspectionReports.score" defaultMessage="Score" />
                    </th>
                    <th width={'5%'}>
                      <FormattedMessage id="inspectionReports.score" defaultMessage="Score" />
                    </th>
                    <th width={'8%'}>
                      <FormattedMessage id="inspectionReports.answer" defaultMessage="Answer" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assessmentQuestions.map(questionItem => {
                    return (
                      <QuestionRow
                        key={questionItem.InspectionTypeQuestionId}
                        question={questionItem}
                        saveQuestion={saveQuestionHandler}
                        onChangeComment={onChangeCommentHandler}
                        siteId={inspectionReport.SiteId}
                        orgId={orgId}
                        intl={intl}
                        widgetCode={widgetCode}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>

          <RiskInfo inspectionReport={inspectionReport} intl={intl} />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(InspectionReportQuestions);

const QuestionRow = ({ question, saveQuestion, onChangeComment, siteId, orgId, widgetCode, intl }) => {
  const onChange = value => {
    saveQuestion({ ...question, Result: value });
  };

  const [showComment, setShowComment] = useState(question.Comment.length > 0);

  const onChangeCommentLocal = e => {
    onChangeComment({ ...question, Comment: e.target.value });
  };

  const onCommentRemove = () => {
    setShowComment(false);
    onChangeComment({ ...question, Comment: '' });
  };

  //FILE UPLOAD
  const onUploadStart = files => {
    // setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    //  setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          SiteId: 0,
          newFile: true,
          S3URL: item.S3URL,
          ImageId: item.fileGuid,
          Name: item.name
        };
      });
      let allFiles = [...question.Files, ...newFiles];
      onChangeComment({ ...question, Files: allFiles });
    }
  };

  const removeFile = fileGuid => {
    let files = question.Files.filter(file => file.ImageId !== fileGuid);
    let currentDeletedFiles = question.deletedFileIds || [];
    let deletedFileIds = [...currentDeletedFiles];
    deletedFileIds.push(fileGuid);
    onChangeComment({ ...question, Files: files, deletedFileIds: deletedFileIds });
  };
  //END FILE UPLOAD

  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;

  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  return (
    <tr>
      <td>
        <div>{question.QuestionNumber}</div>
      </td>
      <td>
        <QuestionDetails item={question} />
        <div className={s.questionComment}>
          {showComment && (
            <Form.Control
              className={s.commentText}
              as="textarea"
              rows="2"
              name="Comment"
              onChange={onChangeCommentLocal}
              placeholder={intl.formatMessage({
                id: 'inspectionReports.comment',
                defaultMessage: 'Comment'
              })}
              value={question.Comment}
            />
          )}
          {showComment && (
            <span className={s.commentButton} onClick={onCommentRemove}>
              {' '}
              <FormattedMessage id="inspectionReports.removeComment" defaultMessage="- Remove Comments" />{' '}
            </span>
          )}
          {!showComment && (
            <span className={s.commentButton} onClick={() => setShowComment(true)}>
              {' '}
              <FormattedMessage id="inspectionReports.addComment" defaultMessage="+ Add Comments" />{' '}
            </span>
          )}
        </div>
      </td>
      <td>
        <div className={s.fileupload}>
          <FileUploader
            widgetCode={widgetCode}
            allowedFileTypes={[
              ['image/jpeg', 'jpeg'],
              ['application/pdf', 'pdf'],
              ['application/msword', 'doc']
            ]}
            location={`organisation/${orgId}/sites/${siteId}/inspectionReport`}
            enableMultipleUpload={true}
            maxFiles={5}
            onUploadComplete={onUploadComplete}
            onUploadStart={onUploadStart}
            colSize={12}
          />

          <FileList files={question.Files || []} onRemoveFile={removeFile} colSize={12} />
        </div>
      </td>
      <td>
        <div>{question.Score}</div>
      </td>

      <td>
        <ToggleSwitch
          handleClick={() => onChange(!question.Result)}
          classname={s.switch}
          checked={question.Result}
          labelChecked={yesText}
          labelUnchecked={noText}
        />
      </td>
    </tr>
  );
};

const QuestionDetails = ({ item }) => {
  return (
    <div>
      <div className={s.questionText}>{item.QuestionText}</div>
      <div className={s.normalItalicText}>{item.ExplanatoryNote}</div>
    </div>
  );
};
const RiskInfo = ({ inspectionReport, intl }) => {
  return (
    <div className={s.breakrow}>
      <Row className={s.standardRow}>
        <Col sm={6} lg={8}>
          <h4 className={s.subheading}>
            <FormattedMessage id="inspectionReports.totalScore" defaultMessage="Total Score :" />{' '}
            <span>{parseInt(inspectionReport.RiskScore)} %</span>
          </h4>
        </Col>
      </Row>
      <Row className={s.standardRow}>
        <Col sm={6} lg={8}>
          <h4 className={s.subheading}>
            <FormattedMessage id="inspectionReports.riskRating" defaultMessage="Risk Rating :" />{' '}
            <span
              className={`${s.dot} ${
                inspectionReport.RiskRating === VERY_HIGH_RISK
                  ? s.veryHighRisk
                  : inspectionReport.RiskRating === HIGH_RISK
                  ? s.highRisk
                  : inspectionReport.RiskRating === MEDIUM_RISK
                  ? s.mediumRisk
                  : s.lowRisk
              }`}
            ></span>{' '}
            <span>{getRiskName(inspectionReport.RiskRating, intl)}</span>
          </h4>
        </Col>
      </Row>
      <Row className={s.standardRow}>
        <Col sm={12}>
          <div className={s.breakrow}>
            <p>
              {' '}
              <FormattedMessage
                id="inspectionReports.mainInfo"
                defaultMessage='Total Risk Score is the Total score of all the Questions with a "Yes" answer as a percentage of the Total Score of all "Yes" questions.'
              />
            </p>
            <p>
              {' '}
              <FormattedMessage id="inspectionReports.riskInfo" defaultMessage="Risk Rating is calculated from the Risk Score: " />{' '}
            </p>
            <p>
              <span className={`${s.dot} ${s.veryHighRisk}`}></span>{' '}
              <FormattedMessage id="inspectionReports.verHighRiskDetail" defaultMessage="90% <= Score = Very high risk" />{' '}
            </p>
            <p>
              <span className={`${s.dot} ${s.highRisk}`}></span>{' '}
              <FormattedMessage id="inspectionReports.highRiskDetail" defaultMessage="90% > Score >= 60%   = High Risk:" />{' '}
            </p>
            <p>
              <span className={`${s.dot} ${s.mediumRisk}`}></span>{' '}
              <FormattedMessage id="inspectionReports.mediumRiskDetail" defaultMessage="60% > Score >= 30% = Medium Risk" />{' '}
            </p>
            <p>
              <span className={`${s.dot} ${s.lowRisk}`}></span>{' '}
              <FormattedMessage id="inspectionReports.lowRiskDetail" defaultMessage="30% > Score> 0% = Low Risk" />{' '}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
