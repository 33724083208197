import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './TestResultReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import TestResultReportListTable from './TestResultReportListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';
import { getUserProfileWidget, getTestResult, getGeneralData } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import {
  getTestResultThunk,
  getFilterThunk,
  setFilteredDataThunk,
  setCurrentPage,
  setPageFilter,
  saveTestResultThunk
} from '../../store/actions/testResults';

const SITE = 'SITE';
const ENTITY_TYPE = 'ENTITY_TYPE';
const ENTITY = 'ENTITY';
const TEST_PURPOSE = 'TEST_PURPOSE';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const TestResultReportList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const testResult = useSelector(state => getTestResult(state));
  const generalData = useSelector(state => getGeneralData(state));

  const [active, setActive] = useState(false);
  const [testResultId, setTestResultId] = useState(0);
  const [filterSiteId, setFilterSiteId] = useState(0);
  const [filterEntityTypeId, setFilterEntityTypeId] = useState(0);
  const [filterEntityId, setFilterEntityId] = useState(0);
  const [filterTestPurposeId, setFilterTestPurposeId] = useState(0);
  const [entitySubTypes, setEntitySubTypes] = useState([]);
  const [asendingOrder, setAsendingOrder] = useState(true);

  let { siteId, segmentName, selectedTab } = useParams();
  selectedTab = selectedTab || '3';

  let offset = testResult.offset || 0;
  let limit = testResult.limit || 1000;

  let filterLocalSiteId = testResult.filterBy.filterSiteId || null;
  let filterLocalEntityId = testResult.filterBy.filterEntityId || null;
  let filterLocalEntityTypeId = testResult.filterBy.filterEntityTypeId || null;
  let filterLocalTestPurposeId = testResult.filterBy.filterTestPurposeId || null;

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_TESTRESULTSREPORT_LIST', siteId));
  }, [dispatch, getFilterThunk]);

  useEffect(() => {
    let filterObj = {
      filterSiteId: filterLocalSiteId || siteId,
      filterEntityId: filterLocalEntityId,
      filterEntityTypeId: filterLocalEntityTypeId,
      filterTestPurposeId: filterLocalTestPurposeId,
      offset: offset,
      limit: limit
    };
    dispatch(getTestResultThunk('WQSP_TESTRESULTSREPORT_LIST', filterObj));
  }, [getTestResultThunk, filterLocalSiteId, filterLocalEntityId, filterLocalEntityTypeId, filterLocalTestPurposeId]);

  let disableControls = testResult.isLoading || false;
  let messageId = (testResult && testResult.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const siteOptions = () => {
    let siteData = [];

    generalData.sites.forEach(element => {
      siteData.push({
        label: element.Name,
        value: element.SiteId
      });
    });

    let sortedData = siteData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allSites" defaultMessage="All Sites" />,
      value: 0
    });
    return sortedData;
  };

  const entityTypeOptions = () => {
    let entityTypeData = [];

    generalData.entityTypes.forEach(element => {
      entityTypeData.push({
        ...element,
        label: element.Name,
        value: element.EntityTypeId
      });
    });

    let sortedData = entityTypeData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntityTypes" defaultMessage="All Entity Types" />,
      value: 0
    });
    return sortedData;
  };

  const entityOptions = () => {
    let entityData = [];

    entitySubTypes.forEach(element => {
      entityData.push({
        ...element,
        label: element.Name,
        value: element.EntityId
      });
    });

    let sortedData = entityData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allEntities" defaultMessage="All Entities" />,
      value: 0
    });
    return sortedData;
  };

  const testPurposeOptions = () => {
    let purposeData = [];

    let data = testResult.filterData.TestPurposes;
    data.forEach(element => {
      purposeData.push({
        ...element,
        label: element.Name,
        value: element.TestPurposeId
      });
    });

    let sortedData = purposeData.sort(compareValues('label'));
    sortedData.splice(0, 0, {
      label: <FormattedMessage id="wqsp.filters.allTestPurposes" defaultMessage="All Test Purposes" />,
      value: 0
    });
    return sortedData;
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const onSiteChange = e => {
    setFilterSiteId(e.value);
    filteredResults(SITE, e.value);
    setCurrentPage(1);
  };

  const onEntityTypeChange = e => {
    setFilterEntityTypeId(e.value);
    setFilterEntityId(0);
    filteredResults(ENTITY_TYPE, e.value);
    setEntitySubTypes(generalData.entities.filter(item => item.EntityTypeId === e.value));
    setCurrentPage(1);
  };

  const onEntityChange = e => {
    setFilterEntityId(e.value);
    filteredResults(ENTITY, e.value);
    setCurrentPage(1);
  };

  const onTestPurposeChange = e => {
    setFilterTestPurposeId(e.value);
    filteredResults(TEST_PURPOSE, e.value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = testResult.testResults;

    let apifilterObj = {
      filterSiteId: null,
      filterEntityId: null,
      filterEntityTypeId: null,
      filterTestPurposeId: null
    };

    if (filterType === SITE) {
      if (value !== 0) {
        apifilterObj.filterSiteId = value;
        filteredList = filteredList.filter(function(item) {
          return item.SiteId === value;
        });
      }
    } else {
      if (filterSiteId !== 0) {
        apifilterObj.filterSiteId = filterSiteId;
        filteredList = filteredList.filter(function(item) {
          return item.SiteId === filterSiteId;
        });
      }
    }

    if (filterType === ENTITY_TYPE) {
      if (value !== 0) {
        apifilterObj.filterEntityTypeId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === value;
        });
      }
    } else {
      if (filterEntityTypeId !== 0) {
        apifilterObj.filterEntityTypeId = filterEntityTypeId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityTypeId === filterEntityTypeId;
        });
      }
    }

    if (filterType === ENTITY) {
      if (value !== 0) {
        apifilterObj.filterEntityId = value;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === value;
        });
      }
    } else {
      if (filterEntityId !== 0 && filterType !== ENTITY_TYPE) {
        apifilterObj.filterEntityId = filterEntityId;
        filteredList = filteredList.filter(function(item) {
          return item.EntityId === filterEntityId;
        });
      }
    }

    if (filterType === TEST_PURPOSE) {
      if (value !== 0) {
        apifilterObj.filterTestPurposeId = value;
        filteredList = filteredList.filter(function(item) {
          return item.TestPurposeId === value;
        });
      }
    } else {
      if (filterTestPurposeId !== 0) {
        apifilterObj.filterTestPurposeId = filterTestPurposeId;
        filteredList = filteredList.filter(function(item) {
          return item.TestPurposeId === filterTestPurposeId;
        });
      }
    }

    if (testResult.testResultListCount > testResult.testResults.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  let testResultObj = Object.values(testResult.filteredResults);

  if (asendingOrder) {
    testResultObj = testResultObj.sort(compareValues('SampleDate'));
  } else {
    testResultObj = testResultObj.sort(compareValues('SampleDate', 'desc'));
  }

  const onAddNewClick = () => {
    if (segmentName === 'measure') {
      history.push(`/site/${siteId}/${segmentName}/${selectedTab}/test-results/test-result-report`);
    } else {
      history.push('/test-results/test-result-report');
    }
  };

  const onTestResultRemoveClick = testResultId => {
    setActive(true);
    setTestResultId(testResultId);
  };

  const handleClose = () => {
    setActive(false);
    setTestResultId(0);
  };

  const handleConfirm = () => {
    deleteTestResult();
    handleClose();
  };

  const deleteTestResult = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.TestResultReportId = testResultId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveTestResultThunk(saveData, 'WQSP_TESTRESULTSREPORT_DELETE'));
  };

  const dialogTitle = intl.formatMessage({
    id: 'testResult.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this test result?'
  });

  const onChangeTestResultClick = (siteId, testResultReportId) => {
    history.push(`/site/${siteId}/measure/3/test-results/test-result-report/${testResultReportId}`);
  };

  const onCreateIncidentReportClick = n => {
    history.push(`/site/${n.SiteId}/respond/2/incident-reports/incident-report`, {
      docType: 'TEST_RESULT',
      referedId: n.TestResultReportId,
      obj: n
    });
  };

  const allowIncidentReport = IsWidgetAccessible(userProfileWidgets, 'WQSP_INCIDENT_REPORT_ADDNEW');
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TESTRESULTSREPORT_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TESTRESULTSREPORT_DELETE');

  return (
    <SiteDashboardControlsContentTemplate
      selectedPage={selectedTab}
      siteId={siteId}
      segmentName={segmentName}
      userProfileWidgets={userProfileWidgets}
    >
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <div className={s.testResultList}>
        {!segmentName && (
          <div>
            <h1 className={s.tabHeader}>{<FormattedMessage id="testResult.testResults" defaultMessage="Test Results" />}</h1>
          </div>
        )}

        <div className={s.contentWrapper}>
          {testResult.isLoading && <LoadingSpinner />}
          {testResult.showBanner && (
            <Banner
              failureText={messageText}
              showBanner={testResult.showBanner}
              status={testResult.isOpSuccessful}
              successText={messageText}
            />
          )}
          <Row>
            <Col sm={12} lg={12}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_TESTRESULTSREPORT_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addInspectionPurposeButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                {' '}
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row className="pb-2">
            {!siteId && (
              <Col sm={6} lg={2}>
                <Dropdown
                  id="drp"
                  dataArray={siteOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onSiteChange(e)}
                  selectedOption={siteOptions().filter(option => option.value === filterSiteId)}
                  disabled={disableControls}
                />
              </Col>
            )}
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpModelId"
                dataArray={entityTypeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.common.entityType" defaultMessage="Entity Type" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onEntityTypeChange(e)}
                selectedOption={entityTypeOptions().filter(option => option.value === filterEntityTypeId)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={2}>
              <Dropdown
                id="drpUnitId"
                dataArray={entityOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="wqsp.common.entity" defaultMessage="Entity" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onEntityChange(e)}
                selectedOption={entityOptions().filter(option => option.value === filterEntityId)}
                disabled={disableControls}
              />
            </Col>

            <Col sm={6} lg={2}>
              <Dropdown
                id="drpComponentTypeId"
                dataArray={testPurposeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="qualityGoals.purpose" defaultMessage="Test Purpose" />,
                  customClassName: ''
                }}
                onDropdownChange={e => onTestPurposeChange(e)}
                selectedOption={testPurposeOptions().filter(option => option.value === filterTestPurposeId)}
                disabled={disableControls}
              />
            </Col>
          </Row>
          <div className="">
            <Pagination
              data={testResultObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={testResult.currentPage}
            >
              <TestResultReportListTable
                onSortOrder={onSortOrder}
                asendingOrder={asendingOrder}
                ChangeTestResultReport={onChangeTestResultClick}
                UpdateAllowed={isUpdateAllowed}
                AllowCreateIncidentReport={allowIncidentReport}
                CreateIncidentReport={onCreateIncidentReportClick}
                deleteAllowed={isDeleteAllowed}
                removeTestResult={onTestResultRemoveClick}
              />
            </Pagination>
          </div>
        </div>
      </div>
    </SiteDashboardControlsContentTemplate>
  );
};

export default injectIntl(TestResultReportList);
