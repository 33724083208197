import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './GeneralFieldType.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import GeneralFieldTypeListTable from './GeneralFieldTypeListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE, FIELD_TYPE_DATA } from '../../constants';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import { getUserProfileWidget, getGeneralFieldType, getGeneralData } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import SearchBar from '../SearchBar/SearchBar';
import Cookies from 'js-cookie';

import {
  getGeneralFieldTypeThunk,
  setFilteredDataThunk,
  setCurrentPage,
  saveGeneralFieldTypeThunk
} from '../../store/actions/generalFieldType';

const FIELD_TYPE = 'FIELD_TYPE';
const SEARCH_FILTER = 'SEARCH_FILTER';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const GeneralFieldTypeList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const generalFieldType = useSelector(state => getGeneralFieldType(state));

  let disableControls = generalFieldType.isLoading || false;

  const [searchFilter, setSearchFilter] = useState('');
  const [filterFieldType, setFilterFieldType] = useState(0);
  const [generalFieldTypeId, setGeneralFieldTypeId] = useState(0);
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(getGeneralFieldTypeThunk('WQSP_GENERAL_FIELDTYPES_LIST'));
  }, [getGeneralFieldTypeThunk]);

  let messageId = (generalFieldType && generalFieldType.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const fieldTypeOptions = () => {
    let opData = [];

    opData.push({
      label: <FormattedMessage id="common.allFieldType" defaultMessage="All Field Types" />,
      value: 0
    });
    const data = FIELD_TYPE_DATA;

    data.forEach(element => {
      opData.push({
        label: intl.formatMessage({ id: element.text, defaultMessage: element.text }),
        value: element.value
      });
    });
    return opData.sort(compareValues('label'));
  };

  const onDropdownChange = (e, fieldName) => {
    if (fieldName === FIELD_TYPE) {
      setFilterFieldType(e.value);
    }

    filteredResults(fieldName, e.value);
    setCurrentPage(1);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onRemoveGeneralFieldTypeClick = generalFieldTypeId => {
    setActive(true);
    setGeneralFieldTypeId(generalFieldTypeId);
  };

  const DeleteGeneralFieldType = () => {
    let saveData = generalFieldType.SelectedGeneralFieldType;
    saveData.PerformDelete = true;
    saveData.GeneralFieldTypeId = generalFieldTypeId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveGeneralFieldTypeThunk(saveData, 'WQSP_GENERAL_FIELDTYPES_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setGeneralFieldTypeId(0);
  };

  const handleConfirm = () => {
    DeleteGeneralFieldType();
    handleClose();
  };

  const filteredResults = (filterType, value) => {
    let filteredList = generalFieldType.GeneralFieldTypes;

    if (filterType === FIELD_TYPE) {
      if (value !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.FieldType === value;
        });
      }
    } else {
      if (filterFieldType !== 0) {
        filteredList = filteredList.filter(function(item) {
          return item.FieldType === filterFieldType;
        });
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
  };

  let generalFieldTypeObj = Object.values(generalFieldType.filteredResults);
  generalFieldTypeObj = generalFieldTypeObj.sort(compareValues('InspectionDate'));

  const onAddNewClick = () => {
    history.push('/operational-setup/general-field-types/fieldtype');
  };

  const onChangeGeneralFieldTypeClick = generalFieldTypeId => {
    history.push(`/operational-setup/general-field-types/fieldtype/${generalFieldTypeId}`);
  };

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_GENERAL_FIELDTYPES_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_GENERAL_FIELDTYPES_DELETE');
  //const isUpdateAllowed = true;

  const dialogTitle = intl.formatMessage({
    id: 'generalFieldTypes.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this field type item?'
  });

  return (
    <div className={s.generalFieldTypeList}>
      <OperationalSetupContentTemplate selectedPage="generalFieldTypes" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
          {generalFieldType.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={generalFieldType.showBanner}
            status={generalFieldType.isOpSuccessful}
            successText={messageText}
          />
          <Row>
            <Col sm={6}>
              <span className={s.textHeading}>
                <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={2}>
              <Dropdown
                id="formFieldType"
                dataArray={fieldTypeOptions()}
                controlData={{
                  placeholderText: <FormattedMessage id="common.allFieldTypes" defaultMessage="All Field Types" />,
                  customClassName: ''
                }}
                name="FieldType"
                onDropdownChange={e => onDropdownChange(e, FIELD_TYPE)}
                selectedOption={fieldTypeOptions().filter(option => option.value === filterFieldType)}
                disabled={disableControls}
              />
            </Col>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="generalFieldType.searchInspectionName"
              />
            </Col>
            <Col sm={12} lg={6}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_GENERAL_FIELDTYPES_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addGeneralFieldTypeButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={generalFieldTypeObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={generalFieldType.currentPage}
            >
              <GeneralFieldTypeListTable
                ChangeGeneralFieldType={onChangeGeneralFieldTypeClick}
                RemoveGeneralFieldType={onRemoveGeneralFieldTypeClick}
                UpdateAllowed={isUpdateAllowed}
                DeleteAllowed={isDeleteAllowed}
                fieldTypeData={FIELD_TYPE_DATA}
              />
            </Pagination>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

export default injectIntl(GeneralFieldTypeList);
