import Cookies from 'js-cookie';
import { apiAction, logEntry, redirectToPageNotFound } from '../../../utils/index';
import _ from 'lodash';

import {
  HAZARD_EVENT_ERROR,
  GET_ALL_HAZARD_EVENT_DETAILS,
  HAZARD_EVENT_IS_LOADING,
  SET_HAZARD_EVENT_CURRENT_PAGE,
  SET_HAZARD_EVENT_PAGE_FILTER,
  SET_HAZARD_EVENT_CHANGE,
  SET_HAZARD_EVENT,
  UPDATE_HAZARD_EVENT_SAVE_STATUS,
  SET_HAZARD_EVENT_FILTERS,
  SET_HAZARD_EVENT_RESULTS,
  SET_HAZARD_EVENT_COMMENTS,
  REMOVE_HAZARD_EVENT
} from '../../../constants/index';

import { HAZARD_EVENT_MANAGEMENT, CREATE_HAZARD_EVENT, UPDATE_HAZARD_EVENT } from '../../../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setHazardEventErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setHazardEventError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setHazardEventError(messageCode, false));
  }, 2500);
};

export const setHazardEventError = (messageCode, status) => {
  return {
    type: HAZARD_EVENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: HAZARD_EVENT_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getHazardEventThunk = (widgetCode, filterObj) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(getHazardEvent(widgetCode, orgId, filterObj));
};

export const getHazardEvent = (widgetCode, orgId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/hazardevents/hazardevent`,
    method: 'GET',
    data: {
      OrganisationId: orgId,
      Offset: filterObj.offset,
      Limit: filterObj.limit,
      filter: filterObj.filter,
      filterSiteId: filterObj.filterSiteId,
      filterWaterSupplyDescriptionId: filterObj.filterWaterSupplyDescriptionId,
      filterEntityTypeId: filterObj.filterEntityTypeId,
      filterEntityId: filterObj.filterEntityId,
      filterHazardCertaintyId: filterObj.filterHazardCertaintyId,
      filterHazardClassId: filterObj.filterHazardClassId
    },
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllHazardEventListThunk(retData),
    onFailure: err => setHazardEventErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllHazardEventListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let hazardEvents = (retData && retData.retData.data) || [];
  dispatch(setAllHazardEventList(messageCode, hazardEvents));
};

export const setAllHazardEventList = (messageCode, hazardEvents) => ({
  type: GET_ALL_HAZARD_EVENT_DETAILS,
  payload: {
    data: {
      hazardEvents: hazardEvents,
      filteredResults: hazardEvents,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_HAZARD_EVENT_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_HAZARD_EVENT_PAGE_FILTER,
  payload: { filter }
});

export const describeHazardEventThunk = (HazardEventId, widgetCode) => dispatch => {
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  dispatch(describeHazardEvent(HazardEventId, widgetCode, orgId));
};

export const describeHazardEvent = (HazardEventId, widgetCode, orgId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}/hazardevents/hazardevent`,
    data: {
      HazardEventId: HazardEventId,
      OrganisationId: orgId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setHazardEventThunk(HazardEventId, retData),
    onFailure: err => setHazardEventErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setHazardEventThunk = (HazardEventId, { retData }) => dispatch => {
  let selectedHazardEvent = retData.data;

  if (
    (!selectedHazardEvent && HazardEventId !== null && parseInt(HazardEventId) !== 0) ||
    (HazardEventId &&
      selectedHazardEvent &&
      Object.keys(selectedHazardEvent).length > 0 &&
      parseInt(HazardEventId) !== selectedHazardEvent.HazardEventId)
  ) {
    redirectToPageNotFound(dispatch);
  } else {
    let details = retData.details || [];
    let comments = retData.comments || [];
    let hazardEventLibraries = retData.hazardEventLibraries || [];
    let riskAssessments = retData.riskAssessments || [];
    let mapData = {
      selectedHazardEvent: {
        ...selectedHazardEvent,
        hazardEventItems: details,
        details: details,
        riskAssessments: riskAssessments,
        comments: comments,
        hazardEventLibraries: hazardEventLibraries
      }
    };

    dispatch(setHazardEvent(mapData));
  }
};

export const setHazardEvent = data => ({
  type: SET_HAZARD_EVENT,
  payload: data
});

export const saveHazardEventThunk = (hazardEvent, widgetCode) => dispatch => {
  let url = '/hazardevents/hazardevent/save';
  let actionName = hazardEvent.HazardEventId === 0 ? CREATE_HAZARD_EVENT : UPDATE_HAZARD_EVENT;
  let log = logEntry(HAZARD_EVENT_MANAGEMENT, actionName, hazardEvent);
  let orgId = Cookies.get(`selectedorganisationid-${envName}`) || 1;
  hazardEvent.OrganisationId = orgId;
  dispatch(saveHazardEvent(hazardEvent, url, log, widgetCode));
};

export const saveHazardEvent = (hazardEvent, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_WQSP}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setHazardEventStatusThunk(retData, hazardEvent),
    onFailure: err => setHazardEventErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      data: hazardEvent,
      log: log
    },
    widgetcode: widgetCode
  });

export const setHazardEventStatusThunk = (data, hazardEvent) => dispatch => {
  let message = data;
  if (hazardEvent.performCommentInsert) {
    dispatch(setHazardEventComments(data.retData));
  } else {
    dispatch(setHazardEventStatus(message, true));
    setTimeout(() => {
      //Hide the banner
      let isRedirect = !hazardEvent.PerformDelete;
      dispatch(setHazardEventStatus(message, false, isRedirect));
      if (hazardEvent.PerformDelete) {
        dispatch(removeHazardEvent(hazardEvent.HazardEventId));
      }
    }, 2500);
  }
};

export const setHazardEventStatus = (messageCode, status, isRedirect) => ({
  type: UPDATE_HAZARD_EVENT_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      isRedirect: isRedirect,
      displayMessageCode: messageCode || ''
    }
  }
});

export const setHazardEventComments = data => ({
  type: SET_HAZARD_EVENT_COMMENTS,
  payload: {
    selectedHazardEvent: {
      comments: data
    }
  }
});

export const removeHazardEvent = data => ({
  type: REMOVE_HAZARD_EVENT,
  payload: {
    hazardEventId: data
  }
});

export const setHazardEventChangeThunk = retData => dispatch => {
  dispatch(setHazardEventChange(retData));
};

export const setHazardEventChange = retData => ({
  type: SET_HAZARD_EVENT_CHANGE,
  payload: retData
});

export const getFilterThunk = (widgetCode, siteId) => dispatch => {
  dispatch(getFilter(widgetCode, siteId));
};

export const getFilter = (widgetCode, siteId) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN_WQSP}/management/general/filters`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      SiteId: siteId || null,
      filterFor: 'hazardEventListing'
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllFilterThunk(retData),
    onFailure: err => setHazardEventErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllFilterThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let filterData = (retData && retData.filterData) || [];
  let itemCount = (retData && retData.itemCount) || -1;
  dispatch(setAllFilter(messageCode, filterData, itemCount));
};

export const setAllFilter = (messageCode, filterData, itemCount) => ({
  type: SET_HAZARD_EVENT_FILTERS,
  payload: {
    data: {
      isLoading: false,
      filterData: {
        TestPurposes: filterData.testPurposes
      },
      hazardEventListCount: itemCount,
      displayMessageCode: messageCode,
      showBanner: false
    }
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_HAZARD_EVENT_RESULTS,
  payload: retData
});
