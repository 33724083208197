import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import s from './style.module.scss';

const Breadcrumb = props => {
  const data = props.data || [];

  const items = data.filter(x => x.showCrumb);

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        {items.map(({ orderNo, url, name, showlink }) => (
          <CrumbItem key={orderNo} url={url} name={name} showlink={showlink} />
        ))}
      </div>

      {props.segmentName && (
        <div className={s.segmentTitle}>
          <FormattedMessage id={`sitePathway.${props.segmentName}Segment`} defaultMessage={`${props.segmentName} Segment`} />
        </div>
      )}
    </div>
  );
};

const CrumbItem = ({ url, name, showlink }) => {
  return showlink ? (
    <div className={s.crumbItem}>
      <Link key={url} to={url}>
        {name}
      </Link>{' '}
      <span className={s.pad5}> / </span>{' '}
    </div>
  ) : (
    <div className={s.crumbItem}>{name}</div>
  );
};

export default Breadcrumb;
