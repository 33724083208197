import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './InspectionPurpose.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getInspectionPurpose } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialInspectionPurposeState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import OperationalSetupContentTemplate from '../OperationalSetup/OperationalSetupContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import {
  describeInspectionPurposeThunk,
  saveInspectionPurposeThunk,
  setInspectionPurposeChangeThunk,
  setInspectionPurposeCleanUpThunk
} from '../../store/actions/inspectionPurpose';
import uuid from 'uuid';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const InspectionPurpose = ({ intl }) => {
  let history = useHistory();
  let { inspectionPurposeId } = useParams();

  const dispatch = useDispatch();
  const inspectionPurpose = useSelector(state => getInspectionPurpose(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});

  const backUrl = '/operational-setup/inspection-report';
  let widgetCode = '';
  if (inspectionPurposeId) {
    IsEdit = true;
    widgetCode = 'WQSP_INSPECTIONPURPOSE_EDIT';
  }

  useEffect(() => {
    if (inspectionPurposeId) {
      dispatch(describeInspectionPurposeThunk(inspectionPurposeId, widgetCode));
    }
  }, [describeInspectionPurposeThunk, dispatch, inspectionPurposeId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setInspectionPurposeCleanUpThunk(initialInspectionPurposeState.inspectionPurpose.SelectedInspectionPurpose));
    };
  }, []);

  // Update redux store
  const setLocalInspectionPurpose = currentState => {
    dispatch(setInspectionPurposeChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = inspectionPurpose.SelectedInspectionPurpose;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.InspectionPurposeId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_INSPECTIONPURPOSE_ADDNEW';
    }

    dispatch(saveInspectionPurposeThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !inspectionPurpose ||
      !inspectionPurpose.SelectedInspectionPurpose ||
      !inspectionPurpose.SelectedInspectionPurpose.Name ||
      isRequired(inspectionPurpose.SelectedInspectionPurpose.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'common.titleRequired',
        defaultMessage: 'Title is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalInspectionPurpose({
      ...inspectionPurpose,
      SelectedInspectionPurpose: { ...inspectionPurpose.SelectedInspectionPurpose, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (inspectionPurpose && inspectionPurpose.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelCompTypeName = (inspectionPurpose.SelectedInspectionPurpose ? inspectionPurpose.SelectedInspectionPurpose.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + modelCompTypeName;
  } else {
    heading = intl.formatMessage({ id: 'inspectionPurposes.addInspectionPurpose', defaultMessage: 'Add new inspection purpose' });
  }

  return (
    <div className={s.inspectionPurpose}>
      {inspectionPurpose.isLoading && <LoadingSpinner />}
      {inspectionPurpose.isRedirect ? <Redirect to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={inspectionPurpose.showBanner}
        status={inspectionPurpose.isOpSuccessful}
        successText={messageText}
      />
      <OperationalSetupContentTemplate selectedPage="inspectionReport" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.inspectionPurposeHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.inspectionPurposeContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formInspectionPurposeName">
                      <Form.Label>
                        <FormattedMessage id="common.title" defaultMessage="Title" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={inspectionPurpose.SelectedInspectionPurpose.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.title',
                          defaultMessage: 'Title'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formInspectionPurposeDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={inspectionPurpose.SelectedInspectionPurpose.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </OperationalSetupContentTemplate>
    </div>
  );
};

InspectionPurpose.defaultProps = {
  inspectionPurpose: {
    ...initialInspectionPurposeState
  }
};

export default injectIntl(InspectionPurpose);
