import React from 'react';
import s from './SafetyPlan.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import ascendingIcon from '../../../assets/arrow-up.png';
import descendingIcon from '../../../assets/arrow-down.png';
import { getDateInDDMMYYYFormat } from '../../../utils/index';
import { useSelector } from 'react-redux';
import { getCurrentTimezone } from '../../../selectors/index';
import MoreOption from '../../WSAControls/MoreOption/MoreOption';
import { useParams } from 'react-router-dom';

const SafetyPlanListTable = ({
  paginatedData,
  onSortOrder,
  asendingOrder,
  UpdateAllowed,
  StakeholderRegisterAllowed,
  TeamAllowed,
  RegulatoryRegisterAllowed,
  ReportViewingAllowed
}) => {
  let sortIcon = asendingOrder ? ascendingIcon : descendingIcon;
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let { segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'build';
  selectedTab = selectedTab || '3';

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="wqsp.common.siteName" defaultMessage="Site Name" />
            </th>
            <th>
              <FormattedMessage id="safetyPlan.safetyPlanName" defaultMessage="Plan Name" />
            </th>
            <th>
              <FormattedMessage id="safetyPlan.planNumber" defaultMessage="Plan Number" />
            </th>
            <th className={s.sortHeading}>
              <span onClick={onSortOrder}>
                <FormattedMessage id="safetyPlan.lastReviewDate" defaultMessage="Last Review Date" />
                <img className={s.sortIcon} src={sortIcon} alt="icon" />{' '}
              </span>
            </th>
            <th>
              <FormattedMessage id="safetyPlan.NextReviewDate" defaultMessage="Next Review Date" />
            </th>

            <th>
              <FormattedMessage id="safetyPlan.reviewFrequency" defaultMessage="Review Frequency" />
            </th>
            {UpdateAllowed && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.WaterQualitySafetyPlanId}>
                <td>{n.SiteName || ''}</td>
                <td>{n.Name || ''}</td>
                <td>{n.PlanNumber || ''}</td>
                <td>{getDateInDDMMYYYFormat(n.LastReviewDate, currentTimezone) || ''}</td>
                <td>{getDateInDDMMYYYFormat(n.NextReviewDate, currentTimezone) || ''}</td>
                <td>{n.ReviewFrequencyName || ''}</td>

                {(UpdateAllowed || StakeholderRegisterAllowed) && (
                  <td>
                    <MoreOption
                      dataArray={[
                        StakeholderRegisterAllowed
                          ? {
                              Url: `/site/${n.SiteId}/${segmentName}/${selectedTab}/safety-plans/${n.WaterQualitySafetyPlanId}/stakeholders`,
                              Name: <FormattedMessage id="common.stakeholders" defaultMessage="STAKEHOLDERS" />
                            }
                          : {},
                        RegulatoryRegisterAllowed
                          ? {
                              Url: `/site/${n.SiteId}/${segmentName}/${selectedTab}/safety-plans/${n.WaterQualitySafetyPlanId}/regulatory-registers`,
                              Name: <FormattedMessage id="common.regulatoryRegister" defaultMessage="REGULATORY REGISTER" />
                            }
                          : {},
                        TeamAllowed
                          ? {
                              Url: `/site/${n.SiteId}/${segmentName}/${selectedTab}/safety-plans/${n.WaterQualitySafetyPlanId}/teams/`,
                              Name: <FormattedMessage id="common.teams" defaultMessage="TEAMS" />
                            }
                          : {},
                        ReportViewingAllowed
                          ? {
                              Url: `/site/${n.SiteId}/${segmentName}/${selectedTab}/safety-plans/${n.WaterQualitySafetyPlanId}/safety-plan-report`,
                              Name: <FormattedMessage id="common.safetyPlanReport" defaultMessage="VIEW REPORTS" />
                            }
                          : {},
                        UpdateAllowed
                          ? {
                              Url: `/site/${n.SiteId}/${segmentName}/${selectedTab}/safety-plans/safety-plan/${n.WaterQualitySafetyPlanId}`,
                              Name: <FormattedMessage id="common.edit" defaultMessage="EDIT" />
                            }
                          : {}
                      ]}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

SafetyPlanListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(SafetyPlanListTable);
