import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './TaskList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { initialTasksState } from '../../store/reducers//initialState';
import TaskListTable from './TaskListTable';
import { useHistory } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE, TASK_STATUS_ACTIVE, TASK_STATUS_COMPLETE, TASK_ASSIGNED_TO_ME, TASK_CREATED_BY_ME } from '../../constants';
import { IsWidgetAccessible, WidgetVisibility } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { compareValues } from '../../utils';
import Cookies from 'js-cookie';
import TasksAndChecklistsContentTemplate from '../TasksAndChecklists/TasksAndChecklistsContentTemplate';

const STATUS = 'STATUS';
const USER_FILTER = 'USER_FILTER';
const SEARCH_FILTER = 'SEARCH_FILTER';
const ERP = 'ERP';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const TaskList = ({
  getTasks,
  setFilterData,
  setCurrentPage,
  setPageFilter,
  getFilter,
  tasksManager,
  intl,
  userProfileWidgets,
  currentTimezone
}) => {
  let userId = Cookies.get(`userid-${envName}`) || 0;
  let filter = tasksManager.filterBy.filter || '';
  let offset = tasksManager.offset || 0;
  let limit = tasksManager.limit || 1000;
  const history = useHistory();

  let filterByStatus = tasksManager.filterBy.filterStatus || null;
  let filterByERP = tasksManager.filterBy.filterERP === null ? null : tasksManager.filterBy.filterERP;
  let filterAssignedByUser = tasksManager.filterBy.filterAssignedByUser || null;
  let filterCreatedByUser = tasksManager.filterBy.filterCreatedByUser || null;

  const [searchFilter, setSearchFilter] = useState(filter === null ? '' : filter);
  const [filterStatus, setFilterStatus] = useState(filterByStatus === null ? '0' : filterByStatus);
  const [filterERP, setFilterERP] = useState(filterByERP === null ? '0' : filterByERP);
  const [filterUser, setFilterUser] = useState('0');
  const [asendingOrder, setAsendingOrder] = useState(false);

  useEffect(() => {
    getFilter();
  }, [getFilter]);

  useEffect(() => {
    let filterObj = {
      filter: filter,
      filterStatus: filterByStatus,
      filterERP: filterByERP,
      filterAssignedUser: filterAssignedByUser,
      filterCreatedUser: filterCreatedByUser,
      offset: offset,
      limit: limit,
      dashboardTasks: false
    };
    getTasks(filterObj);
  }, [getTasks, offset, limit, filter, filterByStatus, filterAssignedByUser, filterCreatedByUser, filterByERP]);

  const statusOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="filters.allstatus" defaultMessage="All Status" />,
      value: '0'
    });
    statusData.push({
      label: TASK_STATUS_ACTIVE,
      value: TASK_STATUS_ACTIVE
    });
    statusData.push({
      label: TASK_STATUS_COMPLETE,
      value: TASK_STATUS_COMPLETE
    });

    return statusData;
  };

  const eRPOptions = () => {
    let eRPData = [];

    eRPData.push({
      label: <FormattedMessage id="checklist.allEmergencyResponsePlan" defaultMessage="All Emergency Response Plan" />,
      value: '0'
    });
    eRPData.push({
      label: <FormattedMessage id="common.no" defaultMessage="No" />,
      value: false
    });
    eRPData.push({
      label: <FormattedMessage id="common.yes" defaultMessage="Yes" />,
      value: true
    });

    return eRPData;
  };

  const userOptions = () => {
    let userData = [];

    userData.push({
      label: <FormattedMessage id="task.all" defaultMessage="All" />,
      value: '0'
    });
    userData.push({
      label: TASK_ASSIGNED_TO_ME,
      value: '1'
    });
    userData.push({
      label: TASK_CREATED_BY_ME,
      value: '2'
    });

    return userData;
  };

  const onStatusChange = e => {
    setFilterStatus(e.value);
    filteredResults(STATUS, e.value);
    setCurrentPage(1);
  };

  const onERPChange = e => {
    setFilterERP(e.value);
    filteredResults(ERP, e.value);
    setCurrentPage(1);
  };

  const onUserFilterChange = e => {
    setFilterUser(e.value);
    filteredResults(USER_FILTER, e.value);
    setCurrentPage(1);
  };

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const viewBtnClick = url => {
    history.push(`${url}`);
  };

  const addBtnClick = () => {
    history.push(`/tasks-and-checklists/task?prevPath=${history.location.pathname}`);
  };

  let tasksObj = Object.values(tasksManager.filteredResults);

  if (asendingOrder) {
    tasksObj = tasksObj.sort(compareValues('DueDateTime'));
  } else {
    tasksObj = tasksObj.sort(compareValues('DueDateTime', 'desc'));
  }

  const filteredResults = (filterType, value) => {
    let filteredList = tasksManager.taskList;

    let apifilterObj = {
      filter: null,
      filterStatus: null,
      filterERP: null,
      filterAssignedByUser: null,
      filterCreatedByUser: null
    };

    if (filterType === STATUS) {
      if (value !== '0') {
        apifilterObj.filterStatus = value;
        filteredList = filteredList.filter(function(item) {
          return item.Status === value;
        });
      }
    } else {
      if (filterStatus !== '0') {
        apifilterObj.filterStatus = filterStatus;
        filteredList = filteredList.filter(function(item) {
          return item.Status === filterStatus;
        });
      }
    }

    if (filterType === ERP) {
      if (value !== '0') {
        apifilterObj.filterERP = value;
        filteredList = filteredList.filter(function(item) {
          return item.IsERP === value;
        });
      }
    } else {
      if (filterERP !== '0') {
        apifilterObj.filterERP = filterERP;
        filteredList = filteredList.filter(function(item) {
          return item.IsERP === filterERP;
        });
      }
    }

    let myUserId = parseInt(userId);
    if (filterType === USER_FILTER) {
      if (value !== '0') {
        if (value === '1') {
          apifilterObj.filterAssignedByUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.AssignedToUserId === myUserId;
          });
        } else if (value === '2') {
          apifilterObj.filterCreatedUByser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.CreatedByUserId === myUserId;
          });
        }
      }
    } else {
      if (filterUser !== '0') {
        if (filterUser === '1') {
          apifilterObj.filterAssignedByUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.AssignedToUserId === myUserId;
          });
        } else if (filterUser === '2') {
          apifilterObj.filterCreatedByUser = myUserId;
          filteredList = filteredList.filter(function(item) {
            return item.CreatedByUserId === myUserId;
          });
        }
      }
    }

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (tasksManager.taskListCount > tasksManager.taskList.length) {
      setPageFilter(apifilterObj);
    } else {
      setFilterData({ filteredResults: { filteredResults: filteredList } });
    }
  };

  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_TASKVIEW');

  return (
    <TasksAndChecklistsContentTemplate selectedPage="myTasks" userProfileWidgets={userProfileWidgets}>
      <div style={WidgetVisibility(userProfileWidgets, `WQSP_TASKLIST`)}>
        <div className={s.pageContent}>
          <div className={s.tasks}>
            {tasksManager.isLoading && <LoadingSpinner />}
            <div className={s.contentWrapper}>
              <Row>
                <Col sm={6} lg={4}>
                  <SearchBar
                    searchHandler={searchHandler}
                    clearSearchInVisible={true}
                    initialText={searchFilter}
                    placeHolderTextId="task.searchTask"
                  />
                </Col>
                <Col sm={6} lg={8} style={WidgetVisibility(userProfileWidgets, `WQSP_TASKADDNEW`)}>
                  <Button className={s.addbutton} onClick={addBtnClick}>
                    + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <span className={s.textHeading}>
                    {' '}
                    <FormattedMessage id="common.filterBy" defaultMessage="Filter By" />
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={4} lg={2}>
                  <Dropdown
                    id="drpStatusId"
                    dataArray={statusOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="common.showAll" defaultMessage="Show: All" />,
                      customClassName: ''
                    }}
                    onDropdownChange={onStatusChange}
                    selectedOption={statusOptions().filter(option => option.value === filterStatus)}
                    disabled={false}
                  />
                </Col>
                <Col sm={4} lg={2}>
                  <Dropdown
                    id="drpUserId"
                    dataArray={userOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="common.showAll" defaultMessage="Show: All" />,
                      customClassName: ''
                    }}
                    onDropdownChange={onUserFilterChange}
                    selectedOption={userOptions().filter(option => option.value === filterUser)}
                    disabled={false}
                  />
                </Col>
                <Col sm={4} lg={2}>
                  <Dropdown
                    id="drpERPId"
                    dataArray={eRPOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="common.showAll" defaultMessage="Show: All" />,
                      customClassName: ''
                    }}
                    onDropdownChange={onERPChange}
                    selectedOption={eRPOptions().filter(option => option.value === filterERP)}
                    disabled={false}
                  />
                </Col>
              </Row>
              <div className="">
                <Pagination
                  data={tasksObj}
                  pageSize={DEFAULT_PAGE_SIZE}
                  onPageChange={onPageChangeHandler}
                  startingPage={tasksManager.currentPage}
                >
                  <TaskListTable
                    ViewAllowed={isViewAllowed}
                    currentPage={tasksManager.currentPage}
                    currentTimezone={currentTimezone}
                    onSortOrder={onSortOrder}
                    asendingOrder={asendingOrder}
                    onViewBtnClick={viewBtnClick}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TasksAndChecklistsContentTemplate>
  );
};

TaskList.defaultProps = {
  tasksManager: initialTasksState.tasks
};

TaskList.propTypes = {
  setFilterData: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  tasksManager: PropTypes.object.isRequired
};

export default injectIntl(TaskList);
