import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import configureStore, { history, loadState } from './store/configureStore';

import './styles/reset.css';
import './styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const persistedState = loadState();

const store = configureStore(persistedState);

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root')
);
