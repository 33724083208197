import React, { useState, useEffect } from 'react';
import s from './InspectionReport.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getInspectionReport, getGeneralData, getCurrentTimezone, getUserProfileWidget } from '../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialInspectionReportState } from '../../store/reducers/initialState';
import uuidv4 from 'uuid/v4';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired, goBack } from '../../utils';
import Cookies from 'js-cookie';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import { compareValues } from '../../utils/';
import Banner from '../Banner/Banner';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import { FREQUENCY, GENERAL_STATUS, DOCUMENT_TYPE_INSPECTION_REPORT } from '../../constants/index';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import IncidentReportItemsTable from '../IncidentReport/IncidentReportItemsTable';

import {
  describeInspectionReportThunk,
  saveInspectionReportThunk,
  setInspectionReportChangeThunk,
  setInspectionReportCleanUpThunk,
  getInspectionTypeQuestionsThunk
} from '../../store/actions/inspectionReport';
import InspectionReportQuestions from './InspectionReportQuestions';
import uuid from 'uuid';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import Breadcrumb from '../WSAControls/Breadcrumb';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const InspectionReport = ({ intl }) => {
  let history = useHistory();
  let { inspectionReportId, siteId, segmentName, selectedTab } = useParams();
  selectedTab = selectedTab || '2';
  const dispatch = useDispatch();
  const inspectionReport = useSelector(state => getInspectionReport(state));
  const generalData = useSelector(state => getGeneralData(state));
  const generalStatus = generalData.generalFieldTypes.filter(item => item.FieldType === GENERAL_STATUS);
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const currentTimestamp = moment().tz(currentTimezone);
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const inspectionTypeId = inspectionReport.SelectedInspectionReport.InspectionTypeId || 0;

  const ddlInitText = intl.formatMessage({ id: 'common.selectOne', defaultMessage: 'Please select one' });
  const noText = <FormattedMessage id="common.no" defaultMessage="No" />;
  const yesText = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});

  let backUrl = '/inspections';
  if (segmentName === 'measure') {
    backUrl = `/site/${siteId}/${segmentName}/${selectedTab}/inspections`;
  }

  let widgetCode = '';
  if (inspectionReportId) {
    IsEdit = true;
    widgetCode = 'WQSP_INSPECTIONREPORT_UPDATE';
  } else {
    widgetCode = 'WQSP_INSPECTIONREPORT_ADD';
    inspectionReportId = 0;
  }

  useEffect(() => {
    if (inspectionReportId || inspectionReportId === 0) {
      dispatch(describeInspectionReportThunk(inspectionReportId, widgetCode));
    }
  }, [describeInspectionReportThunk, dispatch, inspectionReportId]);

  useEffect(() => {
    if (!IsEdit && inspectionTypeId > 0) {
      dispatch(getInspectionTypeQuestionsThunk(inspectionTypeId, widgetCode));
    }
  }, [getInspectionTypeQuestionsThunk, dispatch, inspectionTypeId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setInspectionReportCleanUpThunk(initialInspectionReportState.inspectionReport.SelectedInspectionReport));
    };
  }, []);

  // Update redux store
  const setLocalInspectionReport = currentState => {
    dispatch(setInspectionReportChangeThunk(currentState));
  };

  useEffect(() => {
    if (!inspectionReport.SelectedInspectionReport.SiteId && siteId && !IsEdit) {
      setLocalInspectionReport({
        ...inspectionReport,
        SelectedInspectionReport: { ...inspectionReport.SelectedInspectionReport, SiteId: parseInt(siteId) }
      });
    }
  }, [setLocalInspectionReport]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = inspectionReport.SelectedInspectionReport;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.InspectionReportId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'WQSP_INSPECTIONREPORT_ADD';
    }

    dispatch(saveInspectionReportThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !inspectionReport ||
      !inspectionReport.SelectedInspectionReport ||
      !inspectionReport.SelectedInspectionReport.SiteId ||
      isRequired(inspectionReport.SelectedInspectionReport.SiteId, 1)
    ) {
      formErrors.SiteId = intl.formatMessage({
        id: 'inspectionReports.siteRequired',
        defaultMessage: 'Site is a mandatory field'
      });
      isValid = false;
    }
    if (
      !inspectionReport ||
      !inspectionReport.SelectedInspectionReport ||
      !inspectionReport.SelectedInspectionReport.WaterSupplyDescriptionId ||
      isRequired(inspectionReport.SelectedInspectionReport.WaterSupplyDescriptionId, 1)
    ) {
      formErrors.WaterSupplyDescriptionId = intl.formatMessage({
        id: 'wqsp.common.waterSupplyMandatory',
        defaultMessage: 'Water supply name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !inspectionReport ||
      !inspectionReport.SelectedInspectionReport ||
      !inspectionReport.SelectedInspectionReport.EntityId ||
      isRequired(inspectionReport.SelectedInspectionReport.EntityId, 1)
    ) {
      formErrors.EntityId = intl.formatMessage({
        id: 'inspectionReports.entityNameRequired',
        defaultMessage: 'Entity name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !inspectionReport ||
      !inspectionReport.SelectedInspectionReport ||
      !inspectionReport.SelectedInspectionReport.InspectionTypeId ||
      isRequired(inspectionReport.SelectedInspectionReport.InspectionTypeId, 1)
    ) {
      formErrors.InspectionTypeId = intl.formatMessage({
        id: 'inspectionReports.inspectionTypeRequired',
        defaultMessage: 'Inspection type is a mandatory field'
      });
      isValid = false;
    }

    if (
      !inspectionReport ||
      !inspectionReport.SelectedInspectionReport ||
      !inspectionReport.SelectedInspectionReport.InspectionPurposeId ||
      isRequired(inspectionReport.SelectedInspectionReport.InspectionPurposeId, 1)
    ) {
      formErrors.InspectionPurposeId = intl.formatMessage({
        id: 'inspectionReports.inspectionPurposeRequired',
        defaultMessage: 'Inspection purpose is a mandatory field'
      });
      isValid = false;
    }

    if (
      !inspectionReport ||
      !inspectionReport.SelectedInspectionReport ||
      !inspectionReport.SelectedInspectionReport.InspectionDate ||
      isRequired(inspectionReport.SelectedInspectionReport.InspectionDate, 1)
    ) {
      formErrors.InspectionDate = intl.formatMessage({
        id: 'inspectionReports.inspectionDateRequired',
        defaultMessage: 'Inspection date is a mandatory field'
      });
      isValid = false;
    }
    if (
      !inspectionReport ||
      !inspectionReport.SelectedInspectionReport ||
      !inspectionReport.SelectedInspectionReport.InspectorName ||
      isRequired(inspectionReport.SelectedInspectionReport.InspectorName, 1)
    ) {
      formErrors.InspectorName = intl.formatMessage({
        id: 'inspectionReports.inspectionNameRequired',
        defaultMessage: 'Inspection name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onDateChange = (newVal, fieldName) => {
    let changeDate = null;
    if (newVal && newVal.unix) changeDate = newVal.unix();

    setLocalInspectionReport({
      ...inspectionReport,
      SelectedInspectionReport: { ...inspectionReport.SelectedInspectionReport, [fieldName]: changeDate }
    });
  };

  const frequencyOptions = () => {
    let opData = [];
    const data = generalData.generalFieldTypes.filter(x => x.FieldType === FREQUENCY);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });
    return opData;
  };

  const statusOptions = () => {
    let statusData = [];
    generalStatus.forEach(element => {
      statusData.push({
        ...element,
        label: element.Name,
        value: element.GeneralFieldTypeId
      });
    });

    let sortedData = statusData.sort(compareValues('label'));
    return [{ value: 0, label: ddlInitText }, ...sortedData];
  };
  const onStatusChange = e => {
    setLocalInspectionReport({
      ...inspectionReport,
      SelectedInspectionReport: { ...inspectionReport.SelectedInspectionReport, [e.target.name]: e.target.value, StatusId: null }
    });
  };

  const onDropdownChange = (e, fieldName) => {
    setLocalInspectionReport({
      ...inspectionReport,
      SelectedInspectionReport: { ...inspectionReport.SelectedInspectionReport, [fieldName]: e.value }
    });
  };

  //on control value change
  const onChange = e => {
    setLocalInspectionReport({
      ...inspectionReport,
      SelectedInspectionReport: { ...inspectionReport.SelectedInspectionReport, [e.target.name]: e.target.value }
    });
  };

  const onInspectionDateChange = newVal => {
    let inspectionDate = null;
    if (newVal && newVal.unix) inspectionDate = newVal.unix();

    setLocalInspectionReport({
      ...inspectionReport,
      SelectedInspectionReport: { ...inspectionReport.SelectedInspectionReport, InspectionDate: inspectionDate }
    });
  };

  const cancelHandler = () => {
    goBack(history, backUrl);
  };

  let messageId = (inspectionReport && inspectionReport.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const title = (inspectionReport.SelectedInspectionReport ? inspectionReport.SelectedInspectionReport.InspectorName : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.editTextCC', defaultMessage: 'Edit' }) + ' - ' + title;
  } else {
    heading = intl.formatMessage({
      id: 'inspectionReports.addInspectionReport',
      defaultMessage: 'Add new Inspection Survey'
    });
  }

  const siteOptions = () => {
    let opData = [];

    const data = generalData.sites;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.SiteId
      });
    });
    return opData;
  };

  const waterSupplyOptions = () => {
    let opData = [];

    const data = generalData.waterSupplyDescriptions.filter(x => x.SiteId === inspectionReport.SelectedInspectionReport.SiteId);

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.WaterSupplyDescriptionId
      });
    });
    return opData;
  };

  const entityTypeOptions = () => {
    let opData = [];

    const data = generalData.entityTypes;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntityTypeId
      });
    });
    return opData;
  };

  const entityNameOptions = () => {
    let opData = [];
    const data = generalData.entities.filter(
      x =>
        x.EntityTypeId === inspectionReport.SelectedInspectionReport.EntityTypeId &&
        x.WaterSupplyDescriptionId === inspectionReport.SelectedInspectionReport.WaterSupplyDescriptionId
    );

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.EntityId
      });
    });
    return opData;
  };

  const inspectionTypeOptions = () => {
    let inspectionTypesData = [];

    const data = inspectionReport.InspectionTypes;

    data.forEach(element => {
      inspectionTypesData.push({
        label: element.Name,
        value: element.InspectionTypeId
      });
    });
    return inspectionTypesData.sort(compareValues('label'));
  };

  const inspectionPurposeOptions = () => {
    let opData = [];

    const data = inspectionReport.InspectionPurposes;

    data.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.InspectionPurposeId
      });
    });
    return opData;
  };

  let ddlClassSensor = localformErrors && localformErrors.InspectionTypeId ? s.ddlError : '';

  const getFormattedate = date => {
    if (!date) return '';
    return moment.unix(date).tz(currentTimezone);
  };

  let breadcrumbHeading = intl.formatMessage({
    id: 'siteControlsTabs.listOfInspections',
    defaultMessage: 'List of Inspections'
  });

  const breadcrumbControl = [
    {
      orderNo: 1,
      name: breadcrumbHeading,
      url: `/site/${siteId}/${segmentName}/${selectedTab}/inspections`,
      showlink: true,
      showCrumb: true
    },
    { orderNo: 3, name: heading, url: ``, showlink: false, showCrumb: true }
  ];

  const getBreadcrumbData = () => {
    if (segmentName === 'control') {
      return breadcrumbControl;
    }
    return breadcrumbControl;
  };

  if (inspectionReport.isRedirect) goBack(history, backUrl);

  return (
    <SiteDashboardControlsContentTemplate
      selectedPage={selectedTab}
      siteId={siteId}
      segmentName={segmentName}
      userProfileWidgets={userProfileWidgets}
    >
      <div>
        <div className={s.inspectionReport}>
          {!segmentName && (
            <div>
              <h1 className={s.tabHeader}>{<FormattedMessage id="inspectionReport.inspections" defaultMessage="Inspections" />}</h1>
            </div>
          )}
          {inspectionReport.isLoading && <LoadingSpinner />}

          <Banner
            key={uuid()}
            failureText={messageText}
            showBanner={inspectionReport.showBanner}
            status={inspectionReport.isOpSuccessful}
            successText={messageText}
          />
          <div className={s.contentWrapper}>
            {segmentName && <Breadcrumb data={getBreadcrumbData()} segmentName={segmentName} />}
            {!segmentName && (
              <div className={s.inspectionReportHeader}>
                <GoBack className={s.backLink}>
                  &lt; &nbsp;
                  <FormattedMessage id="common.back" defaultMessage="BACK" />
                </GoBack>
                <h3>{heading}</h3>
              </div>
            )}
            <div className={s.inspectionReportContent}>
              <Form>
                <div className={s.topRow}>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="siteName">
                        <Form.Label>
                          <FormattedMessage id="inspectionReports.Site" defaultMessage="Site" />
                        </Form.Label>

                        <Dropdown
                          id="formSiteName"
                          dataArray={siteOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: ddlClassSensor
                          }}
                          name="SiteId"
                          onDropdownChange={e => onDropdownChange(e, 'SiteId')}
                          selectedOption={siteOptions().filter(option => option.value === inspectionReport.SelectedInspectionReport.SiteId)}
                          disabled={IsEdit || siteId}
                        />
                        {localformErrors && localformErrors.SiteId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.SiteId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="waterSupplyName">
                        <Form.Label>
                          <FormattedMessage id="wqsp.common.waterSupplyName" defaultMessage="Water Supply Name" />
                        </Form.Label>

                        <Dropdown
                          id="formwaterSupplyName"
                          dataArray={waterSupplyOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: ddlClassSensor
                          }}
                          name="WaterSupplyDescriptionId"
                          onDropdownChange={e => onDropdownChange(e, 'WaterSupplyDescriptionId')}
                          selectedOption={waterSupplyOptions().filter(
                            option => option.value === inspectionReport.SelectedInspectionReport.WaterSupplyDescriptionId
                          )}
                          disabled={IsEdit}
                        />
                        {localformErrors && localformErrors.WaterSupplyDescriptionId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.WaterSupplyDescriptionId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="sourceEntityType">
                        <Form.Label>
                          <FormattedMessage id="inspectionReports.sourceEntityType" defaultMessage="Source Entity Type" />
                        </Form.Label>

                        <Dropdown
                          id="formSourceEntityType"
                          dataArray={entityTypeOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: ddlClassSensor
                          }}
                          name="EntityTypeId"
                          onDropdownChange={e => onDropdownChange(e, 'EntityTypeId')}
                          selectedOption={entityTypeOptions().filter(
                            option => option.value === inspectionReport.SelectedInspectionReport.EntityTypeId
                          )}
                          disabled={IsEdit}
                        />
                        {localformErrors && localformErrors.EntityTypeId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.EntityTypeId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="sourceEntityName">
                        <Form.Label>
                          <FormattedMessage id="inspectionReports.sourceEntityName" defaultMessage="Entity Name" />
                        </Form.Label>

                        <Dropdown
                          id="formSourceEntityName"
                          dataArray={entityNameOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: ddlClassSensor
                          }}
                          name="EntityId"
                          onDropdownChange={e => onDropdownChange(e, 'EntityId')}
                          selectedOption={entityNameOptions().filter(
                            option => option.value === inspectionReport.SelectedInspectionReport.EntityId
                          )}
                          disabled={IsEdit}
                        />
                        {localformErrors && localformErrors.EntityId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.EntityId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formInspectionType">
                        <Form.Label>
                          <FormattedMessage id="inspectionReports.inspectionType" defaultMessage="Inspection Survey Type" />
                        </Form.Label>

                        <Dropdown
                          id="formInspectionType"
                          dataArray={inspectionTypeOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: ddlClassSensor
                          }}
                          name="InspectionTypeId"
                          onDropdownChange={e => onDropdownChange(e, 'InspectionTypeId')}
                          selectedOption={inspectionTypeOptions().filter(
                            option => option.value === inspectionReport.SelectedInspectionReport.InspectionTypeId
                          )}
                          disabled={IsEdit}
                          data-unittest="formSensor"
                        />
                        {localformErrors && localformErrors.InspectionTypeId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.InspectionTypeId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formInspectionPurpose">
                        <Form.Label>
                          <FormattedMessage id="inspectionReports.inspectionPurpose" defaultMessage="Inspection Purpose" />
                        </Form.Label>

                        <Dropdown
                          id="formInspectionPurpose"
                          dataArray={inspectionPurposeOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                            customClassName: ddlClassSensor
                          }}
                          name="InspectionPurposeId"
                          onDropdownChange={e => onDropdownChange(e, 'InspectionPurposeId')}
                          selectedOption={inspectionPurposeOptions().filter(
                            option => option.value === inspectionReport.SelectedInspectionReport.InspectionPurposeId
                          )}
                          disabled={IsEdit}
                        />
                        {localformErrors && localformErrors.InspectionPurposeId && (
                          <p role="alert" className={s.error}>
                            {localformErrors.InspectionPurposeId}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formInspectionDate">
                        <Form.Label>
                          <FormattedMessage id="inspectionReports.dateOfInspection" defaultMessage="Date of Inspection" />
                        </Form.Label>
                        <DateTimePicker
                          className={`${localformErrors.InspectionDate ? s.formControlError : s.frmCalendar}`}
                          onChange={m => onInspectionDateChange(m)}
                          timeFormat={null}
                          dateFormat="DD-MMM-YYYY"
                          value={getFormattedate(inspectionReport.SelectedInspectionReport.InspectionDate)}
                          defaultValue={getFormattedate(inspectionReport.SelectedInspectionReport.InspectionDate)}
                          closeOnSelect={true}
                          showClear={false}
                        />
                        {localformErrors && localformErrors.InspectionDate && (
                          <p role="alert" className={s.error}>
                            {localformErrors.InspectionDate}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formInspectionPurposeName">
                        <Form.Label>
                          <FormattedMessage id="inspectionReports.nameofInspection" defaultMessage="Name of Inspection" />
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="InspectorName"
                          onChange={onChange}
                          value={inspectionReport.SelectedInspectionReport.InspectorName}
                          className={`${s.formControl} ${localformErrors && localformErrors.InspectorName ? s.formControlError : ''}`}
                          placeholder={intl.formatMessage({
                            id: 'inspectionReports.nameofInspection',
                            defaultMessage: 'Name of Inspection'
                          })}
                        />
                        {localformErrors && localformErrors.InspectorName && (
                          <p role="alert" className={s.error}>
                            {localformErrors.InspectorName}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formLastReviewDate">
                        <Form.Label>
                          <FormattedMessage id="common.lastReviewDate" defaultMessage="Last Review Date" />
                        </Form.Label>
                        <DateTimePicker
                          isValidDate={disableFutureDates}
                          className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                          onChange={m => onDateChange(m, 'LastReviewDate')}
                          timeFormat={null}
                          dateFormat="DD-MMM-YYYY"
                          value={getFormattedate(inspectionReport.SelectedInspectionReport.LastReviewDate)}
                          defaultValue={getFormattedate(inspectionReport.SelectedInspectionReport.LastReviewDate)}
                          closeOnSelect={true}
                          showClear={false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formNextReviewDate">
                        <Form.Label>
                          <FormattedMessage id="common.nextReviewDate" defaultMessage="Next Review Date" />
                        </Form.Label>
                        <DateTimePicker
                          className={`${localformErrors.NextReviewDate ? s.formControlError : s.frmCalendar}`}
                          onChange={m => onDateChange(m, 'NextReviewDate')}
                          timeFormat={null}
                          dateFormat="DD-MMM-YYYY"
                          value={getFormattedate(inspectionReport.SelectedInspectionReport.NextReviewDate)}
                          defaultValue={getFormattedate(inspectionReport.SelectedInspectionReport.NextReviewDate)}
                          closeOnSelect={true}
                          showClear={false}
                        />
                        {localformErrors && localformErrors.NextReviewDate && (
                          <p role="alert" className={s.error}>
                            {localformErrors.NextReviewDate}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="formGroupReviewFrequency">
                        <Form.Label>
                          <FormattedMessage id="common.reviewFrequency" defaultMessage="Review Frequency" />
                        </Form.Label>

                        <Dropdown
                          id="formMaintenanceFrequency"
                          dataArray={frequencyOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                          }}
                          name="ReviewFrequencyId"
                          onDropdownChange={e => onDropdownChange(e, 'ReviewFrequencyId')}
                          selectedOption={frequencyOptions().filter(
                            option => option.value === inspectionReport.SelectedInspectionReport.ReviewFrequencyId
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Form.Group controlId="formActioned">
                        <Form.Label>
                          <FormattedMessage id="common.actioned" defaultMessage="Is Actioned" />
                        </Form.Label>

                        <ToggleSwitch
                          handleClick={() => {
                            onStatusChange({
                              target: { name: 'IsActioned', value: !inspectionReport.SelectedInspectionReport.IsActioned }
                            });
                          }}
                          classname={s.switch}
                          checked={inspectionReport.SelectedInspectionReport.IsActioned}
                          labelChecked={yesText}
                          labelUnchecked={noText}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {!inspectionReport.SelectedInspectionReport.IsActioned && (
                    <Row>
                      <Col lg={3}>
                        <Form.Group controlId="formStatus">
                          <Form.Label>
                            <FormattedMessage id="common.status" defaultMessage="Status" />
                          </Form.Label>

                          <Dropdown
                            id="formStatus"
                            dataArray={statusOptions()}
                            controlData={{ placeholderText: ddlInitText }}
                            onDropdownChange={e => onDropdownChange(e, 'StatusId')}
                            data-unittest="formDdlRiskStatus"
                            selectedOption={statusOptions().filter(
                              option => option.value === inspectionReport.SelectedInspectionReport.StatusId
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>

        <InspectionReportQuestions
          inspectionReport={inspectionReport.SelectedInspectionReport}
          isLoading={inspectionReport.isLoading}
          widgetCode={widgetCode}
        />
        <AdditionalComments inspectionReport={inspectionReport.SelectedInspectionReport} onChange={onChange} intl={intl} />
        {IsEdit && (
          <IncidentReportItemsTable
            referedId={inspectionReportId}
            incidentReportItems={inspectionReport.SelectedInspectionReport.IncidentReports}
            docType={DOCUMENT_TYPE_INSPECTION_REPORT}
            mainObj={inspectionReport.SelectedInspectionReport}
            userProfileWidgets={userProfileWidgets}
          />
        )}
        <Row>
          <Col>
            <Button
              variant="primary"
              className={classNames(s.margin5, s.btnSaveChanges)}
              onClick={submitForm}
              noValidate
              data-unittest="saveData"
            >
              <FormattedMessage id="common.save" defaultMessage="SAVE" />
            </Button>

            <Button
              variant="outline-secondary"
              className={classNames(s.btnCancel)}
              onClick={cancelHandler}
              noValidate
              data-unittest="saveCancel"
            >
              <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
            </Button>
          </Col>
        </Row>
      </div>
    </SiteDashboardControlsContentTemplate>
  );
};

const AdditionalComments = ({ inspectionReport, intl, onChange }) => {
  return (
    <div className={s.inspectionReport}>
      <div className={s.contentWrapper}>
        <div className={s.inspectionReportContent}>
          <Row>
            <Col>
              <Form.Group controlId="formInspectionReportsAdditionalObservation">
                <Form.Label>
                  <FormattedMessage id="inspectionReports.additionalObservation" defaultMessage="Additional Observation" />
                </Form.Label>

                <Form.Control
                  className={s.textArea}
                  as="textarea"
                  rows="3"
                  name="AdditionalObservation"
                  onChange={onChange}
                  placeholder={intl.formatMessage({
                    id: 'inspectionReports.additionalObservation',
                    defaultMessage: 'Additional Observation'
                  })}
                  value={inspectionReport.AdditionalObservation}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formInspectionReportsGeneralInformation">
                <Form.Label>
                  <FormattedMessage id="inspectionReports.generalInformation" defaultMessage="General Information" />
                </Form.Label>

                <Form.Control
                  className={s.textArea}
                  as="textarea"
                  rows="3"
                  name="GeneralInformation"
                  onChange={onChange}
                  placeholder={intl.formatMessage({
                    id: 'inspectionReports.generalInformation',
                    defaultMessage: 'General Information'
                  })}
                  value={inspectionReport.GeneralInformation}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

InspectionReport.defaultProps = {
  inspectionReport: {
    ...initialInspectionReportState
  }
};

export default injectIntl(InspectionReport);
