import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './ControlMonitoring.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import ControlMonitoringListTable from './ControlMonitoringListTable';
import Pagination from '../Pagination/Pagination';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getUserProfileWidget, getControlMonitoring, getGeneralData, getCurrentTimezone } from '../../selectors/index';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { compareValues } from '../../utils/';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Cookies from 'js-cookie';
import SiteDashboardControlsContentTemplate from '../SiteDashboardControls/SiteDashboardControlsContentTemplate';
import {
  getControlMonitoringThunk,
  getFilterThunk,
  setFilteredDataThunk,
  saveControlMonitoringThunk,
  setCurrentPage,
  setPageFilter
} from '../../store/actions/controlMonitoring';

const SEARCH_FILTER = 'SEARCH_FILTER';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

const ControlMonitoringList = ({ intl }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { siteId, segmentName, selectedTab } = useParams();
  segmentName = segmentName || 'monitor';
  selectedTab = selectedTab || '2';

  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const controlMonitoring = useSelector(state => getControlMonitoring(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const generalData = useSelector(state => getGeneralData(state));
  const [searchFilter, setSearchFilter] = useState('');
  const [asendingOrder, setAsendingOrder] = useState(true);
  const [active, setActive] = useState(false);
  const [controlMonitoringId, setControlMonitoringId] = useState(0);

  let offset = controlMonitoring.offset || 0;
  let limit = controlMonitoring.limit || 1000;
  let filter = controlMonitoring.filterBy.filter || null;

  useEffect(() => {
    dispatch(getFilterThunk('WQSP_CONTROL_MONITORINGS_LIST', siteId));
  }, [dispatch, getFilterThunk, siteId]);

  useEffect(() => {
    let filterObj = {
      filter: filter,
      offset: offset,
      limit: limit
    };
    dispatch(getControlMonitoringThunk('WQSP_CONTROL_MONITORINGS_LIST', filterObj, siteId));
  }, [getControlMonitoringThunk, filter, siteId]);

  let disableControls = controlMonitoring.isLoading || false;
  let messageId = (controlMonitoring && controlMonitoring.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let isEditAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MONITORINGS_EDIT');
  let isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MONITORINGS_VIEW');

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const onSortOrder = () => {
    setAsendingOrder(!asendingOrder);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const filteredResults = (filterType, value) => {
    let filteredList = controlMonitoring.controlMonitorings;

    let apifilterObj = {
      filterName: null,
      filterStatusId: null
    };

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filterName = value;
        filteredList = filteredList.filter(function(item) {
          return item.MonitoredItem.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filterName = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.MonitoredItem.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }

    if (controlMonitoring.controlMonitoringListCount > controlMonitoring.controlMonitorings.length) {
      dispatch(setPageFilter(apifilterObj));
    } else {
      dispatch(setFilteredDataThunk({ filteredResults: { filteredResults: filteredList } }));
    }
  };

  const onAddNewClick = () => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings/control-monitoring`);
  };

  const onChangeControlMonitoringViewClick = controlMonitoringId => {
    history.push(`/site/${siteId}/${segmentName}/${selectedTab}/control-monitorings/control-monitorings-view/${controlMonitoringId}`);
  };

  const onControlMonitoringRemoveClick = controlMonitoringId => {
    setActive(true);
    setControlMonitoringId(controlMonitoringId);
  };

  const handleClose = () => {
    setActive(false);
    setControlMonitoringId(0);
  };

  const handleConfirm = () => {
    deleteControlMonitoring();
    handleClose();
  };

  const deleteControlMonitoring = () => {
    let saveData = {};
    saveData.PerformDelete = true;
    saveData.ControlMonitoringId = controlMonitoringId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveControlMonitoringThunk(saveData, 'WQSP_CONTROL_MONITORINGS_DELETE'));
  };

  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'WQSP_CONTROL_MONITORINGS_DELETE');
  const dialogTitle = intl.formatMessage({
    id: 'controlMonitoring.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this control monitor?'
  });

  let controlMonitorObj = Object.values(controlMonitoring.filteredResults);

  if (asendingOrder) {
    controlMonitorObj = controlMonitorObj.sort(compareValues('LastReviewDate', 'desc'));
  } else {
    controlMonitorObj = controlMonitorObj.sort(compareValues('LastReviewDate'));
  }

  return (
    <div className={s.controlMonitoringList}>
      <SiteDashboardControlsContentTemplate
        selectedPage={selectedTab}
        siteId={siteId}
        segmentName={segmentName}
        userProfileWidgets={userProfileWidgets}
      >
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />

        <div className={s.contentWrapper}>
          {controlMonitoring.isLoading && <LoadingSpinner />}
          <Banner
            failureText={messageText}
            showBanner={controlMonitoring.showBanner}
            status={controlMonitoring.isOpSuccessful}
            successText={messageText}
          />

          <Row>
            <Col sm={6} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                initialText={searchFilter}
                placeHolderTextId="common.search"
              />
            </Col>
            <Col sm={6} lg={8}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'WQSP_CONTROL_MONITORINGS_ADDNEW')}
                onClick={onAddNewClick}
                disabled={disableControls}
                data-unittest="addButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div className="">
            <Pagination
              data={controlMonitorObj}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={onPageChangeHandler}
              startingPage={controlMonitoring.currentPage}
            >
              <ControlMonitoringListTable
                onSortOrder={onSortOrder}
                currentTimezone={currentTimezone}
                asendingOrder={asendingOrder}
                editAllowed={isEditAllowed}
                viewAllowed={isViewAllowed}
                deleteAllowed={isDeleteAllowed}
                editControlMonitoring={onChangeControlMonitoringViewClick}
                removeControlMonitoring={onControlMonitoringRemoveClick}
              />
            </Pagination>
          </div>
        </div>
      </SiteDashboardControlsContentTemplate>
    </div>
  );
};

export default injectIntl(ControlMonitoringList);
